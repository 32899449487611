export default {
  contacto_create: false,
  contacto_update: null,
  contacto_reload: false,
  contacto_key: false,
  contacto_show: false,

  isLoadingContacto: false,
  contacto_firebase: [],
  contacto_data_cliente: [],
  contacto_data_proveedor: [],
  contacto_data_transportadora: [],
  contacto_load: false
}
