
export default {
  isLoading:(state)=>state.loading,
  getAnimal:(state)=>state.data,
  getCelo:(state)=>state.celo,
  getToro:(state)=>state.toro,
  getHembra:(state)=>state.hembra,
  getDisponible:(state)=>state.disponible,
  getTermo:(state)=>state.termo,
  getNomina:(state)=>state.nomina,
  getParaSecado:(state)=>state.parasecado,
  getInseminada:(state)=>state.inseminada,
  getPrenada:(state)=>state.prenada,
  getMuerto:(state)=>state.muerto,
  getVivo:(state)=>state.vivo,
  getTodos:(state)=>state.todos,
  getVenta:(state)=>state.venta,
  getEnfermo:(state)=>state.enfermo,
  getPostParto:(state)=>state.postparto,
  getTernero:(state)=>state.ternero,
  getSecado:(state)=>state.secado,
  getAnimalByEstado:(state)=>state.dataEstado,
  getNominaId:(state)=>state.nominaId,
  getSemen:(state)=>state.semen,
  getFicha:(state)=>state.ficha,
  getVaquilla:(state)=>state.vaquilla,
  getPreparto:(state)=>state.preparto,
  getCount:(state)=>state.count,
  getReporteAvanzado:(state)=>state.reporteavanzado,
  getReporteReproduccion:(state)=>state.reportereproduccion,
  getSocketData: (state) => state.socketData,
}
