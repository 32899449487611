export default {

  celo_data(state, request) {
    state.celo_data = request;
  },
  celo_nuevo(state, request) {
    state.celo_nuevo = request;
  },
  celo_historico(state, request) {
    state.celo_historico = request;
  },
  celo_load(state, request) {
    state.celo_load = request;
  },
  celo_save_load(state, request) {
    state.celo_save_load = request;
  },
}