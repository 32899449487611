<template>
  <v-dialog :value="value" persistent max-width="800" @keydown.esc="cancelar()">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>{{operacion ? 'Registrar Chequera' : 'Modificar Chequera'}}</v-toolbar-title>
      </v-toolbar>
      <v-divider></v-divider>
      <v-container>
        <v-form ref="form">
          <v-row dense>
            <v-col cols="12">
              <vue-text-field
                label="Descripcion"
                v-model="data.descripcion"
                id="chequeInput1"
                @keyup.native.enter="next(1,data.descripcion)"
              ></vue-text-field>
            </v-col>
            <v-col cols="12">
              <vue-autocomplete-generic
                id="chequeInput2"
                action="cuentabanco/Vact_cuentabanco_data"
                getter="cuentabanco/Vget_cuentabanco_data"
                load="cuentabanco/Vget_cuentabanco_load"
                pk="idcuentabanco"
                label="Seleccione Cuenta Bancaria"
                title="desccuentabanco"
                @sync-input="search = $event"
                permission="Marca"
                :create="true"
                @create="formulario.cuentabanco = $event"
                v-model="data.idcuentabanco.idcuentabanco"
                @keyup.native.enter="next(2,data.idcuentabanco.idcuentabanco)"
              />
              <div v-if="formulario.cuentabanco">
                <Apertura-Cuentabanco-Create
                  v-model="formulario.cuentabanco"
                  :sync-input="search"
                  @sync-input="data.idcuentabanco.idcuentabanco = $event ,next(1,'-')"
                />
              </div>
            </v-col>
            <v-radio-group class="ml-3" v-model="data.diferido" id="chequeInput3">
              <template v-slot:label>
                <div>
                  Seleccione el tipo de
                  <strong>cheque</strong>
                </div>
              </template>
              <v-radio label="Cheque a la Vista" class="mt-2" :value="false"></v-radio>
              <v-radio label="Cheque Diferido" :value="true"></v-radio>
            </v-radio-group>
            <v-col md="7"></v-col>
            <v-col cols="12" md="6">
              <vue-text-currency
                label="Numero de Inicio"
                id="chequeInput4"
                :rules="true"
                v-model="data.inicio"
                @keyup.native.enter="next(4,data.inicio)"
              ></vue-text-currency>
            </v-col>
            <v-col cols="12" md="6">
              <vue-text-currency
                label="Numero de Fin"
                id="chequeInput5"
                :rules="true"
                v-model="data.fin"
                @keyup.native.enter="next(5,data.fin)"
              ></vue-text-currency>
            </v-col>
          </v-row>
        </v-form>
      </v-container>

      <v-card-actions>
        <v-btn text color="red" @click="cancelar()">
          <v-icon>clear</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text color="primary" id="chequeInputaceptar" @click="guardar()">
          <v-icon>done</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    <c-overlay :value="Vget_cheque_save_load"></c-overlay>
  </v-dialog>
</template>

<script>
import { focus } from "@/services/focus.service";
import { mapActions, mapGetters } from "vuex";
import { mensaje } from "@/services/notify.service";
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    datos: {
      type: Object,
      default: null,
    },
    'sync-input':{
      type: String,
      default: null
    }
  },
  data() {
    return {
      operacion: true,
      formulario: {
        cuentabanco: false,
      },
      data: {
        idcheque: 0,
        descripcion: "",
        idcuentabanco: {
          idcuentabanco: "",
        },
        inicio: "",
        fin: "",
        actual: 1,
        diferido: false,
        estado: true,
      },
      default: {
        idcheque: 0,
        descripcion: "",
        idcuentabanco: {
          idcuentabanco: "",
        },
        inicio: "",
        fin: "",
        actual: 1,
        diferido: false,
        estado: true,
      },
    };
  },
  mounted() {
    if(this.syncInput != null) this.data.descripcion = this.syncInput;
    if (this.datos != null) {
      this.data = JSON.parse(JSON.stringify(this.datos));
      this.operacion = false;
    }
    this.next(0,'-');
  },
  computed: {
    ...mapGetters("cheque", ["Vget_cheque_save_load"]),
  },
  methods: {
    ...mapActions("cheque", [
      "Vact_cheque_data",
      "Vact_cheque_save",
      "Vact_cheque_update",
    ]),
    cancelar() {
      this.Vact_cheque_data();
      this.$emit("input", false);
      this.$emit("datos", null);
      this.operacion = true;
    },
    next(index, data) {
      focus.nextidName("chequeInput", index, data);
    },
    async guardar() {
      if (!this.$refs.form.validate()) return this.$notify(mensaje.error('Verifique los datos ingresados'));
      let a;
      const sync = this.syncInput;
      if (this.operacion) a = await this.Vact_cheque_save(this.data);
      if (!this.operacion)
        a = await this.Vact_cheque_update({
          id: this.datos.idcheque,
          data: this.data,
        });

      if (a.estado == true) {
        if(sync != null){
          this.cancelar();
          this.$emit('sync-input',a.data.data.id);
        }
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$notify(a.info);
        this.$refs.form.resetValidation();
        this.next(0, "-");
        if(!this.operacion) this.cancelar();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
    },
  },
};
</script>

<style>
</style>