export default {
  transladobancobanco_data(state, request) {
    state.transladobancobanco_data = request;
  }, 
  transladobancobanco_load(state, request) {
    state.transladobancobanco_load = request;
  }, 
  transladobancobanco_save_load(state, request) {
    state.transladobancobanco_save_load = request;
  }, 
}