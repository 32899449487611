import 'material-design-icons-iconfont/dist/material-design-icons.css' 
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

Vue.component('my-component', {
  methods: {
    changeLocale () {
      this.$vuetify.lang.current = 'es'
    },
  },
})

export default new Vuetify({
  icons: {
    iconfont: 'md',
  },
  theme: {
    themes: {
      light: {
        background: colors.grey.lighten2,
        customtext: colors.shades.dark
      },
      dark:{
        customtext: colors.shades.white
      }
    }
  }
});