import { db } from "@/services/db.service";
import { get, post, put, del } from "@/services/api/api.service";
import { SET_LOADING, SET_EMPRESA, url } from "./constants";
import dbMonedaDefault from "@/db/dbMonedaDefault";
import { TokenService } from "@/services/auth/storage.service";
export default {

  async Vact_empresa_data({ commit, dispatch }) {
    commit(SET_LOADING, true);
    try {
      const response = await get(url);
      commit(SET_EMPRESA, response);
      commit(SET_LOADING, false);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(SET_LOADING, false);
      throw e;
    }
  },
  async Vact_empresa_del({ commit, dispatch }, id) {
    commit(SET_LOADING, true);
    const response = await del(`${url}${id}/`);
    dispatch("snackbar/setNotification", response, { root: true });
    commit(SET_LOADING, false);
    return response;
  },
  async Vact_empresa_save({ commit, dispatch }, data) {
    commit(SET_LOADING, true);
    const response = await post(url, data);
    dispatch("snackbar/setNotification", response, { root: true });
    commit(SET_LOADING, false);
    return response;
  },
  async Vact_empresa_update({ commit, dispatch }, { id, data }) {
    commit(SET_LOADING, true);
    const response = await put(`${url}${id}/`, data);
    dispatch("snackbar/setNotification", response, { root: true });
    commit(SET_LOADING, false);
    return response;
  },
  async Vact_empresa_active({ commit }) {
    const xidusuario = TokenService.getToken();
    commit(SET_LOADING, true);
    try {
      const data = await db.get("/apiactive/");
      commit("empresa_active", data);
      data.forEach((element) => {
        if (element.est_emp_tam_usu) {
          dbMonedaDefault
            .transaction("rw", dbMonedaDefault.monedaDefault, () => {
              dbMonedaDefault.monedaDefault.clear();
              const moneda_active = element.idempresa.idmoneda;
              let moneda = { ...moneda_active, ...{ xidusuario: xidusuario } };

              dbMonedaDefault.monedaDefault.add(moneda);
              dbMonedaDefault.monedaDefault.toArray().then((x) => {
                commit("empresa_moneda", x[0]);
              });
            })
            .catch(function(e) {
              console.log(e);
            });
        }
      });
      commit(SET_LOADING, false);
      return true;
    } catch (e) {
      dbMonedaDefault.monedaDefault
        .where("xidusuario")
        .equals(xidusuario)
        .toArray()
        .then((x) => {
          commit("empresa_moneda", x);
        });
        commit(SET_LOADING, false);
      return false;
    }
  },
  async Vact_empresa_active_change({ commit }, { id, data }) {
 
    commit(SET_LOADING, true);
    try {
      const resp = await db.update(`/apiactive/${id}/`, data);
      commit(SET_LOADING, false);
      return resp;
    } catch (e) {
      commit(SET_LOADING, false);
      return false;
    }
  },
};
