<template>
    <div>
        <div class="text-center" v-if="actived">
            <v-btn color="red lighten-2" dark @click="cancelar">Cancelar edicion</v-btn>
        </div>
        <v-expansion-panels>
            <v-expansion-panel>
                <v-expansion-panel-header disable-icon-rotate>
                    Mostrar animales registrados - Existen:
                    <span class="font-weight-bold">{{ cantidad }}</span>
                    <template v-slot:actions>
                        <v-icon color="error" v-if="cantidad == 0">
                            info
                        </v-icon>
                        <v-icon color="teal" v-else> done </v-icon>
                    </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-list two-line dense>
                        <v-list-item-group v-model="selected" active-class="blue--text">
                            <div v-for="(item, index) in items" :key="index">
                                <v-list-item :key="item.actions">
                                    <template>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                {{ item.title }}
                                            </v-list-item-title>
                                            <v-list-item-subtitle class="text--primary">
                                                {{ item.headline }}
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle>
                                                {{ item.subtitle }}
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-list-item-action-text>
                                                {{ item.action }}
                                            </v-list-item-action-text>
                                            <c-btn-table-next />
                                        </v-list-item-action>
                                    </template>
                                </v-list-item>
                                <v-divider v-if="index < items.length - 1" :key="`a${index}`"></v-divider>
                            </div>
                        </v-list-item-group>
                    </v-list>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    props: {
        buscar: String,
    },
    data: () => ({
        actived: false,
        selected: [],
        items: [],
        awaitingSearch: false,
        cantidad: 0,
        noexiste: [
            {
                action: "Actual",
                headline: "Puede agregar el animal",
                subtitle: "No se encontro animales",
                title: "Atencion",
                data: null,
            }
        ],
        permission: {
            can_update: false,
        }
    }),
    watch: {
        getTodos(val) {
            if (val.length == 0) {
                this.items = this.noexiste
            } else {
                val.forEach(element => {
                    this.items.push({
                        action: element.idanimal,
                        headline: `${element.nombanimal} - ${element.rpanimal}`,
                        subtitle: element.idsexo.descsexocria,
                        data: element,
                    });
                });
            }
            this.cantidad = val.length
        },
        buscar() {
            if (!this.awaitingSearch) {
                setTimeout(() => {
                    this.obtain_item()
                    this.awaitingSearch = false
                }, 1000);
            }
            this.awaitingSearch = true
        }
    },
    computed: {
        ...mapGetters("animal", ["getTodos"])
    },
    methods: {
        ...mapActions("animal", ["fetchTodos"]),
        obtain_item() {
            if (this.buscar != null && this.buscar.length >= 3) {
                this.fetchTodos(`name=${this.buscar}`)
            } else if (this.buscar == null || this.buscar == "") {
                this.items = []
                this.cantidad = 0
            }
        },
        cancelar() {
            this.activated = false
        }
    }
}
</script>