<template>
  <c-dialog-confirmation
    :value="value"
    @cancel="close()"
    @done="save()"
    :loading="isLoading"
  >
    Desea eliminar la Actividad Nro <strong>{{ props.idsiembra }}</strong
    >?</c-dialog-confirmation
  >
</template>

<script>
import { mapActions } from "vuex";
import { mensaje } from "@/services/notify.service";
export default {
  props: {
    value: Boolean,
    props: Object,
  },
  data: () => ({
    isLoading: false,
  }),
  watch: {
    $route() {
      return this.close();
    },
  },
  methods: {
    ...mapActions("siembra", ["Vact_siembra_del"]),

    async save() {
      this.isLoading = true;
      const a = await this.Vact_siembra_del({
        id: this.props,
      });
      if (a.estado == true) {
        this.$notify(a.info);
        this.close();
        this.$emit('close');
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.isLoading = false;
    },
    close() {
      this.$emit("input", false);
    },
  },
};
</script>

<style></style>
