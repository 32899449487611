<template>
    <v-card>
        <v-card-title v-if="showtitle">
            <div class="text-center">{{ title }}</div>
            <v-divider class="mx-1" insert vertical></v-divider>
        </v-card-title>
        <v-card-text>
            <v-progress-linear v-if="load" indeterminated></v-progress-linear>
            <div>
                <v-chart :options="options" autoresize />
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
import { db } from "@/services/db.service";
import ECharts from "vue-echarts/components/ECharts";
export default {
    components: {
        "v-chart": ECharts
    },
    props: {
        title: String,
        showtitle: Boolean,
        api: String,
        loadPresupuesto: Boolean,
    },
    mounted() {
        this.options.legend.textStyle.color = this.darkMode ? "#eee" : "#000";
        this.options.yAxis.axisLabel.color = this.darkMode ? "#eee" : "#000";
        this.options.xAxis.axisLabel.color = this.darkMode ? "#eee" : "#000";
        this.obtenerDatos();
    },
    watch: {
        darkMode() {
            this.changeDarkMode()
        },
        loadPresupuesto() {
            this.obtenerDatos()
        }
    },
    computed: {
        darkMode() {
            return this.$vuetify.theme.dark;
        }
    },
    methods: {
        changeDarkMode() {
            this.options.legend.textStyle.color = this.darkMode ? "#eee" : "#000";
            this.options.yAxis.axisLabel.color = this.darkMode ? "#eee" : "#000";
            this.options.xAxis.axisLabel.color = this.darkMode ? "#eee" : "#000";
        },
        async obtenerDatos() {
            this.load = true
            try {
                const response = await db.get(this.api)
                this.options.series[0].name = response.tipo;
                let legend = [];
                let presupuesto = [];
                let gasto = [];
                response.data.forEach((x) => {
                    legend.push(x.name);
                    presupuesto.push(x.montos);
                    gasto.push(x.gastos);
                });
                this.options.yAxis.data = legend;
                this.options.series[0].data = presupuesto;
                this.options.series[1].data = gasto;
            } catch (error) {
                console.log(error)
            }
            this.load = false
        }
    },
    data: () => ({
        load: false,
        options: {
            title: {
                text: 'Presupuesto',
                subtext: 'Presupuesto'
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            legend: {
                data: ['Presupuesto', 'Gasto'],
                textStyle: {
                    color: "#000",
                },
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            xAxis: {
                type: 'value',
                boundaryGap: [0, 0.01],
                axisLabel: {
                    color: "#000",
                },
            },
            yAxis: {
                type: 'category',
                data: [],
                axisLabel: {
                    color: "#000",
                },
            },
            series: [
                {
                    name: 'Presupuesto',
                    type: 'bar',
                    data: [],
                    textStyle: {
                        color: "#000",
                    },
                },
                {
                    name: 'Gasto',
                    type: 'bar',
                    data: [],
                    textStyle: {
                        color: "#000",
                    },
                }
            ]
        }
    })
}
</script>