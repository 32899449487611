export default {
  notacreditoventa_data(state, request) {
    state.notacreditoventa_data = request;
  }, 
  notacreditoventa_load(state, request) {
    state.notacreditoventa_load = request;
  }, 
  notacreditoventa_save_load(state, request) {
    state.notacreditoventa_save_load = request;
  }, 
}