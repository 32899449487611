<template>
  <c-dialog-confirmation
    :value="value"
    @cancel="cancel()"
    @done="disabledUser()"
    :loading="isUsersLoading"
    >Desea {{ active ? "desabilitar" : "habilitar" }} al Usuario
    <strong>{{ user.username }}</strong
    >?</c-dialog-confirmation
  >
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    value: Boolean,
    user: Object,
    active: Boolean,
  },
  computed: {
    ...mapGetters("users", ["isUsersLoading"]),
  },
  methods: {
    ...mapActions("users", [
      "setUserDisabled",
      "fetchUsersActive",
      "fetchUsersInactive",
    ]),
    cancel() {
      this.$emit("input", false);
    },
    async disabledUser() {
      const id = this.user.id;
      const band = !this.active ? "&active=no" : "&active=yes";

      const response = await this.setUserDisabled({
        id,
        band,
      });

      if (response.success) {
        this.cancel();
        this.fetchUsersActive();
        this.fetchUsersInactive();
      }
    },
  },
};
</script>

<style>
</style>