<template>
  <InputAutocomplete
    label="Seleccione el tipo de plaga"
    item-value="idplagas"
    item-text="descripcion"
    dense
    outlined
    :value="value"
    :items="getTipoPlaga"
    :loading="isLoading"
    @input="$emit('input', $event)"
  />
</template>
<script>
import InputAutocomplete from "@/ncomponents/InputAutocomplete";
import { mapActions, mapGetters } from "vuex";
export default {
  props:{
    value: [String, Object]
  },
  components: {
    InputAutocomplete,
  },
  mounted() {
    this.fetchTipoPlaga();
  },
  computed: {
    ...mapGetters("plaga", ["getTipoPlaga", "isLoading"]),
  },
  methods: {
    ...mapActions("plaga", ["fetchTipoPlaga"]),
  },
};
</script>
