import { db } from "@/services/db.service";
export default {

    async Vact_cheque_desembolso_save({dispatch,commit},data){
        commit('cheque_save_load',true);
        try {
            const resp = await db.save('/apidesembolso/',data);
            dispatch('Vact_cheque_data_desembolso');
            commit('cheque_save_load',false);
            return resp
        } catch (e) {
            commit('cheque_save_load',false);
            return false
        } 
    },
    async Vact_cheque_data_desembolso({commit}){
        commit('cheque_load',true);
        try {
            const data = await db.get('/apidesembolso/');
            commit('cheque_data_desembolso',data);
            commit('cheque_load',false);
            return true
        } catch (e) {
            commit('cheque_load',false);
            return false
        } 
    },
    async Vact_cheque_data({commit}){
        commit('cheque_load',true);
        try {
            const data = await db.get('/apicheque/');
            commit('cheque_data',data);
            commit('cheque_load',false);
            return true
        } catch (e) {
            commit('cheque_load',false);
            return false
        } 
    },
    async Vact_cheque_save({commit},data){
        commit('cheque_save_load',true);
        try {
            const resp = await db.save('/apicheque/',data);
            commit('cheque_save_load',false);
            return resp;
        } catch (e) {
            commit('cheque_save_load',false);
            return e
        } 
    },
    async Vact_cheque_del({dispatch},id){
        try {
            const data = await db.delete('/apicheque/'+id);
            dispatch('Vact_cheque_data');
            return data
        } catch (e) {
            return false
        } 
    },
    async Vact_cheque_update({commit},{id, data}){
        commit('cheque_save_load',true);
        try {
            const resp = await db.update('/apicheque/'+id+'/',data);
            commit('cheque_save_load',false);
            return resp;
        } catch (e) {
            commit('cheque_save_load',false);
            return e
        } 
    },
}
