
export default {
  Vget_apertura_data: (state) => {
    return state.apertura_data
  },
  Vget_apertura_load: (state) => {
    return state.apertura_load
  },
  Vget_apertura_save_load: (state) => {
    return state.apertura_save_load
  },
}
