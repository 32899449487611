<template>
  <div>
    <v-dialog
      :value="value"
      max-width="1000px"
      persistent
      @keydown.esc="close()"
    >
      <v-card>
        <v-card-title>Registrar Cobro</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-row dense>
              <v-col cols="12" sm="6" md="6">
                <c-text-date
                  label="Fecha"
                  readonly
                  v-model="data.fechreciboventa"
                  outlined
                  dense
                ></c-text-date>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <vue-text-field
                  label="Recibo Numero"
                  :loading="Vget_cobrocuenta_num_rec_load"
                  v-model="data.nroreciboventa"
                  readonly
                ></vue-text-field>
              </v-col>
              <v-col cols="12" md="7" sm="12">
                <vue-autocomplete-contacto-search
                  label="Cliente"
                  id="input1"
                  action="contacto/Vact_contacto_data_cliente"
                  getter="contacto/Vget_contacto_data_cliente"
                  load="contacto/Vget_contacto_load"
                  vuex="contacto/contacto_create"
                  permission="Cliente"
                  v-model="data.idcontacto.idcontacto"
                  @change="datos = []"
                  @keyup.native.enter="focus(1, data.idcontacto.idcontacto)"
                />
              </v-col>
              <v-col cols="10" md="4" sm="10">
                <vue-autocomplete-object
                  label="Moneda"
                  id="input2"
                  action="moneda/Vact_moneda_data"
                  getter="moneda/Vget_moneda_data"
                  load="moneda/Vget_moneda_load"
                  validar="idmoneda"
                  pk="idmoneda"
                  title="descmoneda"
                  permission="Moneda"
                  v-model="data.idmoneda"
                  @change="datos = []"
                  @keyup.native.enter="focus(2, data.idmoneda)"
                />
              </v-col>
              <v-spacer></v-spacer>
              <v-btn
                v-if="
                  data.idcontacto.idcontacto != '' &&
                    data.idmoneda.idmoneda != ''
                "
                color="primary"
                small
                fab
                outlined
                :loading="Vget_cobrocuenta_load"
                id="input3"
                @click="
                  obtenercuentas(
                    data.idcontacto.idcontacto.idcontacto,
                    data.idmoneda.idmoneda
                  )
                "
              >
                <v-icon>get_app</v-icon>
              </v-btn>

              <v-col cols="12">
                <v-alert
                  dense
                  outlined
                  type="error"
                  v-if="cotizacionerror != null"
                  >{{ cotizacionerror }}</v-alert
                >
              </v-col>
              <v-col cols="12" v-if="datos[0] != undefined">
                <v-data-table
                  :loading="Vget_cobrocuenta_load"
                  :headers="headers"
                  :items="datos"
                  item-key="idcuentaventa"
                 
                  :expanded.sync="expanded"
                  show-expand
                  multiple
                  hide-default-footer
                  :items-per-page="100"
                >
                  <template v-slot:expanded-item="{ item }">
                    <td :colspan="headers.length">
                      <v-data-table
                        dense
                        :headers="subheaders"
                        item-key="idcuotaventa"
                        :items="item.cuotaventa"
                        hide-default-footer
                        :items-per-page="100"
                      >
                        <template v-slot:[`item.montocuotacuenta`]="{ item }">
                          <div>{{ toCurrency(item.montocuotacuenta) }}</div>
                        </template>
                        <template v-slot:[`item.accion`]="{ item }">
                          <v-simple-checkbox
                            dense
                            :ripple="false"
                            v-model="item.estadocobro"
                          ></v-simple-checkbox>
                        </template>
                      </v-data-table>
                    </td>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row align="center" class="ma-2" v-if="datos[0] != undefined">
              <v-checkbox
                v-model="isPagoParcial"
                hide-details
                class="shrink mr-2 mt-0"
              ></v-checkbox>
              <vue-text-currency
                :dense="false"
                :outlined="false"
                :disabled="!isPagoParcial"
                label="Registrar Cobro Parcial"
                v-model="data.cuentaventapagocaja[0].entrega"
              ></vue-text-currency>
            </v-row>
          </v-form>
        </v-card-text>
        <v-form ref="formPago">
          <v-card
            outlined
            class="ma-4"
            :color="!$vuetify.theme.dark ? 'blue lighten-5' : 'dark'"
            v-if="datos[0] != undefined"
          >
            <Venta-CobroCuenta-FormaPago
              class="ma-4"
              :montoCobrar="totalcobrar"
              :key="keyFormaPago"
              @efectivo="addEfectivo($event)"
              @tarjeta="addTarjeta($event)"
              @cheque="addCheque($event)"
            />
          </v-card>
        </v-form>
        <v-col cols="12" v-if="datos[0] != undefined">
          <vue-text-field
            :rules="false"
            label="Comentario"
            v-model="data.descreciboventa"
          ></vue-text-field>
        </v-col>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn @click="close()" color="red" text>
            <v-icon>clear</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="guardar">
            <v-icon>done</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mensaje } from "@/services/notify.service";
import { current_date, dmy } from "@/services/util/date.service";
import { currency } from "@/services/util/number.service";
import { focus } from "@/services/focus.service";
import { recibo } from "@/services/recibo.service";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    $route(to, from) {
      if (from.path === "/cobrocuenta") return this.close();
    },

    Vget_cobrocuenta_num_rec(val) {
      this.data.nroreciboventa = val.nrotambo;
    },
    cuentascobrar(val) {
      this.detrecibo = JSON.parse(JSON.stringify(val));
    },
    isPagoParcial(val) {
      if (!val) {
        this.data.cuentaventapagocaja[0].entrega = 0;
      }
    },
  },
  mounted() {
    this.obtenerNroRecibo();
  },
  computed: {
    ...mapGetters("cobrocuenta", [
      "Vget_cobrocuenta_by_id",
      "Vget_cobrocuenta_load",
      "Vget_cobrocuenta_num_rec",
      "Vget_cobrocuenta_num_rec_load",
    ]),
    ...mapGetters("moneda", ["Vget_moneda_data", "Vget_moneda_load"]),
    totalcobrar() {
      if (this.isPagoParcial) return this.data.cuentaventapagocaja[0].entrega;
      let total = 0;
      for (let i = 0; i <= this.detrecibo.length; i++) {
        if (this.detrecibo[i] === undefined) break;
        for (let j = 0; j <= this.detrecibo[i].idcuotaventa.length; j++) {
          if (this.detrecibo[i].idcuotaventa[j] === undefined) break;
          total =
            Number(total) +
            Number(this.detrecibo[i].idcuotaventa[j].montocuotacuenta);
        }
      }
      return Number(total.toFixed(2));
    },
    cuentascobrar() {
      let cuentascobrar = [];
      this.datos.forEach((x) => {
        let cuotascheckeadas = [];
        let isValid = false;
        x.cuotaventa.forEach((y) => {
          if (y.estadocobro) {
            cuotascheckeadas.push(y);
            isValid = true;
          }
        });
        if (isValid) {
          cuentascobrar.push({
            idcuentaventa: x.idcuentaventa,
            factura: x.factura,
            fecha: dmy(x.fecha),
            idcuotaventa: cuotascheckeadas,
          });
        }
      });

      return cuentascobrar;
    },
  },
  methods: {
    close() {
      this.limpiar();
      this.$emit("input", false);
      this.Vact_caja_data();
    },
    addEfectivo(event) {
      this.data.cuentaventapagocaja[0].monto = event.monto;
    },
    addTarjeta(event) {
      this.data.cuentaventapagocaja[0].venta_pago_tarjeta = event;
    },
    addCheque(event) {
      this.data.cuentaventapagocaja[0].venta_pago_cheque = event;
    },
    toCurrency(value) {
      return currency(value);
    },
    obtenerNroRecibo() {
      if (this.value) {
        setTimeout(() => document.getElementById("input1").focus(), 20);
        this.data.nroreciboventa = this.Vget_cobrocuenta_num_rec.nrotambo;
        this.Vact_cobrocuenta_num_rec();
      }
    },
    limpiar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.data.cuentaventapagocaja[0].entrega = 0;
      this.datos = [];
      this.Vact_cobrocuenta_num_rec();
      this.Vact_cobrocuenta_data();
      this.cotizacionerror = null;
      if (this.$refs.form != undefined)
        return this.$refs.form.resetValidation();
    },
    ...mapActions("cobrocuenta", [
      "Vact_cobrocuenta_by_id",
      "Vact_cobrocuenta_num_rec",
      "Vact_cobrocuenta_save",
      "Vact_cobrocuenta_data",
    ]),
    ...mapActions("moneda", ["Vact_moneda_data"]),
    ...mapActions("caja", ["Vact_caja_data"]),
    focus(id, data) {
      focus.nextid(id, data);
    },
    async obtenercuentas(id, idmoneda) {
      this.cotizacionerror = await this.Vact_cobrocuenta_by_id({
        id,
        idmoneda,
      });
      this.datos = this.Vget_cobrocuenta_by_id;
    },

    addcuenta(data) {
      data.forEach((x, i) => {
        if (this.detrecibo[i] === undefined) {
          this.detrecibo.push({
            idcuentaventa: x.idcuentaventa,
            factura: x.factura,
            fecha: dmy(x.fecha),
            idcuotaventa: [],
          });
        }
      });
    },
    isValid() {
      if (
        !this.$refs.form.validate() ||
        !this.$refs.formPago.validate() ||
        this.datos.length === 0
      )
        return false;
      return true;
    },
    async guardar() {
      if (!this.isValid()) return this.$notify(mensaje.completar());
      this.data.detrecibo = JSON.parse(JSON.stringify(this.detrecibo));
      if (this.data.descreciboventa === "") {
        this.data.descreciboventa = null;
      }
      const resp = await this.Vact_cobrocuenta_save(this.data);
      if (!resp.estado) {
        return resp.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.$notify(resp.info);
      recibo.generar(
        this.data,
        this.totalcobrar,
        this.data.fechreciboventa,
        this.Vget_cobrocuenta_num_rec.ruc,
        resp.data.data.detalle
      );
      this.keyFormaPago++;
      this.selected = [];
      this.detrecibo = [];

      this.limpiar();
      setTimeout(() => document.getElementById("input1").focus(), 500);
    },
  },
  data() {
    return {
      cotizacionerror: null,
      data: {
        idreciboventa: 0,
        cotizacion: 0,
        idmoneda: {
          idmoneda: "",
        },
        fechreciboventa: current_date(),
        idcontacto: {
          idcontacto: "",
        },
        anulreciboventa: false,
        descreciboventa: "",
        detrecibo: [],
        cuentaventapagocaja: [
          {
            idcaja: 0,
            idformapago: {
              idformapago: 1,
            },
            entrega: 0,
            monto: "",
            isEfectivo: true,
            venta_pago_tarjeta: [],
            venta_pago_cheque: [],
          },
        ],
      },
      default: {
        idreciboventa: 0,
        cotizacion: 0,
        idmoneda: {
          idmoneda: "",
        },
        fechreciboventa: current_date(),
        idcontacto: {
          idcontacto: "",
        },
        anulreciboventa: false,
        descreciboventa: "",
        detrecibo: [],
        cuentaventapagocaja: [
          {
            idcaja: 0,
            idformapago: {
              idformapago: 1,
            },
            entrega: 0,
            monto: "",
            isEfectivo: true,
            venta_pago_tarjeta: [],
            venta_pago_cheque: [],
          },
        ],
      },
      keyFormaPago: 1,
      datos: [],
      idmoneda: "",
      selected: [],
      detrecibo: [],
      expanded: [],
      isPagoParcial: false,
      headers: [
        {
          text: "Factura",
          align: "left",
          sortable: false,
          value: "factura",
        },
        {
          text: "Pagado/Total",
          align: "center",
          sortable: false,
          value: "pagarcuota",
        },
        {
          text: "Dia",
          align: "left",
          sortable: false,
          value: "diapago",
        },
        {
          text: "Pagado/Total",
          align: "center",
          sortable: false,
          value: "montocuota",
        },
        { text: "", value: "data-table-expand" },
      ],
      subheaders: [
        {
          text: "Vencimiento",
          value: "fechavence",
        },
        {
          text: "Monto",
          align: "end",
          value: "montocuotacuenta",
        },
        {
          text: "Seleccionar",
          align: "right",
          value: "accion",
        },
      ],
    };
  },
};
</script>
