<template>
  <v-autocomplete
    :value="value"
    :id="id"
    @input="$emit('input', $event)"
    :rules="rules_active"
    dense
    ref="input"
    type="text"
    :items="items"
    :multiple="multiple"
    :filter="customFilter"
    :label="label"
    :item-text="title"
    item-value="id"
    :search-input.sync="syncInput"
    return-object
    :outlined="outlined"
    :readonly="readonly"
    :disabled="disabled"
    :messages="messages"
    height="12"
    autocomplete="off"
    :loading="Vget_load"
    :no-data-text="`No existe ninguna concidencia`"
    v-on:change="change"
    :small-chips="chips"
  >
    <template slot="item" slot-scope="data">
      <v-list-item-content>
        <v-list-item-title>
          {{data.item[pk || validar]}} - {{data.item[title]}}
        </v-list-item-title>
      </v-list-item-content>
    </template>
    <template v-if="noData && create != false && addpermission" v-slot:no-data>
      <v-list-item @click="open_ref()">
        <v-list-item-content>
          <v-list-item-title>
            No existe concidencia. Click para agregar
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
import store from "@/store";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { PUEDE_AGREGAR } from "@/constants/permissions";
export default {
  props: {
    label: String,
    value: [Number, String, Object, Array],
    title: String,
    action: String,
    getter: String,
    vuex: String,
    load: String,
    id: String,
    messages: String,
    multiple: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    validar: {
      type: String,
      default: "",
    },
    create: {
      type: [String, Boolean],
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    chips: {
      type: Boolean,
      default: false,
    },
    permission: {
      type: [String, Boolean],
      default: false,
    },
    rules: {
      type: Boolean,
      default: true,
    },
    pk: {
      type: [Number, String],
    },
  },
  data() {
    return {
      dialog: false,
      rules_validar: [(v) => !!v[this.validar] || "Seleccion un campo"],
      items: [],
      noData: true,
      syncInput: "",
    };
  },
  created() {
    store.dispatch(this.action);
  },
  watch: {
    Vget_data(val) {
      this.procesar(val);
    },
    syncInput(val) {
      this.$emit("sync-input", val);
    },
  },
  computed: {
    addpermission() {
      if (this.permission === false) return false;
      return getPermission(this.permission).permiso[
        PUEDE_AGREGAR
      ];
    },
    rules_active() {
      if (this.value === undefined || this.value === null) return [];
      if (this.rules) return this.rules_validar;
      return [];
    },
    Vget_data() {
      return store.getters[this.getter];
    },
    Vget_load() {
      return store.getters[this.load];
    },
  },
  methods: {
    procesar(val) {
      this.items = [];
      if (val[0] === undefined) return null;
      this.items = JSON.parse(JSON.stringify(val));
    },

    open_ref() {
      if (this.vuex != undefined) return this.$store.commit(this.vuex, true);
      return this.$emit("create", true);
    },
    change(evt) {
      this.$emit("change", evt);
    },
    customFilter(item, queryText) {
      const textOne = item[this.pk || this.validar].toString();
      const textTwo = item[this.title].toLowerCase();
      const searchText = queryText.toLowerCase();
      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },
     focus() {
      this.$refs.input.focus();
    },
  },
};
</script>