
export default {

  Vget_propquimica_update: (state) => {
    return state.propquimica_update
  },
  Vget_propquimica_data: (state) => {
    return state.propquimica_data
  },
  Vget_propquimica_load: (state) => {
    return state.propquimica_load
  },
  Vget_propquimica_save_load: (state) => {
    return state.propquimica_save_load
  },
}
