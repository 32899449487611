<template>
  <div>
    <Crud v-model="crud.add" v-if="crud.add" />
    <Crud
      v-if="crud.view"
      v-model="crud.view"
      :props="crud.datos"
      :editable="!crud.readonly"
      :readonly="crud.readonly"
    />

    <v-alert type="error" v-if="permission_alert"
      >Error!!! Este usuario no tiene permisos en esta ventana</v-alert
    >
    <v-toolbar flat dense>
      <v-toolbar-title>{{ titulo }}</v-toolbar-title>
      <widgets-Favorite ruta="/empresa" formulario="Empresa" />
      <v-divider class="mx-2" inset vertical></v-divider>

      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Busqueda"
        single-line
        hide-details
        v-if="permission.can_view"
      ></v-text-field>
      <v-toolbar-items>
        <c-btn-table-add
          @click="crud.add = true"
          v-if="permission.can_add"
        ></c-btn-table-add>
        <c-btn-table-reload
          class="ml-1"
          @click="Vact_empresa_data()"
        ></c-btn-table-reload>
      </v-toolbar-items>
    </v-toolbar>
    <v-data-table
      :loading="Vget_empresa_load"
      :headers="headers"
      :items="Vget_empresa_data"
      :search="search"
      class="elevation-1"
      v-if="permission.can_view"
    >
      <template v-slot:[`item.accion`]="props">
        <c-btn-table-view
          @click="mupdate(props.item, 'view')"
        ></c-btn-table-view>
        <c-btn-table-edit
          @click="mupdate(props.item)"
          v-if="permission.can_update"
        ></c-btn-table-edit>
        <c-btn-table-delete
          @click="meliminar(props.item)"
          v-if="permission.can_delete"
        ></c-btn-table-delete>
      </template>
    </v-data-table>
    <Delete
      v-if="crud.delete"
      v-model="crud.delete"
      :props="crud.datos"
      @datos="crud.datos = $event"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { EMPRESA } from "@/constants/forms";
import Crud from "./Crud";
import Delete from "./Delete";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";

export default {
  components: {
    Crud,
    Delete,
  },
  data() {
    return {
      crud: {
        delete: false,
        add: false,
        view: false,
        readonly: false,
        datos: null,
      },
      titulo: EMPRESA,
      search: "",
      headers: [
        { text: "Codigo", align: "left", value: "idempresa" },
        { text: "Empresa", align: "left", value: "descempresa" },
        { text: "Ruc", align: "left", value: "rucempresa" },
        { text: "Accion", align: "right", value: "accion" },
      ],
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
    };
  },
  computed: {
    ...mapGetters("empresa", ["Vget_empresa_data", "Vget_empresa_load"]),

    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },
  methods: {
    ...mapActions("empresa", [
      "Vact_empresa_data",
      "Vact_empresa_del",
    ]),
    mupdate(data, view) {
      this.crud.datos = JSON.parse(JSON.stringify(data));
      view ? (this.crud.readonly = true) : (this.crud.readonly = false);
      this.crud.view = true;
    },
    meliminar(item) {
      this.crud.datos = JSON.parse(JSON.stringify(item));
      this.crud.delete = true;
    }
  },
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    const permission = getPermission(EMPRESA).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
    this.Vact_empresa_data();
  },
};
</script>
