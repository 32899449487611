
export default {

  estadocria_create: (state) => {
    return state.estadocria_create
  },
  estadocria_update: (state) => {
    return state.estadocria_update
  },
  estadocria_reload: (state) => {
    return state.estadocria_reload
  },
  estadocria_show: (state) => {
    return state.estadocria_show
  },
  estadocria_key: (state) => {
    return state.estadocria_key
  },
}
