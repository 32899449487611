export default {
  responsable_create: false,
  responsable_update: null,
  responsable_show: false,
  //
  responsable_data: [],
  responsable_data_inactivo: [],
  responsable_load: false,
  responsable_save_load: false
}
