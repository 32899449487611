<template>
    <div>
        <v-dialog :value="value" max-width="800" persistent>
            <v-card>
                <v-toolbar flat>
                    <v-toolbar-title>
                        {{ operacion ? "Agregar Alimentacion" : "Modificar Alimentacion" }}
                    </v-toolbar-title>
                </v-toolbar>
                <v-container>
                    <v-form ref="form" lazy-validation>
                        <v-row row dense>
                            <v-col cols="6">
                                <c-text-date label="Inicio" id="input1" outlined dense v-model="data.inicio"
                                    @keyup.native.enter="next(1, data.inicio)" />
                            </v-col>
                            <v-col cols="6">
                                <c-text-date label="Fin" id="input2" outlined dense v-model="data.fin"
                                    @keyup.native.enter="next(2, data.fin)" />
                            </v-col>
                            <v-col cols="12">
                                <v-chip-group v-model="data.dias" column multiple color="deep-purple accent-3">
                                    <v-chip filter outlined :value="1"> Lunes </v-chip>
                                    <v-chip filter outlined :value="2"> Martes </v-chip>
                                    <v-chip filter outlined :value="3"> Miercoles </v-chip>
                                    <v-chip filter outlined :value="4"> Jueves </v-chip>
                                    <v-chip filter outlined :value="5"> Viernes </v-chip>
                                    <v-chip filter outlined :value="6"> Sabado </v-chip>
                                    <v-chip filter outlined :value="7"> Domingo </v-chip>
                                </v-chip-group>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-form ref="detail" lazy-validation class="mt-2">
                        <v-row row dense>
                            <v-col cols="6" sm="6" md="3">
                                <AutocompleteRacion :returnObject="true" outlined id="input3" dense label="Racion"
                                    v-model="detalle.idracion" @keyup.native.enter="next(3, detalle.idracion)" />
                            </v-col>
                            <v-col cols="6" sm="6" md="3">
                                <vue-text-field label="Cantidad" type="number" :rules="true" id="input4"
                                    v-model="detalle.cantidad"
                                    @keyup.native.enter="next(4, detalle.cantidad)"></vue-text-field>
                            </v-col>
                            <v-col cols="6" sm="6" md="3">
                                <v-btn-toggle v-model="detalle.poranimal" dense rounded color="purple darken-1">
                                    <v-btn :value="true">
                                        Por Animal
                                    </v-btn>
                                    <v-btn :value="false">
                                        Todos
                                    </v-btn>
                                </v-btn-toggle>
                            </v-col>
                            <v-col cols="6" sm="6" md="3">
                                <v-btn class="mx-3" fab outlined dark small color="primary" @click="addTable()">
                                    <v-icon dark>
                                        add
                                    </v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="12">
                                <DataTable :items="data.alimentaciondet" @edit="editarDetalle($event)"
                                    @delete="eliminarDetalle($event)" />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn color="red" text @click="cancelar()">
                        <v-icon>clear</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" id="alimentacionInputAceptar" text @click="guardar()">
                        <v-icon>done</v-icon>
                    </v-btn>
                </v-card-actions>
                <c-overlay :value="Vget_alimentacion_save_load"></c-overlay>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { current_date } from "@/services/util/date.service";
import { focus } from "@/services/focus.service";
import { mensaje } from "@/services/notify.service";
import AutocompleteRacion from "../Racion/autocomplete";
import DataTable from "./DataTable.vue";
export default {
    components: {
        AutocompleteRacion,
        DataTable,
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        datos: {
            type: [Object, Array],
            default: null,
        },
        "sync-input": {
            type: [String, Number],
            default: null,
        },
    },
    data() {
        return {
            data: {
                idalimentacion: 0,
                inicio: current_date(),
                fin: current_date(),
                dias: [1, 2, 3, 4, 5, 6, 7],
                alimentaciondet: [],
                estado: true,
            },
            default: {
                idalimentacion: 0,
                inicio: current_date(),
                fin: current_date(),
                dias: [1, 2, 3, 4, 5, 6, 7],
                alimentaciondet: [],
                estado: true,
            },
            detalle: {
                idracion: {
                    idracion: ""
                },
                cantidad: 0,
                poranimal: true,
            },
            detalle_default: {
                idracion: {
                    idracion: ""
                },
                cantidad: 0,
                poranimal: true,
            },
            operacion: true,
        }
    },
    watch: {
        $route() {
            return this.cancelar();
        },
    },
    computed: {
        ...mapGetters("alimentacion", ["Vget_alimentacion_save_load"]),
    },
    mounted() {
        this.nuevo(this.$props.datos)
        setTimeout(() => this.next(0, "-"), 200)
    },
    methods: {
        ...mapActions("alimentacion", ["Vact_alimentacion_data", "Vact_alimentacion_save", "Vact_alimentacion_update"]),
        next(id, data) {
            focus.nextidName("input", id, data);
        },
        cancelar() {
            this.data = JSON.parse(JSON.stringify(this.default));
            this.Vact_alimentacion_data();
            this.$emit("input", false);
            this.$emit("datos", null);
            this.operacion = true;
        },
        async guardar_finalizar() {
            let a = await this.Vact_alimentacion_save(this.data)
            if (a.estado == true) {
                this.data = JSON.parse(JSON.stringify(this.default))
                if (this.sync != null) {
                    this.$emit("sync-input", a.data.data.id)
                    this.cancelar()
                }
                this.$notify(a.info)
                this.$refs.form.resetValidation();
            } else {
                a.info.map((x) => {
                    this.$notify(mensaje.error(x))
                })
            }
        },
        async editar_finalizar() {
            const id = this.data.idalimentacion
            let a = await this.Vact_alimentacion_update({ id, data: this.data })
            if (a.estado == true) {
                this.$notify(a.info)
                this.cancelar()
            } else {
                a.info.map((x) => {
                    this.$notify(mensaje.error(x))
                })
            }
        },
        guardar() {
            if (!this.$refs.form.validate()) return this.$notify(mensaje.completar())
            if (this.operacion === true) return this.guardar_finalizar()
            return this.editar_finalizar()
        },
        nuevo(val) {
            if (val != null) {
                this.data = JSON.parse(JSON.stringify(val))
                this.operacion = false
            }
        },
        verificar_duplicado() {
            const temp = this.data.alimentaciondet.find(
                (x) =>
                    x.idracion.idracion === this.detalle.idracion.idracion
            )
            return temp ? true : false
        },
        addTable() {
            if (this.verificar_duplicado()) {
                return null
            }
            this.data.alimentaciondet.push(
                this.detalle
            )
            this.detalle = this.detalle_default
            this.next(2, this.data.fin)
        },
        eliminarDetalle(val) {
            const index = this.data.alimentaciondet.indexOf(val)
            this.data.alimentaciondet.splice(index, 1)
        },
        editarDetalle(val) {
            this.eliminarDetalle()
            this.detalle = JSON.parse(JSON.stringify(val))
            this.next(2, this.data.fin)
        }
    }
}
</script>