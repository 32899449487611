<template>
  <div>
    <v-dialog
      :value="value"
      max-width="700px"
      persistent
      @keydown.esc="cancelar()"
    >
      <v-card>
        <v-form ref="form" lazy-validation @submit.prevent="nextfocus(1)">
          <v-toolbar flat>
            <v-toolbar-title>{{
              operacion
                ? "Registrar Medicacion Motivo"
                : "Modificar Medicacion Motivo"
            }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-row row dense>
              <v-col cols="12">
                <v-text-field
                  dense
                  :readonly="readonly"
                  type="text"
                  :rules="validar"
                  ref="input1"
                  v-model="data.descmotivo"
                  label="Descripcion"
                  required
                  outlined
                  autocomplete="off"
                  @keyup.enter="nextfocus(1)"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <c-btn-crud-close @click="cancelar()"></c-btn-crud-close>
            <v-spacer></v-spacer>
            <c-btn-crud-done @click="guardar()" :disabled="readonly" ref="aceptar"></c-btn-crud-done>
          </v-card-actions>
        </v-form>
      </v-card>
      <c-overlay :value="Vget_medicacionmotivo_save_load"></c-overlay>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { mensaje } from "@/services/notify.service";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    datos: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: {
        descmotivo: "",
      },
      default: {
        descmotivo: "",
      },
      validar: [(v) => !!v || "Campo requerido"],

      operacion: true,
    };
  },
  computed: {
    ...mapGetters("medicacionmotivo", ["Vget_medicacionmotivo_save_load"]),
  },
  created() {
    this.nuevo(this.datos);
  },
  watch: {
    $route(to, from) {
      if (from.path === "/medicacionmotivo") return this.cancelar();
    },
  },
  mounted() {
    setTimeout(() => this.$refs.input1.focus(), 500);
  },
  methods: {
    ...mapActions("medicacionmotivo", [
      "Vact_medicacionmotivo_data",
      "Vact_medicacionmotivo_save",
      "Vact_medicacionmotivo_update",
    ]),

    nextfocus(x) {
      switch (x) {
        case 1: {
          if (this.data.descmotivo != "") {
            this.$refs.aceptar.$el.focus();
          }
          break;
        }
      }
    },
    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$emit("input", false);
      this.$emit("datos", null);
      this.Vact_medicacionmotivo_data();
      this.operacion = true;
    },
    async guardar_finalizar() {
      let a = await this.Vact_medicacionmotivo_save(this.data);
      if (!a.success) return null;
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$refs.form.resetValidation();
      setTimeout(() => this.$refs.input1.focus(), 500);
    },
    async editar_finalizar() {
      const id = this.datos.idmedicacion_motivo;
      let a = await this.Vact_medicacionmotivo_update({ id, data: this.data });
      if (!a.success) return null;
      this.cancelar();
    },
    guardar() {
      if (!this.$refs.form.validate()) return this.$notify(mensaje.completar());
      if (this.operacion === true) return this.guardar_finalizar();
      if (this.operacion === false) return this.editar_finalizar();
    },

    nuevo(val) {
      if (val !== null) {
        this.data = JSON.parse(JSON.stringify(val));
        this.operacion = false;
      }
    },
  },
};
</script>

