import { get, post, put, del } from "@/services/api/api.service";
export default {

    async Vact_agenda_data({ commit, dispatch }) {
        commit('agenda_load', true);
        try {
            const data = await get('/apiagenda/');
            commit('agenda_data', data);
            commit('agenda_load', false);
            return true
        } catch (e) {
            dispatch('snackbar/setNotification', e, { root: true });
            commit('agenda_load', false);
            throw e
        }
    },
    async Vact_agenda_data_by_id({ commit, dispatch }, id) {
        commit('agenda_load', true);
        try {
            const data = await get('/apiagenda/' + id);
            commit('agenda_load', false);
            return data
        } catch (e) {
            dispatch('snackbar/setNotification', e, { root: true });
            commit('agenda_load', false);
            throw e
        }
    },
    async Vact_agenda_del({ dispatch }, id) {
        const data = await del('/apiagenda/' + id);
        dispatch('snackbar/setNotification', data, { root: true });
        dispatch('Vact_agenda_data');
        return data

    },
    async Vact_agenda_save({ commit, dispatch }, data) {
        commit('agenda_save_load', true);
        const resp = await post('/apiagenda/', data);
        dispatch('snackbar/setNotification', resp, { root: true })
        commit('agenda_save_load', false);
        return resp;
    },
    async Vact_agenda_update({ commit,dispatch }, { id, data }) {
        commit('agenda_save_load', true);
        const resp = await put('/apiagenda/' + id + '/', data);
        dispatch('snackbar/setNotification', resp, { root: true })
        commit('agenda_save_load', false);
        return resp;

    },

}
