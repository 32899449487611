import {SET_LOADING, SET_SENAVE, SET_SENAVEUNO, SET_SENAVEFECHA, SET_REPORTEINDICE} from './constants'
export default {
  [SET_SENAVE](state, request) {
    state.senave = request;
  },
  [SET_SENAVEUNO](state, request) {
    state.senaveuno = request;
  },
  [SET_SENAVEFECHA](state, request) {
    state.senavefecha = request;
  },
  [SET_REPORTEINDICE](state, request) {
    state.reporteindice = request;
  },
  [SET_LOADING](state, request) {
    state.isLoading = request;
  },
}