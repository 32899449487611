var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('c-alert-permission',{model:{value:(_vm.permission),callback:function ($$v) {_vm.permission=$$v},expression:"permission"}}),_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-toolbar-title',{staticClass:"text-h5"},[_vm._v("Funcionarios")]),_c('v-spacer'),_c('v-toolbar-items',[(_vm.permission.can_add)?_c('c-btn-table-add',{on:{"click":function($event){_vm.crud.add = true}}}):_vm._e()],1)],1),_c('v-tabs',{attrs:{"right":""}},[_c('v-tab',{on:{"click":function($event){return _vm.fetchFuncionario({ active: true })}}},[_vm._v("Activos")]),_c('v-tab',{on:{"click":function($event){return _vm.fetchFuncionario({ active: false })}}},[_vm._v("Inactivos")]),_c('v-tab',[_vm._v("Documentos")]),_c('v-tab-item',[_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Busqueda","placeholder":"Escriba el nombre del funcionario"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.permission.can_view)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.getFuncionario,"search":_vm.search,"loading":_vm.isFuncionarioLoading,"items-per-page":20},scopedSlots:_vm._u([{key:"item.salario",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.toCurrency(item.salario[0])))])]}},{key:"item.sector",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.salario[1]))])]}},{key:"item.fecha_fin",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.salario[2]))])]}},{key:"item.action",fn:function(props){return [_c('v-btn',{attrs:{"text":"","fab":"","outlined":"","x-small":"","color":"deep-purple accent-4"},on:{"click":function($event){return _vm.openFuncionario(props.item)}}},[_c('v-icon',[_vm._v(" arrow_forward ")])],1),_c('v-btn',{staticClass:"ml-2",attrs:{"text":"","fab":"","outlined":"","x-small":"","color":"red accent-4"},on:{"click":function($event){return _vm.deleteFuncionario(props.item)}}},[_c('v-icon',[_vm._v(" delete ")])],1)]}}],null,true)}):_vm._e()],1),_c('v-tab-item',[_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Busqueda","placeholder":"Escriba el nombre del funcionario"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.permission.can_view)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.getFuncionarioInactive,"search":_vm.search,"loading":_vm.isFuncionarioLoading,"items-per-page":20},scopedSlots:_vm._u([{key:"item.salario",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.toCurrency(item.salario[0])))])]}},{key:"item.sector",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.salario[1]))])]}},{key:"item.fecha_fin",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.salario[2]))])]}},{key:"item.action",fn:function(props){return [_c('v-btn',{attrs:{"text":"","fab":"","outlined":"","x-small":"","color":"deep-purple accent-4"},on:{"click":function($event){return _vm.openFuncionario(props.item)}}},[_c('v-icon',[_vm._v(" arrow_forward ")])],1),_c('v-btn',{staticClass:"ml-2",attrs:{"text":"","fab":"","outlined":"","x-small":"","color":"red accent-4"},on:{"click":function($event){return _vm.deleteFuncionario(props.item)}}},[_c('v-icon',[_vm._v(" delete ")])],1)]}}],null,true)}):_vm._e()],1),_c('v-tab-item',[_c('Documentos')],1)],1),(_vm.crud.add)?_c('Crud',{model:{value:(_vm.crud.add),callback:function ($$v) {_vm.$set(_vm.crud, "add", $$v)},expression:"crud.add"}}):_vm._e(),(_vm.crud.open)?_c('DialogPrincipal',{attrs:{"props":_vm.crud.datos,"editable":""},model:{value:(_vm.crud.open),callback:function ($$v) {_vm.$set(_vm.crud, "open", $$v)},expression:"crud.open"}}):_vm._e(),(_vm.crud.delete)?_c('Delete',{attrs:{"props":_vm.crud.datos},on:{"datos":function($event){_vm.crud.datos = $event}},model:{value:(_vm.crud.delete),callback:function ($$v) {_vm.$set(_vm.crud, "delete", $$v)},expression:"crud.delete"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }