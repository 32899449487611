import {SET_LOADING, SET_AGUINALDO, SET_FUNCIONARIO_DATA} from './constants'
export default {
  [SET_AGUINALDO](state, request) {
    state.aguinaldo = request;
  },
  [SET_FUNCIONARIO_DATA](state, request) {
    state.funcionario = request;
  },
  [SET_LOADING](state, request) {
    state.isLoading = request;
  },
}