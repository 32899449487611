
export default {


  Vget_unidadmedida_data: (state) => {
    return state.unidadmedida_data
  },
  Vget_unidadmedida_data_magnitud: (state) => {
    return state.unidadmedida_data_magnitud
  },
  Vget_unidadmedida_load: (state) => {
    return state.unidadmedida_load
  },
  Vget_unidadmedida_save_load: (state) => {
    return state.unidadmedida_save_load
  },
}
