export default [
    {
        path: '/tipoanalisis',
        name: 'Tipoanalisis',
        component: () => import( /* webpackChunkName: "Tipoanalisis" */ '@/views/Analisis/Tipoanalisis/Tipoanalisis.vue'),
      },
      {
        path: '/causanoinseminacion',
        name: 'Causanoinseminacion',
        component: () => import( /* webpackChunkName: "Causanoinseminacion" */ '@/views/Analisis/Causanoinseminacion/Causanoinseminacion.vue'),
      },
      {
        path: '/inseminacion',
        name: 'Inseminacion',
        component: () => import( /* webpackChunkName: "Inseminacion" */ '@/views/Analisis/Inseminacion/Inseminacion.vue'),
      },
      {
        path: '/veterinario',
        name: 'Veterinario',
        component: () => import( /* webpackChunkName: "Veterinario" */ '@/views/Analisis/Veterinario/Veterinario.vue'),
      },
      {
        path: '/celo',
        name: 'Celo',
        component: () => import( /* webpackChunkName: "Celo" */ '@/views/Analisis/Celo/Celo.vue'),
      },
      {
        path: '/viaaplicacion',
        name: 'Via aplicacion',
        component: () => import( /* webpackChunkName: "Viaaplicacion" */ '@/views/Analisis/Viaaplicacion/Viaaplicacion.vue'),
      },
      {
        path: '/medicacion',
        name: 'Medicacion',
        component: () => import( /* webpackChunkName: "Medicacion" */ '@/views/Analisis/Medicacion/Medicacion.vue'),
      },
      {
        path: '/medicacionmotivo',
        name: 'Medicacion Motivo',
        component: () => import( /* webpackChunkName: "Enfermedad" */ '@/views/Analisis/Medicacionmotivo/Medicacionmotivo.vue'),
      },
      {
        path: '/analisis',
        name: 'Analisis',
        component: () => import( /* webpackChunkName: "Enfermedad" */ '@/views/Analisis/Analisis/Analisis.vue'),
      },
    ]