<template>
  <v-text-field
    :value="value"
    :id="id"
    @input="$emit('input', $event)"
    :rules="concatRules"
    :label="label"
    ref="input"
    v-maska="seconds ? '##:##:##' : '##:##'"
    :readonly="readonly"
    :suffix="suffix"
    v-on:focus="$emit('focus')"
    :error-messages="errorMessages"
    :outlined="outlined"
    :messages="messages"
    :placeholder="placeholder"
    :persistent-hint="persistentHint"
    :hint="hint"
    :solo="solo"
    :solo-inverted="soloInverted"
    :disabled="disabled"
    :dense="dense"
    autocomplete="off"
  ></v-text-field>
</template>

<script>
import { maska } from "maska";
export default {
  directives: { maska },
  props: {
    suffix: String,
    label: String,
    value: [String, Number],
    seconds: {
      type: Boolean,
      default: false,
    },
    id: String,
    "persistent-hint": {
      type: Boolean,
      default: false,
    },
    messages: {
      type: [Number, String],
    },
    hint: String,
    "error-messages": {
      type: [String, Array, Object],
    },
    rules: {
      type: [Array],
      default: function () {
        return [(v) => !!v || "Este campo es obligatorio"];
      },
    },
    type: {
      type: String,
      default: "text",
    },
    dense: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    solo: {
      type: Boolean,
      default: false,
    },
    "solo-inverted": {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
  },
  computed:{
    hours(){
      return [
        v => v.split(':')[0] <= 23 || 'Horas invalidos',
        v => v.split(':')[1] != undefined ? v.split(':')[1] <= 59 || 'Minutos invalidos' : v.split(':')[1] === undefined,
        v => v.split(':')[2] != undefined ? v.split(':')[2] <= 59 || 'Segundos invalidos' : v.split(':')[2] === undefined,
      ]
    },
    concatRules(){
      return this.value != null ? this.hours.concat(this.rules) : this.rules;
    }
  }
};
</script>