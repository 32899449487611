<template>
  <v-dialog persistent max-width="700" :value="value">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-form ref="form" @submit.prevent="">
          <v-row dense>
            <v-col cols="12">
              <c-text-field
                label="Descripcion"
                autocomplete="off"
                :rules="rules"
                ref="mantenimiento0"
                v-model="data.descripcion"
                placeholder="Ingrese una descripcion"
              />
            </v-col>
            <v-col cols="12">
              <v-switch
                class="mt-1"
                v-model="data.inhabilita_maquinaria"
                inset
                :label="`Inhabilita Maquinaria: ${data.inhabilita_maquinaria ? 'Si' : 'No'}`"
                color="purple"
              ></v-switch>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <c-btn-crud-close @click="cancel()" />
        <v-spacer></v-spacer>
        <c-btn-crud-done ref="mantenimiento1" @click="save()" />
      </v-card-actions>
      <c-overlay :value="isLoadingMantenimientoTipo" />
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
    },
    "sync-input": {
      type: [String, Number],
      default: null,
    },
  },
  mounted() {
    if (this.editable) this.data = JSON.parse(JSON.stringify(this.props));
    setTimeout(() => this.next(0, "-"), 20);
    if (this.syncInput != null) this.data.descripcion = this.syncInput;
    this.sync = this.syncInput;
  },
  computed: {
    ...mapGetters("maquinaria_mantenimiento_tipo", [
      "isLoadingMantenimientoTipo",
    ]),
    title() {
      return this.editable
        ? "Modificar Tipo Mantenimiento"
        : "Registrar Tipo Mantenimiento";
    },
  },
  methods: {
    ...mapActions("maquinaria_mantenimiento_tipo", [
      "setMaquinariaMantenimientoTipo",
      "setMaquinariaMantenimientoTipoUpdate",
      "fetchMaquinariaMantenimientoTipo",
    ]),
    next(id, data) {
      if (data != "") {
        try {
          this.$refs[`mantenimiento${id + 1}`].focus();
        } catch (error) {
          this.$refs[`mantenimiento${id + 1}`].$el.focus();
        }
      }
    },
    cancel() {
      this.$emit("input", false);
    },
    async save() {
      if (!this.$refs.form.validate()) return null;
      const response = this.editable
        ? await this.setMaquinariaMantenimientoTipoUpdate({
            data: this.data,
            id: this.data.idmaquinaria_mantenimiento_tipo,
          })
        : await this.setMaquinariaMantenimientoTipo(this.data);
      if (response.success) {
        if (this.sync != null) {
          this.cancel();
          this.fetchMaquinariaMantenimientoTipo();
          return this.$emit(
            "sync-input",
            response.data.idmaquinaria_mantenimiento_tipo
          );
        }
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();
        this.fetchMaquinariaMantenimientoTipo();
        this.next(0, "-");
        if (this.editable) this.cancel();
      }
    },
  },
  data: () => ({
    rules: [(v) => !!v || "Este campo es obligatorio"],
    data: {
      idmaquinaria_mantenimiento_tipo: 0,
      descripcion: "",
      inhabilita_maquinaria: false,
    },
    default: {
      idmaquinaria_mantenimiento_tipo: 0,
      descripcion: "",
      inhabilita_maquinaria: false,
    },
    sync: null,
  }),
};
</script>