<template>
  <div>
    <v-dialog :value="value" max-width="800px" persistent @keydown.esc="cancelar()">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>{{'Detalle de translado a Cuenta Bancaria'}}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <c-text-date
                readonly
                outlined
                dense
                label="Fecha"
                v-model="data.fechtransladocaja"
                @keyup.native.enter="next(1,data.fechtransladocaja)"
              />
            </v-col>
            <v-col cols="12" md="12">
              <vue-text-field readonly label="Caja de Origen" v-model="data.idcajaorigen.descaja" />
            </v-col>
            <v-col cols="12">
              <vue-text-field
                readonly
                label="Motivo de Translado"
                v-model="data.motivotranslado"
              />
            </v-col>
          </v-row>
          <v-row v-for="(item, i) of data.transladodet" :key="i" dense>
            <v-col cols="12" md="3">
              <vue-text-field
                label="Cuenta"
                readonly
                :rules="true"
                v-model="data.transladodet[i].idcuentabanco.desccuentabanco"
              />
            </v-col>
            <v-col cols="12" md="3">
              <vue-text-field
                label="Moneda"
                readonly
                :rules="true"
                v-model="data.transladodet[i].idmoneda.descmoneda"
              />
            </v-col>
            <v-col cols="12" md="3">
              <vue-text-currency
                label="Monto"
                readonly
                :messages="data.transladodet[i].disponible"
                :rules="false"
                v-model="data.transladodet[i].monto"
              />
            </v-col>
            <v-col cols="12" md="3">
              <vue-text-field
                label="Numero de Deposito"
                readonly
                :rules="false"
                v-model="data.transladodet[i].nrotranslado"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn color="red" text @click="cancelar">
            <v-icon dark>close</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: null
    },
    mostrar: {
      type: [Object, Array]
    }
  },
  data() {
    return {
      data: {
        idtransladobanco: 0,
        idcajaorigen: {
          idcaja: ""
        },
        fechtransladocaja: "",
        motivotranslado: "",
        transladodet: []
      },
      default: {
        idtransladobanco: 0,
        idcajaorigen: {
          idcaja: ""
        },
        fechtransladocaja: "",
        motivotranslado: "",
        transladodet: []
      },
      monedaCuenta: ""
    };
  },
  mounted() {
    this.data = JSON.parse(JSON.stringify(this.mostrar));
  },
  watch: {
    $route(to, from) {
      if (from.path === "/transladocajabanco") return this.cancelar();
    }
  },

  methods: {
    cancelar() {
      this.$emit("input", false);
      this.$emit("mostrar", null);
      this.data = JSON.parse(JSON.stringify(this.default));
    }
  }
};
</script>