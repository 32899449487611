export default {
    cobrocuenta_load(state, data) {
        state.cobrocuenta_load = data;
    },
    cobrocuenta_data(state, data) {
        state.cobrocuenta_data = data;
    },
    cobrocuenta_data_id(state, data) {
        state.cobrocuenta_data_id = data;
    },
    cobrocuenta_by_id(state, data) {
        state.cobrocuenta_by_id = data;
    },

    cobrocuenta_num_rec_load(state, data) {
        state.cobrocuenta_num_rec_load = data;
    },
    cobrocuenta_num_rec(state, data) {
        state.cobrocuenta_num_rec = data;
    },
}