<template>
  <c-dialog-confirmation
    :value="value"
    @cancel="close()"
    @done="save()"
    :loading="isAsistenciaLoading"
    >Deseas eliminar esta marcacion ?</c-dialog-confirmation
  >
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    value: Boolean,
    props: Object,
  },
  computed: {
    ...mapGetters("asistencia", ["isAsistenciaLoading"]),
  },
  watch: {
    $route() {
      return this.close();
    },
  },
  methods: {
    ...mapActions("asistencia", ["setAsistenciaDelete"]),

    async save() {
      const id = this.$route.params.id;
      const data = {...this.props, estado: false}
      const response = await this.setAsistenciaDelete({ id, data });
      if (!response.success) return null;
      this.close();
      this.$emit('fetch');
    },
    close() {
      this.$emit("datos", null);
      this.$emit("input", false);
    },
  },
};
</script>

<style>
</style>