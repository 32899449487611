<template>
  <div>
    <div v-if="abrirmotivomuerte">
      <Enfermedad-Motivomuerte-create />
    </div>
    <v-dialog
      v-model="value"
      max-width="700px"
      persistent
      @keydown.esc="cancelar()"
    >
      <v-card>
        <v-form ref="form" lazy-validation>
          <v-toolbar flat dense>
            <v-toolbar-title>{{
              isEdit ? "Modificar Muerte" : "Registrar Muerte"
            }}</v-toolbar-title>
          </v-toolbar>

          <v-container>
            <MuerteForm ref="muerteForm" :datos="data" :readonly="readonly" />
          </v-container>

          <v-card-actions>
            <c-btn-crud-close @click="cancelar()"></c-btn-crud-close>
            <v-spacer></v-spacer>
            <c-btn-crud-done
              :disabled="readonly"
              id="aceptar"
              @click="guardar()"
            ></c-btn-crud-done>
          </v-card-actions>
        </v-form>
      </v-card>
      <c-overlay :value="isLoading"></c-overlay>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import MuerteForm from "./CreateRef";
export default {
  components: {
    MuerteForm,
  },
  props: {
    value: Boolean,
    props: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    data: null,
    isEdit: false,
  }),
  watch: {
    $route() {
      return this.cancelar();
    },
  },
  computed: {
    ...mapGetters("muerte",['isLoading']),
    ...mapGetters("motivomuerte", {
      abrirmotivomuerte: "motivomuerte_create",
      get_motivomuerte_reload: "motivomuerte_reload",
    }),
  },
  created() {
    this.nuevo(this.props);
  },
  methods: {
    ...mapActions("muerte", ["fetchMuerte", "setMuerte", "setMuerteUpdate"]),
    cancelar() {
      this.data = null;
      this.$emit("input", false);
      this.$emit("reset", null);
      this.fetchMuerte();
      this.isEdit = false;
    },
    async guardar() {
      if (!this.$refs.muerteForm.validate()) return null;
      this.data = JSON.parse(
        JSON.stringify(await this.$refs.muerteForm.obtenerDatos())
      );
      const id = this.data.idmuerte;
      const response = this.isEdit
        ? await this.setMuerteUpdate({ id, data: this.data })
        : await this.setMuerte(this.data);
      if (response.success) {
        this.data = null;
        if (this.isEdit) this.cancelar();
      }
    },
    nuevo(val) {
      if (val === null) return null;
      this.data = JSON.parse(JSON.stringify(val));
      this.isEdit = true;
    },
  },
};
</script>
