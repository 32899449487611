<template>
  <div>
    <v-dialog max-width="800" :value="true" persistent>
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Indice NDVI</v-toolbar-title>
          <v-divider class="mx-2" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-row>
            <v-col>
              <c-text-date  label="Fecha Inicio" v-model="fechainicio" single-line hide-details></c-text-date>
            </v-col>
            <v-col>
              <c-text-date  label="Fecha Final" v-model="fechafinal" single-line hide-details></c-text-date>
            </v-col>
          </v-row>
          <v-btn outlined text fab small color="primary" @click="obtenerHistorico">
            <v-icon>cloud_download</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-container>
          <v-row dense>
            <v-col cols="12" v-if="grafico">
              <v-chart :options="option" autoresize />
            </v-col>

          </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="$emit('input', 1)">
            <v-icon>clear</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { current_date, substract_days } from "@/services/util/date.service";
import ECharts from "vue-echarts/components/ECharts";
export default {

  components: {
    "v-chart": ECharts
  },
  props: {
    value: Number
  },
  watch: {
    Vget_integralimages(val) {
      this.filtrarStory(val);
    },
    darkMode(val) {
      this.option.legend.textStyle.color = val ? '#eee' : '#000'
      this.option.toolbox.iconStyle.borderColor = val ? '#eee' : '#000'
      this.option.xAxis.axisLabel.color = val ? '#eee' : '#000'
      this.option.yAxis.axisLabel.color = val ? '#eee' : '#000'
    }
  },
  computed: {
    ...mapGetters("agromap", ["Vget_integralimages"]),
    darkMode() {
      return this.$vuetify.theme.dark;
    },
  },
  mounted() {
    this.obtenerHistorico();
    this.option.legend.textStyle.color = this.darkMode ? '#eee' : '#000'
    this.option.toolbox.iconStyle.borderColor = this.darkMode ? '#eee' : '#000'
    this.option.xAxis.axisLabel.color = this.darkMode ? '#eee' : '#000'
    this.option.yAxis.axisLabel.color = this.darkMode ? '#eee' : '#000'
    setTimeout(() => (this.grafico = true), 300);
  },
  methods: {
    ...mapActions("agromap", ["Vact_integralimages"]),
    obtenerHistorico() {
      let fechaInicio = this.fechainicio;
      let fechaFinal = this.fechafinal;
      this.Vact_integralimages({
        fechaInicio,
        fechaFinal,
        key: this.$route.query.polygon
      });
    },

    filtrarStory(val) {
      let fecha = [];
      let min = [];
      let mean = [];
      let max = [];
      if(!val.length) return null;
      val.slice().forEach(x => {
        fecha.push(x.generationdate);
        min.push(x.minimo.toFixed(2));
        mean.push(x.media.toFixed(2));
        max.push(x.maximo.toFixed(2));
      });
      this.option.xAxis.data = fecha;
      this.option.series[0].data = max;
      this.option.series[1].data = mean;
      this.option.series[2].data = min;
    }
  },
  data() {
    return {
      fechainicio: substract_days(90),
      fechafinal: current_date(),
      grafico: false,
      option: {
        tooltip: {
          trigger: "axis"
        },
        toolbox: {
          iconStyle: {
            borderColor: '#eee'
          },
          show: true,
          feature: {
            dataZoom: {
              title: {
                zoom: "Zoom",
                back: "Atras"
              },
              yAxisIndex: "none"
            },
            restore: {
              title: "Restaurar"
            },
            dataView: {
              readOnly: true,
              lang: ["Lista", "Regresar"],
              title: "Listar",
              optionToContent: function(opt) {
                var axisData = opt.xAxis[0].data;
                var series = opt.series;
                var table =
                  '<table style="width:100%;text-align:center"><tbody><tr>' +
                  '<td style="color:black">Fecha:</td>' +
                  '<td style="color:black">' +
                  series[0].name +
                  "</td>" +
                  '<td style="color:black">' +
                  series[1].name +
                  "</td>" +
                  '<td style="color:black">' +
                  series[2].name +
                  "</td>" +
                  '<td style="color:black">';
                for (var i = 0, l = axisData.length; i < l; i++) {
                  table +=
                    "<tr>" +
                    '<td style="color:black">' +
                    axisData[i] +
                    "</td>" +
                    '<td style="color:black">' +
                    series[0].data[i] +
                    "</td>" +
                    '<td style="color:black">' +
                    series[1].data[i] +
                    "</td>" +
                    '<td style="color:black">' +
                    series[2].data[i] +
                    "</td>" +
                    '<td style="color:black">';
                }
                table += "</tbody></table>";
                return table;
              }
            },
            magicType: {
              type: ["line", "bar"],
              title: {
                line: "Linea",
                bar: "Bar"
              }
            },

            saveAsImage: {
              title: "Descargar"
            }
          }
        },
        legend: {
          data: ["Indice Maximo", "Indice Promedio", "Indice Minimo"],
          textStyle: {
            color: '#eee'
          }
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [],
          axisLabel: {
            color: '#eee'
          }
        },
        yAxis: {
          type: "value",
          axisLabel: {
            color: '#eee'
          }
        },
        series: [
          {
            name: "Indice Maximo",
            type: "line",
            data: [],
            markPoint: {
              data: [{ type: "max", name: "Maximo de Max" }]
            },
            markLine: {
              data: [
                { type: "average", name: "Promedio MAX" },
                [
                  {
                    symbol: "none",
                    x: "92%",
                    yAxis: "max"
                  },
                  {
                    symbol: "circle",
                    label: {
                      position: "start",
                      formatter: "   Max"
                    },
                    type: "max",
                    name: "Maximo Historico MAX"
                  }
                ]
              ]
            }
          },
          {
            name: "Indice Promedio",
            type: "line",
            data: [],
            markLine: {
              data: [{ type: "average", name: "Promedio MEAN" }]
            }
          },
          {
            name: "Indice Minimo",
            type: "line",
            data: [],
            markPoint: {
              data: [{ type: "min", name: "Minimo de Min" }]
            },
            markLine: {
              data: [
                { type: "average", name: "Promedio MIN" },
                [
                  {
                    symbol: "none",
                    x: "92%",
                    yAxis: "min"
                  },
                  {
                    symbol: "circle",
                    label: {
                      position: "start",
                      formatter: "   Min"
                    },
                    type: "min",
                    name: "Minimo Historico MIN"
                  }
                ]
              ]
            }
          }
        ]
      }
    };
  }
};
</script>
<style scoped>
.chart-wrapper {
  width: 100%;
  height: 400px;
}
.echarts {
  width: 100%;
  height: 400px;
}
</style>