<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>{{operacion ? 'Registrar Cuenta Diario' : 'Modificar Cuenta Diario'}}</v-toolbar-title>
    </v-toolbar>
    <v-container>
      <v-form ref="form">
        <v-row dense>
          <v-col cols="12">
            <vue-text-field
              ref="cuentadiarioInput1"
              label="Descripcion"
              v-model="data.descripcion"
              @keyup.native.enter="next(1,data.descripcion)"
            ></vue-text-field>
          </v-col>
          <v-col cols="12">
            <vue-autocomplete-generic
              ref="cuentadiarioInput2"
              action="division/Vact_division_data"
              getter="division/Vget_division_data"
              load="division/Vget_division_load"
              pk="iddivision"
              label="Seleccione Division"
              title="descripcion"
              permission="Division"
              v-model="data.iddivision.iddivision"
              :create="true"
              @sync-input="search = $event"
              @create="formulario.division = $event"
              @keyup.native.enter="next(2,data.iddivision.iddivision)"
            />
             <v-dialog v-if="formulario.division" v-model="formulario.division" max-width="700">
              <v-card>
                <DiarioCaja-Division-Crud
                  v-model="formulario.division"
                  dialog-mode
                  :sync-input="search"
                  @sync-input="data.iddivision = $event ,next(2,'-')"
                />
              </v-card>
            </v-dialog>
            
          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <v-card-actions>
      <v-btn text color="red" @click="cancelar()">
        <v-icon>clear</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        text
        color="primary"
        ref="cuentadiarioInputaceptar"
        @click="operacion ? guardar() : editar()"
      >
        <v-icon>done</v-icon>
      </v-btn>
    </v-card-actions>
    <c-overlay absolute :value="Vget_cuentadiario_save_load"></c-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mensaje } from "@/services/notify.service";
export default {
  props:{
    'dialog-mode':{
      type: Boolean,
      default: false
    },
    value:{
      type: Boolean,
      default: false
    },
    "sync-input": {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      search: "",
      operacion: true,
      data: {
        idcuenta_diario: 0,
        descripcion: "",
        iddivision: {
          iddivision: "",
        },
      },
      default: {
        idcuenta_diario: 0,
        descripcion: "",
        iddivision: {
          iddivision: "",
        },
      },
      formulario: {
        division: false,
      },
      path: {
        add: "/diariocaja/cuentadiario/add",
        edit: "/diariocaja/cuentadiario/edit",
      },
    };
  },
  computed: {
    ...mapGetters("cuentadiario", ["Vget_cuentadiario_save_load"]),
    currentPath() {
      return this.$route.path;
    },
  },
  watch: {
    currentPath(path) {
      if (path === this.path.add || path === this.path.edit) {
        this.changeCrudMode();
        if (this.$refs.form) this.$refs.form.resetValidation();
      }
    },
  },
  mounted() {
    setTimeout(() => this.next(0, "-"), 200);
     if (this.syncInput != null) this.data.descripcion = this.syncInput;
    this.changeCrudMode();
  },
  methods: {
    ...mapActions("cuentadiario", [
      "Vact_cuentadiario_data",
      "Vact_cuentadiario_save",
      "Vact_cuentadiario_update",
    ]),
    changeCrudMode() {
      if(this.dialogMode) return null;
      if (this.currentPath != "/diariocaja/cuentadiario/add") {
        this.operacion = false;
        this.data = JSON.parse(JSON.stringify(this.$route.query));
      } else {
        this.operacion = true;
        this.data = JSON.parse(JSON.stringify(this.default));
      }
    },
    next(index, data) {
      try {
        if (data != null && data != "") {
          let id = `cuentadiarioInput${index + 1}`;
          this.$refs[id].$children[0].focus();
        }
      } catch (error) {
        this.$refs.cuentadiarioInputaceptar.$el.focus();
      }
    },
    async guardar() {
      if (!this.$refs.form.validate()) return null;
      const sync =  this.syncInput;
      let a = await this.Vact_cuentadiario_save(this.data);
      if (a.estado == true) {
        if (sync != null) {
          this.cancelar();
          this.$emit('sync-input',a.data.data.id);
        }
        this.data = JSON.parse(JSON.stringify(this.default));
        this.Vact_cuentadiario_data();
        this.$notify(a.info);
        this.$refs.form.resetValidation();
        this.next(0, "-");
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
    },
    async editar() {
      const id = this.$route.params.id;
      const data = this.data;
      let a = await this.Vact_cuentadiario_update({ id, data });
      if (a.estado == true) {
        this.$notify(a.info);
        this.Vact_cuentadiario_data();
        this.cancelar();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
    },
    cancelar() {
      if(!this.dialogMode)
      this.$router.push({
        path: "/diariocaja/cuentadiario",
      });
      this.$emit('input',false);
    },
  },
};
</script>

<style>
</style>