<template>
  <v-dialog :value="value" width="800" persistent @keydown.esc="close()">
    <v-card>
      <v-toolbar flat dense>
        <v-toolbar-title>{{
          isEdit ? "Modificar Termo Semen" : "Registrar Termo Semen"
        }}</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-form ref="form">
          <v-row dense>
            <v-col cols="12" sm="6">
              <c-text-field
                label="Descripcion"
                ref="termo1"
                outlined
                dense
                v-model="data.descripcion"
                @keyup.native.enter="next(1, data.descripcion)"
              ></c-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <c-text-currency
                label="Capacidad del Termo"
                outlined
                ref="termo2"
                dense
                v-model="data.cantidad"
                @keyup.native.enter="next(2, data.cantidad)"
              ></c-text-currency>
            </v-col>
          </v-row>
        </v-form>

          <v-data-table
            :headers="headers"
            :items="data.termosemendet"
            :items-per-page="9999"
            hide-default-footer
          >
          </v-data-table>

      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <c-btn-crud-close @click="close()"></c-btn-crud-close>
        <v-spacer></v-spacer>
        <c-btn-crud-done @click="guardar()"></c-btn-crud-done>
      </v-card-actions>
      <c-overlay :value="isLoading"></c-overlay>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    value: Boolean,
    props: Object,
  },
  data: () => ({
    isEdit: false,
    headers: [
      { text: "Nombre Toro", align: "left", value: "idanimal.nombanimal" },
      { text: "Rp Toro", align: "left", value: "idanimal.rpanimal" },
      { text: "Sexo", align: "left", value: "idsexo.descsexocria" },
      { text: "Cantidad", value: "cantidad", align: "end" }
    ],
    data: {
      descripcion: "",
      cantidad: "",
      termosemendet: [],
    },
    default: {
      descripcion: "",
      cantidad: "",
      termosemendet: [],
    },
    detalle: {
      idanimal: {
        idanimal: "",
      },
      idsexo: {
        idsexo: "",
      },
      cantidad: null,
    },
    detalle_default: {
      idanimal: {
        idanimal: "",
      },
      idsexo: {
        idsexo: "",
      },
      cantidad: null,
    },
  }),
  created() {
    if (this.props) {
      this.data = JSON.parse(JSON.stringify(this.props));
      this.isEdit = true;
    }
  },
  mounted() {
    setTimeout(() => this.next(0, "-"), 20);
  },
  computed: {
    ...mapGetters("termosemen", ["isLoading"]),
  },
  methods: {
    ...mapActions("termosemen", [
      "fetchTermoSemen",
      "setTermoSemen",
      "setTermoSemenUpdate",
    ]),
    close() {
      this.$emit("input", false);
      this.$emit("reset", null);
    },
    next(id, data) {
      if (data != "")
        try {
          this.$refs[`termo${id + 1}`].focus();
        } catch (error) {
          this.$refs[`termo${id + 1}`].$el.focus();
        }
    },
    addTable() {
      if (!this.$refs.formDetail.validate()) return null;
      this.data.termosemendet.push(this.detalle);
      this.detalle = JSON.parse(JSON.stringify(this.detalle_default));
      this.$refs.formDetail.resetValidation();
      this.next(2, "-");
    },
    async guardar() {
      if (!this.$refs.form.validate()) return null;
      const id = this.data.idtermosemen;
      const response = this.isEdit
        ? await this.setTermoSemenUpdate({ id, data: this.data })
        : await this.setTermoSemen(this.data);
      if (response.success) {
        this.data = JSON.parse(JSON.stringify(this.default));
        this.fetchTermoSemen();
        if (this.isEdit) return this.close();
      }
    },
    editar(item) {
      this.detalle = JSON.parse(JSON.stringify(item));
      this.eliminar(item);
    },
    eliminar(item) {
      const index = this.data.termosemendet.indexOf(item);
      this.data.termosemendet.splice(index, 1);
    },
  },
};
</script>
