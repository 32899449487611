<template>
  <v-card>
    <v-alert type="error" v-if="permission_alert">
      Error!!! Este usuario no tiene permisos en esta ventana
    </v-alert>
    <v-toolbar flat dense>
      <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
      <widgets-Favorite :ruta="$route.path" :formulario="$route.name" />
      <v-divider class="mx-2" inset vertical></v-divider>

      <v-spacer></v-spacer>
      <v-text-field v-model="search" append-icon="search" label="Busqueda" single-line hide-details
        v-if="permission.can_view" class="mr-1"></v-text-field>
      <v-toolbar-items>
        <c-btn-table-add @click="crud.add = true" v-if="permission.can_add" class="mr-1"></c-btn-table-add>
        <c-btn-table-reload @click="fetchMuerte()"></c-btn-table-reload>
      </v-toolbar-items>
    </v-toolbar>
    <v-data-table :loading="isLoading" :headers="headers" :items="getMuerte" :search="search"
      v-if="permission.can_view">
      <template v-slot:[`item.accion`]="props">
        <c-btn-table-view @click="rowselect(props.item)"></c-btn-table-view>
        <c-btn-table-edit @click="mupdate(props.item)" v-if="permission.can_update"></c-btn-table-edit>
        <c-btn-table-delete @click="meliminar(props.item)" v-if="permission.can_delete"></c-btn-table-delete>
      </template>
    </v-data-table>
    <Crud v-model="crud.add" v-if="crud.add" :props="crud.datos" @reset="crud.datos = $event" />
    <Crud v-model="crud.view" v-if="crud.view" readonly :props="crud.datos" @reset="crud.datos = $event" />
    <Delete v-model="crud.delete" v-if="crud.delete" :props="crud.datos" />
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { MUERTE } from "@/constants/forms";
import Crud from "./Create";
import Delete from "./Delete";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";

export default {
  components: {
    Crud,
    Delete,
  },
  data: () => ({
    search: "",
    headers: [
      { text: "Fecha", align: "left", value: "fecrmuerte" },
      { text: "Animal", align: "left", value: "idanimal.nombanimal" },
      { text: "Rp", align: "left", value: "idanimal.rpanimal" },
      { text: "Raza", align: "left", value: "idanimal.idraza.descraza" },
      { text: "Lote", align: "left", value: "idanimal.idlote.desclote" },
      { text: "Comentario", align: "left", value: "comnmuerte" },
      {
        text: "Motivo",
        align: "left",
        value: "idmotivomuerte.descmotivomuerte",
      },
      { text: "Accion", value: "accion", sortable: false, align: "right" },
    ],
    permission: {
      can_add: false,
      can_view: false,
      can_update: false,
      can_delete: false,
    },
    crud: {
      add: false,
      view: false,
      delete: false,
      datos: null,
    },
  }),

  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.fetchMuerte();

    const permission = getPermission(MUERTE).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  computed: {
    ...mapGetters("muerte", ["getMuerte", "isLoading"]),
    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },
  methods: {
    ...mapActions("muerte", ["fetchMuerte"]),
    meliminar(item) {
      this.crud.datos = JSON.parse(JSON.stringify(item));
      this.crud.delete = true;
    },
    rowselect(val) {
      this.crud.datos = JSON.parse(JSON.stringify(val));
      this.crud.view = true;
    },
    mupdate(val) {
      this.crud.datos = JSON.parse(JSON.stringify(val));
      this.crud.add = true;
    },
  },
};
</script>
