<template>
  <div>
    <div v-if="crud.view">
      <Venta-Venta-show
        v-model="crud.view"
        :datos="crud.datos"
        @datos="crud.datos = $event"
      />
    </div>
    <div v-if="crud.add">
      <Venta-Venta-Create
        v-model="crud.add"
        :datos="crud.datos"
        @datos="crud.datos = $event"
      />
    </div>
    <v-card>
      <v-alert type="error" v-if="permission_alert"
        >Error!!! Este usuario no tiene permisos en esta ventana</v-alert
      >
      <v-toolbar flat dense>
        <v-toolbar-title>Venta</v-toolbar-title>
        <widgets-Favorite ruta="/venta" formulario="Venta" />
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          :append-icon="search.length > 0 ? `cloud_download` : `search`"
          placeholder="Busqueda por Razon Social, Ruc y Factura"
          autocomplete="off"
          label="Busqueda"
          class="mr-1"
          single-line
          hide-details
          @click:append="busqueda()"
          v-if="permission.can_view"
        ></v-text-field>
        <v-toolbar-items>
          <v-speed-dial v-model="opcion" direction="left" class="mr-1">
            <template v-slot:activator>
              <v-btn v-model="opcion" height="100%" small color="red" tile dark>
                <v-icon v-if="opcion">keyboard_arrow_up</v-icon>
                <v-icon v-else>keyboard_arrow_down</v-icon>
              </v-btn>
            </template>
            <v-btn fab dark small color="indigo" @click="searchadvanced = true">
              <v-icon>zoom_in</v-icon>
            </v-btn>
            <v-btn fab dark small color="green" @click="generarCsv()">
              <v-icon>grid_on</v-icon>
            </v-btn>
            <v-btn fab dark small color="red" @click="generarPdf()">
              <v-icon>get_app</v-icon>
            </v-btn>
            <v-btn
              v-if="permission.can_editNumFactura"
              fab
              dark
              small
              color="black"
              @click="modificarFactura = true"
            >
              <v-icon>build</v-icon>
            </v-btn>
          </v-speed-dial>
          <Venta-SelectPuntoVenta
            v-if="permission.can_add"
            mode="factura"
            class="mr-1"
            v-model="crud.add"
            ref="refPuntoVenta"
          />
          <c-btn-table-reload @click="Vact_venta_data()"></c-btn-table-reload>
        </v-toolbar-items>
      </v-toolbar>
      <widgets-filteradvanced
        v-model="searchadvanced"
        :campos="campos"
        @resp="datos = $event"
        url="/apiventasearch/"
      />
      <v-data-table
        :loading="Vget_venta_load"
        :headers="headers"
        :items="datos"
        :search="search"
        class="elevation-1"
        v-if="permission.can_view"
      >
        <template v-slot:[`item.numfventa`]="{ item }">
          <div>
            {{ formatFact(item) }}
          </div>
        </template>
        <template v-slot:[`item.totalVenta`]="{ item }">
          <div class="font-weight-black">{{ item.totalVenta }}</div>
        </template>
        <template v-slot:[`item.accion`]="props">
          <c-btn-table-view @click="rowselect(props.item)"></c-btn-table-view>
          <c-btn-table-edit
            v-if="permission.can_update"
            @click="mupdate(props.item)"
            :disabled="props.item.anulventa"
          ></c-btn-table-edit>
          <c-btn-table-delete
            @click="meliminar(props.item)"
            v-if="permission.can_delete"
            :disabled="props.item.anulventa"
          ></c-btn-table-delete>
        </template>
      </v-data-table>
    </v-card>
    <Delete
      v-if="crud.delete"
      v-model="crud.delete"
      :props="crud.datos"
      @datos="crud.datos = $event"
    />
    <ModificarFactura
      v-if="modificarFactura"
      v-model="modificarFactura"
      :datos="datos"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { utilidad } from "@/services/compra_utilidad.service";
import { formatFact } from "./calculos";
import { exportTo } from "@/services/export.service";
import campos from "./exportFile";
import Delete from "./Delete";
import ModificarFactura from "./MoficarFactura";

import { VENTA } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
  PUEDE_EDITAR_NUNFACTURA,
} from "@/constants/permissions";
export default {
  components: {
    ModificarFactura,
    Delete,
  },
  data() {
    return {
      searchadvanced: false,
      opcion: false,
      campos: campos,
      search: "",
      datos: [],
      crud: {
        add: false,
        view: false,
        delete: false,
        datos: null,
      },
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
      idpunto: [],
      modificarFactura: false,
      headers: [
        { text: "Fecha", align: "left", value: "fechventa" },
        {
          text: "Cliente",
          align: "left",
          value: "idcontacto.idcontacto.desccontacto",
        },
        { text: "Nro Factura", align: "left", value: "numfventa" },

        {
          text: "Condicion",
          align: "left",
          value: "idtipofactura.desctipofactura",
        },
        {
          text: "Moneda",
          align: "left",
          value: "idmoneda.descmoneda",
        },
        {
          text: "Total",
          align: "end",
          value: "totalVenta",
        },
        { text: "Accion", value: "accion", sortable: false, align: "end" },
      ],
    };
  },

  watch: {
    Vget_venta_data(val) {
      if (val === null) return;
      this.datos = JSON.parse(JSON.stringify(val));
    },
  },
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    const permission = getPermission(VENTA).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
      can_editNumFactura: permission[PUEDE_EDITAR_NUNFACTURA],
    };
    this.Vact_venta_data();
  },
  computed: {
    ...mapGetters("venta", ["Vget_venta_data", "Vget_venta_load"]),
    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },

  methods: {
    ...mapActions("venta", ["Vact_venta_data"]),
    formatFact(item) {
      return formatFact(item);
    },
    meliminar(item) {
      this.crud.datos = JSON.parse(JSON.stringify(item));
      this.crud.delete = true;
    },
    rowselect(val) {
      this.crud.datos = JSON.parse(JSON.stringify(val));
      this.crud.view = true;
    },
    mupdate(val) {
      this.crud.datos = JSON.parse(JSON.stringify(val));
      this.crud.add = true;
    },

    async busqueda() {
      this.Vact_venta_data(this.search);
    },
    generarPdf() {
      let head = [
        ["Fecha", "Cliente", "Nro Factura", "Condicion", "Moneda", "Total"],
      ];
      let body = [];
      this.datos.map((x) => {
        body.push([
          x.fechventa,
          x.idcontacto.idcontacto.desccontacto,
          x.idtimbrado.idestablecimiento.establecimiento +
            "-" +
            x.idtimbrado.idpuntoexpedicion.puntoexpedicion +
            "-" +
            utilidad.completar_nrof(x.numfventa.toString()),
          x.idtipofactura.desctipofactura,
          x.idmoneda.descmoneda,
          x.totalVenta,
        ]);
      });
      exportTo.pdftable(head, body, "Lista de Ventas");
    },
    generarCsv() {
      let data = [];
      this.datos.map((x) => {
        data.push({
          Fecha: x.fechventa,
          Cliente: x.idcontacto.idcontacto.desccontacto,
          Factura:
            x.idtimbrado.idestablecimiento.establecimiento +
            "-" +
            x.idtimbrado.idpuntoexpedicion.puntoexpedicion +
            "-" +
            utilidad.completar_nrof(x.numfventa.toString()),
          Condicion: x.idtipofactura.desctipofactura,
          Moneda: x.idmoneda.descmoneda,
          Total: x.totalVenta,
        });
      });
      exportTo.xlsx(data);
    },
  },
};
</script>
