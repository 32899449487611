import { db } from "@/services/db.service";
export default {

    async tambo_update({commit},request){
        commit('tambo_update',request);
    },
    async Vact_tambo_data({commit}){
        commit('tambo_load',true);
        try {
            const data = await db.get('/apitambo/');
            commit('tambo_data',data);
            commit('tambo_load',false);
            return true
        } catch (e) {
            commit('tambo_load',false);
            return false
        } 
    },
    async Vact_tambo_empresa({commit},idempresa){
        commit('tambo_load',true);
        try {
            const data = await db.get(`/apitambo/?idempresa=${idempresa}`);
            commit('tambo_empresa',data);
            commit('tambo_load',false);
            return true
        } catch (e) {
            commit('tambo_load',false);
            return false
        } 
    },
    async Vact_tambo_del({dispatch},id){
        try {
            const data = await db.delete('/apitambo/'+id);
            dispatch('Vact_tambo_data');
            return data
        } catch (e) {
            return false
        } 
    },
    async Vact_tambo_save({commit},data){
        commit('tambo_save_load',true);
        try {
            const resp = await db.save('/apitambo/',data);
            commit('tambo_save_load',false);
            return resp;
        } catch (e) {
            commit('tambo_save_load',false);
            return e
        } 
    },
    async Vact_tambo_update({commit},{id, data}){
        commit('tambo_save_load',true);
        try {
            const resp = await db.update('/apitambo/'+id+'/',data);
            commit('tambo_save_load',false);
            return resp;
        } catch (e) {
            commit('tambo_save_load',false);
            return e
        } 
    },
}
