export const SET_LOADING_TIPO = 'SET_LOADING_TIPO'
export const SET_TIPO = 'SET_TIPO'
export const url = '/apifinanzatipo/'

export const SET_LOADING_CUENTA = 'SET_LOADING_CUENTA'
export const SET_CUENTA = 'SET_CUENTA'
export const url2 = '/apifinanzacuenta/'

export const SET_LOADING_GRUPO = 'SET_LOADING_GRUPO'
export const SET_GRUPO = 'SET_GRUPO'
export const url3 = '/apifinanzagrupo/'

export const SET_LOADING_CATEGORIA = 'SET_LOADING_CATEGORIA'
export const SET_CATEGORIA = 'SET_CATEGORIA'
export const url4 = '/apifinanzacategoria/'

export const SET_LOADING_MOVIMIENTO = 'SET_LOADING_MOVIMIENTO'
export const SET_MOVIMIENTO = 'SET_MOVIMIENTO'
export const SET_TRANSFERENCIA = 'SET_TRANSFERENCIA'
export const url5 = '/apifinanzamovimiento/'

export const SET_LOADING_SALDO = 'SET_LOADING_SALDO'
export const SET_SALDO = 'SET_SALDO'
export const url6 = '/apifinanzasaldo/'

export const SET_LOADING_MOVIMIENTO_CUENTA = 'SET_LOADING_MOVIMIENTO_CUENTA'
export const SET_MOVIMIENTO_CUENTA = 'SET_MOVIMIENTO_CUENTA'
export const url7 = '/apifinanzamovimientocuenta/'

export const SET_LOADING_GASTO_MENSUAL = 'SET_LOADING_GASTO_MENSUAL'
export const SET_GASTO_MENSUAL = 'SET_GASTO_MENSUAL'
export const url8 = '/apifinanzagastos/'

export const SET_LOADING_TIEMPO = 'SET_LOADING_TIEMPO'
export const SET_TIEMPO = 'SET_TIEMPO'
export const url9 = '/apifinanzatiempo/'

export const SET_LOADING_PRESUPUESTO_DISPONIBLE = 'LOADING_PRESUPUESTO_DISPONIBLE'
export const SET_PRESUPUESTO_DISPONIBLE = 'SET_PRESUPUESTO_DISPONIBLE'
export const url10 = '/apipresupuestodisponible/'