<template>
  <div>
    <v-dialog v-model="dialogo" max-width="700px" persistent @keydown.esc="cancelar()">
      <v-card>
        <v-form ref="form" lazy-validation>
          <v-card-title>
            <span class="headline">{{titulo}}</span>
          </v-card-title>
          <v-card-text>
            <v-row row dense>
              <v-col cols="12">
                <v-text-field
                  dense
                  type="text"
                  :rules="validar"
                  ref="input1"
                  v-model="data.desctipoanalisis"
                  label="Descripcion"
                  required
                  outlined
                  autocomplete="off"
                  @keyup.enter="nextfocus(1)"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  dense
                  type="text"
                  :rules="validar"
                  ref="input2"
                  v-model="data.abrvtipoanalisis"
                  label="Abreviatura"
                  required
                  outlined
                  autocomplete="off"
                  @keyup.enter="nextfocus(2)"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cancelar">Cancelar</v-btn>
            <v-btn ref="aceptar" color="blue darken-1" text @click="guardar">Guardar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { db } from "@/services/db.service";
import { mensaje } from "@/services/notify.service";
export default {
  data() {
    return {
      overlay: false,
      titulo: "Nuevo Tipo Analisis",
      data: {
        desctipoanalisis: "",
        abrvtipoanalisis: ""
      },
      default: {
        desctipoanalisis: "",
        abrvtipoanalisis: ""
      },
      url: "/apitipoanalisis/",
      validar: [v => !!v || "Campo requerido"],
      validars: [v => !!v || "Seleccione un campo"],
      formulario: {},
      dialogo: false,
      menu1: false,
      reload: 0,
      operacion: true
    };
  },
  computed: {
    ...mapGetters("tipoanalisis", {
      getcreate: "tipoanalisis_create",
      getupdate: "tipoanalisis_update",
      tipoanalisis_key_get: "tipoanalisis_key"
    })
  },
  created() {
    this.nuevo(this.getupdate);
  },
  mounted() {
    setTimeout(() => this.$refs.input1.focus(), 500);
  },
  watch: {
    $route(to, from) {
      if (from.path === "/tipoanalisis") return this.cancelar();
    }
  },
  methods: {
    ...mapActions("tipoanalisis", [
      "tipoanalisis_create",
      "tipoanalisis_update",
      "tipoanalisis_reload",
      "tipoanalisis_key"
    ]),

    nextfocus(x) {
      switch (x) {
        case 1: {
          if (this.data.desctipoanalisis != "") {
            this.$refs.input2.focus();
          }
          break;
        }
        case 2: {
          if (this.data.abrvtipoanalisis != "") {
            this.$refs.aceptar.$el.focus();
          }
          break;
        }
      }
    },

    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.dialogo = false;
      this.tipoanalisis_update(null);
      this.tipoanalisis_create(false);
      this.tipoanalisis_reload(true);
      this.tipoanalisis_key(this.tipoanalisis_key_get + 1);
      this.operacion = true;
    },
    async guardar_finalizar() {
      let a = await db.save(this.url, this.data);
      if (!a.estado) {
        this.overlay = false;
        return a.info.map(x => {
          this.$notify(mensaje.error(x));
        });
      }
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$notify(a.info);
      this.$refs.form.resetValidation();
      this.$refs.input1.focus();
      this.overlay = false;
    },
    async editar_finalizar() {
      const id = this.getupdate.idtipoanalisis;
      let a = await db.update(`${this.url}${id}/`, this.data);
      if (!a.estado) {
        this.overlay = false;
        return a.info.map(x => {
          this.$notify(mensaje.error(x));
        });
      }
      this.$notify(a.info);
      this.cancelar();
      this.overlay = false;
    },
    guardar() {
      if (!this.$refs.form.validate()) return this.$notify(mensaje.completar());
      this.overlay = true;
      if (this.operacion === true) return this.guardar_finalizar();
      if (this.operacion === false) return this.editar_finalizar();
    },
    nuevo(val) {
      this.dialogo = true;

      if (val !== null) {
        this.data = val;
        this.operacion = false;
        this.titulo = "Editar Tipo Analisis";
      }
    }
  }
};
</script>

