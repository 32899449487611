import store from '@/store'
const vuex = {
    get(module){
        return store.getters[module];
    },
    save(module,data){
        store.commit(module, data);
    },
    saveA(module,data){
        store.commit(module,store.getters[module]+data);
    }
}
export {
    vuex
}