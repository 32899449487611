<template>
  <div>
      <v-row dense>
      <v-col v-for="(item, i) in Vget_favorites" :key="i" cols="12" sm="6" md="2">
        <v-card outlined :to="item.url">
          <v-progress-linear value="100%" color="primary" height="2" reactive></v-progress-linear>
          <v-toolbar flat>
            <v-toolbar-title class="subtitle-2">{{item.nombre}}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon color="primary">favorite</v-icon>
          </v-toolbar>
        </v-card>
      </v-col>
      <c-overlay absolute :value="Vget_favorites_load"></c-overlay>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
 mounted() {
    this.Vact_favorites();
  },
  computed: {
    ...mapGetters("dashboard", ["Vget_favorites", "Vget_favorites_load"]),
  },
  methods: {
    ...mapActions("dashboard", ["Vact_favorites"]),
  },
}
</script>

<style>

</style>