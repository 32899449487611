
export default {

  Vget_empresa_data: (state) => {
    return state.empresa_data
  },
  Vget_empresa_load: (state) => {
    return state.empresa_load
  },
  Vget_empresa_moneda: (state) => {
    return state.empresa_moneda
  },
  Vget_empresa_active: (state) => {
    return state.empresa_active
  }
}
