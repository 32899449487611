export default {
    async viaaplicacion_create({commit},request){
        commit('viaaplicacion_create',request);
    },
    async viaaplicacion_update({commit},request){
        commit('viaaplicacion_update',request);
    },
    async viaaplicacion_reload({commit},request){
        commit('viaaplicacion_reload',request);
    },
    async viaaplicacion_key({commit},request){
        commit('viaaplicacion_key',request);
    },
    async viaaplicacion_show({commit},request){
        commit('viaaplicacion_show',request);
    },
}
