<template>
  <div>
    <GymTipo v-if="formularios.gymtipo" v-model="formularios.gymtipo" />
    <v-dialog
      :value="value"
      max-width="900px"
      persistent
      @keydown.esc="cancelar()"
    >
      <v-card>
        <v-toolbar flat>
          <v-card-title>
            <span
              >{{ operacion ? "Agregar " : "Editar " }}Planilla Gimnasio</span
            >
          </v-card-title>
        </v-toolbar>
        <v-container>
          <v-alert
            text
            outlined
            color="deep-orange"
            icon="priority_high"
            :value="existe"
          >
            El cliente {{ data.idcontacto.idcontacto.desccontacto }} ya posee
            una planilla activa. Si completa este registro, se anulará la
            planilla anterior.
          </v-alert>
          <v-row row dense>
            <v-col cols="12" md="6" sm="6">
              <vue-autocomplete-contacto-cliente
                label="Cliente"
                id="gymplanilla0"
                v-model="data.idcontacto.idcontacto"
                @change="funcion_cambio"
                @keyup.native.enter="next(0, data.idcontacto.idcontacto)"
              />
            </v-col>
            <v-col cols="12" md="3" sm="3">
              <autocomplete-unidadmedida
                id="gymplanilla1"
                label="Unidad"
                outlined
                :idmagnitud="6"
                v-model="data.idunidadmedida"
                dense
                @keyup.native.enter="next(1, data.idunidadmedida)"
              />
            </v-col>
            <v-col cols="12" md="3" sm="3">
              <c-text-date
                id="gymplanilla2"
                dense
                outlined
                :readonly="false"
                label="Fecha de inscripción"
                v-model="data.fechainscripcion"
                @keyup.native.enter="
                  (panels = [0]), next(2, data.fechainscripcion)
                "
              ></c-text-date>
            </v-col>
            <v-expansion-panels accordion focusable multiple v-model="panels">
              <v-expansion-panel>
                <v-expansion-panel-header> Ejercicios </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row class="mt-2">
                    <v-col cols="5" md="5" sm="5">
                      <vue-autocomplete-generic2
                        id="gymplanilla3"
                        action="gymtipo/Vact_gymtipo_data"
                        getter="gymtipo/Vget_gymtipo_data"
                        load="gymtipo/Vget_gymtipo_load"
                        :readonly="false"
                        pk="idgymtipo"
                        return-object
                        label="Seleccione Tipo"
                        title="descripcion"
                        permission="Tipo Gimnasio"
                        v-model="detalle.idgymtipo"
                        :create="true"
                        @create="formularios.gymtipo = $event"
                        @change="
                          detalle.monto = detalle.idgymtipo.iditem.precitem
                        "
                        @keyup.native.enter="next(3, detalle.idgymtipo)"
                      />
                    </v-col>
                    <v-col cols="5" md="5" sm="5">
                      <c-text-currency
                        label="Monto"
                        dense
                        outlined
                        v-model="detalle.monto"
                        id="gymplanilla4"
                        @keyup.native.enter="next(4, detalle.monto)"
                      ></c-text-currency>
                    </v-col>
                    <v-col cols="2" md="2" sm="2">
                      <v-btn
                        id="gymplanilla5"
                        class="mx-2"
                        fab
                        dark
                        small
                        color="blue"
                        @click="adddetalle(), next(2, true)"
                      >
                        <v-icon dark> add </v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="12" md="12" sm="12">
                      <v-data-table
                        :headers="headers"
                        :items="data.detalle"
                        hide-default-footer
                        class="elevation-1"
                      >
                        <template v-slot:[`item.accion`]="{ item }">
                          <c-btn-table-edit
                            @click="editar(item)"
                          ></c-btn-table-edit>
                          <c-btn-table-delete
                            @click="eliminar(item)"
                          ></c-btn-table-delete>
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header> Lesiones </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-col cols="12" md="12" sm="12">
                    <v-checkbox
                      id="gymplanilla6"
                      v-model="data.lesionosea"
                      class="shrink mr-2 mt-0"
                      :label="`Ha tenido o tiene alguna lesión osea? ${
                        data.lesionosea == true ? 'Si' : 'No'
                      }`"
                      @keyup.native.enter="
                        next(data.lesionosea == true ? 6 : 7, data.lesionosea)
                      "
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="12" sm="12">
                    <c-text-field
                      id="gymplanilla7"
                      label="Lesion Osea"
                      placeholder="Especifique la lesión osea"
                      :rules="[
                        (data.lesionosea && data.desclesionosea != '') ||
                          !data.lesionosea ||
                          'Este campo es requerido',
                      ]"
                      outlined
                      dense
                      :readonly="!data.lesionosea"
                      v-model="data.desclesionosea"
                      @keyup.native.enter="next(7, data.desclesionosea)"
                    ></c-text-field>
                  </v-col>
                  <v-col cols="12" md="12" sm="12">
                    <v-checkbox
                      id="gymplanilla8"
                      v-model="data.lesionmuscular"
                      class="shrink mr-2 mt-0"
                      :label="`Ha tenido o tiene alguna lesión muscular? ${
                        data.lesionmuscular == true ? 'Si' : 'No'
                      }`"
                      @keyup.native.enter="
                        data.lesionmuscular == false
                          ? (panels = [1])
                          : (panels = [0]),
                          next(
                            data.lesionmuscular == true ? 8 : 9,
                            data.lesionmuscular
                          )
                      "
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="12" sm="12">
                    <c-text-field
                      id="gymplanilla9"
                      label="Lesion Muscular"
                      placeholder="Especifique la lesión muscular"
                      :rules="[
                        (data.lesionmuscular &&
                          data.desclesionmuscular != '') ||
                          !data.lesionmuscular ||
                          'Este campo es requerido',
                      ]"
                      outlined
                      dense
                      :readonly="!data.lesionmuscular"
                      v-model="data.desclesionmuscular"
                      @keyup.native.enter="
                        (panels = [1]), next(9, data.desclesionmuscular)
                      "
                    ></c-text-field>
                  </v-col>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Enfermedades
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-col cols="12" md="12" sm="12">
                    <v-checkbox
                      id="gymplanilla10"
                      v-model="data.enfcardio"
                      class="shrink mr-2 mt-0"
                      :label="`Padece alguna enfermedad cardiovascular? ${
                        data.enfcardio == true ? 'Si' : 'No'
                      }`"
                      @keyup.native.enter="
                        next(data.enfcardio == true ? 10 : 11, data.enfcardio)
                      "
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="12" sm="12">
                    <c-text-field
                      id="gymplanilla11"
                      label="Enfermedad cardio vascular"
                      placeholder="Especifique la enfermedad"
                      :rules="[
                        (data.enfcardio && data.descenfcardio != '') ||
                          !data.enfcardio ||
                          'Este campo es requerido',
                      ]"
                      outlined
                      dense
                      :readonly="!data.enfcardio"
                      v-model="data.descenfcardio"
                      @keyup.native.enter="next(11, data.descenfcardio)"
                    ></c-text-field>
                  </v-col>
                  <v-col cols="12" md="12" sm="12">
                    <v-checkbox
                      id="gymplanilla12"
                      v-model="data.asfixia"
                      class="shrink mr-2 mt-0"
                      :label="`Se asfixia con facilidad al realizar ejercicio físico? ${
                        data.asfixia == true ? 'Si' : 'No'
                      }`"
                      @keyup.native.enter="next(12, data.asfixia)"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="12" sm="12">
                    <v-checkbox
                      id="gymplanilla13"
                      v-model="data.anemia"
                      class="shrink mr-2 mt-0"
                      :label="`Padece de anemia en la actualidad? ${
                        data.anemia == true ? 'Si' : 'No'
                      }`"
                      @keyup.native.enter="next(13, data.anemia)"
                    ></v-checkbox>
                  </v-col>
                  <v-card-actions class="ml-2">
                    <div class="title">Es usted</div>
                    <v-divider></v-divider>
                  </v-card-actions>
                  <v-row>
                    <v-col cols="12" md="3" sm="3">
                      <v-checkbox
                        id="gymplanilla14"
                        v-model="data.esusted.asmatico"
                        class="shrink mr-2 mt-0"
                        :label="`Asmático/a ${
                          data.esusted.asmatico == true ? 'Si' : 'No'
                        }`"
                        @keyup.native.enter="next(14, data.esusted.asmatico)"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="3" md="3" sm="3">
                      <v-checkbox
                        id="gymplanilla15"
                        v-model="data.esusted.epileptico"
                        class="shrink mr-2 mt-0"
                        :label="`Epiléptico/a ${
                          data.esusted.epileptico == true ? 'Si' : 'No'
                        }`"
                        @keyup.native.enter="next(15, data.esusted.epileptico)"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="3" md="3" sm="3">
                      <v-checkbox
                        id="gymplanilla16"
                        v-model="data.esusted.diabetico"
                        class="shrink mr-2 mt-0"
                        :label="`Diabético/a ${
                          data.esusted.diabetico == true ? 'Si' : 'No'
                        }`"
                        @keyup.native.enter="next(16, data.esusted.diabetico)"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="3" md="3" sm="3">
                      <v-checkbox
                        id="gymplanilla17"
                        v-model="data.esusted.fumador"
                        class="shrink mr-2 mt-0"
                        :label="`Fumador/a ${
                          data.esusted.fumador == true ? 'Si' : 'No'
                        }`"
                        @keyup.native.enter="next(17, data.esusted.fumador)"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-card-actions class="ml-2">
                    <div class="title">Síntomas al realizar esfuerzo</div>
                    <v-divider></v-divider>
                  </v-card-actions>
                  <v-row>
                    <v-col cols="12" md="3" sm="3">
                      <v-checkbox
                        id="gymplanilla18"
                        v-model="data.sintomasesfuerzo.mareos"
                        class="shrink mr-2 mt-0"
                        :label="`Mareos ${
                          data.sintomasesfuerzo.mareos == true ? 'Si' : 'No'
                        }`"
                        @keyup.native.enter="
                          next(18, data.sintomasesfuerzo.mareos)
                        "
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="3" md="3" sm="3">
                      <v-checkbox
                        id="gymplanilla19"
                        v-model="data.sintomasesfuerzo.nauseas"
                        class="shrink mr-2 mt-0"
                        :label="`Náuseas ${
                          data.sintomasesfuerzo.nauseas == true ? 'Si' : 'No'
                        }`"
                        @keyup.native.enter="
                          next(19, data.sintomasesfuerzo.nauseas)
                        "
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="3" md="3" sm="3">
                      <v-checkbox
                        id="gymplanilla20"
                        v-model="data.sintomasesfuerzo.desmayos_lipotimia"
                        class="shrink mr-2 mt-0"
                        :label="`Desmayos o Lipotimia ${
                          data.sintomasesfuerzo.desmayos_lipotimia == true
                            ? 'Si'
                            : 'No'
                        }`"
                        @keyup.native.enter="
                          next(20, data.sintomasesfuerzo.desmayos_lipotimia)
                        "
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="3" md="3" sm="3">
                      <v-checkbox
                        id="gymplanilla21"
                        v-model="
                          data.sintomasesfuerzo.dificultades_respiratorias
                        "
                        class="shrink mr-2 mt-0"
                        :label="`Dificultades respiratorias ${
                          data.sintomasesfuerzo.dificultades_respiratorias ==
                          true
                            ? 'Si'
                            : 'No'
                        }`"
                        @keyup.native.enter="
                          (panels = [2]),
                            next(
                              21,
                              data.sintomasesfuerzo.dificultades_respiratorias
                            )
                        "
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Actividades
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-col cols="12" md="12" sm="12">
                    <v-checkbox
                      id="gymplanilla22"
                      v-model="data.actividaddeportiva"
                      class="shrink mr-2 mt-0"
                      :label="`Realiza alguna actividad deportiva? ${
                        data.actividaddeportiva == true ? 'Si' : 'No'
                      }`"
                      @keyup.native.enter="next(22, data.actividaddeportiva)"
                    ></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="12" sm="12">
                    <v-checkbox
                      id="gymplanilla23"
                      v-model="data.gimnasio"
                      class="shrink mr-2 mt-0"
                      :label="`Asistía a un gimnasio? ${
                        data.gimnasio == true ? 'Si' : 'No'
                      }`"
                      @keyup.native.enter="
                        (panels = []), next(23, data.gimnasio)
                      "
                    ></v-checkbox>
                  </v-col>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="cancelar">
            <v-icon dark>close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn id="gymplanilla24" color="blue darken-1" text @click="guardar">
            <v-icon dark>done</v-icon>
          </v-btn>
        </v-card-actions>
        <c-overlay :value="Vget_gymplanilla_save_load"></c-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { mensaje } from "@/services/notify.service";
import { focus } from "@/services/focus.service";
import AutocompleteUnidad from "@/views/Item/UnidadMedida/autocomplete";
import GymTipo from "@/views/Gym/Mantenimiento/Gymtipo/Create";
export default {
  components: {
    "autocomplete-unidadmedida": AutocompleteUnidad,
    GymTipo,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    "sync-input": {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Tipo",
          align: "left",
          value: "idgymtipo.descripcion",
        },
        {
          text: "Monto",
          align: "left",
          value: "monto",
        },
        {
          text: "Accion",
          align: "right",
          value: "accion",
          sortable: false,
        },
      ],
      data: {
        descripcion: "",
        fechainscripcion: "",
        idunidadmedida: {
          idunidadmedida: "",
        },
        idcontacto: {
          idcontacto: "",
        },
        lesionosea: false,
        desclesionosea: "",
        lesionmuscular: false,
        desclesionmuscular: "",
        enfcardio: false,
        descenfcardio: "",
        asfixia: false,
        anemia: false,
        esusted: {
          asmatico: false,
          epileptico: false,
          diabetico: false,
          fumador: false,
        },
        sintomasesfuerzo: {
          mareos: false,
          nauseas: false,
          desmayos_lipotimia: false,
          dificultades_respiratorias: false,
        },
        actividaddeportiva: false,
        gimnasio: false,
        estado: false,
        detalle: [],
      },
      default: {
        descripcion: "",
        fechainscripcion: "",
        idunidadmedida: {
          idunidadmedida: "",
        },
        idcontacto: {
          idcontacto: "",
        },
        lesionosea: false,
        desclesionosea: "",
        lesionmuscular: false,
        desclesionmuscular: "",
        enfcardio: false,
        descenfcardio: "",
        asfixia: false,
        anemia: false,
        esusted: {
          asmatico: false,
          epileptico: false,
          diabetico: false,
          fumador: false,
        },
        sintomasesfuerzo: {
          mareos: false,
          nauseas: false,
          desmayos_lipotimia: false,
          dificultades_respiratorias: false,
        },
        actividaddeportiva: false,
        gimnasio: false,
        detalle: [],
      },
      formularios: {
        gymtipo: false,
      },
      detalle: {
        idgymtipo: {},
        monto: 0,
      },
      operacion: true,
      existe: false,
      panels: [],
    };
  },
  watch: {
    $route() {
      return this.cancelar();
    },
  },
  computed: {
    ...mapGetters("gymplanilla", [
      "Vget_gymplanilla_update",
      "Vget_gymplanilla_save_load",
    ]),
  },
  created() {
    this.nuevo(this.Vget_gymplanilla_update);
  },
  mounted() {
    setTimeout(() => document.getElementById("gymplanilla0").focus(), 500);
    if (this.syncInput != null) this.data.descripcion = this.syncInput;
  },
  methods: {
    ...mapActions("gymplanilla", [
      "gymplanilla_update",
      "Vact_gymplanilla_save",
      "Vact_gymplanilla_update",
      "Vact_gymplanilla_data",
      "Vact_gymplanilla_data_by",
    ]),
    ...mapGetters("gymplanilla", ["Vget_gymplanilla_data_by"]),
    next(index, data) {
      if (data === false) data = true;
      if (index === 4 && data != "") setTimeout(500);
      if (index === 9 && data != "") setTimeout(500);
      focus.nextidName("gymplanilla", index, data);
    },
    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.gymplanilla_update(null);
      this.Vact_gymplanilla_data();
      this.operacion = true;
      this.$emit("input", false);
    },
    async guardar_finalizar() {
      const sync = this.syncInput;
      let a = await this.Vact_gymplanilla_save(this.data);
      if (a.estado == true) {
        if (sync != null) {
          this.$emit("sync-input", a.data.data.id);
          return this.cancelar();
        }
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$notify(a.info);
        //this.$refs.form.resetValidation();
        setTimeout(() => document.getElementById("gymplanilla0").focus(), 500);
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
    },

    async editar_finalizar() {
      const id = this.Vget_gymplanilla_update.idgymplanilla;
      let a = await this.Vact_gymplanilla_update({ id, data: this.data });
      if (a.estado == true) {
        this.$notify(a.info);
        this.cancelar();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
    },
    guardar() {
      //if (!this.$refs.form.validate()) return this.$notify(mensaje.completar());
      if (this.operacion === true) return this.guardar_finalizar();
      if (this.operacion === false) return this.editar_finalizar();
    },
    nuevo(val) {
      if (val != null) {
        this.data = JSON.parse(JSON.stringify(val));
        this.operacion = false;
      }
    },
    async funcion_cambio() {
      let idcontacto = this.data.idcontacto.idcontacto.idcontacto;
      let registry = await this.Vact_gymplanilla_data_by({
        id: idcontacto,
      });

      if (registry == true) {
        this.existe = true;
      } else {
        this.existe = false;
      }
    },
    verificar_duplicado() {
      let temp = this.data.detalle.find(
        (x) => x.idgymtipo.idgymtipo == this.detalle.idgymtipo.idgymtipo
      );
      return temp ? false : true;
    },
    adddetalle() {
      let detalle = this.detalle;
      if (!this.verificar_duplicado()) return null;
      this.data.detalle.push({ ...detalle });
    },
    editar(item) {
      this.eliminar(item);
      this.detalle = item;
    },
    eliminar(item) {
      this.index = this.data.detalle.indexOf(item);
      this.data.detalle.splice(this.index)
    },
  },
};
</script>