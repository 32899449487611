<template>
  <v-dialog persistent max-width="700" :value="value">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-form ref="form" @submit.prevent="">
          <v-row dense>
            <v-col cols="6">
              <v-autocomplete
                dense
                v-model="data.idmaquinaria"
                ref="maquinaria1"
                v-bind:items="getMaquinaria"
                label="Maquinaria"
                item-text="descripcion"
                item-value="idmaquinaria"
                return-object
                :rules="[
                  (v) =>
                    !!v.idmaquinaria || 'Seleccione una Maquinaria',
                ]"
                required
                outlined
                autocomplete="off"
                @keyup.native.enter="next(1, data.idmaquinaria.idmaquinaria)"
                :no-data-text="`No existe ninguna concidencia`"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                dense
                v-model="data.idmaquinaria_mantenimiento_tipo"
                ref="maquinaria2"
                v-bind:items="getMaquinariaMantenimientoTipo"
                label="Tipo Mantenimiento"
                item-text="descripcion"
                item-value="idmaquinaria_mantenimiento"
                return-object
                :rules="[
                  (v) =>
                    !!v.idmaquinaria_mantenimiento_tipo || 'Seleccione un Tipo de Mantenimiento',
                ]"
                required
                outlined
                autocomplete="off"
                @keyup.native.enter="
                  next(2, data.idmaquinaria_mantenimiento_tipo.idmaquinaria_mantenimiento_tipo)
                "
                :no-data-text="`No existe ninguna concidencia`"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <c-text-date
                autocomplete="off"
                label="Fecha Inicio"
                :rules="rules"
                outlined
                dense
                ref="maquinaria3"
                v-model="data.fecha"
                placeholder="Ingrese la fecha de inicio"
                @keyup.native.enter="next(3, data.fecha)"
              ></c-text-date>
            </v-col>
            <v-col cols="6">
              <c-text-date
                autocomplete="off"
                label="Fecha Fin"
                :rules="rules"
                outlined
                dense
                ref="maquinaria4"
                v-model="data.fecha_fin"
                placeholder="Ingrese la fecha de fin"
                @keyup.native.enter="next(4, data.fecha_fin)"
              ></c-text-date>
            </v-col>
            <v-col cols="12">
              <v-textarea
                label="Observacion"
                outlined
                ref="maquinaria5"
                v-model="data.observacion"
                @keyup.native.enter="next(5, data.observacion)"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <c-btn-crud-close @click="cancel()"></c-btn-crud-close>
        <v-spacer></v-spacer>
        <c-btn-crud-done ref="maquinaria6" @click="save()"></c-btn-crud-done>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
    },
    "sync-input": {
      type: [String, Number],
      default: null,
    },
  },
  mounted() {
    if (this.editable) this.data = JSON.parse(JSON.stringify(this.props));
    setTimeout(() => this.next(0, "-"), 200);
    if (this.syncInput != null) this.data.descripcion = this.syncInput;
    this.sync = this.syncInput;
  },
  created() {
    this.fetchMaquinariaMantenimientoTipo();
    this.fetchMaquinaria();
  },
  computed: {
    ...mapGetters("maquinaria", ["isLoadingMaquinaria", "getMaquinaria"]),
    ...mapGetters("maquinaria_mantenimiento_tipo", ["isLoadingMantenimientoTipo", "getMaquinariaMantenimientoTipo"]),
    title() {
      return this.editable ? "Modificar Mantenimiento de Maquinaria" : "Registrar Mantenimiento de Maquinaria";
    },
  },
  methods: {
    ...mapActions("maquinaria_mantenimiento_tipo", [
      "fetchMaquinariaMantenimientoTipo",
    ]),
    ...mapActions("maquinaria", ["fetchMaquinaria"]),
    ...mapActions("maquinaria_mantenimiento", [
      "fetchMaquinariaMantenimiento",
      "setMaquinariaMantenimiento",
      "setMaquinariaMantenimientoUpdate",
    ]),
    next(id, data) {
      if (data != "")
        try {
          this.$refs[`maquinaria${id + 1}`].focus();
        } catch (error) {
          this.$refs[`maquinaria${id + 1}`].$el.focus();
        }
    },
    cancel() {
      this.$emit("input", false);
    },
    async save() {
      if (!this.$refs.form.validate()) return null;
      const response = this.editable
        ? await this.setMaquinariaMantenimientoUpdate({
            data: this.data,
            id: this.data.idmaquinaria_mantenimiento,
          })
        : await this.setMaquinariaMantenimiento(this.data);
      if (response.success) {
        if (this.sync != null) {
          this.cancel();
          this.fetchMaquinariaMantenimiento();
          return this.$emit(
            "sync-input",
            response.data.idmaquinaria_mantenimiento
          );
        }
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();
        this.fetchMaquinariaMantenimiento();
        this.next(0, "-");
        if (this.editable) this.cancel();
      }
    },
  },
  data: () => ({
    rules: [(v) => !!v || "Este campo es obligatorio"],
    data: {
      idmaquinaria_mantenimiento: 0,
      idmaquinaria_mantenimiento_tipo: {
        idmaquinaria_mantenimiento_tipo: "",
      },
      idmaquinaria: {
        idmaquinaria: "",
      },
      fecha: "",
      fecha_fin: "",
      observacion: "",
    },
    default: {
      idmaquinaria_mantenimiento: 0,
      idmaquinaria_mantenimiento_tipo: {
        idmaquinaria_mantenimiento_tipo: "",
      },
      idmaquinaria: {
        idmaquinaria: "",
      },
      fecha: "",
      fecha_fin: "",
      observacion: "",
    },
    sync: null,
  }),
};
</script>