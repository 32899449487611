<template>
    <div>
        <v-alert type="error" v-if="permission_alert">
            Error! Este usuario no tiene permiso en esta ventana
        </v-alert>
        <v-toolbar flat dense>
            <v-spacer></v-spacer>
            <c-text-table-search v-if="permission.can_view" v-model="search"></c-text-table-search>
            <v-toolbar-items>
                <c-btn-table-reload class="ml-1" @click="fetchTipo()"></c-btn-table-reload>
            </v-toolbar-items>
        </v-toolbar>
        <v-data-table :headers="headers" :items="getTipo" :loading="isLoadingTipo" :search="search"
            v-if="permission.can_view">
        </v-data-table>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { FINANZA } from "@/constants/forms";
import {
    PUEDE_AGREGAR,
    PUEDE_EDITAR,
    PUEDE_ELIMINAR,
    PUEDE_LISTAR,
} from "@/constants/permissions";
export default {
    data() {
        return {
            title: 'Tipo',
            search: "",
            headers: [
                { text: "Codigo", align: "left", value: "idfinanza_tipo" },
                { text: "Descripcion", align: "left", value: "nombre" },
            ],
            permission: {
                can_add: false,
                can_view: false,
                can_update: false,
                can_delete: false,
            },
            crud: {
                delete: false,
                add: false,
                view: false,
                datos: null,
            },
        }
    },
    computed: {
        ...mapGetters("finanza", ["getTipo", "isLoadingTipo"]),
        permission_alert() {
            return mensaje.permission(this.permission);
        },
    },
    created() {
        this.$store.commit("SET_LAYOUT", "principal-layout");
        const permission = getPermission(FINANZA).permiso;
        this.permission = {
            can_add: permission[PUEDE_AGREGAR],
            can_view: permission[PUEDE_LISTAR],
            can_update: permission[PUEDE_EDITAR],
            can_delete: permission[PUEDE_ELIMINAR],
        };
        this.fetchTipo();
    },
    methods: {
        ...mapActions("finanza", ["fetchTipo"]),
    }
}
</script>