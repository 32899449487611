<template>
  <div>
    <v-card>
      <c-alert-permission v-model="permission"></c-alert-permission>
      <v-toolbar flat dense>
        <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
        <widgets-Favorite :ruta="$route.path" :formulario="$route.name" />
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>

        <c-text-table-search
          v-if="permission.can_view"
          v-model="search"
        ></c-text-table-search>
        <v-toolbar-items>
          <c-btn-table-add
            @click="create()"
            v-if="permission.can_add"
            class="mr-1"
          ></c-btn-table-add>
          <c-btn-table-reload @click="fetchCanje()"> </c-btn-table-reload>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table
        :loading="isLoading"
        :headers="headers"
        :items="getCanje"
        :search="search"
        v-if="permission.can_view"
      >
        <template v-slot:[`item.fecha`]="{ item }">
          <div>{{ formatDate(item.fecha) }}</div>
        </template>
        <template v-slot:[`item.accion`]="{ item }">
          <c-btn-table-view
            class="mr-2"
            @click="rowselect(item)"
          ></c-btn-table-view>
          <c-btn-table-edit
            class="mr-2"
            @click="mupdate(item)"
          ></c-btn-table-edit>
          <c-btn-table-delete @click="meliminar(item)"></c-btn-table-delete>
        </template>
      </v-data-table>
    </v-card>
    <Crud
      v-if="crud.add"
      v-model="crud.add"
      :readonly="crud.readonly"
      :datos="crud.datos"
      @datos="crud.datos = $event"
    />
    <Delete v-model="crud.delete" v-if="crud.delete" :props="crud.datos" @datos="crud.datos = $event" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { CANJE_GRANO } from "@/constants/forms";
import { dmy } from "@/services/util/date.service";
import Crud from "./Create";
import Delete from "./Delete";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";
export default {
  components: {
    Crud,
    Delete
  },
  data: () => ({
    search: "",
    headers: [
      { text: "Fecha", align: "left", value: "fecha" },
      {
        text: "Tipo Grano",
        align: "left",
        value: "iditem.descitem",
      },
      {
        text: "Campaña Origen",
        align: "left",
        value: "idcampanaorigen.descripcion",
      },
      {
        text: "Campaña Destino",
        align: "left",
        value: "idcampanadestino.descripcion",
      },
      { text: "Comentario", align: "left", value: "comentario" },
      { text: "Accion", value: "accion", sortable: false, align: "right" },
    ],
    permission: {
      can_add: false,
      can_view: false,
      can_update: false,
      can_delete: false,
    },
    crud: {
      add: false,
      view: false,
      readonly: false,
      datos: null,
      delete: false,
    },
  }),

  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.fetchCanje();
    const permission = getPermission(CANJE_GRANO).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  computed: {
    ...mapGetters("canje", ["getCanje", "isLoading"]),
  },

  methods: {
    ...mapActions("canje", ["fetchCanje"]),
    formatDate: (date) => dmy(date),
    meliminar(item) {
      this.crud.datos = JSON.parse(JSON.stringify(item));
      this.crud.delete = true;
    },
    rowselect(val) {
      this.crud.datos = JSON.parse(JSON.stringify(val));
      this.crud.readonly = true;
      this.crud.add = true;
    },

    mupdate(val) {
      this.crud.readonly = false;
      this.crud.datos = JSON.parse(JSON.stringify(val));
      this.crud.add = true;
    },
    create(){
      this.crud.readonly = false;
      this.crud.add = true;
    }
  },
};
</script>
