var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.crud.add)?_c('Crud',{attrs:{"datos":_vm.crud.datos},on:{"datos":function($event){_vm.crud.datos = $event}},model:{value:(_vm.crud.add),callback:function ($$v) {_vm.$set(_vm.crud, "add", $$v)},expression:"crud.add"}}):_vm._e(),(_vm.crud.view)?_c('Crud',{attrs:{"props":_vm.crud.datos,"editable":""},on:{"datos":function($event){_vm.crud.datos = $event}},model:{value:(_vm.crud.view),callback:function ($$v) {_vm.$set(_vm.crud, "view", $$v)},expression:"crud.view"}}):_vm._e(),(_vm.permission_alert)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" Error!!! Este usuario no tiene permisos en esta ventana ")]):_vm._e(),_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$route.name))]),_c('widgets-Favorite',{attrs:{"ruta":_vm.$route.path,"formulario":_vm.$route.name}}),_c('v-divider',{staticClass:"mx-2",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),(_vm.permission.can_view)?_c('c-text-table-search',{model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e(),_c('v-toolbar-items',[(_vm.permission.can_add)?_c('c-btn-table-add',{on:{"click":function($event){_vm.crud.add = true}}}):_vm._e(),_c('c-btn-table-reload',{staticClass:"ml-1",on:{"click":function($event){return _vm.fetchMaquinariaSalario()}}})],1)],1),(_vm.permission.can_view)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.getMaquinariaSalario,"loading":_vm.isLoadingMaquinariaSalario,"search":_vm.search},scopedSlots:_vm._u([{key:"item.favor",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.toCurrency(item.favor)))])]}},{key:"item.viaticos",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.toCurrency(item.viaticos)))])]}},{key:"item.reembolso",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.toCurrency(item.reembolso)))])]}},{key:"item.monto",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.toCurrency(item.monto)))])]}},{key:"item.accion",fn:function(props){return [_c('c-btn-table-edit',{on:{"click":function($event){return _vm.update_item(props.item)}}}),_c('c-btn-table-delete',{on:{"click":function($event){return _vm.delete_item(props.item)}}})]}}],null,true)}):_vm._e(),(_vm.crud.delete)?_c('Delete',{attrs:{"props":_vm.crud.datos},on:{"datos":function($event){_vm.crud.datos = $event}},model:{value:(_vm.crud.delete),callback:function ($$v) {_vm.$set(_vm.crud, "delete", $$v)},expression:"crud.delete"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }