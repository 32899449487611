<template>
  <div>

    <v-dialog
      :value="value"
      max-width="700px"
      persistent
      @keydown.esc="cancelar()"
    >
      <v-card>
        <v-toolbar flat dense>
          <v-toolbar-title>{{
            operacion ? "Registrar Enfermedad" : "Modificar Enfermedad"
          }}</v-toolbar-title>
        </v-toolbar>
        <v-form ref="form" lazy-validation>
          <v-card-text>
            <Enfermedad-Enfermedad-CreateRef
              ref="enfermedadForm"
              :idanimal="idanimal"
              :props="props"
            />
          </v-card-text>
          <v-card-actions>
            <c-btn-crud-close @click="cancelar()"></c-btn-crud-close>
            <v-spacer></v-spacer>
            <c-btn-crud-done id="aceptar" @click="guardar()"></c-btn-crud-done>
          </v-card-actions>
        </v-form>
      </v-card>
      <c-overlay :value="isLoading"></c-overlay>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    value: Boolean,
    props: Object,
    idanimal: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      data: {},
      operacion: true,
    };
  },
  watch: {
    $route() {
      return this.cancelar();
    },
  },
  computed: {

    ...mapGetters("enfermedad", ["isLoading"]),
  },

  created() {
    if(this.props) this.operacion = false;
  },
  methods: {
    ...mapActions("enfermedad", ["setEnfermedad",
      "setEnfermedadUpdate",
      "fetchNuevo",
    ]),

    cancelar() {
      this.data = {};
      this.$emit("input", false);
      this.$emit("datos",null);
      this.fetchNuevo();
      this.operacion = false;
    },
    async guardar_finalizar() {
      const response = await this.setEnfermedad(this.data);
      if (response.success) {
        this.data = {};
        if(this.idanimal){
          this.$emit('sync-input');
          this.cancelar();
        }
      }
    },
    async editar_finalizar() {
      const id = this.props.idenfermedad;
      const response = await this.setEnfermedadUpdate({ id, data: this.data });
      if (response.success) {
        this.cancelar();
      }
    },
    async guardar() {
      if (!this.$refs.enfermedadForm.validate()) return null;
        this.data = JSON.parse(
            JSON.stringify(await this.$refs.enfermedadForm.obtenerDatos())
          );
      if (this.operacion === true) return this.guardar_finalizar();
      if (this.operacion === false) return this.editar_finalizar();
    }
  },
};
</script>
