import {SET_LOADING, SET_TRANSFERENCIA, SET_TRANSFERENCIA_BYID} from './constants'
export default {
  [SET_TRANSFERENCIA](state, request) {
    state.transferencia_data = request;
  },
  [SET_LOADING](state, request) {
    state.transferencia_load = request;
  },
  [SET_TRANSFERENCIA_BYID](state,request){
    state.transferencia_byId = request
  },
}