<template>
  <div class="mt-2">
    <v-row dense v-for="(item, i) in detalle" :key="i">
      <v-col cols="7">
        <vue-text-field
          id="itemInput15"
          ref="itemInput15"
          label="Composicion"
          v-model="detalle[i].descripcion"
          @keyup.native.enter="next(15,'-'), $refs.itemInput15[0].$children[0].isMenuActive = false"
        ></vue-text-field>
      </v-col>
      <v-col cols="3">
        <vue-text-field
          label="Porcentaje"
          id="itemInput16"
          v-model="detalle[i].porcentaje"
          :rules="false"
          suffix="%"
          @keyup.native="updateProps()"
          @keyup.native.enter="next(16,'-')"
        ></vue-text-field>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="1" v-if="legthDetalle != 1">
        <v-btn color="red" text @click="deleteDetalle(item)">
          <v-icon>clear</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="1" v-if="legthDetalle === i+1">
        <v-btn color="primary" text @click="addDetalle()">
          <v-icon>add</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { focus } from "@/services/focus.service";

export default {
  props: {
    value: {
      type: [Object, Array],
      required: true
    }
  },
  data() {
    return {
      syncInput: null,
      detalle: []
    };
  },
  watch: {
    value(val) {
      this.detalle = JSON.parse(JSON.stringify(val));
    }
  },
  computed: {
    legthDetalle() {
      return this.detalle.length;
    }
  },
  methods: {
    next(index, data) {
      focus.nextidName("itemInput", index, data);
    },
    addDetalle() {
      const itemDetalle = {
        iditem: 0,
        descripcion: "",
        porcentaje: ""
      };
      this.detalle.push(itemDetalle);
      this.updateProps();
    },
    deleteDetalle(item) {
      const index = this.detalle.indexOf(item);
      this.detalle.splice(index, 1);
      this.updateProps();
    },
    updateProps() {
      this.$emit("input", JSON.parse(JSON.stringify(this.detalle)));
    }
  },
  mounted() {
    this.detalle = JSON.parse(JSON.stringify(this.value));
    if (this.value.length === 0) this.addDetalle();
  }
};
</script>