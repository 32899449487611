// https://vuex.vuejs.org/en/getters.html

export default {
  Vget_densidad_update: (state) => {
    return state.densidad_update;
  },

  Vget_densidad_load: (state) => {
    return state.densidad_load;
  },
  Vget_densidad_data: (state) => {
    return state.densidad_data;
  },
  Vget_densidad_save_load: (state) => {
    return state.densidad_save_load;
  }
}