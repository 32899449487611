<template>
  <v-text-field
    :value="value"
    :id="id"
    @input="$emit('input', $event)"
    @focus="$emit('focus')"
    :rules="rules_active"
    :label="this.label"
    :readonly="readonly"
    :suffix="suffix"
    v-on:focus="$emit('focus')"
    :error-messages="errorMessages"
    :outlined="outlined"
    :messages="messages"
    :persistent-hint="persistentHint"
    :hint="hint"
    dense
    autocomplete="off"
  ></v-text-field>
</template>

<script>
export default {
  props: {
    suffix: String,
    label: String,
    value: [String, Number],
    id: String,
    'persistent-hint':{
      type: Boolean,
      default: false
    },
    messages: {
      type: [Number, String]
    },
    hint: String,
    errorMessages:{
      type: [String, Array,Object]
    },
    rules: {
      type: Boolean,
      default: true
    },
    type:{
      type: String,
      default: 'text'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    outlined:{
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      validar: [v => !!v || "Campo requerido"],
      validarNumber: [
        ()=> !isNaN(this.value) || "Campo numerico",
        v => !!v || "Campo requerido"
      ]
    };
  },
  computed: {
    rules_active() {
      if (this.rules && this.type ==='text') return this.validar;
      if (this.rules && this.type ==='number') return this.validarNumber;
      return [];
    }
  }
};
</script>