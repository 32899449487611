export const LOADING = 'loadingAnimal';
export const FETCH = 'fetchAnimal';
export const FETCH_CELO = 'fetchCelo';
export const FETCH_TORO = 'fetchToro';
export const FETCH_HEMBRA = "fetchHembra";
export const FETCH_DISPONIBLE = 'fetchDisponible';
export const FETCH_TERMO = 'fetchTermo';
export const FETCH_NOMINA = 'fetchNomina';
export const FETCH_PARASECADO = "fetchParaSecado";
export const FETCH_INSEMINADA = "fetchInseminada";
export const FETCH_PRENADA = "fetchPrenada";
export const FETCH_VIVO = 'fetchVivo';
export const FETCH_MUERTO = 'fetchMuerto';
export const FETCH_TODOS = 'fetchTodos';
export const FETCH_TERNERO = 'fetchTernero';
export const FETCH_ENFERMO = 'fetchEnfermo';
export const FETCH_VENTA = 'fetchVenta';
export const FETCH_POSTPARTO = 'fetchPostParto';
export const FETCH_SECADO = "fetchSecado";
export const FETCH_BY_ESTADO = 'fetchByEstado';
export const FETCH_NOMINA_ID = 'fetchNominaAnimalId';
export const FETCH_COUNT = 'fetchCount';
export const FETCH_SEMEN = "fetchSemen";
export const FETCH_FICHA = "fetchFicha";
export const FETCH_VAQUILLA = 'fetchVaquilla';
export const FETCH_REPORTE_AVANZADO = 'fetchReporteAvanzado';
export const FETCH_REPORTE_REPRODUCCION = 'fetchReporteReproduccion';
export const FETCH_PREPARTO = 'fetchPreparto';
export const SET_SOCKET = 'setSockeData';

export const url = {
    animal: '/apianimal/',
    reporteAvanzado: '/apireporteanimal_avanzado/',
    reporteReproduccion: '/apireporteanimal_mes/',
}