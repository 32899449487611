<template>
  <div>
    <Crud
      v-if="crud.add"
      v-model="crud.add"
      :datos="crud.datos"
      @datos="crud.datos = $event"
    />
    <Crud
      v-if="crud.view"
      v-model="crud.view"
      :datos="crud.datos"
      readonly
      @datos="crud.datos = $event"
    />
    <Delete
      v-if="crud.delete"
      v-model="crud.delete"
      :props="crud.datos"
      @datos="crud.datos = $event"
    />
    <v-card>
      <v-alert type="error" v-if="permission_alert"
        >Error!!! Este usuario no tiene permisos en esta ventana</v-alert
      >
      <v-toolbar flat dense>
        <v-toolbar-title>{{ titulo }}</v-toolbar-title>
        <widgets-Favorite :ruta="$route.path" :formulario="titulo" />
        <v-divider class="mx-2" inset vertical></v-divider>

        <v-spacer></v-spacer>

        <c-text-table-search
          v-if="permission.can_view"
          v-model="search"
        ></c-text-table-search>
      </v-toolbar>
      <v-toolbar flat dense>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-tabs v-model="tab" right>
            <v-tab href="#tab-1" @click="Vact_celo_nuevo()">Celo</v-tab>
            <v-tab href="#tab-2" @click="Vact_celo_historico()"
              >Historico</v-tab
            >
          </v-tabs>
          <v-divider class="mx-2" inset vertical></v-divider>

          <c-btn-table-add
            @click="crud.add = true"
            v-if="permission.can_add"
            class="mr-1"
          ></c-btn-table-add>
          <c-btn-table-reload
            @click="tab === 'tab-1' ? Vact_celo_nuevo() : Vact_celo_historico()"
          ></c-btn-table-reload>
        </v-toolbar-items>
      </v-toolbar>
      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1">
          <v-data-table
            :loading="Vget_celo_load"
            :headers="headers"
            :items="Vget_celo_nuevo"
            :search="search"
            class="elevation-1"
            v-if="permission.can_view"
          >
            <template v-slot:[`item.accion`]="props">
              <c-btn-table-view @click="rowselect(props.item)" />
              <c-btn-table-edit @click="mupdate(props.item)" />
              <c-btn-table-delete @click="meliminar(props.item)" />
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item value="tab-2">
          <v-data-table
            :loading="Vget_celo_load"
            :headers="headers_pres"
            :items="Vget_celo_historico"
            :search="search"
            class="elevation-1"
            v-if="permission.can_view"
          >
            <template v-slot:[`item.accion`]="props">
              <c-btn-table-view @click="rowselect(props.item)" />
              <c-btn-table-delete @click="meliminar(props.item)" />
            </template>
            <template v-slot:no-data>
              <v-alert :value="true" color="info" icon="info" outlined
                >No existe ningun registro</v-alert
              >
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import Crud from "./Create";
import Delete from "./Delete";
import { CELO } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";
export default {
  components: {
    Crud,
    Delete,
  },
  data() {
    return {
      tab: null,
      search: "",
      headers: [
        { text: "Animal", align: "left", value: "idanimal.nombanimal" },
        { text: "Rp", align: "left", value: "idanimal.rpanimal" },
        { text: "Fecha", align: "left", value: "fecccelo" },
        { text: "Comentario", align: "left", value: "comncelo" },
        { text: "Accion", value: "accion", sortable: false, align: "right" },
      ],
      headers_pres: [
        { text: "Animal", align: "left", value: "idanimal.nombanimal" },
        { text: "Rp", align: "left", value: "idanimal.rpanimal" },
        { text: "Fecha", align: "left", value: "fecccelo" },
        { text: "Comentario", align: "left", value: "comncelo" },
        { text: "Accion", value: "accion", sortable: false, align: "right" },
      ],
      titulo: CELO,
      crud: {
        delete: false,
        add: false,
        view: false,
        datos: null,
      },
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
    };
  },

  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.Vact_celo_nuevo();
    const permission = getPermission(CELO).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  computed: {
    ...mapGetters("celo", [
      "Vget_celo_load",
      "Vget_celo_nuevo",
      "Vget_celo_historico",
    ]),
    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },

  methods: {
    ...mapActions("celo", ["Vact_celo_nuevo", "Vact_celo_historico"]),
    meliminar(item) {
      this.crud.datos = JSON.parse(JSON.stringify(item));
      this.crud.delete = true;
    },

    rowselect(val) {
      this.crud.datos = JSON.parse(JSON.stringify(this.add_idcelos(val)));
      this.crud.view = true;
    },

    add_idcelos(val) {
      let x = val;
      if (x.idcelos === undefined || x.idcelos.length == 0) {
        return (x = {
          ...x,
          ...{ idcelos: [{ idcelo: "", idcausanoins: { idcausanoins: "" } }] },
          ...{ inseminable: true },
        });
      } else {
        return (x = { ...x, ...{ inseminable: false } });
      }
    },
    mupdate(val) {
      this.crud.datos = JSON.parse(JSON.stringify(this.add_idcelos(val)));
      this.crud.add = true;
    },
  },
};
</script>
