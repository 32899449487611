<template>
  <div>
    
    <v-toolbar flat dense>
      <v-toolbar-title class="title">{{ $route.name }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items v-if="isRoot">
        <c-btn-table-add v-if="permission.can_add" to="/agricultura/campo/plagas/add"></c-btn-table-add>
      </v-toolbar-items>
    </v-toolbar>
    <c-alert-permission v-model="permission"></c-alert-permission>
  </div>
</template>
<script>
import { getPermission } from "@/services/storage/permissionStorage.service";
import { REGISTRO_PLAGA } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";
export default {
  data: () => ({
    permission: {
      can_add: false,
      can_view: false,
      can_update: false,
      can_delete: false,
    },
  }),
  created() {
    const permission = getPermission(REGISTRO_PLAGA).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  computed: {
    isRoot: (vm) => (vm.$route.path === "/agricultura/campo/plagas" ? true : false),
  },
};
</script>
