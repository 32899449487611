import { get } from "@/services/api/api.service";
import { SET_LOADING, SET_LIST_EVENT, SET_REPORTE_LOADING, SET_LIST_REPORTE, url, url2 } from './constants'
export default {
     async fetchList_events({ commit, dispatch }) {
        commit(SET_LOADING, true);
        try {
            const response = await get(url);
            commit(SET_LIST_EVENT, response);
        } catch (e) {
            dispatch('snackbar/setNotification', e, { root: true });
            commit(SET_LOADING, false);
            throw e
        }
        commit(SET_LOADING, false);
    },
    async fetchList_reporte({ commit, dispatch }) {
        commit(SET_REPORTE_LOADING, true);
        try {
            const response = await get(url2);
            commit(SET_LIST_REPORTE, response);
        } catch (e) {
            dispatch('snackbar/setNotification', e, { root: true });
            commit(SET_LOADING, false);
            throw e
        }
    },
}
