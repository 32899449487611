// https://vuex.vuejs.org/en/mutations.html

export default {
  SET_LAYOUT (state, newLayout) {
    state.layout = newLayout
  },
  conexion(state, data) {
    state.conexion = data
  },
}
