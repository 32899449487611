<template>
  <div id="regcampana">
    <v-row dense align="center" justify="center">
      <h4>Registro de Campaña</h4>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>¿Que es una campaña?</v-expansion-panel-header>
          <v-expansion-panel-content>
            Una campaña es un periodo de produccion que inicia desde la siembra finalizando en la cosecha de un determinado tipo de cultivo
            Ejemplo: Soja, Trigo
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>¿Para que sirve una campaña?</v-expansion-panel-header>
          <v-expansion-panel-content>
            Todos los registros (Siembra, Aplicacion, Fumigacion, Egreso y Cosecha)
            se realizan dentro de una campaña.
            Esto es asi para poder separar cada periodo de plantacion una de la otra
            y asi tener un control preciso de los ingresos y egresos de un periodo.
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>¿Cuando se recomienda crear una campaña?</v-expansion-panel-header>
          <v-expansion-panel-content>
            Una vez finalizado el ciclo de siembra y cosecha, se debe crear una nueva Campaña
            para el siguiente ciclo de plantacion.
            <br />Se
            <strong>recomienda</strong> crear una nueva campaña para cada tipo de cultivo. Ejemplo:
            <br />* Campaña SOJA 2020 para Soja
            <br />* Campaña Trigo 2020 para Trigo
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-img class="mt-2" :src="require('@/assets/help/agro/regcampana.png')" max-width="240"></v-img>
      <v-col cols="12">
        <ol style="z-index:3;">
          <li>Campaña seleccionada</li>
          <span>
            <strong>Obs.</strong>Un campo puede pertenecer a varias campañas y se puede separar los
            gastos y ingresos por cada campaña
          </span>
          <li>
            Agregar Nueva Campaña
            <a @click="masAdd = !masAdd">{{masAdd ? 'Menos' : 'Mas'}}</a>
          </li>
          <div v-show="masAdd">
            <v-img
              class="mt-2"
              :src="require('@/assets/help/agro/registrarcampana.png')"
              max-width="270"
            ></v-img>
            <h4>Opciones disponibles</h4>
            <ol>
              <li>
                <strong>(Opcional)</strong>
                Unir el campo actual a una campaña ya existente
                <v-btn
                  text
                  small
                  color="primary"
                  class="text-capitalize"
                  @click="mas = !mas"
                >{{mas ? 'Menos' : 'Mas'}}</v-btn>
              </li>
              <div v-show="mas">
                <span>
                  <strong>Obs.</strong> Esta opcion es util cuando quiere agregar un Campo a una Campaña que ya existente.
                  Ejemplo:
                  <br />*Todos los campos de SOJA en la Campaña SOJA 2020
                  <br />*Todos los campos de TRIGO en la Campaña TRIGO 2020
                </span>
              </div>
              <li>Seleccionar individualmente que CAMPOS entraran en la nueva campaña</li>
              <li>
                <strong>(Opcional)</strong> Seleccionar TODOS o VACIAR los campos seleccionados
              </li>Click en
              <v-icon color="primary">done</v-icon>para guardar
              <br />Click en
              <v-icon color="red">clear</v-icon>para cancelar
              <br />Mision Cumplida!!! :D
            </ol>
          </div>
          <li>Editar Campaña Seleccionada</li>
          <li>Operaciones realizadas</li>
          <div>
            <span>
              <strong>Obs.</strong> Click en la operacion para editar
            </span>
          </div>
          <li>Menu de registro de operaciones</li>
          <ol>
            <v-img
              class="mt-2"
              :src="require('@/assets/help/agro/menuoperaciones.png')"
              max-width="170"
            ></v-img>
            <li>
              Seccion
              <a @click="show = 'Siembra'">Siembra/Aplicacion</a>
            </li>
            <li>
              Seccion
              <a @click="show = 'Egreso'">Egreso</a>
            </li>
            <li>
              Seccion
              <a @click="show = 'Cosecha'">Cosecha</a>
            </li>
          </ol>
        </ol>
      </v-col>
    </v-row>
    <v-progress-linear rounded height="3" value="100"></v-progress-linear>
    <component :is="show"></component>
  </div>
</template>

<script>
export default {
  components: {
    Siembra: () =>
      import(/* webpackPrefetch: true */ "@/views/Help/Agro/Detail/Operacion/Siembra.vue"),
    Egreso: () =>
      import(/* webpackPrefetch: true */ "@/views/Help/Agro/Detail/Operacion/Egreso.vue"),
    Cosecha: () =>
      import(/* webpackPrefetch: true */ "@/views/Help/Agro/Detail/Operacion/Cosecha.vue")
  },
  data() {
    return {
      masAdd: false,
      masOpe: false,
      mas: false,
      show: ""
    };
  }
};
</script>

<style>
</style>