<template>
  <v-dialog persistent max-width="500" :value="value">
    <v-card>
      <v-toolbar flat dense>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-form ref="form" lazy-validation>
          <v-row row dense>
            <v-col cols="12">
              <c-text-field
                label="Descripcion"
                ref="puntoexp1"
                dense
                outlined
                v-model="data.descpuntoexpedicion"
                @keyup.native.enter="next(1, data.descpuntoexpedicion)"
              />
            </v-col>
            <v-col cols="12">
              <c-text-field
                label="Punto Expedicion"
                ref="puntoexp2"
                dense
                outlined
                v-model="data.puntoexpedicion"
                @keyup.native.enter="next(2, data.puntoexpedicion)"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <c-btn-crud-close @click="close()"></c-btn-crud-close>
        <v-spacer></v-spacer>
        <c-btn-crud-done ref="puntoexp3" @click="save()"></c-btn-crud-done>
      </v-card-actions>
      <c-overlay :value="isPuntoExpLoading"></c-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
    },
  },
  data: () => ({
    data: {
      idpuntoexpedicion: 0,
      descpuntoexpedicion: "",
      puntoexpedicion: "",
    },
    default: {
      idpuntoexpedicion: 0,
      descpuntoexpedicion: "",
      puntoexpedicion: "",
    },
  }),
  computed: {
    ...mapGetters("puntoexp", ["isPuntoExpLoading"]),
    title() {
      return this.editable
        ? "Modificar Punto de Expedicion"
        : "Registrar Punto de Expedicion";
    },
  },
  watch: {
    $route(to, from) {
      if (from.path === "/puntoexp") return this.close();
    },
  },
  mounted() {
    if (this.editable) this.data = JSON.parse(JSON.stringify(this.props));
    setTimeout(() => this.next(0, "-"), 20);
  },
  methods: {
    ...mapActions("puntoexp", [
      "setPuntoExp",
      "setPuntoExpUpdate",
      "fetchPuntoExp",
    ]),
    close() {
      this.$emit("input", false);
    },
    next(id, data) {
      if (data != "")
        try {
          this.$refs[`puntoexp${id + 1}`].focus();
        } catch (error) {
          this.$refs[`puntoexp${id + 1}`].$el.focus();
        }
    },
    async save() {
      if (!this.$refs.form.validate()) return null;
      const response = this.editable
        ? await this.setPuntoExpUpdate({
            data: this.data,
            id: this.data.idpuntoexpedicion,
          })
        : await this.setPuntoExp(this.data);
      if (response.success) {
        this.fetchPuntoExp();
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();

        this.next(0, "-");
        if (this.editable) this.close();
      }
    },
  },
};
</script>
