<template>
  <div>
    <v-autocomplete
      :value="value"
      @input="$emit('input', $event)"
      ref="input"
      :items="getParcelaLoteCampana"
      :loading="isParcelaLoading"
      item-value="idparcela"
      :search-input.sync="syncInput"
      :rules="rules"
      :id="id"
      return-object
      :hide-details="hideDetails"
      item-text="descripcion"
      :outlined="outlined"
      :dense="dense"
      :disabled="disabled"
      :readonly="readonly"
      :chips="chips"
      :small-chips="smallChips"
      @focus="$emit('focus')"
      @change="$emit('change')"
      :label="label"
    >
      <template v-if="isPermission && create" v-slot:no-data>
        <v-list-item @click="crud.add = true">
          <v-list-item-content>
            <v-list-item-title
              >No existe concidencia. Click para agregar</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
    <Crud
      v-model="crud.add"
      v-if="crud.add"
      :idlote="idlote"
      :idcampana="idcampana"
      :sync-input="syncInput"
      @sync-input="$emit('input', $event)"
      @next-focus="$emit('next-focus'), fetch()"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import autocompleteProps from "@/services/mixins/autocomplete.mixin";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { ZAFRA } from "@/constants/forms";
import { PUEDE_AGREGAR } from "@/constants/permissions";
import Crud from "./Create";
export default {
  mixins: [autocompleteProps],
  components: {
    Crud,
  },
  props: {
    rules: {
      type: Array,
      default: () => [(v) => !!v.idparcelas || "Este campo es obligatorio"],
    },
    idlote: String,
    idcampana: Object,
  },
  data: () => ({
    syncInput: null,
    crud: {
      add: false,
    },
  }),
  watch: {
    idcampana() {
      this.fetch();
    },
    idlote() {
      this.fetch();
    },
  },
  mounted() {
    this.fetch();
  },
  computed: {
    ...mapGetters("parcela", ["getParcelaLoteCampana", "isParcelaLoading"]),
    isPermission() {
      return getPermission(ZAFRA).permiso[PUEDE_AGREGAR];
    },
  },
  methods: {
    ...mapActions("parcela", ["fetchParcelaByLoteCampana"]),
    fetch() {
      const idlote = this.idlote;
      const idcampana = this.idcampana.idcampana;
      if (idlote && idcampana)
        this.fetchParcelaByLoteCampana({ idlote, idcampana });
    },
    focus() {
      this.$refs.input.focus();
    },
    isMenuActive() {
      this.$refs.input.isMenuActive = false;
    },
  },
};
</script>

<style></style>
