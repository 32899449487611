<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      :clipped="$vuetify.breakpoint.lgAndUp"
      app
      :search="search"
      class="msgs"
      style="z-index: 5"
    >
      <layouts-Navbar :search="search" />
    </v-navigation-drawer>
    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="blue darken-3"
      dark
      style="z-index: 4"
    >
      <v-toolbar-title
        :style="$vuetify.breakpoint.smAndDownwidth ? '100px' : '300px'"
        class="mr-4 pr-3"
        @click="home()"
      >
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <span class="hidden-sm-and-down">Integral Systems</span>
      </v-toolbar-title>
      <v-text-field
        flat
        solo-inverted
        hide-details
        prepend-inner-icon="search"
        label="Busqueda"
        v-model="search"
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-icon color="red" v-if="!conexion">cloud_off</v-icon>
      <!-- SYNCRONIZACION POR PERDIDA DE CONEXION-->
      <widgets-offlinesync />
      <!-- NOTIFICACIONES DEL APP BAR -->
      <widgets-notification />
      <!-- OPCIONES DE INICIO DE SESION -->
      <div class="text-center">
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn icon large v-on="on">
              <v-icon>person</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item
              v-for="(item, index) in menus"
              :key="index"
              @click="cambiarsucursal(item)"
            >
              <v-list-item-avatar>
                <v-icon small>room</v-icon>
              </v-list-item-avatar>
              <v-list-item-title
                >{{ item.idempresa.descempresa }}
                {{ item.idtambo.nombtambo }}</v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="changeDarkMode()">
              <v-list-item-avatar>
                <v-icon small>invert_colors</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>Dark</v-list-item-title>
            </v-list-item>
            <v-list-item @click="salir">
              <v-list-item-avatar>
                <v-icon small>exit_to_app</v-icon>
              </v-list-item-avatar>
              <v-list-item-title>Salir</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-divider class="mx-4 hidden-sm-and-down" inset vertical></v-divider>
      <div class="hidden-sm-and-down">
        <v-card-title>{{ empresa.empresa }}</v-card-title>
        <v-card-subtitle>{{ empresa.tambo }}</v-card-subtitle>
      </div>
    </v-app-bar>
    <v-main>
      <v-col cols="12">
        <transition>
          <keep-alive>
            <router-view></router-view>
          </keep-alive>
        </transition>
      </v-col>
    </v-main>
    <Snackbar />
    <c-overlay :value="overlay" />
  </v-app>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
import {
  savePermission,
  getPermission,
} from "@/services/storage/permissionStorage.service";
import { DarkModeService } from "@/services/auth/storage.service";
import { db } from "@/services/db.service";
import { mensaje } from "@/services/notify.service";
import Snackbar from "@/components/widgets/Snackbar";
export default {
  components: {
    Snackbar,
  },
  data: () => ({
    overlay: false,
    drawer: null,
    permisos: [],
    search: "",
    search_result: [],
    sidebar_concatenado: [],
    menus: [],
    empresa: {
      empresa: "",
      tambo: "",
    },
  }),
  props: {
    source: String,
  },
  mounted() {
    this.obtenerpermisos();
    this.Vact_empresa_active();
    this.$vuetify.theme.dark = DarkModeService.getDarkMode();
    if (this.$route.path === "/agricultura/campo") {
      this.$nextTick(() => (this.drawer = false));
    }
  },
  computed: {
    ...mapGetters("empresa", ["Vget_empresa_active"]),
    ...mapGetters({ conexion: "conexion" }),
  },

  watch: {
    Vget_empresa_active(val) {
      this.obtenersucursales(val);
    },
    $route(to) {
      if (to.path === "/agricultura/campo") return (this.drawer = false);
    },
  },

  methods: {
    ...mapActions("empresa", [
      "Vact_empresa_active",
      "Vact_empresa_active_change",
    ]),
    ...mapActions("auth", ["logout"]),
    home() {
      if (this.$route.path != "/dashboard") this.$router.push("/dashboard");
    },

    salir() {
      this.logout();
    },
    obtenerpermisos() {
      this.permisos = getPermission();
    },
    changeDarkMode() {
      const mode = this.$vuetify.theme.dark ? false : true;
      DarkModeService.saveDarkMode(mode);
      this.$vuetify.theme.dark = DarkModeService.getDarkMode();
    },
    async obtenersucursales(val) {
      this.menus = JSON.parse(JSON.stringify(val));
      this.menus.forEach((x) => {
        if (x.est_emp_tam_usu) {
          this.empresa.empresa = x.idempresa.descempresa;
          this.empresa.tambo = x.idtambo.nombtambo;
        }
      });
    },
    async cambiarsucursal(data) {
      this.overlay = true;
      const id = data.idusuario.id;
      const a = await this.Vact_empresa_active_change({ id, data });
      if (!a.estado) {
        this.overlay = false;
        return a.info.forEach((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.$notify(a.info);
      this.overlay = false;
      const permisos = await db.get("/apigetpermission/");
      savePermission(permisos);
      this.Vact_empresa_active();
      location.reload();
    },
    //Estos metodos verifican sin en cada arbol de niveles  exista formularios con permisos
    //en caso de haber, retorna TRUE y se procede a renderizar en el html
    //Si funciona no lo toques... LITERAL
    mostrar_un_nivel(data) {
      if (data != undefined) {
        return data.find((x) => {
          if (x.sub_nivel_tres != undefined) return true;
          if (x.permission) return true;
        });
      }
    },
    mostrar_tres_y_cuatro_niveles(data) {
      return data.find((sub) => {
        return sub.sub_nivel_dos.find((x) => {
          if (x.sub_nivel_tres != undefined) {
            return x.sub_nivel_tres.find((y) => {
              if (y.permission) return true;
            });
          }
          if (x.permission) return true;
        });
      });
    },
  },
};
</script>

<style>
/* #keep .v-navigation-drawer__border {
  display: none;
} */
.msgs {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  height: 300px;
  overflow: auto;
  overscroll-behavior-y: contain;
}
</style>