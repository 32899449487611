<template>
  <div>
    <div v-if="dialogCotizacion">
      <Finanzas-Cotizacion-CreateV2 v-model="dialogCotizacion" />
    </div>
    <div v-if="crud.add">
      <Crud
        v-model="crud.add"
        :datos="crud.datos"
        :readonly="crud.readonly"
        @datos="crud.datos = $event"
        @readonly="crud.readonly = $event"
      />
    </div>
    <div v-if="crud.bonificacion">
      <Bonificacion
        v-model="crud.bonificacion"
        :datos="crud.datos"
        @datos="crud.datos = $event"
      />
    </div>
    <v-card>
      <v-alert type="error" v-if="permission_alert"
        >Error!!! Este usuario no tiene permisos en esta ventana</v-alert
      >
      <v-toolbar flat dense>
        <v-toolbar-title>{{ titulo }}</v-toolbar-title>
        <widgets-Favorite :ruta="$route.path" :formulario="titulo" />
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>

        <c-text-table-search
          v-if="permission.can_view"
          v-model="search"
        ></c-text-table-search>
        <v-toolbar-items>
          <c-btn-table-add
            @click="createVenta()"
            v-if="permission.can_add"
            class="mr-1"
          ></c-btn-table-add>
          <c-btn-table-change
            @click="createBonificacion()"
            class="mr-1"
          ></c-btn-table-change>
          <c-btn-table-reload 
            @click="Vact_ventaagro_data()"
          ></c-btn-table-reload>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table
        :loading="Vget_ventaagro_load"
        :headers="headers"
        :items="Vget_ventaagro_data"
        :search="search"
        v-if="permission.can_view"
      >
        <template v-slot:[`item.accion`]="props">
          <c-btn-table-view @click="rowselect(props.item)"></c-btn-table-view>
          <c-btn-table-edit @click="mupdate(props.item)"
            v-if="permission.can_update"></c-btn-table-edit>
          <c-btn-table-delete @click="meliminar(props.item)"
            v-if="permission.can_delete"></c-btn-table-delete>
          
        </template>
    
      </v-data-table>
    </v-card>
    <v-dialog v-model="crud.delete" persistent max-width="350">
      <v-card>
        <v-card-title>
          <v-icon left>info</v-icon>
          <span class="title font-weight-light">Ventana de Confirmacion</span>
        </v-card-title>
        <v-card-text
          >Desea eliminar el registro codigo
          <strong>{{ objeto.idventaagro }}</strong
          >?</v-card-text
        >
        <v-card-actions>
          <v-btn color="red" text @click="eliminarcancelar">
            <v-icon>clear</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="meliminarguardar">
            <v-icon>done</v-icon>
          </v-btn>
        </v-card-actions>
        <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { VENTA_GRANOS } from "@/constants/forms";
import Crud from "./Create";
import Bonificacion from "./Bonificacion.vue";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";

export default {
  components:{
    Crud,
    Bonificacion
  },
  data() {
    return {
      overlay: false,
      search: "",
      dialogCotizacion: false,
      headers: [
        { text: "Codigo", align: "left", value: "idventaagro" },
        { text: "Fecha", align: "left", value: "fecha" },
        { text: "Campaña", align: "left", value: "idcampana.descripcion" },
        { text: "Comentario", align: "left", value: "comentario" },
        { text: "Accion", value: "accion", sortable: false, align: "right" },
      ],
      titulo: "Venta de Granos",
      objeto: "",
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
        can_bonificacion: false,
      },
      crud: {
        add: false,
        readonly: false,
        bonificacion: false,
        delete: false,
        datos: null,
      },
    };
  },

  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.Vact_ventaagro_data();
    const permission = getPermission(VENTA_GRANOS).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  computed: {
    ...mapGetters("ventaagro", ["Vget_ventaagro_data", "Vget_ventaagro_load", "Vget_ventaagro_data_byId"]),
    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },

  methods: {
    ...mapActions("ventaagro", ["Vact_ventaagro_data", "Vact_ventaagro_del", "Vact_ventaagro_data_byId"]),
    ...mapActions("cotizacion", ["Vact_cotizacion_data_day"]),
    async createBonificacion() {
      const e = await this.Vact_cotizacion_data_day();
      if (e) return (this.crud.bonificacion = true);
      if (!e) return (this.dialogCotizacion = true);
    },
    async createVenta() {
      const e = await this.Vact_cotizacion_data_day();
      if (e) return (this.crud.add = true);
      if (!e) return (this.dialogCotizacion = true);
    },
    meliminar(item) {
      this.objeto = Object.assign({}, item);
      console.log(this.objeto.idventaagro);
      this.crud.delete = true;
    },
    async rowselect(val) {
      await this.Vact_ventaagro_data_byId(val.idventaagro)
      this.crud.datos = JSON.parse(JSON.stringify(this.Vget_ventaagro_data_byId));
      this.crud.readonly = true;
      this.crud.add = true;
    },
    eliminarcancelar() {
      this.objeto = "";
      this.crud.delete = false;
    },
    async meliminarguardar() {
      this.overlay = true;
      const id = this.objeto.idventaagro;
      const a = await this.Vact_ventaagro_del(id);
      if (a.estado == true) {
        this.$notify(a.info);
        this.crud.delete = false;
        this.eliminarcancelar();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.overlay = false;
    },
    async mupdate(val) {
      await this.Vact_ventaagro_data_byId(val.idventaagro)
      this.crud.datos = JSON.parse(JSON.stringify(this.Vget_ventaagro_data_byId));
      this.crud.add = true;
    },
  },
};
</script>
