<template>
  <div>
    <v-autocomplete
      :value="value"
      @input="$emit('input', $event)"
      ref="input"
      :id="id"
      :items="getPuntoExp"
      :loading="isPuntoExpLoading"
      item-value="idpuntoexpedicion"
      :search-input.sync="syncInput"
      :rules="rules"
      :return-object="returnObject"
      item-text="descpuntoexpedicion"
      :outlined="outlined"
      :dense="dense"
      :disabled="disabled"
      :chips="chips"
      :small-chips="smallChips"
      :label="label"
    >
      <template v-if="isPermission && create" v-slot:no-data>
        <v-list-item @click="crud.add = true">
          <v-list-item-content>
            <v-list-item-title
              >No existe concidencia. Click para agregar</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
    <Crud
      v-if="crud.add"
      v-model="crud.add"
      :sync-input="syncInput"
      @sync-input="$emit('input', $event)"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { PUNTO_EXPEDICION } from "@/constants/forms";
import { PUEDE_AGREGAR } from "@/constants/permissions";
import Crud from "./Create";
export default {
  components: {
    Crud,
  },
  props: {
    value: [Object, Number, String],
    outlined: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    chips: {
      type: Boolean,
      default: false,
    },
    "small-chips": {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Punto de Expedicion",
    },
    id: String,
    rules: {
      type: Array,
      default: function () {
        return [(v) => (this.returnObject ? !!v.idpuntoexpedicion : !!v )|| "Este campo es obligatorio"];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    create: {
      type: Boolean,
      default: false,
    },
    'return-object':{
      tupe: Boolean,
      default: true
    }
  },
  data: () => ({
    crud: {
      add: false,
    },
    syncInput: null,
  }),
  created() {
    this.fetchPuntoExp();
  },
  computed: {
    ...mapGetters("puntoexp", ["getPuntoExp", "isPuntoExpLoading"]),
    isPermission() {
      return getPermission(PUNTO_EXPEDICION).permiso[PUEDE_AGREGAR];
    },
  },
  methods: {
    ...mapActions("puntoexp", ["fetchPuntoExp"]),
    focus() {
      this.$refs.input.focus();
    },
    isMenuActive() {
      this.$refs.input.isMenuActive = false;
    },
  },
};
</script>
