export default {
  estadocria_create(state, request) {
    state.estadocria_create = request;
  }, 
  estadocria_update(state, request) {
    state.estadocria_update = request;
  },   
  estadocria_reload(state, request) {
    state.estadocria_reload = request;
  },   
  estadocria_show(state, request) {
    state.estadocria_show = request;
  },  
  estadocria_key(state, request) {
    state.estadocria_key = request;
  },  
}