import { get } from "@/services/api/api.service";
import { SET_LOADING, SET_DEPARTAMENTO, url } from './constants'
export default {
     async fetchDepartamento({ commit, dispatch },{ idnacionalidad }) {
        if(!idnacionalidad) return null;
        commit(SET_LOADING, true);
        try {
            const response = await get(url+'?nacionalidad='+idnacionalidad);
            commit(SET_DEPARTAMENTO, response);
        } catch (e) {
            dispatch('snackbar/setNotification', e, { root: true });
            commit(SET_LOADING, false);
            throw e
        }
        commit(SET_LOADING, false);
    },
}
