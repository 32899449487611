<template>
  <div>
    <v-autocomplete
      :value="value"
      :id="id"
      @input="$emit('input', $event)"
      :loading="loading"
      :items="items"
      :filter="customFilter"
      :label="label"
      item-text="`${data.item.timbrado}`"
      item-value="'${data.item}'"
      :rules="rules_active"
      type="text"
      outlined
      height="12"
      autocomplete="off"
      :no-data-text="`No existe ninguna concidencia`"
      v-on:change="change"
      small-chips
      dense
    >
      <template slot="selection" slot-scope="data">
        <v-chip small
          >{{ data.item.timbrado }} -
          {{ data.item.idestablecimiento.establecimiento }} -
          {{ data.item.idpuntoexpedicion.puntoexpedicion }}</v-chip
        >
      </template>
      <template slot="item" slot-scope="data">
        <v-list-item-content>
          <v-list-item-title
            v-html="`<b>Timbrado:</b> ${data.item.timbrado} `"
          ></v-list-item-title>
          <v-list-item-subtitle
            v-html="
              `<b>Establecimiento:</b> ${data.item.idestablecimiento.establecimiento} <b>Punto Expedicion:</b> ${data.item.idpuntoexpedicion.puntoexpedicion} <b>Numeracion:</b> ${data.item.numero_inicio} - ${data.item.numero_final} <b>Vto:</b> ${data.item.fecha_final}`
            "
          ></v-list-item-subtitle>
        </v-list-item-content>
      </template>
      <template v-if="noData && timbrado_permission" v-slot:no-data>
        <v-list-item @click="timbrado_create = true">
          <v-list-item-content>
            <v-list-item-title
              >No existe concidencia. Click para agregar</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>

    <div v-if="timbrado_create && timbrado_permission">
      <venta-timbrado-create v-model="timbrado_create" />
    </div>
  </div>
</template>

<script>
import { db } from "@/services/db.service";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { TIMBRADO } from "@/constants/forms";
import { PUEDE_AGREGAR } from "@/constants/permissions";
export default {
  props: {
    label: {
      type: String,
      default: "Seleccione Timbrado",
    },
    id: String,
    value: [Number, String, Object],
    api: String,
    rules: {
      type: Boolean,
      default: false,
    },
    noactive: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dialog: false,
      timbrado_create: false,
      validar: [(v) => !!v || "Seleccion un campo"],
      items: [],
      loading: false,
      noData: true,
    };
  },
  created() {
    this.get_data();
  },
  computed: {
    timbrado_permission() {
      return getPermission(TIMBRADO).permiso[PUEDE_AGREGAR];
    },
    rules_active() {
      if (this.rules) return this.validar;
      return [];
    },
  },
  methods: {
    async get_data() {
      this.loading = true;
      this.items = await db.get(this.api);
      this.loading = false;
    },
    customFilter(item, queryText) {
      const textOne = String(item.timbrado).toLowerCase();
      const textTwo = item.idestablecimiento.establecimiento.toLowerCase();
      const textTree = item.idpuntoexpedicion.puntoexpedicion.toLowerCase();
      const searchText = queryText.toLowerCase();
      return (
        textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1 ||
        textTree.indexOf(searchText) > -1
      );
    },
    change(evt) {
      this.$emit("change", evt);
    },
  },
};
</script>