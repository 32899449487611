import { db } from "@/services/db.service";
import dbItem from "@/db/dbItem";
import { TokenService } from "@/services/auth/storage.service";

export default {
  async item_update({ commit }, request) {
    commit("item_update", request);
  },
  async item_show({ commit }, request) {
    commit("item_show", request);
  },
  async Vact_item_senave({ commit }, {name=null}) {
    commit("item_senave_load", true);
    try {
      let resp = await db.get(`/apisearchsenave/?name=${name}`);
      commit("item_senave", resp);
      commit("item_senave_load", false);
    } catch (e) {
      commit("item_senave_load", false);
      return e;
    }
  },
  async Vact_item_data({ commit }, {desc=null, limit=null}) {
    commit("item_load", true);
    try {
      let resp = ''
      if (desc) {
        resp = await db.get(`/apiitem/?desc=${desc}&limit=${limit}`)
      } else {
        resp = await db.get(`/apiitem/?limit=${limit}`);
      }
      commit("item_data", resp);
      commit("item_load", false);
      return resp;
    } catch (e) {
      commit("item_load", false);
      return e;
    }
  },

  async Vact_item_save({ commit }, data) {
    commit("item_load_save", true);
    try {
      const resp = await db.save("/apiitem/", data);
      commit("item_load_save", false);
      return resp;
    } catch (e) {
      commit("item_load_save", false);
      return e;
    }
  },
  async Vact_item_update({ commit }, { id, data }) {
    commit("item_load_save", true);
    try {
      const resp = await db.update("/apiitem/" + id + "/", data);
      commit("item_load_save", false);
      return resp;
    } catch (e) {
      commit("item_load_save", false);
      return e;
    }
  },
  async Vact_item_del({ dispatch }, id) {
    try {
      const data = await db.delete("/apiitem/" + id);
      dispatch("Vact_item_data");
      return data;
    } catch (e) {
      return false;
    }
  },
  async Vact_item_stock2({ commit }, { search, idmoneda }) {
    commit("item_load", true);
    try {
      var uri = ""
      if (idmoneda != null) {
        uri = `/apiitemstock/?band=item&desc=${search}&money=${idmoneda}`
      } else {
        uri = `/apiitemstock/?band=item&desc=${search}`
      }
      const resp = await db.get(
        uri
      );
      commit("item_stock2", resp);
      commit("item_load", false);
      return resp;
    } catch (e) {
      commit("item_load", false);
      return e;
    }
  },
  async Vact_item_ensilaje({ commit }, { idcampana, ensilaje }) {
    commit("item_load", true);
    try {
      const resp = await db.get(
        `/apiitemstock/?band=&desc=&money=1&campana=${idcampana}&ensilaje=${ensilaje}`
      );
      commit("item_ensilaje", resp);
      commit("item_load", false);
      return resp;
    } catch (e) {
      commit("item_load", false);
      return e;
    }
  },
  async Vact_item_stock_offline({ commit }, { idmoneda }) {
    const xidusuario = TokenService.getToken();
    commit("item_load", true);
    try {
      const resp = await db.get(
        `/apiitemstock/?band=item&desc=&money=${idmoneda}`
      );
      dbItem
        .transaction("rw", dbItem.item, () => {
          dbItem.item.clear();
          resp.map((x) => {
            let item = { ...x, ...{ xidusuario: xidusuario } };
            dbItem.item.add(item);
          });
          dbItem.item.toArray().then((x) => {
            commit("item_stock_offline", x);
          });
        })
        .catch(function(e) {
          console.log(e);
        });
      commit("item_load", false);
      return resp;
    } catch (e) {
      console.log(e);
      dbItem.item
        .where("xidusuario")
        .equals(xidusuario)
        .toArray()
        .then((x) => {
          commit("item_stock_offline", x);
        });
      commit("item_load", false);
      return e;
    }
  },
  async Vact_item_agrostock({ commit }, { search, disabledMoney, campana }) {
    commit("item_load", true);
    try {
      let parameters = `?band=agricultura&desc=${search}${
        disabledMoney ? "" : "&money=1"
      }${campana ? "&campana=" + campana + "" : ""}`;
      let resp = await db.get(`/apiitemstock/` + parameters);
      commit("item_agrostock", resp);
      commit("item_load", false);
      return true;
    } catch (e) {
      commit("item_load", false);
      return e;
    }
  },
  async Vact_item_agrostock_todos({ commit }, { search }) {
    commit("item_load", true);
    try {
      let resp = await db.get(`/apiitemstockexist/?desc=${search}`);
      commit("item_agrostock_todos", resp);
      commit("item_load", false);
      return true;
    } catch (e) {
      commit("item_load", false);
      return e;
    }
  },
  async Vact_item_medicina({ commit }, { search }) {
    commit("item_load", true);
    try {
      const resp = await db.get(
        `/apiitemstock/?band=medicamentos&desc=${search}`
      );
      commit("item_medicina", resp);
      commit("item_load", false);
      return resp;
    } catch (e) {
      commit("item_load", false);
      return e;
    }
  },
  async Vact_item_siembra({ commit }, { idcampana, idlote_agri }) {
    commit("item_siembra", null);
    commit("item_load", true);
    try {
      const id = idcampana.idcampana;
      let resp = await db.get(
        `/apiitem/?band=siembra&zafra=` + id + "&lote=" + idlote_agri
      );
      const hadisponible = await db.get(
        "/apihectareacosechable/" + id + "/" + idlote_agri + "/"
      );
      resp.forEach((x) => {
        hadisponible.forEach((y) => {
          if (x.iditem === y.id) {
            x.hectarea = Number(y.hectarea).toFixed(2);
          }
        });
      });
      commit("item_siembra", resp);
      commit("item_load", false);
      return true;
    } catch (e) {
      commit("item_load", false);
      return e;
    }
  },

  async Vact_item_siembra_default({ commit }) {
    commit("item_siembra_default", []);
    commit("item_load", true);
    try {
      let resp = await db.get(`/apiitem/?band=protegido`);
      commit("item_siembra_default", resp);
      commit("item_load", false);
      return true;
    } catch (e) {
      commit("item_load", false);
      return e;
    }
  },

  async Vact_item_vivo({ commit }) {
    commit("item_load", true);
    try {
      const resp = await db.get(`/apiitem/?band=vivo`);
      commit("item_vivo", resp);
      commit("item_load", false);
      return resp;
    } catch (e) {
      commit("item_load", false);
      return e;
    }
  },
  async Vact_item_lacteo({ commit }) {
    commit("item_load", true);
    try {
      const resp = await db.get(`/apiitem/?band=lacteo`);
      commit("item_lacteo", resp);
      commit("item_load", false);
      return resp;
    } catch (e) {
      commit("item_load", false);
      return e;
    }
  },
  async Vact_item_stockeable({ commit }, {desc}) {
    commit("item_load", true);
    try {
      let resp = null
      if (desc) {
        resp = await db.get(`/apiitem/?band=stockeable&desc=${desc}`)
      } else {
        resp = await db.get(`/apiitem/?band=stockeable`);
      }
      commit("item_stockeable", resp);
      commit("item_load", false);
      return resp;
    } catch (e) {
      commit("item_load", false);
      return e;
    }
  },
  async Vact_tipoitem_data({ commit }) {
    commit("item_load", true);
    try {
      const resp = await db.get(`/apitipoitem/`);
      commit("item_tipoitem_data", resp);
      commit("item_load", false);
      return resp;
    } catch (e) {
      commit("item_load", false);
      return e;
    }
  },
};
