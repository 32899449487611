<template>
  <div>
    <v-dialog
      :value="value"
      @input="$emit('input', $event)"
      persistent
      max-width="700px"
    >
      <v-card>
        <v-toolbar flat dense>
          <v-toolbar-title>Filtro Avanzado</v-toolbar-title>
        </v-toolbar>
        <v-row dense class="pa-0 ma-0" v-for="(item, i) in data" :key="i">
          <v-row
            dense
            v-for="(query, q) in item.query"
            :key="q"
            class="pb-0 ma-0"
          >
            <v-progress-linear
              v-if="q === 0 && i === 0"
              class="mb-1 mx-3"
              height="2"
              value="100"
            ></v-progress-linear>

            <v-col cols="12" md="3" sm="12" class="pa-0 px-1 ma-0">
              <v-text-field
                rounded
                dense
                :value="item.name"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" sm="12" class="pl-1 pa-0 pr-1 ma-0">
              <v-select
                dense
                :items="getoperation(data[i].type)"
                label="Operacion"
                item-text="name"
                item-value="condition"
                autocomplete="off"
                outlined
                v-model="data[i].query[q].comparison"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3" sm="12" class="pl-1 pa-0 pr-1 ma-0">
              <input-autocomplete
                v-if="item.type === 'select'"
                label="Seleccione Campo"
                :api="data[i].api"
                :pk="data[i].id"
                :rules="false"
                :chips="false"
                :title="data[i].desc"
                v-model="data[i].query[q].value"
              />
              <v-text-field
                cols="12"
                md="3"
                sm="12"
                v-if="item.type === 'date'"
                label="Ingrese Fecha"
                outlined
                dense
                autocomplete="off"
                v-model="data[i].query[q].value"
              ></v-text-field>
              <v-text-field
                cols="12"
                md="3"
                sm="12"
                v-if="item.type === 'text'"
                label="Ingrese Texto"
                outlined
                dense
                autocomplete="off"
                v-model="data[i].query[q].value"
              ></v-text-field>
              <v-select
                v-if="item.type === 'boolean'"
                dense
                outlined
                :items="[
                  { desc: '', value: null },
                  { desc: 'SI', value: true },
                  { desc: 'NO', value: false },
                ]"
                label="Seleccione"
                item-text="desc"
                item-value="value"
                autocomplete="off"
                v-model="data[i].query[q].value"
              ></v-select>
            </v-col>

            <v-col
              v-if="data[i].query[q].add"
              cols="12"
              md="2"
              sm="12"
              class="pl-1 pa-0 pr-1 ma-0"
            >
              <v-select
                dense
                :items="operator.logical"
                item-text="name"
                @change="addoperation(i, q)"
                item-value="condition"
                v-model="data[i].query[q].logical"
              ></v-select>
            </v-col>
            <v-col
              class="pl-1 pa-0 pr-1 ma-0"
              cols="1"
              v-if="
                data[i].query[q].value != '' &&
                  data[i].query[q].comparison != '' &&
                  !data[i].query[q].add
              "
            >
              <v-btn
                small
                text
                color="primary"
                @click="data[i].query[q].add = true"
                fab
                class="pl-1 pa-0 pr-1 ma-0"
              >
                <v-icon>add</v-icon>
              </v-btn>
            </v-col>
            <v-col
              class="pl-1 pa-0 pr-1 ma-0"
              cols="1"
              v-if="
                data[i].query[q].value != '' &&
                  data[i].query[q].comparison != '' &&
                  data[i].query[q].add
              "
            >
              <v-btn small text color="primary" @click="deloperation(i, q)" fab>
                <v-icon>clear</v-icon>
              </v-btn>
            </v-col>
            <v-progress-linear
              class="mb-1 mt-n3 mx-3"
              height="2"
              value="100"
              v-if="data[i].query[q].logical === ''"
            ></v-progress-linear>
          </v-row>
        </v-row>
        <v-card-actions>
          <c-btn-crud-close @click="close()"></c-btn-crud-close>
          <v-spacer></v-spacer>
          <v-btn class="ma-2" color="primary"   @click="busqueda()"
            >Filtrar</v-btn
          >
        </v-card-actions>
        <v-overlay v-model="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { db } from "@/services/db.service";
export default {
  props: {
    campos: {
      type: Array,
    },
    value: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
    },
  },
  data() {
    return {
      overlay: false,
      data: [],
      operator: {
        comparison: [
          {
            name: "Igual a",
            condition: "=",
            attrib: ["select", "date", "boolean", "text"],
          },
          {
            name: "Distinto a",
            condition: "<>",
            attrib: ["select", "date", "boolean", "text"],
          },
          {
            name: "Mayor a",
            condition: ">",
            attrib: ["number", "date", "text"],
          },
          {
            name: "Menor a",
            condition: "<",
            attrib: ["number", "date", "text"],
          },
          {
            name: "Mayor o igual a",
            condition: ">=",
            attrib: ["number", "date", "text"],
          },
          {
            name: "Menor o igual a",
            condition: "<=",
            attrib: ["number", "date", "text"],
          },
        ],
        logical: [
          { name: "Y SI", condition: "and" },
          { name: "O SI", condition: "or" },
          { name: "Y NO", condition: "and not" },
          { name: "O NO", condition: "or not" },
        ],
      },
    };
  },
  watch: {
    value() {
      this.data = JSON.parse(JSON.stringify(this.campos));
    },
  },
  methods: {
    addoperation(i, q) {
      if (this.data[i].query[q + 1] === undefined) {
        this.data[i].query.push({
          comparison: "",
          value: "",
          logical: "",
          add: false,
        });
      }
    },
    deloperation(i, q) {
      this.data[i].query.splice(q, 1);
    },
    getoperation(type) {
      var data = [];
      this.operator.comparison.map((x) => {
        x.attrib.map((y) => {
          if (y === type) data.push(x);
        });
      });
      return data;
    },
    async busqueda() {
      this.overlay = true;
      let response = await db.save(this.url, this.data);
      this.$emit("resp", response.data.data);
      this.overlay = false;
    },
    close() {
      this.$emit("input", false);
    },
  },
};
</script>

<style lang="scss" scoped></style>
