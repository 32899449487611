export default {
  notificacion_data: [],
  notificacion_load: false,
  dashboard_menu: [],
  dashboard_diariocaja: [],
  dashboard_load: false,
  favorites: [],
  favorites_load: false,
  activity_data: [],
  activity_load: false,
  commodities: {
    data: [],
    isLoading: false
  }
}
