
export default {

  diagnosticoutero_create: (state) => {
    return state.diagnosticoutero_create
  },
  diagnosticoutero_update: (state) => {
    return state.diagnosticoutero_update
  },
  diagnosticoutero_reload: (state) => {
    return state.diagnosticoutero_reload
  },
  diagnosticoutero_show: (state) => {
    return state.diagnosticoutero_show
  },
  diagnosticoutero_key: (state) => {
    return state.diagnosticoutero_key
  }
}
