<template>
  <div>
    <div v-if="abrirturno">
      <Produccion-Turno-create />
    </div>
    <div v-if="show">
      <Produccion-Turno-show />
    </div>
    <v-card>
      <v-alert type="error" v-if="permission_alert">
        Error!!! Este usuario no tiene permisos en esta ventana
      </v-alert>
      <v-toolbar flat>
        <v-toolbar-title>{{ titulo }}</v-toolbar-title>
        <widgets-Favorite ruta="/turno" formulario="Turno Ordeno" />
        <v-divider class="mx-2" inset vertical></v-divider>

        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Busqueda"
          single-line
          hide-details
          v-if="permission.can_view"
        ></v-text-field>
        <v-btn
          color="primary"
          small
          fab
          dark
          @click="turno_create(true)"
          v-if="permission.can_add"
          ><v-icon dark>add</v-icon></v-btn
        >
      </v-toolbar>
      <v-data-table
        :loading="load"
        :headers="headers"
        :items="datos"
        :search="search"
        class="elevation-1"
        v-if="permission.can_view"
      >
        <template v-slot:item.accion="props">
          <v-icon
            class="mr-2"
            @click="mupdate(props.item)"
            v-if="permission.can_update"
            >edit</v-icon
          >
          <v-icon
            small
            @click="meliminar(props.item)"
            v-if="permission.can_delete"
            >delete</v-icon
          >
        </template>
        <template v-slot:no-data>
          <v-alert :value="true" color="info" icon="info" outlined
            >No existe ningun registro</v-alert
          >
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogoeliminar" persistent max-width="290">
      <v-card>
        <v-card-text class="title"
          >Desea eliminar {{ objeto.descturnoordeno }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="eliminarcancelar"
            >Cancelar</v-btn
          >
          <v-btn color="blue darken-1" text @click="meliminarguardar"
            >Confirmar</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { db } from "@/services/db.service";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { TURNO_ORDENO } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";

export default {
  data() {
    return {
      overlay: false,
      search: "",
      headers: [
        { text: "Codigo", align: "left", value: "idturnoordeno" },
        { text: "Descripcion", align: "left", value: "descturnoordeno" },
        { text: "Hora", align: "left", value: "horaturnoordeno" },
        { text: "Accion", value: "accion", sortable: false, align: "right" },
      ],
      datos: [],
      modal: false,
      dialogo: false,
      url: "/apiturnoordeno/",
      titulo: TURNO_ORDENO,
      objeto: "",
      index: "",
      dialogoeliminar: false,
      load: false,
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
    };
  },

  watch: {
    // eslint-disable-next-line
    reload(val) {
      if (val === true) {
        this.mcargartabla();
      }
    },
  },
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.mcargartabla();
    const permission = getPermission(TURNO_ORDENO).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  computed: {
    ...mapGetters("turno", {
      abrirturno: "turno_create",
      reload: "turno_reload",
      show: "turno_show",
    }),
    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },

  methods: {
    ...mapActions("turno", [
      "turno_create",
      "turno_update",
      "turno_reload",
      "turno_show",
    ]),

    meliminar(item) {
      this.index = this.datos.indexOf(item);
      this.objeto = Object.assign({}, item);
      this.dialogoeliminar = true;
    },

    eliminarcancelar() {
      this.index = "";
      this.objeto = "";
      this.dialogoeliminar = false;
    },
    async meliminarguardar() {
      this.overlay = true;
      const id = this.objeto.idturnoordeno;
      const a = await db.delete(`${this.url}${id}/`);
      if (a.estado == true) {
        this.datos.splice(this.index, 1);
        this.$notify(a.info);
        this.dialogoeliminar = false;
        this.eliminarcancelar();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.overlay = false;
    },
    mupdate(val) {
      this.turno_update(val);
      this.turno_create(true);
    },
    async mcargartabla() {
      this.load = true;
      await db.get(this.url).then((a) => {
        this.datos = a;
      });
      this.load = false;
      this.turno_reload(false);
    },
  },
};
</script>
