
export default {

  Vget_medicacionmotivo_data: (state) => {
    return state.medicacionmotivo_data
  },  
  Vget_medicacionmotivo_load: (state) => {
    return state.medicacionmotivo_load
  }, 
  Vget_medicacionmotivo_save_load: (state) => {
    return state.medicacionmotivo_save_load
  }, 
}
