<template>
  <div>
    <v-dialog :value="value" max-width="800" persistent @keydown.esc="close()">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>{{
            operacion ? "Agregar Timbrado" : "Editar Timbrado"
          }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-row row dense>
              <v-col cols="12" sm="6" md="4">
                <vue-autocomplete-generic
                  id="timbInput1"
                  action="establecimiento/Vact_establecimiento_data"
                  getter="establecimiento/Vget_establecimiento_data"
                  load="establecimiento/Vget_establecimiento_load"
                  pk="idestablecimiento"
                  label="Seleccione Establecimiento"
                  title="descestablecimiento"
                  @sync-input="search = $event"
                  permission="Establecimiento"
                  :create="true"
                  @create="establecimiento_open = $event"
                  v-model="data.idestablecimiento.idestablecimiento"
                  @keyup.native.enter="
                    next(1, data.idestablecimiento.idestablecimiento)
                  "
                />
                <div v-if="establecimiento_open">
                  <Venta-Establecimiento-Crud
                    v-model="establecimiento_open"
                    :sync-input="search"
                    @sync-input="
                      (data.idestablecimiento = $event), next(1, '-')
                    "
                  />
                </div>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <AutocompletePuntoExpedicion
                  id="timbInput2"
                  dense
                  create
                  outlined
                  v-model="data.idpuntoexpedicion"
                  @keyup.native.enter="
                    next(2, data.idpuntoexpedicion.idpuntoexpedicion)
                  "
                />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <input-autocomplete
                  label="Comprobante"
                  api="/apicomprobante/"
                  pk="idcomprobantelegal"
                  title="desccomprobante"
                  :noactive="false"
                  id="timbInput3"
                  v-model="data.idcomprobantelegal.idcomprobantelegal"
                  @keyup.native.enter="
                    next(3, data.idcomprobantelegal.idcomprobantelegal)
                  "
                />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <vue-text-field
                  label="Timbrado"
                  id="timbInput4"
                  v-model="data.timbrado"
                  @keyup.native.enter="next(4, data.timbrado)"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <c-text-date
                  dense
                  outlined
                  label="Fecha de Inicio"
                  id="timbInput5"
                  v-model="data.fecha_inicio"
                  @keyup.native.enter="next(5, data.fecha_inicio)"
                />
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <c-text-date
                  dense
                  outlined
                  label="Fecha de Vencimiento"
                  id="timbInput6"
                  v-model="data.fecha_final"
                  @keyup.native.enter="next(6, data.fecha_final)"
                />
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <vue-text-field
                  label="Numero Inicio"
                  id="timbInput7"
                  v-model="data.numero_inicio"
                  @keyup.native.enter="next(7, data.numero_inicio)"
                />
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <vue-text-field
                  label="Numero Final"
                  id="timbInput8"
                  v-model="data.numero_final"
                  @keyup.native.enter="next(8, data.numero_final)"
                />
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-switch
                  v-model="data.avisar_tim"
                  :label="`Avisar Vto Timbrado?: ${avisar_timbrado}`"
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <vue-text-currency
                  label="Dias para aviso de timbrado"
                  id="timbInput9"
                  v-model="data.dia_tim"
                  @keyup.native.enter="next(9, data.dia_tim)"
                />
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-switch
                  v-model="data.avisar_num"
                  :label="`Avisar Numero Restante?: ${avisar_factura}`"
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <vue-text-currency
                  label="Cantidad de numero restando para aviso"
                  id="timbInput10"
                  v-model="data.cantidad_num"
                  @keyup.native.enter="next(10, data.cantidad_num)"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red" text @click="close()">
            <v-icon dark>close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn id="timbInputaceptar" color="primary" text @click="guardar()">
            <v-icon dark>done</v-icon>
          </v-btn>
        </v-card-actions>
        <c-overlay :value="Vget_timbrado_save_load" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { booleanConverter } from "@/services/util/boolean.service";
import { focus } from "@/services/focus.service";
import { current_date } from "@/services/util/date.service";
import { mensaje } from "@/services/notify.service";
import AutocompletePuntoExpedicion from "@/views/Apertura/Puntoexp/autocomplete";
export default {
  components: {
    AutocompletePuntoExpedicion,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    datos: {
      type: [Object, Array],
      default: null,
    },
  },

  data() {
    return {
      titulo: "Timbrado",
      search: "",
      establecimiento_open: false,
      data: {
        idtimbrado: 0,
        timbrado: "",
        fecha_inicio: current_date(),
        fecha_final: current_date(),
        numero_inicio: "",
        numero_final: "",
        avisar_tim: true,
        avisar_num: true,
        dia_tim: 0,
        cantidad_num: 0,
        idestablecimiento: {
          idestablecimiento: "",
        },
        idpuntoexpedicion: {
          idpuntoexpedicion: "",
        },
        idcomprobantelegal: {
          idcomprobantelegal: "",
        },
      },
      default: {
        idtimbrado: 0,
        timbrado: "",
        fecha_inicio: current_date(),
        fecha_final: current_date(),
        numero_inicio: "",
        numero_final: "",
        avisar_tim: true,
        avisar_num: true,
        dia_tim: 0,
        cantidad_num: 0,
        idestablecimiento: {
          idestablecimiento: "",
        },
        idpuntoexpedicion: {
          idpuntoexpedicion: "",
        },
        idcomprobantelegal: {
          idcomprobantelegal: "",
        },
      },
      operacion: true,
      id: "",
    };
  },
  watch: {
    $route(to, from) {
      if (from.path === "/timbrado") return this.close();
    },
    get_establecimiento_reload(val) {
      if (val === true) {
        this.nuevo_establecimiento();
      }
    },

    get_comprobante_reload(val) {
      if (val === true) {
        this.nuevo_comprobante();
      }
    },
  },
  computed: {
    ...mapGetters("timbrado", ["Vget_timbrado_save_load"]),
    ...mapGetters("comprobante", {
      comprobante_open: "comprobante_create",
      comprobante_key: "comprobante_key",
    }),

    avisar_timbrado() {
      return booleanConverter.si_no(this.data.avisar_tim);
    },
    avisar_factura() {
      return booleanConverter.si_no(this.data.avisar_num);
    },
  },
  created() {
    this.nuevo(this.datos);
  },
  mounted() {
    this.next(0, "-");
  },
  methods: {
    ...mapActions("timbrado", [
      "Vact_timbrado_data",
      "Vact_timbrado_save",
      "Vact_timbrado_update",
    ]),

    close() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.operacion = true;
      this.$emit("input", false);
      this.$emit("datos", null);
      this.Vact_timbrado_data();
    },

    nuevo(val) {
      if (val === null) return null;
      this.data = JSON.parse(JSON.stringify(val));
      this.operacion = false;
      this.id = val.idtimbrado;
    },

    next(id, data) {
      focus.nextidName("timbInput", id, data);
    },
    async guardar_finalizar() {
      let a = await this.Vact_timbrado_save(this.data);
      if (!a.estado) {
        this.overlay = false;
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
        return false;
      }
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$notify(a.info);
      this.$refs.form.resetValidation();
      setTimeout(() => document.getElementById("input1").focus(), 500);
      this.overlay = false;
      return true;
    },
    isValid() {
      return this.$refs.form.validate();
    },
    async editar_finalizar() {
      const id = this.id;
      const data = this.data;
      let a = await this.Vact_timbrado_update({ id, data });
      if (!a.estado) {
        this.overlay = false;
        return a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.$notify(a.info);
      this.overlay = false;
    },
    async guardar() {
      if (!this.isValid()) return this.$notify(mensaje.completar());
      this.overlay = true;
      if (this.operacion) {
        return await this.guardar_finalizar();
      }
      if (!this.operacion) return this.editar_finalizar();
    },
  },
};
</script>
