<template>
    <v-dialog persistent max-width="700" :value="value">
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title>
                    Ver movimientos de la cuenta: {{ cuenta.name }}
                </v-toolbar-title>
            </v-toolbar>
            <v-card-actions>
                <v-btn fab text small @click="mover(-1)">
                    <v-icon small>chevron_left</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                {{ `${month_text} - ${year}` }}
                <v-spacer></v-spacer>
                <v-btn fab text small @click="mover(1)">
                    <v-icon small>chevron_right</v-icon>
                </v-btn>
            </v-card-actions>
            <v-container>
                <v-card-text>
                    <v-row dense>
                        <v-col cols="12">
                            A inicio de mes: {{ toCurrency(cuenta.value) }}
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-data-table :headers="headers" :items="getMovimientoCuenta.movimiento"
                    :loading="isLoadingMovimientoCuenta">
                    <template v-slot:[`item.tipo`]="props">
                        {{ `${props.item.tipo ? 'Movimientos' : 'Transferencia'}` }}
                    </template>
                    <template v-slot:[`item.monto`]="props">
                        {{ toCurrency(props.item.monto) }}
                    </template>
                    <template v-slot:[`item.activo`]="props">
                        <v-chip :color="props.item.activo ? 'green' : 'red'">
                            <v-icon dark>
                                {{ `${props.item.activo ? 'add' : 'remove'}` }}
                            </v-icon>
                        </v-chip>
                    </template>
                </v-data-table>
            </v-container>
            <v-divider></v-divider>
            <v-card-actions>
                <c-btn-crud-close @click="cancel()"></c-btn-crud-close>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { months } from "@/services/util/date.service";
import { currency } from "@/services/util/number.service";
export default {
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        props: {
            type: Object,
        },
    },
    mounted() {
        this.month = this.fecha.getMonth() + 1
        this.year = this.fecha.getFullYear()
        this.cuenta = JSON.parse(JSON.stringify(this.props))
        this.month_text = months[this.month - 1]
        this.findMC()
    },
    computed: {
        ...mapGetters("finanza", ["getMovimientoCuenta", "isLoadingMovimientoCuenta"]),
    },
    watch: {
        getMovimientoCuenta(value) {
            this.cuenta.value = value.balance_inicio
            return value
        }
    },
    methods: {
        ...mapActions("finanza", ["fetchMovimientoCuenta"]),
        cancel() {
            this.$emit("input", false);
        },
        mover(op) {
            let nuevo = this.month + op
            if (nuevo < 1) {
                this.month = 12
                this.year--
            } else if (nuevo > 12) {
                this.month = 1
                this.year++
            } else {
                this.month = nuevo
            }
            this.month_text = months[this.month - 1]
            this.findMC()
        },
        findMC() {
            this.fetchMovimientoCuenta({ id: this.cuenta.id, month: this.month, year: this.year })
        },
        toCurrency(value) {
            return currency(value)
        },
    },
    data: () => ({
        fecha: new Date(),
        month: null,
        month_text: "",
        year: null,
        headers: [
            {
                text: "Operacion", align: "left", value: "tipo"
            },
            {
                text: "Cuenta", align: "left", value: "cuenta"
            },
            {
                text: "Fecha", align: "left", value: "fecha"
            },
            {
                text: "Accion", align: "left", value: "activo"
            },
            {
                text: "monto", align: "right", value: "monto"
            },
        ],
        cuenta: {
            id: "",
            name: "",
            value: 0
        },
        default: {
            id: "",
            name: "",
            value: 0
        }
    })
}
</script>