
export default {
  getRelojBiometrico: (state) => {
    return state.relojbiometrico
  },
  isRelojBiometricoLoading: (state) => {
    return state.isLoading
  },
  getSocketData: (state) => state.socketData,
}
