<template>
  <v-alert type="error" v-if="permission_alert"
    >Error!!! Este usuario no tiene permisos en esta ventana</v-alert
  >
</template>

<script>
import { mensaje } from "@/services/notify.service";
export default {
  props: {
    value: {
      type: Object,
    },
  },
  computed: {
    permission_alert() {
      return mensaje.permission(this.value);
    },
  },
};
</script>

<style>
</style>