<template>
    <div>
        <Help v-model="help" :content="todos"/>
        <v-alert type="error" v-if="permission_alert">
            Error! Este usuario no tiene permiso en esta ventana
        </v-alert>
        <v-toolbar flat dense>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <c-btn-table-help @click="help = true"></c-btn-table-help>
            </v-toolbar-items>
        </v-toolbar>
        <v-container>
            <v-row dense>
                <v-col md="12" cols="12">
                    <Doughnut :api="api"/>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { FINANZA } from "@/constants/forms";
import {
    PUEDE_AGREGAR,
    PUEDE_EDITAR,
    PUEDE_ELIMINAR,
    PUEDE_LISTAR,
} from "@/constants/permissions";
import Doughnut from "../Inicio/Doughnut.vue";
import Help from "../Help.vue";
import { REPORTES } from "@/constants/finanzas";
export default {
    components: {
        Doughnut,
        Help,
    },
    computed: {
        permission_alert() {
            return mensaje.permission(this.permission);
        },
    },
    created() {
        this.$store.commit("SET_LAYOUT", "principal-layout");
        this.todos = REPORTES
        const permission = getPermission(FINANZA).permiso;
        this.permission = {
            can_add: permission[PUEDE_AGREGAR],
            can_view: permission[PUEDE_LISTAR],
            can_update: permission[PUEDE_EDITAR],
            can_delete: permission[PUEDE_ELIMINAR],
        };
    },
    data: () => ({
        help: false,
        todos: null,
        api: "/apifinanzaingresos/",
        permission: {
            can_add: false,
            can_view: false,
        },
    })
}
</script>