<template>
  <div id="crearlote">
    <v-row align="center" justify="center">
      <h4>Crear un nuevo Lote</h4>
      <v-img :src="require('@/assets/help/agro/crearlote.png')" max-width="250"></v-img>
      <v-col>
        <ol>
          <li>Escriba el nombre del Nuevo Lote</li>
          <li>Click para abrir el formulario de Lote</li>
        </ol>
      </v-col>
      <v-img
        :src="require('@/assets/help/agro/completarlote.png')"
        max-width="250"
      ></v-img>
      <v-col>
        <ol>
          <li>
            Click sobre el boton
            <v-icon color="primary">done</v-icon>para guardar
          </li>
          <li>
            Cierre y regrese a esta
            <strong>
              <a @click="href()">seccion</a>
            </strong>
          </li>
        </ol>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  methods: {
    href() {
      document.getElementById("completecampos").scrollIntoView({behavior: "smooth"});
    }
  }
};
</script>

<style>
</style>