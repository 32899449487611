export default {
  division_data(state, request) {
    state.division_data = request;
  }, 
  division_load(state, request) {
    state.division_load = request;
  }, 
  division_save_load(state, request) {
    state.division_save_load = request;
  }, 
}