<template>
  <div>
    <v-dialog
      :value="value"
      max-width="900"
      persistent
      :fullscreen="$vuetify.breakpoint.xs"
    >
      <v-card>
        <v-toolbar flat dense>
          <v-toolbar-title>{{
            readonly
              ? "Detalles"
              : !isEdit
              ? "Registro de Canje Simple"
              : "Modificar Canje Simple"
          }}</v-toolbar-title>
          <v-divider class="mx-2" inset vertical></v-divider>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-container>
          <v-form ref="form">
            <v-row dense>
              <v-col cols="12" sm="2">
                <c-text-date
                  label="Fecha"
                  id="canjesimple1"
                  ref="canjesimple1"
                  :readonly="readonly"
                  v-model="data.fecha"
                  @focus="setSelection(`canjesimple1`)"
                  @keyup.native.enter="next(1, data.fecha)"
                ></c-text-date>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" sm="3">
                <AutocompleteCampana
                  ref="canjesimple2"
                  :readonly="readonly"
                  label="Campaña Origen"
                  return-object
                  v-model="data.idcampana"
                  @keyup.native.enter="next(2, data.idcampana.idcampana)"
              /></v-col>
              <v-col cols="12" sm="3"
                ><c-text-field
                  readonly
                  label="Tipo de Grano"
                  v-model="data.idcampana.iditem.descitem"
                ></c-text-field
              ></v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="4">
                <AutocompleteDepositoStock
                  ref="canjesimple3"
                  :readonly="readonly"
                  :outlined="false"
                  :idcampana="data.idcampana.idcampana"
                  v-model="data.iddeposito"
                  @keyup.native.enter="next(3, data.iddeposito.iddeposito)"
                />
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" sm="4">
                <c-text-currency
                  ref="canjesimple4"
                  outlined
                  dense
                  :readonly="readonly"
                  :rules="[(v) => !!v || 'Obligatorio']"
                  label="Cantidad Kg a Debitar"
                  v-model="data.cantidad"
                  @keyup.native.enter="next(4, data.cantidad)"
                ></c-text-currency>
              </v-col>
            </v-row>
          </v-form>
        </v-container>

        <v-divider></v-divider>

        <v-card-actions>
          <c-btn-crud-close ref="canjesimple5" @click="cerrar()"></c-btn-crud-close>
          <v-spacer></v-spacer>
          <c-btn-crud-done
            :disabled="readonly"
            @click="guardar()"
          ></c-btn-crud-done>
        </v-card-actions>
        <c-overlay :value="isLoading"></c-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { current_date } from "@/services/util/date.service";
import { currency } from "@/services/util/number.service";
import { getPermission } from "@/services/storage/permissionStorage.service";
import AutocompleteCampana from "../../Campana/autocomplete";
import AutocompleteDepositoStock from "@/views/Item/Deposito/autocompleteStock";
import { ITEM } from "@/constants/forms";
import { PUEDE_AGREGAR } from "@/constants/permissions";
export default {
  components: {
    AutocompleteCampana,
    AutocompleteDepositoStock,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    datos: {
      type: [Object, Array],
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("canjesimple", ["isLoading"]),
    item_permission() {
      return getPermission(ITEM).permiso[PUEDE_AGREGAR];
    },
  },
  mounted() {
    setTimeout(() => this.next(0, "-"), 500);
    if (this.datos != null) {
      this.data = JSON.parse(JSON.stringify(this.datos));
      this.data.idcampana.iditem = JSON.parse(
        JSON.stringify(this.datos.iditem)
      );
      this.isEdit = true;
    }
  },
  watch: {
    $route() {
      return this.cerrar();
    },
  },
  methods: {
    ...mapActions("canjesimple", ["setCanjeSimple", "fetchCanjeSimple", "setCanjeSimpleUpdate"]),


    setSelection(id) {
      setTimeout(() => {
        let input = document.getElementById(id);
        input.focus();
        input.setSelectionRange(0, 10);
      }, 200);
    },
    next(id, data) {
      if (data != "" && data)
        try {
          this.$refs[`canjesimple${id + 1}`].focus();
        } catch (error) {
          this.$refs[`canjesimple${id + 1}`].$el.focus();
        }
    },

    toCurrency: (value) => currency(value),
    calcularCantidadDestino(item) {
      const origen = item.cantidadorigen;
      const destino = item.cantidaddestino;
      const isporcentaje = item.isporcentaje;
      let cantidad = 0;
      if (isporcentaje) cantidad = (origen * destino) / 100;
      if (!isporcentaje) cantidad = destino;
      return currency(cantidad);
    },

    async guardar() {
      if (!this.$refs.form.validate())
        return null;
      this.data.iditem.iditem = this.data.idcampana.iditem.iditem;
      const id = this.data.idcanje_simple;
      const response = this.isEdit
        ? await this.setCanjeSimpleUpdate({ id, data: this.data })
        : await this.setCanjeSimple(this.data);
      if (response.success) {
        this.data = JSON.parse(JSON.stringify(this.default));
        if (this.isEdit) return this.cerrar();
        this.$refs.form.resetValidation();
      }
    },
    cerrar() {
      this.$emit("input", false);
      this.$emit("datos", null);
      this.$emit("readonly", false);
      this.fetchCanjeSimple();
      this.data = JSON.parse(JSON.stringify(this.default));
    },
  },

  data() {
    return {
      search: "",
      isEdit: false,

      data: {
        estado: true,
        fecha: current_date(),
        cantidad: null,
        idcampana: {
          idcampana: null,
          iditem: {
            descitem: "",
          },
        },
        iditem: {
          iditem: null,
        },
        iddeposito: {
          iddeposito: null,
        },
      },
      default: {
        estado: true,
        fecha: current_date(),
        cantidad: null,
        idcampana: {
          idcampana: null,
          iditem: {
            descitem: "",
          },
        },
        iditem: {
          iditem: null,
        },
        iddeposito: {
          iddeposito: null,
        },
      }
    };
  },
};
</script>
