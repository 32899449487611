<template>
  <div>
    <div v-if="crud.add">
      <Item-Item-Create v-model="crud.add" />
    </div>
    <input-dialog v-if="show" :value="true">
      <template slot="body">
        <Item-Item-show />
      </template>
    </input-dialog>
    <v-card>
      <v-alert type="error" v-if="permission_alert"
        >Error!!! Este usuario no tiene permisos en esta ventana</v-alert
      >
      <v-toolbar flat dense>
        <v-toolbar-title>{{ titulo }}</v-toolbar-title>
        <widgets-Favorite ruta="/item" formulario="Item" />
        <v-divider class="mx-2" inset vertical></v-divider>

        <v-spacer></v-spacer>
        <v-text-field
          @keyup.native.enter="busqueda()"
          v-model="search"
          :input.sync="search"
          append-icon="search"
          label="Busqueda"
          single-line
          hide-details
          v-if="permission.can_view"
          @click:append="busqueda()"
        ></v-text-field>
        <v-toolbar-items>
          <c-btn-table-add
            @click="crud.add = true"
            v-if="permission.can_add"
          ></c-btn-table-add>
          <c-btn-table-reload
            class="ml-1"
            @click="cargarItem()"
          ></c-btn-table-reload>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table
        :loading="Vget_item_load"
        :headers="headers"
        :items="Vget_item_data"
        :search="search"
        class="elevation-1"
        v-if="permission.can_view"
      >
        <template v-slot:[`item.precitem`]="{ item }">
          <div>{{ toCurrency(item.precitem) }}</div>
        </template>
        <template v-slot:[`item.accion`]="props">
          <c-btn-table-view
            @click="rowselect(props.item)"
            v-if="permission.can_update"
          />
          <c-btn-table-edit
            @click="mupdate(props.item)"
            v-if="permission.can_update"
          />
          <c-btn-table-delete
            @click="meliminar(props.item)"
            v-if="permission.can_delete"
          />
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogoeliminar" persistent max-width="350">
      <v-card>
        <v-card-title>
          <v-icon left>info</v-icon>
          <span class="title font-weight-light">Ventana de Confirmacion</span>
        </v-card-title>
        <v-card-text>Desea eliminar {{ objeto.descitem }}?</v-card-text>
        <v-card-actions>
          <v-btn color="red" text @click="eliminarcancelar">
            <v-icon>clear</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="eliminarguardar">
            <v-icon>done</v-icon>
          </v-btn>
        </v-card-actions>
        <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { currency } from "@/services/util/number.service";
import { ITEM } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";
export default {
  data() {
    return {
      awaitingSearch: false,
      overlay: false,
      search: "",
      headers: [
        { text: "Codigo", align: "left", value: "iditem" },
        { text: "Descripcion", align: "left", value: "descitem" },
        { text: "Precio", align: "left", value: "precitem" },
        { text: "Accion", value: "accion", sortable: false, align: "right" },
      ],
      titulo: ITEM,
      objeto: {},
      dialogoeliminar: false,
      crud: {
        add: false,
      },
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
    };
  },
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.Vact_item_data({limit: 100, desc: null});
    const permission = getPermission(ITEM).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  computed: {
    ...mapGetters("item", {
      show: "item_show",
    }),
    ...mapGetters("item", ["Vget_item_data", "Vget_item_load"]),
    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },
  watch: {
    search() {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.busqueda();
          this.awaitingSearch = false;
        }, 1000);
      }
      this.awaitingSearch = true;
    }
  },
  methods: {
    ...mapActions("item", [
      "item_update",
      "item_show",
      "Vact_item_data",
      "Vact_item_del",
    ]),
    async busqueda() {
      if (this.search.length >= 3 && this.search != null) {
        await this.$store.dispatch("item/Vact_item_data", {
          desc: this.search,
        });
        this.awaitingSearch = false;
      }
    },
    async cargarItem() {
      await this.$store.dispatch("item/Vact_item_data", { limit: 100 });
    },
    toCurrency: (value) => currency(value),
    meliminar(item) {
      this.objeto = Object.assign({}, item);
      this.dialogoeliminar = true;
    },
    eliminarcancelar() {
      this.objeto = {};
      this.dialogoeliminar = false;
    },
    async eliminarguardar() {
      this.overlay = true;
      const id = this.objeto.iditem;
      const a = await this.Vact_item_del(id);
      if (a.estado == true) {
        this.$notify(a.info);
        this.eliminarcancelar();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.overlay = false;
    },
    rowselect(val) {
      this.item_update(val);
      this.item_show(true);
    },
    mupdate(val) {
      this.item_update(val);
      this.crud.add = true;
    },
  },
};
</script>
