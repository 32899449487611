
export default {

  Vget_concepto_data: (state) => {
    return state.concepto_data
  },
  Vget_concepto_load: (state) => {
    return state.concepto_load
  },
  Vget_concepto_save_load: (state) => {
    return state.concepto_save_load
  },
}
