<template>
  <div>
    <div v-if="crud.add">
      <animal-procedencia-create
        v-model="crud.add"
        :datos="crud.datos"
        @datos="crud.datos = $event"
      />
    </div>
    <div v-if="crud.view">
      <animal-procedencia-show
        v-model="crud.view"
        :datos="crud.datos"
        @datos="datos = $event"
      />
    </div>
    <v-card>
      <v-alert type="error" v-if="permission_alert"
        >Error!!! Este usuario no tiene permisos en esta ventana</v-alert
      >
      <v-toolbar flat dense>
        <v-toolbar-title>{{ titulo }}</v-toolbar-title>
        <widgets-Favorite ruta="/procedencia" formulario="Procedencia" />
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <c-text-table-search
          v-model="search"
          v-if="permission.can_view"
        ></c-text-table-search>
      </v-toolbar>
      <v-toolbar flat dense>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-tabs v-model="tab" right>
            <v-tab href="#tab-1" @click="Vact_procedencia_data()">Activo</v-tab>
            <v-tab href="#tab-2" @click="Vact_procedencia_data_inactivo()">Inactivo</v-tab>
          </v-tabs>
          <c-btn-table-add
            @click="crud.add = true"
            v-if="permission.can_add"
            class="mr-1"
          ></c-btn-table-add>
          <c-btn-table-reload
            @click="tab === 'tab-1' ? Vact_procedencia_data() : Vact_procedencia_data_inactivo()"
          ></c-btn-table-reload>
        </v-toolbar-items>
      </v-toolbar>
      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1">
          <v-data-table
            :loading="Vget_procedencia_load"
            :headers="headers"
            :items="Vget_procedencia_data"
            :search="search"
            class="elevation-1"
            v-if="permission.can_view"
          >
            <template v-slot:[`item.estado`]="{ item }">
              <div>
                <v-chip :color="item.estado ? 'green' : 'red'" dark>
                  {{ item.estado ? 'Activo' : 'Inactivo' }}
                </v-chip>
              </div>
            </template>
            <template v-slot:[`item.accion`]="{ item }">
              <v-icon class="mr-2" @click="rowselect(item)"
                >visibility</v-icon
              >
              <v-icon
                class="mr-2"
                @click="mupdate(item)"
                v-if="permission.can_update"
                >edit</v-icon
              >
              <v-icon
                @click="meliminar(item)"
                v-if="permission.can_delete"
                >do_not_disturb_on</v-icon
              >
            </template>
            <template v-slot:no-data>
              <v-alert :value="true" color="info" icon="info" outlined
                >No existe ningun registro</v-alert
              >
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item value="tab-2">
          <v-data-table
            :loading="Vget_procedencia_load"
            :headers="headers"
            :items="Vget_procedencia_data_inactivo"
            :search="search"
            class="elevation-1"
            v-if="permission.can_view"
          >
            <template v-slot:[`item.estado`]="{ item }">
              <div>
                <v-chip :color="item.estado ? 'green' : 'red'" dark>
                  {{ item.estado ? 'Activo' : 'Inactivo' }}
                </v-chip>
              </div>
            </template>
            <template v-slot:[`item.accion`]="{ item }">
              <v-icon class="mr-2" @click="rowselect(item)"
                >visibility</v-icon
              >
              <v-icon
                class="mr-2"
                @click="mupdate(item)"
                v-if="permission.can_update"
                >edit</v-icon
              >
              <v-icon
                @click="meliminar(item)"
                v-if="permission.can_delete"
                >check_circle</v-icon
              >
            </template>
            <template v-slot:no-data>
              <v-alert :value="true" color="info" icon="info" outlined
                >No existe ningun registro</v-alert
              >
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-dialog v-model="dialogoeliminar" persistent max-width="350">
      <v-card>
        <v-card-title>
          <v-icon left>info</v-icon>
          <span class="title font-weight-light">Ventana de Confirmacion</span>
        </v-card-title>
        <v-card-text>Desea {{ objeto.estado ? 'anular' : 'reactivar' }} {{ objeto.descprocedencia }}?</v-card-text>
        <v-card-actions>
          <v-btn color="red" text @click="eliminarcancelar">
            <v-icon>clear</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="meliminarguardar">
            <v-icon>done</v-icon>
          </v-btn>
        </v-card-actions>
        <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { PROCEDENCIA } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";

export default {
  data() {
    return {
      tab: null,
      overlay: false,
      search: "",
      headers: [
        { text: "Codigo", align: "left", value: "idprocedencia" },
        { text: "Descripcion", align: "left", value: "descprocedencia" },
        { text: "Abreviatura", align: "left", value: "abrvprocedencia" },
        { text: "Estado", align: "left", value: "estado" },
        { text: "Accion", value: "accion", sortable: false, align: "right" },
      ],
      datos: [],
      titulo: PROCEDENCIA,
      objeto: "",
      index: "",
      dialogoeliminar: false,
      load: false,
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
      crud: {
        add: false,
        view: false,
        datos: null,
      },
    };
  },

  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.Vact_procedencia_data();
    const permission = getPermission(PROCEDENCIA).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  computed: {
    ...mapGetters("procedencia", [
      "Vget_procedencia_data",
      "Vget_procedencia_data_inactivo",
      "Vget_procedencia_load",
    ]),
    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },

  methods: {
    ...mapActions("procedencia", [
      "Vact_procedencia_data",
      "Vact_procedencia_data_inactivo",
      "Vact_procedencia_del",
    ]),

    meliminar(item) {
      this.index = this.datos.indexOf(item);
      this.objeto = Object.assign({}, item);
      this.dialogoeliminar = true;
    },
    rowselect(val) {
      this.crud.datos = JSON.parse(JSON.stringify(val));
      this.crud.view = true;
    },
    eliminarcancelar() {
      this.index = "";
      this.objeto = "";
      this.dialogoeliminar = false;
    },
    async meliminarguardar() {
      this.overlay = true;
      const id = this.objeto.idprocedencia;
      const a = await this.Vact_procedencia_del(id);
      if (a.estado === true) {
        this.datos.splice(this.index, 1);
        this.$notify(a.info);
        this.dialogoeliminar = false;
        this.eliminarcancelar();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.overlay = false;
    },
    mupdate(val) {
      this.crud.datos = JSON.parse(JSON.stringify(val));
      this.crud.add = true;
    },
  },
};
</script>