export default {
  responsable_create(state, request) {
    state.responsable_create = request;
  }, 
  responsable_update(state, request) {
    state.responsable_update = request;
  },   
  responsable_show(state, request) {
    state.responsable_show = request;
  },   
  responsable_data(state, request) {
    state.responsable_data = request;
  }, 
  responsable_data_inactivo(state, request) {
    state.responsable_data_inactivo = request;
  },
  responsable_load(state, request) {
    state.responsable_load = request;
  }, 
  responsable_save_load(state, request) {
    state.responsable_save_load = request;
  },   
}