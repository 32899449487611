
export default {

  getCotizacionBanks: (state) => state.cotizacionBanks,
  Vget_cotizacion_data: (state) => {
    return state.cotizacion_data
  },
  Vget_cotizacion_load: (state) => {
    return state.cotizacion_load
  },
  Vget_cotizacion_save_load: (state) => {
    return state.cotizacion_save_load
  },
}
