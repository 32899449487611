
export default {
  Vget_procedencia_data: (state) => {
    return state.procedencia_data
  },
  Vget_procedencia_data_inactivo: (state) => {
    return state.procedencia_data_inactivo
  },
  Vget_procedencia_load: (state) => {
    return state.procedencia_load
  },
  Vget_procedencia_save_load: (state) => {
    return state.procedencia_save_load
  }
}

