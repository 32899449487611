import { Workbook } from "exceljs";
import { current_date } from "@/services/util/date.service";
import * as fs from "file-saver";

export const exportProduccionLeche = (datos, head, foot) => {
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Hoja 1");
    let header = []
    head.forEach(x => {
        header.push(x['text'])
    });
    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell) => {
        cell.alignment = {
            vertical: "middle",
            horizontal: "center",
        };
        cell.border = {
            top: { style: "medium" },
            left: { style: "medium" },
            right: { style: "medium" },
            bottom: { style: "medium" },
        };
        cell.font = {
            name: "Arial",
            family: 4,
            size: 13,
        };
    });
    datos.forEach(y => {
        let dt = [y['animal']]
        y['year'].forEach(z => {
            if (z['value']) {
                dt.push(z['value'])
            } else {
                dt.push('')
            }
        });
        let row = worksheet.addRow(dt)
        row.eachCell((cell) => {
            cell.border = {
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
                top: { style: "thin" },
            };
            cell.alignment = {
                vertical: "middle",
                horizontal: "center",
            };
            cell.font = {
                name: "Arial",
                family: 4,
                size: 12,
            };
        });
    });
    let row2 = worksheet.addRow(foot);
    row2.eachCell((cell) => {
        cell.border = {
            left: { style: "medium" },
            bottom: { style: "medium" },
            right: { style: "medium" },
            top: { style: "medium" },
        };
        cell.alignment = {
            vertical: "middle",
            horizontal: "center",
        };
        cell.font = {
            name: "Arial",
            family: 4,
            size: 12,
        };
    });
    workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        fs.saveAs(blob, `Produccion Leche ${current_date()}.xlsx`);
    });
}

export const exportProduccionAnimal = (datos, head) => {
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Hoja 1");
    let header = []
    head.forEach(x => {
        header.push(x['text'])
    });
    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell) => {
        cell.alignment = {
            vertical: "middle",
            horizontal: "center",
        };
        cell.border = {
            top: { style: "medium" },
            left: { style: "medium" },
            right: { style: "medium" },
            bottom: { style: "medium" },
        };
        cell.font = {
            name: "Arial",
            family: 4,
            size: 13,
        }
    });
    datos.forEach(y => {
        let dt = []
        Object.entries(y).forEach(([key]) => {
            if (y[key]) {
                dt.push(y[key])
            } else {
                dt.push('')
            }
        });
        let row = worksheet.addRow(dt)
        row.eachCell((cell) => {
            cell.border = {
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
                top: { style: "thin" },
            };
            cell.alignment = {
                vertical: "middle",
                horizontal: "center",
            };
            cell.font = {
                name: "Arial",
                family: 4,
                size: 12,
            };
        })
    });
    workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        fs.saveAs(blob, `Produccion Animal ${current_date()}.xlsx`);
    });
}