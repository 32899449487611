<template>
  <div>
    <v-dialog :value="value" max-width="700px" persistent @keydown.esc="cancelar()">
      <v-card>
        <v-form ref="form" lazy-validation>
          <v-toolbar flat>
            <v-toolbar-title>{{'Realizar un ajuste de Cuenta Bancaria'}}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-row dense>
              <v-col cols="12">
                <vue-text-field
                  label="Observacion"
                  id="ajustecuentaInput1"
                  v-model="data.observacion"
                  @keyup.native.enter="next(1,data.observacion)"
                />
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  :items="Vget_cuentabanco_data"
                  item-text="desccuentabanco"
                  item-value="idcuentabanco"
                  return-object
                  dense
                  outlined
                  id="ajustecuentaInput2"
                  autocomplete="off"
                  :rules="[v => !!v.idcuentabanco || 'Seleccione una Cuenta']"
                  :loading="Vget_cuentabanco_load"
                  v-model="data.idcuentabanco"
                  :messages="monedaCuenta"
                  label="Cuenta Banco"
                  @change="addMoneda()"
                  @keyup.native.enter="next(2,data.idcuentabanco.idcuentabanco)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <vue-text-currency
                  label="Monto"
                  :rules="true"
                  id="ajustecuentaInput3"
                  v-model="data.monto"
                  @keyup.native.enter="next(3,data.monto)"
                />
              </v-col>

              <v-col cols="12">
                <vue-autocomplete-generic
                  id="ajustecuentaInput4"
                  action="motivoajuste/Vact_motivoajuste_data"
                  getter="motivoajuste/Vget_motivoajuste_data"
                  load="motivoajuste/Vget_motivoajuste_load"
                  pk="idmotivoajuste"
                  label="Seleccione el Motivo"
                  title="descmotivoajuste"
                  permission="Motivo Ajuste"
                  v-model="data.idmotivoajuste.idmotivoajuste"
                  @keyup.native.enter="next(4,data.idmotivoajuste.idmotivoajuste)"
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-btn color="red" text @click="cancelar">
              <v-icon dark>close</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn id="ajustecuentaInputaceptar" color="primary" text @click="guardar">
              <v-icon dark>done</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
      <c-overlay :value="Vget_ajustecuenta_save_load"></c-overlay>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mensaje } from "@/services/notify.service";
import { focus } from "@/services/focus.service";
export default {
  props: {
    value: {
      type: Boolean,
      default: null
    }
  },
  data() {
    return {
      data: {
        idajustecuenta: 0,
        observacion: "",
        monto: "",
        idcuentabanco: {
          idcuentabanco: ""
        },
        fecha: '2000-01-01',
        idmotivoajuste: {
          idmotivoajuste: ""
        },
        idmoneda: {
          idmoneda: ""
        }
      },
      default: {
        idajustecuenta: 0,
        observacion: "",
        monto: "",
        idcuentabanco: {
          idcuentabanco: ""
        },
        fecha: '2000-01-01',
        idmotivoajuste: {
          idmotivoajuste: ""
        },
        idmoneda: {
          idmoneda: ""
        }
      },
      operacion: true,
      monedaCuenta: ""
    };
  },
  watch: {
    $route(to, from) {
      if (from.path === "/ajustecuentabancaria") return this.cancelar();
    }
  },
  mounted() {
    setTimeout(() => this.next(0, "-"), 200);
    this.Vact_cuentabanco_data();
  },
  computed: {
    ...mapGetters("ajustecuenta", ["Vget_ajustecuenta_save_load"]),
    ...mapGetters("cuentabanco", [
      "Vget_cuentabanco_data",
      "Vget_cuentabanco_load"
    ])
  },
  methods: {
    ...mapActions("ajustecuenta", [
      "Vact_ajustecuenta_data",
      "Vact_ajustecuenta_save"
    ]),
    addMoneda() {
      if (this.data.idcuentabanco.idmoneda) {
        this.data.idmoneda.idmoneda = this.data.idcuentabanco.idmoneda.idmoneda;
        return (this.monedaCuenta = this.data.idcuentabanco.idmoneda.descmoneda);
      }
      return (this.monedaCuenta = "");
    },
    ...mapActions("cuentabanco", ["Vact_cuentabanco_data"]),
    next(index, data) {
      focus.nextidName("ajustecuentaInput", index, data);
    },
    cancelar() {
      this.$emit("input", false);
      this.data = JSON.parse(JSON.stringify(this.default));
      this.Vact_ajustecuenta_data();
      this.operacion = true;
    },
    async guardar_finalizar() {
      let a = await this.Vact_ajustecuenta_save(this.data);
      if (a.estado == true) {
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$notify(a.info);
        this.$refs.form.resetValidation();
      } else {
        a.info.map(x => {
          this.$notify(mensaje.error(x));
        });
      }
    },
    guardar() {
      if (!this.$refs.form.validate()) return this.$notify(mensaje.completar());
      if (this.operacion === true) return this.guardar_finalizar();
    }
  }
};
</script>