<template>
  <div id="completecampos">
    <h3 align="center">Pasos para crear un Campo</h3>
    <v-row align="center" justify="center">
      <h4>Dibuje en el mapa</h4>
      <v-img :src="require('@/assets/help/agro/dibujacampo.png')" max-width="100"></v-img>
      <ol>
        <li>Para moverse por el mapa</li>
        <li>Para dibujar un poligono en el mapa</li>
        <li>Para limpiar los poligonos del mapa</li>
      </ol>
      <v-progress-linear rounded height="3" value="100"></v-progress-linear>
      <h4>Complete los campos</h4>
      <v-img :src="require('@/assets/help/agro/completado.png')" max-width="200"></v-img>
      <v-col cols="12">
        <v-divider></v-divider>
        <v-icon small color="info">info</v-icon>
        <span>
          Si no le aparece ningun lote siga los pasos de esta
          <strong>
            <a href="#crearlote">seccion</a>
          </strong>
        </span>
        <v-divider></v-divider>
        <li>Pulse GUARDAR para confirmar</li>
        <li>Pulse CANCELAR para cancelar</li>
        <li>Mision Cumplida!!! :D</li>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  methods: {
    href() {
      document.getElementById("crearlote").scrollIntoView({behavior: "smooth"});
    }
  }
};
</script>

<style>
</style>