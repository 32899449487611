
export default {


  Vget_gymrutina_data: (state) => {
    return state.gymrutina_data
  },
  Vget_gymrutina_data_by: (state) => {
    return state.gymrutina_data_by
  },
  Vget_gymrutina_data_hoy: (state) => {
    return state.gymrutina_data_hoy
  },
  Vget_gymrutina_update: (state) => {
    return state.gymrutina_update
  },
  Vget_gymrutina_load: (state) => {
    return state.gymrutina_load
  },
  Vget_gymrutina_save_load: (state) => {
    return state.gymrutina_save_load
  },
}
