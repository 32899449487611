export const headers = [
  { text: "ID", value: "idanimal" },
  { text: "Rp", value: "rpanimal" },
  { text: "Nombre", value: "nombanimal" },
  { text: "Raza", value: "idraza.descraza" },
  {
    text: "Estado",
    value: "idestadoanimal.descestadoanimal",
  },
  { text: "Lote", value: "idlote.desclote" },
  { text: "Sexo", value: "idsexo.descsexocria" },
  { text: "Item", value: "iditem.descitem", align: "end" },
  { text: "Deposito", value: "iddeposito.desdeposito", align: "end" },
  {
    text: "Procedencia",
    value: "idprocedencia.descprocedencia",
    align: "end",
  },
  { text: "Valor de Animal", value: "montanimal", align: "end" },
  { text: "Informacion", value: "indexed", align: "end" },
  { text: "Fecha Nacimiento", value: "fechanimal" },
  { text: "Extra", value: "extra" }

];
