import { get, post, put, del } from "@/services/api/api.service";
import { SET_LOADING, SET_PERFIL_FIN, url } from "./constants";
export default {
  async fetchPerfilFin({ commit, dispatch }) {
    commit(SET_LOADING, true);
    try {
      const response = await get(url.perfil);
      commit(SET_PERFIL_FIN, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(SET_LOADING, false);
      throw e;
    }
    commit(SET_LOADING, false);
  },
  async fetchFinanciamiento({ commit, dispatch }, data) {
    commit(SET_LOADING, true);
    const response = await post(url.financiamiento, data);
    dispatch("snackbar/setNotification", response, { root: true });
    commit(SET_LOADING, false);
    return response;
  },
  async setPerfilFin({ commit, dispatch }, data) {
    commit(SET_LOADING, true);
    const response = await post(url.perfil, data);
    dispatch("snackbar/setNotification", response, { root: true });
    commit(SET_LOADING, false);
    return response;
  },
  async setPerfilFinUpdate({ commit, dispatch }, { data, id }) {
    commit(SET_LOADING, true);
    const response = await put(`${url.perfil}${id}/`, data);
    dispatch("snackbar/setNotification", response, { root: true });
    commit(SET_LOADING, false);
    return response;
  },
  async setPerfilFinDelete({ commit, dispatch }, id) {
    commit(SET_LOADING, true);
    const response = await del(`${url.perfil}${id}/`);
    dispatch("snackbar/setNotification", response, { root: true });
    commit(SET_LOADING, false);
    return response;
  },
};
