<template>
  <v-autocomplete
    :value="value"
    @input="$emit('input', $event)"
    ref="input"
    :items="getEstadoCivil"
    :loading="isEstadoCivilLoading"
    item-value="idestado_civil"
    item-text="descripcion"
    return-object
    :outlined="outlined"
    :rules="rules"
    :dense="dense"
    :disabled="disabled"
    :chips="chips"
    :small-chips="smallChips"
    :label="label"
  ></v-autocomplete>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
export default {
  props: {
    value: [Object, Number, String],
    outlined: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    chips: {
      type: Boolean,
      default: false,
    },
    "small-chips": {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Estado Civil',
    },
    rules:{
      type: Array,
      default: function () {
        return [(v) => !!v.idestado_civil || "Este campo es obligatorio"];
      },
    },
    disabled:{
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.fetchEstadoCivil();
  },
  computed: {
    ...mapGetters("estadocivil", ["getEstadoCivil", "isEstadoCivilLoading"]),
  },
  methods: {
    ...mapActions("estadocivil", ["fetchEstadoCivil"]),
    focus(){
      this.$refs.input.focus();
    },
    isMenuActive(){
      this.$refs.input.isMenuActive = false
    }
  },
};
</script>

<style>
</style>