import { db } from "@/services/db.service";
export default {
async Vact_banco_data({commit}){
        commit('banco_load',true);
        try {
            const data = await db.get('/apibanco/');
            commit('banco_data',data);
            commit('banco_load',false);
            return true
        } catch (e) {
            commit('banco_load',false);
            return false
        } 
    },
    async Vact_banco_save({commit},data){
        commit('banco_save_load',true);
        try {
            const resp = await db.save('/apibanco/',data);
            commit('banco_save_load',false);
            return resp;
        } catch (e) {
            commit('banco_save_load',false);
            return e
        } 
    },
    async Vact_banco_del({dispatch},id){
        try {
            const data = await db.delete('/apibanco/'+id);
            dispatch('Vact_banco_data');
            return data
        } catch (e) {
            return false
        } 
    },
    async Vact_banco_update({commit},{id, data}){
        commit('banco_save_load',true);
        try {
            const resp = await db.update('/apibanco/'+id+'/',data);
            commit('banco_save_load',false);
            return resp;
        } catch (e) {
            commit('banco_save_load',false);
            return e
        } 
    },
}
