var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.value,"max-width":"900px","persistent":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.cancelar()}}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v("Actualizar Numero de Factura")]),_c('v-spacer'),_c('v-toolbar-items',[_c('v-tabs',{attrs:{"color":"deep-purple accent-4","right":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{key:1},[_vm._v("Registrar")]),_c('v-tab',{key:2,on:{"click":function($event){return _vm.fetchVentaRebuild()}}},[_vm._v("Historico")])],1)],1)],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{key:1},[_c('v-stepper',{attrs:{"dense":"","vertical":"","tile":""},model:{value:(_vm.e6),callback:function ($$v) {_vm.e6=$$v},expression:"e6"}},[_c('v-stepper-step',{attrs:{"complete":_vm.e6 > 1,"step":"1"}},[_vm._v(" Seleccione las facturas "),_c('small',[_vm._v("No olvide completar los campos")])]),_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-row',{staticClass:"ma-1 justify-end",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('SearchVenta')],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-form',{ref:"form"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.desserts,"show-select":"","items-per-page":5,"item-key":"idventa"},on:{"toggle-select-all":_vm.selectAllToggle},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
var select = ref.select;
return [_c('v-simple-checkbox',{attrs:{"ripple":false},on:{"input":function($event){!item.select ? (item.newfact = '') : null,
                            select($event)}},model:{value:(item.select),callback:function ($$v) {_vm.$set(item, "select", $$v)},expression:"item.select"}})]}},{key:"item.cambiarA",fn:function(ref){
                            var item = ref.item;
return [_c('c-text-currency',{class:!item.select ? 'mb-n3' : '',attrs:{"disabled":!item.select,"rules":!item.select
                            ? []
                            : [function (v) { return !!v || 'Complete este campo'; }],"placeholder":item.select ? 'Ingrese numero' : '',"dense":""},model:{value:(item.newfact),callback:function ($$v) {_vm.$set(item, "newfact", $$v)},expression:"item.newfact"}})]}},{key:"item.factura",fn:function(ref){
                            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatFact(item))+" ")]}},{key:"item.fechventa",fn:function(ref){
                            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.fechventa))+" ")]}},{key:"item.estado",fn:function(ref){
                            var item = ref.item;
return [_c('v-chip',{attrs:{"dark":"","color":item.anulventa ? 'red' : 'green'}},[_vm._v(_vm._s(item.anulventa ? "Anulado" : "Activo"))])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1),_c('v-btn',{staticClass:"mt-3 text-capitalize",attrs:{"disabled":_vm.selected.length === 0,"small":"","rounded":"","color":"red","dark":_vm.selected.length > 0},on:{"click":function($event){return _vm.continuar()}}},[_vm._v("Click aqui para continuar "),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("arrow_forward_ios")])],1)],1)],1),_c('v-stepper-step',{attrs:{"complete":_vm.e6 > 2,"step":"2"}},[_vm._v(" Guarde los cambios ")]),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('v-form',{ref:"form2"},[_c('v-row',{staticClass:"ma-1 justify-end",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers_confirm,"items":_vm.selected,"items-per-page":99999,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.factura",fn:function(ref){
                            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatFact(item))+" ")]}},{key:"item.newfact",fn:function(ref){
                            var item = ref.item;
return [_c('div',{staticClass:"font-weight-black"},[_vm._v(" "+_vm._s(_vm.formatFactNew(item))+" ")])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('c-text-field',{attrs:{"label":"Escriba un comentario"},model:{value:(_vm.data.comentario),callback:function ($$v) {_vm.$set(_vm.data, "comentario", $$v)},expression:"data.comentario"}})],1),_c('div',{staticClass:"d-flex justify-center align-center"},[_c('v-btn',{staticClass:"mt-3 mr-2 text-capitalize",attrs:{"small":"","rounded":"","color":"red","dark":""},on:{"click":function($event){return _vm.guardar()}}},[_vm._v("Guardar los cambios "),_c('v-icon',{staticClass:"ml-2",attrs:{"small":""}},[_vm._v("save")])],1),_c('v-btn',{staticClass:"mt-3 text-capitalize red--text",attrs:{"color":_vm.selected.length > 0 ? 'grey lighten-5' : 'red',"small":"","rounded":""},on:{"click":function($event){_vm.e6 = 1}}},[_vm._v("Cancelar")])],1)],1)],1)],1)],1)],1),_c('v-tab-item',{key:2},[_c('v-row',{staticClass:"ma-1",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers_list,"items":_vm.getVentaRebuild,"loading":_vm.Vget_venta_load,"items-per-page":99999,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.fecha",fn:function(ref){
                            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.fecha))+" ")]}},{key:"item.hora",fn:function(ref){
                            var item = ref.item;
return [_vm._v(" "+_vm._s(item.hora.split(".")[0])+" ")]}},{key:"item.detalle",fn:function(ref){
                            var item = ref.item;
return [_c('div',{staticClass:"caption",domProps:{"innerHTML":_vm._s(_vm.formatDetalle(item.rebuilddet))}})]}}],null,true)})],1)],1)],1)],1),_c('v-card-actions',[_c('c-btn-crud-close',{on:{"click":function($event){return _vm.cancelar()}}}),_c('v-spacer')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }