<template>
  <div>
    <v-card min-height="100" outlined>
      <v-card min-height="100" @click="$emit('click')" elevation="0">
        <v-progress-linear
          height="2"
          color="red"
          rounded
          value="100"
        ></v-progress-linear>
        <v-card-title>
          <v-icon size="20" left>info</v-icon>
          <span class="subtitle-2 font-weight-light">Haga Click Aqui</span>
        </v-card-title>
        <v-card-text class="body-2">{{ title }}</v-card-text>
      </v-card>
      
      <div class="d-flex flex-row justify-align-end">
        <v-divider class="align-self-center"></v-divider>
        <v-btn text small fab color="red" @click="dialog.eliminar = true"
          ><v-icon>clear</v-icon></v-btn
        >
      </div>
    </v-card>
    <v-dialog v-model="dialog.eliminar" persistent max-width="350">
      <v-card>
        <v-card-title>
          <v-icon left>info</v-icon>
          <span class="title font-weight-light">Ventana de Confirmacion</span>
        </v-card-title>
        <v-card-text
          >Desea eliminar <strong>{{ title }}</strong
          >?</v-card-text
        >
        <v-card-actions>
          <v-btn color="red" text @click="dialog.eliminar = false">
            <v-icon>clear</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="eliminar()">
            <v-icon>done</v-icon>
          </v-btn>
        </v-card-actions>
        <v-overlay :value="dialog.isLoading">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { mensaje } from "@/services/notify.service";
export default {
  props: {
    id: Number,
    title: String,
  },
  data() {
    return {
      dialog: {
        eliminar: false,
        isLoading: false,
      },
    };
  },
  methods: {
    ...mapActions("dashboard", [
      "Vact_dashboard_menu_del",
      "Vact_dashboard_diariocaja",
    ]),

    async eliminar() {
      this.dialog.isLoading = true;
      const a = await this.Vact_dashboard_menu_del(this.id);
      console.log(a);
      if (a.estado == true) {
        this.$notify(a.info);
        this.Vact_dashboard_diariocaja();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.dialog.eliminar = false;
      this.dialog.isLoading = false;
    },
  },
};
</script>

<style>
</style>