<template>
    <div>
        <v-row>
            <v-col cols="12" md="5">
                <c-text-field label="Nombre" id="input1" :rules="[]" outlined dense v-model="semen.name"
                    @keyup.native.enter="nextfocus(1, semen.name)" />
            </v-col>
            <v-col cols="10" md="5">
                <v-autocomplete id="input2" v-model="semen.es" :items="es" dense item-text="name" item-value="id"
                    label="Es?" outlined autocomplete="off" @keyup.native.enter="nextfocus(2, semen.es)">
                </v-autocomplete>
            </v-col>
            <v-col cols="2" md="2">
                <v-btn id="input3" @click="addDetalle()" outlined color="primary" fab small>
                    <v-icon>add</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="12">
                <Animalequal :buscar="semen.name" />
            </v-col>
        </v-row>
        <v-data-table :headers="header" :items="detalle" :mobile-breakpoint="0" :items-per-page="1000"
            hide-default-footer>
            <template v-slot:[`item.es`]="{ item }">
                {{ changeEs(item.es) }}
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <v-icon small @click="eliminarDetalle(item)">delete</v-icon>
            </template>
        </v-data-table>
    </div>
</template>
<script>
import { focus } from "@/services/focus.service";
import Animalequal from "./Animalequal.vue";
import { mapActions } from "vuex";
export default {
    components: {
        Animalequal
    },
    data() {
        return {
            header: [
                { text: "Nombre", sortable: false, value: "name" },
                { text: "Es?", sortable: false, value: "es" },
                { text: "", sortable: false, align: "end", value: "actions" },
            ],
            detalle: [],
            semen: {
                name: "",
                es: 0,
                identico: null,
            },
            semen_default: {
                name: "",
                es: 0,
                identico: null,
            },
            es: [
                { id: 1, name: "Madre" },
                { id: 2, name: "Padre" },
                { id: 3, name: "Abuela Paterna" },
                { id: 4, name: "Abuelo Paterno" },
                { id: 5, name: "Bisabuela Paterna" },
                { id: 6, name: "Bisabuelo Paterno" }
            ]
        }
    },
    mounted() {
        setTimeout(() => document.getElementById("input1").focus(), 500);
    },
    methods: {
        ...mapActions("snackbar", ["setNotification"]),
        nextfocus(index, data) {
            focus.nextid(index, data);
        },
        verificarDuplicado() {
            let temp = this.detalle.find(
                (x) => x.es == this.semen.es
            )
            return temp ? false : true
        },
        async addDetalle() {
            if (!this.verificarDuplicado()) {
                let texto = this.changeEs(this.semen.es)
                await this.setNotification({
                    message: `No se puede registrar dos ${texto}s iguales`,
                    success: false,
                    type: "error"
                })
                return false
            }
            this.detalle.push(JSON.parse(JSON.stringify(this.semen)))
            this.semen = JSON.parse(JSON.stringify(this.semen_default))
            setTimeout(() => document.getElementById("input1").focus(), 100)
        },
        changeEs(val) {
            let ret = "No se encuentra"
            this.es.forEach((x) => {
                if (x.id == val) {
                    ret = x.name
                }
            })
            return ret
        },
        obtenerDatos() {
            return this.detalle
        },
        async validarParentesco() {
            const combinaciones1 = [[1, 2]]
            const combinaciones2 = [[3, 4]]
            const combinaciones3 = [[5, 6]]
            if (this.detalle.length % 2 !== 0) {
                await this.setNotification({
                    message: `Falta un parentesco`,
                    success: false,
                    type: "error"
                })
                return false
            } else {
                let isvalid = true
                this.detalle.forEach((x) => {
                    switch (x.es) {
                        case 1:
                        case 2:
                            if (!this.encontradas(combinaciones1)) {
                                this.setNotification({
                                    message: `Falta un parentesco de nivel 1`,
                                    success: false,
                                    type: "error"
                                })
                                isvalid = false
                            }
                            break
                        case 3:
                        case 4:
                            if (!this.encontradas(combinaciones2)) {
                                this.setNotification({
                                    message: `Falta un parentesco de nivel 2`,
                                    success: false,
                                    type: "error"
                                })
                                isvalid = false
                            }
                            break
                        case 5:
                        case 6:
                            if (!this.encontradas(combinaciones3)) {
                                this.setNotification({
                                    message: `Falta un parentesco de nivel 3`,
                                    success: false,
                                    type: "error"
                                })
                                isvalid = false
                            }
                            break
                    }
                })
                return isvalid
            }
        },
        encontradas(combinaciones) {
            const encontrado = this.detalle.filter(elemento => {
                return combinaciones.some(combinacion => {
                    return combinacion.includes(elemento.es)
                })
            })
            if (encontrado.length == 0) {
                return true
            }
            return false
        },
        eliminarDetalle(val) {
            const index = this.detalle.indexOf(val)
            this.detalle.splice(index, 1)
        }
    }
}
</script>