<template>
  <v-dialog persistent max-width="800" :value="value">
    <v-card>
      <v-toolbar flat dense>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-form ref="form">
          <v-row dense>
            <v-col cols="12">
              <autocomplete-funcionario
                ref="justificacionInput1"
                label="Funcionario"
                :readonly="readonly"
                :idcontacto="props ? props.idcontacto : null"
                v-model="data.idcontacto"
                @change="obtenerVacaciones(data.idcontacto)"
                @keyup.native.enter="
                  next(1, data.idcontacto.idcontacto.idcontacto)
                "
              />
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                ref="justificacionInput2"
                :rules="[(v) => !!v || 'Requerido']"
                v-model="data.idtipo_justificacion.idtipo_justificacion"
                :items="getMotivoJustificacion"
                :loading="isJustificacionLoading"
                dense
                :readonly="readonly"
                item-text="descripcion"
                item-value="idtipo_justificacion"
                label="Motivo"
                
                @keyup.enter="
                  next(2, data.idtipo_justificacion.idtipo_justificacion)
                "
              >
                <template slot="item" slot-scope="{ item }">
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="`${item.descripcion}`"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      v-html="`${item.comentario}`"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-spacer></v-spacer>
       
            <v-col cols="12" v-if="isVacacion && !readonly">
              <div v-if="getListaVacacion.length != 0 ">
                <span class="body-2 font-weight-medium"
                  >De que año desea descontar las vacaciones?</span
                >
                <div class="d-flex justify-center">
                  <v-chip-group
                    v-model="data.vacaciones_periodo"
                    column
                    mandatory
                    color="deep-purple accent-3"
                  >
                    <v-chip
                      v-for="(item, i) of getListaVacacion"
                      :key="i"
                      filter
                      outlined
                      :value="item.year"
                    >
                      Año {{ item.year }} /
                      <strong class="ml-1 mr-1">{{ item.days }} Dias </strong>
                      Disponibles
                    </v-chip>
                  </v-chip-group>
                </div>
              </div>
              <div v-else>
                <v-alert type="error" dense>
                  No tiene disponible dias de vacaciones
                </v-alert>
              </div>
            </v-col>
            <v-col cols="12" sm="6">
              <c-text-date
                ref="justificacionInput3"
                label="Fecha Inicio"
                :readonly="readonly"
                v-model="data.fecha_inicio"
                @keyup.native.enter="next(3, data.fecha_inicio)"
              ></c-text-date>
            </v-col>
            <v-col cols="12" sm="6">
              <c-text-date
                ref="justificacionInput4"
                label="Fecha Fin"
                :readonly="readonly"
                v-model="data.fecha_fin"
                @keyup.native.enter="next(4, data.fecha_fin)"
              ></c-text-date>
            </v-col>
            <v-col cols="12">
              <v-textarea
                label="Comentario"
                autocomplete="off"
                rows="2"
                :readonly="readonly"
                ref="justificacionInput5"
                v-model="data.comentario"
              ></v-textarea>
            </v-col>
            <v-col cols="12">
              <v-file-input
                accept="image/png,image/jpeg,.doc,.docx,.pdf"
                v-model="documento"
                v-if="!readonly"
                label="Adjuntar Justificativo (PNG, JPG, DOC, DOCX,PDF)"
              ></v-file-input>
              <v-list v-else-if="data.documento">
                <v-subheader
                  >Archivo .{{
                    extensionFile.toUpperCase()
                  }}
                  disponible</v-subheader
                >
                <v-list-item-group :value="true" color="primary">
                  <v-btn
                    :href="data.documento"
                    target="_blank"
                    block
                    text
                    color="deep-purple accent-4"
                    outlined
                    ><v-icon class="mr-6">get_app</v-icon> {{ fileName }}</v-btn
                  >
                </v-list-item-group>
              </v-list>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <c-btn-crud-close @click="cancel()"></c-btn-crud-close>
        <v-spacer></v-spacer>
        <c-btn-crud-done ref="justificacion2" v-if="!readonly" @click="save()"></c-btn-crud-done>
      </v-card-actions>
      <c-overlay :value="isJustificacionLoading"></c-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { current_date } from "@/services/util/date.service";
import autocompleteFuncionario from "@/views/RRHH/Funcionarios/Information/autocomplete";
export default {
  components: {
    "autocomplete-funcionario": autocompleteFuncionario,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
    }
  },

  mounted() {
    this.fetchMotivoJustificacion();
    if (this.editable || this.readonly)
      this.data = JSON.parse(JSON.stringify(this.props));
    setTimeout(() => this.next(0, "-"), 20);
  },
  computed: {
    ...mapGetters("justificacion", [
      "isJustificacionLoading",
      "getMotivoJustificacion",
      "getListaVacacion",
    ]),
    title() {
      return this.readonly
        ? "Justificacion"
        : this.editable
        ? "Modificar Ausencia"
        : "Registrar Ausencia";
    },
    isVacacion() {
      return this.data.idtipo_justificacion.idtipo_justificacion === 1
        ? true
        : false;
    },
    fileName() {
      if (this.readonly) {
        if (!this.props.documento) return "";
        const index = this.props.documento.lastIndexOf("/") + 1;
        return this.props.documento.substr(index);
      }
      return "";
    },
    extensionFile() {
      const ext = this.fileName.split(".");
      return ext[ext.length - 1];
    },
  },
  methods: {
    ...mapActions("justificacion", [
      "setJustificacion",
      "fetchJustificacion",
      "setJustificacionUpdate",
      "fetchMotivoJustificacion",
      "fetchListaVacacion",
    ]),

    cancel() {
      this.$emit("input", false);
    },
    next(id, data) {
      if (data != "")
        try {
          this.$refs[`justificacionInput${id + 1}`].focus();
        } catch (error) {
          this.$refs[`justificacionInput${id + 1}`].$el.focus();
        }
    },
    obtenerVacaciones(contacto) {
      this.fetchListaVacacion(contacto.idcontacto.idcontacto);
    },
    getFormData() {
      let Form = new FormData();
      Form.append("idjustificaciones", this.data.idjustificaciones);
      Form.append("idcontacto", JSON.stringify(this.data.idcontacto));
      Form.append("fecha_inicio", this.data.fecha_inicio);
      Form.append("fecha_fin", this.data.fecha_fin);
      Form.append("vacaciones_periodo", this.data.vacaciones_periodo);
      Form.append(
        "idtipo_justificacion",
        JSON.stringify(this.data.idtipo_justificacion)
      );
      Form.append("comentario", this.data.comentario);
      if (!this.editable)
        this.documento
          ? Form.append("documento", this.documento)
          : Form.append("documento", "");
      if (this.editable)
        this.documento
          ? Form.append("documento_update", this.documento)
          : Form.append("documento_update", "");
      return Form;
    },
    async save() {
      if (!this.$refs.form.validate()) return null;
      const response = this.editable
        ? await this.setJustificacionUpdate({
            data: this.getFormData(),
            id: this.data.idjustificaciones,
          })
        : await this.setJustificacion(this.getFormData());
      if (response.success) {
        this.fetchJustificacion();
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();
        this.next(0, "-");
        if (this.editable) this.cancel();
      }
    },
  },
  data: () => ({
    documento: null,
    data: {
      idjustificaciones: 0,
      vacaciones_periodo: '',
      idcontacto: {
        idcontacto: "",
      },
      idtipo_justificacion: {
        idtipo_justificacion: "",
      },
      fecha_inicio: current_date(),
      fecha_fin: current_date(),
      comentario: "",
      documento: null,
    },
    default: {
      idjustificaciones: 0,
      vacaciones_periodo: '',
      idcontacto: {
        idcontacto: "",
      },
      idtipo_justificacion: {
        idtipo_justificacion: "",
      },
      fecha_inicio: current_date(),
      fecha_fin: current_date(),
      comentario: "",
      documento: null,
    }
  }),
};
</script>

<style>
</style>