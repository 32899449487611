<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>{{operacion ? 'Registrar Division' : 'Modificar Division'}}</v-toolbar-title>
    </v-toolbar>
    <v-container>
      <v-form ref="form" @submit.prevent="next(1,data.descripcion)">
        <v-row dense>
          <v-col cols="12">
            <vue-text-field
              ref="divisionInput1"
              label="Descripcion"
              v-model="data.descripcion"
              @keyup.native.enter="next(1,data.descripcion)"
            ></vue-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <v-card-actions>
      <v-btn text color="red" @click="cancelar()">
        <v-icon>clear</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        text
        color="primary"
        ref="divisionInputaceptar"
        @click="operacion ? guardar() : editar()"
      >
        <v-icon>done</v-icon>
      </v-btn>
    </v-card-actions>
    <c-overlay absolute :value="Vget_division_save_load"></c-overlay>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mensaje } from "@/services/notify.service";
export default {
  props:{
    'dialog-mode':{
      type: Boolean,
      default: false
    },
    value:{
      type: Boolean,
      default: false
    },
    "sync-input": {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      operacion: true,
      data: {
        iddivision: 0,
        descripcion: "",
      },
      default: {
        iddivision: 0,
        descripcion: "",
      },
    };
  },
  computed: {
    ...mapGetters("division", ["Vget_division_save_load"]),
    currentPath() {
      return this.$route.path;
    },
  },
  watch: {
    currentPath() {
      this.changeCrudMode();
      if (this.$refs.form) this.$refs.form.resetValidation();
    },
  },
  mounted() {
    setTimeout(() => this.next(0, "-"), 200);
    if (this.syncInput != null) this.data.descripcion = this.syncInput;
    this.changeCrudMode();
  },
  methods: {
    ...mapActions("division", [
      "Vact_division_data",
      "Vact_division_save",
      "Vact_division_update",
    ]),
    changeCrudMode() {
       if(this.dialogMode) return null;
      if (this.currentPath != "/diariocaja/division/add") {
        this.operacion = false;
        this.data = JSON.parse(JSON.stringify(this.$route.query));
      } else {
        this.operacion = true;
        this.data = JSON.parse(JSON.stringify(this.default));
      }
    },
    next(index, data) {
      try {
        if (data != null && data != "") {
          let id = `divisionInput${index + 1}`;
          this.$refs[id].$children[0].focus();
        }
      } catch (error) {
        this.$refs.divisionInputaceptar.$el.focus();
      }
    },
    async guardar() {
      if (!this.$refs.form.validate()) return null;
      const sync =  this.syncInput;
      let a = await this.Vact_division_save(this.data);
      if (a.estado == true) {
        if (sync != null) {
          this.cancelar();
          this.$emit('sync-input',a.data.data.id);
        }
        this.data = JSON.parse(JSON.stringify(this.default));
        this.Vact_division_data();
        this.$notify(a.info);
        this.$refs.form.resetValidation();
        this.next(0, "-");
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
    },
    async editar() {
      const id = this.$route.params.id;
      const data = this.data;
      let a = await this.Vact_division_update({ id, data });
      if (a.estado == true) {
        this.$notify(a.info);
        this.Vact_division_data();
        this.cancelar();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
    },
    cancelar() {
      if(!this.dialogMode)
      this.$router.push({
        path: "/diariocaja/division",
      });
      this.$emit('input',false);
    },
  },
};
</script>

<style>
</style>