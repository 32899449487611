<template>
  <div>
    <v-autocomplete
      :value="value"
      @input="$emit('input', $event)"
      :items="Vget_moneda_data"
      :loading="Vget_moneda_load"
      :rules="rules"
      return-object
      :search-input.sync="syncInput"
      ref="input"
      item-value="idmonoeda"
      item-text="descmoneda"
      :outlined="outlined"
      :dense="dense"
      :multiple="multiple"
      :chips="chips"
      @change="$emit('change')"
      :disabled="disabled"
      :small-chips="smallChips"
      :label="label"
    >
    </v-autocomplete>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {

  props: {
    value: [Object, Number, String],
    outlined: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    chips: {
      type: Boolean,
      default: false,
    },
    "small-chips": {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Banco",
    },
    rules: {
      type: Array,
      default: function () {
        return [(v) => !!v.idmoneda || "Este campo es obligatorio"];
      },
    },
    multiple:{
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    create:{
      type: Boolean,
      default: false
    }
  },
  data:()=>({
    crud: {
      add: false
    },
    syncInput: null
  }),
  created() {
    this.Vact_moneda_data();
  },
  computed: {
    ...mapGetters("moneda", ["Vget_moneda_load", "Vget_moneda_data"]),
  },
  methods: {
    ...mapActions("moneda", ["Vact_moneda_data"]),
    isMenuActive() {
      this.$refs.input.isMenuActive = false;
    },
    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>

<style>
</style>