var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"md":"7"}},[_c('v-checkbox',{staticClass:"ml-3 mb-0",attrs:{"label":"Agrupar por Zona"},on:{"change":function($event){return _vm.guardarConfig(_vm.zona)}},model:{value:(_vm.zona),callback:function ($$v) {_vm.zona=$$v},expression:"zona"}})],1),_c('v-col',{staticClass:"mt-4",attrs:{"md":"5"}},[_c('v-chip',{attrs:{"outlined":"","color":"orange"}},[_vm._v(" Ha.: "+_vm._s(_vm.Vget_campo_ha.toFixed(2))+" ")])],1),_c('v-col',{attrs:{"md":"7"}},[_c('v-checkbox',{staticClass:"ml-3 mb-0",attrs:{"label":"Mostrar bajas"},on:{"change":function($event){return _vm.guardarBaja(_vm.baja)}},model:{value:(_vm.baja),callback:function ($$v) {_vm.baja=$$v},expression:"baja"}})],1)],1),_c('v-text-field',{staticClass:"ml-2 mr-2 mt-0",attrs:{"append-icon":"search","label":"Busqueda","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(!_vm.zona)?_c('v-data-table',{attrs:{"mobile-breakpoint":"5000","headers":_vm.headers,"items":_vm.Vget_campo_data,"loading":_vm.Vget_campo_load,"items-per-page":1000,"search":_vm.search},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":item.estado ? "red" : "grey","outlined":"","small":""},on:{"click":function($event){return _vm.startBaja(item)}}},[_vm._v(_vm._s(item.estado ? 'Baja' : 'Alta'))]),(_vm.permission.can_view)?_c('v-menu',{attrs:{"top":"","offset-y":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"color":"success","outlined":"","small":""}},on),[_vm._v("Exp.")])]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.downloadMAP(item, 'kml')}}},[_c('v-list-item-title',[_vm._v("KML")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.downloadMAP(item, 'kmz')}}},[_c('v-list-item-title',[_vm._v("KMZ")])],1)],1)],1):_vm._e(),(_vm.permission.can_view)?_c('v-btn',{attrs:{"color":"primary","outlined":"","small":""},on:{"click":function($event){return _vm.selectrow(item)}}},[_vm._v("Abrir")]):_vm._e()]}}],null,true)}):_c('v-data-table',{attrs:{"mobile-breakpoint":"5000","headers":_vm.headers_zona,"items":_vm.Vget_lotegrupo_data,"loading":_vm.Vget_lotegrupo_load,"items-per-page":1000,"search":_vm.search,"expanded":_vm.expanded,"show-expand":"","item-key":"idlote_grupo"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.permission.can_view)?_c('v-btn',{attrs:{"color":"primary","outlined":"","small":""},on:{"click":function($event){return _vm.selectrowZona(item)}}},[_vm._v("Ver")]):_vm._e()]}},{key:"item.ha",fn:function(props){return [_c('td',{staticClass:"text-xs-letf"},[_vm._v(_vm._s(_vm.currencyFormat(props.item.ha)))])]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('v-data-table',{staticClass:"elevation-1",attrs:{"mobile-breakpoint":"5000","hide-default-footer":"","headers":_vm.headers_zona_sub,"items":item.grupo,"loading":_vm.Vget_lotegrupo_load,"items-per-page":1000,"search":_vm.search},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":item.estado ? "red" : "grey","outlined":"","small":""},on:{"click":function($event){return _vm.startBaja(item)}}},[_vm._v(_vm._s(item.estado ? 'Baja' : 'Alta'))]),(_vm.permission.can_view)?_c('v-btn',{attrs:{"color":"secondary","outlined":"","small":""},on:{"click":function($event){return _vm.selectrow(item)}}},[_vm._v("Abrir")]):_vm._e()]}}],null,true)})]}}],null,true)}),_c('v-divider'),(_vm.dialogBaja)?_c('v-dialog',{attrs:{"max-width":"420","persistent":""},model:{value:(_vm.dialogBaja),callback:function ($$v) {_vm.dialogBaja=$$v},expression:"dialogBaja"}},[_c('v-card',[_c('v-card-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("info")]),_c('span',{staticClass:"title font-weight-light"},[_vm._v("Ventana de Confirmacion")])],1),_c('v-card-text',{staticClass:"subtitle-1 font-weight-regular"},[_vm._v(" "+_vm._s(_vm.tituloBaja)+" "),_c('strong',{staticClass:"font-italic font-weight-bold"},[_vm._v(_vm._s(_vm.bajas.campo))]),_vm._v(" ? ")]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"red","text":""},on:{"click":function($event){_vm.dialogBaja = false}}},[_c('v-icon',[_vm._v("clear")])],1),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.darBaja()}}},[_c('v-icon',[_vm._v("done")])],1)],1),_c('c-overlay',{attrs:{"value":_vm.Vget_campo_baja}})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }