export default {

  tambo_update(state, request) {
    state.tambo_update = request;
  },
  tambo_data(state, request) {
    state.tambo_data = request;
  }, 
  tambo_empresa(state, request) {
    state.tambo_empresa = request;
  }, 
  tambo_load(state, request) {
    state.tambo_load = request;
  }, 
  tambo_save_load(state, request) {
    state.tambo_save_load = request;
  }, 
}
