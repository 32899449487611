<template>
  <div>
    <v-dialog
      :value="value"
      max-width="800px"
      persistent
      @keydown.esc="cancelar()"
    >
      <v-card>
        <v-form ref="form" lazy-validation>
          <v-toolbar flat dense>
            <v-toolbar-title>{{
              operacion ? "Registrar Inseminacion" : "Modificar Inseminacion"
            }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-checkbox
              class="mt-5"
              label="Historico"
              v-model="historico"
            ></v-checkbox>
          </v-toolbar>
          <v-container>
            <ChildForm
              ref="childForm"
              :historico="historico"
              :datos="data"
              :readonly="readonly"
              :xid="xid"
            />
          </v-container>
          <v-divider></v-divider>
          <v-card-actions>
            <c-btn-crud-close @click="cancelar()"></c-btn-crud-close>
            <v-spacer></v-spacer>

            <c-btn-crud-done
              id="aceptar"
              :disabled="readonly"
              @click="guardar()"
            ></c-btn-crud-done>
          </v-card-actions>
        </v-form>
      </v-card>
      <c-overlay :value="Vget_inseminacion_save_load"></c-overlay>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ChildForm from "./CreateRef";
export default {
  components: {
    ChildForm,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    datos: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    xid: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      overlay: false,
      data: null,
      historico: false,
      operacion: true,
      isEditAnimal: false,
    };
  },
  watch: {
    $route() {
      return this.cancelar();
    },
  },

  created() {
    if (this.xid != null) {
      return this.nuevo(null);
    }
    this.nuevo(this.datos);
  },
  computed: {
    ...mapGetters("inseminacion", ["Vget_inseminacion_save_load"]),
  },
  methods: {

    ...mapActions("inseminacion", [
      "Vact_inseminacion_save",
      "Vact_inseminacion_update",
      "Vact_inseminacion_nuevo",
      "Vact_inseminacion_historico",
    ]),

    cancelar() {
      this.data = {};
      this.$emit("input", false);
      this.$emit("datos", null);
      this.Vact_inseminacion_nuevo();
      this.isEditAnimal = false;
    },
    async guardar_finalizar() {
      const a = await this.Vact_inseminacion_save(this.data);
      if (!a.success) return null;
      this.isEditAnimal = true;
      this.data = null;
      setTimeout(() => document.getElementById("input1").focus(), 500);
    },
    async editar_finalizar() {
      const id = this.datos.idinseminacion;
      const a = await this.Vact_inseminacion_update({ id, data: this.data });
      if (!a.success) return null;
      this.cancelar();
    },
    async guardar() {
      if (!this.$refs.childForm.validate()) return null;
      this.data = {
        ...JSON.parse(
          JSON.stringify(await this.$refs.childForm.obtenerDatos())
        ),
        historico: this.historico,
      };
      if (this.operacion) return this.guardar_finalizar();
      return this.editar_finalizar();
    },
    nuevo(val) {
      if (val === null) return null;
      this.data = JSON.parse(JSON.stringify(val));
      this.operacion = false;
    },
  },
};
</script>
