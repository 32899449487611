<template>
  <v-overlay :z-index="4" :value="value">
    <v-dialog
      :value="value"
      max-width="400"
      persistent
      :fullscreen="mobile"
      @keydown.esc="cerrar()"
    >
      <v-card>
        <v-form ref="formCabecera">
          <v-card flat>
            <v-row dense class="mx-1">
              <v-card-title> Copiar o Mover registro </v-card-title>
            </v-row>
          </v-card>
        </v-form>
        <v-container>
          <v-form ref="form">
            <v-row dense>
              <v-col cols="12" sm="12">
                <AutocompleteCampana
                  label="Campaña origen"
                  hide-details
                  :fetch="false"
                  return-object
                  v-model="data.origen"
                />
              </v-col>
              <v-col cols="12" sm="12">
                <AutocompleteCampana
                  id="moveInput1"
                  label="Campaña destino"
                  hide-details
                  :fetch="false"
                  return-object
                  v-model="data.destino"
                  @keyup.native.enter="next(1, data.destino)"
                />
              </v-col>
              <v-col cols="12" sm="12">
                <v-switch
                  id="moveInput2"
                  v-model="data.opcion"
                  inset
                  :label="`Accion: ${data.opcion ? `Copiar` : `Mover`}`"
                  @keyup.native.enter="next(2, data.destino)"
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="12">
                <v-switch
                  id="moveInput3"
                  v-model="data.todo"
                  inset
                  :label="`${data.opcion ? `Copiar` : `Mover`}: ${
                    data.todo ? `Todos los registros` : `Este registro`
                  }`"
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="12" v-if="data.todo">
                <v-alert type="warning"> 
                  Atencion! Esta por {{ data.opcion ? `copiar` : `mover` }} <b>TODOS</b> los registros de este lote.
                </v-alert>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <c-btn-crud-close @click="cerrar"></c-btn-crud-close>
          <v-spacer></v-spacer>
          <c-btn-crud-done
            :id="'moveInput3'"
            @click="guardar()"
          ></c-btn-crud-done>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-overlay>
</template>

<script>
import { mapActions } from "vuex";
import { mensaje } from "@/services/notify.service";
import { focus } from "@/services/focus.service";
import AutocompleteCampana from "../Campana/autocomplete";
export default {
  components: {
    AutocompleteCampana,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    datos: {
      type: Object,
    },
  },
  data() {
    return {
      data: {
        datos: {},
        origen: {},
        destino: {},
        opcion: false,
        todo: false,
      },
    };
  },
  mounted() {
    this.data.origen = this.$props.datos.origen.idcampana;
    this.data.datos = this.$props.datos.origen;
    setTimeout(() => document.getElementById("moveInput1").focus(), 500);
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  methods: {
    ...mapActions("siembra", ["Vact_siembra_move"]),
    next(index, data) {
      focus.nextidName("moveInput", index, data);
    },
    cerrar() {
      this.$emit("input", false);
    },
    cerrarTodo(e) {
      this.$emit("cerrar-todo", e);
    },
    async guardar() {
      let a = await this.Vact_siembra_move(this.data);
      if (!a.estado) {
        return a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      } else {
        this.$notify(a.info);
        this.cerrar();
        this.cerrarTodo(!this.data.opcion);
      }
    },
  },
};
</script>
