<template>
  <div>
    <div v-if="abrirPropquimica">
      <Item-PropiedadQuimica-Create v-model="abrirPropquimica" />
    </div>
    <div v-if="showPropquimica">
      <Item-PropiedadQuimica-Show v-model="showPropquimica" />
    </div>
    <v-card>
      <v-alert
        type="error"
        v-if="permission_alert"
      >Error!!! Este usuario no tiene permisos en esta ventana</v-alert>
      <v-toolbar flat dense>
        <v-toolbar-title>{{titulo}}</v-toolbar-title>
        <widgets-Favorite ruta="/propiedadquimica" formulario="Propiedad Quimica" />
        <v-divider class="mx-2" inset vertical></v-divider>

        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Busqueda"
          single-line
          hide-details
          v-if="permission.can_view"
        ></v-text-field>
        <v-toolbar-items>
          <c-btn-table-add
            @click="abrirPropquimica = true"
            v-if="permission.can_add"
          ></c-btn-table-add>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table
        :loading="Vget_propquimica_load"
        :headers="headers"
        :items="Vget_propquimica_data"
        :search="search"
        class="elevation-1"
        v-if="permission.can_view"
      >
        <template v-slot:[`item.accion`]="props">
          <v-icon small @click="rowselect(props.item)">visibility</v-icon>
          <v-icon small @click="mupdate(props.item)" v-if="permission.can_update">edit</v-icon>
          <v-icon small @click="meliminar(props.item)" v-if="permission.can_delete">delete</v-icon>
        </template>
        <template v-slot:no-data>
          <v-alert :value="true" color="info" icon="info" outlined>No existe ningun registro</v-alert>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogoeliminar" persistent max-width="400">
      <v-card>
        <v-card-title>
          <v-icon left>info</v-icon>
          <span class="title font-weight-light">Ventana de Confirmacion</span>
        </v-card-title>
        <v-card-text class="subtitle">Desea eliminar {{ objeto.descripcion}}?</v-card-text>
        <v-card-actions>
          <v-btn color="red" text @click="eliminarcancelar">
            <v-icon>clear</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="eliminarguardar">
            <v-icon>done</v-icon>
          </v-btn>
        </v-card-actions>
        <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { PROPIEDAD_QUIMICA } from "@/constants/forms";
import {
    PUEDE_AGREGAR,
    PUEDE_EDITAR,
    PUEDE_ELIMINAR,
    PUEDE_LISTAR,
} from "@/constants/permissions";
export default {
  data() {
    return {
      overlay: false,
      search: "",
      abrirPropquimica: false,
      showPropquimica: false,
      headers: [
        { text: "Codigo", align: "left", value: "idpropiedad_quimica" },
        { text: "Descripcion", align: "left", value: "descripcion" },
        { text: "Accion", value: "accion", sortable: false, align: "right" }
      ],
      titulo: PROPIEDAD_QUIMICA,
      objeto: "",
      dialogoeliminar: false,
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false
      }
    };
  },
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.Vact_propquimica_data();
    const permission = getPermission(PROPIEDAD_QUIMICA).permiso;
this.permission = {
    can_add: permission[PUEDE_AGREGAR],
    can_view: permission[PUEDE_LISTAR],
    can_update: permission[PUEDE_EDITAR],
    can_delete: permission[PUEDE_ELIMINAR],
};
  },
  computed: {
    ...mapGetters("propiedadquimica", [
      "Vget_propquimica_data",
      "Vget_propquimica_load"
    ]),
    permission_alert() {
      return mensaje.permission(this.permission);
    }
  },
  methods: {
    ...mapActions("propiedadquimica", [
      "propquimica_update",
      "Vact_propquimica_data",
      "Vact_propquimica_del"
    ]),
    meliminar(item) {
      this.objeto = Object.assign({}, item);
      this.dialogoeliminar = true;
    },
    eliminarcancelar() {
      this.objeto = "";
      this.dialogoeliminar = false;
    },
    async eliminarguardar() {
      this.overlay = true;
      const id = this.objeto.idpropiedad_quimica;
      const a = await this.Vact_propquimica_del(id);
      if (a.estado == true) {
        this.$notify(a.info);
        this.eliminarcancelar();
      } else {
        a.info.map(x => {
          this.$notify(mensaje.error(x));
        });
      }
      this.overlay = false;
    },
    rowselect(val) {
      this.propquimica_update(val);
      this.showPropquimica = true;
    },
    mupdate(val) {
      this.propquimica_update(val);
      this.abrirPropquimica = true;
    }
  }
};
</script>