
export default {

  motivomuerte_create: (state) => {
    return state.motivomuerte_create
  },
  motivomuerte_update: (state) => {
    return state.motivomuerte_update
  },
  motivomuerte_reload: (state) => {
    return state.motivomuerte_reload
  },
  motivomuerte_show: (state) => {
    return state.motivomuerte_show
  },
}
