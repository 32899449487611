export default {
  data: [],
  celo: [],
  toro: [],
  hembra: [],
  disponible: [],
  termo: [],
  nomina: [],
  parasecado: [],
  inseminada: [],
  prenada: [],
  vivo: [],
  muerto: [],
  todos: [],
  venta: [],
  secado: [],
  ternero: [],
  enfermo: [],
  postparto: [],
  dataEstado: [],
  preparto: [],
  count: [],
  semen:[],
  ficha:[],
  reporteavanzado: [],
  reportereproduccion: [],
  vaquilla: [],
  nominaId: false,
  loading: false,
  socketData: null,
};
