<template>
  <div>
    <div v-if="formularios.deposito">
      <Item-Deposito-create v-model="formularios.deposito" />
    </div>
    <v-dialog
      :value="value"
      max-width="900"
      persistent
      :fullscreen="$vuetify.breakpoint.xs"
    >
      <v-card>
        <CopyMove
          v-if="copymove.show"
          v-model="copymove.show"
          :value="copymove.show"
          :datos="copymove.datos"
          @datos="copymode.datos = $event"
          @cerrar-todo="cerrarTodo"
        />
        <c-overlay :value="overlay || Vget_cosecha_load"></c-overlay>
        <v-toolbar flat>
          <v-toolbar-title>Modificar Cosecha</v-toolbar-title>
          <v-divider class="mx-2" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-divider class="my-3" inset vertical></v-divider>
          <v-col cols="12" sm="1" class="pa-2" tile>
            <v-tooltip bottom color="info">
              <template v-slot:activator="{ on }">
                <v-btn
                  class="mx-2"
                  text
                  dark
                  small
                  fab
                  color="primary"
                  v-on="on"
                  @click="copymove.show = true"
                >
                  <v-icon> content_paste </v-icon>
                </v-btn>
              </template>
              <v-icon small color="red" class="mr-2">help_outline</v-icon>
              <span class="text-center">Click para copiar o mover</span>
            </v-tooltip>
          </v-col>
        </v-toolbar>
        <v-card-text>
          <div>
            <v-form ref="form">
              <v-row row dense>
                <v-col cols="12" md="4" sm="4">
                  <v-btn-toggle
                    v-model="data.ensilaje"
                    dense
                    rounded
                    mandatory
                    color="red darken-1"
                  >
                    <v-btn> Cosecha </v-btn>
                    <v-btn> G. Húmedo </v-btn>
                    <v-btn> Ensilaje </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col cols="12" md="4" sm="4">
                  <vue-text-field
                    :rules="false"
                    label="Item"
                    :readonly="true"
                    v-model="data.iditem.descitem"
                  ></vue-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="2">
                  <c-text-date
                    label="Fecha Inicio"
                    id="input1"
                    outlined
                    dense
                    :readonly="!readonly"
                    v-model="data.fecha"
                    @keyup.native.enter="next(1, data.fecha)"
                  ></c-text-date>
                </v-col>
                <v-col cols="12" md="2" sm="2">
                  <c-text-date
                    label="Fecha Fin"
                    id="input2"
                    outlined
                    :rules="[]"
                    dense
                    :readonly="!readonly"
                    v-model="data.fechafin"
                    @keyup.native.enter="next(2, '0')"
                  ></c-text-date>
                </v-col>
                <v-col cols="12" md="3" sm="3">
                  <vue-text-field
                    label="Hectarea"
                    id="input3"
                    :readonly="!readonly"
                    v-model="data.hectarea"
                    @keyup.native.enter="next(3, data.hectarea)"
                  ></vue-text-field>
                </v-col>
                <v-col cols="12" md="3" sm="3">
                  <c-text-currency
                    :label="'Produccion/Kg'"
                    outlined
                    dense
                    ref="input4"
                    :readonly="!readonly"
                    v-model="data.produccion"
                    @keyup.native.enter="next(4, data.produccion)"
                  ></c-text-currency>
                </v-col>
                <v-col cols="12" md="3" sm="3">
                  <c-text-currency
                    label="Precio/Tn (Para Referencia)"
                    ref="input5"
                    outlined
                    dense
                    :readonly="!readonly"
                    v-model="data.precio"
                    suffix="$"
                    @keyup.native.enter="next(5, '-')"
                  ></c-text-currency>
                </v-col>

                <v-col cols="12" md="3" sm="3">
                  <vue-text-field
                    label="Humedad"
                    suffix="%"
                    :rules="false"
                    id="input6"
                    :readonly="!readonly"
                    v-model="data.humedad"
                    @keyup.native.enter="next(6, '0')"
                  ></vue-text-field>
                </v-col>
                <v-col cols="12" md="3" sm="3">
                  <vue-text-field
                    label="Calidad"
                    id="input7"
                    suffix="%"
                    :readonly="!readonly"
                    :rules="false"
                    v-model="data.calidad"
                    @keyup.native.enter="next(7, '0')"
                  ></vue-text-field>
                </v-col>
                <v-col cols="12" md="3" sm="3">
                  <vue-autocomplete-object
                    id="input8"
                    action="deposito/Vact_deposito_siembra"
                    getter="deposito/Vget_deposito_siembra"
                    load="deposito/Vget_deposito_load"
                    validar="iddeposito"
                    label="Deposito"
                    title="desdeposito"
                    :create="true"
                    :readonly="!readonly"
                    @create="formularios.deposito = $event"
                    permission="Deposito"
                    v-model="data.iddeposito"
                    @keyup.native.enter="next(8, data.iddeposito.iddeposito)"
                  />
                </v-col>
                <v-col cols="8" md="6" sm="6">
                  <vue-text-field
                    label="Comentario"
                    id="'input9'"
                    :readonly="!readonly"
                    :rules="false"
                    v-model="data.comentario"
                    @keyup.native.enter="next(9, '0')"
                  ></vue-text-field>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" v-if="data.ensilaje === 1 || data.ensilaje === 2">
                  <AutocompleteItem
                    :label="`Seleccione el tipo de ${data.ensilaje === 2 ? 'Ensilaje' : 'Se. humeda'} que va a producir`"
                    action="Vact_item_agrostock"
                    getter="Vget_item_agrostock"
                    v-model="data.iditem_ensilaje"
                  />
                </v-col>
                <v-col cols="12" class="d-flex justify-end align-center">
                  <v-btn
                    class="mr-3"
                    rounded
                    dark
                    small
                    color="grey"
                    @click="expand = !expand"
                  >
                    Registrar Pago de Alquiler
                    <v-icon>{{
                      expand ? "expand_less" : "expand_more"
                    }}</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="!expand"
                    dark
                    color="red darken-1"
                    :disabled="!readonly"
                    rounded
                    @click="guardarCosecha(false)"
                    >Guardar sin Alquiler</v-btn
                  >
                </v-col>
                <v-col cols="12">
                  <Alquilar
                    v-if="expand"
                    :porcentaje="data.porcentaje"
                    :cantidad="data.cantidad"
                    :produccion="data.produccion"
                    :precio="data.precio"
                    :descstock="data.descstock"
                    @descstock="data.descstock = $event"
                    @porcentaje="data.porcentaje = $event"
                    @cantidad="data.cantidad = $event"
                  />
                </v-col>
              </v-row>
              <v-card-actions v-if="expand">
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  :disabled="!readonly"
                  outlined
                  @click="guardarCosecha(true)"
                  >Guardar con Alquiler</v-btn
                >
              </v-card-actions>
              <v-progress-linear :height="2" value="100"></v-progress-linear>
            </v-form>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="cerrar()">
            <v-icon>clear</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-divider class="mx-2" inset vertical></v-divider>
          <v-btn
            color="red"
            :disabled="!readonly"
            v-if="permission.can_delete"
            text
            @click="dialog.delete = true"
            >Eliminar</v-btn
          >
        </v-card-actions>
      </v-card>
      <v-dialog v-model="dialog.delete" persistent max-width="400">
        <v-card>
          <v-card-title>
            <v-icon left>info</v-icon>
            <span class="title font-weight-light">Ventana de Confirmacion</span>
          </v-card-title>
          <v-card-text>
            Desea eliminar la Actividad Nro
            <strong>{{ data.idcosecha }}?</strong>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="red" text="text" @click="dialog.delete = false">
              <v-icon>clear</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" text="text" @click="eliminarCosecha()">
              <v-icon>done</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-overlay :value="dialog.isLoading">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-dialog>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { mensaje } from "@/services/notify.service";
import { getPermission } from "@/services/storage/permissionStorage.service";
import Alquilar from "./Alquiler";
import { ZAFRA } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";
import CopyMove from "../Siembra/CopyMove";
export default {
  components: {
    AutocompleteItem: () => import("@/views/Item/Item/AutocompleteItemVivo2"),
    Alquilar,
    CopyMove,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    idcosecha: {
      type: Number,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    Vget_item_siembra(val) {
      if (val === null) return null;
      this.data = [];
      this.isValid = false;
      val.forEach((x) => {
        if (x.hectarea) {
          this.isValid = true;
        }
        const cosecha = {
          ...this.cosecha,
          ...{ hectarea: x.hectarea || 0 },
          ...{ iditem: JSON.parse(JSON.stringify(x)) },
          ...{ idcampana: this.idcampana },
        };
        this.data.push(cosecha);
      });
      this.item_siembra = JSON.parse(JSON.stringify(val));
      if (!this.isValid) {
        this.$notify(mensaje.error("No se encontraron hectareas cosechables"));
      }
    },
    Vget_cosecha_data(val) {
      this.data = JSON.parse(JSON.stringify(val));
      if (!this.data.iditem_ensilaje)
        this.data.iditem_ensilaje = { iditem: "" };
      if (this.data.cantidad != null) this.expand = true;
      this.copymove.datos.origen = JSON.parse(JSON.stringify(val));
      this.data.ensilaje = this.semillaChange(this.data.ensilaje, [false, null, true], [0, 1, 2])
    },
    $route() {
      this.cerrar();
    },
  },
  computed: {
    ...mapGetters("item", ["Vget_item_siembra", "Vget_item_load"]),
    ...mapGetters("cosecha", [
      "Vget_cosecha_save_load",
      "Vget_cosecha_data",
      "Vget_cosecha_load",
    ]),
    ...mapGetters("campana", ["Vget_campana_data", "Vget_campana_load"]),

    overlay() {
      if (this.Vget_item_load || this.Vget_cosecha_save_load) return true;
      return false;
    },
  },
  async mounted() {
    const permission = getPermission(ZAFRA).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
    this.idcampana_temp = this.idcampana;
    this.idlote_agri_temp = this.$route.params.id;

    if (this.idcosecha != null) {
      this.Vact_cosecha_data(this.idcosecha);
      this.operacion = false;
    }
  },
  methods: {
    ...mapActions("item", ["Vact_item_siembra"]),
    ...mapActions("siembra", ["Vact_siembra_timeline"]),
    ...mapActions("cosecha", [
      "Vact_cosecha_update",
      "Vact_cosecha_data",
      "Vact_cosecha_del",
    ]),
    ...mapActions("campana", ["Vact_campana_data_byLote"]),
    semillaChange(val, estate, ret) {
      console.log(val)
      if (val == estate[0]) {
        return ret[0]
      } else if (val == estate[1]) {
        return ret[1]
      } else {
        return ret[2]
      }
    },
    async init(idcampana, idlote_agri) {
      this.isValid = true;
      let e = await this.Vact_item_siembra({ idcampana, idlote_agri });
      if (e != true) {
        this.$notify(mensaje.error(e.message));
        this.isValid = false;
      }
    },
    async eliminarCosecha() {
      this.dialog.isLoading = true;
      const a = await this.Vact_cosecha_del({
        id: this.data.idcosecha,
      });
      if (a.estado == true) {
        this.$notify(a.info);
        this.dialog.delete = false;
        this.cerrar();
        const campana = this.cosecha.idcampana;
        const idlote_agri = this.cosecha.idlote_agri.idlote_agri;
        this.Vact_siembra_timeline({ campana, idlote_agri });
        this.Vact_campana_data_byLote(this.$route.params.id);
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.dialog.isLoading = false;
    },
    changeLote(item) {
      this.$router.push({
        path: "/agricultura/campo/detalle/" + item,
      });
      this.init(this.idcampana_temp, this.idlote_agri_temp);
      this.cosecha.idlote_agri.idlote_agri = item;
    },
    next(id, data) {
      if (data != null && data != "") {
        if (id === 3) {
          return this.$refs["input4"][0].$el.querySelector("input").focus();
        }
        if (id === 4) {
          return this.$refs["input5"][0].$el.querySelector("input").focus();
        }
        try {
          id = id + 1;
          document.getElementById("input" + id).focus();
        } catch (e) {
          document.getElementById("aceptar").focus();
        }
      } else {
        document.getElementById("input" + id).focus();
      }
    },
    async guardarCosecha(alquiler) {
      let ensilaje = this.data.ensilaje
      this.data.ensilaje = this.semillaChange(this.data.ensilaje, [0, 1, 2], [false, null, true])
      if (this.data.fechafin === "" || this.data.fechafin === "--") {
        this.data.fechafin = null;
      }
      if (!alquiler) {
        this.data.porcentaje = true;
        this.data.cantidad = null;
        this.data.descstock = false;
      }
      if (this.data.calidad === "") this.data.calidad = null;
      if (this.data.humedad === "") this.data.humedad = null;
      if (this.data.precio === "") this.data.precio = null;
      if (!this.$refs.form.validate()) return this.$notify(mensaje.completar());
      let id = this.idcosecha;
      let a = await this.Vact_cosecha_update({ id, data: this.data });
      this.data.ensilaje = ensilaje
      if (!a.estado) {
        return a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.$notify(a.info);
      this.Vact_campana_data_byLote(this.$route.params.id);
      this.$refs.form.resetValidation();
    },
    cerrar() {
      this.$emit("input", false);
      this.$emit("resp", null);
    },
    async cerrarTodo(e) {
      if (e) {
        this.cerrar();
        await this.Vact_siembra_timeline({ 
          campana: this.data.idcampana, 
          idlote_agri: this.idlote_agri_temp
        });
      }
    }
  },

  data() {
    return {
      copymove: {
        show: false,
        datos: {
          origen: {},
          destino: {}
        },
      },
      validar: [(v) => !!v || "Seleccion un campo"],
      idlote_agri_temp: "",
      idcampana_temp: "",
      item_siembra: [],
      isValid: false,
      operacion: false,
      expand: false,
      permission: {
        can_delete: false,
      },
      dialog: {
        delete: false,
        isLoading: false,
      },
      data: {
        iditem: {
          descitem: "",
        },
        iddeposito: {
          iddeposito: "",
        },
        ensilaje: false
      },
      cosecha: {
        idcosecha: 0,
        precio: "",
        produccion: "",
        hectarea: "",
        isstock: false,
        descstock: false,
        iddeposito: {
          iddeposito: "",
        },
        humedad: "",
        calidad: "",
        comentario: "",
        estado: true,
        fecha: "",
        idlote_agri: { idlote_agri: this.$route.params.id },
      },
      formularios: {
        deposito: false,
      },
    };
  },
};
</script>

<style></style>
