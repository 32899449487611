import { db } from "@/services/db.service";
export default {

    async Vact_deposito_data({commit}){
        commit('deposito_load',true);
        try {
            const data = await db.get('/apideposito/');
            commit('deposito_data',data);
            commit('deposito_load',false);
            return true
        } catch (e) {
            commit('deposito_load',false);
            return false
        } 
    },
    async Vact_deposito_sucursal_data({commit}, idsucursal) {
        commit('deposito_load', true);
        try {
            const data = await db.get('/apideposito/?sucursal=' + idsucursal);
            commit('deposito_data', data);
            commit('deposito_load', false);
            return true
        } catch (e) {
            commit('deposito_load', false);
            return false
        }
    },
    async Vact_deposito_reporte({commit}, iddeposito) {
        commit('deposito_load', true);
        try {
            const data = await db.get('apidepositoreporte/?iddeposito=' + iddeposito)
            commit('deposito_reporte', data);
            commit('deposito_load', false);
            return true
        } catch (e) {
            commit('deposito_load', false);
            return false
        }
    },
    async Vact_deposito_siembra({commit}){
        commit('deposito_load',true);
        try {
            const data = await db.get('/apideposito/?band=siembra');
            commit('deposito_siembra',data);
            commit('deposito_load',false);
            return true
        } catch (e) {
            commit('deposito_load',false);
            return false
        } 
    },
    async Vact_deposito_del({dispatch},id){
        try {
            const data = await db.delete('/apideposito/'+id);
            dispatch('Vact_deposito_data');
            return data
        } catch (e) {
            return false
        } 
    },
    async Vact_deposito_save({dispatch, commit},data){
        commit('deposito_save_load',true);
        try {
            const resp = await db.save('/apideposito/',data);
            commit('deposito_save_load',false);
            dispatch('Vact_deposito_siembra');
            return resp;
        } catch (e) {
            commit('deposito_save_load',false);
            return e
        } 
    },
    async Vact_deposito_update({commit},{id, data}){
        commit('deposito_save_load',true);
        try {
            const resp = await db.update('/apideposito/'+id+'/',data);
            commit('deposito_save_load',false);
            return resp;
        } catch (e) {
            commit('deposito_save_load',false);
            return e
        } 
    },
}

