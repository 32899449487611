
export default {

  Vget_bonificacionagro_data: (state) => {
    return state.bonificacionagro_data
  },
  Vget_bonificacionagro_load: (state) => {
    return state.bonificacionagro_load
  },
  ventaagro_update: (state) => {
    return state.ventaagro_update
  },
  Vget_ventaagro_data: (state) => {
    return state.ventaagro_data
  },
  Vget_ventaagro_data_byId: (state) => {
    return state.ventaagro_data_byId
  },
  Vget_ventaagro_load: (state) => {
    return state.ventaagro_load
  },
  Vget_ventaagro_save_load: (state) => {
    return state.ventaagro_save_load
  },
}
