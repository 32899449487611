export default {
  diagnosticoutero_create(state, request) {
    state.diagnosticoutero_create = request;
  }, 
  diagnosticoutero_update(state, request) {
    state.diagnosticoutero_update = request;
  },   
  diagnosticoutero_reload(state, request) {
    state.diagnosticoutero_reload = request;
  },
  diagnosticoutero_show(state, request) {
    state.diagnosticoutero_show = request;
  },
  diagnosticoutero_key(state, request) {
    state.diagnosticoutero_key = request;
  }
}