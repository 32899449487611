<template>
  <div>
    <Crud
      v-if="crud.add"
      v-model="crud.add"
      :datos="crud.datos"
      @datos="crud.datos = $event"
    />
    <Crud
      v-if="crud.view"
      v-model="crud.view"
      :datos="crud.datos"
      readonly
      @datos="crud.datos = $event"
    />
    <Delete
      v-if="crud.delete"
      v-model="crud.delete"
      :props="crud.datos"
      @datos="crud.datos = $event"
    />
    <v-card>
      <v-alert type="error" v-if="permission_alert"
        >Error!!! Este usuario no tiene permisos en esta ventana</v-alert
      >
      <v-toolbar flat dense>
        <v-toolbar-title>{{ titulo }}</v-toolbar-title>
        <widgets-Favorite :ruta="$route.path" :formulario="titulo" />
        <v-divider class="mx-2" inset vertical></v-divider>

        <v-spacer></v-spacer>
        <c-text-table-search
          v-if="permission.can_view"
          v-model="search"
        ></c-text-table-search>
        <v-toolbar-items>
          <c-btn-table-add
            @click="crud.add = true"
            v-if="permission.can_add"
          ></c-btn-table-add>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table
        :loading="Vget_medicacionmotivo_load"
        :headers="headers"
        :items="Vget_medicacionmotivo_data"
        :search="search"
        class="elevation-1"
        v-if="permission.can_view"
      >
        <template v-slot:[`item.accion`]="props">
          <c-btn-table-view @click="rowselect(props.item)" />
          <c-btn-table-edit @click="mupdate(props.item)" />
          <c-btn-table-delete @click="meliminar(props.item)" />
        </template>
        <template v-slot:no-data>
          <v-alert :value="true" color="info" icon="info" outlined
            >No existe ningun registro</v-alert
          >
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import Crud from "./Create";
import Delete from "./Delete";
import { MEDICACION_MOTIVO } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";
export default {
  components: {
    Crud,
    Delete,
  },
  data() {
    return {
      overlay: false,
      search: "",
      headers: [
        { text: "Descripcion", align: "left", value: "descmotivo" },
        { text: "Accion", value: "accion", sortable: false, align: "right" },
      ],
      datos: [],
      modal: false,
      dialogo: false,
      url: "/apimedicacionmotivo/",
      titulo: MEDICACION_MOTIVO,
      objeto: "",
      index: "",
      dialogoeliminar: false,
      load: false,
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
      crud: {
        delete: false,
        add: false,
        view: false,
        datos: null,
      },
    };
  },

  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.Vact_medicacionmotivo_data();
    const permission = getPermission(MEDICACION_MOTIVO).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  computed: {
    ...mapGetters("medicacionmotivo", [
      "Vget_medicacionmotivo_data",
      "Vget_medicacionmotivo_load",
    ]),
    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },

  methods: {
    ...mapActions("medicacionmotivo", [
      "Vact_medicacionmotivo_data",
      "Vact_medicacionmotivo_del",
    ]),
    rowselect(val) {
      this.crud.datos = JSON.parse(JSON.stringify(val));
      this.crud.view = true;
    },
    meliminar(item) {
      this.crud.datos = JSON.parse(JSON.stringify(item));
      this.crud.delete = true;
    },

    mupdate(val) {
      this.crud.datos = JSON.parse(JSON.stringify(val));
      this.crud.add = true;
    },
  },
};
</script>