<template>
  <div>
    <v-card>
      <v-alert type="error" v-if="permission_alert">
        Error!!! Este usuario no tiene permisos en esta ventana
      </v-alert>
      <v-toolbar flat dense>
        <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
        <widgets-Favorite :ruta="$route.path" :formulario="$route.name" />
        <v-divider class="mx-2" inset vertical></v-divider>

        <v-spacer></v-spacer>
        <c-text-table-search
          v-model="search"
          v-if="permission.can_view"
        ></c-text-table-search>
        <v-toolbar-items>
          <c-btn-table-add
            class="mr-1"
            @click="crud.add = true"
            v-if="permission.can_add"
          ></c-btn-table-add>
          <c-btn-table-reload @click="fetchCosto()"></c-btn-table-reload>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table
        :loading="isLoading"
        :headers="headers"
        :items="getCosto"
        :search="search"
        class="elevation-1"
        v-if="permission.can_view"
      >
        <template v-slot:[`item.accion`]="props">
          <c-btn-table-view @click="rowselect(props.item)"></c-btn-table-view>
          <c-btn-table-edit
            @click="mupdate(props.item)"
            v-if="permission.can_update"
          ></c-btn-table-edit>

          <c-btn-table-delete
            @click="meliminar(props.item)"
            v-if="permission.can_delete"
          ></c-btn-table-delete>
        </template>
      </v-data-table>
      <Delete
        v-if="crud.delete"
        v-model="crud.delete"
        :props="crud.datos"
        @datos="crud.datos = $event"
      />
      <Crud
        v-if="crud.add"
        v-model="crud.add"
        :props="crud.datos"
        @reset="crud.datos = $event"
      />
      <Show
        v-if="crud.show"
        v-model="crud.show"
        :props="crud.datos"
        @reset="crud.datos = $event"
      />
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { COSTO_NACIMIENTO } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";
import Crud from "./Create";
import Show from "./Show";
import Delete from "./Delete";
export default {
  components: {
    Show,
    Crud,
    Delete,
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "Monto", align: "left", value: "montcostonacimiento" },
        { text: "Moneda", align: "left", value: "idmoneda.descmoneda" },
        { text: "Accion", value: "accion", sortable: false, align: "right" },
      ],
      crud: {
        show: false,
        add: false,
        delete: false,
        datos: null,
      },
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
    };
  },

  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.fetchCosto();
    const permission = getPermission(COSTO_NACIMIENTO).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  computed: {
    ...mapGetters("costonacimiento", ["getCosto", "isLoading"]),
    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },

  methods: {
    ...mapActions("costonacimiento", [
      "fetchCosto",
    ]),

    meliminar(item) {
      this.crud.datos = JSON.parse(JSON.stringify(item));
      this.crud.delete = true;
    },
    rowselect(val) {
      this.crud.datos = JSON.parse(JSON.stringify(val));
      this.crud.show = true;
    },
    mupdate(val) {
      this.crud.datos = JSON.parse(JSON.stringify(val));
      this.crud.add = true;
    },
  },
};
</script>
