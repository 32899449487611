import { db } from "@/services/db.service";

export default {
    async Vact_notacreditoventa_data({ commit }) {
        commit('notacreditoventa_load', true);
        try {
            const data = await db.get('/apinotacreditoventa/?active=yes');
            commit('notacreditoventa_data', data);
            commit('notacreditoventa_load', false);
            return true;
        } catch (error) {
            commit('notacreditoventa_load', false);
            return error;
        }
    },
    async Vact_notacreditoventa_presupuesto({ commit }) {
        commit('notacreditoventa_load', true);
        try {
            const data = await db.get('/apinotacreditoventa/');
            commit('notacreditoventa_presupuesto', data)
            commit('notacreditoventa_load', false);
        } catch (error) {
            commit('notacreditoventa_load', false)
            return error;
        }
    },

    async Vact_notacreditoventa_del({ dispatch }, { id, params }) {
        try {
            const data = await db.delete('/apinotacreditoventa/' + id + '/', params);
            dispatch('Vact_notacreditoventa_data');
            return data
        } catch (e) {
            return false
        }
    },
    async Vact_notacreditoventa_save({ commit }, data) {
        commit('notacreditoventa_save_load', true);
        try {
            const resp = await db.save('/apinotacreditoventa/', data);
            commit('notacreditoventa_save_load', false);
            return resp;
        } catch (e) {
            commit('notacreditoventa_save_load', false);
            return e
        }
    },
    async Vact_notacreditoventa_update({ commit }, { id, data }) {
        commit('notacreditoventa_save_load', true);
        try {
            const resp = await db.update('/apinotacreditoventa/' + id + '/', data);
            commit('notacreditoventa_save_load', false);
            return resp;
        } catch (e) {
            commit('notacreditoventa_save_load', false);
            return e
        }
    }
}
