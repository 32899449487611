<template>
  <div>
    <v-dialog
      :value="value"
      max-width="900"
      persistent
      :fullscreen="$vuetify.breakpoint.xs"
    >
      <v-card>
        <v-form ref="formCabecera">
          <v-toolbar flat>
            <v-toolbar-title>
              {{
                readonly
                  ? "Esta seguro de que desea cerrar la Venta?"
                  : operacion
                  ? "Registro de Preventa de Granos"
                  : "Modificar Preventa de Granos"
              }}</v-toolbar-title
            >
            <v-divider class="mx-2" inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-toolbar>
        </v-form>
        <v-container>
          <v-form ref="form" @submit.prevent="next(1, preventaagro.fecha)">
            <v-row dense>
              <v-col cols="12" md="2" sm="3">
                <c-text-date
                  label="Fecha"
                  id="preventaagroInput1"
                  outlined
                  dense
                  :readonly="readonly"
                  v-model="preventaagro.fecha"
                  @focus="setSelection(`preventaagroInput1`)"
                  @keyup.native.enter="next(1, preventaagro.fecha)"
                ></c-text-date>
              </v-col>
              <v-col cols="12" md="3" sm="3">
                <vue-autocomplete-object
                  id="preventaagroInput2"
                  action="campana/Vact_campana_data"
                  getter="campana/Vget_campana_data"
                  load="campana/Vget_campana_load"
                  pk="idcampana"
                  validar="idcampana"
                  :readonly="readonly"
                  label="Seleccione Campana"
                  title="descripcion"
                  permission="Zafra"
                  v-model="preventaagro.idcampana"
                  :create="true"
                  @create="formularios.campana = $event"
                  @sync-input="searchc = $event"
                  @keyup.native.enter="
                    next(2, preventaagro.idcampana.idcampana)
                  "
                />
                <div v-if="formularios.campana">
                  <Agro-Campana-Create
                    v-model="formularios.campana"
                    :sync-input="searchc"
                    @sync-input="
                      (preventaagro.idcampana = $event), next(2, '-')
                    "
                  />
                </div>
              </v-col>
              <v-spacer></v-spacer>

              <v-col cols="12" md="4" sm="4">
                <v-autocomplete
                  :readonly="readonly"
                  dense
                  id="preventaagroInput3"
                  :items="Vget_caja_cajacuenta"
                  label="Seleccione Caja/Cuenta Bancaria de destino"
                  item-text="desc"
                  return-object
                  :rules="[(v) => !!v || 'Este campo es Obligatorio']"
                  :loading="Vget_caja_load"
                  :search-input.sync="search"
                  required
                  outlined
                  v-model="preventaagro.idcajacuentabanco"
                  autocomplete="off"
                  @keyup.enter="
                    next(3, preventaagro.idcajacuentabanco.idcajacuentabanco)
                  "
                  :no-data-text="`No existe ninguna concidencia`"
                >
                  <template
                    v-if="caja_permission || cuentabanco_permission"
                    v-slot:no-data
                  >
                    <v-list-item
                      v-if="caja_permission"
                      @click="formularios.caja = true"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          >Click para agregar
                          <strong>CAJA</strong></v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-if="cuentabanco_permission"
                      @click="formularios.cuentabanco = true"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          >Click para agregar
                          <strong>CUENTA BANCARIA</strong></v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <div v-if="formularios.caja">
                  <FormCaja :sync-input="search" v-model="formularios.caja" />
                </div>
                <div v-if="formularios.cuentabanco">
                  <FormCuentaBanco
                    :sync-input="search"
                    v-model="formularios.cuentabanco"
                  />
                </div>
              </v-col>
            </v-row>
          </v-form>
          <v-form ref="formdetalle">
            <v-row dense>
              <v-col cols="12" md="5" sm="5">
                <vue-autocomplete-object
                  id="preventaagroInput4"
                  action="deposito/Vact_deposito_siembra"
                  getter="deposito/Vget_deposito_siembra"
                  load="deposito/Vget_deposito_load"
                  validar="iddeposito"
                  label="Deposito"
                  title="desdeposito"
                  :create="true"
                  :readonly="readonly"
                  @create="formularios.deposito = $event"
                  @sync-input="searchd = $event"
                  permission="Deposito"
                  v-model="preventaagrodet.iddeposito"
                  @keyup.native.enter="next(4, preventaagrodet.iddeposito)"
                />
                <div v-if="formularios.deposito">
                  <Item-Deposito-create
                    v-model="formularios.deposito"
                    :sync-input="searchd"
                    @sync-input="
                      (preventaagrodet.iddeposito = $event), next(4, '-')
                    "
                  />
                </div>
              </v-col>

              <v-col cols="12" md="3" sm="3">
                <vue-text-currency
                  label="Cantidad en Kg"
                  id="preventaagroInput5"
                  type="number"
                  :readonly="readonly"
                  @focus="setSelection(`preventaagroInput5`)"
                  :rules="true"
                  v-model="preventaagrodet.cantidad"
                  @keyup.native.enter="next(5, preventaagrodet.cantidad)"
                ></vue-text-currency>
              </v-col>

              <v-col cols="12" md="3" sm="3">
                <vue-text-currency
                  label="Precio por Tn"
                  :rules="true"
                  :readonly="readonly"
                  v-model="preventaagrodet.precio"
                  @focus="setSelection(`preventaagroInput6`)"
                  id="preventaagroInput6"
                  @keyup.native.enter="next(6, preventaagrodet.precio)"
                ></vue-text-currency>
              </v-col>

              <v-spacer></v-spacer>
              <v-btn
                id="preventaagroInput7"
                outlined
                color="primary"
                fab
                small
                :disabled="readonly"
                @click="addTable()"
              >
                <v-icon>add</v-icon>
              </v-btn>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="preventaagro.preventaagrodet"
                  :items-per-page="100"
                  hide-default-footer
                >
                  <template v-slot:item="props">
                    <tr>
                      <td class="text-xs-letf">
                        {{ props.item.iditem.descitem }}
                      </td>
                      <td class="text-xs-letf">
                        {{ props.item.iddeposito.desdeposito }}
                      </td>
                      <td class="text-xs-letf">
                        {{ currencyFormat(props.item.precio) }}
                      </td>
                      <td class="text-xs-letf">
                        {{ currencyFormat(props.item.preciokg) }}
                      </td>
                      <td class="text-xs-letf">
                        {{ currencyFormat(props.item.cantidad) }}
                      </td>
                      <td class="text-end">
                        {{ currencyFormat(props.item.total) }}
                      </td>
                      <td class="text-end">
                        <v-icon
                          :disabled="readonly"
                          class="mr-2"
                          @click="editarDetalle(props.item)"
                          >edit</v-icon
                        >
                        <v-icon
                          :disabled="readonly"
                          small
                          @click="eliminarDetalle(props.item)"
                          >delete</v-icon
                        >
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-form>
          <v-divider class="mb-2"></v-divider>
          <v-row dense no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="6" sm="3">
              <vue-text-currency
                label="Total Preventa"
                :rules="false"
                :readonly="true"
                :length="4"
                :value="totalEgreso"
              ></vue-text-currency>
            </v-col>
            <v-col cols="12">
              <vue-text-field
                label="Comentario"
                :rules="false"
                :readonly="readonly"
                v-model="preventaagro.comentario"
              ></vue-text-field>
            </v-col>
            <v-btn color="red" text @click="cerrar()">
              <v-icon>clear</v-icon>
            </v-btn>
            <v-spacer></v-spacer>

            <v-btn
              :disabled="readonly"
              color="primary"
              text
              @click="guardarEgreso()"
            >
              <v-icon>done</v-icon>
            </v-btn>
            <v-btn
              v-if="readonly"
              color="red"
              text
              outlined
              @click="cerrarVenta()"
            >
              Cerrar Venta
            </v-btn>
          </v-row>
        </v-container>
        <c-overlay :value="Vget_preventaagro_save_load"></c-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { current_date } from "@/services/util/date.service";
import { focus } from "@/services/focus.service";
import { mensaje } from "@/services/notify.service";
import { getPermission } from "@/services/storage/permissionStorage.service";

import FormCaja from "@/views/Apertura/Caja/Create";
import FormCuentaBanco from "@/views/Apertura/Cuentabanco/Create";

import { ITEM, CAJA, CUENTA_BANCO } from "@/constants/forms";
import { PUEDE_AGREGAR } from "@/constants/permissions";

export default {
  components: {
    FormCaja,
    FormCuentaBanco,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    datos: {
      type: [Object, Array],
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    $route(to, from) {
      if (from.path === "/preventaagro") return this.cerrar();
    },
  },

  computed: {
    ...mapGetters("preventaagro", ["Vget_preventaagro_save_load"]),
    ...mapGetters("caja", ["Vget_caja_cajacuenta", "Vget_caja_load"]),
    ...mapGetters("campana", ["Vget_campana_data", "Vget_campana_load"]),

    item_permission() {
      return getPermission(ITEM).permiso[PUEDE_AGREGAR];
    },
    caja_permission() {
      return getPermission(CAJA).permiso[PUEDE_AGREGAR];
    },
    cuentabanco_permission() {
      return getPermission(CUENTA_BANCO).permiso[PUEDE_AGREGAR];
    },
    totalEgreso() {
      let total = 0;
      this.preventaagro.preventaagrodet.forEach((x) => {
        total = (Number(total) + Number(x.total)).toFixed(4);
      });
      return total;
    },
  },
  mounted() {
    this.next(0, "-");
    this.Vact_caja_cajacuenta();
    if (this.datos != null) {
      this.asignarDatos(this.datos);
      this.operacion = false;
    }
  },
  methods: {
    ...mapActions("preventaagro", [
      "Vact_preventaagro_save",
      "Vact_preventaagro_data",
      "Vact_preventaagro_update",
      "Vact_preventaagro_convertir",
      "Vact_preventaagro_data_byId",
    ]),
    ...mapActions("ventaagro", ["Vact_ventaagro_data"]),
    ...mapActions("caja", ["Vact_caja_cajacuenta"]),

    asignarPrecio() {
      if (this.preventaagrodet.iditem.precitem)
        this.preventaagrodet.precio = this.preventaagrodet.iditem.precitem;
    },
    asignarDatos(val) {
      val.preventaagrodet.forEach((x) => {
        x.preciokg = Number(x.precio) / 1000;
        x.total = x.preciokg * Number(x.cantidad);
      });
      this.preventaagro = JSON.parse(JSON.stringify(val));
    },
    setSelection(id) {
      setTimeout(() => {
        let input = document.getElementById(id);
        input.focus();
        input.setSelectionRange(0, 10);
      }, 200);
    },
    currencyFormat(value) {
      return new Intl.NumberFormat({ maximumSignificantDigits: 3 }).format(
        value
      );
    },
    async next(id, data) {
      if (data === "" || data <= 0) return null;
      focus.nextidName("preventaagroInput", id, data);
    },
    editarDetalle(val) {
      this.preventaagro.idcampana.iditem = JSON.parse(JSON.stringify(val.iditem))
      const index = this.preventaagro.preventaagrodet.indexOf(val);
      this.preventaagro.preventaagrodet.splice(index, 1);
      this.preventaagrodet = val;
    },
    eliminarDetalle(val) {
      const index = this.preventaagro.preventaagrodet.indexOf(val);
      this.preventaagro.preventaagrodet.splice(index, 1);
    },

    limpiarCampos() {
      this.preventaagro = JSON.parse(JSON.stringify(this.default));
      this.preventaagrodet = JSON.parse(
        JSON.stringify(this.preventaagrodet_default)
      );
      this.$refs.formdetalle.resetValidation();
    },

    addTable() {
      if (!this.$refs.formdetalle.validate()) return null;
      this.preventaagrodet.iditem = JSON.parse(JSON.stringify(this.preventaagro.idcampana.iditem));

      this.preventaagrodet.preciokg = Number(this.preventaagrodet.precio) / 1000;
      this.preventaagrodet.total = this.preventaagrodet.preciokg * Number(this.preventaagrodet.cantidad);

      this.preventaagro.preventaagrodet.push(this.preventaagrodet);
      this.preventaagrodet = JSON.parse(
        JSON.stringify(this.preventaagrodet_default)
      );
      this.$refs.formdetalle.resetValidation();
      setTimeout(() => this.next(3, "-"), 200);
    },
    async cerrarVenta() {
      const a = await this.Vact_preventaagro_convertir({
        id: this.preventaagro.idventaagro,
        data: this.preventaagro,
      });
      if (!a.estado) {
        return a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.$notify(a.info);
      this.$refs.form.resetValidation();
      this.limpiarCampos();
      this.Vact_ventaagro_data();
      this.cerrar();
    },
    async guardarEgreso() {
      if (
        !this.$refs.form.validate() ||
        this.preventaagro.preventaagrodet.length === 0
      )
        return this.$notify(mensaje.completar());
      let a;
      if (this.operacion) {
        a = await this.Vact_preventaagro_save(this.preventaagro);
      } else {
        let id = this.preventaagro.idventaagro;
        a = await this.Vact_preventaagro_update({
          id,
          data: this.preventaagro,
        });
      }
      if (!a.estado) {
        return a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.operacion = true;
      this.$notify(a.info);
      this.$refs.form.resetValidation();
      this.limpiarCampos();
      this.next(0, "-");
    },
    cerrar() {
      this.$emit("input", false);
      this.$emit("datos", null);
      this.$emit("readonly", false);
      this.Vact_preventaagro_data();
      this.limpiarCampos();
    },
  },

  data() {
    return {
      search: "",
      searchc: "",
      searchd: "",
      operacion: true,
      formularios: {
        campana: false,
        deposito: false,
        caja: false,
        cuentabanco: false,
      },
      preventaagro: {
        idventaagro: 0,
        fecha: current_date(),
        idcampana: {
          idcampana: "",
        },
        idcajacuentabanco: {
          idcajacuentabanco: "",
        },
        comentario: "",
        estado: true,
        preventaagrodet: [],
        venta: false,
      },
      default: {
        idventaagro: 0,
        fecha: current_date(),
        idcampana: {
          idcampana: "",
        },
        idcajacuentabanco: {
          idcajacuentabanco: "",
        },
        comentario: "",
        estado: true,
        preventaagrodet: [],
        venta: false,
      },
      preventaagrodet: {
        iddeposito: {
          iddeposito: "",
        },
        iditem: {
          iditem: "",
          descitem: "",
        },
        cantidad: "",
        precio: "",
      },
      preventaagrodet_default: {
        iddeposito: {
          iddeposito: "",
        },
        iditem: {
          iditem: "",
          descitem: "",
        },
        cantidad: "",
        precio: "",
      },

      headers: [
        {
          text: "Item",
          sortable: false,
        },
        {
          text: "Deposito",
          sortable: false,
        },
        { text: "Precio Tn", sortable: false },
        { text: "Precio Kg", sortable: false },
        { text: "Cantidad Kg", sortable: false },
        { text: "SubTotal", sortable: false, align: "right" },
        { text: "Accion", sortable: false, align: "right" },
      ],
    };
  },
};
</script>
