<template>
  <div>

    <v-alert type="error" v-if="permission_alert">
      Error!!! Este usuario no tiene permisos en esta ventana
    </v-alert> 
    <v-data-table
      :headers="headers"
      :search="search"
      :loading="isCiudadLoading"
      :items="getCiudad"
      :items-per-page="15"
    >
      <template v-slot:top>
        <v-toolbar flat dense>
          <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <c-text-table-search v-model="search"></c-text-table-search>
          <v-toolbar-items>
            <c-btn-table-reload @click="fetchCiudad()"></c-btn-table-reload>
          </v-toolbar-items>
        </v-toolbar>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mensaje } from "@/services/notify.service";

export default {
   data: () => ({
    search: '',
    headers: [
      {
        text: "Codigo",
        align: "start",
        value: "idciudad",
      },
      { text: "Descripcion", value: "descciudad" },
      { text: "Nacionalidad", value: "idnacionalidad.descnacionalidad" }
    ],
     permission: {
        can_add: true,
        can_view: true,
        can_update: true,
        can_delete: true,
      },
  }),


  created() {
    this.$store.commit('SET_LAYOUT', 'principal-layout');
    this.fetchCiudad();
  },
  computed: {
    ...mapGetters("ciudad", ["getCiudad", "isCiudadLoading"]),
    permission_alert(){
      return mensaje.permission(this.permission);
    }
  },

  methods: {
     ...mapActions("ciudad", ["fetchCiudad"]),
  }
};
</script>
