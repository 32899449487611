<template>
  <div>
    <v-dialog :value="value" max-width="650" persistent>
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>{{
            !editable ? "Agregar Tipo de Pico" : "Modificar Tipo de pico"
          }}</v-toolbar-title>
        </v-toolbar>
        <v-container>
          <v-form ref="form" @submit.prevent="next(1, data.descripcion)">
            <v-row dense>
              <v-col cols="12">
                <c-text-field
                  dense
                  outlined
                  label="Descripcion"
                  ref="tipopico1"
                  v-model="data.descripcion"
                  @keyup.native.enter="next(1, data.descripcion)"
                ></c-text-field>
              </v-col>
              <v-col cols="12">
                <c-text-field
                  dense
                  outlined
                  label="Abreviatura"
                  ref="tipopico2"
                  v-model="data.abreviatura"
                  @keyup.native.enter="next(2, data.abreviatura)"
                ></c-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <c-btn-crud-close @click="cancelar()"></c-btn-crud-close>
          <v-spacer></v-spacer>
          <c-btn-crud-done ref="tipopico3" @click="guardar()"></c-btn-crud-done>
        </v-card-actions>
        <c-overlay :value="isTipoPicoLoading"></c-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
    },
    "sync-input": {
      type: [String, Number],
      default: null,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sync: null,
      data: {
        idtipo_pico: "",
        descripcion: "",
        abreviatura: "",
      },
      default: {
        idtipo_pico: "",
        descripcion: "",
        abreviatura: "",
      },
    };
  },
  watch: {
    $route() {
      return this.cancelar();
    },
  },
  computed: {
    ...mapGetters("tipopico", ["isTipoPicoLoading"]),
  },
  mounted() {
    if (this.editable) this.data = JSON.parse(JSON.stringify(this.props));
    setTimeout(() => this.next(0, "-"), 200);
    if (this.syncInput != null) {
      this.data.descripcion = this.syncInput;
      this.sync = this.syncInput;
    }
  },
  methods: {
    ...mapActions("tipopico", [
      "setTipoPico",
      "setTipoPicoUpdate",
      "fetchTipoPico",
    ]),
    next(id, data) {
      if (data != "")
        try {
          this.$refs[`tipopico${id + 1}`].focus();
        } catch (error) {
          this.$refs[`tipopico${id + 1}`].$el.focus();
        }
    },
    cancelar() {
      this.$emit("input", false);
      this.$emit("datos", null);
    },

    async guardar() {
      if (!this.$refs.form.validate()) return null;
      const response = this.editable
        ? await this.setTipoPicoUpdate({
            data: this.data,
            id: this.data.idtipo_pico,
          })
        : await this.setTipoPico(this.data);
      if (response.success) {
        this.fetchTipoPico();
        if (this.sync != null) {
          this.$emit("sync-input", response.data.id);
          this.$emit("next-focus");
          return this.cancelar();
        }
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();
        if (this.editable) return this.cancelar();
        this.next(0, "-");
      }
    },
  },
};
</script>
