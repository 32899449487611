<template>
    <div>
        <Help v-model="help" :content="todos"/>
        <Crud v-if="crud.add" v-model="crud.add" :datos="crud.datos" @datos="crud.datos = $event" />
        <Crud v-if="crud.view"  v-model="crud.view" :props="crud.datos" editable @datos="crud.datos = $event"/>
        <Delete v-if="crud.delete" v-model="crud.delete" :props="crud.datos" @datos="crud.datos = $event"/>
        <v-alert type="error" v-if="permission_alert">
            Error! Este usuario no tiene permiso en esta ventana
        </v-alert>
        <v-toolbar flat dense>
            <v-toolbar-items>
                <v-tabs v-model="tab" right>
                    <v-tab href="#tab-1" @click="finanza_get(`movimiento`)">Movimientos</v-tab>
                    <v-tab href="#tab-2" @click="finanza_get(`transferencia`)">Transferencias</v-tab>
                </v-tabs>
            </v-toolbar-items>
            <v-spacer></v-spacer>
            <c-text-table-search class="mr-1" v-if="permission.can_view" v-model="search"></c-text-table-search>
            <v-toolbar-items>
                <c-btn-table-help @click="help = true"></c-btn-table-help>
                <c-btn-table-add class="ml-1" @click="crud.add = true" v-if="permission.can_add"></c-btn-table-add>
                <c-btn-table-reload class="ml-1" @click="fetchMovimiento(type)"></c-btn-table-reload>
            </v-toolbar-items>
        </v-toolbar>
        <v-tabs-items v-model="tab">
            <v-tab-item value="tab-1">
                <v-data-table :headers="headers" :items="getMovimiento" :loading="isLoadingMovimiento" :search="search"
                    v-if="permission.can_view">
                    <template v-slot:[`item.idfinanza_cuenta.nombre`]="props">
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ `${props.item.idfinanza_cuenta.nombre}` }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ toCurrency(props.item.monto_movimiento) }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                    <template v-slot:[`item.activo`]="props">
                        <v-chip :color="props.item.activo ? 'green' : 'red'">
                            <v-icon dark>
                                {{ `${props.item.activo ? 'add' : 'remove'}` }}
                            </v-icon>
                        </v-chip>
                    </template>
                    <template v-slot:[`item.idfinanza_categoria`]="props">
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ props.item.idfinanza_categoria.nombre }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ toCurrency(props.item.monto) }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                    <template v-slot:[`item.accion`]="props">
                        <c-btn-table-edit @click="update_item(props.item)" />
                        <c-btn-table-delete @click="delete_item(props.item)" />
                    </template>
                </v-data-table>
            </v-tab-item>
            <v-tab-item value="tab-2">
                <v-data-table :headers="headers_transferencia" :items="getTransferencia" :loading="isLoadingMovimiento" :search="search"
                    v-if="permission.can_view">
                    <template v-slot:[`item.idfinanza_cuenta.nombre`]="props">
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ `${props.item.idfinanza_cuenta.nombre}` }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ toCurrency(props.item.monto_movimiento) }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                    <template v-slot:[`item.activo`]="props">
                        <v-chip :color="props.item.activo ? 'green' : 'green'">
                            <v-icon dark>
                                {{ `${props.item.activo ? 'chevron_right' : 'chevron_right'}` }}
                            </v-icon>
                        </v-chip>
                    </template>
                    <template v-slot:[`item.idfinanza_destino`]="props">
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ props.item.idfinanza_destino.nombre }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ toCurrency(props.item.monto) }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </template>
                    <template v-slot:[`item.accion`]="props">
                        <c-btn-table-edit @click="update_item(props.item)" />
                        <c-btn-table-delete @click="delete_item(props.item)" />
                    </template>
                </v-data-table>
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { currency } from "@/services/util/number.service";
import { mensaje } from "@/services/notify.service";
import { FINANZA } from "@/constants/forms";
import {
    PUEDE_AGREGAR,
    PUEDE_EDITAR,
    PUEDE_ELIMINAR,
    PUEDE_LISTAR,
} from "@/constants/permissions";
import Crud from "./Crud";
import Delete from "./Delete";
import Help from "../Help.vue";
import { MOVIMIENTOS } from "@/constants/finanzas";
export default {
    components: {
        Crud,
        Delete,
        Help
    },
    data() {
        return {
            help: false,
            todos: null,
            tab: null,
            title: 'Movimiento',
            search: "",
            headers: [
                { text: "Codigo", align: "left", value: "idfinanza_movimiento" },
                { text: "Fecha", align: "left", value: "fecha" },
                { text: "Origen", align: "right", value: "idfinanza_cuenta.nombre", sortable: false },
                { text: "", align: "left", value: "activo", sortable: false },
                { text: "Categoria", align: "right", value: "idfinanza_categoria", sortable: false },
                { text: "Accion", align: "right", value: "accion", sortable: false },
            ],
            headers_transferencia: [
                { text: "Codigo", align: "left", value: "idfinanza_movimiento" },
                { text: "Fecha", align: "left", value: "fecha" },
                { text: "Origen", align: "right", value: "idfinanza_cuenta.nombre", sortable: false },
                { text: "", align: "left", value: "activo", sortable: false },
                { text: "Destino", align: "right", value: "idfinanza_destino", sortable: false },
                { text: "Accion", align: "right", value: "accion", sortable: false },
            ],
            permission: {
                can_add: false,
                can_view: false,
                can_update: false,
                can_delete: false,
            },
            crud: {
                delete: false,
                add: false,
                view: false,
                datos: null,
            },
            type: 'movimiento'
        }
    },
    computed: {
        ...mapGetters("finanza", ["getMovimiento", "getTransferencia", "isLoadingMovimiento"]),
        permission_alert() {
            return mensaje.permission(this.permission);
        },
    },
    created() {
        this.$store.commit("SET_LAYOUT", "principal-layout");
        this.todos = MOVIMIENTOS
        const permission = getPermission(FINANZA).permiso;
        this.permission = {
            can_add: permission[PUEDE_AGREGAR],
            can_view: permission[PUEDE_LISTAR],
            can_update: permission[PUEDE_EDITAR],
            can_delete: permission[PUEDE_ELIMINAR],
        };
        this.fetchMovimiento(this.type);
    },
    methods: {
        ...mapActions("finanza", ["fetchMovimiento"]),
        update_item(data) {
            this.crud.datos = JSON.parse(JSON.stringify(data));
            this.crud.view = true;
        },
        delete_item(item) {
            this.crud.datos = JSON.parse(JSON.stringify(item));
            this.crud.delete = true;
        },
        finanza_destino(categoria, destino) {
            if (categoria.length > 0) {
                return categoria[0].idfinanza_categoria.nombre;
            }
            return destino[0].idfinanza_cuenta.nombre;
        },
        finanza_monto(categoria, destino) {
            if (categoria.length > 0) {
                return categoria[0].monto;
            }
            return destino[0].monto;
        },
        finanza_get(type) {
            this.fetchMovimiento(type)
            this.type = type
        },
        toCurrency(value) {
            return currency(value)
        }
    }
}
</script>