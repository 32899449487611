<script>
export default {
  props: {
    google: {
      type: Object,
      required: true,
    },
    map: {
      type: Object,
      required: true,
    },
    value: {
      type: [Object, String],
    },
    props: {
      type: Object,
    },
  },
  data: () => ({
    drawingManager: "",
    selectedShape: [],
    marker_editable: [],
    marker_selected: [],
    polygon_selected: [],
  }),
  mounted() {
    this.mostrarDrawing();
    if (this.props.poligono.length > 0) this.viewSelection(this.props.poligono);
  },
  methods: {
    initialize() {
      this.drawingManager = new this.google.maps.drawing.DrawingManager({
        drawingControl: true,
        drawingControlOptions: {
          position: this.google.maps.ControlPosition.BOTTOM_CENTER,
          drawingModes: ["polygon"],
        },
        polygonOptions: {
          fillOpacity: 0.0,
          strokeColor: "red",
          editable: true,
        },
      });
      this.drawingManager.setMap(this.map);
      var customControlDiv = document.createElement("div");
      this.CustomControl(customControlDiv, this.map);

      customControlDiv.index = 1;
      this.map.controls[this.google.maps.ControlPosition.BOTTOM_CENTER].push(
        customControlDiv
      );
    },
    CustomControl(controlDiv) {
      var controlUI = document.createElement("div");
      controlUI.style.float = "left";
      controlUI.style.lineHeight = 0;
      controlUI.style.marginLeft = "-6px";
      controlUI.style.marginBottom = "5px";
      controlUI.style.marginTop = "0px";

      controlDiv.appendChild(controlUI);

      var controlText = document.createElement("div");
      controlText.style.direction = "ltr";
      controlText.style.overflow = "hidden";
      controlText.style.textAlign = "left";
      controlText.style.position = "relative";
      controlText.style.color = "rgb(86, 86, 86)";
      controlText.style.fontFamily = "Roboto, Arial, sans-serif";
      controlText.style.userSelect = "none";
      controlText.style.fontSize = "11px";
      controlText.style.backgroundColor = "rgb(255, 255, 255)";
      controlText.style.padding = "6px";
      controlText.style.borderBottomRightRadius = "2px";
      controlText.style.borderTopRightRadius = "2px";
      controlText.style.backgroundClip = "padding-box";
      controlText.style.boxShadow = "rgba(0, 0, 0, 0.3) 0px 1px 4px -1px";
      controlText.style.borderLeft = "0px";
      controlText.style.fontWeight = 500;
      controlText.innerHTML =
        '<button><img src="https://img.icons8.com/material-sharp/12/000000/delete-sign.png"/></button>';

      controlUI.appendChild(controlText);

      let self = this;
      controlUI.addEventListener("click", () => {
        self.limpiarDrawing();
      })
      /*
      Sustituido por obsolescencia 
      this.google.maps.event.addDomListener(controlUI, "click", function() {
        self.limpiarDrawing();
      });
      */
    },
    obtenerDatos(e) {
      if (e.type === "polygon") {
        let geo_json_coordinate = [[]];
        let coordenadas = JSON.parse(
          JSON.stringify(e.overlay.getPath().getArray())
        );
        coordenadas.forEach((x) => {
          geo_json_coordinate[0].push([x.lat, x.lng]);
        });
        geo_json_coordinate[0].push(geo_json_coordinate[0][0]);
        this.$emit("coordinates", geo_json_coordinate);
      }
    },
    setSelection(shape) {
      this.clearSelection();
      shape.setEditable(true);
      this.selectedShape.push(shape);
    },
    viewSelection(coordenadas) {
      this.limpiarDrawing();
      let paths = [];
      coordenadas[0].forEach((x) => {
        paths.push({ lat: x[0], lng: x[1] });
      });
      const polygon = new this.google.maps.Polygon({
        paths: paths,
        fillOpacity: 0.0,
        strokeColor: "yellow",
      });
      polygon.setMap(this.map);
      this.polygon_selected.push(polygon);

      const evento = { type: "polygon", overlay: polygon };
      this.overlayCompleteListener(evento);

      let bounds = new this.google.maps.LatLngBounds();
      paths.forEach((x) => {
        bounds.extend(new this.google.maps.LatLng(x.lat, x.lng));
      });
      this.map.setCenter(bounds.getCenter());
      this.map.setZoom(16);
    },
    clearSelection() {
      if (this.selectedShape.length > 0) {
        this.selectedShape.forEach((x) => {
          x.setEditable(false);
          x = null;
        });
      }
    },
    overlayCompleteListener(e) {
      {
        let self = this;
        let e_change = e;
        this.obtenerDatos(e);
        let newShape = e.overlay;
        newShape.type = e.type;
        this.setSelection(newShape);

        this.google.maps.event.addListener(
          e.overlay.getPath(),
          "insert_at",
          () => {
            self.obtenerDatos(e_change);
          }
        );
        this.google.maps.event.addListener(
          e.overlay.getPath(),
          "set_at",
          () => {
            self.obtenerDatos(e_change);
          }
        );
        this.drawingManager.setDrawingMode(null);
      }
    },
    limpiarDrawing() {
      this.deleteSelectedShape();
      this.marker_editable.forEach((x) => {
        x.setMap(null);
      });
    },
    deleteSelectedShape() {
      if (this.selectedShape.length > 0) {
        this.selectedShape.forEach((x) => {
          x.setMap(null);
        });
      }
    },
    mostrarDrawing() {
      this.initialize();
      let self = this;
      let identificador = 1;
      this.google.maps.event.addListener(
        self.drawingManager,
        "overlaycomplete",
        (e) => {
          identificador = identificador + 1;
          self.overlayCompleteListener(e, identificador);
        }
      );
    },
  },
  // eslint-disable-next-line
  render() {},
};
</script>
