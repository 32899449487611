<template>
  <div>
    <v-dialog
      v-model="dialogo"
      max-width="800px"
      persistent
      @keydown.esc="cancelar()"
    >
      <v-card>
        <v-form ref="form" lazy-validation>
          <v-toolbar flat dense>
            <v-toolbar-title>{{
              operacion ? "Registrar Nuevo Contacto" : "Modificar Contacto"
            }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-row dense>
              <v-col cols="10" sm="3" md="3">
                <SearchFirebase
                  outlined
                  ref="contacto1"
                  dense
                  label="RUC"
                  v-model="data.ruccontacto"
                  @fetch="mergeContacto($event)"
                />
              </v-col>
              <v-col cols="2" sm="1" md="1">
                <c-text-field
                  dense
                  ref="contacto2"
                  :rules="[(v) => v !== '' || 'Campo requerido']"
                  v-model="data.docucontactos"
                  label="DV"
                  outlined
                  @keyup.native.enter="next(2, data.docucontactos)"
                ></c-text-field>
              </v-col>
              <v-col cols="12" sm="8" md="8">
                <c-text-field
                  dense
                  ref="contacto3"
                  v-model="data.desccontacto"
                  label="Razon Social"
                  outlined
                  @keyup.native.enter="next(3, data.desccontacto)"
                ></c-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <c-text-field
                  dense
                  ref="contacto4"
                  v-model="data.telecontacto"
                  label="Telefono"
                  outlined
                  :rules="[]"
                  @keyup.native.enter="next(4, 'a')"
                ></c-text-field>
              </v-col>
              <v-col cols="12" sm="5" md="5">
                <c-text-field
                  dense
                  ref="contacto5"
                  v-model="data.correo"
                  label="Correo"
                  :rules="[]"
                  outlined
                  @keyup.native.enter="next(5, 'a')"
                ></c-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <c-text-date
                  label="Fecha Nac"
                  ref="contacto6"
                  v-model="data.fechcontacto"
                  dense
                  outlined
                  :rules="[]"
                  @keyup.native.enter="next(6, 'a')"
                ></c-text-date>
              </v-col>
              <v-col cols="12">
                <c-text-field
                  dense
                  ref="contacto7"
                  v-model="data.direcontacto"
                  label="Direccion"
                  outlined
                  :rules="[]"
                  @keyup.native.enter="next(7, 'a')"
                ></c-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <AutocompleteNacionalidad
                  ref="contacto8"
                  dense
                  outlined
                  :rules="[]"
                  label="Seleccione Nacionalidad"
                  v-model="data.idnacionalidad"
                  @keyup.native.enter="
                    next(8, 'a'), $refs.contacto8.isMenuActive()
                  "
                />
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <Autocomplete-departamento
                  v-model="data.iddepartamento"
                  ref="contacto9"
                  :rules="[]"
                  dense
                  outlined
                  :filter-country="idnacionalidad"
                  label="Seleccione Departamento"
                  @keyup.native.enter="
                    next(9, 'a'), $refs.contacto9.isMenuActive()
                  "
                />
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <AutocompleteCiudad
                  v-model="data.idciudad"
                  ref="contacto10"
                  :rules="[]"
                  dense
                  outlined
                  :filter-country="idnacionalidad"
                  :filter-departament="iddepartamento"
                  label="Seleccione Ciudad"
                  @keyup.native.enter="$refs.contacto10.isMenuActive()"
                />
              </v-col>
              <v-col cols="12">
                <v-row justify="space-around" dense>
                  <v-checkbox
                    v-model="check_cliente"
                    label="Cliente"
                    v-if="permission_cliente"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="check_proveedor"
                    label="Proveedor"
                    v-if="permission_proveedor"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="check_transportadora"
                    label="Transportadora"
                    v-if="permission_transporadora"
                  ></v-checkbox>
                  <v-checkbox
                    v-model="check_conductor"
                    label="Conductor"
                    v-if="permission_conductor"
                  ></v-checkbox>
                </v-row>
              </v-col>
              <v-col cols="12" v-if="check_cliente">
                <v-autocomplete
                  :items="Vget_contacto_data_cliente"
                  item-text="desccontacto"
                  item-value="idcontacto"
                  return-object
                  ref="contacto14"
                  autocomplete="off"
                  outlined
                  dense
                  v-model="idcontacto"
                  :loading="Vget_contacto_load"
                  label="Selecciones Representante"
                  @keyup.enter="
                    next(14, 'a'), ($refs.contacto14.isMenuActive = false)
                  "
                >
                  <template v-slot:no-data>
                    <v-list-item dense>
                      <v-list-item-content>
                        <v-list-item-title
                          >No se encontro ningun Cliente</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="12" md="6" sm="6" v-if="check_cliente">
                <vue-autocomplete-moneda-moneda
                  label="Seleccione Moneda"
                  ref="contacto15"
                  v-model="cliente.idmoneda"
                  @keyup.native.enter="next(15, cliente.idmoneda)"
                ></vue-autocomplete-moneda-moneda>
              </v-col>
              <v-col cols="12" md="6" sm="6" v-if="check_cliente">
                <c-text-currency
                  label="Linea de Credito"
                  :length="0"
                  id="contactoInput16"
                  :rules="[(v) => !!v || 'Campo requerido']"
                  v-model="cliente.lineacredito"
                  @keyup.native.enter="nextfocus(15, '0')"
                ></c-text-currency>
              </v-col>

              <v-col cols="12" md="3">
                <v-btn
                  text
                  color="primary"
                  @click="ubicacion ? (ubicacion = false) : (ubicacion = true)"
                >
                  <v-icon>{{
                    ubicacion ? "keyboard_arrow_up" : "keyboard_arrow_down"
                  }}</v-icon
                  >Ubicacion
                </v-btn>
              </v-col>
              <v-col cols="12" md="9">
                <v-text-field
                  label="Ubicacion"
                  outlined
                  readonly
                  dense
                  :value="`${latlog.lat || ''} ${latlog.lng || ''} `"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12" v-if="ubicacion">
                <GoogleMapLoader
                  :mapConfig="mapConfig"
                  libraries="drawing"
                  class="travel-map"
                >
                  <template slot-scope="{ google, map }">
                    <GoogleMapMarker
                      :google="google"
                      :map="map"
                      v-model="latlog"
                    ></GoogleMapMarker>
                  </template>
                </GoogleMapLoader>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="red" text @click="cancelar">
              <v-icon>clear</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn id="aceptar" color="primary" text @click="guardar">
              <v-icon>done</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { db } from "@/services/db.service";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import GoogleMapLoader from "@/components/map/GoogleMapLoader";
import GoogleMapMarker from "@/components/map/GoogleMapMarker";
import { mapSettings } from "@/services/constants/mapSettings";
import autocompleteDepartamento from "@/views/Mantenimientos/Departamento/autocomplete";
import AutocompleteNacionalidad from "@/views/Mantenimientos/Nacionalidad/autocomplete";
import AutocompleteCiudad from "@/views/Mantenimientos/Ciudad/autocomplete";
import SearchFirebase from "./SearchFirebase";

import {
  PROVEEDOR,
  CONDUCTOR,
  TRANSPORTADORA,
  CLIENTE,
} from "@/constants/forms";
import { PUEDE_AGREGAR } from "@/constants/permissions";

export default {
  components: {
    GoogleMapLoader,
    GoogleMapMarker,
    "Autocomplete-departamento": autocompleteDepartamento,
    AutocompleteNacionalidad,
    AutocompleteCiudad,
    SearchFirebase,
  },
  data() {
    return {
      ubicacion: false,
      overlay: false,
      latlog: "",
      active: false,
      check: "",
      check_cliente: false,
      check_proveedor: false,
      check_transportadora: false,
      check_conductor: false,
      idcontacto: "",
      cliente: {
        idmoneda: { idmoneda: "" },
        lineacredito: "",
      },
      data: {
        idcontacto: 0,
        responsable: {
          idresp: {
            idcontacto: "",
          },
        },
        destcontacto: "",
        ruccontacto: "",
        docucontactos: "",
        fechcontacto: null,
        direcontacto: "",
        telecontacto: "",
        idnacionalidad: {
          idnacionalidad: "",
        },
        idciudad: {
          idciudad: "",
        },
        iddepartamento: {
          iddepartamento: "",
        },

        cliente: [],
        proveedor: [],
        transportadora: [],
        conductor: [],
        grupo: "",
        latitud: "",
        longitud: "",
      },
      default: {
        idcontacto: 0,
        destcontacto: "",
        ruccontacto: "",
        responsable: {
          idresp: {
            idcontacto: "",
          },
        },
        docucontactos: "",
        fechcontacto: "",
        direcontacto: "",
        telecontacto: "",
        idnacionalidad: {
          idnacionalidad: "",
        },
        idciudad: {
          idciudad: "",
        },
        iddepartamento: {
          iddepartamento: "",
        },

        cliente: "",
        proveedor: "",
        transportadora: "",
        conductor: "",
        grupo: "",
        latitud: "",
        longitud: "",
      },
      url: "/apicontacto/",
      dialogo: false,
      operacion: true,
      permission_cliente: false,
      permission_proveedor: false,
      permission_conductor: false,
      permission_transporadora: false,
    };
  },

  watch: {
    $route(to, from) {
      if (from.path === "/contacto") return this.cancelar();
    },
  },
  computed: {
    ...mapGetters("contacto", {
      create: "contacto_create",
      update: "contacto_update",
      key: "contacto_key",
    }),
    ...mapGetters("contacto", [
      "Vget_contacto_data_cliente",
      "Vget_contacto_load",
    ]),
    mapConfig() {
      return {
        ...mapSettings,
        center: { lat: -25.361663, lng: -55.686352 },
      };
    },
    idnacionalidad() {
      if (this.data.idnacionalidad)
        return this.data.idnacionalidad.idnacionalidad;
      return null;
    },
    iddepartamento() {
      if (this.data.iddepartamento)
        return this.data.iddepartamento.iddepartamento;
      return null;
    },
  },
  created() {
    this.nuevo(this.update);
    this.permission_proveedor = getPermission(PROVEEDOR).permiso[PUEDE_AGREGAR];
    this.permission_conductor = getPermission(CONDUCTOR).permiso[PUEDE_AGREGAR];
    this.permission_transporadora = getPermission(TRANSPORTADORA).permiso[
      PUEDE_AGREGAR
    ];
    this.permission_cliente = getPermission(CLIENTE).permiso[PUEDE_AGREGAR];
  },
  mounted() {
    setTimeout(() => this.next(0, "-"), 100);
    this.Vact_contacto_data_cliente();
  },
  methods: {
    ...mapActions("contacto", [
      "contacto_create",
      "contacto_update",
      "contacto_reload",
      "contacto_key",
      "Vact_contacto_data_proveedor",
      "Vact_contacto_data_cliente",
    ]),
    mergeContacto(val) {
      if (val.idcontacto) return this.nuevo(val);
      this.data = {
        ...this.data,
        ...val,
        idnacionalidad: val.idnacionalidad || { idnacionalidad: "" },
        idciudad: val.idciudad || { idciudad: "" },
      };
      this.next(1, "-");
    },
    checkbox_unir() {
      if (this.idcontacto != "") {
        this.data.grupo = [{ idcontacto: this.idcontacto }];
      }
      let x = 0;
      if (this.check_cliente) {
        this.data.cliente = [this.cliente];
      } else {
        x++;
        this.data.cliente = [];
        if (this.data.responsable.length > 0)
          this.data.responsable.idresp.idcontacto = null;
      }
      if (this.check_proveedor) {
        this.data.proveedor = [{ idcontacto: null }];
      } else {
        this.data.proveedor = [];
        x++;
      }
      if (this.check_transportadora) {
        this.data.transportadora = [{ idcontacto: null }];
      } else {
        this.data.transportadora = [];
        x++;
      }
      if (this.check_conductor) {
        this.data.conductor = [{ idcontacto: null }];
      } else {
        this.data.conductor = [];
        x++;
      }
      if (x != 4) {
        return true;
      } else {
        return false;
      }
    },
    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.dialogo = false;
      this.Vact_contacto_data_cliente();
      this.Vact_contacto_data_proveedor();
      this.contacto_update(null);
      this.contacto_create(false);
      this.contacto_reload(true);
      this.contacto_key(this.key + 1);
      this.operacion = true;
    },
    async guardar_finalizar() {
      if (this.data.fechcontacto === "") this.data.fechcontacto = null;
      let a = await db.save(this.url, this.data);
      if (!a.estado) {
        this.overlay = false;
        return a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.data = JSON.parse(JSON.stringify(this.default));
      this.idcontacto = "";
      this.$notify(a.info);
      this.$refs.form.resetValidation();
      setTimeout(() => document.getElementById("contactoInput1").focus(), 500);
      this.overlay = false;
    },
    async editar_finalizar() {
      const id = this.data.idcontacto;
      let a = await db.update(`${this.url}${id}/`, this.data);
      if (!a.estado) {
        this.overlay = false;
        return a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.$notify(a.info);
      this.cancelar();
      this.overlay = false;
    },
    guardar() {
      if (this.$refs.form.validate() && this.checkbox_unir()) {
        (this.data.latitud = JSON.stringify(this.latlog.lat)),
          (this.data.longitud = JSON.stringify(this.latlog.lng));
        this.overlay = true;
        if (this.operacion) return this.guardar_finalizar();
        if (this.operacion === false) return this.editar_finalizar();
      } else {
        this.$notify(mensaje.completar());
      }
    },

    next(id, data) {
      if (data != "")
        try {
          this.$refs[`contacto${id + 1}`].focus();
        } catch (error) {
          this.$refs[`contacto${id + 1}`].$el.focus();
        }
    },

    nuevo(val) {
      this.dialogo = true;
      if (val !== null) {
        if (val.grupo[0] != undefined) {
          this.idcontacto = val.grupo[0].idcontacto;
        }
        this.check_cliente = false;
        let data = val;
        if (data.idnacionalidad === null) {
          data.idnacionalidad = { idnacionalidad: "" };
        }
        if (data.proveedor != "") {
          this.check_proveedor = true;
        }
        if (data.cliente != "") {
          this.check_cliente = true;
          this.cliente = data.cliente[0];
        }
        if (data.transportadora != "") {
          this.check_transportadora = true;
        }
        if (data.conductor != "") {
          this.check_conductor = true;
        }
        if (data.idciudad === null) {
          data.idciudad = { idciudad: "" };
        }
        this.data = data;
        this.data = { ...this.data, ...{ latitud: "", longitud: "" } };
        this.data = {
          ...this.data,
          ...this.default.responsable,
        };

        this.operacion = false;
        this.latlog = {
          lat: Number(data.latitud),
          lng: Number(data.longitud),
        };
      }
    },
  },
};
</script>

<style lang="css" scoped>
.travel-map {
  position: relative;
  height: 400px;
  z-index: 1;
}
</style>
