<template>
  <div>
    <div v-if="formulario.lote">
      <Lote v-model="formulario.lote" />
    </div>
    <div v-if="formulario.item">
      <Item v-model="formulario.item" />
    </div>
    <v-dialog
      :value="value"
      max-width="800px"
      persistent
      @keydown.esc="close()"
    >
      <v-card>
        <v-toolbar flat dense>
          <v-toolbar-title>Medicacion</v-toolbar-title>
        </v-toolbar>

        <v-form ref="form" lazy-validation>
          <v-window v-model="step">
            <v-window-item :value="1">
              <v-container>
                <v-row dense>
                  <v-col cols="12" sm="3">
                    <c-text-date
                      label="Fecha"
                      id="input1"
                      :readonly="readonly"
                      outlined
                      dense
                      v-model="data.fecmmedicacion"
                      @keyup.native.enter="next(1, data.fecmmedicacion)"
                    />
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="12" sm="4">
                    <vue-autocomplete-generic
                      label="Motivo Medicacion"
                      :readonly="readonly"
                      id="input2"
                      action="medicacionmotivo/Vact_medicacionmotivo_data"
                      getter="medicacionmotivo/Vget_medicacionmotivo_data"
                      load="medicacionmotivo/Vget_medicacionmotivo_load"
                      pk="idmedicacion_motivo"
                      title="descmotivo"
                      :create="true"
                      @create="formulario.medicacionmotivo = $event"
                      :permission="permission.medicacionmotivo"
                      v-model="data.idmedicacion_motivo.idmedicacion_motivo"
                      @keyup.native.enter="
                        next(2, data.idmedicacion_motivo.idmedicacion_motivo)
                      "
                    />
                    <MedicacionMotivo
                      v-if="formulario.medicacionmotivo"
                      v-model="formulario.medicacionmotivo"
                    />
                  </v-col>

                  <v-col cols="12" sm="6">
                    <c-text-currency
                      label="Dias de Descarte"
                      id="input3"
                      outlined
                      dense
                      :readonly="readonly"
                      :rules="[(v) => !!v || 'Obligatorio']"
                      v-model="data.descmedicacion"
                      @keyup.native.enter="nextfocus(3, data.descmedicacion)"
                    />
                  </v-col>
                  <v-col cols="12" sm="6">
                    <c-text-currency
                      label="Costo Total"
                      ref="input4"
                      outlined
                      dense
                      :readonly="readonly"
                      :rules="[(v) => !!v || 'Obligatorio']"
                      v-model="data.costototal"
                      @keyup.native.enter="next(4, data.costototal)"
                    />
                  </v-col>

                  <v-col cols="6">
                    <vue-autocomplete-item-medicina
                      id="input5"
                      :readonly="readonly"
                      label="Medicamento"
                      v-model="medicaciondet.idstock"
                      @create="formulario.item = $event"
                      @keyup.native.enter="nextfocus(6, medicaciondet.idstock)"
                    ></vue-autocomplete-item-medicina>
                  </v-col>
                  <v-col cols="3">
                    <c-text-currency
                      label="Dosis"
                      :readonly="readonly"
                      outlined
                      dense
                      ref="input7"
                      :rules="[]"
                      v-model="medicaciondet.dosis"
                      @keyup.native.enter="next(7, medicaciondet.dosis)"
                    />
                  </v-col>
                  <v-spacer></v-spacer>

                  <c-btn-crud-add
                    :disabled="readonly"
                    id="input8"
                    class="mt-1"
                    @click="addtabla()"
                  ></c-btn-crud-add>

                  <v-col cols="12">
                    <v-data-table
                      hide-default-footer
                      :headers="headersmedicamento"
                      :items="data.medicaciondet"
                    >
                      <template v-slot:[`item.accion`]="{ item }">
                        <c-btn-crud-delete
                          :disabled="readonly"
                          @click="rowdelete(item)"
                        ></c-btn-crud-delete
                      ></template>
                    </v-data-table>
                  </v-col>
                  <v-col cols="12">
                    <vue-text-field
                      id="input9"
                      :readonly="readonly"
                      v-model="data.comnmedicacion"
                      label="Comentario"
                      @keyup.native.enter="nextwindows(5, '0')"
                    ></vue-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-window-item>
            <v-window-item :value="2">
              <v-container grid-list-md>
                <v-row dense>
                  <v-col cols="12" sm="5" md="5">
                    <v-autocomplete
                      dense
                      v-model="idlote"
                      :readonly="readonly"
                      id="input9"
                      :loading="isLoading"
                      v-bind:items="lote"
                      fkdes="item"
                      label="Seleccione Lote"
                      item-text="fkdes"
                      item-value="id"
                      autocomplete="off"
                      v-on:change="cargar_animal_autocomplete"
                      @keyup.enter="next(9, idlote)"
                      :no-data-text="`No existe ninguna concidencia`"
                      small-chips
                    >
                      <template v-if="noData && lote_permission" v-slot:no-data>
                        <v-list-item @click="formulario.lote = true">
                          <v-list-item-content>
                            <v-list-item-title
                              >No existe concidencia. Click para
                              agregar</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="11" sm="5" md="5">
                    <v-autocomplete
                      id="input10"
                      v-on:change="validar_select_animal"
                      v-model="animal_det"
                      :readonly="readonly"
                      :items="animal_autocomplete"
                      item-text="fkdes"
                      item-value="id"
                      label="Seleccione Animal"
                      multiple
                      dense
                      persistent-hint
                      small-chips
                      autocomplete="off"
                      @keyup.enter="next(10, animal_det)"
                      :no-data-text="`No existe ninguna concidencia`"
                    ></v-autocomplete>
                  </v-col>
                  <v-spacer></v-spacer>

                  <c-btn-crud-add
                    :disabled="readonly"
                    id="input11"
                    @click="addtablaanimal()"
                  ></c-btn-crud-add>
                  <v-col cols="12">
                    <v-data-table
                      :headers="headers"
                      :items="data.medicacionanimal"
                    >
                      <template v-slot:[`item.accion`]="{ item }">
                        <c-btn-crud-delete
                          :disabled="readonly"
                          @click="rowdeleteanimal(item)"
                        ></c-btn-crud-delete
                      ></template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-container>
            </v-window-item>
          </v-window>
        </v-form>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="blue darken-1"
            :disabled="step === 1"
            text
            @click="step--"
          >
            <v-icon dark>arrow_back_ios</v-icon>
          </v-btn>

          <c-btn-crud-close @click="close()"></c-btn-crud-close>
          <v-spacer></v-spacer>
          <v-btn
            v-if="step != 2"
            text
            color="blue darken-1"
            id="sig1"
            depressed
            @click="step++"
          >
            <v-icon dark>arrow_forward_ios</v-icon>
          </v-btn>
          <c-btn-crud-done
            v-if="step === 2"
            id="aceptar"
            :disabled="readonly"
            @click="pre_guardar()"
          ></c-btn-crud-done>
        </v-card-actions>
        <c-overlay :value="isLoading"></c-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { focus } from "@/services/focus.service";
import { current_date } from "@/services/util/date.service";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { LOTE, MEDICACION_MOTIVO } from "@/constants/forms";
import { PUEDE_AGREGAR } from "@/constants/permissions";
import MedicacionMotivo from "@/views/Analisis/Medicacionmotivo/Create";
import Item from "@/views/Item/Item/Create";
import Lote from "@/views/Animal/Lote/Create";
export default {
  components: {
    MedicacionMotivo,
    Item,
    Lote,
  },
  props: {
    idmedicacion: Number,
    value: {
      type: Boolean,
      default: false,
    },
    datos: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      step: 1,
      noData: true,
      idlote: "",
      animal_todos: "",
      animal_autocomplete: [],
      animal_det: "",
      permission: {
        medicacionmotivo: MEDICACION_MOTIVO,
      },
      formulario: {
        item: false,
        lote: false,
        medicacionmotivo: false,
      },
      data: {
        idmedicacion: "0",
        fechmedicacion: "2019-08-06",
        fecmmedicacion: current_date(),
        descmedicacion: "",
        comnmedicacion: "",

        idmedicacion_motivo: {
          idmedicacion_motivo: "",
        },
        medicaciondet: [],
        medicacionanimal: [],
      },
      medicaciondet: {
        idstock: {},
        dosis: "",
      },
      default: {
        idmedicacion: "0",
        fechmedicacion: "2019-08-06",
        fecmmedicacion: current_date(),
        descmedicacion: "",
        comnmedicacion: "",
        idmedicacion_motivo: {
          idmedicacion_motivo: "",
        },
        medicaciondet: [],
        medicacionanimal: [],
      },
      headersmedicamento: [
        {
          text: "Medicamento",
          sortable: false,
          value: "idstock.iditem.descitem",
        },
        {
          text: "Deposito",
          sortable: false,
          value: "idstock.iddeposito.desdeposito",
        },
        { text: "Dosis", sortable: false, value: "dosis" },
        { text: "Accion", sortable: false, value: "accion", align: "end" },
      ],
      headers: [
        { text: "Rp", sortable: false, value: "idanimal.rpanimal" },
        { text: "Nombre", sortable: false, value: "idanimal.nombanimal" },
        { text: "Accion", sortable: false, value: "accion", align: "end" },
      ],
      id: "",
      isEdit: false,
      lote: [],
    };
  },
  watch: {
    $route() {
      return this.close();
    },
    getLote(val) {
      //Se obtienen todos los lotes disponibles
      this.lote = [];
      this.lote.push({ id: -999, fkdes: "TODOS" });
      val.map((element) => {
        this.lote.push({ id: element.idlote, fkdes: element.desclote });
      });
    },
    getAnimal(val) {
      this.animal_todos = JSON.parse(JSON.stringify(val));
    },
  },
  computed: {
    ...mapGetters("animal", ["getAnimal"]),
    ...mapGetters("lote", ["getLote", "isLoading"]),
    ...mapGetters("medicacion", ["isLoading","getMedicacionId"]),

    lote_permission() {
      return getPermission(LOTE).permiso[PUEDE_AGREGAR];
    },
  },
  async created() {
    this.fetchAnimal();
    if (this.idmedicacion){ 
      await this.fetchMedicacionId(this.idmedicacion);
      return this.nuevo(this.getMedicacionId);
    }
    this.nuevo(this.datos);
  },
  mounted() {
    setTimeout(() => document.getElementById("input1").focus(), 500);
  },
  methods: {
    ...mapActions("animal", ["fetchAnimal"]),
    ...mapActions("medicacion", [
      "fetchMedicacion",
      "fetchMedicacionId",
      "setMedicacion",
      "setMedicacionUpdate",
    ]),
    ...mapActions("lote", ["fetchLote"]),
    ...mapActions("stock", ["Vact_stock_data"]),

    async guardar() {
      if (!this.$refs.form.validate()) return null;
      const id = this.id;
      const response = !this.isEdit
        ? await this.setMedicacion(this.data)
        : await this.setMedicacionUpdate({
            id,
            data: this.data,
          });
      if (response.success) {
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();
        this.step = 1;
        setTimeout(() => document.getElementById("input1").focus(), 500);
      }
    },
    next(id, data) {
      if ((id === 4 && data === 0) || data === "") return null;
      if ((id === 7 && data === 0) || data === "") return null;
      focus.nextid(id, data);
    },
    nextfocus(id, data) {
      if (id === 3 && data != "") {
        this.$refs.input4.$el.querySelector("input").focus();
      }
      if (id === 6 && data != "") {
        this.$refs.input7.$el.querySelector("input").focus();
      }
    },
    nextwindows(id, data) {
      if (id === 5 && data != "") {
        this.step++;
        setTimeout(() => document.getElementById("input6").focus(), 500);
      }
    },
    rowdelete(item) {
      this.index = this.data.medicaciondet.indexOf(item);
      this.data.medicaciondet.splice(this.index, 1);
      this.index = null;
    },
    rowdeleteanimal(item) {
      this.index = this.data.medicacionanimal.indexOf(item);
      this.data.medicacionanimal.splice(this.index, 1);
      this.index = null;
    },
    verificar_duplicado(option) {
      if (option === "M") {
        let temp = null;
        this.data.medicaciondet.find((x) => {
          if (
            x.idstock.iditem.iditem ===
              this.medicaciondet.idstock.iditem.iditem &&
            x.idstock.idstock === this.medicaciondet.idstock.idstock
          ) {
            return (temp = x);
          }
        });
        if (temp != null) {
          this.index = this.data.medicaciondet.indexOf(temp);
          this.data.medicaciondet.splice(this.index, 1);
        }
      }
      if (option === "A") {
        //Si se selecciono en el autocomplete de animal la opcion "TODOS"
        //se ejecuta esta condicional
        //El cual recorre la variable principal que contiene todos los animales
        //y compara si ya existe en la tabla detalle algun animal de la variable
        if (this.animal_det[0] === -999) {
          this.animal_todos.map((x) => {
            let existe = this.data.medicacionanimal.find((a) => {
              if (a.idanimal.idanimal === x.idanimal) return true;
              return false;
            });
            if (this.idlote === -999 && !existe) {
              this.data.medicacionanimal.push({
                idanimal: {
                  idanimal: x.idanimal,
                  rpanimal: x.rpanimal,
                  nombanimal: x.nombanimal,
                },
              });
            }
            if (this.idlote === x.idlote.idlote && !existe) {
              this.data.medicacionanimal.push({
                idanimal: {
                  idanimal: x.idanimal,
                  rpanimal: x.rpanimal,
                  nombanimal: x.nombanimal,
                },
              });
            }
          });
        } else {
          this.animal_det.map((x) => {
            let existe = this.data.medicacionanimal.find((a) => {
              if (a.idanimal.idanimal === x) return true;
              return false;
            });
            if (!existe) {
              let a = this.animal_todos.find((b) => {
                return b.idanimal === x;
              });
              this.data.medicacionanimal.push({
                idanimal: {
                  idanimal: a.idanimal,
                  rpanimal: a.rpanimal,
                  nombanimal: a.nombanimal,
                },
              });
            }
          });
        }
      }
    },
    validar_det(option) {
      if (option === "M") {
        if (
          this.medicaciondet.iditem != "" &&
          this.medicaciondet.dosis != "" &&
          this.medicaciondet.dosis > 0
        )
          return true;
        return false;
      }
      if (option === "A") {
        if (this.idlote != "" && this.animal_det != "") return true;
        return false;
      }
    },
    limpiar_det() {
      this.medicaciondet.idstock = {};
      this.medicaciondet.dosis = "";
      this.idlote = "";
      this.animal_autocomplete = [];
      this.animal_det = [];
    },
    limpiar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$emit("input", false);
      this.$emit("datos", null);
      this.fetchMedicacion();
      this.isEdit = false;
    },
    addtabla() {
      if (!this.validar_det("M")) return null;
      this.verificar_duplicado("M");
      this.data.medicaciondet.push({
        idstock: this.medicaciondet.idstock,
        dosis: this.medicaciondet.dosis,
      });
      this.limpiar_det();
      setTimeout(() => document.getElementById("input5").focus(), 500);
    },

    addtablaanimal() {
      //Primero se verifica si los autocompletes estan completados
      if (!this.validar_det("A")) return null;
      this.verificar_duplicado("A");
      this.limpiar_det();
      setTimeout(() => document.getElementById("input9").focus(), 500);
    },
    close() {
      this.limpiar();
      this.Vact_stock_data();
    },
    async pre_guardar() {
      if (this.data.comnmedicacion === "") {
        this.data.comnmedicacion = null;
      }
      const response = await this.guardar();
      if (response) {
        setTimeout(() => document.getElementById("input1").focus(), 500);
        return (this.step = 1);
      }
    },
    //Una vez seleccionado el lote se ejecuta este metodo
    //Lo que hace es cargar la variable "animal_autocomplete"
    //en base al lote seleccionado.
    //Si el lote seleccionado es "TODOS" se cargaran todos los animales
    //en la variable

    cargar_animal_autocomplete() {
      this.animal_autocomplete = [];
      this.animal_todos.map((x) => {
        if (this.idlote === -999) {
          this.animal_autocomplete.push({ id: -999, fkdes: "TODOS" });
          this.animal_autocomplete.push({
            id: x.idanimal,
            fkdes: `${x.nombanimal} ${x.rpanimal}`,
          });
        }
        if (this.idlote === x.idlote.idlote) {
          this.animal_autocomplete.push({ id: -999, fkdes: "TODOS" });
          this.animal_autocomplete.push({
            id: x.idanimal,
            fkdes: `${x.nombanimal} ${x.rpanimal}`,
          });
        }
      });
    },
    validar_select_animal() {
      if (
        this.animal_det.find((x) => {
          return x === -999;
        }) === -999
      ) {
        this.animal_det = [-999];
        this.animal_autocomplete = [{ id: -999, fkdes: "TODOS" }];
      } else {
        this.cargar_animal_autocomplete();
      }
    },
    nuevo(val) {
      this.fetchLote();
      if (val === null) return null;
      this.id = val.idmedicacion;
      this.data = JSON.parse(JSON.stringify(val));
      this.isEdit = true;
    },
  },
};
</script>
