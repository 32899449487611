import { db } from "@/services/db.service";
export default {

    async Vact_campana_data({commit}){
        commit('campana_load', true);
        try {
            const data = await db.get('/apizafra/');
            commit('campana_data', data);
            commit('campana_load', false);
            return true
        } catch (e) {
            commit('campana_load', false);
            return false
        } 
    },
    async fetch_reporte_async({commit} ) {
        commit('reporte_load', true);
        try {
            const data = await db.get('/apireportedemoasync/');
            commit('reporte_async', data);
            commit('reporte_load', false);
            return true
        } catch (e) {
            commit('reporte_load', false);
            return false
        }
    },
    async Vact_campana_data_all({commit}){
        commit('campana_load', true);
        try {
            const data = await db.get('/apizafra/');
            commit('campana_data_all', data);
            commit('campana_load', false);
            return true
        } catch (e) {
            commit('campana_load', false);
            return false
        } 
    },
    async Vact_campana_data_anho({commit},{anhoInicio, anhoFin}){
        commit('campana_load', true);
        try {
            const data = await db.get(`/apizafra/?inicio=${anhoInicio}&fin=${anhoFin}`);
            commit('campana_data_anho', data);
            commit('campana_load', false);
            return true
        } catch (e) {
            commit('campana_load', false);
            return false
        } 
    },
    async Vact_campana_data_byLote({commit}, id){
        commit('campana_load', true);
        try {
            const data = await db.get('/apizafra/?band='+id+'&active=yes');
            commit('campana_data_byLote', data);
            commit('campana_load', false);
            return true
        } catch (e) {
            commit('campana_load', false);
            return false
        } 
    },
    async Vact_campana_save({dispatch, commit}, data){
        commit('campana_save_load', true);
        try {
            const resp = await db.save('/apizafra/', data);
            dispatch('Vact_campana_data');
            commit('campana_save_load', false);
            return resp;
        } catch (e) {
            commit('campana_save_load', false);
            return e
        } 
    },
    async Vact_campana_update({commit},{id, data}){
        commit('campana_save_load', true);
        try {
            const resp = await db.update('/apizafra/'+id+'/',data);
            commit('campana_save_load', false);
            return resp;
        } catch (e) {
            commit('campana_save_load', false);
            return e
        } 
    },
    async Vact_campana_cierre({commit},{id, data}){
        commit('campana_save_load', true);
        try {
            const resp = await db.update('/apizafra/'+id+'/?cierre=yes',data);
            commit('campana_save_load', false);
            return resp;
        } catch (e) {
            commit('campana_save_load', false);
            return e
        } 
    },
    async Vact_campana_del({dispatch},id){
        try {
            const data = await db.delete('/apizafra/'+id+'/');
            dispatch('Vact_campana_data');
            return data
        } catch (e) {
            return false
        } 
    }
}
