<template>
    <c-dialog-confirmation
        :value="value"
        @cancel="close()"
        @done="save()"
        :loading="isLoadingCuenta"
    >Desea eliminar la cuenta
    <strong>{{ props.nombre }}</strong>?
    </c-dialog-confirmation>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    props: {
        value: Boolean,
        props: Object,
    },
    computed: {
        ...mapGetters("finanza", ["isLoadingCuenta"])
    },
    watch: {
        $route() {
            return this.close();
        }
    },
    methods: {
        ...mapActions("finanza", ["setCuentaDelete", "fetchCuenta"]),
        async save() {
            const id = this.props.idfinanza_cuenta;
            const response = await this.setCuentaDelete(id);
            if (!response.success) return null;
            this.close();
        },
        close() {
            this.$emit("datos", null);
            this.$emit("input", false);
            this.fetchCuenta();
        }
    }
}
</script>