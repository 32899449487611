
export default {

  tambo_update: (state) => {
    return state.tambo_update
  },
  Vget_tambo_data: (state) => {
    return state.tambo_data
  },
  Vget_tambo_empresa: (state) => {
    return state.tambo_empresa
  },
  Vget_tambo_load: (state) => {
    return state.tambo_load
  },
  Vget_tambo_save_load: (state) => {
    return state.tambo_save_load
  },
}
