
export default {

  categoria_update: (state) => {
    return state.categoria_update
  },
  categoria_show: (state) => {
    return state.categoria_show
  },
  Vget_categoria_data: (state) => {
    return state.categoria_data
  },
  Vget_categoria_load: (state) => {
    return state.categoria_load
  },
  Vget_categoria_save_load: (state) => {
    return state.categoria_save_load
  },
}
