<template>
  <div>
    <v-dialog
      :value="value"
      max-width="700px"
      persistent
      @keydown.esc="cancelar()"
    >
      <v-card>
        <v-toolbar flat dense>
          <v-toolbar-title>{{
            operacion ? "Registrar Cotizacion" : "Modificar Cotizacion"
          }}</v-toolbar-title>
        </v-toolbar>
        <v-divider></v-divider>
        <v-form ref="form">
          <v-row dense class="mx-2">
            <v-col cols="12">
              <c-text-date
                label="Fecha"
                v-model="data.fecha"
                persistent-hint
                readonly
              ></c-text-date>
            </v-col>
            <v-col cols="12" md="4" sm="4">
              <v-autocomplete
                :items="Vget_moneda_cotizacion"
                :loading="Vget_moneda_load"
                item-text="descmoneda"
                return-object
                :readonly="readonly"
                autocomplete="off"
                outlined
                dense
                label="Moneda"
                ref="cotizacion1"
                @change="get_cotizacion_banco()"
                @keyup.native.enter="next(1, cotizaciondet.idmoneda.idmoneda)"
                v-model="cotizaciondet.idmoneda"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3" sm="3">
              <c-text-currency
                dense
                outlined
                ref="cotizacion2"
                :readonly="readonly"
                v-model="cotizaciondet.compra"
                :rules="rules"
                label="Compra"
                @keyup.native.enter="next(2, cotizaciondet.compra)"
              ></c-text-currency>
            </v-col>

            <v-col cols="10" md="3" sm="3">
              <c-text-currency
                dense
                outlined
                ref="cotizacion3"
                :rules="rules"
                :readonly="readonly"
                v-model="cotizaciondet.venta"
                label="Venta"
                @keyup.native.enter="next(3, cotizaciondet.venta)"
              ></c-text-currency>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="2" md="1" sm="1">
              <c-btn-crud-add
                ref="cotizacion4"
                :disabled="readonly"
                @click="addtabla()"
              ></c-btn-crud-add>
            </v-col>
          </v-row>
        </v-form>
        <v-row dense class="mx-2">
          <v-col
            cols="12"
            sm="4"
            md="3"
            v-for="(item, i) in cotizacionBanks"
            :key="i"
          >
            <CotizacionCards @click="addCotizacion(item)" :item="item" />
          </v-col>
        </v-row>

        <v-data-table
          :loading="Vget_cotizacion_load"
          hide-default-footer
          :headers="headers"
          :items="data.cotizaciondet"
        >
          <template v-slot:[`item.compra`]="{ item }">
            <div>{{ toCurrency(item.compra) }}</div>
          </template>
          <template v-slot:[`item.venta`]="{ item }">
            <div>{{ toCurrency(item.venta) }}</div>
          </template>
          <template v-slot:[`item.accion`]="props">
            <c-btn-table-delete
              v-if="operacion"
              @click="rowdelete(props.item)"
            ></c-btn-table-delete>
          </template>
        </v-data-table>

        <v-divider></v-divider>
        <v-card-actions>
          <c-btn-crud-close @click="cancelar()"></c-btn-crud-close>
          <v-spacer></v-spacer>
          <c-btn-crud-done
            :disabled="readonly"
            @click="guardar()"
          ></c-btn-crud-done>
        </v-card-actions>
        <c-overlay :value="Vget_cotizacion_save_load"></c-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { current_date } from "@/services/util/date.service";
import { currency } from "@/services/util/number.service";
import { mensaje } from "@/services/notify.service";
import CotizacionCards from "./CotizacionBanks";

export default {
  components: {
    CotizacionCards,
  },
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    datos: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      rules: [
        (v) => !!v || "Este campo es obligatorio",
        (v) => !(Number(v) <= 0) || "Este campo no puede ser menor o igual 0",
      ],
      cotizacionBanks: [],

      cotizaciondet: {
        idmoneda: {
          idmoneda: "",
          descmoneda: "",
        },
        compra: "",
        venta: "",
      },
      cotizaciondet_default: {
        idmoneda: {
          idmoneda: "",
          descmoneda: "",
        },
        compra: "",
        venta: "",
      },

      data: {
        fecha: current_date(),
        cotizaciondet: [],
      },
      default: {
        fecha: current_date(),
        cotizaciondet: [],
      },
      index: null,
      headers: [
        { text: "Moneda", value: "idmoneda.descmoneda", sortable: false },
        { text: "Compra", value: "compra", sortable: false, align: "right" },
        { text: "Venta", value: "venta", sortable: false, align: "right" },
        { text: "Accion", value: "accion", sortable: false, align: "right" },
      ],
      operacion: true,
    };
  },

  watch: {
    $route(to, from) {
      if (from.path === "/cotizacion") return this.cancelar();
    },
  },
  computed: {
    ...mapGetters("cotizacion", [
      "Vget_cotizacion_save_load",
      "Vget_cotizacion_load",
      "getCotizacionBanks",
    ]),
    ...mapGetters("moneda", ["Vget_moneda_cotizacion", "Vget_moneda_load"]),
  },
  created() {
    this.nuevo(this.datos);
  },
  mounted() {
    this.Vact_moneda_cotizacion();
    setTimeout(() => this.next(0, "-"), 300);
  },
  methods: {
    ...mapActions("cotizacion", [
      "fetchCotizacionBanks",
      "Vact_cotizacion_save",
      "Vact_cotizacion_update",
      "Vact_cotizacion_data",
    ]),
    ...mapActions("moneda", ["Vact_moneda_cotizacion"]),
    toCurrency(value) {
      return currency(value);
    },
    async addCotizacion(val) {
      this.cotizaciondet.compra = val.compra;
      this.cotizaciondet.venta = val.venta;
      if (this.Vget_moneda_cotizacion.length === 1)
        setTimeout(() => {
          this.addtabla();
          this.guardar_finalizar();
        }, 100);
    },
    async get_cotizacion_banco() {
      await this.fetchCotizacionBanks(this.cotizaciondet.idmoneda.idmoneda);
      this.cotizacionBanks = this.getCotizacionBanks;
    },
    rowdelete(item) {
      this.index = this.data.cotizaciondet.indexOf(item);
      this.cotizaciondet = JSON.parse(JSON.stringify(item));
      this.data.cotizaciondet.splice(this.index, 1);
      this.index = null;
    },
    verificar_duplicado() {
      let temp = null;
      this.data.cotizaciondet.forEach((x) => {
        if (x.idmoneda.idmoneda === this.cotizaciondet.idmoneda.idmoneda) {
          return (temp = x);
        }
      });
      if (temp != null) {
        this.$notify(mensaje.error("Ya existe una cotizacion de esta moneda"));
        return false;
      }
      return true;
    },
    isValid() {
      if (
        this.cotizaciondet.compra === 0 ||
        this.cotizaciondet.venta === 0 ||
        !this.$refs.form.validate()
      )
        return false;
      return true;
    },
    addtabla() {
      if (!this.isValid())
        return this.$notify(
          mensaje.error("Complete los campos y haga click en el boton +")
        );
      if (!this.verificar_duplicado()) return null;
      this.data.cotizaciondet.push({
        idmoneda: this.cotizaciondet.idmoneda,
        compra: this.cotizaciondet.compra,
        venta: this.cotizaciondet.venta,
      });
      this.cotizaciondet = JSON.parse(
        JSON.stringify(this.cotizaciondet_default)
      );
      this.$refs.form.resetValidation();
      this.cotizacionBanks = [];
      this.next(0, "-");
      return;
    },

    cancelar() {
      if (this.$router.history.current.path === "/cotizacion/add") {
        this.limpiar();
        this.$router.push("/");
      } else {
        this.limpiar();
      }
      this.Vact_cotizacion_data();
    },
    limpiar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.index = null;
      this.$emit("input", false);
      this.$emit("datos", null);
    },
    async guardar_finalizar() {
      let a = await this.Vact_cotizacion_save(this.data);
      if (!a.success) return null;
      this.data = JSON.parse(JSON.stringify(this.default));
      this.index = null;
      if (this.$router.history.current.path === "/cotizacion/add") {
        this.$router.push("/");
      }
      this.cancelar();
    },
    async editar_finalizar() {
      const id = this.datos.idcotizacion;
      let a = await this.Vact_cotizacion_update({ id, data: this.data });
      if (!a.success) return null;
      this.operacion = true;
    },
    guardar() {
      if (this.data.cotizaciondet.length < 1)
        return this.$notify(mensaje.completar());
      if (this.operacion === true) return this.guardar_finalizar();
      if (this.operacion === false) return this.editar_finalizar();
    },

    next(id, data) {
      if (data != "")
        try {
          this.$refs[`cotizacion${id + 1}`].focus();
        } catch (error) {
          this.$refs[`cotizacion${id + 1}`].$el.focus();
        }
    },
    nuevo(val) {
      if (val === null) return null;
      this.data = JSON.parse(JSON.stringify(val));
      this.operacion = false;
    },
  },
};
</script>
<style></style>
