import {SET_LOADING, SET_CONTRATO, SET_CONTRATO_BY_FUN, SET_DET_CONTRATO_BY_FUN} from './constants'
export default {
  [SET_CONTRATO](state, request) {
    state.contratofuncionario = request;
  },
  [SET_CONTRATO_BY_FUN](state,request){
    state.contratofuncionariobyId = request
  },
  [SET_DET_CONTRATO_BY_FUN](state,request){
    state.detContratoFuncionario = request
  },
  [SET_LOADING](state, request) {
    state.isLoading = request;
  },
}