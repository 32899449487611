
export default {

  Vget_dashboard_menu: (state) => {
    return state.dashboard_menu
  },
  Vget_dashboard_diariocaja: (state) => {
    return state.dashboard_diariocaja
  },
  Vget_dashboard_load: (state) => {
    return state.dashboard_load
  },
  Vget_favorites: (state) => {
    return state.favorites
  },
  Vget_favorites_load: (state) => {
    return state.favorites_load
  },

  Vget_notificacion_data: (state) => {
    return state.notificacion_data
  },
  Vget_notificacion_load: (state) => {
    return state.notificacion_load
  },
  Vget_activity_data: (state) => {
    return state.activity_data
  },
  Vget_activity_load: (state) => {
    return state.activity_load
  },
  Vget_commodities_load: (state) => {
    return state.commodities.isLoading
  },
  Vget_commodities_data: (state) => {
    return state.commodities.data
  }
}
