<template>
  <v-card>
    <v-toolbar flat dense>
      <v-toolbar-title>Configuraciones Agro</v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider>
    <v-container class="mx-2">
      <v-row dense>
        <v-col cols="12" sm="6">
          <div class="title">Siembra/Aplicacion</div>
          <v-checkbox
            class="my-2"
            v-model="data.registrar_parcelas"
            label="Registrar Parcelas"
          ></v-checkbox>
          <v-checkbox
            class="my-2"
            v-model="data.registrar_humedad"
            label="Registrar Humedad (%)"
          ></v-checkbox>
          <v-checkbox
            class="my-2"
            v-model="data.registrar_epi"
            label="Registrar EPI (S/N)"
          ></v-checkbox>
          <v-checkbox
            class="my-2"
            v-model="data.registrar_temperatura"
            label="Registrar Temperatura (ºC)"
          ></v-checkbox>
          <v-checkbox
            class="my-2"
            v-model="data.registrar_tipo_pico"
            label="Registrar Tipo de Pico"
          ></v-checkbox>
          <v-checkbox
            class="my-2"
            v-model="data.registrar_volumen_agua"
            label="Registrar Volumen de Agua"
          ></v-checkbox>
          <v-checkbox
            class="my-2"
            v-model="data.registrar_velocidad_viento"
            label="Registrar Velocidad del Viento (km/h)"
          ></v-checkbox>
          <v-checkbox
            class="my-2"
            v-model="data.registrar_responsable"
            label="Registrar Aplicador"
          ></v-checkbox>
          <v-checkbox
            class="my-2"
            v-model="data.aplicacion_ha_total"
            label="Calcular sobre total"
          ></v-checkbox>
          <div class="title">Datos Senave V2</div>
          <v-checkbox
            class="my-2"
            v-model="data.senave_vdos"
            label="Mostrar siempre datos SENAVE"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" sm="6">
          <div class="title">Fijacion de Precio</div>
          <v-radio-group v-model="data.fijar_precio">
            <template v-slot:label>
              <div>
                Cuando fijar el precio oficial de 
                <strong>Venta?</strong>
              </div>
            </template>
            <v-radio label="Cuando se cierre una venta" :value="true"></v-radio>
            <v-radio label="Cuando se cierre la campaña" :value="false"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
    </v-container>
    <v-card-actions>
      <v-divider></v-divider
      ><c-btn-crud-done @click="guardar()"></c-btn-crud-done>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data: () => ({
    data: {
      idconfiguracion_agro: 0,
      registrar_parcelas: false,
      registrar_humedad: false,
      registrar_epi: false,
      registrar_temperatura: false,
      registrar_tipo_pico: false,
      registrar_volumen_agua: false,
      registrar_velocidad_viento: false,
      registrar_responsable: false,
      aplicacion_ha_total: false,
      fijar_precio: true,
      senave_vdos: false,
    },
  }),
  mounted() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.fetchConfigAgro();
  },
  watch: {
    getConfigAgro(val) {
      if (val.length > 0) this.data = JSON.parse(JSON.stringify(val[0]));
    },
  },
  computed: {
    ...mapGetters("configagro", ["getConfigAgro", "isConfigAgroLoading"]),
  },
  methods: {
    ...mapActions("configagro", ["fetchConfigAgro", "setConfigAgro"]),
    async guardar() {
      await this.setConfigAgro(this.data);
    },
  },
};
</script>
