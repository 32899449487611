<template>
  <v-dialog persistent max-width="700" :value="value">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-form ref="form" @submit.prevent="">
          <v-row dense>
            <v-col cols="6">
              <v-autocomplete
                dense
                v-model="data.idmaquinaria"
                id="input1"
                ref="maquinaria1"
                v-bind:items="getMaquinaria"
                label="Maquinaria"
                item-text="descripcion"
                item-value="idmaquinaria"
                return-object
                :rules="[
                  (v) =>
                    !!v.idmaquinaria || 'Seleccione una Maquinaria',
                ]"
                required
                outlined
                autocomplete="off"
                @keyup.native.enter="focus(1, data.idmaquinaria.idmaquinaria)"
                :no-data-text="`No existe ninguna concidencia`"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <vue-autocomplete-contacto-search
                label="Transportista"
                  id="input2"
                  ref="maquinaria2"
                  action="contacto/Vact_contacto_data_transportadora"
                  getter="contacto/Vget_contacto_data_transportadora"
                  load="contacto/Vget_contacto_load"
                  vuex="contacto/contacto_create"
                  permission="Transportadora"
                  v-model="data.idtransportista.idcontacto"
                  @keyup.native.enter="next(2, data.idtransportista.idcontacto)"
              />
            </v-col>
            <v-col cols="6">
              <autocomplete-funcionario
                id="input3"
                ref="maquinaria3"
                label="Funcionario"
                outlined
                dense
                v-model="data.idchofer"
                :idcontacto="props ? props.idchofer : null"
                @keyup.native.enter="
                  focus(3, data.idchofer.idcontacto.idcontacto)
                "
              />
            </v-col>
            <v-col cols="6">
              <c-text-date
                autocomplete="off"
                label="Fecha Inicio"
                :rules="rules"
                outlined
                dense
                id="input4"
                ref="maquinaria4"
                v-model="data.fecha"
                placeholder="Ingrese la fecha de inicio"
                @keyup.native.enter="focus(4, data.fecha)"
              ></c-text-date>
            </v-col>
            <v-col cols="12">
              <v-textarea
                label="Observacion"
                outlined
                id="input5"
                ref="maquinaria5"
                v-model="data.observacion"
                @keyup.native.enter="focus(5, data.observacion)"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <c-btn-crud-close @click="cancel()"></c-btn-crud-close>
        <v-spacer></v-spacer>
        <c-btn-crud-done id="input6" ref="maquinaria6" @click="save()"></c-btn-crud-done>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import autocompleteFuncionario from "@/views/RRHH/Funcionarios/Information/autocomplete";
import { focus } from "@/services/focus.service";
export default {
  components: {
    "autocomplete-funcionario": autocompleteFuncionario,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
    },
    "sync-input": {
      type: [String, Number],
      default: null,
    },
  },
  mounted() {
    if (this.editable) this.data = JSON.parse(JSON.stringify(this.props));
    setTimeout(() => this.next(0, "-"), 200);
    if (this.syncInput != null) this.data.descripcion = this.syncInput;
    this.sync = this.syncInput;
  },
  created() {
    this.fetchMaquinariaMantenimientoTipo();
    this.fetchMaquinaria();
  },
  computed: {
    ...mapGetters("maquinaria", ["isLoadingMaquinaria", "getMaquinaria"]),
    ...mapGetters("maquinaria_mantenimiento_tipo", [
      "isLoadingMantenimientoTipo",
      "getMaquinariaMantenimientoTipo",
    ]),
    ...mapGetters("contacto", [
      "Vget_contacto_data_transportadora",
      "Vget_contacto_load",
    ]),
    title() {
      return this.editable
        ? "Modificar Asignacion de Maquinaria"
        : "Registrar Asignacion de Maquinaria";
    },
  },
  methods: {
    ...mapActions("maquinaria_mantenimiento_tipo", [
      "fetchMaquinariaMantenimientoTipo",
    ]),
    ...mapActions("maquinaria", ["fetchMaquinaria"]),
    ...mapActions("funcionario", ["fetchFuncionario"]),
    ...mapActions("contacto", ["Vact_contacto_data_transportadora"]),

    ...mapActions("maquinaria_asignacion", [
      "setMaquinariaAsignacion",
      "setMaquinariaAsignacionUpdate",
      "fetchMaquinariaAsignacion"
    ]),
    focus(id, data) {
      console.log(data)
      focus.nextid(id, data);
    },
    next(id, data) {
      if (data != "")
        try {
          this.$refs[`maquinaria${id + 1}`].focus();
        } catch (error) {
          this.$refs[`maquinaria${id + 1}`].$el.focus();
        }
    },
    cancel() {
      this.$emit("input", false);
    },
    async save() {
      if (!this.$refs.form.validate()) return null;
      const response = this.editable
        ? await this.setMaquinariaAsignacionUpdate({
            data: this.data,
            id: this.data.idmaquinaria_asignacion,
          })
        : await this.setMaquinariaAsignacion(this.data);
      if (response.success) {
        if (this.sync != null) {
          this.cancel();
          this.fetchMaquinariaAsignacion();
          return this.$emit(
            "sync-input",
            response.data.idmaquinaria_asignacion
          );
        }
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();
        this.fetchMaquinariaAsignacion();
        this.next(0, "-");
        if (this.editable) this.cancel();
      }
    },
  },
  data: () => ({
    rules: [(v) => !!v || "Este campo es obligatorio"],
    data: {
      idmaquinaria_asignacion: 0,
      idmaquinaria: {
        idmaquinaria: "",
      },
      idchofer: {
        idcontacto: {
          idcontacto: "",
        },
      },
      idtransportista: {
        idcontacto: {
          idcontacto: "",
        },
      },
      fecha: "",
      observacion: "",
    },
    default: {
      idmaquinaria_asignacion: 0,
      idmaquinaria: {
        idmaquinaria: "",
      },
      idchofer: {
        idcontacto: {
          idcontacto: "",
        },
      },
      idtransportista: {
        idcontacto: {
          idcontacto: "",
        },
      },
      fecha: "",
      observacion: "",
    },
    sync: null,
  }),
};
</script>