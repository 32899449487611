<template>
  <div id="eliminar">
    <v-row dense align="center" justify="center">
      <h4>Eliminar Campo</h4>
      <v-img :src="require('@/assets/help/agro/eliminarcampo.png')" max-width="240"></v-img>
      <v-col cols="12">
        <span>
          <v-icon color="info" small>info</v-icon>Obs: No se puede eliminar un campo que ya tuvo alguna operacion (Siembra, Aplicacion, Egreso, etc..)
        </span>
        <li>
          Click en
          <v-icon color="primary">done</v-icon>para eliminar
        </li>
        <li>
          Click en
          <v-icon color="red">clear</v-icon>para cancelar
        </li>
        <li>Mision Cumplida!!! :D</li>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>