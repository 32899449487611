export default {
  tipocuenta_create(state, request) {
    state.tipocuenta_create = request;
  }, 
  tipocuenta_update(state, request) {
    state.tipocuenta_update = request;
  },
  tipocuenta_reload(state, request) {
    state.tipocuenta_reload = request;
  }, 
  tipocuenta_show(state, request) {
    state.tipocuenta_show = request;
  }, 
}