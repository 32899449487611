export default {
  banco_data(state, request) {
    state.banco_data = request;
  }, 
  banco_load(state, request) {
    state.banco_load = request;
  },
  banco_save_load(state, request) {
    state.banco_save_load = request;
  },  
}