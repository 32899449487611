<template>
  <div>
    <Crud
      v-if="crud.add"
      v-model="crud.add"
      :datos="crud.datos"
      @datos="crud.datos = $event"
    />
    <Crud
      v-if="crud.view"
      v-model="crud.view"
      :props="crud.datos"
      editable
      @datos="crud.datos = $event"
    />
    <v-alert type="error" v-if="permission_alert"
      >Error!!! Este usuario no tiene permisos en esta ventana</v-alert
    >
    <v-card>
      <v-toolbar flat dense>
        <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
        <widgets-Favorite :ruta="$route.path" :formulario="$route.name" />
        <v-divider class="mx-2" inset vertical></v-divider>

        <v-spacer></v-spacer>
        <c-text-table-search
          v-if="permission.can_view"
          v-model="search"
        ></c-text-table-search>
        <v-toolbar-items>
          <c-btn-table-add
            @click="crud.add = true"
            v-if="permission.can_add"
          ></c-btn-table-add>
          <c-btn-table-reload
            class="ml-1"
            @click="fetchTipoPico()"
          ></c-btn-table-reload>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table
        :loading="isTipoPicoLoading"
        :headers="headers"
        :items="getTipoPico"
        :search="search"
        v-if="permission.can_view"
      >
        <template v-slot:[`item.accion`]="props">
          <c-btn-table-edit @click="editar(props.item)" />
          <c-btn-table-delete @click="eliminar(props.item)" />
        </template>
      </v-data-table>

      <Delete
        v-if="crud.delete"
        v-model="crud.delete"
        :props="crud.datos"
        @datos="crud.datos = $event"
      />
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { ZAFRA } from "@/constants/forms";
import Crud from "./Create";
import Delete from "./Delete";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";
export default {
  components: {
    Crud,
    Delete,
  },
  data() {
    return {
      search: "",
      titulo: "Tipo Pico",
      headers: [
        { text: "Descripcion", align: "left", value: "descripcion" },
        { text: "Abreviatura", align: "right", value: "abreviatura" },
        { text: "Accion", value: "accion", sortable: false, align: "right" },
      ],
      crud: {
        add: false,
        view: false,
        delete: false,
        datos: null,
      },
      overlay: false,
      objeto: {
        descripcion: "",
      },
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
    };
  },
  watch: {},
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.fetchTipoPico();
    const permission = getPermission(ZAFRA).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  computed: {
    ...mapGetters("tipopico", ["getTipoPico", "isTipoPicoLoading"]),
    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },
  methods: {
    ...mapActions("tipopico", ["fetchTipoPico"]),
    editar(item) {
      this.crud.datos = JSON.parse(JSON.stringify(item));
      this.crud.view = true;
    },
    eliminar(item) {
      this.crud.datos = JSON.parse(JSON.stringify(item));
      this.crud.delete = true;
    },
    eliminarCancelar() {
      this.objeto.descripcion = "";
      this.crud.delete = false;
    },
    async eliminarLote() {
      this.overlay = true;
      const id = this.objeto.idcampana;
      const a = await this.Vact_campana_del(id);
      if (a.estado == true) {
        this.$notify(a.info);
        this.eliminarCancelar();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.overlay = false;
    },
  },
};
</script>
