import { db } from "@/services/db.service";
export default {
    async Vact_recetario_data({commit}, {zafra=null, lote=null}) {
        commit('recetario_load', true);
        try {
            let data = '';
            if (zafra && lote) {
                data = await db.get(`/apirecetario/?lote=${lote}&zafra=${zafra}`);
                commit('recetario_lote', data);
            } else {
                data = await db.get('/apirecetario/');
                commit('recetario_data', data);
            }
            commit('recetario_load', false);
            return true;
        } catch (e) {
            commit('recetario_load', false);
            return false;
        }
    },
    async Vact_recetario_siembra({commit}, {siembra}) {
        commit('recetario_load', true);
        try {
            const data = await db.get(`/apirecetariosiembra/?siembra=${siembra}`)
            commit('recetario_siembra', data)
            commit('recetario_load', false);
            return true;
        } catch (e) {
            commit('recetario_load', false);
            return false;
        }
    },
    async Vact_recetario_save({dispatch, commit}, data) {
        commit('recetario_load', true);
        try {
            const resp = await db.save('/apirecetario/', data);
            dispatch('Vact_recetario_data');
            commit('recetario_load', false);
            return resp;
        } catch (e) {
            commit('recetario_load', false);
            return e;
        }
    },
    async Vact_recetario_update({commit}, {id, data}) {
        commit('recetario_load', true);
        try {
            const resp = await db.update(`/apirecetario/${id}/`, data);
            commit('recetario_load', false);
            return resp;
        } catch (e) {
            commit('recetario_load', false);
            return e;
        }
    },
    async Vact_recetario_delete({commit}, id) {
        commit('recetario_load', true);
        try {
            const resp = await db.delete(`/apirecetario/${id}/`);
            commit('recetario_load', false);
            return resp;
        } catch (e) {
            commit('recetario_load', false);
            return e;
        }
    }
}