export default {
  tipoanalisis_create(state, request) {
    state.tipoanalisis_create = request;
  }, 
  tipoanalisis_update(state, request) {
    state.tipoanalisis_update = request;
  },   
  tipoanalisis_reload(state, request) {
    state.tipoanalisis_reload = request;
  },
  tipoanalisis_show(state, request) {
    state.tipoanalisis_show = request;
  },
  tipoanalisis_key(state, request) {
    state.tipoanalisis_key = request;
  }
}