import {SET_LOADING, SET_LOADING_MONTO, SET_MAQUINARIA_MONTO, SET_MAQUINARIA_SALARIO} from './constants'
export default {
  [SET_MAQUINARIA_SALARIO](state, request) {
    state.maquinariaSalario = request;
  },
  [SET_MAQUINARIA_MONTO](state, request) {
    state.maquinariaMonto = request;
  },
  [SET_LOADING](state, request) {
    state.isLoadingSalario = request;
  },
  [SET_LOADING_MONTO](state, request) {
    state.isLoadingMonto = request;
  }
}