import {SET_LOADING, SET_EMPRESA} from './constants'
export default {
  [SET_LOADING](state,request){
    state.empresa_load = request
  },
  [SET_EMPRESA](state,request){
    state.empresa_data = request;
  },
  empresa_moneda(state, request) {
    state.empresa_moneda = request;
  },
  empresa_active(state, request) {
    state.empresa_active = request;
  }
}