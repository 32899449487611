<template>
  <v-dialog :value="value" fullscreen persistent transition="dialog-bottom-transition" @keydown.esc="cerrar()">
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="cerrar">
          <v-icon>close</v-icon>
        </v-btn>
        <v-toolbar-title>{{titulo}}</v-toolbar-title>
        <div class="flex-grow-1"></div>
         <v-toolbar-items>
          <v-btn dark text v-if="modo" @click="cambiar">Ver arbol masculino</v-btn>
          <v-btn dark text v-if="!modo" @click="cambiar">Ver arbol general</v-btn> 
        </v-toolbar-items> 
      </v-toolbar>
      <div v-if="modo">
        <Animal-Animal-TreeChart :datos="datos" />
      </div>
      <div v-if="!modo">
        <Animal-Animal-TreeChartAsc :datos="datos" />
      </div> 
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    datos: {
      type: [Object, Array, Number],
      default: null,
    },
  },
  data() {
    return {
      modo: false,
      notifications: false,
      sound: true,
      widgets: false,
      data: null,
    };
  },
  computed: {
    titulo() {
      if (this.modo) {
        return "Arbol General";
      } else {
        return "Arbol Masculino";
      }
    },
  },
  watch: {
    $route() {
       return this.cerrar();
    },
  },
  methods: {
    cerrar() {
      this.$emit("input", false);
      this.$emit("datos", null);
    },
    cambiar() {
      if (this.modo) {
        this.modo = false;
      } else {
        this.modo = true;
      }
    },
  },
};
</script>