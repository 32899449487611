<template>
  <v-dialog :value="value" persistent max-width="800" @keydown.esc="cancelar()">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>{{operacion ? 'Emitir Cheque' : 'Modificar Cheque Emitido'}}</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-form ref="form">
          <v-row dense>
            <v-col cols="12" md="4">
              <vue-autocomplete-object
                id="emitirChequeInput1"
                action="cheque/Vact_cheque_data"
                getter="cheque/Vget_cheque_data"
                load="cheque/Vget_cheque_load"
                pk="idcheque"
                label="Seleccione la Chequera"
                title="descripcion"
                validar="idcheque"
                @sync-input="search = $event"
                permission="Cuenta Banco"
                :create="true"
                @create="formulario.cheque = $event"
                v-model="data.idcheque"
                @change="asignarNroCheque()"
                @keyup.native.enter="next(1,data.idcheque.idcheque)"
              />
              <div v-if="formulario.cheque">
                <FormCheque
                  v-model="formulario.cheque"
                  :sync-input="search"
                  @sync-input="data.idcheque = $event ,next(1,'-')"
                />
              </div>
            </v-col>
            <v-badge
              v-show="data.idcheque.idcheque"
              :value="true"
              color="deep-purple accent-4"
              :content="data.idcheque.diferido ? 'Diferido' : 'A la Vista'"
              left
              transition="slide-x-transition"
            ></v-badge>
            <v-spacer></v-spacer>
            <v-col cols="12" md="3">
              <vue-text-currency readonly label="Numero de Cheque" :rules="true" v-model="data.nro"></vue-text-currency>
            </v-col>
            <v-col cols="12">
              <vue-autocomplete-contacto-proveedor
                id="emitirChequeInput2"
                label="Seleccione Contacto"
                v-model="data.idcontacto"
                :rules="false"
                @keyup.native.enter="next(2,data.idcontacto)"
              />
            </v-col>
            <v-col cols="12" md="3">
              <c-text-date
                id="emitirChequeInput3"
                label="Fecha de Registro"
                dense
                outlined
                v-model="data.fecha"
                @keyup.native.enter="next(!option.diferido ? 4 : 3,data.fecha)"
              ></c-text-date>
            </v-col>
            <v-col cols="12" md="3" v-if="option.diferido">
              <c-text-date
                id="emitirChequeInput4"
                label="Fecha de Desembolso"
                dense
                outlined
                v-model="data.fecha_acredita"
                @keyup.native.enter="next(4,data.fecha_acredita)"
              ></c-text-date>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" md="3">
              <vue-text-currency
                id="emitirChequeInput5"
                label="Monto de Debitar"
                error
                v-model="data.monto"
                @keyup.native.enter="next(5,data.monto)"
              ></vue-text-currency>
            </v-col>
            <v-col cols="12">
              <vue-text-field
                id="emitirChequeInput6"
                label="Comentario"
                :not-current="true"
                v-model="data.comentario"
                @keyup.native.enter="next(6,data.comentario)"
              ></vue-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-container>

      <v-card-actions>
        <v-btn text color="red" @click="cancelar()">
          <v-icon>clear</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text color="primary" id="emitirChequeInputaceptar" @click="guardar()">
          <v-icon>done</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    <c-overlay :value="Vget_emisioncheque_save_load"></c-overlay>
  </v-dialog>
</template>

<script>
import { focus } from "@/services/focus.service";
import { mapActions, mapGetters } from "vuex";
import { mensaje } from "@/services/notify.service";
import { current_date } from "@/services/util/date.service";
import FormCheque from '@/views/CajaBanco/Cheque/Create'
export default {
  components:{
    FormCheque
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    datos: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      operacion: true,
      formulario: {
        cheque: false,
      },
      option: {
        diferido: false,
      },
      data: {
        idcheque_emitido: 0,
        nro: "",
        idcheque: {
          idcheque: "",
        },
        idcontacto: {
          idcontacto: "",
        },
        fecha: current_date(),
        fecha_acredita: null,
        pagado: false,
        anulado: false,
      },
      default: {
        idcheque_emitido: 0,
        nro: "",
        idcheque: {
          idcheque: "",
        },
        idcontacto: {
          idcontacto: "",
        },
        fecha: current_date(),
        fecha_acredita: null,
        pagado: false,
        anulado: false,
      },
    };
  },
  mounted() {
    if (this.datos != null) {
      if (this.datos.idcontacto) {
        this.data = JSON.parse(JSON.stringify(this.datos));
      } else {
        const temp = { ...this.datos, ...{ idcontacto: "" } };
        this.data = JSON.parse(JSON.stringify(temp));
      }
      return (this.operacion = false);
    }
    this.next(0, "-");
  },
  computed: {
    ...mapGetters("emisioncheque", ["Vget_emisioncheque_save_load"]),
  },
  methods: {
    ...mapActions("emisioncheque", [
      "Vact_emisioncheque_data",
      "Vact_emisioncheque_save",
      "Vact_emisioncheque_update",
      "Vact_emisioncheque_data_pagado"
    ]),
    asignarNroCheque() {
      if (this.data.idcheque === undefined) return (this.data.idcheque = "");
      this.data.idcheque.actual != undefined
        ? (this.data.nro = this.data.idcheque.actual)
        : null;
      this.option.diferido = this.data.idcheque.diferido;
      if (!this.option.diferido)
        this.data.fecha_acredita = current_date();
    },
    cancelar() {
      this.Vact_emisioncheque_data();
      this.Vact_emisioncheque_data_pagado();
      this.$emit("input", false);
      this.$emit("datos", null);
      this.operacion = true;
    },
    next(index, data) {
      focus.nextidName("emitirChequeInput", index, data);
    },
    async guardar() {
      if (!this.$refs.form.validate())
        return this.$notify(mensaje.error("Verifique los datos ingresados"));
      let a;
      if (this.operacion) a = await this.Vact_emisioncheque_save(this.data);
      if (!this.operacion)
        a = await this.Vact_emisioncheque_update({
          id: this.datos.idcheque_emitido,
          data: this.data,
        });

      if (a.estado == true) {
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$notify(a.info);
        this.$refs.form.resetValidation();
        this.next(0, "-");
        if (!this.operacion) this.cancelar();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
    },
  },
};
</script>

<style>
</style>