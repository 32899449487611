<template>
  <div>
    <Venta-apertura-create v-model="apertura_open" :datos="data" />
    <div v-if="apertura_show">
      <Venta-apertura-show
        v-model="apertura_show"
        :datos="data"
        @datos="data = $event"
      />
    </div>
    <v-card>
      <v-alert type="error" v-if="permission_alert"
        >Error!!! Este usuario no tiene permisos en esta ventana</v-alert
      >
      <v-toolbar flat>
        <v-toolbar-title>{{ titulo }}</v-toolbar-title>
        <widgets-Favorite ruta="/apertura" formulario="Apertura" />
        <v-divider class="mx-2" inset vertical></v-divider>

        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Busqueda"
          single-line
          hide-details
          v-if="permission.can_view"
        ></v-text-field>
      </v-toolbar>
      <v-data-table
        :loading="Vget_caja_load"
        :headers="headers"
        :items="Vget_caja_data"
        :search="search"
        class="elevation-1"
        v-if="permission.can_view"
      >
        <template v-slot:[`item.apertura`]="props">
          <v-btn
            class="ma-2"
            width="150px"
            tile
            outlined
            color="red"
            @click="rowselectview(props.item)"
            v-if="props.item.apertura[0]"
          >
            <v-icon left>lock</v-icon>ver
          </v-btn>
          <v-btn
            class="ma-2"
            width="150px"
            tile
            outlined
            color="primary"
            @click="rowselect(props.item)"
            v-else
          >
            <v-icon left>lock_open</v-icon>ABRIR
          </v-btn>
        </template>
        <template v-slot:no-data>
          <v-alert :value="true" color="info" icon="info" outlined
            >No existe ningun registro</v-alert
          >
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { APERTURA_CAJA } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";
export default {
  data() {
    return {
      data: [],
      overlay: false,
      apertura_open: false,
      apertura_show: false,
      search: "",
      headers: [
        { text: "Codigo", align: "left", value: "idcaja" },
        { text: "Caja", align: "left", value: "descaja" },
        {
          text: "Apertura",
          value: "apertura",
          sortable: false,
          align: "right",
        },
      ],
      datos: [],
      titulo: APERTURA_CAJA,
      index: "",
      load: false,
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
    };
  },
  watch: {
    $route(to) {
      if (to.path === "/apertura") {
        this.Vact_caja_data();
      }
    },
  },
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.Vact_caja_data();
    const permission = getPermission(APERTURA_CAJA).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  computed: {
    ...mapGetters("caja", ["Vget_caja_data", "Vget_caja_load"]),
    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },

  methods: {
    ...mapActions("caja", ["Vact_caja_data"]),
    rowselect(val) {
      this.data = val;
      this.apertura_open = true;
    },
    rowselectview(val) {
      this.apertura_show = true;
      this.data = val;
    },
  },
};
</script>