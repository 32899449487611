import { db } from "@/services/db.service";
export default {

    async Vact_campo_geo_json({commit},data){
        commit('campo_geo_json',null);
        commit('campo_geo_json',data);
    },
    async Vact_campo_data({commit}, baja='no'){
        commit('campo_load',true);
        try {
            const data = await db.get(`/apiloteagri/?baja=${baja}`);
            let ha = 0
            data.forEach(x => {
                ha = ha + x.hectarea;
            });
            commit('campo_ha', ha);
            commit('campo_data',data);
            commit('campo_load',false);
            return true
        } catch (e) {
            commit('campo_load',false);
            return false
        } 
    },
    async Vact_campo_clima({commit},id){
        commit('campo_load',true);
        try {
            const data = await db.get('/apiclima/'+id+'/');
            commit('campo_clima',data);
            commit('campo_load',false);
            return true
        } catch (e) {
            commit('campo_load',false);
            return false
        } 
    },
    async Vact_campo_pronostico({commit},id){
        commit('campo_load',true);
        try {
            const data = await db.get('/apipronostico/'+id+'/');
            commit('campo_pronostico',data);
            commit('campo_load',false);
            return true
        } catch (e) {
            commit('campo_load',false);
            return false
        } 
    },
    async Vact_campo_storyndvi({commit},{fechainicio,fechafinal,id}){
        commit('campo_load',true);
        try {
            const data = await db.get('/storyndvi/'+fechainicio+'/'+fechafinal+'/'+id+'/');
            commit('campo_storyndvi',data);
            commit('campo_load',false);
            return true
        } catch (e) {
            commit('campo_load',false);
            return false
        } 
    },
    async Vact_campo_data_by_id({commit},id){
        commit('campo_load',true);
        try {
            const data = await db.get('/apiloteagri/'+id+'/');
            commit('agromap/agromap_coordinates',data,{ root: true });
            commit('campo_load',false);
            return true
        } catch (e) {
            commit('campo_load',false);
            return false
        } 
    },
    async Vact_campo_del({dispatch,commit},id){
        try {
            const data = await db.delete('/apiloteagri/'+id+'/');
            dispatch('Vact_campo_data');
            dispatch('lotegrupo/Vact_lotegrupo_data', null, { root: true })
            commit('campo_deleted',id);
            return data
        } catch (e) {
            return false
        } 
    },
    async Vact_campo_deleted({commit}){
        commit('campo_deleted',null);
    },
    async Vact_campo_save({dispatch, commit},data){
        commit('campo_save_load',true);
        try {
            const resp = await db.save('/apiloteagri/',data);
            commit('campo_save_load',false);
            dispatch('Vact_campo_data');
            return resp;
        } catch (e) {
            commit('campo_save_load',false);
            return e
        } 
    },
    async Vact_campo_baja({commit}, id) {
        commit('campo_baja_load', true);
        try {
            const resp = await db.delete('/apiloteagri2/'+id+'/');
            commit('campo_baja_load', false);
            return resp;
        } catch (e) {
            commit('campo_baja_load', false);
            return e
        }
    },
    async Vact_campo_update({commit},{id, data}){
        commit('campo_save_load',true);
        try {
            const resp = await db.update('/apiloteagri/'+id+'/',data);
            commit('campo_save_load',false);
            return resp;
        } catch (e) {
            commit('campo_save_load',false);
            return e
        } 
    },
}
