<template>
  <div>
    <v-dialog :value="value" max-width="900px" persistent @keydown.esc="close()">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>{{header}}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-window v-model="step">
              <v-window-item :value="1">
                <c-overlay :value="overlay" />
                <v-tabs align-with-title>
                  <v-tab
                    v-for="(item,i) in data.apertura"
                    :key="i"
                    :href="'#tab-'+i"
                  >{{item.idmoneda.descmoneda}}</v-tab>
                  <v-tab-item v-for="(item,i) in data.apertura" :key="i" :value="'tab-'+i">
                    <v-row dense fluid>
                      <v-col cols="6">
                        <v-data-table
                          :headers="headers"
                          :items="item.cierre"
                          :items-per-page="50"
                          hide-default-header
                          hide-default-footer
                          class="elevation-1"
                          dense
                        >
                          <template v-slot:[`item.accion`]="props">
                            <v-text-field
                              placeholder="Ingrese cantidad"
                              v-model="data.apertura[i].cierre[indice(i,props.item)].cantidad"
                              @keyup="sumatotal(i)"
                            ></v-text-field>
                          </template>
                        </v-data-table>
                      </v-col>
                      <v-col cols="6" md="6" sm="6">
                        <v-alert outlined color="blue lighten-4" class="pa-5" text>
                          <vue-text-currency
                            class="pt-5"
                            :readonly="true"
                            v-model="data.apertura[i].idcaja.monto"
                            label="Saldo en Caja"
                          ></vue-text-currency>

                          <vue-text-currency
                            :readonly="true"
                            v-model="data.apertura[i].montocierre"
                            label="Saldo Arqueo"
                          ></vue-text-currency>

                          <vue-text-currency
                            :error="diferencia(data.apertura[i].idcaja.monto,data.apertura[i].montocierre) !=0"
                            :appendoutericon="diferencia(data.apertura[i].idcaja.monto,data.apertura[i].montocierre) === 0 ? 'done' : 'warning'"
                            :readonly="true"
                            label="Diferencia"
                            :value="diferencia(data.apertura[i].idcaja.monto,data.apertura[i].montocierre)"
                          ></vue-text-currency>
                        </v-alert>

                        <vue-text-currency
                          :outlined="false"
                          v-model="data.apertura[i].montocheque"
                          @keyup.native="sumatotal(i)"
                          placeholder="Ingrese Monto"
                          label="Cheques"
                        ></vue-text-currency>

                        <vue-text-currency
                          :outlined="false"
                          v-model="data.apertura[i].montotarjeta"
                          label="Tarjetas"
                          @keyup.native="sumatotal(i)"
                          placeholder="Ingrese Monto"
                        ></vue-text-currency>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs>
              </v-window-item>
              <v-window-item :value="2">
                <v-alert
                  dense
                  outlined
                  type="error"
                  v-if="!desactivar_deposito"
                >No se puede ingresar un valor superior al saldo</v-alert>
                <v-alert outlined color="purple lighten-4" text dense cols="12" md="3">
                  <v-row dense>
                    <vue-text-currency
                      v-for="(item,i) in data.apertura"
                      :key="i"
                      class="pt-5"
                      :readonly="true"
                      :value="calculardeposito(i)"
                      :label="`Saldo ${item.idmoneda.descmoneda}`"
                    ></vue-text-currency>
                  </v-row>
                </v-alert>

                <v-card-subtitle>
                  <span class="subtitle-1 font-weight-black">Transladar Saldo a Otra Caja</span>
                </v-card-subtitle>
                <v-alert outlined color="blue lighten-4" dense text>
                  <v-form ref="formtrans" lazy-validation>
                    <v-row dense class="pt-5">
                      <v-col cols="12" md="4" sm="4">
                        <input-autocomplete
                          label="Caja Destino"
                          :quitar_id="idcaja"
                          api="/apicaja/"
                          pk="idcaja"
                          title="descaja"
                          :noactive="false"
                          v-model="data_transladocaja.idcajadestino.idcaja"
                        />
                      </v-col>
                      <v-col cols="12" md="4" sm="4">
                        <c-text-date dense outlined label="Fecha Translado" id="input5" v-model="data_transladocaja.fechtransladocaja" />
                      </v-col>
                      <v-col cols="12" md="4" sm="4">
                        <vue-text-field
                          label="Motivo Translado"
                          id="input6"
                          v-model="data_transladocaja.motivotranslado"
                        />
                      </v-col>
                    </v-row>
                  </v-form>
                  <v-row dense>
                    <v-col cols="12" md="3" v-for="(item, i) in data.apertura" :key="i">
                      <vue-text-currency
                        :outlined="false"
                        :label="item.idmoneda.descmoneda"
                        placeholder="Ingrese Monto"
                        v-model="data_transladocaja.transladodet[i].monto"
                        @keyup.native="desactivar_deposito_buttom(i)"
                      ></vue-text-currency>
                    </v-col>
                  </v-row>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      small
                      color="primary"
                      @click="guardar_translado"
                      v-if="desactivar_deposito"
                    >Guardar</v-btn>
                  </v-card-actions>
                </v-alert>
              </v-window-item>
            </v-window>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="close()">
            <v-icon dark>close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn tile outlined color="red" @click="guardar" v-if="step === 1">
            <v-icon left>lock</v-icon>CERRAR CAJA
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { db } from "@/services/db.service";
import { mensaje } from "@/services/notify.service";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    datos: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      step: 1,
      tab: null,
      header: "Cierre de Caja",
      data: {
        apertura: [],
      },
      data_transladobanco: {
        idtransladobanco: 0,
        idcajaorigen: {
          idcaja: "",
        },
        fechtransladocaja: "",
        motivotranslado: "",
        transladodet: [],
      },
      data_transladocaja: {
        idtransladocaja: 0,
        idcajaorigen: {
          idcaja: "",
        },
        idcajadestino: {
          idcaja: "",
        },
        idtamboorigen: {
          idtambo: null,
        },
        idtambodestino: {
          idtambo: null,
        },
        fechtransladocaja: "",
        motivotranslado: "",
        transladodet: [],
        horatransladocaja: null,
      },
      temp: [],
      url: "/apicierre/",
      headers: [
        {
          text: "Denominacion",
          align: "left",
          value: "deno_desc",
        },
        { text: "Accion", value: "accion" },
      ],
      cuentabanco: [],
      loadcuentabanco: false,
      overlay: false,
      desactivar_deposito: true,
      idcaja: "",
    };
  },
  computed:{
    ...mapGetters('empresa',["Vget_empresa_active"])
  },
  created() {
    this.update(this.datos);
    this.asignarOrigenDestino();
  },
  watch: {
    $route(to, from) {
      if (from.path === "/apertura") return this.close();
    },
    Vget_empresa_active(){
      this.asignarOrigenDestino();
    }
  },
  methods: {
    ...mapActions("caja", ["Vact_caja_data"]),
    asignarOrigenDestino(){
      this.Vget_empresa_active.forEach(x=>{
        if(x.est_emp_tam_usu){
          this.data_transladocaja.idtamboorigen.idtambo = x.idtambo.idtambo;
          this.data_transladocaja.idtambodestino.idtambo = x.idtambo.idtambo;
        }
      })
    },
    close() {
      this.$emit('input',false);
      this.$emit('datos', []);
      this.Vact_caja_data();
    },
    calculardeposito(index) {
      return (
        Number(this.data.apertura[index].idcaja.monto) -
        (Number(this.data_transladocaja.transladodet[index].monto) +
          Number(this.data_transladobanco.transladodet[index].monto))
      );
    },
    desactivar_deposito_buttom(index) {
      let a = Number(this.data.apertura[index].idcaja.monto);
      let b = Number(this.data_transladocaja.transladodet[index].monto);
      let c = Number(this.data_transladobanco.transladodet[index].monto);
      let calc = a - (b + c);
      if (calc < 0) {
        this.desactivar_deposito = false;
      } else {
        this.desactivar_deposito = true;
      }
    },
    diferencia(a, b) {
      return Number(a) - Number(b);
    },
    indice(index, data) {
      return this.data.apertura[index].cierre.indexOf(data);
    },
    async guardar_finalizar(url, data) {
      let a = await db.save(url, data);
      if (a.estado == true) {
        this.$notify(a.info);
        this.$refs.form.resetValidation();
        this.step = 2;
        this.header = "Deposito de Saldos";
        return true;
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.overlay = false;
    },
    guardar() {
      this.data.apertura.map((x, index) => {
        if (x.montocheque === "") {
          this.data.apertura[index].montocheque = 0;
        }
        if (x.montotarjeta === "") {
          this.data.apertura[index].montotarjeta = 0;
        }
      });
      if (this.$refs.form.validate()) {
        this.overlay = true;
        return this.guardar_finalizar(this.url, this.data);
      } else {
        this.$notify(mensaje.completar());
      }
    },
    async guardar_translado() {
      if (this.$refs.formtrans.validate()) {
        this.overlay = true;

        const resp = await this.guardar_finalizar(
          "/apitransladocaja/",
          this.data_transladocaja
        );
        if (resp) {
          this.data.apertura.map((x, index) => {
            let deposito = this.data_transladocaja.transladodet[index].monto;
            this.data_transladocaja.transladodet[index].monto = "";
            this.data_transladocaja.motivotranslado = "";
            this.data.apertura[index].idcaja.monto = x.idcaja.monto - deposito;
            this.$refs.formtrans.resetValidation();
          });
        }
      } else {
        this.$notify(mensaje.completar());
      }
    },
    async guardar_transladob() {
      if (this.$refs.formtransb.validate()) {
        this.overlay = true;
        const resp = await this.guardar_finalizar(
          "/apitransladobanco/",
          this.data_transladobanco
        );
        if (resp) {
          this.data.apertura.map((x, index) => {
            let deposito = this.data_transladobanco.transladodet[index].monto;
            this.data_transladobanco.transladodet[index].monto = "";
            this.data_transladobanco.motivotranslado = "";
            this.data.apertura[index].idcaja.monto = x.idcaja.monto - deposito;
            this.$refs.formtransb.resetValidation();
          });
        }
      } else {
        this.$notify(mensaje.completar());
      }
    },
    async obtenercuentabanco() {
      this.loadcuentabanco = true;
      this.cuentabanco = await db.get("/apicuentabanco/");
      this.loadcuentabanco = false;
    },
    cuentabancoxmoneda(id) {
      const cuenta = [];
      this.cuentabanco.map((x) => {
        if (x.idmoneda.idmoneda === id) {
          cuenta.push({
            id: x.idcuentabanco,
            fkdes: x.desccuentabanco,
          });
        }
      });
      return cuenta;
    },
    sumatotal(index) {
      let suma = Number(0);
      this.data.apertura[index].cierre.map((x) => {
        suma = suma + x.valor * x.cantidad;
      });
      suma = suma + Number(this.data.apertura[index].montocheque);
      suma = suma + Number(this.data.apertura[index].montotarjeta);
      this.data.apertura[index].montocierre = suma;
    },
    async update(data) {
      this.overlay = true;
      this.obtenercuentabanco();
      if (data === null) return null;
      this.temp = await db.get(`/apiapertura/${data.apertura[1]}`);
      this.idcaja = this.temp.idcaja.idcaja;
      this.temp.apertura.map((x) => {
        let monto = 0;
        this.temp.idcaja.cajadet.map((z) => {
          if (z.idcaja === x.idcaja) {
            if (z.idmoneda.idmoneda === x.idmoneda.idmoneda) {
              monto = z.monto;
            }
          }
        });
        const deno = [];
        x.idmoneda.idmonedas.map((y) => {
          deno.push({
            idmoneda_deno: y.idmoneda_deno,
            idmoneda: y.idmoneda,
            deno_desc: y.deno_desc,
            valor: y.valor,
            cantidad: "",
          });
        });
        this.data_transladobanco.transladodet.push({
          idtransladobanco: 0,
          idmoneda: x.idmoneda.idmoneda,
          idcuentabanco: {
            idcuentabanco: "",
          },
          monto: "",
          nrotranslado: "",
        }),
          (this.data_transladobanco.idcajaorigen.idcaja = x.idcaja);
        this.data_transladocaja.idcajaorigen.idcaja = x.idcaja;
        this.data_transladocaja.transladodet.push({
          idtransladocaja: 0,
          idmoneda: {
            idmoneda: x.idmoneda.idmoneda,
          },
          idmonedaconvertir: {
            idmoneda: "",
          },
          monto: "",
          estado: false,
          cotizacion: null,
          convertir: false,
        });
        this.data.apertura.push({
          idaperturacaja: {
            idaperturacaja: x.idaperturacaja,
          },
          idcaja: {
            idcaja: x.idcaja,
            monto: monto,
          },
          idmoneda: {
            idmoneda: x.idmoneda.idmoneda,
            descmoneda: x.idmoneda.descmoneda,
          },
          montocierre: 0,
          montocheque: "",
          montotarjeta: "",
          fechacierre: "2000-01-01",
          horacierre: "00:00:00",
          cierre: deno,
        });
      });
      this.overlay = false;
    },
  },
};
</script>
