<template>
  <div>
    <div v-if="crud.add">
      <venta-establecimiento-crud
        v-model="crud.add"
        :datos="crud.datos"
        @datos="crud.datos = $event"
      />
    </div>
    <div v-if="crud.view">
      <venta-establecimiento-view
        v-model="crud.view"
        :datos="crud.datos"
        @datos="crud.datos = $event"
      />
    </div>
    <v-card>
      <v-alert type="error" v-if="permission_alert"
        >Error!!! Este usuario no tiene permisos en esta ventana</v-alert
      >
      <v-toolbar flat>
        <v-toolbar-title>{{ titulo }}</v-toolbar-title>
        <widgets-Favorite ruta="/establecimiento" :formulario="titulo" />
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Busqueda"
          single-line
          hide-details
          v-if="permission.can_view"
        ></v-text-field>
        <v-btn
          color="primary"
          small
          fab
          dark
          @click="crud.add = true"
          v-if="permission.can_add"
        >
          <v-icon dark>add</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-table
        :loading="Vget_establecimiento_load"
        :headers="headers"
        :items="Vget_establecimiento_data"
        :search="search"
        class="elevation-1"
        v-if="permission.can_view"
      >
        <template v-slot:[`item.accion`]="props">
          <v-icon class="mr-2" @click="rowselect(props.item)"
            >visibility</v-icon
          >
          <v-icon
            class="mr-2"
            @click="mupdate(props.item)"
            v-if="permission.can_update"
            >edit</v-icon
          >
          <v-icon
            small
            @click="meliminar(props.item)"
            v-if="permission.can_delete"
            >delete</v-icon
          >
        </template>
        <template v-slot:no-data>
          <v-alert :value="true" color="info" icon="info" outlined
            >No existe ningun registro</v-alert
          >
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogoeliminar" persistent max-width="350">
      <v-card>
        <v-card-title>
          <v-icon left>info</v-icon>
          <span class="title font-weight-light">Ventana de Confirmacion</span>
        </v-card-title>
        <v-card-text
          >Desea eliminar {{ objeto.descestablecimiento }}?</v-card-text
        >
        <v-card-actions>
          <v-btn color="red" text @click="eliminarcancelar">
            <v-icon>clear</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="meliminarguardar">
            <v-icon>done</v-icon>
          </v-btn>
        </v-card-actions>
        <c-overlay :value="isLoading"></c-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mapActions, mapGetters } from "vuex";
import { mensaje } from "@/services/notify.service";

import { ESTABLECIMIENTO } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";

export default {
  data() {
    return {
      titulo: ESTABLECIMIENTO,
      search: "",
      dialogoeliminar: false,
      objeto: "",
      isLoading: false,
      crud: {
        add: false,
        view: false,
        datos: null,
      },
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
      headers: [
        { text: "Codigo", align: "left", value: "idestablecimiento" },
        { text: "Descripcion", align: "left", value: "descestablecimiento" },
        { text: "Establecimiento", align: "left", value: "establecimiento" },
        { text: "Accion", align: "right", value: "accion" },
      ],
    };
  },
  computed: {
    ...mapGetters("establecimiento", [
      "Vget_establecimiento_data",
      "Vget_establecimiento_load",
    ]),
    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    const permission = getPermission(ESTABLECIMIENTO)
      .permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
    this.Vact_establecimiento_data();
  },
  methods: {
    ...mapActions("establecimiento", [
      "Vact_establecimiento_data",
      "Vact_establecimiento_del",
    ]),
    rowselect(val) {
      this.crud.datos = JSON.parse(JSON.stringify(val));
      this.crud.view = true;
    },
    meliminar(concepto) {
      this.objeto = Object.assign({}, concepto);
      this.dialogoeliminar = true;
    },
    eliminarcancelar() {
      this.objeto = "";
      this.dialogoeliminar = false;
    },

    async meliminarguardar() {
      this.isLoading = true;
      const id = this.objeto.idestablecimiento;
      const a = await this.Vact_establecimiento_del(id);
      if (a.estado == true) {
        this.$notify(a.info);
        this.dialogoeliminar = false;
        this.eliminarcancelar();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.isLoading = false;
    },

    mupdate(val) {
      this.crud.datos = JSON.parse(JSON.stringify(val));
      this.crud.add = true;
    },
  },
};
</script>