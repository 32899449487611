import {SET_LOADING, SET_VENTA, SET_REBUILD, SET_ITEM_VENTA,SET_VENTA_ADV, SET_VENTA_BYID, SET_REIMPRESION} from './constants'
export default {
  [SET_VENTA](state, request) {
    state.venta_data = request;
  },
  [SET_LOADING](state, request) {
    state.venta_load = request;
  },
  [SET_ITEM_VENTA](state,request){
    state.venta_itemvendido = request
  },
  [SET_VENTA_ADV](state,request){
    state.venta_advanced = request
  },
  [SET_VENTA_BYID](state,request){
    state.venta_byId = request
  },
  [SET_REIMPRESION](state,request){
    state.venta_reimpresion = request
  },
  [SET_REBUILD](state, request){
    state.venta_rebuild =request
  }
}