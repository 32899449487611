export default {
    async tipocontacto_create({commit},request){
        commit('tipocontacto_create',request);
    },
    async tipocontacto_update({commit},request){
        commit('tipocontacto_update',request);
    },
    async tipocontacto_reload({commit},request){
        commit('tipocontacto_reload',request);
    },
    async tipocontacto_show({commit},request){
        commit('tipocontacto_show',request);
    },

}
