import { db } from "@/services/db.service";
export default {
    async lotegrupo_update({commit}, data){
        commit('lotegrupo_update', data);
    },

    async Vact_lotegrupo_data({commit}, baja='no'){
        commit('lotegrupo_load', true);
        try {
            const data = await db.get(`/apilotegrupo/?baja=${baja}`);
            commit('lotegrupo_data', data);
            commit('lotegrupo_load', false);
            return true
        } catch (e) {
            commit('lotegrupo_load', false);
            return false
        } 
    },
    async Vact_lotegrupo_empresa_data({commit}, idempresa) {
        commit('lotegrupo_load', true);
        try {
            const data = await db.get('/apilotegrupo/?idempresa=' + idempresa);
            commit('lotegrupo_data', data);
            commit('lotegrupo_load', false);
            return true
        } catch (e) {
            commit('lotegrupo_load', false);
            return false
        }
    },
    async Vact_lotegrupo_save({dispatch, commit}, data){
        commit('lotegrupo_save_load', true);
        try {
            const resp = await db.save('/apilotegrupo/', data);
            dispatch('Vact_lotegrupo_data');
            commit('lotegrupo_save_load', false);
            return resp;
        } catch (e) {
            commit('lotegrupo_save_load', false);
            return e
        } 
    },
    async Vact_lotegrupo_update({commit}, {id, data}){
        commit('lotegrupo_save_load', true);
        try {
            const resp = await db.update('/apilotegrupo/'+id+'/', data);
            commit('lotegrupo_save_load', false);
            return resp;
        } catch (e) {
            commit('lotegrupo_save_load', false);
            return e
        } 
    },
    async Vact_lotegrupo_del({dispatch}, id){
        try {
            const data = await db.delete('/apilotegrupo/'+id);
            dispatch('Vact_lotegrupo_data');
            return data
        } catch (e) {
            return false
        } 
    }
}
