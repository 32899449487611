import { db } from "@/services/db.service";
export default {
    async gymcuota_update({commit},data){
        commit('gymcuota_update',data);
    },

    async Vact_gymcuota_data({commit}){
        commit('gymcuota_load',true);
        try {
            const data = await db.get('/apigimnasiocuota/');
            commit('gymcuota_data',data);
            commit('gymcuota_load',false);
            return true
        } catch (e) {
            commit('gymcuota_load',false);
            return false
        } 
    },
    async Vact_gymcuota_data_by({commit}, {id}) {
        commit('gymcuota_load', true);
        try {
            const data = await db.get('/apigimnasiocuota/?idgymplanilla=' + id)
            commit('gymcuota_data_by', data)
            commit('gymcuota_load', false);
            return true
        } catch (e) {
            commit('gymcuota_load', false);
            return false
        }
    },
    async Vact_gymcuota_save({dispatch, commit},data){
        commit('gymcuota_save_load',true);
        try {
            const resp = await db.save('/apigimnasiocuota/',data);
            dispatch('Vact_gymcuota_data');
            commit('gymcuota_save_load',false);
            return resp;
        } catch (e) {
            commit('gymcuota_save_load',false);
            return e
        } 
    },
    async Vact_gymcuota_update({commit},{id, data}){
        commit('gymcuota_save_load',true);
        try {
            const resp = await db.update('/apigimnasiocuota/'+id+'/',data);
            commit('gymcuota_save_load',false);
            return resp;
        } catch (e) {
            commit('gymcuota_save_load',false);
            return e
        } 
    },
    async Vact_gymcuota_del({dispatch},id){
        try {
            const data = await db.delete('/apigimnasiocuota/'+id);
            dispatch('Vact_gymcuota_data');
            return data
        } catch (e) {
            return false
        } 
    }
}
