import { db } from "@/services/db.service";

export default {
    async Vact_notacreditocompra_data({ commit }) {
        commit('notacreditocompra_load', true);
        try {
            const data = await db.get('/apinotacredito/?active=yes');
            commit('notacreditocompra_data', data);
            commit('notacreditocompra_load', false);
            return true;
        } catch (error) {
            commit('notacreditocompra_load', false);
            return error;
        }
    },
    async Vact_notacreditocompra_presupuesto({ commit }) {
        commit('notacreditocompra_load', true);
        try {
            const data = await db.get('/apinotacredito/');
            commit('notacreditocompra_presupuesto', data)
            commit('notacreditocompra_load', false);
        } catch (error) {
            commit('notacreditocompra_load', false)
            return error;
        }
    },

    async Vact_notacreditocompra_del({ dispatch }, { id, params }) {
        try {
            const data = await db.delete('/apinotacredito/' + id + '/', params);
            dispatch('Vact_notacreditocompra_data');
            return data
        } catch (e) {
            return false
        }
    },
    async Vact_notacreditocompra_save({ commit }, data) {
        commit('notacreditocompra_save_load', true);
        try {
            const resp = await db.save('/apinotacredito/', data);
            commit('notacreditocompra_save_load', false);
            return resp;
        } catch (e) {
            commit('notacreditocompra_save_load', false);
            return e
        }
    },
    async Vact_notacreditocompra_update({ commit }, { id, data }) {
        commit('notacreditocompra_save_load', true);
        try {
            const resp = await db.update('/apinotacredito/' + id + '/', data);
            commit('notacreditocompra_save_load', false);
            return resp;
        } catch (e) {
            commit('notacreditocompra_save_load', false);
            return e
        }
    }
}
