<template>
  <div>
    <c-alert-permission v-model="permission"></c-alert-permission>
    <v-toolbar flat>
      <v-toolbar-title>Reportes Comercial</v-toolbar-title>
      <widgets-Favorite
        ruta="/reportecomercial/estadoresultado"
        formulario="Informes Comerciales"
      />
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-tabs>
          <v-tab
            v-if="permission.estadoresultado"
            to="/reportecomercial/estadoresultado"
            >Estado de Resultado</v-tab
          >
          <v-tab v-if="permission.estadoresultado" to="/reportecomercial/estadoresultado2">
            Estado de Resultado (Dep)
          </v-tab>
          <v-tab
            v-if="permission.librocompra"
            to="/reportecomercial/librocompra"
            >Libro de Compras</v-tab
          >
          <v-tab v-if="permission.libroventa" to="/reportecomercial/libroventa"
            >Libro de Ventas</v-tab
          >
        </v-tabs>
      </v-toolbar-items>
    </v-toolbar>
    <v-divider></v-divider>
    <router-view></router-view>
  </div>
</template>

<script>
import { getPermission } from "@/services/storage/permissionStorage.service";
import { REPORTE_COMERCIAL } from "@/constants/forms";
import {
  ESTADO_RESULTADO,
  LIBRO_COMPRA,
  LIBRO_VENTA,
} from "@/constants/permissions";
export default {
  data: () => ({
    permission: {
      estadoresultado: false,
      librocompra: false,
      libroventa: false,
    },
  }),
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
  },
  mounted() {
    const permission = getPermission(REPORTE_COMERCIAL).permiso;
    this.permission = {
      estadoresultado: permission[ESTADO_RESULTADO],
      librocompra: permission[LIBRO_COMPRA],
      libroventa: permission[LIBRO_VENTA],
    };
    const path = "/reportecomercial/estadoresultado";
    if (this.$route.path != path)
      this.$router.push({
        path,
      });
  },
};
</script>

<style>
</style>