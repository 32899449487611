export default {
  rubro_data(state, request) {
    state.rubro_data = request;
  }, 
  rubro_load(state, request) {
    state.rubro_load = request;
  }, 
  rubro_save_load(state, request) {
    state.rubro_save_load = request;
  }, 
}