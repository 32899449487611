export default {

  egreso_data(state, request) {
    state.egreso_data = request;
  },
  egreso_load(state, request) {
    state.egreso_load = request;
  },
  egreso_save_load(state, request) {
    state.egreso_save_load = request;
  }
}