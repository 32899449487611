<template>
  <div>
    <v-dialog v-model="dialogo" max-width="800px" persistent>
      <v-card>
        <v-form ref="form" lazy-validation>
          <v-toolbar flat>
            <v-toolbar-title>Detalle de Contacto</v-toolbar-title>
          </v-toolbar>

          <v-card-text>
            <v-row row dense>
              <v-col cols="10" sm="3" md="3">
                <v-text-field
                  dense
                  type="text"
                  v-model="data.ruccontacto"
                  label="RUC"
                  readonly
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="2" sm="1" md="1">
                <v-text-field
                  dense
                  type="text"
                  v-model="data.docucontactos"
                  label="DV"
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="8" md="8">
                <v-text-field
                  dense
                  type="text"
                  v-model="data.desccontacto"
                  label="Razon Social"
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-text-field
                  dense
                  type="text"
                  v-model="data.telecontacto"
                  label="Telefono"
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="5" md="5">
                <v-text-field
                  dense
                  type="text"
                  v-model="data.correo"
                  label="Correo"
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3" md="3">
                <v-text-field
                  dense
                  type="text"
                  v-model="dateFormatted"
                  label="Fecha Nac"
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  dense
                  type="text"
                  v-model="data.direcontacto"
                  label="Direccion"
                  outlined
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  dense
                  type="text"
                  v-model="data.idnacionalidad.descnacionalidad"
                  label="Nacionalidad"
                  outlined
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  dense
                  type="text"
                  v-model="data.idciudad.descciudad"
                  label="Ciudad"
                  outlined
                  readonly
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-row justify="space-around">
                  <v-checkbox disabled v-model="check_cliente" label="Cliente"></v-checkbox>
                  <v-checkbox disabled v-model="check_proveedor" label="Proveedor"></v-checkbox>
                  <v-checkbox disabled v-model="check_transportadora" label="Transportadora"></v-checkbox>
                  <v-checkbox disabled v-model="check_conductor" label="Conductor"></v-checkbox>
                </v-row>
              </v-col>
              <v-col cols="12" sm="12" md="12" v-if="data.grupo[0] != undefined">
                <v-text-field
                  dense
                  type="text"
                  v-model="data.grupo[0].idcontacto.desccontacto"
                  label="Representante"
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" v-if="data.cliente != undefined">
                <v-text-field
                  dense
                  type="text"
                  v-model="data.cliente[0].idmoneda.descmoneda"
                  label="Moneda"
                  outlined
                  readonly
                ></v-text-field>
              </v-col>
               <v-col cols="12" sm="6" md="6" v-if="data.cliente != undefined">
                <vue-text-currency
                  v-model="data.cliente[0].lineacredito"
                  label="Limite Credite"
                  :length="0"
                  readonly
                ></vue-text-currency>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="red" text @click="cancelar"><v-icon>clear</v-icon></v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { db } from "@/services/db.service";
export default {
  data() {
    return {
      noData: true,
      active: false,
      date: new Date().toISOString().substr(0, 10),
      dateFormatted: null,
      loadfirebase: false,
      check: "",
      check_cliente: true,
      check_proveedor: false,
      check_transportadora: false,
      check_conductor: false,
      data: {
        destcontacto: "",
        ruccontacto: "",
        docucontactos: "",
        fechcontacto: "",
        direcontacto: "",
        telecontacto: "",
        idnacionalidad: {
          idnacionalidad: "",
          descnacionalidad: ""
        },
        idciudad: {
          idciudad: "",
          descciudad: ""
        },
        idtipocontacto: {
          idtipocontacto: ""
        },
        cliente: "",
        proveedor: "",
        transportadora: "",
        conductor: ""
      },
      default: {
        destcontacto: "",
        ruccontacto: "",
        docucontactos: "",
        fechcontacto: "",
        direcontacto: "",
        telecontacto: "",
        idnacionalidad: {
          idnacionalidad: ""
        },
        idciudad: {
          idciudad: ""
        },
        idtipocontacto: {
          idtipocontacto: ""
        },
        cliente: "",
        proveedor: "",
        transportadora: "",
        conductor: ""
      },
      dialogo: false,
      menu1: false,
      reload: 0,
      operacion: true,
      nacionalidad: [],
      ciudad: []
    };
  },

  watch: {
    $route(to, from) {
      if (from.path === "/contacto") return this.cancelar();
    },
    // eslint-disable-next-line
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    }
  },
  computed: {
    ...mapGetters("contacto", {
      create: "contacto_create",
      update: "contacto_update"
    })
  },
  created() {
    this.nuevo(this.update);
  },

  methods: {
    ...mapActions("contacto", ["contacto_update", "contacto_show"]),
    cancelar() {
      this.data = this.default;
      this.dialogo = false;
      this.contacto_update(null);
      this.contacto_show(false);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },

    cambiarciudad() {
      var a = this.data.idnacionalidad.idnacionalidad;

      db.get("/apiciudad/").then(b => {
        b.map(element => {
          if (element.idnacionalidad.idnacionalidad == a) {
            this.ciudad.push({
              id: element.idciudad,
              fkdes: element.descciudad
            });
          }
        });
      });
    },
    nuevo(val) {
      this.dialogo = true;
      if (val !== null) {
        this.check_cliente = false;
        let data = val;
        this.dateFormatted = this.formatDate(data.fechcontacto);
        if (data.idnacionalidad === null) {
          data.idnacionalidad = { idnacionalidad: "" };
        }
        if (data.idciudad === null) {
          data.idciudad = { idciudad: "" };
        }
        if (data.proveedor != "") {
          this.check_proveedor = true;
        }
        if (data.cliente != "") {
          this.check_cliente = true;
        }
        if (data.transportadora != "") {
          this.check_transportadora = true;
        }
        if (data.conductor != "") {
          this.check_conductor = true;
        }

        this.data = data;
        this.operacion = false;
      }
    }
  }
};
</script>