export default [
    {
        path: '/item',
        name: 'Item',
        component: () => import( /* webpackChunkName: "Item" */ '@/views/Item/Item/Item.vue'),
      },
      {
        path: '/concepto',
        name: 'Concepto',
        component: () => import( /* webpackChunkName: "Concepto" */ '@/views/Item/Concepto/Concepto.vue'),
      },
      {
        path: '/marca',
        name: 'Marca',
        component: () => import( /* webpackChunkName: "Marca" */ '@/views/Item/Marca/Marca.vue'),
      },
      {
        path: '/unidadmedida',
        name: 'Unidad',
        component: () => import( /* webpackChunkName: "UnidadMedida" */ '@/views/Item/UnidadMedida/UnidadMedida.vue'),
      },
      {
        path: '/categoria',
        name: 'Categoria',
        component: () => import( /* webpackChunkName: "Marca" */ '@/views/Item/Categoria/Categoria.vue'),
      },
      {
        path: '/cuenta',
        name: 'Cuenta',
        component: () => import( /* webpackChunkName: "Marca" */ '@/views/Item/Cuenta/Cuenta.vue'),
      },
      {
        path: '/deposito',
        name: 'Deposito',
        component: () => import( /* webpackChunkName: "Deposito" */ '@/views/Item/Deposito/Deposito.vue'),
      },
      {
        path: '/propiedadquimica',
        name: 'Propiedad Quimica',
        component: () => import( /* webpackChunkName: "Deposito" */ '@/views/Item/PropiedadQuimica/PropiedadQuimica.vue'),
      },
      {
        path: '/objetivo',
        name: 'Objetivo Item',
        component: () => import( /* webpackChunkName: "Deposito" */ '@/views/Item/Objetivo/Objetivo.vue'),
      },
    ]
