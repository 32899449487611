import { db } from "@/services/db.service";
export default {

    async Vact_alimentacion_data({commit}){
        commit('alimentacion_load',true);
        try {
            const data = await db.get('/apialimentacion/');
            commit('alimentacion_data',data);
            commit('alimentacion_load',false);
            return true
        } catch (e) {
            commit('alimentacion_load',false);
            return false
        } 
    },
    async Vact_alimentacion_save({dispatch, commit},data){
        commit('alimentacion_save_load',true);
        try {
            const resp = await db.save('/apialimentacion/',data);
            dispatch('Vact_alimentacion_data');
            commit('alimentacion_save_load',false);
            return resp;
        } catch (e) {
            commit('alimentacion_save_load',false);
            return e
        } 
    },
    async Vact_alimentacion_update({commit},{id, data}){
        commit('alimentacion_save_load',true);
        try {
            const resp = await db.update('/apialimentacion/'+id+'/',data);
            commit('alimentacion_save_load',false);
            return resp;
        } catch (e) {
            commit('alimentacion_save_load',false);
            return e
        } 
    },
    async Vact_alimentacion_del({dispatch},id){
        try {
            const data = await db.delete('/apialimentacion/'+id+'/');
            dispatch('Vact_alimentacion_data');
            return data
        } catch (e) {
            return false
        } 
    }
}
