import { get, post,  } from "@/services/api/api.service";
import { FETCH, LOADING, url } from './constants'
export default {
    async fetchConfigGanaderia({ commit, dispatch }) {
        commit(LOADING, true);
        try {
            const response = await get(url.root);
            commit(FETCH, response);
        } catch (e) {
            dispatch('snackbar/setNotification', e, { root: true });
            commit(LOADING, false);
            throw e
        }
        commit(LOADING, false);
    },

    async setConfigGanaderia({ commit, dispatch },  data ) {
        commit(LOADING, true);
        const response = await post(url.root, data);
        dispatch('snackbar/setNotification', response, { root: true })
        commit(LOADING, false);
        return response
    }
}
