const SUBTITULO = 'Indice de movimientos';
const INGRESOS = 'Ingresos';
const GASTOS = 'Gastos';
const PRODUCCION = 'Produccion';
const generarSeries = (response) => {
    let option = [];
    let years = [];
    Object.entries(response.ingresos).map(([key]) => {

        let sumIngresos = 0;
        response.ingresos[key].forEach(monto => {
            sumIngresos = sumIngresos + monto;
        })
        let sumEgresos = 0;
        response.egresos[key].forEach(monto => {
            sumEgresos = sumEgresos + monto;
        })
        let sumProduccion = 0;
        response.produccion[key].forEach(monto => {
            sumProduccion = sumProduccion + monto;
        })

        if (Number(sumIngresos) + Number(sumEgresos) + Number(sumProduccion) != 0) {
            years.push({
                value: key
            })
            option.push({
                title: { text: key },
                series: [
                    {
                        data: response.ingresos[key].map((ingresos, index) => {
                            return {
                                name: response.lotes[index],
                                value: Math.floor(ingresos),
                            }
                        })
                    },
                    {
                        data: response.egresos[key].map((egresos, index) => {
                            return {
                                name: response.lotes[index],
                                value: Math.floor(egresos)
                            }
                        })
                    },
                    {
                        data: response.produccion[key].map((produccion, index) => {
                            return {
                                name: response.lotes[index],
                                value: Math.floor(produccion)
                            }
                        })
                    },
                    {
                        data: [
                            {
                                name: INGRESOS,
                                value: Math.floor(sumIngresos)
                            },
                            {
                                name: GASTOS,
                                value: Math.floor(sumEgresos)
                            },
                            {
                                name: PRODUCCION,
                                value: Math.floor(sumProduccion)
                            }
                        ]
                    }
                ]
            })
        }
    })
    return { option, years }
}

const graficoindice = (response, dark) => {

    const {option, years} = generarSeries(response);
    let color = dark ? '#ccc' : '#000'
    return {
        baseOption: {
            timeline: {
                axisType: 'category',
                autoPlay: true,
                playInterval: 1500,
                padding: [
                    30,  // up
                    10, // right
                    0,  // down
                    10, // left
                ],
                data: years
                ,
                label: {
                    formatter: years,
                    textStyle: {
                        color: color
                    }
                }
            },
            title: {
                subtext: SUBTITULO,
                textStyle: {
                    color: color
                }
            },
            tooltip: {
            },
            legend: {
                left: 'right',
                data: [INGRESOS, GASTOS],
                textStyle: {
                    color: color
                }
            },
            calculable: true,
            grid: {
                top: 80,
                bottom: 100,
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow',
                        label: {
                            show: true,
                            formatter: function (params) {
                                return params.value.replace('\n', '');
                            }
                        },
                        textStyle: {
                            color: color
                        }
                    }
                }
            },
            xAxis: [
                {
                    type: 'category',
                    data: response.lotes,
                    axisLabel: {
                        interval: 0,
                        rotate: 12,
                        textStyle: {
                            color: color
                        }
                    },
                    splitLine: { show: false },

                }
            ],
            yAxis: [
                {
                    type: 'value',
                    axisLabel: {
                        textStyle: {
                            color: color
                        }
                    },
                }
            ],
            series: [
                { name: INGRESOS, type: 'bar', },
                { name: GASTOS, type: 'bar' },
                { name: PRODUCCION, type: 'bar' },
                {
                    type: 'pie',
                    center: ['85%', '30%'],
                    radius: '18%',
                    z: 100
                }
            ]
        },
        options: option
    };
}
export default graficoindice;