<template>
  <div>
    <v-dialog :value="value" max-width="700px" persistent @keydown.esc="cancelar()">
      <v-card>
        <v-form ref="form" lazy-validation>
          <v-toolbar flat>
            <v-toolbar-title>{{'Detalle de ajuste Cuenta Bancaria'}}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-row dense>
              <v-col cols="12">
                <vue-text-field
                  readonly
                  label="Observacion"
                  v-model="data.observacion"
                />
              </v-col>
              <v-col cols="12">
                <vue-text-field
                  readonly
                  label="Cuenta Banco"
                  v-model="data.idcuentabanco.desccuentabanco"
                />
              </v-col>
              <v-col cols="12">
                <vue-text-field
                  readonly
                  label="Moneda"
                  v-model="data.idmoneda.descmoneda"
                />
              </v-col>
              <v-col cols="12">
                <vue-text-currency
                  label="Monto"
                  readonly
                  v-model="data.monto"
                />
              </v-col>
              <v-col cols="12">
                <vue-text-field
                  label="Motivo de Ajuste"
                  readonly
                  v-model="data.idmotivoajuste.descmotivoajuste"
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-btn color="red" text @click="cancelar">
              <v-icon dark>close</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: null
    },
    mostrar: {
      type: [Object,Array]
    }
  },
  data() {
    return {
      data: {
        idajustecuenta: 0,
        observacion: "",
        monto: "",
        idcuentabanco: {
          idcuentabanco: ""
        },
        idmotivoajuste: {
          idmotivoajuste: ""
        },
        idmoneda: {
          idmoneda: ""
        }
      },
      default: {
        idajustecuenta: 0,
        observacion: "",
        monto: "",
        idcuentabanco: {
          idcuentabanco: ""
        },
        idmotivoajuste: {
          idmotivoajuste: ""
        },
        idmoneda: {
          idmoneda: ""
        }
      },
      monedaCuenta: ""
    };
  },
  mounted(){
    this.data = JSON.parse(JSON.stringify(this.mostrar))
  },
  watch: {
    $route(to, from) {
      if (from.path === "/ajustecuentabancaria") return this.cancelar();
    }
  },
 
  methods: {
    cancelar() {
      this.$emit("input", false);
      this.$emit("mostrar",null);
      this.data = JSON.parse(JSON.stringify(this.default));
    }
  }
};
</script>