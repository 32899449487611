<template>
  <v-dialog :value="value" max-width="1000" persistent>
    <v-card>
      <v-toolbar flat dense>
        <v-toolbar-title>Operaciones</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
            <v-tabs color="deep-purple accent-4" right v-model="tab">
                <v-tab>Cuotas</v-tab>
                <v-tab>Rutinas</v-tab>
                <v-tab>Asistencias</v-tab>
            </v-tabs>
        </v-toolbar-items>
      </v-toolbar>
      <div class="d-flex">
        <v-col cols="12">
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <Cuotas :props=props />
            </v-tab-item>
            <v-tab-item>
              <Rutina :props=props />
            </v-tab-item>
            <v-tab-item>
              <Asistencia :props=props />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </div>
      <v-card-actions>
        <c-btn-crud-close @click="cancelar()"></c-btn-crud-close>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Cuotas from "@/views/Gym/Gymcuota/Cuotas";
import Rutina from "@/views/Gym/Gymrutina/Rutina";
import Asistencia from "@/views/Gym/Gymasistencia/Asistencia";
import { mapActions } from 'vuex';
export default {
  components: {
    Cuotas,
    Rutina,
    Asistencia,
  },
  props: {
    value: Boolean,
    props: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
      tab: null,
  }),
  methods: {
    ...mapActions("gymplanilla", [
      "Vact_gymplanilla_data"
    ]),
    cancelar() {
      this.$emit("input", false);
      this.Vact_gymplanilla_data();
    },
  },
};
</script>
