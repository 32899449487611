import { get } from "@/services/api/api.service";
import { SET_LOADING, SET_DOC_TIPO, url,} from './constants'
export default {
    async fetchDocTipo({ commit, dispatch }) {
        commit(SET_LOADING, true);
        try {
            const response = await get(url);
            commit(SET_DOC_TIPO, response);
        } catch (e) {
            dispatch('snackbar/setNotification', e, { root: true });
            commit(SET_LOADING, false);
            throw e
        }
        commit(SET_LOADING, false);
    },
    
}
