<template>
  <v-card class="mt-1">
    <c-alert-permission v-model="permission"></c-alert-permission>
    <v-toolbar flat v-if="permission.estadoresultado">
      <v-toolbar-title>Estado de Resultado</v-toolbar-title>
    </v-toolbar>
    <v-form ref="form" >
      <v-row dense class="ma-2">
        <v-col cols="12" sm="3">
          <v-autocomplete
            :items="Vget_tambo_data"
            :loading="Vget_tambo_load"
            item-text="nombtambo"
            item-value="idtambo"
            return-object
            :rules="[v=>!!v ||'Selecciones una Sucursal']"
            autocomplete="off"
            hide-details
            multiple
            label="Sucursal"
            v-model="data.sucursal"
          >
            <template v-slot:no-data>
              <v-list-item dense>
                <v-list-item-content>
                  <v-list-item-title
                    >No se encontro ningun Lote</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="3">
          <c-text-date
            autocomplete="off"
            hide-details
            :outlined="false"
            :dense="false"
            label="Fecha Inicio"
            id="input3"
            v-model="data.firstDate"
          ></c-text-date>
        </v-col>
        <v-col cols="12" sm="3">
          <c-text-date
            autocomplete="off"
            hide-details
            :outlined="false"
            :dense="false"
            label="Fecha Fin"
            id="input3"
            v-model="data.lastDate"
          ></c-text-date>
        </v-col>
        <v-spacer></v-spacer>
        <v-btn
          text
          class="mt-2"
          outlined
          color="primary"
          dense
          @click="getReport()"
        >
          <v-icon>cloud_download</v-icon>
        </v-btn>
      </v-row>
    </v-form>
    <c-overlay :value="data.isLoading"></c-overlay>

    <v-tabs color="deep-purple accent-4" right v-if="permission.estadoresultado">
      <v-tab v-for="(item, i) of response" :key="i">{{ item.year }}</v-tab>
      <v-tab-item v-for="(item, i) of response" :key="i">
        <v-alert
          text
          v-if="item.datos.length === 0"
          prominent
          type="error"
          icon="mdi-cloud-alert"
          >NINGUN REGISTRO ENCONTRADO.</v-alert
        >
        <div v-for="(sub, b) of item.datos" :key="b">
          <v-toolbar flat>
            <v-toolbar-title>Moneda {{ sub.moneda }}</v-toolbar-title>
          </v-toolbar>
          <v-progress-linear
            value="100"
            height="3"
            color="orange"
          ></v-progress-linear>
          <v-data-table
            dense
            hide-default-footer
            :items-per-page="999999999"
            :headers="headers"
            :items="sub.data"
            class="elevation-1 ma-2"
          >
            <template v-slot:item.descripcion="props">
              <p
                class="text-start ma-0 font-weight-black"
                v-if="props.item.debe === '-'"
              >
                {{ props.item.descripcion }}
              </p>
              <p class="text-start ma-0" v-else>{{ props.item.descripcion }}</p>
            </template>
            <template v-slot:item.debe="props">
              <p class="text-end ma-0" v-if="props.item.debe != '-'">
                {{ currencyFormat(props.item.debe) }}
              </p>
            </template>
            <template v-slot:item.haber="props">
              <p class="text-end ma-0" v-if="props.item.debe != '-'">
                {{ currencyFormat(props.item.haber) }}
              </p>
            </template>
            <template slot="body.append">
              <tr>
                <th class="subtitle-2 black--text">TOTAL</th>
                <th class="subtitle-2 text-end black--text">
                  {{ currencyFormat(sub.debe) }}
                </th>
                <th class="subtitle-2 text-end black--text">
                  {{ currencyFormat(sub.haber) }}
                </th>
              </tr>
            </template>
          </v-data-table>

          <v-card class="ma-2">
            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>Cuentas por Cobrar</td>
                    <td class="text-end">
                      {{ currencyFormat(sub.porcobrar) }}
                    </td>
                  </tr>
                  <tr>
                    <td>Cuentas por Pagar</td>
                    <td class="text-end">{{ currencyFormat(sub.porpagar) }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">
                      Ganancia sin cuentas a Pagar y Cobrar
                    </td>
                    <td class="text-end font-weight-bold">
                      {{ currencyFormat(sub.debe - sub.haber) }}
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">
                      Ganancia con cuentas a Pagar y Cobrar
                    </td>
                    <td class="text-end font-weight-bold">
                      {{
                        currencyFormat(
                          sub.debe + sub.porcobrar - (sub.haber + sub.porpagar)
                        )
                      }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>
        </div>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { current_last_date, current_first_date } from "@/services/util/date.service";
import { db } from "@/services/db.service";
import { currency } from "@/services/util/number.service";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { REPORTE_COMERCIAL } from "@/constants/forms";
import { ESTADO_RESULTADO } from "@/constants/permissions";
export default {
  data() {
    return {
      data: {
        sucursal: [],
        lastDate: current_last_date(),
        firstDate: current_first_date(),
        isLoading: false,
      },
      response: [],
      headers: [
        {
          text: "Cuenta",
          value: "descripcion",
          sortable: false,
        },
        {
          text: "Debe",
          value: "debe",
          width: "30",
          sortable: false,
          align: "right",
        },
        {
          text: "Haber",
          value: "haber",
          sortable: false,
          align: "right",
        },
      ],
      permission:{
        estadoresultado: false
      }
    };
  },
  methods: {
    ...mapActions("tambo", ["Vact_tambo_data"]),
    currencyFormat(val) {
      return currency(val);
    },
    async getReport() {
      console.log(this.$refs.form)
      if (!this.$refs.form.validate()) return null;
      try {
        this.data.isLoading = true;
        let sucursal;
        this.data.sucursal.forEach((x, i) => {
          if (i === 0) return (sucursal = x.idtambo);
          sucursal = sucursal + "-" + x.idtambo;
        });
        let response = await db.get(
          `/apiestadoresultado/?sucursal=${sucursal}&fecha_inicio=${this.data.firstDate}&fecha_fin=${this.data.lastDate}`
        );
        response.map((year) => {
          year.datos.map((moneda) => {
            moneda.data = [
              {
                descripcion: "Ingresos",
                debe: "-",
                haber: "-",
              },
            ];
            moneda.ingreso.map((x) => {
              moneda.data.push({
                descripcion: x.descripcion,
                debe: x.monto,
                haber: 0,
              });
            });
            moneda.data.push({
              descripcion: "Egresos",
              debe: "-",
              haber: "-",
            });
            moneda.egreso.map((x) => {
              moneda.data.push({
                descripcion: x.descripcion,
                debe: 0,
                haber: x.monto,
              });
            });
            moneda.data.push({
              descripcion: "Compras",
              debe: "-",
              haber: "-",
            });
            moneda.compra.map((x) => {
              moneda.data.push({
                descripcion: x.descripcion,
                debe: 0,
                haber: x.monto,
              });
            });
            moneda.debe = 0;
            moneda.haber = 0;
            moneda.data.map((x) => {
              if (x.debe != "-") {
                moneda.debe = moneda.debe + x.debe;
                moneda.haber = moneda.haber + x.haber;
              }
            });
          });
        });
        this.response = JSON.parse(JSON.stringify(response));
        this.data.isLoading = false;
      } catch (error) {
        console.log(error);
        this.$notify(mensaje.error(error));
        this.data.isLoading = false;
      }
    },
  },
  mounted() {
    this.Vact_tambo_data();
    const permission = getPermission(REPORTE_COMERCIAL)
      .permiso;
    this.permission = {
      estadoresultado: permission[ESTADO_RESULTADO],
    };
  },
  computed: {
    ...mapGetters("tambo", ["Vget_tambo_data", "Vget_tambo_load"]),

  }
};
</script>

<style>
</style>