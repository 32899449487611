export default {
  bonificacionagro_data: [],
  bonificacionagro_load: false,
  ventaagro_update: null,
  ventaagro_data: [],
  ventaagro_data_byId: [],
  ventaagro_load: false,
  ventaagro_save_load: false
}


