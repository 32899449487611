<template>
  <div>
    <Create
      v-if="crud.add"
      v-model="crud.add"
      :datos="crud.datos"
      @datos="crud.datos = $event"
    />

    <v-alert type="error" v-if="permission_alert"
      >Error!!! Este usuario no tiene permisos en esta ventana</v-alert
    >
    <v-toolbar flat dense>
      <v-toolbar-title>{{ titulo }}</v-toolbar-title>
      <widgets-Favorite :ruta="$route.path" :formulario="titulo" />
      <v-divider class="mx-2" inset vertical></v-divider>

      <v-spacer></v-spacer>
      <c-text-table-search
        v-if="permission.can_view"
        v-model="search"
      ></c-text-table-search>
      <v-toolbar-items>
        <c-btn-table-add
          @click="crud.add = true"
          v-if="permission.can_add"
        ></c-btn-table-add>
      </v-toolbar-items>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="Vget_emisioncheque_data"
      :loading="Vget_emisioncheque_load"
      :search="search"
      v-if="permission.can_view"
    >
      <template v-slot:item.estado="{ item }">
        <v-chip :color="item.color" dark>{{ item.estado }}</v-chip>
      </template>
      <template v-slot:item.monto="{ item }">
        <div>{{ toCurrency(item.monto) }}</div>
      </template>
      <template v-slot:item.accion="props">
        <v-icon
          :disabled="props.item.anulado"
          class="mr-2"
          @click="mupdate(props.item)"
          v-if="permission.can_update"
          >edit</v-icon
        >
        <v-icon
          :disabled="props.item.anulado"
          @click="meliminar(props.item)"
          v-if="permission.can_delete"
          >delete</v-icon
        >
      </template>
      <template v-slot:no-data>
        <v-alert :value="true" color="info" icon="info" outlined
          >No existe ningun registro</v-alert
        >
      </template>
    </v-data-table>

    <v-dialog v-model="crud.delete" persistent max-width="350">
      <v-card>
        <v-card-title>
          <v-icon left>info</v-icon>
          <span class="title font-weight-light">Ventana de Confirmacion</span>
        </v-card-title>
        <v-card-text
          >Desea eliminar el cheque Nro {{ objeto.nro }}?</v-card-text
        >
        <v-card-actions>
          <v-btn color="red" text @click="eliminarcancelar">
            <v-icon>clear</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="meliminarguardar()">
            <v-icon>done</v-icon>
          </v-btn>
        </v-card-actions>
        <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { currency } from "@/services/util/number.service";
import { EMITIR_CHEQUE } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";
import Create from "./Create";
export default {
  components: {
    Create,
  },
  data() {
    return {
      overlay: false,
      search: "",
      headers: [
        {
          text: "Codigo",
          align: "left",
          value: "idcheque_emitido",
          divider: true,
        },
        { text: "Numero", align: "left", value: "nro" },
        { text: "Contacto", align: "left", value: "idcontacto.desccontacto" },
        { text: "Fecha Emision", align: "left", value: "fecha" },
        { text: "Fecha Desembolso", align: "left", value: "fecha_acredita" },
        { text: "Comentario", align: "left", value: "comentario" },
        { text: "Estado", align: "left", value: "estado" },
        { text: "Monto", align: "right", value: "monto" },
        { text: "Accion", value: "accion", sortable: false, align: "right" },
      ],
      titulo: EMITIR_CHEQUE,
      objeto: "",
      index: "",
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
      crud: {
        add: false,
        delete: false,
        datos: null,
      },
    };
  },

  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.Vact_emisioncheque_data();
    const permission = getPermission(EMITIR_CHEQUE).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  computed: {
    ...mapGetters("emisioncheque", [
      "Vget_emisioncheque_data",
      "Vget_emisioncheque_load",
    ]),
    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },
  methods: {
    ...mapActions("emisioncheque", [
      "Vact_emisioncheque_data",
      "Vact_emisioncheque_del",
    ]),
    toCurrency(val) {
      return currency(val);
    },
    meliminar(item) {
      this.objeto = Object.assign({}, item);
      this.crud.delete = true;
    },

    eliminarcancelar() {
      this.index = "";
      this.objeto = "";
      this.crud.delete = false;
    },
    mupdate(val) {
      this.crud.datos = JSON.parse(JSON.stringify(val));
      this.crud.add = true;
    },

    async meliminarguardar() {
      this.overlay = true;
      const id = this.objeto.idcheque_emitido;
      const a = await this.Vact_emisioncheque_del(id);
      if (a.estado === true) {
        this.$notify(a.info);
        this.crud.delete = false;
        this.eliminarcancelar();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.overlay = false;
    },
  },
};
</script>