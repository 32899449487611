<template>
  <div>
    <v-form ref="form" class="mt-2">
      <v-row dense class="mx-2">
        <v-col cols="12" sm="2"> 
          <c-text-date
            hide-details
            dense
            :outlined="false"
            label="Fecha Inicio"
            v-model="data.fechaInicio"
          />
        </v-col>
        <v-col cols="12" sm="2">
          <c-text-date
            hide-details
            dense
            :outlined="false"
            label="Fecha Fin"
            v-model="data.fechaFin"
          />
        </v-col>
        <v-col cols="12" sm="2">
          <v-autocomplete
            :items="Vget_tambo_data"
            :loading="Vget_tambo_load"
            item-text="nombtambo"
            item-value="idtambo"
            autocomplete="off"
            hide-details
            :rules="[(v) => !!v.length > 0 || 'Seleccione una sucursal']"
            label="Sucursal"
            dense
            multiple
            v-model="data.sucursal"
            @change="obtenerPuntoVenta()"
          ></v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          sm="2"
          v-if="data.sucursal.length < 2 && mode === 'VENTA'"
        >
          <v-autocomplete
            :items="Vget_puntoventa_byIdSucursal"
            :loading="Vget_puntoventa_load"
            item-text="descpuntoventa"
            item-value="idpuntoventa"
            autocomplete="off"
            hide-details
            :rules="[(v) => !!v || 'Seleccione un Punto de Venta']"
            dense
            label="Punto de Venta"
            v-model="data.puntoventa"
          ></v-autocomplete>
        </v-col>
        <v-spacer></v-spacer>
        <v-btn
          outlined
          text
          color="primary"
          class="mr-2"
          @click="descargarDatos()"
          >Filtrar</v-btn
        >
        <v-btn
          outlined
          text
          color="green"
          class="mr-2"
          @click="descargarDatos('BSS')"
          >XLS BSS</v-btn
        >
        <v-btn
          outlined
          text
          color="red"
          class="mr-2"
          v-if="response.datos.length > 0"
          @click="descargarARCHIVO('PDF')"
          >PDF</v-btn
        >
        <v-btn
          outlined
          text
          color="green"
          v-if="response.datos.length > 0"
          @click="descargarARCHIVO('XLS')"
          >EXCEL</v-btn
        >
      </v-row>
    </v-form>
    <c-overlay
      :value="response.isLoading"
      v-if="response.isLoading"
    ></c-overlay>
    <v-tabs color="deep-purple accent-4" right class="mt-2">
      <v-tab v-for="(item, i) of response.datos" :key="i">{{
        item.moneda
      }}</v-tab>
      <v-tab-item v-for="(item, i) of response.datos" :key="i">
        <div v-for="(sub, b) of item.detalle" :key="b">
          <v-toolbar flat>
            <v-toolbar-title>{{ sub.fechaText }}</v-toolbar-title>
          </v-toolbar>
          <v-data-table
            :ref="`refDataTable${b}`"
            dense
            :loading="response.isLoading"
            :headers="headers"
            :items="sub.detalle"
            :search="search"
          >
            <template v-slot:item.iva10="props">
              <p class="text-end ma-2">
                {{ currencyFormat(props.item.iva10) }}
              </p>
            </template>
            <template v-slot:item.gra10="props">
              <p class="text-end ma-2">
                {{ currencyFormat(props.item.gra10) }}
              </p>
            </template>
            <template v-slot:item.gra5="props">
              <p class="text-end ma-2">{{ currencyFormat(props.item.gra5) }}</p>
            </template>
            <template v-slot:item.iva5="props">
              <p class="text-end ma-2">{{ currencyFormat(props.item.iva5) }}</p>
            </template>
            <template v-slot:item.exenta="props">
              <p class="text-end ma-2">
                {{ currencyFormat(props.item.exenta) }}
              </p>
            </template>
            <template v-slot:item.total="props" class="text-end">
              <p class="text-end ma-2">
                {{ currencyFormat(props.item.total) }}
              </p>
            </template>
            <template slot="body.append">
              <tr class="black--text">
                <th class="subtitle-1">TOTAL</th>
                <th class="subtitle-1"></th>
                <th class="subtitle-1"></th>
                <th class="subtitle-1"></th>
                <th class="subtitle-1 text-end">
                  {{ currencyFormat(sub.totales.gra10) }}
                </th>
                <th class="subtitle-1 text-end">
                  {{ currencyFormat(sub.totales.iva10) }}
                </th>
                <th class="subtitle-1 text-end">
                  {{ currencyFormat(sub.totales.gra5) }}
                </th>
                <th class="subtitle-1 text-end">
                  {{ currencyFormat(sub.totales.iva5) }}
                </th>
                <th class="subtitle-1 text-end">
                  {{ currencyFormat(sub.totales.exenta) }}
                </th>
                <th class="subtitle-1 text-end">
                  {{ currencyFormat(sub.totales.total) }}
                </th>
              </tr>
            </template>
          </v-data-table>
        </div>
      </v-tab-item>
    </v-tabs>
  </div>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
import {
  current_first_date,
  current_last_date,
  dmy,
} from "@/services/util/date.service";
import { mensaje } from "@/services/notify.service";
import { db } from "@/services/db.service";
import {
  exportAvanzado,
  exportXLSContable,
  exportXLS,
} from "./LibroCompraVentaPDF";
export default {
  props: {
    mode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      search: "",
      rawResponse: [],
      response: {
        isLoading: false,
        datos: [],
        excel: [],
      },
      data: {
        fechaInicio: current_first_date(),
        fechaFin: current_last_date(),
        sucursal: [],
        puntoventa: "",
      },
      headers: [
        { text: "Dia", value: "dia" },
        { text: "Numero", value: "nro" },
        { text: "Razon Social", value: "razon" },
        { text: "Ruc", value: "ruc" },
        { text: "Gravadas 10%", value: "gra10" },
        { text: "Iva 10%", value: "iva10" },
        { text: "Gravadas 5%", value: "gra5" },
        { text: "Iva 5%", value: "iva5" },
        { text: "Exentas", value: "exenta" },
        { text: "Total", value: "total" },
      ],
    };
  },
  computed: {
    ...mapGetters("tambo", ["Vget_tambo_data", "Vget_tambo_load"]),
    ...mapGetters("puntoventa", [
      "Vget_puntoventa_byIdSucursal",
      "Vget_puntoventa_load",
    ]),
  },
  methods: {
    ...mapActions("tambo", ["Vact_tambo_data"]),
    ...mapActions("puntoventa", ["Vact_puntoventa_byIdSucursal"]),

    obtenerPuntoVenta() {
      if (this.mode === "COMPRA") return null;
      this.Vact_puntoventa_byIdSucursal(null);
      if (this.data.sucursal.length === 1) {
        this.Vact_puntoventa_byIdSucursal(this.data.sucursal[0]);
      }
    },
    currencyFormat(value) {
      return new Intl.NumberFormat({ maximumSignificantDigits: 3 }).format(
        value.toFixed(2)
      );
    },
    obtenerUrl() {
      let url = "";
      if (this.data.sucursal.length === 1) {
        url = `punto=${this.data.puntoventa}&sucursal=${this.data.sucursal[0]}&fecha_inicio=${this.data.fechaInicio}&fecha_fin=${this.data.fechaFin}`;
        return url;
      }
      url = `&fecha_inicio=${this.data.fechaInicio}&fecha_fin=${this.data.fechaFin}`;
      this.data.sucursal.forEach((suc, i) => {
        if (i === 0) return (url = url + `&sucursal=${suc}`);
        url = url + "-" + suc;
      });
      return url;
    },
    obtenerUrlContable() {
      let url = "";
      if (this.data.sucursal.length === 1) {
        url = `punto=${this.data.puntoventa}&sucursal=${this.data.sucursal[0]}&inicio=${this.data.fechaInicio}&fin=${this.data.fechaFin}`;
        return url;
      }
      url = `&inicio=${this.data.fechaInicio}&fin=${this.data.fechaFin}`;
      this.data.sucursal.forEach((suc, i) => {
        if (i === 0) return (url = url + `&sucursal=${suc}`);
        url = url + "-" + suc;
      });
      return url;
    },
    async descargarDatos(bss) {
      if (!this.$refs.form.validate())
        return this.$notify(mensaje.error("Complete los campos"));
      this.response.isLoading = true;
      try {
        const url = this.mode === "COMPRA" ? "apilibrocompra" : "apilibroventa";
        const response = await db.get(`/${url}/?${this.obtenerUrl()}`);
        if (bss) {
          const excel = await db.get(
            `/apiventacontable/?${this.obtenerUrlContable()}`
          );
          this.response.excel = JSON.parse(JSON.stringify(excel));
          this.descargarARCHIVO(bss);
          return  this.response.isLoading = false;
        }
        this.rawResponse = JSON.parse(JSON.stringify(response));
        let datosFormateados = [];
        response.map((moneda, i) => {
          let fechaTemp = [];
          moneda.detalle.forEach((det) => {
            let isRepeat = false;
            if (fechaTemp.length === 0) return fechaTemp.push(det.fecha);
            fechaTemp.forEach((x) => {
              if (x === det.fecha) {
                isRepeat = true;
              }
            });
            if (!isRepeat) fechaTemp.push(det.fecha);
          });
          datosFormateados.push({
            moneda: moneda.moneda,
            detalle: fechaTemp.map((x) => {
              return {
                fecha: x,
                fechaText: this.anhomesToText(x),
                detalle: [],
              };
            }),
          });
          let total = {
            exenta: 0,
            gra10: 0,
            gra5: 0,
            iva5: 0,
            iva10: 0,
            total: 0,
          };
          moneda.detalle.forEach((x) => {
            total.exenta = total.exenta + x.exenta;
            total.gra10 = total.gra10 + x.gra10;
            total.gra5 = total.gra5 + x.gra5;
            total.iva5 = total.iva5 + x.iva5;
            total.iva10 = total.iva10 + x.iva10;
            total.total = total.total + x.total;
            datosFormateados[i].detalle.forEach((y) => {
              if (y.fecha === x.fecha) {
                y.detalle.push(x);
                y.totales = JSON.parse(JSON.stringify(total));
              }
            });
          });
        });
        this.response.datos = JSON.parse(JSON.stringify(datosFormateados));
        this.$refs.form.resetValidation();
      } catch (error) {
        console.log(error);
      }
      this.response.isLoading = false;
    },
    anhomesToText(anhomes) {
      const mesD = [
        "ENERO",
        "FEBRERO",
        "MARZO",
        "ABRIL",
        "MAYO",
        "JUNIO",
        "JULIO",
        "AGOSTO",
        "SEPTIEMBRE",
        "OCTUBRE",
        "NOVIEMBRE",
        "DICIEMBRE",
      ];
      const [anho, mes] = anhomes.split("-");
      return `${mesD[Number(mes) - 1]} ${anho}`;
    },
    descargarARCHIVO(MODE) {
      const datos = JSON.parse(JSON.stringify(this.response));
      const title =
        this.mode === "COMPRA" ? "Libro de Compras" : "Libro de Ventas";
      switch (MODE) {
        case "PDF":
          exportAvanzado(datos, this.headers, this.data, title);
          break;
        case "XLS":
          exportXLS(this.rawResponse, title);
          break;
        case "BSS":
          this.response.excel.map((x) => {
            x.FECHA = dmy(x.FECHA).replace(/-/g, "/");
          });
          exportXLSContable(this.response.excel, 'Import to BSS '+title);
          break;
      }
    },
  },
  mounted() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.Vact_tambo_data();
  },
};
</script>

<style>
</style>