import { del, get, post, put } from "@/services/api/api.service";
import { url, LOADING, FETCH_HISTO, FETCH_NUEVO } from "./constants";
export default {
  setParto: async ({ commit, dispatch }, data) => {
    commit(LOADING, true);
    const response = await post(url.parto, data);
    dispatch("snackbar/setNotification", response, { root: true });
    commit(LOADING, false);
    return response;
  },

  async setPartoUpdate({ commit, dispatch }, { data, id }) {
    commit(LOADING, true);
    const response = await put(`${url.parto}${id}/`, data);
    dispatch("snackbar/setNotification", response, { root: true });
    commit(LOADING, false);
    return response;
  },

  deleteParto: async ({ commit, dispatch }, id) => {
    commit(LOADING, true);
    const response = await del(`${url.parto}${id}/`);
    dispatch("snackbar/setNotification", response, { root: true });
    commit(LOADING, false);
    return response;
  },

  fetchPartoNuevo: async ({ commit, dispatch }) => {
    commit(LOADING, true);
    try {
      const response = await get(url.parto+ "?band=nuevo");
      commit(FETCH_NUEVO, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(LOADING, false);
      throw e;
    }
    commit(LOADING, false);
  },
  
  fetchPartoHisto: async ({ commit, dispatch }) => {
    commit(LOADING, true);
    try {
      const response = await get(url.parto+ "?band=historico");
      commit(FETCH_HISTO, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(LOADING, false);
      throw e;
    }
    commit(LOADING, false);
  }
};
