<template>
  <c-dialog-confirmation
    :value="value"
    @cancel="close()"
    @done="save()"
    :loading="isLoading"
    >Desea eliminar este <strong>Canje</strong
    >?</c-dialog-confirmation
  >
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    value: Boolean,
    props: Object,
  },
  computed:{
    ...mapGetters('canjesimple',["isLoading"])
  },
    watch: {
    $route() {
      return this.close();
    },
  },
  methods: {
    ...mapActions("canjesimple", ["setCanjeSimpleDelete","fetchCanjeSimple"]),

    async save() {
      const id = this.props.idcanje_simple;
      const response = await this.setCanjeSimpleDelete(id);
      if (!response.success) return null;
      this.close();
    },
    close() {
      this.$emit('datos',null);
      this.$emit("input", false);
      this.fetchCanjeSimple();
    },
  }
};
</script>

<style>
</style>