
export default {

  Vget_agenda_data: (state) => {
    return state.agenda_data
  },
  Vget_agenda_load: (state) => {
    return state.agenda_load
  },
  Vget_agenda_save_load: (state) => {
    return state.agenda_save_load
  },
}
