
export default {
  getParcela: (state) => {
    return state.parcela
  },
  getParcelaLoteCampana: (state) => {
    return state.parcelaLoteCampana
  },
  isParcelaLoading: (state) => {
    return state.isLoading
  }
}
