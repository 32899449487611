import { get, post, put } from "@/services/api/api.service";
import { SET_LOADING, SET_ASISTENCIA, SET_LISTA, url, url_lista } from './constants'
export default {
    async fetchAsistencia({ commit, dispatch }, { desde, hasta, sector }) {
        commit(SET_LOADING, true);
        try {
            let ruta = `${url}?inicio=${desde}&fin=${hasta}`
            if (sector) ruta = `${ruta}&sector=${sector}`
            const response = await get(ruta);
            commit(SET_ASISTENCIA, response);
        } catch (e) {
            dispatch('snackbar/setNotification', e, { root: true });
            commit(SET_LOADING, false);
            throw e
        }
        commit(SET_LOADING, false);
    },
    async fetchListaAsistencia({ commit, dispatch }, { id, desde, hasta }) {
        commit(SET_LOADING, true);
        try {
            const response = await get(`${url_lista}${id}/?inicio=${desde}&fin=${hasta}`);
            commit(SET_LISTA, response);
        } catch (e) {
            dispatch('snackbar/setNotification', e, { root: true });
            commit(SET_LOADING, false);
            throw e
        }
        commit(SET_LOADING, false);
    },
    
    async setAsistencia({ commit, dispatch }, data) {
        commit(SET_LOADING, true);
        const response = await post(url, data);
        dispatch('snackbar/setNotification', response, { root: true })
        commit(SET_LOADING, false);
        return response
    },

    async setAsistenciaUpdate({ commit, dispatch }, { data, id }) {
        commit(SET_LOADING, true);
        const response = await post(`${url_lista}${id}/`, data);
        dispatch('snackbar/setNotification', response, { root: true })
        commit(SET_LOADING, false);
        return response
    },
    async setAsistenciaDelete({ commit, dispatch }, {id, data}) {
        commit(SET_LOADING, true);
        const response = await put(`${url_lista}${id}/`,data);
        dispatch('snackbar/setNotification', response, { root: true })
        commit(SET_LOADING, false)
        return response;
    },
}
