<template>
  <v-dialog persistent max-width="800" :value="value">
    <v-card>
      <v-toolbar flat dense>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-form ref="header">
          <v-row dense>
            <v-col cols="12" sm="6">
              <autocomplete-cuentabanco
                label="Cuenta Bancaria de Origen"
                v-model="data.idcuentabanco"
                @change="changeMoneda()"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <c-text-field
                readonly
                label="Moneda"
                v-model="data.idmoneda.descmoneda"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="5">
              <autocomplete-funcionario
                ref="aguinaldoInput1"
                label="Funcionario"
                v-model="data.idcontacto"
                @keyup.native.enter="
                  next(1, data.idcontacto.idcontacto.idcontacto)
                "
              />
            </v-col>
            <v-col cols="10" sm="3">
              <c-text-date
                ref="aguinaldoInput2"
                label="Pagar Aguinaldo hasta"
                v-model="data.periodo_fin"
                @keyup.native.enter="next(2, data.periodo_fin)"
              ></c-text-date>
            </v-col>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              ref="aguinaldoInput3"
              fab
              readonly
              outlined
              small
              class="mt-4 ml-2"
              @click="obtenerDatos(data.idcontacto)"
              ><v-icon>get_app</v-icon></v-btn
            >
          </v-row>
        </v-form>
        <v-form ref="form">
          <v-row dense>
            <v-col cols="12" sm="4">
              <c-text-date
                label="Fecha de Inicio"
                v-model="data.periodo_inicio"
                readonly
              ></c-text-date>
            </v-col>
            <v-col cols="12" sm="4">
              <c-text-currency
                readonly
                label="Salario Promedio Mensual"
                v-model="data.promedio_mensual"
              ></c-text-currency>
            </v-col>
            <v-col cols="12" sm="4">
              <c-text-currency
                readonly
                label="Salario Promedio Diario"
                v-model="data.promedio_diario"
              ></c-text-currency>
            </v-col>
          </v-row>

          <v-row dense class="mt-2">
            <v-col cols="12" sm="5" class="mt-2">
              <span>Antigüedad: </span>
              <span class="font-weight-bold">
                {{ getFuncionarioData.texto }}
              </span></v-col
            >
            <v-spacer></v-spacer>
            <v-col cols="12" sm="3" class="mt-2">
              <span class="font-weight-black">Aguinaldo a cobrar:</span>
            </v-col>

            <v-col cols="12" sm="4">
              <c-text-currency
                v-model="data.monto"
                dense
                readonly
                :rules="[(v) => !!v || 'Requerido']"
                outlined
              ></c-text-currency>
            </v-col>
            <v-col cols="12">
              <v-textarea
                rows="2"
                label="Comentario"
                v-model="data.comentario"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <c-btn-crud-close @click="cancel()"></c-btn-crud-close>
        <v-spacer></v-spacer>
        <c-btn-crud-done ref="aguinaldo2" @click="save()"></c-btn-crud-done>
      </v-card-actions>
      <c-overlay :value="isAguinaldoLoading || isConfigRrhhLoading"></c-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { current_date } from "@/services/util/date.service";
import autocompleteFuncionario from "@/views/RRHH/Funcionarios/Information/autocomplete";
import autocompleteCuentabanco from "@/views/Apertura/Cuentabanco/autocomplete";
import { generarRecibo } from "./../Salario/recibo";
const configRecibo = {
  title: "LIQUIDACION DE AGUINALDO",
  articulo: "(Conforme al Art. 243 del Código Laboral)",
};
export default {
  components: {
    "autocomplete-funcionario": autocompleteFuncionario,
    "autocomplete-cuentabanco": autocompleteCuentabanco,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
    },
  },
  watch: {
    $route(to, from) {
      if (from.path === "/rrhh/pago/aguinaldo") return this.cancel();
    },
    getConfigRrhh(val) {
      this.data.idcuentabanco = val.idcuentabanco;
      this.changeMoneda();
    },
  },
  mounted() {
    if (this.editable) {
      this.data = JSON.parse(JSON.stringify(this.props));
      this.data.promedio_mensual = this.props.monto;
      this.data.promedio_diario = this.props.monto / 30;
    } else {
      this.fetchConfigRrhh();
    }
    setTimeout(() => this.next(0, "-"), 20);
    if (this.syncInput != null) this.data.descripcion = this.syncInput;
  },
  computed: {
    ...mapGetters("configrrhh", ["getConfigRrhh","isConfigRrhhLoading"]),
    ...mapGetters("aguinaldo", ["isAguinaldoLoading", "getFuncionarioData"]),
    title() {
      return this.editable ? "Modificar Aguinaldo" : "Registrar Aguinaldo";
    },
  },
  methods: {
    ...mapActions("configrrhh", ["fetchConfigRrhh"]),
    ...mapActions("aguinaldo", [
      "setAguinaldo",
      "fetchAguinaldo",
      "fetchFuncionarioData",
      "setAguinaldoUpdate",
    ]),
    changeMoneda() {
      this.data.idmoneda = JSON.parse(
        JSON.stringify(this.data.idcuentabanco.idmoneda)
      );
    },
    cancel() {
      this.$emit("input", false);
    },
    next(id, data) {
      if (data != "")
        try {
          this.$refs[`aguinaldoInput${id + 1}`].focus();
        } catch (error) {
          this.$refs[`aguinaldoInput${id + 1}`].$el.focus();
        }
    },
    async obtenerDatos(funcionario) {
      if (!this.$refs.header.validate()) return null;
      const id = funcionario.idcontacto.idcontacto;
      const fecha = this.data.periodo_fin;
      const idmoneda = this.data.idmoneda.idmoneda
      await this.fetchFuncionarioData({ id, fecha, idmoneda });
      this.data.periodo_inicio = this.getFuncionarioData.periodo_inicio;
      this.data.promedio_mensual = this.getFuncionarioData.promedio_mensual;
      this.data.monto = this.getFuncionarioData.promedio_mensual;
      this.data.promedio_diario = this.getFuncionarioData.promedio_mensual / 30;
    },
    formatAndGenerateRecibo() {
      const data = {
        ...this.data,
        detalle: [
          {
            idconcepto_salario: {
              descripcion: "Aguinaldo",
              positivo_negativo: true,
            },
            monto: this.data.monto,
          },
        ],
      };
      generarRecibo(data, configRecibo, "Aguinaldo");
    },
    async save() {
      if (!this.$refs.form.validate()) return null;
      const response = this.editable
        ? await this.setAguinaldoUpdate({
            data: this.data,
            id: this.data.idaguinaldo,
          })
        : await this.setAguinaldo(this.data);
      if (response.success) {
        this.fetchAguinaldo();
        this.formatAndGenerateRecibo();
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();
        this.next(0, "-");
        if (this.editable) this.cancel();
      }
    },
  },
  data: () => ({
    data: {
      idaguinaldo: 0,
      idcontacto: {
        idcontacto: "",
      },
      idcuentabanco: {
        idcuentabanco: "",
      },
      idmoneda: {
        idmoneda: "",
        descmoneda: "",
      },
      fecha: current_date(),
      periodo_inicio: current_date(),
      periodo_fin: current_date(),
      monto: "",
      promedio_mensual: "",
      promedio_diario: "",
      comentario: "",
    },
    default: {
      idaguinaldo: 0,
      idcontacto: {
        idcontacto: "",
      },
      idcuentabanco: {
        idcuentabanco: "",
      },
      idmoneda: {
        idmoneda: "",
        descmoneda: "",
      },
      fecha: current_date(),
      periodo_inicio: current_date(),
      periodo_fin: current_date(),
      monto: "",
      promedio_mensual: "",
      promedio_diario: "",
      comentario: "",
    },
  }),
};
</script>

<style></style>
