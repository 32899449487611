import jsPDF from 'jspdf'
import 'jspdf-autotable'
//import store from '@/store'
import { current_date, dmy,  current_time } from "@/services/util/date.service";
const currencyFormat = (value) => {
    return new Intl.NumberFormat({ maximumSignificantDigits: 1 }).format(
        value.toFixed(2)
    );
}

export const exportPDF = (datos, headers, title) => {

    var doc = new jsPDF();
    doc.setFontSize(12);
    doc.text(title, doc.internal.pageSize.width /2, 20, { align: 'center' });
    doc.setFontSize(9);
    doc.text('Fecha: ', 15, 25);
    doc.text('Hora: ', 15, 30);
    doc.text(current_date(), 27, 25);
    doc.text(current_time(), 27, 30);
    
    //doc.setFontType("bold");
    let startY = 45;
    datos.forEach((det, i) => {
        if (i > 0) startY = doc.previousAutoTable.finalY + 30;
        doc.setFontSize(9);
        doc.text(det.moneda, doc.internal.pageSize.width / 2, startY - 8, { align: 'center' })
        doc.setLineWidth(1);
        doc.line(13, startY - 7, doc.internal.pageSize.width - 12, startY - 7);

        if (i > 0) startY = doc.previousAutoTable.finalY + 15;
        doc.setFontSize(8);
        det.detalle.push({name: 'Total', cant: 0, monto: det.detalle.reduce((acc,cur)=>{return acc = acc + cur.monto},0)});
        doc.autoTable({
            theme: 'grid',
            head: [headers.map(x => {
                return x.text
            })],
            body: det.detalle.map(x => {
                return [
                    x.name,
                    currencyFormat(x.cant),
                    currencyFormat(x.monto)
                ]
            }),
            headStyles: {
                textColor: [0, 0, 0],
                fillColor: [255, 255, 255],
                fontSize: 7,
            },
            bodyStyles: {
                fontSize: 7
            },
            columnStyles: {

                1: { halign: 'right' },
                2: { halign: 'right', fontStyle: 'bold' },
            },
            startY: startY,
            showHead: 'firstPage',
            styles: { overflow: 'hidden' },
            didParseCell: (data) => {
                if (data.row.index == det.detalle.length - 1 && data.column.index == 0) {
                    data.row.cells[0].colSpan = 2;
                    Object.entries(data.row.cells).forEach((v) => {
                        v[1].styles.fontStyle = 'bold';
                    })
                }
            },
        })


    })
    doc.save(title +' '+dmy(current_date()) + '.pdf');
}
