export default {
  Vget_alimentacion_data: (state) => {
    return state.alimentacion_data;
  },
  Vget_alimentacion_load: (state) => {
    return state.alimentacion_load;
  },
  Vget_alimentacion_save_load: (state) => {
    return state.alimentacion_save_load;
  },
};
