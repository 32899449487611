<template>
   <v-btn @click="$emit('click')" text fab x-small :disabled="disabled" :to="to">
    <v-icon >edit</v-icon>
  </v-btn>
</template>

<script>
export default {
  props:{
    disabled: {
      type: Boolean,
      default: false
    },
    to: String
  }
}
</script>

<style>

</style>