import {SET_LOADING, SET_DESPIDO,SET_MOTIVO, SET_FUNCIONARIO_DATA} from './constants'
export default {
  [SET_DESPIDO](state, request) {
    state.despido = request;
  },
  [SET_MOTIVO](state, request) {
    state.motivo = request;
  },
  [SET_FUNCIONARIO_DATA](state, request) {
    state.funcionariodata = request;
  },
  [SET_LOADING](state, request) {
    state.isLoading = request;
  },
}