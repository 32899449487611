<template>
  <div>
    <v-dialog :value="value" max-width="700px" persistent>
      <v-card>
        <v-card-title>
          <span class="headline">Detalles</span>
        </v-card-title>
        <v-card-text>
          <v-row row dense>
            <v-col cols="12">
              <v-text-field
                dense
                type="text"
                v-model="data.descunidadmedida"
                label="Descripcion"
                outlined
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-text-field
                dense
                type="text"
                v-model="data.mediunidadmedida"
                label="Medida"
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                dense
                type="text"
                v-model="data.idmagnitud.descripcion"
                label="Magnitud"
                outlined
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cancelar">Salir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props:{
    props: {
      type: Object,
      default: null
    },
    value:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      data: {
        idmagnitud: {
          descripcion: ""
        }
      },
      default: {
        idmagnitud: {
          descripcion: ""
        }
      }
    };
  },


  created() {
    this.data = JSON.parse(JSON.stringify(this.props))
  },
  watch: {
    $route(to, from) {
      if (from.path === "/unidadmedida") return this.cancelar();
    }
  },
  methods: {
    cancelar() {
      this.data =JSON.parse(JSON.stringify( this.default));
      this.$emit('input',false);
      this.$emit('datos',null);
    },
  }
};
</script>
