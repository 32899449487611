<template>
  <div>
    <div v-if="crudADD">
      <Create v-model="crudADD" />
    </div>
    <div v-if="show">
      <Show v-model="show" :mostrar="datos" @mostrar="datos = $event" />
    </div>

    <v-alert type="error" v-if="permission_alert">
      Error!!! Este usuario no tiene permisos en esta ventana
    </v-alert>
    <v-toolbar flat dense>
      <v-toolbar-title>{{ titulo }}</v-toolbar-title>
      <widgets-Favorite :ruta="$route.path" :formulario="titulo" />
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-spacer></v-spacer>

      <c-text-table-search
        v-if="permission.can_view"
        v-model="search"
      ></c-text-table-search>
      <v-toolbar-items>
        <c-btn-table-add
          @click="crudADD = true"
          v-if="permission.can_add"
        ></c-btn-table-add>
      </v-toolbar-items>
    </v-toolbar>
    <v-data-table
      :loading="Vget_transladocajabanco_load"
      :headers="headers"
      :items="Vget_transladocajabanco_data"
      :search="search"
      v-if="permission.can_view"
    >
      <template v-slot:item.accion="props">
        <v-icon class="mr-2" @click="rowselect(props.item)">visibility</v-icon>
      </template>
      <template v-slot:no-data>
        <v-alert :value="true" color="info" icon="info" outlined
          >No existe ningun registro</v-alert
        >
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { TRANSFERENCIA_CAJA_BANCO } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";

import Create from "./Create";
import Show from "./Show";
export default {
  components: {
    Create,
    Show,
  },
  data() {
    return {
      crudADD: false,
      show: false,
      overlay: false,
      search: "",
      datos: null,
      headers: [
        { text: "Fecha", value: "fechtransladocaja" },
        { text: "Caja Origen", value: "idcajaorigen.descaja" },
        { text: "Observacion", align: "left", value: "motivotranslado" },
        { text: "Accion", value: "accion", sortable: false, align: "right" },
      ],
      titulo: TRANSFERENCIA_CAJA_BANCO,
      objeto: { descaja: "" },
      index: "",
      dialogoeliminar: false,
      load: false,
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
    };
  },

  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.Vact_transladocajabanco_data();
    const permission = getPermission(TRANSFERENCIA_CAJA_BANCO).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  computed: {
    ...mapGetters("transladocajabanco", [
      "Vget_transladocajabanco_data",
      "Vget_transladocajabanco_load",
    ]),
    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },

  methods: {
    ...mapActions("transladocajabanco", [
      "Vact_transladocajabanco_data",
      "Vact_transladocajabanco_del",
    ]),

    meliminar(item) {
      this.objeto = Object.assign({}, item);
      this.dialogoeliminar = true;
    },
    rowselect(val) {
      this.datos = val;
      this.show = true;
    },
    eliminarcancelar() {
      this.objeto = { descaja: "" };
      this.dialogoeliminar = false;
    },
  },
};
</script>