<template>
  <div>
    <h3 align="center">Egreso</h3>
    <v-divider></v-divider>
    <Navegacion />
    <v-row dense>
      <v-col cols="12">
        <h4>2 - Seleccione el concepto</h4>
      </v-col>
      <span>
        <strong>Obs.</strong>En caso de que el concepto no exista haga click en la opcion de crear y
        abra el formulario para crear un nuevo concepto
      </span>
      <v-col cols="12">
        <h4>3 - Cantidad por hectarea</h4>
      </v-col>
      <v-col cols="12">
        <h4>4 - Precio por hectarea</h4>
      </v-col>
      <v-col cols="12">
        <h4>5 - Cantidad de hectareas</h4>
      </v-col>
      <v-col cols="12">
        <h4>
          5 - Click en el boton
          <v-icon color="primary">add</v-icon>para agregar en el detalle
        </h4>
      </v-col>
      <v-col>
        <li>
          Click en
          <v-icon color="primary">done</v-icon>para guardar
        </li>
        <li>
          Click en
          <v-icon color="red">clear</v-icon>para cancelar
        </li>
      </v-col>
    </v-row>
    <v-progress-linear rounded height="3" value="100"></v-progress-linear>
    <component :is="show"></component>
  </div>
</template>

<script>
export default {
  components: {
    Navegacion: () =>
      import(
        /* webpackPrefetch: true */ "@/views/Help/Agro/Detail/Operacion/Navegacion.vue"
      )
  },
  data() {
    return {
      switch1: false,
      masNav: false,
      masOpc: false,
      masTip: false,
      masProd: false,
      show: ""
    };
  },
  computed: {
    tipo() {
      return this.switch1 ? "Siembra" : "Replantacion";
    }
  }
};
</script>

<style>
</style>