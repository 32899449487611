<template>
  <div>
    <v-dialog :value="value" max-width="700px" persistent>
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Detalles</v-toolbar-title>
        </v-toolbar>
        <v-container>
          <v-row row dense>
            <v-col cols="12">
              <vue-text-field v-model="data.desdeposito" label="Descripcion" :readonly="true"></vue-text-field>
            </v-col>
            <v-col cols="12">
              <vue-text-field
                v-model="data.idtipo_item.desctipo_item"
                label="Tipo Item"
                :readonly="true"
              ></vue-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-btn color="red" text @click="cancelar">
            <v-icon>clear</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    value: Boolean,
    datos: {
      type: [Object, Array],
      default: null,
    },
  },
  data() {
    return {
      data: {
        idtipo_item: {
          desctipo_item: "",
        },
      },
      default: {
        idtipo_item: {
          desctipo_item: "",
        },
      },
    };
  },
  created() {
    this.show(this.datos);
  },
  watch: {
    $route(to, from) {
      if (from.path === "/deposito") return this.cancelar();
    },
  },
  methods: {
    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$emit("input", false);
      this.$emit("datos", null);
    },

    show(val) {
      this.data = JSON.parse(JSON.stringify(val));
    },
  },
};
</script>
