export default {
    tipo: [],
    loadingTipo: false,
    cuenta: [],
    loadingCuenta: false,
    grupo: [],
    loadingGrupo: false,
    categoria: [],
    loadingCategoria: false,
    movimiento: [],
    transferencia: [],
    loadingMovimiento: false,
    saldo: [],
    loadingSaldo: false,
    movimientoCuenta: [],
    loadingMovimientoCuenta: false,
    gastoMensual: [],
    loadingGastoMensual: false,
    tiempo: [],
    loadingTiempo: false,
    presupuestoDisponible: {},
    loadingPresupuestoDisponible: false,
}