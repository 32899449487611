
export default {
  isLoading:(state)=>state.loading,
  getLote:(state)=>state.data,
  isLoadingLotedep:(state)=>state.loadingdep,
  getLotedep:(state)=>state.datalotedep,
  isLoadingLotedis:(state)=>state.loadingdis,
  getLotedis:(state)=>state.datalotedis,
  isLoadingLoteloc:(state)=>state.loadingloc,
  getLoteloc:(state)=>state.dataloteloc,
}
