export default {
  cuentabanco_data(state, request) {
    state.cuentabanco_data = request;
  },
  cuentabanco_load(state, request) {
    state.cuentabanco_load = request;
  },
  cuentabanco_save_load(state, request) {
    state.cuentabanco_load = request;
  }
}