<template>
  <v-dialog persistent max-width="700" :value="value">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-form ref="form" @submit.prevent="">
          <v-row dense>
            <v-col cols="12">
              <c-text-field
                label="Descripcion"
                autocomplete="off"
                :rules="rules"
                ref="fabricante1"
                v-model="data.descripcion"
                placeholder="Ingrese una descripcion"
              ></c-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <c-btn-crud-close @click="cancel()"></c-btn-crud-close>
        <v-spacer></v-spacer>
        <c-btn-crud-done ref="fabricante2" @click="save()"></c-btn-crud-done>
      </v-card-actions>
      <c-overlay :value="isLoading"></c-overlay>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
    },
    "sync-input": {
      type: [String, Number],
      default: null,
    },
  },
  mounted() {
    if (this.editable) this.data = JSON.parse(JSON.stringify(this.props));
    setTimeout(() => this.next(0, "-"), 20);
    if (this.syncInput != null) this.data.descripcion = this.syncInput;
    this.sync = this.syncInput;
  },
  computed: {
    ...mapGetters("fabricante", ["isLoading"]),
    title() {
      return this.editable ? "Modificar Fabricante" : "Registrar Fabricante";
    },
  },
  methods: {
    ...mapActions("fabricante", ["setFabricante", "setFabricanteUpdate", "fetchFabricante"]),
    next(id, data) {
      if (data != "")
        try {
          this.$refs[`fabricante${id + 1}`].focus();
        } catch (error) {
          this.$refs[`fabricante${id + 1}`].$el.focus();
        }
    },
    cancel() {
      this.$emit("input", false);
    },
    async save() {
      if (!this.$refs.form.validate()) return null;
      const response = this.editable
        ? await this.setFabricanteUpdate({
            data: this.data,
            id: this.data.idmaquinaria_fabricante,
          })
        : await this.setFabricante(this.data);
        if (response.success) {
            if (this.sync != null) {
                this.cancel();
                this.fetchFabricante();
                return this.$emit("sync-input", response.data.idmaquinaria_fabricante);
            }
            this.data = JSON.parse(JSON.stringify(this.default));
            this.$refs.form.resetValidation();
            this.fetchFabricante();
            this.next(0, '-')
            if (this.editable) this.cancel();
        }
    },
  },
  data: () => ({
    rules: [(v) => !!v || "Este campo es obligatorio"],
    data: {
      idmaquinaria_fabricante: 0,
      descripcion: "",
    },
    default: {
      idmaquinaria_fabricante: 0,
      descripcion: "",
    },
    sync: null,
  }),
};
</script>