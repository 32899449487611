export default {

  compra_data(state, request) {
    state.compra_data = request;
  },  
  compra_load(state, request) {
    state.compra_load = request;
  },
  compra_save_load(state, request) {
    state.compra_save_load = request;
  }
}