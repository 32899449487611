<template>
  <div>
    <Toolbar />
    <transition>
      <router-view></router-view>
    </transition>
  </div>
</template>
<script>
import Toolbar from "./Toolbar";
export default {
  components: {
    Toolbar,
  },
};
</script>
