import { db } from "@/services/db.service";
export default {

    async Vact_racion_data({commit}){
        commit('racion_load',true);
        try {
            const data = await db.get('/apiracion/');
            commit('racion_data',data);
            commit('racion_load',false);
            return true
        } catch (e) {
            commit('racion_load',false);
            return false
        } 
    },
    async Vact_racion_data_all({commit}){
        commit('racion_load',true);
        try {
            const data = await db.get('/apiracion/');
            commit('racion_data_all',data);
            commit('racion_load',false);
            return true
        } catch (e) {
            commit('racion_load',false);
            return false
        } 
    },
    async Vact_racion_data_anho({commit},{anhoInicio, anhoFin}){
        commit('racion_load',true);
        try {
            const data = await db.get(`/apiracion/?inicio=${anhoInicio}&fin=${anhoFin}`);
            commit('racion_data_anho',data);
            commit('racion_load',false);
            return true
        } catch (e) {
            commit('racion_load',false);
            return false
        } 
    },
    async Vact_racion_data_byLote({commit},id){
        commit('racion_load',true);
        try {
            const data = await db.get('/apiracion/?band='+id+'&active=yes');
            commit('racion_data_byLote',data);
            commit('racion_load',false);
            return true
        } catch (e) {
            commit('racion_load',false);
            return false
        } 
    },
    async Vact_racion_save({dispatch, commit},data){
        commit('racion_save_load',true);
        try {
            const resp = await db.save('/apiracion/',data);
            dispatch('Vact_racion_data');
            commit('racion_save_load',false);
            return resp;
        } catch (e) {
            commit('racion_save_load',false);
            return e
        } 
    },
    async Vact_racion_update({commit},{id, data}){
        commit('racion_save_load',true);
        try {
            const resp = await db.update('/apiracion/'+id+'/',data);
            commit('racion_save_load',false);
            return resp;
        } catch (e) {
            commit('racion_save_load',false);
            return e
        } 
    },
    async Vact_racion_cierre({commit},{id, data}){
        commit('racion_save_load',true);
        try {
            const resp = await db.update('/apiracion/'+id+'/?cierre=yes',data);
            commit('racion_save_load',false);
            return resp;
        } catch (e) {
            commit('racion_save_load',false);
            return e
        } 
    },
    async Vact_racion_del({dispatch},id){
        try {
            const data = await db.delete('/apiracion/'+id+'/');
            dispatch('Vact_racion_data');
            return data
        } catch (e) {
            return false
        } 
    }
}
