<template>
  <v-data-table
    hide-default-footer
    :headers="headers"
    :items="items"
    :items-per-page="99999"
  >
    <template v-slot:[`item.cantidad`]="{ item }">
      <div>{{ currencyFormat(item.cantidad) }}</div>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small @click="$emit('rowdelete',item)">delete</v-icon>
    </template>
  </v-data-table>
</template>
<script>
import { currency } from "@/services/util/number.service";
export default {
  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    currencyFormat(value) {
      if (value != "") return currency(value);
      return "";
    },
  },
};
</script>
