<template>
  <div>
    <v-dialog
      :value="value"
      max-width="1000px"
      persistent
      @keydown.esc="close()"
    >
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>{{
            operacion ? "Registrar Pago" : "Detalle de Pago"
          }}</v-toolbar-title>
        </v-toolbar>
        <v-divider></v-divider>
        <v-container>
          <v-form ref="form" lazy-validation>
            <v-row dense>
              <v-col cols="12" sm="6" md="6">
                <c-text-date
                  label="Fecha"
                  :readonly="readonly"
                  v-model="data.fechordenpago"
                  outlined
                  dense
                  :rules="[v=>!!v || 'Campo Obligatorio']"
                  id="input1"
                  @keyup.native.enter="focus(1, data.fechordenpago)"
                ></c-text-date>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <vue-text-field
                  id="input2"
                  :readonly="readonly"
                  label="Recibo Numero"
                  v-model="data.nro_rec_pagar"
                  @keyup.native.enter="focus(2, data.nro_rec_pagar)"
                ></vue-text-field>
              </v-col>
              <v-col cols="10" md="6" sm="6">
                <vue-autocomplete-object
                  label="Moneda"
                  id="input3"
                  :readonly="readonly"
                  action="moneda/Vact_moneda_data"
                  getter="moneda/Vget_moneda_data"
                  load="moneda/Vget_moneda_load"
                  validar="idmoneda"
                  pk="idmoneda"
                  title="descmoneda"
                  permission="Moneda"
                  v-model="data.idmoneda"
                  @change="(ordenpagodet = []), (data.ordenpagodet = [])"
                  @keyup.native.enter="focus(3, data.idmoneda)"
                />
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <vue-text-field
                  id="input4"
                  :readonly="readonly"
                  label="Cotizacion"
                  v-model="data.cotizacion"
                  :rules="false"
                  @keyup.native.enter="focus(4, '0')"
                ></vue-text-field>
              </v-col>
              <v-col cols="10" md="10" sm="10">
                <vue-autocomplete-contacto-search
                  label="Proveedor"
                  id="input5"
                  :readonly="readonly"
                  action="contacto/Vact_contacto_data_proveedor"
                  getter="contacto/Vget_contacto_data_proveedor"
                  load="contacto/Vget_contacto_load"
                  vuex="contacto/contacto_create"
                  permission="Proveedores"
                  v-model="data.idcontacto.idcontacto"
                  @change="(ordenpagodet = []), (data.ordenpagodet = [])"
                  @keyup.native.enter="focus(5, data.idcontacto.idcontacto)"
                />
              </v-col>

              <v-spacer></v-spacer>

              <v-btn
                color="primary"
                small
                fab
                outlined
                :disabled="readonly"
                :loading="Vget_pagocuenta_load"
                id="input6"
                @click="
                  obtenercuentas(
                    data.idcontacto.idcontacto.idcontacto,
                    data.idmoneda.idmoneda,
                    data.cotizacion
                  )
                "
              >
                <v-icon>get_app</v-icon>
              </v-btn>
              <v-col cols="12">
                <v-alert
                  dense
                  outlined
                  type="error"
                  v-if="cotizacionerror != null"
                  >{{ cotizacionerror }}</v-alert
                >
              </v-col>
              <v-col cols="12" v-if="ordenpagodet.length > 0">
                <v-data-table
                  :loading="Vget_pagocuenta_load"
                  :headers="headers"
                  :items="ordenpagodet"
                  dense
                  item-key="idcuentapagar"
                  :show-select="!readonly"
                  v-model="data.ordenpagodet"
                  :single-select="false"
                  :items-per-page="10000"
                  hide-default-footer
                >
                  <template v-slot:[`item.montoapagar`]="props">
                    <v-edit-dialog
                      v-if="!readonly"
                      :return-value.sync="props.item.montoapagar"
                      large
                      cancel-text="Cerrar"
                      save-text="Guardar"
                      persistent
                    >
                      <div>{{ toCurrency(props.item.montoapagar) }}</div>
                      <template v-slot:input>
                        <div class="mt-4 text-center">Editar Monto a Pagar</div>
                        <v-text-field
                          v-model="props.item.montoapagar"
                          label="Edit"
                          type="number"
                          single-line
                          counter
                          autofocus
                        ></v-text-field>
                      </template>
                    </v-edit-dialog>
                    <div v-else>{{ toCurrency(props.item.montoapagar) }}</div>
                  </template>
                  <template v-slot:[`item.action`]="props">
                    <div>
                      <v-edit-dialog
                        v-if="!readonly"
                        :return-value.sync="props.item.montoapagar"
                        large
                        cancel-text="Cerrar"
                        save-text="Guardar"
                        persistent
                      >
                        <div>
                          <v-icon>edit</v-icon>
                        </div>
                        <template v-slot:input>
                          <div class="mt-4 text-center">
                            Editar Monto a Pagar
                          </div>
                          <v-text-field
                            v-model="props.item.montoapagar"
                            label="Edit"
                            type="number"
                            single-line
                            counter
                            autofocus
                          ></v-text-field>
                        </template>
                      </v-edit-dialog>
                      <v-icon v-else :disabled="readonly">edit</v-icon>
                    </div>
                  </template>
                </v-data-table>
              </v-col>

              <v-col cols="12" v-if="data.ordenpagodet.length > 0">
                <vue-text-field
                  :rules="false"
                  :readonly="readonly"
                  label="Comentario"
                  v-model="data.desc_rec_pagar"
                ></vue-text-field>
              </v-col>
            </v-row>
          </v-form>
          <Compra-FormaPago
            v-if="data.ordenpagodet.length > 0"
            @caja="addCaja($event)"
            @cuentabanco="addCuentaBanco($event)"
            @cheque="addCheque($event)"
            @tarjetacredito="addTarjeta($event)"
            class="ma-4"
            :datos="dataPago"
            crudMode="Pago"
            :readonly="readonly"
            :montoCobrar="totalcobrar"
            :idmoneda="data.idmoneda.idmoneda"
          ></Compra-FormaPago>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn @click="close()" color="red" text>
            <v-icon>clear</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="guardar" v-if="!readonly">
            <v-icon>done</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mensaje } from "@/services/notify.service";
import { current_date } from "@/services/util/date.service";
import { focus } from "@/services/focus.service";
import { currency } from "@/services/util/number.service";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    datos: {
      type: [Object, Array],
      default: null,
    },
  },
  mounted() {
    if (this.datos != null) {
      this.operacion = false;
      return this.Vact_pagocuenta_data_id(
        this.datos.idcuentapagar_ordenpago_recibo
      );
    }
    if (this.datos != null) return this.activeEditionMode();
  },
  watch: {
    $route(to, from) {
      if (from.path === "/pagocuenta") return this.close();
    },
    Vget_pagocuenta_num_rec(val) {
      this.data.nroreciboventa = val.nrotambo;
    },
    value(val) {
      if (val) {
        setTimeout(() => document.getElementById("input1").focus(), 20);
        this.data.nroreciboventa = this.Vget_pagocuenta_num_rec.nrotambo;
      }
    },
    Vget_pagocuenta_data_id(val) {
      let det = [];
      val.ordenpagodet.forEach((x) => {
        det.push({
          ...x,
          montoapagar: x.monto,
          fechavencimiento: x.idcuentapagar.fechavencimiento,
          idcuentapagar: x.idcuentapagar.idcuentapagar,
        });
      });
      this.ordenpagodet = JSON.parse(JSON.stringify(det));
      val.ordenpagodet = JSON.parse(JSON.stringify(det));
      this.data = JSON.parse(JSON.stringify(val));
      this.dataPago = JSON.parse(JSON.stringify(val));
    },
  },
  computed: {
    ...mapGetters("pagocuenta", [
      "Vget_pagocuenta_by_id",
      "Vget_pagocuenta_data_id",
      "Vget_pagocuenta_load",
      "Vget_pagocuenta_num_rec",
      "Vget_pagocuenta_num_rec_load",
    ]),
    ...mapGetters("moneda", ["Vget_moneda_data", "Vget_moneda_load"]),
    totalcobrar() {
      let total = 0;
      this.data.ordenpagodet.forEach((x) => {
        total = Number(total) + Number(x.montoapagar);
      });
      return Number(total.toFixed(2));
    },
  },
  methods: {
    addCaja(event) {
      this.data.ordenpagocaja = JSON.parse(JSON.stringify(event));
    },
    addTarjeta(event) {
      this.data.ordenpagotarjeta = JSON.parse(JSON.stringify(event));
    },
    addCuentaBanco(event) {
      this.data.ordenpagobanco = JSON.parse(JSON.stringify(event));
    },
    addCheque(event) {
      this.data.ordenpagocheque = JSON.parse(JSON.stringify(event));
    },
    toCurrency(value) {
      return currency(Number(value));
    },
    close() {
      this.limpiar();
      this.$emit("input", false);
      this.$emit("datos", null);
      this.operacion = false;
    },
    limpiar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.Vact_pagocuenta_data();
      this.cotizacionerror = null;
      if (this.$refs.form != undefined)
        return this.$refs.form.resetValidation();
    },
    ...mapActions("pagocuenta", [
      "Vact_pagocuenta_by_id",
      "Vact_pagocuenta_data_id",
      "Vact_pagocuenta_save",
      "Vact_pagocuenta_data",
    ]),
    ...mapActions("moneda", ["Vact_moneda_data"]),
    focus(id, data) {
      if (id === 4 && data.idmoneda === "") return null;
      focus.nextid(id, data);
    },
    async obtenercuentas(id, idmoneda, cotizacion) {
      this.cotizacionerror = await this.Vact_pagocuenta_by_id({
        id,
        idmoneda,
        cotizacion,
      });
      this.ordenpagodet = this.Vget_pagocuenta_by_id;
    },
    isValid() {
      if (!this.$refs.form.validate() || this.data.ordenpagodet.length === 0)
        return false;
      return true;
    },
    isValidCabecera() {
      if (!this.$refs.form.validate()) return null;
      this.step++;
    },
    async guardar() {
      if (!this.isValid()) return this.$notify(mensaje.completar());
      if (this.data.cotizacion === "") {
        this.data.cotizacion = null;
      }
      const resp = await this.Vact_pagocuenta_save(this.data);
      if (!resp.estado) {
        return resp.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.$notify(resp.info);
      this.ordenpagodet = [];
      this.limpiar();
      setTimeout(() => document.getElementById("input1").focus(), 500);
    },
  },
  data() {
    return {
      cotizacionerror: null,
      dataPago: null,
      operacion: true,
      data: {
        idcuentapagar_ordenpago_recibo: 0,
        fechordenpago: current_date(),
        nro_rec_pagar: "",
        tipopago: false,
        nro_cheque: null,
        desc_rec_pagar: "",
        anulrecibo: false,
        cotizacion: "",
        idcontacto: {
          idcontacto: "",
        },
        idmoneda: {
          idmoneda: "",
        },
        ordenpagobanco: [],
        ordenpagocaja: [],
        ordenpagocheque: [],
        ordenpagotarjeta: [],
        ordenpagodet: [],
      },
      default: {
        idcuentapagar_ordenpago_recibo: 0,
        fechordenpago: current_date(),
        nro_rec_pagar: "",
        tipopago: false,
        nro_cheque: null,
        desc_rec_pagar: "",
        anulrecibo: false,
        cotizacion: "",
        idcontacto: {
          idcontacto: "",
        },
        idmoneda: {
          idmoneda: "",
        },
        ordenpagobanco: [],
        ordenpagocaja: [],
        ordenpagocheque: [],
        ordenpagotarjeta: [],
        ordenpagodet: [],
      },
      ordenpagodet: [],

      headers: [
        {
          text: "Factura",
          align: "left",
          value: "factura",
        },
        {
          text: "Vencimiento",
          align: "center",
          value: "fechavencimiento",
        },
        {
          text: "Monto",
          align: "right",
          value: "montoapagar",
        },
        {
          text: "Accion",
          align: "right",
          value: "action",
        },
      ],
    };
  },
};
</script>
