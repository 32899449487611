<template>
  <div>
    <v-form
      ref="form"
      v-if="permission.reporte"
      @submit.prevent="obtenerDatos()"
    >
      <v-row class="ma-1">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          fab
          small
          class="mt-3"
          outlined
          text
          @click="obtenerDatos()"
        >
          <v-icon>cloud_download</v-icon>
        </v-btn>
        <v-btn
          color="red"
          fab
          small
          class="ml-1 mt-3"
          outlined
          text
          @click="generarPDF()"
        >
          <v-icon>get_app</v-icon>
        </v-btn>
      </v-row>
      <v-overlay :value="load">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card-text>
        <div class="chart-wrapper">
          <v-chart :options="option" autoresize/>
        </div>
      </v-card-text>
    </v-form>
  </div>
</template>
<style scoped>
.chart-wrapper {
  width: 100%;
  height: 700px;
}
.echarts {
  width: 100%;
  height: 100%;
}
</style>
<script>
import { db } from "@/services/db.service";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { PUEDE_GENERAR_REPORTE } from "@/constants/permissions";
import { ANIMAL } from "@/constants/forms";
import ECharts from "vue-echarts/components/ECharts";
import { exportIndiceProduccion } from "./excel"
export default {
  components: {
    "v-chart": ECharts,
  },
  data() {
    return {
      load: false,
      permission: {
        reporte: getPermission(ANIMAL).permiso[PUEDE_GENERAR_REPORTE],
      },
      option: {
        tooltip: {
          trigger: "axis",
        },
        toolbox: {
          iconStyle: {
            borderColor: '#eee'
          },
          left: "center",
          show: true,
          feature: {
            dataZoom: {
              title: {
                zoom: "Zoom",
                back: "Atras",
              },
              yAxisIndex: "none",
            },
            restore: {
              title: "Restaurar",
            },
            dataView: {
              readOnly: true,
              lang: ["Lista", "Regresar"],
              title: "Listar",
              optionToContent: function (opt) {
                var axisData = opt.xAxis[0].data;
                var series = opt.series;
                var table =
                  '<table style="width:100%;text-align:center"><tbody><tr>' +
                  "<td>Fecha:</td>" +
                  "<td>" +
                  series[0].name +
                  "</td>" +
                  "<td>";
                for (var i = 0, l = axisData.length; i < l; i++) {
                  table +=
                    "<tr>" +
                    "<td>" +
                    axisData[i] +
                    "</td>" +
                    "<td>" +
                    series[0].data[i] +
                    "</td>" +
                    "<td>";
                }
                table += "</tbody></table>";
                return table;
              },
            },
            magicType: {
              type: ["line", "bar"],
              title: {
                line: "Linea",
                bar: "Bar",
              },
            },

            saveAsImage: {
              title: "Descargar",
            },
          },
        },
        xAxis: {
          type: "category",
          axisLabel: {
            color: '#eee'
          },
          boundaryGap: false,
          data: [],
          splitLine: {
            show: true,
            interval: 100,
            lineStyle: {
              color: "red",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            color: '#eee'
          }
        },
        series: [
          {
            name: "Vacas",
            type: "line",
            data: [],
            markPoint: {
              data: [
                { type: "max", name: "最大值" },
                { type: "min", name: "最小值" },
              ],
            },
            markLine: {
              data: [{ type: "average", name: "平均值" }],
            },
          },
        ],
      },
    };
  },
  watch: {
    darkMode(val) {
      this.option.toolbox.iconStyle.borderColor = val ? '#eee' : '#000'
      this.option.xAxis.axisLabel.color = val ? '#eee' : '#000'
      this.option.yAxis.axisLabel.color = val ? '#eee' : '#000'
    }
  },
  computed: {
    darkMode() {
      return this.$vuetify.theme.dark;
    },
  },
  async mounted() {
    this.obtenerDatos();
    this.option.toolbox.iconStyle.borderColor = this.darkMode ? '#eee' : '#000'
    this.option.xAxis.axisLabel.color = this.darkMode ? '#eee' : '#000'
    this.option.yAxis.axisLabel.color = this.darkMode ? '#eee' : '#000'
  },
  methods: {
    async obtenerDatos() {
      this.load = true
      try {
        const response = await db.get(`/apiindiceproductivo/`);
        this.option.series[0].data = response.value;
        this.option.xAxis.data = response.data;
        this.option.xAxis.splitLine.interval = response.linea;
      } catch (error) {
        console.log(error);
      }
      this.load = false
    },
    async generarPDF() {
      let dat = [];
      let x = this.option.series[0].data;
      for (let i = 0; i < x.length; i++) {
        dat.push([this.option.xAxis.data[i], this.option.series[0].data[i]])
      }
      exportIndiceProduccion(dat);
    },
  },
};
</script>
