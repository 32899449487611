<template>
  <div>
    <v-autocomplete
      dense
      :value="value"
      @input="$emit('input', $event)"
      @focus="$emit('focus')"
      v-on:change="$emit('change')"
      height="12px"
      ref="input"
      :id="id"
      input="input"
      :filter="customFilter"
      v-bind:items="item"
      :search-input.sync="searchitem"
      :label="label"
      :error-messages="errorMessage"
      item-text="descitem"
      return-object
      :loading="Vget_item_load"
      :rules="[(v) => !!v || 'Seleccion un Item']"
      required
      :clearable="true"
      :readonly="readonly"
      outlined
      autocomplete="off"
      :no-data-text="`No existe ninguna concidencia`"
      small-chips
    >
      <template v-if="noData && item_permission && create" v-slot:no-data>
        <v-list-item @click="$emit('create', true)">
          <v-list-item-content>
            <v-list-item-title
              >No existe concidencia. Click para agregar</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </template>
      <template slot="selection" slot-scope="data">
        <v-chip small>{{ `${data.item.iditem.descitem}` }}</v-chip>
      </template>
      <template slot="item" slot-scope="data">
        <v-list-item-icon>
          <v-icon
            :color="
              data.item.iddeposito.iddeposito === 0
                ? 'grey lighten-3'
                : 'green accent-3'
            "
            >{{
              data.item.iddeposito.iddeposito !== 0
                ? "lens"
                : "panorama_fish_eye"
            }}</v-icon
          >
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{data.item.iditem.iditem}} - {{data.item.iditem.descitem}} - {{data.item.iditem.idmarca.descmarca}}
          </v-list-item-title>
          <v-list-item-subtitle>
            Deposito: {{data.item.iddeposito.desdeposito}} - Cantidad: {{data.item.cantstock}} - {{data.item.iditem.idunidadmedida == null ? 'No posee' : data.item.iditem.idunidadmedida.abreviatura}}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { ITEM } from "@/constants/forms";
import { PUEDE_AGREGAR } from "@/constants/permissions";
export default {
  props: {
    value: {
      type: [String, Object, Number],
    },
    label: {
      type: String,
      default: "Item",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    "search-item-id": {
      type: [String, Number],
      default: null,
    },
    create: {
      type: Boolean,
      default: true,
    },
    id: String,
  },
  data() {
    return {
      searchitem: "",
      item: [],
      iditem: "",
      noData: true,
      errorMessage: "",
      awaitingSearch: false,
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    Vget_item_agrostock(val) {
      this.item = [];
      this.item = JSON.parse(JSON.stringify(val));
      if (this.searchItemId != null) return this.search();
    },
    searchitem(val) {
      this.$emit("sync-input", val);
      this.obtain_item(val);
      /*
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.obtain_item();
          this.awaitingSearch = false;
        }, 1000)
      }
      this.awaitingSearch = true;
      */
    },
    searchItemId() {
      this.search();
    },
  },
  computed: {
    ...mapGetters("item", ["Vget_item_agrostock", "Vget_item_load"]),
    item_permission() {
      return getPermission(ITEM).permiso[PUEDE_AGREGAR];
    },
    length() {
      if (
        this.searchitem === null ||
        this.searchitem === undefined ||
        this.searchitem === ""
      )
        return 0;
      return this.searchitem.length;
    },
  },
  methods: {
    ...mapActions("item", ["Vact_item_agrostock"]),
    async obtain_item(val) {
      if (val != null && val.length == 3) {
        this.Vact_item_agrostock({
          search: val,
          idmoneda: 1,
        });
      } else if (val != null && val.length < 3) {
        this.item = []
      }
    },
    focus() {
      this.$refs.input.focus();
    },
    isMenuActive() {
      this.$refs.input.isMenuActive = false;
    },
    async init() {
      this.item = [];
    },
    customFilter(item, queryText) {
      const textOne = item.iditem.iditem.toString();
      const textTwo = item.iditem.idmarca.descmarca.toLowerCase();
      const textTree = item.iditem.descitem.toLowerCase();
      const searchText = queryText.toLowerCase();
      return (
        textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1 ||
        textTree.indexOf(searchText) > -1
      );
    },
    search() {
      this.item.forEach((x) => {
        if (x.iditem.iditem === this.searchItemId) {
          this.$emit("input", x);
          this.$emit("change");
          this.$emit("resetSearch", null);
          return;
        }
      });
    },
  },
};
</script>
