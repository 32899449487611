<template>
  <div>
    <v-dialog :value="value" max-width="700px" persistent>
      <v-card>
        <v-toolbar flat dense>
          <v-toolbar-title> Detalles</v-toolbar-title>
        </v-toolbar>
        <v-container>
          <v-row row dense>
            <v-col cols="12">
              <v-text-field
                dense
                type="text"
                id="input3"
                v-model="data.descraza"
                label="Descripcion"
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                dense
                id="input2"
                v-model="data.abrvraza"
                label="Abreviatura"
                persistent-hint
                outlined
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <c-btn-crud-close @click="cancelar()"></c-btn-crud-close>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    value: Boolean,
    datos: {
      type: [Object, Array],
      default: null,
    },
  },
  data() {
    return {
      data: {
        descraza: "",
        abrvraza: "",
      },
      default: {
        descraza: "",
        abrvraza: "",
      },
    };
  },
  created() {
    this.show(this.datos);
  },
  watch: {
    $route(to, from) {
      if (from.path === "/raza") return this.cancelar();
    },
  },
  methods: {
    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$emit("reset", null);
      this.$emit("input", false);
    },

    show(val) {
      this.data = JSON.parse(JSON.stringify(val));
    },
  },
};
</script>
