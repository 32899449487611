
export default {
  Vget_caja_data: (state) => {
    return state.caja_data
  },
  Vget_caja_cajacuenta: (state) => {
    return state.caja_cajacuenta
  },
  Vget_caja_load: (state) => {
    return state.caja_load
  },
  Vget_caja_save_load: (state) => {
    return state.caja_save_load
  },
}
