<template>
  <div>
    <NotificationList
      @click-calendar="calendar = true"
      @open-form="openForm($event)"
      @delete-notification="deleteNotification($event)"
    />
    <Calendar
      v-if="calendar"
      v-model="calendar"
      @open-form="openForm($event)"
    />
    <component
      v-if="dialog_crud"
      :is="form"
      v-bind="idoperacion"
      v-model="dialog_crud"
    ></component>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Calendar from "./Calendar";
import NotificationList from "./NotificacionList";
import ApiService from "@/services/auth/api.service";
export default {
  components: {
    Calendar,
    NotificationList,
    Inseminacion: () => import("@/views/Analisis/Inseminacion/Create"),
    Secado: () => import("@/views/Animal/Secado/Create"),
    Palpacion: () => import("@/views/Enfermedad/Tacto/Create"),
    Timbrado: () => import("@/views/Venta/Timbrado/Show"),
    Agenda: () => import("@/views/Configuraciones/Agenda/Create"),
    Preventa: () => import("@/views/Venta/PedidoVenta/Show"),
    Celo: () => import("@/views/Analisis/Celo/Create"),
    Medicacion: () => import("@/views/Analisis/Medicacion/Create"),
    Zafra: () => import("@/views/Agro/Campana/Cierre"),
    Preparto: () => import("@/views/Animal/Preparto/Create"),
  },
  data: () => ({
    connection: null,
    calendar: false,
    form: "",
    idoperacion: "",
    dialog_crud: false,
  }),
  computed: {
    ...mapGetters("auth", ["getAccessToken"]),
  },

  mounted() {
    this.connetWebSocket();
    this.Vact_notificacion_data();
  },
  methods: {
    ...mapActions("dashboard", [
      "Vact_notificacion_data",
      "Vact_notificacion_add",
      "Vact_notificacion_view",
    ]),
    ...mapActions("plaga", ["fetchPlagaZona"]),
    connetWebSocket() {
      const wsRoute = "/ws/notifications/?token=" + this.getAccessToken;
      const [protocol, hostWithRoute] = ApiService.getBaseURL().split("//");
      const [wsHost] = hostWithRoute.split("/");
      const wsProtocol = protocol === "https:" ? "wss://" : "ws://";
      this.connection = new WebSocket(wsProtocol + wsHost + wsRoute);
      const self = this;

      this.connection.onmessage = (event) =>
        self.Vact_notificacion_add(JSON.parse(event.data));
      this.connection.onopen = () =>
        console.info("Successfully connected websocket");
      this.connection.onerror = () =>
        console.error("Failed connected websocket");
    },
    deleteNotification(data) {
      this.Vact_notificacion_view(data.idnotificacion);
    },
    openForm(data) {
      if(data.formulario === 'Termo Semen' || data.formulario === 'Animal' || data.formulario === 'Parto' || data.active ===false) return null;
      switch (data.formulario) {
        case "Zafra":
          this.idoperacion = {
            idcampana: data.clave,
          };
          break;
        case "Emision de Cheque":
          return null;
        case "Extracto de Proveedor":
          this.$router.push({
            path: "/extractoproveedor",
            query: { idcontacto: Number(data.clave) },
          });
          return null;
        case "Extracto de Cliente":
          this.$router.push({
            path: "/extractocliente",
            query: { idcontacto: Number(data.clave) },
          });
          return null;
        case "Calendario":
          return (this.calendar = true);
        case "Preventa":
          this.idoperacion = {
            idpedido_venta: Number(data.clave),
          };
          break;
        case "Secado":
          this.idoperacion = {
            xidanimal: Number(data.clave),
          };
          break;
        case "Celo":
          this.idoperacion = {
            xidanimal: Number(data.idanimal),
          };
          break;
        case "Preparto":
          this.idoperacion = {
            idanimal: Number(data.clave),
          };
          break;
        case "Timbrado":
          this.idoperacion = {
            xid: Number(data.clave),
          };
          break;
        case "Agenda":
          this.idoperacion = {
            xid: Number(data.clave),
          };
          break;
        case "Medicacion":
          this.idoperacion = {
            readonly: true,
            idmedicacion: Number(data.clave),
          };
          break;
        case "Campo":
          if (this.$route.path !== "/agricultura/campo/detalle/" + data.clave)
            this.$router.push({
              path: "/agricultura/campo/detalle/" + data.clave,
              query: {
                polygon: data.id_polygon,
              },
            });
          return this.fetchPlagaZona(data.clave);
        default:
          this.idoperacion = { xid: Number(data.clave) };
          break;
      }
      this.dialog_crud = true;
      this.form = data.formulario;
    },
  },
};
</script>
