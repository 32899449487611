import { get } from "@/services/api/api.service";
import { FETCH, LOADING, url } from "./contants";
export default {
  fetchSexoCria: async ({ commit, dispatch }) => {
    commit(LOADING, true);
    try {
      const response = await get(url.root);
      commit(FETCH, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(LOADING, false);
      throw e;
    }
    commit(LOADING, false);
  },
};
