<template>
    <v-btn color="primary" text fab x-small @click="$emit('click')">
        <v-icon dark>chevron_right</v-icon>
    </v-btn>
</template>

<script>
export default {
    props: {
        to: String,
    },
};
</script>