<template>
  <v-autocomplete
    dense
    :value="value"
    @input="$emit('input', $event)"
    v-on:change="$emit('change')"
    height="12px"
    :id="id"
    :filter="customFilter"
    v-bind:items="item"
    :search-input.sync="searchitem"
    fkdes="item"
    :label="label"
    item-text="descitem"
    return-object
    :loading="Vget_item_load"
    required
    :clearable="true"
    :readonly="readonly"
    outlined
    autocomplete="off"
    :no-data-text="`No existe ninguna concidencia`"
    small-chips
  >
    <template slot="selection" slot-scope="data">
      <v-chip small>{{ `${data.item.iditem.descitem}` }}</v-chip>
    </template>
    <template slot="item" slot-scope="data">
      <v-list-item-content>
        <v-list-item-title
          v-html="`${data.item.iditem.iditem} - ${data.item.iditem.descitem} - ${data.item.iditem.idmarca.descmarca}`"
        ></v-list-item-title>
        <v-list-item-subtitle
          v-html="`Deposito: ${data.item.iddeposito.desdeposito} - Cantidad: ${data.item.cantstock}`"
        ></v-list-item-subtitle>
      </v-list-item-content>
    </template>
    <template v-if="noData && item_permission && length > 0" v-slot:no-data>
      <v-list-item @click="$emit('create', true)">
        <v-list-item-content>
          <v-list-item-title>No existe concidencia. Click para agregar</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { ITEM } from "@/constants/forms";
import {
  PUEDE_AGREGAR
} from "@/constants/permissions";
export default {
  props: {
    value: {
      type: [String, Object, Number]
    },
    label: {
      type: [String],
      default: ""
    },
    readonly: {
      type: Boolean,
      default: false
    },
    id: String
  },
  data() {
    return {
      searchitem: "",
      item: [],
      iditem: "",
      noData: true
    };
  },
  watch: {
    searchitem(val) {
      if (val === null || val === undefined || val === "") return null;
      if (val.length > 0 && this.idmoneda != "") {
        this.Vact_item_medicina({
          search: this.searchitem,
          idmoneda: this.idmoneda
        });
      }
    },
    Vget_item_medicina(val) {
      this.item = [];
      this.item = val;
    }
  },
  computed: {
    ...mapGetters("item", ["Vget_item_medicina", "Vget_item_load"]),
    item_permission() {
      return getPermission(ITEM).permiso[PUEDE_AGREGAR];
    },
    length() {
      if (
        this.searchitem === null ||
        this.searchitem === undefined ||
        this.searchitem === ""
      )
        return 0;
      return this.searchitem.length;
    }
  },
  methods: {
    ...mapActions("item", ["Vact_item_medicina"]),
    customFilter(item, queryText) {
      const textOne = item.iditem.iditem.toString();
      const textTwo = item.iditem.idmarca.descmarca.toLowerCase();
      const textTree = item.iditem.descitem.toLowerCase();
      const searchText = queryText.toLowerCase();
      return (
        textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1 ||
        textTree.indexOf(searchText) > -1
      );
    }
  }
};
</script>

<style>
</style>