<template>
    <v-dialog persistent max-width="700" :value="value">
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title>
                    {{ title }}
                </v-toolbar-title>
            </v-toolbar>
            <v-container>
                <v-form ref="form" @submit.prevent="">
                    <v-row dense>
                        <v-col cols="12">
                            <c-text-date label="Fecha" ref="movimiento1" id="movimiento1" outlined dense
                                v-model="data.fecha" @keyup.native.enter="next(1, data.fecha)"></c-text-date>
                        </v-col>
                        <v-col cols="12">
                            <AutocompleteCuenta :returnObject="true" id="movimiento2" v-model="data.idfinanza_cuenta"
                                @nextfocus="next(4, '-')" @keyup.native.enter="next(4, data.idfinanza_cuenta)" />
                        </v-col>
                        <v-col cols="12">
                            <v-btn-toggle v-model="data.tipo" dense rounded color="purple darken-1">
                                <v-btn :value="true">
                                    Movimiento
                                </v-btn>
                                <v-btn :value="false">
                                    Transferencia
                                </v-btn>
                            </v-btn-toggle>
                        </v-col>
                        <v-col cols="12">
                            <v-alert dense type="info" v-if="data.idfinanza_categoria.presupuesto">
                                Presupuesto disponible: <strong>{{ getPresupuestoDisponible.disponible }}</strong>
                            </v-alert>
                        </v-col>
                        <v-col cols="12" v-if="data.tipo == true">
                            <AutocompleteCategoria :returnObject="true" id="movimiento3"
                                v-model="data.idfinanza_categoria" @nextfocus="next(3, '-')"
                                @keyup.native.enter="next(3, data.idfinanza_categoria)"/>
                        </v-col>
                        <v-col cols="12" v-else>
                            <AutocompleteCuenta :returnObject="true" id="movimiento4" v-model="data.idfinanza_destino"
                                @nextfocus="next(4, '-')" @keyup.native.enter="next(4, data.idfinanza_destino)" />
                        </v-col>
                        <v-col cols="2">
                            <v-btn class="mx-2" fab dark small :color="`${data.activo ? 'green' : 'red'}`"
                                @click="data.activo = !data.activo">
                                <v-icon dark>
                                    {{ `${data.activo ? 'add' : 'remove'}` }}
                                </v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="10">
                            <c-text-currency dense outlined ref="movimiento4" id="movimiento4" :rules="rules"
                                v-model="data.monto" label="Monto"
                                @keyup.native.enter="next(4, data.monto)"></c-text-currency>
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>
            <v-divider></v-divider>
            <v-card-actions>
                <c-btn-crud-close @click="cancel()"></c-btn-crud-close>
                <v-spacer></v-spacer>
                <c-btn-crud-done ref="movimiento5" id="movimiento5" @click="save()"></c-btn-crud-done>
            </v-card-actions>
            <c-overlay :value="isLoadingMovimiento"></c-overlay>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { focus } from "@/services/focus.service";
import { current_date } from "@/services/util/date.service";
import AutocompleteCuenta from "../Cuenta/Autocomplete.vue";
import AutocompleteCategoria from "../Categoria/Autocomplete.vue";
export default {
    components: {
        AutocompleteCuenta,
        AutocompleteCategoria,
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        editable: {
            type: Boolean,
            default: false,
        },
        props: {
            type: Object,
        },
        "sync-input": {
            type: [String, Number],
            default: null,
        },
    },
    mounted() {
        if (this.editable) this.data = JSON.parse(JSON.stringify(this.props));
        setTimeout(() => this.next(0, "-"), 20);
        if (this.syncInput != null) this.data.cuenta = this.syncInput;
        this.sync = this.syncInput;
    },
    watch: {
        'movimiento': function (newValue) {
            if (newValue == 0) {
                this.finanzadestino = []
                this.destino = this.destino_default
            } else {
                this.finanzacategoria = []
                this.categoria = this.categoria_default
            }
        },
        'data.idfinanza_categoria': function (newValue) {
            if (newValue != "") {
                this.data.activo = newValue.idfinanza_grupo.activo
            }
        }
    },
    computed: {
        ...mapGetters("finanza", [
            "isLoadingMovimiento",
            "getMovimiento",
            "getTransferencia",
            "getPresupuestoDisponible",
            "isLoadingPresupuestoDisponible",
        ]),
        title() {
            return this.editable ? "Modificar Movimiento" : "Registrar Movimiento";
        },
    },
    methods: {
        ...mapActions("finanza", [
            "setMovimiento",
            "setMovimientoUpdate",
            "fetchMovimiento",
            "fetchSaldo",
            "fetchPresupuestoDisponible",
        ]),
        async presupuestoDisponible() {
            await this.fetchPresupuestoDisponible({
                categoria: this.data.idfinanza_categoria.idfinanza_categoria
            })
        },
        next(id, data) {
            focus.nextidName("movimiento", id, data)
        },
        cancel() {
            this.$emit("input", false);
        },
        async save() {
            if (!this.$refs.form.validate()) return null;
            const response = this.editable
                ? await this.setMovimientoUpdate({
                    data: this.data,
                    id: this.data.idfinanza_movimiento,
                })
                : await this.setMovimiento(this.data);
            if (response.success) {
                if (this.sync != null) {
                    this.cancel();
                    this.fetchMovimiento();
                    return this.$emit("sync-input", response.data.idfinanza_movimiento);
                }
                this.data = JSON.parse(JSON.stringify(this.default));
                this.$refs.form.resetValidation();
                this.fetchMovimiento();
                this.fetchSaldo();
                this.next(0, '-');
                if (this.editable) this.cancel();
            }
        }
    },
    data: () => ({
        rules: [(v) => !!v || "Este campo es obligatorio"],
        movimiento: 0,
        data: {
            idfinanza_movimiento: 0,
            fecha: current_date(),
            idfinanza_cuenta: "",
            monto_movimiento: 0,
            tipo: true,
            idfinanza_categoria: "",
            idfinanza_destino: "",
            activo: true,
            monto: 0,
            finanzadestino: [],
            finanzacategoria: [],
        },
        default: {
            idfinanza_movimiento: 0,
            fecha: current_date(),
            idfinanza_cuenta: "",
            monto_movimiento: 0,
            tipo: true,
            idfinanza_categoria: "",
            idfinanza_destino: "",
            activo: true,
            monto: 0,
            finanzadestino: [],
            finanzacategoria: [],
        }
    })
}
</script>