

export default {
  cobrocuenta_load: false,
  cobrocuenta_data: [],
  cobrocuenta_data_id: [],
  cobrocuenta_by_id: [],
  cobrocuenta_num_rec_load: false,
  cobrocuenta_num_rec: ''
}
