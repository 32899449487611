<template>
  <div>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>{{titulo}}</v-toolbar-title>
        <widgets-Favorite ruta="/magnitud" formulario="magnitud" />
        <v-divider class="mx-2" inset vertical></v-divider>

        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Busqueda"
          single-line
          hide-details
        ></v-text-field>

      </v-toolbar>
      <v-data-table
        :loading="Vget_magnitud_load"
        :headers="headers"
        :items="Vget_magnitud_data"
        :search="search"
        class="elevation-1"
      >
        <template v-slot:no-data>
          <v-alert :value="true" color="info" icon="info" outlined>No existe ningun registro</v-alert>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "Codigo", align: "left", value: "idmagnitud" },
        { text: "Descripcion", align: "left", value: "descripcion" }
      ],
      titulo: "Magnitud Fisica",

    }
  },
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.Vact_magnitud_data();
  },
  computed: {
    ...mapGetters("magnitud", ["Vget_magnitud_data","Vget_magnitud_load"]),

  },
  methods: {
    ...mapActions("magnitud", [
      "Vact_magnitud_data"
    ])
  }
};
</script>