export default {
    async tipoajuste_create({commit},request){
        commit('tipoajuste_create',request);
    },
    async tipoajuste_update({commit},request){
        commit('tipoajuste_update',request);
    },
    async tipoajuste_reload({commit},request){
        commit('tipoajuste_reload',request);
    },
    async tipoajuste_show({commit},request){
        commit('tipoajuste_show',request);
    },
}
