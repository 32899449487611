<template>
  <div>
    <v-dialog v-model="dialogo" max-width="700px" persistent @keydown.esc="cancelar()">
      <v-card>
        <v-form ref="form" lazy-validation @submit.prevent="nextfocus(1)">
          <v-card-title>
            <span class="headline">{{titulo}}</span>
          </v-card-title>
          <v-card-text>
            <v-row row wrap>
              <v-col cols="12">
                <v-text-field
                  dense
                  type="text"
                  :rules="validar"
                  ref="input1"
                  v-model="data.descripcion"
                  label="Descripcion"
                  required
                  outlined
                  autocomplete="off"
                  @keyup.enter="nextfocus(1)"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="cancelar">Cancelar</v-btn>
            <v-btn ref="aceptar" color="blue darken-1" text @click="guardar">Guardar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { db } from "@/services/db.service";
import { mensaje } from "@/services/notify.service";
import { setTimeout } from "timers";
export default {
  data() {
    return {
      overlay: false,
      titulo: "Nuevo Tipo Cuenta",
      data: {
        idtipocuentabanco: 0,
        descripcion: ""
      },
      default: {
        idtipocuentabanco: 0,
        descripcion: ""
      },
      url: "/apitipocuentabanco/",
      validar: [v => !!v || "Campo requerido"],
      validars: [v => !!v || "Seleccione un campo"],
      formulario: {},
      dialogo: false,
      reload: 0,
      operacion: true
    };
  },
  computed: {
    ...mapGetters("tipocuenta", {
      getcreate: "tipocuenta_create",
      getupdate: "tipocuenta_update"
    })
  },
  watch: {
    $route(to, from) {
      if (from.path === "/tipocuenta") return this.cancelar();
    }
  },
  created() {
    this.nuevo(this.getupdate);
  },
  mounted() {
    setTimeout(() => this.$refs.input1.focus());
  },
  methods: {
    ...mapActions("tipocuenta", [
      "tipocuenta_create",
      "tipocuenta_update",
      "tipocuenta_reload"
    ]),

    nextfocus(x) {
      switch (x) {
        case 1: {
          if (this.data.descripcion != "") {
            this.$refs.aceptar.$el.focus();
          }
          break;
        }
      }
    },

    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.dialogo = false;
      this.tipocuenta_update(null);
      this.tipocuenta_create(false);
      this.tipocuenta_reload(true);
      this.operacion = true;
    },
    async guardar_finalizar() {
      let a = await db.save(this.url, this.data);
      if (a.estado == true) {
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$notify(a.info);
        this.$refs.form.resetValidation();
        this.$refs.input1.focus();
      } else {
        a.info.map(x => {
          this.$notify(mensaje.error(x));
        });
      }
      this.overlay = false;
    },
    async editar_finalizar() {
      const id = this.getupdate.idtipocuentabanco;
      let a = await db.update(`${this.url}${id}/`, this.data);
      if (a.estado == true) {
        this.$notify(a.info);
        this.cancelar();
      } else {
        a.info.map(x => {
          this.$notify(mensaje.error(x));
        });
      }
      this.overlay = false;
    },
    guardar() {
      if (this.$refs.form.validate()) {
        this.overlay = true;
        if (this.operacion === true) return this.guardar_finalizar();
        if (this.operacion === false) return this.editar_finalizar();
      } else {
        this.$notify(mensaje.completar());
      }
    },
    nuevo(val) {
      this.dialogo = true;
      if (val !== null) {
        this.data = val;
        this.operacion = false;
        this.titulo = "Editar Tipo Cuenta";
      }
    }
  }
};
</script>

