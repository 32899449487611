export default {
  funcionario: [],
  funcionario_inactive: [],
  saldos:[],
  anhos: [],
  documentos: [],
  empleados_obreros: [],
  planilla_sueldos_ips:[],
  resumen_general: [],
  isReporteLoading: false,
  cargo: [],
  sueldos_jornales: [],
  isLoading: false
}
