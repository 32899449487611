export default {
  emisioncheque_data(state, request) {
    state.emisioncheque_data = request;
  }, 
  emisioncheque_data_pagado(state, request) {
    state.emisioncheque_data_pagado = request;
  }, 
  emisioncheque_load(state, request) {
    state.emisioncheque_load = request;
  }, 
  emisioncheque_save_load(state, request) {
    state.emisioncheque_save_load = request;
  }, 
}