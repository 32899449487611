<template>
  <div>
    <div v-if="Vget_create">
      <Personas-Contacto-Create />
    </div>
    <v-autocomplete
      :value="value"
      :id="id"
      ref="input"
      @input="$emit('input', $event)"
      :rules="rules_active"
      :search-input.sync="searchText"
      dense
      type="text"
      :messages="messages"
      :items="Vget_contacto_data_proveedor"
      :label="label"
      :filter="customFilter"
      return-object
      item-text="desccontacto"
      item-value="idcontacto"
      outlined
      :readonly="readonly"
      :disabled="disabled"
      height="12"
      autocomplete="off"
      :loading="Vget_contacto_load"
      :no-data-text="`No existe ninguna concidencia`"
      v-on:change="change"
    >
      <template slot="selection" slot-scope="data">
        <v-list-item-title>
          {{ data.item.desccontacto }} - RUC: {{ data.item.ruccontacto }}-{{ data.item.docucontactos }}
        </v-list-item-title>
      </template>
      <template slot="item" slot-scope="data">
        <v-list-item-content>
          <v-list-item-title>{{ data.item.desccontacto }}</v-list-item-title>
          <v-list-item-subtitle>
            {{ data.item.ruccontacto }}-{{ data.item.docucontactos }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
      <template v-if="noData && isPermit && create" v-slot:no-data>
        <v-list-item @click="open_ref()">
          <v-list-item-content>
            <v-list-item-title
              >No existe concidencia. Click para agregar</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mapGetters, mapActions } from "vuex";
import { PUEDE_AGREGAR } from "@/constants/permissions";
export default {
  props: {
    label: String,
    value: [Number, String, Object],
    id: String,
    readonly: {
      type: Boolean,
      default: false,
    },
    create: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Boolean,
      default: true,
    },
    messages: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      validar: [(v) => !!v.idcontacto || "Seleccion un proveedor"],
      permission: "Proveedores",
      noData: true,
      searchText: null,
      isPermit: false,
    };
  },

  mounted() {
    this.isPermit = getPermission(this.permission).permiso[PUEDE_AGREGAR];
    this.Vact_contacto_data_proveedor();
  },

  computed: {
    ...mapGetters("contacto", [
      "Vget_contacto_data_proveedor",
      "Vget_contacto_load",
    ]),
    ...mapGetters("contacto", {
      Vget_create: "contacto_create",
    }),
    rules_active() {
      if (this.rules) return this.validar;
      return [];
    },
  },
  methods: {
    ...mapActions("contacto", [
      "Vact_contacto_data_proveedor",
      "contacto_create",
    ]),
    focus() {
      this.$refs.input.focus();
    },
    open_ref() {
      this.contacto_create(true);
    },
    change(evt) {
      this.$emit("change", evt);
    },
    customFilter(item, queryText) {
      const textOne = item.ruccontacto.toLowerCase();
      const textTwo = item.desccontacto.toLowerCase();
      const searchText = queryText.toLowerCase();
      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },
  },
};
</script>
<style>
.v-select__selections {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  flex-wrap: nowrap;
  line-height: 18px;
  max-width: 100%;
  min-width: 0;
}
</style>
