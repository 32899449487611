<template>
  <v-data-table :headers="permiso.can_view_numbers ? parcela ? headersP : headers : parcela ? headersPNoPrecio: headersNoPrecio" :items="items" :mobile-breakpoint="0" :items-per-page="1000"
    hide-default-footer>
    <template v-slot:[`item.idstock.iditem.itemquimica`]="{ item }">
      {{ producto_activo(item.idstock.iditem.itemquimica) }}
    </template>
    <template v-slot:[`item.cantidad`]="{ item }">
      {{ currencyFormat(item.cantidad) }}
    </template>
    <template v-slot:[`item.cantxha`]="{ item }">
      {{ currencyFormat(item.cantxha) }}
    </template>
    <template v-slot:[`item.precio`]="{ item }">
      {{ currencyFormat(item.precio) }}
    </template>
    <template v-slot:[`item.precioxha`]="{ item }">
      {{ currencyFormat(item.precioxha) }}
    </template>
    <template v-slot:[`item.total`]="{ item }">
      {{ currencyFormat(item.total) }}
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small class="mr-1" @click="$emit('edit', item)" :disabled="!readonly">edit</v-icon>
      <v-icon small @click="$emit('delete', item)" :disabled="!readonly">delete</v-icon>
    </template>
  </v-data-table>
</template>
<script>
import { currency } from "@/services/util/number.service";
export default {
  props: {
    items: Array,
    readonly: Boolean,
    parcela: Boolean,
    permiso: Object,
  },
  data: () => ({
    headers: [
      { text: "Hectareas", sortable: false, value: "hectarea" },
      {
        text: "Producto",
        align: "start",
        sortable: false,
        value: "idstock.iditem.descitem",
      },
      {
        text: "Producto Activo",
        align: "start",
        sortable: false,
        value: "idstock.iditem.itemquimica"
      },
      {
        text: "Unidad Medida",
        sortable: false,
        value: "idunidadmedida.descunidadmedida",
      },
      { text: "Cant. U x Ha", sortable: false, value: "cantidad" },
      { text: "Cant. Total", sortable: false, value: "cantxha" },
      { text: "Precio Unitario", sortable: false, value: "precio" },
      { text: "Precio x Ha", sortable: false, value: "precioxha" },
      { text: "Precio Total", sortable: false, value: "total" },
      { text: "Accion", value: "actions", sortable: false, align: "right" },
    ],
    headersP: [
      { text: "Parcela", sortable: false, value: "idparcelas.descripcion" },
      { text: "Hectareas", sortable: false, value: "hectarea" },
      {
        text: "Producto",
        align: "start",
        sortable: false,
        value: "idstock.iditem.descitem",
      },
      {
        text: "Unidad Medida",
        sortable: false,
        value: "idunidadmedida.descunidadmedida",
      },
      { text: "Cant. U x Ha", sortable: false, value: "cantidad" },
      { text: "Cant. Total", sortable: false, value: "cantxha" },
      { text: "Precio Unitario", sortable: false, value: "precio" },
      { text: "Precio x Ha", sortable: false, value: "precioxha" },
      { text: "Precio Total", sortable: false, value: "total" },
      { text: "Accion", value: "actions", sortable: false, align: "right" },
    ],
    headersNoPrecio: [
      { text: "Hectareas", sortable: false, value: "hectarea" },
      {
        text: "Producto",
        align: "start",
        sortable: false,
        value: "idstock.iditem.descitem",
      },
      {
        text: "Producto Activo",
        align: "start",
        sortable: false,
        value: "idstock.iditem.itemquimica"
      },
      {
        text: "Unidad Medida",
        sortable: false,
        value: "idunidadmedida.descunidadmedida",
      },
      { text: "Cant. U x Ha", sortable: false, value: "cantidad" },
      { text: "Cant. Total", sortable: false, value: "cantxha" },
      { text: "Accion", value: "actions", sortable: false, align: "right" },
    ],
    headersPNoPrecio: [
      { text: "Parcela", sortable: false, value: "idparcelas.descripcion" },
      { text: "Hectareas", sortable: false, value: "hectarea" },
      {
        text: "Producto",
        align: "start",
        sortable: false,
        value: "idstock.iditem.descitem",
      },
      {
        text: "Producto Activo",
        align: "start",
        sortable: false,
        value: "idstock.iditem.itemquimica"
      },
      {
        text: "Unidad Medida",
        sortable: false,
        value: "idunidadmedida.descunidadmedida",
      },
      { text: "Cant. U x Ha", sortable: false, value: "cantidad" },
      { text: "Cant. Total", sortable: false, value: "cantxha" },
      { text: "Accion", value: "actions", sortable: false, align: "right" },
    ]
  }),

  methods: {
    currencyFormat(value) {
      return currency(Number(value));
    },
    producto_activo(value) {
      let texto = ""
      if (value != null) {
        value.forEach(x => {
          if (x.porcentaje) {
            texto = texto + x.descripcion + " " + x.porcentaje + "; "
          } else {
            texto = texto + x.descripcion + "; "
          }
        });
      }
      return texto
    },
  },
};
</script>
