<template>
  <input type="hidden" :value="value" @input="$emit('input', $event)" />
</template>  
<script>
export default {
  props: {
    google: {
      type: Object,
      required: true
    },
    map: {
      type: Object,
      required: true
    },
    value: {
      type: [Object, String],
      required: true
    }
  },

  mounted() {
    var marker = null;
    var self = this;
    var google = this.google;
    if (this.value === "") {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function(x) {
          let coord = { lat: x.coords.latitude, lng: x.coords.longitude };
          placeMarker(coord, self.map);
          self.$emit("input", coord);
        });
      }
    }else{
      placeMarker(this.value, this.map);
    }

    google.maps.event.addListener(this.map, "click", function(event) {
      placeMarker(event.latLng, self.map);
      let coord = { lat: event.latLng.lat(), lng: event.latLng.lng() };
      self.$emit("input", coord);
    });

    function placeMarker(position, map) {
      if (marker === null) {
        marker = new google.maps.Marker({
          position: position,
          map: map
        });
        map.panTo(position);
      } else {
        marker.setPosition(position);
      }
    }
  },
  methods: {},
  // eslint-disable-next-line
  render() {}
};
</script>
