<template>
  <div>
    <div v-if="crud.add">
      <Item-Deposito-create
        v-model="crud.add"
        :datos="crud.datos"
        @datos="crud.datos = $event"
      />
    </div>
    <div v-if="crud.view">
      <Item-Deposito-show
        v-model="crud.view"
        :datos="crud.datos"
        @datos="crud.datos = $event"
      />
    </div>
    <v-card>
      <v-alert type="error" v-if="permission_alert"
        >Error!!! Este usuario no tiene permisos en esta ventana</v-alert
      >
      <v-toolbar flat dense>
        <v-toolbar-title>{{ titulo }}</v-toolbar-title>
        <widgets-Favorite ruta="/deposito" formulario="Deposito" />
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Busqueda"
          single-line
          hide-details
          v-if="permission.can_view"
        ></v-text-field>
        <v-toolbar-items>
          <c-btn-table-add
            @click="crud.add = true"
            v-if="permission.can_add"
          ></c-btn-table-add>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table
        :loading="Vget_deposito_load"
        :headers="headers"
        :items="Vget_deposito_data"
        :search="search"
        class="elevation-1"
        v-if="permission.can_view"
      >
        <template v-slot:[`item.accion`]="props">
          <v-icon class="mr-2" @click="rowselect(props.item)"
            >visibility</v-icon
          >
          <v-icon
            class="mr-2"
            @click="mupdate(props.item)"
            v-if="permission.can_update"
            >edit</v-icon
          >
          <v-icon
            small
            @click="meliminar(props.item)"
            v-if="permission.can_delete"
            >delete</v-icon
          >
        </template>
        <template v-slot:no-data>
          <v-alert :value="true" color="info" icon="info" outlined
            >No existe ningun registro</v-alert
          >
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogoeliminar" persistent max-width="350">
      <v-card>
        <v-card-title>
          <v-icon left>info</v-icon>
          <span class="title font-weight-light">Ventana de Confirmacion</span>
        </v-card-title>
        <v-card-text>Desea eliminar {{ objeto.desdeposito }}?</v-card-text>
        <v-card-actions>
          <v-btn color="red" text @click="eliminarcancelar">
            <v-icon>clear</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="meliminarguardar">
            <v-icon>done</v-icon>
          </v-btn>
        </v-card-actions>
        <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { DEPOSITO } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";

export default {
  data() {
    return {
      overlay: false,
      search: "",
      headers: [
        { text: "Codigo", align: "left", value: "iddeposito" },
        { text: "Descripcion", align: "left", value: "desdeposito" },
        { text: "Accion", value: "accion", sortable: false, align: "right" },
      ],
      dialogo: false,
      titulo: "Deposito",
      objeto: "",
      index: "",
      dialogoeliminar: false,
      load: false,
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
      crud: {
        add: false,
        view: false,
        datos: null,
      },
    };
  },

  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.Vact_deposito_data();

    const permission = getPermission(DEPOSITO).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  computed: {
    ...mapGetters("deposito", ["Vget_deposito_data", "Vget_deposito_load"]),
    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },

  methods: {
    ...mapActions("deposito", ["Vact_deposito_data", "Vact_deposito_del"]),

    meliminar(item) {
      this.index = this.Vget_deposito_data.indexOf(item);
      this.objeto = Object.assign({}, item);
      this.dialogoeliminar = true;
    },
    rowselect(val) {
      this.crud.datos = JSON.parse(JSON.stringify(val));
      this.crud.view = true;
    },
    eliminarcancelar() {
      this.index = "";
      this.objeto = "";
      this.dialogoeliminar = false;
    },
    async meliminarguardar() {
      this.overlay = true;
      const id = this.objeto.iddeposito;
      const a = await this.Vact_deposito_del(id);
      if (a.estado == true) {
        this.$notify(a.info);
        this.dialogoeliminar = false;
        this.eliminarcancelar();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.overlay = false;
    },
    mupdate(val) {
      this.crud.datos = JSON.parse(JSON.stringify(val));
      this.crud.add = true;
    },
  },
};
</script>
