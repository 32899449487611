<template>
  <div>
    <h4 class="text-center">Saldos en Cajas</h4>
    <v-progress-linear color="green" rounded value="100"></v-progress-linear>
    <v-data-table
      :headers="headers.caja"
      :items="Vget_caja_data"
      :items-per-page="1000"
      :loading="Vget_caja_load"
      item-key="idcaja"
      hide-default-header
      hide-default-footer
      dense
      :expanded.sync="expanded"
      show-expand
    >
      <template v-slot:item.descaja="{ item }">
        <p class="font-weight-black pa-0 ma-0">{{ item.descaja }}</p>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td :colspan="12">
          <v-data-table
            :headers="headers.caja_det"
            :items="item.cajadet"
            hide-default-footer
            dense
            :items-per-page="100"
          >
            <template v-slot:item.monto="{ item }">
              <p class="font-weight-black pa-0 ma-0">
                {{ toCurrency(item.monto) }}
              </p>
            </template>
          </v-data-table>
        </td>
      </template>
    </v-data-table>
    <h4 class="text-center">Saldos en Cuentas Bancarias</h4>
    <v-progress-linear color="yellow" rounded value="100"></v-progress-linear>
    <v-data-table
      :headers="headers.cuentabanco"
      :items="Vget_cuentabanco_data"
      :items-per-page="1000"
      :loading="Vget_cuentabanco_load"
      hide-default-footer
      dense
    >
      <template v-slot:item.desccuentabanco="{ item }">
        <p class="font-weight-black pa-0 ma-0">{{ item.desccuentabanco }}</p>
      </template>
      <template v-slot:item.monto="{ item }">
        <p class="font-weight-black pa-0 ma-0">{{ toCurrency(item.monto) }}</p>
      </template>
    </v-data-table>
    <h4 class="text-center">Saldos en Tarjeta de Credito</h4>
    <v-progress-linear color="orange" rounded value="100"></v-progress-linear>
    <v-data-table
      :headers="headers.tarjetacredito"
      :items="Vget_tarjetacredito_data"
      :items-per-page="1000"
      :loading="Vget_tarjetacredito_load"
      hide-default-footer
      dense
    >
      <template v-slot:item.desccuentabanco="{ item }">
        <p class="font-weight-black pa-0 ma-0">{{ item.descripcion }}</p>
      </template>
      <template v-slot:item.utilizado="{ item }">
        <p class="font-weight-black pa-0 ma-0">
          {{ toCurrency(item.utilizado) }}
        </p>
      </template>
      <template v-slot:item.credito="{ item }">
        <p class="font-weight-black pa-0 ma-0">
          {{ toCurrency(item.credito - item.utilizado) }}
        </p>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { currency } from "@/services/util/number.service";
export default {
  data() {
    return {
      expanded: [],
      headers: {
        cuentabanco: [
          {
            text: "Cuenta Bancaria",
            sortable: false,
            align: "left",
            value: "desccuentabanco",
          },
          {
            text: "Moneda",
            sortable: false,
            align: "left",
            value: "idmoneda.descmoneda",
          },
          {
            text: "Saldo",
            sortable: false,
            align: "right",
            value: "monto",
          },
        ],
        tarjetacredito: [
          {
            text: "Tarjeta Credito",
            sortable: false,
            align: "left",
            value: "descripcion",
          },
          {
            text: "Moneda",
            sortable: false,
            align: "left",
            value: "idmoneda.descmoneda",
          },
          {
            text: "Deuda",
            sortable: false,
            align: "right",
            value: "utilizado",
          },
          {
            text: "Saldo",
            sortable: false,
            align: "right",
            value: "credito",
          },
        ],
        caja: [
          {
            text: "Caja",
            sortable: false,
            value: "descaja",
          },
          { text: "", align: "right", value: "data-table-expand" },
        ],
        caja_det: [
          {
            text: "Moneda",
            value: "idmoneda.descmoneda",
            align: "left",
          },
          {
            text: "Saldo",
            value: "monto",
            align: "right",
          },
        ],
      },
    };
  },
  mounted() {
    this.getBalance();
  },
  watch: {
    Vget_caja_data(val) {
      this.expanded = JSON.parse(JSON.stringify(val));
    },
    $route(val) {
      if (val.path === "/cajabanco") {
        this.getBalance();
      }
    },
  },
  computed: {
    ...mapGetters("caja", ["Vget_caja_data", "Vget_caja_load"]),
    ...mapGetters("cuentabanco", [
      "Vget_cuentabanco_data",
      "Vget_cuentabanco_load",
    ]),
    ...mapGetters("tarjetacredito", [
      "Vget_tarjetacredito_data",
      "Vget_tarjetacredito_load",
    ]),
  },
  methods: {
    ...mapActions("caja", ["Vact_caja_data"]),
    ...mapActions("cuentabanco", ["Vact_cuentabanco_data"]),
    ...mapActions("tarjetacredito", ["Vact_tarjetacredito_data"]),
    toCurrency(val) {
      return currency(val);
    },
    getBalance() {
      this.Vact_caja_data();
      this.Vact_cuentabanco_data();
      this.Vact_tarjetacredito_data();
    },
  },
};
</script>

<style>
</style>