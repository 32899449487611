import { UserService, AuthenticationError } from "@/services/auth/user.service";
import router from "@/router";
import { get } from "@/services/api/api.service";
import {
  savePermission,
  getPermission,
} from "@/services/storage/permissionStorage.service";
import { PUEDE_AGREGAR } from "@/constants/permissions";
import { COTIZACION } from "@/constants/forms";
export default {
  async login({ commit }, { email, password, recordar }) {
    commit("loginRequest");

    try {
      const token = await UserService.login(email, password, recordar);
      const permisos = await get("/apigetpermission/");
      savePermission(permisos);
      commit("loginSuccess", token);
      if (getPermission(COTIZACION).permiso[PUEDE_AGREGAR]) {
        const cotizacion = await get("/apicotizacion/?band=day");
        if (cotizacion[0] === undefined) {
          if (router.history.current.path != "/cotizacion/add")
            router.push("/cotizacion/add");
        } else {
          if (router.history.current.path != "/")
            router.push(router.history.current.query.redirect);
        }
      } else {
        router.push(router.history.current.query.redirect || "/");
      }
      return true;
    } catch (e) {
      router.push(router.history.current.query.redirect || "/");
      if (e instanceof AuthenticationError) {
        commit("loginError", {
          errorCode: e.errorCode,
          errorMessage: e.message,
        });
      }

      return false;
    }
  },

  logout({ commit }) {
    UserService.logout();
    commit("logoutSuccess");
    //router.push('/login')
    location.reload();
  },
  refreshToken({ commit, state }) {
    // If this is the first time the refreshToken has been called, make a request
    // otherwise return the same promise to the caller
    if (!state.refreshTokenPromise) {
      const p = UserService.refreshToken();
      commit("refreshTokenPromise", p);

      // Wait for the UserService.refreshToken() to resolve. On success set the token and clear promise
      // Clear the promise on error as well.
      p.then(
        (response) => {
          commit("refreshTokenPromise", null);
          commit("loginSuccess", response);
        },
        () => {
          commit("refreshTokenPromise", null);
        }
      );
    }

    return state.refreshTokenPromise;
  },
};
