<template>
  <div>
    <c-alert-permission v-model="permission"></c-alert-permission>
    <div v-if="permission.can_view">
      <v-form ref="form">
        <v-card-text>
          <c-overlay :value="data.isLoading"></c-overlay>
          <v-row dense>
            <v-col cols="12" sm="2">
              <v-autocomplete
                :items="Vget_campana_data"
                item-text="descripcion"
                item-value="idcampana"
                multiple
                :rules="[(v) => !!v.length > 0 || 'Seleccione una Campaña']"
                autocomplete="off"
                :hide-details="true"
                :loading="Vget_campana_load"
                label="Seleccione la Campaña"
                v-model="parameters.idcampana"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="2">
              <v-autocomplete
                :items="tiporeporte"
                item-text="descripcion"
                item-value="id"
                :rules="validar"
                autocomplete="off"
                :hide-details="true"
                label="Tipo de Filtro"
                v-model="parameters.filtro"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="2">
              <v-autocomplete
                :items="commodities"
                item-text="commodity"
                return-object
                :rules="validar"
                autocomplete="off"
                :hide-details="true"
                @change="changePrice()"
                :loading="Vget_commodities_load"
                label="Tipo de Cultivo"
                v-model="parameters.commoditie"
              ></v-autocomplete>
            </v-col>
            <v-col cols="8" sm="2">
              <vue-text-currency
                :rules="true"
                :hide-details="true"
                :outlined="false"
                :dense="false"
                label="Precio por Tn"
                v-model="parameters.preciotn"
              />
            </v-col>
            <v-spacer></v-spacer>
            <v-btn
              class="mt-4"
              text
              fab
              small
              color="primary"
              id="aceptar"
              outlined
              @click="descargarDatos()"
            >
              <v-icon>cloud_download</v-icon>
            </v-btn>
          </v-row>
        </v-card-text>
      </v-form>
      <v-card>
        <v-row dense class="mx-2">
          <v-col cols="12" md="6">
            <vue-text-field
              label="Gasto Total Actual"
              v-model="total.gasto"
              suffix="$$"
              readonly
            ></vue-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <vue-text-field
              label="Para cubrir los gastos necesita producir"
              v-model="total.produccion"
              suffix="Kg"
              readonly
            ></vue-text-field>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="data.response"
          :items-per-page="100000"
          class="elevation-1"
        >
          <template v-slot:[`item.medida_value`]="{ item }">
            <div>
              {{ toCurrency(item.medida_value) }}
            </div>
          </template>
          <template v-slot:[`item.value`]="{ item }">
            <div class="font-weight-black pa-0 ma-0">
              {{ toCurrency(item.value) }}
            </div>
          </template>
          <template v-slot:[`item.estimacion`]="{ item }">
            <div class="font-weight-black pa-0 ma-0">
              {{ convertKg(item.estimacion) }} Kg
            </div>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { db } from "@/services/db.service";
import { currency } from "@/services/util/number.service";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { PUEDE_GENERAR_REPORTE } from "@/constants/permissions";
import { REPORTE_AGRICULTURA } from "@/constants/forms";
export default {
  data() {
    return {
      headers: [
        {
          text: "Descripcion",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Cantidad Usada", value: "medida_value", align: "right" },
        { text: "Unidad de Medida", value: "medida" },
        { text: "Gasto Actual", value: "value", align: "right" },
        { text: "Necesita Producir", value: "estimacion", align: "right" },
      ],
      data: {
        response: [],
        isLoading: false,
      },
      total: {
        gasto: 0,
        produccion: 0,
      },
      parameters: {
        idcampana: [],
        filtro: "",
        commoditie: "",
        preciotn: "",
      },
      validar: [(v) => !!v || "Este campo es obligatorio"],
      tiporeporte: [
        {
          id: "item",
          descripcion: "Insumo",
        },
        {
          id: "categoria",
          descripcion: "Categoria",
        },
        {
          id: "tipo",
          descripcion: "Tipo Item",
        },
      ],
      commodities: [],
      permission: {
        can_view: false,
      },
    };
  },
  computed: {
    ...mapGetters("campana", ["Vget_campana_data", "Vget_campana_load"]),
    ...mapGetters("dashboard", [
      "Vget_commodities_data",
      "Vget_commodities_load",
    ]),
  },
  watch: {
    Vget_commodities_data(val) {
      this.commodities = [];
      this.commodities = JSON.parse(JSON.stringify(val));
    },
  },
  created() {
    this.permission.can_view = getPermission(REPORTE_AGRICULTURA).permiso[
      PUEDE_GENERAR_REPORTE
    ];
  },
  mounted() {
    this.Vact_campana_data();
    this.Vact_commodities_data();
  },
  methods: {
    ...mapActions("campana", ["Vact_campana_data"]),
    ...mapActions("dashboard", ["Vact_commodities_data"]),
    toCurrency(value) {
      return currency(value);
    },
    convertKg(value) {
      return currency(value * 1000);
    },
    changePrice() {
      if (this.parameters.commoditie.server[1])
        this.parameters.preciotn = this.parameters.commoditie.server[1].data[0].price.tn;
    },
    calcularTotales(value) {
      let gasto = 0;
      let produccion = 0;
      value.forEach((x) => {
        gasto = gasto + x.value;
        produccion = produccion + x.estimacion;
      });
      this.total = {
        gasto: this.toCurrency(gasto),
        produccion: this.convertKg(produccion),
      };
    },
    obtenerUrl() {
      const campana = this.parameters.idcampana;
      const preciotn = this.parameters.preciotn;
      const filtro = this.parameters.filtro;
      let url = "";
      if (campana.length === 1) {
        url = `apiestimacion_costos/${filtro}/?costo=${preciotn}&campana=${campana[0]}`;
        return url;
      }
      url = `apiestimacion_costos/${filtro}/?costo=${preciotn}`;
      campana.forEach((camp, i) => {
        if (i === 0) return (url = url + `&campana=${camp}`);
        url = url + "-" + camp;
      });
      return url;
    },
    async descargarDatos() {
      if (!this.$refs.form.validate()) return null;
      this.data.isLoading = true;
      try {
        const response = await db.get(this.obtenerUrl());
        this.calcularTotales(response);
        this.data.response = JSON.parse(JSON.stringify(response));
      } catch (error) {
        console.log(error);
      }
      this.data.isLoading = false;
    },
  },
};
</script>

<style></style>
