export default {
  agromap_coordinates: [],
  agromap_coordinates_list: [],
  agromap_coordinates_view: [],
  agromap_tile: {},
  agromap_filter_data: null,
  agromap_load: false,
  integralimages: [],
  integralload: false,
}


