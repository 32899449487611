<template>
  <div>
    <InputAutocomplete
      :label="label"
      item-value="idracion"
      item-text="obsracion"
      :id="id"
      ref="input"
      :outlined="outlined"
      :dense="dense"
      :readonly="readonly"
      :value="value"
      :return-object="returnObject"
      :items="Vget_racion_data"
      :loading="Vget_racion_load"
      :permission="create ? permission : false"
      @sync-input="formulario.search = $event"
      @create="formulario.crud = true"
      @input="$emit('input', $event)"
      @change="$emit('change')"
    />
    <Crud
      v-model="formulario.crud"
      v-if="formulario.crud"
      :sync-input="formulario.search"
      @sync-input="syncInputHandler($event)"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import InputAutocomplete from "@/ncomponents/InputAutocomplete";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { RACION } from "@/constants/forms";
import { PUEDE_AGREGAR } from "@/constants/permissions";
import Crud from "./Create";
export default {
  components: {
    InputAutocomplete,
    Crud,
  },
  props: {
    value: [String, Object, Number],
    dense: Boolean,
    readonly: Boolean,
    outlined: Boolean,
    returnObject: Boolean,
    create: Boolean,
    id: String,
    label:{
      type: String,
      default: 'Racion'
    },
    fetch: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
     formulario: {
      crud: false,
      search: "",
    },
  }),

  mounted() {
    if (this.fetch) this.Vact_racion_data();
  },

  computed: {
    ...mapGetters("racion", [
      "Vget_racion_load",
      "Vget_racion_data",
    ]),
    permission: () =>  getPermission(RACION).permiso[PUEDE_AGREGAR],
  },

  methods: {
    ...mapActions("racion", ["Vact_racion_data"]),
    syncInputHandler(event) {
      if (this.returnObject) this.$emit("input", event);
      if (!this.returnObject) this.$emit("input", event.idracion);
      this.$emit("next-focus");
    },
    focus() {
      this.$refs.input.focus();
    },
    isMenuActive() {
      this.$refs.input.isMenuActive = false;
    },
  },
};
</script>

<style></style>
