<template>
  <v-dialog :value="value" persistent max-width="700">
    <Information :value="value" @input="$emit('input', $event)" />
  </v-dialog>
</template>

<script>
import Information from "./Information";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Information,
  },
  watch:{
    $route(){
      this.$emit('input', false);
    }
  }
};
</script>

<style>
</style>