export default {
  pedidoventa_data: [],
  pedidoventa_presupuesto: [],
  pedidoventa_reporte: [],
  pedidoventa_finalizado: [],
  pedidoventa_byId: null,
  pedidoventa_load: false,
  pedidoventa_save_load: false
}


