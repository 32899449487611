<template>
  <div>
    <InputAutocomplete
      label="Seleccione tipo tipoenfermedad"
      item-value="idtipoenfermedad"
      item-text="descenfermedad"
      :id="id"
      ref="input"
      dense
      outlined
      :value="value"
      :items="getTipoEnfermedad"
      :loading="isLoading"
      :return-object="returnObject"
      @input="$emit('input', $event)"
      @sync-input="formulario.search = $event"
      @create="formulario.crud = true"
      :permission="permission"
    />
    <Crud
      v-if="formulario.crud"
      v-model="formulario.crud"
      :sync-input="formulario.search"
      @sync-input="syncInputHandler($event)"
    />
  </div>
</template>

<script>
import InputAutocomplete from "@/ncomponents/InputAutocomplete";
import Crud from "./Create";
import { mapActions, mapGetters } from "vuex";
import { TIPO_ENFERMEDAD } from "@/constants/forms";
import { PUEDE_AGREGAR } from "@/constants/permissions";
import { getPermission } from "@/services/storage/permissionStorage.service";
export default {
  props: {
    id: String,
    value: [String, Object, Number],
    'return-object': Boolean,
  },
  components: {
    InputAutocomplete,
    Crud,
  },
  data: () => ({
    formulario: {
      crud: false,
      search: "",
    },
  }),
  computed: {
    ...mapGetters("tipoenfermedad", ["getTipoEnfermedad", "isLoading"]),
    permission() {
      return getPermission(TIPO_ENFERMEDAD).permiso[PUEDE_AGREGAR];
    },
  },
  mounted() {
    this.fetchTipoEnfermedad();
  },
  methods: {
    ...mapActions("tipoenfermedad", [
      "fetchTipoEnfermedadId",
      "fetchTipoEnfermedad",
    ]),
    syncInputHandler(event) {
      if (this.returnObject) this.$emit("input", event);
      if (!this.returnObject) this.$emit("input", event.idtipoenfermedad);
      this.$emit("nextfocus");
      this.fetchTipoEnfermedad();
    },
    isMenuActive() {
      this.$refs.input.isMenuActive = false;
    },
    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>

<style></style>
