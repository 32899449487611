export default {
    async rol_create({commit},request){
        commit('rol_create',request);
    },
    async rol_update({commit},request){
        commit('rol_update',request);
    },
    async rol_reload({commit},request){
        commit('rol_reload',request);
    },
    async rol_show({commit},request){
        commit('rol_show',request);
    },
}
