export default {
   causanoins_data(state, request) {
    state.causanoins_data = request;
  },
  causanoins_load(state, request) {
    state.causanoins_load = request;
  },
  causanoins_save_load(state, request) {
    state.causanoins_save_load = request;
  },
}