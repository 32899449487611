import jsPDF from "jspdf";
import { Workbook } from "exceljs";
import * as fs from "file-saver";
import { current_date } from "@/services/util/date.service";

import store from "@/store";
const exportTo = {
  convertToCSV(objArray) {
    const array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str = "";

    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line != "") line += "\t";

        line += array[i][index];
      }

      str += line + "\r\n";
    }

    return str;
  },
  csv(headers, items, fileName) {
    if (headers) {
      items.unshift(headers);
    }

    const jsonObject = JSON.stringify(items);

    const csv = this.convertToCSV(jsonObject);

    const exportName = fileName + ".xls" || "export.xls";

    const blob = new Blob([csv], {
      type: "text/xls;charset=utf-8;",
    });
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, exportName);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportName);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  },
  xlsx(data, title) {
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("HOJA 1");
    worksheet.addRow(Object.keys(data[0]));
    data.forEach((x) => {
      worksheet.addRow(Object.values(x));
    });
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(blob, `${title || 'EXCEL'} ${current_date()}.xlsx`);
    });
  },
  pdftable(head, body, title, theme) {
    var doc = new jsPDF('1');
    doc.autoTable({
      head: head,
      //styles: { overflow: 'ellipsize', cellWidth: 'wrap', fontSize: 10 },
      //columnStyles: { 2: {cellWidth: 'auto'}},
      body: body,
      //tableWidth: "wrap",
      theme: theme || "grid",
      rowPageBreak: 'auto',
      bodyStyle: { valign: 'top' }
    });
    doc.save(title + " " + current_date() + ".pdf" || "table.pdf");
  },
  PdfLote(head, body, title, columnStyles, data) {
    var doc = new jsPDF();
    let pageWidth = doc.internal.pageSize.width;
    let fechaWidth = doc.getTextWidth(data.fecha) - 13;
    doc.setFontSize(10);
    store.getters["empresa/Vget_empresa_active"].forEach((empresa) => {
      if (empresa.est_emp_tam_usu)
        return doc.text(empresa.idempresa.descempresa, 15, 20);
    });
    doc.text(data.fecha, pageWidth - fechaWidth, 20);
    doc.setFontSize(14);
    doc.text(title, doc.internal.pageSize.width / 2, 30, { align: "center" });
    doc.autoTable({
      head: head,
      body: body,
      headStyles: { fillColor: 0 },
      columnStyles: columnStyles,
      startY: 35,
      theme: "grid",
    });
    doc.save(title + " " + current_date() + ".pdf");
  },
};
export { exportTo };
