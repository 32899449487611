export default [
    {
        descripcion: "Campaña",
        id: "campana",
    },
    {
        descripcion: "Zona",
        id: "lote",
    },
    {
        descripcion: "Lote",
        id: "campo",
    },
    {
        descripcion: "Producto",
        id: "siembra",
    },
    {
        descripcion: "Categoria",
        id: "categoria",
    },
]