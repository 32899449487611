<template>
  <div>
    <div v-if="crud.add">
      <TarjetaCreate
        v-model="crud.add"
        :datos="crud.datos"
        @datos="crud.datos = $event"
      />
    </div>

    
      <v-alert type="error" v-if="permission_alert"
        >Error!!! Este usuario no tiene permisos en esta ventana</v-alert
      >
      <v-toolbar flat dense>
        <v-toolbar-title>{{ titulo }}</v-toolbar-title>
        <widgets-Favorite ruta="/tarjetacredito" :formulario="titulo" />
        <v-divider class="mx-2" inset vertical></v-divider>

        <v-spacer></v-spacer>

        <c-text-table-search
          v-if="permission.can_view"
          v-model="search"
        ></c-text-table-search>
        <v-toolbar-items>
          <c-btn-table-add
            @click="crud.add = true"
            v-if="permission.can_add"
          ></c-btn-table-add>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="Vget_tarjetacredito_data"
        :loading="Vget_tarjetacredito_load"
        :search="search"
        class="elevation-1"
        v-if="permission.can_view"
      >
        <template v-slot:item.estado="{ item }">
          <v-chip :color="item.estado ? 'primary' : 'red'" dark>{{
            item.estado ? "Activo" : "Inactivo"
          }}</v-chip>
        </template>
        <template v-slot:item.credito="{ item }">
          <div>{{ toCurrency(item.credito) }}</div>
        </template>
        <template v-slot:item.accion="props">
          <v-icon
            class="mr-2"
            @click="mupdate(props.item)"
            v-if="permission.can_update"
            >edit</v-icon
          >
          <v-icon
            small
            @click="meliminar(props.item)"
            v-if="permission.can_delete"
            >delete</v-icon
          >
        </template>
        <template v-slot:no-data>
          <v-alert :value="true" color="info" icon="info" outlined
            >No existe ningun registro</v-alert
          >
        </template>
      </v-data-table>
    <v-dialog v-model="crud.delete" persistent max-width="350">
      <v-card>
        <v-card-title>
          <v-icon left>info</v-icon>
          <span class="title font-weight-light">Ventana de Confirmacion</span>
        </v-card-title>
        <v-card-text
          >Desea eliminar el cheque Nro {{ objeto.descripcion }}?</v-card-text
        >
        <v-card-actions>
          <v-btn color="red" text @click="eliminarcancelar">
            <v-icon>clear</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="meliminarguardar()">
            <v-icon>done</v-icon>
          </v-btn>
        </v-card-actions>
        <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { currency } from "@/services/util/number.service";

import TarjetaCreate from "@/views/CajaBanco/TarjetaCredito/Create";

import { TARJETA_CREDITO } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";

export default {
  components: {
    TarjetaCreate,
  },
  data() {
    return {
      overlay: false,
      search: "",
      headers: [
        { text: "Codigo", align: "left", value: "idtarjeta" },
        { text: "Descripcion", align: "left", value: "descripcion" },
        { text: "Banco", align: "left", value: "idbanco.descbanco" },
        { text: "Linea de Credito", align: "right", value: "credito" },
        { text: "Estado", align: "right", value: "estado" },
        { text: "Accion", value: "accion", sortable: false, align: "right" },
      ],
      titulo: TARJETA_CREDITO,
      objeto: "",
      index: "",
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
      crud: {
        add: false,
        view: false,
        delete: false,
        datos: null,
      },
    };
  },

  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.Vact_tarjetacredito_data();
    const permission = getPermission(TARJETA_CREDITO).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  computed: {
    ...mapGetters("tarjetacredito", [
      "Vget_tarjetacredito_data",
      "Vget_tarjetacredito_load",
    ]),
    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },
  methods: {
    ...mapActions("tarjetacredito", [
      "Vact_tarjetacredito_data",
      "Vact_tarjetacredito_del",
    ]),
    toCurrency(val) {
      return currency(val);
    },
    meliminar(item) {
      this.objeto = Object.assign({}, item);
      this.crud.delete = true;
    },

    eliminarcancelar() {
      this.index = "";
      this.objeto = "";
      this.crud.delete = false;
    },
    mupdate(val) {
      this.crud.datos = JSON.parse(JSON.stringify(val));
      this.crud.add = true;
    },
    rowselect(val) {
      this.crud.datos = JSON.parse(JSON.stringify(val));
      this.crud.view = true;
    },
    async meliminarguardar() {
      this.overlay = true;
      const id = this.objeto.idtarjeta;
      const a = await this.Vact_tarjetacredito_del(id);
      if (a.estado === true) {
        this.$notify(a.info);
        this.crud.delete = false;
        this.eliminarcancelar();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.overlay = false;
    },
  },
};
</script>