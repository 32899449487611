import { db } from "@/services/db.service";
export default {
    async causarechazo_create({commit},request){
        commit('causarechazo_create',request);
    },
    async causarechazo_update({commit},request){
        commit('causarechazo_update',request);
    },
    async causarechazo_show({commit},request){
        commit('causarechazo_show',request);
    },
    async Vact_causarechazo_data({commit}){
        commit('causarechazo_load',true);
        try {
            const data = await db.get('/apicausarechazo/');
            commit('causarechazo_data',data);
            commit('causarechazo_load',false);
            return true
        } catch (e) {
            commit('causarechazo_load',false);
            return false
        } 
    },
    async Vact_causarechazo_del({dispatch},id){
        try {
            const data = await db.delete('/apicausarechazo/'+id);
            dispatch('Vact_causarechazo_data');
            return data
        } catch (e) {
            return false
        } 
    },
    async Vact_causarechazo_save({commit},data){
        commit('causarechazo_save_load',true);
        try {
            const resp = await db.save('/apicausarechazo/',data);
            commit('causarechazo_save_load',false);
            return resp;
        } catch (e) {
            commit('causarechazo_save_load',false);
            return e
        } 
    },
    async Vact_causarechazo_update({commit},{id, data}){
        commit('causarechazo_save_load',true);
        try {
            const resp = await db.update('/apicausarechazo/'+id+'/',data);
            commit('causarechazo_save_load',false);
            return resp;
        } catch (e) {
            commit('causarechazo_save_load',false);
            return e
        } 
    },
}
