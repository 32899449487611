<template>
  <v-btn @click="$emit('click')" color="primary" text fab x-small>
    <v-icon >visibility</v-icon>
  </v-btn>
</template>

<script>
export default {};
</script>

<style>
</style>