import { db } from "@/services/db.service";
export default {

    async Vact_cosecha_data({commit},id){
        commit('cosecha_load',true);
        try {
            const data = await db.get('/apicosecha/'+id+'/');
            commit('cosecha_data',data);
            commit('cosecha_load',false);
            return true
        } catch (e) {
            commit('cosecha_load',false);
            return false
        } 
    },
    async Vact_cosecha_del({commit},{id,params}){
        try {
            commit('cosecha_load',true);
            const data = await db.delete('/apicosecha/'+id+'/',params);
            commit('cosecha_load',false);
            return data
        } catch (e) {
            return false
        } 
    },
    async Vact_cosecha_save({commit},data){
        commit('cosecha_save_load',true);
        try {
            const resp = await db.save('/apicosecha/',data);
            commit('cosecha_save_load',false);
            return resp;
        } catch (e) {
            commit('cosecha_save_load',false);
            return e
        } 
    },
    async Vact_cosecha_update({commit},{id, data}){
        commit('cosecha_save_load',true);
        try {
            const resp = await db.update('/apicosecha/'+id+'/',data);
            commit('cosecha_save_load',false);
            return resp;
        } catch (e) {
            commit('cosecha_save_load',false);
            return e
        } 
    },
}
