<template>
  <div>
    <v-row row dense>
      <v-col cols="12" md="4" sm="4">
        <vue-text-currency
          :error="true"
          :value="montoCobrar"
          :readonly="true"
          label="Total a Cobrar"
        ></vue-text-currency>
      </v-col>
      <v-col cols="12" md="4" sm="4">
        <vue-text-currency
          :readonly="true"
          :value="montoEntregado"
          label="Monto Entregado"
        ></vue-text-currency>
      </v-col>
      <v-col cols="12" md="4" sm="4">
        <vue-text-currency
          :readonly="true"
          :value="diferenciaMonto"
          label="Vuelto"
        ></vue-text-currency>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row dense v-if="!readonly">
      <v-spacer></v-spacer>
      <v-menu transition="slide-x-transition" bottom right>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ma-1"
            color="primary"
            outlined
            small
            text
            v-bind="attrs"
            v-on="on"
            >Agregar Metodo de Pago</v-btn
          >
        </template>

        <v-list>
          <v-list-item @click="addCaja()">
            <v-list-item-title>Caja Local</v-list-item-title>
          </v-list-item>
          <v-list-item @click="addCheque()">
            <v-list-item-title>Cheque</v-list-item-title>
          </v-list-item>
          <v-list-item @click="addTransferencia()">
            <v-list-item-title>Transferencia Bancaria</v-list-item-title>
          </v-list-item>
          <v-list-item @click="addTarjetaCredito()">
            <v-list-item-title>Tarjeta de Credito</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-row>
    <v-row dense v-for="(item, i) of pago.caja" :key="'c' + i">
      <v-col cols="12" sm="2">
        <h4 class="mt-2">Caja</h4>
      </v-col>

      <v-col cols="12" sm="3">
        <v-autocomplete
          :readonly="readonly"
          :items="origenFondo.caja"
          item-text="descaja"
          item-value="idcaja"
          outlined
          dense
          :rules="validar"
          autocomplete="off"
          :loading="Vget_caja_load"
          label="Caja de Origen"
          v-model="pago.caja[i].idcaja.idcaja"
          @keyup.native="setCaja()"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="3">
        <vue-text-currency
          :readonly="readonly"
          :rules="true"
          label="Monto"
          @keyup.native="setCaja()"
          v-model="pago.caja[i].monto"
        />
      </v-col>
      <v-spacer></v-spacer>
      <v-btn
        class="ma-1"
        color="red"
        outlined
        text
        @click="deleteCaja(i)"
        v-if="!readonly"
      >
        <v-icon>clear</v-icon>
      </v-btn>
    </v-row>
    <v-row dense v-for="(item, i) of pago.cheque" :key="'ch' + i">
      <v-col cols="12" sm="2">
        <h4 class="mt-2">Cheque</h4>
      </v-col>
      <v-col cols="12" sm="3">
        <v-autocomplete
          :readonly="readonly"
          :items="chequeEmitido"
          return-object
          outlined
          dense
          :rules="validar"
          autocomplete="off"
          :loading="Vget_emisioncheque_load"
          v-model="pago.cheque[i].idcheque_emitido"
          label="Numero de Cheque"
          @change="
            setCheque(),
              (pago.cheque[i].monto = pago.cheque[i].idcheque_emitido.monto)
          "
        >
          <template slot="selection" slot-scope="data">
            <v-list-item-title v-html="`${data.item.nro}`"></v-list-item-title>
          </template>
          <template slot="item" slot-scope="data">
            <v-list-item-content>
              <v-list-item-title
                v-html="
                  `Nro: ${data.item.nro} - Chequera: ${data.item.idcheque.descripcion}`
                "
              ></v-list-item-title>
              <v-list-item-subtitle
                v-html="`Saldo cheque: ${data.item.monto}`"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </template>
          <template v-if="permission.emisioncheque.can_add" v-slot:no-data>
            <v-list-item @click="formularios.emisioncheque = true" dense>
              <v-list-item-content>
                <v-list-item-title
                  >No existe ningun Cheque. Click para
                  agregar</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>
        <div v-if="formularios.emisioncheque">
          <FormCheque v-model="formularios.emisioncheque" />
        </div>
      </v-col>
      <v-col cols="12" sm="3">
        <vue-text-currency
          :readonly="true"
          label="Monto"
          :rules="true"
          @keyup.native="setCheque()"
          v-model="pago.cheque[i].monto"
        />
      </v-col>

      <v-spacer></v-spacer>
      <v-btn
        class="ma-1"
        color="red"
        outlined
        text
        @click="deleteCheque(i)"
        v-if="!readonly"
      >
        <v-icon>clear</v-icon>
      </v-btn>
    </v-row>
    <v-row dense v-for="(item, i) of pago.cuentabanco" :key="'ct' + i">
      <v-col cols="12" sm="2">
        <h5 class="mt-1">Transferencia Bancaria</h5>
      </v-col>

      <v-col cols="12" sm="3">
        <v-autocomplete
          :readonly="readonly"
          :items="origenFondo.cuentabanco"
          item-text="desccuentabanco"
          item-value="idcuentabanco"
          outlined
          dense
          :rules="validar"
          autocomplete="off"
          :loading="Vget_cuentabanco_load"
          v-model="pago.cuentabanco[i].idcuentabanco.idcuentabanco"
          label="Cuenta Bancaria"
          @change="setTransferencia()"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="3">
        <vue-text-currency
          :readonly="readonly"
          label="Monto"
          :rules="true"
          @keyup.native="setTransferencia()"
          v-model="pago.cuentabanco[i].monto"
        />
      </v-col>
      <v-spacer></v-spacer>
      <v-btn
        class="ma-1"
        color="red"
        outlined
        text
        @click="deleteTransferencia(i)"
        v-if="!readonly"
      >
        <v-icon>clear</v-icon>
      </v-btn>
    </v-row>
    <v-row dense v-for="(item, i) of pago.tarjeta_credito" :key="'tc' + i">
      <v-col cols="12" sm="2">
        <h4 class="mt-2">Tarjeta de Credito</h4>
      </v-col>
      <v-col cols="12" sm="3">
        <v-autocomplete
          :readonly="readonly"
          :items="Vget_tarjetacredito_data"
          return-object
          outlined
          dense
          item-text="descripcion"
          :rules="validar"
          autocomplete="off"
          :loading="Vget_tarjetacredito_load"
          v-model="pago.tarjeta_credito[i].idtarjeta"
          label="Tarjeta de Credito"
          @change="setTarjetaCredito()"
        >
          <template slot="selection" slot-scope="data">
            <v-list-item-title
              v-html="`${data.item.descripcion}`"
            ></v-list-item-title>
          </template>
          <template slot="item" slot-scope="data">
            <v-list-item-content>
              <v-list-item-title
                v-html="`${data.item.descripcion}`"
              ></v-list-item-title>
              <v-list-item-subtitle
                v-html="
                  `Credito Disponible: ${
                    data.item.credito - data.item.utilizado
                  }`
                "
              ></v-list-item-subtitle>
            </v-list-item-content>
          </template>
          <template v-if="permission.tarjetacredito.can_add" v-slot:no-data>
            <v-list-item @click="formularios.tarjetacredito = true" dense>
              <v-list-item-content>
                <v-list-item-title
                  >No existe ningun cheque. Click para emitir</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>
        <div v-if="formularios.tarjetacredito">
          <TarjetaCreate v-model="formularios.tarjetacredito" />
        </div>
      </v-col>
      <v-col cols="12" sm="2">
        <vue-text-currency
          label="Monto"
          :readonly="readonly"
          :rules="true"
          @keyup.native="setTarjetaCredito()"
          v-model="pago.tarjeta_credito[i].monto"
        />
      </v-col>
      <v-col cols="12" sm="2">
        <vue-text-field
          label="Voucher"
          :readonly="readonly"
          :rules="true"
          @keyup.native="setTarjetaCredito()"
          v-model="pago.tarjeta_credito[i].voucher"
        />
      </v-col>
      <v-col cols="12" sm="2">
        <c-text-date
          label="Fecha Vencimiento"
          outlined
          dense
          :readonly="readonly"
          @keyup.native="setTarjetaCredito()"
          v-model="pago.tarjeta_credito[i].fechavence"
        />
      </v-col>
      <v-spacer></v-spacer>
      <v-btn
        class="ma-1"
        color="red"
        outlined
        text
        @click="deleteTarjetaCredito(i)"
        v-if="!readonly"
      >
        <v-icon>clear</v-icon>
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { sum_days } from "@/services/util/date.service";
import TarjetaCreate from "@/views/CajaBanco/TarjetaCredito/Create";
import FormCheque from "@/views/CajaBanco/EmisionCheque/Create";
import { EMITIR_CHEQUE, TARJETA_CREDITO } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";

export default {
  components: {
    TarjetaCreate,
    FormCheque
  },
  props: {
    montoCobrar: {
      type: [Number, String],
      default: 0,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    datos: {
      type: Object,
      default: null,
    },
    idmoneda: {
      type: Number,
      default: null,
    },
    crudMode: {
      type: String,
      default: "Compra",
    },
  },
  watch: {
    Vget_cuentabanco_data() {
      this.obtener_cuentabanco();
    },
    Vget_caja_data() {
      this.obtener_caja();
    },
    idmoneda() {
      this.obtener_cuentabanco();
    },
    montoCobrar(val) {
      if (this.pago.caja.length > 0) {
        this.pago.caja[0].monto = val;
        this.pago.caja[0].idcaja.idcaja = this.Vget_caja_data[0].idcaja;
        this.setCaja();
      }
    },
    montoEntregado() {
      this.setCaja();
      this.setTransferencia();
      this.setCheque();
      this.setTarjetaCredito();
    },
  },
  computed: {
    ...mapGetters("caja", ["Vget_caja_data", "Vget_caja_load"]),
    ...mapGetters("cuentabanco", [
      "Vget_cuentabanco_data",
      "Vget_cuentabanco_load",
    ]),
    ...mapGetters("emisioncheque", [
      "Vget_emisioncheque_data_pagado",
      "Vget_emisioncheque_load",
    ]),
    ...mapGetters("tarjetacredito", [
      "Vget_tarjetacredito_data",
      "Vget_tarjetacredito_load",
    ]),
    chequeEmitido() {
      let cheques = [];

      this.Vget_emisioncheque_data_pagado.map((x) => {
        if (x.idcheque.idcuentabanco.idmoneda.idmoneda === this.idmoneda) {
          cheques.push(x);
        }
      });

      if (this.datos != null)
        switch (this.crudMode) {
          case "Compra":
            this.datos.compracheque.map((c) => {
              cheques.push(c.idcheque_emitido);
            });
            break;

          case "Pago":
            this.datos.ordenpagocheque.map((c) => {
              cheques.push(c.idcheque_emitido);
            });
            break;
        }

      return cheques;
    },
    montoEntregado() {
      let saldo = 0;
      this.pago.cuentabanco.forEach((cuentabanco) => {
        saldo = saldo + Number(cuentabanco.monto);
      });
      this.pago.cheque.forEach((cheque) => {
        saldo = saldo + Number(cheque.monto);
      });
      this.pago.caja.forEach((caja) => {
        saldo = saldo + Number(caja.monto);
      });
      this.pago.tarjeta_credito.forEach((tarjeta) => {
        saldo = saldo + Number(tarjeta.monto);
      });
      return saldo;
    },
    diferenciaMonto() {
      if (this.montoCobrar > 0)
        return Number(this.montoEntregado) - Number(this.montoCobrar);
      return 0;
    },
  },
  mounted() {
    const permissionC = getPermission(EMITIR_CHEQUE).permiso;
    this.permission.emisioncheque = {
      can_add: permissionC[PUEDE_AGREGAR],
      can_view: permissionC[PUEDE_LISTAR],
      can_update: permissionC[PUEDE_EDITAR],
      can_delete: permissionC[PUEDE_ELIMINAR],
    };
    const permissionT = getPermission(TARJETA_CREDITO).permiso;
    this.permission.tarjetacredito = {
      can_add: permissionT[PUEDE_AGREGAR],
      can_view: permissionT[PUEDE_LISTAR],
      can_update: permissionT[PUEDE_EDITAR],
      can_delete: permissionT[PUEDE_ELIMINAR],
    };
    this.Vact_caja_data();
    this.Vact_cuentabanco_data();
    this.Vact_emisioncheque_data_pagado();
    this.Vact_tarjetacredito_data();

    if (this.datos != null) {
      switch (this.crudMode) {
        case "Compra":
          this.pago.cheque = JSON.parse(
            JSON.stringify(this.datos.compracheque)
          );
          this.pago.caja = JSON.parse(JSON.stringify(this.datos.compracaja));
          this.pago.tarjeta_credito = JSON.parse(
            JSON.stringify(this.datos.compratarjeta)
          );
          this.pago.cuentabanco = JSON.parse(
            JSON.stringify(this.datos.comprabanco)
          );
          break;

        case "Pago":
          this.pago.cheque = JSON.parse(
            JSON.stringify(this.datos.ordenpagocheque)
          );
          this.pago.caja = JSON.parse(JSON.stringify(this.datos.ordenpagocaja));
          this.pago.tarjeta_credito = JSON.parse(
            JSON.stringify(this.datos.ordenpagotarjeta)
          );
          this.pago.cuentabanco = JSON.parse(
            JSON.stringify(this.datos.ordenpagobanco)
          );
          break;
      }
    } else {
      this.addCaja();
    }
  },
  methods: {
    ...mapActions("caja", ["Vact_caja_data"]),
    ...mapActions("cuentabanco", ["Vact_cuentabanco_data"]),
    ...mapActions("emisioncheque", ["Vact_emisioncheque_data_pagado"]),
    ...mapActions("tarjetacredito", ["Vact_tarjetacredito_data"]),

    setCaja() {
      this.$emit("caja", this.pago.caja);
    },
    setTransferencia() {
      this.$emit("cuentabanco", this.pago.cuentabanco);
    },
    setCheque() {
      this.$emit("cheque", this.pago.cheque);
    },
    setTarjetaCredito() {
      this.$emit("tarjetacredito", this.pago.tarjeta_credito);
    },
    addCaja() {
      this.pago.caja.push({
        monto: "",
        idcaja: {
          idcaja: "",
        },
      });
      this.setCaja();
    },
    deleteCaja(index) {
      this.pago.caja.splice(index, 1);
      this.setCaja();
    },
    addCheque() {
      this.pago.cheque.push({
        monto: "",
        idcheque_emitido: {
          idcheque_emitido: "",
        },
      });
      this.setCheque();
    },
    deleteCheque(i) {
      this.pago.cheque.splice(i, 1);
      this.setCheque();
    },
    addTransferencia() {
      this.pago.cuentabanco.push({
        monto: "",
        idcuentabanco: {
          idcuentabanco: "",
        },
      });
      this.setTransferencia();
    },
    deleteTransferencia(i) {
      this.pago.cuentabanco.splice(i, 1);
      this.setTransferencia();
    },
    addTarjetaCredito() {
      this.pago.tarjeta_credito.push({
        monto: "",
        fechavence: sum_days(30),
        voucher: "",
        idtarjeta: {
          idtarjeta: "",
        },
      });
      this.setTransferencia();
    },
    deleteTarjetaCredito(index) {
      this.pago.tarjeta_credito.splice(index, 1);
      this.setTarjetaCredito();
    },

    obtener_caja() {
      this.origenFondo.caja = [];
      if (this.Vget_caja_data[0] === undefined) return null;
      this.Vget_caja_data.forEach((caja) => {
        caja.cajadet.forEach((cajadet) => {
          if (this.idmoneda === cajadet.idmoneda.idmoneda) {
            this.origenFondo.caja.push(caja);
          }
        });
      });
    },
    obtener_cuentabanco() {
      this.origenFondo.cuentabanco = [];
      if (this.Vget_cuentabanco_data[0] === undefined) return null;
      this.Vget_cuentabanco_data.forEach((cuentabanco) => {
        if (this.idmoneda === cuentabanco.idmoneda.idmoneda) {
          this.origenFondo.cuentabanco.push(cuentabanco);
        }
      });
    },
  },
  data() {
    return {
      validar: [(v) => !!v || "Seleccione un campo"],
      origenFondo: {
        caja: [],
        cuentabanco: [],
      },
      pago: {
        caja: [],
        cuentabanco: [],
        cheque: [],
        tarjeta_credito: [],
      },
      formularios: {
        emisioncheque: false,
        tarjetacredito: false,
      },
      permission: {
        emisioncheque: {
          can_add: false,
        },
        tarjetacredito: {
          can_add: false,
        },
      },
    };
  },
};
</script>

<style>
</style>
