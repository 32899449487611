
export default {

  marca_update: (state) => {
    return state.marca_update
  },
  marca_show: (state) => {
    return state.marca_show
  },

  Vget_marca_data: (state) => {
    return state.marca_data
  },
  Vget_marca_load: (state) => {
    return state.marca_load
  },
  Vget_marca_save_load: (state) => {
    return state.marca_save_load
  },
}
