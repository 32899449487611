<template>
  <v-dialog :value="value" persistent max-width="650">
    <v-card>
      <v-toolbar flat>
        Seleccione la estacion meteorologica
        <v-spacer></v-spacer>
        <c-btn-crud-close @click="cancelar()"></c-btn-crud-close>
      </v-toolbar>
    </v-card>
    <v-card>
      <GoogleMapLoader
        :mapConfig="mapConfig"
        libraries="drawing"
        :class="displaySM ? `travel-map-sm` : `travel-map`"
      >
        <template slot-scope="{ google, map }">
          <SensorMap :google="google" :map="map" @seleccionado="seleccionado" />
        </template>
      </GoogleMapLoader>
    </v-card>
  </v-dialog>
</template>
<script>
import GoogleMapLoader from "@/components/map/GoogleMapLoader";
import { mapSettings } from "@/services/constants/mapSettings";
import SensorMap from "./SensorMap";
export default {
  components: {
    GoogleMapLoader,
    SensorMap,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    displaySM() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    mapConfig() {
      return {
        ...mapSettings,
        mapTypeId: "hybrid",
        fullscreenControl: false,
        mapTypeControl: false,
        center: { lat: -25.361663, lng: -55.686352 },
      };
    },
  },
  methods: {
    cancelar() {
      this.$emit("input", false);
    },
    seleccionado(data) {
      console.log(data)
      this.$emit("seleccionado", data)
    }
  },
};
</script>

<style lang="css" scoped>
.travel-map {
  position: relative;
  height: 60vh;
  z-index: 1;
}
.travel-map-sm {
  position: relative;
  height: 90vh;
  z-index: 1;
}
.position-dialog {
  position: absolute;
  top: 1px;
  width: 380px;
  height: 82vh;
  z-index: 3;
  overflow: hidden;
}
.position-sensor {
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;
  z-index: 2;
}
.position-dialog-sm {
  position: absolute;
  top: 1px;
  width: 380px;
  height: 85vh;
  z-index: 3;
  overflow: hidden;
}
.position-dialog-minimize {
  position: absolute;
  top: 1px;
  width: 380px;
  z-index: 3;
  overflow: hidden;
}

.help-dialog {
  position: absolute;
  right: 1%;
  width: 320px;
  top: 2%;
  z-index: 3;
}
</style>