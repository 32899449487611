export default [
  {
    path: '/animal',
    name: 'Animal',
    component: () => import( /* webpackChunkName: "Animal" */ '@/views/Animal/Animal/Animal.vue'),
  },
  {
    path: '/ventaganado',
    name: 'Venta de Ganado',
    component: () => import( /* webpackChunkName: "Venta" */ '@/views/Animal/Venta/Venta.vue'),
  },
  {
    path: '/termosemen',
    name: 'Termo Semen',
    component: () => import( /* webpackChunkName: "Animal" */ '@/views/Animal/TermoSemen/TermoSemen.vue'),
  },
  {
    path: '/pajuela',
    name: 'Pajuela',
    component: () => import( /* webpackChunkName: "Pajuela" */ '@/views/Animal/Pajuela/Pajuela.vue'),
  },
  {
    path: '/estadocria',
    name: 'Estadocria',
    component: () => import( /* webpackChunkName: "Estadocria" */ '@/views/Animal/Estadocria/Estadocria.vue'),
  },
  {
    path: '/preparto',
    name: 'Preparto',
    component: () => import( /* webpackChunkName: "Preparto" */ '@/views/Animal/Preparto/Preparto.vue'),
  },
  {
    path: '/secado',
    name: 'Secado',
    component: () => import( /* webpackChunkName: "Secado" */ '@/views/Animal/Secado/Secado.vue'),
  },
  {
    path: '/parto',
    name: 'Parto',
    component: () => import( /* webpackChunkName: "Parto" */ '@/views/Animal/PartoV2/Parto.vue'),
  },
  {
    path: '/tipoparto',
    name: 'Tipo parto',
    component: () => import( /* webpackChunkName: "Tipoparto" */ '@/views/Animal/Tipoparto/Tipoparto.vue'),
  },
  {
    path: '/rol',
    name: 'Rol',
    component: () => import( /* webpackChunkName: "Rol" */ '@/views/Animal/Rol/Rol.vue'),
  },
  {
    path: '/procedencia',
    name: 'Procedencia',
    component: () => import( /* webpackChunkName: "Procedencia" */ '@/views/Animal/Procedencia/Procedencia.vue'),
  },
  {
    path: '/raza',
    name: 'Raza',
    component: () => import( /* webpackChunkName: "Raza" */ '@/views/Animal/Raza/Raza.vue'),
  },
  {
    path: '/costonacimiento',
    name: 'Costo de Nacimiento',
    component: () => import( /* webpackChunkName: "Costonacimiento" */ '@/views/Animal/Costonacimiento/Costonacimiento.vue'),
  },
  {
    path: '/lote',
    name: 'Lote',
    component: () => import( /* webpackChunkName: "Lote" */ '@/views/Animal/Lote/Lote.vue'),
  },
  {
    path: '/responsable',
    name: 'Responsable',
    component: () => import( /* webpackChunkName: "Resposable" */ '@/views/Animal/Responsable/Responsable.vue'),
  },
  {
    path: '/tiporesponsable',
    name: 'Tipo Responsable',
    component: () => import( /* webpackChunkName: " Tipo Resposable" */ '@/views/Animal/Tiporesponsable/Tiporesponsable.vue'),
  },
  {
    path: '/gnconfig',
    name: 'Configuracion',
    component: () => import( /* webpackChunkName: "Configuracion" */ '@/views/Animal/Configuracion/Configuracion.vue'),
  },
  {
    path: '/reporteanimal',
    component: () => import( /* webpackChunkName: " Tipo Resposable" */ '@/views/Animal/Reporte/Root.vue'),
    children: [
      {
        path: "/",
        component: () =>
          import(
            /* webpackChunkName: "Ficha" */ "@/views/Animal/Reporte/Animal.vue"
          ),
      },
      {
        path: "avanzado",
        component: () =>
          import(
            /* webpackChunkName: "Avanzado" */ "@/views/Animal/Reporte/Avanzado.vue"
          ),
      },
      {
        path: "reproduccion",
        component: () =>
          import(
            /* webpackChunkName: "Actividad" */ "@/views/Animal/Reporte/Reproduccion.vue"
          ),
      },
      {
        path: "indiceproductivo",
        component: () =>
          import(
            /* webpackChunkName: "indiceproductivo" */ "@/views/Animal/Reporte/IndiceProductivo.vue"
          )
      }
    ]
  },
]