<template>
  <div>
    <c-alert-permission v-model="permission"></c-alert-permission>
    <div v-if="permission.can_view">
      <v-tabs v-model="tab" centered icons-and-text>
        <v-tabs-slider></v-tabs-slider>
        <v-tab>
          Por Fecha
          <v-icon>today</v-icon>
        </v-tab>
        <v-tab>
          General
          <v-icon>grass</v-icon>
        </v-tab>
      </v-tabs>
      <v-progress-linear
        v-if="isSenaveLoading"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-form ref="formFecha">
            <v-container>
              <v-row dense>
                <v-col cols="12" sm="3">
                  <c-text-date
                    label="Fecha Inicio"
                    id="fechasenaveInput1"
                    ref="fechasenaveInput1"
                    outlined
                    dense
                    v-model="fechasenave.inicio"
                    @keyup.native.enter="nextFecha(1, fechasenave.inicio)"
                  ></c-text-date>
                </v-col>
                <v-col cols="12" sm="3">
                  <c-text-date
                    label="Fecha Fin"
                    id="fechasenaveInput2"
                    ref="fechasenaveInput2"
                    outlined
                    dense
                    v-model="fechasenave.fin"
                    @keyup.native.enter="nextFecha(2, fechasenave.fin)"
                  ></c-text-date>
                </v-col>
                <v-col cols="10" sm="3">
                  <v-autocomplete
                    id="fechasenaveInput3"
                    ref="fechasenaveInput3"
                    :items="Vget_campo_data"
                    :loading="Vget_campo_load"
                    dense
                    label="Lotes"
                    multiple
                    return-object
                    item-value="idlote_agri"
                    item-text="campo"
                    v-model="fechasenave.lotes"
                    outlined
                    @keyup.native.enter="nextFecha(3, fechasenave.lotes)"
                  >
                    <template slot="prepend-item">
                      <v-list-item @click="marcar_todos(Vget_campo_data)">
                        <v-list-item-content>
                          <v-list-item-title class="text-center"
                            >TODOS</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item @click="desmarcar_todos()">
                        <v-list-item-content>
                          <v-list-item-title class="text-center"
                            >NINGUNO</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="2" sm="3">
                  <v-spacer></v-spacer>
                  <v-btn
                    id="fechasenaveInput4"
                    ref="fechasenaveInput4"
                    text
                    fab
                    small
                    color="red"
                    outlined
                    @click="obtenerDatosFecha()"
                  >
                    <v-icon>get_app</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-tab-item>
        <v-tab-item>
          <v-form ref="form">
            <v-container>
              <v-row dense>
                <v-col cols="12">
                  <AutocompleteCampana
                    id="generalsenaveInput1"
                    ref="generalsenaveInput1"
                    label="Seleccione una Campaña"
                    return-object
                    :disponible="false"
                    v-model="data.idcampana"
                    @keyup.native.enter="nextGeneral(1, data.idcampana)"
                  />
                </v-col>
              </v-row>
              <div class="title">Asesor Tecnico</div>
              <v-divider></v-divider>
              <v-row dense>
                <v-col cols="12" sm="4">
                  <c-text-field
                    id="generalsenaveInput2"
                    ref="generalsenaveInput2"
                    label="Asesor Tecnico"
                    v-model="data.asesor"
                    @keyup.native.enter="nextGeneral(2, data.asesor)"
                  ></c-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <c-text-field
                    id="generalsenaveInput3"
                    ref="generalsenaveInput3"
                    label="Matriculacion Nro"
                    v-model="data.matricula"
                    @keyup.native.enter="nextGeneral(3, data.matricula)"
                  ></c-text-field>
                </v-col>
                <v-col cols="12" sm="4">
                  <c-text-field
                    id="generalsenaveInput4"
                    ref="generalsenaveInput4"
                    label="Regitro Senave Nro"
                    v-model="data.regsenave"
                    @keyup.native.enter="nextGeneral(4, data.regsenave)"
                  ></c-text-field>
                </v-col>
              </v-row>
              <div class="title">Productor</div>
              <v-divider></v-divider>
              <v-row dense>
                <v-col cols="12" sm="3">
                  <c-text-field
                    id="generalsenaveInput5"
                    ref="generalsenaveInput5"
                    label="Nombre del Productor"
                    v-model="data.productor"
                    @keyup.native.enter="nextGeneral(5, data.productor)"
                  ></c-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <c-text-field
                    id="generalsenaveInput6"
                    ref="generalsenaveInput6"
                    label="Localidad del Productor"
                    v-model="data.localidad"
                    @keyup.native.enter="nextGeneral(6, data.localidad)"
                  ></c-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <c-text-field
                    id="generalsenaveInput7"
                    ref="generalsenaveInput7"
                    label="Distrito"
                    v-model="data.distrito"
                    @keyup.native.enter="nextGeneral(7, data.distrito)"
                  ></c-text-field>
                </v-col>
                <v-col cols="8" sm="3">
                  <c-text-field
                    id="generalsenaveInput8"
                    ref="generalsenaveInput8"
                    label="Departamento"
                    v-model="data.departamento"
                    @keyup.native.enter="nextGeneral(8, data.departamento)"
                  ></c-text-field>
                </v-col>
                <v-col cols="1" sm="9"></v-col>
                <v-spacer></v-spacer>
                <v-btn
                  id="generalsenaveInput9"
                  ref="generalsenaveInput9"
                  text
                  fab
                  small
                  class="mt-4"
                  color="red"
                  outlined
                  @click="obtenerDatos()"
                >
                  <v-icon>get_app</v-icon>
                </v-btn>
              </v-row>
            </v-container>
          </v-form>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AutocompleteCampana from "../../Campana/autocomplete";
import { focus } from "@/services/focus.service";
import { exportSenave, exportSenavePDF2 } from "./export";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { SENAVE } from "@/constants/permissions";
import { REPORTE_AGRICULTURA } from "@/constants/forms";
export default {
  data: () => ({
    tab: null,
    lotes: [],
    fechasenave: {
      inicio: "",
      fin: "",
      lotes: [],
    },
    data: {
      idcampana: {
        idcampana: "",
      },
      asesor: "",
      matricula: "",
      regsenave: "",
    },
    permission: {
      can_view: false,
    },
  }),
  components: {
    AutocompleteCampana,
  },
  created() {
    this.permission.can_view =
      getPermission(REPORTE_AGRICULTURA).permiso[SENAVE];
  },
  mounted() {
    this.Vact_campo_data();
  },
  watch: {
    getSenave(val) {
      exportSenave(val, this.data);
    },
    getSenavefecha(val) {
      exportSenavePDF2(val, null);
    },
  },
  computed: {
    ...mapGetters("agroreporte", [
      "getSenave",
      "getSenavefecha",
      "isSenaveLoading",
    ]),
    ...mapGetters("campo", ["Vget_campo_data", "Vget_campo_load"]),
  },
  methods: {
    ...mapActions("agroreporte", ["fetchSenave", "fetchSenaveFecha"]),
    ...mapActions("campo", ["Vact_campo_data"]),
    marcar_todos(id) {
      this.fechasenave.lotes = id;
    },
    desmarcar_todos() {
      this.fechasenave.lotes = []
    },
    async nextFecha(id, data) {
      await focus.nextidName("fechasenaveInput", id, data);
    },
    async nextGeneral(id, data) {
      await focus.nextidName("generalsenaveInput", id, data);
    },
    obtenerDatos() {
      if (!this.$refs.form.validate()) return null;
      this.fetchSenave(this.data.idcampana.idcampana);
    },
    obtenerDatosFecha() {
      this.fetchSenaveFecha(this.fechasenave);
    },
  },
};
</script>

<style></style>
