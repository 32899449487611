import {SET_LOADING, SET_JUSTIFICACION,SET_MOTIVO_AUSENCIA, SET_LISTA_VACACION} from './constants'
export default {
  [SET_JUSTIFICACION](state, request) {
    state.justificacion = request;
  },
  [SET_MOTIVO_AUSENCIA](state, request) {
    state.motivo_ausencia = request;
  },
  [SET_LISTA_VACACION](state, request) {
    state.lista_vacacion = request;
  },
  [SET_LOADING](state, request) {
    state.isLoading = request;
  },
}