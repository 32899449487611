<template>
  <div>
    <v-toolbar flat dense>
      <v-toolbar-title>Reporte Avanzado</v-toolbar-title>
      <widgets-Favorite :ruta="$route.path" formulario="Reporte Avanzado (Ventas)" />
    </v-toolbar>
    <v-form ref="form" class="mt-3">
      <v-row dense class="mx-2">
        <v-col cols="12" sm="3">
          <c-text-date
            dense
            ref="reporAdv1"
            label="Desde"
            v-model="data.fecha_inicio"
            @keyup.native.enter="next(1,data.fecha_inicio)"
          ></c-text-date>
        </v-col>
        <v-col cols="12" sm="3">
          <c-text-date
            dense
            ref="reporAdv2"
            label="Hasta"
            v-model="data.fecha_fin"
            @keyup.native.enter="next(2,data.fecha_fin)"
          ></c-text-date>
        </v-col>
        <v-col cols="12" sm="3">
          <v-autocomplete
            :items="filtro"
            dense
            ref="reporAdv3"
            label="Agrupar Por"
            item-text="desc"
            item-value="id"
            v-model="data.filtro"
            return-object
            chips
            small-chips
            :rules="[(v) => !!v || 'Seleccione un filtro']"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-form>
    <v-row dense class="mx-2">
      <v-col cols="12" sm="3" v-for="(item, i) of filtro" :key="i">
        <v-autocomplete
          :items="getItems(item.id)"
          dense
          :ref="`reporAdv${4 + i}`"
          :label="item.desc"
          multiple
          :loading="getLoad(item.id)"
          :item-value="item.value"
          :item-text="item.name"
          outlined
          v-model="data[item.id]"
      
        >
          <template slot="prepend-item">
            <v-list-item @click="getItemsId(item.id, item.value)">
              <v-list-item-content>
                <v-list-item-title class="text-center">TODOS</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="data[item.id] = []">
              <v-list-item-content>
                <v-list-item-title class="text-center"
                  >NINGUNO</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-autocomplete>
      </v-col>
      <v-spacer></v-spacer>
      <v-btn
        text
        color="primary"
        ref="reporAdv9"
        fab
        small
        outlined
        @click="obtenerDatos()"
        ><v-icon>cloud_download</v-icon></v-btn
      >
      <v-btn
          outlined
          text
          color="red"
          class="ml-2"
          small
          fab
          v-if="Vget_venta_advanced.length > 0"
          @click="descargarPDF()"
          ><v-icon>get_app</v-icon></v-btn>
     
    </v-row>
    <v-tabs color="deep-purple accent-4" right>
      <v-tab v-for="(item, i) of Vget_venta_advanced" :key="i">{{
        item.moneda
      }}</v-tab>
      <v-tab-item v-for="(item, i) of Vget_venta_advanced" :key="i">
        <v-data-table
          :ref="`refDataTable${i}`"
          dense
          :loading="Vget_venta_load"
          :headers="headers"
          :items="item.detalle"
          :search="search"
        >
          <template v-slot:[`item.cant`]="props">
            {{ toCurrency(props.item.cant) }}
          </template>
          <template v-slot:[`item.monto`]="props">
            {{ toCurrency(props.item.monto) }}
          </template>
          <template slot="body.append">
            <tr class="black--text">
              <th class="subtitle-1">TOTAL</th>
              <th></th>
              <th class="subtitle-1 text-end">
                {{ toCurrency(getTotal(item.detalle)) }}
              </th>
            </tr>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs>
    <c-overlay :value="Vget_venta_load"></c-overlay>
  </div>
</template>

<script>
import {
  current_first_date,
  current_last_date,
} from "@/services/util/date.service";
import { currency } from "@/services/util/number.service";
import { mapActions, mapGetters } from "vuex";
import { exportPDF } from './CustomPdf';
export default {
  data: () => ({
    search: "",
    headers: [
      { text: "", value: "name" },
      { text: "Cantidad Items Vendidos", value: "cant", align: "right" },
      { text: "Ingresos Obtenidos", value: "monto", align: "right" },
    ],
    filtro: [
      {
        id: "item",
        desc: "Item",
        value: "iditem",
        name: "descitem",
      },
      {
        id: "categoria",
        desc: "Categoria",
        value: "idcategoriaitem",
        name: "desccategoriaitem",
      },
      {
        id: "marca",
        desc: "Marca",
        value: "idmarca",
        name: "descmarca",
      },
      {
        id: "cuenta",
        desc: "Cuenta",
        value: "idcuenta",
        name: "desccuenta",
      },
      {
        id: "cliente",
        desc: "Cliente",
        value: "idcontacto",
        name: "desccontacto",
      },
    ],
    data: {
      fecha_inicio: current_first_date(),
      fecha_fin: current_last_date(),
      filtro: "",
      item: [],
      categoria: [],
      marca: [],
      cuenta: [],
      cliente: [],
    },
  }),
  mounted() {
    this.Vact_categoria_data();
    this.Vact_marca_data();
    this.Vact_item_data();
    this.Vact_contacto_data_cliente();
    this.Vact_cuenta_data();
  },
  computed: {
    ...mapGetters("categoria", ["Vget_categoria_data", "Vget_categoria_load"]),
    ...mapGetters("marca", ["Vget_marca_data", "Vget_marca_load"]),
    ...mapGetters("contacto", [
      "Vget_contacto_data_cliente",
      "Vget_contacto_load",
    ]),
    ...mapGetters("item", ["Vget_item_data", "Vget_item_load"]),
    ...mapGetters("cuenta", ["Vget_cuenta_data"]),
    ...mapGetters("venta", ["Vget_venta_advanced", "Vget_venta_load"]),
  },
  methods: {
    ...mapActions("contacto", ["Vact_contacto_data_cliente"]),
    ...mapActions("categoria", ["Vact_categoria_data"]),
    ...mapActions("marca", ["Vact_marca_data"]),
    ...mapActions("item", ["Vact_item_data"]),
    ...mapActions("cuenta", ["Vact_cuenta_data"]),
    ...mapActions("venta", ["Vact_venta_advanced"]),
    next(id, data) {
      if (data != "")
        try {
          this.$refs[`reporAdv${id + 1}`].focus();
        } catch (error) {
          this.$refs[`reporAdv${id + 1}`].$el.focus();
        }
    },
    toCurrency(value) {
      return currency(value);
    },
    getTotal(item) {
      if (!item) return 0;
      return item.reduce((accumulator, current) => {
        return (accumulator = accumulator + current.monto);
      }, 0);
    },
    getItems(id) {
      switch (id) {
        case "cliente":
          return this.Vget_contacto_data_cliente;
        case "categoria":
          return this.Vget_categoria_data;
        case "cuenta":
          return this.Vget_cuenta_data;
        case "item":
          return this.Vget_item_data;
        case "marca":
          return this.Vget_marca_data;
      }
    },
    getLoad(id) {
      switch (id) {
        case "cliente":
          return this.Vget_contacto_load;
        case "categoria":
          return this.Vget_categoria_load;
        case "cuenta":
          return this.Vget_cuenta_load;
        case "item":
          return this.Vget_item_load;
        case "marca":
          return this.Vget_marca_load;
      }
    },
    getItemsId(id, value) {
      const a = this.getItems(id).map((x) => {
        return x[value];
      });
      this.data[id] = a;
    },
    descargarPDF(){
      exportPDF(this.Vget_venta_advanced,this.headers, 'Reporte Avanzado')
    },
    async obtenerDatos() {
      if (!this.$refs.form.validate()) return null;
      const categoria = this.data.categoria.reduce(
        (acc, cur, index) => {
          console.log(acc, cur);
          if (index === 0) return (acc = acc + cur);
          return (acc = acc + "," + cur);
        },
        this.data.categoria.length > 0 ? "&categoria=" : ""
      );
      const cliente = this.data.cliente.reduce(
        (acc, cur, index) => {
          if (index === 0) return (acc = acc + cur);
          return (acc = acc + "," + cur);
        },
        this.data.cliente.length > 0 ? "&cliente=" : ""
      );
      const marca = this.data.marca.reduce(
        (acc, cur, index) => {
          if (index === 0) return (acc = acc + cur);
          return (acc = acc + "," + cur);
        },
        this.data.marca.length > 0 > 0 ? "&marca=" : ""
      );
      const cuenta = this.data.cuenta.reduce(
        (acc, cur, index) => {
          if (index === 0) return (acc = acc + cur);
          return (acc = acc + "," + cur);
        },
        this.data.cuenta.length > 0 ? "&cuenta=" : ""
      );
      const item = this.data.item.reduce(
        (acc, cur, index) => {
          if (index === 0) return (acc = acc + cur);
          return (acc = acc + "," + cur);
        },
        this.data.item.length > 0 ? "&item=" : ""
      );
      const url = `agrupar=${this.data.filtro.id}&inicio=${this.data.fecha_inicio}&fin=${this.data.fecha_fin}${marca}${categoria}${cliente}${cuenta}${item}`;
      this.headers[0].text = this.data.filtro.desc;
      await this.Vact_venta_advanced(url);
    },
  },
};
</script>

<style>
</style>