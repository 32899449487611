export default {
  causarechazo_create(state, request) {
    state.causarechazo_create = request;
  }, 
  causarechazo_update(state, request) {
    state.causarechazo_update = request;
  },   
  causarechazo_show(state, request) {
    state.causarechazo_show = request;
  },
  causarechazo_data(state, request) {
    state.causarechazo_data = request;
  },
  causarechazo_load(state, request) {
    state.causarechazo_load = request;
  },
  causarechazo_save_load(state, request) {
    state.causarechazo_save_load = request;
  }
}