
export default {

  viaaplicacion_create: (state) => {
    return state.viaaplicacion_create
  },
  viaaplicacion_update: (state) => {
    return state.viaaplicacion_update
  },
  viaaplicacion_reload: (state) => {
    return state.viaaplicacion_reload
  },
  viaaplicacion_key: (state) => {
    return state.viaaplicacion_key
  },
  viaaplicacion_show: (state) => {
    return state.viaaplicacion_show
  },
}
