<template>
  <v-btn
    text
    color="primary"
    :disabled="disabled"
    :id="id"
    @click="$emit('click')"
    ><v-icon>done</v-icon></v-btn
  >
</template>

<script>
export default {
  props: {
    id: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
</style>