<template>
  <v-autocomplete
    :value="value"
    @input="$emit('input', $event)"
    ref="input"
    :items="funcionarios"
    :loading="isFuncionarioLoading"
    item-value="idcontacto.idcontacto"
    :rules="rules"
    :clearable="clearable"
    return-object
    item-text="idcontacto.desccontacto"
    :outlined="outlined"
    :dense="dense"
    :disabled="disabled"
    :readonly="readonly"
    :chips="chips"
    :small-chips="smallChips"
    v-on:change="$emit('change', $event)"
    :label="label"
  ></v-autocomplete>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    value: [Object, Number, String],
    clearable:{
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    chips: {
      type: Boolean,
      default: false,
    },
    "small-chips": {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Transportadora",
    },
    rules: {
      type: Array,
      default: function() {
        return [(v) => v!== '' || "Campo requerido",(v) => v!== undefined || "Campo requerido"];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    "date-start": {
      type: String,
      default: null,
    },
    "date-end": {
      type: String,
      default: null,
    },
    idcontacto: {
      type: [Object, Array],
      default: null,
    },
  },
  mounted() {
    const params = {
      active: true,
      dateStart: this.dateStart,
      dateEnd: this.dateEnd,
    };
    this.fetchFuncionario(params);
  },

  computed: {
    ...mapGetters("funcionario", ["getFuncionario", "isFuncionarioLoading"]),
    funcionarios() {
      if (this.idcontacto) return this.getFuncionario.concat(this.idcontacto);
      return this.getFuncionario;
    },
  },
  methods: {
    ...mapActions("funcionario", ["fetchFuncionario"]),
    focus() {
      this.$refs.input.focus();
    },
    isMenuActive() {
      this.$refs.input.isMenuActive = false;
    },
    getfuncionario() {
      const params = {
        active: true,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
      };
      this.fetchFuncionario(params);
    }
  },
};
</script>

<style></style>
