<template>
  <div>
    <v-dialog
      :value="value"
      max-width="700px"
      persistent
      @keydown.esc="cancelar()"
    >
      <v-card>
        <v-form ref="form" lazy-validation>
          <v-toolbar flat dense>
            <v-toolbar-title>{{
              !isEdit ? "Agregar Lote Ganaderia" : "Modificar Lote Ganaderia"
            }}</v-toolbar-title>
          </v-toolbar>
          <v-container>
            <v-row row dense>
              <v-col cols="12">
                <v-text-field
                  dense
                  type="text"
                  :rules="validar"
                  :error-messages="this.mensajito_loco.desclote"
                  ref="input1"
                  v-model="data.desclote"
                  label="Descripcion"
                  required
                  outlined
                  autocomplete="off"
                  @keyup.enter="nextfocus(1)"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  dense
                  type="text"
                  :rules="validar"
                  :error-messages="this.mensajito_loco.nrolote"
                  ref="input2"
                  v-model="data.nrolote"
                  label="Nro Lote"
                  required
                  outlined
                  autocomplete="off"
                  @keyup.enter="nextfocus(2)"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-card-actions>
            <c-btn-crud-close @click="cancelar()"></c-btn-crud-close>
            <v-spacer></v-spacer>
            <c-btn-crud-done ref="aceptar" @click="guardar()"></c-btn-crud-done>
          </v-card-actions>
        </v-form>
        <c-overlay :value="isLoading"></c-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    datos: {
      type: [Object, Array],
      default: null,
    },
    "sync-input": {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      data: {
        desclote: "",
        nrolote: "",
      },
      default: {
        desclote: "",
        nrolote: "",
      },
      validar: [(v) => !!v || "Campo requerido"],
      isEdit: false,
      sync: null,
      mensajito_loco: [],
    };
  },
  watch: {
    $route() {
      return this.cancelar();
    },
  },
  computed: {
    ...mapGetters("lote", ["isLoading"]),
  },
  created() {
    this.nuevo(this.datos);
  },
  mounted() {
    setTimeout(() => this.$refs.input1.focus(), 500);
    if (this.syncInput != null) {
      this.data.desclote = this.syncInput;
      this.sync = this.syncInput;
    }
  },
  methods: {
    ...mapActions("lote", ["fetchLote", "setLote", "setLoteUpdate"]),

    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$emit("input", false);
      this.$emit("datos", null);
      this.fetchLote();
      this.operacion = true;
    },

    async guardar() {
     if (!this.$refs.form.validate()) return null;
      const id = this.data.idlote;
      const response = this.isEdit
        ? await this.setLoteUpdate({ id, data: this.data })
        : await this.setLote(this.data);
      if (response.success) {
         if (this.sync != null) {
          this.$emit("sync-input", response.data.id);
          this.$emit("next-focus")
          return this.cancelar();
        }
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation()
        this.fetchLote();
        if (this.isEdit) return this.cancelar();
      }
    },
    nextfocus(x) {
      switch (x) {
        case 1: {
          if (this.data.desclote != "") {
            this.$refs.input2.focus();
          }
          break;
        }
        case 2: {
          if (this.data.nrolote != "") {
            this.$refs.aceptar.$el.focus();
          }
          break;
        }
      }
    },
    nuevo(val) {
      if (val !== null) {
        this.data = JSON.parse(JSON.stringify(val));
        this.isEdit = true;
      }
    },
  },
};
</script>
