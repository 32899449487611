import { LOADING, FETCH, FETCHLOTEDEP, FETCHLOTEDIS, FETCHLOTELOC, LOADINGLOTEDEP, LOADINGLOTEDIS, LOADINGLOTELOC } from "./constants";
export default {
  [LOADING]: (state, request) => (state.loading = request),
  [FETCH]: (state, request) => (state.data = request),
  [LOADINGLOTEDEP]: (state, request) => (state.loadingdep = request),
  [FETCHLOTEDEP]: (state, request) => (state.datalotedep = request),
  [LOADINGLOTEDIS]: (state, request) => (state.loadingdis = request),
  [FETCHLOTEDIS]: (state, request) => (state.datalotedis = request),
  [LOADINGLOTELOC]: (state, request) => (state.loadingloc = request),
  [FETCHLOTELOC]: (state, request) => (state.dataloteloc = request),
}