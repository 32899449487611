import { db } from "@/services/db.service";
export default {
    async Vact_formapago_data({commit}){
        commit('formapago_load',true);
        try {
            const data = await db.get('/apiformapago/');
            commit('formapago_data',data);
            commit('formapago_load',false);
            return true
        } catch (e) {
            commit('formapago_load',false);
            return false
        } 
    }
}
