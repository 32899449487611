import jsPDF from "jspdf";
import "jspdf-autotable";
import { current_date, dmy } from "@/services/util/date.service";
import store from "@/store";
import { currency } from "@/services/util/number.service";

export const generarRecibo = (data, config, title, lote) => {
  let doc = new jsPDF();
  let name = "";
  if (!lote) {
    name = data.idcontacto.nombre;
    data = [data];
  }

  data.map((props, i) => {
    if (i > 0) doc.addPage();
    let ingreso = 0;
    let egreso = 0;
    const body = props.detalle.map((x) => {
      return [
        x.idconcepto_salario.descripcion,
        x.idconcepto_salario.positivo_negativo
          ? ((ingreso = ingreso + x.monto), currency(x.monto))
          : "",
        !x.idconcepto_salario.positivo_negativo
          ? ((egreso = egreso + x.monto), currency(x.monto))
          : "",
      ];
    });
    body.push(["Total", currency(ingreso), currency(egreso)]);

    let row = 18;
    let y = 0;
    [1, 2].forEach(() => {
      y = y + 1;
      if (y == 2) {
        row = 170;
      }
      const empresa = store.getters["empresa/Vget_empresa_active"].filter(
        (empresa) => empresa.est_emp_tam_usu
      );
      doc.setFontSize(12);
      doc.text(config.title, doc.internal.pageSize.width / 2, row, {
        align: "center",
      });
      doc.setFontSize(8);
      doc.text(config.articulo, doc.internal.pageSize.width / 2, row + 3, {
        align: "center",
      });
      doc.setLineWidth(0.4);
      doc.line(13, row + 7, doc.internal.pageSize.width - 12, row + 7);
      doc.setFontSize(9);
      doc.text("Empleador: " + empresa[0].idempresa.descempresa, 15, row + 11);
      try {
        if (props.iscuenta) {
          doc.text(
            `Cuenta Bancaria: ${props.adelantocuenta[0].idcuentabanco.desccuentabanco} - Banco: ${props.adelantocuenta[0].idcuentabanco.idbanco.descbanco}`,
            15,
            row + 16
          );
        } else {
          doc.text(
            `Caja: ${props.adelantocaja[0].idcaja.descaja}`,
            15,
            row + 16
          )
        }
      } catch {
        doc.text(
          `Cuenta Bancaria: ${props.idcuentabanco.desccuentabanco} - Banco: ${props.idcuentabanco.idbanco.descbanco}`,
          15,
          row + 16
        );
      }
      doc.text(
        `Moneda: ${props.idmoneda.descmoneda}`,
        15,
        row + 21
      );
      doc.text(
        "Apellido y Nombre del Trabajador: " +
          props.idcontacto.apellido +
          " " +
          props.idcontacto.nombre,
        100,
        row + 11
      );
      doc.text(
        `Periodo de Pago: ${dmy(props.periodo_inicio)} al ${dmy(
          props.periodo_fin
        )}`,
        100,
        row + 16
      );
      doc.text(
        `Horas Trabajadas: ${props.trabajadas}`,
        100,
        row + 21
      );
      row = row + 21;
      doc.setLineWidth(0.4);
      doc.line(13, row + 3, doc.internal.pageSize.width - 12, row + 3);
      doc.autoTable({
        head: [["", "Ingresos", "Egresos"]],
        body: body,
        startY: row + 5,
        theme: "striped",
        columnStyles: {
          1: { halign: "right", fontStyle: "bold" },
          2: { halign: "right", fontStyle: "bold" },
        },
        headStyles: {
          textColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          halign: "right",
        },
        styles: {
          cellPadding: 0.7,
        },
        didParseCell: (data) => {
          if (data.row.index == body.length - 1 && data.column.index == 0) {
            if (data.cell.raw === "Total") {
              data.row.cells[0].colSpan = 1;
              Object.entries(data.row.cells).forEach((v) => {
                v[1].styles.fontStyle = "bold";
              });
            }
          }
        },
      });
      doc.autoTable({
        head: [],
        startY: doc.lastAutoTable.finalY + 3,
        body: [["Neto a Recibir", currency(ingreso - egreso)]],
        columnStyles: {
          1: { halign: "right", fontStyle: "bold" },
        },
        theme: "striped",
        styles: {
          cellPadding: 0.7,
        },
        didParseCell: (data) => {
          data.row.cells[0].colSpan = 1;
          Object.entries(data.row.cells).forEach((v) => {
            v[1].styles.fontStyle = "bold";
          });
        },
      });

      doc.autoTable({
        head: [],
        startY: doc.lastAutoTable.finalY + 8,
        body: [
          ["----------------------------", "----------------------------"],
          ["", "CI: " + props.idcontacto.idcontacto.ruccontacto],
          ["Representante", props.idcontacto.idcontacto.desccontacto],
        ],
        columnStyles: {
          0: { halign: "center" },
          1: { halign: "center" },
        },
        bodyStyles: {
          fontSize: 9,
        },
        styles: {
          cellPadding: 0.5,
        },
        theme: "plain",
      });
      doc.setLineWidth(0.7);
      doc.line(
        13,
        doc.lastAutoTable.finalY + 2,
        doc.internal.pageSize.width - 12,
        doc.lastAutoTable.finalY + 2
      );
      row = doc.lastAutoTable.finalY + 15;
    });
  });
  doc.save(
    title + " " + (lote ? "LOTE" : name)+ " " + dmy(current_date()) + ".pdf"
  );
};
