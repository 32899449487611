<template>
  <div>
    <v-dialog v-model="dialogo" max-width="700px" persistent :key="reload">
      <v-card>
        <v-card-title>
          <span class="headline">Detalles</span>
        </v-card-title>
        <v-card-text>
          <v-row row dense>
            <v-col cols="12">
              <v-text-field
                dense
                type="text"
                id="input3"
                v-model="data.desccuenta"
                label="Descripcion"
                outlined
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cancelar">Salir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      data: {},
      default: {},
      dialogo: false,
      reload: 0
    };
  },
  watch: {
    $route(to, from) {
      if (from.path === "/cuenta") return this.cancelar();
    }
  },
  computed: {
    ...mapGetters("cuenta", {
      create: "cuenta_show",
      update: "cuenta_update"
    })
  },
  created() {
    this.show(this.update);
  },
  methods: {
    ...mapActions("cuenta", ["cuenta_show", "cuenta_update"]),
    cancelar() {
      this.data = this.default;
      this.dialogo = false;
      this.cuenta_update(null);
      this.cuenta_show(false);
      this.reload++;
    },

    show(val) {
      this.dialogo = true;
      this.data = val;
    }
  }
};
</script>
