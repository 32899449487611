<template>
    <c-dialog-confirmation
    :value="value"
    @cancel="close()"
    @done="save()"
    :loading="isLoading"
    >Desea eliminar este <strong>Reajuste</strong
    >?</c-dialog-confirmation
  >
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
    props: {
        value: Boolean,
        props: Object,
    },
    computed: {
        ...mapGetters("reajuste", ["isLoading"])
    },
    watch: {
        $route() {
            return this.close()
        }
    },
    methods: {
        ...mapActions("reajuste", ["setReajusteDelete", "fetchReajuste"]),
        async save() {
            const id = this.props.idreajusteagro;
            const response = await this.setReajusteDelete(id);
            if (!response.success) return null;
            this.close();
        },
        close() {
            this.$emit('datos', null);
            this.$emit('input', false);
            this.fetchReajuste();
        }
    }
}
</script>