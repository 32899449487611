<template>
  <div>
    <Show
      v-if="crud.view"
      v-model="crud.view"
      :props="crud.datos"
      @datos="crud.datos = $event"
    />
    <v-card>
      <v-alert type="error" v-if="permission_alert"
        >Error!!! Este usuario no tiene permisos en esta ventana</v-alert
      >
      <v-toolbar flat>
        <v-toolbar-title>{{ titulo }}</v-toolbar-title>
        <widgets-Favorite ruta="/unidadmedida" formulario="Unidad Medida" />
        <v-divider class="mx-2" inset vertical></v-divider>

        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Busqueda"
          single-line
          hide-details
          v-if="permission.can_view"
        ></v-text-field>
      </v-toolbar>
      <v-data-table
        :loading="Vget_unidadmedida_load"
        :headers="headers"
        :items="Vget_unidadmedida_data"
        :search="search"
        class="elevation-1"
        v-if="permission.can_view"
      >
        <template v-slot:item.accion="props">
          <v-icon class="mr-2" @click="rowselect(props.item)"
            >visibility</v-icon
          >
        </template>
        <template v-slot:no-data>
          <v-alert :value="true" color="info" icon="info" outlined
            >No existe ningun registro</v-alert
          >
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { ITEM } from "@/constants/forms";
import Show from "./Show";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";
export default {
  components: {
    Show,
  },
  data() {
    return {
      overlay: false,
      crud: {
        view: false,
        datos: null,
      },
      search: "",
      headers: [
        { text: "Codigo", align: "left", value: "idunidadmedida" },
        { text: "Descripcion", align: "left", value: "descunidadmedida" },
        { text: "Abreviatura", align: "left", value: "abreviatura" },
        { text: "Medida", align: "left", value: "mediunidadmedida" },
        { text: "Magnitud", align: "left", value: "idmagnitud.descripcion" },
        { text: "Accion", value: "accion", sortable: false, align: "right" },
      ],
      titulo: "Unidad de Medida",
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
    };
  },

  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.Vact_unidadmedida_data();
    const permission = getPermission(ITEM).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  computed: {
    ...mapGetters("unidadmedida", [
      "Vget_unidadmedida_data",
      "Vget_unidadmedida_load",
    ]),
    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },

  methods: {
    ...mapActions("unidadmedida", [
      "Vact_unidadmedida_data"
    ]),
    rowselect(val) {
      this.crud.datos = JSON.parse(JSON.stringify(val));
      this.crud.view = true;
    },
  },
};
</script>
