<template>
  <div class="text-center">
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn icon large v-on="on">
          <v-badge color="green" overlap :value="notify">
            <template v-slot:badge>
              <span v-if="notify > 0">{{ notify }}</span>
            </template>
            <v-icon>notifications</v-icon>
          </v-badge>
        </v-btn>
      </template>
      <v-list dense style="max-height: 400px" class="overflow-y-auto">
        <c-overlay
          :size="32"
          absotule
          :value="Vget_notificacion_load"
        ></c-overlay>
        <v-list-item-group :value="active" multiple>
          <template v-for="(item, index) in Vget_notificacion_data">
            <v-list-item
              :key="`A${index}`"
              
              :color="item.color"
            >
              <v-list-item-action @click="$emit('open-form', item)">
                <v-icon small :color="item.color">circle</v-icon>
              </v-list-item-action>
              <v-list-item-content @click="$emit('open-form', item)">
                <v-list-item-title
                  class="font-weight-medium"
                  v-text="item.descripcion"
                ></v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn fab x-small elevation="0" class="my-n2"  color="grey lighten-5" @click="$emit('delete-notification',item)">
                  <v-icon color="red">clear</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider v-if="index + 1 < item.length" :key="index"></v-divider>
          </template>
        </v-list-item-group>
        <v-divider></v-divider>
        <v-list-item @click="$emit('click-calendar')">
          <v-list-item-action>
            <v-icon small>calendar_today</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Calendario de Actividad</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("dashboard", [
      "Vget_notificacion_data",
      "Vget_notificacion_load",
    ]),
    active() {
      return [...this.Vget_notificacion_data.keys()];
    },
    notify() {
      return this.active.length;
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-btn--fab.v-size--x-small {
    height: 27px;
    width: 27px;
}
</style>