
export default {
Vget_banco_data: (state) => {
    return state.banco_data
  },
  Vget_banco_load: (state) => {
    return state.banco_load
  },
  Vget_banco_save_load: (state) => {
    return state.banco_save_load
  },
}
