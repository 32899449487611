import { db } from "@/services/db.service";
export default {

    async Vact_emisioncheque_data({commit}){
        commit('emisioncheque_load',true);
        try {
            const data = await db.get('/apichequeemitido/');
            commit('emisioncheque_data',data);
            commit('emisioncheque_load',false);
            return true
        } catch (e) {
            commit('emisioncheque_load',false);
            return false
        } 
    },
    async Vact_emisioncheque_data_pagado({commit}){
        commit('emisioncheque_load',true);
        try {
            const data = await db.get('/apichequeemitido/?pagado=no');
            commit('emisioncheque_data_pagado',data);
            commit('emisioncheque_load',false);
            return true
        } catch (e) {
            commit('emisioncheque_load',false);
            return false
        } 
    },
    async Vact_emisioncheque_save({commit},data){
        commit('emisioncheque_save_load',true);
        try {
            const resp = await db.save('/apichequeemitido/',data);
            commit('emisioncheque_save_load',false);
            return resp;
        } catch (e) {
            commit('emisioncheque_save_load',false);
            return e
        } 
    },
    async Vact_emisioncheque_del({dispatch},id){
        try {
            const data = await db.delete('/apichequeemitido/'+id);
            dispatch('Vact_emisioncheque_data');
            return data
        } catch (e) {
            return false
        } 
    },
    async Vact_emisioncheque_update({commit},{id, data}){
        commit('emisioncheque_save_load',true);
        try {
            const resp = await db.update('/apichequeemitido/'+id+'/',data);
            commit('emisioncheque_save_load',false);
            return resp;
        } catch (e) {
            commit('emisioncheque_save_load',false);
            return e
        } 
    },
}
