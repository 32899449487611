<template>
  <div>
    <v-dialog :value="value" max-width="650" persistent>
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>
            {{
              !isEdit ? "Agregar Sensor" : "Modificar Sensor"
            }}
            <v-btn @click="sensorMap = true">
            <v-icon>map</v-icon>
          </v-btn>
          </v-toolbar-title>
        </v-toolbar>
        <v-container>
          <v-form ref="form" @submit.prevent="next(1, data.descripcion)">
            <v-row dense>
              <v-col cols="9">
                <vue-text-field
                  label="Descripcion"
                  id="sensorInput1"
                  v-model="data.descripcion"
                  @keyup.native.enter="next(1, data.descripcion)"
                ></vue-text-field>
              </v-col>
              <v-col cols="3">
                <v-switch
                  class="mt-1"
                  inset
                  label="Compartir?"
                  v-model="data.compartido"
                ></v-switch>
              </v-col>
              <v-col cols="12">
                <vue-text-field
                  id="sensorInput2"
                  label="Api key"
                  v-model="data.api_key"
                  @keyup.native.enter="next(2, data.api_key)"
                ></vue-text-field>
              </v-col>
              <v-col cols="12">
                <vue-text-field
                  id="sensorInput3"
                  label="Aplication key"
                  v-model="data.application_key"
                  @keyup.native.enter="next(3, data.application_key)"
                ></vue-text-field>
              </v-col>
              <v-col cols="12">
                <vue-text-field
                  id="sensorInput4"
                  label="Mac"
                  v-model="data.mac"
                  @keyup.native.enter="next(4, data.mac)"
                ></vue-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <c-btn-crud-close @click="cancelar()"></c-btn-crud-close>
          <v-spacer></v-spacer>
          <c-btn-crud-done @click="guardar()"></c-btn-crud-done>
        </v-card-actions>
        <c-overlay :value="isLoading"></c-overlay>
      </v-card>
    </v-dialog>
    <SensorOpen v-model="sensorMap" @seleccionado="seleccionado"/>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { focus } from "@/services/focus.service";
import SensorOpen from "./SensorOpen";
export default {
  components: {
    SensorOpen
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    props: {
      type: [Object, Array],
      default: null,
    },
  },
  data() {
    return {
      sensorMap: false,
      isEdit: false,
      data: {
        descripcion: "",
        api_key: "",
        application_key: "",
        compartido: true,
        mac: "",
        clave_net: ""
      },
      default: {
        descripcion: "",
        api_key: "",
        application_key: "",
        compartido: true,
        mac: "",
        clave_net: ""
      },
    };
  },
  watch: {
    $route() {
      return this.cancelar();
    },
  },
  computed: {
    ...mapGetters("sensor", ["isLoading"]),
  },
  mounted() {
    setTimeout(() => this.next(0, "-"), 200);
    this.nuevo(this.props);
  },
  methods: {
    ...mapActions("sensor", ["setSensor", "setSensorUpdate", "fetchSensor"]),
    ...mapActions("item", ["Vact_item_siembra_default"]),
    next(id, data) {
      focus.nextidName("sensorInput", id, data);
    },
    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.fetchSensor();
      this.isEdit = false;
      this.$emit("input", false);
       this.$emit('reset', null);
    },

    async guardar() {
      if (!this.$refs.form.validate()) return null;
      const id = this.data.idambient_weather;
      const response = this.isEdit
        ? await this.setSensorUpdate({ id, data: this.data })
        : await this.setSensor(this.data);
      if (response.success) {
        this.data = JSON.parse(JSON.stringify(this.default));
        if (this.isEdit) return this.cancelar();
        this.$refs.form.resetValidation();
      }
    },

    nuevo(val) {
      if (val != null) {
        this.data = JSON.parse(JSON.stringify(val));
        this.isEdit = true;
      }
    },
    seleccionado(data) {
      this.data.descripcion = data.name
      this.data.mac = data.mac
      this.data.clave_net = data.clave_net
    }
  },
};
</script>
