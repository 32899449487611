export default {


  gymtipo_data(state, request) {
    state.gymtipo_data = request;
  }, 
  gymtipo_update(state, request) {
    state.gymtipo_update = request;
  }, 
  gymtipo_load(state, request) {
    state.gymtipo_load = request;
  }, 
  gymtipo_save_load(state, request) {
    state.gymtipo_save_load = request;
  }, 
}