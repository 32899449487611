<template>
  <v-dialog :value="value" persistent max-width="900">
    <v-card>
      <v-toolbar flat><v-toolbar-title>{{titulo}}</v-toolbar-title></v-toolbar>
      <v-card-text>
        <v-form ref="form">
          <v-row dense>
            <v-col cols="6" md="2" sm="3">
              <c-text-date label="Fecha" v-model="data.fecha" :readonly="true"></c-text-date>
            </v-col>
            <v-col cols="6" md="2" sm="3">
              <c-text-date
                label="Fecha de Entrega"
                id="input1"
                v-model="data.fecha_entrega"
                @keyup.native.enter="next(1,data.fecha_entrega)"
              ></c-text-date>
            </v-col>
            <v-col cols="0" md="6" sm="4"></v-col>
            <v-col cols="12" md="2" sm="2">
              <v-checkbox v-model="presupuesto" label="Presupuesto" class="mt-1"></v-checkbox>
            </v-col>
            <v-col cols="12" md="5" sm="5">
              <vue-autocomplete-contacto-search
                label="Cliente"
                id="input2"
                action="contacto/Vact_contacto_data_cliente"
                getter="contacto/Vget_contacto_data_cliente"
                load="contacto/Vget_contacto_load"
                vuex="contacto/contacto_create"
                permission="Cliente"
                v-model="data.idcontacto"
                @keyup.native.enter="next(2,data.idcontacto)"
              />
            </v-col>
            <v-col cols="12" md="4" sm="4" v-if="isExistResponsable || data.idresp != null">
              <v-autocomplete
                dense
                id="input3"
                v-model="data.idresp.idcontacto"
                :items="data.idcontacto.responsable"
                label="Responsable"
                item-text="idresp.desccontacto"
                item-value="idresp.idcontacto"
                outlined
                autocomplete="off"
                :no-data-text="`No existe ninguna concidencia`"
                @keyup.native.enter="next(3,'S')"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3" sm="3">
              <vue-autocomplete-generic
                id="input4"
                action="moneda/Vact_moneda_data"
                getter="moneda/Vget_moneda_data"
                load="moneda/Vget_moneda_load"
                pk="idmoneda"
                label="Seleccione moneda"
                title="descmoneda"
                permission="Moneda"
                v-model="data.idmoneda.idmoneda"
                @keyup.native.enter="next(4,data.idmoneda.idmoneda)"
              />
            </v-col>
          </v-row>
        </v-form>
        <v-form ref="formDet">
          <v-row dense>
            <v-col cols="12" md="6" sm="6" v-show="!limite">
              <vue-autocomplete-item-stock2
                :idmoneda="data.idmoneda.idmoneda"
                id="input5"
                mode="Offline"
                :create="false"
                :rules="true"
                v-model="data_det.idstock"
                @change="data_det.precio = data_det.idstock.iditem.precitem"
                @keyup.native.enter="next(5,data_det.idstock.idstock)"
              ></vue-autocomplete-item-stock2>
            </v-col>
            <v-col cols="12" sm="2" md="2" v-show="!limite">
              <vue-text-currency
                label="Precio"
                ref="input6"
                v-model="data_det.precio"
                :rules="true"
                :rules-option="validarPrecio"
                @keyup.native.enter="next(6,data_det.precio)"
              ></vue-text-currency>
            </v-col>
            <v-col cols="8" sm="2" md="2" v-show="!limite">
              <vue-text-currency
                label="Cantidad"
                ref="input7"
                v-model="data_det.cantidad"
                :rules="true"
                :rules-option="validarCantidad"
                @keyup.native.enter="next(7,data_det.cantidad)"
              ></vue-text-currency>
            </v-col>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              id="input8"
              small
              fab
              outlined
              text
              @click="addTable()"
              v-show="!limite"
            >
              <v-icon>add</v-icon>
            </v-btn>
            <v-expand-transition>
              <v-col cols="12" v-show="limite">
                <v-alert dense type="info">
                  Ya alcanzo el limite de
                  <strong>Items</strong> en una factura para este Punto de Venta
                </v-alert>
              </v-col>
            </v-expand-transition>
            <v-col cols="12" md="12" sm="12">
              <v-data-table hide-default-footer :headers="headers" :items="data.pedidoventadet">
                <template v-slot:[`item.actions`]="props">
                  <v-icon small class="mr-1" @click="editarDetalle(props.item)">edit</v-icon>
                  <v-icon small class="mr-1" @click="eliminarDetalle(props.item)">delete</v-icon>
                </template>
              </v-data-table>
            </v-col>
            <v-col md="9" sm="9"></v-col>
            <v-col cols="12" md="3" sm="3">
              <vue-text-currency :rules="false" :readonly="true" label="Total" :value="total"></vue-text-currency>
            </v-col>
            <v-col cols="12">
              <vue-text-field
                :rules="false"
                :readonly="false"
                label="Observacion"
                v-model="data.observacion"
              ></vue-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="red" text @click="cancelar()">
          <v-icon>clear</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="guardar()">
          <v-icon>done</v-icon>
        </v-btn>
      </v-card-actions>
      <c-overlay :value="Vget_pedidoventa_save_load"></c-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { current_date } from "@/services/util/date.service";
import { focus } from "@/services/focus.service";
import { mensaje } from "@/services/notify.service";
import { db } from "@/services/db.service";
export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    editar: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      presupuesto: false,
      limiteDet: 1,
      validarPrecio: [
        () =>
          !(Number(this.data_det.precio) <= 0) ||
          "Este campo no puede ser menor o igual a 0",
        () => !!this.data_det.precio || "Este campo es requerido"
      ],
      validarCantidad: [
        () =>
          !(Number(this.data_det.cantidad) < 1) ||
          "Este campo no puede ser menor a 1",
        () => !!this.data_det.cantidad || "Este campo es requerido"
      ],
      data: {
        idpedido_venta: 0,
        idcontacto: {
          idcontacto: "",
          responsable: []
        },
        idresp: {
          idcontacto: ""
        },
        idmoneda: {
          idmoneda: ""
        },

        fecha: current_date(),
        fecha_entrega: current_date(),
        idestadopedido: {
          idestadopedido: 1
        },
        observacion: "",
        pedidoventadet: []
      },
      default: {
        idpedido_venta: 0,
        idcontacto: {
          idcontacto: "",
          responsable: []
        },
        idresp: {
          idcontacto: ""
        },
        idmoneda: {
          idmoneda: ""
        },
        fecha: current_date(),
        fecha_entrega: current_date(),
        idestadopedido: {
          idestadopedido: 1
        },
        observacion: "",
        pedidoventadet: []
      },
      data_det: {
        idstock: {
          iditem: {
            iditem: ""
          },
          iddeposito: {
            iddeposito: ""
          },
          idstock: ""
        },
        iditem: "",
        precio: 0,
        cantidad: 0
      },
      default_det: {
        idstock: {
          iditem: {
            iditem: ""
          },
          iddeposito: {
            iddeposito: ""
          },
          idstock: ""
        },
        iditem: "",
        precio: 0,
        cantidad: 0
      },
      abriritem: false,
      operacion: true,
      headers: [
        { text: "#", value: "idstock.iditem.iditem", sortable: false },
        { text: "Item", value: "idstock.iditem.descitem", sortable: false },
        { text: "Precio", value: "precio", sortable: false },
        { text: "Cantidad", value: "cantidad", sortable: false },
        {
          text: "SubTotal",
          value: "subtotal",
          align: "right",
          sortable: false
        },
        { text: "Accion", value: "actions", sortable: false, align: "right" }
      ]
    };
  },
  mounted() {
    if (this.editar != null) {
      this.data = JSON.parse(JSON.stringify(this.editar));
      this.operacion = false;
      this.calculoEdicion();
    }
    this.obtenerLimiteItems();
    if (this.data.idestadopedido.idestadopedido === 3) this.presupuesto = true;
    setTimeout(() => document.getElementById("input1").focus(), 200);
  },
  watch: {
    $route(to, from) {
      if (from.path === "/pedidoventa") return this.cancelar();
    },
    presupuesto(val) {
      if (val) return (this.data.idestadopedido.idestadopedido = 3);
      return (this.data.idestadopedido.idestadopedido = 1);
    }
  },
  computed: {
    ...mapGetters("pedidoventa", ["Vget_pedidoventa_save_load"]),
    total() {
      let total = 0;
      this.data.pedidoventadet.forEach(x => {
        total = total + x.cantidad * x.precio;
      });
      return total;
    },
    titulo() {
      if (this.operacion) {
        if (this.data.idestadopedido.idestadopedido === 1)
          return "Agregar Pedido";
        return "Agregar Presupuesto";
      }
      if (this.data.idestadopedido.idestadopedido === 3)
        return "Modificar Presupuesto";
      return "Modificar Pedido";
    },
    limite() {
      if (this.data.pedidoventadet.length < this.limiteDet) return false;
      return true;
    },
    isExistResponsable() {
      if (this.data.idcontacto.responsable === undefined) return false;
      if (this.data.idcontacto.responsable.length > 0) return true;
      return false;
    }
  },
  methods: {
    ...mapActions("pedidoventa", [
      "Vact_pedidoventa_save",
      "Vact_pedidoventa_update",
      "Vact_pedidoventa_presupuesto",
      "Vact_pedidoventa_finalizado",
      "Vact_pedidoventa_data",
      "Vact_pedidoventa_byId"
    ]),
    async obtenerLimiteItems() {
      try {
        const { filasfactura__min } = await db.get("/apipuntominimo/");
        this.limiteDet = filasfactura__min;
      } catch (error) {
        console.log(error);
      }
    },
    next(id, data) {
      if (data === "" || data <= 0) return null;
      if (id === 2 && data != "" && !this.isExistResponsable)
        return this.next(3,data);
      if (id === 5 && data != "")
        return this.$refs.input6.$el.querySelector("input").focus();
      if (id === 6 && data != "")
        return this.$refs.input7.$el.querySelector("input").focus();
      focus.nextid(id, data);
    },
    isValidDetail() {
      if (
        !this.$refs.formDet.validate() ||
        this.isExist()
      )
        return false;
      return true;
    },
    isExist() {
      let exist = false;
      this.data.pedidoventadet.forEach(item => {
        if (item.idstock.iditem.iditem === this.data_det.idstock.iditem.iditem)
          exist = true;
      });
      return exist;
    },
    addTable() {
      if (!this.isValidDetail())
        return this.$notify(mensaje.error("Verifique los datos ingresados"));
      this.data_det.subtotal = (
        Number(this.data_det.precio) * Number(this.data_det.cantidad)
      ).toFixed(2);

      this.data.pedidoventadet.push(this.data_det);
      this.data_det = JSON.parse(JSON.stringify(this.default_det));
      this.$refs.formDet.resetValidation();
      setTimeout(() => document.getElementById("input4").focus(), 200);
    },
    calculoEdicion() {
      let data = this.editar.pedidoventadet;
      data.forEach(x => {
        x.subtotal = (Number(x.precio) * Number(x.cantidad)).toFixed(2);
      });
      this.data.pedidoventadet = JSON.parse(JSON.stringify(data));
    },
    editarDetalle(item) {
      const index = this.data.pedidoventadet.indexOf(item);
      this.data.pedidoventadet.splice(index, 1);
      this.data_det = JSON.parse(JSON.stringify(item));
    },
    eliminarDetalle(item) {
      const index = this.data.pedidoventadet.indexOf(item);
      this.data.pedidoventadet.splice(index, 1);
    },
    cancelar() {
      this.$emit("input", false);
      this.$emit("editar", null);
      this.$emit("buscar-pedido");
      //this.Vact_pedidoventa_data();
      //this.Vact_pedidoventa_presupuesto();
      //this.Vact_pedidoventa_finalizado();
      this.limpiarCampos();
    },
    async guardar() {
      if (!this.$refs.form.validate() || this.data.pedidoventadet.length === 0)
        return this.$notify(mensaje.completar());
      let a;
      if (this.operacion) {
        a = await this.Vact_pedidoventa_save(this.data);
      } else {
        let id = this.data.idpedido_venta;
        a = await this.Vact_pedidoventa_update({ id, data: this.data });
      }
      if (!a.estado) {
        return a.info.map(x => {
          this.$notify(mensaje.error(x));
        });
      }
      this.$notify(a.info);
      this.operacion = true;
      this.$refs.form.resetValidation();
      this.limpiarCampos();
      setTimeout(() => document.getElementById("input1").focus(), 200);
    },
    limpiarCampos() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.data_det = JSON.parse(JSON.stringify(this.default_det));
    }
  }
};
</script>

<style>
</style>