export default {
  rol_create(state, request) {
    state.rol_create = request;
  }, 
  rol_update(state, request) {
    state.rol_update = request;
  },  
  rol_reload(state, request) {
    state.rol_reload = request;
  }, 
  rol_show(state, request) {
    state.rol_show = request;
  },  
}