export default {

  cotizacion_data(state, request) {
    state.cotizacion_data = request;
  }, 
  cotizacion_load(state, request) {
    state.cotizacion_load = request;
  }, 
  cotizacion_save_load(state, request) {
    state.cotizacion_save_load = request;
  }, 
  SET_COTIZACION_BANKS(state, request) {
    state.cotizacionBanks = request;
  }, 
}