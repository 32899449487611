<template>
  <div>
    <div v-if="formularios.deposito">
      <Item-Deposito-create v-model="formularios.deposito" />
    </div>
    <v-dialog
      :value="value"
      max-width="900"
      persistent
      :fullscreen="$vuetify.breakpoint.xs"
    >
      <v-card>
        <c-overlay v-model="overlay"></c-overlay>
        <v-toolbar flat>
          <v-toolbar-title>Registrar Cosecha</v-toolbar-title>
          <v-divider class="mx-2" inset vertical></v-divider>
          <v-spacer></v-spacer>

          <v-col class="hidden-sm-and-down">
            <v-autocomplete
              :items="Vget_campana_data"
              item-text="descripcion"
              item-value="idcampana"
              return-object
              autocomplete="off"
              :readonly="!readonly"
              :rules="validar"
              @change="idlote_agri_temp = ''"
              hide-details
              :loading="Vget_campana_load"
              v-model="idcampana_temp"
              label="Campaña"
            >
              <template v-slot:no-data>
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title
                      >No se encontro ninguna Campaña</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col class="hidden-sm-and-down">
            <v-autocomplete
              :items="idcampana_temp.campanadet"
              autocomplete="off"
              item-text="idlote_agri.campo"
              item-value="idlote_agri.idlote_agri"
              :rules="validar"
              :readonly="!readonly"
              hide-details
              :loading="Vget_campana_load"
              v-model="idlote_agri_temp"
              @change="changeLote(idlote_agri_temp)"
              label="Lote"
            >
              <template v-slot:no-data>
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title
                      >No tiene lotes en esta Campaña</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>
        </v-toolbar>
        <v-card-text>
          <v-alert dense outlined type="error" v-if="!isValid">
            No se encontraron
            <strong>Hectareas</strong> cosechables en este
            <strong>Lote</strong>
          </v-alert>
          <div v-else>
            <v-form :ref="i" v-for="(item, i) of item_siembra" :key="i">
              <v-row row dense v-if="item.hectarea > 0">
                <v-col cols="12" md="5" sm="5">
                  <v-btn-toggle
                    v-model="data[i].ensilaje"
                    dense
                    rounded
                    mandatory
                    color="red darken-1"
                  >
                    <v-btn> Cosecha </v-btn>
                    <v-btn> G. Húmedo </v-btn>
                    <v-btn> Ensilaje </v-btn>
                  </v-btn-toggle>
                </v-col>
                <v-col cols="12" md="3" sm="3">
                  <vue-text-field
                    :rules="false"
                    label="Tipo de Cultivo"
                    ref="input1"
                    :readonly="true"
                    :id="`cosechaInput${i}1`"
                    v-model="data[i].iditem.descitem"
                    @keyup.native.enter="next(1, data[i].iditem.descitem, i)"
                  ></vue-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="2">
                  <c-text-date
                    label="Fecha Inicio"
                    :id="`cosechaInput${i}2`"
                    :rules="[(v) => !!v || 'Campo Obligatorio']"
                    :readonly="!readonly"
                    dense
                    outlined
                    @focus="setSelection(`cosechaInput${i}2`)"
                    v-model="data[i].fecha"
                    @keyup.native.enter="next(2, data[i].fecha, i)"
                  ></c-text-date>
                </v-col>
                <v-col cols="12" md="2" sm="2">
                  <c-text-date
                    label="Fecha Fin"
                    :readonly="!readonly"
                    dense
                    outlined
                    :rules="[]"
                    :id="`cosechaInput${i}3`"
                    @focus="setSelection(`cosechaInput${i}3`)"
                    v-model="data[i].fechafin"
                    @keyup.native.enter="next(3, '-', i)"
                  ></c-text-date>
                </v-col>

                <v-col cols="12" md="3" sm="3">
                  <vue-text-field
                    label="Hectareas a Cosechar"
                    :id="`cosechaInput${i}4`"
                    :readonly="!readonly"
                    @focus="setSelection(`cosechaInput${i}4`)"
                    v-model="data[i].hectarea"
                    @keyup.native.enter="next(4, data[i].hectarea, i)"
                  ></vue-text-field>
                </v-col>
                <v-col cols="12" md="3" sm="3">
                  <c-text-currency
                    label="Produccion en Kg"
                    placeholder="Ejemplo: 10000"
                    outlined
                    dense
                    :rules="[(v) => !!v || 'Obligatorio']"
                    :readonly="!readonly"
                    :id="`cosechaInput${i}5`"
                    v-model="data[i].produccion"
                    @keyup.native.enter="next(5, data[i].produccion, i)"
                  ></c-text-currency>
                </v-col>
                <v-col cols="12" md="3" sm="3">
                  <c-text-currency
                    label="Precio/Tn (Para Referencia)"
                    placeholder="Ejemplo: 400"
                    :readonly="!readonly"
                    :id="`cosechaInput${i}6`"
                    v-model="data[i].precio"
                    suffix="$"
                    outlined
                    dense
                    @keyup.native.enter="next(6, '-', i)"
                  ></c-text-currency>
                </v-col>

                <v-col cols="12" md="3" sm="3">
                  <c-text-field
                    label="Humedad"
                    placeholder="Elemplo: 60"
                    suffix="%"
                    :rules="[]"
                    outlined
                    dense
                    :readonly="!readonly"
                    :id="`cosechaInput${i}7`"
                    v-model="data[i].humedad"
                    @keyup.native.enter="next(7, '0', i)"
                  ></c-text-field>
                </v-col>
                <v-col cols="12" md="3" sm="3">
                  <c-text-field
                    label="Calidad"
                    :readonly="!readonly"
                    :id="`cosechaInput${i}8`"
                    placeholder="Elemplo: 100"
                    suffix="%"
                    outlined
                    dense
                    :rules="[]"
                    v-model="data[i].calidad"
                    @keyup.native.enter="next(8, '0', i)"
                  ></c-text-field>
                </v-col>
                <v-col cols="12" md="3" sm="3">
                  <vue-autocomplete-object
                    :id="`cosechaInput${i}9`"
                    action="deposito/Vact_deposito_siembra"
                    getter="deposito/Vget_deposito_siembra"
                    load="deposito/Vget_deposito_load"
                    validar="iddeposito"
                    label="Deposito"
                    title="desdeposito"
                    :create="true"
                    :readonly="!readonly"
                    @create="formularios.deposito = $event"
                    permission="Deposito"
                    v-model="data[i].iddeposito"
                    @keyup.native.enter="
                      next(9, data[i].iddeposito.iddeposito, i)
                    "
                  />
                </v-col>
                <v-col cols="12" md="6" sm="6">
                  <c-text-field
                    label="Comentario"
                    :id="`cosechaInput${i}10`"
                    :rules="[]"
                    outlined
                    dense
                    placeholder="Elemplo: Cosecha sin ningun problema"
                    :readonly="!readonly"
                    v-model="data[i].comentario"
                    @keyup.native.enter="next(10, '0', i)"
                  ></c-text-field>
                </v-col>
                <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" v-if="data[i].ensilaje === 2 || data[i].ensilaje === 1">
                  <AutocompleteItem
                    v-model="data[i].iditem_ensilaje"
                    :label="`Seleccione el tipo de ${data[i].ensilaje === 2 ? 'Ensilaje' : 'Se. humeda'} que va a producir`"
                  />
                  <!--
                  <AutocompleteItem
                    label="Seleccione el tipo de Ensilaje que va producir"
                    action="Vact_item_data"
                    getter="Vget_item_data"
                    v-model="data[i].iditem_ensilaje.iditem"
                  />
                  -->
                </v-col>
                <v-col cols="12" class="d-flex justify-end align-center">
                  <v-btn
                    class="mr-3"
                    rounded
                    dark
                    small
                    color="grey"
                    @click="expand = !expand"
                  >
                    Registrar Pago de Alquiler
                    <v-icon>{{
                      expand ? "expand_less" : "expand_more"
                    }}</v-icon>
                  </v-btn>
                  <v-btn
                    v-if="!expand"
                    dark
                    color="red darken-1"
                    :id="`cosechaInput${i}aceptar`"
                    :disabled="!readonly"
                    rounded
                    @click="guardarCosecha(i, false)"
                    >Guardar sin Alquiler</v-btn
                  >
                </v-col>
                <v-col cols="12">
                  <Alquilar
                    v-if="expand"
                    :porcentaje="data[i].porcentaje"
                    :cantidad="data[i].cantidad"
                    :produccion="data[i].produccion"
                    :precio="data[i].precio"
                    :descstock="data[i].descstock"
                    @descstock="data[i].descstock = $event"
                    @porcentaje="data[i].porcentaje = $event"
                    @cantidad="data[i].cantidad = $event"
                  />
                </v-col>
              </v-row>
              <v-card-actions v-if="expand && item.hectarea > 0">
                <v-spacer></v-spacer>
                <v-btn
                  color="red darken-1"
                  :disabled="!readonly"
                  rounded
                  dark
                  small
                  @click="guardarCosecha(i, true)"
                  >Guardar con Alquiler</v-btn
                >
              </v-card-actions>
              <v-progress-linear
                v-if="item.hectarea > 0"
                :height="2"
                color="orange"
                value="100"
              ></v-progress-linear>
            </v-form>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <c-btn-crud-close @click="cerrar()"></c-btn-crud-close>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { current_date } from "@/services/util/date.service";
import { mensaje } from "@/services/notify.service";
import { focus } from "@/services/focus.service";

import Alquilar from "./Alquiler";
export default {
  components: {
    Alquilar,
    AutocompleteItem: () => import("@/views/Item/Item/AutocompleteItemVivo2"),
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    idcampana: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    Vget_item_siembra(val) {
      if (val === null) return null;
      this.data = [];
      this.isValid = false;
      val.forEach((x) => {
        if (x.hectarea) {
          this.isValid = true;
        }
        const cosecha = {
          ...this.cosecha,
          ...{ hectarea: x.hectarea || 0 },
          ...{ iditem: JSON.parse(JSON.stringify(x)) },
          ...{ idcampana: this.idcampana },
        };
        this.data.push(cosecha);
      });
      this.item_siembra = JSON.parse(JSON.stringify(val));
      if (this.isValid) this.next(1, "-", 0);
    },
    $route() {
      this.cerrar();
    },
  },
  computed: {
    ...mapGetters("item", ["Vget_item_siembra", "Vget_item_load"]),
    ...mapGetters("cosecha", ["Vget_cosecha_save_load"]),
    ...mapGetters("campana", ["Vget_campana_data", "Vget_campana_load"]),

    overlay() {
      if (this.Vget_cosecha_save_load) return true;
      return false;
    },
  },
  async mounted() {
    this.idcampana_temp = this.idcampana;
    this.idlote_agri_temp = this.$route.params.id;
    this.init(this.idcampana_temp, this.idlote_agri_temp);
  },
  methods: {
    ...mapActions("item", ["Vact_item_siembra"]),
    ...mapActions("siembra", ["Vact_siembra_timeline"]),
    ...mapActions("cosecha", ["Vact_cosecha_save"]),
    ...mapActions("campana", ["Vact_campana_data_byLote"]),
    changeSemilla(val, x, y) {
      if (val == x[0]) {
        return y[0]
      } else if (val == x[1]) {
        return y[1]
      } else {
        return y[2]
      }
    },
    async init(idcampana, idlote_agri) {
      this.isValid = true;
      let e = await this.Vact_item_siembra({ idcampana, idlote_agri });
      if (e != true) {
        this.$notify(mensaje.error(e.message));
        this.isValid = false;
      }
    },
    setSelection(id) {
      setTimeout(() => {
        let input = document.getElementById(id);
        input.focus();
        input.setSelectionRange(0, 10);
      }, 20);
    },
    changeLote(item) {
      this.$router.push({
        path: "/agricultura/campo/detalle/" + item,
      });
      this.init(this.idcampana_temp, this.idlote_agri_temp);
      this.cosecha.idlote_agri.idlote_agri = item;
    },
    next(index, data, i) {
      focus.nextidName("cosechaInput" + i, index, data);
    },
    async guardarCosecha(index, alquiler) {
      let ensilaje = this.data[index].ensilaje
      this.data[index].ensilaje = this.changeSemilla(this.data[index].ensilaje, [0, 1, 2], [false, null, true])
      if (
        this.data[index].fechafin === "" ||
        this.data[index].fechafin === "--"
      ) {
        this.data[index].fechafin = null;
      }
      if (!alquiler) {
        this.data[index].porcentaje = true;
        this.data[index].cantidad = null;
        this.data[index].descstock = false;
      }
      this.data[index].idcampana = JSON.parse(
        JSON.stringify(this.idcampana_temp)
      );
      if (this.data[index].calidad === "") this.data[index].calidad = null;
      if (this.data[index].precio === "") this.data[index].precio = null;
      if (this.data[index].humedad === "") this.data[index].humedad = null;

      const form = index;
      if (!this.$refs[form][0].validate())
        return this.$notify(mensaje.completar());
      let a = await this.Vact_cosecha_save(this.data[index]);
      if (!a.estado) {
        return a.info.map((x) => {
          this.data[index].ensilaje = ensilaje
          this.$notify(mensaje.error(x));
          
        });
      }
      this.$notify(a.info);
      this.Vact_siembra_timeline({
        campana: this.idcampana_temp,
        idlote_agri: this.idlote_agri_temp,
      });
      this.init(this.idcampana_temp, this.idlote_agri_temp);
      this.Vact_campana_data_byLote(this.$route.params.id);
      this.$refs[form][0].resetValidation();
    },
    cerrar() {
      this.$emit("input", false);
    },
  },

  data() {
    return {
      validar: [(v) => !!v || "Seleccion un campo"],
      idlote_agri_temp: "",
      idcampana_temp: "",
      item_siembra: [],
      isValid: false,
      data: [],
      expand: false,
      cosecha: {
        idcosecha: 0,
        ensilaje: false,
        precio: "",
        produccion: "",
        isstock: false,
        hectarea: "",
        iddeposito: {
          iddeposito: "",
        },
        iditem_ensilaje: {
          iditem: null,
        },
        porcentaje: true,
        descstock: false,
        cantidad: "",
        humedad: "",
        calidad: "",
        comentario: "",
        estado: true,
        fecha: current_date(),
        fechafin: current_date(),
        idlote_agri: { idlote_agri: this.$route.params.id },
      },
      formularios: {
        deposito: false,
      },
    };
  },
};
</script>

<style></style>
