<template>
  <v-btn
    @click="$emit('click')"
    color="red"
    :disabled="disabled"
    text
    fab
    x-small
  >
    <v-icon>delete</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style></style>
