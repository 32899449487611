export default {
  tipocontacto_create(state, request) {
    state.tipocontacto_create = request;
  }, 
  tipocontacto_update(state, request) {
    state.tipocontacto_update = request;
  },   
  tipocontacto_reload(state, request) {
    state.tipocontacto_reload = request;
  },   
  tipocontacto_show(state, request) {
    state.tipocontacto_show = request;
  },   
}