<template>
  <div>
    <v-toolbar flat dense>
      <v-toolbar-title>Configuraciones</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-tabs right :background-color="darkMode ? 'black' : 'white'" color="deep-purple accent-4" v-model="tab">
          <v-tab>Ultimas Novedades</v-tab>
          <v-tab v-if="permission.agro.can_view">Conf. Agro</v-tab>
          <v-tab v-if="permission.animal.can_view">Conf. Ganaderia</v-tab>
          <v-tab v-if="permission.rrhh.can_view">Conf. RRHH</v-tab>
        </v-tabs>
      </v-toolbar-items>
    </v-toolbar>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <Novedades></Novedades>
      </v-tab-item>
      <v-tab-item v-if="permission.agro.can_view">
        <Configuracion></Configuracion>
      </v-tab-item>
      <v-tab-item v-if="permission.animal.can_view">
        <ConfiguracionAnimal></ConfiguracionAnimal>
      </v-tab-item>
      <v-tab-item v-if="permission.rrhh.can_view">
        <Settings></Settings>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import Novedades from "./Novedades.vue";
import Configuracion from "../Agro/Configuracion/Configuracion.vue";
import ConfiguracionAnimal from "../Animal/Configuracion/Configuracion.vue";
import Settings from "../RRHH/Settings/Settings.vue";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { ANIMAL, ZAFRA, VENTA, PLANILLA_GIMNASIO, FINANZA, CONTRATO } from "@/constants/forms";
import { PUEDE_LISTAR } from "@/constants/permissions";
export default {
  components: {
    Novedades,
    Configuracion,
    ConfiguracionAnimal,
    Settings
  },
  data() {
    return {
      tab: null,
      permission: {
        animal: {
          can_view: false,
        },
        agro: {
          can_view: false,
        },
        comercial: {
          can_view: false,
        },
        gimnasio: {
          can_view: false,
        },
        finanza: {
          can_view: false,
        },
        rrhh: {
          can_view: false,
        }
      },
    }
  },
  computed: {
    darkMode() {
      return this.$vuetify.theme.dark;
    }
  },
  created() {
    const permissionA = getPermission(ANIMAL).permiso;
    this.permission.animal = {
      can_view: permissionA[PUEDE_LISTAR],
    };
    const permissionB = getPermission(ZAFRA).permiso;
    this.permission.agro = {
      can_view: permissionB[PUEDE_LISTAR],
    };
    const permissionC = getPermission(VENTA).permiso;
    this.permission.comercial = {
      can_view: permissionC[PUEDE_LISTAR],
    };
    const permissionD = getPermission(PLANILLA_GIMNASIO).permiso;
    this.permission.gimnasio = {
      can_view: permissionD[PUEDE_LISTAR],
    };
    const permissionE = getPermission(FINANZA).permiso;
    this.permission.finanza = {
      can_view: permissionE[PUEDE_LISTAR],
    }
    const permissionR = getPermission(CONTRATO).permiso;
    this.permission.rrhh = {
      can_view: permissionR[PUEDE_LISTAR],
    }
  }
};
</script>