
export default {


  Vget_gymplanilla_data: (state) => {
    return state.gymplanilla_data
  },
  Vget_gymplanilla_data_by: (state) => {
    return state.gymplanilla_data_by
  },
  Vget_gymplanilla_update: (state) => {
    return state.gymplanilla_update
  },
  Vget_gymplanilla_load: (state) => {
    return state.gymplanilla_load
  },
  Vget_gymplanilla_save_load: (state) => {
    return state.gymplanilla_save_load
  },
}
