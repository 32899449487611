<template>
  <div>
    <v-dialog :value="value" max-width="700px" persistent @keydown.esc="cancelar()">
      <v-card>
        <v-form ref="form" lazy-validation>
          <v-toolbar flat>
            <v-toolbar-title>{{operacion ? 'Agregar Veterinario' : 'Modificar Veterinario'}}</v-toolbar-title>
          </v-toolbar>
          <v-container>
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  dense
                  type="text"
                  :rules="validar"
                  ref="input1"
                  v-model="data.descveterinario"
                  label="Nombre"
                  required
                  outlined
                  autocomplete="off"
                  @keyup.enter="nextfocus(1)"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  dense
                  type="text"
                  :rules="validar"
                  ref="input2"
                  v-model="data.telveterinario"
                  label="Telefono"
                  required
                  outlined
                  autocomplete="off"
                  @keyup.enter="nextfocus(2)"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  dense
                  type="text"
                  :rules="validar"
                  ref="input3"
                  v-model="data.dirveterinario"
                  label="Direccion"
                  required
                  outlined
                  autocomplete="off"
                  @keyup.enter="nextfocus(3)"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>

          <v-card-actions>
            <v-btn color="red" text @click="cancelar">
              <v-icon>clear</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn ref="aceptar" color="primary" text @click="guardar">
              <v-icon>done</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
        <c-overlay :value="Vget_veterinario_save_load"></c-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { mensaje } from "@/services/notify.service";
export default {
  props: {
    value: Boolean,
    datos: {
      type: [Object, Array],
      default: null,
    },
  },
  data() {
    return {
      data: {
        descveterinario: "",
        telveterinario: "",
        dirveterinario: "",
      },
      default: {
        descveterinario: "",
        telveterinario: "",
        dirveterinario: "",
      },
      validar: [(v) => !!v || "Campo requerido"],
      operacion: true,
    };
  },
  watch: {
    $route(to, from) {
      if (from.path === "/veterinario") return this.cancelar();
    },
  },
  computed: {
    ...mapGetters('veterinario',["Vget_veterinario_save_load"])
  },
  created() {
    this.nuevo(this.datos);
  },
  mounted() {
    setTimeout(() => this.$refs.input1.focus(), 500);
  },
  methods: {
    ...mapActions("veterinario", [
      "Vact_veterinario_data",
      "Vact_veterinario_save",
      "Vact_veterinario_update"
    ]),
    nextfocus(x) {
      switch (x) {
        case 1: {
          if (this.data.descveterinario != "") {
            this.$refs.input2.focus();
          }
          break;
        }
        case 2: {
          if (this.data.telveterinario != "") {
            this.$refs.input3.focus();
          }
          break;
        }
        case 3: {
          if (this.data.dirveterinario != "") {
            this.$refs.aceptar.$el.focus();
          }
          break;
        }
      }
    },
    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$emit("input", false);
      this.$emit("datos", null);
      this.Vact_veterinario_data();
      this.operacion = true;
    },
    async guardar_finalizar() {
      let a = await this.Vact_veterinario_save(this.data);
      if (a.estado == true) {
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$notify(a.info);
        this.$refs.form.resetValidation();
        this.$refs.input1.focus();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
    },
    async editar_finalizar() {
      const id = this.datos.idveterinario;
      let a = await this.Vact_veterinario_update({id, data: this.data});
      if (a.estado == true) {
        this.$notify(a.info);
        this.cancelar();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
    },
    guardar() {
      if (!this.$refs.form.validate()) return this.$notify(mensaje.completar());
      if (this.operacion === true) return this.guardar_finalizar();
      if (this.operacion === false) return this.editar_finalizar();
    },

    nuevo(val) {
      if (val !== null) {
        this.data = JSON.parse(JSON.stringify(val));
        this.operacion = false;
      }
    },
  },
};
</script>

