<template>
  <div class="mx-2">
    <v-toolbar flat dense>
      <v-toolbar-title>Saldo a pagar</v-toolbar-title>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="getSaldos"
      :loading="isFuncionarioLoading"
      hide-default-footer
    >
      <template v-slot:[`item.monto`]="{ item }">
        <div class="font-weight-black">
          {{toCurrency(item.monto) }}
        </div>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { currency } from "@/services/util/number.service";
export default {
  props: {
    props: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    headers: [
      {
        text: "Moneda",
        align: "left",
        value: "idmoneda.descmoneda",
      },
      {
        text: "Monto",
        align: "right",
        value: "monto",
      },
    ],
  }),
  mounted() {
    this.fetchSaldos(this.props.idcontacto.idcontacto);
  },
  computed: {
    ...mapGetters("funcionario", ["isFuncionarioLoading", "getSaldos"]),
  },
  methods: {
    ...mapActions("funcionario", ["fetchSaldos"]),
        toCurrency(value) {
      return currency(value);
    },
  },
};
</script>
