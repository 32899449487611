<template>
  <c-dialog-confirmation
    :value="value"
    @cancel="close()"
    @done="save()"
    :loading="isAguinaldoLoading"
    >Desea eliminar la aguinaldo de
    <strong>{{ props.idcontacto.nombre }}</strong
    >?</c-dialog-confirmation
  >
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    value: Boolean,
    props: Object,
  },
  computed: {
    ...mapGetters("aguinaldo", ["isAguinaldoLoading"]),
  },
  watch: {
    $route() {
      return this.close();
    },
  },
  methods: {
    ...mapActions("aguinaldo", ["setAguinaldoDelete", "fetchAguinaldo"]),

    async save() {
      const id = this.props.idaguinaldo;
      const response = await this.setAguinaldoDelete(id);
      if (!response.success) return null;
      this.close();
    },
    close() {
      this.$emit("datos", null);
      this.$emit("input", false);
      this.fetchAguinaldo();
    },
  },
};
</script>

<style></style>
