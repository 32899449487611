<template>
  <div>
    <v-toolbar flat dense>
      <v-toolbar-title>Maquinarias y Rodados</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-sheet class="d-flex justify-end mb-2">
      <v-slide-group multiple show-arrows>
        <v-slide-item>
          <v-btn
            active-class="deep-purple--text accent-4"
            exact
            text
            tile
            to="/maquinaria"
            >Inicio</v-btn
          >
        </v-slide-item>
        <v-slide-item>
          <v-menu bottom left offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                tile
                class="align-self-center"
                v-bind="attrs"
                v-on="on"
              >
                Maquinarias
                <v-icon right>arrow_drop_down</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                v-for="(sub, subkey) in menu.maquinaria"
                :key="subkey"
                :to="sub.route"
                active-class="deep-purple--text accent-4"
                >{{ sub.title }}</v-list-item
              >
            </v-list>
          </v-menu>
        </v-slide-item>
        <v-slide-item>
          <v-menu bottom left offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                tile
                class="align-self-center"
                v-bind="attrs"
                v-on="on"
              >
                Reportes
                <v-icon right>arrow_drop_down</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                v-for="(sub, subkey) in menu.reporte"
                :key="subkey"
                :to="sub.route"
                active-class="deep-purple--text accent-4"
                >{{ sub.title }}</v-list-item
              >
            </v-list>
          </v-menu>
        </v-slide-item>
        <v-slide-item>
          <v-menu bottom left offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                tile
                class="align-self-center"
                v-bind="attrs"
                v-on="on"
              >
                Mantenimientos
                <v-icon right>arrow_drop_down</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item
                v-for="(sub, subkey) in menu.mantenimientos"
                :key="subkey"
                :to="sub.route"
                active-class="deep-purple--text accent-4"
                >{{ sub.title }}</v-list-item
              >
            </v-list>
          </v-menu>
        </v-slide-item>
        <v-slide-item v-if="!configMaquinaria">
            <v-btn
              tile
              text
              exact
              active-class="deep-purple--text accent-4"
              to="/maquinaria/configuracion"
              ><v-icon>settings</v-icon></v-btn
            >
          </v-slide-item>
      </v-slide-group>
    </v-sheet>
    <v-divider></v-divider>
    <v-card>
      <transition>
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </transition>
    </v-card>
  </div>
</template>
<script>
import { getPermission } from "@/services/storage/permissionStorage.service";
import { MAQUINARIA, CONFIG_MAQUINARIA } from "@/constants/forms";
import { PUEDE_LISTAR } from "@/constants/permissions";
import { mensaje } from "@/services/notify.service";
export default {
  data() {
    return {
      menu: {
        permission: {
          maquinaria: false,
        },
        maquinaria: [
          {
            title: "Maquinaria",
            route: "/maquinaria/maquinaria/maquinaria",
          },
          {
            title: "Mantenimiento",
            route: "/maquinaria/maquinaria/maquinariamantenimiento",
          },
          {
            title: "Asignacion",
            route: "/maquinaria/maquinaria/maquinariaasignacion",
          },
          {
            title: "Gasto",
            route: "/maquinaria/maquinaria/maquinariagasto",
          },
          {
            title: "Transporte",
            route: "/maquinaria/maquinaria/maquinariatransporte",
          },
          {
            title: "Salario",
            route: "/maquinaria/maquinaria/maquinariasalario",
          }
        ],
        reporte: [
          {
            title: "Reporte",
            route: "/maquinaria/reporte/reporte",
          }
        ],
        mantenimientos: [
          {
            title: "Fabricante",
            route: "/maquinaria/mantenimiento/fabricante",
          },
          {
            title: "Tipo",
            route: "/maquinaria/mantenimiento/tipo",
          },
          {
            title: "Tipo Mantenimiento",
            route: "/maquinaria/mantenimiento/tipomantenimiento",
          },
          {
            title: "Tipo Gasto Maquinaria",
            route: "/maquinaria/mantenimiento/tipogastomaquinaria",
          },
        ],
      },
    };
  },
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
  },
  mounted() {
    const permission = getPermission(MAQUINARIA).permiso;
    this.permission = {
      maquinaria: permission[PUEDE_LISTAR],
    };
  },
  computed: {
    configMaquinaria() {
      const permissionConfigMaquinaria = getPermission(CONFIG_MAQUINARIA).permiso;
      return mensaje.permission(permissionConfigMaquinaria);
    },
  },
};
</script>