export default {
  ajustecuenta_data(state, request) {
    state.ajustecuenta_data = request;
  }, 
  ajustecuenta_load(state, request) {
    state.ajustecuenta_load = request;
  }, 
  ajustecuenta_save_load(state, request) {
    state.ajustecuenta_save_load = request;
  }, 
}