
export default {
  Vget_cheque_data: (state) => {
    return state.cheque_data
  },
  Vget_cheque_data_desembolso: (state) => {
    return state.cheque_data_desembolso
  },
  Vget_cheque_load: (state) => {
    return state.cheque_load
  },
  Vget_cheque_save_load: (state) => {
    return state.cheque_save_load
  },
}
