<template>
  <v-card @click="$emit('click')" outlined>
    <v-progress-linear
      height="2"
      color="red"
      rounded
      value="100"
    ></v-progress-linear>
    <v-container>
      <span class="font-weight-bold caption">{{ item.name }}</span>
      <v-divider></v-divider>
      <table style="width: 100%">
        <tr>
          <td>
            <span class="font-weight-light caption">Compra:</span>
          </td>
          <td style="text-align: right">
            <span class="font-weight-bold caption">{{ item.compra }}</span>
          </td>
        </tr>
        <tr>
          <td>
            <span class="font-weight-light caption">Venta:</span>
          </td>
          <td style="text-align: right">
            <span class="font-weight-bold caption">{{ item.venta }}</span>
          </td>
        </tr>
      </table>
    </v-container>
    <div class="ml-3 mb-1" style="font-size: 12px">
      <v-icon small>autorenew</v-icon> {{ item.time }}
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
    },
  },
};
</script>

<style></style>
