<template>
  <v-dialog :value="value" max-width="1000" persistent>
    <v-card>
      <v-toolbar flat dense>
        <v-toolbar-title>Registro de Funcionario</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-tabs color="deep-purple accent-4" right v-model="tab">
            <v-tab>Informacion</v-tab>
            <v-tab v-if="!has_permission">Contrato</v-tab>
            <v-tab>Ajustes</v-tab>
            <v-tab>Archivos</v-tab>
            <v-tab>Saldos</v-tab>
          </v-tabs>
        </v-toolbar-items>
      </v-toolbar>
      <v-divider></v-divider>
      <div class="d-flex">
        <v-col cols="4">
          <v-card>
            <v-timeline dense style="max-height: 80vh" class="overflow-y-auto">
              <div v-for="(item, key) of timeline" :key="key">
                <v-timeline-item fill-dot large color="orange">
                  <v-row class="pt-3">
                    <strong>{{ key }}</strong>
                  </v-row>
                </v-timeline-item>
                <div v-for="(sub, o) of item" :key="o">
                  <v-timeline-item fill-dot color="deep-purple accent-4" small>
                    <v-row class="pt-2 d-flex flex-column">
                      <span class="caption">{{ sub.day_month }}</span>
                      <strong>{{ sub.operacion }}</strong>
                      <span class="caption">{{ sub.comentario }}</span>
                    </v-row>
                  </v-timeline-item>
                </div>
              </div>
            </v-timeline>
          </v-card>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col cols="8">
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <Information :props="props" :editable="editable" />
            </v-tab-item>
            <v-tab-item>
              <Contracts :props="props" />
            </v-tab-item>
             <v-tab-item>
              <Ajustes :props="props" />
            </v-tab-item>
            <v-tab-item>
              <DocsFuncionario :props="props" />
            </v-tab-item>
            <v-tab-item>
              <Saldos :props="props" />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </div>
      <v-divider></v-divider>
      <v-card-actions>
        <c-btn-crud-close @click="cancelar()"></c-btn-crud-close>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Contracts from "./Contracts/Contracts";
import Information from "./Information/Information";
import DocsFuncionario from "./Information/DocsFuncionario";
import Saldos from "./Saldos";
import Ajustes from "./Ajustes"
import {
  getYearFromDate,
  getDayMonthFromDate,
} from "@/services/util/date.service";
import { groupByKey } from "@/services/util/array.service";
import { mensaje } from "@/services/notify.service";
import { CONTRATO } from "@/constants/forms";
import { getPermission } from "@/services/storage/permissionStorage.service";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";
export default {
  components: {
    Ajustes,
    Contracts,
    Information,
    DocsFuncionario,
    Saldos
  },
  props: {
    value: Boolean,
    props: {
      type: Object,
      default: null,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    tab: null,
    timeline: [],
    items: [
      { title: "Datos Funcionarios", icon: "dashboard" },
      { title: "Documentos", icon: "forum" },
    ],
    permission_contrato: {
      can_add: false,
      can_view: false,
      can_update: false,
      can_delete: false,
    },
  }),
  created() {
    const permission_contrato = getPermission(CONTRATO).permiso;
    this.permission_contrato = {
      can_add: permission_contrato[PUEDE_AGREGAR],
      can_view: permission_contrato[PUEDE_LISTAR],
      can_update: permission_contrato[PUEDE_EDITAR],
      can_delete: permission_contrato[PUEDE_ELIMINAR],
    };
  },
  mounted() {
    this.fetchTimelineFuncionario(this.props.idcontacto.idcontacto);
  },
  watch: {
    $route() {
      this.cancelar();
    },
    getTimelineFuncionario(val) {
      val.map((x) => {
        x.year = getYearFromDate(x.fecha);
        x.day_month = getDayMonthFromDate(x.fecha);
      });
      this.timeline = JSON.parse(JSON.stringify(groupByKey(val, "year")));
    },
  },
  computed: {
    ...mapGetters("timelinefuncionario", [
      "getTimelineFuncionario",
      "isTimelineFuncionarioLoading",
    ]),
    has_permission() {
      return mensaje.permission(this.permission_contrato);
    },
  },
  methods: {
    ...mapActions("timelinefuncionario", ["fetchTimelineFuncionario"]),
    cancelar() {
      this.$emit("input", false);
    },
  },
};
</script>

<style></style>
