// https://vuex.vuejs.org/en/getters.html

export default {
  loggedIn: (state) => {
    return state.accessToken ? true : false
  },

  authenticationErrorCode: (state) => {
    return state.authenticationErrorCode
  },

  authenticationError: (state) => {
    return state.authenticationError
  },

  authenticating: (state) => {
    return state.authenticating
  },
  getAccessToken:(state)=> state.accessToken
}