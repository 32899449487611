<template>
  <div>
    <v-dialog v-model="dialogo" max-width="700px" persistent :key="reload" @keydown.esc="cancelar()">
      <v-card>
        <v-card-title>
          <span class="headline">Detalles</span>
        </v-card-title>
        <v-card-text>
          <v-row row dense>
            <v-col cols="12" sm="4">
              <v-switch
                v-model="data.stock"
                :label="`Stockeable?: ${stockeable}`"
                outlined
                readonly
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="4">
              <v-switch
                v-model="data.tipoitem"
                :label="`Es un ser vivo?: ${tipo}`"
                outlined
                readonly
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="4">
              <v-switch
                v-model="data.porcentaje"
                :label="`Precio/Porcentaje?: ${porcentaje}`"
                outlined
                readonly
              ></v-switch>
            </v-col>
            <v-col cols="12">
              <v-text-field
                dense
                type="text"
                v-model="data.descitem"
                label="Descripcion"
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                dense
                v-model="data.idunidadmedida.descunidadmedida"
                label="Unidad de medida"
                persistent-hint
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                dense
                v-model="data.cant_contiene"
                :label="`Contenido del Item en ${data.idunidadmedida.descunidadmedida || 'Unidad'}`"
                persistent-hint
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                dense
                v-model="data.idmoneda.descmoneda"
                label="Moneda"
                persistent-hint
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                dense
                v-model="data.precitem"
                label="Precio Compra"
                persistent-hint
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                dense
                v-model="data.preciov"
                :label="data.porcentaje ? 'Porcentaje Ganancia' : 'Precio Venta'"
                persistent-hint
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                dense
                v-model="data.minitem"
                label="Cantidad minima"
                persistent-hint
                outlined
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                dense
                v-model="data.idmarca.descmarca"
                label="Marca"
                persistent-hint
                outlined
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                dense
                v-model="data.idcategoriaitem.desccategoriaitem"
                label="Categoria de item"
                persistent-hint
                outlined
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                dense
                v-model="data.idcuenta.desccuenta"
                label="Cuenta"
                persistent-hint
                outlined
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-btn color="red" text @click="cancelar"><v-icon>clear</v-icon></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      data: {
        idcategoriaitem: {
          idcategoriaitem: "",
          desccategoriaitem: ""
        },
        descitem: "",
        idmarca: {
          idmarca: "",
          descmarca: ""
        },
        idmoneda: {
          idmoneda: "",
          descmoneda: ""
        },
        idunidadmedida: {
          idunidadmedida: "",
          descunidadmedida: ""
        },
        preciov: "",
        porcentaje: false,
        precitem: "",
        minitem: "",
        tipoitem: false,
        stock: true,
        idcuenta: {
          idcuenta: "",
          desccuenta: ""
        }
      },
      default: {
        idcategoriaitem: {
          idcategoriaitem: "",
          desccategoriaitem: ""
        },
        descitem: "",
        idmarca: {
          idmarca: "",
          descmarca: ""
        },
        idunidadmedida: {
          idunidadmedida: "",
          descunidadmedida: ""
        },
        idmoneda: {
          idmoneda: "",
          descmoneda: ""
        },
        preciov: "",
        porcentaje: false,
        precitem: "",
        minitem: "",
        tipoitem: false,
        stock: true,
        idcuenta: {
          idcuenta: "",
          desccuenta: ""
        }
      },
      dialogo: false,
      reload: 0
    };
  },

  computed: {
    ...mapGetters("item", {
      create: "item_show",
      update: "item_update"
    }),
    stockeable() {
      if (this.data.stock === true) {
        return "Si";
      } else {
        return "No";
      }
    },
    porcentaje() {
      if (this.data.porcentaje === true) {
        return "Si";
      } else {
        return "No";
      }
    },
    tipo() {
      if (this.data.tipoitem === true) {
        return "Si";
      } else {
        return "No";
      }
    }
  },
  created() {
    this.show(this.update);
  },
  watch: {
    $route(to, from) {
      if (from.path === "/item") return this.cancelar();
    }
  },
  methods: {
    ...mapActions("item", ["item_show", "item_update"]),
    cancelar() {
      this.data = this.default;
      this.dialogo = false;
      this.item_update(null);
      this.item_show(false);
      this.reload++;
    },

    show(val) {
      this.dialogo = true;
      this.data = val;
    }
  }
};
</script>
