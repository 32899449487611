<template>
  <div>
    <v-dialog
      :value="value"
      max-width="900px"
      persistent
      @keydown.esc="cancelar()"
    >
      <v-card>
        <v-form ref="form" lazy-validation>
          <v-toolbar flat dense>
            <v-toolbar-title>Modificar Animal</v-toolbar-title>
          </v-toolbar>
          <v-container>
            <v-row row dense>
              <v-col cols="12" sm="6" md="6">
                <c-text-field
                  label="Nombre"
                  :rules="[]"
                  dense
                  outlined
                  v-model="data.nombanimal"
                />
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <c-text-field
                  label="RP Animal"
                  dense
                  outlined
                  v-model="data.rpanimal"
                />
              </v-col>
              <v-col cols="12" sm12>
                <AutocompleteLote 
                   v-model="data.idlote.idlote"
                />
              </v-col>
              <v-col cols="12" sm12 md12>
                <v-autocomplete
                  v-model="data.idestadoanimal.idestadoanimal"
                  :rules="validars"
                  :loading="loadestadoanimal"
                  v-bind:items="estadoanimal"
                  outlined
                  height="12"
                  dense
                  label="Seleccione estado animal"
                  item-text="fkdes"
                  item-value="id"
                  required
                  :no-data-text="`No existe ninguna concidencia`"
                  small-chips
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="6" v-if="opcion_avanzada">
                <c-text-date
                  dense
                  outlined
                  label="Fecha de nacimiento"
                  v-model="data.fechanimal"
                />
              </v-col>
              <v-col cols="12" sm="6" md="6" v-if="opcion_avanzada">
                <vue-text-currency
                  label="Valor del animal"
                  v-model="data.montanimal"
                />
              </v-col>
              <v-col cols="12" sm="6" v-if="opcion_avanzada">
                <vue-autocomplete-generic
                  label="Seleccione el item"
                  action="item/Vact_item_vivo"
                  getter="item/Vget_item_vivo"
                  load="item/Vget_item_load"
                  pk="iditem"
                  title="descitem"
                  permission="Item"
                  :create="true"
                  @create="item_open = $event"
                  v-model="data.iditem.iditem"
                />
                <div v-if="item_open">
                  <Item-Item-create v-model="item_open" />
                </div>
              </v-col>

              <v-col cols="12" sm="6" v-if="opcion_avanzada">
                <vue-autocomplete-generic
                  label="Seleccione procedencia"
                  action="procedencia/Vact_procedencia_data"
                  getter="procedencia/Vget_procedencia_data"
                  load="procedencia/Vget_procedencia_load"
                  pk="idprocedencia"
                  title="descprocedencia"
                  permission="Procedencia"
                  @create="formularios.procedencia = $event"
                  :create="true"
                  v-model="data.idprocedencia.idprocedencia"
                />
                <div v-if="formularios.procedencia">
                  <Animal-procedencia-create
                    v-model="formularios.procedencia"
                  />
                </div>
              </v-col>

              <v-col cols="12" sm="6" v-if="opcion_avanzada">
                  <AutocompleteRaza
                    v-model="data.idraza.idraza"
                  />
      
              </v-col>
              <v-col cols="12" sm="6" v-if="opcion_avanzada">
                <vue-autocomplete-generic
                  action="deposito/Vact_deposito_data"
                  getter="deposito/Vget_deposito_data"
                  load="deposito/Vget_deposito_load"
                  pk="iddeposito"
                  label="Deposito"
                  title="desdeposito"
                  :create="true"
                  @create="formularios.deposito = $event"
                  permission="Deposito"
                  v-model="data.iddeposito.iddeposito"
                />
                <div v-if="formularios.deposito">
                  <Item-Deposito-create v-model="formularios.deposito" />
                </div>
              </v-col>

              <v-col :cols="isMacho ? 9 : 12" v-if="opcion_avanzada">
                <input-autocomplete
                  label="Seleccione Sexo"
                  api="/apisexocria/"
                  pk="idsexo"
                  title="descsexocria"
                  vuex="sexo/sexo_create"
                  :noactive="false"
                  v-model="data.idsexo.idsexo"
                />
              </v-col>
              <v-col cols="3" class="mt-n3" v-if="isMacho && opcion_avanzada">
                <v-checkbox v-model="data.issemen" label="Semen?"></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="red" text @click="cancelar">
              <v-icon dark>close</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              v-if="!getNominaId"
              text
              rounded
              color="orange"
              @click="formularios.nomina = !formularios.nomina"
              >Agregar a Produccion</v-btn
            >
            <v-btn
              v-if="opcion_avanzada === false"
              color="blue darken-1"
              text
              @click="opcion_avanzada = true"
            >
              <v-icon dark>expand_more</v-icon>
            </v-btn>
            <v-btn
              v-if="opcion_avanzada === true"
              color="blue darken-1"
              text
              @click="opcion_avanzada = false"
            >
              <v-icon dark>expand_less</v-icon>
            </v-btn>

            <v-btn ref="aceptar" color="blue darken-1" @click="guardar" text>
              <v-icon dark>check</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
        <c-overlay :value="isLoading"></c-overlay>
        <Nomina
          v-if="formularios.nomina"
          v-model="formularios.nomina"
          :props="datos"
          @reset="fetchNominaId(datos.idanimal)"
        />
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { db } from "@/services/db.service";
import { mensaje } from "@/services/notify.service";
import Nomina from "./Nomina";
import AutocompleteRaza from "../Raza/Autocomplete";
import AutocompleteLote from "../Lote/Autocomplete";
export default {
  components: {
    AutocompleteLote,
    AutocompleteRaza,
    Nomina,
  },
  props: {
    value: Boolean,
    datos: {
      type: [Object, Array],
      default: null,
    },
  },
  data() {
    return {
      overlay: false,
      noData: true,
      item_open: false,
      opcion_avanzada: false,
      data: {
        fechanimal: "",
        nombanimal: "",
        rpanimal: "",
        issemen: false,
        idestadoanimal: {
          idestadoanimal: "",
        },
        idprocedencia: {
          idprocedencia: "",
        },
        idraza: {
          idraza: "",
        },
        idlote: {
          idlote: "",
        },
        iddeposito: {
          iddeposito: "",
        },
        iditem: {
          iditem: "",
        },
        idsexo: {
          idsexo: "",
        },
        montanimal: "",
      },
      default: {
        fechanimal: "",
        nombanimal: "",
        rpanimal: "",
        issemen: false,
        idestadoanimal: {
          idestadoanimal: "",
        },
        idprocedencia: {
          idprocedencia: "",
        },
        idraza: {
          idraza: "",
        },
        idlote: {
          idlote: "",
        },
        iddeposito: {
          iddeposito: "",
        },
        iditem: {
          iditem: "",
        },
        idsexo: {
          idsexo: "",
        },
        montanimal: "",
      },
      validars: [(v) => !!v || "Seleccione un campo"],
      operacion: true,
      estadoanimal: [],
      loadestadoanimal: true,
      formularios: {
        nomina: false,
        raza: false,
        lote: false,
        procedencia: false,
        deposito: false,
      },
    };
  },
  computed: {
    ...mapGetters("animal", ["isLoading", "getNominaId"]),
    isMacho() {
      return this.data.idsexo.idsexo === 1 ? true : false;
    },
  },
  created() {
    this.nuevo(this.datos);
    if (this.datos.idsexo.idsexo === 2)
      return this.fetchNominaId(this.datos.idanimal);
    this.fetchNominaId(null);
  },
  watch: {
    $route() {
      return this.cancelar();
    },
  },
  methods: {
    ...mapActions("animal", [
      "fetchAnimal",
      "setAnimalUpdate",
      "fetchNominaId",
    ]),
    ...mapActions("stock", ["Vact_stock_data"]),

    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$emit("input", false);
      this.$emit("datos", null);
      this.fetchAnimal();
      this.Vact_stock_data();
      this.operacion = true;
    },
    async guardar() {
      if (!this.$refs.form.validate()) return this.$notify(mensaje.completar());
      if (this.operacion) return null;
      const id = this.datos.idanimal;
      if (this.data.nombanimal === "") {
        this.data.nombanimal = null;
      }
      const response = await this.setAnimalUpdate({ id, data: this.data });
      if (response.success) {
        this.cancelar();
      }
    },

    nuevo_estadoanimal(x) {
      db.get("/apiestadoanimal/").then((a) => {
        a.map((element) => {
          if (
            element.idestadoanimal === 1 ||
            element.idestadoanimal === 2 ||
            element.idestadoanimal === 11 ||
            element.idestadoanimal === x
          ) {
            this.estadoanimal.push({
              id: element.idestadoanimal,
              fkdes: element.descestadoanimal,
            });
          }
        });
        this.loadestadoanimal = false;
      });
    },
    async nuevo(val) {
      this.nuevo_estadoanimal(val.idestadoanimal.idestadoanimal);
      if (val === null) return null;
      this.data = JSON.parse(JSON.stringify(val));
      this.operacion = false;
    },
  },
};
</script>
