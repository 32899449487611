export default [
  {
    path: '/usuarioempresa',
    name: 'Usuario Empresa',
    component: () => import( /* webpackChunkName: "Cotizacion" */ '@/views/Configuraciones/users/Users.vue'),
  },
  {
    path: '/agenda',
    name: 'Agenda',
    component: () => import( /* webpackChunkName: "Cotizacion" */ '@/views/Configuraciones/Agenda/Agenda.vue'),
  },
  {
    path: '/configimpresion',
    name: 'Configuracion de Impresion',
    component: () => import( /* webpackChunkName: "Cotizacion" */ '@/views/Configuraciones/Configimpresion/Configimpresion.vue'),
  },
  {
    path: '/rolusuario',
    name: 'Rol de Usuario',
    component: () => import( /* webpackChunkName: "Tambo" */ '@/views/Configuraciones/modulos/Modulos.vue'),
  },
]