<template>
  <div>
    <div v-if="crud.add">
      <Venta-CobroCuenta-Create v-model="crud.add" />
    </div>
    <div v-if="crud.view">
      <Venta-CobroCuenta-Show
        v-model="crud.view"
        :datos="crud.datos"
        @datos="datos = $event"
      />
    </div>

    <v-card>
      <v-alert type="error" v-if="permission_alert"
        >Error!!! Este usuario no tiene permisos en esta ventana</v-alert
      >
      <v-toolbar flat dense>
        <v-toolbar-title>{{ titulo }}</v-toolbar-title>
        <widgets-Favorite :ruta="$route.path" :formulario="$route.name" />
        <v-divider class="mx-2" inset vertical></v-divider>

        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          :append-icon="search.length > 0 ? 'cloud_download' : 'search'"
          placeholder="Busqueda por Razon Social y Ruc"
          autocomplete="off"
          label="Busqueda"
          class="mr-1"
          single-line
          hide-details
          @click:append="busqueda()"
          v-if="permission.can_view"
        ></v-text-field>
        <widgets-filteradvanced
          v-model="searchadvanced"
          :campos="campos"
          @resp="$store.commit('cobrocuenta/cobrocuenta_data', $event)"
          url="/apireciboventasearch/"
        />
        <v-toolbar-items>
          <v-speed-dial v-model="opcion" direction="left" class="mr-1">
            <template v-slot:activator>
              <v-btn v-model="opcion" small color="red" height="100%" tile dark>
                <v-icon v-if="opcion">keyboard_arrow_up</v-icon>
                <v-icon v-else>keyboard_arrow_down</v-icon>
              </v-btn>
            </template>
            <v-btn fab dark small color="indigo" @click="searchadvanced = true">
              <v-icon>zoom_in</v-icon>
            </v-btn>
            <v-btn fab dark small color="green" @click="generarCsv()">
              <v-icon>grid_on</v-icon>
            </v-btn>
            <v-btn fab dark small color="red" @click="generarPdf()">
              <v-icon>get_app</v-icon>
            </v-btn>
          </v-speed-dial>

          <c-btn-table-add
            @click="crud.add = true"
            v-if="permission.can_add"
          ></c-btn-table-add>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table
        :loading="Vget_cobrocuenta_load"
        :headers="headers"
        :items="Vget_cobrocuenta_data"
        :search="search"
        class="elevation-1"
        v-if="permission.can_view"
      >
        <template v-slot:[`item.accion`]="{ item }">
          <c-btn-table-view
            class="mr-2"
            @click="rowselect(item)"
          ></c-btn-table-view>
          <c-btn-table-delete
            :disabled="item.anulreciboventa"
            @click="meliminar(item)"
            v-if="permission.can_delete"
          ></c-btn-table-delete>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogoeliminar" persistent max-width="350">
      <v-card>
        <v-card-title>
          <v-icon left>info</v-icon>
          <span class="title font-weight-light">Ventana de Confirmacion</span>
        </v-card-title>
        <v-card-text>Desea eliminar {{ objeto.nroreciboventa }}?</v-card-text>
        <v-card-actions>
          <v-btn color="red" text @click="eliminarcancelar">
            <v-icon>clear</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="meliminarguardar">
            <v-icon>done</v-icon>
          </v-btn>
        </v-card-actions>
        <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { exportTo } from "@/services/export.service";
import { COBRO_CUENTA } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";

export default {
  data() {
    return {
      crud: {
        add: false,
        view: false,
      },
      overlay: false,
      searchadvanced: false,
      opcion: false,
      search: "",
      headers: [
        { text: "Codigo", align: "left", value: "idreciboventa" },
        { text: "Recibo", align: "left", value: "nroreciboventa" },
        { text: "Fecha", align: "left", value: "fechreciboventa" },
        {
          text: "Cliente",
          align: "left",
          value: "idcontacto.idcontacto.desccontacto",
        },
        {
          text: "Ruc",
          align: "left",
          value: "idcontacto.idcontacto.ruccontacto",
        },
        { text: "Moneda", align: "left", value: "idmoneda.descmoneda" },
        { text: "Total", align: "left", value: "totalrecibo" },
        { text: "Accion", value: "accion", sortable: false, align: "right" },
      ],
      data: {
        idcontacto: {
          idcontacto: {
            desccontacto: "",
          },
        },
      },
      dialogo: false,
      titulo: COBRO_CUENTA,
      objeto: "",
      index: "",
      dialogoeliminar: false,
      load: false,
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
      campos: [
        {
          name: "Cliente",
          api: "/apicontacto/?tipo=cliente",
          id: "idcontacto",
          desc: "desccontacto",
          type: "select",
          query: [
            {
              comparison: "",
              value: "",
              logical: "",
              add: false,
            },
          ],
        },
        {
          name: "Nro Recibo",
          value: "nroreciboventa",
          type: "text",
          query: [
            {
              comparison: "",
              value: "",
              logical: "",
              add: false,
            },
          ],
        },
        {
          name: "Moneda",
          api: "/apimoneda/",
          id: "idmoneda",
          desc: "descmoneda",
          type: "select",
          query: [
            {
              comparison: "",
              value: "",
              logical: "",
              add: false,
            },
          ],
        },
        {
          name: "Fecha Cobro",
          value: "fechreciboventa",
          type: "date",
          query: [
            {
              comparison: "",
              value: "",
              logical: "",
              add: false,
            },
          ],
        },
        {
          name: "Anulado",
          value: "anulreciboventa",
          type: "boolean",
          query: [
            {
              comparison: "",
              value: "",
              logical: "",
              add: false,
            },
          ],
        },
      ],
    };
  },

  watch: {
    // eslint-disable-next-line
    reload(val) {
      if (val === true) {
        this.mcargartabla();
      }
    },
  },
  async created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    const permission = getPermission(COBRO_CUENTA).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
    await this.Vact_cobrocuenta_data();
  },
  computed: {
    ...mapGetters("cobrocuenta", [
      "Vget_cobrocuenta_data",
      "Vget_cobrocuenta_load",
    ]),
    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },

  methods: {
    ...mapActions("cobrocuenta", [
      "Vact_cobrocuenta_data",
      "Vact_cobrocuenta_del",
    ]),
    ...mapActions("caja", ["Vact_caja_data"]),
    async busqueda() {
      this.Vact_cobrocuenta_data(this.search);
    },
    meliminar(item) {
      this.objeto = Object.assign({}, item);
      this.dialogoeliminar = true;
    },
    rowselect(val) {
      this.crud.datos = JSON.parse(JSON.stringify(val));
      this.crud.view = true;
    },
    eliminarcancelar() {
      this.index = "";
      this.objeto = "";
      this.dialogoeliminar = false;
    },
    async meliminarguardar() {
      this.overlay = true;
      const id = this.objeto.idreciboventa;
      const a = await this.Vact_cobrocuenta_del(id);
      if (a.estado == true) {
        this.$notify(a.info);
        this.dialogoeliminar = false;
        this.Vact_caja_data();
        this.eliminarcancelar();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.overlay = false;
    },

    generarPdf() {
      let head = [["Fecha", "Cliente", "Nro Factura", "Moneda", "Total"]];
      let body = [];
      this.Vget_cobrocuenta_data.map((x) => {
        body.push([
          x.fechreciboventa,
          x.idcontacto.idcontacto.desccontacto,
          x.nroreciboventa,
          x.idmoneda.descmoneda,
          x.totalrecibo,
        ]);
      });
      exportTo.pdftable(head, body, "Lista de Recibos");
    },
    generarCsv() {
      let data = [];
      this.Vget_cobrocuenta_data.forEach((x) => {
        data.push({
          Fecha: x.fechreciboventa,
          Cliente: x.idcontacto.idcontacto.desccontacto,
          Factura: x.nroreciboventa,
          Moneda: x.idmoneda.descmoneda,
          Total: x.totalrecibo,
        });
      });
      exportTo.xlsx(data);
    },
  },
};
</script>
