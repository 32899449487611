<template>
  <c-dialog-confirmation
    :value="value"
    @cancel="close()"
    @done="save()"
    :loading="isValidacionLoading"
    >Desea eliminar el registro <strong>{{ props.nombre }}</strong
    >?</c-dialog-confirmation
  >
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    value: Boolean,
    props: Object,
  },
  computed: {
    ...mapGetters("validacion", ["isValidacionLoading"]),
  },
  watch: {
    $route() {
      return this.close();
    },
  },
  methods: {
    ...mapActions("validacion", ["setValidacionDelete", "fetchValidacion"]),
    async save() {
      const id = this.props.idagro_validacion_bot;
      const response = await this.setValidacionDelete(id);
      if (!response.success) return null;
      this.close();
    },
    close() {
      this.$emit("datos", null);
      this.$emit("input", false);
      this.fetchValidacion();
    },
  },
};
</script>