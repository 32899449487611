<template>
  <div v-if="isActivemap">
    <v-card>
      <v-container
        :class="
          `${
            minimizar
              ? `position-dialog-minimize`
              : displaySM
              ? `position-dialog-sm`
              : `position-dialog`
          } overflow-y-auto px-4 pt-1 mt-0`
        "
        fluid
      >
        <v-row style="max-height:83vh">
          <v-card>
            <v-toolbar flat>
              AgroTech
              <widgets-Favorite
                ruta="/agricultura/campo"
                formulario="Mapa de Lotes"
              />
              <v-btn small fab text color="primary" @click="openHelp = true">
                <v-icon>help</v-icon>
              </v-btn>

              <v-btn
                small
                fab
                text
                outlined
                color="red"
                to="/agricultura/campo/plagas"
                ><v-icon>pest_control</v-icon></v-btn
              >
              <v-spacer></v-spacer>
              <v-btn small fab text color="primary" @click="navegar()">
                <v-icon>arrow_back</v-icon>
              </v-btn>
              <v-btn
                small
                text
                fab
                color="primary"
                to="/agricultura/campo/add"
                v-if="
                  $route.path === '/agricultura/campo' && permission.can_add
                "
              >
                <v-icon>add</v-icon>
              </v-btn>

              <v-btn
                small
                fab
                text
                color="primary"
                @click="minimizar ? (minimizar = false) : (minimizar = true)"
              >
                <v-icon>{{ minimizar ? "expand_more" : "expand_less" }}</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-alert type="error" v-if="permission_alert"
              >Error!!! Este usuario no tiene permisos en esta ventana</v-alert
            >

            <router-view v-show="!minimizar"></router-view>
          </v-card>
        </v-row>
      </v-container>
    </v-card>
    <v-card>
      <GoogleMapLoader
        :mapConfig="mapConfig"
        libraries="drawing"
        :class="displaySM ? `travel-map-sm` : `travel-map`"
      >
        <template slot-scope="{ google, map }">
          <AgroMap :google="google" :map="map" @mostrar="mostrar()" />
          <AgroSensor :google="google" :map="map" />
          <AgroPest :google="google" :map="map" />
          <SensorMap :google="google" :map="map" />
        </template>
      </GoogleMapLoader>
    </v-card>
    <v-expand-transition>
      <v-card
        v-if="openHelp"
        class="help-dialog overflow-y-auto"
        style="max-height: 590px"
      >
        <v-toolbar flat>
          <v-toolbar-title>Ayuda</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn fab text small @click="openHelp = false">
            <v-icon color="red">clear</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text>
          <div v-if="$route.path === '/agricultura/campo'">
            <component :is="'Controles'"></component>
          </div>
          <div v-if="$route.path === '/agricultura/campo/add'">
            <component :is="'CrearCampo'"></component>
            <v-progress-linear
              rounded
              height="3"
              value="100"
            ></v-progress-linear>
            <component :is="'CrearLote'"></component>
          </div>
          <div v-if="$route.name === 'Agro Detalle'">
            <Detail />
          </div>
        </v-card-text>
      </v-card>
    </v-expand-transition>
  </div>
</template>

<script>
import GoogleMapLoader from "@/components/map/GoogleMapLoader";
import { mapSettings } from "@/services/constants/mapSettings";
import { mapActions } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { ZONAS_CULTIVO } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";
import AgroMap from "./AgroMap";
import AgroPest from "./AgroPest";
import AgroSensor from "./AgroSensor";
import SensorMap from "./Sensor/SensorMap";
export default {
  components: {
    GoogleMapLoader,
    AgroSensor,
    AgroPest,
    AgroMap,
    SensorMap,
    Controles: () =>
      import(
        /* webpackPrefetch: true */ "@/views/Help/Agro/Principal/Controles.vue"
      ),
    CrearCampo: () =>
      import(
        /* webpackPrefetch: true */ "@/views/Help/Agro/Principal/CrearCampo.vue"
      ),
    CrearLote: () =>
      import(
        /* webpackPrefetch: true */ "@/views/Help/Agro/Principal/CrearLote.vue"
      ),
    Detail: () =>
      import(/* webpackPrefetch: true */ "@/views/Help/Agro/Detail/Detail.vue"),
  },
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    const permission = getPermission(ZONAS_CULTIVO).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  mounted() {
    this.isActivemap = true;
    this.Vact_campo_data();
  },
  computed: {
    displaySM() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    mapConfig() {
      return {
        ...mapSettings,
        mapTypeId: "hybrid",
        fullscreenControl: false,
        mapTypeControl: false,
        center: { lat: -25.361663, lng: -55.686352 },
      };
    },
    permission_alert() {
      return mensaje.permission(this.permission);
    },
    options() {
      return {
        duration: 300,
        offset: -60,
        easing: "easeInOutCubic",
      };
    },
  },
  methods: {
    ...mapActions("campo", ["Vact_campo_data"]),
    navegar() {
      if (this.$router.history.current.path != "/agricultura/campo")
        this.$router.push("/agricultura/campo");
    },
    mostrar() {
      if (this.minimizar) this.minimizar = false;
    }
  },
  data() {
    return {
      isActivemap: false,
      minimizar: false,
      show: "",
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
      openHelp: false,
    };
  },
};
</script>

<style lang="css" scoped>
.travel-map {
  position: relative;
  height: 83vh;
  z-index: 1;
}
.travel-map-sm {
  position: relative;
  height: 90vh;
  z-index: 1;
}
.position-dialog {
  position: absolute;
  top: 1px;
  width: 380px;
  /*height: 82vh;*/
  z-index: 3;
  overflow: hidden;
}
.position-sensor {
  position: absolute;
  top: 0.6rem;
  right: 0.6rem;
  z-index: 2;
}
.position-dialog-sm {
  position: absolute;
  top: 1px;
  width: 380px;
  /*height: 85vh;*/
  z-index: 3;
  overflow: hidden;
}
.position-dialog-minimize {
  position: absolute;
  top: 1px;
  width: 380px;
  z-index: 3;
  overflow: hidden;
}

.help-dialog {
  position: absolute;
  right: 1%;
  width: 320px;
  top: 2%;
  z-index: 3;
}
</style>
