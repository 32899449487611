<template>
  <c-dialog-confirmation
    :value="value"
    @cancel="close()"
    @done="save()"
    :loading="isLoading"
    >Desea eliminar el secado de  <strong>{{ props.idanimal.rpanimal }}</strong
    >?</c-dialog-confirmation
  >
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    value: Boolean,
    props: Object,
  },
  computed:{
    ...mapGetters('secado',["isLoading"])
  },
    watch: {
    $route() {
      return this.close();
    },
  },
  methods: {
    ...mapActions("secado", ["setSecadoDelete","fetchNuevo"]),

    async save() {
      const id = this.props.idsecado;
      const response = await this.setSecadoDelete(id);
      if (!response.success) return null;
      this.close();
    },
    close() {
      this.$emit('datos', null);
      this.$emit("input", false);
      this.fetchNuevo();
    },
  }
};
</script>

<style>
</style>