import dbPostError from '@/db/dbPostError'
import { save_sync } from '@/services/api/api.service'
import { TokenService } from '@/services/auth/storage.service'

/* eslint-disable */
export default {
    get_posterror({  commit  }) {
        const xidusuario = TokenService.getToken();
        commit('load_sync',true);
        dbPostError.posterror.where({"xidusuario": xidusuario}).toArray().then((x) => {
            commit('sync_after', x)
        })
        commit('load_sync',false);
    },
    async set_posterror_api({  commit, dispatch  }) {
        let countError = 0;
        commit('load_sync',true);
            const datos = await dbPostError.posterror.toArray();
            var resp = datos.map( async x=>{
                try {
                    await save_sync(x.url,x.data);
                    dbPostError.posterror.where('id').equals(x.id).delete();   
                } catch (error) {
                    countError = countError + 1;
                    console.log(error);
                }
            }) 
            let mensaje = Promise.all(resp).then( ()=> {
                commit('load_sync',false);
                dispatch('pedidoventa/Vact_pedidoventa_data', null, { root: true });
                dispatch('get_posterror'); 
                if(countError === 0)
                return {
                    info: {
                        group: 'app',
                        type: 'success',
                        title: 'Mensaje:',
                        text: 'Sincronizacion exitosa'
                    },
                    estado: true
                }
                return {
                    info: {
                        group: 'app',
                        type: 'warn',
                        title: 'Mensaje:',
                        text: 'No se pudo sincronizar '+countError+' registros'
                    },
                    estado: true
                }

    
            })
            return mensaje;
    },
    async set_posterror_dblocal({ commit, dispatch },{ data, url }) {
        const xidusuario = TokenService.getToken();
        commit('load_sync',true);
        dbPostError.posterror.add({ data, url, status: true, xidusuario});
        dispatch('get_posterror');
        commit('load_sync',false);
        return true;
    }
}