
export default {

  Vget_timbrado_data: (state) => {
    return state.timbrado_data
  },
  Vget_timbrado_load: (state) => {
    return state.timbrado_load
  },
  Vget_timbrado_save_load: (state) => {
    return state.timbrado_save_load
  },
}
