<template>
  <div>
    <v-toolbar flat dense>
      <v-toolbar-title>Items Vendidos</v-toolbar-title>
      <widgets-Favorite :ruta="$route.path" formulario="Items Vendidos por Dia" />
    </v-toolbar>
    <v-form ref="form" class="mt-3">
      <v-row dense class="mx-2">
        <v-col cols="12" sm="3">
          <c-text-date
            dense
            label="Desde"
            v-model="data.fecha_inicio"
          ></c-text-date>
        </v-col>
        <v-col cols="12" sm="3">
          <c-text-date
            dense
            label="Hasta"
            v-model="data.fecha_fin"
          ></c-text-date>
        </v-col>
        <v-col cols="12" sm="3">
          <v-autocomplete
            :items="Vget_item_data"
            dense
            label="Items"
            item-text="descitem"
            item-value="iditem"
            multiple
            v-model="data.items"
            :rules="[(v) => !!v.length > 0 || 'Este campo es obligatorio']"
          ></v-autocomplete>
        </v-col>
        <v-spacer></v-spacer>
        <v-btn text color="primary" fab small outlined @click="obtenerDatos()"
          ><v-icon>cloud_download</v-icon></v-btn
        >
      </v-row>
    </v-form>
    <c-overlay :value="Vget_venta_load"></c-overlay>

    <v-tabs color="deep-purple accent-4" right>
      <v-tab v-for="(item, i) of Vget_venta_itemvendido" :key="i">{{
        item.moneda
      }}</v-tab>
      <v-tab-item v-for="(item, i) of Vget_venta_itemvendido" :key="i">
        <v-data-table
          :ref="`refDataTable${i}`"
          dense
          :loading="Vget_venta_load"
          :headers="headers"
          :items="item.detalle"
          :search="search"
        >
          <template v-slot:[`item.item`]="props">
            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                  <tr v-for="(item, i) in props.item.detalle" :key="i">
                    <td>{{ item.name }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
          <template v-slot:[`item.cant`]="props">
            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                  <tr v-for="(item, i) in props.item.detalle" :key="i">
                    <td>{{ toCurrency(item.cant) }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
          <template v-slot:[`item.monto`]="props">
            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                  <tr v-for="(item, i) in props.item.detalle" :key="i">
                    <td>{{ toCurrency(item.monto) }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
          <template slot="body.append">
            <tr class="black--text">
              <th class="subtitle-1">TOTAL</th>
              <th></th>
              <th></th>
              <th class="subtitle-1 text-end">
                {{toCurrency(getTotal(item.detalle)) }}
              </th>
            </tr>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import {
  current_first_date,
  current_last_date,
} from "@/services/util/date.service";
import { currency } from "@/services/util/number.service";

import { mapActions, mapGetters } from "vuex";
export default {
  data: () => ({
    search: "",
    data: {
      fecha_inicio: current_first_date(),
      fecha_fin: current_last_date(),
      items: [],
    },
    headers: [
      { text: "Fecha", value: "fecha" },
      { text: "Item", value: "item", align: "right" },
      { text: "Cantidad", value: "cant", align: "right" },
      { text: "Ingresos", value: "monto", align: "right" },
    ],
  }),
  mounted() {
    this.Vact_item_data();
  },
  computed: {
    ...mapGetters("item", ["Vget_item_data", "Vget_item_load"]),
    ...mapGetters("venta", ["Vget_venta_load", "Vget_venta_itemvendido"]),
  },
  methods: {
    ...mapActions("item", ["Vact_item_data"]),
    ...mapActions("venta", ["Vact_venta_itemvendido"]),
    getTotal(item) {
        if(!item) return 0;
      return item.reduce((accumulator, current) => {
        return (accumulator =
          accumulator +
          current.detalle.reduce((acc, cur) => {
            return (acc = acc + cur.monto);
          }, 0));
      }, 0);
    },
    async obtenerDatos() {
      if (!this.$refs.form.validate()) return null;
      const item = this.data.items.reduce((accumulator, current, i) => {
        if (i === 0) return (accumulator = accumulator + current);
        return (accumulator = accumulator + "," + current);
      }, "item=");
      await this.Vact_venta_itemvendido(
        `${item}&inicio=${this.data.fecha_inicio}&fin=${this.data.fecha_fin}`
      );
    },
    toCurrency(value) {
      return currency(value);
    },
  },
};
</script>

<style>
</style>