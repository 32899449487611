<template>
  <v-btn x-small fab text color="primary" @click="$emit('click')"
    ><v-icon>edit</v-icon></v-btn
  >
</template>

<script>
export default {};
</script>

<style>
</style>