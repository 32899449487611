<template>
  <div>
    <v-dialog :value="value" persistent max-width="900" @keydown.esc="cancelar()">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Detalle Nota de Credito Compra</v-toolbar-title>
        </v-toolbar>
        <v-container>
          <v-form ref="form1">
            <v-row dense>
              <v-col cols="12" md="2" sm="3">
                <c-text-date label="Fecha" v-model="data.fecha" outlined dense id="input1" readonly></c-text-date>
              </v-col>
              <v-col cols="12" md="2" sm="3">
                <vue-text-field
                  label="Numero Nota Credito"
                  readonly
                  v-model="data.numnotacreditocompra"
                ></vue-text-field>
              </v-col>
              <v-col cols="12" md="5" sm="6">
                <vue-text-field
                  label="Proveedor"
                  :create="false"
                  v-model="data.idcompra.idcontacto.idcontacto.desccontacto"
                  readonly
                />
              </v-col>
              <v-col cols="12" md="3" sm="3">
                <vue-text-field label="Factura que modifica" readonly v-model="factura"></vue-text-field>
              </v-col>
            </v-row>
          </v-form>
          <v-form ref="formDet">
            <v-row dense>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="data.notacredito"
                  :items-per-page="1000"
                  hide-default-footer
                ></v-data-table>
              </v-col>
            </v-row>
          </v-form>
          <v-form ref="form2">
            <v-row dense>
              <v-col cols="12">
                <vue-text-field label="Descripcion de la operacion" v-model="data.comentario" readonly></vue-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="cancelar()">
            <v-icon>clear</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    show: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters("notacreditocompra", ["Vget_notacreditocompra_save_load"])
  },
  mounted() {
    this.data = JSON.parse(JSON.stringify(this.show));
    this.factura = this.show.idcompra.regi_compra + '-' + this.show.idcompra.numfcompra;
    this.data.notacredito = [];
    this.show.notacredito.forEach(item=>{
      this.addDetalle(item)
    }); 
  },
  methods: {
    ...mapActions("notacreditocompra", [
      "Vact_notacreditocompra_save",
      "Vact_notacreditocompra_data"
    ]),

    cancelar() {
      this.$emit("input", false);
      this.$emit("show", {});
    },

    addDetalle(item) {
      switch (item.iditem.idcategoriaitem.idiva.idiva) {
        case 3:
          item.iva10 = Number(item.cantidad) * Number(item.precio);
          break;
        case 2:
          item.iva5 = Number(item.cantidad) * Number(item.precio);
          break;
        case 1:
          item.exenta = Number(item.cantidad) * Number(item.precio);
          break;
        default:
          break;
      }
      this.data.notacredito.push(item);
    }
  },
  data() {
    return {
      factura: "",
      headers: [
        { text: "Cantidad", value: "cantidad", sortable: false },
        { text: "Item", value: "iditem.descitem", sortable: false },
        { text: "Precio Unitario", value: "precio", sortable: false },
        { text: "Exenta", value: "exenta", sortable: false },
        { text: "Iva 5%", value: "iva5", sortable: false },
        { text: "Iva 10%", value: "iva10", sortable: false }
      ],
      data: {
        idnotacreditocompra: 0,
        comentario: "",
        fecha: "",
        idcompra: {
          idcompra: "",
          idcontacto: {
            idcontacto: {
              descontacto: ""
            }
          }
        },
        iddeposito: {
          iddeposito: ""
        },
        numnotacreditocompra: "",
        notacredito: []
      },

      default: {
        idnotacreditocompra: 0,
        comentario: "",
        fecha: "",
        idcompra: {
          idcompra: ""
        },
        iddeposito: {
          iddeposito: ""
        },
        idcontacto: {
          idcontacto: ""
        },
        numnotacreditocompra: "",
        notacredito: []
      }
    };
  }
};
</script>

<style>
</style>