<template>
  <div>
    <v-dialog
      :value="value"
      max-width="700px"
      persistent
      @keydown.esc="cancelar()"
    >
      <v-card>
        <v-toolbar flat dense>
          <v-toolbar-title>{{
            operacion
              ? "Registrar Tipo de Enfermedad"
              : "Modificar Tipo de Enfermedad"
          }}</v-toolbar-title>
        </v-toolbar>
        <v-form ref="form" lazy-validation>
          <v-container>
            <v-row row dense>
              <v-col cols="12">
                <c-text-field
                  ref="input1"
                  v-model="data.descenfermedad"
                  label="Descripcion"
                  dense
                  outlined
                  @keyup.native.enter="nextfocus(1)"
                ></c-text-field>
              </v-col>
              <v-col cols="12">
                <c-text-field
                  ref="input2"
                  v-model="data.abvrenfermedad"
                  label="Abreviatura"
                  dense
                  outlined
                  @keyup.native.enter="nextfocus(2)"
                ></c-text-field>
              </v-col>
            </v-row>
          </v-container>

          <v-card-actions>
            <c-btn-crud-close @click="cancelar()"></c-btn-crud-close>
            <v-spacer></v-spacer>
            <c-btn-crud-done ref="aceptar" @click="guardar()"></c-btn-crud-done>
          </v-card-actions>
        </v-form>
      </v-card>
      <c-overlay :value="isLoading"></c-overlay>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    props: Object,
    value: Boolean,
    "sync-input": {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      sync: null,
      data: {
        descenfermedad: "",
        abvrenfermedad: "",
      },
      default: {
        descenfermedad: "",
        abvrenfermedad: "",
      },

      operacion: true,
    };
  },
  computed: {
    ...mapGetters("tipoenfermedad", ["isLoading"]),
  },
  watch: {
    $route() {
      return this.cancelar();
    },
  },
  mounted() {
    if (this.syncInput != null) {
      this.data.descenfermedad = this.syncInput;
      this.sync = this.syncInput;
    }
    if (this.props) {
      this.data = JSON.parse(JSON.stringify(this.props));
      this.operacion = false;
    }
    setTimeout(() => this.$refs.input1.focus(), 500);
  },
  methods: {
    ...mapActions("tipoenfermedad", [
      "setTipoEnfermedad",
      "setTipoEnfermedadUpdate",
      "fetchTipoEnfermedad",
    ]),

    nextfocus(x) {
      switch (x) {
        case 1: {
          if (this.data.descenfermedad != "") {
            this.$refs.input2.focus();
          }
          break;
        }
        case 2: {
          if (this.data.abvrenfermedad != "") {
            this.$refs.aceptar.$el.focus();
          }
          break;
        }
      }
    },

    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$emit("input", false);
      this.$emit("datos", null);
      this.operacion = true;
      this.fetchTipoEnfermedad();
    },
    async guardar_finalizar() {
      const response = await this.setTipoEnfermedad(this.data);
      if (response.success) {
        if (this.sync != null) {
          this.$emit("sync-input", response.data.data);
          return this.cancelar();
        }
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();
        this.$refs.input1.focus();
      }
    },
    async editar_finalizar() {
      const id = this.props.idtipoenfermedad;
      const response = await this.setTipoEnfermedadUpdate({
        id,
        data: this.data,
      });
      if (response.success) {
        this.cancelar();
      }
    },
    guardar() {
      if (!this.$refs.form.validate()) return null;
      if (this.operacion === true) this.guardar_finalizar();
      if (this.operacion === false) this.editar_finalizar();
    },
  },
};
</script>
