
export default {

  transladocaja_create: (state) => {
    return state.transladocaja_create
  },
  transladocaja_update: (state) => {
    return state.transladocaja_update
  },
  transladocaja_reload: (state) => {
    return state.transladocaja_reload
  },
  transladocaja_show: (state) => {
    return state.transladocaja_show
  },
  transladocaja_key: (state) => {
    return state.transladocaja_key
  },

  Vget_transladocaja_data: (state) => {
    return state.transladocaja_data
  },
  Vget_transladocaja_load: (state) => {
    return state.transladocaja_load
  },
  Vget_transladocaja_save_load: (state) => {
    return state.transladocaja_save_load
    
  },
}
