export default {
  asientodiario_data(state, request) {
    state.asientodiario_data = request;
  }, 
  asientodiario_load(state, request) {
    state.asientodiario_load = request;
  }, 
  asientodiario_save_load(state, request) {
    state.asientodiario_save_load = request;
  }, 
}