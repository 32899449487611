import jsPDF from "jspdf";
import { current_date, dmy } from "@/services/util/date.service";

export const exportRecetarioPDF = (title, datos) => {
    const dato = JSON.parse(JSON.stringify(datos));
    var doc = new jsPDF();
    doc.setFontSize(12);
    doc.text(title, doc.internal.pageSize.width / 2, 20, { align: "center" });
    doc.setFontSize(10);
    doc.text("Fecha: " + dato.fecha, 20, 25);
    doc.text("Lote: " + dato.idlote_agri.campo, doc.internal.pageSize.width / 2 + 20, 25);
    doc.text("Zafra: " + dato.idcampana.descripcion, 20, 30);
    doc.text("Comentario: " + dato.comentario, 20, 35);
    doc.autoTable({
        theme: "plain",
        head: [
            [
                "HA",
                "Descripcion",
                "Activo",
                "Medida",
                "Cant. U x Ha",
                "Cant. Total",
            ]
        ],
        body: dato.recetariodet.map((x) => {
            let detail = ""
            x.idstock.iditem.itemquimica.forEach((y) => { 
                if (y.porcentaje) {
                    detail = detail + y.descripcion + " " + y.porcentaje + ";\n"
                } else {
                    detail = detail + y.descripcion + ";\n"
                }
            })
            return [
                x.hectarea,
                x.idstock.iditem.descitem,
                detail,
                x.idstock.iditem.idunidadmedida.descunidadmedida,
                x.cantidad,
                x.cantxha,
            ]
        }),
        headStyles: {
            fontSize: 7,
            textColor: [0, 0, 0],
            fillColor: [255, 255, 255],
            lineWidth: 0.2
        },
        bodyStyles: {
            fontSize: 7,
            textColor: [0, 0, 0],
            fillColor: [255, 255, 255],
            lineWidth: 0.2 
        },
        startY: 40
    });
    doc.save(title + " " + dmy(current_date()) + ".pdf")
};