
export default {

  tipoparto_create: (state) => {
    return state.tipoparto_create
  },
  tipoparto_update: (state) => {
    return state.tipoparto_update
  },
  tipoparto_reload: (state) => {
    return state.tipoparto_reload
  },
  tipoparto_show: (state) => {
    return state.tipoparto_show
  },
  tipoparto_key: (state) => {
    return state.tipoparto_key
  },
}
