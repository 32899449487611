<template>
  <div>
    <InputAutocomplete
      :label="label"
      item-value="idlote"
      item-text="desclote"
      :id="id"
      dense
      :outlined="outlined"
      :rounded="rounded"
      :filled="filled"
      :readonly="readonly"
      :value="value"
      :return-object="returnObject"
      :items="getLote"
      :loading="isLoading"
      :multiple="multiple"
      :permission="permission"
      @sync-input="formulario.search = $event"
      @create="formulario.crud = true"
      @input="$emit('input', $event)"
    />
    <Crud
      v-if="formulario.crud"
      v-model="formulario.crud"
      :sync-input="formulario.search"
      @sync-input="syncInputHandler($event)"
    />
  </div>
</template>

<script>
import InputAutocomplete from "@/ncomponents/InputAutocomplete";
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { LOTE } from "@/constants/forms";
import { PUEDE_AGREGAR } from "@/constants/permissions";
import Crud from "./Create";
export default {
  props: {
    id: String,
    value: [String, Object, Number],
    returnObject: Boolean,
    readonly: Boolean,
    rounded: Boolean,
    filled: Boolean,
    multiple: Boolean,
    outlined: {
      type: Boolean,
      default: true
    },
    label:{
        type: String,
        default: 'Lote'
    }
  },
  components: {
    InputAutocomplete,
    Crud,
  },
  data: () => ({
    formulario: {
      crud: false,
      search: "",
    },
  }),
  computed: {
    ...mapGetters("lote", ["getLote", "isLoading"]),
    permission: () => getPermission(LOTE).permiso[PUEDE_AGREGAR],
  },
  mounted() {
    this.fetchLote();
  },
  methods: {
    ...mapActions("lote", ["fetchLote"]),
    syncInputHandler(event) {
      if (this.returnObject) this.$emit("input", event);
      if (!this.returnObject) this.$emit("input", event.idlote);
      this.$emit("nextfocus");
      this.fetchLote();
    },
  },
};
</script>
