import { db } from "@/services/db.service";
export default {

    async Vact_egreso_data({ commit }) {
        commit('egreso_load', true);
        try {
            const data = await db.get('/apicompra/?band=egreso&limit=10');
            commit('egreso_data', data);
            commit('egreso_load', false);
            return true
        } catch (e) {
            commit('egreso_load', false);
            return false
        }
    },
    async Vact_egreso_del({ dispatch }, id) {
        try {
            const data = await db.delete(`/apicompra/${id}/?band=egreso`);
            dispatch('Vact_egreso_data');
            return data
        } catch (e) {
            return false
        }
    },
    async Vact_egreso_save({ commit }, data) {
        commit('egreso_save_load', true);
        try {
            const resp = await db.save('/apicompra/?band=egreso', data);
            commit('egreso_save_load', false);
            return resp;
        } catch (e) {
            commit('egreso_save_load', false);
            return e
        }
    },
    async Vact_egreso_update({ commit }, { id, data }) {
        commit('egreso_save_load', true);
        try {
            const resp = await db.update('/apicompra/' + id + '/?band=egreso', data);
            commit('egreso_save_load', false);
            return resp;
        } catch (e) {
            commit('egreso_save_load', false);
            return e
        }
    }
}
