<template>
  <v-text-field
    v-model="search"
    :append-icon="search.length > 0 ? 'cloud_download' : 'search'"
    placeholder="Busqueda por Razon Social, Ruc y Factura"
    autocomplete="off"
    label="Busqueda"
    :loading="Vget_venta_load"
    class="mr-1"
    single-line
    hide-details
    @click:append="busqueda()"
  ></v-text-field>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data: () => ({
    search: "",
  }),
  computed: {
    ...mapGetters("venta", ["Vget_venta_data", "Vget_venta_load"]),
  },
  methods: {
    ...mapActions("venta", ["Vact_venta_data"]),
    async busqueda() {
      this.Vact_venta_data(this.search);
    },
  },
};
</script>

<style></style>
