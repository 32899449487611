
export default {
  Vget_transladocajabanco_data: (state) => {
    return state.transladocajabanco_data
  },
  Vget_transladocajabanco_load: (state) => {
    return state.transladocajabanco_load
  },
  Vget_transladocajabanco_save_load: (state) => {
    return state.transladocajabanco_save_load
  },
}
