import { db } from "@/services/db.service";
export default {

    async Vact_agromap_coordinates({commit}, data){
        commit('agromap_coordinates', null);
        commit('agromap_coordinates', data);
    },
    async Vact_agromap_coordinates_list({commit}, data){
        commit('agromap_coordinates_list', null);
        commit('agromap_coordinates_list', data);
    },
    async Vact_agromap_coordinates_view({commit}, data){
        commit('agromap_coordinates_view', null);
        commit('agromap_coordinates_view', data);
    },
    async Vact_agromap_tile({commit}, data){
        commit('agromap_tile', null);
        commit('agromap_tile', data);
    },
    async Vact_agromap_filter_data({commit},{fechaInicio, fechaFinal, id}){
        commit('agromap_load', true);
        try {
            const url = `${fechaInicio}/${fechaFinal}/${id}/`
            const data = await db.get('/apiloteagri/'+url);
            commit('agromap_filter_data', data);
            commit('agromap_load', false);
            return true
        } catch (e) {
            commit('agromap_load', false);
            return false
        } 
    },
    async Vact_integralimages({commit}, {fechaInicio, fechaFinal, key}){
        commit('integralload', true);
        try {
            const url = `?start=${fechaInicio}&end=${fechaFinal}&key=${key}`;
            const data = await db.get(`/integralimages/${url}`)
            commit('integralimages', data);
        } catch (e) {
            console.log(e);
        }
        commit('integralload', false);
    }
}
