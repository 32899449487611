
export default {
  getAsistencia: (state) => {
    return state.asistencia
  },
  getLista: (state) => {
    return state.lista
  },
  isAsistenciaLoading: (state) => {
    return state.isLoading
  }
}
