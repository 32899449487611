import { db } from "@/services/db.service";
export default {
  async Vact_notificacion_data({ commit }) {
    commit("notificacion_load", true);
    try {
      const data = await db.get("/apinotificacion/");
      commit("notificacion_data", data);
      commit("notificacion_load", false);
      return true;
    } catch (e) {
      commit("notificacion_load", false);
      return false;
    }
  },
  async Vact_notificacion_add({ commit, dispatch }, data) {
    dispatch("Vact_notificacion_delete", data.idnotificacion);
    if (data.op != "D") commit("notificacion_merge", data);
  },
  async Vact_notificacion_delete({ commit }, id) {
    commit("notificacion_splice", id);
  },
  async Vact_notificacion_view({ commit, dispatch }, id) {
    commit("notificacion_load", true);
    try {
      const resp = await db.delete("/apinotificacion/" + id + "/");
      dispatch("Vact_notificacion_delete", id);
      commit("notificacion_load", false);
      return resp;
    } catch (e) {
      commit("notificacion_load", false);
      return false;
    }
  },
  async Vact_dashboard_menu({ commit }) {
    commit("dashboard_load", true);
    try {
      const data = await db.get("/apidashboard/");
      commit("dashboard_menu", data);
      commit("dashboard_load", false);
      return true;
    } catch (e) {
      commit("dashboard_load", false);
      return false;
    }
  },
  async Vact_dashboard_diariocaja({ commit }) {
    commit("dashboard_load", true);
    try {
      const data = await db.get("/apidashboard/?band=diario");
      commit("dashboard_diariocaja", data);
      commit("dashboard_load", false);
      return true;
    } catch (e) {
      commit("dashboard_load", false);
      return false;
    }
  },
  async Vact_dashboard_menu_save({ dispatch, commit }, data) {
    commit("dashboard_load", true);
    try {
      const resp = await db.save("/apidashboard/", data);
      dispatch("Vact_dashboard_menu");
      commit("dashboard_load", false);
      return resp;
    } catch (e) {
      commit("dashboard_load", false);
      return false;
    }
  },
  async Vact_dashboard_menu_update({ dispatch, commit }, { id, data }) {
    commit("dashboard_load", true);
    try {
      const resp = await db.update("/apidashboard/" + id + "/", data);
      dispatch("Vact_dashboard_menu");
      commit("dashboard_load", false);
      return resp;
    } catch (e) {
      commit("dashboard_load", false);
      return false;
    }
  },
  async Vact_dashboard_menu_del({ dispatch }, id) {
    try {
      const resp = await db.delete("/apidashboard/" + id + "/");
      dispatch("Vact_dashboard_menu");
      return resp;
    } catch (e) {
      console.log(e);
      return false;
    }
  },
  async Vact_favorites({ commit }) {
    commit("favorites_load", true);
    try {
      const fav = await db.get("/apimenu/");
      commit("favorites", fav);
      commit("favorites_load", false);
      return true;
    } catch (e) {
      commit("favorites_load", false);
      console.log(e);
      return false;
    }
  },
  async Vact_activity_data({ commit }) {
    commit("activity_load", true);
    try {
      const resp = await db.get("/apilistaactividades/");
      commit("activity_data", resp);
      commit("activity_load", false);
      return true;
    } catch (e) {
      commit("activity_load", false);
      console.log(e);
      return false;
    }
  },

  async Vact_commodities_data({ commit }) {
    commit("commodities_load", true);
    try {
      const resp = await db.get("/apicurrent_commodities/");
      commit("commodities_data", resp);
      commit("commodities_load", false);
      return true;
    } catch (e) {
      commit("commodities_load", false);
      console.log(e);
      return false;
    }
  },
};
