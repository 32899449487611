export default {
  cosecha_data(state, request) {
    state.cosecha_data = request;
  }, 
  cosecha_load(state, request) {
    state.cosecha_load = request;
  }, 
  cosecha_save_load(state, request) {
    state.cosecha_save_load = request;
  }, 
}