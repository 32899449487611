<template>
  <div>
    <v-dialog :value="value" max-width="700px" persistent @keydown.esc="cancelar()">
      <v-card>
        <v-form ref="form" lazy-validation @submit.prevent="guardar()">
          <v-card-title>Detalles</v-card-title>
          <v-card-text>
            <v-row row dense>
              <v-col cols="12">
                <vue-text-field label="Propiedad Quimica" :readonly="true" id="input1" v-model="data.descripcion"></vue-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="red" text @click="cancelar">
              <v-icon dark>close</v-icon>
            </v-btn>
            <v-spacer></v-spacer>

          </v-card-actions>
        </v-form>
        <v-overlay :value="Vget_propquimica_save_load">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { mensaje } from "@/services/notify.service";
export default {
  props:{
    value: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      titulo: "Nuevo Aborto",
      data: {
        idpropiedad_quimica: 1,
        descripcion: ''
      },
      default: {
        idpropiedad_quimica: 1,
        descripcion: ''
      },
      operacion: true
    };
  },
  watch: {
    $route(to, from) {
      if (from.path === "/propquimica") return this.cancelar();
    },
  },
  computed: {
    ...mapGetters("propiedadquimica", ["Vget_propquimica_save_load","Vget_propquimica_update"]),
  },
  created() {
    this.nuevo(this.Vget_propquimica_update);
  },
  mounted() {
    setTimeout(() => document.getElementById("input1").focus(), 500);
  },
  methods: {
    ...mapActions("propiedadquimica", [
      "propquimica_update",
      "Vact_propquimica_save",
      "Vact_propquimica_update",
      "Vact_propquimica_data"
    ]),

    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.propquimica_update(null);
      this.Vact_propquimica_data();
      this.$emit('input',false);
      this.operacion = true;
    },
    async guardar_finalizar() {
      let a = await this.Vact_propquimica_save(this.data);
      if (a.estado == true) {
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$notify(a.info);
        this.$refs.form.resetValidation();
        setTimeout(() => document.getElementById("input1").focus(), 500);
      } else {
        a.info.map(x => {
          this.$notify(mensaje.error(x));
        });
      }
    },

    async editar_finalizar() {
      const id = this.Vget_propquimica_update.idpropiedad_quimica;
      let a = await this.Vact_propquimica_update({id, data : this.data});
      if (a.estado == true) {
        this.$notify(a.info);
        this.cancelar();
      } else {
        a.info.map(x => {
          this.$notify(mensaje.error(x));
        });
      }
    },
    guardar() {
      if (!this.$refs.form.validate()) return this.$notify(mensaje.completar());
      if (this.operacion === true) return this.guardar_finalizar();
      if (this.operacion === false) return this.editar_finalizar();
    },

    nuevo(val) {
      if (val !== null) {
        this.data = val;
        this.operacion = false;
      }
    }
  }
};
</script>