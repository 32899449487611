<template>
  <div>
    <v-dialog
      :value="value"
      max-width="900px"
      persistent
      @keydown.esc="cancelar()"
    >
      <v-card>
        <v-toolbar flat dense>
          <v-toolbar-title>Registrar Produccion</v-toolbar-title>
        </v-toolbar>
        <v-container></v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <c-btn-crud-close @click="cancelar()"></c-btn-crud-close>
          <v-spacer></v-spacer>
          <c-btn-crud-done id="aceptar" @click="guardar()"></c-btn-crud-done>
        </v-card-actions>
        <c-overlay :value="isLoading"></c-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
    data() {
        //detalle: []
    },
    methods: {
        getFormData() {
            let Form = new FormData();
            Form.append("documento", this.documento)
            return Form
        },
        async guardar() {
            if (!this.$refs.form.validate()) return null;
            const response = await this.setProduccion(this.data);
            if (response.success) {
                this.data = JSON.parse(JSON.stringify(this.default));
                this.produccion = "";
                this.$refs.form.resetValidation();
                setTimeout(() => document.getElementById("input0").focus(), 500);
            }
        },
        cancelar() {
            this.data = JSON.parse(JSON.stringify(this.default));
            this.$emit("input", false);
            this.fetchProduccion();
        },
    }
}
</script>
