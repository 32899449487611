import { db } from "@/services/db.service";
export default {
    async gymrutina_update({commit},data){
        commit('gymrutina_update',data);
    },

    async Vact_gymrutina_data({commit}){
        commit('gymrutina_load',true);
        try {
            const data = await db.get('/apigimnasiorutina/');
            commit('gymrutina_data',data);
            commit('gymrutina_load',false);
            return true
        } catch (e) {
            commit('gymrutina_load',false);
            return false
        } 
    },
    async Vact_gymrutina_data_hoy({commit}){
        commit('gymrutina_load', true);
        try {
            const data = await db.get('/apigimnasiohoy/');
            commit('gymrutina_data_hoy', data);
            commit('gymrutina_load', false);
            return true
        } catch (e) {
            commit('gymrutina_load', false);
            return false
        }
    },
    async Vact_gymrutina_data_by({commit}, {id}) {
        commit('gymrutina_load', true);
        try {
            const data = await db.get('/apigimnasiorutina/?idgymplanilla=' + id)
            commit('gymrutina_data_by', data)
            commit('gymrutina_load', false);
            return true
        } catch (e) {
            commit('gymrutina_load', false);
            return false
        }
    },
    async Vact_gymrutina_save({dispatch, commit},data){
        commit('gymrutina_save_load',true);
        try {
            const resp = await db.save('/apigimnasiorutina/',data);
            dispatch('Vact_gymrutina_data');
            commit('gymrutina_save_load',false);
            return resp;
        } catch (e) {
            commit('gymrutina_save_load',false);
            return e
        } 
    },
    async Vact_gymrutina_update({commit},{id, data}){
        commit('gymrutina_save_load',true);
        try {
            const resp = await db.update('/apigimnasiorutina/'+id+'/',data);
            commit('gymrutina_save_load',false);
            return resp;
        } catch (e) {
            commit('gymrutina_save_load',false);
            return e
        } 
    },
    async Vact_gymrutina_del({dispatch},id){
        try {
            const data = await db.delete('/apigimnasiorutina/'+id);
            dispatch('Vact_gymrutina_data');
            return data
        } catch (e) {
            return false
        } 
    }
}
