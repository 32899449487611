export default {

  racion_data: [],
  racion_data_all: [],
  racion_data_anho: [],
  racion_data_byLote: [],
  racion_load: false,
  racion_save_load: false

}
