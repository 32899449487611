<template>
  <div>
    <c-alert-permission v-model="permission"></c-alert-permission>
    <v-toolbar flat dense>
      <v-toolbar-title class="text-h5">Funcionarios</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <c-btn-table-add
          @click="crud.add = true"
          v-if="permission.can_add"
        ></c-btn-table-add>
      </v-toolbar-items>
    </v-toolbar>
    <v-tabs right>
      <v-tab @click="fetchFuncionario({ active: true })">Activos</v-tab>
      <v-tab @click="fetchFuncionario({ active: false })">Inactivos</v-tab>
      <v-tab>Documentos</v-tab>
      <v-tab-item>
        <v-text-field
          v-model="search"
          label="Busqueda"
          placeholder="Escriba el nombre del funcionario"
          class="mx-4"
        ></v-text-field>
        <v-data-table
          v-if="permission.can_view"
          :headers="headers"
          :items="getFuncionario"
          :search="search"
          :loading="isFuncionarioLoading"
          :items-per-page="20"
        >
          <template v-slot:[`item.salario`]="{ item }">
            <div>{{ toCurrency(item.salario[0]) }}</div>
          </template>
          <template v-slot:[`item.sector`]="{ item }">
            <div>{{ item.salario[1] }}</div>
          </template>
          <template v-slot:[`item.fecha_fin`]="{ item }">
            <div>{{ item.salario[2] }}</div>
          </template>
          <template v-slot:[`item.action`]="props">
            <v-btn
              text
              fab
              outlined
              x-small
              color="deep-purple accent-4"
              @click="openFuncionario(props.item)"
            >
              <v-icon> arrow_forward </v-icon>
            </v-btn>
            <v-btn
              class="ml-2"
              text
              fab
              outlined
              x-small
              color="red accent-4"
              @click="deleteFuncionario(props.item)"
            >
              <v-icon> delete </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-tab-item>
      <v-tab-item>
        <v-text-field
          v-model="search"
          label="Busqueda"
          placeholder="Escriba el nombre del funcionario"
          class="mx-4"
        ></v-text-field>
        <v-data-table
          v-if="permission.can_view"
          :headers="headers"
          :items="getFuncionarioInactive"
          :search="search"
          :loading="isFuncionarioLoading"
          :items-per-page="20"
        >
          <template v-slot:[`item.salario`]="{ item }">
            <div>{{ toCurrency(item.salario[0]) }}</div>
          </template>
          <template v-slot:[`item.sector`]="{ item }">
            <div>{{ item.salario[1] }}</div>
          </template>
          <template v-slot:[`item.fecha_fin`]="{ item }">
            <div>{{ item.salario[2] }}</div>
          </template>
          <template v-slot:[`item.action`]="props">
            <v-btn
              text
              fab
              outlined
              x-small
              color="deep-purple accent-4"
              @click="openFuncionario(props.item)"
            >
              <v-icon> arrow_forward </v-icon>
            </v-btn>
            <v-btn
              class="ml-2"
              text
              fab
              outlined
              x-small
              color="red accent-4"
              @click="deleteFuncionario(props.item)"
            >
              <v-icon> delete </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-tab-item>
      <v-tab-item>
        <Documentos />
      </v-tab-item>
    </v-tabs>
    <Crud v-if="crud.add" v-model="crud.add"></Crud>
    <DialogPrincipal
      v-if="crud.open"
      v-model="crud.open"
      :props="crud.datos"
      editable
    ></DialogPrincipal>
    <Delete
      v-if="crud.delete"
      v-model="crud.delete"
      :props="crud.datos"
      @datos="crud.datos = $event"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Crud from "./Information/Dialog";
import DialogPrincipal from "./DialogPrincipal";
import Delete from "./Delete";
import Documentos from "./Documentos";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { FUNCIONARIO } from "@/constants/forms";
import { currency } from "@/services/util/number.service";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";
export default {
  components: {
    DialogPrincipal,
    Crud,
    Delete,
    Documentos,
  },
  mounted() {
    this.fetchFuncionario({ active: true });
    const permission = getPermission(FUNCIONARIO).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  computed: {
    ...mapGetters("funcionario", [
      "getFuncionario",
      "getFuncionarioInactive",
      "isFuncionarioLoading",
    ]),
  },
  methods: {
    ...mapActions("funcionario", ["fetchFuncionario"]),
    toCurrency(value) {
      return currency(value);
    },
    openFuncionario(item) {
      this.crud.datos = JSON.parse(JSON.stringify(item));
      this.crud.open = true;
    },
    deleteFuncionario(item) {
      this.crud.datos = JSON.parse(JSON.stringify(item));
      this.crud.delete = true;
    },
  },

  data: () => ({
    crud: {
      add: false,
      delete: false,
      open: false,
      datos: null,
    },
    search: "",
    items: ["Administracion", "Industrial", "Comercial", "Atencion al Cliente"],
    headers: [
      {
        text: "Codigo",
        align: "start",
        sortable: false,
        value: "idcontacto.idcontacto",
      },
      {
        text: "Funcionario",
        align: "start",
        sortable: false,
        value: "nombre",
      },
      {
        text: "ID Reloj",
        align: "start",
        sortable: false,
        value: "idcontacto_reloj",
      },
      { text: "Fecha de Ingreso", value: "fecha_ingreso", align: "end" },
      { text: "Salario Bruto", value: "salario", align: "end" },
      { text: "Sector", value: "sector", align: "end" },
      { text: "Fecha Fin Contrato", value: "fecha_fin", align: "end" },
      { text: "Opciones", value: "action", align: "end" },
    ],
    permission: {
      can_add: false,
      can_view: false,
      can_update: false,
      can_delete: false,
    },
  }),
};
</script>
