<template>
  <div>
    <v-dialog
      :value="value"
      max-width="700px"
      persistent
      @keydown.esc="cancelar()"
    >
      <v-card>
        <v-form ref="form" lazy-validation @submit.prevent="nextfocus(1)">
          <v-toolbar flat>
            <v-toolbar-title>{{
              operacion ? "Registrar Banco" : "Modificar Banco"
            }}</v-toolbar-title>
          </v-toolbar>
          <v-container>
            <v-row row dense>
              <v-col cols="12">
                <vue-text-field
                  label="Descripcion"
                  id="bancoInput1"
                  :readonly="readonly"
                  v-model="data.descbanco"
                  @keyup.native.enter="next(1, data.descbanco)"
                ></vue-text-field>
              </v-col>
              <v-col cols="12">
                <vue-autocomplete-contacto-proveedor
                  label="Proveedor"
                  :readonly="readonly"
                  id="bancoInput2"
                  v-model="data.idcontacto.idcontacto"
                  @keyup.native.enter="next(2, data.idcontacto.idcontacto)"
                />
              </v-col>
            </v-row>
          </v-container>

          <v-card-actions>
            <v-btn color="red" text @click="cancelar">
              <v-icon>clear</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              id="bancoInputaceptar"
              color="primary"
              text
              @click="guardar()"
            >
              <v-icon>done</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
      <c-overlay :value="Vget_banco_save_load"></c-overlay>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { mensaje } from "@/services/notify.service";
import { focus } from "@/services/focus.service";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    datos: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    "sync-input": {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      data: {
        idbanco: 0,
        idcontacto: {
          idcontacto: {
            idcontacto: "",
          },
        },
        descbanco: "",
      },
      default: {
        idbanco: 0,
        idcontacto: {
          idcontacto: {
            idcontacto: "",
          },
        },
        descbanco: "",
      },
      operacion: true,
      sync: null
    };
  },
  computed: {
    ...mapGetters("banco", ["Vget_banco_save_load"]),
  },
  watch: {
    $route(to, from) {
      if (from.path === "/banco") return this.cancelar();
    }
  },
  created() {
    this.nuevo(this.datos);
  },
  mounted() {
    setTimeout(() => this.next(0, "-"));
    if (this.syncInput != null) this.data.descbanco = this.syncInput;
    this.sync = this.syncInput;
  },
  methods: {
    ...mapActions("banco", [
      "Vact_banco_data",
      "Vact_banco_save",
      "Vact_banco_update",
    ]),
    next(id, data) {
      return focus.nextidName("bancoInput", id, data);
    },

    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.operacion = true;
      this.Vact_banco_data();
      this.$emit("input", false);
    },
    async guardar_finalizar() {
      let a = await this.Vact_banco_save(this.data);
      if (a.estado == true) {
        if (this.sync != null) {
          this.$emit("sync-input", a.data.data.idbanco);
          return this.cancelar();
        }
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$notify(a.info);
        this.$refs.form.resetValidation();
        this.$refs.input1.focus();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
    },
    async editar_finalizar() {
      const id = this.datos.idbanco;
      let a = await this.Vact_banco_update({ id, data: this.data });
      if (a.estado == true) {
        this.$notify(a.info);
        this.cancelar();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
    },
    guardar() {
      if (!this.$refs.form.validate()) return this.$notify(mensaje.completar());
      if (this.operacion === true) return this.guardar_finalizar();
      if (this.operacion === false) return this.editar_finalizar();
    },
    nuevo(val) {
      if (val === null) return null;
      this.data = val;
      this.operacion = false;
    },
  },
};
</script>

