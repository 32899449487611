<template>
  <div>
    <c-alert-permission v-model="permission"></c-alert-permission>
    <div v-if="permission.can_view">
      <v-form ref="form">
        <v-toolbar flat>
          <v-col>
            <v-autocomplete :items="Vget_campo_data" item-text="campo" item-value="idlote_agri" :rules="validar"
              autocomplete="off" hide-details :loading="Vget_campo_load" label="Campo" id="input1"
              @keyup.enter="next(1, data.idlote_agri)" v-model="data.idlote_agri">
              <template v-slot:no-data>
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title>No se encontro ningun Lote</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col>
            <v-text-field autocomplete="off" hide-details id="input2" :rules="validar" label="Año Inicio"
              v-model="data.anho_inicio" @keyup.enter="next(2, data.anho_inicio)"></v-text-field>
          </v-col>
          <v-col>
            <v-text-field autocomplete="off" :rules="validar" hide-details label="Año Fin" id="input3"
              v-model="data.anho_fin" @keyup.enter="next(3, data.anho_fin)"></v-text-field>
          </v-col>
          <v-divider class="mx-2" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn text color="primary" class="mr-1" fab small id="aceptar" outlined @click="descargarDatos()">
            <v-icon>cloud_download</v-icon>
          </v-btn>
          <v-btn text color="red" outlined fab small @click="generarPdf()" v-if="datosDescargados.length > 0">
            <v-icon>get_app</v-icon>
          </v-btn>
        </v-toolbar>
      </v-form>
      <v-data-table :loading="overlay" :headers="headers" :items="datosDescargados" :search="search" show-expand
        item-key="iditem" :expanded.sync="expanded">
        <template v-slot:expanded-item="{ item }">
          <td :colspan="3">
            <v-card>
              <v-data-table :headers="headers_expanded" :items="item.cosecha" hide-default-footer
                :items-per-page="100"></v-data-table>
            </v-card>
          </td>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { db } from "@/services/db.service";
import { exportTo } from "@/services/export.service";
import { focus } from "@/services/focus.service";
import { mensaje } from "@/services/notify.service";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { PUEDE_GENERAR_REPORTE } from "@/constants/permissions";
import { REPORTE_AGRICULTURA } from "@/constants/forms";
export default {
  data() {
    return {
      validar: [v => !!v || "Seleccion un campo"],
      headers: [
        { text: "Campana", align: "left", value: "descripcion" },
        { text: "", value: "data-table-expand", align: "right" }
      ],
      headers_expanded: [
        { text: "Item", align: "left", value: "descripcion" },
        { text: "Hectarea", align: "left", value: "hectarea" },
        { text: "Produccion", align: "right", value: "produccion" }
      ],
      data: {
        idlote_agri: "",
        anho_inicio: new Date().getFullYear(),
        anho_fin: new Date().getFullYear()
      },
      datosDescargados: [],
      overlay: false,
      expanded: [],
      title: "",
      fecha: "",
      permission: {
        can_view: false,
      },
    };
  },
  props: {
    search: [String, Number]
  },
  created() {
    this.permission.can_view = getPermission(REPORTE_AGRICULTURA).permiso[PUEDE_GENERAR_REPORTE];
  },
  mounted() {
    this.Vact_campo_data();
  },
  computed: {
    ...mapGetters("campo", ["Vget_campo_data", "Vget_campo_load"]),
    ...mapGetters("campana", ["Vget_campana_data_all", "Vget_campana_load"])
  },
  methods: {
    ...mapActions("campo", ["Vact_campo_data"]),
    next(id, data) {
      focus.nextid(id, data);
    },
    generarPdf() {
      const head = [
        ["Campana", "Fecha", "Item", "Hectareas", "Kg", "Precio x Tn"]
      ];
      const title = this.title;
      const columnStyles = { 4: { halign: "right" }, 5: { halign: "right" } };
      const data = {
        fecha: this.fecha
      };
      let body = [];
      this.datosDescargados.forEach(campana => {
        campana.cosecha.forEach(cosecha => {
          body.push([
            campana.descripcion,
            cosecha.fecha,
            cosecha.descripcion,
            cosecha.hectarea,
            cosecha.produccion,
            cosecha.precio
          ]);
        });
      });
      exportTo.PdfLote(head, body, title, columnStyles, data);
    },
    async descargarDatos() {
      try {
        if (!this.$refs.form.validate()) return null;
        this.overlay = true;
        const id = this.data.idlote_agri;
        const anho_i = this.data.anho_inicio;
        const anho_f = this.data.anho_fin;
        const response = await db.get(
          `/apireporte_lote_produccion/${id}/${anho_i}/${anho_f}/`
        );
        this.title = response.desc;
        this.fecha = response.fecha;
        this.datosDescargados = JSON.parse(JSON.stringify(response.campana));
        this.expanded = JSON.parse(JSON.stringify(response.campana));
        this.overlay = false;
      } catch (error) {
        this.$notify(mensaje.error(error.message))
        this.overlay = false;
      }
    }
  }
};
</script>

<style></style>