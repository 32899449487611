<template>
  <v-row dense class="">
    <v-col cols="12" sm="" v-for="(item, i) in card" :key="i">
      <v-card v-if="item.commodity" class="rounded-xl">
        <c-overlay absolute :value="Vget_commodities_load"></c-overlay>
        <v-card-title class=" mb-n2">
          <span class="title font-weight-medium">{{ item.commodity }}</span>
          <v-spacer></v-spacer>
          <span class="title font-weight-medium"> - </span>
          <v-spacer></v-spacer>
          <span class="title font-weight-light mr-2"
            >{{ item.server }} - {{ item.month }}</span
          >
          <v-icon :color="colorCard(item.pounts)">{{
            iconCard(item.pounts)
          }}</v-icon>
        </v-card-title>
        <div class="d-flex justify-space-between mx-4">
          <div class="text-h5 font-weight-bold">
            {{ toCurrency(item.price.bsh) }}
          </div>
          <div
            :class="`text-subtitle-1 font-weight-medium ${colorText(
              item.pounts
            )}`"
          >
            {{ item.pounts }}
          </div>
          <div
            :class="`text-subtitle-1 font-weight-medium ${colorText(
              item.pounts
            )}`"
          >
            ({{ item.porcent }})
          </div>
        </div>
        <div class="d-flex justify-space-between mx-4 mt-3">
          <div class="caption font-weight-regular">Precio por Tn</div>
          <div class="text-subtitle-2 font-weight-medium">
            {{ toCurrency(item.price.tn) }}
          </div>
        </div>
        <div class="d-flex justify-space-between mx-4">
          <div class="caption font-weight-regular">Precio por Kg</div>
          <div class="text-subtitle-2 font-weight-medium">
            {{ toCurrency(item.price.kg) }}
          </div>
        </div>
        <v-card-actions class="mx-1">
          <span class="caption">(Precios x Tn)</span><v-divider></v-divider>
        </v-card-actions>
        <div class="d-flex justify-space-around mx-2 mt-0 mb-n2">
          <div class="text-caption">
            Cierre:
            <strong>
              {{ toCurrency(item.secondary_prices_tn.prev_close) }}
            </strong>
          </div>
          <div class="text-caption">
            Apertura:
            <strong>
              {{ toCurrency(item.secondary_prices_tn.price_open) }}
            </strong>
          </div>
          <div class="text-caption">
            Rango:
            <strong>
              {{ toCurrency(item.secondary_prices_tn.prev_range) }} -
              {{ toCurrency(item.secondary_prices_tn.next_range) }}</strong
            >
          </div>
        </div>
        <v-card-actions class="my-b3">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                class="ml-0 mr-0"
                text
                fab
                small
                v-bind="attrs"
                v-on="on"
                ><v-icon>settings</v-icon></v-btn
              >
            </template>
            <v-list dense>
              <v-list-item
                v-for="(sv, index) in server"
                :key="index"
                @click="setServerMonth(item, sv.server)"
              >
                <v-list-item-title>{{ sv.server }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-divider></v-divider>
          <div style="font-size: 11px" class="text-end">
            {{ item.time }}
          </div>
          <v-btn
            color="primary"
            fab
            small
            text
            @click="Vact_commodities_data()"
          >
            <v-icon>autorenew</v-icon>
          </v-btn>
          <v-btn
            :color="colorCard(item.pounts)"
            class="ml-0 mr-0"
            small
            fab
            text
            @click="showFutureList(item)"
          >
            <v-icon>list_alt</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <FuturosList
      v-model="futuros.dialog"
      :data="futuros.list"
      :title="futuros.title"
      :current-month="futuros.month"
      @change="showFutureCard()"
    />
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { currency } from "@/services/util/number.service";
import { LocalService } from "@/services/auth/storage.service";
import FuturosList from "./Futuros";

export default {
  components: {
    FuturosList,
  },
  data: () => ({
    futuros: {
      dialog: false,
      title: "",
      month: null,
      list: null,
    },
    card: [],
    server: [
      {
        server: "Investing",
      },
      {
        server: "CME Group",
      },
    ],
  }),
  watch: {
    Vget_commodities_data() {
      this.showFutureCard();
    },
  },
  mounted() {
    this.Vact_commodities_data();
  },
  computed: {
    ...mapGetters("dashboard", [
      "Vget_commodities_data",
      "Vget_commodities_load",
    ]),
  },
  methods: {
    ...mapActions("dashboard", ["Vact_commodities_data"]),
    async showFutureCard() {
      let card = [];
      const data = JSON.parse(JSON.stringify(this.Vget_commodities_data));
      let resp = await data.map(async (cm) => {
        
        let { server, month } = await this.getServerMonth(cm.commodity);
        cm.server.forEach((sv) => {
          if (sv.name === server) {
            if (month === null) {
              return sv.data[0] ? card.push(sv.data[0]) : null;
            }
            let salir = false;
            sv.data.forEach((quote) => {
              if (month === quote.month) {
                salir = true;
                return card.push(quote);
              }
            });
            if(salir) return null;
            return sv.data[0] ? card.push(sv.data[0]) : null;
          }
        });
      });
      Promise.all(resp).then(() => {
        this.card = [];
        this.card = JSON.parse(JSON.stringify(card));
      });
    },
    showFutureList(data) {
      this.Vget_commodities_data.forEach((cm) => {
        
        if (cm.commodity === data.commodity) {
          cm.server.forEach((sv) => {
            if (sv.name === data.server) {
              this.futuros.title = cm.commodity;
              this.futuros.list = JSON.parse(JSON.stringify(sv.data));
            }
          });
        }
      });
      this.futuros.month = data.month;
      this.futuros.dialog = true;
    },
    async getServerMonth(commodity) {
      
      const resp = await LocalService.get(commodity);
      if (resp === null)
        return {
          server: "CME Group",
          month: null,
        };
      return resp;
    },
    async setServerMonth(item, server) {
      await LocalService.save(
        item.commodity,
        JSON.stringify({ server: server, month: null })
      );
      this.showFutureCard();
    },
    toCurrency(value) {
      return value != "-" ? currency(value) : "-";
    },
    colorText(value) {
      return value.indexOf("+") > -1 ? "green--text" : "red--text";
    },
    colorCard(value) {
      return value.indexOf("+") > -1 ? "green accent-3" : "red";
    },
    iconCard(value) {
      return value.indexOf("+") > -1 ? "north_east" : "south_east";
    },
  },
};
</script>

<style>
</style>