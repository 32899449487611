<template>
  <div>
    <v-autocomplete
      :value="value"
      @input="$emit('input', $event)"
      :items="Vget_cuentabanco_data"
      :loading="Vget_cuentabanco_load"
      :rules="rules"
      return-object
      :search-input.sync="syncInput"
      ref="input"
      item-value="idcuentabanco"
      item-text="desccuentabanco"
      :outlined="outlined"
      :dense="dense"
      :multiple="multiple"
      :chips="chips"
      @change="$emit('change')"
      :disabled="disabled"
      :small-chips="smallChips"
      :label="label"
    >
      <template v-if="isPermission && create" v-slot:no-data>
        <v-list-item @click="crud.add = true">
          <v-list-item-content>
            <v-list-item-title
              >No existe concidencia. Click para agregar</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
    <Crud v-if="crud.add" v-model="crud.add" :sync-input="syncInput" @sync-input="$emit('input',$event )" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Crud from "./Create";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { CUENTA_BANCO } from "@/constants/forms";
import { PUEDE_AGREGAR } from "@/constants/permissions";
export default {
  components: {
    Crud,
  },
  props: {
    value: [Object, Number, String],
    outlined: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    chips: {
      type: Boolean,
      default: false,
    },
    "small-chips": {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Banco",
    },
    rules: {
      type: Array,
      default: function () {
        return [(v) => !!v.idcuentabanco || "Este campo es obligatorio"];
      },
    },
    multiple:{
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    create:{
      type: Boolean,
      default: false
    }
  },
  data:()=>({
    crud: {
      add: false
    },
    syncInput: null
  }),
  created() {
    this.Vact_cuentabanco_data();
  },
  computed: {
    ...mapGetters("cuentabanco", ["Vget_cuentabanco_load", "Vget_cuentabanco_data"]),
    isPermission(){
      return getPermission(CUENTA_BANCO).permiso[PUEDE_AGREGAR];
    }
  },
  methods: {
    ...mapActions("cuentabanco", ["Vact_cuentabanco_data"]),
    isMenuActive() {
      this.$refs.input.isMenuActive = false;
    },
    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>

<style>
</style>