
export default {
  Vget_cuentadiario_data: (state) => {
    return state.cuentadiario_data
  },
  Vget_cuentadiario_load: (state) => {
    return state.cuentadiario_load
  },
  Vget_cuentadiario_save_load: (state) => {
    return state.cuentadiario_save_load
  },
}
