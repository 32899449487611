<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="200"
    offset-y
    v-if="count > 0"
  >
    <template v-slot:activator="{ on }">
      <v-btn color="orange" dark v-on="on" fab text small>
        <v-badge color="green" :content="count" overlap>
          <v-icon>loop</v-icon>
        </v-badge>
      </v-btn>
    </template>

    <v-card>
      <v-progress-linear v-if="Vget_load_sync" indeterminate color="primary"></v-progress-linear>
      <v-list dense two-line>
        <v-list-item dense>
          <v-card-subtitle>Sincronizar</v-card-subtitle>
          <v-spacer></v-spacer>
          <v-list-item-action>
            <v-btn color="primary" small text fab @click="sincronizar()">
              <v-icon>send</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item-group color="primary">
          <v-list-item v-for="(item,i) in Vget_sync_after" :key="i" dense>
            <v-list-item-content>
              <v-list-item-title>Pedido de Venta</v-list-item-title>
              <v-list-item-subtitle>Cliente: {{item.data.idcontacto.desccontacto}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
    <v-overlay :value="Vget_load_sync">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-menu>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters("offlineSync", ["Vget_sync_after","Vget_load_sync"]),
    count() {
      return this.Vget_sync_after.length;
    }
  },
  mounted() {
    this.get_posterror();
  },
  methods: {
    ...mapActions("offlineSync", ["get_posterror", "set_posterror_api"]),
    async sincronizar(){
        let a = await this.set_posterror_api();
        this.$notify(a.info)
    }
  },
  data() {
    return {
      menu: false,
      datos: []
    };
  }
};
</script>

<style>
</style>