export default {
  categoria_update(state, request) {
    state.categoria_update = request;
  },
  categoria_show(state, request) {
    state.categoria_show = request;
  }, 

  categoria_data(state, request) {
    state.categoria_data = request;
  }, 
  categoria_load(state, request) {
    state.categoria_load = request;
  }, 
  categoria_save_load(state, request) {
    state.categoria_save_load = request;
  }, 
}