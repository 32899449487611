<template>
  <div>
    <v-dialog
      :value="value"
      max-width="700px"
      persistent
      @keydown.esc="cancelar()"
    >
      <v-card>
        <v-form ref="form" lazy-validation>
          <v-toolbar flat>
            <v-toolbar-title>{{
              operacion ? "Agregar Deposito" : "Modificar Deposito"
            }}</v-toolbar-title>
          </v-toolbar>
          <v-container>
            <v-row row dense>
              <v-col cols="12">
                <v-text-field
                  dense
                  type="text"
                  :rules="validar"
                  id="depositoInput1"
                  v-model="data.desdeposito"
                  label="Descripcion"
                  required
                  outlined
                  autocomplete="off"
                  @keyup.enter="next(1, data.desdeposito)"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <vue-autocomplete-generic
                  id="depositoInput2"
                  action="item/Vact_tipoitem_data"
                  getter="item/Vget_tipoitem_data"
                  load="item/Vget_item_load"
                  pk="idtipo_item"
                  label="Tipo Item"
                  title="desctipo_item"
                  permission="Tipo Item"
                  v-model="data.idtipo_item.idtipo_item"
                  @keyup.native.enter="next(2, data.idtipo_item.idtipo_item)"
                />
              </v-col>
            </v-row>
          </v-container>

          <v-card-actions>
            <v-btn color="red" text @click="cancelar"
              ><v-icon>clear</v-icon></v-btn
            >
            <v-spacer></v-spacer>
            <v-btn
              id="depositoInputaceptar"
              color="primary"
              text
              @click="guardar"
              ><v-icon>done</v-icon></v-btn
            >
          </v-card-actions>
        </v-form>
        <c-overlay :value="Vget_deposito_save_load"></c-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { mensaje } from "@/services/notify.service";
import { focus } from "@/services/focus.service";
export default {
  props: {
    value: Boolean,
    datos: {
      type: [Object, Array],
      default: null,
    },
    "sync-input": {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      sync: null,
      data: {
        desdeposito: "",
        idtipo_item: {
          idtipo_item: "",
        },
      },
      default: {
        desdeposito: "",
        idtipo_item: {
          idtipo_item: "",
        },
      },
      validar: [(v) => !!v || "Campo requerido"],

      operacion: true,
    };
  },
  watch: {
    $route() {
      return this.cancelar();
    },
  },
  computed: {
    ...mapGetters("deposito", ["Vget_deposito_save_load"]),
  },
  created() {
    this.nuevo(this.datos);
  },
  mounted() {
    setTimeout(() => this.next(0, "-"), 200);
    if (this.syncInput != null) {
      this.data.desdeposito = this.syncInput;
      this.sync = this.syncInput;
    }
  },
  methods: {
    ...mapActions("deposito", [
      "Vact_deposito_save",
      "Vact_deposito_update",
      "Vact_deposito_data",
    ]),
    next(id, data) {
      focus.nextidName("depositoInput", id, data);
    },
    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$emit("input", false);
      this.$emit("datos", null);
      this.Vact_deposito_data();
      this.operacion = true;
    },
    async guardar_finalizar() {
      let a = await this.Vact_deposito_save(this.data);
      if (a.estado == true) {
        if (this.sync != null) {
          this.$emit("sync-input", a.data.data.id);
          this.$emit("next-focus");
          return this.cancelar();
        }
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$notify(a.info);
        this.$refs.form.resetValidation();
        this.next(0, "-");
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
    },
    async editar_finalizar() {
      const id = this.datos.iddeposito;
      let a = await this.Vact_deposito_update({ id, data: this.data });
      if (a.estado == true) {
        this.$notify(a.info);
        this.cancelar();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
    },
    guardar() {
      if (this.$refs.form.validate()) {
        if (this.operacion === true) return this.guardar_finalizar();
        if (this.operacion === false) return this.editar_finalizar();
      } else {
        this.$notify(mensaje.completar());
      }
    },
    nuevo(val) {
      if (val !== null) {
        this.data = val;
        this.operacion = false;
      }
    },
  },
};
</script>
