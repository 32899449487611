<template>
  <div>
    <v-card>
      <Crud
        v-if="crud.add"
        v-model="crud.add"
        :datos="crud.datos"
        @datos="crud.datos = $event"
      />
      <Update
        v-if="crud.update"
        v-model="crud.update"
        :datos="crud.datos"
        @datos="crud.datos = $event"
      />
      <Show
        v-if="crud.view"
        v-model="crud.view"
        :datos="crud.datos"
        @datos="crud.datos = $event"
      />

      <TreeDialog
        v-if="crud.tree"
        v-model="crud.tree"
        :datos="crud.datos"
        @datos="crud.datos = $event"
      />

      <v-alert type="error" v-if="permission_alert"
        >Error!!! Este usuario no tiene permisos en esta ventana</v-alert
      >
      <Toolbar
        v-model="search"
        :permission="permission.can_add"
        :listAnimal = listAnimal
        @click="crud.add = true"
        @listAnimal="setLista($event)"
        @chlist="setEvent($event)"
        @itempage="iPage"
        :headers="headers"
      />

      <v-data-table
        ref="listanimal"
        :headers="headers"
        :items="listAnimal"
        dense
        :search="search"
        :loading="isLoading"
        :custom-filter="customFilter"
        :items-per-page.sync="options.itemsPerPage"
        :footer-props="{'items-per-page-options':[15, 30, 50, 100, -1]}"
        v-if="permission.can_view"
      >
        <template v-slot:[`item.fechanimal`]="{ item }">
          <div>{{ formatDate(item.fechanimal) }}</div>
        </template>
        <template v-slot:[`item.detalle.FechaInseminacion`]="{ item }">
          <div>{{ formatDate(item.detalle.FechaInseminacion) }}</div>
        </template>
        <template v-slot:[`item.detalle.FechaParto`]="{ item }">
          <div>{{ formatWdays(item.detalle.FechaParto) }}</div>
        </template>
        <template v-slot:[`item.detalle.DiasAbiertos`]="{ item }">
          <div>{{ formatWdays(item.detalle.DiasAbiertos) }}</div>
        </template>
        <template v-slot:[`item.indexed`]="{ item }">
          <div v-html="item.information"></div>
        </template>
        <template v-slot:[`item.idsexo.descsexocria`]="{ item }">
          <div>
            {{ item.idsexo.descsexocria }}
            <span v-if="item.issemen">(Semen)</span>
          </div>
        </template>
        <template v-slot:[`item.accion`]="{ item }">
          <v-btn
            elevation="1"
            class="my-1"
            fab
            text
            x-small
            @click="irFicha(item.idanimal)"
            ><v-icon>list_alt</v-icon></v-btn
          >
          <v-btn
            elevation="1"
            class="my-1"
            fab
            text
            x-small
            @click="animal_tree(item.idanimal)"
            ><v-icon>swap_calls</v-icon></v-btn
          >
          <v-btn
            elevation="1"
            class="my-1"
            fab
            text
            x-small
            @click="rowselect(item)"
          >
            <v-icon>visibility</v-icon></v-btn
          >
          <v-btn
            fab
            text
            elevation="1"
            class="py-1"
            x-small
            color="primary"
            @click="update(item)"
            v-if="permission.can_update"
            ><v-icon>edit</v-icon></v-btn
          >
          <v-btn
            fab
            text
            elevation="1"
            class="my-1"
            x-small
            color="red"
            @click="eliminar(item)"
            v-if="permission.can_delete"
            ><v-icon>delete</v-icon></v-btn
          >
        </template>
      </v-data-table>
      <Delete
        v-if="crud.delete"
        v-model="crud.delete"
        :props="crud.datos"
        @datos="crud.datos = $event"
      />
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { ANIMAL } from "@/constants/forms";
import { dmy } from "@/services/util/date.service";
import { headers } from "./constants";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";
import Crud from "./Create";
import Delete from "./Delete";
import Update from "./Update";
import Show from "./Show";
import TreeDialog from "./TreeDialog";
import Toolbar from "./Toolbar";
export default {
  components: {
    Crud,
    Show,
    Delete,
    Update,
    TreeDialog,
    Toolbar,
  },
  data() {
    return {
      options: {
        itemsPerPage: 50
      },
      chlist: null,
      listAnimal: [],
      search: "",
      crud: {
        add: false,
        update: false,
        delete: false,
        view: false,
        tree: false,
        datos: null,
      },
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
    };
  },
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.$store.dispatch["configganaderia/fetchConfigGanaderia"];
    const permission = getPermission(ANIMAL).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  computed: {
    ...mapGetters("animal", ["isLoading"]),
    ...mapGetters("configganaderia", ["getColumnsAnimal"]),
    ...mapGetters("configganaderia", ["getConfigGanaderia"]),
    permission_alert() {
      return mensaje.permission(this.permission);
    },
    headers() {
      const head = [];
      if (this.chlist != null && this.chlist != undefined){
        let struc = this.$store.getters["configganaderia/getColumnsAnimal"];
        let config = this.$store.getters["configganaderia/getConfigGanaderia"];
        config[0].campos_personalizados.map((x) => {
          if (x.name == this.chlist) {
            x.fields.map((y) => {
              headers.push({
                text: y,
                value: "detalle." + y.replace(" ", ""),
              });
            });
          }
        });
        struc[this.chlist].map((x) => {
          head.push(headers.find(({ text }) => text === x));
        });
        head.push({
          text: "Accion",
          value: "accion",
          sortable: false,
          align: "right",
        });
      }
      return head;
    },
  },
  methods: {
    customFilter(value, search, item) {
      const textOne = item.information;
      return (
        (value != null &&
          search != null &&
          typeof value === "string" &&
          value
            .toString()
            .toLocaleUpperCase()
            .indexOf(search.toLocaleUpperCase()) > -1) ||
        textOne.toLocaleUpperCase().indexOf(search.toLocaleUpperCase()) > -1
      );
    },
    formatInformation(item) {
      const estado = item.idestadoanimal.descestadoanimal;
      let info = "";
      if (item.toro) info = info + `Toro <strong>${item.toro}</strong> `;
      if (estado === "Preñada" || estado === "Secado")
        info =
          info +
          `Parto <strong>${dmy(item.extra.split("/")[0])}</strong> en <strong>${
            item.extra.split("/")[1]
          }</strong> Dias`;
      if (estado === "Disponible" || estado === "Anestro Post-Parto")
        if (item.extra != null) {
          if (item.idsexo.idsexo == 1 && estado == "Disponible") {
            info = info + `<strong>(${item.extra})</strong> Inseminaciones`;
          } else {
            info = info + `<strong>(${item.extra})</strong> Dias Abiertos`;
          }
        }
          
      if (estado === "Inseminada")
        info = info + `- <strong>${dmy(item.extra)}</strong>`;
      if (!item.extra) return info;
      return info;
    },
    indexedInformation(item) {
      const estado = item.idestadoanimal.descestadoanimal;
      if (estado === "Inseminada") return item.toro;
      if (estado === "Preñada" || estado === "Secado")
        return Number(item.extra.split("/")[1]);

      if (estado === "Disponible" || estado === "Anestro Post-Parto")
        return Number(item.extra);
      if (!item.extra) return 0;
    },
    formatDate(date) {
      if (date != 'N/A') {
        return dmy(date);
      }
      return date
    },
    formatWdays(date) {
      if (date != 'N/A') {
        return this.formatDate(date[0]) + " / " + date[1]
      }
      return date
    },
    irFicha(idanimal) {
      this.$router.push({ path: "/reporteanimal", query: { idanimal } });
    },
    setEvent(data) {
      this.chlist = data;
    },
    setLista(data) {
      const list = JSON.parse(JSON.stringify(data));
      this.listAnimal = list.map((x) => {
        return {
          ...x,
          ...x.detalle,
          information: this.formatInformation(x),
          indexed: this.indexedInformation(x),
        };
      });
    },
    eliminar(item) {
      this.crud.datos = JSON.parse(JSON.stringify(item));
      this.crud.delete = true;
    },
    rowselect(val) {
      this.crud.datos = JSON.parse(JSON.stringify(val));
      this.crud.view = true;
    },
    animal_tree(val) {
      this.crud.datos = JSON.parse(JSON.stringify(val));
      this.crud.tree = true;
    },
    update(val) {
      this.crud.datos = JSON.parse(JSON.stringify(val));
      this.crud.update = true;
    },
    iPage(val) {
      this.options.itemsPerPage=val;
      console.log(this.options)
    }
  },
};
</script>
