<template>
  <div>
    <v-card>
      <v-alert type="error" v-if="permission_alert">Error!!! Este usuario no tiene permisos en esta ventana</v-alert>
      <v-toolbar flat dense>
        <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
        <widgets-Favorite :ruta="$route.path" :formulario="$route.name" />
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <c-text-table-search v-model="search" class="mr-1"></c-text-table-search>
        <widgets-filteradvanced v-model="searchadvanced" :campos="campos" @resp="datos = $event"
          url="/apiproduccionsearch/" />
        <v-toolbar-items>
          <v-speed-dial v-model="opcion" direction="left" class="mr-1">
            <template v-slot:activator>
              <v-btn v-model="opcion" height="100%" tile small color="red" dark>
                <v-icon v-if="opcion">keyboard_arrow_right</v-icon>
                <v-icon v-else>keyboard_arrow_left</v-icon>
              </v-btn>
            </template>
            <v-btn fab dark small color="indigo" @click="searchadvanced = true">
              <v-icon>zoom_in</v-icon>
            </v-btn>
            <v-btn fab dark small color="green" @click="generarCsv()">
              <v-icon>grid_on</v-icon>
            </v-btn>
            <v-btn fab dark small color="red" @click="generarPdf()">
              <v-icon>get_app</v-icon>
            </v-btn>
          </v-speed-dial>
          <c-btn-table-add @click="add()" v-if="permission.can_add"></c-btn-table-add>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table :loading="isLoading" :headers="headers" :items="getProduccion" :search="search" class="elevation-1"
        v-if="permission.can_view">
        <template v-slot:[`item.subtotal`]="{ item }">
          {{ currencyFormat(item.subtotal) }}
        </template>
        <template v-slot:[`item.totalProduccion`]="{ item }">
          {{ currencyFormat(item.totalProduccion) }}
        </template>
        <template v-slot:[`item.accion`]="props">
          <c-btn-table-view @click="rowselect(props.item)" v-if="permission.can_view"></c-btn-table-view>
          <c-btn-table-edit @click="update(props.item)" v-if="permission.can_update"></c-btn-table-edit>
          <c-btn-table-delete @click="meliminar(props.item)" v-if="permission.can_delete"></c-btn-table-delete>
        </template>
      </v-data-table>
    </v-card>
    <Delete v-if="crud.delete" v-model="crud.delete" :props="crud.datos" @datos="crud.datos = $event" />
    <Crud v-if="crud.add" v-model="crud.add" :props="crud.datos" @reset="crud.datos = $event"
      :readonly="crud.readonly" />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { exportTo } from "@/services/export.service";
import { PRODUCCION_LECHE } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";
import campos from "./exportFile";
import Delete from "./Delete";
import Crud from "./Create";
export default {
  components: {
    Delete,
    Crud,
  },
  data() {
    return {
      opcion: false,
      searchadvanced: false,
      search: "",
      campos: campos,
      headers: [
        { text: "Fecha", align: "left", value: "fechproduccionleche" },
        {
          text: "Responsable",
          align: "left",
          value: "idresponsable.descresponsable",
        },
        {
          text: "Turno",
          align: "left",
          value: "idturnoordeno.descturnoordeno",
        },
        { text: "Deposito", align: "left", value: "iddeposito.desdeposito" },
        { text: "Litros", align: "left", value: "totalProduccion" },
        { text: "Subtotal", align: "left", value: "subtotal" },
        { text: "Accion", value: "accion", sortable: false, align: "right" },
      ],
      datos: [],
      crud: {
        add: false,
        update: false,
        delete: false,
        datos: null,
      },
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
    };
  },
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.fetchProduccion();
    const permission = getPermission(PRODUCCION_LECHE).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  computed: {
    ...mapGetters("produccion", ["getProduccion", "getProduccionById", "isLoading"]),
    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },
  watch: {
    getProduccion(val) {
      if (val === null) return;
      this.datos = JSON.parse(JSON.stringify(val))
    },
    getProduccionById(val) {
      if (val === null) return;
      this.crud.datos = JSON.parse(JSON.stringify(val))
    }
  },
  methods: {
    ...mapActions("produccion", [
      "fetchProduccion",
      "fetchProduccionById"
    ]),
    currencyFormat(value) {
      if (!value) return 0;
      return new Intl.NumberFormat({ maximumSignificantDigits: 3 }).format(
        value.toFixed(2)
      );
    },
    generarPdf() {
      let head = [["Fecha", "Responsable", "Turno", "Deposito", "Litros", "Subtotal"]];
      let body = [];
      this.datos.map((x) => {
        body.push([
          x.fechproduccionleche,
          x.idresponsable.descresponsable,
          x.idturnoordeno.descturnoordeno,
          x.iddeposito.desdeposito,
          x.totalProduccion,
          x.subtotal,
        ]);
      });
      exportTo.pdftable(head, body);
    },
    generarCsv() {
      let data = [];
      this.datos.map((x) => {
        data.push({
          Fecha: x.fechproduccionleche,
          Responsable: x.idresponsable.descresponsable,
          Turno: x.idturnoordeno.descturnoordeno,
          Deposito: x.iddeposito.desdeposito,
          Leche: x.totalProduccion,
          Subtotal: x.subtotal,
        });
      });
      exportTo.xlsx(data);
    },
    add() {
      this.crud.add = true;
      this.crud.readonly = false;
    },
    async update(val) {
      await this.fetchProduccionById(val.idproduccionleche);
      this.crud.add = true;
      this.crud.readonly = false;
    },
    async meliminar(item) {
      await this.fetchProduccionById(item.idproduccionleche);
      this.crud.delete = true;
    },
    async rowselect(val) {
      await this.fetchProduccionById(val.idproduccionleche);
      this.crud.add = true;
      this.crud.readonly = true;
    },
  },
};
</script>
