<template>
  <v-overlay :value="value" :absolute="absolute">
    <v-progress-circular
      indeterminate
      :color="color"
      :size="size"
    ></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "primary",
    },
    absolute: {
      type: Boolean,
      defautl: false,
    },
    size: {
      type: Number,
      default: 64,
    },
  },
};
</script>

<style>
</style>