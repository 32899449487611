<template>
  <c-dialog-confirmation
    :value="value"
    @cancel="close()"
    @done="save()"
    :loading="isLoadingMaquinariaSalario"
    >Desea eliminar el transporte de 
    <strong>{{ props.iditem.descitem }}</strong>
    de 
    <strong>{{ props.idmaquinaria_asignacion.idmaquinaria.descripcion }}</strong>
  </c-dialog-confirmation>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    value: Boolean,
    props: Object,
  },
  computed: {
    ...mapGetters("maquinaria_transporte", ["isLoadingMaquinariaTransprorte"]),
  },
  watch: {
    $route() {
      return this.close();
    },
  },
  methods: {
    ...mapActions("maquinaria_transporte", [
      "setMaquinariaTransporteDelete",
      "fetchMaquinariaTransporte",
    ]),
    async save() {
      const id = this.props.idmaquinaria_transporte;
      const response = await this.setMaquinariaTransporteDelete(id);
      if (!response.success) return null;
      this.close();
    },
    close() {
      this.$emit("datos", null);
      this.$emit("input", false);
      this.fetchMaquinariaTransporte();
    },
  },
};
</script>