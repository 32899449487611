<template>
  <div>
    <v-card-text>
      <h3 align="center">Crear Usuario</h3>
      <v-divider></v-divider>
      <v-row dense align="center" justify="center">
        <v-col cols="12">
          <ol>
            <li>
                Asignar Deposito
                <a @click="masDepo = !masDepo">{{masDepo ? 'Cerrar' : 'Ver'}}</a>
            </li>
            <div v-if="masDepo">
                Cuando el nivel de usuario tenga el permiso de operar con deposito, este le permitira filtrar los depositos con los cuales el usuario podra operar
            </div>
            <li>
                Asignar Zonas
                <a @click="masZona = !masZona">{{masZona ? 'Cerrar' : 'Ver'}}</a>
            </li>
            <div v-if="masZona">
                Cuando el nivel de usuario tenga el permiso de operar con Grupo de Lotes, este le permitira filtrar las zonas con los cuales el usuario podra operar
            </div>
          </ol>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>
<script>
export default {
  data() {
    return {
      masDepo: false,
      masZona: false,
    };
  },
};
</script>