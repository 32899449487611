<template>
  <div>
    <div v-if="formularios.lote">
      <Animal-Lote-create v-model="formularios.lote" />
    </div>
    <div v-if="abrirrol">
      <Animal-Rol-create />
    </div>
    <v-dialog :value="true" max-width="900px" persistent @keydown.esc="close()">
      <v-card>
        <v-toolbar flat dense>
          <v-toolbar-title>{{
            isEdit ? "Modificar Parto" : "Registrar Parto"
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-checkbox class="mt-5" label="Historico" v-model="data.historico"></v-checkbox>
        </v-toolbar>

        <v-form ref="form" lazy-validation>
          <v-card-actions>
            <span class="ml-3 subtitle-1 font-weight-black"
              >Datos de la Progenitora</span
            >
            <v-divider></v-divider>
          </v-card-actions>
          <v-card-text>
            <v-row dense>
              <v-col cols="6">
                <v-switch
                  :readonly="readonly"
                  v-model="mellizo"
                  :label="`Mellizos?: ${tipo}`"
                ></v-switch>
              </v-col>
              <v-spacer></v-spacer>
    
                   <v-col cols="12" sm="6">
                <AutocompleteAnimal
                  :action="fetche"
                  :getter="gett"
                  label="Seleccione a la animal Madre"
                  id="input1"
                  no-create
                  v-model="data.idanimal.idanimal"
                  :animal="animal"
                  :readonly="readonly"
                  @keyup.native.enter="next(1, data.idanimal.idanimal)"
                />
              </v-col>
                        <v-col cols="12" sm="3">
                <c-text-date
                  label="Fecha del Parto"
                  id="input2"
                  dense
                  outlined
                  :readonly="readonly"
                  v-model="data.fecpparto"
                  @keyup.native.enter="next(2, data.fecpparto)"
                />
              </v-col>
           
              <v-col cols="12" sm="3">
                <vue-autocomplete-generic
                  label="Seleccione un Responsable"
                  id="input3"
                  action="responsable/Vact_responsable_data"
                  getter="responsable/Vget_responsable_data"
                  load="responsable/Vget_responsable_load"
                  vuex="responsable/responsable_create"
                  pk="idresponsable"
                  title="descresponsable"
                  permission="Responsable"
                  :create="true"
                  :readonly="readonly"
                  v-model="data.idresponsable.idresponsable"
                  @keyup.native.enter="
                    next(3, data.idresponsable.idresponsable)
                  "
                />
                <input-dialog v-if="responsable_open" :value="true">
                  <template slot="body">
                    <Animal-Responsable-create />
                  </template>
                </input-dialog>
              </v-col>
              <v-col cols="12" sm="3">
                <input-autocomplete
                  label="Seleccione el Tipo de Parto"
                  api="/apitipoparto/"
                  pk="idtipoparto"
                  permission="Tipo Parto"
                  title="desctipoparto"
                  vuex="tipoparto/tipoparto_create"
                  id="input4"
                  :key="`C${tipoparto_key}`"
                  :readonly="readonly"
                  v-model="data.idtipoparto.idtipoparto"
                  @keyup.native.enter="next(4, data.idtipoparto.idtipoparto)"
                />
                <input-dialog v-if="tipoparto_open" :value="true">
                  <template slot="body">
                    <Animal-Tipoparto-create />
                  </template>
                </input-dialog>
              </v-col>
              <v-col cols="12" sm="3">
                <input-autocomplete
                  label="Seleccione el estado de la Cria"
                  api="/apiestadocria/"
                  pk="idestadocria"
                  title="descestadocria"
                  permission="Estado Cria"
                  vuex="estadocria/estadocria_create"
                  id="input5"
                  :key="`D${estadocria_key}`"
                  :readonly="readonly"
                  v-model="data.idestadocria.idestadocria"
                  @keyup.native.enter="next(5, data.idestadocria.idestadocria)"
                />
                <input-dialog v-if="estadocria_open" :value="true">
                  <template slot="body">
                    <Animal-Estadocria-create />
                  </template>
                </input-dialog>
              </v-col>
              <v-col cols="12" sm="12">
                <c-text-field
                  label="Comentario del parto"
                  id="input6"
                  :rules="[]"
                  outlined
                  dense
                  :readonly="readonly"
                  v-model="data.comnparto"
                  @keyup.native.enter="next(7, '0')"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <span class="ml-3 subtitle-1 font-weight-black"
              >Datos de la Cria</span
            >
            <v-divider></v-divider>
          </v-card-actions>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" sm="3">
                <vue-autocomplete-generic
                  label="Seleccione procedencia"
                  id="input8"
                  action="procedencia/Vact_procedencia_data"
                  getter="procedencia/Vget_procedencia_data"
                  load="procedencia/Vget_procedencia_load"
                  pk="idprocedencia"
                  title="descprocedencia"
                  :readonly="readonly"
                  permission="Procedencia"
                  @create="formularios.procedencia = $event"
                  :create="true"
                  v-model="animal_1.idanimal.idprocedencia.idprocedencia"
                  @keyup.native.enter="
                    next(8, animal_1.idanimal.idprocedencia.idprocedencia)
                  "
                />
                <div v-if="formularios.procedencia">
                  <Animal-procedencia-create
                    v-model="formularios.procedencia"
                  />
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <vue-autocomplete-generic
                  id="input9"
                  action="deposito/Vact_deposito_data"
                  getter="deposito/Vget_deposito_data"
                  load="deposito/Vget_deposito_load"
                  pk="iddeposito"
                  label="Deposito"
                  title="desdeposito"
                  :readonly="readonly"
                  :create="true"
                  @create="formularios.deposito = $event"
                  permission="Deposito"
                  v-model="animal_1.idanimal.iddeposito.iddeposito"
                  @keyup.native.enter="
                    next(9, animal_1.idanimal.iddeposito.iddeposito)
                  "
                />
                <div v-if="formularios.deposito">
                  <Item-Deposito-create v-model="formularios.deposito" />
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <vue-autocomplete-generic
                  label="Seleccione el item"
                  id="input10"
                  action="item/Vact_item_vivo"
                  getter="item/Vget_item_vivo"
                  load="item/Vget_item_load"
                  pk="iditem"
                  title="descitem"
                  permission="Item"
                  :readonly="readonly"
                  :create="true"
                  @create="item_open = $event"
                  v-model="animal_1.idanimal.iditem.iditem"
                  @keyup.native.enter="
                    next(10, animal_1.idanimal.iditem.iditem)
                  "
                />
                <div v-if="item_open">
                  <Item-Item-create v-model="item_open" />
                </div>
              </v-col>
              <v-col cols="12" sm="3">
                <AutocompleteCosto
                  id="input11"
                  :readonly="readonly"
                  v-model="animal_1.idcostonacimiento.idcostonacimiento"
                  @keyup.native.enter="
                    next(11, animal_1.idcostonacimiento.idcostonacimiento)
                  "
                />
              </v-col>

              <v-col cols="12" sm="3">
                <v-autocomplete
                  v-model="animal_1.idanimal.idsexo.idsexo"
                  id="input12"
                  dense
                  :loading="loadsexo"
                  :rules="validars"
                  v-bind:items="sexo"
                  fkdes="item"
                  label="Seleccione el sexo"
                  item-text="fkdes"
                  item-value="id"
                  required
                  :readonly="readonly"
                  outlined
                  height="12"
                  autocomplete="off"
                  @keyup.native.enter="
                    next(12, animal_1.idanimal.idsexo.idsexo)
                  "
                  :no-data-text="`No existe ninguna concidencia`"
                  small-chips
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3">
                <c-text-field
                  label="Nombre del animal"
                  id="input13"
                  outlined
                  :readonly="readonly"
                  dense
                  :rules="[]"
                  v-model="animal_1.idanimal.nombanimal"
                  @keyup.native.enter="next(13, '0')"
                />
              </v-col>
              <v-col cols="12" sm="3">
                <c-text-field
                  label="RP del animal"
                  id="input14"
                  outlined
                  :readonly="readonly"
                  dense
                  v-model="animal_1.idanimal.rpanimal"
                  @keyup.native.enter="next(14, animal_1.idanimal.rpanimal)"
                />
              </v-col>
              <v-col cols="12" sm="3">
                <v-autocomplete
                  v-model="animal_1.idanimal.idlote.idlote"
                  id="input15"
                  :loading="isLoteLoading"
                  :rules="validars"
                  v-bind:items="getLote"
                  label="Seleccione el lote"
                  item-text="desclote"
                  item-value="idlote"
                  required
                  :readonly="readonly"
                  outlined
                  height="12"
                  autocomplete="off"
                  @keyup.native.enter="
                    next(15, animal_1.idanimal.idlote.idlote)
                  "
                  :no-data-text="`No existe ninguna concidencia`"
                  small-chips
                  dense
                >
                  <template v-if="noData && lote_permission" v-slot:no-data>
                    <v-list-item @click="formularios.lote = true">
                      <v-list-item-content>
                        <v-list-item-title
                          >No existe concidencia. Click para
                          agregar</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3">
                <v-autocomplete
                  v-model="animal_1.idanimalrol.idanimalrol"
                  id="input16"
                  :loading="loadrol"
                  :rules="validars"
                  v-bind:items="rol"
                  fkdes="item"
                  label="Seleccione el rol"
                  item-text="fkdes"
                  item-value="id"
                  required
                  :readonly="readonly"
                  outlined
                  height="12"
                  autocomplete="off"
                  @keyup.enter="next(16, animal_1.idanimalrol.idanimalrol)"
                  :no-data-text="`No existe ninguna concidencia`"
                  small-chips
                  dense
                >
                  <template v-if="noData && rol_permission" v-slot:no-data>
                    <v-list-item @click="rol_create(true)">
                      <v-list-item-content>
                        <v-list-item-title
                          >No existe concidencia. Click para
                          agregar</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions v-if="mellizo">
            <span class="ml-3 subtitle-1 font-weight-black"
              >Datos de la 2da Cria</span
            >
            <v-divider></v-divider>
          </v-card-actions>
          <v-card-text>
            <v-row dense v-if="mellizo">
              <v-col cols="12" sm="3">
                <v-autocomplete
                  dense
                  v-model="animal_2.idanimal.idsexo.idsexo"
                  id="input17"
                  :loading="loadsexo"
                  :rules="validars"
                  v-bind:items="sexo"
                  fkdes="item"
                  label="Seleccione el sexo de segundo Animal"
                  item-text="fkdes"
                  item-value="id"
                  required
                  outlined
                  :readonly="readonly"
                  height="12"
                  autocomplete="off"
                  @keyup.native.enter="
                    next(17, animal_2.idanimal.idsexo.idsexo)
                  "
                  :no-data-text="`No existe ninguna concidencia`"
                  small-chips
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3">
                <c-text-field
                  label="Nombre del segundo animal"
                  id="input18"
                  outlined
                  dense
                  :readonly="readonly"
                  :rules="[]"
                  v-model="animal_2.idanimal.nombanimal"
                  @keyup.native.enter="next(18, '0')"
                />
              </v-col>
              <v-col cols="12" sm="3">
                <c-text-field
                  label="RP del segundo animal"
                  id="input19"
                  outlined
                  :readonly="readonly"
                  dense
                  v-model="animal_2.idanimal.rpanimal"
                  @keyup.native.enter="next(19, animal_2.idanimal.rpanimal)"
                />
              </v-col>
              <v-col cols="12" sm="3">
                <v-autocomplete
                  v-model="animal_2.idanimal.idlote.idlote"
                  id="input20"
                  :loading="isLoteLoading"
                  :rules="validars"
                  v-bind:items="getLote"
                  fkdes="item"
                  label="Seleccione el lote del segundo animal"
                  item-text="desclote"
                  item-value="idlote"
                  required
                  :readonly="readonly"
                  outlined
                  height="12"
                  autocomplete="off"
                  @keyup.native.enter="
                    next(20, animal_2.idanimal.idlote.idlote)
                  "
                  :no-data-text="`No existe ninguna concidencia`"
                  small-chips
                  dense
                >
                  <template v-if="noData && lote_permission" v-slot:no-data>
                    <v-list-item @click="formularios.lote = true">
                      <v-list-item-content>
                        <v-list-item-title
                          >No existe concidencia. Click para
                          agregar</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="3">
                <v-autocomplete
                  v-model="animal_2.idanimalrol.idanimalrol"
                  id="input21"
                  :loading="loadrol"
                  :rules="validars"
                  v-bind:items="rol"
                  fkdes="item"
                  label="Seleccione rol del segundo animal"
                  item-text="fkdes"
                  item-value="id"
                  required
                  :readonly="readonly"
                  outlined
                  height="12"
                  dense
                  autocomplete="off"
                  @keyup.enter="next(21, animal_2.idanimalrol.idanimalrol)"
                  :no-data-text="`No existe ninguna concidencia`"
                  small-chips
                >
                  <template v-if="noData && rol_permission" v-slot:no-data>
                    <v-list-item @click="rol_create(true)">
                      <v-list-item-content>
                        <v-list-item-title
                          >No existe concidencia. Click para
                          agregar</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>

        <v-divider></v-divider>
        <v-card-actions>
          <c-btn-crud-close @click="close()"></c-btn-crud-close>

          <v-spacer></v-spacer>

          <v-btn
            id="aceptar"
            color="blue darken-1"
            :disabled="readonly"
            text
            @click="guardar()"
          >
            <v-icon dark>check</v-icon>
          </v-btn>
        </v-card-actions>
        <c-overlay :value="isLoading" />
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { db } from "@/services/db.service";
import { focus } from "@/services/focus.service";
import { booleanConverter } from "@/services/util/boolean.service";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { current_date } from "@/services/util/date.service";
import { LOTE, ROL } from "@/constants/forms";
import { PUEDE_AGREGAR } from "@/constants/permissions";
import AutocompleteCosto from "../Costonacimiento/Autocomplete";
export default {
  components: {
    AutocompleteCosto,
    AutocompleteAnimal: () =>
      import("@/views/Animal/Animal/AutocompleteAnimal"),
  },
  props: {
    value: Boolean,
    props: Object,
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fetche: "fetchPrenada",
      gett: "getPrenada",
      item_open: false,
      noData: true,
      mellizo: false,
      isEdit: false,
      data: {
        idparto: 0,
        fechparto: "2010-10-10",
        fecpparto: current_date(),
        comnparto: "",
        idanimal: {
          idanimal: "",
        },
        idresponsable: {
          idresponsable: "",
        },
        idtipoparto: {
          idtipoparto: "",
        },
        idestadocria: {
          idestadocria: "",
        },
        parto: [],
        historico: false,
      },
      default: {
        idparto: 0,
        fechparto: "2010-10-10",
        fecpparto: current_date(),
        comnparto: "",
        idanimal: {
          idanimal: "",
        },
        idresponsable: {
          idresponsable: "",
        },
        idtipoparto: {
          idtipoparto: "",
        },
        idestadocria: {
          idestadocria: "",
        },
        parto: [],
        historico: false,
      },
      animal_1: {
        idanimal: {
          idanimal: 0,
          issemen: false,
          fechanimal: "",
          nombanimal: "",
          rpanimal: "",
          idestadoanimal: {
            idestadoanimal: "1",
          },
          idprocedencia: {
            idprocedencia: "",
          },
          idlote: {
            idlote: "",
          },
          iddeposito: {
            iddeposito: "",
          },
          iditem: {
            iditem: "",
          },
          idsexo: {
            idsexo: "",
          },
          montanimal: "0",
        },
        idanimalrol: {
          idanimalrol: "",
        },
        idparto: 0,
        idcostonacimiento: {
          idcostonacimiento: "",
        },
      },
      animal_2: {
        idanimal: {
          idanimal: 0,
          issemen: false,
          fechanimal: "",
          nombanimal: "",
          rpanimal: "",
          idestadoanimal: { idestadoanimal: "1" },
          idprocedencia: { idprocedencia: "" },
          idlote: { idlote: "" },
          iddeposito: { iddeposito: "" },
          iditem: { iditem: "" },
          idsexo: { idsexo: "" },
          montanimal: "0",
        },
        idanimalrol: { idanimalrol: "" },
        idparto: 0,
        idcostonacimiento: { idcostonacimiento: "" },
      },
      animal_2_default: {
        idanimal: {
          idanimal: 0,
          issemen: false,
          fechanimal: "",
          nombanimal: "",
          rpanimal: "",
          idestadoanimal: { idestadoanimal: "1" },
          idprocedencia: { idprocedencia: "" },
          idlote: { idlote: "" },
          iddeposito: { iddeposito: "" },
          iditem: { iditem: "" },
          idsexo: { idsexo: "" },
          montanimal: "0",
        },
        idanimalrol: { idanimalrol: "" },
        idparto: 0,
        idcostonacimiento: { idcostonacimiento: "" },
      },
      animal_1_default: {
        idanimal: {
          idanimal: 0,
          issemen: false,
          fechanimal: "",
          nombanimal: "",
          rpanimal: "",
          idestadoanimal: { idestadoanimal: "1" },
          idprocedencia: { idprocedencia: "" },
          idlote: { idlote: "" },
          iddeposito: { iddeposito: "" },
          iditem: { iditem: "" },
          idsexo: { idsexo: "" },
          montanimal: "0",
        },
        idanimalrol: { idanimalrol: "" },
        idparto: 0,
        idcostonacimiento: { idcostonacimiento: "" },
      },
      validars: [(v) => !!v || "Seleccione un campo"],
      sexo: [],
      rol: [],
      loadsexo: true,
      loadrol: true,
      animal: null,
      formularios: {
        raza: false,
        lote: false,
        procedencia: false,
        deposito: false,
      },
    };
  },

  watch: {
    $route() {
      return this.close();
    },
    reload_rol(val) {
      if (val) return this.nuevo_rol();
    },
    'data.historico'() {
      if (this.data.historico == true) {
        this.fetche = 'fetchTodos'
        this.gett = 'getTodos'
      } else {
        this.fetche = 'fetchPrenada'
        this.gett = 'getPrenada'
      }
    },
  },
  computed: {
    ...mapGetters("parto", ["isLoading"]),
    ...mapGetters("lote", ["getLote"]),
    ...mapGetters("lote",{isLoteLoading: "isLoading"}),
    ...mapGetters("responsable", {
      responsable_open: "responsable_create",
    }),
    ...mapGetters("tipoparto", {
      tipoparto_open: "tipoparto_create",
      tipoparto_key: "tipoparto_key",
    }),
    ...mapGetters("estadocria", {
      estadocria_open: "estadocria_create",
      estadocria_key: "estadocria_key",
    }),
    ...mapGetters("rol", {
      abrirrol: "rol_create",
      reload_rol: "rol_reload",
    }),

    lote_permission() {
      return getPermission(LOTE).permiso[PUEDE_AGREGAR];
    },
    rol_permission() {
      return getPermission(ROL).permiso[PUEDE_AGREGAR];
    },
    tipo() {
      return booleanConverter.si_no(this.mellizo);
    },
  },
  created() {
    this.nuevo(this.props);
  },
  mounted() {
    setTimeout(() => document.getElementById("input1").focus(), 500);
  },
  methods: {
    ...mapActions("parto", ["setParto", "setPartoUpdate", "fetchPartoNuevo"]),
    ...mapActions("animal", ["fetchPrenada"]),
    ...mapActions("lote", ["fetchLote"]),
    ...mapActions("estadocria", ["estadocria_create"]),
    ...mapActions("rol", ["rol_create", "rol_reload"]),
    next(id, data) {
      focus.nextid(id, data);
    },

    close() {
      this.$emit("input", false);
      this.$emit("reset", null);
      this.fetchPartoNuevo();
      this.vaciarcampos();
    },

    asimilar_datos() {
      this.data.parto = [];
      if (this.data.comnparto === "") {
        this.data.comnparto = null;
      }

      if (this.mellizo) {
        if (this.animal_2.nombanimal === "") {
          this.animal_2.nombanimal = null;
        }
        this.animal_1.idanimal.fechanimal = this.data.fecpparto;
        this.animal_2.idanimal.fechanimal = this.data.fecpparto;
        this.animal_2.idanimal.idprocedencia.idprocedencia = this.animal_1.idanimal.idprocedencia.idprocedencia;
        this.animal_2.idanimal.iddeposito.iddeposito = this.animal_1.idanimal.iddeposito.iddeposito;
        this.animal_2.idanimal.iditem.iditem = this.animal_1.idanimal.iditem.iditem;
        this.animal_2.idcostonacimiento.idcostonacimiento = this.animal_1.idcostonacimiento.idcostonacimiento;
        this.data.parto.push(this.animal_1);
        this.data.parto.push(this.animal_2);
      } else {
        if (this.animal_1.nombanimal === "") {
          this.animal_1.nombanimal = null;
        }
        this.animal_1.idanimal.fechanimal = this.data.fecpparto;
        this.data.parto.push(this.animal_1);
      }
    },
    vaciarcampos() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.animal_1 = JSON.parse(JSON.stringify(this.animal_1_default));
      this.animal_2 = JSON.parse(JSON.stringify(this.animal_2_default));
    },
    async guardar() {
      if (!this.$refs.form.validate()) return null;
      this.asimilar_datos();
      const response = this.isEdit
        ? await this.setPartoUpdate({ id: this.data.idparto, data: this.data })
        : await this.setParto(this.data);
      if (response.success) {
        if (this.isEdit) return this.close();
        this.fetchPrenada();
        this.vaciarcampos();
        this.$refs.form.resetValidation();
      }
    },
    async nuevo_sexo() {
      this.sexo = await db.get("/apisexocria/", "idsexo", "descsexocria");
      this.loadsexo = false;
    },

    async nuevo_rol() {
      this.rol = await db.get("/apirol/", "idanimalrol", "descanimalrol");
      this.loadrol = false;
      this.rol_reload(false);
    },

    async nuevo(val) {
      this.fetchLote();
      this.nuevo_sexo();
      this.nuevo_rol();
      if (!val) return null;
      this.id = val.idparto;
      this.animal = JSON.parse(JSON.stringify(val.idanimal));
      this.animal_1 = JSON.parse(JSON.stringify(val.parto[0]));
      val.parto.map((x, index) => {
        if (index === 1) {
          this.mellizo = true;
          this.animal_2 = val.parto[1];
        }
      });
      this.data = JSON.parse(JSON.stringify(val));
      this.isEdit = true;
    },
  },
};
</script>
