<template>
  <div v-if="permission.can_view">
    <v-data-table :headers="headers" :items="getPlaga" :loading="isLoading">
      <template v-slot:[`item.fecha`]="{ item }">
        <div>{{ formatDate(item.fecha) }}</div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <c-btn-table-edit
          @click="update_item(item)"
          v-if="permission.can_update"
        />
        <c-btn-table-delete
          @click="delete_item(item)"
          v-if="permission.can_delete"
        />
      </template>
    </v-data-table>
    <Delete
      v-if="crud.delete"
      v-model="crud.delete"
      :props="crud.datos"
      @datos="crud.datos = $event"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { dmy } from "@/services/util/date.service";
import Delete from "./Delete";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { REGISTRO_PLAGA } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";
export default {
  components: {
    Delete,
  },
  data: () => ({
    crud: {
      delete: false,
      datos: null,
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
    },
    headers: [
      { text: "Fecha", value: "fecha" },
      { text: "Plaga", value: "idplagas.descripcion" },
      { text: "Tipo de Plaga", value: "idplagas.idtipo_plagas.descripcion" },
      { text: "", value: "actions", align: "end" },
    ],
  }),
  created() {
    const permission = getPermission(REGISTRO_PLAGA).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  mounted() {
    this.fetchPlaga();
  },
  computed: {
    ...mapGetters("plaga", ["getPlaga", "isLoading"]),
  },
  methods: {
    ...mapActions("plaga", ["fetchPlaga", "fetchPlagaId"]),
    formatDate(date) {
      return dmy(date);
    },
    async update_item(item) {
      await this.fetchPlagaId({ data: item });
      this.$router.push({
        path: `/agricultura/campo/plagas/edit/` + item.idregistro_plagas,
      });
    },
    delete_item(item) {
      this.crud.datos = JSON.parse(JSON.stringify(item));
      this.crud.delete = true;
    },
  },
};
</script>
