import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { dmy,current_date } from "@/services/util/date.service";
import store from '@/store'
const Reporte = (datos) => {
    const fecha = dmy(current_date());
    var doc = new jsPDF();
    let pageWidth = doc.internal.pageSize.width;
    let fechaWidth = doc.getTextWidth(fecha) + 5;
    doc.setFontSize(16);
    store.getters['empresa/Vget_empresa_active'].forEach(empresa => {
        if (empresa.est_emp_tam_usu)
            return doc.text(empresa.idempresa.descempresa, 13, 20);
    })
    doc.setFontSize(11);
    doc.text(fecha, pageWidth - fechaWidth, 20);
    doc.text('Reporte de Pedidos', doc.internal.pageSize.width / 2, 30, { align: 'center' });
    let head =[ [ 'Codigo', 'Cliente','Responsable', 'Fecha Pedido', 'Fecha Entrega', 'Estado', 'SubTotal']];
    let total = 0;
    let body = datos.map(x => {
        total = Number(total) + Number(x.total)
        return [
            x.idpedido_venta,
            x.idcontacto.desccontacto || '',
            x.idresp != null ? x.idresp.desccontacto : '',
            dmy(x.fecha),
            dmy(x.fecha_entrega),
            x.idestadopedido.descestadopedido,
            new Intl.NumberFormat(
                { maximumSignificantDigits: 3 }
            ).format(x.total)
        ]
    });
    doc.autoTable({
        head: head,
        body: body,
        startY: 40,
        theme: 'grid',
        styles: {
            fontSize: 8
        },
        columnStyles: { 6: { halign: 'right', fontStyle: 'bold' } },
        headStyles: {
            textColor: [0, 0, 0],
            fillColor: [255, 255, 255],
            fontSize: 8,
        },
    });

    doc.autoTable({
        body: [['Total', new Intl.NumberFormat(
            { maximumSignificantDigits: 3 }
        ).format(total)]],
        startY: doc.previousAutoTable.finalY + 2,
        theme: 'grid',
        styles: { lineWidth: 0.5 },
        columnStyles: { 1: { halign: 'right', fontStyle: 'bold', cellWidth: 30 } },
        headStyles: {
            textColor: [0, 0, 0],
            fillColor: [255, 255, 255],
            fontSize: 8,
        },
    });

    doc.save('Reporte' + ' ' + current_date() + '.pdf');
}
export default Reporte;
