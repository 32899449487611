<template>
  <div>
    <v-dialog :value="value" max-width="650" persistent>
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>{{
            operacion ? "Agregar Campaña" : "Modificar Campaña"
          }}</v-toolbar-title>
        </v-toolbar>
        <v-container>
          <v-form ref="form" @submit.prevent="next(1, data.descripcion)">
            <v-row dense>
              <v-col cols="12">
                <vue-text-field
                  label="Descripcion"
                  id="campanaInput1"
                  v-model="data.descripcion"
                  @keyup.native.enter="next(1, data.descripcion)"
                ></vue-text-field>
              </v-col>
              <v-col cols="12">
                <c-text-date
                  label="Fecha de Inicio"
                  id="campanaInput2"
                  outlined
                  dense
                  v-model="data.fechacampana"
                  @keyup.native.enter="next(2, data.fechacampana)"
                ></c-text-date>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  dense
                  v-model="data.iditem"
                  id="campanaInput3"
                  v-bind:items="Vget_item_siembra_default"
                  label="Tipo de Cultivo"
                  item-text="descitem"
                  item-value="iditem"
                  return-object
                  :rules="[(v) => !!v.iditem || 'Seleccione un Item']"
                  :loading="Vget_item_load"
                  required
                  outlined
                  autocomplete="off"
                  @keyup.enter="next(3, data.iditem.iditem)"
                  :no-data-text="`No existe ninguna concidencia`"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="cancelar()">
            <v-icon>clear</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            id="campanaInputaceptar"
            text
            @click="guardar()"
          >
            <v-icon>done</v-icon>
          </v-btn>
        </v-card-actions>
        <c-overlay :value="Vget_campana_save_load"></c-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { mensaje } from "@/services/notify.service";
import { current_date } from "@/services/util/date.service";
import { focus } from "@/services/focus.service";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    datos: {
      type: [Object, Array],
      default: null,
    },
    "sync-input": {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      sync: null,
      data: {
        campanadet: [],
        descripcion: "",
        iditem: {
          iditem: "",
        },
        estado: true,
        fechacampana: current_date(),
        idcampana: 0,
      },
      default: {
        campanadet: [],
        descripcion: "",
        iditem: {
          iditem: "",
        },
        estado: true,
        fechacampana: current_date(),
        idcampana: 0,
      },
      operacion: true,
    };
  },
  watch: {
    $route() {
      return this.cancelar();
    },
  },
  computed: {
    ...mapGetters("campana", ["Vget_campana_save_load"]),
    ...mapGetters("item", ["Vget_item_load", "Vget_item_siembra_default"]),
  },
  mounted() {
    setTimeout(() => this.next(0, "-"), 200);
    this.Vact_item_siembra_default();
    if (this.syncInput != null) {
      this.data.descripcion = this.syncInput;
       this.sync = this.syncInput;
    }
    this.nuevo(this.datos);
  },
  methods: {
    ...mapActions("campana", [
      "Vact_campana_save",
      "Vact_campana_update",
      "Vact_campana_data",
    ]),
    ...mapActions("item", ["Vact_item_siembra_default"]),
    next(id, data) {
      focus.nextidName("campanaInput", id, data);
    },
    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.Vact_campana_data();
      this.operacion = true;
      this.$emit("input", false);
      this.$emit("datos", null);
      this.operacion = true;
    },
    async guardar_finalizar() {
      let a = await this.Vact_campana_save(this.data);
      if (a.estado == true) {
        this.data = JSON.parse(JSON.stringify(this.default));
        if (this.sync != null) {
          this.$emit("sync-input", a.data.data.id);
          this.cancelar();
        }
        this.$notify(a.info);
        this.$refs.form.resetValidation();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
    },

    async editar_finalizar() {
      const id = this.data.idcampana;
      let a = await this.Vact_campana_update({ id, data: this.data });
      if (a.estado == true) {
        this.$notify(a.info);
        this.cancelar();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
    },
    guardar() {
      if (!this.$refs.form.validate()) return this.$notify(mensaje.completar());
      if (this.operacion === true) return this.guardar_finalizar();
      if (this.operacion === false) return this.editar_finalizar();
    },

    nuevo(val) {
      if (val != null) {
        this.data = JSON.parse(JSON.stringify(val));
        this.operacion = false;
      }
    },
  },
};
</script>
