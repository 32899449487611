<script>
import { mapGetters, mapActions } from "vuex";
var self = null;
export default {
  props: {
    google: {
      type: Object,
      required: true,
    },
    map: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      listeners: {
        pest: null,
      },
      markers: {
        pest: [],
      },
    };
  },
  created() {
    self = this;
  },
  watch: {
    getPlagaId(val) {
      const position = new self.google.maps.LatLng(
        val.poligono[1],
        val.poligono[0]
      );
      self.centerPestMarker(position);
    },
    getPlagaZona(val) {
      self.markers.pest.map((x) => x.setMap(null));
      val.map((x) => {
        const position = new self.google.maps.LatLng(
          x.coordenada[1],
          x.coordenada[0]
        );
        self.centerPestMarker(position, true, x.name);
      });
    },
    $route(to, from) {
      if (to.path === "/agricultura/campo/plagas/add" || to.name === "Modificar Plaga") {
        self.addPestMarker();
      }
      if (
        from.path === "/agricultura/campo/plagas/add" ||
        from.name === "Modificar Plaga"
      ) {
        self.removePestMarker();
      }
    },
  },
  computed: {
    ...mapGetters("plaga", ["getPlagaId", "getPlagaZona"]),
  },
  methods: {
    ...mapActions("plaga", ["setCoordenadaPlaga"]),
    viewInfoMap(marker,PestName) {
      let contentString =
        `<h4 style="text-align: center;">Plaga</h4><hr>` +
        `<table>` +
        `<tr>` +
        `<th style="text-align: left; color:black;">Tipo:  </th>` +
        `<td style="color:black; text-align: right;"> ` +
        PestName + `</td>` +
        `</tr>` +
        `</table>`;

      let infowindow = new this.google.maps.InfoWindow({
        content: contentString,
      });
      infowindow.open(self.map, marker);
    },
    centerPestMarker(position, viewInfoMap, PestName) {
      const marker = new self.google.maps.Marker({
        position: position,
        map: self.map,
        icon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAACuElEQVRIidVVTUiUURQ99775LJswylL8wyiodtGi/JkxiUlDHS0XIxTUtnUSRLvaRItaBy4LWmgUSNMijdDG0m2I0R8YOY5mJoWT8/O9d1vo6Ix+M45togMffO+e9865977He8C/xFd/XcVch6f8b3kA4FwkGfZog4beQECt53oDAWWBvbYmTy4NVy5SEUdBUlTzO1Id8Xsvg3ARAgHhAZtIjxAxhBb/2kDY/kxQxyzGXQjOQVIErukEDjPQ61LmUy4NSv3MtnlPGxYrYWi0OhhaWNYBzfob/UKmDyLbMsyBBBurq/TZy37CsvWXNu/uApZaNpQsDYYGMwym2+ubGVwMYUMs7xJz9kerRHWKxj0ARVkSXBLDV7Q7eb9gSR0S8BGQYU3yvaJ/ZAAAVjev5mjtZJUddRmSfQSUqB3qEgzdBlCYowMWQdqUrUpBiGoSAuj9yPay0b6JCcmoIIWpTl8xx/VxYvsRAHeu/qa3S4zVtdMde7Wr782PdG7DMa188mJesX0qX/GVLAsUJVvWizsarGTUmq/46hpCk1Pc2YCwP8vkG2DczOJR5hR0AUC4w9OkhPauOcAtDpOF8IEEcOIAFM60e8+nBmQwVxoMDTIAuDRlrBGHzd8q7JSRExnxex2TJMIFABDBQye+7Glog162yy6xeY6ZECDuFHc2IJneSo9o+QvnZTDV6Ssm0HiWjXSEAQCh8Z+Buj1ZDSQQUDOt3hqy480Mei6QRL5VEBCHMgOLMXUm4m84IWnvx6rBt1jYB4UDokULYYhFropkO5FpEAgE3QweFi2aSA5GYmFfil57DwwbI1goFPt1cf/YLwBvp1sbkkxyR5avDZ0mqwEoAqIGqrs8ONQDAPMtNZNxbdULsUmrLjfCZ09WsTHXjRHNoGEQCQSNwqKMlbxV+XhsatMq/2v8Ad/rElJ4Z0YVAAAAAElFTkSuQmCC",
      });
      self.markers.pest.push(marker);
      self.map.panTo(position);
      if (viewInfoMap) self.viewInfoMap(marker,PestName);
    },
    addPestMarker() {
      self.listeners.addPestMarker = self.map.addListener("click", (e) => {
        if (self.markers.pest.length > 0)
          self.markers.pest.map((x) => x.setMap(null));
        self.centerPestMarker(e.latLng);
        self.setCoordenadaPlaga([e.latLng.lng(), e.latLng.lat()]);
      });
    },
    removePestMarker() {
      self.google.maps.event.removeListener(self.listeners.addPestMarker);
      self.setCoordenadaPlaga(null);
      if (self.markers.pest.length > 0)
        self.markers.pest.map((x) => x.setMap(null));
    },
  },
  // eslint-disable-next-line
  render() {},
};
</script>
