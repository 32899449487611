import { db } from "@/services/db.service";
import { get } from "@/services/api/api.service";
import {SET_HA_SEMBRABLE, url} from './contants'
export default {
    async fetchHaSembrable({dispatch,commit},{idlote,idcampana,iditem,idparcela}){
        try {
          const response = await get(`${url.haSembrable}${idlote}/${idcampana}/${iditem}/${idparcela}/`);
          commit(SET_HA_SEMBRABLE, response);
        } catch (e) {
          dispatch("snackbar/setNotification", e, { root: true });
          throw e;
        }
    },
    async Vact_siembra_move({ commit }, data) {
        commit('siembra_save_load', true);
        const response = await db.save('/apisiembramove/', data);
        commit('siembra_save_load', false);
        return response
    },
    async Vact_siembra_save({ dispatch, commit }, data) {
        commit('siembra_save_load', true);
        const campana = data.idcampana;
        try {
            const resp = await db.save('/apisiembra/', data);
            commit('siembra_save_load', false);
            if(Array.isArray(data.idlote_agri)){
                data.idlote_agri.map(x=>{
                    dispatch('Vact_siembra_timeline', { campana , idlote_agri: x.idlote_agri.idlote_agri });
                })
            }else{
                dispatch('Vact_siembra_timeline', { campana , idlote_agri: data.idlote_agri.idlote_agri  });
            }
            return resp;
        } catch (e) {
            commit('siembra_save_load', false);
            return false;
        }
    },
    async Vact_siembra_update({dispatch, commit }, { id, data }) {
        commit('siembra_save_load', true);
        const campana = data.idcampana;
        const idlote_agri = data.idlote_agri.idlote_agri;
        try {
            const resp = await db.update('/apisiembra/' + id + '/', data);
            dispatch('Vact_siembra_timeline', { campana , idlote_agri  });
            commit('siembra_save_load', false);
            return resp;
        } catch (e) {
            commit('siembra_save_load', false);
            return e
        }
    },
    async Vact_siembra_data_byId({ commit }, id) {
        commit('siembra_load', true);
        try {
            let data = await db.get('/apisiembra/'+id+'/');
            data.siembradet.forEach(x => {
                if (!x.tiposiembra) {
                    x.iditem.idtipo_item.desctipo_item = 'Replantacion'
                }
            });
            commit('siembra_data_byId', data);
            commit('siembra_load', false);
            return true
        } catch (e) {
            commit('siembra_load', false);
            return false
        }
    },

    async Vact_siembra_timeline({ commit }, { campana, idlote_agri }) {

        commit('siembra_timeline', null);
        if (campana === null || campana === '') {
            return true;
        }
        commit('siembra_load', true);
        try {
            const id = campana.idcampana;
            const data = await db.get('/apitimeline/' + id + '/' + idlote_agri+'/');
            commit('siembra_timeline', data);
            commit('siembra_load', false);
            return true
        } catch (e) {
            commit('siembra_load', false);
            return false
        }
    },

    async Vact_siembra_del({ dispatch }, { id }) {
        try {
            const data = await db.delete('/apisiembra/' + id.idsiembra +'/');
            const campana = id.idcampana;
            const idlote_agri = id.idlote_agri.idlote_agri
            dispatch('Vact_siembra_timeline', {campana, idlote_agri});
            return data
        } catch (e) {
            return false
        }
    },
}
