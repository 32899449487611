<template>
  <div>
    <c-alert-permission v-model="permission"></c-alert-permission>
    <div v-if="permission.generar_reporte">
      <v-toolbar flat dense>
        <v-toolbar-title>{{ titulo }}</v-toolbar-title>
        <widgets-Favorite :ruta="route.main" :formulario="titulo" />
      </v-toolbar>
      <v-form ref="form">
        <v-row dense class="mx-2">
          <v-col cols="12" sm="3">
            <c-text-date
              dense
              outlined
              label="Desde"
              id="repAvanzadoInput1"
              v-model="data.fechaDesde"
              @keyup.native.enter="next(1, data.fechaDesde)"
            ></c-text-date>
          </v-col>
          <v-col cols="12" sm="3">
            <c-text-date
              dense
              outlined
              label="Hasta"
              id="repAvanzadoInput2"
              v-model="data.fechaHasta"
              @keyup.native.enter="next(2, data.fechaHasta)"
            ></c-text-date>
          </v-col>
          <v-col cols="12" sm="3">
            <v-autocomplete
              :items="filtro"
              dense
              label="Agrupar Por"
              id="repAvanzadoInput3"
              item-text="desc"
              return-object
              :rules="validar"
              outlined
              v-model="data.filtro"
              @change="changeHeader(), resetTable()"
              @keyup.native.enter="next(3, data.filtro.id)"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-form>
      <v-row dense class="mx-2">
        <v-col cols="12" sm="3" v-for="(item, i) of filtro" :key="i">
          <v-autocomplete
            :items="getItems(item.id)"
            dense
            :id="`repAvanzadoInput${4 + i}`"
            :ref="`input${4 + i}`"
            :label="item.desc"
            multiple
            return-object
            :loading="getLoad(item.id)"
            :item-value="item.value"
            :item-text="item.name"
            outlined
            v-model="data[item.value]"
            @change="resetTable()"
            @keyup.native.enter="
              next(4 + i, '-'), ($refs[`input${4 + i}`][0].isMenuActive = false)
            "
          >
            <template slot="prepend-item">
              <v-list-item @click="getItemsId(item.id, item.value)">
                <v-list-item-content>
                  <v-list-item-title class="text-center"
                    >TODOS</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="data[item.value] = []">
                <v-list-item-content>
                  <v-list-item-title class="text-center"
                    >NINGUNO</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <div class="d-flex justify-end mr-3">
        <v-btn
          color="primary"
          fab
          small
          id="repAvanzadoInputaceptar"
          outlined
          text
          @click="descargarDatos()"
          class="mb-3"
        >
          <v-icon>cloud_download</v-icon>
        </v-btn>
        <v-btn
          v-if="datos"
          class="ml-1"
          color="red"
          fab
          small
          outlined
          text
          @click="exportarPdf()"
          ><v-icon>get_app</v-icon></v-btn
        >
        <SaveReport v-if="datos" :url="datos.url" :vista="data" />
      </div>
      <div class="ma-1" v-if="datos != null">
        <Header :datos="datos" :parametros="data" />
        <div v-for="(item, i) of desserts" :key="i">
          <v-progress-linear value="100" height="2"></v-progress-linear>
          <div class="subtitle-2 ml-3">
            Moneda <strong>{{ item.moneda }}</strong>
          </div>
          <v-data-table
            dense
            :headers="headers"
            :items="item.detalle"
            :items-per-page="500000"
            hide-default-footer
          >
            <template v-slot:item.precio_debito="props">
              <p class="text-end ma-2">
                {{ toCurrency(props.item.precio_debito) }}
              </p>
            </template>
            <template v-slot:item.precio_credito="props">
              <p class="text-end ma-2">
                {{ toCurrency(props.item.precio_credito) }}
              </p>
            </template>
            <template v-slot:item.saldo="props">
              <p class="text-end ma-2">
                {{ toCurrency(props.item.saldo) }}
              </p>
            </template>
            <template slot="body.append">
              <tr class="black--text">
                <th class="subtitle-1">TOTAL</th>
                <th class="subtitle-1"></th>
                <th class="subtitle-1 text-end">
                  {{ toCurrency(item.totales.precio_debito) }}
                </th>
                <th class="subtitle-1 text-end">
                  {{ toCurrency(item.totales.precio_credito) }}
                </th>
                <th class="subtitle-1 text-end">
                  {{ toCurrency(item.totales.saldo) }}
                </th>
              </tr>
            </template>
          </v-data-table>
          <v-divider></v-divider>
        </div>
      </div>
      <c-overlay :value="isLoading" absolute></c-overlay>
      <v-card-actions v-if="Vget_dashboard_diariocaja.length > 0">
        <span class="subtitle-2 font-weight-light">Consultas Guardadas</span
        ><v-divider></v-divider>
      </v-card-actions>
      <v-row dense class="mx-2">
        <v-col
          cols="12"
          md="3"
          sm="3"
          v-for="(item, i) of Vget_dashboard_diariocaja"
          :key="i"
        >
          <AccessDirect
            :id="item.iddashboard"
            :title="item.descripcion"
            @click="irAccesoDirecto(item)"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { db } from "@/services/db.service";
import { currency } from "@/services/util/number.service";
import { current_date, current_first_date } from "@/services/util/date.service";
import { exportPdf } from "./Export";
import { focus } from "@/services/focus.service";
import Header from "./Header";
import SaveReport from "./SaveReport";
import AccessDirect from "./AccessDirect";
import { DIARIO_DE_CAJA } from "@/constants/forms";
import { PUEDE_GENERAR_REPORTE } from "@/constants/permissions";
import { getPermission } from "@/services/storage/permissionStorage.service";
export default {
  components: {
    SaveReport,
    Header,
    AccessDirect,
  },
  data() {
    return {
      datos: null,
      isLoading: false,

      validar: [(v) => !!v.id || "Este campo es obligatorio"],
      route: {
        main: "/diariocaja/reporteavanzado",
      },
      titulo: "Reporte Diario Caja",
      permission: {
        generar_reporte: false,
      },
      filtro: [
        { id: "rubro", desc: "Rubro", value: "idrubro", name: "descripcion" },
        {
          id: "division",
          desc: "Division",
          value: "iddivision",
          name: "descripcion",
        },
        {
          id: "cuenta",
          desc: "Cuenta",
          value: "idcuenta_diario",
          name: "descripcion",
        },
        {
          id: "referencia",
          desc: "Referencia",
          value: "idreferencia",
          name: "descripcion",
        },
      ],
      data: {
        fechaDesde: current_first_date(),
        fechaHasta: current_date(),
        filtro: {
          desc: "",
          id: "",
        },
        idrubro: [],
        iddivision: [],
        idcuenta_diario: [],
        idreferencia: [],
      },
      headers: [
        {
          text: "Codigo",
          align: "start",
          sortable: false,
          value: "idreferencia",
        },
        { text: "Referencia", value: "referencia" },
        { text: "Debito", value: "precio_debito", align: "right" },
        { text: "Credito", value: "precio_credito", align: "right" },
        { text: "Saldo", value: "saldo", align: "right" },
      ],
      desserts: [],
    };
  },
  computed: {
    ...mapGetters("rubro", ["Vget_rubro_data", "Vget_rubro_load"]),
    ...mapGetters("division", ["Vget_division_data", "Vget_division_load"]),
    ...mapGetters("cuentadiario", [
      "Vget_cuentadiario_data",
      "Vget_cuentadiario_load",
    ]),
    ...mapGetters("referencia", [
      "Vget_referencia_data",
      "Vget_referencia_load",
    ]),
    ...mapGetters("dashboard", [
      "Vget_dashboard_load",
      "Vget_dashboard_diariocaja",
    ]),
    cuentadiario_data() {
      let cuenta = [];
      this.data.iddivision.forEach((div) => {
        this.Vget_cuentadiario_data.forEach((cta) => {
          if (div.iddivision === cta.iddivision.iddivision) {
            cuenta.push(cta);
          }
        });
      });
      return cuenta;
    },
    division_lengthChange() {
      return this.data.iddivision.length;
    },
  },
  watch: {
    division_lengthChange() {
      let cuenta = [];
      this.data.iddivision.forEach((div) => {
        this.data.idcuenta_diario.forEach((cta) => {
          if (cta.iddivision.iddivision === div.iddivision) {
            cuenta.push(cta);
          }
        });
      });
      this.data.idcuenta_diario = JSON.parse(JSON.stringify(cuenta));
    },
  },
  mounted() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.permission.generar_reporte = getPermission(DIARIO_DE_CAJA).permiso[
      PUEDE_GENERAR_REPORTE
    ];

    this.Vact_rubro_data();
    this.Vact_division_data();
    this.Vact_cuentadiario_data();
    this.Vact_referencia_data();
    this.Vact_dashboard_diariocaja();
  },
  methods: {
    ...mapActions("rubro", ["Vact_rubro_data"]),
    ...mapActions("division", ["Vact_division_data"]),
    ...mapActions("cuentadiario", ["Vact_cuentadiario_data"]),
    ...mapActions("referencia", ["Vact_referencia_data"]),
    ...mapActions("dashboard", ["Vact_dashboard_diariocaja"]),
    changeHeader() {
      this.headers[1].text = this.data.filtro.desc;
    },
    resetTable() {
      this.datos = null;
    },
    toCurrency(value) {
      return currency(value);
    },
    exportarPdf() {
      exportPdf(this.datos, this.headers, this.desserts);
    },
    getItems(id) {
      switch (id) {
        case "rubro":
          return this.Vget_rubro_data;
        case "division":
          return this.Vget_division_data;
        case "cuenta":
          return this.cuentadiario_data;
        case "referencia":
          return this.Vget_referencia_data;
      }
    },
    getItemsId(id, value) {
      this.data[value] = this.getItems(id).map((x) => {
        return x;
      });
    },
    getLoad(id) {
      switch (id) {
        case "rubro":
          return this.Vget_rubro_load;
        case "division":
          return this.Vget_division_load;
        case "cuenta":
          return this.Vget_cuentadiario_load;
        case "referencia":
          return this.Vget_referencia_load;
      }
    },

    next(index, data) {
      focus.nextidName("repAvanzadoInput", index, data);
    },

    obtenerUrl() {
      let rubro;
      this.data.idrubro.forEach((x, i) => {
        if (i === 0) return (rubro = "rubro=" + x.idrubro);
        rubro = rubro + "-" + x.idrubro;
      });
      let division;
      this.data.iddivision.forEach((x, i) => {
        if (i === 0) return (division = "division=" + x.iddivision);
        division = division + "-" + x.iddivision;
      });
      let cuentadiario;
      this.data.idcuenta_diario.forEach((x, i) => {
        if (i === 0) return (cuentadiario = "cuenta=" + x.idcuenta_diario);
        cuentadiario = cuentadiario + "-" + x.idcuenta_diario;
      });
      let referencia;
      this.data.idreferencia.forEach((x, i) => {
        if (i === 0) return (referencia = "referencia=" + x.idreferencia);
        referencia = referencia + "-" + x.idreferencia;
      });
      let url = `/apireportediario/${this.data.filtro.id}/${
        this.data.fechaDesde
      }/${this.data.fechaHasta}/?${rubro ? rubro + "&" : ""}${
        division ? division + "&" : ""
      }${cuentadiario ? cuentadiario + "&" : ""}${referencia || ""}`;
      return url;
    },
    irAccesoDirecto(item) {
      this.data = JSON.parse(item.vista);
      setTimeout(() => this.descargarDatos(), 500);
    },
    async descargarDatos() {
      if (!this.$refs.form.validate()) return null;
      this.isLoading = true;
      const url = await this.obtenerUrl();
      try {
        const response = await db.get(url);
        this.desserts = JSON.parse(JSON.stringify(response.detalle));
        this.desserts.forEach((moneda) => {
          let totales = {
            precio_credito: 0,
            precio_debito: 0,
            saldo: 0,
          };
          moneda.detalle.forEach((x) => {
            totales.precio_credito = totales.precio_credito + x.precio_credito;
            totales.precio_debito = totales.precio_debito + x.precio_debito;
            totales.saldo = totales.saldo + x.saldo;
          });
          moneda.totales = JSON.parse(JSON.stringify(totales));
        });

        this.datos = JSON.parse(JSON.stringify(response));
        this.datos.url = this.obtenerUrl();
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
  },
};
</script>

<style>
</style>