
export default {
  getDespido: (state) => {
    return state.despido
  },
  getMotivo: (state) => {
    return state.motivo
  },
  getFuncionarioData: (state) => {
    return state.funcionariodata
  },
  isDespidoLoading: (state) => {
    return state.isLoading
  }
}
