<template>
    <div>
        <v-dialog :value="value" max-width="850" persistent>
            <v-card>
                <v-toolbar flat>
                    <v-toolbar-title>
                        {{ readonly ? "Ver Recetario" : operacion ? "Agregar Recetario" : "Modificar Recetario" }}
                    </v-toolbar-title>
                </v-toolbar>
                <v-container>
                    <v-form ref="form" @submit.prevent="next(1, data.fecha)">
                        <v-row dense>
                            <v-col cols="12" md="4" sm="6">
                                <c-text-date label="Fecha" id="recetarioInput1" outlined dense v-model="data.fecha"
                                    @keyup.native.enter="next(1, data.fecha)" :readonly="readonly"></c-text-date>
                            </v-col>
                            <v-col cols="12" md="4" sm="6">
                                <v-autocomplete outlined dense :items="Vget_campo_data" item-text="campo" return-object
                                    :rules="validar" autocomplete="off" hide-details :loading="Vget_campo_load"
                                    :readonly="readonly || !habilitado" label="Campo" id="recetarioInput2"
                                    @keyup.enter="next(2, data.idlote_agri)" v-model="data.idlote_agri"
                                    v-on:change="changeLote">
                                    <template v-slot:no-data>
                                        <v-list-item dense>
                                            <v-list-item-content>
                                                <v-list-item-title>No se encontro ningun Lote</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="4" sm="6">
                                <v-autocomplete outlined dense :items="Vget_campana_data_byLote" item-text="descripcion"
                                    return-object :rules="validar" autocomplete="off" hide-details :readonly="readonly || !habilitado"
                                    :loading="Vget_campana_load" label="Zafra" id="recetarioInput3"
                                    @keyup.enter="next(3, data.idcampana)" v-model="data.idcampana">

                                    <template v-slot:no-data>
                                        <v-list-item dense>
                                            <v-list-item-content>
                                                <v-list-item-title>No se encontro ningun Lote</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="12" sm="12">
                                <vue-text-field label="Comentario" id="recetarioInput4" outlined dense
                                    v-model="data.comentario" :readonly="readonly"
                                    @keyup.native.enter="next(4, data.comentario)"></vue-text-field>
                            </v-col>
                        </v-row>
                        <v-form ref="formdetalle" v-if="!readonly == true">
                            <v-row dense>
                                <v-col cols="12" md="6" sm="6">
                                    <AutocompleteItem id="recetarioInput5" ref="recetarioInput5"
                                        v-model="recetariodet.idstock" :operacion="operacion" :siembra="data"
                                        :idparcelas="{ idparcelas: null }"
                                        :tipoItemSeleccionado="config.tipoItemSeleccionado" :editDetail="editDetailMode"
                                        :resetItem="resetItem" @focus="scrollIntoView('recetarioInput5')"
                                        @hectarea="recetariodet.hectarea = $event"
                                        @unidadmedida="recetariodet.idunidadmedida = $event"
                                        @tiposeleccionado="config.tipoItemSeleccionado = $event"
                                        @nextfocus="next(5, '-')"
                                        @keyup.native.enter="next(5, recetariodet.idstock), $refs.recetarioInput5.isMenuActive()" />
                                </v-col>

                                <v-col cols="12" md="3" sm="3" v-if="configForm.aplicacion_ha_total">
                                    <vue-text-field label="Cant. Total" type="number" :rules="true" id="recetarioInput6"
                                        ref="recetarioInput6" v-model="recetariodet.subtotal"
                                        @focus="scrollIntoView('recetarioInput6')"
                                        @keyup.native.enter="next(6, recetariodet.subtotal)"></vue-text-field>
                                </v-col>

                                <v-col cols="12" md="3" sm="3" v-else>
                                    <vue-text-field label="Cantidad" type="number" :rules="true" id="recetarioInput6"
                                        ref="recetarioInput6" v-model="recetariodet.cantidad"
                                        @focus="scrollIntoView('recetarioInput6')"
                                        @keyup.native.enter="next(6, recetariodet.cantidad)"></vue-text-field>
                                </v-col>

                                <v-col cols="12" md="3" sm="3">
                                    <AutocompleteUnidad id="recetarioInput7" v-model="recetariodet.idunidadmedida"
                                        ref="recetarioInput7" @change="obtenePrecio()"
                                        @focus="scrollIntoView('recetarioInput7')"
                                        @keyup.native.enter="next(7, recetariodet.idunidadmedida.idunidadmedida), $refs.recetarioInput7.isMenuActive()" />
                                </v-col>

                                <v-col cols="12" md="3" sm="3">
                                    <vue-text-currency label="Precio" v-model="recetariodet.precio" :rules="true"
                                        ref="recetarioInput8" type="number" id="recetarioInput8"
                                        @focus="setSelection('recetarioInput8')"
                                        @keyup.native.enter="next(8, recetariodet.precio)"></vue-text-currency>
                                </v-col>

                                <v-col cols="12" md="3" sm="3">
                                    <vue-text-field label="Hectarea" id="recetarioInput9" ref="recetarioInput9"
                                        @focus="setSelection('recetarioInput9')" v-model="recetariodet.hectarea"
                                        @keyup.native.enter="next(9, recetariodet.hectarea)"></vue-text-field>
                                </v-col>

                                <v-col cols="12" md="3" sm="3">
                                    <v-btn id="recetarioInput10" ref="recetarioInput10" outlined color="primary" fab
                                        small @click="addTable()">
                                        <v-icon>add</v-icon>
                                    </v-btn>
                                </v-col>

                            </v-row>
                        </v-form>

                        <v-row>
                            <v-col cols="12">
                                <v-data-table :headers="headers" :items="data.recetariodet" :mobile-breakpoint="0"
                                    :items-per-page="1000" hide-default-footer>
                                    <template v-slot:[`item.idstock.iditem.itemquimica`]="{ item }">
                                        {{ producto_activo(item.idstock.iditem.itemquimica) }}
                                    </template>
                                    <template v-slot:[`item.cantidad`]="{ item }">
                                        {{ currencyFormat(item.cantidad) }}
                                    </template>
                                    <template v-slot:[`item.cantxha`]="{ item }">
                                        {{ currencyFormat(item.cantxha) }}
                                    </template>
                                    <template v-slot:[`item.precio`]="{ item }">
                                        {{ currencyFormat(item.precio) }}
                                    </template>
                                    <template v-slot:[`item.precioxha`]="{ item }">
                                        {{ currencyFormat(item.precioxha) }}
                                    </template>
                                    <template v-slot:[`item.total`]="{ item }">
                                        {{ currencyFormat(item.total) }}
                                    </template>
                                    <template v-if="!readonly == true" v-slot:[`item.actions`]="{ item }">
                                        <v-icon small class="mr-1" @click="updateDetail(item)">edit</v-icon>
                                        <v-icon small @click="deleteDetail(item)">delete</v-icon>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn color="red" text @click="cancelar()">
                        <v-icon>clear</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="green" v-if="readonly" text @click="imprimir()">
                        <v-icon>print</v-icon>
                    </v-btn>
                    <c-btn-crud-done v-if="!readonly" @click="guardarRecetario()"></c-btn-crud-done>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { exportRecetarioPDF } from "./export";
import { mapGetters, mapActions } from 'vuex';
import { mensaje } from "@/services/notify.service";
import { current_date } from "@/services/util/date.service";
import { focus } from "@/services/focus.service";
import { db } from "@/services/db.service";
import { currency } from "@/services/util/number.service";
import AutocompleteItem from "./AutocompleteItem";
import AutocompleteUnidad from "@/views/Agro/Siembra/AutocompleteUnidad";

export default {
    components: {
        AutocompleteItem,
        AutocompleteUnidad,
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        datos: {
            type: [Object, Array],
            default: null,
        },
        "sync-input": {
            type: [String, Number],
            default: null,
        },
        readonly: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            sync: null,
            validar: [v => !!v || "Seleccion un campo"],
            resetItem: false,
            senave: false,
            config: {
                tipoItemSeleccionado: 5,
            },
            habilitado: true,
            editDetailMode: false,
            headers: [
                { text: "Hectareas", sortable: false, value: "hectarea" },
                {
                    text: "Producto",
                    align: "start",
                    sortable: false,
                    value: "idstock.iditem.descitem",
                },
                {
                    text: "Producto Activo",
                    align: "start",
                    sortable: false,
                    value: "idstock.iditem.itemquimica"
                },
                {
                    text: "Unidad Medida",
                    sortable: false,
                    value: "idunidadmedida.descunidadmedida",
                },
                { text: "Cant. U x Ha", sortable: false, value: "cantidad" },
                { text: "Cant. Total", sortable: false, value: "cantxha" },
                { text: "Precio Unitario", sortable: false, value: "precio" },
                { text: "Precio x Ha", sortable: false, value: "precioxha" },
                { text: "Precio Total", sortable: false, value: "total" },
                { text: "Accion", value: "actions", sortable: false, align: "right" },
            ],
            recetariodet: {
                idstock: {
                    idstock: "",
                    iditem: {
                        iditem: "",
                        idtipo_item: {
                            idtipo_item: 0
                        },
                        idunidadmedida: {
                            idunidadmedida: "",
                            idmagnitud: {
                                idmagnitud: "",
                            }
                        }
                    },
                },
                iditem: {
                    iditem: ""
                },
                iddeposito: {
                    iddeposito: ""
                },
                cantidad: 0,
                hectarea: 0,
                precio: 0,
                cantxha: 0,
                precioxha: 0,
                idunidadmedida: {
                    idunidadmedida: ""
                },
            },
            recetariodet_default: {
                idstock: {
                    idstock: "",
                    iditem: {
                        iditem: "",
                        idtipo_item: {
                            idtipo_item: 0
                        },
                        idunidadmedida: {
                            idunidadmedida: "",
                            idmagnitud: {
                                idmagnitud: "",
                            }
                        }
                    },
                },
                iditem: {
                    iditem: ""
                },
                iddeposito: {
                    iddeposito: ""
                },
                cantidad: 0,
                hectarea: 0,
                precio: 0,
                idunidadmedida: {
                    idunidadmedida: ""
                },
            },
            data: {
                idrecetario: 0,
                estado: true,
                fecha: current_date(),
                comentario: "",
                idcampana: {
                    idcampana: "",
                    campanadet: []
                },
                idlote_agri: {
                    idlote_agri: ""
                },
                recetariodet: [],
            },
            default: {
                idrecetario: 0,
                estado: true,
                fecha: current_date(),
                comentario: "",
                idcampana: {
                    idcampana: "",
                    campanadet: []
                },
                idlote_agri: {
                    idlote_agri: ""
                },
                recetariodet: [],
            },
            operacion: true,
        }
    },
    watch: {
        $route() {
            return this.cancelar()
        },
        'data.recetariodet': function(newValue) {
            if (newValue.length != 0) {
                this.habilitado = false
            } else {
                this.habilitado = true
            }
        }
    },
    computed: {
        ...mapGetters("recetario", ["Vget_recetario_load"]),
        ...mapGetters("campo", ["Vget_campo_data", "Vget_campo_load"]),
        ...mapGetters("campana", ["Vget_campana_data_byLote", "Vget_campana_load"]),
        ...mapGetters("configagro", ["getConfigAgro"]),
        configForm() {
            if (this.getConfigAgro.length > 0) {
                this.senave = this.getConfigAgro[0].senave_vdos;
                return this.getConfigAgro[0];
            }
            return "";
        },
    },
    created() {
        this.fetchConfigAgro();
    },
    async mounted() {
        if (this.datos != null) {
            this.operacion = false
            this.data = this.datos;
            this.changeLote()
            this.data.recetariodet.forEach((x) => {
                x.total = x.cantidad * x.precio * x.hectarea
                x.cantxha = x.cantidad * x.hectarea
                x.precioxha = x.cantidad * x.precio
            })
        }
        await this.Vact_campo_data('yes');
        setTimeout(() => this.next(0, "-"), 200);

    },
    methods: {
        ...mapActions("recetario", ["Vact_recetario_update", "Vact_recetario_save", "Vact_recetario_data"]),
        ...mapActions("campo", ["Vact_campo_data"]),
        ...mapActions("campana", ["Vact_campana_data_byLote"]),
        ...mapActions("configagro", ["fetchConfigAgro"]),
        changeLote() {
            this.Vact_campana_data_byLote(this.data.idlote_agri.idlote_agri)
            this.recetariodet.hectarea = this.data.idlote_agri.hectarea;
            this.recetariodet_default.hectarea = this.data.idlote_agri.hectarea;
        },
        next(id, data) {
            focus.nextidName("recetarioInput", id, data);
        },
        cancelar() {
            this.data = JSON.parse(JSON.stringify(this.default))
            this.operacion = true;
            this.$emit("input", false);
            this.$emit("readonly", false);
            this.$emit("datos", null);
        },
        setSelection(id) {
            setTimeout(() => {
                let input = document.getElementById(id);
                input.focus();
                let numb = false;
                if (input.type === "number") {
                    input.type = "text";
                    numb = true;
                }
                input.setSelectionRange(0, 30);
                if (numb) input.type = "number";
            }, 20);
        },
        async obtenePrecio() {
            if (!this.editDetailMode) {
                if (this.recetariodet.idstock.idstock === "") return null;
                const calcular = {
                    item: {
                        valor: this.recetariodet.idstock.iditem.precitem,
                        cantidad_contiene: this.recetariodet.idstock.iditem.cant_contiene,
                        medi_unidad: this.recetariodet.idstock.iditem.idunidadmedida.mediunidadmedida,
                    },
                    unidad: {
                        unidad: this.recetariodet.idunidadmedida.idunidadmedida,
                        medi_unidad: this.recetariodet.idunidadmedida.mediunidadmedida,
                    }
                }
                const resp = await db.save("/apicalculoprecio/", calcular);
                setTimeout(() => (this.recetariodet.precio = resp.data.data), 200);
            }
            this.editDetailMode = false;
            this.resetItem = false;
        },
        async editar_finalizar() {
            const id = this.data.idrecetario;
            let a = await this.Vact_recetario_update({ id, data: this.data });
            if (a.estado == true) {
                this.$notify(a.info);
                this.cancelar();
            } else {
                a.info.map((x) => {
                    this.$notify(mensaje.error(x));
                });
            }
        },
        guardar() {
            if (!this.$refs.form.validate()) return this.$notify(mensaje.completar());
            if (this.operacion === true) return this.guardar_finalizar();
            if (this.operacion === false) return this.editar_finalizar();
        },
        nuevo(val) {
            if (val != null) {
                this.data = JSON.parse(JSON.stringify(val));
                this.operacion = false;
            }
        },
        scrollIntoView(id) {
            document.getElementById(id).scrollIntoView();
        },
        addTable() {
            if (
                !this.$refs.formdetalle.validate() ||
                (this.recetariodet.cantidad <= 0 && this.recetariodet.subtotal <= 0) ||
                this.recetariodet.idstock.iditem.iditem === "" ||
                this.recetariodet.precio <= 0
            )
                return null;
            if (this.config.tipoItemSeleccionado === 10) this.recetariodet.hectarea = 1;
            let total = 0;
            total =
                Number(this.recetariodet.cantidad) *
                Number(this.recetariodet.precio) *
                Number(this.recetariodet.hectarea);
            this.recetariodet.subtotal = Number(this.recetariodet.cantidad) * Number(this.recetariodet.hectarea);
            this.recetariodet.total = total;
            this.recetariodet.cantxha = Number(this.recetariodet.cantidad) * Number(this.recetariodet.hectarea);
            this.recetariodet.precioxha = Number(this.recetariodet.cantidad) * Number(this.recetariodet.precio);
            this.data.recetariodet.push(this.recetariodet);
            this.recetariodet = JSON.parse(JSON.stringify(this.recetariodet_default));
            this.$refs.formdetalle.resetValidation();
            this.next(4, "-")
        },
        updateDetail(item) {
            this.config.tipoItemSeleccionado = item.idstock.iditem.idtipo_item.idtipo_item;
            this.data.recetariodet.splice(this.data.recetariodet.indexOf(item), 1);
            this.recetariodet = JSON.parse(JSON.stringify(item));
            if (this.configForm.aplicacion_ha_total) {
                if (this.recetariodet.idstock.iditem.idtipo_item.idtipo_item == 9) {
                    this.recetariodet.subtotal = this.recetariodet.cantidad
                }
            }
            this.editDetailMode = true;
            if (!this.operacion) {
                this.recetariodet.idstock.iditem.iditem = item.idstock.iditem.iditem
            }
        },
        deleteDetail(item) {
            const index = this.data.recetariodet.indexOf(item);
            this.data.recetariodet.splice(index, 1);
        },
        currencyFormat(value) {
            return currency(Number(value));
        },
        producto_activo(value) {
            let texto = ""
            if (value != null) {
                value.forEach(x => {
                    if (x.porcentaje) {
                        texto = texto + x.descripcion + " " + x.porcentaje + "; "
                    } else {
                        texto = texto + x.descripcion + "; "
                    }
                });
            }
            return texto
        },
        isValid() {
            if (
                !this.$refs.form.validate() ||
                this.data.recetariodet === 0
            )
                return false
            return true
        },
        async guardarRecetario() {
            if (!this.isValid()) return this.$notify(mensaje.completar());
            let a;
            if (this.operacion) {
                a = await this.Vact_recetario_save(this.data);
            } else {
                let id = this.data.idrecetario;
                a = await this.Vact_recetario_update({ id, data: this.data });
            }
            if (!a.estado) {
                return a.info.map((x) => {
                    this.$notify(mensaje.error(x));
                });
            }
            this.$notify(a.info);
            this.Vact_recetario_data();
            if (!this.operacion) {
                this.cerrar();
            } else {
                this.$refs.form.resetValidation();
                this.$refs.formdetalle.resetValidation();
                this.limpiarCampos();
                this.cerrar();
            }
        },

        limpiarCampos() {
            this.$refs.formdetalle.resetValidation();
            this.data = JSON.parse(JSON.stringify(this.default));
            this.recetariodet = JSON.parse(JSON.stringify(this.recetariodet_default));
        },
        cerrar() {
            this.limpiarCampos();
            this.$emit("datos", null);
            this.$emit("input", false);
            this.$emit("readonly", false);
        },
        imprimir() {
            exportRecetarioPDF("Recetario", this.data)
        }
    }
}
</script>