import { get, post, put, del } from "@/services/api/api.service";
export default {

    async Vact_celo_data({ commit, dispatch }) {
        commit('celo_load', true);
        try {
            const data = await get('/apicelo/');
            commit('celo_data', data);
            commit('celo_load', false);
            return true
        } catch (e) {
            dispatch('snackbar/setNotification', e, { root: true })
            commit('celo_load', false);
            throw e
        }
    },
    async Vact_celo_nuevo({ commit, dispatch }) {
        commit('celo_load', true);
        try {
            const data = await get('/apicelo/?band=nuevo');
            commit('celo_nuevo', data);
            commit('celo_load', false);
            return true
        } catch (e) {
            dispatch('snackbar/setNotification', e, { root: true })
            commit('celo_load', false);
            throw e
        }
    },
    async Vact_celo_historico({ commit, dispatch }) {
        commit('celo_load', true);
        try {
            const data = await get('/apicelo/?band=historico');
            commit('celo_historico', data);
            commit('celo_load', false);
            return true
        } catch (e) {
            dispatch('snackbar/setNotification', e, { root: true })
            commit('celo_load', false);
            throw e
        }
    },
    async Vact_celo_save({ commit, dispatch }, data) {
        commit('celo_save_load', true);
        const resp = await post('/apicelo/', data);
        dispatch('snackbar/setNotification', resp, { root: true });
        commit('celo_save_load', false);
        return resp;

    },
    async Vact_celo_del({ dispatch }, id) {
        const data = await del('/apicelo/' + id);
        dispatch('snackbar/setNotification', data, { root: true });
        dispatch('Vact_celo_nuevo');
        return data
    },
    async Vact_celo_update({ commit, dispatch }, { id, data }) {
        commit('celo_save_load', true);
        const resp = await put('/apicelo/' + id + '/', data);
        dispatch('snackbar/setNotification', resp, { root: true })
        commit('celo_save_load', false);
        return resp;

    },
}
