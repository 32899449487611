<template>
  <div>
    <v-dialog
      :value="value"
      max-width="700px"
      persistent
      @keydown.esc="cancelar()"
    >
      <v-card>
        <v-form ref="form" lazy-validation>
          <v-toolbar flat>
            <v-toolbar-title>{{
              operacion
                ? "Agregar Causa de No Inseminacion"
                : "Modificar Causa de No Inseminacion"
            }}</v-toolbar-title>
          </v-toolbar>
          <v-container>
            <v-row row dense>
              <v-col cols="12">
                <v-text-field
                  dense
                  :readonly="readonly"
                  type="text"
                  :rules="validar"
                  ref="input1"
                  v-model="data.desccausanoins"
                  label="Descripcion"
                  required
                  outlined
                  autocomplete="off"
                  @keyup.enter="nextfocus(1)"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  dense
                  :readonly="readonly"
                  type="text"
                  :rules="validar"
                  ref="input2"
                  v-model="data.abrvcausanoins"
                  label="Abreviatura"
                  required
                  outlined
                  autocomplete="off"
                  @keyup.enter="nextfocus(2)"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>

          <v-card-actions>
            <c-btn-crud-close @click="cancelar()"></c-btn-crud-close>
            <v-spacer></v-spacer>

            <c-btn-crud-done
              ref="aceptar"
              id="aceptar"
              :disabled="readonly"
              @click="guardar()"
            ></c-btn-crud-done>
          </v-card-actions>
        </v-form>
        <c-overlay :value="Vget_causanoins_save_load"></c-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { mensaje } from "@/services/notify.service";
export default {
  props: {
    value: Boolean,
    datos: {
      type: [Object, Array],
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: {
        desccausanoins: "",
        abrvcausanoins: "",
      },
      default: {
        desccausanoins: "",
        abrvcausanoins: "",
      },
      validar: [(v) => !!v || "Campo requerido"],
      operacion: true,
    };
  },
  computed: {
    ...mapGetters("causanoins", ["Vget_causanoins_save_load"]),
  },
  created() {
    this.nuevo(this.datos);
  },
  mounted() {
    setTimeout(() => this.$refs.input1.focus(), 500);
  },
  watch: {
    $route(to, from) {
      if (from.path === "/causanoinseminacion") return this.cancelar();
    },
  },
  methods: {
    ...mapActions("causanoins", [
      "Vact_causanoins_data",
      "Vact_causanoins_save",
      "Vact_causanoins_update",
    ]),

    nextfocus(x) {
      switch (x) {
        case 1: {
          if (this.data.desccausanoins != "") {
            this.$refs.input2.focus();
          }
          break;
        }
        case 2: {
          if (this.data.abrvcausanoins != "") {
            this.$refs.aceptar.$el.focus();
          }
          break;
        }
      }
    },

    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$emit("input", false);
      this.$emit("datos", null);
      this.Vact_causanoins_data();
      this.operacion = true;
    },
    async guardar_finalizar() {
      let a = await this.Vact_causanoins_save(this.data);
      if (!a.success) return null;
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$refs.form.resetValidation();
      this.$refs.input1.focus();
    },
    async editar_finalizar() {
      const id = this.datos.idcausanoins;
      let a = await this.Vact_causanoins_update({ id, data: this.data });
      if (!a.success) return null;
      this.cancelar();
    },
    guardar() {
      if (!this.$refs.form.validate()) return this.$notify(mensaje.completar());
      if (this.operacion === true) return this.guardar_finalizar();
      if (this.operacion === false) return this.editar_finalizar();
    },
    nuevo(val) {
      if (val !== null) {
        this.data = JSON.parse(JSON.stringify(val));
        this.operacion = false;
      }
    },
  },
};
</script>

