export default {
  inseminacion_data_id(state, request) {
    state.inseminacion_data_id = request;
  },
  inseminacion_data(state, request) {
    state.inseminacion_data = request;
  },
  inseminacion_nuevo(state, request) {
    state.inseminacion_nuevo = request;
  },
  inseminacion_historico(state, request) {
    state.inseminacion_historico = request;
  },
  inseminacion_load(state, request) {
    state.inseminacion_load = request;
  },
  inseminacion_save_load(state, request) {
    state.inseminacion_save_load = request;
  },
}