export default {

  gymrutina_data: [],
  gymrutina_data_by: [],
  gymrutina_data_hoy: [],
  gymrutina_update: null,
  gymrutina_load: false,
  gymrutina_save_load: false

}
