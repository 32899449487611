<script>
import { mapGetters, mapActions } from "vuex";
var self = null;
export default {
  props: {
    google: {
      type: Object,
      required: true,
    },
    map: {
      type: Object,
      required: true,
    },
    value: {
      type: [Object, String],
    },
  },
  data() {
    return {
      data: {
        type: "Feature",
        properties: {},
        geometry: {
          type: "Polygon",
          hectarea: "",
          coordinates: [],
        },
      },
      drawingManager: "",
      selectedShape: [],
      marker_editable: [],
      marker_selected: [],
      polygon_selected: [],
      listeners: {
        pest: null,
      },
      markers: {
        pest: null,
      },
    };
  },
  created() {
    self = this;
  },
  watch: {
    value(val) {
      this.data = JSON.parse(JSON.stringify(val));
    },
    Vget_campo_geo_json(val) {
      if (val.id != undefined) {
        this.Vact_campo_data_by_id(val.id);
        this.clearSelection();
      }
    },
    Vget_campo_deleted(val) {
      if (val != null) {
        this.limpiarSelected(val);
        this.Vact_campo_deleted(null);
      }
    },
    Vget_agromap_coordinates(val) {
      if (val != null && this.$route.path.includes("/agricultura/campo/edit"))
        return this.viewSelection(val, null, true);
      if (val != null) this.viewSelection(val);
    },
    Vget_agromap_coordinates_list(val) {
      this.limpiarSelected(null, -1)
      const id = val.length - 1;
      val.forEach((x, i) => {
        if (id === i)
          return setTimeout(() => this.viewSelection(x, "ZONA"), 1000);
        this.viewSelection(x, "ZONA");
      });
    },
    Vget_agromap_coordinates_view(val) {
      const id = val.length - 1;
      val.forEach((x, i) => {
        if (id === i)
          return setTimeout(() => this.viewPolygons(x), 1000);
        this.viewPolygons(x);
      });
    },
    Vget_agromap_tile(val) {
      if (val.data) this.tileIntegral(val);
    },

    $route(to, from) {
      if (to.path === "/agricultura/campo/add") {
        this.limpiarDrawing();
      }
      if (from.path.includes("/agricultura/campo/edit")) {
        this.viewSelection(this.Vget_agromap_coordinates);
      }
      if (from.name === "Agro Detalle") {
        this.map.overlayMapTypes.clear();
      }
    },
  },
  computed: {
    ...mapGetters("agromap", [
      "Vget_agromap_coordinates",
      "Vget_agromap_coordinates_list",
      "Vget_agromap_coordinates_view",
      "Vget_agromap_tile",
    ]),
    ...mapGetters("campo", ["Vget_campo_geo_json", "Vget_campo_deleted"]),
    ...mapGetters("plaga", ["getPlagaId"]),
  },
  methods: {
    ...mapActions("campo", [
      "Vact_campo_geo_json",
      "Vact_campo_data_by_id",
      "Vact_campo_deleted",
    ]),
    ...mapActions("plaga", ["setCoordenadaPlaga"]),
    ...mapActions("lotegrupo", ["Vact_lotegrupo_data"]),

    initialize() {
      this.drawingManager = new this.google.maps.drawing.DrawingManager({
        drawingControl: true,
        drawingControlOptions: {
          position: this.google.maps.ControlPosition.BOTTOM_CENTER,
          drawingModes: ["polygon"],
        },
        polygonOptions: {
          fillOpacity: 0.0,
          strokeColor: "red",
          editable: true,
        },
      });
      this.drawingManager.setMap(this.map);
      var customControlDiv = document.createElement("div");
      this.CustomControl(customControlDiv, this.map);

      customControlDiv.index = 1;
      this.map.controls[this.google.maps.ControlPosition.BOTTOM_CENTER].push(
        customControlDiv
      );
    },
    CustomControl(controlDiv) {
      var controlUI = document.createElement("div");
      controlUI.style.float = "left";
      controlUI.style.lineHeight = 0;
      controlUI.style.marginLeft = "-6px";
      controlUI.style.marginBottom = "5px";
      controlUI.style.marginTop = "0px";

      controlDiv.appendChild(controlUI);

      var controlText = document.createElement("div");
      controlText.style.direction = "ltr";
      controlText.style.overflow = "hidden";
      controlText.style.textAlign = "left";
      controlText.style.position = "relative";
      controlText.style.color = "rgb(86, 86, 86)";
      controlText.style.fontFamily = "Roboto, Arial, sans-serif";
      controlText.style.userSelect = "none";
      controlText.style.fontSize = "11px";
      controlText.style.backgroundColor = "rgb(255, 255, 255)";
      controlText.style.padding = "6px";
      controlText.style.borderBottomRightRadius = "2px";
      controlText.style.borderTopRightRadius = "2px";
      controlText.style.backgroundClip = "padding-box";
      controlText.style.boxShadow = "rgba(0, 0, 0, 0.3) 0px 1px 4px -1px";
      controlText.style.borderLeft = "0px";
      controlText.style.fontWeight = 500;
      controlText.innerHTML =
        '<button><img src="https://img.icons8.com/material-sharp/12/000000/delete-sign.png"/></button>';

      controlUI.appendChild(controlText);
      controlUI.addEventListener("click", () => {
        self.limpiarDrawing();
      });
      /*
      Sustituido por obsolescencia 
      this.google.maps.event.addDomListener(controlUI, "click", function() {
        self.limpiarDrawing();
      });
      */
    },
    limpiarDrawing() {
      this.deleteSelectedShape();
      this.marker_editable.forEach((x) => {
        x.setMap(null);
      });
    },
    tileIntegral(val) {
      if (val.data == null) return null;
      if (val.selected === "google") return this.map.overlayMapTypes.clear();
      let imageMapType = new this.google.maps.ImageMapType({
        getTileUrl: function (coord, zoom) {
          if (zoom > 13) {
            return `https://sentinel.integral.com.py/api/imagesndvi/${val.data.title}/${val.data.polygon}/${coord.x}/${coord.y}/${zoom}/?token=442ae83efca32ce518717a0d7b5095e0ada7ba16`;
            //return `http://127.0.0.1:8000/api/imagesndvi/${val.data.title}/${val.data.polygon}/${coord.x}/${coord.y}/${zoom}/?token=04d9c2437ac22934e932637232e04cb57743b397`
          }
        },
      });
      this.map.overlayMapTypes.push(imageMapType);
    },
    tileAgromonitoring(val) {
      if (val.data.length === 0) return null;
      if (val.selected === "google") return this.map.overlayMapTypes.clear();
      let str = val.data.tile[val.selected];
      let [a, b] = str.split("{z}/{x}/{y}");
      if (val.selected === "ndvi") {
        b = b + "&paletteid=4";
      }
      let imageMapType = new this.google.maps.ImageMapType({
        getTileUrl: function (coord, zoom) {
          if (zoom > 13) {
            return [a + zoom + "/" + coord.x + "/" + coord.y + b].join("");
          }
        },
        tileSize: new this.google.maps.Size(256, 256),
      });
      this.map.overlayMapTypes.push(imageMapType);
    },
    limpiarSelected(idlote_agri, idlotegrupo) {
      this.marker_selected.forEach((x) => {
        if (x.data.idlote_agri === idlote_agri) {
          x.setMap(null);
        }
        if (idlotegrupo) {
          if (x.data.idlote_grupo.idlote_grupo != idlotegrupo) {
            x.setMap(null);
          }
        }
      });
      this.polygon_selected.forEach((x) => {
        x.setOptions({ strokeColor: "yellow" });
        if (x.data.idlote_agri === idlote_agri) {
          x.setMap(null);
        }
        if (idlotegrupo) {
          if (x.data.idlote_grupo.idlote_grupo != idlotegrupo) {
            x.setMap(null);
          }
        }
      });
    },

    viewPolygons(val) {
      this.clearSelection();
      this.limpiarSelected(val.idlote_agri, val.idlote_grupo.idlotegrupo);
      let paths = [];
      val.coordenadas[0].forEach((x) => {
        paths.push({ lat: x[1], lng: x[0] });
      });
      const polygon = new this.google.maps.Polygon({
        paths: paths,
        fillOpacity: 0.0,
        strokeColor: "yellow",
        editable: false,
        data: val,
      });
      polygon.setMap(this.map);
      this.polygon_selected.push(polygon);
      this.google.maps.event.addListener(polygon, "click", function () {
        self.positionbyID(polygon.data);
        self.$emit("mostrar");
      });
      
      let bounds = new this.google.maps.LatLngBounds();
      paths.forEach((x) => {
        bounds.extend(new this.google.maps.LatLng(x.lat, x.lng));
      });
      let infowindow = new this.google.maps.InfoWindow({
        content: self.plantillaInfo(val),
      });
      let marker = new this.google.maps.Marker({
        position: bounds.getCenter(),
        map: this.map,
        title: "Informacion",
        icon: "https://img.icons8.com/offices/36/000000/marker.png",
        data: val,
      });

      this.marker_selected.push(marker);
      //infowindow.open(self.map, marker);
      marker.addListener("click", function () {
        infowindow.open(self.map, marker);
        self.positionbyID(marker.data);
      });
    },

    viewSelection(val, mode, editable) {
      this.limpiarDrawing();
      this.limpiarSelected(val.idlote_agri, val.idlote_grupo.idlote_grupo);
      let paths = [];
      val.coordenadas[0].forEach((x) => {
        paths.push({ lat: x[1], lng: x[0] });
      });
      const polygon = new this.google.maps.Polygon({
        paths: paths,
        fillOpacity: 0.0,
        strokeColor: mode != "ZONA" ? "blue" : "yellow",
        editable: editable || false,
        data: val,
      });
      polygon.setMap(this.map);
      this.polygon_selected.push(polygon);
      this.google.maps.event.addListener(polygon, "click", function () {
        self.positionbyID(polygon.data);
        self.$emit("mostrar");
      });
      if (editable) {
        const evento = { type: "polygon", overlay: polygon };
        this.overlayCompleteListener(evento, 0);
      }
      let bounds = new this.google.maps.LatLngBounds();
      paths.forEach((x) => {
        bounds.extend(new this.google.maps.LatLng(x.lat, x.lng));
      });
      this.map.setCenter(bounds.getCenter());
      if (mode != "ZONA") this.map.setZoom(16);
      this.map.setZoom(14);
      let infowindow = new this.google.maps.InfoWindow({
        content: self.plantillaInfo(val),
      });
      if (!editable) {
        let marker = new this.google.maps.Marker({
          position: bounds.getCenter(),
          map: this.map,
          title: "Informacion",
          icon: "https://img.icons8.com/offices/36/000000/marker.png",
          data: val,
        });

        this.marker_selected.push(marker);
        infowindow.open(self.map, marker);
        marker.addListener("click", function () {
          infowindow.open(self.map, marker);
          self.positionbyID(marker.data);
        });
      }
    },
    plantillaInfo(val) {
      let contentString =
        `<h4 style="color:black; text-align: center;">Informacion</h4><hr>` +
        `<table>` +
        `<tr>` +
        `<th style="text-align: left; color:black;">Zona: </th>` +
        `<td style="color:black">` +
        val.idlote_grupo.descripcion +
        `</td>` +
        `</tr>` +
        `<tr>` +
        `<th style="text-align: left; color:black;">Lote: </th>` +
        `<td style="color:black">` +
        val.campo +
        `</td>` +
        `</tr>` +
        `<tr>` +
        `<th style="text-align: left; color:black;">Hectarea: </th>` +
        `<td style="color:black">` +
        val.hectarea +
        `</td>` +
        `</tr>` +
        `</table>`;
      return contentString;
    },
    positionbyID(data) {
      if (this.$route.params.id != data.idlote_agri) {
        this.$router.push({
          path: "/agricultura/campo/detalle/" + data.idlote_agri,
          query: {
            polygon: data.id_polygon,
          },
        });
      }
    },
    setSelection(shape) {
      this.clearSelection();
      shape.setEditable(true);
      this.selectedShape.push(shape);
    },
    deleteSelectedShape() {
      if (this.selectedShape.length > 0) {
        this.selectedShape.forEach((x) => {
          x.setMap(null);
        });
      }
    },
    clearSelection() {
      if (this.selectedShape.length > 0) {
        this.selectedShape.forEach((x) => {
          x.setEditable(false);
          x = null;
        });
      }
    },
    obtenerDatos(e) {
      if (e.type === "polygon") {
        let geo_json_coordinate = [[]];
        let coordenadas = JSON.parse(
          JSON.stringify(e.overlay.getPath().getArray())
        );
        coordenadas.forEach((x) => {
          geo_json_coordinate[0].push([x.lng, x.lat]);
        });
        geo_json_coordinate[0].push(geo_json_coordinate[0][0]);
        this.data.geometry.hectarea = this.calcularArea(e).hectarea;
        this.data.geometry.coordinates = geo_json_coordinate;
        this.Vact_campo_geo_json(this.data);
      }
    },
    calcularArea(e) {
      const area = this.google.maps.geometry.spherical.computeArea(
        e.overlay.getPath().getArray()
      );
      const hectarea = area * 0.0001;
      const m2 = area * 100;
      return {
        area: area.toFixed(2),
        hectarea: hectarea.toFixed(2),
        m2: m2.toFixed(0),
      };
    },
    infoArea(e, selectedShape, identificador) {
      //let geocoder = new this.google.maps.Geocoder();
      this.marker_editable.forEach((x) => {
        if (x.identificador === identificador) return x.setMap(null);
      });
      const area = this.calcularArea(e);
      const paths = JSON.parse(JSON.stringify(e.overlay.getPath().getArray()));
      let bounds = new this.google.maps.LatLngBounds();
      paths.forEach((x) => {
        bounds.extend(new this.google.maps.LatLng(x.lat, x.lng));
      });
      /*
      await geocoder.geocode({location: bounds.getCenter()}).then((response) => {
        console.log(response)
      }).catch((e) => console.log("error:" + e))
      */
      let contentString =
        `<h7 style="color: black; text-align: center;">Informacion</h7><hr>` +
        `<table>` +
        `<tr>` +
        `<th style="text-align: left; color:black;">Hectarea: </th>` +
        `<td style="color:black; text-align: right;">` +
        area.hectarea +
        ` <strong>ha</strong></td>` +
        `</tr>` +
        `<tr>` +
        `<th style="text-align: left; color:black;">Metro Cuadrado: </th>` +
        `<td style="color:black; text-align: right">` +
        area.m2 +
        ` <strong>m2</strong></td>` +
        `</tr>` +
        `</table>`;

      let infowindow = new this.google.maps.InfoWindow({
        content: contentString,
      });
      let marker = new this.google.maps.Marker({
        position: bounds.getCenter(),
        map: this.map,
        title: "Informacion",
        icon: "https://img.icons8.com/ultraviolet/36/000000/marker.png",
        identificador: identificador,
      });
      this.marker_editable.push(marker);
      infowindow.open(this.map, marker);
      marker.addListener("click", function () {
        infowindow.open(self.map, marker);
        self.positionbyID(marker.data);
      });
    },
    overlayCompleteListener(e, identificador) {
      {
        let e_change = e;
        this.obtenerDatos(e);
        let newShape = e.overlay;
        newShape.type = e.type;
        this.setSelection(newShape);
        this.infoArea(e, newShape, identificador);
        this.google.maps.event.addListener(
          e.overlay.getPath(),
          "insert_at",
          () => {
            self.obtenerDatos(e_change);
            this.infoArea(e, newShape, identificador);
          }
        );
        this.google.maps.event.addListener(
          e.overlay.getPath(),
          "set_at",
          () => {
            self.obtenerDatos(e_change);
            this.infoArea(e, newShape, identificador);
          }
        );
        this.drawingManager.setDrawingMode(null);
      }
    },

    mostrarDrawing() {
      this.initialize();
      let identificador = 1;
      this.google.maps.event.addListener(
        self.drawingManager,
        "overlaycomplete",
        (e) => {
          identificador = identificador + 1;
          self.overlayCompleteListener(e, identificador);
        }
      );
    },
  },
  async mounted() {
    this.mostrarDrawing();
    this.Vact_lotegrupo_data();
  },
  // eslint-disable-next-line
  render() {},
};
</script>