<template>
  <v-dialog persistent max-width="900" :value="value">
    <v-card>
      <v-toolbar flat dense>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-form ref="header">
          <v-row dense>
            <v-col cols="12" sm="6">
              <autocomplete-cuentabanco
                label="Cuenta Bancaria de Origen"
                v-model="data.idcuentabanco"
                @change="changeMoneda()"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <c-text-field
                readonly
                label="Moneda"
                v-model="data.idmoneda.descmoneda"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="3">
              <v-select
                :items="[
                  { desc: 'Despido', value: true },
                  { desc: 'Renuncia', value: false },
                ]"
                item-text="desc"
                ref="despidoInput0"
                item-value="value"
                :readonly="readonly"
                @change="data.idjustificacion_motivo = undefined"
                label="Tipo de Operacion"
                v-model="data.despido_renuncia"
                @keyup.native.enter="
                  next(0, '-'), ($refs.despidoInput0.isMenuActive = false)
                "
              ></v-select>
            </v-col>
            <v-col cols="12" sm="3">
              <autocomplete-funcionario
                ref="despidoInput1"
                label="Funcionario"
                :readonly="readonly"
                v-model="data.idcontacto"
                :idcontacto="props ? props.idcontacto : null"
                @keyup.native.enter="
                  next(1, data.idcontacto.idcontacto.idcontacto)
                "
              />
            </v-col>
            <v-col cols="8" sm="3">
              <c-text-date
                ref="despidoInput2"
                label="Fecha de Desvinculacion"
                :readonly="readonly"
                v-model="data.fecha_desvinculacion"
                @keyup.native.enter="next(2, data.fecha_desvinculacion)"
              ></c-text-date>
            </v-col>
            <v-col cols="8" sm="3">
              <v-text-field
                ref="despidoInput3"
                label="Dias de Preaviso"
                type="number"
                :rules="rules"
                :readonly="readonly"
                v-model="data.diapreaviso"
                @keyup.native.enter="next(3, data.diapreaviso)"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="3" class="ml-3 mt-n2">
              <v-switch
                ref="despidoInput4"
                :readonly="readonly"
                v-model="data.justificado"
                inset
                ><template v-slot:label>
                  Justificado?:
                  <div class="font-weight-black ml-1">
                    {{ data.justificado ? " Si" : " No" }}
                  </div>
                </template>
              </v-switch>
            </v-col>
            <v-col cols="10" sm="8" v-show="data.justificado">
              <autocomplete-despido
                ref="despidoInput5"
                :rules="data.justificado ? undefined : []"
                dense
                :readonly="readonly"
                :tipo="data.despido_renuncia ? 'despido' : 'renuncia'"
                :label="
                  `Justificacion (Art ${
                    data.despido_renuncia ? '81' : '84'
                  } - Codigo del Laboral)`
                "
                v-model="data.idjustificacion_motivo"
                @keyup.native.enter="
                  next(5, data.idjustificacion_motivo.idjustificacion_motivo)
                "
              />
            </v-col>
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              ref="despidoInput6"
              fab
              outlined
              readonly
              v-if="!readonly"
              small
              class="mt-1 ml-2"
              @click="obtenerDatos(data.idcontacto)"
              ><v-icon>get_app</v-icon></v-btn
            >
          </v-row>
        </v-form>

        <v-form ref="form">
          <v-row dense>
            <v-col cols="12" sm="6">
              <c-text-date
                readonly
                label="Fecha Inicio en la empresa"
                v-model="data.fecha_inicio"
              ></c-text-date>
            </v-col>

            <v-col cols="12" sm="6">
              <c-text-field
                readonly
                :rules="[]"
                label="Antiguedad"
                v-model="data.antiguedad"
              ></c-text-field>
            </v-col>
          </v-row>
          <v-data-table
            :headers="headers"
            :items="data.detalle"
            :items-per-page="99999"
            hide-default-footer
            ><template v-slot:[`item.ingreso`]="{ item }"
              ><div>
                {{ toCurrency(item.ingreso) }}
              </div>
            </template>
            <template v-slot:[`item.egreso`]="{ item }"
              ><div>
                {{ toCurrency(item.egreso) }}
              </div>
            </template>
            <template slot="body.append">
              <tr>
                <th>
                  <span class="subtitle-1 font-weight-black customtext--text"
                    >TOTAL</span
                  >
                </th>

                <th class="text-right">
                  <span class="subtitle-1 font-weight-black customtext--text">
                    {{ toCurrency(totalIngreso) }}</span
                  >
                </th>
                <th class="text-right">
                  <span class="subtitle-1 font-weight-black customtext--text">
                    {{ toCurrency(totalEgreso) }}</span
                  >
                </th>
              </tr>
              <tr>
                <th
                  class="text-left subtitle-1 font-weight-black customtext--text"
                >
                  Neto a Recibir:
                </th>
                <th></th>

                <th
                  class="text-right subtitle-1 font-weight-black customtext--text"
                >
                  {{ toCurrency(data.monto) }}
                </th>
              </tr>
            </template>
          </v-data-table>
          <v-textarea
            label="Comentario"
            :readonly="readonly"
            class="mx-1"
            v-model="data.comentario"
            rows="2"
          ></v-textarea>
        </v-form>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <c-btn-crud-close @click="cancel()"></c-btn-crud-close>
        <v-spacer></v-spacer>
        <c-btn-crud-done
          v-if="!readonly"
          ref="despido2"
          @click="save()"
        ></c-btn-crud-done>
      </v-card-actions>
      <c-overlay :value="isDespidoLoading || isConfigRrhhLoading"></c-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { current_date } from "@/services/util/date.service";
import autocompleteFuncionario from "@/views/RRHH/Funcionarios/Information/autocomplete";
import autocompleteDespido from "./autocompleteDespido";
import autocompleteCuentabanco from "@/views/Apertura/Cuentabanco/autocomplete";
import { currency } from "@/services/util/number.service";
import { generarRecibo } from "./../Salario/recibo";
const configRecibo = {
  title: "LIQUIDACION LABORAL",
  articulo: "",
};
export default {
  components: {
    "autocomplete-funcionario": autocompleteFuncionario,
    "autocomplete-cuentabanco": autocompleteCuentabanco,
    "autocomplete-despido": autocompleteDespido,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    if (this.readonly) {
      this.data = JSON.parse(JSON.stringify(this.props));
      this.formatDetail(this.props.detalle);
      this.$refs.header.resetValidation();
    } else {
      this.fetchConfigRrhh();
    }
    setTimeout(() => this.next(-1, "-"), 20);
  },
  watch: {
    $route(to, from) {
      if (from.path === "/rrhh/pago/despido") return this.cancel();
    },
    getFuncionarioData(val) {
      this.data.fecha_inicio = val.fecha;
      this.data.antiguedad = val.text;
      this.formatDetail(val.detalle);
    },
    getConfigRrhh(val) {
      this.data.idcuentabanco = val.idcuentabanco;
      this.changeMoneda();
    },
  },
  computed: {
    ...mapGetters("configrrhh", ["getConfigRrhh","isConfigRrhhLoading"]),
    ...mapGetters("despido", ["isDespidoLoading", "getFuncionarioData"]),
    title() {
      return this.editable
        ? "Modificar Despido / Renuncia"
        : "Registrar Despido / Renuncia";
    },
  },
  methods: {
    ...mapActions("configrrhh", ["fetchConfigRrhh"]),
    ...mapActions("despido", [
      "setDespido",
      "fetchDespido",
      "fetchFuncionarioData",
      "setDespidoUpdate",
    ]),
    changeMoneda() {
      this.data.idmoneda = JSON.parse(
        JSON.stringify(this.data.idcuentabanco.idmoneda)
      );
    },
    formatDetail(detalle) {
      let ingreso = 0;
      let egreso = 0;
      detalle.map((x) => {
        x.ingreso = x.idconcepto_salario.positivo_negativo
          ? ((ingreso = ingreso + x.monto), x.monto)
          : "";
        x.egreso = !x.idconcepto_salario.positivo_negativo
          ? ((egreso = egreso + x.monto), x.monto)
          : "";
      });
      this.data.detalle = JSON.parse(JSON.stringify(detalle));
      this.obtenerTotales();
    },
    toCurrency(value) {
      if (value) if (value != "") return currency(value);
      return "";
    },
    cancel() {
      this.$emit("input", false);
    },
    obtenerTotales() {
      this.totalIngreso = 0;
      this.totalEgreso = 0;
      this.data.detalle.map((x) => {
        this.totalIngreso =
          this.totalIngreso + (x.ingreso != "" ? x.ingreso : 0);
        this.totalEgreso = this.totalEgreso + (x.egreso != "" ? x.egreso : 0);
      });
      this.data.monto = this.totalIngreso - this.totalEgreso;
    },
    obtenerDatos(contacto) {
      if (!this.$refs.header.validate()) return null;
      const data = {
        id: contacto.idcontacto.idcontacto,
        hasta: this.data.fecha_desvinculacion,
        dias: this.data.diapreaviso,
        justifica: this.data.justificado ? "si" : "no",
        transaccion: this.data.despido_renuncia ? "despido" : "renuncia",
        idmoneda: this.data.idmoneda.idmoneda
      };
      this.fetchFuncionarioData({ ...data });
    },
    next(id, data) {
      if (data != "")
        try {
          this.$refs[`despidoInput${id + 1}`].focus();
        } catch (error) {
          this.$refs[`despidoInput${id + 1}`].$el.focus();
        }
    },
    formatAndGenerateRecibo() {
      const data = {
        ...this.data,
        periodo_inicio: this.data.fecha_inicio,
        periodo_fin: this.data.fecha_desvinculacion,
      };
      generarRecibo(data, configRecibo, "Despido");
    },
    async save() {
      if (!this.$refs.form.validate() && this.data.detalle.length === 0)
        return null;
      if (!this.data.justifica)
        this.data.idjustificacion_motivo = { idjustificacion_motivo: null };
      const response = this.editable
        ? await this.setDespidoUpdate({
            data: this.data,
            id: this.data.iddespido,
          })
        : await this.setDespido(this.data);
      if (response.success) {
        this.fetchDespido();
        this.data.justifica = false;
        this.formatAndGenerateRecibo();
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.header.resetValidation();
        this.$refs.form.resetValidation();
        this.next(-1, "-");
        if (this.editable) this.cancel();
      }
    },
  },
  data: () => ({
    rules: [
      (v) => v !== "" || "Este campo es obligatorio",
      (v) => v > -1 || "Este campo no puede ser negativo",
    ],
    totalEgreso: 0,
    totalIngreso: 0,
    headers: [
      {
        text: "Concepto",
        align: "start",
        sortable: false,
        value: "idconcepto_salario.descripcion",
      },
      { text: "Ingreso", value: "ingreso", sortable: false, align: "end" },
      { text: "Egreso", value: "egreso", sortable: false, align: "end" },
    ],
    data: {
      iddespido: 0,
      monto: "",
      diapreaviso: "",
      idcontacto: {
        idcontacto: "",
      },
      idjustificacion_motivo: {
        idjustificacion_motivo: "",
      },
      idcuentabanco: {
        idcuentabanco: "",
      },
      idmoneda: {
        idmoneda: "",
        descmoneda: "",
      },
      despido_renuncia: true,
      justificado: false,
      fecha: current_date(),
      fecha_inicio: "",
      fecha_desvinculacion: current_date(),
      antiguedad: "",
      comentario: "",
      detalle: [],
    },
    default: {
      iddespido: 0,
      monto: "",
      diapreaviso: "",
      idcontacto: {
        idcontacto: "",
      },
      idjustificacion_motivo: {
        idjustificacion_motivo: "",
      },
      idcuentabanco: {
        idcuentabanco: "",
      },
      idmoneda: {
        idmoneda: "",
        descmoneda: "",
      },
      despido_renuncia: true,
      justificado: false,
      fecha: current_date(),
      fecha_inicio: "",
      fecha_desvinculacion: current_date(),
      antiguedad: "",
      comentario: "",
      detalle: [],
    },
  }),
};
</script>

<style></style>
