import {SET_LOADING, SET_MAQUINARIA_GASTO, SET_MAQUINARIA_REPORTE} from './constants'
export default {
  [SET_MAQUINARIA_GASTO](state, request) {
    state.maquinariaGasto = request;
  },
  [SET_MAQUINARIA_REPORTE](state, request) {
    state.maquinariaReporte = request;
  },
  [SET_LOADING](state, request) {
    state.isLoadingGasto = request;
  },
}