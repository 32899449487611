export default {
    async motivotraslado_create({commit},request){
        commit('motivotraslado_create',request);
    },
    async motivotraslado_update({commit},request){
        commit('motivotraslado_update',request);
    },
    async motivotraslado_reload({commit},request){
        commit('motivotraslado_reload',request);
    },
    async motivotraslado_show({commit},request){
        commit('motivotraslado_show',request);
    },
}
