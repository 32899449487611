<template>
  <div>
    <v-card>
      <v-toolbar flat dense>
        <v-toolbar-title>Rutinas</v-toolbar-title>
        <v-spacer></v-spacer>
        <c-text-table-search v-model="search"></c-text-table-search>
        <v-toolbar-items>
          <c-btn-table-add
            @click="crud.add = true"
            v-if="permission.can_add"
            class="mr-1"
          ></c-btn-table-add>
          <c-btn-table-reload
            v-if="permission.can_view"
            class="ml-1"
            @click="fetchRutina()"
          ></c-btn-table-reload>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="Vget_gymrutina_data_by"
        :loading="Vget_gymrutina_load"
        v-if="permission.can_view"
      >
        <template v-slot:[`item.dias`]="{ item }">
          {{ diasFormat(item.dias) }}
        </template>
        <template v-slot:[`item.estado`]="{ item }">
          <v-chip small dark :color="item.estado == true ? `green` : `red`">
            {{ item.estado == true ? "Si" : "No" }}
          </v-chip>
        </template>
        <template v-slot:[`item.accion`]="{ item }" v-if="permission.can_view">
          <c-btn-table-edit @click="editar(item)"></c-btn-table-edit>
          <c-btn-table-delete @click="eliminar(item)"></c-btn-table-delete>
        </template>
      </v-data-table>
      <v-dialog v-model="dialogEliminar" persistent max-width="400">
        <v-card>
          <v-card-title>
            <v-icon left>info</v-icon>
            <span class="subtitle-1 font-weight-light">
              Ventana de Confirmacion
            </span>
          </v-card-title>
          <v-form ref="form" @submit.prevent="eliminarGymrutina()">
            <v-card-subtitle>
              Desea eliminar la rutina {{ objeto.descripcion }}
            </v-card-subtitle>
          </v-form>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="red" text="text" @click="dialogEliminar = false">
              <v-icon>clear</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" text="text" @click="eliminarGymrutina()">
              <v-icon>done</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
        <c-overlay :value="overlay" absolute></c-overlay>
      </v-dialog>
    </v-card>
    <Create
      v-if="crud.add"
      v-model="crud.add"
      :props="crud.datos"
      @datos="crud.datos = $event"
      :editable="crud.datos != null ? true : false"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import Create from "./Create";
import { mensaje } from "@/services/notify.service";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { RUTINA_GIMNASIO } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";
export default {
  components: {
    Create,
  },
  props: {
    props: Object,
  },
  data: () => ({
    search: "",
    crud: {
      add: false,
      datos: null,
    },
    dialogEliminar: false,
    overlay: false,
    objeto: {
      descrpcion: "",
    },
    permission: {
      can_add: false,
      can_view: false,
      can_update: false,
      can_delete: false,
    },
    headers: [
      {
        text: "Fecha",
        align: "start",
        sortable: true,
        value: "fecha",
      },
      {
        text: "Descripcion",
        align: "start",
        sortable: false,
        value: "descripcion",
      },
      {
        text: "Dias",
        align: "start",
        sortable: false,
        value: "dias",
      },
      {
        text: "Accion",
        align: "end",
        sortable: false,
        value: "accion",
      },
    ],
  }),
  mounted() {
    this.fetchRutina();
    this.crud.datos = this.$props.props;
  },
  created() {
    const permission = getPermission(RUTINA_GIMNASIO).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  computed: {
    ...mapGetters("gymrutina", [
      "Vget_gymrutina_data_by",
      "Vget_gymrutina_load",
    ]),
  },
  methods: {
    ...mapActions("gymrutina", [
      "Vact_gymrutina_data_by",
      "gymrutina_update",
      "Vact_gymrutina_del",
    ]),
    fetchRutina() {
      let id = this.props.idgymplanilla;
      this.Vact_gymrutina_data_by({ id });
    },
    editar(item) {
      this.gymrutina_update(item);
      this.crud.datos = JSON.parse(JSON.stringify(item));
      this.crud.add = true;
    },
    eliminar(item) {
      this.dialogEliminar = true;
      this.objeto = item;
    },
    eliminarCancelar() {
      this.objeto.descrpcion = "";
      this.dialogEliminar = false;
    },
    async eliminarGymrutina() {
      this.overlay = true;
      const id = this.objeto.idgymrutina;
      const a = await this.Vact_gymrutina_del(id);
      if (a.estado == true) {
        this.$notify(a.info);
        this.fetchRutina();
        this.eliminarCancelar();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
    },
    diasFormat(item) {
      let dias = [
        "",
        "Lunes",
        "Martes",
        "Miercoles",
        "Jueves",
        "Viernes",
        "Sabado",
        "Domingo",
      ];
      if (!item) return "";
      let detalle = "";
      let count = item.length - 1;
      item.forEach((key, i) => {
        if (i === 0) return (detalle = dias[key]);
        if (i === count) detalle = detalle + " y " + dias[key];
        else detalle = detalle + ", " + dias[key];
      });
      return detalle;
    },
  },
};
</script>
