var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"headers":_vm.permiso.can_view_numbers ? _vm.parcela ? _vm.headersP : _vm.headers : _vm.parcela ? _vm.headersPNoPrecio: _vm.headersNoPrecio,"items":_vm.items,"mobile-breakpoint":0,"items-per-page":1000,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.idstock.iditem.itemquimica",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.producto_activo(item.idstock.iditem.itemquimica))+" ")]}},{key:"item.cantidad",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currencyFormat(item.cantidad))+" ")]}},{key:"item.cantxha",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currencyFormat(item.cantxha))+" ")]}},{key:"item.precio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currencyFormat(item.precio))+" ")]}},{key:"item.precioxha",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currencyFormat(item.precioxha))+" ")]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.currencyFormat(item.total))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","disabled":!_vm.readonly},on:{"click":function($event){return _vm.$emit('edit', item)}}},[_vm._v("edit")]),_c('v-icon',{attrs:{"small":"","disabled":!_vm.readonly},on:{"click":function($event){return _vm.$emit('delete', item)}}},[_vm._v("delete")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }