<template>
  <div>
    <CausaNoinseminacion
      v-if="formularios.causanoins"
      v-model="formularios.causanoins"
    />

    <v-form ref="form" lazy-validation>
      <v-row dense>
        <v-col cols="12" v-if="modo">
          <AutocompleteAnimal
            :key="historico"
            :action="historico ? 'fetchTodos' : 'fetchAnimalDisponible'"
            :getter="historico ? 'getTodos' : 'getDisponible'"
            no-create
            :readonly="readonly"
            :animal="animal"
            v-model="data.idanimal"
            :returnObject=true
            id="input1"
            @keyup.native.enter="nextfocus(1, data.idanimal.idanimal)"
            @change="esInseminable()"
          />

        </v-col>

        <v-col cols="12" sm="4" md="4">
          <v-switch
            :disabled="readonly"
            v-model="data.inseminable"
            :label="`Inseminable?: ${tipo}`"
          ></v-switch>
        </v-col>
        <v-col cols="12" sm="12" md="12" v-if="data.inseminable === false">
          <vue-autocomplete-generic
            :readonly="readonly"
            label="Causa de no Inseminacion"
            action="causanoins/Vact_causanoins_data"
            getter="causanoins/Vget_causanoins_data"
            load="causanoins/Vget_causanoins_load"
            pk="idcausanoins"
            title="desccausanoins"
            :permission="permission.causanoins"
            :create="true"
            @create="formularios.causanoins = $event"
            v-model="data.idcelos[0].idcausanoins.idcausanoins"
            @keyup.native.enter="
              nextfocus(1, data.idcelos[0].idcausanoins.idcausanoins)
            "
          />
        </v-col>

        <v-col cols="12" sm="12" md="12">
          <c-text-date
            label="Fecha"
            outlined
            dense
            :readonly="readonly"
            id="input2"
            v-model="data.fecccelo"
            @keyup.native.enter="nextfocus(2, data.fecccelo)"
          />
        </v-col>
        <v-col cols="12">
          <vue-text-field
            label="Comentario"
            id="input3"
            :readonly="readonly"
            :rules="false"
            v-model="data.comncelo"
            @keyup.native.enter="nextfocus(3, '0')"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { booleanConverter } from "@/services/util/boolean.service";
import { focus } from "@/services/focus.service";
import { current_date } from "@/services/util/date.service";
import CausaNoinseminacion from "../Causanoinseminacion/Create";
import { CAUSA_NO_INSEMINACION } from "@/constants/forms";
export default {
  components: {
    AutocompleteAnimal: () => import("../../Animal/Animal/AutocompleteAnimal"),
    CausaNoinseminacion,
  },
  props: {
     xidanimal: {
      type: Number,
      default: null
    },
    historico: {
      type: Boolean,
      default: false
    },
    datos: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    modo: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      noData: true,
      permission: {
        causanoins: CAUSA_NO_INSEMINACION,
      },
      data: {
        idcelo: 0,
        fechcelo: "2019-07-15",
        fecccelo: current_date(),
        comncelo: "",
        idanimal: {
          idanimal: "",
        },
        idcelos: [
          {
            idcelo: "",
            idcausanoins: {
              idcausanoins: "",
            },
          },
        ],
        inseminable: true,
      },
      default: {
        idcelo: 0,
        fechcelo: "2019-07-15",
        fecccelo: current_date(),
        comncelo: "",
        idanimal: {
          idanimal: "",
        },
        idcelos: [
          {
            idcelo: "",
            idcausanoins: {
              idcausanoins: "",
            },
          },
        ],
        inseminable: true,
      },

      animal: null,
      formularios: {
        causanoins: false,
      },
    };
  },
  watch: {
    reload_medicacion(val) {
      if (val) return this.nuevo_medicamento();
    },

    datos(val) {
      if (val != null) return null;
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$refs.form.resetValidation();
    },
  },
  computed: {
    tipo() {
      return booleanConverter.si_no(this.data.inseminable);
    },
  },
  created() {
    this.nuevo(this.datos);
  },
  mounted() {
    if(this.xidanimal) this.data.idanimal.idanimal = this.xidanimal;
    if (this.modo)
      return setTimeout(() => document.getElementById("input1").focus(), 500);
    return setTimeout(() => document.getElementById("input2").focus(), 500);
  },
  methods: {
    esInseminable() {
      if (this.data.idanimal.idestadoanimal.idestadoanimal == 7) {
        this.data.inseminable = false
      }
    },

    nextfocus(index, data) {
      focus.nextid(index, data);
    },

    validate() {
      return this.$refs.form.validate();
    },
    obtenerDatos() {
      if (this.data.comncelo === "") {
        this.data.comncelo = null;
      }
      return this.data;
    },

    nuevo(val) {
      if (val === null) return null;
      this.animal = JSON.parse(JSON.stringify(val.idanimal));
      this.data = JSON.parse(JSON.stringify(val));
    },
  },
};
</script>
