export const SET_LOADING = 'SET_LOADING'
export const SET_FUCIONARIO_ACTIVE = 'SET_FUCIONARIO_ACTIVE'
export const SET_FUCIONARIO_INACTIVE = 'SET_FUCIONARIO_INACTIVE'
export const SET_ANHOS = 'SET_ANHOS'
export const SET_EMPLEADO_OBRERO = 'SET_EMPLEADO_OBRERO'
export const SET_SUELDOS_JORNALES = 'SET_SUELDOS_JORNALES'
export const SET_RESUMEN_GENERAL = 'SET_RESUMEN_GENERAL'
export const SET_DOCUMENTOS = 'SET_DOCUMENTOS'
export const SET_CARGO = 'SET_CARGO'
export const SET_REPORTE_LOADING = "SET_REPORTE_LOADING"
export const SET_PLANILLA_SUELDO_IPS = "SET_PLANILLA_SUELDO_IPS"
export const SET_SALDOS = "SET_SALDOS"

export const url = {
    funcionarios: '/apifuncionario/',
    anhos:'/apiyearreporte/',
    empleadoAndObrero: '/apiempleadoobrero/',
    sueldosAndJornales: '/apisueldojornales/',
    resumenGeneral: '/apiresumengeneral/',
    documentos: '/apidocumentos/',
    cargo: '/apicargo/',
    sueldosAndJornalesIps: '/apiexcelips/',
    saldos: '/apianticipofuncionario/'
}