export default {
    async motivomuerte_create({commit},request){
        commit('motivomuerte_create',request);
    },
    async motivomuerte_update({commit},request){
        commit('motivomuerte_update',request);
    },
    async motivomuerte_reload({commit},request){
        commit('motivomuerte_reload',request);
    },
    async motivomuerte_show({commit},request){
        commit('motivomuerte_show',request);
    },
}
