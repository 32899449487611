<template>
  <div>
    <div v-if="abrircontacto">
      <Personas-Contacto-create />
    </div>
    <div v-if="abrircontactoshow">
      <Personas-Contacto-View />
    </div>
    <v-card>
      <v-alert type="error" v-if="permission_alert"
        >Error!!! Este usuario no tiene permisos en esta ventana</v-alert
      >
      <v-toolbar flat dense>
        <v-tabs fixed-tabs v-model="tab">
          <v-tab href="#tab-1" v-if="!cliente()">Clientes</v-tab>
          <v-tab href="#tab-2" v-if="!proveedor()">Proveedores</v-tab>
          <v-tab href="#tab-3" v-if="!transportadora()">Transportadoras</v-tab>
          <v-tab href="#tab-4" v-if="!conductor()">Conductores</v-tab>
         
        </v-tabs>
         <v-toolbar-items>
            <widgets-Favorite
              class="mt-1"
              ruta="/contacto"
              formulario="Contacto"
            />

            <c-btn-table-add
              @click="contacto_create(true)"
              v-if="permission_add"
            />
          </v-toolbar-items>
      </v-toolbar>
      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1">
          <v-card>
            <Personas-Contacto-Cliente />
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-2">
          <v-card>
            <Personas-Contacto-Proveedor />
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-3">
          <v-card>
            <Personas-Contacto-Transportador />
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-4">
          <v-card>
            <Personas-Contacto-Conductor />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { mapActions, mapGetters } from "vuex";
import {
  CLIENTE,
  PROVEEDOR,
  CONDUCTOR,
  TRANSPORTADORA,
} from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";
export default {
  data() {
    return {
      tab: null,
      permission_cliente: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
      permission_proveedor: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
      permission_conductor: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
      permission_transporadora: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
    };
  },
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    const prove = getPermission(PROVEEDOR).permiso;
    this.permission_proveedor = {
      can_add: prove[PUEDE_AGREGAR],
      can_view: prove[PUEDE_LISTAR],
      can_update: prove[PUEDE_EDITAR],
      can_delete: prove[PUEDE_ELIMINAR],
    };
    const cond = getPermission(CONDUCTOR).permiso;
    this.permission_conductor = {
      can_add: cond[PUEDE_AGREGAR],
      can_view: cond[PUEDE_LISTAR],
      can_update: cond[PUEDE_EDITAR],
      can_delete: cond[PUEDE_ELIMINAR],
    };
    const trans = getPermission(TRANSPORTADORA).permiso;
    this.permission_transporadora = {
      can_add: trans[PUEDE_AGREGAR],
      can_view: trans[PUEDE_LISTAR],
      can_update: trans[PUEDE_EDITAR],
      can_delete: trans[PUEDE_ELIMINAR],
    };
    const clie = getPermission(CLIENTE).permiso;
    this.permission_cliente = {
      can_add: clie[PUEDE_AGREGAR],
      can_view: clie[PUEDE_LISTAR],
      can_update: clie[PUEDE_EDITAR],
      can_delete: clie[PUEDE_ELIMINAR],
    };
  },
  computed: {
    ...mapGetters("contacto", {
      abrircontacto: "contacto_create",
      reload: "contacto_reload",
      abrircontactoshow: "contacto_show",
    }),
    permission_add() {
      if (
        this.permission_proveedor.can_add ||
        this.permission_conductor.can_add ||
        this.permission_transporadora.can_add ||
        this.permission_cliente.can_add
      )
        return true;
      return false;
    },
    permission_alert() {
      if (
        this.proveedor() &&
        this.conductor() &&
        this.transportadora() &&
        this.cliente()
      )
        return true;
      return false;
    },
  },

  methods: {
    ...mapActions("contacto", [
      "contacto_create",
      "contacto_update",
      "contacto_reload",
    ]),
    proveedor() {
      return mensaje.permission(this.permission_proveedor);
    },
    conductor() {
      return mensaje.permission(this.permission_conductor);
    },
    transportadora() {
      return mensaje.permission(this.permission_transporadora);
    },
    cliente() {
      return mensaje.permission(this.permission_cliente);
    },
  },
};
</script>
