export default {

  campana_data: [],
  campana_data_all: [],
  campana_data_anho: [],
  campana_data_byLote: [],
  campana_load: false,
  campana_save_load: false,
  reporte_async: [],
  reporte_load: false,
}
