export default {


  campana_data(state, request) {
    state.campana_data = request;
  }, 
  campana_data_all(state, request) {
    state.campana_data_all = request;
  }, 
  campana_data_anho(state, request) {
    state.campana_data_anho = request;
  }, 
  campana_data_byLote(state, request) {
    state.campana_data_byLote = request;
  }, 
  campana_load(state, request) {
    state.campana_load = request;
  }, 
  campana_save_load(state, request) {
    state.campana_save_load = request;
  }, 
  reporte_async(state, request) {
    state.reporte_async = request
  },
  reporte_load(state, request) {
    state.reporte_load = request;
  }
}