<template>
  <v-card>
    <v-toolbar flat>
      <v-toolbar-title>{{
        operacion ? "Registrar Asientos" : "Modificar Asiento"
      }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-form ref="form">
        <v-row dense>
          <v-col cols="12" sm="2">
            <vue-text-field
              label="Asiento Nro"
              v-model="data.numero"
              readonly
            ></vue-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="2">
            <c-text-date
              ref="asientoInput1"
              label="Fecha"
              v-model="data.fecha"
              @keyup.native.enter="next(1, data.fecha)"
            ></c-text-date>
          </v-col>
          <v-col cols="12" sm="2">
            <vue-autocomplete-generic
              ref="asientoInput2"
              action="moneda/Vact_moneda_data"
              getter="moneda/Vget_moneda_data"
              load="moneda/Vget_moneda_load"
              pk="idmoneda"
              label="Seleccione Moneda"
              title="descmoneda"
              permission="Moneda"
              v-model="data.idmoneda.idmoneda"
              @keyup.native.enter="next(2, data.idmoneda.idmoneda)"
            />
          </v-col>
        </v-row>
      </v-form>
      <v-form ref="formDetalle">
        <v-row dense>
          <v-col cols="12" sm="2">
            <vue-autocomplete-object
              ref="asientoInput3"
              action="rubro/Vact_rubro_data"
              getter="rubro/Vget_rubro_data"
              load="rubro/Vget_rubro_load"
              pk="idrubro"
              label="Rubro"
              title="descripcion"
              validar="idrubro"
              permission="Rubro"
              v-model="detalle.idrubro"
              :create="true"
              @sync-input="search = $event"
              @create="formulario.rubro = $event"
              @keyup.native.enter="next(3, detalle.idrubro.idrubro)"
            />
            <v-dialog
              v-if="formulario.rubro"
              v-model="formulario.rubro"
              max-width="700"
            >
              <v-card>
                <DiarioCaja-Rubro-Crud
                  v-model="formulario.rubro"
                  dialog-mode
                  :sync-input="search"
                  @sync-input="(detalle.idrubro = $event), next(3, '-')"
                />
              </v-card>
            </v-dialog>
          </v-col>
          <v-col cols="12" sm="2">
            <vue-autocomplete-object
              ref="asientoInput4"
              action="referencia/Vact_referencia_data"
              getter="referencia/Vget_referencia_data"
              load="referencia/Vget_referencia_load"
              pk="idreferencia"
              label="Referencia"
              title="descripcion"
              validar="idreferencia"
              permission="Referencia"
              v-model="detalle.idreferencia"
              :create="true"
              @sync-input="search = $event"
              @create="formulario.referencia = $event"
              @keyup.native.enter="next(4, detalle.idreferencia.idreferencia)"
            />
            <v-dialog
              v-if="formulario.referencia"
              v-model="formulario.referencia"
              max-width="700"
            >
              <v-card>
                <DiarioCaja-Referencia-Crud
                  v-model="formulario.referencia"
                  dialog-mode
                  :sync-input="search"
                  @sync-input="(detalle.idreferencia = $event), next(4, '-')"
                />
              </v-card>
            </v-dialog>
          </v-col>
          <v-col cols="12" sm="2">
            <vue-autocomplete-object
              ref="asientoInput5"
              action="cuentadiario/Vact_cuentadiario_data"
              getter="cuentadiario/Vget_cuentadiario_data"
              load="cuentadiario/Vget_cuentadiario_load"
              pk="idcuenta_diario"
              label="Cuenta Origen"
              title="descripcion"
              validar="idcuenta_diario"
              permission="Cuenta Diario"
              v-model="detalle.idcuenta_diario"
              :create="true"
              @sync-input="search = $event"
              @create="formulario.cuentadiario = $event"
              @keyup.native.enter="
                next(5, detalle.idcuenta_diario.idcuenta_diario)
              "
            />
            <v-dialog
              v-if="formulario.cuentadiario"
              v-model="formulario.cuentadiario"
              max-width="700"
            >
              <v-card>
                <DiarioCaja-Cuenta-Crud
                  v-model="formulario.cuentadiario"
                  dialog-mode
                  :sync-input="search"
                  @sync-input="(detalle.idcuenta_diario = $event), next(5, '-')"
                />
              </v-card>
            </v-dialog>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="2">
            <vue-text-currency
              label="Debito"
              v-model="detalle.precio_debito"
              ref="asientoInput6"
              :rules="true"
              @keyup.native.enter="next(6, detalle.precio_debito)"
            ></vue-text-currency>
          </v-col>
          <v-col cols="12" sm="2">
            <vue-text-currency
              label="Credito"
              v-model="detalle.precio_credito"
              ref="asientoInput7"
              :rules="true"
              @keyup.native.enter="next(7, detalle.precio_credito)"
            ></vue-text-currency>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12" sm="2">
            <vue-autocomplete-object
              ref="asientoInput8"
              action="cuentadiario/Vact_cuentadiario_data"
              getter="cuentadiario/Vget_cuentadiario_data"
              load="cuentadiario/Vget_cuentadiario_load"
              pk="idcuenta_diario"
              label="Cuenta Destino"
              validar="idcuenta_diario"
              title="descripcion"
              permission="Cuenta Diario"
              v-model="detalle.idcuenta_diario1"
              :create="true"
              @sync-input="search = $event"
              @create="formulario.cuentadiario1 = $event"
              @keyup.native.enter="next(8, detalle.idcuenta_diario1)"
            />
            <v-dialog
              v-if="formulario.cuentadiario1"
              v-model="formulario.cuentadiario1"
              max-width="700"
            >
              <v-card>
                <DiarioCaja-Cuenta-Crud
                  v-model="formulario.cuentadiario1"
                  dialog-mode
                  :sync-input="search"
                  @sync-input="
                    (detalle.idcuenta_diario1 = $event), next(8, '-')
                  "
                />
              </v-card>
            </v-dialog>
          </v-col>
          <v-col cols="8" sm="4">
            <vue-text-field
              label="Comentario"
              v-model="detalle.comentario"
              ref="asientoInput9"
              @keyup.native.enter="next(9, detalle.comentario)"
            ></vue-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            ref="asientoInputaceptar"
            class="mr-3"
            text
            outlined
            @click="addTabla()"
            >AGREGAR</v-btn
          >
        </v-row>
      </v-form>
      <v-row>
        <v-col cols="12">
          <v-data-table
            dense
            :headers="headers"
            :items="data.diariocaja"
            :items-per-page="10000"
            hide-default-footer
          >
            <template v-slot:item.precio_debito="props">
              <p class="text-end ma-2">
                {{ toCurrency(props.item.precio_debito) }}
              </p>
            </template>
            <template v-slot:item.precio_credito="props">
              <p class="text-end ma-2">
                {{ toCurrency(props.item.precio_credito) }}
              </p>
            </template>
            <template v-slot:item.accion="props">
              <v-icon
                class="mr-2"
                @click="eliminarDetalle(props.item)"
                v-if="props.item.isSaved"
                >delete</v-icon
              >
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn color="red" text @click="cancelar()">
        <v-icon>clear</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="operacion ? guardar() : editar()">
        <v-icon>done</v-icon>
      </v-btn>
    </v-card-actions>
    <v-divider></v-divider>
    <c-overlay :value="Vget_asientodiario_save_load"></c-overlay>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mensaje } from "@/services/notify.service";
import { current_date } from "@/services/util/date.service";
import { db } from "@/services/db.service";
import {currency} from  "@/services/util/number.service";
export default {
  data() {
    return {
      operacion: false,
      debitocredito: "",
      search: "",
      detalle: {
        iddiario_caja: 0,
        idrubro: "",
        idcuenta_diario: "",
        idreferencia: "",
        idcuenta_diario1: "",
        comentario: "",
        precio_debito: 0,
        precio_credito: 0,
      },
      detalle_default: {
        iddiario_caja: 0,
        idrubro: "",
        idcuenta_diario: "",
        idreferencia: "",
        idcuenta_diario1: "",
        comentario: "",
        precio_debito: 0,
        precio_credito: 0,
      },
      data: {
        iddiario: 0,
        idmoneda: {
          idmoneda: "",
        },
        fecha: current_date(),
        numero: null,
        diariocaja: [],
      },
      default: {
        iddiario: 0,
        idmoneda: {
          idmoneda: "",
        },
        fecha: current_date(),
        numero: null,
        diariocaja: [],
      },
      headers: [
        { text: "Rubro", align: "left", value: "idrubro.descripcion" },
        {
          text: "Referencia",
          align: "left",
          value: "idreferencia.descripcion",
        },
        { text: "Cuenta", align: "left", value: "idcuenta_diario.descripcion" },
        { text: "Comentario", align: "left", value: "comentario" },
        { text: "Debito", align: "left", value: "precio_debito" },
        { text: "Credito", align: "left", value: "precio_credito" },
        { text: "Accion", value: "accion", sortable: false, align: "right" },
      ],
      path: {
        add: "/diariocaja/asiento/add",
        edit: "/diariocaja/asiento/edit",
        editName: 'asientoEdit'
      },
      formulario: {
        rubro: false,
        referencia: false,
        cuentadiario: false,
        cuentadiario1: false
      },
    };
  },
  async mounted() {
    this.changeCrudMode();
    this.next(0, "-");
  },
  watch: {
    currentPath(path) {
      if (path === this.path.add || this.$route.name === this.path.editName) {
        
        this.changeCrudMode();
        if (this.$refs.form) this.$refs.form.resetValidation();
        if (this.$refs.formDetalle) this.$refs.form.resetValidation();
      }
    },
  },
  computed: {
    ...mapGetters("asientodiario", ["Vget_asientodiario_save_load"]),
    currentPath() {
      return this.$route.path;
    },
  },
  methods: {
    ...mapActions("asientodiario", [
      "Vact_asientodiario_save",
      "Vact_asientodiario_update",
      "Vact_asientodiario_data",
    ]),
    toCurrency(value){
      if(value)
      return currency(value)
    },
    changeCrudMode() {
      if (this.currentPath != this.path.add) {
        const data = JSON.parse(JSON.stringify(this.$route.query));
        if (!Array.isArray(data.diariocaja)) return this.cancelar();
        this.data = JSON.parse(JSON.stringify(data));
        this.data.diariocaja = [];
        data.diariocaja.map((detalle) => {
          this.generarDatosTabla(detalle);
        });
        this.operacion = false;
      } else {
        this.operacion = true;
        this.data = JSON.parse(JSON.stringify(this.default));
        this.obtenerAsientoNro();
      }
    },
    next(index, data) {
      let id = `asientoInput${index + 1}`;
      if (index === 6 || index === 7) {
        this.$refs[id].$children[0].focus();
      }
      try {
        if (data != null && data != "") {
          this.$refs[id].$children[0].focus();
        }
      } catch (error) {
        this.$refs.asientoInputaceptar.$el.focus();
      }
    },
    async obtenerAsientoNro() {
      if (!this.operacion) return null;
      try {
        const firstId = await db.get("/apidiarionumero/");
        this.data.numero = firstId;
      } catch (error) {
        console.log(error); 
      }
    },
    isValid() {
      if (!this.$refs.formDetalle.validate()) return false;
      if (this.detalle.precio_debito != 0 && this.detalle.precio_credito != 0)
        return false;
      if (this.detalle.precio_debito === this.detalle.precio_credito)
        return false;
      if (
        this.detalle.precio_debito === "" ||
        this.detalle.precio_debito === null ||
        this.detalle.precio_debito === undefined
      )
        return false;
      if (
        this.detalle.precio_credito === "" ||
        this.detalle.precio_credito === null ||
        this.detalle.precio_credito === undefined
      )
        return false;
      return true;
    },
    generarDatosTabla(detalle) {
      const first = {
        ...detalle,
        isSaved: true,
      };
      let last = {
        ...detalle,
        ...{ idcuenta_diario: detalle.idcuenta_diario1 },
        isSaved: false,
      };
      if (detalle.precio_debito != "") {
        last.precio_credito = detalle.precio_debito;
        last.precio_debito = 0;
      } else {
        last.precio_debito = detalle.precio_credito;
        last.precio_credito = 0;
      }
      this.data.diariocaja.push(first);
      this.data.diariocaja.push(last);
    },
    addTabla() {
      if (!this.isValid())
        return this.$notify(
          mensaje.error("Ocurrio un error, verifique los valores ingresados")
        );
      this.generarDatosTabla(this.detalle);
      this.detalle = JSON.parse(JSON.stringify(this.detalle_default));
      this.$refs.formDetalle.resetValidation();
      setTimeout(() => this.next(2, "-"), 200);
    },
    eliminarDetalle(val) {
      const index = this.data.diariocaja.indexOf(val);
      this.data.diariocaja.splice(index, 2);
    },
    cancelar() {
      this.$router.push({ path: "/diariocaja/asiento" });
    },
    async guardar() {
      if (!this.$refs.form.validate()) return null;
      let a = await this.Vact_asientodiario_save(this.data);
      if (a.estado == true) {
        this.data = JSON.parse(JSON.stringify(this.default));
        this.Vact_asientodiario_data();
        this.$notify(a.info);
        this.obtenerAsientoNro();
        this.$refs.form.resetValidation();
        this.next(0, "-");
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
    },
    async editar() {
      const id = this.$route.params.id;
      const data = this.data;
      let a = await this.Vact_asientodiario_update({ id, data });
      if (a.estado == true) {
        this.$notify(a.info);
        this.Vact_asientodiario_data();
        this.cancelar();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
    },
  },
};
</script>

<style>
</style>