<template>
    <div>
        <c-alert-permission v-model="permission"></c-alert-permission>
        <div v-if="permission.can_view">
            <v-form ref="form">
                <v-toolbar flat>
                    <v-col>
                        <v-autocomplete :items="Vget_campo_data" item-text="campo" return-object :rules="validar"
                            autocomplete="off" hide-details :loading="Vget_campo_load" label="Campo" id="input1"
                            @keyup.enter="next(1, data.idlote_agri)" v-model="data.idlote_agri"
                            v-on:change="changeLote">
                            <template v-slot:no-data>
                                <v-list-item dense>
                                    <v-list-item-content>
                                        <v-list-item-title>No se encontro ningun Lote</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col>
                        <v-autocomplete :items="Vget_campana_data_byLote" item-text="descripcion" return-object
                            :rules="validar" autocomplete="off" hide-details :loading="Vget_campana_load" label="Zafra"
                            id="input2" @keyup.enter="next(2, data.idcampana)" v-model="data.idcampana">

                            <template v-slot:no-data>
                                <v-list-item dense>
                                    <v-list-item-content>
                                        <v-list-item-title>No se encontro ningun Lote</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col>
                        <v-btn text color="primary" class="mr-1" fab small id="aceptar" outlined
                            @click="descargarDatos()">
                            <v-icon>cloud_download</v-icon>
                        </v-btn>
                    </v-col>
                </v-toolbar>
                <v-container>
                    <v-card elevation="1" tile v-if="reporte.zafra != ''">
                        <v-toolbar flat dense>
                            <div class="body-1 flex font-weight-black text-center">
                                Informacion General
                            </div>
                        </v-toolbar>
                        <v-container class="mt-1 overflow-y-auto">
                            <v-row>
                                <v-col cols="4"><b>Zafra: </b>{{ reporte.zafra }}</v-col>
                                <v-col cols="4"><b>Lote: </b>{{ reporte.lote }}</v-col>
                                <v-col cols="4"><b>Ha: </b>{{ reporte.ha }}</v-col>
                                <v-col cols="4"><b>Cultivo: </b>{{ reporte.cultivo }}</v-col>
                            </v-row>
                        </v-container>
                        <v-toolbar flat dense>
                            <div class="body-1 flex font-weight-black text-center">
                                Indice NDVI
                            </div>
                        </v-toolbar>
                        <v-container class="mt-1 overflow-y-auto">
                            <v-data-table :headers="headers" :items="reporte.image" dense :items-per-page="10000"
                                hide-default-footer class="mb-2">

                                <template v-slot:[`item.minimo`]="{ item }">
                                    {{ item.minimo.toFixed(2) }}
                                </template>

                                <template v-slot:[`item.maximo`]="{ item }">
                                    {{ item.maximo.toFixed(2) }}
                                </template>

                                <template v-slot:[`item.media`]="{ item }">
                                    {{ item.media.toFixed(2) }}
                                </template>
                            </v-data-table>
                            <v-row>
                                <v-col v-for="n in reporte.image" :key="n" class="d-flex child-flex" cols="12" md="3"
                                    sm="12">
                                    <v-card>
                                        <v-card-title>
                                            <div class="body-1 flex font-weight-black text-center">
                                                {{ n.generationdate }}
                                            </div>
                                        </v-card-title>
                                        <v-img
                                            :src="`https://sentinel.integral.com.py/api/integralndvi/${n.title}/?token=442ae83efca32ce518717a0d7b5095e0ada7ba16`">

                                            <template v-slot:placeholder>
                                                <v-row class="fill-height ma-0" align="center" justify="center">
                                                    <v-progress-circular indeterminate
                                                        color="grey lighten-5"></v-progress-circular>
                                                </v-row>
                                            </template>
                                        </v-img>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-container>
                        <v-toolbar flat dense>
                            <div class="body-1 flex font-weight-black text-center">
                                Predicción: {{ reporte.estimated.toFixed(2) }} KG x ha
                            </div>
                        </v-toolbar>
                    </v-card>
                </v-container>
            </v-form>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { PUEDE_GENERAR_REPORTE } from "@/constants/permissions";
import { REPORTE_AGRICULTURA } from "@/constants/forms";
export default {
    data() {
        return {
            validar: [v => !!v || "Seleccione un campo"],
            headers: [
                {
                    text: "Fecha",
                    align: "start",
                    value: "generationdate",
                },
                {
                    text: "Mínimo",
                    align: "start",
                    value: "minimo",
                },
                {
                    text: "Máximo",
                    align: "start",
                    value: "maximo",
                },
                {
                    text: "Media",
                    align: "start",
                    value: "media",
                },
            ],
            permission: {
                can_view: false,
            },
            data: {
                idlote_agri: {
                    idlote_agri: ""
                },
                idcampana: {
                    idcampana: ""
                },
            },
            reporte: {
                zafra: "",
                lote: "",
                cultivo: "",
                ha: "",
                comentario: "",
                image: [],
                estimated: 0
            }
        }
    },
    created() {
        this.permission.can_view = getPermission(REPORTE_AGRICULTURA).permiso[PUEDE_GENERAR_REPORTE];
    },
    mounted() {
        this.Vact_campo_data('yes');
    },
    computed: {
        ...mapGetters("campo", ["Vget_campo_data", "Vget_campo_load"]),
        ...mapGetters("campana", ["Vget_campana_data_byLote", "Vget_campana_load"]),
        ...mapGetters("agroreporte", ["getReporteindice"]),

    },
    watch: {
        getReporteindice(val) {
            this.reporte.image = val.images
            this.reporte.estimated = val.estimated
        }
    },
    methods: {
        ...mapActions("campo", ["Vact_campo_data"]),
        ...mapActions("campana", ["Vact_campana_data_byLote"]),
        ...mapActions("agroreporte", ["fetchReporteindice"]),
        changeLote() {
            this.Vact_campana_data_byLote(this.data.idlote_agri.idlote_agri)
        },
        descargarDatos() {
            this.reporte.zafra = this.data.idcampana.descripcion
            this.reporte.lote = this.data.idlote_agri.campo
            this.reporte.cultivo = this.data.idcampana.iditem.descitem
            this.reporte.ha = this.data.idlote_agri.hectarea
            this.reporte.comentario = this.data.idcampana.comentario
            const idlote = this.data.idlote_agri.idlote_agri
            const idcampana = this.data.idcampana.idcampana
            const fecha_inicio = this.data.idcampana.fechacampana
            this.fetchReporteindice({
                idlote: idlote,
                idcampana: idcampana,
                fecha_inicio: fecha_inicio
            })
        }
    }
}
</script>
<style>
.theme--light.v-data-table tbody tr:nth-of-type(even) {
    background-color: rgba(0, 0, 0, .03);
}
.theme--dark.v-data-table tbody tr:nth-of-type(even) {
    background-color: rgba(0, 0, 0, .5);
}
</style>