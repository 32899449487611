<template>
  <div>
    <v-card>
      <v-toolbar flat dense>
        <v-toolbar-title>Contratos</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <c-btn-table-add
            @click="crud.add = true"
            v-if="permission_contrato.can_add"
          ></c-btn-table-add>
          <c-btn-table-reload
            v-if="permission_contrato.can_view"
            class="ml-1"
            @click="fetchContract()"
          ></c-btn-table-reload>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="getContratoFuncionarioById"
        :loading="isContratoFuncionarioLoading"
        hide-default-footer
        :items-per-page="99999"
        v-if="permission_contrato.can_view"
      >
        <template v-slot:[`item.idcontrato_estado`]="props">
          <v-chip small dark :color="props.item.idcontrato_estado.color">
            {{ props.item.idcontrato_estado.descripcion }}
          </v-chip>
        </template>
        <template v-slot:[`item.action`]="props">
          <v-btn
            x-small
            fab
            text
            outlined
            color="deep-purple accent-4"
            @click="modificar(props.item)"
            ><v-icon>arrow_forward</v-icon></v-btn
          >
          <v-btn
            v-if="props.item.idcontrato_estado.idcontrato_estado == 1"
            x-small
            fab
            text
            outlined
            color="deep-purple accent-4"
            @click="eliminar(props.item)"
            ><v-icon>close</v-icon></v-btn
          >
        </template>
      </v-data-table>
    </v-card>
    <Crud
      v-if="crud.add"
      v-model="crud.add"
      :props="crud.datos"
      :editable="crud.datos != null ? true : false"
      @datos="crud.datos = $event"
      :idcontacto="props.idcontacto"
    />
    <Delete
      v-if="crud.delete"
      v-model="crud.delete"
      :props="crud.datos"
      @filter="fetchContract()"
      @datos="crud.datos = $event"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Crud from "./Crud";
import Delete from "./Delete";
import { CONTRATO } from "@/constants/forms";
import { getPermission } from "@/services/storage/permissionStorage.service";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";
export default {
  components: {
    Crud,
    Delete,
  },
  props: {
    props: Object,
  },
  created() {
    const permission_contrato = getPermission(CONTRATO).permiso;
    this.permission_contrato = {
      can_add: permission_contrato[PUEDE_AGREGAR],
      can_view: permission_contrato[PUEDE_LISTAR],
      can_update: permission_contrato[PUEDE_EDITAR],
      can_delete: permission_contrato[PUEDE_ELIMINAR],
    };
  },
  mounted() {
    this.fetchContract();
  },
  computed: {
    ...mapGetters("contratofuncionario", [
      "getContratoFuncionarioById",
      "isContratoFuncionarioLoading",
    ]),
  },
  methods: {
    ...mapActions("contratofuncionario", ["fetchContratoFuncionarioById"]),
    modificar(item) {
      this.crud.datos = JSON.parse(JSON.stringify(item));
      this.crud.add = true;
    },
    eliminar(item) {
      this.crud.datos = JSON.parse(JSON.stringify(item));
      this.crud.delete = true;
    },
    fetchContract() {
      this.fetchContratoFuncionarioById(this.props.idcontacto.idcontacto);
    },
  },
  data: () => ({
    crud: {
      add: false,
      edit: false,
      delete: false,
      datos: null,
    },
    permission_contrato: {
      can_add: false,
      can_view: false,
      can_update: false,
      can_delete: false,
    },
    headers: [
      {
        text: "Cargo",
        align: "start",
        sortable: false,
        value: "descargo",
      },
      {
        text: "Sector",
        align: "start",
        sortable: false,
        value: "idsector.descripcion",
      },
      {
        text: "Nivel",
        align: "start",
        sortable: false,
        value: "idnivel.descripcion",
      },
      { text: "Salario", value: "salario", align: "start" },
      { text: "Estado", value: "idcontrato_estado", align: "start" },
      {
        text: "Opcion",
        align: "right",
        value: "action",
      },
    ],
  }),
};
</script>

<style></style>
