<template>
  <div>
    <v-dialog
      :value="value"
      max-width="900"
      persistent
      :fullscreen="$vuetify.breakpoint.xs"
      @keydown.esc="cerrar()"
    >
      <v-card>
        <v-form ref="formCabecera">
          <v-toolbar flat>
            <v-toolbar-title>
              {{
                readonly
                  ? "Detalles"
                  : operacion
                  ? "Registro de Venta de Granos"
                  : "Modificar Venta de Granos"
              }}</v-toolbar-title
            >
            <v-divider class="mx-2" inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-toolbar>
        </v-form>
        <v-container>
          <v-form ref="form" @submit.prevent="next(1, ventaagro.fecha)">
            <v-row dense>
              <v-col cols="12" md="2" sm="3">
                <c-text-date
                  label="Fecha"
                  id="ventaagroInput1"
                  :readonly="readonly"
                  outlined
                  dense
                  v-model="ventaagro.fecha"
                  @focus="setSelection(`ventaagroInput1`)"
                  @keyup.native.enter="next(1, ventaagro.fecha)"
                ></c-text-date>
              </v-col>
              <v-col cols="12" md="3" sm="3">
                <vue-autocomplete-generic
                  id="ventaagroInput2"
                  action="campana/Vact_campana_data"
                  getter="campana/Vget_campana_data"
                  load="campana/Vget_campana_load"
                  pk="idcampana"
                  :readonly="readonly"
                  :disabled="isDetail"
                  label="Seleccione Campana"
                  title="descripcion"
                  permission="Zafra"
                  v-model="ventaagro.idcampana.idcampana"
                  @create="formularios.campana = $event"
                  @keyup.native.enter="next(2, ventaagro.idcampana.idcampana)"
                />
              </v-col>
              <v-spacer></v-spacer>

              <v-col cols="12" md="4" sm="4">
                <v-autocomplete
                  :readonly="readonly"
                  dense
                  id="ventaagroInput3"
                  :items="Vget_caja_cajacuenta"
                  label="Seleccione Caja/Cuenta Bancaria de destino"
                  item-text="desc"
                  return-object
                  :rules="[(v) => !!v || 'Este campo es Obligatorio']"
                  :loading="Vget_caja_load"
                  :search-input.sync="search"
                  required
                  outlined
                  v-model="ventaagro.idcajacuentabanco"
                  autocomplete="off"
                  @keyup.enter="
                    next(3, ventaagro.idcajacuentabanco.idcajacuentabanco)
                  "
                  :no-data-text="`No existe ninguna concidencia`"
                >
                  <template
                    v-if="caja_permission || cuentabanco_permission"
                    v-slot:no-data
                  >
                    <v-list-item
                      v-if="caja_permission"
                      @click="formularios.caja = true"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          >Click para agregar
                          <strong>CAJA</strong></v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                      v-if="cuentabanco_permission"
                      @click="formularios.cuentabanco = true"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          >Click para agregar
                          <strong>CUENTA BANCARIA</strong></v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <div v-if="formularios.caja">
                  <FormCaja :sync-input="search" v-model="formularios.caja" />
                </div>
                <div v-if="formularios.cuentabanco">
                  <FormCuentaBanco
                    :sync-input="search"
                    v-model="formularios.cuentabanco"
                  />
                </div>
              </v-col>
            </v-row>
          </v-form>

          <v-form ref="formdetalle">
            <v-row dense>
              <v-col cols="12" md="4" sm="4">
                <v-btn-toggle
                  v-model="ventaagrodet.ensilaje"
                  dense
                  rounded
                  mandatory
                  color="red darken-1"
                >
                  <v-btn>
                    Granos
                  </v-btn>
                  <v-btn>
                    G. Humedo
                  </v-btn>
                  <v-btn>
                    Ensilaje
                  </v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <AutocompleteDepositoStock
                  id="ventaagroInput4"
                  :readonly="readonly"
                  :label="ventaagrodet.ensilaje == 2 ? 'Tipo de ensilaje' : ventaagrodet.ensilaje == 0 ? 'Deposito' : 'G. Humedo'"
                  :return-type="ventaagrodet.ensilaje == 2 ? 'ensilaje' : ventaagrodet.ensilaje == 0 ? 'stock' : 'hum'"
                  :idcampana="ventaagro.idcampana.idcampana"
                  v-model="ventaagrodet.idstock"
                  @keyup.native.enter="next(4, ventaagrodet.idstock)"
                />
              </v-col>

              <v-col cols="12" md="4" sm="4">
                <vue-text-currency
                  label="Cantidad en Kg"
                  id="ventaagroInput5"
                  type="number"
                  :readonly="readonly"
                  @focus="setSelection(`ventaagroInput5`)"
                  :messages="
                    ventaagrodet.idstock.cantstock
                      ? `Te sobra: ${toCurrency(
                          ventaagrodet.idstock.cantstock - ventaagrodet.cantidad
                        )}`
                      : ''
                  "
                  :rules="true"
                  v-model="ventaagrodet.cantidad"
                  @keyup.native.enter="next(5, ventaagrodet.cantidad)"
                ></vue-text-currency>
              </v-col>

              <v-col cols="12" md="3" sm="3">
                <vue-text-currency
                  label="Precio por Tn"
                  :rules="true"
                  :readonly="readonly"
                  v-model="ventaagrodet.precio"
                  @focus="setSelection(`ventaagroInput6`)"
                  id="ventaagroInput6"
                  @keyup.native.enter="next(6, ventaagrodet.precio)"
                ></vue-text-currency>
              </v-col>

              <v-spacer></v-spacer>
              <v-btn
                id="ventaagroInput7"
                outlined
                color="primary"
                fab
                small
                :disabled="readonly"
                @click="addTable()"
              >
                <v-icon>add</v-icon>
              </v-btn>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="ventaagro.ventaagrodet"
                  :items-per-page="100"
                  hide-default-footer
                >
                  <template v-slot:item="props">
                    <tr>
                      <td class="text-xs-letf">
                        {{ props.item.idstock.iditem.descitem }}
                      </td>
                      <td class="text-xs-letf">
                        {{ props.item.idstock.iddeposito.desdeposito }}
                      </td>
                      <td class="text-xs-letf">
                        {{ currencyFormat(props.item.precio) }}
                      </td>
                      <td class="text-xs-letf">
                        {{ currencyFormat(props.item.preciokg) }}
                      </td>
                      <td class="text-xs-letf">
                        {{ currencyFormat(props.item.cantidad) }}
                      </td>
                      <td class="text-end">
                        {{ currencyFormat(props.item.total) }}
                      </td>
                      <td class="text-end">
                        <v-icon
                          :disabled="readonly"
                          class="mr-2"
                          @click="editarDetalle(props.item)"
                          >edit</v-icon
                        >
                        <v-icon
                          :disabled="readonly"
                          small
                          @click="eliminarDetalle(props.item)"
                          >delete</v-icon
                        >
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-form>
          <v-divider class="mb-2"></v-divider>
          <v-row dense no-gutters>
            <v-spacer></v-spacer>
            <v-col cols="6" sm="3">
              <vue-text-currency
                label="Total Venta"
                :rules="false"
                :readonly="true"
                :length="4"
                :value="totalEgreso"
              ></vue-text-currency>
            </v-col>
            <v-col cols="12">
              <vue-text-field
                label="Comentario"
                :rules="false"
                :readonly="readonly"
                v-model="ventaagro.comentario"
              ></vue-text-field>
            </v-col>
            <v-btn color="red" text @click="cerrar()">
              <v-icon>clear</v-icon>
            </v-btn>
            <v-spacer></v-spacer>

            <v-btn
              :disabled="readonly"
              color="primary"
              text
              @click="guardarEgreso()"
            >
              <v-icon>done</v-icon>
            </v-btn>
          </v-row>
        </v-container>
        <c-overlay :value="Vget_ventaagro_save_load"></c-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { current_date } from "@/services/util/date.service";
import { focus } from "@/services/focus.service";
import { mensaje } from "@/services/notify.service";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { currency } from "@/services/util/number.service";
import AutocompleteDepositoStock from "@/views/Item/Deposito/autocompleteStock";
import FormCaja from "@/views/Apertura/Caja/Create";
import FormCuentaBanco from "@/views/Apertura/Cuentabanco/Create";
import { CAJA, CUENTA_BANCO } from "@/constants/forms";
import { PUEDE_AGREGAR } from "@/constants/permissions";

export default {
  components: {
    FormCaja,
    FormCuentaBanco,
    AutocompleteDepositoStock,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    datos: {
      type: [Object, Array],
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    $route() {
      return this.cerrar();
    },
  },

  computed: {
    ...mapGetters("ventaagro", ["Vget_ventaagro_save_load"]),
    ...mapGetters("caja", ["Vget_caja_cajacuenta", "Vget_caja_load"]),
    ...mapGetters("campana", ["Vget_campana_data", "Vget_campana_load"]),

    caja_permission() {
      return getPermission(CAJA).permiso[PUEDE_AGREGAR];
    },
    cuentabanco_permission() {
      return getPermission(CUENTA_BANCO).permiso[PUEDE_AGREGAR];
    },

    totalEgreso() {
      let total = 0;
      this.ventaagro.ventaagrodet.forEach((x) => {
        total = (Number(total) + Number(x.total)).toFixed(4);
      });
      return total;
    },
    isDetail() {
      return this.ventaagro.ventaagrodet.length > 0 ? true : false;
    },
  },
  mounted() {
    this.next(0, "-");
    this.Vact_caja_cajacuenta();
    if (this.datos != null) {
      this.asignarDatos(this.datos);
      this.operacion = false;
    }
    if (this.readonly == true) {
      this.datos.ventaagrodet.forEach((x) => {
        if (x.ensilaje == true) {
          this.ventaagrodet.ensilaje = 2
        } else if (x.ensilaje == null) {
          this.ventaagrodet.ensilaje = 1
        } else {
          this.ventaagrodet.ensilaje = 0
        }
      })
    }
  },
  methods: {
    ...mapActions("ventaagro", [
      "Vact_ventaagro_save",
      "Vact_ventaagro_data",
      "Vact_ventaagro_update",
      "Vact_ventaagro_data_byId",
    ]),
    ...mapActions("caja", ["Vact_caja_cajacuenta"]),
    semillaChange(val, estate, ret) {
      if (val == estate[0]) {
        return ret[0]
      } else if (val == estate[1]) {
        return ret[1]
      } else {
        return ret[2]
      }
    },
    toCurrency(value) {
      return currency(value);
    },
    asignarDatos(val) {
      val.ventaagrodet.forEach((x) => {
        x.preciokg = Number(x.precio) / 1000;
        x.total = x.preciokg * Number(x.cantidad);
      });
      this.ventaagro = JSON.parse(JSON.stringify(val));
    },
    setSelection(id) {
      setTimeout(() => {
        let input = document.getElementById(id);
        input.focus();
        input.setSelectionRange(0, 10);
      }, 200);
    },
    currencyFormat(value) {
      return new Intl.NumberFormat({ maximumSignificantDigits: 3 }).format(
        value
      );
    },
    async next(id, data) {
      if (data === "" || data <= 0) return null;
      focus.nextidName("ventaagroInput", id, data);
    },
    editarDetalle(val) {
      const index = this.ventaagro.ventaagrodet.indexOf(val);
      this.ventaagro.ventaagrodet.splice(index, 1);
      this.ventaagrodet = val;
      this.ventaagrodet.ensilaje = this.semillaChange(val.ensilaje, [false, null], [0, 1, 2])
    },
    eliminarDetalle(val) {
      const index = this.ventaagro.ventaagrodet.indexOf(val);
      this.ventaagro.ventaagrodet.splice(index, 1);
    },

    limpiarCampos() {
      this.ventaagro = JSON.parse(JSON.stringify(this.default));
      this.ventaagrodet = JSON.parse(JSON.stringify(this.ventaagrodet_default));
      this.$refs.formdetalle.resetValidation();
    },

    addTable() {
      if (!this.$refs.formdetalle.validate()) return null;
      this.ventaagrodet.ensilaje = this.semillaChange(this.ventaagrodet.ensilaje, [0, 1], [false, null, true])
      this.ventaagrodet.preciokg = Number(this.ventaagrodet.precio) / 1000;
      this.ventaagrodet.total =
        this.ventaagrodet.preciokg * Number(this.ventaagrodet.cantidad);
      this.ventaagro.ventaagrodet.push(this.ventaagrodet);
      this.ventaagrodet = JSON.parse(JSON.stringify(this.ventaagrodet_default));
      this.$refs.formdetalle.resetValidation();
      setTimeout(() => this.next(3, "-"), 200);
    },
    async guardarEgreso() {
      if (
        !this.$refs.form.validate() ||
        this.ventaagro.ventaagrodet.length === 0
      )
        return this.$notify(mensaje.completar());
      let a;
      if (this.operacion) {
        a = await this.Vact_ventaagro_save(this.ventaagro);
      } else {
        let id = this.ventaagro.idventaagro;
        a = await this.Vact_ventaagro_update({ id, data: this.ventaagro });
      }
      if (!a.estado) {
        return a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.operacion = true;
      this.$notify(a.info);
      this.$refs.form.resetValidation();
      this.limpiarCampos();
      this.next(0, "-");
    },
    cerrar() {
      this.$emit("input", false);
      this.$emit("datos", null);
      this.$emit("readonly", false);
      this.Vact_ventaagro_data();
      this.limpiarCampos();
    },
  },

  data() {
    return {
      search: "",
      operacion: true,
      formularios: {
        caja: false,
        cuentabanco: false,
      },
      ventaagro: {
        idventaagro: 0,
        fecha: current_date(),
        idcampana: {
          idcampana: "",
        },
        idcajacuentabanco: {
          idcajacuentabanco: "",
        },
        idcontacto: {
          idcontacto: "",
        },
        comentario: "",
        estado: true,
        ventaagrodet: [],
        venta: false,
      },
      default: {
        idventaagro: 0,
        fecha: current_date(),
        idcampana: {
          idcampana: "",
        },
        idcajacuentabanco: {
          idcajacuentabanco: "",
        },
        idcontacto: {
          idcontacto: "",
        },
        comentario: "",
        estado: true,
        ventaagrodet: [],
        venta: false,
      },
      ventaagrodet: {
        ensilaje: false,
        idstock: {
          idstock: "",
          iditem: "",
          iddeposito: "",
        },
        cantidad: "",
        precio: "",
      },
      ventaagrodet_default: {
        ensilaje: false,
        idstock: {
          idstock: "",
          iditem: "",
          iddeposito: "",
        },
        cantidad: "",
        precio: "",
      },

      headers: [
        {
          text: "Item",
          sortable: false,
        },
        {
          text: "Deposito",
          sortable: false,
        },
        { text: "Precio Tn", sortable: false },
        { text: "Precio Kg", sortable: false },
        { text: "Cantidad Kg", sortable: false },
        { text: "SubTotal", sortable: false, align: "right" },
        { text: "Accion", sortable: false, align: "right" },
      ],
    };
  },
};
</script>
