
export default {

  motivotraslado_create: (state) => {
    return state.motivotraslado_create
  },
  motivotraslado_update: (state) => {
    return state.motivotraslado_update
  },
  motivotraslado_reload: (state) => {
    return state.motivotraslado_reload
  },
  motivotraslado_show: (state) => {
    return state.motivotraslado_show
  },
}
