<template>
  <c-dialog-confirmation
    :value="value"
    @cancel="close()"
    @done="save()"
    :loading="isLoading"
    >Desea eliminar la cotizacion <strong>{{ props.fecha }}</strong
    >?</c-dialog-confirmation
  >
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    value: Boolean,
    props: Object,
  },
  methods: {
    ...mapActions("cotizacion", ["Vact_cotizacion_del"]),

    async save() {
      this.isLoading = true;
      const id = this.props.idcotizacion;
      const a = await this.Vact_cotizacion_del(id);
      this.isLoading = false;
      if (!a.success) return null;
      this.close();
    },
    close() {
       this.$emit('datos',null);
      this.$emit("input", false);
    },
  },
  data: () => ({
    isLoading: false,
  }),
};
</script>

<style>
</style>