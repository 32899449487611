<template>
    <v-dialog persistent max-width="700" :value="value">
        <v-card>
            <v-toolbar flat>
                <v-toolbar-title>{{ title }}</v-toolbar-title>
            </v-toolbar>
            <v-container>
                <v-form ref="form" @submit.prevent="">
                    <v-row dense>
                        <v-col cols="12">
                            <c-text-field label="Nombre" autocomplete="off" :rules="rules" ref="cuenta1"
                                v-model="data.nombre" outlined dense placeholder="Ingrese nombre"
                                @keyup.native.enter="next(1, data.nombre)"></c-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-autocomplete :items="getTipo" :loading="isLoadingTipo" item-text="nombre" return-object
                                autocomplete="off" outlined dense label="Tipo" ref="cuenta2"
                                v-model="data.idfinanza_tipo"
                                @keyup.native.enter="next(2, data.idfinanza_tipo)"></v-autocomplete>
                        </v-col>
                        <v-col cols="2">
                            <v-btn class="mx-2" fab dark small :color="`${data.activo ? 'green' : 'red'}`"
                                @click="data.activo = !data.activo">
                                <v-icon dark>
                                    {{ `${data.activo ? 'add' : 'remove'}` }}
                                </v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="4">
                            <v-autocomplete :items="Vget_moneda_data" :loading="Vget_moneda_load" item-text="descmoneda"
                                return-object autocomplete="off" outlined dense label="Moneda" ref="cuenta3"
                                v-model="data.idmoneda" @keyup.native.enter="next(3, data.idmoneda)"></v-autocomplete>
                        </v-col>
                        <v-col cols="6">
                            <c-text-currency dense outlined ref="cuenta4" :rules="rules" v-model="data.monto"
                                label="Balance Inicial" @keyup.native.enter="next(3, data.monto)"></c-text-currency>
                        </v-col>
                    </v-row>
                </v-form>
            </v-container>
            <v-divider></v-divider>
            <v-card-actions>
                <c-btn-crud-close @click="cancel()"></c-btn-crud-close>
                <v-spacer></v-spacer>
                <c-btn-crud-done ref="cuenta5" @click="save()"></c-btn-crud-done>
            </v-card-actions>
            <c-overlay :value="isLoadingCuenta"></c-overlay>
        </v-card>
    </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        editable: {
            type: Boolean,
            default: false,
        },
        props: {
            type: Object,
        },
        "sync-input": {
            type: [String, Number],
            default: null,
        },
    },
    mounted() {
        if (this.editable) this.data = JSON.parse(JSON.stringify(this.props));
        this.Vact_moneda_data();
        this.fetchTipo();
        setTimeout(() => this.next(0, "-"), 20);
        if (this.syncInput != null) this.data.nombre = this.syncInput;
        this.sync = this.syncInput;
    },
    computed: {
        ...mapGetters("finanza", ["isLoadingCuenta", "isLoadingTipo", "getTipo"]),
        ...mapGetters("moneda", ["Vget_moneda_data", "Vget_moneda_load"]),
        title() {
            return this.editable ? "Modificar Cuenta" : "Registrar Cuenta";
        },
    },
    methods: {
        ...mapActions("finanza", ["setCuenta", "setCuentaUpdate", "fetchCuenta", "fetchTipo"]),
        ...mapActions("moneda", ["Vact_moneda_data"]),

        next(id, data) {
            if (data != "") {
                try {
                    this.$refs[`cuenta${id + 1}`].focus();
                } catch (error) {
                    this.$refs[`cuenta${id + 1}`].$el.focus();
                }
            }
        },
        cancel() {
            this.$emit("input", false);
        },
        async save() {
            if (!this.$refs.form.validate()) return null;
            const response = this.editable
                ? await this.setCuentaUpdate({
                    data: this.data,
                    id: this.data.idfinanza_cuenta,
                })
                : await this.setCuenta(this.data);
            if (response.success) {
                if (this.sync != null) {
                    this.cancel();
                    this.fetchCuenta();
                    return this.$emit("sync-input", response.data.idfinanza_cuenta);
                }
                this.data = JSON.parse(JSON.stringify(this.default));
                this.$refs.form.resetValidation();
                this.fetchCuenta();
                this.next(0, '-')
                if (this.editable) this.cancel();
            }
        }
    },
    data: () => ({
        rules: [(v) => !!v || "Este campo es obligatorio"],
        data: {
            idfinanza_cuenta: 0,
            nombre: "",
            idfinanza_tipo: "",
            activo: true,
            idmoneda: "",
            monto: 0,
        },
        default: {
            idfinanza_cuenta: 0,
            nombre: "",
            idfinanza_tipo: "",
            activo: true,
            idmoneda: "",
            monto: 0,
        }
    })
}
</script>