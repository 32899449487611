<template>
  <div>
    <v-toolbar flat dense>
      <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
      <v-divider></v-divider>
      <v-btn
        v-if="permission.can_add || permission.can_update"
        :disabled="isEditable"
        fab
        text
        small
        color="deep-purple accent-4"
        class="ml-2"
        outlined
        @click="isEditable = !isEditable"
      >
        <v-icon>edit</v-icon></v-btn
      >
    </v-toolbar>
    <v-form ref="form">
      <span class="title ml-2">Comision de transporte</span>
      <v-row dense class="mx-1">
        <v-col cols="12">
          <c-text-currency
            :disabled="!isEditable"
            v-model="data.comision"
            label="Comision"
          ></c-text-currency>
        </v-col>
      </v-row>
      <v-row dense class="mx-1">
        <v-checkbox
          class="my-2"
          v-model="data.reflejar_rrhh"
          label="Reflejar adelantos y liquidaciones en gastos de maquinaria"
        ></v-checkbox>
      </v-row>
    </v-form>
    <v-card-actions v-if="isEditable">
      <v-divider></v-divider>
      <v-btn
        color="red"
        class="ml-2"
        text
        @click="(isEditable = false), fetchConfigRrhh()"
      >
        Cancelar
      </v-btn>
      <v-btn color="deep-purple accent-4" class="ml-2" text @click="save()">
        Guardar Cambios
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { CONFIG_MAQUINARIA } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";
export default {
  data: () => ({
    isEditable: false,
    data: {
      idmaquinaria_configuracion: 0,
      comision: 0,
      reflejar_rrhh: false,
    },
    permission: {
      can_add: false,
      can_view: false,
      can_update: false,
      can_delete: false,
    },
  }),
  mounted() {
    this.fetchMaquinariaConfiguracion();
  },
  created() {
    const permission = getPermission(CONFIG_MAQUINARIA).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  watch: {
    getMaquinariaConfiguracion(val) {
      if (val.length >= 1) {
        this.data = JSON.parse(JSON.stringify(val))[0];
      }
    },
  },
  computed: {
    ...mapGetters("maquinaria_configuracion", ["getMaquinariaConfiguracion"]),
  },
  methods: {
    ...mapActions("maquinaria_configuracion", [
      "fetchMaquinariaConfiguracion",
      "setMaquinariaConfiguracion",
    ]),
    async save() {
      if (!this.$refs.form.validate()) return null;
      const response = await this.setMaquinariaConfiguracion(
        this.data,
      );
      if (response.success) {
        this.isEditable = false;
      }
    },
  },
};
</script>