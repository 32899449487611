import { db } from "@/services/db.service";
export default {

    async Vact_formulario_data({commit}){
        commit('formulario_load',true);
        try {
            const data = await db.get('/apimipermiso/');
            commit('formulario_data',data);
            commit('formulario_load',false);
            return true
        } catch (e) {
            commit('formulario_load',false);
            return false
        } 
    }
}
