<template>
  <v-autocomplete
    :id="id"
    label="Unidad Medida"
    :items="u_medida"
    :loading="Vget_unidadmedida_load"
    dense
    :menu-props="{ auto: true }"
    :rules="validar"
    :readonly="readonly"
    ref="input"
    return-object
    item-text="descunidadmedida"
    item-value="idunidadmedida"
    :value="value"
    outlined
    @focus="$emit('focus')"
    @input="$emit('input', $event)"
  ></v-autocomplete>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: {
    id: String,
    readonly: Boolean,
    value: [Number, String,Object],
  },
  data: () => ({
    validar: [(v) => !!v.idunidadmedida || "Seleccion una Unidad"],
    u_medida: []
  }),
  watch: {
    value() {
      this.$emit("change");
    },
    Vget_unidadmedida_data_magnitud(val) {
      this.u_medida = val
    },
    Vget_unidadmedida_data(val) {
      this.u_medida = val
    }
  },
  computed: {
    ...mapGetters("unidadmedida", [
      "Vget_unidadmedida_data_magnitud",
      "Vget_unidadmedida_data",
      "Vget_unidadmedida_load",
    ]),
  },

  methods: {
    focus() {
      this.$refs.input.focus();
    },
    isMenuActive() {
      this.$refs.input.isMenuActive = false;
    },
  },
};
</script>
