<template>
  <div>
    <v-dialog
      :value="value"
      max-width="900px"
      persistent
      @keydown.esc="cancelar()"
    >
      <v-card>
        <v-toolbar flat dense>
          <v-toolbar-title>Detalle de Animal</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-tabs v-model="tab" right>
              <v-tab href="#tab-1">Datos</v-tab>
              <v-tab
                href="#tab-2"
                @click="cargar_grafico()"
                v-if="data.idsexo.idsexo === 2"
                >Rendimiento</v-tab
              >
            </v-tabs>
          </v-toolbar-items>
        </v-toolbar>

        <v-tabs-items v-model="tab">
          <v-tab-item value="tab-1">
            <v-card-text>
              <v-row row dense>
                <v-col cols="12" sm="6" md="4">
                  <c-text-field
                    dense
                    readonly
                    outlined
                    label="Nombre del Animal"
                    v-model="data.nombanimal"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <c-text-field
                    dense
                    readonly
                    outlined
                    label="Rp del animal"
                    v-model="data.rpanimal"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <c-text-date
                    dense
                    readonly
                    outlined
                    label="Fecha de Nacimiento"
                    v-model="data.fechanimal"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <c-text-field
                    dense
                    readonly
                    outlined
                    label="Estado del animal"
                    v-model="data.idestadoanimal.descestadoanimal"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <c-text-field
                    dense
                    readonly
                    outlined
                    label="Raza del animal"
                    v-model="data.idraza.descraza"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <c-text-field
                    dense
                    readonly
                    outlined
                    label="Lote"
                    v-model="data.idlote.desclote"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <c-text-field
                    dense
                    readonly
                    outlined
                    label="Deposito"
                    v-model="data.iddeposito.desdeposito"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <c-text-field
                    dense
                    readonly
                    outlined
                    label="Item"
                    v-model="data.iditem.descitem"
                  />
                </v-col>
                <v-col
                  :cols="isMacho ? 9 : 12"
                  :sm="isMacho ? 3 : 6"
                  :md="isMacho ? 2 : 4"
                >
                  <c-text-field
                    dense
                    readonly
                    outlined
                    label="Sexo"
                    v-model="data.idsexo.descsexocria"
                  />
                </v-col>
                <v-col cols="3" md="2" class="mt-n3" v-if="isMacho">
                  <v-checkbox
                    disabled
                    v-model="data.issemen"
                    label="Semen?"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12" sm="6" md="12">
                  <c-text-currency
                    dense
                    readonly
                    outlined
                    label="Monto"
                    v-model="data.montanimal"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-tab-item>
          <v-tab-item value="tab-2">
            <v-card-text>
              <div class="chart-wrapper">
                <v-chart :options="option" autoresize />
              </div>
            </v-card-text>
          </v-tab-item>
        </v-tabs-items>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="cancelar">
            <v-icon>clear</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { db } from "@/services/db.service";
import { getblob } from "@/services/api/api.service";

import ECharts from "vue-echarts/components/ECharts";
export default {
  props: {
    value: Boolean,
    datos: {
      type: [Object, Array],
      default: null,
    },
  },
  components: {
    "v-chart": ECharts,
  },
  data() {
    return {
      tab: null,
      data: {
        fechanimal: "",
        nombanimal: "",
        rpanimal: "",
        idestadoanimal: {
          idestadoanimal: "",
          descestadoanimal: "",
        },
        idprocedencia: {
          idprocedencia: "",
          descprocedencia: "",
        },
        idraza: {
          idraza: "",
          descraza: "",
        },
        idlote: {
          idlote: "",
          desclote: "",
        },
        iddeposito: {
          iddeposito: "",
          desdeposito: "",
        },
        iditem: {
          iditem: "",
          descitem: "",
        },
        idsexo: {
          idsexo: "",
          descsexocria: "",
        },
        montanimal: "",
      },
      option: {
        tooltip: {
          trigger: "axis",
        },
        toolbox: {
          iconStyle: {
            borderColor: "#eee",
          },
          show: true,
          feature: {
            dataZoom: {
              title: {
                zoom: "Zoom",
                back: "Atras",
              },
              yAxisIndex: "none",
            },
            restore: {
              title: "Restaurar",
            },
            dataView: {
              readOnly: true,
              lang: ["Lista", "Regresar"],
              title: "Listar",
              optionToContent: function (opt) {
                var axisData = opt.xAxis[0].data;
                var series = opt.series;
                var table =
                  '<table style="width:100%;text-align:center"><tbody><tr>' +
                  "<td>Fecha:</td>" +
                  "<td>" +
                  series[0].name +
                  "</td>" +
                  "<td>";
                for (var i = 0, l = axisData.length; i < l; i++) {
                  table +=
                    "<tr>" +
                    "<td>" +
                    axisData[i] +
                    "</td>" +
                    "<td>" +
                    series[0].data[i] +
                    "</td>" +
                    "<td>";
                }
                table += "</tbody></table>";
                return table;
              },
            },
            magicType: {
              type: ["line", "bar"],
              title: {
                line: "Linea",
                bar: "Bar",
              },
            },

            saveAsImage: {
              title: "Descargar",
            },
          },
        },
        xAxis: {
          type: "category",
          axisLabel: {
            color: "#eee",
          },
          boundaryGap: false,
          data: [],
        },
        yAxis: {
          type: "value",
          axisLabel: {
            color: "#eee",
          },
        },
        series: [
          {
            name: "Datos",
            type: "line",
            data: [],
            markPoint: {
              data: [
                { type: "max", name: "最大值" },
                { type: "min", name: "最小值" },
              ],
            },
            markLine: {
              data: [{ type: "average", name: "平均值" }],
            },
          },
        ],
      },
    };
  },
  computed: {
    isMacho() {
      return this.data.idsexo.idsexo === 1 ? true : false;
    },
    darkMode() {
      return this.$vuetify.theme.dark;
    }
  },
  watch: {
    $route(to, from) {
      if (from.path === "/animal") return this.cancelar();
    },
    darkMode(val) {
      this.option.toolbox.iconStyle.borderColor = val ? '#eee' : '#000'
      this.option.xAxis.axisLabel.color = val ? '#eee' : '#000'
      this.option.yAxis.axisLabel.color = val ? '#eee' : '#000'
    }
  },
  created() {
    this.show(this.datos);
  },
  mounted() {
    this.option.toolbox.iconStyle.borderColor = this.darkMode ? '#eee' : '#000'
    this.option.xAxis.axisLabel.color = this.darkMode ? '#eee' : '#000'
    this.option.yAxis.axisLabel.color = this.darkMode ? '#eee' : '#000'
  },
  methods: {
    cancelar() {
      //this.data = JSON.parse(JSON.stringify(this.default));
      this.$emit("input", false);
      this.$emit("datos", null);
    },
    async generarpdf() {
      await getblob("/apianimalreport/", { idanimal: this.data.idanimal });
    },
    show(val) {
      this.data = JSON.parse(JSON.stringify(val));
    },
    async cargar_grafico() {
      try {
        const response = await db.get(`/apianimalprod/${this.data.idanimal}`);
        this.option.series[0].data = response.value;
        this.option.xAxis.data = response.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
<style scoped>
.chart-wrapper {
  width: 100%;
  height: 400px;
}
.echarts {
  width: 100%;
  height: 400px;
}
</style>
