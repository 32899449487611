
export default {
  getUsersActive: (state) => {
    return state.active
  },
  getUsersInactive: (state) => {
    return state.inactive
  },
  getUserWithPermissions: (state) => {
    return state.user_with_permissions
  },
  isUsersLoading: (state) => {
    return state.isLoading
  }
}
