<template>
  <div>
    <v-row dense>
      <c-overlay :value="response.isLoading" absolute></c-overlay>
      <v-col cols="12" sm="6" md="3" v-for="(item, i) of resumen" :key="i">
        <v-progress-linear
          height="2"
          color="primary"
          rounded
          value="100"
        ></v-progress-linear>
        <v-card elevation="1" tile min-height="380">
          <v-toolbar flat dense>
            <div class="body-1 flex font-weight-black text-center">
              {{ item.title }}
            </div>
          </v-toolbar>

          <v-container style="max-height: 355px" class="mt-1 overflow-y-auto">
            <v-data-table
              class="mt-n5"
              hide-default-footer
              dense
              :items-per-page="10000"
              :headers="headers"
              :items="item.items"
              :mobile-breakpoint="0"
              :item-class="itemRowBackground"
            >
              <template v-slot:[`item.value`]="props">
                <div class="text-end mr-1 font-weight-black">
                  {{ toCurrency(props.item.value) }}
                </div>
              </template>
              <template slot="body.append" v-if="item.total">
                <tr>
                  <td>
                    <div class="font-weight-black">Total</div>
                  </td>
                  <td>
                    <div class=" font-weight-black text-end mr-1">
                      {{ toCurrency(item.total) }}
                    </div>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { db } from "@/services/db.service";
import { mensaje } from "@/services/notify.service";
import { currency } from "@/services/util/number.service";
export default {
  props: {
    url: {
      type: String,
    },
    mode: {
      type: String,
      default: "simple",
    },
    vista: {
      type: Object,
      default() {
        return {
          categoria: true,
          gasto: true,
          general: true,
          hectarea: true,
          servicio: true,
          top: true,
          zona: true,
        };
      },
    },
  },
  data() {
    return {
      response: {
        data: [],
        isLoading: false,
      },
      headers: [
        {
          text: "Descripcion",
          value: "name",
          align: "start",
          sortable: false,
        },
        {
          text: "Valor",
          value: "value",
          align: "end",
          sortable: false,
        },
      ],
      resumen: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    toCurrency(value) {
      return currency(value);
    },
    itemRowBackground: function(item) {
      if (item.color) return item.color;
    },
    init() {
      if (this.mode === "simple") {
        this.ejecutarConsulta(this.url);
      }
    },
    async ejecutarConsulta(url) {
      this.response.isLoading = true;
      try {
        this.response.data = [];
        this.response.data = await db.get(url);
        this.generarResumenes();
      } catch (error) {
        this.response.data = [];
        this.$notify(mensaje.error(error.message));
      }
      this.response.isLoading = false;
    },
    generarResumenes() {
      this.resumen = [];
      if (this.vista.general) this.generarResumenGeneral();
      if (this.vista.gasto) this.generarResumenGasto();
      if (this.vista.hectarea) this.generarResumenHa();
      if (this.vista.zona) {
        this.generarResumenZona();
        this.generarResumenZonaUtilizada();
        this.generarResumenZonaSembrada();
      }
      if (this.vista.top) this.generarResumenRendimiento();
      if (this.vista.categoria) this.generarResumenCategoria();
      if (this.vista.servicio) this.generarResumenServicio();
      if (this.vista.general) this.generarItemsCanjeados();
      this.$emit("change", this.response);
    },
    generarResumenRendimiento() {
      this.resumen.push({
        title: "Top Produccion Kg x Ha",
        items: JSON.parse(
          JSON.stringify(this.response.data.general.top_rendimiento)
        ),
      });
    },

    generarResumenCategoria() {
      let total = 0;
      this.response.data.categoria.map((x) => {
        total = total + x.value;
      });
      this.resumen.push({
        title: "Resumen por Categoria",
        total: total,
        items: JSON.parse(JSON.stringify(this.response.data.categoria)),
      });
    },
    generarResumenServicio() {
      let total = 0;
      this.response.data.lista_servicio.map((x) => {
        total = total + x.value;
      });
      this.resumen.push({
        total: total,
        title: "Resumen por Servicio",
        items: JSON.parse(JSON.stringify(this.response.data.lista_servicio)),
      });
    },
    generarResumenZona() {
      let total = 0;
      this.response.data.zonas.map((x) => {
        total = total + x.value;
      });
      this.resumen.push({
        title: "Hectareas por Zona Total",
        total: total,
        items: JSON.parse(JSON.stringify(this.response.data.zonas)),
      });
    },
    generarResumenZonaSembrada() {
      let total = 0;
      this.response.data.sembradas.map((x) => {
        total = total + x.value;
      });
      this.resumen.push({
        title: "Hectareas por Zona Sembrada",
        total: total,
        items: JSON.parse(JSON.stringify(this.response.data.sembradas)),
      });
    },
    generarResumenZonaUtilizada() {
      let total = 0;
      this.response.data.utilizadas.map((x) => {
        total = total + x.value;
      });
      this.resumen.push({
        title: "Hectareas por Zona Trabajadas",
        total: total,
        items: JSON.parse(JSON.stringify(this.response.data.utilizadas)),
      });
    },
    generarResumenHa() {
      const resumen = this.response.data.general;
      this.resumen.push({
        title: "Resumen por Hectarea",
        items: [
          {
            name: "Servicio x Ha:",
            value: resumen.serviciohectarea,
          },
          {
            name: "Abono x Ha:",
            value: resumen.abonohectarea,
          },
          {
            name: "Pagos x Ha:",
            value: resumen.pagohectarea,
          },
          {
            name: "Fumigacion x Ha:",
            value: resumen.fumigacionhectarea,
          },
          {
            name: "Plantio x Ha:",
            value: resumen.plantiohectarea,
          },
          {
            name: "Cal y Yeso x Ha:",
            value: resumen.aplicacionhectarea,
          },
          {
            name: "Alquiler x Ha:",
            value: resumen.alquilerhectarea,
          },
          {
            name: "Gasto x Ha:",
            value: resumen.gastohectarea,
          },
        ],
      });
    },
    generarResumenGasto() {
      const resumen = this.response.data.general;
      this.resumen.push({
        total: resumen.totalgasto,
        title: "Resumen de Gastos",
        items: [
          {
            name: "Servicio",
            value: resumen.servicio,
          },
          {
            name: "Abono",
            value: resumen.abono,
          },
          {
            name: "Pagos",
            value: resumen.pago,
          },
          {
            name: "Fumigacion",
            value: resumen.fumigacion,
          },
          {
            name: "Plantio",
            value: resumen.plantio,
          },
          {
            name: "Cal y Yeso",
            value: resumen.aplicacion,
          },
          {
            name: "Alquiler",
            value: resumen.alquiler,
          },
        ],
      });
    },

    generarResumenGeneral() {
      const resumen = this.response.data.general;
      this.resumen.push({
        title: "Balance General",
        items: [
          {
            name: "Ingreso Neto x Ha:",
            value: resumen.ingresohectarea,
          },
          {
            name: "Ingreso por Canje:",
            value: this.response.data.canje_precio,
          },
          {
            name: "Egreso por Canje:",
            value: this.response.data.canje_egreso,
          },
          {
            name: "Ingreso Bruto:",
            value: resumen.totalcosecha,
          },
          {
            name: "Ingreso Ensilaje:",
            value: resumen.totalensilaje,
          },
          {
            name: "Promedio Venta de Granos:",
            value: resumen.prom_ven,
          },
          {
            name: "Promedio Venta de Ensilaje:",
            value: resumen.prom_ens,
          },
          {
            name: "Total Ingresos:",
            value: resumen.totalingreso,
          },
          {
            name: "Gastos:",
            value: resumen.totalgasto,
          },
        ],
        total: resumen.ingreso,
      });
      this.resumen.push({
        title: "Informacion General",
        items: [
          {
            name: "Hectareas Cosechadas:",
            value: resumen.hectarea,
          },
          {
            name: "Kg x Ha:",
            value: resumen.produccionhectarea,
          },

          {
            name: "Kg Producido:",
            value: resumen.produccion,
          },
          {
            name: "Kg pago Alquiler:",
            value: resumen.kg_palquiler,
          },
          {
            name: "Kg Canjeado:",
            value: this.response.data.canje_kg,
          },
          {
            name: "Kg Vendido:",
            value: resumen.vendidas,
          },
          {
            name: "Kg Preventa:",
            value: resumen.prevendidas,
          },
          {
            name: "Kg en Stock:",
            value: resumen.sobran,
          },
          {
            name: "Kg disponible para Venta:",
            value: resumen.kg_pventa,
            color:
              resumen.kg_pventa === 0
                ? ""
                : resumen.kg_pventa > 0
                ? "row-datatable-green"
                : "row-datatable-red",
          },
        ],
      });
      this.resumen.push({
        title: "Informacion Ensilaje",
        items: [
          {
            name: "Hectareas Ensiladas:",
            value: resumen.hectarea_e,
          },
          {
            name: "Kg x Ha:",
            value: resumen.produccionhectareae,
          },

          {
            name: "Kg Producido:",
            value: resumen.produccione,
          },
          {
            name: "Kg pago Alquiler:",
            value: resumen.kg_palquilere,
          },
          {
            name: "Kg Vendido:",
            value: resumen.vendidase,
          },
          {
            name: "Kg en Stock:",
            value: resumen.sobrane,
          }
        ],
      });
    },
    generarItemsCanjeados() {
      this.resumen.push({
        title: "Productos Canjeados",
        items: JSON.parse(JSON.stringify(this.response.data.canje)),
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0.1rem;
}
/* ::v-deep .v-data-table__wrapper > table > thead > tr > th {
  padding-left: 0.3rem;
  padding-right: 0.3rem;
} */
</style>
