<template>
  <v-overlay :z-index="3" :value="value">
    <v-dialog :value="value" max-width="900" persistent :fullscreen="mobile">
      <agro-siembra-create
        @input="$emit('input',$event)"
        :idcampana="idcampana"
        :idsiembra="idsiembra"
        :readonly="readonly"
        :propIdlote="$route.params.id"
        @resp="$emit('resp',$event)"
        mode="Dialog"
      />
    </v-dialog>
  </v-overlay>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    idcampana: {
      type: Object,
      required: true
    },
    idsiembra: {
      type: Number,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  computed:{
    mobile(){
      return this.$vuetify.breakpoint.mobile
    }
  }
};
</script>

<style>
</style>