<template>
  <div v-if="sensorList.length > 0">
    <v-card-actions class="my-n2">
      <div class="font-weight-thin">Sensores</div>
      <v-divider></v-divider>
    </v-card-actions>
    <v-row dense>
      <v-col cols="12" sm="" v-for="(item, i) of sensorList" :key="i">
        <v-card class="ma-1 rounded-xl ">
          <v-card-text class="mb-n5" @click="$router.push('/agricultura/sensor/'+item.idambient_weather)">
            <div class="d-flex flex-column">
              <div class="title font-weight-medium">
                Sensor
                <span
                  :class="`caption ${item.other ? 'red--text' : 'green--text'}`"
                >
                  {{ item.other ? "(Public)" : "(Propio)" }}
                </span>
              </div>
              <div
                style="height: 1.8em; overflow: hidden;"
                class="body-1 font-weight-light mr-2"
              >
                {{ item.descripcion }}
              </div>
            </div>
          </v-card-text>
          <v-container> </v-container>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  mounted() {
    this.fetchSensor();
  },
  computed: {
    ...mapGetters("sensor", ["getSensor", "isLoading"]),
    sensorList(){
      return this.getSensor.filter(x=>x.favorite);
    }
  },
  methods: {
    ...mapActions("sensor", ["fetchSensor"]),
  },
};
</script>

<style></style>
