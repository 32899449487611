export default {

  gymasistencia_data: [],
  gymasistencia_data_by: [],
  gymasistencia_data_hoy: [],
  gymasistencia_update: null,
  gymasistencia_load: false,
  gymasistencia_save_load: false

}
