export default {


  alimentacion_data(state, request) {
    state.alimentacion_data = request;
  }, 
  alimentacion_load(state, request) {
    state.alimentacion_load = request;
  }, 
  alimentacion_save_load(state, request) {
    state.alimentacion_save_load = request;
  }, 
}