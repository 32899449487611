<template>
  <div>
    <v-dialog
      :value="value"
      max-width="800px"
      persistent
      @keydown.esc="cancelar()"
    >
      <v-card>
        <v-form ref="form" lazy-validation>
          <v-toolbar flat dense>
            <v-toolbar-title>{{ "Agregar Pajuela" }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" sm="3">
                <c-text-date
                  dense
                  outlined
                  label="Fecha"
                  ref="pajuela1"
                  v-model="data.fecha"
                  @keyup.native.enter="next(1, data.fecha)"
                ></c-text-date>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" sm="6">
                <AutocompleteAnimal
                  action="fetchAnimalSemen"
                  getter="getSemen"
                  ref="pajuela2"
                  label="Seleccione Semen"
                  v-model="data.idanimal.idanimal"
                  @keyup.native.enter="next(2, data.idanimal.idanimal)"
                />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="3">
                <AutocompleteSexo
                  :rules="[]"
                  v-model="data.idsexo.idsexo"
                  ref="pajuela3"
                  @keyup.native.enter="next(3, '-')"
                />
              </v-col>
              <v-col cols="12" sm="3">
                <AutocompleteTermo
                  v-model="data.idtermosemen.idtermosemen"
                  ref="pajuela4"
                  @keyup.native.enter="next(4, data.idtermosemen.idtermosemen)"
                />
              </v-col>
              <v-col cols="12" sm="3">
                <c-text-currency
                  outlined
                  dense
                  label="Precio"
                  ref="pajuela5"
                  v-model="data.precio"
                  @keyup.native.enter="next(5, data.precio)"
                ></c-text-currency>
              </v-col>
              <v-col cols="12" sm="3">
                <c-text-currency
                  outlined
                  dense
                  label="Cantidad"
                  ref="pajuela6"
                  v-model="data.cantidad"
                  @keyup.native.enter="next(6, data.cantidad)"
                ></c-text-currency>
              </v-col>
              <v-col cols="12" sm="12">
                <c-text-field
                  outlined
                  dense
                  label="Comentario"
                  ref="pajuela7"
                  v-model="data.comentario"
                  :rules="[]"
                  @keyup.native.enter="next(7, data.comentario)"
                ></c-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <c-btn-crud-close @click="cancelar()"></c-btn-crud-close>
            <v-spacer></v-spacer>
            <c-btn-crud-done ref="pajuela8" @click="guardar()"></c-btn-crud-done>
          </v-card-actions>
        </v-form>
        <c-overlay :value="isLoading"></c-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { current_date } from "@/services/util/date.service";
import AutocompleteAnimal from "../Animal/AutocompleteAnimal";
import AutocompleteSexo from "../SexoCria/Autocomplete";
import AutocompleteTermo from "../TermoSemen/Autocomplete";
export default {
  components: {
    AutocompleteAnimal,
    AutocompleteSexo,
    AutocompleteTermo,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    datos: {
      type: [Object, Array],
      default: null,
    },
    "sync-input": {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      data: {
        fecha: current_date(),
        idanimal: {
          idanimal: null,
        },
        idsexo: {
          idsexo: null,
        },
        idtermosemen: {
          idtermosemen: null,
        },
        cantidad: null,
        precio: null,
        comentario: "",
      },
      default: {
        fecha: current_date(),
        idanimal: {
          idanimal: null,
        },
        idsexo: {
          idsexo: null,
        },
        idtermosemen: {
          idtermosemen: null,
        },
        cantidad: null,
        precio: null,
        comentario: "",
      },
      isEdit: false,
      sync: null
    };
  },
  watch: {
    $route() {
      return this.cancelar();
    },
  },
  computed: {
    ...mapGetters("pajuela", ["isLoading"]),
  },
  created() {
    this.nuevo(this.datos);
  },
  mounted() {
    setTimeout(() => this.$refs.input1.focus(), 500);
    if (this.syncInput != null) {
      this.data.descpajuela = this.syncInput;
      this.sync = this.syncInput;
    }
  },
  methods: {
    ...mapActions("pajuela", [
      "fetchPajuela",
      "setPajuela",
      "setPajuelaUpdate",
    ]),
    next(id, data) {
      if (data != "")
        try {
          this.$refs[`pajuela${id + 1}`].focus();
        } catch (error) {
          this.$refs[`pajuela${id + 1}`].$el.focus();
        }
    },

    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$emit("input", false);
      this.$emit("datos", null);
      this.fetchPajuela();
      this.operacion = true;
    },

    async guardar() {
      if (!this.$refs.form.validate()) return null;
      const id = this.data.idpajuelas;
      const response = this.isEdit
        ? await this.setPajuelaUpdate({ id, data: this.data })
        : await this.setPajuela(this.data);
      if (response.success) {
        if (this.sync != null) {
          this.$emit("sync-input", response.data.id);
          this.$emit("next-focus");
          return this.cancelar();
        }
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();
        this.fetchPajuela();
        if (this.isEdit) return this.cancelar();
      }
    },
    nuevo(val) {
      if (val !== null) {
        this.data = JSON.parse(JSON.stringify(val));
        this.isEdit = true;
      }
    },
  },
};
</script>
