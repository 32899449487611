<template>
    <v-card>
        <v-card-title v-if="showtitle">
            <div class="text-center">{{ title }}</div>
            <v-divider class="mx-1" insert vertical></v-divider>
        </v-card-title>
        <v-card-actions>
            <v-btn fab text small @click="mover(-1)">
                <v-icon small>chevron_left</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            {{ `${month_text} - ${year}` }}
            <v-spacer></v-spacer>
            <v-btn fab text small @click="mover(1)">
                <v-icon small>chevron_right</v-icon>
            </v-btn>
        </v-card-actions>
        <v-card-text>
            <v-progress-linear v-if="load" indeterminated></v-progress-linear>
            <div>
                <v-chart :options="options" autoresize />
            </div>
            <div>
                <v-data-table :headers="headers" :items="items" :loading="load">
                    <template v-slot:[`item.monto`]="props">
                        {{ toCurrency(props.item.monto) }}
                    </template>
                </v-data-table>
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
import { db } from "@/services/db.service";
import ECharts from "vue-echarts/components/ECharts";
import { months } from "@/services/util/date.service";
import { currency } from "@/services/util/number.service";
export default {
    components: {
        "v-chart": ECharts
    },
    props: {
        showtitle: Boolean,
        title: String,
        api: String,
        loadDoughnut: Boolean,
    },
    mounted() {
        this.month = this.fecha.getMonth() + 1
        this.year = this.fecha.getFullYear()
        this.options.legend.textStyle.color = this.darkMode ? "#eee" : "#000"
        this.month_text = months[this.month - 1]
        this.obtenerDatos()
    },
    watch: {
        darkMode() {
            this.changeDarkMode()
        },
        loadDoughnut() {
            this.obtenerDatos();
        }
    },
    computed: {
        darkMode() {
            return this.$vuetify.theme.dark;
        },
    },
    methods: {
        changeDarkMode() {
            this.options.legend.textStyle.color = this.darkMode ? "#eee" : "#000"
        },
        mover(op) {
            let nuevo = this.month + op
            if (nuevo < 1) {
                this.month = 12
                this.year--
            } else if (nuevo > 12) {
                this.month = 1
                this.year++
            } else {
                this.month = nuevo
            }
            this.month_text = months[this.month - 1]
            this.obtenerDatos()
        },
        async obtenerDatos() {
            this.load = true
            try {
                const response = await db.get(`${this.api}${this.month}/${this.year}/`)
                this.options.series[0].data = response.data;
                this.options.series[0].name = response.tipo;
                let legend = [];
                response.data.forEach((x) => {
                    legend.push(x.name);
                });
                this.items = response.items;
                this.options.legend.data = legend;
            } catch (error) {
                console.log(error)
            }
            this.load = false;
        },
        toCurrency(value) {
            return currency(value)
        },
    },
    data: () => ({
        load: false,
        fecha: new Date(),
        month: null,
        month_text: "",
        year: null,
        headers: [
            {
                text: "Movimiento", align: "left", value: "movimiento"
            },
            {
                text: "Fecha", align: "left", value: "fecha"
            },
            {
                text: "Monto", align: "right", value: "monto"
            }
        ],
        items: [],
        options: {
            title: {
                text: 'Title',
                subtext: 'Gastos',
                left: 'center'
            },
            tooltip: {
            },
            legend: {
                type: 'scroll',
                orient: 'vertical',
                left: 'left',
                data: ['Pasaje', 'Entretenimiento', 'Combustible', 'Comer fuera', 'Supermercado'],
                textStyle: {
                    color: "#000",
                },
            },
            series: [
                {
                    name: 'Mayo',
                    type: 'pie',
                    radius: ['40%', "70%"],
                    center: ['50%', '60%'],
                    data: [
                        { value: 335, name: 'Pasaje' },
                        { value: 310, name: 'Entretenimiento' },
                        { value: 234, name: 'Combustible' },
                        { value: 135, name: 'Comer fuera' },
                        { value: 1548, name: 'Supermercado' }
                    ],
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        },
                    },
                    textStyle: {
                        color: 'transparent'
                    }
                }
            ]
        }
    })
}
</script>