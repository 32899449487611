import { db } from "@/services/db.service";
export default {
    async transladocaja_create({commit},request){
        commit('transladocaja_create',request);
    },
    async transladocaja_update({commit},request){
        commit('transladocaja_update',request);
    },
    async transladocaja_reload({commit},request){
        commit('transladocaja_reload',request);
    },
    async transladocaja_show({commit},request){
        commit('transladocaja_show',request);
    },
    async transladocaja_key({commit},request){
        commit('transladocaja_key',request);
    },
    async Vact_transladocaja_data({commit}){
        commit('transladocaja_load',true);
        try {
            const data = await db.get('/apitransladocaja/');
            commit('transladocaja_data',data);
            commit('transladocaja_load',false);
            return true
        } catch (e) {
            commit('transladocaja_load',false);
            return false
        } 
    },
    async Vact_transladocaja_save({commit},data){
        commit('transladocaja_save_load',true);
        try {
            const resp = await db.save('/apitransladocaja/',data);
            commit('transladocaja_save_load',false);
            return resp;
        } catch (e) {
            commit('transladocaja_save_load',false);
            return e
        } 
    },
}
