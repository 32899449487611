<template>
  <div>
    <InputAutocomplete
      label="Seleccione el Termo Semen"
      item-value="idtermosemen"
      item-text="descripcion"
      :id="id"
      dense
      outlined
      :readonly="readonly"
      :value="value"
      :return-object="returnObject"
      :items="getTermoSemen"
      :loading="isLoading"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
import InputAutocomplete from "@/ncomponents/InputAutocomplete";
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    id: String,
    value: [String, Object, Number],
    returnObject: Boolean,
    readonly: Boolean
  },
  components: {
    InputAutocomplete,
  },
  data: () => ({
    formulario: {
      crud: false,
      search: "",
    },
  }),
  computed: {
    ...mapGetters("termosemen", ["getTermoSemen", "isLoading"]),
  },
  mounted() {
    this.fetchTermoSemen();
  },
  methods: {
    ...mapActions("termosemen", ["fetchTermoSemen"]),
  },
};
</script>