
export default {

  comprobante_create: (state) => {
    return state.comprobante_create
  },
  comprobante_update: (state) => {
    return state.comprobante_update
  },
  comprobante_reload: (state) => {
    return state.comprobante_reload
  },
  comprobante_show: (state) => {
    return state.comprobante_show
  },
  comprobante_modo: (state) => {
    return state.comprobante_modo
  },
  comprobante_validar: (state) => {
    return state.comprobante_validar
  },
  comprobante_key: (state) => {
    return state.comprobante_key
  },
}
