export default {
  marca_update(state, request) {
    state.marca_update = request;
  },  
  marca_show(state, request) {
    state.marca_show = request;
  }, 
  
  marca_data(state, request) {
    state.marca_data = request;
  }, 
  marca_load(state, request) {
    state.marca_load = request;
  }, 
  marca_save_load(state, request) {
    state.marca_save_load = request;
  }, 
}