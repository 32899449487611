<template>
  <div>
    <v-row dense>
      <v-col md="7">
        <v-checkbox
          v-model="zona"
          @change="guardarConfig(zona)"
          class="ml-3 mb-0"
          label="Agrupar por Zona"
        ></v-checkbox>
      </v-col>
      <v-col md="5" class="mt-4">
        <v-chip outlined color="orange">
          Ha.: {{ Vget_campo_ha.toFixed(2) }}
        </v-chip>
      </v-col>
      <v-col md="7">
        <v-checkbox
          v-model="baja"
          @change="guardarBaja(baja)"
          class="ml-3 mb-0"
          label="Mostrar bajas"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-text-field
      class="ml-2 mr-2 mt-0"
      v-model="search"
      append-icon="search"
      label="Busqueda"
      single-line
      hide-details
    ></v-text-field>
    <v-data-table
      mobile-breakpoint="5000"
      v-if="!zona"
      :headers="headers"
      :items="Vget_campo_data"
      :loading="Vget_campo_load"
      :items-per-page="1000"
      :search="search"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn :color="item.estado ? `red` : `grey`" outlined small @click="startBaja(item)">{{ item.estado ? 'Baja' : 'Alta' }}</v-btn>
        <v-menu top :offset-y="true" v-if="permission.can_view">
          <template v-slot:activator="{ on }">
            <v-btn color="success" outlined small v-on="on">Exp.</v-btn>
          </template>

          <v-list dense>
            <v-list-item @click="downloadMAP(item, 'kml')">
              <v-list-item-title>KML</v-list-item-title>
            </v-list-item>
            <v-list-item @click="downloadMAP(item, 'kmz')">
              <v-list-item-title>KMZ</v-list-item-title>
            </v-list-item>
          </v-list>

        </v-menu>
        <v-btn
          color="primary"
          outlined
          small
          @click="selectrow(item)"
          v-if="permission.can_view"
          >Abrir</v-btn
        >
      </template>
    </v-data-table>
    <v-data-table
      mobile-breakpoint="5000"
      v-else
      :headers="headers_zona"
      :items="Vget_lotegrupo_data"
      :loading="Vget_lotegrupo_load"
      :items-per-page="1000"
      :search="search"
      :expanded.sync="expanded"
      show-expand
      item-key="idlote_grupo"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          color="primary"
          outlined
          small
          @click="selectrowZona(item)"
          v-if="permission.can_view"
          >Ver</v-btn
        >
      </template>
      <template v-slot:[`item.ha`]="props">
        <td class="text-xs-letf">{{ currencyFormat(props.item.ha) }}</td>
      </template>
      <template v-slot:expanded-item="{ item }">
        <v-data-table
          mobile-breakpoint="5000"
          hide-default-footer
          :headers="headers_zona_sub"
          :items="item.grupo"
          :loading="Vget_lotegrupo_load"
          :items-per-page="1000"
          :search="search"
          class="elevation-1"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn :color="item.estado ? `red` : `grey`" outlined small @click="startBaja(item)">{{ item.estado ? 'Baja' : 'Alta' }}</v-btn>
            <v-btn
              color="secondary"
              outlined
              small
              @click="selectrow(item)"
              v-if="permission.can_view"
              >Abrir</v-btn
            >
          </template>
        </v-data-table>
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <v-dialog
      v-if="dialogBaja"
      v-model="dialogBaja"
      max-width="420"
      persistent
    >
      <v-card>
        <v-card-title>
          <v-icon left>info</v-icon>
          <span class="title font-weight-light">Ventana de Confirmacion</span>
        </v-card-title>

        <v-card-text class="subtitle-1 font-weight-regular">
          {{ tituloBaja }}
          <strong class="font-italic font-weight-bold">{{
            bajas.campo
          }}</strong>
          ?
        </v-card-text>

        <v-card-actions>
          <v-btn color="red" text @click="dialogBaja = false">
            <v-icon>clear</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="darBaja()">
            <v-icon>done</v-icon>
          </v-btn>
        </v-card-actions>
        <c-overlay :value="Vget_campo_baja"></c-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mensaje } from "@/services/notify.service";
import { mapGetters, mapActions } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { LocalService } from "@/services/auth/storage.service";
import { ZONAS_CULTIVO } from "@/constants/forms";
import { get } from "@/services/api/api.service";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";
const variable = "isZonaListAgro";
const download = (filename, data, mode) => {
  var element = document.createElement("a");
  if (mode === "kmz") {
    element.setAttribute("href", "data:text/plain;base64," + data);
  } else {
    const blob = new Blob([data], { type: "application/octet-stream" });
    element.setAttribute("href", window.URL.createObjectURL(blob));
  }
  element.setAttribute("download", filename);
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};
export default {
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    const permission = getPermission(ZONAS_CULTIVO).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
    this.zona = LocalService.get(variable);
  },
  computed: {
    ...mapGetters("campo", [
      "Vget_campo_load",
      "Vget_campo_data",
      "Vget_campo_ha",
      "Vget_campo_baja",
    ]),
    ...mapGetters("lotegrupo", ["Vget_lotegrupo_data", "Vget_lotegrupo_load"]),
  },
  methods: {
    ...mapActions("agromap", ["Vact_agromap_coordinates_list"]),
    ...mapActions("campo", ["Vact_campo_baja", "Vact_campo_data"]),
    ...mapActions("lotegrupo", ["Vact_lotegrupo_data"]),
    selectrow(item) {
      this.$router.push({
        path: "/agricultura/campo/detalle/" + item.idlote_agri,
        query: { polygon: item.id_polygon },
      });
    },
    async downloadMAP(item, mode) {
      try {
        let response = await get(`/apilotekmz/${item.idlote_agri}/${mode}/`);
        download(item.campo + "." + mode, response, mode);
      } catch (error) {
        console.log(error);
      }
    },
    selectrowZona(item) {
      this.Vact_agromap_coordinates_list(item.grupo);
    },

    currencyFormat(value) {
      return new Intl.NumberFormat({ maximumSignificantDigits: 3 }).format(
        value
      );
    },
    guardarConfig(zona) {
      LocalService.save(variable, zona);
    },

    startBaja(item) {
      this.dialogBaja = true
      this.bajas = item
      if (item.estado) {
        this.tituloBaja = `Desea dar de baja al lote `
      } else {
        this.tituloBaja = `Desea dar de alta al lote `
      }
    },
    async darBaja() {
      let a = await this.Vact_campo_baja(this.bajas.idlote_agri)
      if (!a.estado) {
        return a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      } else {
        this.$notify(a.info);
      }
      this.dialogBaja = false
      this.guardarBaja(this.baja)
    },
    guardarBaja(item) {
      if (item) {
        this.Vact_campo_data('yes')
        this.Vact_lotegrupo_data('yes')
      } else {
        this.Vact_campo_data('no')
        this.Vact_lotegrupo_data('no')
      }
    }
  },
  data() {
    return {
      search: "",
      ha: 0,
      zona: true,
      baja: false,
      expanded: [],
      headers: [
        { text: "#", value: "nrolote_agri" },
        { text: "Lote", value: "campo" },
        { text: "Ha", value: "hectarea", sortable: false },
        { text: "Accion", align: "right", value: "actions", sortable: false },
      ],
      headers_zona: [
        { text: "Zona", value: "descripcion" },
        { text: "Ha", value: "ha" },
        { text: "Accion", align: "right", value: "actions", sortable: false },
        { text: "", value: "data-table-expand", align: "right" },
      ],
      headers_zona_sub: [
        { text: "Lote", value: "campo" },
        { text: "Ha", value: "hectarea" },
        { text: "Accion", align: "right", value: "actions", sortable: false },
      ],
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
      dialogBaja: false,
      bajas: {
        idlote_agri: "",
        campo: ""
      },
      tituloBaja: ""
    };
  },
};
</script>