import {SET_LOADING, SET_ASISTENCIA, SET_LISTA} from './constants'
export default {
  [SET_ASISTENCIA](state, request) {
    state.asistencia = request;
  },
  [SET_LISTA](state, request) {
    state.lista = request;
  },
  [SET_LOADING](state, request) {
    state.isLoading = request;
  },
}