import { get  } from "@/services/api/api.service";
import { url, LOADING, FETCH_GENERAL, FETCH_RESUMIDO, FETCH_CLIENTE, FETCH_PROVEEDOR } from "./contants";
export default {
  fetchGeneral: async ({ commit, dispatch }, {fecha_inicio, fecha_fin}) => {
    commit(LOADING, true);
    try {
      const response = await get(`${url.general}?fecha_inicio=${fecha_inicio}&fecha_fin=${fecha_fin}`);
      commit(FETCH_GENERAL, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(LOADING, false);
      throw e;
    }
    commit(LOADING, false);
  },
  fetchResumido: async ({ commit, dispatch }, {fecha_inicio, fecha_fin}) => {
    commit(LOADING, true);
    try {
      const response = await get(`${url.resumido}?fecha_inicio=${fecha_inicio}&fecha_fin=${fecha_fin}`);

      commit(FETCH_RESUMIDO, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(LOADING, false);
      throw e;
    }
    commit(LOADING, false);
  },
  fetchCliente: async ({ commit, dispatch },idcliente) => {
    commit(LOADING, true);
    try {
      const response = await get(`${url.cliente}${idcliente}/` );

      commit(FETCH_CLIENTE, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(LOADING, false);
      throw e;
    }
    commit(LOADING, false);
  },
  fetchProveedor: async ({ commit, dispatch },idproveedor) => {
    commit(LOADING, true);
    try {
      const response = await get(`${url.proveedor}${idproveedor}/` );

      commit(FETCH_PROVEEDOR, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(LOADING, false);
      throw e;
    }
    commit(LOADING, false);
  }
};
