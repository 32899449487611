import { db } from "@/services/db.service";
export default {
    async propquimica_update({commit},request){
        commit('propquimica_update',request);
    },
    async Vact_propquimica_data({commit}){
        commit('propquimica_load',true);
        try {
            const data = await db.get('/apipropiedadquimica/');
            commit('propquimica_data',data);
            commit('propquimica_load',false);
            return true
        } catch (e) {
            commit('propquimica_load',false);
            return false
        } 
    },
    async Vact_propquimica_del({dispatch},id){
        try {
            const data = await db.delete('/apipropiedadquimica/'+id);
            dispatch('Vact_propquimica_data');
            return data
        } catch (e) {
            return false
        } 
    },
    async Vact_propquimica_save({commit},data){
        commit('propquimica_save_load',true);
        try {
            const resp = await db.save('/apipropiedadquimica/',data);
            commit('propquimica_save_load',false);
            return resp;
        } catch (e) {
            commit('propquimica_save_load',false);
            return e
        } 
    },
    async Vact_propquimica_update({commit},{id, data}){
        commit('propquimica_save_load',true);
        try {
            const resp = await db.update('/apipropiedadquimica/'+id+'/',data);
            commit('propquimica_save_load',false);
            return resp;
        } catch (e) {
            commit('propquimica_save_load',false);
            return e
        } 
    },
}
