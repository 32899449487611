import { db } from "@/services/db.service";
export default {
    async responsable_create({commit},request){
        commit('responsable_create',request);
    },
    async responsable_update({commit},request){
        commit('responsable_update',request);
    },
    async responsable_show({commit},request){
        commit('responsable_show',request);
    },
    async Vact_responsable_data({commit}){
        commit('responsable_load',true);
        try {
            const data = await db.get('/apiresponsable/');
            commit('responsable_data',data);
            commit('responsable_load',false);
            return true
        } catch (e) {
            commit('responsable_load',false);
            return false
        } 
    },
    async Vact_responsable_data_inactivo({commit}){
        commit('responsable_load',true);
        try {
            const data = await db.get('/apiresponsable/?band=inactivo');
            commit('responsable_data_inactivo',data);
            commit('responsable_load',false);
            return true
        } catch (e) {
            commit('responsable_load',false);
            return false
        } 
    },
    async Vact_responsable_del({dispatch},id){
        try {
            const data = await db.delete('/apiresponsable/'+id);
            dispatch('Vact_responsable_data');
            return data
        } catch (e) {
            return false
        } 
    },
    async Vact_responsable_save({commit},data){
        commit('responsable_save_load',true);
        try {
            const resp = await db.save('/apiresponsable/',data);
            commit('responsable_save_load',false);
            return resp;
        } catch (e) {
            commit('responsable_save_load',false);
            return e
        } 
    },
    async Vact_responsable_update({commit},{id, data}){
        commit('responsable_save_load',true);
        try {
            const resp = await db.update('/apiresponsable/'+id+'/',data);
            commit('responsable_save_load',false);
            return resp;
        } catch (e) {
            commit('responsable_save_load',false);
            return e
        } 
    },
}
