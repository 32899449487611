<template>
    <div>
        <v-dialog :value="value" max-width="900" persistent :fullscreen="$vuetify.breakpoint.xs">
            <v-card>
                <v-toolbar flat dense>
                    <v-toolbar-title>
                        {{ readonly ? "Detalles" : !isEdit ? "Registrar Reajuste" : "Modificar Reajuste" }}
                    </v-toolbar-title>
                    <v-divider class="mx-2" inset vertical></v-divider>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-container>
                    <v-form ref="form">
                        <v-row dense>
                            <v-col cols="12" sm="3">
                                <c-text-date label="Fecha" ref="reajuste1" id="reajuste1" :readonly="readonly"
                                    v-model="data.fecha" @focus="setSelection(`reajuste1`)"
                                    @keyup.native.enter="next(1, data.fecha)"></c-text-date>
                            </v-col>
                            <v-col cols="12" sm="3">
                                <AutocompleteCampana ref="reajuste2" :readonly="readonly" label="Campaña Origen"
                                    return-object v-model="data.idcampana"
                                    @keyup.native.enter="next(2, data.idcampana.idcampana)" />
                            </v-col>
                            <v-col cols="12" sm="6" align-self="center">
                                <v-btn-toggle v-model="data.ensilaje" dense rounded mandatory color="red darken-1">
                                    <v-btn>
                                        Granos
                                    </v-btn>
                                    <v-btn>
                                        G. Humedo
                                    </v-btn>
                                    <v-btn>
                                        Ensilaje
                                    </v-btn>
                                </v-btn-toggle>
                            </v-col>
                            <v-col cols="12" md="4" sm="4">

                                <AutocompleteDepositoStock ref="reajuste3"
                                    :outlined="false"
                                    :label="data.ensilaje == 2 ? 'Tipo de ensilaje' : data.ensilaje == 0 ? 'Deposito' : 'G. Humedo'"
                                    :return-type="data.ensilaje == 2 ? 'ensilaje' : data.ensilaje == 0 ? 'stock' : 'hum'"
                                    :idcampana="data.idcampana.idcampana" v-model="data.idstock"
                                    @keyup.native.enter="next(3, data.idstock.idstock)" />
                            </v-col>
                            <v-col cols="12" md="1" sm="1">
                                <v-btn class="mx-2" fab dark small :color="`${data.positivo ? 'green' : 'red'}`"
                                    @click="data.positivo = !data.positivo">
                                    <v-icon dark>
                                        {{ `${data.positivo ? 'add' : 'remove'}` }}
                                    </v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="12" md="4" sm="4">
                                <c-text-currency 
                                    label="Reajuste en Kg" 
                                    :rules="[(v) => !!v || 'Obligatorio']" 
                                    v-model="data.valor" 
                                    outlined
                                    dense
                                    :readonly="readonly"
                                    ref="reajuste4" 
                                    @keyup.native.enter="next(4, data.valor)"
                                ></c-text-currency>
                            </v-col>
                            <v-col cols="12">
                                <c-text-field 
                                    label="Comentario"
                                    :rules="[(v) => !!v || 'Obligatorio']" 
                                    v-model="data.comentario"
                                    outlined
                                    dense
                                    :readonly="readonly"
                                    ref="reajuste5" 
                                    @keyup.native.enter="next(5, data.comentario)"
                                ></c-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
                <v-divider></v-divider>
                <v-card-actions>
                    <c-btn-crud-close ref="reajuste6" @click="cerrar()"></c-btn-crud-close>
                    <v-spacer></v-spacer>
                    <c-btn-crud-done :disabled="readonly" @click="guardar()"></c-btn-crud-done>
                </v-card-actions>
                <c-overlay :value="isLoading"></c-overlay>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { current_date } from "@/services/util/date.service";
import { currency } from "@/services/util/number.service";
import AutocompleteCampana from "../../Campana/autocomplete";
import AutocompleteDepositoStock from "@/views/Item/Deposito/autocompleteStock";
export default {
    components: {
        AutocompleteCampana,
        AutocompleteDepositoStock
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        datos: {
            type: [Object, Array],
            default: null,
        },
        readonly: {
            type: Boolean,
            default: false,
        }
    },
    computed: {
        ...mapGetters("reajuste", ["isLoading"]),
    },
    mounted() {
        setTimeout(() => this.next(0, "-"), 500)
    },
    watch: {
        $route() {
            return this.cerrar();
        },
    },
    methods: {
        ...mapActions("reajuste", ["fetchReajuste", "setReajuste", "setReajusteUpdate"]),
        setSelection(id) {
            setTimeout(() => {
                let input = document.getElementById(id);
                input.focus();
                input.setSelectionRange(0, 10);
            }, 500);
        },
        next(id, data) {
            if (data != "" && data)
                try {
                    this.$refs[`reajuste${id + 1}`].focus();
                } catch (error) {
                    this.$refs[`reajuste${id + 1}`].$el.focus();
                }
        },

        toCurrency: (value) => currency(value),
        async guardar() {
            if (!this.$refs.form.validate())
                return null;
            this.data.iditem.iditem = this.data.idcampana.iditem.iditem;
            this.data.iddeposito.iddeposito = this.data.idstock.iddeposito.iddeposito;
            const id = this.data.idreajusteagro
            const response = this.isEdit
                ? await this.setReajusteUpdate({ id, data: this.data })
                : await this.setReajuste(this.data);
            if (response.success) {
                this.data = JSON.parse(JSON.stringify(this.default));
                if (this.isEdit) return this.cerrar();
                this.$refs.form.resetValidation();
                setTimeout(() => this.next(0, "-"), 500)
            }
        },
        cerrar() {
            this.$emit("input", false);
            this.$emit("datos", null);
            this.$emit("readonly", false);
            this.fetchReajuste();
            this.data = JSON.parse(JSON.stringify(this.default));
        },
    },
    data() {
        return {
            search: "",
            isEdit: false,
            data: {
                idreajusteagro: 0,
                fecha: current_date(),
                valor: null,
                ensilaje: 0,
                positivo: false,
                idcampana: {
                    idcampana: null,
                    iditem: {
                        descitem: "",
                    },
                },
                iddeposito: {
                    iddeposito: ""
                },
                iditem: {
                    iditem: ""
                },
                idstock: {
                    idstock: ""
                },
            },
            default: {
                idreajusteagro: 0,
                fecha: current_date(),
                valor: null,
                ensilaje: 0,
                positivo: false,
                idcampana: {
                    idcampana: null,
                    iditem: {
                        descitem: "",
                    },
                },
                iddeposito: {
                    iddeposito: ""
                },
                iditem: {
                    iditem: ""
                },
                idstock: {
                    idstock: ""
                },
            }
        };
    },
}
</script>