export default {
  pedidoventa_data(state, request) {
    state.pedidoventa_data = request;
  }, 
  pedidoventa_presupuesto(state, request) {
    state.pedidoventa_presupuesto = request;
  }, 
  pedidoventa_reporte(state, request) {
    state.pedidoventa_reporte = request;
  }, 
  pedidoventa_finalizado(state, request) {
    state.pedidoventa_finalizado = request;
  }, 
  pedidoventa_byId(state, request) {
    state.pedidoventa_byId = request;
  }, 
  pedidoventa_load(state, request) {
    state.pedidoventa_load = request;
  }, 
  pedidoventa_save_load(state, request) {
    state.pedidoventa_save_load = request;
  }, 
}