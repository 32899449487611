<template>
  <div>
    <v-dialog :value="value" max-width="750" persistent>
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>{{
            operacion ? "Agregar Racion" : "Modificar Racion"
          }}</v-toolbar-title>
        </v-toolbar>
        <v-container>
          <v-form ref="form">
            <v-row dense>
              <v-col cols="4">
                <AutocompleteLote
                  id="racionInput1"
                  v-model="data.idlote.idlote"
                  @keyup.native.enter="next(1, data.idlote.idlote)"
                />
              </v-col>
              <v-col cols="4">
                <c-text-field
                  label="Descripcion"
                  id="racionInput2"
                  v-model="data.obsracion"
                  dense
                  outlined
                  @keyup.native.enter="next(2, data.obsracion)"
                ></c-text-field>
              </v-col>
              <v-col cols="4">
                <vue-autocomplete-moneda-day
                  label="Moneda"
                  id="racionInput3"
                  :chips="false"
                  v-model="data.idmoneda"
                  @keyup.native.enter="next(3, data.idmoneda.idmoneda)"
                />
              </v-col>
            </v-row>
          </v-form>
          <v-form ref="detalle">
            <v-row dense>
              <v-col cols="4">
                <AutocompleteItem
                  id="racionInput4"
                  label="Seleccione producto"
                  action="Vact_item_data"
                  getter="Vget_item_data"
                  v-model="detalle.iditem"
                  :returnObject="true"
                  @keyup.native.enter="next(4, detalle.iditem)"
                />
              </v-col>
              <v-col cols="4">
                <vue-text-field
                  label="Cantidad"
                  type="number"
                  :rules="true"
                  id="racionInput5"
                  v-model="detalle.cantdetracion"
                  @keyup.native.enter="next(5, detalle.cantdetracion)"
                ></vue-text-field>
              </v-col>
              <v-col cols="4">
                <v-spacer></v-spacer>
                <v-btn
                  id="racionInput6"
                  outlined
                  color="primary"
                  fab
                  small
                  @click="addTable()"
                >
                  <v-icon>add</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12">
                <DataTable
                  :items="data.racion"
                  @edit="editarDetalle($event)"
                  @delete="eliminarDetalle($event)"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="cancelar()">
            <v-icon>clear</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            id="racionInputAceptar"
            text
            @click="guardar()"
          >
            <v-icon>done</v-icon>
          </v-btn>
        </v-card-actions>
        <c-overlay :value="Vget_racion_save_load"></c-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { focus } from "@/services/focus.service";
import { mensaje } from "@/services/notify.service";
import AutocompleteLote from "@/views/Animal/Lote/Autocomplete";
import DataTable from "./DataTable.vue";
export default {
  components: {
    AutocompleteLote,
    AutocompleteItem: () => import("@/views/Item/Item/AutocompleteItemVivo2"),
    DataTable,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    datos: {
      type: [Object, Array],
      default: null,
    },
    "sync-input": {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      sync: null,
      data: {
        idracion: 0,
        racion: [],
        obsracion: "",
        idmoneda: {
          idmoneda: "",
        },
        idlote: {
          idlote: "",
        },
      },
      default: {
        idracion: 0,
        racion: [],
        obsracion: "",
        idmoneda: {
          idmoneda: ""
        },
        idlote: {
          idlote: "",
        },
      },
      detalle: {
        iditem: {
          iditem: "",
        },
        cantdetracion: "",
      },
      operacion: true,
    };
  },
  watch: {
    $route() {
      return this.cancelar();
    },
  },
  computed: {
    ...mapGetters("racion", ["Vget_racion_save_load"]),
  },
  mounted() {
    setTimeout(() => this.next(0, "-"), 200);
    this.nuevo(this.datos);
  },
  methods: {
    ...mapActions("racion", [
      "Vact_racion_save",
      "Vact_racion_update",
      "Vact_racion_data",
    ]),
    next(id, data) {
      focus.nextidName("racionInput", id, data);
    },
    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.Vact_racion_data();
      this.operacion = true;
      this.$emit("input", false);
      this.$emit("datos", null);
      this.operacion = true;
    },
    async guardar_finalizar() {
      let a = await this.Vact_racion_save(this.data);
      if (a.estado == true) {
        this.data = JSON.parse(JSON.stringify(this.default));
        if (this.sync != null) {
          this.$emit("sync-input", a.data.data.id);
          this.cancelar();
        }
        this.$notify(a.info);
        this.$refs.form.resetValidation();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
    },

    async editar_finalizar() {
      const id = this.data.idracion;
      let a = await this.Vact_racion_update({ id, data: this.data });
      if (a.estado == true) {
        this.$notify(a.info);
        this.cancelar();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
    },
    guardar() {
      if (!this.$refs.form.validate()) return this.$notify(mensaje.completar());
      if (this.operacion === true) return this.guardar_finalizar();
      if (this.operacion === false) return this.editar_finalizar();
    },

    nuevo(val) {
      if (val != null) {
        this.data = JSON.parse(JSON.stringify(val));
        this.operacion = false;
      }
    },
    verificar_duplicado() {
      const temp = this.data.racion.find(
        (x) =>
          x.iditem.iditem === this.detalle.iditem.iditem
      );
      return temp ? true: false;
    },
    addTable() {
      if (this.verificar_duplicado()) {
        return null;
      }
      this.data.racion.push(
        {
          cantdetracion: this.detalle.cantdetracion,
          iditem: this.detalle.iditem
        }
      );
      this.next(3, this.data.idmoneda)
    },
  },
};
</script>
