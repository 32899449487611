<template>
  <div>
    <v-autocomplete
      dense
      :value="value"
      @input="$emit('input', $event)"
      @focus="$emit('focus')"
      v-on:change="$emit('change')"
      height="12px"
      ref="input"
      :id="id"
      input="input"
      :filter="customFilter"
      v-bind:items="item"
      :search-input.sync="searchitem"
      :label="label"
      :error-messages="errorMessage"
      item-text="descitem"
      return-object
      :loading="Vget_item_load"
      :rules="[(v) => !!v || 'Seleccion un Item']"
      required
      :clearable="true"
      :readonly="readonly"
      outlined
      autocomplete="off"
      :no-data-text="`No existe ninguna concidencia`"
      small-chips
    >
      <template v-if="noData && item_permission && create" v-slot:no-data>
        <v-list-item @click="formulario.crud=true">
          <v-list-item-content>
            <v-list-item-title
              >No existe concidencia. Click para agregar</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </template>
      <template slot="selection" slot-scope="data">
        <v-chip small>{{ `${data.item.descitem}` }}</v-chip>
      </template>
      <template slot="item" slot-scope="data">
        <v-list-item-content>
          <v-list-item-title>
            {{data.item.iditem}} - {{data.item.descitem}} - {{data.item.idmarca.descmarca}}
          </v-list-item-title>
        </v-list-item-content>
      </template>
    </v-autocomplete>
    <Crud
      v-if="formulario.crud"
      v-model="formulario.crud"
      :sync-input="formulario.search"
      @sync-input="syncInputHandler($event)"
    />
  </div>
</template>

<script>
import Crud from "@/views/Item/Item/Create";
import { mapGetters, mapActions } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { ITEM } from "@/constants/forms";
import { PUEDE_AGREGAR } from "@/constants/permissions";
export default {
  components: {
    Crud,
  },
  props: {
    value: {
      type: [String, Object, Number],
    },
    label: {
      type: String,
      default: "Item",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    "search-item-id": {
      type: [String, Number],
      default: null,
    },
    create: {
      type: Boolean,
      default: true,
    },
    id: String,
  },
  data() {
    return {
      searchitem: "",
      item: [],
      iditem: "",
      noData: true,
      errorMessage: "",
      awaitingSearch: false,
      formulario: {
        crud: false,
        search: "",
      },
    };
  },
  mounted() {
    this.init();
    if (this.value) {
      this.searchitem = this.value.descitem;
      this.obtain_item();
    }
  },
  watch: {
    Vget_item_data(val) {
      this.item = [];
      this.item = JSON.parse(JSON.stringify(val));
      if (this.searchItemId != null) return this.search();
    },
    searchitem(val) {
      this.$emit("sync-input", val);
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.obtain_item();
          this.awaitingSearch = false;
        }, 1000);
      }
      this.formulario.search = val;
      this.awaitingSearch = true;
    },
    searchItemId() {
      this.search();
    },
  },
  computed: {
    ...mapGetters("item", ["Vget_item_data", "Vget_item_load"]),
    item_permission() {
      return getPermission(ITEM).permiso[PUEDE_AGREGAR];
    },
    length() {
      if (
        this.searchitem === null ||
        this.searchitem === undefined ||
        this.searchitem === ""
      )
        return 0;
      return this.searchitem.length;
    },
  },
  methods: {
    ...mapActions("item", ["Vact_item_data"]),
    syncInputHandler(event) {
      this.searchitem = event.descitem;
      this.search();
      //if (this.returnObject) this.$emit("input", event);
      //if (!this.returnObject) this.$emit("input", event.iditem);
      this.$emit("nextfocus");
    },
    obtain_item() {
      if (this.searchitem != null && this.searchitem.length >= 3) {
        this.Vact_item_data({
          desc: this.searchitem,
        });
      }
    },
    focus() {
      this.$refs.input.focus();
    },
    isMenuActive() {
      this.$refs.input.isMenuActive = false;
    },
    async init() {
      this.item = [];
      //const response = await this.Vact_item_agrostock({
      //  search: "",
      //});
      //if (response != true);
      //this.errorMessage = response.message;
    },
    customFilter(item, queryText) {
      const textOne = item.iditem.toString();
      const textTwo = item.idmarca.descmarca.toLowerCase();
      const textTree = item.descitem.toLowerCase();
      const searchText = queryText.toLowerCase();
      return (
        textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1 ||
        textTree.indexOf(searchText) > -1
      );
    },
    search() {
      this.item.forEach((x) => {
        if (x.iditem === this.searchItemId) {
          this.$emit("input", x);
          this.$emit("change");
          this.$emit("resetSearch", null);
          return;
        }
      });
    },
  },
};
</script>