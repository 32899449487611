
export default {
  Vget_notacreditoventa_data: (state) => {
    return state.notacreditoventa_data
  },
  Vget_notacreditoventa_load: (state) => {
    return state.notacreditoventa_load
  },
  Vget_notacreditoventa_save_load: (state) => {
    return state.notacreditoventa_save_load
  },
}
