import ApiService from '@/services/auth/api.service'
import { TokenService } from '@/services/auth/storage.service'
import store from '@/store'

const saveDbLocal = (data, url) => {
    store.dispatch('conexion', false);
    store.dispatch('offlineSync/set_posterror_dblocal', { data, url });
    return {
        info: {
            group: 'app',
            type: 'warn',
            title: 'Guardado Localmente',
            text: 'Operacion pendiente de sincronizacion'
        },
        estado: true
    }
}

const db = {

    save: async function (url, data) {
        const requestData = {
            method: 'POST',
            url: url,
            data: data,
            headers: {
                'Authorization': `Token ${TokenService.getToken()}`
            }

        }

        try {
            const response = await ApiService.customRequest(requestData);
            store.dispatch('conexion', true)
            return {
                info: {
                    group: 'app',
                    type: 'success',
                    title: 'Mensaje:',
                    text: response.data.Success
                },
                estado: true,
                data: response
            }


        } catch (error) {
            if (error.response === undefined) {
                if (url === '/apipedidoventa/')
                return saveDbLocal(data, url)
            }
            if (error.response.data.Error != undefined) {
                if (error.response.data.Error[1] === 'cotizacion') {
                    return saveDbLocal(data, url)
                }
            }
            let array = []
            let meta = []
            Object.keys(error.response.data).map(key => {
                array.push(error.response.data[key][0]);
                meta.push(error.response.data[key][1])
            });
            return {
                info: array,
                meta: meta,
                estado: false,
                data: error
            }
        }
    },

    get: async function (url, id, desc) {

        const requestData = {
            method: 'GET',
            url: url,
            headers: {
                'Authorization': `Token ${TokenService.getToken()}`
            }
        }

        try {
            const response = await ApiService.customRequest(requestData)
            store.dispatch('conexion', true)
            if (id != undefined && desc != undefined) {
                var resultado = [];
                response.data.map(x => {
                    resultado.push({
                        id: x[id],
                        fkdes: x[desc]
                    })
                });
                return resultado;
            }
            return response.data
        } catch (error) {
            store.dispatch('conexion', false);
            if (error.response === undefined) throw new Error('Sin Conexion');
            throw new Error(error.response.data.Error);

        }
    },
    update: async function (url, data) {
        const requestData = {
            method: 'PUT',
            url: url,
            data: data,
            headers: {
                'Authorization': `Token ${TokenService.getToken()}`
            }

        }

        try {
            const response = await ApiService.customRequest(requestData)
            store.dispatch('conexion', true)
            return {
                info: {
                    group: 'app',
                    type: 'success',
                    title: 'Mensaje:',
                    text: response.data.Success
                },
                estado: true,
                data: response
            }

        } catch (error) {
            store.dispatch('conexion', false);
            let array = []
            Object.keys(error.response.data).map(key => {
                array.push(error.response.data[key][0]);
            });
            return {
                info: array,
                estado: false,
                data: error
            }
        }
    },

    delete: async function (url, params) {
        const requestData = {
            method: 'DELETE',
            url: url,
            params: params,
            headers: {
                'Authorization': `Token ${TokenService.getToken()}`
            }

        }

        try {
            const response = await ApiService.customRequest(requestData)
            store.dispatch('conexion', true)
            return {
                info: {
                    group: "app",
                    type: "success",
                    title: 'Mensaje:',
                    text: response.data.Success
                },
                estado: true,
                data: response
            }

        } catch (error) {
            store.dispatch('conexion', false);
            let array = []
            Object.keys(error.response.data).map(key => {
                array.push(error.response.data[key]);
            });
            return {
                info: array,
                estado: false,
                data: error
            }
        }
    },

    

}

export {
    db
}