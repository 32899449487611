<template>
  <v-autocomplete
    :value="value"
    :id="id"
    @input="$emit('input', $event)"
    :rules="rules_active"
    dense
    type="text"
    :items="items"
    :label="label"
    item-text="fkdes"
    item-value="id"
    outlined
    :readonly="readonly"
    :disabled="disabled"
    height="12"
    autocomplete="off"
    :loading="loading"
    :no-data-text="`No existe ninguna concidencia`"
    v-on:change="change"
    :small-chips="chips"
  >
    <template v-if="noData && noactive && addpermission" v-slot:no-data>
      <v-list-item @click="open_ref()">
        <v-list-item-content>
          <v-list-item-title>No existe concidencia. Click para agregar</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
import { db } from "@/services/db.service";
import { PUEDE_AGREGAR } from "@/constants/permissions";
import { getPermission } from "@/services/storage/permissionStorage.service";
export default {
  props: {
    label: String,
    value: [Number, String],
    api: String,
    pk: String,
    title: String,
    vuex: String,
    id: String,
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    chips: {
      type: Boolean,
      default: true
    },
    noactive: {
      type: Boolean,
      default: true
    },
    quitar_id: {
      type: Number,
      default: null
    },
    permission: {
      type: [String, Boolean],
      default: false
    },
    rules: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      dialog: false,
      validar: [v => !!v || "Seleccion un campo"],
      items: [],
      loading: false,
      noData: true
    };
  },
  created() {
    this.get_data();
  },
  computed:{
    addpermission() {
      if(this.permission === false) return false;
      return getPermission(this.permission).permiso[PUEDE_AGREGAR];
    },
    rules_active() {
      if (this.rules) return this.validar;
      return [];
    }
  },
  methods: {
    async get_data() {
      this.loading = true;
      const response = await db.get(this.api);
      response.map(x => {
        if (x[this.pk] != this.quitar_id) {
          this.items.push({ id: x[this.pk], fkdes: x[this.title] });
        }
      });
      this.loading = false;
    },
    open_ref() {
      this.$store.commit(this.vuex, true);
    },
    change(evt) {
      this.$emit("change", evt);
    }
  }
};
</script>