<template>
  <v-btn x-small fab text color="red" @click="$emit('click')"
    ><v-icon>delete</v-icon></v-btn
  >
</template>

<script>
export default {};
</script>

<style>
</style>