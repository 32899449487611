import { Workbook } from "exceljs";
import { current_date } from "@/services/util/date.service";
import * as fs from "file-saver"

export const exportIndiceProduccion = (datos) => {
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Hoja 1");
    const header = ["Fecha", "Animales"]
    worksheet.addRow(["Reporte de Indice Productivo"]).font = {
        name: "Arial",
        family: 4,
        size: 14,
        bold: true,
    };
    worksheet.mergeCells(`A1:B1`);
    let headerRow = worksheet.addRow(header);
    headerRow.eachCell((cell) => {
        cell.alignment = {
            vertical: "middle",
            horizontal: "center",
        };
        cell.border = {
            top: { style: "medium" },
            left: { style: "medium" },
            right: { style: "medium" },
            bottom: { style: "medium" },
        };
        cell.font = {
            name: "Arial",
            family: 4,
            size: 13,
        };
    });
    datos.forEach((x) => {
        let row = worksheet.addRow(x);
        row.eachCell((cell) => {
            cell.alignment = {
                vertical: "middle",
                horizontal: "center",
            };
            cell.border = {
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
                top: { style: "thin" },
            };
            cell.font = {
                name: "Arial",
                family: 4,
                size: 12,
            };
        });
    });
    workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        fs.saveAs(blob, `Indice de Produccion ${current_date()}.xlsx`);
    });
}