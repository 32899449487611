<template>
    <div>
        <v-dialog :value="value" max-width="900" persistent :fullscreen="$vuetify.breakpoint.xs" @keydown.esc="cerrar()">
            <v-card>
                <v-toolbar flat dense>
                    <v-toolbar-title>
                        Registrar Bonificacion
                    </v-toolbar-title>
                </v-toolbar>
                <v-container>
                    <v-form ref="form" @submit.prevent="next(1, bonificacion.fecha)">
                        <v-row dense>
                            <v-col cols="12" md="4" sm="4">
                                <c-text-date label="Fecha" id="bonificacionInput1" outlined dense
                                    v-model="bonificacion.fecha" @focus="setSelection(`bonificacionInput1`)"
                                    @keyup.native.enter="next(1, bonificacion.fecha)"></c-text-date>
                            </v-col>
                            <v-col cols="12" md="4" sm="4">
                                <vue-autocomplete-generic id="bonificacionInput2" action="campana/Vact_campana_data"
                                    getter="campana/Vget_campana_data" load="campana/Vget_campana_load" pk="idcampana"
                                    label="Seleccione Campana" title="descripcion" permission="Zafra"
                                    v-model="bonificacion.idcampana.idcampana"
                                    @keyup.native.enter="next(2, bonificacion.idcampana.idcampana)" />
                            </v-col>
                            <v-col cols="12" md="4" sm="4">
                                <v-btn-toggle v-model="bonificacion.ensilaje" dense rounded mandatory color="red darken-1">
                                    <v-btn>
                                        Granos
                                    </v-btn>
                                    <v-btn>
                                        G. Humedo
                                    </v-btn>
                                    <v-btn>
                                        Ensilaje
                                    </v-btn>
                                </v-btn-toggle>
                            </v-col>
                            <v-col cols="12" md="4" sm="4">
                                <AutocompleteDepositoStock id="bonificacionInput3"
                                    :label="bonificacion.ensilaje == 2 ? 'Tipo de ensilaje' : bonificacion.ensilaje == 0 ? 'Deposito' : 'G. Humedo'"
                                    :return-type="bonificacion.ensilaje == 2 ? 'ensilaje' : bonificacion.ensilaje == 0 ? 'stock' : 'hum'"
                                    :idcampana="bonificacion.idcampana.idcampana" v-model="bonificacion.idstock"
                                    @keyup.native.enter="next(3, bonificacion.idstock)" />
                            </v-col>
                            <v-col cols="12" md="4" sm="4">
                                <vue-text-currency label="Precio por Tn" :rules="true" v-model="bonificacion.precio"
                                    id="bonifacionInput4"
                                    @keyup.native.enter="next(4, bonificacion.precio)"></vue-text-currency>
                            </v-col>
                            <v-col cols="12">
                                <vue-text-field label="Comentario" :rules="false" v-model="bonificacion.comentario"
                                    id="bonificacionInput5"
                                    @keyup.native.enter="next(5, bonificacion.comentario)"></vue-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
                <v-container>
                    <v-divider class="mb-2"></v-divider>
                    <v-row dense no-gutters>
                        <v-btn color="red" text @click="cerrar()">
                            <v-icon>clear</v-icon>
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="guardarBonificacion()">
                            <v-icon>done</v-icon>
                        </v-btn>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { current_date } from "@/services/util/date.service";
import { focus } from "@/services/focus.service";
import { mapGetters, mapActions } from "vuex";
import AutocompleteDepositoStock from "@/views/Item/Deposito/autocompleteStock";
import { mensaje } from "@/services/notify.service";
export default {
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        AutocompleteDepositoStock,
    },
    data() {
        return {
            bonificacion: {
                idbonificacion_agro: 0,
                fecha: current_date(),
                idcampana: {
                    idcampana: "",
                },
                iddeposito: {
                    iddeposito: ""
                },
                iditem: {
                    iditem: ""
                },
                idstock: {
                    idstock: ""
                },
                precio: 0,
                comentario: "",
                ensilaje: 0,
            }
        }
    },
    computed: {
        ...mapGetters("campana", ["Vget_campana_data", "Vget_campana_load"]),
    },
    methods: {
        ...mapActions("ventaagro", ["Vact_bonificacionagro_save", "Vact_bonificacionagro_data"]),
        cerrar() {
            this.$emit("input", false)
            this.$emit("datos", null)
            this.Vact_bonificacionagro_data()
        },
        setSelection(id) {
            setTimeout(() => {
                let input = document.getElementById(id)
                input.focus()
                input.setSelectionRange(0, 10)
            }, 200)
        },
        async next(id, data) {
            if (data === "" || data <= 0) return null
            focus.nextidName("bonificacionInput", id, data)
        },
        async guardarBonificacion() {
            this.bonificacion.iditem.iditem = this.bonificacion.idstock.iditem.iditem
            this.bonificacion.iddeposito.iddeposito = this.bonificacion.idstock.iddeposito.iddeposito
            let a = await this.Vact_bonificacionagro_save(this.bonificacion)
            if (!a.estado) {
                return a.info.map((x) => {
                    this.$notify(mensaje.error(x))
                })
            }
            this.$notify(a.info)
            this.$refs.form.resetValidation()
            this.cerrar()
        }
    }
}
</script>