// https://vuex.vuejs.org/en/getters.html

export default {
  Vget_cobrocuenta_load: (state) => {
    return state.cobrocuenta_load;
  },

  Vget_cobrocuenta_data: (state) => {
    return state.cobrocuenta_data;
  },
  Vget_cobrocuenta_data_id: (state) => {
    return state.cobrocuenta_data_id;
  },
  Vget_cobrocuenta_by_id: (state) => {
    return state.cobrocuenta_by_id;
  },
  
  Vget_cobrocuenta_num_rec_load: (state) => {
    return state.cobrocuenta_num_rec_load;
  },
  Vget_cobrocuenta_num_rec: (state) => {
    return state.cobrocuenta_num_rec;
  },
}