<template>
  <v-dialog
    :value="value"
    max-width="900px"
    persistent
    @keydown.esc="cancelar()"
  >
    <v-card>
      <v-toolbar flat dense>
        <v-toolbar-title>Actualizar Numero de Factura</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-tabs color="deep-purple accent-4" right v-model="tab">
            <v-tab :key="1">Registrar</v-tab>
            <v-tab :key="2" @click="fetchVentaRebuild()">Historico</v-tab>
          </v-tabs>
        </v-toolbar-items>
      </v-toolbar>
      <v-tabs-items v-model="tab">
        <v-tab-item :key="1">
          <v-stepper v-model="e6" dense vertical tile>
            <v-stepper-step :complete="e6 > 1" step="1">
              Seleccione las facturas
              <small>No olvide completar los campos</small>
            </v-stepper-step>

            <v-stepper-content step="1">
              <v-row dense class="ma-1 justify-end">
                <v-col cols="12">
                  <SearchVenta />
                </v-col>
                <v-col cols="12">
                  <v-form ref="form">
                    <v-data-table
                      :headers="headers"
                      :items="desserts"
                      show-select
                      :items-per-page="5"
                      v-model="selected"
                      item-key="idventa"
                      class="elevation-1"
                      @toggle-select-all="selectAllToggle"
                    >
                      <template
                        v-slot:[`item.data-table-select`]="{ item, select }"
                      >
                        <v-simple-checkbox
                          v-model="item.select"
                          :ripple="false"
                          @input="
                            !item.select ? (item.newfact = '') : null,
                              select($event)
                          "
                        ></v-simple-checkbox>
                      </template>
                      <template v-slot:[`item.cambiarA`]="{ item }">
                        <c-text-currency
                          :disabled="!item.select"
                          :class="!item.select ? 'mb-n3' : ''"
                          :rules="
                            !item.select
                              ? []
                              : [(v) => !!v || 'Complete este campo']
                          "
                          v-model="item.newfact"
                          :placeholder="item.select ? 'Ingrese numero' : ''"
                          dense
                        ></c-text-currency>
                      </template>
                      <template v-slot:[`item.factura`]="{ item }">
                        {{ formatFact(item) }}
                      </template>
                      <template v-slot:[`item.fechventa`]="{ item }">
                        {{ formatDate(item.fechventa) }}
                      </template>
                      <template v-slot:[`item.estado`]="{ item }">
                        <v-chip dark :color="item.anulventa ? 'red' : 'green'">{{
                          item.anulventa ? "Anulado" : "Activo"
                        }}</v-chip>
                      </template>
                    </v-data-table>
                  </v-form>
                </v-col>
                <v-btn
                  class="mt-3 text-capitalize"
                  :disabled="selected.length === 0"
                  small
                  rounded
                  color="red"
                  :dark="selected.length > 0"
                  @click="continuar()"
                  >Click aqui para continuar
                  <v-icon class="ml-2" small>arrow_forward_ios</v-icon></v-btn
                >
              </v-row>
            </v-stepper-content>

            <v-stepper-step :complete="e6 > 2" step="2">
              Guarde los cambios
            </v-stepper-step>

            <v-stepper-content step="2">
              <v-form ref="form2">
                <v-row dense class="ma-1 justify-end">
                  <v-col cols="12">
                    <v-data-table
                      :headers="headers_confirm"
                      :items="selected"
                      :items-per-page="99999"
                      hide-default-footer
                      class="elevation-1"
                    >
                      <template v-slot:[`item.factura`]="{ item }">
                        {{ formatFact(item) }}
                      </template>
                      <template v-slot:[`item.newfact`]="{ item }">
                        <div class="font-weight-black">
                          {{ formatFactNew(item) }}
                        </div>
                      </template>
                    </v-data-table>
                  </v-col>
                  <v-col cols="12">
                    <c-text-field
                      v-model="data.comentario"
                      label="Escriba un comentario"
                    ></c-text-field>
                  </v-col>
                  <div class="d-flex justify-center align-center">
                    <v-btn
                      class="mt-3 mr-2 text-capitalize"
                      small
                      rounded
                      color="red"
                      dark
                      @click="guardar()"
                      >Guardar los cambios
                      <v-icon class="ml-2" small>save</v-icon></v-btn
                    >
                    <v-btn
                      class="mt-3 text-capitalize red--text"
                      :color="selected.length > 0 ? 'grey lighten-5' : 'red'"
                      small
                      rounded
                      @click="e6 = 1"
                      >Cancelar</v-btn
                    >
                  </div>
                </v-row>
              </v-form>
            </v-stepper-content>
          </v-stepper>
        </v-tab-item>

        <v-tab-item :key="2">
          <v-row dense class="ma-1">
            <v-col cols="12">
              <v-data-table
                :headers="headers_list"
                :items="getVentaRebuild"
                :loading="Vget_venta_load"
                :items-per-page="99999"
                hide-default-footer
                class="elevation-1"
              >
                <template v-slot:[`item.fecha`]="{ item }">
                  {{ formatDate(item.fecha) }}
                </template>
                <template v-slot:[`item.hora`]="{ item }">
                  {{ item.hora.split(".")[0] }}
                </template>
                <template v-slot:[`item.detalle`]="{ item }">
                  <div
                    class="caption"
                    v-html="formatDetalle(item.rebuilddet)"
                  ></div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
      <v-card-actions>
        <c-btn-crud-close @click="cancelar()"></c-btn-crud-close>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { formatFact } from "./calculos";
import { dmy } from "@/services/util/date.service";
import SearchVenta from "./Search";
export default {
  props: {
    value: Boolean,
    datos: Array
  },
  components: {
    SearchVenta,
  },
  data: () => ({
    tab: null,
    selected: [],
    e6: 1,
    headers: [
      {
        text: "Factura",
        align: "start",
        sortable: false,
        value: "factura",
      },

      { text: "Timbrado", align: "left", value: "idtimbrado.timbrado" },
      {
        text: "Cambiar a",
        align: "start",
        sortable: false,
        value: "cambiarA",
      },
      {
        text: "Cliente",
        value: "idcontacto.idcontacto.desccontacto",
        align: "start",
      },
      { text: "Fecha", value: "fechventa", align: "end" },
      { text: "Monto", value: "totalVenta", align: "end" },
      {
        text: "Estado",
        align: "end",
        sortable: false,
        value: "estado",
      },
    ],
    headers_confirm: [
      {
        text: "Cliente",
        align: "start",
        sortable: false,
        value: "idcontacto.idcontacto.desccontacto",
      },
      {
        text: "Facturar Original",
        align: "end",
        sortable: false,
        value: "factura",
      },
      {
        text: "Factura Cambiada",
        align: "end",
        sortable: false,
        value: "newfact",
      },
    ],
    headers_list: [
      {
        text: "Responsable",
        align: "start",
        sortable: false,
        value: "idusuario.first_name",
      },
      {
        text: "Fecha",
        align: "start",
        sortable: false,
        value: "fecha",
      },
      {
        text: "Hora",
        align: "end",
        sortable: false,
        value: "hora",
      },
      {
        text: "Comentario",
        align: "end",
        sortable: false,
        value: "comentario",
      },
      {
        text: "Facturas",
        align: "end",
        sortable: false,
        value: "detalle",
      },
    ],
    data: {
      comentario: "",
      rebuilddet: [],
    },
    desserts: [],
  }),
  computed: {
    ...mapGetters("venta", [
      "Vget_venta_data",
      "getVentaRebuild",
      "Vget_venta_load",
    ]),
  },
  mounted() {
    this.asignarDesserts(this.datos);
  },
  watch: {
    Vget_venta_data(val) {
      this.asignarDesserts(val);
    },
  },
  methods: {
    ...mapActions("venta", ["setVentaRebuild", "fetchVentaRebuild"]),
    cancelar() {
        this.desserts = [];
      this.$emit("input", false);
    },
    selectAllToggle(props) {
      if (this.selected.length != this.desserts.length) {
        this.selected = [];
        const self = this;
        props.items.forEach((item) => {
          item.select = true;
          self.selected.push(item);
        });
      } else this.selected = [];
    },
    asignarDesserts(val) {
      this.desserts = JSON.parse(
        JSON.stringify(
          val.map((x) => ({
            ...x,
            select: false,
            newfact: "",
          }))
        )
      );
    },
    formatFact(item) {
      return formatFact(item);
    },
    formatFactNew(item) {
      return formatFact({ ...item, numfventa: item.newfact });
    },
    formatDate(date) {
      return dmy(date);
    },
    formatDetalle(detalle) {
      return detalle.reduce(
        (acc, curr) =>
          acc +
          `${this.formatFact({
            ...curr,
            numfventa: curr.numfventa_old,
          })} => ${this.formatFactNew({
            ...curr,
            newfact: curr.numfventa,
          })}</br>`,
        ""
      );
    },
    continuar() {
      if (!this.$refs.form.validate()) return null;
      this.e6 = 2;
    },
    async guardar() {
      if (!this.$refs.form2.validate()) return null;
      this.data.rebuilddet = [];
      this.data.rebuilddet = this.selected.map((venta) => ({
        idventa: {
          idventa: venta.idventa,
          numfventa: venta.numfventa,
        },
        numfventa: venta.newfact,
        idtimbrado: {
          idtimbrado: venta.idtimbrado.idtimbrado,
        },
      }));
      const response = await this.setVentaRebuild(this.data);
      if (response.success) {
        this.cancelar();
      }
    },
  },
};
</script>

<style></style>
