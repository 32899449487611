import { get, del, saveform, updateform } from "@/services/api/api.service";
import { SET_LOADING, SET_CONFIG, url } from "./constants";
export default {
  async fetchConfigImpresion({ commit, dispatch }) {
    commit(SET_LOADING, true);
    try {
      const response = await get(url);
      commit(SET_CONFIG, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(SET_LOADING, false);
      throw e;
    }
    commit(SET_LOADING, false);
  },
  async setConfigImpresion({ commit, dispatch }, data) {
    commit(SET_LOADING, true);
    const response = await saveform(url, data);
    dispatch("snackbar/setNotification", response, { root: true });
    commit(SET_LOADING, false);
    return response;
  },
  async setConfigImpresionUpdate({ commit, dispatch }, { data, id }) {
    commit(SET_LOADING, true);
    const response = await updateform(`${url}${id}/`, data);
    dispatch("snackbar/setNotification", response, { root: true });
    commit(SET_LOADING, false);
    return response;
  },
  async setConfigImpresionDelete({ commit, dispatch }, id) {
    commit(SET_LOADING, true);
    const response = await del(`${url}${id}/`);
    dispatch("snackbar/setNotification", response, { root: true });
    commit(SET_LOADING, false);
    return response;
  },
};
