<template>
  <div>
    <v-dialog :value="value" max-width="600px" persistent @keydown.esc="cancelar()">
      <v-card>
        <v-form ref="form" lazy-validation @submit.prevent="guardar()">
          <v-card-title>
            <span>{{operacion ? 'Agregar una Nueva Zona de Cultivo' : 'Editar Zona de Cultivo'}}</span>
          </v-card-title>
          <v-card-text>
            <v-row row dense>
              <vue-text-field v-model="data.descripcion" label="Nombre de la Zona" id="input1"></vue-text-field>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="red" text @click="cancelar">
              <v-icon dark>close</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn ref="aceptar" color="blue darken-1" text @click="guardar">
              <v-icon dark>done</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
        <c-overlay :value="Vget_lotegrupo_save_load"></c-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { mensaje } from "@/services/notify.service";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    "sync-input": {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      data: {
        idlote_grupo: 0,
        descripcion: "",
      },
      default: {
        idlote_grupo: 0,
        descripcion: "",
      },
      operacion: true,
    };
  },
  watch: {
    $route() {
     return this.cancelar();
    },
  },
  computed: {
    ...mapGetters("lotegrupo", [
      "Vget_lotegrupo_update",
      "Vget_lotegrupo_save_load",
    ]),
  },
  created() {
    this.nuevo(this.Vget_lotegrupo_update);
  },
  mounted() {
    if (this.syncInput != null) this.data.descripcion = this.syncInput;
    setTimeout(() => document.getElementById("input1").focus(), 500);
  },
  methods: {
    ...mapActions("lotegrupo", [
      "lotegrupo_update",
      "Vact_lotegrupo_save",
      "Vact_lotegrupo_update",
      "Vact_lotegrupo_data",
    ]),

    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.lotegrupo_update(null);
      this.Vact_lotegrupo_data();
      this.operacion = true;
      this.$emit("input", false);
    },
    async guardar_finalizar() {
      const sync = this.syncInput;
      let a = await this.Vact_lotegrupo_save(this.data);
      if (a.estado == true) {
        if (sync != null) {
          this.$emit("sync-input", a.data.data.id);
          return this.cancelar();
        }
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$notify(a.info);
        this.$refs.form.resetValidation();
        setTimeout(() => document.getElementById("input1").focus(), 500);
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
    },

    async editar_finalizar() {
      const id = this.Vget_lotegrupo_update.idlote_grupo;
      let a = await this.Vact_lotegrupo_update({ id, data: this.data });
      if (a.estado == true) {
        this.$notify(a.info);
        this.cancelar();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
    },
    guardar() {
      if (!this.$refs.form.validate()) return this.$notify(mensaje.completar());
      if (this.operacion === true) return this.guardar_finalizar();
      if (this.operacion === false) return this.editar_finalizar();
    },

    nuevo(val) {
      if (val != null) {
        this.data = JSON.parse(JSON.stringify(val));
        this.operacion = false;
      }
    },
  },
};
</script>