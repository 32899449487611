<template>
  <div>
    <v-dialog :value="value" width="800" persistent @keydown.esc="cancelar()">
      <v-card>
        <v-toolbar flat dense>
          <v-toolbar-title>{{ isEdit ? "Modificar Secado" : "Registrar Secado" }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-checkbox class="mt-5 mr-2" label="Irregular" v-model="irregular"></v-checkbox>
          <v-checkbox class="mt-5" label="Historico" v-model="historico"></v-checkbox>
        </v-toolbar>
        <v-container>
          <Animal-Secado-CreateRef ref="childForm" :irregular="irregular" :historico="historico" :modo="isEdit ? false : true"
            :xidanimal="xidanimal" :props="props" />
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <c-btn-crud-close @click="cancelar()"></c-btn-crud-close>
          <v-spacer></v-spacer>
          <c-btn-crud-done id="aceptar" @click="guardar()"></c-btn-crud-done>
        </v-card-actions>
        <c-overlay :value="isLoading"></c-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    //Este prop es utilizado con el componente <widgets-calendar />
    //Y se hereda al componente CreateRef
    props: Object,
    value: {
      type: Boolean,
      default: false,
    },
    xidanimal: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isEdit: false,
      irregular: false,
      historico: false,
      data: {
        fecssecado: "",
        fechsecado: "2000-01-01",
        comnsecado: "",
        idanimal: {
          idanimal: "",
        },
      },
      default: {
        fecssecado: "",
        fechsecado: "2000-01-01",
        comnsecado: "",
        idanimal: {
          idanimal: "",
        },
      },
      animal: [],
      loadanimal: false,
      isEditAnimal: false,
    };
  },

  watch: {
    $route() {
      return this.cancelar();
    },
  },

  computed: {
    ...mapGetters("secado", ["isLoading"]),
  },
  created() {
    if (this.props) this.isEdit = true;
  },

  methods: {
    ...mapActions("secado", ["fetchNuevo", "setSecado", "setSecadoUpdate"]),

    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$emit("input", false);
      this.$emit("reset", null);
      this.fetchNuevo();
      this.isEdit = false;
      this.isEditAnimal = false;
    },

    async guardar() {
      if (!this.$refs.childForm.validate()) return null;
      this.data = {
        ...JSON.parse(
          JSON.stringify(await this.$refs.childForm.obtenerDatos())
        ),
        irregular: this.irregular,
        historico: this.historico,
      };
      const response = this.isEdit
        ? await this.setSecadoUpdate({
          id: this.data.idsecado,
          data: this.data,
        })
        : await this.setSecado(this.data);
      if (response.success) {
        this.data = JSON.parse(JSON.stringify(this.default));
        setTimeout(() => document.getElementById("input1").focus(), 500);
      }
    },
  },
};
</script>
