<template>
    <v-card class="mx-auto" elevation="0">
        <v-card-title>
            <div class="text-center">
                {{ help.title }}
            </div>
        </v-card-title>
        <v-card-text>
            {{ help.body }}
            <v-expansion-panels accordion class="mt-2">
                <v-expansion-panel v-for="(item, i) in help.elements" :key="i">
                    <v-expansion-panel-header>{{ item.title }}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <div v-for="(item, i) in item.body" :key="`body${i}`">
                            {{ item }}
                        </div>
                        <div v-for="(item, i) in item.actions" :key="`img${i}`" class="mt-4">
                            <v-img class="mt-2" :src="require(`@/assets/help/${item.image}`)"></v-img>
                            <div v-for="(item, i) in item.text" :key="`text${i}`" class="mt-1">
                                {{ item }}
                            </div>
                        </div>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    props: {
        help: Object,
    }
}
</script>