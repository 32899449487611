<template>
  <div>
    <v-card>
      <v-toolbar flat dense>
        <v-toolbar-title> Asistencias </v-toolbar-title>
        <v-spacer></v-spacer>
        <c-text-table-search v-model="search"></c-text-table-search>
        <v-toolbar-items>
          <c-btn-table-add
            @click="crud.add = true"
            v-if="permission.can_add"
            class="mr-1"
          ></c-btn-table-add>
          <c-btn-table-reload
            @click="fetchAsistencia()"
            v-if="permission.can_view"
            class="mr-1"
          ></c-btn-table-reload>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="Vget_gymasistencia_data_by"
        :loading="Vget_gymasistencia_load"
        v-if="permission.can_view"
      >
      <template v-slot:[`item.accion`]="{ item }" v-if="permission.can_view">
        <c-btn-table-delete @click="eliminar(item)"></c-btn-table-delete>
      </template>
      </v-data-table>
      <v-dialog v-model="dialogEliminar" persistent max-width="400">
        <v-card>
          <v-card-title>
            <v-icon left>info</v-icon>
            <span class="subtitle-1 font-weight-light">
              Ventana de Confirmacion
            </span>
          </v-card-title>
          <v-form ref="form" @submit.prevent="eliminarGymrutina()">
            <v-card-subtitle>
              Desea eliminar la asistencia en fecha {{ objeto.fecha }}
            </v-card-subtitle>
          </v-form>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="red" text="text" @click="dialogEliminar = false">
              <v-icon>clear</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" text="text" @click="eliminarGymasistencia()">
              <v-icon>done</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
        <c-overlay :value="overlay" absolute></c-overlay>
      </v-dialog>
    </v-card>
    <Create
      v-if="crud.add"
      v-model="crud.add"
      :props="crud.datos"
      @datos="crud.datos = $event"
      :editable="crud.datos != null ? true: false"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { ASISTENCIA_GIMNASIO } from '@/constants/forms';
import Create from "./Create";
import { mensaje } from "@/services/notify.service";
import { PUEDE_AGREGAR, PUEDE_EDITAR, PUEDE_ELIMINAR, PUEDE_LISTAR } from '@/constants/permissions';
import { getPermission } from "@/services/storage/permissionStorage.service";
export default {
  components: {
    Create
  },
  props: {
    props: Object,
  },
  data: () => ({
    search: "",
    crud: {
      add: false,
      datos: null,
    },
    dialogEliminar: false,
    overlay: false,
    objeto: {
      fecha: ""
    },
    permission: {
      can_add: false,
      can_view: false,
      can_update: false,
      can_delete: false,
    },
    headers: [
      {
        text: "Fecha",
        align: "start",
        sortable: true,
        value: "fecha",
      },
      {
        text: "Accion",
        align: "end",
        sortable: false,
        value: "accion"
      }
    ]
  }),
  mounted() {
    this.fetchAsistencia();
    this.crud.datos = this.$props.props
  },
  created() {
    const permission = getPermission(ASISTENCIA_GIMNASIO).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  computed: {
    ...mapGetters("gymasistencia", [
      "Vget_gymasistencia_data_by",
      "Vget_gymasistencia_load",
    ]),
  },
  methods: {
    ...mapActions("gymasistencia", [
      "Vact_gymasistencia_data_by",
      "gymasistencia_update",
      "Vact_gymasistencia_del",
    ]),
    fetchAsistencia() {
      let id = this.props.idgymplanilla;
      this.Vact_gymasistencia_data_by({ id });
    },
    editar(item) {
      this.gymasistencia_update(item);
      this.crud.datos = JSON.parse(JSON.stringify(item));
      this.crud.add = true;
    },
    eliminar(item) {
      this.dialogEliminar = true;
      this.objeto = item;
    },
    eliminarCancelar() {
      this.objeto.fecha = "";
      this.dialogEliminar = false;
    },
    async eliminarGymasistencia() {
      this.overlay = true;
      const id = this.objeto.idgymasistencia;
      const a = await this.Vact_gymasistencia_del(id);
      if (a.estado == true) {
        this.$notify(a.info);
        this.fetchAsistencia();
        this.eliminarCancelar();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
    },
  }
};
</script>
