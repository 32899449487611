<template>
  <div>
    <v-dialog :value="value" max-width="700px" persistent>
      <v-card>
        <v-toolbar flat dense>
          <v-toolbar-title>
            Detalles
          </v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <v-row row dense>
            <v-col cols="12">
              <v-text-field
                dense
                type="text"
                v-model="data.montcostonacimiento"
                label="Monto"
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                dense
                v-model="data.idmoneda.descmoneda"
                label="Moneda"
                persistent-hint
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                dense
                id="input2"
                v-model="data.idcotizacion.fecha"
                label="Moneda"
                persistent-hint
                outlined
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <c-btn-crud-close @click="cancelar()"></c-btn-crud-close>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    value: Boolean,
    props: Object,
  },
  data() {
    return {
      data: {},
      default: {},
    };
  },
  watch: {
    $route() {
      return this.cancelar();
    },
  },
  created() {
    this.data = JSON.parse(JSON.stringify(this.props));
  },
  methods: {
    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$emit("input", false);
      this.$emit("reset", null);
    },
  },
};
</script>
