<template>
  <div>
    <v-dialog :value="value" max-width="700px" persistent @keydown.esc="cancelar()">
      <v-card>
        <v-form ref="form" lazy-validation>
          <v-toolbar flat>
            <v-toolbar-title>{{!isEdit ? 'Agregar Raza' : 'Modifica Raza'}}</v-toolbar-title>
          </v-toolbar>
          <v-container>
            <v-row row dense>
              <v-col cols="12">
                <v-text-field
                  dense
                  type="text"
                  :rules="validar"
                  id="razaInput1"
                  v-model="data.descraza"
                  label="Descripcion"
                  required
                  outlined
                  autocomplete="off"
                  @keyup.enter="nextfocus(1,data.descraza)"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  dense
                  type="text"
                  :rules="validar"
                  id="razaInput2"
                  v-model="data.abrvraza"
                  label="Abreviatura"
                  required
                  outlined
                  autocomplete="off"
                  @keyup.enter="nextfocus(2,data.abrvraza)"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>

          <v-card-actions>
            <c-btn-crud-close @click="cancelar()"></c-btn-crud-close>
  
            <v-spacer></v-spacer>
            <c-btn-crud-done id="razaInputaceptar" @click="guardar()"></c-btn-crud-done>
          </v-card-actions>
        </v-form>
        <c-overlay :value="isLoading" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { focus } from "@/services/focus.service";
export default {
  props: {
    value: Boolean,
    datos: {
      type: [Object, Array],
      default: null,
    },
    "sync-input": {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      data: {
        descraza: "",
        abrvraza: "",
      },
      default: {
        descraza: "",
        abrvraza: "",
      },
      validar: [(v) => !!v || "Campo requerido"],
      isEdit: false,
      sync: null,
    };
  },
  watch: {
    $route() {
      return this.cancelar();
    }
  },
  computed: {
    ...mapGetters("raza", ["isLoading"]),
  },
  created() {
    this.nuevo(this.datos);
  },
  mounted() {
    setTimeout(() => this.nextfocus(0,'-'), 500);
    if (this.syncInput != null) {
      this.data.descraza = this.syncInput;
      this.sync = this.syncInput;
      }
  },
  methods: {
    ...mapActions("raza", [
      "fetchRaza",
      "setRaza",
      "setRazaUpdate",
    ]),

    nextfocus(index,data) {
      focus.nextidName("razaInput", index, data);
    },

    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$emit("input", false);
      this.$emit("reset", null);
      this.fetchRaza();
      this.isEdit = false;
    },

    async guardar() {
      if (!this.$refs.form.validate()) return null;
      const id = this.data.idraza;
      const response = this.isEdit
        ? await this.setRazaUpdate({ id, data: this.data })
        : await this.setRaza(this.data);
      if (response.success) {
         if (this.sync != null) {
          this.$emit("sync-input", response.data.id);
          this.$emit("next-focus")
          return this.cancelar();
        }
        this.data = JSON.parse(JSON.stringify(this.default));
        this.fetchRaza();
        if (this.isEdit) return this.cancelar();
      }
    },
    nuevo(val) {
      if (val !== null) {
        this.data = JSON.parse(JSON.stringify(val));
        this.isEdit = true;
      }
    },
  },
};
</script>

