export default {


  gymasistencia_data(state, request) {
    state.gymasistencia_data = request;
  },
  gymasistencia_data_by(state, request) {
    state.gymasistencia_data_by = request;
  },
  gymasistencia_data_hoy(state, request) {
    state.gymasistencia_data_hoy = request;
  },
  gymasistencia_update(state, request) {
    state.gymasistencia_update = request;
  }, 
  gymasistencia_load(state, request) {
    state.gymasistencia_load = request;
  }, 
  gymasistencia_save_load(state, request) {
    state.gymasistencia_save_load = request;
  }, 
}