
export default {
  Vget_ajustecuenta_data: (state) => {
    return state.ajustecuenta_data
  },
  Vget_ajustecuenta_load: (state) => {
    return state.ajustecuenta_load
  },
  Vget_ajustecuenta_save_load: (state) => {
    return state.ajustecuenta_save_load
  },
}
