<template>
  <v-btn text color="red" @click="$emit('click')"><v-icon>close</v-icon></v-btn>
</template>

<script>
export default {

}
</script>

<style>

</style>