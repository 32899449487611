<template>
  <div>
    <v-toolbar flat dense>
      <v-toolbar-title>Dashboard</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-tabs right :background-color="darkMode ? 'black' : 'white'" color="deep-purple accent-4" v-model="tab">
          <v-tab v-if="permission.agro.can_view">Agricultura</v-tab>
          <v-tab v-if="permission.animal.can_view">Ganaderia</v-tab>
          <v-tab v-if="permission.gimnasio.can_view">Gimnasio</v-tab>
          <v-tab v-if="permission.comercial.can_view">Comercial</v-tab>
          <v-tab v-if="permission.finanza.can_view">Finanza</v-tab>
          <v-tab v-if="permission.rrhh.can_view">RRHH</v-tab>
          <v-tab>
            <v-icon color="primary">settings</v-icon>
          </v-tab>
        </v-tabs>
      </v-toolbar-items>
    </v-toolbar>
    <Favorites></Favorites>
    <v-divider class="my-1"></v-divider>
    <v-tabs-items v-model="tab">
      <v-tab-item v-if="permission.agro.can_view">
        <Agro></Agro>
      </v-tab-item>
      <v-tab-item v-if="permission.animal.can_view">
        <Ganaderia></Ganaderia>
      </v-tab-item>
      <v-tab-item v-if="permission.gimnasio.can_view">
        <Gimnasio></Gimnasio>
      </v-tab-item>
      <v-tab-item v-if="permission.comercial.can_view">
        <Comercial></Comercial>
      </v-tab-item>
      <v-tab-item v-if="permission.finanza.can_view">
        <FinanzaD></FinanzaD>
      </v-tab-item>
      <v-tab-item v-if="permission.rrhh.can_view">
        <Inicio></Inicio>
      </v-tab-item>
      <v-tab-item>
        <Settings></Settings>
      </v-tab-item>
    </v-tabs-items>
    <div v-if="updates">
      <Updated v-model="updates"/>
    </div>
  </div>
</template>

<script>
import Favorites from "./Favorites";
import Agro from "./Agro";
import Ganaderia from "./Ganaderia/Ganaderia";
import Comercial from "./Comercial";
import Gimnasio from "./Gimnasio";
import Inicio from "../RRHH/Inicio/Inicio";
import FinanzaD from "../Finanzas/Operaciones/Inicio/Inicio";
//import Settings from "./Settings";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { ANIMAL, ZAFRA, VENTA, PLANILLA_GIMNASIO, FINANZA, CONTRATO } from "@/constants/forms";
import { PUEDE_LISTAR } from "@/constants/permissions";
import Updated from "./Updated.vue";
import Settings from "./Settings.vue";
import dbUpdate from '@/db/dbUpdate';

export default {
  components: {
    Favorites,
    Agro,
    Ganaderia,
    Gimnasio,
    Settings,
    Inicio,
    Comercial,
    Updated,
    FinanzaD,
  },
  data() {
    return {
      updates: false,
      tab: null,
      permission: {
        animal: {
          can_view: false,
        },
        agro: {
          can_view: false,
        },
        comercial: {
          can_view: false,
        },
        gimnasio: {
          can_view: false,
        },
        finanza: {
          can_view: false,
        },
        rrhh: {
          can_view: false,
        }
      },
    };
  },
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");

    const permissionA = getPermission(ANIMAL).permiso;
    this.permission.animal = {
      can_view: permissionA[PUEDE_LISTAR],
    };
    const permissionB = getPermission(ZAFRA).permiso;
    this.permission.agro = {
      can_view: permissionB[PUEDE_LISTAR],
    };
    const permissionC = getPermission(VENTA).permiso;
    this.permission.comercial = {
      can_view: permissionC[PUEDE_LISTAR],
    };
    const permissionD = getPermission(PLANILLA_GIMNASIO).permiso;
    this.permission.gimnasio = {
      can_view: permissionD[PUEDE_LISTAR],
    };
    const permissionE = getPermission(FINANZA).permiso;
    this.permission.finanza = {
      can_view: permissionE[PUEDE_LISTAR],
    }
    const permissionR = getPermission(CONTRATO).permiso;
    this.permission.rrhh = {
      can_view: permissionR[PUEDE_LISTAR],
    }
  },
  mounted() {
    dbUpdate.update.where('id').equals(1).toArray().then((x) => {
      if (x.length == 0) {
        dbUpdate.transaction("rw", dbUpdate.update, () => {
          dbUpdate.update.clear()
          dbUpdate.update.put({id: 1, update: true})
          this.updates = true
        })
      }
      for (let y of x) {
        if (y.update == true) {
          this.updates = true
        }
      }
    })
  },
  computed: {
    darkMode() {
      return this.$vuetify.theme.dark;
    },
  },
};
</script>

<style></style>