<template>
  <div>
    <v-toolbar flat dense>
      <v-btn
        text
        x-small
        fab
        outlined
        color="deep-purple accent-4"
        @click="goback"
        class="mr-2"
        ><v-icon>arrow_back</v-icon></v-btn
      >
      <v-toolbar-title>Asistencias de {{ $route.query.name }}</v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider>
    <v-form ref="form">
      <v-toolbar flat>
        <c-text-date
          v-model="filtro.desde"
          hide-details
          label="Desde"
          class="mr-2"
        ></c-text-date>
        <c-text-date
          v-model="filtro.hasta"
          hide-details
          label="Hasta"
        ></c-text-date>
        <v-spacer></v-spacer>
        <v-btn
          text
          outlined
          color="deep-purple accent-4"
          @click="obtenerAsistencias()"
          >Filtrar</v-btn
        >
      </v-toolbar>
    </v-form>
    <v-data-table
      :headers="headers"
      :items="listaAsistencia"
      :loading="isAsistenciaLoading"
      hide-default-footer
      :items-per-page="99999"
      :mobile-breakpoint="750"
    >
      <template v-slot:[`item.turnos`]="props">
        <div class="mt-2 mr-n6" v-for="(item, i) of props.item.data" :key="i">
          <v-row class="d-flex flex-wrap mb-n5 justify-center" dense>
            <v-col cols="4">
              <c-text-field
                solo-inverted
                dense
                :rules="[]"
                :readonly="!item.edit"
                :value="item.inicio_hora"
              ></c-text-field>
            </v-col>
            <div class="mt-3 font-weight-black">:</div>
            <v-col cols="5">
              <v-text-field
                solo-inverted
                dense
                :readonly="!item.edit"
                :value="item.fin_hora"
              >
                <template v-slot:append>
                  <v-icon
                    color="deep-purple accent-4"
                    @click="updateAsistencia(item)"
                    v-if="!item.edit && permission.can_update"
                    >{{item.comentario != null  ? item.comentario != '' ? 'text_snippet' : 'edit' : 'edit'}}</v-icon
                  >
                  <v-icon
                    color="red"
                    @click="updateAsistencia(item, 'delete')"
                    v-if="!item.edit && permission.can_delete"
                    >delete</v-icon
                  >
                </template>
              </v-text-field>
            </v-col>
            <div class="mt-3">{{ item.horaminuto || "0h 0m" }}</div>
          </v-row>
        </div>
      </template>

      <template v-slot:[`item.horas`]="props">
        <div style="min-width: 50px" class="font-weight-bold">
          {{ sumarHoras(props.item.data) }}
        </div>
      </template>

      <template v-slot:[`item.status`]="props">
        <div
          style="min-width:60px;"
          v-if="verificarConsistencia(props.item.data).length === 0"
        >
          <v-progress-linear
            color="green accent-3"
            dark
            value="100"
            height="30"
          >
            <v-icon>check</v-icon>
          </v-progress-linear>
        </div>
        <div v-else class="d-flex flex-column justify-space-between">
          <v-tooltip
            bottom
            v-for="(error, i) of verificarConsistencia(props.item.data)"
            :key="i"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-progress-linear
                class="ma-1"
                v-bind="attrs"
                v-on="on"
                style="min-width:60px;"
                :color="color(error.tipo)"
                dark
                value="100"
                height="30"
              >
                <v-icon>report_problem</v-icon>
              </v-progress-linear>
            </template>
            <span>{{ error.consistencia[0] }}</span>
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
    <UpdateForm
      v-if="dialog.open"
      v-model="dialog.open"
      :props="dialog.data"
      @reset="dialog.data = null"
      @fetch="obtenerAsistencias()"
    />
    <Delete
      v-if="dialog.delete"
      v-model="dialog.delete"
      :props="dialog.data"
      @fetch="obtenerAsistencias()"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { groupByKey } from "@/services/util/array.service";
import { getDayMonthYearFromDate } from "@/services/util/date.service";
import { getPermission } from "@/services/storage/permissionStorage.service";
import UpdateForm from "./Update";
import Delete from "./Delete";
import { ASISTENCIA } from "@/constants/forms";
import { PUEDE_EDITAR, PUEDE_ELIMINAR } from "@/constants/permissions";
export default {
  components: {
    UpdateForm,
    Delete,
  },
  data: () => ({
    filtro: {
      desde: "",
      hasta: "",
    },
    dialog: {
      open: false,
      delete: false,
      data: null,
    },
    headers: [
      {
        text: "Fecha",
        align: "left",
        value: "name",
        sortable: false,
      },
      {
        text: "Turnos Trabajados",
        align: "center",
        value: "turnos",
        sortable: false,
      },
      { text: "Horas", align: "right", value: "horas", sortable: false },
      { text: "Estado", align: "right", value: "status", sortable: false },
    ],
    permission: {
      can_update: false,
      can_delete: false,
    },
  }),
  watch: {
    $route(to) {
      if (to.params.id) {
        this.filtro = {
          desde: this.$route.query.desde,
          hasta: this.$route.query.hasta,
        };
        this.obtenerAsistencias();
      }
    },
  },
  created() {
    const permission = getPermission(ASISTENCIA).permiso;
    this.permission = {
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  mounted() {
    this.filtro = {
      desde: this.$route.query.desde,
      hasta: this.$route.query.hasta,
    };
    this.$nextTick(() => this.obtenerAsistencias());
  },
  computed: {
    ...mapGetters("asistencia", ["getLista", "isAsistenciaLoading"]),

    listaAsistencia() {
      const temp = this.getLista.map((x) => {
        return {
          ...x,
          edit: false,
          day_month: getDayMonthYearFromDate(x.inicio_fecha || x.fin_fecha),
        };
      });
      const data = Object.entries(groupByKey(temp, "day_month")).map((key) => {
        return {
          name: key[0],
          data: key[1],
        };
      });
      return data;
    },
  },

  methods: {
    ...mapActions("asistencia", ["fetchListaAsistencia"]),
    goback() {
      this.$router.go(-1)
    },
    color(status){
      switch(status){
        case 1: return 'amber';
        case 2: return 'primary';
        case 3: return 'red';
        case 4: return 'deep-purple accent-4';
        default: return 'green accent-3';
      }
    },
    obtenerAsistencias() {
      if (this.$refs.form === undefined) return null;
      if (!this.$refs.form.validate()) return null;
      const id = this.$route.params.id;
      const desde = this.filtro.desde;
      const hasta = this.filtro.hasta;
      this.fetchListaAsistencia({ id, desde, hasta });
    },
    sumarHoras(item) {
      const num = item.reduce((acc, curr) => {
        return acc + (curr.total || 0);
      }, 0);
      const temp = num * 60;
      const hours = Math.floor(temp / 60);
      const min = Math.floor(temp % 60);
      return `${hours}h ${min}m`;
    },
    verificarConsistencia(item) {
      let error = [];
      item.map((x) => {
        error = error.concat(x.consistencia.length > 0 ? x : []);
      });
      return error;
    },
    updateAsistencia(item, del) {
      this.dialog.data = JSON.parse(JSON.stringify(item));
      del ? (this.dialog.delete = true) : (this.dialog.open = true);
    },
  },
};
</script>

<style></style>
