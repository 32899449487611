
export default {
  Vget_campo_ha: (state) => {
    return state.campo_ha
  },
  Vget_campo_data: (state) => {
    return state.campo_data
  },
  Vget_campo_clima: (state) => {
    return state.campo_clima
  },
  Vget_campo_storyndvi: (state) => {
    return state.campo_storyndvi
  },
  Vget_campo_pronostico: (state) => {
    return state.campo_pronostico
  },
  Vget_campo_geo_json: (state) => {
    return state.campo_geo_json
  },
  Vget_campo_load: (state) => {
    return state.campo_load
  },
  Vget_campo_save_load: (state) => {
    return state.campo_save_load
  },
  Vget_campo_deleted: (state) => {
    return state.campo_deleted
  },
  Vget_campo_baja: (state) => {
    return state.campo_baja_load
  },
}
