<template>
  <div>
    <v-alert type="error" v-if="permission_alert"
      >Error!!! Este usuario no tiene permisos en esta ventana</v-alert
    >
    <v-toolbar flat dense v-if="permission.historial">
      <v-toolbar-title>{{ titulo }}</v-toolbar-title>
      <widgets-Favorite
        :ruta="$route.path"
        :formulario="titulo"
      />
      
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-spacer></v-spacer>

      <c-text-table-search v-model="search"></c-text-table-search>
    </v-toolbar>
    <v-form ref="form" v-if="permission.historial">
      <v-container>
        <v-row dense>
          <v-col cols="12" sm="3">
            <c-text-date
              autocomplete="off"
              hide-details
              :dense="false"
              :outlined="false"
              label="Fecha Inicio"
              v-model="data.fechaInicio"
            ></c-text-date>
          </v-col>
          <v-col cols="12" sm="3">
            <c-text-date
              autocomplete="off"
              hide-details
              :dense="false"
              :outlined="false"
              label="Fecha Fin"
              v-model="data.fechaFin"
            ></c-text-date>
          </v-col>
          <v-col cols="12" sm="3">
            <v-autocomplete
              :items="transferencia"
              :rules="validar"
              item-text="descripcion"
              item-value="id"
              autocomplete="off"
              hide-details
              multiple
              label="Tipo Transferencia"
              v-model="data.transferencia"
            ></v-autocomplete>
          </v-col>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            fab
            small
            outlined
            @click="descargarReporte()"
          >
            <v-icon>cloud_download</v-icon>
          </v-btn>
        </v-row>
      </v-container>
    </v-form>
    <v-data-table
      :loading="loadReporte"
      :headers="headers"
      :items="datos"
      :search="search"
      v-if="permission.historial"
    >
      <template v-slot:item.destino="props">
        <v-simple-table dense>
          <template v-slot:default>
            <tbody v-for="(item, i) of props.item.detalle" :key="i">
              <tr>
                <th>Destino</th>
                <td>{{ item.destino }}</td>
              </tr>
              <tr>
                <th>Moneda</th>
                <td>{{ item.moneda }}</td>
              </tr>
              <tr>
                <th>Monto</th>
                <td>{{ item.monto }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </template>
      <template v-slot:no-data>
        <v-alert :value="true" color="info" icon="info" outlined
          >No existe ningun registro</v-alert
        >
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { db } from "@/services/db.service";
import { substract_days, current_date } from "@/services/util/date.service";

import { INFORME_CAJA_CUENTA } from "@/constants/forms";
import { HISTORIAL_TRANSFERENCIAS } from "@/constants/permissions";

export default {
  data() {
    return {
      search: "",
      datos: [],
      loadReporte: false,
      validar: [(v) => !!v.length > 0 || "Seleccione una opcion"],
      data: {
        fechaInicio: substract_days(30),
        fechaFin: current_date(),
        transferencia: [],
      },
      transferencia: [
        {
          id: "caja_caja=yes",
          descripcion: "Transferencia de Caja a Caja",
        },
        {
          id: "caja_banco=yes",
          descripcion: "Transferencia de Caja a Banco",
        },
        {
          id: "banco_banco=yes",
          descripcion: "Transferencia de Banco a Banco",
        },
        {
          id: "banco_caja=yes",
          descripcion: "Transferencia de Banco a Caja",
        },
      ],
      headers: [
        { text: "Fecha", value: "fecha" },
        {
          text: "Usuario",
          value: "usu",
        },
        {
          text: "Tipo Transferencia",
          value: "name",
        },
        {
          text: "Origen",
          value: "origen",
        },
        {
          text: "Destino",
          value: "destino",
        },
      ],
      titulo: "Historial de Transferencia",
      permission: {
        historial: false,
      },
    };
  },

  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    const permission = getPermission(INFORME_CAJA_CUENTA).permiso;
    this.permission = {
      historial: permission[HISTORIAL_TRANSFERENCIAS],
    };
  },
  computed: {
    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },

  methods: {
    async descargarReporte() {
      if (!this.$refs.form.validate()) return null;
      this.loadReporte = true;
      try {
        const a = this.data.fechaInicio;
        const b = this.data.fechaFin;
        let options = "";
        this.data.transferencia.map((x) => {
          if (options === "") return (options = options + x);
          options = options + "&" + x;
        });
        const resp = await db.get(`/apireportetranslado/${a}/${b}/?${options}`);
        this.datos = JSON.parse(JSON.stringify(resp));
      } catch (error) {
        this.$notify(mensaje.error(error.message));
      }
      this.loadReporte = false;
    },
  },
};
</script>
