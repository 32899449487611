<template>
  <div>
    <span class="ml-3 subtitle-2 font-weight-medium">Filtrar Datos</span>
    <v-form ref="form">
      <v-row dense class="mx-2">
        <v-col cols="12" sm="3">
          <AutocompleteFilter
            :filled="false"
            :rounded="false"
            :hide-details="false"
            :dense="false"
            v-model="band"
            :rules="[(v) => !!v || 'Obligatorio']"
          />
        </v-col>
        <v-col cols="12" sm="3">
          <c-text-date label="Filtrar desde" v-model="data.desde"></c-text-date>
        </v-col>
        <v-col cols="12" sm="3">
          <c-text-date label="Filtrar hasta" v-model="data.hasta"></c-text-date>
        </v-col>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          fab
          small
          class="mt-3"
          outlined
          text
          @click="obtenerDatos()"
        >
          <v-icon>cloud_download</v-icon>
        </v-btn>
        <v-btn
          color="red"
          fab
          small
          class="ml-1 mt-3"
          outlined
          text
          @click="exportPdf()"
        >
          <v-icon>get_app</v-icon>
        </v-btn>
      </v-row>
    </v-form>
    <v-row dense class="mx-2 mb-2">
      <v-spacer></v-spacer>
      <v-col cols="7" sm="5">
        <c-text-table-search v-model="search"></c-text-table-search>
      </v-col>
    </v-row>

    <v-divider></v-divider>
    <v-data-table
      :headers="headers"
      :loading="isLoading"
      :search="search"
      dense
      :items="getReporteReproduccion"
      :items-per-page="999999999"
    >
      <template v-slot:[`item.PADRE`]="{ item }">
        <BtnAnimal v-if="item.PADRE" @click="irFicha(item.IDPADRE)">{{
          item.PADRE
        }}</BtnAnimal>
      </template>
      <template v-slot:[`item.MADRE`]="{ item }">
        <BtnAnimal v-if="item.MADRE" @click="irFicha(item.IDMADRE)">{{
          item.MADRE
        }}</BtnAnimal>
      </template>
      <template v-slot:[`item.VACA`]="{ item }">
        <BtnAnimal v-if="item.VACA" @click="irFicha(item.IDVACA)">{{
          item.VACA
        }}</BtnAnimal>
      </template>
      <template v-slot:[`item.cria`]="{ item }">
        <div class="fill-height d-flex justify-space-between align-center">
          <BtnAnimal
            v-if="item['NRO. CRIA']"
            @click="irFicha(item.IDCRIA.split(',')[0])"
            >{{ item["NRO. CRIA"].split(",")[0] }}</BtnAnimal
          >
          <BtnAnimal
            v-if="mellizo(item['NRO. CRIA'])"
            @click="irFicha(item.IDCRIA.split(',')[1])"
            >{{ item["NRO. CRIA"].split(",")[1] }}</BtnAnimal
          >
        </div>
      </template>
      <template v-slot:[`item.TORO`]="{ item }">
        <BtnAnimal v-if="item.TORO" @click="irFicha(item.IDTORO)">{{
          item.TORO
        }}</BtnAnimal>
      </template>
      <template v-slot:[`item.ultimaIA`]="{ item }">
        <span class="mx-n2 caption">{{
          formatDate(item["ULTIMA FECHA I.A."])
        }}</span>
      </template>
      <template v-slot:[`item.fechaParto`]="{ item }">
        <div class="ma-n2 pa-1 caption">
          {{ formatDate(item["FECHA DE PARTO"]) }}
        </div>
      </template>
      <template v-slot:[`item.iep`]="{ item }">
        <div>{{ formatNumber(item["I.E.P meses"]) }}</div>
      </template>
      <template slot="body.prepend">
        <tr class="black--text">
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th class="text-right">{{ promedioAI }}</th>
          <th></th>
          <th></th>
          <th></th>
          <th>{{ promedioIEP }}</th>
          <th></th>
          <th class="text-right">{{ promedioDA }}</th>
          <th class="text-right">{{ promedioDL }}</th>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { exportReproducciones } from "./export";
import { current_date, dmy } from "@/services/util/date.service";
import { currency } from "@/services/util/number.service";
import AutocompleteFilter from "../Animal/AutocompleteFilter";
import BtnAnimal from "./BtnAnimal";
export default {
  components: {
    BtnAnimal,
    AutocompleteFilter,
  },
  computed: {
    ...mapGetters("animal", ["getReporteReproduccion", "isLoading"]),
    promedioIEP() {
      const sum = this.getReporteReproduccion.reduce(
        (acc, curr) => (acc = acc + (curr["I.E.P meses"] || 0)),
        0
      );
      const cant = this.getReporteReproduccion.reduce((acc, curr) => {
        return (acc = acc + (curr["I.E.P meses"] === null ? 0 : 1));
      }, 0);
      const result = this.formatNumber(sum / cant);
      return Number.isNaN(result) ? 0 : result;
    },
    promedioAI() {
      const sum = this.getReporteReproduccion.reduce(
        (acc, curr) => (acc = acc + (curr["A.I"] || 0)),
        0
      );
      const cant = this.getReporteReproduccion.reduce(
        (acc, curr) => (acc = acc + (curr["A.I"] === null ? 0 : 1)),
        0
      );
      const result = this.formatNumber(sum / cant);
      return Number.isNaN(result) ? 0 : result;
    },
    promedioDA() {
      const sum = this.getReporteReproduccion.reduce(
        (acc, curr) => (acc = acc + (curr["DIAS ABIERT."] || 0)),
        0
      );
      const cant = this.getReporteReproduccion.reduce(
        (acc, curr) => (acc = acc + (curr["DIAS ABIERT."] === null ? 0 : 1)),
        0
      );
      const result = this.formatNumber(sum / cant);
      return Number.isNaN(result) ? 0 : result;
    },
    promedioDL() {
      const sum = this.getReporteReproduccion.reduce(
        (acc, curr) => (acc = acc + (curr["LACTACION TOTAL"] || 0)),
        0
      );
      const cant = this.getReporteReproduccion.reduce(
        (acc, curr) => (acc = acc + (curr["LACTACION TOTAL"] === null ? 0 : 1)),
        0
      );
      const result = this.formatNumber(sum / cant);
      return Number.isNaN(result) ? 0 : result;
    },
  },
  methods: {
    ...mapActions("animal", ["fetchReporteReproduccion"]),
    exportPdf() {
      exportReproducciones(this.getReporteReproduccion);
    },
    mellizo(cria) {
      if (!cria) return false;
      const crias = cria.split(",");
      return crias.length > 1 ? true : false;
    },
    formatDate(date) {
      return dmy(date);
    },
    formatNumber(number) {
      if (number === null) return "";
      return currency(number);
    },
    irFicha(idanimal) {
      this.$router.push({ path: "/reporteanimal", query: { idanimal } });
    },
    async obtenerDatos() {
      if (!this.$refs.form.validate()) return null;
      const band = this.band.band;
      const inicio = this.data.desde;
      const fin = this.data.hasta;
      await this.fetchReporteReproduccion({ band, inicio, fin });
    },
  },
  data: () => ({
    search: "",
    band: {
      text: "Vivos",
      action: "fetchVivo",
      getter: "getVivo",
      band: "vivo",
    },
    headers: [
      {
        text: "Padre",
        align: "start",
        value: "PADRE",
        divider: true,
      },
      {
        text: "Madre",
        align: "start",
        value: "MADRE",
        divider: true,
      },
      {
        text: "Animal",
        align: "start",
        value: "VACA",
        divider: true,
      },

      {
        text: "Raza",
        align: "start",
        sortable: false,
        value: "RAZA",
        divider: true,
      },
      {
        text: "Ultima I.A.",
        align: "start",
        sortable: false,
        value: "ultimaIA",
        divider: true,
      },
      {
        text: "Toro",
        align: "start",
        sortable: false,
        value: "TORO",
        divider: true,
      },
      {
        text: "A.I",
        align: "end",
        sortable: false,
        value: "A.I",
        divider: true,
      },
      {
        text: "Parto",
        align: "start",
        sortable: false,
        value: "fechaParto",
        divider: true,
      },
      {
        text: "Cria",
        align: "start",
        sortable: false,
        value: "cria",
        divider: true,
      },
      {
        text: "Sexo Cria",
        align: "start",
        sortable: false,
        value: "SEXO CRIA",
        divider: true,
      },
      {
        text: "I.E.P",
        align: "start",
        sortable: false,
        value: "iep",
        divider: true,
      },

      {
        text: "Nro Parto",
        align: "start",
        sortable: false,
        value: "# DE PARTO",
        divider: true,
      },

      {
        text: "Dias Abiertos",
        align: "end",
        sortable: false,
        value: "DIAS ABIERT.",
        divider: true,
      },
      {
        text: "Dias Lact.",
        align: "end",

        sortable: false,
        value: "LACTACION TOTAL",
      },
    ],

    data: {
      desde: "2000-01-01",
      hasta: current_date(),
    },
  }),
};
</script>
