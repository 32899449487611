
export default {

  Vget_deposito_data: (state) => {
    return state.deposito_data
  },
  Vget_deposito_reporte: (state) => {
    return state.deposito_reporte
  },
  Vget_deposito_siembra: (state) => {
    return state.deposito_siembra
  },
  Vget_deposito_load: (state) => {
    return state.deposito_load
  },
  Vget_deposito_save_load: (state) => {
    return state.deposito_save_load
  },
}
