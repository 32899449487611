
export default {

  ajuste_update: (state) => {
    return state.ajuste_update
  },
  Vget_ajuste_data: (state) => {
    return state.ajuste_data
  },
  Vget_ajuste_load: (state) => {
    return state.ajuste_load
  },
  Vget_ajuste_save_load: (state) => {
    return state.ajuste_save_load
  },
}
