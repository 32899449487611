<template>
  <div>
    <v-dialog v-model="value" max-width="700px" persistent @keydown.esc="cancelar()">
      <v-card>
        <v-form ref="form" lazy-validation>
          <v-toolbar flat>
            <v-toolbar-title>
              {{ operacion ? "Registrar Palpacion" : "Modificar Palpacion" }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-checkbox class="mt-5" label="Historico" v-model="data.historico"></v-checkbox>
          </v-toolbar>
          <v-container>
            <v-row dense>
              <v-col cols="4">
                <v-switch v-model="data.band" :label="`Por enfermedad?: ${tipo}`"></v-switch>
              </v-col>
              <v-col cols="4" v-if="data.band === false">
                <v-switch v-model="data.pren" :label="`Está preñada?: ${prenada}`"></v-switch>
              </v-col>
              <v-col cols="4" v-if="data.band === false && data.historico === false">
                <v-switch v-model="data.secada" :label="`Palpar secada? ${secada}`"></v-switch>
              </v-col>
              <v-col cols="12">
                <AutocompleteAnimal :action="fetche" :getter="gett" id="input1" v-model="data.idanimal.idanimal"
                  @keyup.native.enter="next(1, data.idanimal.idanimal)" @change="
                    keyE++, (data.idtactos[0].idenfermedad.idenfermedad = null)
                    " />
              </v-col>

              <v-col cols="12" v-if="data.band === true">
                <AutocompleteEnfermedad :key="keyE" :idanimal="data.idanimal.idanimal"
                  v-model="data.idtactos[0].idenfermedad.idenfermedad" @keyup.native.enter="
                    next(2, data.idtactos[0].idenfermedad.idenfermedad)
                    " />
              </v-col>
              <v-col cols="12">
                <input-autocomplete label="Seleccione un diagnostico" api="/apidiagnosticoutero/"
                  pk="iddiagnosticoutero" title="descdiagnosticoutero" vuex="diagnosticoutero/diagnosticoutero_create"
                  id="input2" permission="Diagnostico Utero" :key="diagnosticoutero_key"
                  v-model="data.iddiagnosticoutero.iddiagnosticoutero" @keyup.native.enter="
                    next(2, data.iddiagnosticoutero.iddiagnosticoutero)
                    " />
                <input-dialog v-if="diagnosticoutero_open" :value="true">
                  <template slot="body">
                    <Enfermedad-Diagnosticoutero-create />
                  </template>
                </input-dialog>
              </v-col>
              <v-col cols="12">
                <c-text-date label="Fecha de registro" id="input3" v-model="data.fecrtacto" dense outlined
                  @keyup.native.enter="next(3, data.fecrtacto)" />
              </v-col>
              <v-col cols="12">
                <c-text-field label="Comentario" id="input4" :rules="[]" dense outlined v-model="data.comntacto"
                  @keyup.native.enter="next(4, '0')" />
              </v-col>
            </v-row>
          </v-container>
          <v-divider></v-divider>
          <v-card-actions>
            <c-btn-crud-close @click="cancelar()"></c-btn-crud-close>
            <v-spacer></v-spacer>
            <c-btn-crud-done id="aceptar" @click="guardar()"></c-btn-crud-done>
          </v-card-actions>
        </v-form>
      </v-card>
      <c-overlay :value="isLoading"></c-overlay>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { booleanConverter } from "@/services/util/boolean.service";
import { current_date } from "@/services/util/date.service";
import { mensaje } from "@/services/notify.service";
import { focus } from "@/services/focus.service";
import AutocompleteAnimal from "@/views/Animal/Animal/AutocompleteAnimal";
import AutocompleteEnfermedad from "@/views/Enfermedad/Enfermedad/AutocompleteEnfermedad";
export default {
  components: {
    AutocompleteAnimal,
    AutocompleteEnfermedad,
  },
  props: {
    //Este prop es utilizado con el componente <widgets-calendar />
    //Y se hereda al componente CreateRef
    value: {
      type: Boolean,
      default: false,
    },
    props: Object,
    xid: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      fetche: "fetchInseminada",
      gett: "getInseminada",
      keyE: 1,
      data: {
        idtacto: 0,
        historico: false,
        fecrtacto: current_date(),
        fechtacto: "2010-10-10",
        idanimal: {
          idanimal: null,
        },
        iddiagnosticoutero: {
          iddiagnosticoutero: 0,
        },
        comntacto: "",
        band: false,
        pren: true,
        secada: false,
        idtactos: [
          {
            idtacto: 0,
            idenfermedad: {
              idenfermedad: "",
            },
          },
        ],
      },
      default: {
        idtacto: 0,
        historico: false,
        fecrtacto: current_date(),
        fechtacto: "2010-10-10",
        idanimal: {
          idanimal: null,
        },
        iddiagnosticoutero: {
          iddiagnosticoutero: 0,
        },
        comntacto: "",
        band: false,
        pren: true,
        secada: false,
        idtactos: [
          {
            idtacto: "",
            idenfermedad: {
              idenfermedad: "",
            },
          },
        ],
      },
      validars: [(v) => !!v || "Seleccione un campo"],
      operacion: true,
      enfermedad_load: true,
      enfermedad: [],
      isEditAnimal: false,
    };
  },
  watch: {
    $route() {
      return this.cancelar();
    },
    'data.secada'() {
      if (this.data.secada == true) {
        this.fetche = 'fetchAnimalSecado'
        this.gett = 'getSecado'
      } else {
        this.fetche = 'fetchInseminada'
        this.gett = 'getInseminada'
      }
    },
    'data.historico'() {
      if (this.data.historico == true) {
        this.fetche = 'fetchTodos'
        this.gett = 'getTodos'
      } else {
        if (this.data.secada == true) {
          this.fetche = 'fetchAnimalSecado'
          this.gett = 'getSecado'
        } else {
          this.fetche = 'fetchInseminada'
          this.gett = 'getInseminada'
        }
      }
    }
  },
  computed: {
    ...mapGetters("tacto", ["isLoading"]),
    ...mapGetters("diagnosticoutero", {
      diagnosticoutero_open: "diagnosticoutero_create",
      diagnosticoutero_key: "diagnosticoutero_key",
    }),

    tipo() {
      return booleanConverter.si_no(this.data.band);
    },
    prenada() {
      return booleanConverter.si_no(this.data.pren);
    },
    secada() {
      return booleanConverter.si_no(this.data.secada);
    }
  },
  created() {
    if (this.xid != null) {
      this.data.idanimal.idanimal = this.xid;
    }
    this.nuevo(this.props);
  },
  mounted() {
    setTimeout(() => document.getElementById("input1").focus(), 500);
  },
  methods: {
    ...mapActions("tacto", ["setTacto", "setTactoUpdate", "fetchNuevo"]),


    ...mapActions("diagnosticoutero", [
      "diagnosticoutero_create",
      "diagnosticoutero_reload",
    ]),
    next(index, data) {
      focus.nextid(index, data);
    },

    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$emit("input", false);
      this.$emit("datos", null);
      this.fetchNuevo();
      this.operacion = true;
    },
    async guardar_finalizar() {
      let response = await this.setTacto(this.data);
      if (response.success) {
        this.data = JSON.parse(JSON.stringify(this.default));
        this.isEditAnimal = true;
        this.$refs.form.resetValidation();
        setTimeout(() => document.getElementById("input1").focus(), 500);
      }
    },
    async editar_finalizar() {
      const id = this.props.idtacto;
      let response = await this.setTactoUpdate({ id, data: this.data });
      if (response.success) {
        this.cancelar();
      }
    },
    guardar() {
      if (this.data.comntacto === "") {
        this.data.comntacto = null;
      }
      if (!this.$refs.form.validate()) return this.$notify(mensaje.completar());
      if (this.operacion) return this.guardar_finalizar();
      return this.editar_finalizar();
    },

    nuevo(val) {
      if (!val) return null;
      this.data = JSON.parse(JSON.stringify(val));
      this.operacion = false;
      if (this.data.idtactos[0] === undefined) {
        this.data.band = false;
      } else {
        this.data.band = true;
      }
    },
  },
};
</script>
