<template>
  <div>
    <!-- contenedor del objeto map extraido de la api de Google -->
    <div
      class="google-map"
      ref="googleMap"
    ></div>
    <!--  -->
    <template v-if="Boolean(this.google) && Boolean(this.map)">
      <slot
        :google="google"
        :map="map"
      />
      
    </template>
  </div>
</template>

<script>
import GoogleMapsApiLoader from "google-maps-api-loader";

export default {
  props: {
    //Objeto de configuracion de Google Maps
    mapConfig: Object,
    libraries: {
      type: [String],
      default: ''
    }
  },

  data() {
    return {
      google: null,
      map: null
    };
  },

  async mounted() {
    const googleMapApi = await GoogleMapsApiLoader({
      libraries: [this.libraries],
      apiKey: this.mapConfig.apiKey
    });
    //Instancia de Google Maps Api
    this.google = googleMapApi;
    this.initializeMap();
  },

  methods: {
    initializeMap() {
      const mapContainer = this.$refs.googleMap;
      //Instancia de la instancia de Google Maps Api... Este es el mapa
      this.map = new this.google.maps.Map(mapContainer, this.mapConfig);
    }
  }
};
</script>

<style scoped>
.google-map {
  width: 100%;
  min-height: 100%;
}
</style>
