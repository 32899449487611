<template>
    <div>
        <v-dialog :value="value" max-width="580" style="max-width: 100%;" persistent
            :fullscreen="$vuetify.breakpoint.xs" @keydown.esc="cerrar()">
            <v-card>
                <v-toolbar flat dense>
                    <v-toolbar-title>
                        Ultimas Novedades
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn color="red" text @click="cerrar()">
                        <v-icon>clear</v-icon>
                    </v-btn>
                </v-toolbar>
                <Novedades></Novedades>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import dbUpdate from '@/db/dbUpdate';
import { getPermission } from "@/services/storage/permissionStorage.service";
import { ANIMAL, ZAFRA, FUNCIONARIO } from '@/constants/forms';
import { PUEDE_LISTAR } from '@/constants/permissions';
import Novedades from './Novedades.vue';
export default {
    components: {
        Novedades
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    created() {
        const animal = getPermission(ANIMAL).permiso
        this.permission.animal = animal[PUEDE_LISTAR]
        const zafra = getPermission(ZAFRA).permiso
        this.permission.zafra = zafra[PUEDE_LISTAR]
        const funcionario = getPermission(FUNCIONARIO).permiso
        this.permission.funcionario = funcionario[PUEDE_LISTAR]
    },
    methods: {
        cerrar() {
            this.$emit("input", false)
            dbUpdate.transaction("rw", dbUpdate.update, () => {
                dbUpdate.update.clear()
                dbUpdate.update.put({ id: 1, update: false })
            })
        },
    },
    data: () => ({
        permission: {
            animal: false,
            zafra: false,
            funcionario: false,
        }
    })
}
</script>