export default {
 
  medicacionmotivo_data(state, request) {
    state.medicacionmotivo_data = request;
  },  
  medicacionmotivo_load(state, request) {
    state.medicacionmotivo_load = request;
  },  
  medicacionmotivo_save_load(state, request) {
    state.medicacionmotivo_save_load = request;
  },  
}