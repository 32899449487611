export default {
 
  veterinario_data(state, request) {
    state.veterinario_data = request;
  }, 
  veterinario_load(state, request) {
    state.veterinario_load = request;
  }, 
  veterinario_save_load(state, request) {
    state.veterinario_save_load = request;
  }, 
}