<template>
  <div>
    <InputAutocomplete
      label="Sexo del Animal"
      item-value="idsexo"
      item-text="descsexocria"
      :id="id"
      ref="input"
      dense
      :rules="rules"
      outlined
      :value="value"
      :items="getSexoCria"
      :loading="isLoading"
      :return-object="returnObject"
      @input="$emit('input', $event)"
      @sync-input="formulario.search = $event"
      @create="formulario.crud = true"
    />
  </div>
</template>

<script>
import InputAutocomplete from "@/ncomponents/InputAutocomplete";
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    id: String,
    value: [String, Object, Number],
    'return-object': Boolean,
    rules: Array
  },
  components: {
    InputAutocomplete,
  },
  data: () => ({
    formulario: {
      crud: false,
      search: "",
    },
  }),

  computed: {
    ...mapGetters("sexocria", ["getSexoCria", "isLoading"]),
  },
  mounted() {
    this.fetchSexoCria();
  },
  methods: {
    ...mapActions("sexocria", [
      "fetchSexoCria",
    ]),

    isMenuActive() {
      this.$refs.input.isMenuActive = false;
    },
    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>

<style></style>
