<template>
  <v-card>
    <v-toolbar flat dense>
      <v-toolbar-title class="text-h6 font-weight-bolt">{{
        $route.name
      }}</v-toolbar-title>
       <widgets-Favorite :key="$route.path" :ruta="$route.path" :formulario="$route.name" />
    </v-toolbar>
    <router-view></router-view>
  </v-card>
</template>

<script>
export default {
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
  }
};
</script>
