import { get, post, put, del } from "@/services/api/api.service";
export default {
    async Vact_inseminacion_data({ commit, dispatch }) {
        commit('inseminacion_load', true);
        try {
            const data = await get('/apiinseminacion/');
            commit('inseminacion_data', data);
            commit('inseminacion_load', false);
            return true
        } catch (e) {
            dispatch('snackbar/setNotification', e, { root: true })
            commit('inseminacion_load', false);
            throw e
        }
    },
    async Vact_inseminacion_byId({ commit, dispatch }, id) {
        commit('inseminacion_load', true);
        try {
            const data = await get(`/apiinseminacion/${id}/`);
            commit('inseminacion_data_id', data);
            commit('inseminacion_load', false);
            return true
        } catch (e) {
            dispatch('snackbar/setNotification', e, { root: true })
            commit('inseminacion_load', false);
            throw e
        }
    },
    async Vact_inseminacion_nuevo({ commit, dispatch }) {
        commit('inseminacion_load', true);
        try {
            const data = await get('/apiinseminacion/?band=nuevo');
            commit('inseminacion_nuevo', data);
            commit('inseminacion_load', false);
            return true
        } catch (e) {
            dispatch('snackbar/setNotification', e, { root: true })
            commit('inseminacion_load', false);
            throw e
        }
    },
    async Vact_inseminacion_historico({ commit, dispatch }) {
        commit('inseminacion_load', true);
        try {
            const data = await get('/apiinseminacion/?band=historico');
            commit('inseminacion_historico', data);
            commit('inseminacion_load', false);
            return true
        } catch (e) {
            dispatch('snackbar/setNotification', e, { root: true })
            commit('inseminacion_load', false);
            throw e
        }
    },
    async Vact_inseminacion_save({ commit, dispatch }, data) {
        commit('inseminacion_save_load', true);
        const resp = await post('/apiinseminacion/', data);
        dispatch('snackbar/setNotification', resp, { root: true });
        commit('inseminacion_save_load', false);
        return resp;

    },
    async Vact_inseminacion_del({ dispatch }, id) {
        const data = await del('/apiinseminacion/' + id);
        dispatch('snackbar/setNotification', data, { root: true });
        dispatch('Vact_inseminacion_nuevo');
        return data
    },
    async Vact_inseminacion_update({ commit, dispatch }, { id, data }) {
        commit('inseminacion_save_load', true);
        const resp = await put('/apiinseminacion/' + id + '/', data);
        dispatch('snackbar/setNotification', resp, { root: true })
        commit('inseminacion_save_load', false);
        return resp;

    },
}
