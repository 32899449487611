<template>
  <div>
    <div v-if="crearNotaCredito">
      <Compra-NotaCredito-Create v-model="crearNotaCredito" />
    </div>
    <div v-if="showNotaCredito">
      <Compra-NotaCredito-Show v-model="showNotaCredito" :show="show" @show="show = $event" />
    </div>
    <v-card>
      <v-alert type="error" v-if="permission_alert">Error!!! Este usuario no tiene permisos en esta ventana</v-alert>
      <v-toolbar flat dense>
        <v-toolbar-title>{{ titulo }}</v-toolbar-title>
        <widgets-Favorite ruta="/notacompra" formulario="Nota Credito Compra" />
        <v-divider class="mx-2" inset vertical></v-divider>

        <v-spacer></v-spacer>
        <v-text-field class="mr-1" v-model="search" append-icon="search" label="Busqueda" single-line hide-details
          v-if="permission.can_view"></v-text-field>

        <v-toolbar-items>
          <c-btn-table-add @click="crearNotaCredito = true" v-if="permission.can_add"></c-btn-table-add>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table :loading="Vget_notacreditocompra_load" :headers="headers" :items="Vget_notacreditocompra_data"
        :search="search" class="elevation-1" v-if="permission.can_view">
        <template v-slot:item.accion="props">
          <v-icon class="mr-2" @click="rowselect(props.item)">visibility</v-icon>
          <v-icon @click="meliminar(props.item)" v-if="permission.can_delete">delete</v-icon>
        </template>
        <template v-slot:no-data>
          <v-alert :value="true" color="info" icon="info" outlined>No existe ningun registro</v-alert>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogAnular" persistent max-width="400">
      <v-card>
        <v-card-title>
          <v-icon left>info</v-icon>
          <span class="title font-weight-light">Ventana de Confirmacion</span>
        </v-card-title>
        <v-form ref="form" @submit.prevent="anularNotaCredito()">
          <v-card-title>
            <vue-text-field label="Por que desea anular esta Operacion?" v-model="comentarioanul"></vue-text-field>
          </v-card-title>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text="text" @click="dialogAnular = false">
            <v-icon>clear</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text="text" @click="anularNotaCredito()">
            <v-icon>done</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { NOTA_CREDITO_COMPRA } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";
export default {
  data() {
    return {
      overlay: false,
      crearNotaCredito: false,
      showNotaCredito: false,
      dialogAnular: false,
      comentarioanul: "",
      show: {},
      search: "",
      headers: [
        { text: "Codigo", align: "left", value: "idnotacreditocompra" },
        { text: "Descripcion", align: "left", value: "comentario" },
        { text: "Fecha", align: "left", value: "fecha" },
        { text: "SubTotal", align: "right", value: "totalNota" },
        { text: "Accion", value: "accion", sortable: false, align: "right" },
      ],
      titulo: NOTA_CREDITO_COMPRA,
      objeto: {},
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
    };
  },
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.Vact_notacreditocompra_data();
    const permission = getPermission(NOTA_CREDITO_COMPRA).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  computed: {
    ...mapGetters("notacreditocompra", [
      "Vget_notacreditocompra_data",
      "Vget_notacreditocompra_load",
    ]),
    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },
  methods: {
    ...mapActions("notacreditocompra", [
      "Vact_notacreditocompra_data",
      "Vact_notacreditocompra_del",
    ]),
    meliminar(item) {
      this.objeto = Object.assign({}, item);
      this.dialogAnular = true;
    },
    eliminarcancelar() {
      this.objeto = {};
      this.dialogAnular = false;
    },
    async anularNotaCredito() {
      this.overlay = true;
      const id = this.objeto.idnotacreditocompra;
      const a = await this.Vact_notacreditocompra_del({
        id,
        params: { comentarioanul: this.comentarioanul },
      });
      if (a.estado == true) {
        this.$notify(a.info);
        this.eliminarcancelar();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.overlay = false;
    },
    rowselect(val) {
      this.show = val;
      this.showNotaCredito = true;
    },
  },
};
</script>
