 <template>
  <div>
    <v-row dense class="mx-1" v-if="reloj.length > 0 && permission.can_view">
      <v-col cols="12" md="3" sm="6" v-for="(item, h) in reloj" :key="h">
        <v-card color="deep-purple" height="140" dark>
          <div class="d-flex flex-row justify-space-between">
            <div>
              <v-card-title class="subtitle-1 font-weight-black">
                {{ item.descripcion }}
              </v-card-title>
              <v-card-subtitle class="mt-0">
                Ultima sincronizacion:
                {{ formatDate(item.fecha) }}
              </v-card-subtitle>
            </div>
            <v-avatar class="ma-3" color="deep-purple lighten-3" size="30">
              <v-icon>schedule</v-icon>
            </v-avatar>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense class="mx-1">
      <v-col
        v-for="(item, i) in getList_events"
        :key="i"
        cols="12"
        md="3"
        sm="6"
      >
        <v-card :color="item.fontcolor" height="140" dark>
          <div class="d-flex flex-row justify-space-between">
            <div>
              <v-card-title class="subtitle-1 font-weight-black">{{
                item.header
              }}</v-card-title>
              <v-card-subtitle class="mt-0">
                {{ item.nombre }} - {{ item.mensaje }}
              </v-card-subtitle>
            </div>
            <v-avatar class="ma-3" :color="item.iconcolor" size="30">
              <v-icon>{{ item.icon }}</v-icon>
            </v-avatar>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="getListReporte.length > 0 & (asistencia.can_report == true | funcionario.can_report == true)">
      <v-col cols="12" md="12" sm="12">
        <v-toolbar flat>
          <v-spacer></v-spacer>
          <c-text-date
            v-model="filtro.desde"
            label="Desde"
            class="mr-2 mt-6"
          ></c-text-date>
          <c-text-date
            v-model="filtro.hasta"
            label="Hasta"
            class="mr-2 mt-6"
          ></c-text-date>
          <v-btn text outlined fab small class="ml-2" @click="obtenerDatos()">
            <v-icon color="primary">cloud_download</v-icon>
          </v-btn>
          <v-btn text outlined fab small class="ml-2" @click="generarExcel()">
            <v-icon color="success">get_app</v-icon>
          </v-btn>
        </v-toolbar>
      </v-col>
      <v-col
        v-for="(item, index) in getListReporte"
        :key="index"
        md="12"
        cols="12"
      >
        <div v-if="item.name == `Calendario`">
          <Asistence />
        </div>
        <div v-if="item.name != `Calendario`">
          <Doughnut
            :title="item.name"
            :api="item.url"
            :reload="reload"
            :desde="filtro.desde"
            :hasta="filtro.hasta"
            @obtener="obtenerExcel"
          />
        </div>
      </v-col>
    </v-row>
    <c-overlay :value="isList_eventsLoading"></c-overlay>
  </div>
</template>
 <script>
import { mapActions, mapGetters } from "vuex";
import { dmy_s } from "@/services/util/date.service";
import { getPermission } from "@/services/storage/permissionStorage.service";
import ApiService from "@/services/auth/api.service";
import Doughnut from "../Reporte/Doughnut.vue";
import Asistence from "@/components/widgets/Asistence";
import { exportInformes } from "../Reporte/export";
import { current_first_date, current_date } from "@/services/util/date.service";
import { RELOJ_BIOMETRICO, FUNCIONARIO, ASISTENCIA } from "@/constants/forms";
import { PUEDE_LISTAR, PUEDE_GENERAR_REPORTE } from "@/constants/permissions";
export default {
  components: {
    Doughnut,
    Asistence,
  },
  mounted() {
    this.fetchList_events();
    this.fetchList_reporte();
    this.init();
  },
  watch: {
    getSocketData(val) {
      if (this.mountedFirst) {
        this.reloj.forEach((element, index) => {
          if (element.key_reloj === val.id) {
            this.reloj[index].fecha = val.fecha;
          }
        });
      }
    },
    getRelojBiometrico(val) {
      this.reloj = JSON.parse(JSON.stringify(val));
      return val;
    },
  },
  created() {
    const permission = getPermission(RELOJ_BIOMETRICO).permiso;
    this.permission = {
      can_view: permission[PUEDE_LISTAR],
    };
    const pasistencia = getPermission(ASISTENCIA).permiso;
    this.asistencia = {
      can_report: pasistencia[PUEDE_GENERAR_REPORTE]
    };
    const pfuncionario = getPermission(FUNCIONARIO).permiso;
    this.funcionario = {
      can_report: pfuncionario[PUEDE_GENERAR_REPORTE]
    }
    this.fetchRelojBiometrico();
  },
  computed: {
    ...mapGetters("dashboardrrhh", [
      "getList_events",
      "isList_eventsLoading",
      "getListReporte",
      "isList_reporteLoading",
    ]),
    ...mapGetters("empresa", ["Vget_empresa_active"]),
    ...mapGetters("relojbiometrico", [
      "getSocketData",
      "getRelojBiometrico",
      "isRelojBiometricoLoading",
    ]),
    ...mapGetters("auth", ["getAccessToken"]),
    data1() {
      if (!this.getSocketData) return {};
      return this.getSocketData;
    },
    current_user() {
      const user = this.Vget_empresa_active.find(
        (x) => x.est_emp_tam_usu === true
      );
      if (user)
        return user.idusuario.first_name != ""
          ? user.idusuario.first_name
          : user.idusuario.username;
      return "";
    },
  },
  methods: {
    ...mapActions("dashboardrrhh", ["fetchList_events", "fetchList_reporte"]),
    ...mapActions("relojbiometrico", ["setSocketData", "fetchRelojBiometrico"]),
    init() {
      this.reset();
      this.connectWebSocket();
    },
    reset() {
      try {
        this.connection.close();
      } catch (error) {
        console.log(error.message);
      }
      this.setSocketData(null);
      this.mountedFirst = true;
    },
    obtenerDatos() {
      this.reload = !this.reload;
    },
    formatDate(date) {
      return dmy_s(date);
    },
    generarExcel() {
      exportInformes(this.data, this.filtro.desde, this.filtro.hasta);
    },
    obtenerExcel(val) {
      let existe = false;
      this.data.forEach((x) => {
        if (x.api == val.api) {
          x.data = val.data;
          existe = true;
        }
      });
      if (existe == false) this.data.push(val);
    },
    connectWebSocket() {
      this.isLoadingSocket = true;
      const wsRoute = "/ws/reloj/?token=" + this.getAccessToken;
      const [protocol, hostWithRoute] = ApiService.getBaseURL().split("//");
      const [wsHost] = hostWithRoute.split("/");
      const wsProtocol = protocol === "https:" ? "wss://" : "ws://";
      this.connection = new WebSocket(wsProtocol + wsHost + wsRoute);
      const self = this;

      this.connection.onmessage = (event) => {
        self.setSocketData(JSON.parse(event.data));
      };
      this.connection.onopen = () => {
        self.isLoadingSocket = false;
        self.setSocketData(null);
        console.info("Successfully connected websocket");
      };
      this.connection.onerror = () => {
        self.setSocketData(null);
        self.isLoadingSocket = false;
        console.error("Failed connected websocket");
      };
      this.connection.onclose = (event) => {
        console.log("WebSocket connection closed:", event.code);
      }
    },
  },
  data: () => ({
    reloj: [],
    mountedFirst: true,
    isLoadingSocket: false,
    data: [],
    reload: false,
    filtro: {
      desde: current_first_date(),
      hasta: current_date(),
    },
    permission: {
      can_view: false,
    },
    funcionario: {
      can_report: false,
    },
    asistencia: {
      can_report: false,
    }
  }),
};
</script>