import { db } from "@/services/db.service";
export default {

    async Vact_referencia_data({commit}){
        commit('referencia_load',true);
        try {
            const data = await db.get('/apireferencia/');
            commit('referencia_data',data);
            commit('referencia_load',false);
            return true
        } catch (e) {
            commit('referencia_load',false);
            return false
        } 
    },
    async Vact_referencia_save({commit},data){
        commit('referencia_save_load',true);
        try {
            const resp = await db.save('/apireferencia/',data);
            commit('referencia_save_load',false);
            return resp;
        } catch (e) {
            commit('referencia_save_load',false);
            return e
        } 
    },
    async Vact_referencia_del({dispatch},id){
        try {
            const data = await db.delete('/apireferencia/'+id);
            dispatch('Vact_referencia_data');
            return data
        } catch (e) {
            return false
        } 
    },
    async Vact_referencia_update({commit},{id, data}){
        commit('referencia_save_load',true);
        try {
            const resp = await db.update('/apireferencia/'+id+'/',data);
            commit('referencia_save_load',false);
            return resp;
        } catch (e) {
            commit('referencia_save_load',false);
            return e
        } 
    },
}
