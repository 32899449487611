<template>
  <v-autocomplete
    :id="id"
    :value="value"
    @input="$emit('input', $event)"
    ref="input"
    :items="idmagnitud ? unidadContrato : Vget_unidadmedida_data"
    :loading="Vget_unidadmedida_load"
    item-value="idunidadmedida"
    :rules="rules"
    :return-object="!returnId ? true : false"
    item-text="descunidadmedida"
    :outlined="outlined"
    :dense="dense"
    :disabled="disabled"
    :chips="chips"
    :small-chips="smallChips"
    :label="label"
  ></v-autocomplete>
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
export default {
  props: {
    id: {
      type: String,
      default: "field"
    },
    idmagnitud: {
      type: Number,
      default: null
    },
    'return-id':{
      type: Boolean,
      default: false
    },
    value: [Object, Number, String],
    outlined: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    chips: {
      type: Boolean,
      default: false,
    },
    "small-chips": {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Unidad de Medida',
    },
    rules:{
      type: Array,
      default: function () {
        
        return !this.returnId ? [(v) => !!v.idunidadmedida || "Este campo es obligatorio"] : [(v) => !!v || "Este campo es obligatorio"];
      },
    },
    disabled:{
      type: Boolean,
      default: false,
    },
  },
  created() {
    if(this.idmagnitud) return this.Vact_unidadmedida_data_magnitud(this.idmagnitud);
    this.Vact_unidadmedida_data();
  },
  computed: {
    ...mapGetters("unidadmedida", ["Vget_unidadmedida_data","Vget_unidadmedida_data_magnitud", "Vget_unidadmedida_load"]),
    unidadContrato(){

      return this.Vget_unidadmedida_data_magnitud.concat({idunidadmedida: null, descunidadmedida: 'Variable'})
    }
  },
  methods: {
    ...mapActions("unidadmedida", ["Vact_unidadmedida_data","Vact_unidadmedida_data_magnitud"]),
    focus(){
      this.$refs.input.focus();
    },
    isMenuActive(){
      this.$refs.input.isMenuActive = false
    }
  },
};
</script>

<style>
</style>