import { current_date } from "@/services/util/date.service";
import { Workbook } from "exceljs";
import * as fs from "file-saver";
export const exportEmpleadosObreros = (datos) => {
  let workbook = new Workbook();
  let worksheet = workbook.addWorksheet("EMPLEADOS Y OBREROS");
  let data = datos.map((x) => {
    return {
      Nropatronal: x.Nropatronal,
      Documento: x.Documento,
      Nombre: x.Nombre,
      Apellido: x.Apellido,
      Sexo: x.Sexo,
      Estadocivil: x.Estadocivil,
      Fechanac: x.Fechanac,
      Nacionalidad: x.Nacionalidad,
      Domicilio: x.Domicilio,
      Fechanacmenor: x.Fechanacmenor,
      hijosmenores: x.hijosmenores,
      cargo: x.cargo,
      profesion: x.profesion,
      fechaentrada: x.fechaentrada,
      horariotrabajo: x.horariotrabajo,
      menorescapa: x.menorescapa,
      menoresescolar: x.menorescolar,
      fechasalida: x.fechasalida,
      motivosalida: x.motivosalida,
      Estado: x.estado,
    };
  });
  worksheet.addRow(Object.keys(data[0]));
  data.forEach((x) => {
    worksheet.addRow(Object.values(x));
  });

  workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    fs.saveAs(blob, `EMPLEADOS Y OBREROS ${current_date()}.xlsx`);
  });
};

export const exportSueldosJornales = (datos) => {
  let workbook = new Workbook();
  let worksheet = workbook.addWorksheet("SUELDO Y JORNALES");
  let data = datos.map((x) => {
    return {
      Nropatronal: x.Nropatronal,
      documento: x.documento,
      formadepago: x.formadepago,
      importeunitario: x.importeunitario,
      h_ene: x.h_ene,
      s_ene: x.s_ene,
      h_feb: x.h_feb,
      s_feb: x.s_feb,
      h_mar: x.h_mar,
      s_mar: x.s_mar,
      h_abr: x.h_abr,
      s_abr: x.s_abr,
      h_may: x.h_may,
      s_may: x.s_may,
      h_jun: x.h_jun,
      s_jun: x.s_jun,
      h_jul: x.h_jul,
      s_jul: x.s_jul,
      h_ago: x.h_ago,
      s_ago: x.s_ago,
      h_set: x.h_set,
      s_set: x.s_set,
      h_oct: x.h_oct,
      s_oct: x.s_oct,
      h_nov: x.h_nov,
      s_nov: x.s_nov,
      h_dic: x.h_dic,
      s_dic: x.s_dic,
      h_50: x.h_50,
      s_50: x.s_50,
      h_100: x.h_100,
      s_100: x.s_100,
      Aguinaldo: x.Aguinaldo,
      Beneficios: x.Beneficios,
      Bonificaciones: x.Bonificaciones,
      Vacaciones: x.Vacaciones,
      total_h: x.total_h,
      total_s: x.total_s,
      totalgeneral: x.totalgeneral,
    };
  });
  worksheet.addRow(Object.keys(data[0]));
  data.forEach((x) => {
    worksheet.addRow(Object.values(x));
  });
  workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    fs.saveAs(blob, `SUELDO Y JORNALES ${current_date()}.xlsx`);
  });
};

export const exportResumenGeneral = (datos) => {
  let workbook = new Workbook();
  let worksheet = workbook.addWorksheet("RESUMEN GENERAL");
  let data = datos.map((x) => {
    return {
      Nropatronal: x.Nropatronal,
      anho: x.anho,
      supjefesvarones: x.supjefesvarones,
      supjefesmujeres: x.supjefesmujeres,
      empleadosvarones: x.empleadosvarones,
      empleadosmujeres: x.empleadosmujeres,
      obrerosvarones: x.obrerosvarones,
      obrerosmujeres: x.obrerosmujeres,
      menoresvarones: x.menoresvarones,
      menoresmujeres: x.menoresmujeres,
      orden: x.orden,
    };
  });
  worksheet.addRow(Object.keys(data[0]));
  data.forEach((x) => {
    worksheet.addRow(Object.values(x));
  });
  workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    fs.saveAs(blob, `RESUMEN GENERAL ${current_date()}.xlsx`);
  });
};

export const exportPlanillaIPS = (datos) => {
  const title = "PLANILLA DE SUELDOS Y JORNALES";
  const header = [
    "Nº",
    "Nombres y Apellidos",
    "Salario Basico",
    "Dias Trabaj.",
    "Percibido en el mes",
    "Diferencia",
    "Salario del Mes",
    "50 %",
    "",
    "100 %",
    "",
    "30 %",
    "",
    "Salario por Hs. Ex.",
    "Comisiones",
    "Preaviso",
    "Indemnizacion",
    "Vacac.",
    "SUB TOTAL",
    "Aporte a IPS (9%",
    "Bonificacion Familiar",
    "Aguinaldo",
    "Otros Descuentos",
    "Salario a Pagar",
    "Establecimiento",
  ];
  const subheaders = [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Hs",
    "Importe",
    "Hs",
    "Importe",
    "Hs",
    "Importe",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ];
  const titles = [
    [`EMPRESA: ${datos.titulo.Empresa}`],
    [`Periodo: ${datos.titulo.Periodo}`],
    [`PATRONAL M.J.T. Nº: ${datos.titulo["Patronal MTESS"]}`],
    [`PATRONAL I.P.S. Nº: ${datos.titulo["Patronal IPS"]}`],
  ];
  let workbook = new Workbook();
  if (datos.detalle.length === 0) workbook.addWorksheet("HOJA 1");
  datos.detalle.map((det) => {
    let data = det.detalle.map((x) => {
      return [
        x["Nº"],
        x["Nombres y Apellidos"],
        x["Salario Basico"],
        x["Dias Trabaj."],
        x["Percibido en el mes"],
        x["Diferencia"],
        x["Salario del Mes"],
        x["50 %"]["Hs"],
        x["50 %"]["Importe"],
        x["100 %"]["Hs"],
        x["100 %"]["Importe"],
        x["30 %"]["Hs"],
        x["30 %"]["Importe"],
        x["Salario por Hs. Ex."],
        x["Comisiones"],
        x["Preaviso"],
        x["Indemnizacion"],
        x["Vacac."],
        x["SUB TOTAL"],
        x["Aporte a IPS (9%)"],
        x["Bonificacion Familiar"],
        x["Aguinaldo"],
        x["Otros Descuentos"],
        x["Salario a Pagar"],
        x["Establecimiento"],
      ];
    });
    let worksheet = workbook.addWorksheet(det.descripcion);
    let titleRow = worksheet.addRow([title]);

    titleRow.font = {
      name: "Arial",
      family: 4,
      size: 20,
      bold: true,
    };
    titles.map((t, i) => {
      let row = worksheet.addRow(t);
      row.font = {
        name: "Arial",
        family: 4,
        size: 14,
        bold: true,
        underline: "double",
      };
      if (i === 0 || i === 3) worksheet.addRow([]);
    });
    let headerRow = worksheet.addRow(header);
    let subHeaderRow = worksheet.addRow(subheaders);
    headerRow.eachCell((cell) => {
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "medium" },
        left: { style: "medium" },
        right: { style: "medium" },
        bottom: { style: "medium" },
      };
    });
    subHeaderRow.eachCell((cell) => {
      cell.border = {
        left: { style: "medium" },
        bottom: { style: "medium" },
        right: { style: "medium" },
      };
    });
    data.forEach((d) => {
      let row = worksheet.addRow(d);
      row.eachCell((cell) => {
        cell.border = {
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
          top: { style: "thin" },
        };
      });
      let style = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "68F5E2" },
      };
      row.getCell(4).fill = style;
      row.getCell(5).fill = style;
    });
    worksheet.columns.map((column) => {
      let widthMax = 0;
      column.values.map((x, i) => {
        if (x.length) if (i > 7) if (x.length > widthMax) widthMax = x.length;
      });
      column.width = widthMax < 8 ? 8 : widthMax;
    });
    worksheet.getCell("A1").alignment = {
      vertical: "top",
      horizontal: "center",
    };
    worksheet.mergeCells("A1:Z1");
    worksheet.mergeCells("H8:I8");
    worksheet.mergeCells("J8:K8");
    worksheet.mergeCells("L8:M8");

    worksheet.mergeCells("A8:A9");
    worksheet.mergeCells("B8:B9");
    worksheet.mergeCells("C8:C9");
    worksheet.mergeCells("D8:D9");
    worksheet.mergeCells("E8:E9");
    worksheet.mergeCells("F8:F9");
    worksheet.mergeCells("G8:G9");
    worksheet.mergeCells("N8:N9");
    worksheet.mergeCells("O8:O9");
    worksheet.mergeCells("P8:P9");
    worksheet.mergeCells("Q8:Q9");
    worksheet.mergeCells("R8:R9");
    worksheet.mergeCells("S8:S9");
    worksheet.mergeCells("T8:T9");
    worksheet.mergeCells("U8:U9");
    worksheet.mergeCells("V8:V9");
    worksheet.mergeCells("W8:W9");
    worksheet.mergeCells("X8:X9");
    worksheet.mergeCells("Y8:Y9");
  });

  workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    fs.saveAs(blob, `PLANILLA DE SUELDOS Y JORNALES ${current_date()}.xlsx`);
  });
};
