export default {
    recetario_data(state, request) {
        state.recetario_data = request;
    },
    recetario_lote(state, request) {
        state.recetario_lote = request;
    },
    recetario_siembra(state, request) {
        state.recetario_siembra = request;
    },
    recetario_load(state, request) {
        state.recetario_load = request;
    }
}