export default {
  medicamento_create(state, request) {
    state.medicamento_create = request;
  }, 
  medicamento_update(state, request) {
    state.medicamento_update = request;
  },
  medicamento_reload(state, request) {
    state.medicamento_reload = request;
  }, 
  medicamento_show(state, request) {
    state.medicamento_show = request;
  }, 
  medicamento_validar(state, request) {
    state.medicamento_validar = request;
  },
}