export default {

  concepto_data(state, request) {
    state.concepto_data = request;
  }, 
  concepto_load(state, request) {
    state.concepto_load = request;
  }, 
  concepto_save_load(state, request) {
    state.concepto_save_load = request;
  }, 
}