<template>
  <div>
    <v-toolbar flat dense>
      <v-toolbar-title class="subtitle-1">{{
        isEdit ? "Modificar plaga detectada" : "Registrar plaga detectada"
      }}</v-toolbar-title>
    </v-toolbar>
    <v-container>
      <v-form ref="form">
        <v-row dense>
          <v-col cols="12">
            <AutocompletePlaga v-model="data.idplagas.idplagas" />
          </v-col>
          <v-col cols="12">
            <c-text-field
              dense
              outlined
              :rules="[]"
              label="Descripcion"
              v-model="data.comentario"
            ></c-text-field>
          </v-col>
          <v-col cols="12">
            <c-text-field
              dense
              outlined
              readonly
              label="Click sobre el mapa para obtener la coordenada"
              v-model="data.poligono"
            ></c-text-field>
          </v-col>
          <v-col cols="12">
            <v-checkbox
              v-model="data.compartido"
              label="Compartir con otros usuarios?"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-form>
      <c-overlay :value="isLoading"></c-overlay>
    </v-container>
    <v-card-actions>
      <v-spacer></v-spacer>
      <c-btn-crud-done @click="guardar"></c-btn-crud-done>
    </v-card-actions>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AutocompletePlaga from "./AutocompletePlaga";
export default {
  components: {
    AutocompletePlaga,
  },
  data: () => ({
    isEdit: false,
    data: {
      fecha: "2000-01-01",
      compartido: true,
      comentario: "",
      idplagas: {
        idplagas: "",
      },
      poligono: null,
    },
    default: {
      fecha: "2000-01-01",
      compartido: true,
      comentario: "",
      idplagas: {
        idplagas: "",
      },
      poligono: null,
    },
  }),
  mounted() {
    if (this.$route.params.id) this.editHandler();
  },
  computed: {
    ...mapGetters("plaga", ["isLoading", "getCoordenada", "getPlagaId"]),
  },
  watch: {
    getCoordenada(val) {
      if (val) this.data.poligono = val;
    },
  },
  methods: {
    ...mapActions("plaga", [
      "setPlaga",
      "setPlagaUpdate",
      "fetchPlaga",
      "fetchPlagaId",
    ]),
    async guardar() {
      if (!this.$refs.form.validate()) return null;
      const response = this.isEdit
        ? await this.setPlagaUpdate({
            data: this.data,
            id: this.$route.params.id,
          })
        : await this.setPlaga(this.data);
      if (response.success && !this.isEdit) {
        this.fetchPlaga();
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();
      }
    },
    async editHandler() {
      this.isEdit = true;
      if (this.getPlagaId)
        return (this.data = JSON.parse(JSON.stringify(this.getPlagaId)));
      await this.fetchPlagaId({ id: this.$route.params.id });
      this.data = JSON.parse(JSON.stringify(this.getPlagaId));
    },
  },
};
</script>

<style></style>
