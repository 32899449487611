<template>
  <div>
    <div v-if="abrircausaaborto">
      <Enfermedad-Causaaborto-create />
    </div>
    <div v-if="abrirresponsable">
      <Animal-Responsable-create />
    </div>
    <v-dialog
      v-model="value"
      max-width="700px"
      persistent
      @keydown.esc="cancelar()"
    >
      <v-card>
        <v-form ref="form" lazy-validation>
          <v-toolbar flat dense>
            <v-toolbar-title>{{
              isEdit ? "Modificar Aborto" : "Registrar Aborto"
            }}</v-toolbar-title>
          </v-toolbar>
          <v-container>
            <v-row row dense>
              <v-col cols="12">
                <AutocompleteAnimal
                  action="fetchPrenada"
                  getter="getPrenada"
                  id="input1"
                  no-create
                  v-model="data.idanimal.idanimal"
                  :animal="animal"
                  @keyup.native.enter="nextfocus(1)"
                />
              </v-col>
              <v-col cols="12">
                <vue-autocomplete-generic
                  label="Seleccione una Causa"
                  id="input2"
                  action="causaaborto/Vact_causaaborto_data"
                  getter="causaaborto/Vget_causaaborto_data"
                  load="causaaborto/Vget_causaaborto_load"
                  vuex="causaaborto/causaaborto_create"
                  pk="idcausaaborto"
                  title="desccausaaborto"
                  permission="Causa Aborto"
                  :create="true"
                  v-model="data.idcausaaborto.idcausaaborto"
                  @keyup.native.enter="nextfocus(2)"
                />
              </v-col>

              <v-col cols="12">
                <vue-autocomplete-generic
                  label="Seleccione un Responsable"
                  id="input3"
                  action="responsable/Vact_responsable_data"
                  getter="responsable/Vget_responsable_data"
                  load="responsable/Vget_responsable_load"
                  vuex="responsable/responsable_create"
                  pk="idresponsable"
                  title="descresponsable"
                  permission="Responsable"
                  :create="true"
                  v-model="data.idresponsable.idresponsable"
                  @keyup.native.enter="nextfocus(3)"
                />
              </v-col>
              <v-col cols="12">
                <c-text-date
                  label="Fecha de registro"
                  id="input4"
                  ref="input4"
                  outlined
                  dense
                  v-model="data.fecraborto"
                  @keyup.native.enter="nextfocus(4)"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  dense
                  type="text"
                  id="input5"
                  ref="input5"
                  v-model="data.comnaborto"
                  label="Comentario"
                  outlined
                  autocomplete="off"
                  @keyup.enter="nextfocus(5)"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>

          <v-card-actions>
            <c-btn-crud-close @click="cancelar()"></c-btn-crud-close>
            <v-spacer></v-spacer>
            <c-btn-crud-done
              id="aceptar"
              @click="dialogotraspaso = true"
            ></c-btn-crud-done>
          </v-card-actions>
        </v-form>
        <c-overlay :value="isLoading"></c-overlay>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogotraspaso" persistent max-width="450">
      <v-card>
        <v-card-title>
          <v-icon left>info</v-icon>
          <span class="title font-weight-light"
            >Seleccione el estado de traspaso</span
          >
        </v-card-title>
        <v-card-actions>
          <v-container>
            <v-row>
              <v-col cols="6" md="12">
                <v-switch
                  v-model="data.productora"
                  inset
                  :label="`Es productora? ${data.productora ? `Si` : `No`}`"
                ></v-switch>
              </v-col>
              <v-col cols="6" md="12">
                <v-switch
                  v-model="data.saltar"
                  inset
                  :label="`Iniciar un nuevo ciclo? ${data.saltar ? `Si` : `No`}`"
                ></v-switch>
              </v-col>
              <v-col cols="12">
                <v-btn color="secondary" text @click="saltar(0)">
                  <v-icon>done</v-icon>
                  Saltar a Disponible
                </v-btn>
              </v-col>
              <v-col cols="12">
                <v-btn color="primary" text @click="saltar(1)">
                  <v-icon>done</v-icon>
                  Pasar a Anestro
                </v-btn>
              </v-col>
              <v-col cols="12">
                <v-btn color="red" text @click="dialogotraspaso = false">
                  <v-icon>clear</v-icon>
                  Cancelar
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { current_date } from "@/services/util/date.service";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { CAUSA_ABORTO, RESPONSABLE } from "@/constants/forms";
import { PUEDE_AGREGAR } from "@/constants/permissions";

export default {
  components: {
    AutocompleteAnimal: () =>
      import("@/views/Animal/Animal/AutocompleteAnimal"),
  },
  props: {
    value: Boolean,
    props: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isEdit: false,
      dialogotraspaso: false,
      data: {
        idaborto: 0,
        fecraborto: current_date(),
        fechaborto: "2010-10-10",
        comnaborto: "",
        idanimal: {
          idanimal: 0,
        },
        idcausaaborto: {
          idcausaaborto: 0,
        },
        idresponsable: {
          idresponsable: 0,
        },
        idestadoanimal: {
          idestadoanimal: 0,
        },
        productora: true,
        saltar: false,
      },
      default: {
        idaborto: 0,
        fecraborto: current_date(),
        fechaborto: "2010-10-10",
        comnaborto: "",
        idanimal: {
          idanimal: 0,
        },
        idcausaaborto: {
          idcausaaborto: 0,
        },
        idresponsable: {
          idresponsable: 0,
        },
        idestadoanimal: {
          idestadoanimal: 0,
        },
        productora: true,
        saltar: false,
      },
      causaaborto_load: true,
      causaaborto: [],
      responsable_load: true,
      responsable: [],
      animal: null,
      
    };
  },
  watch: {
    $route() {
      return this.cancelar();
    },
  },
  computed: {
    ...mapGetters("aborto", ["isLoading"]),
    ...mapGetters("causaaborto", {
      abrircausaaborto: "causaaborto_create",
    }),
    ...mapGetters("responsable", {
      abrirresponsable: "responsable_create",
    }),

    causaaborto_permission() {
      return getPermission(CAUSA_ABORTO).permiso[PUEDE_AGREGAR];
    },
    responsable_permission() {
      return getPermission(RESPONSABLE).permiso[PUEDE_AGREGAR];
    },
  },
  created() {
    this.nuevo(this.props);
  },
  mounted() {
    setTimeout(() => document.getElementById("input1").focus(), 500);
  },
  methods: {
    ...mapActions("aborto", ["setAborto", "setAbortoUpdate", "fetchAborto"]),
    ...mapActions("animal", ["fetchPrenada"]),
    ...mapActions("causaaborto", ["causaaborto_create"]),
    ...mapActions("responsable", ["responsable_create"]),

    nextfocus(x) {
      switch (x) {
        case 1: {
          if (this.data.idanimal.idanimal != "") {
            document.getElementById("input2").focus();
          }
          break;
        }
        case 2: {
          if (this.data.idcausaaborto.idcausaaborto != "") {
            document.getElementById("input3").focus();
          }
          break;
        }
        case 3: {
          if (this.data.idresponsable.idresponsable != "") {
            document.getElementById("input4").focus();
          }
          break;
        }
        case 4: {
          if (this.data.fecraborto != "") {
            document.getElementById("input5").focus();
          }
          break;
        }
        case 5: {
          document.getElementById("aceptar").focus();

          break;
        }
      }
    },

    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$emit("input", false);
      this.$emit("reset", null);
      this.fetchAborto();
      this.isEdit = true;
    },

    async guardar() {
      if (!this.$refs.form.validate()) return null;
      if (this.data.comnaborto === "") {
        this.data.comnaborto = null;
      }
      const id = this.data.idaborto;
      const response = this.isEdit
        ? await this.setAbortoUpdate({ id, data: this.data })
        : await this.setAborto(this.data);
      if (response.success) {
        this.data = JSON.parse(JSON.stringify(this.default));
        this.dialogotraspaso = false;
        if (this.isEdit) return this.cancelar();
        this.$refs.form.resetValidation();
      }
    },

    saltar(paso) {
      if (paso == 1) {
        this.data.idestadoanimal.idestadoanimal = 7;
      } else {
        this.data.idestadoanimal.idestadoanimal = 2;
      }
      this.guardar();
    },

    nuevo(val) {
      if (val !== null) {
        this.isEdit = true;
        this.data = JSON.parse(JSON.stringify(val));
        this.animal = JSON.parse(JSON.stringify(val.idanimal));
      }
    },
  },
};
</script>
