<template>
  <div>
    <v-dialog :value="value" persistent max-width="900">
      <v-card>
        <v-toolbar flat dense>
          <v-toolbar-title>
            <span>
              {{ operacion ? "Agregar " : "Editar " }}Rutina Gimnasio
            </span>
          </v-toolbar-title>
        </v-toolbar>
        <v-container>
          <v-row row dense>
            <v-col cols="12" md="6" sm="6">
              <c-text-date
                id="gymrutina0"
                dense
                outlined
                :readonly="false"
                label="Fecha"
                v-model="data.fecha"
                @keyup.native.enter="(panels = [0]), next(0, data.fecha)"
              ></c-text-date>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <c-text-field
                id="gymrutina1"
                label="Descripcion"
                placeholder="Agregue una descripcion"
                :rules="[!data.descripcion == '' || 'Este campo es requerido']"
                outlined
                dense
                v-model="data.descripcion"
                @keyup.native.enter="next(1, data.descripcion)"
              ></c-text-field>
            </v-col>
            <v-col cols="12" class="d-flex align-center flex-column">
              <v-chip-group
                v-model="data.dias"
                column
                multiple
                color="deep-purple accent-3"
              >
                <v-chip filter outlined :value="1"> Lunes </v-chip>
                <v-chip filter outlined :value="2"> Martes </v-chip>
                <v-chip filter outlined :value="3"> Miercoles </v-chip>
                <v-chip filter outlined :value="4"> Jueves </v-chip>
                <v-chip filter outlined :value="5"> Viernes </v-chip>
                <v-chip filter outlined :value="6"> Sabado </v-chip>
                <v-chip filter outlined :value="7"> Domingo </v-chip>
              </v-chip-group>
            </v-col>
            <v-col cols="12" md="10" sm="10">
              <c-text-field
                id="gymrutina2"
                label="Detalle"
                placeholder="Agregue un detalle"
                :rules="[]"
                outlined
                dense
                v-model="detalle.detalle"
              ></c-text-field>
            </v-col>
            <v-col cols="12" md="2" sm="10">
              <v-btn
                id="gymrutina3"
                class="mx-2"
                fab
                dark
                small
                color="blue"
                @click="adddetalle(), next(2, true)"
              >
                <v-icon dark> add </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-data-table
                :headers="headers"
                :items="data.detalle"
                hide-default-footer
                class="elevation-1"
              >
                <template v-slot:[`item.accion`]="{ item }">
                  <c-btn-table-edit @click="editar(item)"></c-btn-table-edit>
                  <c-btn-table-delete
                    @click="eliminar(item)"
                  ></c-btn-table-delete>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <c-btn-crud-close @click="cancelar()"></c-btn-crud-close>
          <v-spacer></v-spacer>
          <c-btn-crud-done @click="save()"></c-btn-crud-done>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { mensaje } from "@/services/notify.service";
import { focus } from "@/services/focus.service";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    "sync-input": {
      type: String,
      default: null,
    },
    props: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Detalle",
          align: "left",
          value: "detalle",
        },
        {
          text: "Accion",
          align: "right",
          sortable: false,
          value: "accion",
        },
      ],
      data: {
        idgymplanilla: "",
        estado: true,
        fecha: "",
        descripcion: "",
        dias: [],
        detalle: [],
      },
      default: {
        idgymplanilla: {
          idgymplanilla: "",
        },
        estado: true,
        fecha: "",
        descripcion: "",
        dias: [],
        detalle: [],
      },
      operacion: true,
      detalle: {
        detalle: "",
      },
    };
  },
  computed: {
    ...mapGetters("gymrutina", ["Vget_gymrutina_update"]),
  },
  created() {
    this.nuevo(this.Vget_gymrutina_update);
  },
  mounted() {
    setTimeout(() => document.getElementById("gymrutina0").focus(), 500);
    this.data.idgymplanilla = this.$props.props.idgymplanilla;
  },
  methods: {
    ...mapActions("gymrutina", [
      "gymrutina_update",
      "Vact_gymrutina_save",
      "Vact_gymrutina_data_by",
    ]),
    next(index, data) {
      focus.nextidName("gymrutina", index, data);
    },
    nuevo(val) {
      if (val != null) {
        this.data = JSON.parse(JSON.stringify(val));
        this.operacion = false;
      }
    },
    async guardar_finalizar() {
      const sync = this.syncInput;
      let a = await this.Vact_gymrutina_save(this.data);
      if (a.estado == true) {
        if (sync != null) {
          this.$emit("sync-input", a.data.data.id);
          return this.cancelar();
        }
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$notify(a.info);
        setTimeout(() => document.getElementById("gymrutina0").focus(), 500);
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
    },
    save() {
      if (this.operacion) {
        return this.guardar_finalizar();
      } else {
        return this.editar_finalizar();
      }
    },
    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.gymrutina_update(null);
      this.operacion = true;
      this.$emit("input", false);
      this.$emit("datos", null);
      this.Vact_gymrutina_data_by({id: this.$props.props.idgymplanilla});
    },
    verificar_duplicado() {
      let temp = this.data.detalle.find(
        (x) => x.detalle == this.detalle.detalle
      );
      return temp ? false : true;
    },
    adddetalle() {
      let detalle = this.detalle;
      if (!this.verificar_duplicado()) return null;
      this.data.detalle.push({ ...detalle });
    },
    editar(item) {
      this.eliminar(item);
      this.detalle = item;
    },
    eliminar(item) {
      this.index = this.data.detalle.indexOf(item);
      this.data.detalle.splice(this.index);
    },
  },
};
</script>
