<template>
  <div>
    <v-toolbar flat dense>
      <v-toolbar-title
        >Archivos de {{ props.idcontacto.desccontacto }}</v-toolbar-title
      >
    </v-toolbar>
    <v-card tile outlined class="px-1">
      <v-form ref="form">
        <v-row dense>
          <v-col cols="12" sm="6">
            <c-text-field
              label="Descripcion"
              v-model="data.comentario"
            ></c-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              :items="getDocTipo"
              :loading="isLoadingDocTipo"
              :rules="[(v) => !!v || 'Este campo es Obligatorio']"
              v-model="data.iddocumentos_tipo.iddocumentos_tipo"
              item-text="descripcion"
              item-value="iddocumentos_tipo"
              label="Tipo de Documento"
            ></v-select>
          </v-col>
          <v-col cols="9" sm="9">
            <v-file-input
              dense
              accept="image/png,image/jpeg,.doc,.docx,.pdf"
              v-model="documento"
              label="Seleccione el archivo  (PNG, JPG, DOC, DOCX,PDF)"
            ></v-file-input>
          </v-col>
          <v-spacer></v-spacer>
          <c-btn-crud-done @click="guardar()"></c-btn-crud-done>
          <c-btn-crud-close @click="cancelar()"></c-btn-crud-close>
        </v-row>
      </v-form>
    </v-card>
    <v-data-table
      :headers="headers"
      :items="getDocumentos"
      :loading="isFuncionarioLoading"
      :items-per-page="999"
      hide-default-footer
    >
      <template v-slot:[`item.fecha`]="{ item }">
        <div>{{ formatDate(item.fecha) }}</div>
      </template>

      <template v-slot:[`item.documento`]="{ item }">
        <v-btn
          :href="item.documento"
          target="_blank"
          small
          text
          color="deep-purple accent-4"
          >{{ fileName(item.documento)
          }}<v-icon class="ml-2">get_app</v-icon></v-btn
        >
      </template>
      <template v-slot:[`item.option`]="{ item }">
        <v-btn text x-small fab color="primary" @click="editar(item)"
          ><v-icon small>edit</v-icon></v-btn
        >
        <v-btn text x-small fab color="red" @click="eliminarDialog(item)"
          ><v-icon small>delete</v-icon></v-btn
        >
      </template>
    </v-data-table>
    <c-dialog-confirmation
      :value="dialog.eliminar"
      @cancel="dialog.eliminar = false"
      @done="eliminar()"
      :loading="isFuncionarioLoading"
      >Deseas eliminar el documento <strong>{{ dialog.data.comentario }}</strong
      >?</c-dialog-confirmation
    >
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { current_date, dmy } from "@/services/util/date.service";
export default {
  props: {
    props: {
      type: Object,
      require: true,
    },
  },
  data: () => ({
    editable: false,
    dialog: {
      eliminar: false,
      data: {
        comentario: "",
      },
    },
    data: {
      iddocumentos: 0,
      iddocumentos_tipo: {
        iddocumentos_tipo: "",
      },
      idcontacto: {
        idcontacto: "",
      },
      fecha: current_date(),
      comentario: "",
      documento: "",
    },
    default: {
      iddocumentos: 0,
      iddocumentos_tipo: {
        iddocumentos_tipo: "",
      },
      idcontacto: {
        idcontacto: "",
      },
      fecha: current_date(),
      comentario: "",
      documento: "",
    },
    documento: null,
    headers: [
      {
        text: "Documento",
        align: "start",
        sortable: false,
        value: "comentario",
      },
      {
        text: "Tipo de Documento",
        align: "start",
        value: "iddocumentos_tipo.descripcion",
      },
      { text: "Fecha", value: "fecha" },
      { text: "Descargar", value: "documento", align: "right" },
      { text: "Opciones", value: "option", align: "right" },
    ],
  }),
  computed: {
    ...mapGetters("doc_tipo", ["getDocTipo", "isLoadingDocTipo"]),
    ...mapGetters("funcionario", ["getDocumentos", "isFuncionarioLoading"]),
  },
  mounted() {
    this.fetchDocTipo();
    this.fetchDocumentos(this.props.idcontacto.idcontacto);
    this.data.idcontacto = JSON.parse(JSON.stringify(this.props.idcontacto));
  },
  methods: {
    ...mapActions("doc_tipo", ["fetchDocTipo"]),
    ...mapActions("funcionario", [
      "setDocumentos",
      "setDocumentosUpdate",
      "setDocumentosDelete",
      "fetchDocumentos",
    ]),
    formatDate(date) {
      return dmy(date);
    },
    editar(item) {
      this.data = JSON.parse(JSON.stringify(item));
      this.data.idcontacto = JSON.parse(JSON.stringify(item.idcontacto.idcontacto))
      this.editable = true;
      this.documento = null;
    },
    eliminarDialog(item) {
      this.dialog.data = JSON.parse(JSON.stringify(item));
      this.dialog.eliminar = true;
    },
    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
    },
    fileName(file) {
      if (!file) return "";
      const index = file.lastIndexOf("/") + 1;
      const ext = file.substr(index).split(".");
      return ext[ext.length - 1];
    },
    async eliminar() {
      const id = this.dialog.data.iddocumentos;
      const response = await this.setDocumentosDelete(id);
      if (!response.success) return null;
      this.dialog.eliminar = false;
      this.fetchDocumentos(this.props.idcontacto.idcontacto);
    },
    async guardar() {
      if (!this.$refs.form.validate()) return null;
      const response = this.editable
        ? await this.setDocumentosUpdate({
            data: this.getFormData(),
            id: this.data.iddocumentos,
          })
        : await this.setDocumentos(this.getFormData());
      if (response.success) {
        this.fetchDocumentos(this.props.idcontacto.idcontacto);
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();
      }
    },
    getFormData() {
      let Form = new FormData();
      Form.append("iddocumentos", this.data.iddocumentos);
      Form.append(
        "iddocumentos_tipo",
        JSON.stringify(this.data.iddocumentos_tipo)
      );
      Form.append(
        "idcontacto",
        JSON.stringify({ idcontacto: this.data.idcontacto })
      );
      Form.append("fecha", this.data.fecha);
      Form.append("comentario", this.data.comentario);
      if (!this.editable)
        this.documento
          ? Form.append("documento", this.documento)
          : Form.append("documento", "");
      if (this.editable)
        this.documento
          ? Form.append("documento_update", this.documento)
          : Form.append("documento_update", "");
      return Form;
    },
  },
};
</script>
