export default {
  referencia_data(state, request) {
    state.referencia_data = request;
  }, 
  referencia_load(state, request) {
    state.referencia_load = request;
  }, 
  referencia_save_load(state, request) {
    state.referencia_save_load = request;
  }, 
}