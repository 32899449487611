<template>
  <div>
    <v-toolbar flat dense>
      <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
      <widgets-Favorite :ruta="$route.path" :formulario="$route.name" />
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-form ref="form">
      <v-row dense class="mt-2">
        <v-col cols="12" sm="2">
          <v-select
            dense
            v-model="data.mes"
            :items="mes"
            label="Mes"
            item-text="text"
            item-value="id"
            outlined
            multiple
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0">
                <span>{{ item.text }}</span>
              </v-chip>
              <span v-if="index === 1" class="grey--text text-caption">
                (+{{ data.mes.length - 1 }} others)
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col cols="12" sm="2">
          <v-select
            v-model="data.anho"
            dense
            outlined
            :items="year"
            label="Año"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="2">
          <v-autocomplete
            dense
            v-model="data.idmaquinaria_asignacion"
            ref="maquinaria1"
            v-bind:items="getMaquinariaAsignacion"
            label="Maquinaria Asignacion"
            item-value="idmaquinaria_asignacion"
            return-object
            :rules="[
              (v) => !!v.idmaquinaria_asignacion || 'Seleccione una Asignacion',
            ]"
            required
            outlined
            autocomplete="off"
            :no-data-text="`No existe ninguna concidencia`"
          >
            <template slot="selection" slot-scope="data">
              {{ data.item.idmaquinaria.descripcion }} -
              {{ data.item.idmaquinaria.chapa }}
            </template>
            <template slot="item" slot-scope="data">
              <v-list-item-content>
                <v-list-item-title>
                  {{ data.item.idmaquinaria.descripcion }} -
                  {{ data.item.idmaquinaria.chapa }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ data.item.idchofer.nombre }}
                  {{ data.item.idchofer.apellido }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="12" sm="2">
          <v-btn
            text
            fab
            color="primary"
            outlined
            small
            @click="generarReporte()"
          >
            <v-icon>cloud_download</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-card-text v-for="(item, i) of result" :key="i">
      <div v-for="(item2, i2) of item.months" :key="i2" class="mt-2">
        <p class="font-weight-black body-1 flex text-center">
          {{ item.year }} - {{ item2.month }}
        </p>
        <TableResumen :registro="item2"></TableResumen>
      </div>
    </v-card-text>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import TableResumen from "./TableResumen";
export default {
  components: {
    TableResumen,
  },
  data: () => ({
    mes: Array.from({ length: 12 }, (item, i) => {
      return {
        text: new Date(0, i).toLocaleString("es-ES", { month: "long" }),
        id: i + 1,
      };
    }),
    year: Array.from({ length: 10 }, (item, i) => {
      return new Date().getFullYear() - i;
    }),
    data: {
      mes: [],
      anho: 0,
      idmaquinaria_asignacion: {
        idmaquinaria_asignacion: "",
      },
    },
    result: [],
  }),
  created() {
    this.fetchMaquinariaAsignacion();
  },
  computed: {
    ...mapGetters("maquinaria_gasto", ["getMaquinariaReporte"]),
    ...mapGetters("maquinaria_asignacion", [
      "isLoadingMaquinaria",
      "getMaquinariaAsignacion",
    ]),
  },
  methods: {
    ...mapActions("maquinaria_gasto", ["fetchMaquinariaReporte"]),
    ...mapActions("maquinaria_asignacion", ["fetchMaquinariaAsignacion"]),
    async generarReporte() {
      this.result = [];
      this.data.mes.sort(function (a, b) {
        return a - b;
      });
      let mess = this.data.mes.toString();
      let yearr = this.data.anho.toString();
      this.result = await this.fetchMaquinariaReporte({
        month: mess,
        year: yearr,
        asignacion: this.data.idmaquinaria_asignacion.idmaquinaria_asignacion,
      });
    },
  },
};
</script>
