<template>
  <div>
    <v-dialog :value="value" max-width="650" persistent>
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>{{
            !editable ? "Agregar Objetivo Item" : "Modificar Objetivo Item"
          }}</v-toolbar-title>
        </v-toolbar>
        <v-container>
          <v-form ref="form" @submit.prevent="next(1, data.descripcion)">
            <v-row dense>
              <v-col cols="12">
                <vue-text-field
                  label="Descripcion"
                  id="objetivo1"
                  v-model="data.descripcion"
                  @keyup.native.enter="next(1, data.descripcion)"
                ></vue-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <c-btn-crud-close @click="cancelar()"></c-btn-crud-close>
          <v-spacer></v-spacer>
          <c-btn-crud-done
            id="objetivoaceptar"
            @click="guardar()"
          ></c-btn-crud-done>
        </v-card-actions>
        <c-overlay :value="isObjetivoLoading"></c-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { focus } from "@/services/focus.service";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
    },
    "sync-input": {
      type: [String, Number],
      default: null,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sync: null,
      data: {
        idobjetivo: "",
        descripcion: "",
      },
      default: {
        idobjetivo: "",
        descripcion: "",
      },
    };
  },
  watch: {
    $route() {
      return this.cancelar();
    },
  },
  computed: {
    ...mapGetters("objetivo", ["isObjetivoLoading"]),
  },
  mounted() {
    if (this.editable) this.data = JSON.parse(JSON.stringify(this.props));
    setTimeout(() => this.next(0, "-"), 200);
    if (this.syncInput != null) {
      this.data.descripcion = this.syncInput;
      this.sync = this.syncInput;
    }
  },
  methods: {
    ...mapActions("objetivo", [
      "setObjetivo",
      "setObjetivoUpdate",
      "fetchObjetivo",
    ]),
    next(id, data) {
      focus.nextidName("objetivo", id, data);
    },
    cancelar() {
      this.$emit("input", false);
      this.$emit("datos", null);
    },

    async guardar() {
      if (!this.$refs.form.validate()) return null;
      const response = this.editable
        ? await this.setObjetivoUpdate({
            data: this.data,
            id: this.data.idobjetivo,
          })
        : await this.setObjetivo(this.data);
      if (response.success) {
        this.fetchObjetivo();
        if (this.sync != null) {
          this.$emit("sync-input", response.data.id);
          this.$emit("next-focus")
          return this.cancelar();
        }
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();
        if (this.editable) return this.cancelar();
        this.next(0, "-");
      }
    },
  },
};
</script>
