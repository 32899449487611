export default {


  gymrutina_data(state, request) {
    state.gymrutina_data = request;
  },
  gymrutina_data_by(state, request) {
    state.gymrutina_data_by = request;
  },
  gymrutina_data_hoy(state, request) {
    state.gymrutina_data_hoy = request;
  },
  gymrutina_update(state, request) {
    state.gymrutina_update = request;
  }, 
  gymrutina_load(state, request) {
    state.gymrutina_load = request;
  }, 
  gymrutina_save_load(state, request) {
    state.gymrutina_save_load = request;
  }, 
}