export default [
    {
        path: '/cajabanco',
        name: 'Cajas y Bancos',
        component: () => import( /* webpackChunkName: "About" */ '@/views/CajaBanco/Principal.vue'),
        children: [
            {
                path: 'transaccion',
                meta: {
                    tab: 0
                },
                component: () => import( /* webpackChunkName: "Cotizacion" */ '@/views/RouteViewer.vue'),
                children: [
                    {
                        path: 'emitircheque',
                        meta: {
                            tab: 0
                        },
                        component: () => import( /* webpackChunkName: "List" */ '@/views/CajaBanco/EmisionCheque/EmisionCheque.vue'),
                    },
                    {
                        path: 'confirmacioncheque',
                        meta: {
                            tab: 0
                        },
                        component: () => import( /* webpackChunkName: "List" */ '@/views/CajaBanco/Cheque/ConfirmacionCheque.vue'),
                    }
                ]
            },
            {
                path: 'transferencia',
                meta: {
                    tab: 0
                },
                component: () => import( /* webpackChunkName: "Cotizacion" */ '@/views/RouteViewer.vue'),
                children: [
                    {
                        path: 'cajacaja',
                        meta: {
                            tab: 0
                        },
                        component: () => import( /* webpackChunkName: "List" */ '@/views/CajaBanco/Transferencia/transladocaja/List.vue'),
                    },
                    {
                        path: 'cajabanco',
                        meta: {
                            tab: 0
                        },
                        component: () => import( /* webpackChunkName: "List" */ '@/views/CajaBanco/Transferencia/TransladoCajaBanco/TransladoCajaBanco.vue'),
                    },
                    {
                        path: 'bancocaja',
                        meta: {
                            tab: 0
                        },
                        component: () => import( /* webpackChunkName: "List" */ '@/views/CajaBanco/Transferencia/TransladoBancoCaja/TransladoBancoCaja.vue'),
                    }
                    ,
                    {
                        path: 'bancobanco',
                        meta: {
                            tab: 0
                        },
                        component: () => import( /* webpackChunkName: "List" */ '@/views/CajaBanco/Transferencia/TransladoBancoBanco/TransladoBancoBanco.vue'),
                    },
                    {
                        path: 'historial',
                        meta: {
                            tab: 0
                        },
                        component: () => import( /* webpackChunkName: "List" */ '@/views/CajaBanco/Transferencia/HistorialTransferencia/HistorialTransferencia.vue'),
                    }
                ]
            },
            {
                path: 'ajuste',
                meta: {
                    tab: 0
                },
                component: () => import( /* webpackChunkName: "Cotizacion" */ '@/views/RouteViewer.vue'),
                children: [
                    {
                        path: 'caja',
                        meta: {
                            tab: 0
                        },
                        component: () => import( /* webpackChunkName: "List" */ '@/views/CajaBanco/Ajuste/AjusteCaja/AjusteCaja.vue'),
                    },
                    {
                        path: 'cuentabancaria',
                        meta: {
                            tab: 0
                        },
                        component: () => import( /* webpackChunkName: "List" */ '@/views/CajaBanco/Ajuste/AjusteCuenta/AjusteCuenta.vue'),
                    }
                ]
            },
            {
                path: 'mantenimiento',
                meta: {
                    tab: 0
                },
                component: () => import( /* webpackChunkName: "Cotizacion" */ '@/views/RouteViewer.vue'),
                children: [
                    {
                        path: 'caja',
                        meta: {
                            tab: 0
                        },
                        component: () => import( /* webpackChunkName: "List" */ '@/views/Apertura/Caja/Caja.vue'),
                    },
                    {
                        path: 'banco',
                        meta: {
                            tab: 0
                        },
                        component: () => import( /* webpackChunkName: "List" */ '@/views/Apertura/Banco/Banco.vue'),
                    },
                    {
                        path: 'cuentabanco',
                        meta: {
                            tab: 0
                        },
                        component: () => import( /* webpackChunkName: "List" */ '@/views/Apertura/Cuentabanco/Cuentabanco.vue'),
                    },
                    {
                        path: 'chequera',
                        meta: {
                            tab: 0
                        },
                        component: () => import( /* webpackChunkName: "List" */ '@/views/CajaBanco/Cheque/Cheque.vue'),
                    },
                    {
                        path: 'tarjetacredito',
                        meta: {
                            tab: 0
                        },
                        component: () => import( /* webpackChunkName: "List" */ '@/views/CajaBanco/TarjetaCredito/TarjetaCredito.vue'),
                    }
                ]
            }
        ]
    }
]

