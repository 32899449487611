<template>
  <div>
    <v-dialog
      :value="value"
      max-width="900px"
      persistent
      @keydown.esc="cancelar()"
    >
      <v-form ref="form" lazy-validation>
        <v-card>
          <v-toolbar flat dense>
            <v-toolbar-title>Detalles de Transferencia</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="6" sm="6">
                <c-text-date
                  readonly
                  dense
                  outlined
                  label="Fecha"
                  v-model="data.fecha"
                ></c-text-date>
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <c-text-field
                  readonly
                  dense
                  outlined
                  label="Stock"
                  v-model="data.idstock.iditem.descitem"
                ></c-text-field>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <c-text-field
                  readonly
                  dense
                  outlined
                  label="Observacion"
                  v-model="data.comentario"
                ></c-text-field>
              </v-col>
            </v-row>
            <DataTable-Detalle
              :headers="headers"
              :items="data.transferenciadetalle"
            />
          </v-card-text>
          <v-card-actions>
            <v-btn color="red" text @click="cancelar">
              <v-icon>clear</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import dataTableDetalle from "./Detalle";
export default {
  components: {
    "DataTable-Detalle": dataTableDetalle,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    datos: {
      type: [Object, Array],
      default: null,
    },
  },
  data() {
    return {
      data: {
        search: null,
        idtransferencia: 0,
        idstock: {
          idstock: "",
          iditem: {
            iditem: "",
            descitem: "",
          },
        },
        iddeposito: null,
        iditem: null,
        fecha: "",
        comentario: "",
        transferenciadetalle: [],
      },
      default: {
        search: null,
        idtransferencia: 0,
        idstock: {
          idstock: "",
          iditem: {
            iditem: "",
            descitem: "",
          },
        },
        iddeposito: null,
        iditem: null,
        fecha: "",
        comentario: "",
        transferenciadetalle: [],
      },
      headers: [
        {
          text: "Deposito",
          sortable: false,
          value: "iddeposito.desdeposito",
        },
        { text: "Cantidad", sortable: false, value: "cantidad" },
      ],
    };
  },
  computed: {
    ...mapGetters("transferencia", ["getTransferenciaById"]),
  },
  async mounted() {
    try {
      await this.Vact_transferencia_byId(this.datos.idtransferencia);
      this.data = JSON.parse(JSON.stringify(this.getTransferenciaById));
    } catch (e) {
      this.cancelar();
    }
  },
  methods: {
    ...mapActions("transferencia", ["Vact_transferencia_byId"]),
    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$emit("input", false);
      this.$emit("datos", null);
    },
  },
};
</script>