export const SET_LOADING = 'SET_LOADING'
export const SET_VENTA = 'SET_VENTA'
export const SET_ITEM_VENTA = 'SET_ITEM_VENTA'
export const SET_VENTA_ADV = 'SET_VENTA_ADV'
export const SET_VENTA_BYID = 'SET_VENTA_BYID'
export const SET_REIMPRESION = 'SET_REIMPRESION'
export const SET_REBUILD = "SET_REBUILD"

//export const url = '/apiventa/'

export const url = {
    venta: '/apiventa/',
    itemventa: '/apiventaitem/',
    advanced: '/apiventaitemadvanced/',
    reimpresion: 'reimpresion/',
    rebuild: '/apiventarebuild/'
}