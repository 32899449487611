<template>
  <div>
      <v-form ref="form" v-if="permission">
        <v-toolbar flat>
          <v-col>
            <v-autocomplete
              :items="[{id:'subtotal',desc: 'Movimiento'},{id:'cantidad' , desc: 'Cantidad'}]"
              item-text="desc"
              item-value="id"
              return-object
              :rules="validar"
              autocomplete="off"
              hide-details
              label="Tipo de Reporte"
              v-model="tipoSeleccionado"
            >
              <template v-slot:no-data>
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title>No se encontro ningun Lote</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col>
            <c-text-date
              autocomplete="off"
              :rules="validar"
              hide-details
              label="Fecha Inicio"
              id="input3"
              v-model="fechaInicio"
            ></c-text-date>
          </v-col>
          <v-col>
            <c-text-date
              autocomplete="off"
              :rules="validar"
              hide-details
              label="Fecha Fin"
              id="input4"
              v-model="fechaFin"
            ></c-text-date>
          </v-col>
          <v-col>
            <v-autocomplete
              :items="Vget_moneda_data"
              item-text="descmoneda"
              item-value="idmoneda"
              :rules="validar"
              autocomplete="off"
              hide-details
              label="Moneda"
              v-model="monedaSeleccionada"
            >
              <template v-slot:no-data>
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-title>No se encontro ninguna Moneda</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>
          <v-divider class="mx-2" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn text color="primary" id="aceptar" outlined @click="descargarDatos()">
            <v-icon>cloud_download</v-icon>
          </v-btn>
        </v-toolbar>
      </v-form>
      <v-card-text v-if="permission">
        <v-row dense row v-if="response.top_cliente.length > 0 || response.top_proveedor.length > 0">
          <v-col cols="12" sm="3">

            <v-card v-if="grafico"  max-height="250">
              <v-progress-linear value="100"></v-progress-linear>
              <v-card-title class="subtitle-1 font-weight-bold mb-0 pb-0">Top Clientes - {{ titleVenta }}</v-card-title>
              <v-chart :options="optionCliente" autoresize  />
            </v-card>
          </v-col>
          <v-col cols="12" sm="3">
            <v-card v-if="grafico"  max-height="250">
              <v-progress-linear value="100"></v-progress-linear>
              <v-card-title class="subtitle-1 font-weight-bold mb-0 pb-0">Top Item Venta - {{titleVenta}}</v-card-title>
              <v-chart :options="optionItemVenta" autoresize  />
            </v-card>
          </v-col>
          <v-col cols="12" sm="3">
            <v-card v-if="grafico"  max-height="250">
              <v-progress-linear value="100"></v-progress-linear>
              <v-card-title class="subtitle-1 font-weight-bold mb-0 pb-0">Top Proveedor - {{titleCompra}}</v-card-title>
              <v-chart :options="optionProveedor" autoresize  />
            </v-card>
          </v-col>
          <v-col cols="12" sm="3">
            <v-card v-if="grafico"  max-height="250">
              <v-progress-linear value="100"></v-progress-linear>
              <v-card-title class="subtitle-1 font-weight-bold mb-0 pb-0">Top Item Compra - {{titleCompra}}</v-card-title>
              <v-chart :options="optionItemCompra" autoresize  />
            </v-card>
          </v-col>
        </v-row>

        <v-alert
          v-else
          :value="true"
          dense
          color="warning"
          icon="warning"
          outlined
        >No existe ningun dato filtrado</v-alert>
        <c-overlay :value="overlay" absolute></c-overlay>
      </v-card-text>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { current_first_date, current_date } from "@/services/util/date.service";
import { db } from "@/services/db.service";
import grafico from "./GraficoTop";
import { mensaje } from "@/services/notify.service";
import ECharts from "vue-echarts/components/ECharts";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { REPORTE_COMERCIAL } from "@/constants/forms";
import { GRAFICO_RANKING } from "@/constants/permissions";
export default {
  components: {
    "v-chart": ECharts
  },
  data() {
    return {
      permission: false,
      validar: [v => !!v || "Seleccion un campo"],
      tipoSeleccionado:{ id:'subtotal',desc: 'Ganancia'},
      monedaSeleccionada: 1,
      fechaInicio: current_first_date(),
      fechaFin: current_date(),

      response: {
        top_cliente: [],
        top_item: [],
        top_proveedor: []
      },
      overlay: false,
      grafico: false,
      optionCliente: {},
      optionItemVenta: {},
      optionProveedor: {},
      optionItemCompra: {}
    };
  },
  props: {
    search: [String, Number]
  },
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
  },
  mounted() {
    this.permission = getPermission(REPORTE_COMERCIAL).permiso[GRAFICO_RANKING];
    if(this.permission)
    this.Vact_moneda_data();
    
  },
  watch: {
    darkMode() {
      this.generarGrafico(this.response)
    },
    Vget_moneda_data(val){
      this.monedaSeleccionada = val[0].idmoneda;
      this.descargarDatos();
    }
  },
  computed: {
    ...mapGetters("moneda", ["Vget_moneda_data", "Vget_moneda_load"]),
    titleVenta(){
      return this.tipoSeleccionado.id === 'subtotal' ? 'Ingreso' : 'Cantidad'
    },
    titleCompra(){
      return this.tipoSeleccionado.id === 'subtotal' ? 'Egreso' : 'Cantidad'
    },
    darkMode() {
      return this.$vuetify.theme.dark;
    }
  },
  methods: {
    ...mapActions("moneda", ["Vact_moneda_data"]),

    async descargarDatos() {
      try {
        if (!this.$refs.form.validate()) return null;
        this.overlay = true;
        const tipo = this.tipoSeleccionado.id;
        const fecha_i = this.fechaInicio;
        const fecha_f = this.fechaFin;
        const idmoneda = this.monedaSeleccionada;
        this.response = await db.get(
          `apireportecompraventa/${tipo}/${fecha_i}/${fecha_f}/${idmoneda}/`
        );
        this.generarGrafico(this.response);
        this.overlay = false;
      } catch (error) {
        this.$notify(mensaje.error(error.message));
        this.overlay = false;
      }
    },
    generarGrafico(data) {

      this.optionCliente = grafico.avanzado(
        data.top_cliente,
        this.darkMode,
        this.tipoSeleccionado.id
      );
      this.optionItemVenta = grafico.avanzado(
        data.top_items,
        this.darkMode,
        this.tipoSeleccionado.id
      );
      this.optionProveedor = grafico.avanzado(
        data.top_proveedor,
        this.darkMode,
        this.tipoSeleccionado.id
      );
      this.optionItemCompra = grafico.avanzado(
        data.top_items_compra,
        this.darkMode,
        this.tipoSeleccionado.id
      );
      setTimeout(()=>this.grafico = true,50)
    }
  }
};
</script>
<style scoped>
.chart-wrapper {
  width: 100%;
  height: 220px;
}
.echarts {
  width: 100%;
  height: 220px;
}
</style>
