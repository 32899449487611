import { get, post, put, del } from "@/services/api/api.service";
import { SET_LOADING, SET_LOADING_MONTO, SET_MAQUINARIA_SALARIO, SET_MAQUINARIA_MONTO, url, url2 } from './constants'
export default {
    async fetchMaquinariaSalario({ commit, dispatch }) {
        commit(SET_LOADING, true);
        try {
            const response = await get(url);
            commit(SET_MAQUINARIA_SALARIO, response);
            commit(SET_LOADING, false);
            return response
        } catch (e) {
            dispatch('snackbar/setNotification', e, { root: true });
            commit(SET_LOADING, false);
            throw e
        }
    },
    async fetchMaquinariaMonto({ commit, dispatch }, {y, m, a}) {
        commit(SET_LOADING_MONTO, true);
        try {
            const response = await get(`${url2}?year=${y}&month=${m}&asignacion=${a}`);
            commit(SET_MAQUINARIA_MONTO, response);
            commit(SET_LOADING_MONTO, false);
            return response
        } catch (e) {
            dispatch('snackbar/setNotification', e, { root: true });
            commit(SET_LOADING_MONTO, false);
            throw e
        }
    },
    async setMaquinariaSalario({ commit, dispatch }, data) {
        commit(SET_LOADING, true);
        const response = await post(url, data);
        dispatch('snackbar/setNotification', response, { root: true })
        commit(SET_LOADING, false);
        return response
    },
    async setMaquinariaSalarioUpdate({ commit, dispatch }, { data, id }) {
        commit(SET_LOADING, true);
        const response = await put(`${url}${id}/`, data);
        dispatch('snackbar/setNotification', response, { root: true })
        commit(SET_LOADING, false);
        return response
    },
    async setMaquinariaSalarioDelete({ commit, dispatch }, id) {
        commit(SET_LOADING, true);
        const response = await del(`${url}${id}/`);
        dispatch('snackbar/setNotification', response, { root: true })
        commit(SET_LOADING, false)
        return response;
    },
}
