import { 
    SET_LOADING_TIPO, 
    SET_TIPO, 
    SET_LOADING_CUENTA, 
    SET_CUENTA, 
    SET_LOADING_GRUPO, 
    SET_GRUPO, 
    SET_CATEGORIA, 
    SET_LOADING_CATEGORIA, 
    SET_MOVIMIENTO, 
    SET_LOADING_MOVIMIENTO,
    SET_SALDO,
    SET_LOADING_SALDO,
    SET_MOVIMIENTO_CUENTA,
    SET_LOADING_MOVIMIENTO_CUENTA,
    SET_GASTO_MENSUAL,
    SET_LOADING_GASTO_MENSUAL,
    SET_TIEMPO,
    SET_LOADING_TIEMPO,
    SET_TRANSFERENCIA,
    SET_LOADING_PRESUPUESTO_DISPONIBLE,
    SET_PRESUPUESTO_DISPONIBLE
} from "./constants";
export default {
    [SET_TIPO](state, request) {
        state.tipo = request;
    },
    [SET_LOADING_TIPO](state, request) {
        state.loadingTipo = request;
    },
    [SET_CUENTA](state, request) {
        state.cuenta = request;
    },
    [SET_LOADING_CUENTA](state, request) {
        state.loadingCuenta = request;
    },
    [SET_GRUPO](state, request) {
        state.grupo = request;
    },
    [SET_LOADING_GRUPO](state, request) {
        state.loadingGrupo = request;
    },
    [SET_CATEGORIA](state, request) {
        state.categoria = request;
    },
    [SET_LOADING_CATEGORIA](state, request) {
        state.loadingCategoria = request;
    },
    [SET_MOVIMIENTO](state, request) {
        state.movimiento = request;
    },
    [SET_TRANSFERENCIA](state, request) {
        state.transferencia = request;
    },
    [SET_LOADING_MOVIMIENTO](state, request) {
        state.loadingMovimiento = request;
    },
    [SET_SALDO](state, request) {
        state.saldo = request;
    },
    [SET_LOADING_SALDO](state, request) {
        state.loadingSaldo = request;
    },
    [SET_MOVIMIENTO_CUENTA](state, request) {
        state.movimientoCuenta = request;
    },
    [SET_LOADING_MOVIMIENTO_CUENTA](state, request) {
        state.loadingMovimientoCuenta = request;
    },
    [SET_GASTO_MENSUAL](state, request) {
        state.gastoMensual = request;
    },
    [SET_LOADING_GASTO_MENSUAL](state, request) {
        state.loadingGastoMensual = request;
    },
    [SET_TIEMPO](state, request) {
        state.tiempo = request;
    },
    [SET_LOADING_TIEMPO](state, request) {
        state.loadingTiempo = request;
    },
    [SET_PRESUPUESTO_DISPONIBLE](state, request) {
        state.presupuestoDisponible = request;
    },
    [SET_LOADING_PRESUPUESTO_DISPONIBLE](state, request) {
        state.loadingPresupuestoDisponible = request;
    },
}