export default {

  puntoventa_data(state, request) {
    state.puntoventa_data = request;
  }, 
  puntoventa_punto(state, request) {
    state.puntoventa_punto = request;
  }, 
  puntoventa_byIdSucursal(state, request) {
    state.puntoventa_byIdSucursal = request;
  }, 
  puntoventa_parametros(state, request) {
    state.puntoventa_parametros = request;
  },
  puntoventa_load(state, request) {
    state.puntoventa_load = request;
  }, 
  puntoventa_save_load(state, request) {
    state.puntoventa_save_load = request;
  }, 
}