
<template>
  <div>
    <div class="text-center ma-2">
      <v-snackbar bottom right :value="updateExists" :timeout="-1" color="primary">
        <div class="text-h6">
          Atención
        </div>
        <div>
          Hay una nueva actualización disponible
        </div>
        <v-btn text @click="refreshApp" color="blue">
          Instalar
        </v-btn>
      </v-snackbar>
    </div>
    <component :is="layout"></component>
    <notifications group="auth"/>
    <notifications group="app"/>
  </div>

</template>
<script>
  import LoginLayout from './components/layouts/Login'
  import PrincipalLayout from './components/layouts/Principal'
  import update from './mixins/update'
  import {mapState} from 'vuex'
  export default {
    components: {LoginLayout, PrincipalLayout},
    mixins: [update],
    computed: mapState(['layout']),
  }
</script>