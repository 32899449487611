export const PUEDE_AGREGAR = 'Puede agregar';
export const PUEDE_EDITAR = 'Puede editar';
export const PUEDE_ELIMINAR = 'Puede eliminar';
export const PUEDE_LISTAR = 'Puede listar';
export const PUEDE_GENERAR_REPORTE = 'Puede generar reporte';
export const PUEDE_BONIFICAR = 'Puede bonificar';
export const LIMITE_DE_REGISTRO = 'Limite de registros';
export const SALDO_CAJA_CUENTA = 'Saldos de Caja y Cuenta';
export const HISTORIAL_TRANSFERENCIAS = 'Historial de Transferencias';
export const ESTADO_RESULTADO = 'Estado de Resultado';
export const LIBRO_VENTA = 'Libro de Ventas';
export const LIBRO_COMPRA = 'Libro de Compras';
export const GRAFICO_RANKING = 'Grafico y Ranking';
export const PUEDE_EDITAR_NUNFACTURA = 'Puede editar Nro de Factura';
export const PUEDE_VER_NUMEROS = 'Puede Ver Numeros';
export const SENAVE = 'Senave';