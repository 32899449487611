import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import { TokenService } from '@/services/auth/storage.service'

//dbaciones de archivos de rutas
import router_principal from '@/router/router_principal'
import router_animal from '@/router/router_animal'
import router_items from '@/router/router_items'
import router_analisis from '@/router/router_analisis'
import router_finanzas from '@/router/router_finanzas'
import router_mantenimientos from '@/router/router_mantenimientos'
import router_personas from '@/router/router_personas'
import router_enfermedad from '@/router/router_enfermedad'
import router_compra from '@/router/router_compra'
import router_apertura from '@/router/router_apertura'
import router_stock from '@/router/router_stock'
import router_venta from '@/router/router_venta'
import router_produccion from '@/router/router_produccion'
import router_ajuste from '@/router/router_ajuste'
import router_agro from '@/router/router_agro'
import router_sistema from '@/router/router_sistema'
import router_cajabanco from '@/router/router_cajabanco'
import router_rrhh from  './router/router_rrhh'
import router_gym from './router/router_gym'
import router_maquinaria from './router/router_maquinaria'

Vue.use(Router)

export let routes_import = []
routes_import = routes_import.concat(
  router_principal,
  router_animal,
  router_items,
  router_analisis,
  router_finanzas,
  router_mantenimientos,
  router_personas,
  router_enfermedad,
  router_compra,
  router_apertura,
  router_stock,
  router_venta,
  router_ajuste,
  router_produccion,
  router_agro,
  router_maquinaria,
  router_sistema,
  router_cajabanco,
  router_rrhh,
  router_gym
);



const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes_import
})


router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.public)
  const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut)
  const loggedIn = !!TokenService.getToken();
  if (!isPublic && !loggedIn && to.path !== '/login') {
    return next({
      path: '/login',
      query: {
        redirect: to.fullPath
      }
    });
  }

  if (loggedIn && onlyWhenLoggedOut) {
    return next('/')
  }
  next();
})

Vue.use(Meta)

export default router
//Si buscas en que momento se abre el formulario post-login, revisa en el store de "Auth"