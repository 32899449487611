import { db } from "@/services/db.service";
export default {

    async Vact_stock_data({commit}){
        commit('stock_load',true);
        try {
            const data = await db.get('/apistock/');
            commit('stock_data',data);
            commit('stock_load',false);
            return true
        } catch (e) {
            commit('stock_load',false);
            return false
        } 
    },
}
