
export default {
  Vget_pedidoventa_data: (state) => {
    return state.pedidoventa_data
  },
  Vget_pedidoventa_presupuesto: (state) => {
    return state.pedidoventa_presupuesto
  },
  Vget_pedidoventa_reporte: (state) => {
    return state.pedidoventa_reporte
  },
  Vget_pedidoventa_finalizado: (state) => {
    return state.pedidoventa_finalizado
  },
  Vget_pedidoventa_byId: (state) => {
    return state.pedidoventa_byId
  },
  Vget_pedidoventa_load: (state) => {
    return state.pedidoventa_load
  },
  Vget_pedidoventa_save_load: (state) => {
    return state.pedidoventa_save_load
  },
}
