<template>
  <div>
    <div v-if="formularios.banco">
      <Apertura-Banco-create v-model="formularios.banco" />
    </div>
    <div v-if="abrirtipocuenta">
      <Apertura-Tipocuenta-create />
    </div>

    <v-dialog :value="value" max-width="700px" persistent @keydown.esc="cancelar()">
      <v-card>
        <v-form ref="form" lazy-validation>
          <v-toolbar flat>
            <v-toolbar-title>{{operacion ? 'Registrar Cuenta Bancaria' : 'Modificar Cuenta Bancaria'}}</v-toolbar-title>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text>
            <v-row row dense>
              <v-col cols="12" md="12">
                <v-text-field
                  dense
                  type="text"
                  :rules="validar"
                  id="cuentaBancoInput1"
                  v-model="data.desccuentabanco"
                  label="Descripcion"
                  outlined
                  autocomplete="off"
                  @keyup.enter="next(1,data.desccuentabanco)"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete
                  dense
                  v-model="data.idbanco.idbanco"
                  id="cuentaBancoInput2"
                  :rules="validars"
                  v-bind:items="Vget_banco_data"
                  fkdes="item"
                  label="Seleccione un Banco"
                  item-text="descbanco"
                  item-value="idbanco"
                  outlined
                  :loading="Vget_banco_load"
                  @keyup.enter="next(2,data.idbanco.idbanco)"
                  :no-data-text="`No existe ninguna concidencia`"
                  autocomplete="off"
                >
                  <template v-if="noData && banco_permission" v-slot:no-data>
                    <v-list-item @click="formularios.banco = true">
                      <v-list-item-content>
                        <v-list-item-title>No existe concidencia. Click para agregar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete
                  dense
                  v-model="data.idtipocuentabanco.idtipocuentabanco"
                  id="cuentaBancoInput3"
                  :rules="validars"
                  v-bind:items="tipocuenta"
                  fkdes="item"
                  label="Seleccione el Tipo de Cuenta"
                  item-text="fkdes"
                  item-value="id"
                  outlined
                  :loading="tipocuenta_load"
                  @keyup.enter="next(3,data.idtipocuentabanco.idtipocuentabanco)"
                  :no-data-text="`No existe ninguna concidencia`"
                  autocomplete="off"
                >
                  <template v-if="noData && tipocuenta_permission" v-slot:no-data>
                    <v-list-item @click="tipocuenta_create(true)">
                      <v-list-item-content>
                        <v-list-item-title>No existe concidencia. Click para agregar</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete
                  dense
                  v-model="data.idmoneda.idmoneda"
                  id="cuentaBancoInput4"
                  :rules="validars"
                  v-bind:items="moneda"
                  fkdes="item"
                  label="Seleccione Moneda"
                  item-text="fkdes"
                  item-value="id"
                  outlined
                  :loading="moneda_load"
                  @keyup.enter="next(4,data.idmoneda.idmoneda)"
                  :no-data-text="`No existe ninguna concidencia`"
                  autocomplete="off"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  dense
                  type="text"
                  :rules="validar"
                  id="cuentaBancoInput5"
                  v-model="data.numrcuenta"
                  label="Nro. Cuenta"
                  outlined
                  autocomplete="off"
                  @keyup.enter="next(5,data.numrcuenta)"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  dense
                  type="text"
                  :rules="validar"
                  id="cuentaBancoInput6"
                  v-model="data.nomtitular"
                  label="Titular"
                  outlined
                  autocomplete="off"
                  @keyup.enter="next(6,data.nomtitular)"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <vue-text-currency
                  id="cuentaBancoInput7"
                  v-model="data.sobregiro"
                  label="Monto de Sobregiro"
                  @keyup.native.enter="next(7,data.sobregiro)"
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="red" text @click="cancelar">
              <v-icon dark>close</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn id="cuentaBancoInputaceptar" color="primary" text @click="guardar()">
              <v-icon dark>done</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
        <v-overlay :value="Vget_cuentabanco_save_load">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { db } from "@/services/db.service";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { focus } from "@/services/focus.service";
import { BANCO, TIPO_CUENTA_BANCO } from "@/constants/forms";
import {
  PUEDE_AGREGAR
} from "@/constants/permissions";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    datos: {
      type: Object,
      default: null,
    },
     "sync-input": {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      overlay: false,
      noData: true,
      titulo: "Cuenta Banco",
      data: {
        idcuentabanco: 0,
        numrcuenta: "",
        desccuentabanco: "",
        nomtitular: "",
        idbanco: {
          idbanco: 0,
        },
        idmoneda: {
          idmoneda: "",
        },
        idtipocuentabanco: {
          idtipocuentabanco: 0,
        },
        monto: 0,
        sobregiro: 0,
        sobregirousado: 0,
      },
      default: {
        idcuentabanco: 0,
        numrcuenta: "",
        desccuentabanco: "",
        nomtitular: "",
        idbanco: {
          idbanco: 0,
        },
        idmoneda: {
          idmoneda: "",
        },
        idtipocuentabanco: {
          idtipocuentabanco: 0,
        },
        monto: 0,
        sobregiro: 0,
        sobregirousado: 0,
      },
      url: "/apicuentabanco/",
      validar: [(v) => !!v || "Campo requerido"],
      validars: [(v) => !!v || "Seleccione un campo"],
      formulario: {},
      operacion: true,
      banco_load: true,
      banco: [],
      tipocuenta_load: true,
      tipocuenta: [],
      moneda_load: true,
      moneda: [],
      formularios:{
        banco: false
      }
    };
  },
  watch: {
    $route(to, from) {
      if (from.path === "/cuentabanco") return this.cancelar();
    },

    get_tipocuenta_reload(val) {
      if (val) return this.nuevo_tipocuenta();
    },
  },
  computed: {
    ...mapGetters("banco",["Vget_banco_data","Vget_banco_load"]),
    ...mapGetters("cuentabanco", ["Vget_cuentabanco_save_load"]),

    ...mapGetters("tipocuenta", {
      abrirtipocuenta: "tipocuenta_create",
      get_tipocuenta_reload: "tipocuenta_reload",
    }),

    banco_permission() {
      return getPermission(BANCO).permiso[PUEDE_AGREGAR];
    },
    tipocuenta_permission() {
      return getPermission(TIPO_CUENTA_BANCO).permiso[PUEDE_AGREGAR];
    },
  },
  created() {
    this.nuevo(this.datos);
  },
  mounted() {
    this.Vact_banco_data();
    setTimeout(() => this.next(0,'-'), 200);
    if (this.syncInput != null) this.data.desccuentabanco = this.syncInput;
  },
  methods: {
    ...mapActions("cuentabanco", [
      "Vact_cuentabanco_data",
      "Vact_cuentabanco_save",
      "Vact_cuentabanco_update",
    ]),
    ...mapActions('caja',["Vact_caja_cajacuenta"]),
    ...mapActions("banco", ["Vact_banco_data"]),
    ...mapActions("tipocuenta", ["tipocuenta_create", "tipocuenta_reload"]),

    next(index, data) {
      focus.nextidName("cuentaBancoInput", index, data);
    },

    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.Vact_cuentabanco_data();
      this.Vact_caja_cajacuenta();
      this.$emit("input", false);
      this.$emit("datos", null);
      this.operacion = true;
    },
    async guardar_finalizar() {
      const sync =  this.syncInput;
      let a = await this.Vact_cuentabanco_save(this.data);
      if (a.estado == true) {
        if (sync != null) {
          this.cancelar();
          return this.$emit('sync-input',a.data.data.id);
        }
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$notify(a.info);
        this.$refs.form.resetValidation();
        this.next(0,'-');
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.overlay = false;
    },
    async editar_finalizar() {
      const id = this.datos.idcuentabanco;
      let a = await this.Vact_cuentabanco_update({ id, data: this.data });
      if (a.estado == true) {
        this.$notify(a.info);
        this.cancelar();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
    },
    guardar() {
      if (!this.$refs.form.validate()) return this.$notify(mensaje.completar());
      this.overlay = true;
      if (this.operacion === true) return this.guardar_finalizar();
      if (this.operacion === false) return this.editar_finalizar();
    },

    nuevo_tipocuenta() {
      db.get("/apitipocuentabanco/").then((a) => {
        a.map((element) => {
          this.tipocuenta.push({
            id: element.idtipocuentabanco,
            fkdes: element.descripcion,
          });
        });
      });
      this.tipocuenta_load = false;
      this.tipocuenta_reload(false);
    },
    nuevo_moneda() {
      db.get("/apimoneda/").then((a) => {
        a.map((element) => {
          this.moneda.push({
            id: element.idmoneda,
            fkdes: element.descmoneda,
          });
        });
      });
      this.moneda_load = false;
    },
    nuevo(val) {
      this.nuevo_tipocuenta();
      this.nuevo_moneda();
      if (val !== null) {
        this.data = val;
        this.operacion = false;
        this.titulo = "Editar Cuenta";
      }
    },
  },
};
</script>