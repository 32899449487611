<template>
  <v-autocomplete
    :items="items"
    dense
    label="Tipo"
    :id="id"
    ref="input"
    :readonly="readonly"
    :value="value"
    @change="$emit('change')"
    append-icon
    item-value="id"
    item-text="text"
    outlined
    @focus="$emit('focus')"
    @input="$emit('input', $event)"
  ></v-autocomplete>
</template>
<script>
export default {
  props: {
    id: String,
    readonly: Boolean,
    value: Number,
  },
  data: () => ({
    items: [
      { id: 5, text: "Siembra" },
      { id: 6, text: "Aplicacion" },
      { id: 8, text: "Fumigacion" },
      { id: 9, text: "Servicio" },
      { id: 10, text: "Pagos" },
    ],
  }),
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    isMenuActive() {
      this.$refs.input.isMenuActive = false;
    },
  },
};
</script>
