export default {


  lotegrupo_data(state, request) {
    state.lotegrupo_data = request;
  }, 
  lotegrupo_update(state, request) {
    state.lotegrupo_update = request;
  }, 
  lotegrupo_load(state, request) {
    state.lotegrupo_load = request;
  }, 
  lotegrupo_save_load(state, request) {
    state.lotegrupo_save_load = request;
  }, 
}