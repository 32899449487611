<template>
  <div>
    <Crud v-if="crud.add" v-model="crud.add" :datos="crud.datos" @datos="crud.datos = $event" />
    <Crud v-if="crud.view" v-model="crud.view" :datos="crud.datos" readonly @datos="crud.datos = $event" />
    <Delete v-if="crud.delete" v-model="crud.delete" :props="crud.datos" @datos="crud.datos = $event" />
    <v-card>
      <v-alert type="error" v-if="permission_alert">Error!!! Este usuario no tiene permisos en esta ventana</v-alert>
      <v-toolbar flat dense>
        <v-toolbar-title>{{ titulo }}</v-toolbar-title>
        <widgets-Favorite :ruta="$route.name" :formulario="titulo" />
        <v-spacer></v-spacer>

        <c-text-table-search v-if="permission.can_view" v-model="search"></c-text-table-search>
      </v-toolbar>
      <v-toolbar flat dense>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-tabs v-model="tab" right>
            <v-tab href="#tab-1" @click="Vact_inseminacion_nuevo">Actual</v-tab>
            <v-tab href="#tab-2" @click="Vact_inseminacion_historico">Historico</v-tab>
          </v-tabs>

          <v-divider class="mx-2" inset vertical></v-divider>
          <c-btn-table-add @click="crud.add = true" v-if="permission.can_add" class="mr-1"></c-btn-table-add>
          <c-btn-table-reload @click="
            tab === 'tab-1'
              ? Vact_inseminacion_nuevo()
              : Vact_inseminacion_historico()
            "></c-btn-table-reload>
        </v-toolbar-items>
      </v-toolbar>
      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1">
          <v-data-table :loading="Vget_inseminacion_load" :headers="headers" :items="Vget_inseminacion_nuevo"
            :search="search" class="elevation-1" v-if="permission.can_view">
            <template v-slot:[`item.feccinseminacion`]="{ item }">
              <div>{{ formatDate(item.feccinseminacion) }}</div>
            </template>
            <template v-slot:[`item.accion`]="props">
              <c-btn-table-view @click="rowselect(props.item)" />
              <c-btn-table-edit @click="mupdate(props.item)" />
              <c-btn-table-delete @click="meliminar(props.item)" />
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item value="tab-2">
          <v-data-table :loading="Vget_inseminacion_load" :headers="headers_hist" :items="Vget_inseminacion_historico"
            :search="search" class="elevation-1" v-if="permission.can_view">
            <template v-slot:[`item.feccinseminacion`]="{ item }">
              <div>{{ formatDate(item.feccinseminacion) }}</div>
            </template>
            <template v-slot:[`item.accion`]="props">
              <c-btn-table-view @click="rowselect(props.item)" />
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import Crud from "./Create";
import Delete from "./Delete";
import { INSEMINACION } from "@/constants/forms";
import { dmy } from "@/services/util/date.service";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";
export default {
  components: {
    Crud,
    Delete,
  },
  data() {
    return {
      tab: null,
      search: "",

      headers: [
        { text: "Animal", align: "left", value: "idanimal.nombanimal" },
        { text: "Rp", align: "left", value: "idanimal.rpanimal" },
        {
          text: "Veterinario",
          align: "left",
          value: "idveterinario.descveterinario",
        },
        { text: "Toro", align: "left", value: "idanimaltoro.nombanimal" },
        { text: "Fecha", align: "left", value: "feccinseminacion" },
        { text: "Comentario", align: "left", value: "comninseminacion" },
        { text: "Accion", value: "accion", sortable: false, align: "right" },
      ],
      headers_hist: [
        { text: "Animal", align: "left", value: "idanimal.nombanimal" },
        { text: "Rp", align: "left", value: "idanimal.rpanimal" },
        {
          text: "Veterinario",
          align: "left",
          value: "idveterinario.descveterinario",
        },
        { text: "Toro", align: "left", value: "idanimaltoro.nombanimal" },
        { text: "Fecha", align: "left", value: "feccinseminacion" },
        { text: "Comentario", align: "left", value: "comninseminacion" },
        { text: "Accion", value: "accion", sortable: false, align: "right" },
      ],
      titulo: INSEMINACION,
      crud: {
        delete: false,
        add: false,
        view: false,
        datos: null,
      },
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
    };
  },

  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.Vact_inseminacion_nuevo();
    const permission = getPermission(INSEMINACION).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  watch: {
    Vget_inseminacion_byId(val) {
      this.crud.datos = val
    }
  },
  computed: {
    ...mapGetters("inseminacion", [
      "Vget_inseminacion_load",
      "Vget_inseminacion_nuevo",
      "Vget_inseminacion_historico",
      "Vget_inseminacion_byId",
    ]),
    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },

  methods: {
    ...mapActions("inseminacion", [
      "Vact_inseminacion_byId",
      "Vact_inseminacion_nuevo",
      "Vact_inseminacion_historico",
    ]),
    formatDate(date) {
      return dmy(date)
    },
    meliminar(item) {
      this.crud.datos = JSON.parse(JSON.stringify(item));
      this.crud.delete = true;
    },

    async rowselect(val) {
      await this.Vact_inseminacion_byId(val.idinseminacion)
      this.crud.view = true;
    },

    async mupdate(val) {
      await this.Vact_inseminacion_byId(val.idinseminacion)
      this.crud.add = true;
    },
  },
};
</script>
