<template>
  <v-card>
    <v-toolbar flat dense>
      <v-toolbar-title>Configuraciones de Ganaderia</v-toolbar-title>
    </v-toolbar>
    <v-divider></v-divider>
    <v-card-actions class="ml-2">
      <div class="title">Notificaciones</div>
      <v-divider></v-divider>
    </v-card-actions>

    <v-row dense class="mx-2">
      <v-col cols="12" sm="3">
        <c-text-currency
          @click:prepend="data.notifica_celo = !data.notifica_celo"
          :prepend-inner-icon="
            data.notifica_celo ? 'check_box' : 'check_box_outline_blank'
          "
          label="Notificar posible Celo"
          :messages="
            data.notifica_celo
              ? `Se notificara de un posible CELO cada ${data.tiempo_celo} dias`
              : ''
          "
          v-model="data.tiempo_celo"
        ></c-text-currency>
      </v-col>

      <v-col cols="12" sm="3">
        <c-text-currency
          @click:prepend="data.notifica_palpacion = !data.notifica_palpacion"
          :prepend-inner-icon="
            data.notifica_palpacion ? 'check_box' : 'check_box_outline_blank'
          "
          label="Notificar sugerencia de Palpacion"
          :messages="
            data.notifica_palpacion
              ? `Despues de ${data.tiempo_palpacion} dias de una inseminacion se recomendara realizar una palpacion para confirmar preñez`
              : ''
          "
          v-model="data.tiempo_palpacion"
        ></c-text-currency>
      </v-col>

      <v-col cols="12" sm="3">
        <c-text-currency
          @click:prepend="data.notifica_secado = !data.notifica_secado"
          :prepend-inner-icon="
            data.notifica_secado ? 'check_box' : 'check_box_outline_blank'
          "
          label="Notificar sugerencia de Secado"
          :messages="
            data.notifica_secado
              ? `Se notificara que debe secar un animal ${data.tiempo_secado} dias despues de la inseminacion`
              : ''
          "
          v-model="data.tiempo_secado"
        ></c-text-currency>
      </v-col>

      <v-col cols="12" sm="3">
        <c-text-currency
          @click:prepend="data.notifica_parto = !data.notifica_parto"
          :prepend-inner-icon="
            data.notifica_parto ? 'check_box' : 'check_box_outline_blank'
          "
          label="Notificar periodo de Parto"
          :messages="
            data.notifica_parto
              ? `Se notificara de un posible parto ${data.tiempo_parto} dias despues de la inseminacion (durante 5 dias)`
              : ''
          "
          v-model="data.tiempo_parto"
        ></c-text-currency>
      </v-col>

      <v-col cols="12" sm="3">
        <c-text-currency
          @click:prepend="data.notifica_termo = !data.notifica_termo"
          :prepend-inner-icon="
            data.notifica_termo ? 'check_box' : 'check_box_outline_blank'
          "
          label="Notificar stock de Termo"
          :messages="
            data.notifica_termo
              ? `Cuando tenga ${data.cantidad_termo} pajuelas o menos sera notificado`
              : ''
          "
          v-model="data.cantidad_termo"
        ></c-text-currency>
      </v-col>
      <v-col cols="12" sm="3">
        <c-text-currency
          @click:prepend="data.notifica_preparto = !data.notifica_preparto"
          :prepend-inner-icon="
            data.notifica_preparto ? 'check_box' : 'check_box_outline_blank'
          "
          label="Notificar sugerencia de Preparto"
          :messages="
            data.notifica_preparto
              ? `Cuando tenga ${data.tiempo_preparto} dias antes del parto sera notificado`
              : ''
          "
          v-model="data.tiempo_preparto"
        ></c-text-currency>
      </v-col>
    </v-row>

    <v-card-actions class="ml-2">
      <div class="title">Automatizacion</div>
      <v-divider></v-divider>
    </v-card-actions>

    <v-row dense class="mx-2">
      <v-col cols="12" sm="3">
        <c-text-currency
          @click:prepend="data.notifica_anestro = !data.notifica_anestro"
          :prepend-inner-icon="
            data.notifica_anestro ? 'check_box' : 'check_box_outline_blank'
          "
          label="Anestro Post-Parto"
          :messages="
            data.notifica_anestro
              ? `Despues de ${data.tiempo_anestro} dias de un parto/aborto el animal sera devuelto al estado DISPONIBLE y sera notificado`
              : ''
          "
          v-model="data.tiempo_anestro"
        ></c-text-currency>
      </v-col>
    </v-row>
    <v-card-actions class="ml-2">
      <div class="title">Dashboard</div>
      <v-divider></v-divider>
    </v-card-actions>
    <draggable class="row ml-1" @end="onEnd" v-model="draggableCards">
      <template >
        <v-col cols="12" sm="3" class="mr-n1" v-for="(item, i) of draggableCards" :key="i">
          <v-card
            elevation="2"
            min-width="40"
            :dark="item.activo"
            :color="item.activo ? item.color : ''"
          >
            <div class="d-flex mt-3 mb-2 mx-2">
              <div class="subtitle-1 font-weight-medium">
                {{ item.nombre }}
              </div>
              <v-spacer></v-spacer>
              <span class="font-weight-thin mr-1">Orden </span>
              <div class="font-weight-bolt">{{ item.orden }}</div>
            </div>
            <v-card-actions class="mt-n4">
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ml-1"
                    :color="item.color"
                    fab
                    x-small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="white">color_lens</v-icon>
                  </v-btn>
                </template>
                <v-color-picker
                  hide-sliders
                  hide-inputs
                  v-model="item.color"
                ></v-color-picker>
              </v-menu>
              <v-spacer></v-spacer>
              <v-btn
                fab
                :color="item.activo ? 'white' : 'grey'"
                small
                text
                @click="item.activo = !item.activo"
                ><v-icon>{{
                  item.activo ? "favorite" : "favorite_border"
                }}</v-icon></v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </template>
    </draggable>
    <v-card-actions class="ml-2">
      <div class="title">Campos Personalizados</div>
      <v-divider></v-divider>
    </v-card-actions>
    <v-row dense class="mx-2">
      <v-col cols="12" sm="3">
        <AutocompleteFilter ref="filter" v-model="fetchSelected" :change="changeCampos()" />
      </v-col>
      <v-col cols="12" sm="3">
        <AutocompleteCampos ref="filterCampo" v-model="fetchCampos" />
      </v-col>
      <v-col cols="12" sm="3">
        <v-btn class="mx-2" fab dark small color="primary" @click="addDet">
          <v-icon dark> add </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" sm="12">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Filtros</th>
                <th class="text-left">Campos Extra</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in data.campos_personalizados" :key="item.name">
                <td>{{ item.name }}</td>
                <td>{{ item.fields }}</td>
                <td>
                  <v-btn color="red" fab text x-small @click="deleteRow(item)">
                    <v-icon>delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <!--
    <v-card-actions class="ml-2">
      <div class="title">Curva de Produccion</div>
      <v-divider></v-divider>
    </v-card-actions>
    <v-row dense class="mx-2">
      <v-col cols="12" sm="2">
        <vue-text-field label="Etapa" v-model="etapa"></vue-text-field>
      </v-col>
      <v-col cols="12" sm="2">
        <AutocompleteLote
          :returnObject="true"
          id="racionInput1"
          v-model="idlote"
          @nextfocus="next(10, '-')"
          @keyup.native.enter="next(1, idlote)"
        />
      </v-col>
      <v-col cols="12" sm="2">
        <vue-text-field
          label="Dias"
          type="number"
          v-model="dias"
          @keyup.native.enter="next(5, dias)"
        ></vue-text-field>
      </v-col>
      <v-col cols="12" sm="1" class="mt-n3">
        <v-checkbox
          v-model="checkbox"
          :error-messages="checkboxErrors"
          label="Notificar?"
          required
          @change="$v.checkbox.$touch()"
          @blur="$v.checkbox.$touch()"
        ></v-checkbox>
      </v-col>
      <v-col cols="12" sm="2">
        <v-btn class="mx-2" fab dark small color="primary" @click="addCurva">
          <v-icon dark> add </v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Etapa</th>
                <th class="text-left">Lote</th>
                <th class="text-left">Dias</th>
                <th class="text-left">Notifica</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in data.detalleetapas" :key="item.name">
                <td>{{ item.etapa }}</td>
                <td>{{ item.idlote.desclote }}</td>
                <td>{{ item.dias }}</td>
                <td>{{ item.notifica }}</td>
                <td>
                  <v-btn color="red" fab text x-small @click="deleteRowCurva(item)">
                    <v-icon>delete</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    -->
    <v-card-actions>
      <v-divider></v-divider>
      <c-btn-crud-done @click="guardar()"></c-btn-crud-done>
    </v-card-actions>
    <c-overlay :value="isLoading"></c-overlay>
  </v-card>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import draggable from "vuedraggable";
import AutocompleteFilter from "../Animal/AutocompleteFilter";
import AutocompleteCampos from "./AutocompleteCampos";
//import AutocompleteLote from "@/views/Animal/Lote/Autocomplete";

export const CARDS = [
  {
    nombre: "Productoras",
    icon: "trending_up",
    orden: 0,
    activo: false,
    color: "red darken-1",
    query: {
      action: "fetchNomina",
    },
  },
  {
    nombre: "Vivas",
    icon: "favorite",
    orden: 1,
    activo: false,
    color: "pink darken-1",
    query: {
      action: "fetchVivo",
    },
  },
  {
    nombre: "Stock de Semen",
    icon: "adb",
    orden: 2,
    activo: false,
    color: "purple darken-1",
    query: {
      action: "fetchAnimalTermo",
    },
  },
  {
    nombre: "Terneros",
    icon: "pets",
    orden: 3,
    activo: false,
    color: "deep-purple darken-1",
    query: {
      action: "fetchAnimalByEstado",
      idestado: 1,
    },
  },
  {
    nombre: "Vaquillas",
    icon: "pregnant_woman",
    orden: 4,
    activo: false,
    color: "indigo darken-1",
    query: {
      action: "fetchVaquilla",
    },
  },
  {
    nombre: "Disponibles",
    icon: "event_available",
    orden: 5,
    activo: false,
    color: "blue darken-1",
    query: {
      action: "fetchAnimalByEstado",
      idestado: 2,
    },
  },
  {
    nombre: "Celos",
    icon: "schedule",
    orden: 6,
    activo: false,
    color: "light-blue darken-1",
    query: {
      action: "fetchAnimalByEstado",
      idestado: 3,
    },
  },
  {
    nombre: "Inseminadas",
    icon: "pregnant_woman",
    orden: 7,
    activo: false,
    color: "cyan darken-1",
    query: {
      action: "fetchAnimalByEstado",
      idestado: 4,
    },
  },
  {
    nombre: "Preñadas",
    icon: "child_care",
    orden: 8,
    activo: false,
    color: "teal darken-1",
    query: {
      action: "fetchPrenada",
    },
  },
  {
    nombre: "Secadas",
    icon: "verified_user",
    orden: 9,
    activo: false,
    color: "green darken-1",
    query: {
      action: "fetchAnimalByEstado",
      idestado: 6,
    },
  },
  {
    nombre: "Anestro Postparto",
    icon: "weekend",
    orden: 10,
    activo: false,
    color: "light-green darken-1",
    query: {
      action: "fetchAnimalByEstado",
      idestado: 7,
    },
  },
  {
    nombre: "Enfermos",
    icon: "add_alert",
    orden: 11,
    activo: false,
    color: "lime darken-1",
    query: {
      action: "fetchAnimalByEstado",
      idestado: 8,
    },
  },
  {
    nombre: "Preparto",
    icon: "lock_clock",
    orden: 12,
    activo: false,
    color: "yellow darken-3",
    query: {
      action: "fetchPreparto",
    },
  },
];
export default {
  components: {
    draggable,
    AutocompleteFilter,
    AutocompleteCampos,
    //AutocompleteLote,
  },
  data: () => ({
    draggableCards: CARDS,
    data: {
      idconfiguracion_gana: null,
      notifica_celo: false,
      tiempo_celo: 21,
      notificar_inseminacion: false,
      notifica_palpacion: false,
      tiempo_palpacion: 30,
      notifica_secado: false,
      tiempo_secado: 210,
      notifica_parto: false,
      tiempo_parto: 285,
      notifica_anestro: false,
      tiempo_anestro: 50,
      notifica_termo: false,
      cantidad_termo: 10,
      notifica_preparto: false,
      tiempo_preparto: 40,
      detalle: [],
      campos_personalizados: [],
      detalleetapas: [],
    },
    etapa: "",
    idlote: {
      idlote: "",
    },
    dias: null,
    checkbox: false,
    fetchSelected: {
      text: "Disponibles",
      action: "fetchAnimalByEstado",
      getter: "getAnimalByEstado",
      band: "disponible",
      idestado: 2,
    },
    fetchCampos: [],
  }),
  mounted() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.fetchConfigGanaderia();
  },
  watch: {
    getConfigGanaderia(val) {
      if (val.length > 0) this.mergeData(val[0]);
    },
  },
  computed: {
    ...mapGetters("configganaderia", ["getConfigGanaderia", "isLoading"]),
  },
  methods: {
    ...mapActions("configganaderia", [
      "fetchConfigGanaderia",
      "setConfigGanaderia",
    ]),
    changeCampos() {
      let unir = []
      let name = this.fetchSelected.band
      this.data.campos_personalizados.forEach(function(obj) {
        if (obj.name === name) {
          console.log(obj)
          obj.fields.forEach(function(x) {
            console.log(x)
            unir.push({
              text: x
            })
          });
        }
      })
    },
    deleteRow(item) {
      const index = this.data.campos_personalizados.indexOf(item);
      this.data.campos_personalizados.splice(index, 1);
    },
    deleteRowCurva(item) {
      const index = this.data.detalleetapas.indexOf(item);
      this.data.detalleetapas.splice(index, 1);
    },
    isRepeat() {
      return this.data.campos_personalizados.find(
        (x) => x.name === this.fetchSelected.band
      );
    },
    isRepeatCurva() {
      return this.data.detalleetapas.find(
        (x) => x.idlote.idlote === this.idlote.idlote
      );
    },
    addDet() {
      let name = this.fetchSelected.band
      let fields = this.fetchCampos
      if (!this.isRepeat()) {
        this.data.campos_personalizados =
          this.data.campos_personalizados.concat({
            name: name,
            fields: fields,
          });
      } else {
        this.data.campos_personalizados.forEach(function(obj) {
          if (obj.name === name) obj.fields = fields;
        });
      }
    },
    addCurva() {
      if (!this.isRepeatCurva()) {
        this.data.detalleetapas = 
          this.data.detalleetapas.concat(
            {
              etapa: this.etapa,
              idlote: this.idlote,
              dias: this.dias,
              notifica: this.checkbox
            }
          );
      }
    },
    async guardar() {
      this.data.detalle = JSON.parse(JSON.stringify(this.draggableCards));
      await this.setConfigGanaderia(this.data);
    },
    onEnd() {
      this.draggableCards.map((item, i) => {
        item.orden = i;
      });
    },
    mergeData(data) {
      this.data = JSON.parse(JSON.stringify(data));
      const detalle = JSON.parse(JSON.stringify(data.detalle));
      const temp = [];
      CARDS.map((card) => {
        const exist = detalle.find(({ nombre }) => nombre === card.nombre);
        exist ? temp.push(exist) : temp.push(card);
      });
      this.draggableCards = JSON.parse(JSON.stringify(temp))
        .sort((a, b) => (a.orden > b.orden ? -1 : 1))
        .sort((a, b) => (a.activo && !b.activo ? -1 : 1));
    },
  },
};
</script>
