<template>
  <v-dialog persistent max-width="500" :value="value">
    <v-card>
      <v-toolbar flat dense>
        <v-toolbar-title> Registrar Asistencia Manual</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-form ref="form" @submit.prevent="next(1, data.descripcion)">
          <v-row dense>
            <v-col cols="12">
              <autocomplete-funcionario
                ref="asistCrud1"
                v-model="data.idcontacto"
                @keyup.native.enter="
                  next(1, data.idcontacto.idcontacto.idcontacto)
                "
              />
            </v-col>
            <v-col cols="12">
              <c-text-date
                ref="asistCrud2"
                label="Fecha"
                v-model="data.fecha"
                @keyup.native.enter="next(2, data.fecha)"
              ></c-text-date>
            </v-col>
            <v-col cols="12" md="6">
              <c-text-time
                ref="asistCrud3"
                seconds
                label="Entrada"
                v-model="data.hora_entrada"
                @keyup.native.enter="next(3, data.hora_entrada)"
              ></c-text-time>
            </v-col>
            <v-col cols="12" md="6">
              <c-text-time
                ref="asistCrud4"
                seconds
                :rules="[]"
                label="Salida"
                v-model="data.hora_salida"
                @keyup.native.enter="next(4, '-')"
              ></c-text-time>
            </v-col>
            <v-col cols="12" md="12">
              <v-checkbox
                v-model="data.fecha_distinta"
                :label="`Fecha distinta?: ${data.fecha_distinta ? 'Si' : 'No'}`"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <c-btn-crud-close @click="cancel()"></c-btn-crud-close>
        <v-spacer></v-spacer>
        <c-btn-crud-done ref="asistCrud5" @click="save()"></c-btn-crud-done>
      </v-card-actions>
      <c-overlay :value="isAsistenciaLoading"></c-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { current_date } from "@/services/util/date.service";
import autocompleteFuncionario from "@/views/RRHH/Funcionarios/Information/autocomplete";
export default {
  components: {
    "autocomplete-funcionario": autocompleteFuncionario,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
    },
  },

  mounted() {
    setTimeout(() => this.next(0, "-"), 20);
  },
  watch: {
    $route(to, from) {
      if (from.path === "/rrhh/asistencias") return this.cancel();
    },
  },
  computed: {
    ...mapGetters("asistencia", ["isAsistenciaLoading"]),
  },
  methods: {
    ...mapActions("asistencia", ["setAsistencia"]),
    cancel() {
      this.$emit("input", false);
    },
    next(id, data) {
      if (data != "")
        try {
          this.$refs[`asistCrud${id + 1}`].focus();
        } catch (error) {
          this.$refs[`asistCrud${id + 1}`].$el.focus();
        }
    },
    async save() {
      if (this.data.hora_salida === "") this.data.hora_salida = null;
      if (!this.$refs.form.validate()) return null;
      const response = await this.setAsistencia(this.data);
      if (response.success) {
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();
        this.next(0, "-");
      }
    },
  },
  data: () => ({
    rules: [(v) => !!v || "Este campo es obligatorio"],
    data: {
      idasistencia: "",
      idioestado: {
        idioestado: "",
      },
      idcontacto: {
        idcontacto: {
          idcontacto: "",
        },
      },
      fecha: current_date(),
      hora_entrada: "",
      hora_salida: "",
      fecha_distinta: false,
      idmarca: null,
      comentario: null,
      estado: true,
    },
    default: {
      idasistencia: "",
      idioestado: {
        idioestado: "",
      },
      idcontacto: {
        idcontacto: {
          idcontacto: "",
        },
      },
      fecha: current_date(),
      hora_entrada: "",
      hora_salida: "",
      fecha_distinta: false,
      idmarca: null,
      comentario: null,
      estado: true,
    },
  }),
};
</script>

<style></style>
