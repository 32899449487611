
export default {

  proveedor_create: (state) => {
    return state.proveedor_create
  },
  proveedor_update: (state) => {
    return state.proveedor_update
  },
  proveedor_reload: (state) => {
    return state.proveedor_reload
  },
  proveedor_show: (state) => {
    return state.proveedor_show
  },
}
