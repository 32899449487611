export const LOADING = 'loadingLote';
export const FETCH = 'fetchLote';
export const FETCHLOTEDEP = 'fetchLotedep';
export const FETCHLOTEDIS = 'fetchLotedis';
export const FETCHLOTELOC = 'fetchLoteloc';
export const LOADINGLOTEDEP = 'loadingLotedep'
export const LOADINGLOTEDIS = 'loadingLotedis'
export const LOADINGLOTELOC = 'loadingLoteloc'

export const url = {
    root: '/apilote/',
    urldep: '/apilotedep/',
    urldis: '/apilotedis/',
    urlloc: '/apiloteloc/'
}