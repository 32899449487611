export default {
  motivomuerte_create(state, request) {
    state.motivomuerte_create = request;
  }, 
  motivomuerte_update(state, request) {
    state.motivomuerte_update = request;
  },   
  motivomuerte_reload(state, request) {
    state.motivomuerte_reload = request;
  },
  motivomuerte_show(state, request) {
    state.motivomuerte_show = request;
  }
}