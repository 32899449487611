import { db } from "@/services/db.service";
export default {
    async Vact_bonificacionagro_data({commit}) {
        commit('bonificacionagro_load', true)
        try {
            const data = await db.get('/apibonificacion/')
            commit('bonificacionagro_data', data)
            commit('bonificacionagro_load', false)
            return true
        } catch (e) {
            commit('bonificacionagro_load', false)
            return false
        }
    },

    async Vact_bonificacionagro_save({commit}, data){
        commit('bonificacionagro_load', true);
        try {
            const resp = await db.save('/apibonificacion/', data);
            commit('bonificacionagro_load', false);
            return resp;
        } catch (e) {
            commit('bonificacionagro_load', false);
            return e
        } 
    },

    async Vact_bonificacionagro_del({dispatch},id){
        try {
            const data = await db.delete('/apibonificacion/'+ id+ '/');
            dispatch('Vact_bonificacionagro_data');
            return data
        } catch (e) {
            return false
        } 
    },

    async ventaagro_update({commit},request){
        commit('ventaagro_update',request);
    },

    async Vact_ventaagro_data({commit}){
        commit('ventaagro_load',true);
        try {
            const data = await db.get('/apiventaagro/');
            commit('ventaagro_data',data);
            commit('ventaagro_load',false);
            return true
        } catch (e) {
            commit('ventaagro_load',false);
            return false
        } 
    },

    async Vact_ventaagro_data_byId({commit},id){
        commit('ventaagro_load',true);
        try {
            const data = await db.get('/apiventaagro/'+id+'/');
            commit('ventaagro_data_byId',data);
            commit('ventaagro_load',false);
            return true
        } catch (e) {
            commit('ventaagro_load',false);
            return false
        } 
    },

    async Vact_ventaagro_del({dispatch},id){
        try {
            const data = await db.delete('/apiventaagro/'+ id+ '/');
            dispatch('Vact_ventaagro_data');
            return data
        } catch (e) {
            return false
        } 
    },

    async Vact_ventaagro_save({commit},data){
        commit('ventaagro_save_load',true);
        try {
            const resp = await db.save('/apiventaagro/',data);
            commit('ventaagro_save_load',false);
            return resp;
        } catch (e) {
            commit('ventaagro_save_load',false);
            return e
        } 
    },

    async Vact_ventaagro_update({commit},{id, data}){
        commit('ventaagro_save_load',true);
        try {
            const resp = await db.update('/apiventaagro/'+id+'/',data);
            commit('ventaagro_save_load',false);
            return resp;
        } catch (e) {
            commit('ventaagro_save_load',false);
            return e
        } 
    },
}
