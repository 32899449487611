<template>
  <div>
    <Compra-PagoCuenta-Create
      v-if="crud.add"
      v-model="crud.add"
      :datos="crud.datos"
      @datos="crud.datos = $event"
    />
    <Compra-PagoCuenta-Create
      v-if="crud.view"
      v-model="crud.view"
      :datos="crud.datos"
      @datos="crud.datos = $event"
      readonly
    />
    <v-card>
      <v-alert type="error" v-if="permission_alert"
        >Error!!! Este usuario no tiene permisos en esta ventana</v-alert
      >
      <v-toolbar flat dense>
        <v-toolbar-title>{{ titulo }}</v-toolbar-title>
        <widgets-Favorite :ruta="$route.path" :formulario="$route.name" />
        <v-divider class="mx-2" inset vertical></v-divider>

        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Busqueda"
          single-line
          hide-details
          v-if="permission.can_view"
        ></v-text-field>
        <widgets-filteradvanced
          v-model="searchadvanced"
          :campos="campos"
          @resp="$store.commit('pagocuenta/pagocuenta_data', $event)"
          url="/apireciboventasearch/"
        />
        <v-toolbar-items>
          <v-speed-dial v-model="opcion" direction="left" class="mr-1">
            <template v-slot:activator>
              <v-btn v-model="opcion" color="red" small height="100%" tile dark>
                <v-icon v-if="opcion">keyboard_arrow_up</v-icon>
                <v-icon v-else>keyboard_arrow_down</v-icon>
              </v-btn>
            </template>
            <v-btn fab dark small color="indigo" @click="searchadvanced = true">
              <v-icon>zoom_in</v-icon>
            </v-btn>
            <v-btn fab dark small color="green" @click="generarCsv()">
              <v-icon>grid_on</v-icon>
            </v-btn>
            <v-btn fab dark small color="red" @click="generarPdf()">
              <v-icon>get_app</v-icon>
            </v-btn>
          </v-speed-dial>

          <c-btn-table-add
            @click="crud.add = true"
            v-if="permission.can_add"
          ></c-btn-table-add>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table
        :loading="Vget_pagocuenta_load"
        :headers="headers"
        :items="Vget_pagocuenta_data"
        :search="search"
        class="elevation-1"
        v-if="permission.can_view"
      >
        <template v-slot:[`item.accion`]="props">
          <v-icon class="mr-2" @click="rowselect(props.item)"
            >visibility</v-icon
          >
          <v-icon
            small
            @click="meliminar(props.item)"
            v-if="permission.can_delete"
            >not_interested</v-icon
          >
        </template>
        <template v-slot:no-data>
          <v-alert :value="true" color="info" icon="info" outlined
            >No existe ningun registro</v-alert
          >
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="crud.delete" persistent max-width="350">
      <v-card>
        <v-card-title>
          <v-icon left>info</v-icon>
          <span class="title font-weight-light">Ventana de Confirmacion</span>
        </v-card-title>
        <v-card-text>Desea eliminar {{ objeto.nro_rec_pagar }}?</v-card-text>
        <v-card-actions>
          <v-btn color="red" text @click="eliminarcancelar()">
            <v-icon>clear</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="meliminarguardar()">
            <v-icon>done</v-icon>
          </v-btn>
        </v-card-actions>
        <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { exportTo } from "@/services/export.service";
import { PAGO_CUENTA } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";
export default {
  data() {
    return {
      crud: {
        add: false,
        view: false,
        delete: false,
        datos: null,
      },
      dialog_show: false,
      overlay: false,
      searchadvanced: false,
      opcion: false,
      search: "",
      headers: [
        {
          text: "Codigo",
          align: "left",
          value: "idcuentapagar_ordenpago_recibo",
        },
        { text: "Recibo", align: "left", value: "nro_rec_pagar" },
        { text: "Fecha", align: "left", value: "fechordenpago" },
        {
          text: "Cliente",
          align: "left",
          value: "idcontacto.idcontacto.desccontacto",
        },
        {
          text: "Ruc",
          align: "left",
          value: "idcontacto.idcontacto.ruccontacto",
        },
        { text: "Moneda", align: "left", value: "idmoneda.descmoneda" },
        { text: "Accion", value: "accion", sortable: false, align: "right" },
      ],
      data: {
        idcontacto: {
          idcontacto: {
            desccontacto: "",
          },
        },
      },
      titulo: PAGO_CUENTA,
      objeto: "",
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
      campos: [
        {
          name: "Cliente",
          api: "/apicontacto/?tipo=cliente",
          id: "idcontacto",
          desc: "desccontacto",
          type: "select",
          query: [
            {
              comparison: "",
              value: "",
              logical: "",
              add: false,
            },
          ],
        },
        {
          name: "Nro Recibo",
          value: "nroreciboventa",
          type: "text",
          query: [
            {
              comparison: "",
              value: "",
              logical: "",
              add: false,
            },
          ],
        },
        {
          name: "Moneda",
          api: "/apimoneda/",
          id: "idmoneda",
          desc: "descmoneda",
          type: "select",
          query: [
            {
              comparison: "",
              value: "",
              logical: "",
              add: false,
            },
          ],
        },
        {
          name: "Fecha Cobro",
          value: "fechreciboventa",
          type: "date",
          query: [
            {
              comparison: "",
              value: "",
              logical: "",
              add: false,
            },
          ],
        },
        {
          name: "Anulado",
          value: "anulreciboventa",
          type: "boolean",
          query: [
            {
              comparison: "",
              value: "",
              logical: "",
              add: false,
            },
          ],
        },
      ],
    };
  },

  async created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    const permission = getPermission(PAGO_CUENTA).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
    await this.Vact_pagocuenta_data();
  },
  computed: {
    ...mapGetters("pagocuenta", [
      "Vget_pagocuenta_data",
      "Vget_pagocuenta_load",
      "Vget_pagocuenta_data_id"
    ]),
    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },

  methods: {
    ...mapActions("pagocuenta", [
      "Vact_pagocuenta_data",
      "Vact_pagocuenta_del",
      "Vact_pagocuenta_data_id",
    ]),
    ...mapActions("caja", ["Vact_caja_data"]),

    meliminar(item) {
      this.objeto = Object.assign({}, item);
      this.crud.delete = true;
    },
    async rowselect(val) {
      await this.Vact_pagocuenta_data_id(val.idcuentapagar_ordenpago_recibo)
      this.crud.datos = JSON.parse(JSON.stringify(this.Vget_pagocuenta_data_id))
      //this.crud.datos = JSON.parse(JSON.stringify(val));
      this.crud.view = true;
    },
    eliminarcancelar() {
      this.objeto = "";
      this.crud.delete = false;
    },
    async meliminarguardar() {
      this.overlay = true;
      const id = this.objeto.idcuentapagar_ordenpago_recibo;
      const a = await this.Vact_pagocuenta_del(id);
      if (a.estado == true) {
        this.$notify(a.info);
        this.crud.delete = false;
        this.Vact_caja_data();
        this.eliminarcancelar();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.overlay = false;
    },
    generarPdf() {
      let head = [["Codigo", "Recibo", "Fecha", "Cliente", "Ruc","Moneda"]];

      const body = this.Vget_pagocuenta_data.map((x) => {
        return [
          x.idcuentapagar_ordenpago_recibo,
          x.nro_rec_pagar,
          x.fechordenpago,
          x.idcontacto.idcontacto.desccontacto,
          x.idcontacto.idcontacto.ruccontacto,
          x.idmoneda.descmoneda,
        ];
      });
      exportTo.pdftable(head, body, "Lista de Recibos");
    },
    generarCsv() {
      const data = this.Vget_pagocuenta_data.map((x) => {
        return {
          Codigo: x.idcuentapagar_ordenpago_recibo,
          Recibo: x.nro_rec_pagar,
          Fecha: x.fechordenpago,
          Cliente: x.idcontacto.idcontacto.desccontacto,
          Ruc: x.idcontacto.idcontacto.ruccontacto,
          Moneda: x.idmoneda.descmoneda,
        };
      });

      exportTo.xlsx(data, "Lista de Pagos");
    },
  },
};
</script>
