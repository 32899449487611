import {
  SET_LOADING,
  SET_FUCIONARIO_ACTIVE,
  SET_FUCIONARIO_INACTIVE,
  SET_ANHOS,
  SET_EMPLEADO_OBRERO,
  SET_REPORTE_LOADING,
  SET_RESUMEN_GENERAL,
  SET_PLANILLA_SUELDO_IPS,
  SET_SUELDOS_JORNALES,
  SET_DOCUMENTOS,
  SET_CARGO,
  SET_SALDOS
} from "./constants";
export default {
  [SET_FUCIONARIO_ACTIVE](state, request) {
    state.funcionario = request;
  },
  [SET_FUCIONARIO_INACTIVE](state, request) {
    state.funcionario_inactive = request;
  },
  [SET_ANHOS](state, request) {
    state.anhos = request;
  },
  [SET_DOCUMENTOS](state, request) {
    state.documentos = request;
  },
  [SET_LOADING](state, request) {
    state.isLoading = request;
  },
  [SET_EMPLEADO_OBRERO](state, request) {
    state.empleados_obreros = request;
  },
  [SET_RESUMEN_GENERAL](state, request) {
    state.resumen_general = request;
  },
  [SET_REPORTE_LOADING](state, request) {
    state.isReporteLoading = request;
  },
  [SET_CARGO](state, request) {
    state.cargo = request;
  },
  [SET_SUELDOS_JORNALES](state, request) {
    state.sueldos_jornales = request;
  },
  [SET_SALDOS](state, request) {
    state.saldos = request;
  },
  [SET_PLANILLA_SUELDO_IPS](state, request) {
    state.planilla_sueldos_ips = request;
  },
};
