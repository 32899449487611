<template>
  <div>
    <v-select
      hide-details
      :items="items"
      rounded
      return-object
      item-value="text"
      item-text="text"
      :rules="[(v) => !!v || 'Seleccione un fitro']"
      dense
      filled
      :value="value"
      @input="$emit('input', $event)"
    ></v-select>
  </div>
</template>
<script>
export default {
  props: {
    value: Object
  },
  data: () => ({
    items: [
      {
        text: "Animal",
        value: "animal",
      },
      {
        text: "Estado",
        value: "estado",
      },
      {
        text: "Raza",
        value: "raza",
      },
      {
        text: "Lote",
        value: "lote",
      },
      {
        text: "Item",
        value: "item",
      },
    ],
  }),
};
</script>
