import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { current_date } from "@/services/util/date.service";
import { currency } from "@/services/util/number.service";
import store from '@/store'
export const exportPdf = (data, head, table) => {
    var doc = new jsPDF();
    let pageWidth = doc.internal.pageSize.width;
    let fechaWidth = doc.getTextWidth(data.fecha);
    doc.setFontSize(10);
    store.getters['empresa/Vget_empresa_active'].forEach(empresa => {
        if (empresa.est_emp_tam_usu)
            return doc.text(empresa.idempresa.descempresa, 15, 20);

    })
    doc.text(data.fecha, pageWidth - fechaWidth, 20);
    doc.setLineWidth(0.5);
    doc.setFontSize(13);
    doc.text(data.mensaje, doc.internal.pageSize.width / 2, 30, { align: 'center' });
    doc.setFontSize(13);
    doc.text(data.group, doc.internal.pageSize.width / 2, 37, { align: 'center' });

  
    const align = (indice) => {
        if (indice % 2 === 0)
        return { right: 107 }
            return { left: 107 }
        
    }

    let startY = 0;
    let startYData = 0;
    const level = (indice, finalY) => {
        
        if (startYData < finalY) startYData = finalY;
        if (indice < 2) {
            if (finalY > 47) startY = finalY;
            return 47
        }
        return startY + 15;
    }

    doc.setFontSize(11);
    doc.text('Filtros Aplicados', doc.internal.pageSize.width / 2, 43, { align: 'center' });


    data.lista.forEach((lista, i) => {
        doc.autoTable({
            head: [
                [
                    {
                        content: lista.name,
                        colSpan: 2,
                        styles: { halign: 'center', fillColor: [160, 160, 160] },
                    },
                ],
            ],
            body: lista.datos.map(x => {
                return [
                    x.id,
                    x.name
                ]
            }),
            startY: level(i, doc.previousAutoTable.finalY),
            showHead: 'firstPage',
            styles: { overflow: 'hidden' },
            margin: align(i)
        });
    })
    if (startYData < doc.previousAutoTable.finalY) startYData = doc.previousAutoTable.finalY;
    doc.setFontSize(12);
    doc.text('Ultima Cotizacion: '+data.cotizacion, doc.internal.pageSize.width / 2, startYData + 10, { align: 'center' });
    table.forEach((val, i) => {
        let moneda = JSON.parse(JSON.stringify(val))
        if (i != 0) {
            startYData = doc.previousAutoTable.finalY + 10;
        } else {
            startYData = startYData + 20;
        }
        doc.setFontSize(12);
        doc.text('Moneda: ' + moneda.moneda, doc.internal.pageSize.width / 2, startYData - 3, { align: 'center' });
        moneda.detalle.push({idreferencia: 'Total', referencia: '', ...moneda.totales});
        doc.autoTable({
            head: [head.map(x => {
                return x.text
            })],
            body: moneda.detalle.map(x => {
                return [
                    x.idreferencia,
                    x.referencia,
                    currency(x.precio_debito || 0),
                    currency(x.precio_credito || 0),
                    currency(x.saldo || 0)
                ]
            }),
            startY: startYData,
            columnStyles: { 2: { halign: 'right', fontStyle: 'bold' }, 3: { halign: 'right', fontStyle: 'bold' }, 4: { halign: 'right', fontStyle: 'bold' } },
            theme: 'grid',
            headStyles: {
                textColor: [0, 0, 0],
                fillColor: [255, 255, 255],
                fontSize: 8,
            },
            didParseCell: (data) => {
                if (data.row.index == moneda.detalle.length - 1 && data.column.index == 0) {
                    data.row.cells[0].colSpan = 2;
                    Object.entries(data.row.cells).forEach((v) => {
                        v[1].styles.fontStyle = 'bold';
                    })
                }
            },
        });
    })

    doc.save('Diario de Caja' + current_date() + '.pdf');
}