<template>
  <div>
    <v-toolbar flat dense>
      <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
      <widgets-Favorite :ruta="$route.path" :formulario="$route.name" />
    </v-toolbar>

    <v-row dense class="mx-2">
      <v-col cols="12" sm="3">
        <c-text-date
          dense
          ref="reporAdv1"
          label="Desde"
          v-model="data.fecha_inicio"
          @keyup.native.enter="next(1, data.fecha_inicio)"
        ></c-text-date>
      </v-col>
      <v-col cols="12" sm="3">
        <c-text-date
          dense
          ref="reporAdv2"
          label="Hasta"
          v-model="data.fecha_fin"
          @keyup.native.enter="next(2, data.fecha_fin)"
        ></c-text-date>
      </v-col>
      <v-col cols="12" sm="3">
        <v-btn text small color="red" @click="generarPdf()">PDF</v-btn>
        <c-btn-table-reload
          class="ml-2"
          @click="fetchResumido(data)"
        ></c-btn-table-reload>
      </v-col>
    </v-row>

      <c-overlay absolute :value="isLoading"></c-overlay>
    <v-tabs right v-model="tab">
      <v-tab v-for="(item, i) of getResumen" :key="i">{{ item.moneda }}</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(item, i) of getResumen" :key="i">
        <v-data-table
          :headers="headers"
          :items="item.detalle"
          :loading="isLoading"
          :items-per-page="999999"
        >
          <template v-slot:[`item.montopago`]="{ item }">
            <div class="font-weight-black">
              {{ currencyFormat(item.montopago) }}
            </div>
          </template>
          <template v-slot:[`item.montopagar`]="{ item }">
            <div class="font-weight-black">
              {{ currencyFormat(item.montopagar) }}
            </div>
          </template>
          <template v-slot:[`item.totalpago`]="{ item }">
            <div class="font-weight-black">
              {{ currencyFormat(item.totalpago) }}
            </div>
          </template>
          <template slot="body.append">
            <tr class="black--text">
              <th class="subtitle-2">TOTAL</th>
              <th class="subtitle-1"></th>
<th class="subtitle-2 font-weight-black text-end">
                {{ currencyFormat(subtotal[item.moneda].pagado) }}
              </th>
              <th class="subtitle-2 font-weight-black text-end">
                {{ currencyFormat(subtotal[item.moneda].pagar) }}
              </th>
<!--               <th class="subtitle-2 font-weight-black text-end">
                {{ currencyFormat(subtotal[item.moneda].total) }}
              </th> -->
            </tr>
          </template>
        </v-data-table>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { currency } from "@/services/util/number.service";
import {
  current_first_date,
  current_last_date,
} from "@/services/util/date.service";
import { exportResumenPDF } from "./export";
export default {
  data: () => ({
    data: {
      fecha_inicio: current_first_date(),
      fecha_fin: current_last_date(),
    },
    tab: null,
    headers: [
      {
        text: "Cliente",
        align: "start",
        sortable: false,
        value: "nombre",
      },
      { text: "Ruc", value: "ruc", sortable: false },
      {
        text: "Saldo Pagado",
        value: "montopago",
        align: "end",
        sortable: false,
      },
      {
        text: "Saldo a Pagar",
        value: "montopagar",
        align: "end",
        sortable: false,
      },
/*       { text: "Total", value: "totalpago", align: "end", sortable: false }, */
    ],
  }),
  mounted() {
    this.fetchResumido(this.data);
  },
  computed: {
    ...mapGetters("extracto", ["isLoading", "getResumen"]),
    subtotal() {
      let moneda = {};
      this.getResumen.map(x=>{
        moneda[x.moneda] = {
          pagar: 0,
          pagado: 0,
          total: 0
        }
      })
      this.getResumen.map((mn) => {
        mn.detalle.map(x=>{
          moneda[mn.moneda].pagar = moneda[mn.moneda].pagar + x.montopagar;
          moneda[mn.moneda].pagado = moneda[mn.moneda].pagado + x.montopago;
          moneda[mn.moneda].total = moneda[mn.moneda].total + x.totalpago;
        })
      });
      return moneda;
    },
  },
  methods: {
    ...mapActions("extracto", ["fetchResumido"]),
    currencyFormat(val) {
      return currency(val);
    },
    generarPdf() {
      exportResumenPDF(
        this.getResumen,
        this.headers,
        this.$route.name,
        this.subtotal
      );
    },
  },
};
</script>
