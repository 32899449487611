<template>
  <div>
    <div v-if="abrircaja">
      <Apertura-Caja-create
        v-model="abrircaja"
        :editar="datos"
        @editar="datos = $event"
      />
    </div>
    <div v-if="show">
      <Apertura-Caja-show
        v-model="show"
        :mostrar="datos"
        @mostrar="datos = $event"
      />
    </div>
      <v-alert type="error" v-if="permission_alert">
        Error!!! Este usuario no tiene permisos en esta ventana
      </v-alert>
      <v-toolbar flat dense>
        <v-toolbar-title>{{ titulo }}</v-toolbar-title>
        <widgets-Favorite ruta="/caja" formulario="Caja" />
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>

        <c-text-table-search
          v-if="permission.can_view"
          v-model="search"
        ></c-text-table-search>
        <v-toolbar-items>
          <c-btn-table-add
            @click="abrircaja = true"
            v-if="permission.can_add"
          ></c-btn-table-add>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table
        :loading="Vget_caja_load"
        :headers="headers"
        :items="Vget_caja_data"
        :search="search"
        class="elevation-1"
        v-if="permission.can_view"
      >
        <template v-slot:item.accion="props">
          <v-icon class="mr-2" @click="rowselect(props.item)"
            >visibility</v-icon
          >
          <v-icon
            class="mr-2"
            @click="mupdate(props.item)"
            v-if="permission.can_update"
            >edit</v-icon
          >
          <v-icon
            small
            @click="meliminar(props.item)"
            v-if="permission.can_delete"
            >delete</v-icon
          >
        </template>
        <template v-slot:no-data>
          <v-alert :value="true" color="info" icon="info" outlined
            >No existe ningun registro</v-alert
          >
        </template>
      </v-data-table>

    <v-dialog v-model="dialogoeliminar" persistent max-width="350">
      <v-card>
        <v-card-title>
          <v-icon left>info</v-icon>
          <span class="title font-weight-light">Ventana de Confirmacion</span>
        </v-card-title>
        <v-card-text>Desea eliminar {{ objeto.descaja }}?</v-card-text>
        <v-card-actions>
          <v-btn color="red" text @click="eliminarcancelar">
            <v-icon>clear</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="meliminarguardar">
            <v-icon>done</v-icon>
          </v-btn>
        </v-card-actions>
        <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { CAJA } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";
export default {
  data() {
    return {
      abrircaja: false,
      show: false,
      overlay: false,
      search: "",
      datos: null,
      headers: [
        { text: "Descripcion", align: "left", value: "descaja" },
        { text: "Accion", value: "accion", sortable: false, align: "right" },
      ],
      titulo: CAJA,
      objeto: { descaja: "" },
      index: "",
      dialogoeliminar: false,
      load: false,
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
    };
  },

  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.Vact_caja_data();
    const permission = getPermission(CAJA).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  computed: {
    ...mapGetters("caja", ["Vget_caja_data", "Vget_caja_load"]),
    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },

  methods: {
    ...mapActions("caja", ["Vact_caja_data", "Vact_caja_del"]),

    meliminar(item) {
      this.objeto = Object.assign({}, item);
      this.dialogoeliminar = true;
    },
    rowselect(val) {
      this.datos = val;
      this.show = true;
    },
    eliminarcancelar() {
      this.objeto = { descaja: "" };
      this.dialogoeliminar = false;
    },
    async meliminarguardar() {
      this.overlay = true;
      const id = this.objeto.idcaja;
      const a = await this.Vact_caja_del(id);
      if (a.estado == true) {
        this.$notify(a.info);
        this.dialogoeliminar = false;
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.overlay = false;
    },
    mupdate(val) {
      this.datos = val;
      this.abrircaja = true;
    },
  },
};
</script>