<template>
  <v-data-table
    hide-default-footer
    :headers="headers"
    :items="items"
    :items-per-page="99999"
  >
    <template v-slot:[`item.cantdetventa`]="{ item }">
      <div>{{ currencyFormat(item.cantdetventa) }}</div>
    </template>
    <template v-slot:[`item.precdetventa`]="{ item }">
      <div>{{ currencyFormat(item.precdetventa) }}</div>
    </template>

    <template v-slot:[`item.iditem.subtotal`]="{ item }">
      <div class="font-weight-black">
        {{ currencyFormat(item.cantdetventa * item.precdetventa) }}
      </div>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon small @click="$emit('rowdelete',item)">delete</v-icon>
    </template>
  </v-data-table>
</template>
<script>
import { currency } from "@/services/util/number.service";
export default {
  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    currencyFormat(value) {
      if (value != "") return currency(value);
      return "";
    },
  },
};
</script>
