<template>
  <div>
    <div v-if="crud.add">
      <Venta-Venta-Create
        v-model="crud.add"
        :datos="crud.datos"
        :datos_cuota="crud.datos_cuota"
        @datos="crud.datos = $event"
      />
    </div>
    <v-card>
      <v-toolbar flat dense>
        <v-toolbar-title>Cuotas</v-toolbar-title>
        <v-spacer></v-spacer>
        <c-text-table-search v-model="search"></c-text-table-search>
        <v-toolbar-items>
          <Gym-SelectPuntoVenta
            mode="factura"
            class="mr-1"
            v-model="crud.add"
            ref="refPuntoVenta"
          />
          <c-btn-table-reload
            class="ml-1"
            @click="fetchCuota()"
          ></c-btn-table-reload>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="Vget_gymcuota_data_by"
        :loading="Vget_gymcuota_load"
      >
        <template v-slot:[`item.monto`]="{ item }">
          {{ currencyFormat(item.monto) }}
        </template>
        <template v-slot:[`item.estado`]="{ item }">
          <v-chip small dark :color="item.estado == true ? `green` : `red`">
            {{ item.estado == true ? "Si" : "No" }}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    props: Object,
  },
  data: () => ({
    search: "",
    crud: {
      add: false,
      datos: null,
    },
    headers: [
      {
        text: "Fecha",
        align: "start",
        sortable: true,
        value: "fechacuota",
      },
      {
        text: "Monto",
        align: "start",
        sortable: false,
        value: "monto",
      },
      {
        text: "Pagado",
        align: "start",
        sortable: false,
        value: "estado",
      },
    ],
  }),
  mounted() {
    this.fetchCuota();
    this.crud.datos_cuota = this.$props.props;
  },
  computed: {
    ...mapGetters("gymcuota", ["Vget_gymcuota_data_by", "Vget_gymcuota_load"]),
  },
  methods: {
    ...mapActions("gymcuota", ["Vact_gymcuota_data_by"]),
    fetchCuota() {
      let id = this.props.idgymplanilla;
      this.Vact_gymcuota_data_by({ id });
    },
    currencyFormat(value) {
      return new Intl.NumberFormat({ maximumSignificantDigits: 3 }).format(
        value.toFixed(2)
      );
    },
  },
};
</script>
