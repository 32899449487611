<template>
  <v-card>
    <v-toolbar flat dense>
      <v-toolbar-title>Reportes</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-tabs>
          <v-tab exact to="/venta/reportes">Avanzado</v-tab>
          <v-tab  to="/venta/reportes/extractogeneral">Extracto General</v-tab>
          <v-tab  to="/venta/reportes/extractoresumido">Extracto Resumido</v-tab>
          <v-tab to="/venta/reportes/libroventa">Libro de Ventas</v-tab>
          <v-tab to="/venta/reportes/itemventa">Items Vendidos</v-tab>
        </v-tabs>
      </v-toolbar-items>
    </v-toolbar>
    <v-divider></v-divider>

    <router-view></router-view>
  </v-card>
</template>

<script>
export default {
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
  },
};
</script>

<style></style>
