<template>
  <div>
    <v-card>
      <v-alert type="error" v-if="permission_alert"
        >Error!!! Este usuario no tiene permisos en esta ventana</v-alert
      >
      <v-toolbar flat dense>
        <v-toolbar-title>{{ titulo }}</v-toolbar-title>
        <widgets-Favorite ruta="/reporte" formulario="Reporte Agricultura" />
        <v-divider class="mx-2" inset vertical></v-divider>

        <v-spacer></v-spacer>
        <c-text-table-search
          v-if="permission.can_view"
          v-model="search"
        ></c-text-table-search>
      </v-toolbar>
      <v-sheet class="d-flex justify-end mb-2">
        <v-slide-group multiple show-arrows>
          <v-slide-item>
            <div>
              <v-btn active-class="primary--text" tile exact text to="/reporte/"
                >Avanzado</v-btn
              >
            </div>
          </v-slide-item>
          <v-slide-item>
            <div>
              <v-btn
                active-class="primary--text"
                tile
                exact
                text
                to="/reporte/lote"
                >Lote</v-btn
              >
            </div>
          </v-slide-item>
          <v-slide-item>
            <div>
              <v-btn
                active-class="primary--text"
                tile
                exact
                text
                to="/reporte/index"
                >Indice Historico</v-btn
              >
            </div>
          </v-slide-item>
          <v-slide-item>
            <div>
              <v-btn
                active-class="primary--text"
                tile
                exact
                text
                to="/reporte/costos"
                >Estimacion de Costos</v-btn
              >
            </div>
          </v-slide-item>
          <v-slide-item>
            <div>
              <v-btn
                active-class="primary--text"
                tile
                exact
                text
                to="/reporte/senave"
                >Senave</v-btn
              >
            </div>
          </v-slide-item>
          <v-slide-item>
            <div>
              <v-btn
                active-class="primary--text"
                tile
                exact
                text
                to="/reporte/indicelote"
                >Indice Lote</v-btn
              >
            </div>
          </v-slide-item>
        </v-slide-group>
      </v-sheet>

      <router-view></router-view>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { db } from "@/services/db.service";
import { REPORTE_AGRICULTURA } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";
export default {
  data() {
    return {
      tabs: 1,
      titulo: "Reportes de Agricultura",
      search: "",
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
      headers: [
        { text: "Campaña", align: "left", value: "descripcion" },
        { text: "Accion", value: "accion", sortable: false, align: "right" },
      ],
    };
  },
  watch: {},
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    const permission = getPermission(REPORTE_AGRICULTURA).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  mounted() {
    this.Vact_campana_data_all();
  },
  computed: {
    ...mapGetters("campana", ["Vget_campana_data_all", "Vget_campana_load"]),
    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },
  methods: {
    ...mapActions("campana", ["Vact_campana_data_all"]),
    async reporteCampanaId(idcampana) {
      let response = await db.get("/informezafra/" + idcampana + "/");
      const url = new Blob([response], { type: "application/pdf" });
      var fileURL = URL.createObjectURL(url);
      window.open(fileURL);
    },
  },
};
</script>