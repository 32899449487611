export default {
    async turno_create({commit},request){
        commit('turno_create',request);
    },
    async turno_update({commit},request){
        commit('turno_update',request);
    },
    async turno_reload({commit},request){
        commit('turno_reload',request);
    },
    async turno_show({commit},request){
        commit('turno_show',request);
    },
}
