<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title class="text-h4">
        Hola, {{ current_user }}!
      </v-toolbar-title>
    </v-toolbar>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("empresa", ["Vget_empresa_active"]),
    current_user() {
      const user = this.Vget_empresa_active.find(
        (x) => x.est_emp_tam_usu === true
      );
      if (user)
        return user.idusuario.first_name != ""
          ? user.idusuario.first_name
          : user.idusuario.username;
      return "";
    },
  },
};
</script>