<template>
  <v-autocomplete
    :value="value"
    :id="id"
    @input="$emit('input', $event)"
    :rules="rules_active"
    dense
    type="text"
    :items="getPerfilFin"
    :label="label"
    item-text="descperfil"
    return-object
    outlined
    :readonly="readonly"
    :disabled="disabled"
    height="12"
    autocomplete="off"
    :clearable="true"
    :loading="isPerfilFinLoadig"
    :no-data-text="`No existe ninguna concidencia`"
    v-on:change="change"
    :small-chips="chips"
  >
    <template slot="selection" slot-scope="data">
      <v-chip
        small
      >{{ `${data.item.descperfil} - Periodo: ${data.item.idperiodo_interes.descripcion} - Interes: ${data.item.porcinteres}% - min/max: ${data.item.cantcuotamin}-${data.item.cantcuotamax}` }}</v-chip>
    </template>
    <template slot="item" slot-scope="data">
      <v-list-item-content>
        <v-list-item-title
          v-html="`${data.item.descperfil} - Periodo: ${data.item.idperiodo_interes.descripcion}`"
        ></v-list-item-title>
        <v-list-item-subtitle
          v-html="`Interes: ${data.item.porcinteres}% - min/max: ${data.item.cantcuotamin}-${data.item.cantcuotamax}`"
        ></v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    label: String,
    value: [Number, String, Object],
    id: String,
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    chips: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      validar: [v => !!v || "Seleccion un campo"],
    };
  },
  created() {
    this.fetchPerfilFin();
  },
  computed: {
    ...mapGetters('perfilfin',["getPerfilFin","isPerfilFinLoadig"]),
    rules_active() {
      if (this.rules) return this.validar;
      return [];
    }
  },
  methods: {
    ...mapActions('perfilfin',['fetchPerfilFin']),
    change(evt) {
      this.$emit("change", evt);
    }
  }
};
</script>