
export default {
  Vget_rubro_data: (state) => {
    return state.rubro_data
  },
  Vget_rubro_load: (state) => {
    return state.rubro_load
  },
  Vget_rubro_save_load: (state) => {
    return state.rubro_save_load
  },
}
