
export default {


  Vget_lotegrupo_data: (state) => {
    return state.lotegrupo_data
  },
  Vget_lotegrupo_update: (state) => {
    return state.lotegrupo_update
  },
  Vget_lotegrupo_load: (state) => {
    return state.lotegrupo_load
  },
  Vget_lotegrupo_save_load: (state) => {
    return state.lotegrupo_save_load
  },
}
