<template>
  <div>
    <div v-if="abrirresponsable">
      <Animal-Responsable-create />
    </div>
    <div v-if="abrirturno">
      <Produccion-Turno-create />
    </div>
    <div v-if="formularios.deposito">
      <Item-Deposito-create v-model="formularios.deposito" />
    </div>

    <div v-if="formularios.item">
      <Item-Item-create
        v-model="formularios.item"
        @sync-input="Vact_item_lacteo()"
      />
    </div>
    <v-dialog
      :value="value"
      max-width="900px"
      persistent
      @keydown.esc="cancelar()"
    >
      <v-card>
        <v-toolbar flat dense>
          <v-toolbar-title
            >{{
              operacion ? "Registrar" : "Editar"
            }}
            Produccion</v-toolbar-title
          >
        </v-toolbar>
        <v-container>
          <v-form ref="form" lazy-validation @submit.prevent="nextfocus(1)">
            <v-row row dense>
              <v-col cols="12" sm="3">
                <v-autocomplete
                  dense
                  v-model="produccion"
                  id="input0"
                  :rules="validars"
                  v-bind:items="produccion_tipo"
                  fkdes="item"
                  label="Ingresar por"
                  item-text="fkdes"
                  item-value="id"
                  :readonly="readonly"
                  outlined
                  autocomplete="off"
                  :no-data-text="`No existe ninguna concidencia`"
                  @keyup.enter="nextfocus(0, produccion)"
                  small-chips
                ></v-autocomplete>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" sm="3">
                <c-text-date
                  label="Fecha Inicio"
                  id="input1"
                  :readonly="readonly"
                  v-model="data.fechproduccionleche"
                  dense
                  outlined
                  @keyup.native.enter="nextfocus(1, data.fechproduccionleche)"
                />
              </v-col>
            </v-row>
            <v-row row dense>
              <v-col cols="12" sm="4">
                <vue-autocomplete-generic
                  label="Seleccione un Responsable"
                  id="input2"
                  :readonly="readonly"
                  action="responsable/Vact_responsable_data"
                  getter="responsable/Vget_responsable_data"
                  load="responsable/Vget_responsable_load"
                  vuex="responsable/responsable_create"
                  pk="idresponsable"
                  title="descresponsable"
                  permission="Responsable"
                  :create="true"
                  v-model="data.idresponsable.idresponsable"
                  @keyup.native.enter="
                    nextfocus(2, data.idresponsable.idresponsable)
                  "
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-autocomplete
                  dense
                  v-model="data.idturnoordeno.idturnoordeno"
                  id="input3"
                  height="12"
                  :readonly="readonly"
                  :rules="validars"
                  v-bind:items="turno"
                  fkdes="item"
                  label="Turno"
                  item-text="fkdes"
                  item-value="id"
                  outlined
                  autocomplete="off"
                  @change="tipo_produccion()"
                  :loading="turno_load"
                  :no-data-text="`No existe ninguna concidencia`"
                  @keyup.enter="nextfocus(3, data.idturnoordeno.idturnoordeno)"
                  small-chips
                >
                  <template v-if="turno_permission" v-slot:no-data>
                    <v-list-item @click="turno_create(true)">
                      <v-list-item-content>
                        <v-list-item-title
                          >No existe concidencia. Click para
                          agregar</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="4">
                <vue-autocomplete-generic
                  id="input4"
                  :readonly="readonly"
                  action="deposito/Vact_deposito_data"
                  getter="deposito/Vget_deposito_data"
                  load="deposito/Vget_deposito_load"
                  pk="iddeposito"
                  label="Deposito"
                  title="desdeposito"
                  :create="true"
                  @create="formularios.deposito = $event"
                  permission="Deposito"
                  v-model="data.iddeposito.iddeposito"
                  @keyup.native.enter="nextfocus(4, data.iddeposito.iddeposito)"
                />
              </v-col>
              <v-col cols="12" md="3" sm="3">
                <c-text-currency
                  dense
                  v-model="data.precio"
                  :id="`input5`"
                  :readonly="readonly"
                  :rules="rules.number2"
                  label="Precio"
                  outlined
                  @keyup.native.enter="nextfocus(5, data.precio)"
                ></c-text-currency>
              </v-col>
              <v-col cols="12" md="3" sm="3">
                <c-text-currency
                  dense
                  v-model="data.grasa"
                  :id="`input6`"
                  :readonly="readonly"
                  :rules="rules.number2"
                  label="Grasa"
                  outlined
                  @keyup.native.enter="nextfocus(6, data.grasa)"
                ></c-text-currency>
              </v-col>
            </v-row>
          </v-form>
          <v-form ref="formDet" @submit.prevent="nextfocus(6, data.grasa)">
            <v-row dense v-if="produccion === 2">
              <!--
              <v-col cols="12" md="6" sm="6">
                <v-autocomplete
                  dense
                  v-model="item_det"
                  height="12px"
                  id="input7"
                  :readonly="readonly"
                  v-bind:items="Vget_item_lacteo"
                  label="Item"
                  :rules="rules.item"
                  item-text="descitem"
                  item-value="iditem"
                  :loading="Vget_item_load"
                  required
                  return-object
                  outlined
                  autocomplete="off"
                  @keyup.enter="nextfocus(7, item_det)"
                  :no-data-text="`No existe ninguna concidencia`"
                  small-chips
                >
                  <template v-if="item_permission" v-slot:no-data>
                    <v-list-item @click="formularios.item = true">
                      <v-list-item-content>
                        <v-list-item-title
                          >No existe concidencia. Click para
                          agregar</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              -->
              <v-col cols="11" md="3" sm="3">
                <c-text-currency
                  dense
                  v-model="item_cant"
                  id="input7"
                  :rules="rules.number"
                  :readonly="readonly"
                  label="Cantidad"
                  outlined
                  autocomplete="off"
                  @keyup.native.enter="addtabla(2)"
                ></c-text-currency>
              </v-col>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="readonly"
                color="primary"
                outlined
                text
                fab
                small
                @click="addtabla(2)"
              >
                <v-icon>add</v-icon>
              </v-btn>
              <v-col cols="12">
                <v-alert type="error" dense v-if="existDetail">
                  Ya existe este detalle
                </v-alert></v-col
              >
              <v-col cols="12">
                <v-data-table
                  hide-default-footer
                  :headers="headers"
                  :items="data.produccionleche"
                >
                  <template v-slot:[`item.action`]="props">
                    <v-btn
                      :disabled="readonly"
                      text
                      fab
                      x-small
                      @click="rowdelete(props.item)"
                    >
                      <v-icon>delete</v-icon></v-btn
                    >
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
            <v-row dense v-if="produccion === 1">
              <v-col cols="12" md="4" sm="4">
                <AutocompleteAnimal
                  :action="null"
                  getter="getNomina"
                  no-create
                  :readonly="readonly"
                  return-object
                  v-model="animal_det"
                  id="input7"
                  @keyup.native.enter="nextfocus(7, animal_det)"
                />
              </v-col>
              <!--
              <v-col cols="12" md="4" sm="4">
                <v-autocomplete
                  dense
                  v-model="item_det"
                  height="12px"
                  id="input8"
                  :items="Vget_item_lacteo"
                  label="Item"
                  item-text="descitem"
                  :rules="rules.item"
                  item-value="iditem"
                  :loading="Vget_item_load"
                  required
                  :readonly="readonly"
                  return-object
                  outlined
                  autocomplete="off"
                  @keyup.enter="nextfocus(8, item_det)"
                  :no-data-text="`No existe ninguna concidencia`"
                  small-chips
                >
                  <template v-if="item_permission" v-slot:no-data>
                    <v-list-item @click="formularios.item = true">
                      <v-list-item-content>
                        <v-list-item-title
                          >No existe concidencia. Click para
                          agregar</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              -->
              <v-col cols="10" md="3" sm="3">
                <c-text-currency
                  dense
                  v-model="item_cant"
                  id="input8"
                  label="Cantidad"
                  :rules="rules.number"
                  :readonly="readonly"
                  outlined
                  autocomplete="off"
                  @keyup.native.enter="addtabla(1)"
                ></c-text-currency>
              </v-col>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="readonly"
                color="primary"
                outlined
                text
                fab
                small
                @click="addtabla(1)"
              >
                <v-icon>add</v-icon>
              </v-btn>
              <v-col cols="12">
                <v-alert type="error" dense v-if="existDetail">
                  Ya existe este detalle
                </v-alert></v-col
              >
              <v-col cols="12">
                <v-data-table
                  :headers="headers_animal"
                  :items="data.produccionanimal"
                >
                  <template v-slot:[`item.action`]="props">
                    <v-btn
                      :disabled="readonly"
                      text
                      fab
                      x-small
                      @click="rowdelete(props.item)"
                    >
                      <v-icon>delete</v-icon></v-btn
                    >
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-form>
          <v-row dense>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="3">
              <c-text-currency
                dense
                outlined
                readonly
                v-model="produccionTotal"
                label="Produccion Total"
              ></c-text-currency>
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <c-btn-crud-close @click="cancelar()"></c-btn-crud-close>
          <v-spacer></v-spacer>
          <c-btn-crud-done id="aceptar" @click="guardar()"></c-btn-crud-done>
        </v-card-actions>
        <c-overlay :value="isLoading"></c-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { db } from "@/services/db.service";
import { focus } from "@/services/focus.service";
import { current_date } from "@/services/util/date.service";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { TURNO_ORDENO, ITEM } from "@/constants/forms";
import { PUEDE_AGREGAR } from "@/constants/permissions";
import AutocompleteAnimal from "@/views/Animal/Animal/AutocompleteAnimal";
export default {
  props: {
    value: Boolean,
    props: Object,
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AutocompleteAnimal,
  },
  data() {
    return {
      item_det: {
        iditem: 10,
        descitem: "Leche",
      },
      item_cant: "",
      animal_det: "",
      produccion: "",
      existDetail: false,
      rules: {
        number2: [
          (v) => !!v != null || "Obligatorio",
          (v) => !(Number(v) < 0) || "Este campo no puede ser menor a 0",
        ],
        number: [
          (v) => !!v || "Obligatorio",
          (v) => !(Number(v) <= 0) || "Este campo no puede ser menor o igual 0",
        ],
        item: [(v) => !!v.iditem || "Obligatorio"],
      },
      produccion_tipo: [
        { id: 1, fkdes: "Por Animal" },
        { id: 2, fkdes: "El total" },
      ],
      headers: [
        { text: "Item", sortable: false, value: "iditem.descitem" },
        {
          text: "Cantidad",
          sortable: false,
          value: "medidetproduccion",
          align: "end",
        },
        { text: "Accion", sortable: false, value: "action", align: "end" },
      ],
      headers_animal: [
        { text: "Item", sortable: false, value: "iditem.descitem" },
        { text: "Animal", sortable: false, value: "idanimal.rpanimal" },
        {
          text: "Cantidad",
          sortable: false,
          value: "medidetproducciont",
          align: "end",
        },
        { text: "Accion", sortable: false, value: "action", align: "end" },
      ],
      data: {
        idproduccionleche: 0,
        fechproduccionleche: current_date(),
        idresponsable: {
          idresponsable: "",
        },
        idturnoordeno: {
          idturnoordeno: "",
        },
        iddeposito: {
          iddeposito: "",
        },
        precio: 0,
        grasa: 0,
        produccionleche: [],
        produccionanimal: [],
        update_desde: null,
      },
      default: {
        idproduccionleche: 0,
        fechproduccionleche: current_date(),
        idresponsable: {
          idresponsable: "",
        },
        idturnoordeno: {
          idturnoordeno: "",
        },
        iddeposito: {
          iddeposito: "",
        },
        precio: 0,
        grasa: 0,
        produccionleche: [],
        produccionanimal: [],
        update_desde: null,
      },
      validars: [(v) => !!v || "Seleccione un campo"],
      operacion: true,
      turno: [],
      turno_load: true,
      formularios: {
        deposito: false,
        item: false,
        turnoordeno: false,
      },
    };
  },
  watch: {
    $route() {
      return this.cancelar();
    },

    turn_reload(data) {
      if (data) return this.nuevo_turno();
    },
  },
  computed: {
    ...mapGetters("produccion", ["isLoading"]),

    ...mapGetters("responsable", {
      abrirresponsable: "responsable_create",
    }),
    ...mapGetters("turno", {
      abrirturno: "turno_create",
      turn_reload: "turno_reload",
    }),
    ...mapGetters("item", ["Vget_item_lacteo", "Vget_item_load"]),
    turno_permission() {
      return getPermission(TURNO_ORDENO).permiso[PUEDE_AGREGAR];
    },

    item_permission() {
      return getPermission(ITEM).permiso[PUEDE_AGREGAR];
    },
    produccionTotal() {
      if (this.produccion === 2)
        return this.data.produccionleche.reduce(
          (acc, curr) => (acc = acc + Number(curr.medidetproduccion)),
          0
        );
      return this.data.produccionanimal.reduce(
        (acc, curr) => (acc = acc + Number(curr.medidetproducciont)),
        0
      );
    },
  },
  created() {
    this.nuevo(this.props);
  },
  mounted() {
    setTimeout(() => document.getElementById("input0").focus(), 500);
  },
  methods: {
    ...mapActions("animal", ["fetchNomina"]),
    ...mapActions("produccion", [
      "setProduccion",
      "setProduccionUpdate",
      "fetchProduccion",
    ]),
    ...mapActions("responsable", ["responsable_create"]),
    ...mapActions("turno", ["turno_create", "turno_reload"]),
    ...mapActions("item", ["Vact_item_lacteo"]),
    nextfocus(id, data) {
      focus.nextid2(id, data);
    },

    rowdelete(item) {
      if (this.produccion === 2) {
        this.index = this.data.produccionleche.indexOf(item);
        this.data.produccionleche.splice(this.index, 1);
        return (this.index = null);
      }
      this.index = this.data.produccionanimal.indexOf(item);
      this.data.produccionanimal.splice(this.index, 1);
      return (this.index = null);
    },
    existeDetalle(modo) {
      if (modo === 2)
        this.existDetail = this.data.produccionleche.find(
          (x) => x.iditem.iditem === this.item_det.iditem
        );
      if (modo === 1)
        this.existDetail = this.data.produccionanimal.find(
          (x) => x.idanimal.idanimal === this.animal_det.idanimal
        );
      return this.existDetail;
    },

    limpiar_det() {
      this.item_cant = "";
      this.animal_det = "";
    },
    addtabla(modo) {
      if (!this.$refs.formDet.validate()) return null;
      if (this.existeDetalle(modo)) return null;
      if (modo === 2) {
        this.data.produccionanimal = [];
        this.data.produccionleche.push({
          iditem: JSON.parse(JSON.stringify(this.item_det)),
          medidetproduccion: Number(this.item_cant),
          idproduccionleche: 0,
        });
      } else {
        this.data.produccionleche = [];
        this.data.produccionanimal.push({
          iditem: JSON.parse(JSON.stringify(this.item_det)),
          idanimal: this.animal_det,
          medidetproducciont: Number(this.item_cant),
          idproduccionleche: 0,
        });
      }
      this.limpiar_det();
      this.$refs.formDet.resetValidation();
      document.getElementById("input7").focus();
    },
    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.operacion = true;
      this.$emit("input", false);
      this.$emit("reset", null);
      this.fetchProduccion();
    },

    async guardar() {
      if (!this.$refs.form.validate()) return null;
      const response = this.operacion
        ? await this.setProduccion(this.data)
        : await this.setProduccionUpdate({
            id: this.data.idproduccionleche,
            data: this.data,
          });
      if (response.success) {
        this.data = JSON.parse(JSON.stringify(this.default));
        if (!this.operacion) return this.cancelar();
        this.produccion = "";
        this.$refs.form.resetValidation();
        setTimeout(() => document.getElementById("input0").focus(), 500);
      }
    },

    async nuevo_turno() {
      this.turno = await db.get(
        "/apiturnoordeno/",
        "idturnoordeno",
        "descturnoordeno"
      );
      this.turno_load = false;
      this.turno_reload(false);
    },
    tipo_produccion() {
      if (this.produccion === 1) {
        this.nuevo_animal();
      }
    },

    async nuevo_animal() {
      let turno = this.data.idturnoordeno.idturnoordeno;
      let fecha = this.data.fechproduccionleche;
      await this.fetchNomina({ turno, fecha });
    },

    nuevo(val) {
      this.nuevo_turno();
      this.Vact_item_lacteo();
      if (val) {
        this.produccion = val.produccionleche.length > 0 ? 2 : 1;
        this.operacion = false;
        this.data = JSON.parse(JSON.stringify(val));
      }
    },
  },
};
</script>
