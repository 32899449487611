import { get, post, put, del } from "@/services/api/api.service";
import { url, LOADING, FETCH } from "./constants";
export default {
    fetchCanje: async ({ commit, dispatch }) => {
        commit(LOADING, true);
        try {
          const response = await get(url.root);
          commit(FETCH, response);
        } catch (e) {
          dispatch("snackbar/setNotification", e, { root: true });
          commit(LOADING, false);
          throw e;
        }
        commit(LOADING, false);
      },
      async setCanje({ commit, dispatch }, data) {
        commit(LOADING, true);
        const response = await post(url.root, data);
        dispatch("snackbar/setNotification", response, { root: true });
        commit(LOADING, false);
        return response;
      },
      async setCanjeUpdate({ commit, dispatch }, { data, id }) {
        commit(LOADING, true);
        const response = await put(`${url.root}${id}/`, data);
        dispatch("snackbar/setNotification", response, { root: true });
        commit(LOADING, false);
        return response;
      },
      async setCanjeDelete({ commit, dispatch }, id) {
        commit(LOADING, true);
        const response = await del(`${url.root}${id}/`);
        dispatch("snackbar/setNotification", response, { root: true });
        commit(LOADING, false);
        return response;
      }
}
