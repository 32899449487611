export default [
    {
        path: '/maquinaria',
        component: () => import( /* webpackChunkName: "Informe Maquinaria" */ '@/views/Maquinaria/Root.vue'),
        children: [
            {
                path: '/',
                name: 'Inicio',
                component: () => import( /* webpackChunkName: "List" */ '@/views/Maquinaria/Inicio/Inicio.vue'),
            },
            {
                path: 'maquinaria',
                component: () => import( /* webpackChunkName: "Maquinarias" */ '@/views/Maquinaria/Maquinaria/index.vue'),
                children: [
                    {
                        path: 'maquinaria',
                        name: 'Maquinaria',
                        component: () => import( /* webpackChunkName: "Maquinaria" */ '@/views/Maquinaria/Maquinaria/Maquinaria/Maquinaria.vue'),
                    },
                    {
                        path: 'maquinariamantenimiento',
                        name: 'Maquinaria Mantenimiento',
                        component: () => import( /* webpackChunkName: "Maquinaria Mantenimiento" */ '@/views/Maquinaria/Maquinaria/MaquinariaMantenimiento/MaquinariaMantenimiento.vue'),
                    },
                    {
                        path: 'maquinariaasignacion',
                        name: 'Maquinaria Asignacion',
                        component: () => import( /* webpackChunkName: "Maquinaria Asignacion" */ '@/views/Maquinaria/Maquinaria/MaquinariaAsignacion/MaquinariaAsignacion.vue'),
                    },
                    {
                        path: 'maquinariagasto',
                        name: 'Maquinaria Gasto',
                        component: () => import( /* webpackChunkName: "Maquinaria Gasto" */ '@/views/Maquinaria/Maquinaria/MaquinariaGasto/MaquinariaGasto.vue'),
                    },
                    {
                        path: 'maquinariatransporte',
                        name: 'Maquinaria Transporte',
                        component: () => import( /* webpackChunkName: "Maquinaria Transporte" */ '@/views/Maquinaria/Maquinaria/MaquinariaTransporte/MaquinariaTransporte.vue' ),
                    },
                    {
                        path: 'maquinariasalario',
                        name: 'Maquinaria Salario',
                        component: () => import( /* webpackChunkName: "Maquinaria Salario" */ '@/views/Maquinaria/Maquinaria/MaquinariaSalario/MaquinariaSalario.vue' ),
                    }
                ],
            },
            {
                path: 'configuracion',
                name: 'Configuracion Maqunaria',
                component: () => import( /* webpackChunkName: "Configuracion" */ '@/views/Maquinaria/Settings/Settings.vue'),
            },
            {
                path: 'reporte',
                component: () => import( /* webpackChunkName: "Reporte" */ '@/views/Maquinaria/Reporte/index.vue'),
                children: [
                    {
                        path: 'reporte',
                        name: 'Reporte',
                        component: () => import( /* webpackChunkName: "Reporte" */ '@/views/Maquinaria/Reporte/Reporte.vue' ),
                    }
                ]
            },
            {
                path: 'mantenimiento',
                component: () => import( /* webpackChunkName: "Mantenimiento" */ '@/views/Maquinaria/Mantenimiento/index.vue'),
                children: [
                    {
                        path: 'tipo',
                        name: 'Tipo',
                        component: () => import( /* webpackChunkName: "Tipo" */ '@/views/Maquinaria/Mantenimiento/Tipo/Tipo.vue')
                    },
                    {
                        path: 'fabricante',
                        name: 'Fabricante',
                        component: () => import( /* webpackChunkName: "Sector" */ '@/views/Maquinaria/Mantenimiento/Fabricante/Fabricante.vue')
                    },
                    {
                        path: 'tipomantenimiento',
                        name: 'Tipo Mantenimiento',
                        component: () => import( /* webpackChunkName: "Tipo" */ '@/views/Maquinaria/Mantenimiento/MantenimientoTipo/MantenimientoTipo.vue')
                    },
                    {
                        path: 'tipogastomaquinaria',
                        name: 'Tipo Gasto Maquinaria',
                        component: () => import( /* webpackChunkName: "Tipo" */ '@/views/Maquinaria/Mantenimiento/GastoTipo/GastoTipo.vue')
                    },
                ]
            }
        ]
    }
]