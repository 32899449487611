export default {
  comprobante_create(state, request) {
    state.comprobante_create = request;
  }, 
  comprobante_update(state, request) {
    state.comprobante_update = request;
  },
  comprobante_reload(state, request) {
    state.comprobante_reload = request;
  }, 
  comprobante_show(state, request) {
    state.comprobante_show = request;
  }, 
  comprobante_modo(state, request) {
    state.comprobante_modo = request;
  }, 
  comprobante_validar(state, request) {
    state.comprobante_validar = request;
  }, 
  comprobante_key(state, request) {
    state.comprobante_key = request;
  }, 
}