import { db } from "@/services/db.service";
export default {
    async categoria_update({commit},request){
        commit('categoria_update',request);
    },
    async categoria_show({commit},request){
        commit('categoria_show',request);
    },
    async Vact_categoria_data({commit}){
        commit('categoria_load',true);
        try {
            const data = await db.get('/apicategoriaitem/');
            commit('categoria_data',data);
            commit('categoria_load',false);
            return true
        } catch (e) {
            commit('categoria_load',false);
            return false
        } 
    },
    async Vact_categoria_del({dispatch},id){
        try {
            const data = await db.delete('/apicategoriaitem/'+id);
            dispatch('Vact_categoria_data');
            return data
        } catch (e) {
            return false
        } 
    },
    async Vact_categoria_save({commit},data){
        commit('categoria_save_load',true);
        try {
            const resp = await db.save('/apicategoriaitem/',data);
            commit('categoria_save_load',false);
            return resp;
        } catch (e) {
            commit('categoria_save_load',false);
            return e
        } 
    },
    async Vact_categoria_update({commit},{id, data}){
        commit('categoria_save_load',true);
        try {
            const resp = await db.update('/apicategoriaitem/'+id+'/',data);
            commit('categoria_save_load',false);
            return resp;
        } catch (e) {
            commit('categoria_save_load',false);
            return e
        } 
    },
}
