export default {
  comprobante_create: false,
  comprobante_update: null,
  comprobante_reload: false,
  comprobante_show: false,
  comprobante_modo: false,
  comprobante_validar: false,
  comprobante_key: 0
}


