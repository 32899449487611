<template>
  <v-dialog persistent max-width="800" :value="value">
    <v-card>
      <v-toolbar flat dense>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-form ref="form">
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-switch
                v-model="data.iscuenta"
                inset
                :label="`Origen: ${data.iscuenta ? 'Cuenta' : 'Caja'}`"
                @change="changeOrigen()"
              ></v-switch>
            </v-col>
            
            <v-col cols="12" sm="4">
              <c-text-date
                label="Fecha"
                v-model="data.fecha"
              ></c-text-date>
            </v-col>
            <v-col cols="12" sm="4">
              <div v-if="data.iscuenta">
                <autocomplete-cuentabanco
                  label="Cuenta Bancaria de Origen"
                  v-model="data.adelantocuenta[0].idcuentabanco"
                  @change="changeMoneda()"
                />
              </div>
              <div v-else>
                <autocomplete-caja
                  label="Caja de Origen"
                  v-model="data.adelantocaja[0].idcaja"
                  @change="changeMoneda()"
                />
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              <v-select
                ref="adelantoInput0"
                :items="monedas"
                item-value="idmoneda"
                item-text="descmoneda"
                v-model="data.idmoneda"
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="5">
              <autocomplete-funcionario
                ref="adelantoInput1"
                label="Funcionario"
                v-model="data.idcontacto"
                @keyup.native.enter="
                  next(1, data.idcontacto.idcontacto.idcontacto)
                "
              />
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="3">
              <v-select
                ref="adelantoInput2"
                :items="months"
                item-value="position"
                item-text="desc"
                label="Mes de Adelanto"
                v-model="data.mes"
                @keyup.enter="
                  next(2, data.mes), ($refs.adelantoInput2.isMenuActive = false)
                "
              ></v-select>
            </v-col>
            <v-col cols="12" sm="3">
              <v-select
                ref="adelantoInput3"
                :items="years"
                label="Año"
                v-model="data.anho"
                @keyup.enter="
                  next(3, data.anho),
                    ($refs.adelantoInput3.isMenuActive = false)
                "
              ></v-select>
            </v-col>
          </v-row>

          <v-row dense class="mt-2">
            <v-spacer></v-spacer>
            <v-col cols="12" sm="3" class="mt-2">
              <span class="font-weight-black">Adelanto a cobrar:</span>
            </v-col>

            <v-col cols="12" sm="4">
              <c-text-currency
                v-model="data.monto"
                dense
                ref="adelantoInput4"
                :rules="[(v) => !!v || 'Requerido']"
                outlined
                @keyup.native.enter="next(1, data.monto)"
              ></c-text-currency>
            </v-col>
            <v-col cols="12">
              <v-textarea
                rows="2"
                ref="adelantoInput5"
                label="Comentario"
                v-model="data.comentario"
                @keyup.enter="next(5, '-')"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <c-btn-crud-close @click="cancel()"></c-btn-crud-close>
        <v-spacer></v-spacer>
        <c-btn-crud-done ref="adelantoInput6" @click="save()"></c-btn-crud-done>
      </v-card-actions>
      <c-overlay :value="isAdelantoLoading || isConfigRrhhLoading"></c-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  current_date,
  months,
  getMonthPosition,
} from "@/services/util/date.service";
import autocompleteFuncionario from "@/views/RRHH/Funcionarios/Information/autocomplete";
import autocompleteCuentabanco from "@/views/Apertura/Cuentabanco/autocomplete";
import autocompleteCaja from "@/views/Apertura/Caja/autocomplete";
import { generarRecibo } from "./../Salario/recibo";
const configRecibo = {
  title: "ANTICIPO DE SALARIO",
  articulo: "",
};
export default {
  components: {
    "autocomplete-funcionario": autocompleteFuncionario,
    "autocomplete-cuentabanco": autocompleteCuentabanco,
    "autocomplete-caja": autocompleteCaja,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
    },
  },
  watch: {
    $route() {
      return this.cancel();
    },
    getConfigRrhh(val) {
      if (val.configuracioncaja.length == 0) {
        this.data.iscuenta = true
      }
      this.changeOrigen()
      this.changeMoneda();
    },
  },
  mounted() {
    if (this.editable) {
      this.data = JSON.parse(JSON.stringify(this.props));
    } else {
      this.fetchConfigRrhh();
    }
    setTimeout(() => this.next(0, "-"), 20);
  },
  computed: {
    ...mapGetters("configrrhh", ["getConfigRrhh", "isConfigRrhhLoading"]),
    ...mapGetters("adelanto", ["isAdelantoLoading"]),
    title() {
      return this.editable ? "Modificar Adelanto" : "Registrar Adelanto";
    },
    years() {
      let year = new Date().getFullYear();
      const max = year + 5;
      let years = [];
      for (var i = year; i < max; i++) {
        years.push(i);
      }
      return years;
    },
    months: () =>
      months.map((x, i) => {
        return {
          position: i + 1,
          desc: x,
        };
      }),
  },
  methods: {
    ...mapActions("configrrhh", ["fetchConfigRrhh"]),
    ...mapActions("adelanto", [
      "setAdelanto",
      "fetchAdelanto",
      "setAdelantoUpdate",
    ]),
    changeOrigen() {
      let conf = this.getConfigRrhh
      this.data.adelantocuenta[0].idcuentabanco = conf.idcuentabanco;
      if (conf.configuracioncaja.length > 0) {
        this.data.adelantocaja[0].idcaja = conf.configuracioncaja[0].idcaja;
      } else {
        this.iscuenta = false;
      }
      this.changeMoneda();
    },
    changeMoneda() {
      this.data.idmoneda = this.default.idmoneda;
      if (this.data.iscuenta) {
        if (this.data.adelantocuenta[0].idcuentabanco.idcuentabanco != "") {
          this.data.idmoneda = JSON.parse(
            JSON.stringify(this.data.adelantocuenta[0].idcuentabanco.idmoneda)
          );
          this.monedas = [this.data.adelantocuenta[0].idcuentabanco.idmoneda]
        }
        this.data.adelantocaja = this.default.adelantocaja;
      } else {
        if (this.data.adelantocaja[0].idcaja.idcaja != "") {
          this.data.idmoneda = JSON.parse(
            JSON.stringify(this.data.adelantocaja[0].idcaja.cajadet[0].idmoneda)
          );
          this.data.adelantocaja[0].idcaja.cajadet.forEach((x) => {
            this.monedas.push(x.idmoneda)
          });
        }
        this.data.adelantocuenta = this.default.adelantocuenta;
      }
    },
    cancel() {
      this.$emit("input", false);
    },
    next(id, data) {
      if (data != "")
        try {
          this.$refs[`adelantoInput${id + 1}`].focus();
        } catch (error) {
          this.$refs[`adelantoInput${id + 1}`].$el.focus();
        }
    },

    formatAndGenerateRecibo() {
      const data = {
        ...this.data,
        periodo_inicio: this.data.fecha,
        periodo_fin: this.data.fecha,
        detalle: [
          {
            idconcepto_salario: {
              descripcion: "Anticipo Salarial",
              positivo_negativo: true,
            },
            monto: this.data.monto,
          },
        ],
      };
      generarRecibo(data, configRecibo, "Anticipo");
    },
    async save() {
      if (!this.$refs.form.validate()) return null;
      const response = this.editable
        ? await this.setAdelantoUpdate({
            data: this.data,
            id: this.data.idanticipo,
          })
        : await this.setAdelanto(this.data);
      if (response.success) {
        this.fetchAdelanto();
        this.formatAndGenerateRecibo();
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();
        this.next(0, "-");
        if (this.editable) this.cancel();
      }
    },
  },
  data: () => ({
    monedas: [],
    data: {
      idanticipo: 0,
      idcontacto: {
        idcontacto: "",
      },
      idcuentabanco: {
        idcuentabanco: "",
      },
      idmoneda: {
        idmoneda: "",
      },
      monto_pagado: 0,
      mes: getMonthPosition(current_date()),
      anho: new Date().getFullYear(),
      monto: "",
      fecha: current_date(),
      comentario: "",
      iscuenta: false,
      adelantocuenta: [
        {
          idcuentabanco: {
            idcuentabanco: ""
          }
        }
      ],
      adelantocaja: [
        {
          idcaja: {
            idcaja: ""
          }
        }
      ]
    },
    default: {
      idanticipo: 0,
      idcontacto: {
        idcontacto: "",
      },
      idcuentabanco: {
        idcuentabanco: "",
      },
      idmoneda: {
        idmoneda: "",
      },
      monto_pagado: 0,
      mes: getMonthPosition(current_date()),
      anho: new Date().getFullYear(),
      monto: "",
      fecha: current_date(),
      comentario: "",
      iscuenta: false,
      adelantocuenta: [
        {
          idcuentabanco: {
            idcuentabanco: ""
          }
        }
      ],
      adelantocaja: [
        {
          idcaja: {
            idcaja: ""
          }
        }
      ]
    },
  }),
};
</script>

<style></style>
