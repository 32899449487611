<template>
  <div>
    <v-dialog :value="value" max-width="700px" persistent @keydown.esc="cancelar()">
      <v-card>
        <v-form ref="form" lazy-validation>
          <v-toolbar flat>
            <v-toolbar-title>{{operacion ? 'Agregar Procedencia' : 'Modificar Procedencia'}}</v-toolbar-title>
          </v-toolbar>
          <v-container>
            <v-row row dense>
              <v-col cols="12">
                <v-text-field
                  dense
                  type="text"
                  :rules="validar"
                  id="procedenciaInput1"
                  v-model="data.descprocedencia"
                  label="Descripcion"
                  required
                  outlined
                  autocomplete="off"
                  @keyup.enter="nextfocus(1,data.descprocedencia)"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  dense
                  type="text"
                  :rules="validar"
                  id="procedenciaInput2"
                  v-model="data.abrvprocedencia"
                  label="Abreviatura"
                  required
                  outlined
                  autocomplete="off"
                  @keyup.enter="nextfocus(2,data.abrvprocedencia)"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>

          <v-card-actions>
            <v-btn color="red" text @click="cancelar">
              <v-icon>clear</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn id="procedenciaInputaceptar" color="primary" text @click="guardar">
              <v-icon>done</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { mensaje } from "@/services/notify.service";
import { focus } from "@/services/focus.service";
export default {
  props: {
    value: Boolean,
    datos: {
      type: [Object, Array],
      default: null,
    },
    "sync-input": {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      overlay: false,
      data: {
        descprocedencia: "",
        abrvprocedencia: "",
        estado: true
      },
      default: {
        descprocedencia: "",
        abrvprocedencia: "",
        estado: true
      },
      url: "/apiprocedencia/",
      validar: [(v) => !!v || "Campo requerido"],
      validars: [(v) => !!v || "Seleccione un campo"],
      formulario: {},
      operacion: true,
    };
  },
  computed: {
    ...mapGetters('procedencia',["Vget_procedencia_save_load"])
  },
  watch: {
    $route(to, from) {
      if (from.path === "/procedencia") return this.cancelar();
    },
  },
  created() {
    this.nuevo(this.datos);
  },
  mounted() {
    setTimeout(() => this.nextfocus(0,'-'), 500);
    if (this.syncInput != null) this.data.descprocedencia = this.syncInput;
  },
  methods: {
    ...mapActions("procedencia", [
      "Vact_procedencia_data",
      "Vact_procedencia_save",
      "Vact_procedencia_update"
    ]),

    nextfocus(index,data) {
      focus.nextidName("procedenciaInput", index, data);
    },
    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$emit("input", false);
      this.$emit("datos", null);
      this.Vact_procedencia_data();
      this.operacion = true;
    },
    async guardar_finalizar() {
      const sync =  this.syncInput;
      let a = await this.Vact_procedencia_save(this.data);
      if (a.estado == true) {
        if (sync != null) {
          this.cancelar();
          this.$emit('sync-input',a.data.data.id);
        }
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$notify(a.info);
        this.$refs.form.resetValidation();
        this.nextfocus(0,'-');
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.overlay = false;
    },
    async editar_finalizar() {
      const id = this.datos.idprocedencia;
      let a = await this.Vact_procedencia_update({id, data: this.data});
      if (a.estado == true) {
        this.$notify(a.info);
        this.cancelar();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.overlay = false;
    },
    guardar() {
      if (!this.$refs.form.validate()) return this.$notify(mensaje.completar());
      this.overlay = true;
      if (this.operacion === true) return this.guardar_finalizar();
      if (this.operacion === false) return this.editar_finalizar();
    },
    nuevo(val) {
      if (val !== null) {
        this.data = val;
        this.operacion = false;
      }
    },
  },
};
</script>

