<template>
  <div>
    <Crud
      v-if="crud.add"
      v-model="crud.add"
    />
    <Crud
      v-if="crud.view"
      v-model="crud.view"
      :props="crud.datos"
      :editable="!crud.readonly"
      :readonly="crud.readonly"
    />

    <v-alert type="error" v-if="permission_alert"
      >Error!!! Este usuario no tiene permisos en esta ventana</v-alert
    >
    <v-toolbar flat dense>
      <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
      <widgets-Favorite :ruta="$route.path" :formulario="$route.name" />
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <c-text-table-search
        v-if="permission.can_view"
        v-model="search"
        class="mr-1"
      ></c-text-table-search>

      <v-toolbar-items>
        <c-btn-table-add
          @click="crud.add = true"
          v-if="permission.can_add"
        ></c-btn-table-add>
        <c-btn-table-reload
          class="ml-1"
          @click="fetchJustificacion()"
        ></c-btn-table-reload>
      </v-toolbar-items>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="getJustificacion"
      :loading="isJustificacionLoading"
      :search="search"
      v-if="permission.can_view"
    >
      <template v-slot:[`item.fecha`]="props">
        <div>
          {{ formatFecha(props.item.fecha_inicio) }} /
          {{ formatFecha(props.item.fecha_fin) }}
        </div>
      </template>
      <template v-slot:[`item.accion`]="props">
        <c-btn-table-view @click="update_item(props.item,'view')" v-if="permission.can_view" />
        <c-btn-table-edit @click="update_item(props.item)" v-if="permission.can_update" />
        <c-btn-table-delete @click="delete_item(props.item)" v-if="permission.can_delete" />
      </template>
    </v-data-table>

    <Delete
      v-if="crud.delete"
      v-model="crud.delete"
      :props="crud.datos"
      @datos="crud.datos = $event"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { dmy } from "@/services/util/date.service";
import Crud from "./Crud";
import Delete from "./Delete";
import { JUSTIFICACIONES } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";
export default {
  components: {
    Crud,
    Delete,
  },
  data() {
    return {
      title: JUSTIFICACIONES,
      search: "",

      headers: [
        {
          text: "Funcionario",
          align: "left",
          value: "idcontacto.idcontacto.desccontacto",
        },
        {
          text: "Comentario",
          align: "left",
          value: "comentario",
        },
        {
          text: "Rango de Fecha Justificado",
          align: "left",
          value: "fecha",
        },
        { text: "Accion", value: "accion", sortable: false, align: "right" },
      ],
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
      crud: {
        delete: false,
        add: false,
        view: false,
        readonly: false,
        datos: null,
      },
    };
  },
  computed: {
    ...mapGetters("justificacion", [
      "getJustificacion",
      "isJustificacionLoading",
    ]),
    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    const permission = getPermission(JUSTIFICACIONES).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
    this.fetchJustificacion();
  },
  methods: {
    ...mapActions("justificacion", ["fetchJustificacion"]),
    formatFecha(date) {
      return dmy(date);
    },
    update_item(data, view) {
      this.crud.datos = JSON.parse(JSON.stringify(data));
      view ? (this.crud.readonly = true) : (this.crud.readonly = false);
      this.crud.view = true;
    },
    delete_item(item) {
      this.crud.datos = JSON.parse(JSON.stringify(item));
      this.crud.delete = true;
    },
  },
};
</script>