<template>
  <div>
    <v-select
      :hide-details="hideDetails"
      :label="label"
      placeholder="Busqueda"
      :items="estados"
      :rounded="rounded"
      item-value="text"
      item-text="text"
      :rules="[(v) => !!v || 'Seleccione un fitro']"
      :dense="dense"
      :filled="filled"
      :value="value"
      multiple
      @input="$emit('input', $event)"
    ></v-select>
  </div>
</template>
<script>
export default {
  props: {
    value: Array,
    label: String,
    filled: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: true,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    "hide-details": {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    estados: [
      { text: "Fecha Inseminacion" },
      { text: "Toro" },
      { text: "Fecha Parto" },
      { text: "Dias Abiertos" },
      { text: "Pajuela" },
      { text: "Partos" },
      { text: "Estado Secado"},
      { text: "Intentos" }
    ],
  }),
};
</script>
