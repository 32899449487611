<template>
    <div>
        <v-card>
            <c-alert-permission v-model="permission"></c-alert-permission>
            <v-toolbar flat dense>
                <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
                <widgets-Favorite :ruta="$route.path" :formulario="$route.name" />
                <v-divider class="mx-2" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <c-text-table-search v-if="permission.can_view" v-model="search"></c-text-table-search>
                <v-toolbar-items>
                    <c-btn-table-add @click="create()" v-if="permission.can_add" class="mr-1"></c-btn-table-add>
                    <c-btn-table-reload @click="fetchReajuste()"></c-btn-table-reload>
                </v-toolbar-items>
            </v-toolbar>
            <v-data-table :loading="isLoading" :headers="headers" :items="getReajuste" :search="search"
                v-if="permission.can_view">
                <template v-slot:[`item.fecha`]="{ item }">
                    <div>{{ formDate(item.fecha) }}</div>
                </template>
                <template v-slot:[`item.positivo`]="{ item }">
                    <div>
                        {{ item.positivo ? "Agregado" : "Restado" }}
                    </div>
                </template>
                <template v-slot:[`item.valor`]="{ item }">
                    <div>
                        {{ toCurrency(item.valor) }}
                    </div>
                </template>
                <template v-slot:[`item.accion`]="{ item }">
                    <c-btn-table-view class="mr-2" @click="rowselect(item)"></c-btn-table-view>
                    <c-btn-table-delete @click="meliminar(item)"></c-btn-table-delete>
                </template>
            </v-data-table>
        </v-card>
        <Crud v-if="crud.add" v-model="crud.add" :readonly="crud.readonly" :datos="crud.datos"
            @datos="crud.datos = $event" />
        <Delete v-model="crud.delete" v-if="crud.delete" :props="crud.datos" @datos="crud.datos = $event" />
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { getPermission } from "@/services/storage/permissionStorage.service";
import { currency } from "@/services/util/number.service";
import { ZAFRA } from "@/constants/forms";
import { dmy } from "@/services/util/date.service";
import Crud from "./Create";
import Delete from "./Delete";
import {
    PUEDE_AGREGAR,
    PUEDE_ELIMINAR,
    PUEDE_LISTAR,
} from "@/constants/permissions";
export default {
    components: {
        Crud,
        Delete
    },
    data: () => ({
        search: "",
        headers: [
            {
                text: "Fecha",
                align: "left",
                value: "fecha"
            },
            {
                text: "Tipo Grano",
                align: "left",
                value: "iditem.descitem",
            },
            {
                text: "Campaña",
                align: "left",
                value: "idcampana.descripcion",
            },
            {
                text: "Deposito",
                align: "left",
                value: "iddeposito.desdeposito",
            },
            {
                text: "Operacion",
                align: "end",
                value: "positivo",
            },
            {
                text: "Cantidad",
                align: "end",
                value: "valor",
            },
            {
                text: "Comentario",
                align: "end",
                value: "comentario",
            },
            { text: "Accion", value: "accion", sortable: false, align: "right" },
        ],
        permission: {
            can_add: false,
            can_view: false,
            can_delete: false,
        },
        crud: {
            add: false,
            view: false,
            readonly: false,
            datos: null,
            delete: false
        }
    }),
    created() {
        this.$store.commit("SET_LAYOUT", "principal-layout");
        this.fetchReajuste()
        const permission = getPermission(ZAFRA).permiso
        this.permission = {
            can_add: permission[PUEDE_AGREGAR],
            can_delete: permission[PUEDE_ELIMINAR],
            can_view: permission[PUEDE_LISTAR]
        }
    },
    computed: {
        ...mapGetters("reajuste", ["getReajuste", "isLoading"]),
    },
    methods: {
        ...mapActions("reajuste", ["fetchReajuste"]),
        formDate: (date) => dmy(date),
        meliminar(item) {
            this.crud.datos = JSON.parse(JSON.stringify(item));
            this.crud.delete = true;
        },
        rowselect(val) {
            this.crud.datos = JSON.parse(JSON.stringify(val));
            this.crud.readonly = true;
            this.crud.add = true;
        },
        mupdate(val) {
            this.crud.readonly = false;
            this.crud.datos = JSON.parse(JSON.stringify(val));
            this.crud.add = true;
        },
        create() {
            this.crud.readonly = false;
            this.crud.add = true;
        },
        toCurrency(value) {
            return currency(value)
        }
    }
}
</script>