
export default {
  Vget_asientodiario_data: (state) => {
    return state.asientodiario_data
  },
  Vget_asientodiario_load: (state) => {
    return state.asientodiario_load
  },
  Vget_asientodiario_save_load: (state) => {
    return state.asientodiario_save_load
  },
}
