import { db } from "@/services/db.service";
export default {
    async gymtipo_update({commit},data){
        commit('gymtipo_update',data);
    },

    async Vact_gymtipo_data({commit}){
        commit('gymtipo_load',true);
        try {
            const data = await db.get('/apigimnasiotipo/');
            commit('gymtipo_data',data);
            commit('gymtipo_load',false);
            return true
        } catch (e) {
            commit('gymtipo_load',false);
            return false
        } 
    },
    async Vact_gymtipo_save({dispatch, commit},data){
        commit('gymtipo_save_load',true);
        try {
            const resp = await db.save('/apigimnasiotipo/',data);
            dispatch('Vact_gymtipo_data');
            commit('gymtipo_save_load',false);
            return resp;
        } catch (e) {
            commit('gymtipo_save_load',false);
            return e
        } 
    },
    async Vact_gymtipo_update({commit},{id, data}){
        commit('gymtipo_save_load',true);
        try {
            const resp = await db.update('/apigimnasiotipo/'+id+'/',data);
            commit('gymtipo_save_load',false);
            return resp;
        } catch (e) {
            commit('gymtipo_save_load',false);
            return e
        } 
    },
    async Vact_gymtipo_del({dispatch},id){
        try {
            const data = await db.delete('/apigimnasiotipo/'+id);
            dispatch('Vact_gymtipo_data');
            return data
        } catch (e) {
            return false
        } 
    }
}
