<template>
  <div>
    <v-dialog :value="value" max-width="900px" persistent @keydown.esc="cancelar()">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>{{'Realizar translado Caja a Banco'}}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form">
            <v-row dense>
              <v-col cols="12">
                <c-text-date
                  label="Fecha"
                  dense
                  outlined
                  id="transCBInput1"
                  v-model="data.fechtransladocaja"
                  @keyup.native.enter="next(1,data.fechtransladocaja)"
                />
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <vue-autocomplete-object
                  label="Caja de Origen"
                  id="transCBInput2"
                  action="caja/Vact_caja_data"
                  getter="caja/Vget_caja_data"
                  load="caja/Vget_caja_load"
                  pk="idcaja"
                  validar="idcaja"
                  permission="Caja"
                  title="descaja"
                  v-model="data.idcajaorigen"
                  @change="addDetalle()"
                  @keyup.native.enter="next(2,data.idcajaorigen.idcaja)"
                />
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <vue-text-field
                  label="Motivo de Translado"
                  id="transCBInput3"
                  v-model="data.motivotranslado"
                  @keyup.native.enter="next(3,data.motivotranslado)"
                />
              </v-col>
            </v-row>
            <v-form ref="formDetalle">
              <v-row v-for="(item, i) of data.transladodet" :key="i" dense>
                <v-col cols="12" md="2" sm="2">
                  <vue-text-field
                    label="Moneda"
                    readonly
                    :rules="true"
                    v-model="data.transladodet[i].moneda"
                  />
                </v-col>
                <v-col cols="12" md="2" sm="2">
                  <vue-text-currency
                    label="Monto a Depositar"
                    :id="`transCBInput${4+i}`"
                    :messages="data.transladodet[i].disponible"
                    :rules="false"
                    v-model="data.transladodet[i].monto"
                    @keyup.native="data.transladodet[i].cotizacion = '', data.transladodet[i].montorecibir = ''"
                    @keyup.native.enter="next(4+i,data.transladodet[i].monto)"
                  />
                </v-col>
                <v-col cols="12" md="2" sm="2">
                  <v-autocomplete
                    :items="Vget_cuentabanco_data"
                    item-text="desccuentabanco"
                    item-value="idcuentabanco"
                    return-object
                    autocomplete="off"
                    outlined
                    dense
                    append-icon
                    :loading="Vget_cuentabanco_load"
                    label="Cuenta Banco"
                    @change="verificarMoneda(i)"
                    v-model="data.transladodet[i].idcuentabanco"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="2" v-if="data.transladodet[i].convertir">
                  <vue-text-field
                    label="Nro de Deposito"
                    :id="`transCBInput${5+i}`"
                    :rules="false"
                    v-model="data.transladodet[i].nrotranslado"
                    @keyup.native.enter="next(5+i,data.transladodet[i].monto)"
                  />
                </v-col>
                <v-col cols="12" sm="2" v-if="data.transladodet[i].convertir">
                  <vue-text-currency
                    :rules="true"
                    :label="'Cotizacion '+data.transladodet[i].idcuentabanco.idmoneda.descmoneda"
                    v-model="data.transladodet[i].cotizacion"
                    @keyup.native="calcularMontoRecibir(i)"
                  ></vue-text-currency>
                </v-col>
                <v-col cols="12" sm="2" v-if="data.transladodet[i].convertir">
                  <vue-text-currency
                    :rules="true"
                    :label="'Monto '+data.transladodet[i].idcuentabanco.idmoneda.descmoneda"
                    v-model="data.transladodet[i].montorecibir"
                    @keyup.native="calcularCotizacion(i)"
                  ></vue-text-currency>
                </v-col>
              </v-row>
            </v-form>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn color="red" text @click="cancelar">
            <v-icon dark>close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn id="transCBInputaceptar" color="primary" text @click="guardar()">
            <v-icon dark>done</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
      <c-overlay :value="Vget_transladocajabanco_save_load"></c-overlay>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mensaje } from "@/services/notify.service";
import { current_date } from "@/services/util/date.service";
import { focus } from "@/services/focus.service";
export default {
  props: {
    value: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      caja_open: false,
      data: {
        idtransladobanco: 0,
        idcajaorigen: {
          idcaja: "",
        },
        fechtransladocaja: current_date(),
        motivotranslado: "",
        transladodet: [],
      },
      default: {
        idtransladobanco: 0,
        idcajaorigen: {
          idcaja: "",
        },
        fechtransladocaja: current_date(),
        motivotranslado: "",
        transladodet: [],
      },
      operacion: true,
    };
  },
  watch: {
    $route(to, from) {
      if (from.path === "/transladocajabanco") return this.cancelar();
    },
  },
  mounted() {
    setTimeout(() => this.next(0, "-"), 200);
    this.Vact_cuentabanco_data();
  },
  computed: {
    ...mapGetters("transladocajabanco", ["Vget_transladocajabanco_save_load"]),
    ...mapGetters("cuentabanco", [
      "Vget_cuentabanco_data",
      "Vget_cuentabanco_load",
    ]),
  },
  methods: {
    ...mapActions("transladocajabanco", [
      "Vact_transladocajabanco_data",
      "Vact_transladocajabanco_save",
    ]),
    ...mapActions("cuentabanco", ["Vact_cuentabanco_data"]),
    ...mapActions("caja",["Vact_caja_data"]),
    verificarMoneda(i) {
      this.data.transladodet[i].convertir = false;
      this.data.transladodet[
        i
      ].idmonedaconvertir.idmoneda = this.data.transladodet[
        i
      ].idcuentabanco.idmoneda.idmoneda;
      if (
        this.data.transladodet[i].idmoneda.idmoneda !=
        this.data.transladodet[i].idcuentabanco.idmoneda.idmoneda
      ) {
        this.data.transladodet[i].convertir = true;
      }
    },
    calcularMontoRecibir(i) {
      this.data.transladodet[i].montorecibir =
        Number(this.data.transladodet[i].cotizacion) *
        Number(this.data.transladodet[i].monto);
    },
    calcularCotizacion(i) {
      this.data.transladodet[i].cotizacion =
        Number(this.data.transladodet[i].montorecibir) /
        Number(this.data.transladodet[i].monto);
    },
    addDetalle() {
      this.data.transladodet = [];
      this.data.idcajaorigen.cajadet.forEach((caja) => {
        this.data.transladodet.push({
          idtransladobanco: 0,
          idmoneda: caja.idmoneda,
          idmonedaconvertir: {
            idmoneda: "",
          },
          idcuentabanco: {
            idcuentabanco: "",
          },
          cotizacion: "",
          convertir: false,
          monto: "",
          disponible:
            "Saldo Disponible: " + new Intl.NumberFormat().format(caja.monto),
          nrotranslado: "",
          moneda: caja.idmoneda.descmoneda,
        });
      });
    },
    next(index, data) {
      focus.nextidName("transCBInput", index, data);
    },
    cancelar() {
      this.$emit("input", false);
      this.data = JSON.parse(JSON.stringify(this.default));
      this.Vact_transladocajabanco_data();
      this.operacion = true;
    },
    async guardar_finalizar() {
      let a = await this.Vact_transladocajabanco_save(this.data);
      if (a.estado == true) {
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$notify(a.info);
        this.$refs.form.resetValidation();
        this.Vact_caja_data();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
    },
    guardar() {
      if (!this.$refs.form.validate() || !this.$refs.formDetalle.validate())
        return this.$notify(mensaje.completar());
      if (this.operacion === true) return this.guardar_finalizar();
    },
  },
};
</script>