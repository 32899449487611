<template>
  <v-autocomplete
    :readonly="readonly"
    dense
    ref="input"
    :id="id"
    :items="items"
    :label="label"
    :item-text="config.text"
    :item-value="config.value"
    return-object
    :rules="[(v) => !!v.iddeposito || 'Obligatorio']"
    :loading="Vget_item_load"
    :messages="`Cantidad Disponible: ${toCurrency(stock || 0)}`"
    required
    outlined
    :value="value"
    autocomplete="off"
    @input="setValue($event)"
  >
  </v-autocomplete>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { currency } from "@/services/util/number.service";
export default {
  props: {
    value: [String, Number, Object],
    idcampana: {
      type: [Number, String],
    },
    returnType: {
      type: String,
      default: "deposito",
    },
    readonly: Boolean,
    id: String,
    label: {
      type: String,
      default: "Deposito",
    },
  },
  data: () => ({
    stock: null,
  }),
  watch: {
    idcampana() {
      this.obtenerItem();
    },
    returnType() {
      this.obtenerItem();
    },
  },
  computed: {
    ...mapGetters("item", [
      "Vget_item_agrostock",
      "Vget_item_ensilaje",
      "Vget_item_load",
    ]),
    config() {
      let config = {
        text: "desdeposito",
        value: "iddeposito",
        type: this.returnType,
      };
      switch (this.returnType) {
        case "deposito":
          return config;
        case "stock":
          config.text = "iddeposito.desdeposito";
          config.value = undefined;
          return config;
        case "ensilaje":
          config.text = "iditem.descitem";
          config.value = "idstock";
          return config;
        case "hum":
          config.text = "iditem.descitem";
          config.value = "idstock";
          return config;
        default:
          return config;
      }
    },
    items() {
      if (this.config.type === "ensilaje" || this.config.type === "hum")
        return this.Vget_item_ensilaje.map((x) => ({
          ...x,
          iditem: {
            ...x.iditem,
            descitem:
              x.iditem.descitem + " - Deposito: " + x.iddeposito.desdeposito,
          },
        }));
      if (this.config.type === "deposito")
        return this.Vget_item_agrostock.map((x) => ({
          ...x.iddeposito,
          stock: x.cantstock,
        }));
      return this.Vget_item_agrostock;
    },
  },
  methods: {
    ...mapActions("item", ["Vact_item_agrostock", "Vact_item_ensilaje"]),
    toCurrency(value) {
      return currency(value);
    },
    obtenerItem() {
      if (this.idcampana === "" || !this.idcampana) return null;
      if (this.returnType === "hum")
        return this.Vact_item_ensilaje({ 
          idcampana: this.idcampana, 
          ensilaje: 'hum' 
        });
      if (this.returnType === "ensilaje")
        return this.Vact_item_ensilaje({ 
          idcampana: this.idcampana, 
          ensilaje: 'yes' 
        });
      if (this.returnType !== "ensilaje")
        return this.Vact_item_agrostock({
          search: "",
          campana: this.idcampana,
        });
      
    },
    setValue(value) {
      this.$emit("input", value);
      if (this.config.type === "deposito") return (this.stock = value.stock);
      this.stock = value.cantstock;
    },
    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>
