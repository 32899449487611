export default {
    async estadocria_create({commit},request){
        commit('estadocria_create',request);
    },
    async estadocria_update({commit},request){
        commit('estadocria_update',request);
    },
    async estadocria_reload({commit},request){
        commit('estadocria_reload',request);
    },
    async estadocria_show({commit},request){
        commit('estadocria_show',request);
    },
    async estadocria_key({commit},request){
        commit('estadocria_key',request);
    },
}
