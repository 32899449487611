
<template>
  <div>
    <v-dialog :value="value" max-width="700" persistent>
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Crear Punto de Venta</v-toolbar-title>
        </v-toolbar>
        <v-form ref="form" lazy-validation>
          <v-container fluid>
            <v-row no-gutters>
              <v-col cols="12" md="12">
                <vue-text-field
                  label="Descripcion"
                  id="puntoventaInput1"
                  v-model="data.descpuntoventa"
                  @keyup.native.enter="next(1,data.descpuntoventa)"
                />
              </v-col>
              <v-col cols="12" md="6">
                <input-autocompletetimbrado
                  label="Timbrado para factura"
                  id="puntoventaInput2"
                  api="/apitimbrado/?band=nuevo&tipo=factura"
                  v-model="data.timbrado_puntoventa[0].idtimbrado"
                  @keyup.native.enter="next(2,data.timbrado_puntoventa[0].idtimbrado)"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete
                  dense
                  v-model="data.timbrado_puntoventa[0].idconfig_impresion.idconfig_impresion"
                  v-bind:items="config"
                  label="Configuracion Factura"
                  item-text="descreportes"
                  item-value="idconfig_impresion"
                  :loading="config_load"
                  outlined
                  autocomplete="off"
                  @keyup.enter="next(5,data.timbrado_puntoventa[0].idconfig_impresion.idconfig_impresion)"
                  :no-data-text="`No existe ninguna concidencia`"
                  small-chips
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" md="6">
                <input-autocompletetimbrado
                  label="Timbrado para Nota de Credito"
                  api="/apitimbrado/?band=nuevo&tipo=notacredito"
                  v-model="data.timbrado_puntoventa[1].idtimbrado"
                  @keyup.native.enter="next(3,data.timbrado_puntoventa[1].idtimbrado)"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete
                  dense
                  v-model="data.timbrado_puntoventa[1].idconfig_impresion.idconfig_impresion"
                  v-bind:items="config"
                  label="Configuracion Nota de Credito"
                  item-text="descreportes"
                  item-value="idconfig_impresion"
                  :loading="config_load"
                  outlined
                  autocomplete="off"
                  @keyup.enter="next(5,data.timbrado_puntoventa[1].idconfig_impresion.idconfig_impresion)"
                  :no-data-text="`No existe ninguna concidencia`"
                  small-chips
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6">
                <input-autocompletetimbrado
                  label="Timbrado para Nota de Remision"
                  id="puntoventaInput4"
                  api="/apitimbrado/?band=nuevo&tipo=notaremision"
                  v-model="data.timbrado_puntoventa[2].idtimbrado"
                  @keyup.native.enter="next(4,data.timbrado_puntoventa[2].idtimbrado)"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete
                  dense
                  v-model="data.timbrado_puntoventa[2].idconfig_impresion.idconfig_impresion"
                  v-bind:items="config"
                  label="Configuracion Nota de Remision"
                  item-text="descreportes"
                  item-value="idconfig_impresion"
                  :loading="config_load"
                  outlined
                  autocomplete="off"
                  @keyup.enter="next(5,data.timbrado_puntoventa[2].idconfig_impresion.idconfig_impresion)"
                  :no-data-text="`No existe ninguna concidencia`"
                  small-chips
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="12">
                <vue-autocomplete-generic
                  label="Caja del Punto de venta"
                  id="puntoventaInput5"
                  action="caja/Vact_caja_data"
                  getter="caja/Vget_caja_data"
                  load="caja/Vget_caja_load"
                  pk="idcaja"
                  :create="true"
                  @create="caja_open = $event"
                  permission="Caja"
                  title="descaja"
                  v-model="data.idcaja.idcaja"
                  @keyup.native.enter="next(5,data.idcaja.idcaja)"
                />
                <div v-if="caja_open">
                    <Apertura-Caja-create v-model="caja_open" />
                </div>
              </v-col>
              <v-col cols="12">
                <input-autocomplete
                  label="Metodo de impresion"
                  api="/apiimpresionforma/"
                  pk="idimpresion_forma"
                  title="descripcion"
                  :noactive="false"
                  id="puntoventaInput8"
                  v-model="data.idimpresion_forma.idimpresion_forma"
                  @keyup.native.enter="next(8,data.idimpresion_forma.idimpresion_forma)"
                />
              </v-col>
              <v-col cols="12" sm="6">
                <vue-text-field
                  label="Cantidad de Reimpresiones"
                  id="puntoventaInput9"
                  v-model="data.cantidad"
                  @keyup.native.enter="next(9,data.cantidad)"
                ></vue-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <vue-text-field
                  label="Limite de Items en Factura"
                  id="puntoventaInput10"
                  v-model="data.filasfactura"
                  @keyup.native.enter="next(10,data.cantidad)"
                ></vue-text-field>
              </v-col>
              <v-col cols="12">
                <v-switch
                  :label="`Control de caja por apertura y cierre?: ${tipo}`"
                  v-model="data.apertura"
                ></v-switch>
              </v-col>
            </v-row>
          </v-container>
          <v-container fluid>
            <v-row dense no-gutters cols="10" v-for="(item, i) in data.puntoventa_usuario" :key="i">
              <v-col>
                <input-autocomplete
                  :label="`Usuario ${data.puntoventa_usuario[i].activo ? 'Habilidado' : 'Deshabilitado'}`"
                  api="/apicuenta2/?active=yes"
                  pk="id"
                  title="username"
                  :id="`puntoventaInput${11+i}`"
                  :noactive="true"
                  :readonly="!operacion && data.puntoventa_usuario[i].idpuntoventa != 0"
                  :disabled="!data.puntoventa_usuario[i].activo"
                  :chips="data.puntoventa_usuario[i].idpuntoventa === 0"
                  v-model="data.puntoventa_usuario[i].idusuario.id"
                  @keyup.native.enter="next(9+i,data.puntoventa_usuario[i].idusuario.id)"
                />
              </v-col>
              <v-col cols="1" v-if="data.puntoventa_usuario[i].idpuntoventa === 0 && i != 0">
                <v-btn class="ma-2" fab text small color="primary" @click="deleteuser(i)">
                  <v-icon>clear</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="1" v-if="!operacion && data.puntoventa_usuario[i].idpuntoventa != 0">
                <v-btn class="ma-2" fab text small color="primary" @click="disableduser(i)">
                  <v-icon>block</v-icon>
                </v-btn>
              </v-col>
              <v-col
                cols="1"
                v-if="i === ultimoindex && data.puntoventa_usuario[i].idusuario.id != ''"
              >
                <v-btn class="ma-2" fab text small color="primary" @click="adduser">
                  <v-icon>add</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-card-actions>
          <v-btn color="red" text @click="close()">
            <v-icon dark>close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn id="aceptar" color="primary" text @click="pre_guardar">
            <v-icon dark>done</v-icon>
          </v-btn>
        </v-card-actions>
        <c-overlay :value="Vget_puntoventa_save_load"></c-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { booleanConverter } from "@/services/util/boolean.service";
import { db } from "@/services/db.service";
import { mensaje } from "@/services/notify.service";
import { focus } from "@/services/focus.service";
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    editar: {
      type: [Object, Array],
      default: null
    }
  },
  data() {
    return {
      caja_open: false,
      validars: [v => !!v || "Seleccione un campo"],
      noData: true,

      data: {
        
        idpuntoventa: 0,
        descpuntoventa: "",
        apertura: false,
        idcaja: {
          idcaja: ""
        },
        idcaja_a_depositar: {
          idcaja: ""
        },
        idcuentabanco_a_depositar: {
          idcuentabanco: ""
        },
        idimpresion_forma: {
          idimpresion_forma: ""
        },
        timbrado_puntoventa: [
          {
            idpuntoventa: 0,
            idtimbrado: { idtimbrado: "" },
            idconfig_impresion: { idconfig_impresion: "" }
          },
          {
            idpuntoventa: 0,
            idtimbrado: { idtimbrado: "" },
            idconfig_impresion: { idconfig_impresion: "" }
          },
          {
            idpuntoventa: 0,
            idtimbrado: { idtimbrado: "" },
            idconfig_impresion: { idconfig_impresion: "" }
          }
        ],
        puntoventa_usuario: [
          {
            idpuntoventa: 0,
            idusuario: {
              id: ""
            },
            activo: true
          }
        ]
      },
      default: {
        idpuntoventa: 0,
        descpuntoventa: "",
        apertura: false,
        idcaja: {
          idcaja: ""
        },
        idcaja_a_depositar: {
          idcaja: ""
        },
        idcuentabanco_a_depositar: {
          idcuentabanco: ""
        },
        idimpresion_forma: {
          idimpresion_forma: ""
        },
        timbrado_puntoventa: [
          {
            idpuntoventa: 0,
            idtimbrado: { idtimbrado: "" },
            idconfig_impresion: { idconfig_impresion: "" }
          },
          {
            idpuntoventa: 0,
            idtimbrado: { idtimbrado: "" },
            idconfig_impresion: { idconfig_impresion: "" }
          },
          {
            idpuntoventa: 0,
            idtimbrado: { idtimbrado: "" },
            idconfig_impresion: { idconfig_impresion: "" }
          }
        ],
        puntoventa_usuario: [
          {
            idpuntoventa: 0,
            idusuario: {
              id: ""
            },
            activo: true
          }
        ]
      },
      url: "/apipuntoventa/",
      operacion: true,
      id: "",
      config: [],
      config_load: false
    };
  },
  watch: {
    $route(to, from) {
      if (from.path === "/puntoventa") return this.close();
    }
  },
  computed: {
    ...mapGetters('puntoventa',['Vget_puntoventa_save_load']),

    ultimoindex() {
      return this.data.puntoventa_usuario.length - 1;
    },
    tipo() {
      return booleanConverter.si_no(this.data.apertura);
    }
  },
  created() {
    this.nuevo(this.editar);
  },
  methods: {
    ...mapActions('puntoventa',["Vact_puntoventa_save","Vact_puntoventa_update","Vact_puntoventa_data"]),
    close() {
      this.$emit("input", false);
      this.$emit("editar", null);
      this.Vact_puntoventa_data();
      this.data = JSON.parse(JSON.stringify(this.default));
      this.operacion = true;
    },
    pre_guardar() {
      this.guardar();
    },
    deleteuser(index) {
      this.data.puntoventa_usuario.splice(index, 1);
    },
    disableduser(index) {
      if (this.data.puntoventa_usuario[index].activo)
        return (this.data.puntoventa_usuario[index].activo = false);
      return (this.data.puntoventa_usuario[index].activo = true);
    },
    adduser() {
      this.data.puntoventa_usuario.push({
        idpuntoventa: 0,
        idusuario: {
          id: ""
        },
        activo: true
      });
    },
    async obtenerconfig() {
      this.config_load = true;
      this.config = await db.get("/apiconfigimpresion");
      this.config_load = false;
    },
    nuevo(val) {
      this.obtenerconfig();
      if (val === null) return null;
      this.data = val;
      if (this.data.timbrado_puntoventa[0] === undefined) {
        this.data.timbrado_puntoventa.push(this.default.timbrado_puntoventa[0]);
      }
      if (this.data.timbrado_puntoventa[1] === undefined) {
        this.data.timbrado_puntoventa.push(this.default.timbrado_puntoventa[1]);
      }
      if (this.data.timbrado_puntoventa[2] === undefined) {
        this.data.timbrado_puntoventa.push(this.default.timbrado_puntoventa[2]);
      }

      this.operacion = false;
      this.header = "Editar Timbrado";
      this.id = val.idpuntoventa;
    },
    next(id, data) {
      focus.nextidName('puntoventaInput',id, data);
    },
    async guardar_finalizar() {
      let a = await this.Vact_puntoventa_save(this.data);
      if (!a.estado) {
        this.overlay = false;
        a.info.map(x => {
          this.$notify(mensaje.error(x));
        });
        return false;
      }
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$notify(a.info);
      this.$refs.form.resetValidation();
      setTimeout(() => document.getElementById("puntoventaInput1").focus(), 500);
      this.overlay = false;
      return true;
    },
    async editar_finalizar() {
      let a = await this.Vact_puntoventa_update({id: this.id, data: this.data});
      if (!a.estado) {
        this.overlay = false;
        return a.info.map(x => {
          this.$notify(mensaje.error(x));
        });
      }
      this.$notify(a.info);
      this.overlay = false;
    },
    async guardar() {
      if (!this.$refs.form.validate()) return this.$notify(mensaje.completar());
      this.overlay = true;
      if (this.operacion) {
        return await this.guardar_finalizar();
      }
      if (!this.operacion) return this.editar_finalizar();
    }
  }
};
</script>