<template>
  <div>
    <v-dialog :value="value" max-width="800px" persistent @keydown.esc="cancelar()">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>{{'Realizar translado Banco a Caja'}}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form">
            <v-row dense>
              <v-col cols="12">
                <c-text-date
                  dense
                  outlined
                  label="Fecha"
                  id="transBCInput1"
                  v-model="data.fecha"
                  @keyup.native.enter="next(1,data.fecha)"
                />
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <vue-autocomplete-object
                  label="Cuenta Bancaria de Origen"
                  action="cuentabanco/Vact_cuentabanco_data"
                  getter="cuentabanco/Vget_cuentabanco_data"
                  load="cuentabanco/Vget_cuentabanco_load"
                  pk="idcuentabanco"
                  validar="idcuentabanco"
                  permission="Cuenta Banco"
                  title="desccuentabanco"
                  v-model="data.idcuentabanco"
                  id="transBCInput2"
                  :messages="cuentaMessage"
                  @keyup.native.enter="next(2,data.idcuentabanco.idcuentabanco)"
                />
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <vue-autocomplete-generic
                  label="Caja de Destino"
                  action="caja/Vact_caja_data"
                  getter="caja/Vget_caja_data"
                  load="caja/Vget_caja_load"
                  pk="idcaja"
                  permission="Caja"
                  title="descaja"
                  id="transBCInput3"
                  v-model="data.idcaja.idcaja"
                  @keyup.native.enter="next(3,data.idcaja.idcaja)"
                />
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <vue-text-field
                  label="Monto"
                  id="transBCInput4"
                  v-model="data.monto"
                  @keyup.native.enter="next(4,data.monto)"
                />
              </v-col>
              <v-col cols="12" md="3" sm="3">
                <v-checkbox
                  v-model="data.convertir"
                  id="transBCInput5"
                  label="Convertir"
                  class="ma-2"
                  @keyup.enter="next(5,'-')"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="3" sm="3" v-if="data.convertir">
                <v-autocomplete
                  id="transBCInput6"
                  :items="Vget_moneda_data"
                  item-text="descmoneda"
                  item-value="idmoneda"
                  outlined
                  dense
                  autocomplete="off"
                  :rules="[ v => !!v || 'Seleccione una Moneda']"
                  :loading="Vget_moneda_load"
                  v-model="data.idmoneda.idmoneda"
                  label="Moneda"
                  @keyup.native.enter="next(6,data.idmoneda.idmoneda)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3" sm="3" v-if="data.convertir">
                <vue-text-currency
                  id="transBCInput7"
                  label="Cotizacion"
                  v-model="data.cotizacion"
                  :rules="true"
                  @keyup.native="calcularMontoRecibir()"
                  @keyup.native.enter="next(7,data.cotizacion)"
                ></vue-text-currency>
              </v-col>
              <v-col cols="12" md="3" sm="3" v-if="data.convertir">
                <vue-text-currency
                  id="transBCInput8"
                  label="Monto a Recibir"
                  v-model="data.montorecibir"
                  :rules="true"
                  @keyup.native="calcularCotizacion()"
                  @keyup.native.enter="next(8,data.montorecibir)"
                ></vue-text-currency>
              </v-col>
              <v-col cols="12" md="6">
                <vue-text-field
                  id="transBCInput9"
                  :rules="false"
                  label="Numero de Translado"
                  v-model="data.nrotranslado"
                  @keyup.native.enter="next(9,data.nrotranslado)"
                />
              </v-col>
              <v-col cols="12">
                <vue-text-field
                  id="transBCInput10"
                  label="Observacion"
                  v-model="data.observacion"
                  @keyup.native.enter="next(10,data.observacion)"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn color="red" text @click="cancelar">
            <v-icon dark>close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn id="transBCInputaceptar" color="primary" text @click="guardar()">
            <v-icon dark>done</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
      <c-overlay :value="Vget_transladobancocaja_save_load"></c-overlay>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mensaje } from "@/services/notify.service";
import { current_date } from "@/services/util/date.service";
import { focus } from "@/services/focus.service";
export default {
  props: {
    value: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      data: {
        idtransladobancocaja: 0,
        observacion: "",
        monto: "",
        convertir: "",
        montorecibir: "",
        idmoneda: {
          idmoneda: "",
        },
        idcuentabanco: {
          idcuentabanco: "",
        },
        idcaja: {
          idcaja: "",
        },
        nrotranslado: "",
        fecha: current_date(),
      },
      default: {
        idtransladobancocaja: 0,
        observacion: "",
        monto: "",
        convertir: "",
        montorecibir: "",
        idmoneda: {
          idmoneda: "",
        },
        idcuentabanco: {
          idcuentabanco: "",
        },
        idcaja: {
          idcaja: "",
        },
        nrotranslado: "",
        fecha: current_date(),
      },
      operacion: true,
      monedaCuenta: "",
    };
  },
  watch: {
    $route(to, from) {
      if (from.path === "/transladobancocaja") return this.cancelar();
    },
  },
  mounted() {
    setTimeout(() => this.next(0, "-"), 200);
    this.Vact_moneda_data();
  },
  computed: {
    ...mapGetters("transladobancocaja", ["Vget_transladobancocaja_save_load"]),
    ...mapGetters("moneda", ["Vget_moneda_data", "Vget_moneda_load"]),
    cuentaMessage() {
      return this.data.idcuentabanco.monto
        ? "Saldo " +
            this.data.idcuentabanco.idmoneda.descmoneda +
            ": " +
            new Intl.NumberFormat().format(this.data.idcuentabanco.monto)
        : "";
    },
  },
  methods: {
    ...mapActions("transladobancocaja", [
      "Vact_transladobancocaja_data",
      "Vact_transladobancocaja_save",
    ]),
    ...mapActions("cuentabanco",["Vact_cuentabanco_data"]),
    ...mapActions("moneda", ["Vact_moneda_data"]),
    calcularMontoRecibir() {
      if (this.data.cotizacion === "" || this.data.monto === "") return null;
      this.data.montorecibir =
        Number(this.data.cotizacion) * Number(this.data.monto);
    },
    calcularCotizacion() {
      if (this.data.montorecibir === "" || this.data.monto === "") return null;
      this.data.cotizacion =
        Number(this.data.montorecibir) / Number(this.data.monto);
    },
    next(index, data) {
      focus.nextidName("transBCInput", index, data);
    },

    cancelar() {
      this.$emit("input", false);
      this.data = JSON.parse(JSON.stringify(this.default));
      this.Vact_transladobancocaja_data();
      this.operacion = true;
    },
    async guardar_finalizar() {
      let a = await this.Vact_transladobancocaja_save(this.data);
      if (a.estado == true) {
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$notify(a.info);
        this.$refs.form.resetValidation();
        this.Vact_cuentabanco_data();
        setTimeout(()=>this.next(0,'-'),200);
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
    },
    guardar() {
      if (!this.$refs.form.validate()) return this.$notify(mensaje.completar());
      if (this.operacion === true) return this.guardar_finalizar();
    },
  },
};
</script>