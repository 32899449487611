export default {
  tiporesponsable_create(state, request) {
    state.tiporesponsable_create = request;
  }, 
  tiporesponsable_update(state, request) {
    state.tiporesponsable_update = request;
  },   
  tiporesponsable_reload(state, request) {
    state.tiporesponsable_reload = request;
  },
  tiporesponsable_show(state, request) {
    state.tiporesponsable_show = request;
  },   
}