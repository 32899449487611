
export default {
  getList_events: (state) => {
    return state.list_events
  },
  isList_eventsLoading: (state) => {
    return state.isLoading
  },
  getListReporte: (state) => {
    return state.list_reporte
  },
  isList_reporteLoading: (state) => {
    return state.isReporteLoading
  }
}
