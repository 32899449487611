
export default {
  getAguinaldo: (state) => {
    return state.aguinaldo
  },
  getFuncionarioData: (state) => {
    return state.funcionario
  },
  isAguinaldoLoading: (state) => {
    return state.isLoading
  }
}
