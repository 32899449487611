<template>
  <v-card min-height="84vh">
    <v-toolbar flat>
      <v-btn
        text
        small
        fab
        color="primary"
        @click="$router.push('/agricultura/sensor')"
        ><v-icon>arrow_back</v-icon></v-btn
      >
      <v-toolbar-title
        >Sensor -
        <span class="font-weight-bold">{{ data.title }}</span></v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-autocomplete
        :items="getSensor"
        :loading="isLoading"
        item-text="descripcion"
        item-value="idambient_weather"
        class="mt-5"
        label="Sensor"
        v-model="idambient_weather"
        @change="replaceRoute"
      ></v-autocomplete>
    </v-toolbar>
    <v-row dense class="ma-1">
      <v-col cols="12" sm="4">
        <v-card class="my-auto rounded-lg ">
          <v-card-actions class="font-weight-bold caption mb-n4">
            Temperatura
            <v-divider class="mx-2"></v-divider>
            <v-btn fab x-small color="primary"
              ><v-icon>device_thermostat</v-icon></v-btn
            >
          </v-card-actions>
          <v-card-text class="d-flex flex-column align-center">
            <div class="text-h3 font-weight-bold">
              {{ data.temperatura }}
            </div>
            <div class="d-flex flex-row">
              <div class="d-flex flex-column align-center">
                <div class="caption">Punto de Rocio</div>
                <div class="font-weight-bold">{{ data.dowpoint }}</div>
              </div>
              <v-divider class="mx-3" vertical></v-divider>
              <div class="d-flex flex-column align-center">
                <div class="caption">Sensacion</div>
                <div class="font-weight-bold">
                  {{ data.feelsLike }}
                </div>
              </div>
              <v-divider class="mx-3" vertical></v-divider>
              <div class="d-flex flex-column align-center">
                <div class="caption">Humedad</div>
                <div class="font-weight-bold">{{ data.humidity }}</div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4">
        <v-card class="my-auto rounded-lg">
          <v-card-actions class="font-weight-bold caption mb-n4">
            Viento
            <v-divider class="mx-2"></v-divider>
            <v-btn fab x-small color="primary"><v-icon>speed</v-icon></v-btn>
          </v-card-actions>
          <v-card-text class="d-flex flex-column align-center">
            <div class="text-h3 font-weight-bold">
              {{ data.speed }}
            </div>
            <div class="d-flex flex-row">
              <div class="d-flex flex-column align-center">
                <div class="caption">Direccion</div>
                <div class="font-weight-bold">{{ data.from }}</div>
              </div>
              <v-divider class="mx-3" vertical></v-divider>
              <div class="d-flex flex-column align-center">
                <div class="caption">Rafagas de Vientos</div>
                <div class="font-weight-bold">{{ data.gust }}</div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="4">
        <v-card class="my-auto rounded-lg">
          <v-card-actions class="font-weight-bold caption mb-n4">
            Radiacion Solar
            <v-divider class="mx-2"></v-divider>
            <v-btn fab x-small color="primary"
              ><v-icon>brightness_7</v-icon></v-btn
            >
          </v-card-actions>
          <v-card-text class="d-flex flex-column align-center">
            <div class="text-h3 font-weight-bold">
              {{ data.solar }}
            </div>
            <div class="d-flex flex-row">
              <div class="d-flex flex-column align-center">
                <div class="caption">UV Index</div>
                <div class="font-weight-bold">{{ data.uv }}</div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" sm="8" v-if="options">
        <v-card class="rounded-lg">
          <v-card-actions class="caption mb-n8">
            Precipitaciones
            <v-divider class="mx-2"></v-divider>
            <v-btn fab x-small color="primary"
              ><v-icon>filter_drama</v-icon></v-btn
            >
          </v-card-actions>
          <v-chart :options="options" autoresize />
        </v-card>
      </v-col>
      <v-col cols="12" sm="4" v-if="options_pression">
        <v-card class="rounded-lg">
          <v-card-actions class="caption mb-n8">
            Presion Atmosferica
            <v-divider class="mx-2"></v-divider>
            <v-btn fab x-small color="primary"><v-icon>compress</v-icon></v-btn>
          </v-card-actions>
          <v-chart :options="options_pression" autoresize />
        </v-card>
      </v-col>
      <c-overlay :value="isLoadingSocket"></c-overlay>
    </v-row>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ApiService from "@/services/auth/api.service";
import ECharts from "vue-echarts/components/ECharts";
import generarGrafico, { presion } from "./grafico";
export default {
  components: {
    "v-chart": ECharts,
  },
  data: () => ({
    isLoadingSocket: false,
    idambient_weather: null,
    mountedFirst: true,
    options: null,
    options_pression: null,
  }),
  mounted() {
    this.init();
  },
  
  computed: {
    ...mapGetters("auth", ["getAccessToken"]),
    ...mapGetters("sensor", ["getSocketData", "getSensor", "isLoading"]),
    data() {
      if (!this.getSocketData) return {};
      if(this.getSocketData.lastData.idambient_weather !== this.$route.params.id) return this.reset();
      console.log(this.getSocketData)
      const data = {
        title: this.getSocketData?.info?.name || null,
        temperatura:
          this.format(this.getSocketData?.lastData?.tempf) + "°" || null,
        dowpoint:
          this.format(this.getSocketData?.lastData?.dewPoint) + "°" || null,
        feelsLike:
          this.format(this.getSocketData?.lastData?.feelsLike) + "°" || null,
        humidity:
          this.format(this.getSocketData?.lastData?.humidity) + "%" || null,
        speed:
          this.format(this.getSocketData?.lastData?.windspeedmph) + " km/h" ||
          null,
        from: this.getSocketData?.lastData?.winddirt || null,
        gust: this.format(this.getSocketData?.lastData?.windgustmph) || null,

        solar:
          this.format(this.getSocketData?.lastData?.solarradiation) + " lux" ||
          null,
        uv: this.getSocketData?.lastData?.uv || null,
      };
      return data;
    },
    darkMode() {
      return this.$vuetify.theme.dark;
    },
  },
  watch: {
    getSocketData() {
      if (this.mountedFirst) {
        this.generarGrafico();
      }
    },
    $route(to, from) {
      if (to.name === "Datos") this.init();
      if (from.name === "Datos" && to.name != "Datos") this.reset();
    },
  },
  methods: {
    ...mapActions("sensor", ["setSocketData", "fetchSensor"]),
    init() {
      this.reset();
      if (this.getSensor.length === 0) this.fetchSensor();
      this.idambient_weather = this.$route.params.id;
      this.connetWebSocket();
    },
    reset() {
      try {
        this.connection.close();
      } catch (error) {
        console.log(error.message);
      }
      this.setSocketData(null);
      this.options = null;
      this.options_pression = null;
      this.idambient_weather = null;
      this.mountedFirst = true;
    },
    format(value) {
      return value?.toFixed(0);
    },
    replaceRoute() {
      this.$router.replace("/agricultura/sensor/" + this.idambient_weather);
    },
    precipitaciones() {
      const data = [
        {
          title: "Hora",
          item: this.getSocketData?.lastData?.hourlyrainin,
        },
        {
          title: "Dia",
          item: this.getSocketData?.lastData?.dailyrainin,
        },
        {
          title: "Evento",
          item: this.getSocketData?.lastData?.eventrainin,
        },
        {
          title: "Semana",
          item: this.getSocketData?.lastData?.weeklyrainin,
        },
        {
          title: "Mes",
          item: this.getSocketData?.lastData?.monthlyrainin,
        },
        {
          title: "Total",
          item: this.getSocketData?.lastData?.totalrainin,
        },
      ];
      const max = Math.floor(
        Math.max.apply(
          null,
          data.map((x) => x.item)
        )
      );
      const y = data.map((x) => max - x.item);
      let dark = this.darkMode
      return { y, data, dark };
    },
    generarGrafico() {
      if (!this.getSocketData) return null;
      this.mountedFirst = false;
      this.options = JSON.parse(
        JSON.stringify(generarGrafico(this.precipitaciones()))
      );
      this.options_pression = JSON.parse(
        JSON.stringify(presion(this.getSocketData?.lastData?.baromrelin))
      );
    },
    connetWebSocket() {
      this.isLoadingSocket = true;
      const wsRoute =
        "/ws/ambientweather/" +
        this.$route.params.id +
        "/?token=" +
        this.getAccessToken;
      const [protocol, hostWithRoute] = ApiService.getBaseURL().split("//");
      const [wsHost] = hostWithRoute.split("/");
      const wsProtocol = protocol === "https:" ? "wss://" : "ws://";
      this.connection = new WebSocket(wsProtocol + wsHost + wsRoute);
      const self = this;

      this.connection.onmessage = (event) =>
        self.setSocketData(JSON.parse(event.data));
      this.connection.onopen = () => {
        self.isLoadingSocket = false;
        self.setSocketData(null);
        console.info("Successfully connected websocket");
      };
      this.connection.onerror = () => {
        self.setSocketData(null);
        self.isLoadingSocket = false;
        console.error("Failed connected websocket");
      };
    },
  },
};
</script>
<style scoped>
.chart-wrapper {
  width: 100%;
  height: 50vh;
}
.echarts {
  width: 100%;
  height: 50vh;
}
</style>
