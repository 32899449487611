<template>
  <div>
    <div v-if="abrirajuste">
      <Stock-Ajuste-create v-model="abrirajuste" />
    </div>
    <div v-if="show">
      <Stock-Ajuste-show v-model="show" />
    </div>
    <v-card>
      <v-alert type="error" v-if="permission_alert"
        >Error!!! Este usuario no tiene permisos en esta ventana</v-alert
      >
      <v-toolbar flat dense>
        <v-toolbar-title>{{ titulo }}</v-toolbar-title>
        <widgets-Favorite ruta="/ajuste" :formulario="titulo" />
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <c-text-table-search
        v-if="permission.can_view"
        v-model="search"
        class="mr-2"
      ></c-text-table-search>
        <v-toolbar-items>
        <c-btn-table-add
          @click="abrirajuste = true"
          v-if="permission.can_add"
        ></c-btn-table-add>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table
        :loading="Vget_ajuste_load"
        :headers="headers"
        :items="Vget_ajuste_data"
        :search="search"
        class="elevation-1"
        v-if="permission.can_view"
      >
        <template v-slot:[`item.accion`]="props">
          <v-icon class="mr-2" @click="rowselect(props.item)"
            >visibility</v-icon
          >
        </template>
        <template v-slot:no-data>
          <v-alert :value="true" color="info" icon="info" outlined
            >No existe ningun registro</v-alert
          >
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { AJUSTE_STOCK } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";

export default {
  data() {
    return {
      search: "",
      abrirajuste: false,
      show: false,
      headers: [
        { text: "Fecha", align: "left", value: "fechajustestock" },
        { text: "Item", align: "left", value: "iditem.descitem" },
        {
          text: "Tipo Ajuste",
          align: "left",
          value: "idtipoajuste.desctipoajuste",
        },
        {
          text: "Motivo Ajuste",
          align: "left",
          value: "idmotivoajuste.descmotivoajuste",
        },
        { text: "Deposito", align: "left", value: "iddeposito.desdeposito" },
        { text: "Cantidad", align: "left", value: "cantajustestock" },
        { text: "Accion", value: "accion", sortable: false, align: "right" },
      ],
      datos: [],
      titulo: AJUSTE_STOCK,
      objeto: { iditem: { descitem: "" } },
      index: "",
      dialogoeliminar: false,
      load: false,
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
    };
  },

  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.Vact_ajuste_data();
    const permission = getPermission(AJUSTE_STOCK).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  computed: {
    ...mapGetters("ajuste", ["Vget_ajuste_data", "Vget_ajuste_load"]),
    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },

  methods: {
    ...mapActions("ajuste", ["ajuste_update", "Vact_ajuste_data"]),

    rowselect(val) {
      this.ajuste_update(val);
      this.show = true;
    },
  },
};
</script>