export default {

  cuenta_update(state, request) {
    state.cuenta_update = request;
  },
  cuenta_show(state, request) {
    state.cuenta_show = request;
  }, 

  cuenta_data(state, request) {
    state.cuenta_data = request;
  }, 
  cuenta_load(state, request) {
    state.cuenta_load = request;
  }, 
  cuenta_save_load(state, request) {
    state.cuenta_save_load = request;
  }, 
}