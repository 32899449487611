<template>
  <div>
    <div v-if="abrircotizacion">
      <Finanzas-Cotizacion-CreateV2 v-model="abrircotizacion" />
    </div>
    <v-dialog
      :value="value"
      max-width="700px"
      persistent
      @keydown.esc="cancelar()"
    >
      <v-card>
        <v-form ref="form" lazy-validation>
          <v-toolbar flat dense>
            <v-toolbar-title
              >{{
                isEdit
                  ? "Modificar costo de Nacimiento"
                  : "Registrar costo de Nacimiento"
              }}
            </v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-row row dense>
              <v-col cols="12">
                <c-text-currency
                  ref="input1"
                  v-model="data.montcostonacimiento"
                  label="Costo de Nacimiento"
                  :rules="[(v) => !!v || 'Obligatorio']"
                  outlined
                  dense
                  @keyup.native.enter="nextfocus(1)"
                ></c-text-currency>
              </v-col>
              <v-col cols="12">
                <vue-autocomplete-object
                  label="Seleccione Cotizacion"
                  ref="input2"
                  action="cotizacion/Vact_cotizacion_data"
                  getter="cotizacion/Vget_cotizacion_data"
                  load="cotizacion/Vget_cotizacion_load"
                  pk="idcotizacion"
                  title="fecha"
                  validar="idcotizacion"
                  @create="abrircotizacion = $event"
                  permission="Cotizacion"
                  :create="true"
                  v-model="data.idcotizacion"
                  @keyup.native.enter="nextfocus(2)"
                />
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  dense
                  v-model="data.idmoneda"
                  ref="input3"
                  :rules="validars"
                  v-bind:items="data.idcotizacion.cotizaciondet"
                  fkdes="item"
                  label="Seleccione Moneda"
                  item-text="idmoneda.descmoneda"
                  item-value="idmoneda.idmoneda"
                  required
                  outlined
                  height="12"
                  autocomplete="off"
                  @keyup.enter="nextfocus(3)"
                  :no-data-text="`No existe ninguna concidencia`"
                  small-chips
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions>
            <c-btn-crud-close @click="cancelar()"></c-btn-crud-close>
            <v-spacer></v-spacer>
            <c-btn-crud-done @click="guardar()" ref="aceptar"></c-btn-crud-done>
          </v-card-actions>
        </v-form>
        <c-overlay :value="isLoading"></c-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { COTIZACION } from "@/constants/forms";
import { PUEDE_AGREGAR } from "@/constants/permissions";
export default {
  props: {
    value: Boolean,
    props: Object,
    "sync-input": {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      noData: true,
      isEdit: false,
      data: {
        montcostonacimiento: "",
        idmoneda: "",
        idcotizacion: {
          idcotizacion: "",
          idmoneda: {
            idmoneda: "",
          },
        },
      },
      default: {
        montcostonacimiento: "",
        idmoneda: "",
        idcotizacion: {
          idcotizacion: "",
          idmoneda: {
            idmoneda: "",
          },
        },
      },
      validars: [(v) => !!v || "Seleccione un campo"],
      loadmoneda: false,
      moneda: [],
      abrircotizacion: false,
    };
  },
  watch: {
    $route() {
      return this.cancelar();
    },
  },
  computed: {
    ...mapGetters("costonacimiento", ["isLoading"]),
    cotizacion_permission() {
      return getPermission(COTIZACION).permiso[PUEDE_AGREGAR];
    },
  },
  created() {
    this.nuevo(this.props);
  },
  mounted() {
    setTimeout(() => this.$refs.input1.$el.querySelector("input").focus(), 500);
    if (this.syncInput != null) {
      this.data.montcostonacimiento = Number(this.syncInput);
      this.sync = this.syncInput;
    }
  },
  methods: {
    ...mapActions("costonacimiento", [
      "setCosto",
      "setCostoUpdate",
      "fetchCosto",
    ]),

    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$emit("input", false);
      this.$emit("reset", null);
      this.isEdit = false;
    },

    async guardar() {
      if (!this.$refs.form.validate()) return null;
      const response = this.isEdit
        ? await this.setCostoUpdate({
            id: this.props.idcostonacimiento,
            data: this.data,
          })
        : await this.setCosto(this.data);
      if (response.success) {
        if (this.sync != null) {
          this.$emit("sync-input", response.data.data);
          return this.cancelar();
        }
        if (this.isEdit) return this.cancelar();
        this.fetchCosto();
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();
        this.$refs.input1.$el.querySelector("input").focus();
      }
    },
    nextfocus(x) {
      switch (x) {
        case 1: {
          if (this.data.montcostonacimiento != "") {
            this.$refs.input2.focus();
          }
          break;
        }
        case 2: {
          if (this.data.idcotizacion.idcotizacion != "") {
            this.$refs.input3.focus();
          }
          break;
        }
        case 3: {
          if (this.data.idmoneda != "") {
            this.$refs.aceptar.$el.focus();
          }
          break;
        }
      }
    },

    nuevo(val) {
      if (!val) return null;
      this.isEdit = true;
      this.data = JSON.parse(JSON.stringify(val));
      this.data.idmoneda = val.idmoneda.idmoneda;
    },
  },
};
</script>
