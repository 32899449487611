export default {
  ajustecaja_data(state, request) {
    state.ajustecaja_data = request;
  }, 
  ajustecaja_load(state, request) {
    state.ajustecaja_load = request;
  }, 
  ajustecaja_save_load(state, request) {
    state.ajustecaja_save_load = request;
  }, 
}