<template>
  <div>
    <VeterinarioForm
      v-model="formularios.veterinario"
      v-if="formularios.veterinario"
    />
    <v-form ref="form" lazy-validation>
      <v-row dense>
        <v-switch
          :disabled="readonly"
          v-model="data.formains"
          @change="
            (data.idanimaltoro.idanimal = null),
              (data.idtermosemen.idtermosemen = null)
          "
          :label="`Tipo de inseminacion?: ${tipo}`"
        ></v-switch>
        <v-spacer></v-spacer>
        <v-col cols="12" sm="7" v-if="modo">
          <AutocompleteAnimal
            :key="historico"
            :action="historico ? 'fetchTodos' : 'fetchAnimalCelo'"
            :getter="historico ? 'getTodos' : 'getCelo'"
            no-create
            :readonly="readonly"
            :animal="animal"
            v-model="data.idanimal.idanimal"
            id="input1"
            @keyup.native.enter="nextfocus(1, data.idanimal.idanimal)"
          />
        </v-col>
        <v-col cols="12" v-if="!data.formains">
          <AutocompleteAnimal
            action="fetchAnimalToro"
            getter="getToro"
            label="Seleccione el Toro"
            :readonly="readonly"
            no-create
            v-model="data.idanimaltoro.idanimal"
            id="input2"
            @nextfocus="nextfocus(2, '-')"
            @keyup.native.enter="nextfocus(2, data.idanimaltoro.idanimal)"
          />
        </v-col>
        <v-col cols="12" sm="4" v-if="data.formains">
          <AutocompleteTermo
            :readonly="readonly"
            v-model="data.idtermosemen"
            id="input3"
            return-object
            @keyup.native.enter="nextfocus(3, data.idtermosemen.idtermosemen)"
          />
        </v-col>
        <v-col cols="12" sm="4" v-if="data.formains">
          <AutocompleteAnimal
            :getter="null"
            :action="null"
            :items="toroTermoSemen"
            label="Seleccione el Toro"
            no-create
            :readonly="readonly"
            v-model="data.idanimaltoro.idanimal"
            id="input4"
            @change="data.idsexo.idsexo = null"
            @nextfocus="nextfocus(4, '-')"
            @keyup.native.enter="nextfocus(4, data.idanimaltoro.idanimal)"
          />
        </v-col>
        <v-col cols="12" sm="4" v-if="data.formains && itemSexo.length > 1">
          <v-autocomplete
            :items="itemSexo"
            clearable
            item-value="idsexo"
            item-text="descsexocria"
            label="Sexo"
            id="input5"
            outlined
            :readonly="readonly"
            dense
            v-model="data.idsexo.idsexo"
             @keyup.enter="nextfocus(5, data.idsexo.idsexo)"
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="4" v-if="data.formains" >
          <c-text-currency
            :readonly="readonly"
            outlined
            dense
            label="Cantidad"
            :rules="[(v) => !!v || 'Obligatorio']"
            v-model="data.cantidad"
          ></c-text-currency>
        </v-col>
        <v-col cols="12" v-if="data.formains">
          <vue-autocomplete-generic
            id="input5"
            action="veterinario/Vact_veterinario_data"
            getter="veterinario/Vget_veterinario_data"
            load="veterinario/Vget_veterinario_load"
            :readonly="readonly"
            pk="idveterinario"
            label="Seleccione Veterinario"
            title="descveterinario"
            :permission="permission.veterinario"
            :create="true"
            @create="formularios.veterinario = $event"
            v-model="data.idveterinario.idveterinario"
            @keyup.native.enter="nextfocus(5, data.idveterinario.idveterinario)"
          />
        </v-col>
        <v-col cols="12">
          <c-text-date
            label="Fecha"
            :readonly="readonly"
            outlined
            dense
            id="input6"
            v-model="data.feccinseminacion"
            @keyup.native.enter="nextfocus(6, data.feccinseminacion)"
          />
        </v-col>
        <v-col cols="12">
          <c-text-field
            label="Comentario"
            :readonly="readonly"
            id="input7"
            :rules="[]"
            outlined
            dense
            v-model="data.comninseminacion"
            @keyup.enter="nextfocus(7, '0')"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { focus } from "@/services/focus.service";
import { current_date } from "@/services/util/date.service";
import VeterinarioForm from "@/views/Analisis/Veterinario/Create";
import { VETERINARIO } from "@/constants/forms";
import AutocompleteAnimal from "@/views/Animal/Animal/AutocompleteAnimal";
import AutocompleteTermo from "@/views/Animal/TermoSemen/Autocomplete";
export default {
  components: {
    VeterinarioForm,
    AutocompleteAnimal,
    AutocompleteTermo,
  },
  props: {
    historico: {
      type: Boolean,
      default: false,
    },
    xid: {
      type: Number,
      default: null,
    },
    datos: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    modo: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      permission: {
        veterinario: VETERINARIO,
      },
      data: {
        formains: true,
        fechinseminacion: "2019-05-05",
        feccinseminacion: current_date(),
        comninseminacion: "",
        cantidad: 1,
        idtermosemen: {
          idtermosemen: "",
          termosemendet: [],
        },
        idsexo:{
          idsexo: null
        },
        idanimal: {
          idanimal: "",
        },
        idanimaltoro: {
          idanimal: "",
        },
        idveterinario: {
          idveterinario: "",
        },
      },
      default: {
        formains: true,
        fechinseminacion: "2019-05-05",
        feccinseminacion: current_date(),
        comninseminacion: "",
        cantidad: 1,
        idtermosemen: {
          idtermosemen: "",
          termosemendet: [],
        },
        idanimal: {
          idanimal: "",
        },
        idsexo:{
          idsexo: null
        },
        idanimaltoro: {
          idanimal: "",
        },
        idveterinario: {
          idveterinario: "",
        },
      },
      animal: null,
      formularios: {
        veterinario: false,
      },
    };
  },
  watch: {
    datos(val) {
      if (val === null) {
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();
      }
    },
  },
  computed: {
    tipo() {
      if (this.data.formains) return "Artificial";
      return "Monta Natural";
    },
    itemSexo() {
      const animal = this.toroTermoSemen.filter(
        (x) => x.idanimal.idanimal === this.data.idanimaltoro.idanimal
      );
      const sexo = animal.filter((x) => x.idsexo).map(x=>x.idsexo);
      return sexo;
    },
    toroTermoSemen() {
      if (this.data.idtermosemen) return this.data.idtermosemen.termosemendet;
      return [];
    },
  },
  created() {
    if (this.xid != null) {
      this.data.idanimal.idanimal = this.xid;
    }
    this.nuevo(this.datos);
  },
  mounted() {
    if (this.modo)
      return setTimeout(() => document.getElementById("input1").focus(), 500);
    return setTimeout(() => document.getElementById("input2").focus(), 500);
  },
  methods: {
    nextfocus(index, data) {
      focus.nextid(index, data);
    },
    validate() {
      return this.$refs.form.validate();
    },
    obtenerDatos() {
      if (this.data.comninseminacion === "") {
        this.data.comninseminacion = null;
      }
      if (!this.data.formains) {
        this.data.idveterinario.idveterinario = null;
      }
      if(this.itemSexo.length === 1) this.data.idsexo.idsexo = this.itemSexo[0].idsexo;
      if(this.itemSexo.length === 0) this.data.idsexo.idsexo = null;
      return this.data;
    },

    nuevo(val) {
      if (val === null) return null;
      if (val.idveterinario === null) val.idveterinario = { idveterinario: "" };
      this.animal = val.idanimal;
      if (!val.idtermosemen)
        val.idtermosemen = {
          idtermosemen: "",
          termosemendet: [],
        };
      if(!val.idsexo) val.idsexo = {idsexo: null};
      this.data = JSON.parse(JSON.stringify(val));
      this.operacion = false;
    },
  },
};
</script>
