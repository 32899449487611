<template>
  <div>
    <div v-if="dialogCotizacion">
      <Finanzas-Cotizacion-CreateV2 v-model="dialogCotizacion" />
    </div>
    <div v-if="dialogSiembra">
      <agro-siembra-dialog
        v-model="dialogSiembra"
        :idcampana="idcampana"
        :idsiembra="idsiembra"
        :propIdlote="$route.params.id"
        @resp="idsiembra = $event"
        :readonly="idcampana.estado"
      />
    </div>

    <div v-if="dialogCosecha">
      <agro-cosecha-create
        v-model="dialogCosecha"
        :idcampana="idcampana"
        :readonly="idcampana.estado"
      />
    </div>
    <div v-if="dialogCosechaShow">
      <agro-cosecha-update
        v-model="dialogCosechaShow"
        :idcosecha="idcosecha"
        :readonly="idcampana.estado"
      />
    </div>
    <v-toolbar flat class="">
      <v-col cols="6" class="ml-n4 mt-7">
        <v-select
          :items="Vget_campana_data_byLote"
          dense
          append-icon
          :loading="Vget_campana_load"
          label="Campaña"
          return-object
          item-text="descripcion"
          @change="obtenerTimeLine()"
          v-model="idcampana"
          outlined
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title
                  >No tiene campañas en este Lote</v-list-item-title
                >
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-select>
      </v-col>
      <v-col
        cols="2"
        v-if="idcampana != '' || (idcampana != undefined) & reportPermission"
        class="ml-n3"
      >
        <v-tooltip bottom color="info">
          <template v-slot:activator="{ on }">
            <Agro-Reporte-LoteCampana
              :idcampana="idcampana.idcampana"
              :idlote="$route.params.id"
              :permiso="permission"
              v-on="on"
            />
          </template>
          <v-icon small color="red" class="mr-2">help_outline</v-icon>
          <span class="text-center">Click para descargar los datos</span>
        </v-tooltip>
      </v-col>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <v-col cols="2">
        <v-tooltip bottom color="info" v-if="permission.can_add">
          <template v-slot:activator="{ on }">
            <v-btn
              height="39"
              small
              text
              color="primary"
              v-on="on"
              @click="crudCampana('add')"
            >
              <v-icon>add_box</v-icon>
            </v-btn>
          </template>
          <v-icon small color="red" class="mr-2">help_outline</v-icon>
          <span class="text-center">
            Click para crear una nueva
            <br />campaña o unirse a una existente.
          </span>
        </v-tooltip>
      </v-col>
      <v-col cols="2" v-if="idcampana != ''">
        <v-tooltip bottom color="info" v-if="permission.can_update">
          <template v-slot:activator="{ on }">
            <v-btn
              height="39"
              small
              text
              color="primary"
              v-on="on"
              @click="crudCampana('edit')"
              :disabled="!idcampana.estado"
            >
              <v-icon>edit</v-icon>
            </v-btn>
          </template>
          <v-icon small color="red" class="mr-2">help_outline</v-icon>
          <span class="text-center">Click para editar esta campaña</span>
        </v-tooltip>
      </v-col>
    </v-toolbar>
    <v-divider></v-divider>
    <v-progress-linear
      indeterminate
      height="2"
      v-if="Vget_siembra_load"
      color="primary"
    ></v-progress-linear>
    <v-card-text class="py-0 overflow-y-auto" style="max-height: 200px">
      <c-overlay absolute :value="Vget_siembra_load"></c-overlay>
      <v-alert
        dense
        outlined
        type="info"
        v-if="
          Vget_siembra_timeline === null ||
          Vget_siembra_timeline.length === 0 ||
          !permission.can_view
        "
        >No existe ninguna aplicacion en esta Campaña</v-alert
      >
      <v-timeline dense v-else>
        <v-timeline-item
          color="pink"
          v-for="(item, i) in Vget_siembra_timeline"
          :key="i"
          small
          dense
        >
          <v-card class="elevation-2">
            <v-list dense>
              <v-list-item three-line @click="vetTimeline(item)">
                <v-list-item-content>
                  <v-list-item-subtitle>
                    <strong class="caption">{{ item.fecha }}</strong>
                    {{ item.operacion }}
                  </v-list-item-subtitle>

                  <v-list-item-subtitle>
                    <div class="subtitle-2">{{ item.comentario }}</div>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-menu
        top
        :offset-y="true"
        v-if="idcampana.estado && permission.can_add"
      >
        <template v-slot:activator="{ on }">
          <v-btn color="primary" outlined v-on="on">Registrar</v-btn>
        </template>

        <v-list dense>
          <v-list-item @click="crearSiembra()">
            <v-list-item-title>Siembra/Aplicacion</v-list-item-title>
          </v-list-item>
          <v-list-item @click="crearCosecha()">
            <v-list-item-title>Cosecha</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-actions>
    <v-overlay :z-index="3" :value="dialogCampana">
      <v-dialog v-model="dialogCampana" max-width="650" persistent hide-overlay>
        <v-card>
          <v-card-title class="subtitle-2">
            {{ newCampana ? "Registrar" : "Editar" }} Campaña
            <v-spacer></v-spacer>
            <v-menu offset-y v-if="campanaDisponible.length > 0 && newCampana">
              <template v-slot:activator="{ on }">
                <v-chip color="warning" outlined v-on="on">
                  <v-icon left>notification_important</v-icon>Pulse aqui para
                  unirse a una campaña existente
                </v-chip>
              </template>
              <v-list class="overflow-y-auto" max-height="400">
                <v-list-item
                  v-for="(item, index) in campanaDisponible"
                  :key="index"
                  @click="unirCampana(index)"
                >
                  <v-list-item-title>{{ item.descripcion }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-title>
          <v-divider></v-divider>
          <v-container>
            <v-form ref="form">
              <v-row dense>
                <v-col cols="12" md="4">
                  <c-text-date
                    outlined
                    dense
                    label="Fecha Inicio"
                    v-model="campana.fechacampana"
                  ></c-text-date>
                </v-col>
                <v-col cols="12" md="8">
                  <vue-text-field
                    :label="newCampana ? 'Registrar' : 'Editar' + ' Campaña'"
                    v-model="campana.descripcion"
                  ></vue-text-field>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    dense
                    v-model="campana.iditem"
                    id="campanaInput3"
                    v-bind:items="Vget_item_siembra_default"
                    label="Tipo de Cultivo"
                    item-text="descitem"
                    return-object
                    :rules="[(v) => !!v.iditem || 'Seleccione un Item']"
                    :loading="Vget_item_load"
                    required
                    outlined
                    autocomplete="off"
                    :no-data-text="`No existe ninguna concidencia`"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="9" md="9">
                  <v-autocomplete
                    :items="lotes"
                    :loading="Vget_campo_load"
                    dense
                    label="Lotes a agregar en la campaña"
                    multiple
                    return-object
                    item-value="idlote_agri.idlote_agri"
                    item-text="idlote_agri.campo"
                    v-model="campana.campanadet"
                    outlined
                  ></v-autocomplete>
                </v-col>

                <v-spacer></v-spacer>
                <v-col cols="12" sm="2" md="2">
                  <v-btn
                    text
                    color="red"
                    block
                    outlined
                    @click="agregarLotes"
                    >{{ allLotes ? "Todos " : "Vaciar" }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="red" text @click="dialogCampana = false">
              <v-icon>clear</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="guardarCampana()">
              <v-icon>done</v-icon>
            </v-btn>
          </v-card-actions>
          <c-overlay :value="Vget_campana_save_load"></c-overlay>
        </v-card>
      </v-dialog>
    </v-overlay>
    <v-dialog
      v-if="dialogUnirCampana"
      v-model="dialogUnirCampana"
      max-width="420"
      persistent
    >
      <v-card>
        <v-card-title>
          <v-icon left>info</v-icon>
          <span class="title font-weight-light">Ventana de Confirmacion</span>
        </v-card-title>

        <v-card-text class="subtitle-1 font-weight-regular">
          Desea unir este Lote a la Campaña
          <strong class="font-italic font-weight-bold">{{
            campanaDisponible[indexCampana].descripcion
          }}</strong>
          ?
        </v-card-text>

        <v-card-actions>
          <v-btn color="red" text @click="dialogUnirCampana = false">
            <v-icon>clear</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="guardarUnirCampana()">
            <v-icon>done</v-icon>
          </v-btn>
        </v-card-actions>
        <c-overlay :value="Vget_campana_save_load"></c-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { current_date } from "@/services/util/date.service";
import { mensaje } from "@/services/notify.service";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { ZAFRA } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
  PUEDE_GENERAR_REPORTE,
  PUEDE_VER_NUMEROS,
} from "@/constants/permissions";

export default {
  data() {
    return {
      idcampana: "",
      indexCampana: null,
      newCampana: true,
      idsiembra: null,
      idcosecha: null,
      dialogCampana: false,
      dialogUnirCampana: false,
      dialogSiembra: false,
      dialogCosecha: false,
      dialogCosechaShow: false,
      dialogCotizacion: false,

      allLotes: true,
      lotes: [],
      campana: {
        descripcion: "",
        iditem: "",
        idcampana: 0,
        fechacampana: current_date(),
        estado: true,
        campanadet: [],
      },
      campana_default: {
        descripcion: "",
        iditem: "",
        idcampana: 0,
        fechacampana: current_date(),
        estado: true,
        campanadet: [],
      },
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
        can_view_numbers: false,
      },
    };
  },
  watch: {
    //Evento cuando se da click sobre otro poligono
    Vget_campana_data_byLote(val) {
      if (val[0] === undefined) {
        this.Vact_siembra_timeline({ campana: null });
        this.idcampana = "";
        return null;
      }
      this.idcampana = val[0];
      this.obtenerTimeLine();
    },
    Vget_campo_data(val) {
      this.obtenerLotes(val);
    },
  },
  created() {
    const permission = getPermission(ZAFRA).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
      can_view_numbers: permission[PUEDE_VER_NUMEROS],
    };
  },
  mounted() {
    this.obtenerLotes(this.Vget_campo_data);
    this.Vact_item_siembra_default();
  },
  computed: {
    ...mapGetters("siembra", ["Vget_siembra_timeline", "Vget_siembra_load"]),
    ...mapGetters("campana", [
      "Vget_campana_save_load",
      "getCampanaDisponible",
      "Vget_campana_load",
      "Vget_campana_data_byLote",
    ]),
    ...mapGetters("campo", ["Vget_campo_data", "Vget_campo_load"]),
    ...mapGetters("item", ["Vget_item_load", "Vget_item_siembra_default"]),
    reportPermission() {
      return getPermission(ZAFRA).permiso[PUEDE_GENERAR_REPORTE];
    },
    campanaDisponible() {
      return this.getCampanaDisponible.filter((x) => {
        const exist = this.Vget_campana_data_byLote.filter(
          (y) => x.idcampana === y.idcampana
        );
        if (exist.length === 0) return x;
      });
    },
  },
  methods: {
    ...mapActions("campana", [
      "Vact_campana_save",
      "Vact_campana_data_byLote",
      "Vact_campana_data",
      "Vact_campana_update",
    ]),
    ...mapActions("item", ["Vact_item_siembra_default"]),
    ...mapActions("cotizacion", ["Vact_cotizacion_data_day"]),
    ...mapActions("siembra", ["Vact_siembra_timeline"]),
    ...mapActions("campo", ["Vact_campo_data"]),
    agregarLotes() {
      this.campana.campanadet = [];
      if (this.allLotes) {
        this.campana.campanadet = JSON.parse(JSON.stringify(this.lotes));
      }
      this.allLotes = !this.allLotes;
    },
    obtenerTimeLine() {
      const campana = this.idcampana;
      const idlote_agri = this.$route.params.id;
      this.Vact_siembra_timeline({ campana, idlote_agri });
    },
    obtenerLotes(val) {
      this.lotes = [];
      this.campana.campanadet = [];
      val.forEach((x) => {
        const lote = { idcampana: 0, idlote_agri: x };
        this.lotes.push(lote);
        if (this.$route.params.id === x.idlote_agri)
          this.campana.campanadet.push(lote);
      });
    },
    crudCampana(tipo) {
      if (tipo === "add") {
        this.dialogCampana = true;
        this.newCampana = true;
        this.campana = JSON.parse(JSON.stringify(this.campana_default));
      }
      if (tipo === "edit") {
        this.dialogCampana = true;
        this.newCampana = false;
        this.campana = JSON.parse(JSON.stringify(this.idcampana));
      }
    },
    unirCampana(index) {
      this.dialogUnirCampana = true;
      this.indexCampana = index;
    },
    async guardarUnirCampana() {
      let existLote = false;
      this.campana = this.campanaDisponible[this.indexCampana];
      this.campana.campanadet.forEach((x) => {
        if (x.idlote_agri.idlote_agri === this.$route.params.id) {
          existLote = true;
        }
      });
      if (!existLote) {
        this.campana.campanadet.push({
          idcampana: 0,
          idlote_agri: { idlote_agri: this.$route.params.id },
        });
      }
      let a = await this.Vact_campana_update({
        id: this.campanaDisponible[this.indexCampana].idcampana,
        data: this.campana,
      });
      if (!a.estado) {
        return a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.$notify(a.info);
      this.dialogUnirCampana = false;
      this.dialogCampana = false;
      this.Vact_campana_data_byLote(this.$route.params.id);
    },
    crearCosecha() {
      this.dialogCosecha = true;
    },
    async crearSiembra() {
      const e = await this.Vact_cotizacion_data_day();
      if (e) return (this.dialogSiembra = true);
      if (!e) return (this.dialogCotizacion = true);
    },
    async vetTimeline(val) {
      switch (val.operacion) {
        case "Cosecha":
          this.dialogCosechaShow = true;
          this.idcosecha = val.id;
          break;
        default:
          this.dialogSiembra = true;
          this.idsiembra = val.id;
          break;
      }
    },
    async guardarCampana() {
      if (!this.$refs.form.validate()) return null;
      let a;
      if (this.newCampana) a = await this.Vact_campana_save(this.campana);
      if (!this.newCampana)
        a = await this.Vact_campana_update({
          id: this.idcampana.idcampana,
          data: this.campana,
        });
      this.Vact_campana_data();
      if (!a.estado) {
        return a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.$notify(a.info);
      this.campana = JSON.parse(JSON.stringify(this.campana_default));
      this.Vact_campana_data_byLote(this.$route.params.id);
      this.dialogCampana = false;
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style></style>
