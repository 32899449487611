
export default {

  tipoanalisis_create: (state) => {
    return state.tipoanalisis_create
  },
  tipoanalisis_update: (state) => {
    return state.tipoanalisis_update
  },
  tipoanalisis_reload: (state) => {
    return state.tipoanalisis_reload
  },
  tipoanalisis_show: (state) => {
    return state.tipoanalisis_show
  },
  tipoanalisis_key: (state) => {
    return state.tipoanalisis_key
  },
}
