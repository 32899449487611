<template>
  <v-overlay :z-index="4" :value="value">
    <v-dialog
      :value="value"
      max-width="400"
      persistent
      :fullscreen="mobile"
      @keydown.esc="cerrar()"
    >
      <v-card>
        <v-toolbar color="primary" dark>Archivo para SENAVE</v-toolbar>
        <v-card-text>
          <v-row align="center">
            <v-col cols="12" align="center">
              <v-btn color="success" @click="$emit(`export-senave`);">
                <v-icon right dark> table </v-icon>
                Descargar en formato excel
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="cerrar()">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-overlay>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    datos: {
      type: Object,
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  methods: {
    cerrar() {
      this.$emit("cerrar-senave");
    },
  },
};
</script>
