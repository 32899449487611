<template>
  <div>
    <v-dialog :value="value" max-width="700" persistent>
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Detalles del Punto de Venta</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12" md="12">
              <c-text-field dense readonly outlined label="Descripcion" v-model="data.descpuntoventa" />
            </v-col>
            <v-col cols="12" md="6">
              <c-text-field dense readonly outlined
                label="Timbrado para factura"
                v-model="data.timbrado_puntoventa[0].idtimbrado.timbrado"
              />
            </v-col>
            <v-col cols="12" md="6">
              <c-text-field dense readonly outlined
                label="Configuracion Factura"
                v-model="data.timbrado_puntoventa[0].idconfig_impresion.descreportes"
              />
            </v-col>
            <v-col cols="12" md="6">
              <c-text-field dense readonly outlined
                label="Timbrado para Nota de Credito"
                v-model="data.timbrado_puntoventa[1].idtimbrado.timbrado"
              />
            </v-col>
            <v-col cols="12" md="6">
              <c-text-field dense readonly outlined
                label="Configuracion Nota de Credito"
                v-model="data.timbrado_puntoventa[1].idconfig_impresion.descreportes"
              />
            </v-col>
            <v-col cols="12" md="6">
              <c-text-field dense readonly outlined
                label="Timbrado para Nota de Remision"
                v-model="data.timbrado_puntoventa[2].idtimbrado.timbrado"
              />
            </v-col>
            <v-col cols="12" md="6">
              <c-text-field dense readonly outlined
                label="Configuracion Nota de Remision"
                v-model="data.timbrado_puntoventa[2].idconfig_impresion.descreportes"
              />
            </v-col>
            <v-col cols="12" md="12">
              <c-text-field dense readonly outlined label="Caja del Punto de venta" v-model="data.idcaja.descaja" />
            </v-col>
            <v-col cols="12" md="6">
              <c-text-field dense readonly outlined
                label="Metodo de impresion"
                v-model="data.idimpresion_forma.descripcion"
              />
            </v-col>
            <v-col cols="12" md="6">
              <c-text-field dense readonly outlined label="Cantidad de Reimpresiones" v-model="data.cantidad" />
            </v-col>
            <v-col cols="12" md="6">
              <c-text-field dense readonly outlined label="Limite de Items en Factura" v-model="data.filasfactura" />
            </v-col>
            <v-col cols="12" md="6">
              <v-switch
                :label="`Control de caja por apertura y cierre?: ${tipo}`"
                v-model="data.apertura"
                readonly
              ></v-switch>
            </v-col>
          </v-row>
          <v-row dense no-gutters cols="12" v-for="(item, i) in data.puntoventa_usuario" :key="i">
            <v-col cols="12" sm="12" md="12">
              <c-text-field dense readonly outlined
                :label="`Usuario ${data.puntoventa_usuario[i].activo ? 'Habilidado' : 'Deshabilitado'}`"
                v-model="data.puntoventa_usuario[i].idusuario.username"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="red" text @click="cancelar" v-if="xid === null"><v-icon>clear</v-icon></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { booleanConverter } from "@/services/util/boolean.service";
export default {
  props: {
    xid: {
      type: Number,
      default: null
    },
    value: {
      type: Boolean,
      default: false
    },
    mostrar: {
      type: [Object, Array],
      default: null
    }
  },
  watch: {
    $route(to, from) {
      if (from.path === "/puntoventa") return this.cancelar();
    }
  },
  data() {
    return {
      header: "Detalle",
      dateFormatted: "",
      dateFormatted_vence: "",
      data: {
        idpuntoventa: 0,
        descpuntoventa: "",
        idcaja: {
          idcaja: ""
        },
        idcaja_a_depositar: {
          idcaja: ""
        },
        idcuentabanco_a_depositar: {
          idcuentabanco: ""
        },
        idimpresion_forma: {
          idimpresion_forma: ""
        },
        timbrado_puntoventa: [
          {
            idpuntoventa: 0,
            idtimbrado: { timbrado: "" },
            idconfig_impresion: { descreportes: "" }
          },
          {
            idpuntoventa: 0,
            idtimbrado: { timbrado: "" },
            idconfig_impresion: { descreportes: "" }
          },
          {
            idpuntoventa: 0,
            idtimbrado: { timbrado: "" },
            idconfig_impresion: { descreportes: "" }
          }
        ],
        puntoventa_usuario: [
          {
            idpuntoventa: 0,
            idusuario: {
              id: "",
              username: ""
            },
            activo: true
          }
        ]
      },
      default: {
        idpuntoventa: 0,
        descpuntoventa: "",
        idcaja: {
          idcaja: ""
        },
        idcaja_a_depositar: {
          idcaja: ""
        },
        idcuentabanco_a_depositar: {
          idcuentabanco: ""
        },
        idimpresion_forma: {
          idimpresion_forma: ""
        },
        timbrado_puntoventa: [
          {
            idpuntoventa: 0,
            idtimbrado: { idtimbrado: "" },
            idconfig_impresion: { descreportes: "" }
          },
          {
            idpuntoventa: 0,
            idtimbrado: { idtimbrado: "" },
            idconfig_impresion: { descreportes: "" }
          },
          {
            idpuntoventa: 0,
            idtimbrado: { idtimbrado: "" },
            idconfig_impresion: { descreportes: "" }
          }
        ],
        puntoventa_usuario: [
          {
            idpuntoventa: 0,
            idusuario: {
              id: ""
            },
            activo: true
          }
        ]
      },
      dialogo: false
    };
  },

  computed: {
    tipo() {
      return booleanConverter.si_no(this.data.apertura);
    }
  },
  async created() {
    this.show(this.mostrar);
  },

  methods: {
    cancelar() {
      this.data = this.default;
      this.$emit('input',false);
      this.$emit('mostrar',null);
    },

    show(val) {
      this.dialogo = true;
      let data = val;
      if (data.timbrado_puntoventa[0] === undefined) {
        data.timbrado_puntoventa.push(this.default.timbrado_puntoventa[0]);
      }
      if (data.timbrado_puntoventa[1] === undefined) {
        data.timbrado_puntoventa.push(this.default.timbrado_puntoventa[1]);
      }
      if (data.timbrado_puntoventa[2] === undefined) {
        data.timbrado_puntoventa.push(this.default.timbrado_puntoventa[2]);
      }

      this.data = data;
    }
  }
};
</script>
