import { get } from "@/services/api/api.service";
import { SET_LOADING, SET_CIUDAD, url } from "./constants";
export default {
  async fetchCiudad(
    { commit, dispatch },
    { idnacionalidad, iddepartamento, search }
  ) {
    if(!idnacionalidad && !iddepartamento) return null;
    commit(SET_LOADING, true);
    try {
      let urlParams = url + "?";
      if (idnacionalidad)
        urlParams = urlParams + "nacionalidad=" + idnacionalidad + "&";
      if (iddepartamento)
        urlParams = urlParams + "departamento=" + iddepartamento + "&";
      urlParams = urlParams + "buscar=" + search;
      const response = await get(urlParams);
      commit(SET_CIUDAD, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(SET_LOADING, false);
      throw e;
    }
    commit(SET_LOADING, false);
  },
};
