import { db } from "@/services/db.service";
export default {
    async marca_update({commit},request){
        commit('marca_update',request);
    },
    async marca_show({commit},request){
        commit('marca_show',request);
    },

    async Vact_marca_data({commit}){
        commit('marca_load',true);
        try {
            const data = await db.get('/apimarca/');
            commit('marca_data',data);
            commit('marca_load',false);
            return true
        } catch (e) {
            commit('marca_load',false);
            return false
        } 
    },
    async Vact_marca_del({dispatch},id){
        try {
            const data = await db.delete('/apimarca/'+id);
            dispatch('Vact_marca_data');
            return data
        } catch (e) {
            return false
        } 
    },
    async Vact_marca_save({commit},data){
        commit('marca_save_load',true);
        try {
            const resp = await db.save('/apimarca/',data);
            commit('marca_save_load',false);
            return resp;
        } catch (e) {
            commit('marca_save_load',false);
            return e
        } 
    },
    async Vact_marca_update({commit},{id, data}){
        commit('marca_save_load',true);
        try {
            const resp = await db.update('/apimarca/'+id+'/',data);
            commit('marca_save_load',false);
            return resp;
        } catch (e) {
            commit('marca_save_load',false);
            return e
        } 
    },
}
