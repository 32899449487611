export default {

  deposito_data(state, request) {
    state.deposito_data = request;
  },
  deposito_reporte(state, request) {
    state.deposito_reporte = request;
  },
  deposito_siembra(state, request) {
    state.deposito_siembra = request;
  },  
  deposito_load(state, request) {
    state.deposito_load = request;
  },  
  deposito_save_load(state, request) {
    state.deposito_save_load = request;
  },  
}