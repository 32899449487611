
export default {

  Vget_puntoventa_data: (state) => {
    return state.puntoventa_data
  },
  Vget_puntoventa_punto: (state) => {
    return state.puntoventa_punto
  },
  Vget_puntoventa_byIdSucursal: (state) => {
    return state.puntoventa_byIdSucursal
  },
  Vget_puntoventa_parametros: (state) => {
    return state.puntoventa_parametros
  },
  Vget_puntoventa_load: (state) => {
    return state.puntoventa_load
  },
  Vget_puntoventa_save_load: (state) => {
    return state.puntoventa_save_load
  },
}
