<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="date"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{on}">
        <v-text-field
          v-model="mask"
          :id="id"
          ref="input"
          :rules="concatRules"
          :label="label"
          :rounded="rounded"
          :filled="filled"
          :readonly="readonly"
          v-maska="'##-##-####'"
          :outlined="outlined"
          v-on:focus="$emit('focus')"
          :dense="dense"
          :hide-details="hideDetails"
          autocomplete="off"
          :disabled="disabled"
          :append-icon="disabled || readonly ? '' : 'date_range'"
          @click:append="menu = on"
          @keyup.enter="completeDate()"
          @blur="completeDate(), $emit('blur')"
        ></v-text-field>
      </template>
      <v-date-picker no-title ref="picker" v-model="date" @input="menu = false, convertValueToMask(date), $emit('change')"></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import { current_date, dmy } from "@/services/util/date.service";
import { maska } from 'maska'
export default {
  directives: { maska },
  props: {
    "hide-details": {
      type: Boolean,
      default: false,
    },
    filled: Boolean,
    rounded: Boolean,
    label: String,
    dense: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
    },
    id: String,
    rules: {
      type: [Array],
      default: function () {
        return [(v) => !!v || "Este campo es obligatorio"];
      },
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    
    value(val) {
      this.convertValueToMask(val);
      this.date = val ? val.length === 10 ? val : current_date() : current_date();
    },
    mask(val) {
      this.setDate(val);
      let [day, month, year] = this.getDateParts(val);
      if (day.length === 2 && month === "") {
        day = day + "-";
        this.setDate(day);
      }
      if (month.length === 2 && year === "") {
        month = month + "-";
        this.setDate(`${day}-${month}`);
      }
      if (day.length > 2 && month.length > 0) {
        let dayParts = day.split("");
        day = dayParts[0] + "" + dayParts[1];
        month = dayParts[2] + "" + month;
        this.setDate(`${day}-${month}-${year}`);
      }
      if (month.length > 2 && year.length > 0) {
        let monthParts = month.split("");
        month = monthParts[0] + "" + monthParts[1];
        year = monthParts[2] + "" + year;
        this.setDate(`${day}-${month}-${year}`);
      }
      if (year.length > 4) {
        let yearParts = year.split("");
        year =
          yearParts[0] +
          "" +
          yearParts[1] +
          "" +
          yearParts[2] +
          "" +
          yearParts[3];
        this.setDate(`${day}-${month}-${year}`);
      }
    },
  },
  methods: {
    setDate(val) {
      this.mask = val;
      const [day, month, year] = this.getDateParts(val);
      this.$emit("input", `${year}-${month}-${day}`);
    },
    getDateParts(val) {
      let [day, month, year] = val
        .split(" ")
        .join("")
        .replace(/[/]/gi, "-")
        .split("-");
      return [day || "", month || "", year || ""];
    },
    convertValueToMask(val) {
      if (val === null) return (this.mask = "");
      let [year, month, day] = this.getDateParts(val);
      if (year != "" && month != "" && day != "") this.mask = dmy(val);
    },
    completeDate() {
      let [day, month, year] = this.getDateParts(this.mask);
      if (day.length === 0) return;
      if (day.length === 1) day = "0" + day;
      if (month.length === 0) return;
      if (month.length === 1) month = "0" + month;
      switch (year.length) {
        case 0:
          year = new Date().getFullYear();
          break;
        case 1:
          year = "200" + year;
          break;
        case 2:
          year = "20" + year;
          break;
        case 3:
          year = "2" + year;
          break;
        default:
          break;
      }
      this.setDate(`${day}-${month}-${year}`);
    },
    focus() {
      this.$refs.input.focus();
    },
  },
  data() {
    return {
      validar: [(v) => !!v || "Campo requerido"],
      mask: "",
      menu: false,
      date: '',
    };
  },
  mounted() {
    if (this.value != null) {
      this.date = this.value;
      this.convertValueToMask(this.value);
    }
  },

  computed: {
    computed_val() {
      return this.value != null ? this.value : current_date();
    },
    rulesDefault(){
      return [
        v => v.split('-')[0] <= 31 || 'Dia invalido',
        v => v.split('-')[1] != undefined ? v.split('-')[1] <= 12 || 'Mes invalido' : v.split('-')[1] === undefined,
        v => v.split('-')[2] != undefined ? v.split('-')[2] <= 2199 || 'Año ya supera el limite' : v.split('-')[2] === undefined,
      ]
    },
    concatRules(){
      return this.rulesDefault.concat(this.rules);
    }
  },
};
</script>