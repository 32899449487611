<template>
  <v-dialog persistent max-width="700" :value="value">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-form ref="form" @submit.prevent="">
          <v-row dense>
            <v-col cols="6">
              <c-text-date
                autocomplete="off"
                label="Fecha"
                :rules="rules"
                outlined
                dense
                ref="maquinaria1"
                v-model="data.fecha"
                placeholder="Ingrese la fecha"
                @keyup.native.enter="next(1, data.fecha)"
              ></c-text-date>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                dense
                v-model="data.idmaquinaria_asignacion"
                ref="maquinaria2"
                v-bind:items="getMaquinariaAsignacion"
                label="Maquinaria Asignacion"
                item-value="idmaquinaria_asignacion"
                return-object
                :rules="[
                  (v) =>
                    !!v.idmaquinaria_asignacion || 'Seleccione una asignacion',
                ]"
                required
                outlined
                autocomplete="off"
                @keyup.native.enter="
                  next(2, data.idmaquinaria_asignacion.idmaquinaria_asignacion)
                "
                :no-data-text="`No existe ninguna coincidencia`"
              >
                <template slot="selection" slot-scope="data">
                  {{ data.item.idmaquinaria.descripcion }} -
                  {{ data.item.idmaquinaria.chapa }}
                </template>
                <template slot="item" slot-scope="data">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ data.item.idmaquinaria.descripcion }} -
                      {{ data.item.idmaquinaria.chapa }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ data.item.idchofer.nombre }}
                      {{ data.item.idchofer.apellido }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                dense
                v-model="data.iditem"
                ref="maquinaria3"
                v-bind:items="item"
                :filter="customFilter"
                :search-input.sync="searchitem"
                label="Item"
                item-text="descitem"
                return-object
                :rules="[(v) => !!v.iditem || 'Seleccione un Item']"
                :loading="Vget_item_load"
                item-value="iditem"
                required
                outlined
                autocomplete="off"
                @keyup.enter="next(3, data.iditem.iditem)"
                :no-data-text="`No existe ninguna concidencia`"
              >
                <template v-if="item_permission" v-slot:no-data>
                  <v-list-item @click="formularios.item = true">
                    <v-list-item-content>
                      <v-list-item-title
                        >No existe concidencia. Click para
                        agregar</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete>
              <div v-if="formularios.item">
                <Item-Item-create
                  :sync-input="searchitem"
                  @sync-input="
                    (data.iditem = $event),
                      next(3, data.iditem.iditem)
                  "
                  v-model="formularios.item"
                />
              </div>
            </v-col>
            <v-col cols="6">
              <AutocompleteCiudad
                v-model="data.idorigen"
                ref="maquinaria4"
                :rules="[]"
                dense
                outlined
                :filter-country="172"
                :detailed="true"
                label="Seleccione Ciudad de origen"
                @keyup.native.enter="next(4, data.idorigen.idciudad)"
              />
            </v-col>
            <v-col cols="6">
              <AutocompleteCiudad
                v-model="data.iddestino"
                ref="maquinaria5"
                :rules="[]"
                dense
                outlined
                :filter-country="172"
                :detailed="true"
                label="Seleccione Ciudad de destino"
                @keyup.native.enter="next(5, data.idodestino.idciudad)"
              />
            </v-col>
            <v-col cols="6">
              <c-text-currency
                label="Peso en Kg"
                ref="maquinaria6"
                :length="0"
                dense
                outlined
                v-model="data.peso"
                @keyup.native.enter="next(6, data.peso)"
              ></c-text-currency>
            </v-col>
            <v-col cols="6">
              <c-text-currency
                label="Precio"
                ref="maquinaria7"
                :length="0"
                dense
                outlined
                v-model="data.precio"
                @keyup.native.enter="next(7, data.precio)"
              ></c-text-currency>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <c-btn-crud-close @click="cancel()"></c-btn-crud-close>
        <v-spacer></v-spacer>
        <c-btn-crud-done ref="maquinaria7" @click="save()"></c-btn-crud-done>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import AutocompleteCiudad from "@/views/Mantenimientos/Ciudad/autocomplete2";
import { ITEM } from "@/constants/forms";
import { PUEDE_AGREGAR } from "@/constants/permissions";
import { getPermission } from "@/services/storage/permissionStorage.service";
export default {
  components: {
    AutocompleteCiudad,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
    },
    "sync-input": {
      type: [String, Number],
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    searchitem(val) {
      this.obtain_item(val);
    },
    Vget_item_data(val) {
      this.item = [];
      this.item = JSON.parse(JSON.stringify(val));
      if (this.searchItemId != null) return this.search();
    },
    searchItemId() {
      this.search();
    },
  },
  mounted() {
    if (this.editable) {
      this.data = JSON.parse(JSON.stringify(this.props));
    }
    setTimeout(() => this.next(0, "-"), 200);
    if (this.syncInput != null) this.data.descripcion = this.syncInput;
    this.sync = this.syncInput;
  },
  created() {
    this.fetchMaquinariaAsignacion();
  },
  computed: {
    ...mapGetters("maquinaria_asignacion", [
      "isLoadingMaquinariaAsignacion",
      "getMaquinariaAsignacion",
    ]),
    ...mapGetters("item", ["Vget_item_data", "Vget_item_load"]),
    item_permission() {
      return getPermission(ITEM).permiso[PUEDE_AGREGAR];
    },
    title() {
      return this.editable
        ? "Modificar Transporte Maquinaria"
        : "Registrar Transporte Maquinaria";
    },
  },
  methods: {
    ...mapActions("maquinaria_asignacion", ["fetchMaquinariaAsignacion"]),
    ...mapActions("maquinaria_transporte", [
      "setMaquinariaTransporte",
      "setMaquinariaTransporteUpdte",
      "fetchMaquinariaTransporte"
    ]),
    ...mapActions("item", ["Vact_item_data", "item_update"]),
    obtain_item(val) {
      if (val != null && val.length >= 3) {
        this.Vact_item_data({
          desc: this.searchitem
        })
      } else if (val != null && val.length <= 3) {
        this.item = []
      }
    },
    customFilter(item, queryText) {
      const textOne = item.iditem.toString();
      const textTwo = item.descitem.toLowerCase();
      const searchText = queryText.toLowerCase();
      return (
        textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1
      )
    },
    search() {
      this.item.forEach((x) => {
        if (x.iditem === this.searchItemId) {
          return
        }
      })
    },
    next(id, data) {
      if (data != "")
        try {
          this.$refs[`maquinaria${id + 1}`].focus();
        } catch (error) {
          this.$refs[`maquinaria${id + 1}`].$el.focus();
        }
    },
    cancel() {
      this.$emit("input", false);
    },
    async save() {
      if (!this.$refs.form.validate()) return null;
      const response = this.editable
        ? await this.setMaquinariaTransporteUpdate({
            data: this.data,
            id: this.data.idmaquinaria_transporte,
          })
        : await this.setMaquinariaTransporte(this.data);
      if (response.success) {
        if (this.sync != null) {
          this.cancel();
          this.fetchMaquinariaTransporte();
          return this.$emit(
            "sync-input",
            response.data.idmaquinaria_transporte
          );
        }
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();
        this.fetchMaquinariaTransporte();
        this.next(0, "-");
        if (this.editable) this.cancel();
      }
    },
  },
  data: () => ({
    rules: [(v) => !!v || "Este campo es obligatorio"],
    formularios: {
      item: false,
    },
    searchitem: "",
    item: [],
    data: {
      idmaquinaria_transporte: 0,
      fecha: "",
      idmaquinaria_asignacion: {
        idmaquinaria_asignacion: "",
      },
      iditem: {
        iditem: "",
      },
      idorigen: {
        idciudad: "",
      },
      iddestino: {
        idciudad: "",
      },
      peso: 0,
      precio: 0,
    },
    default: {
      idmaquinaria_transporte: 0,
      fecha: "",
      idmaquinaria_asignacion: {
        idmaquinaria_asignacion: "",
      },
      iditem: {
        iditem: "",
      },
      idorigen: {
        idciudad: "",
      },
      iddestino: {
        idciudad: "",
      },
      peso: 0,
      precio: 0,
    },
    sync: null,
  }),
};
</script>