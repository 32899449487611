import { del, get, post, put } from "@/services/api/api.service";
import {
  url,
  LOADING,
  FETCH,
  FETCH_CELO,
  FETCH_TORO,
  FETCH_DISPONIBLE,
  FETCH_HEMBRA,
  FETCH_TERMO,
  FETCH_NOMINA,
  FETCH_PARASECADO,
  FETCH_INSEMINADA,
  FETCH_PRENADA,
  FETCH_VIVO,
  FETCH_MUERTO,
  FETCH_TODOS,
  FETCH_ENFERMO,
  FETCH_VENTA,
  FETCH_POSTPARTO,
  FETCH_TERNERO,
  FETCH_SECADO,
  FETCH_BY_ESTADO,
  FETCH_NOMINA_ID,
  FETCH_COUNT,
  FETCH_SEMEN,
  FETCH_FICHA,
  FETCH_VAQUILLA,
  FETCH_REPORTE_AVANZADO,
  FETCH_REPORTE_REPRODUCCION,
  FETCH_PREPARTO,
  SET_SOCKET,
} from "./contants";
export default {
  setAnimal: async ({ commit, dispatch }, data) => {
    commit(LOADING, true);
    const response = await post(url.animal, data);
    dispatch("snackbar/setNotification", response, { root: true });
    commit(LOADING, false);
    return response;
  },

  async setAnimalUpdate({ commit, dispatch }, { data, id }) {
    commit(LOADING, true);
    const response = await put(`${url.animal}${id}/`, data);
    dispatch("snackbar/setNotification", response, { root: true });
    commit(LOADING, false);
    return response;
  },

  setNomina: async ({ commit, dispatch }, id) => {
    commit(LOADING, true);
    const response = await post("/apinominaanimal/" + id + "/");
    dispatch("snackbar/setNotification", response, { root: true });
    commit(LOADING, false);
    return response;
  },

  fetchCount: async ({ commit, dispatch }) => {
    commit(LOADING, true);
    try {
      const response = await get("/apianimalcount/");
      commit(FETCH_COUNT, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(LOADING, false);
      throw e;
    }
    commit(LOADING, false);
  },
  fetchNominaId: async ({ commit, dispatch }, id) => {
    if (!id) return commit(FETCH_NOMINA_ID, true);
    commit(LOADING, true);
    try {
      const response = await get("/apinominaanimal/" + id + "/");
      commit(FETCH_NOMINA_ID, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(LOADING, false);
      throw e;
    }
    commit(LOADING, false);
  },
  fetchFicha: async ({ commit, dispatch }, params) => {
    commit(LOADING, true);
    try {
      const response = await get(
        "/apireporteanimal/?idanimal=" +
          params.idanimal +
          "&inicio=" +
          params.desde +
          "&fin=" +
          params.hasta
      );
      commit(FETCH_FICHA, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(LOADING, false);
      throw e;
    }
    commit(LOADING, false);
  },

  fetchEnfermo: async ({ commit, dispatch }) => {
    commit(LOADING, true);
    try {
      const response = await get(url.animal + "?band=enfermo");
      commit(FETCH_ENFERMO, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(LOADING, false);
      throw e;
    }
    commit(LOADING, false);
  },

  fetchPreparto: async ({ commit, dispatch }) => {
    commit(LOADING, true);
    try {
      const response = await get(url.animal + "?band=preparto");
      commit(FETCH_PREPARTO, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(LOADING, false);
      throw e;
    }
    commit(LOADING, false);
  },

  fetchVaquilla: async ({ commit, dispatch }) => {
    commit(LOADING, true);
    try {
      const response = await get(url.animal + "?band=vaquilla");
      commit(FETCH_VAQUILLA, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(LOADING, false);
      throw e;
    }
    commit(LOADING, false);
  },
  fetchVenta: async ({ commit, dispatch }) => {
    commit(LOADING, true);
    try {
      const response = await get(url.animal + "?band=vendido");
      commit(FETCH_VENTA, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(LOADING, false);
      throw e;
    }
    commit(LOADING, false);
  },
  fetchPostParto: async ({ commit, dispatch }) => {
    commit(LOADING, true);
    try {
      const response = await get(url.animal + "?band=postparto");
      commit(FETCH_POSTPARTO, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(LOADING, false);
      throw e;
    }
    commit(LOADING, false);
  },

  fetchVivo: async ({ commit, dispatch }) => {
    commit(LOADING, true);
    try {
      const response = await get(url.animal + "?band=vivo");
      commit(FETCH_VIVO, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(LOADING, false);
      throw e;
    }
    commit(LOADING, false);
  },

  fetchMuerto: async ({ commit, dispatch }) => {
    commit(LOADING, true);
    try {
      const response = await get(url.animal + "?band=muerte");
      commit(FETCH_MUERTO, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(LOADING, false);
      throw e;
    }
    commit(LOADING, false);
  },
  fetchTodos: async ({ commit, dispatch }, params) => {
    commit(LOADING, true);
    try {
      let response = null
      if (params != null) {
        response = await get(url.animal + `?band=todos&${params}`);
      } else {
        response = await get(url.animal + "?band=todos");
      }
      commit(FETCH_TODOS, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(LOADING, false);
      throw e;
    }
    commit(LOADING, false);
  },
  fetchReporteAvanzado: async ({ commit, dispatch }, params) => {
    commit(LOADING, true);
    try {
      const response = await get(url.reporteAvanzado + "?" + params);
      commit(FETCH_REPORTE_AVANZADO, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(LOADING, false);
      throw e;
    }
    commit(LOADING, false);
  },
  fetchReporteReproduccion: async (
    { commit, dispatch },
    { band, inicio, fin }
  ) => {
    commit(LOADING, true);
    try {
      const response = await get(
        url.reporteReproduccion +
          "?band=" +
          band +
          "&inicio=" +
          inicio +
          "&fin=" +
          fin
      );
      commit(FETCH_REPORTE_REPRODUCCION, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(LOADING, false);
      throw e;
    }
    commit(LOADING, false);
  },
  fetchPrenada: async ({ commit, dispatch }) => {
    commit(LOADING, true);
    try {
      const response = await get(url.animal + "?band=prenada");
      commit(FETCH_PRENADA, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(LOADING, false);
      throw e;
    }
    commit(LOADING, false);
  },

  fetchInseminada: async ({ commit, dispatch }) => {
    commit(LOADING, true);
    try {
      const response = await get(url.animal + "?band=inseminada");
      commit(FETCH_INSEMINADA, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(LOADING, false);
      throw e;
    }
    commit(LOADING, false);
  },

  fetchParaSecado: async ({ commit, dispatch }) => {
    commit(LOADING, true);
    try {
      const response = await get(url.animal + "?band=parasecado");
      commit(FETCH_PARASECADO, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(LOADING, false);
      throw e;
    }
    commit(LOADING, false);
  },

  fetchNomina: async ({ commit, dispatch }, { turno, fecha }) => {
    commit(LOADING, true);
    var band = "?band=nomina";
    if (turno) band = band + "&turnoordeno=" + turno + "&fecha=" + fecha;
    try {
      const response = await get(url.animal + band);
      commit(FETCH_NOMINA, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(LOADING, false);
      throw e;
    }
    commit(LOADING, false);
  },

  fetchAnimalTermo: async ({ commit, dispatch }) => {
    commit(LOADING, true);
    try {
      const response = await get(url.animal + "?band=termo");
      commit(FETCH_TERMO, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(LOADING, false);
      throw e;
    }
    commit(LOADING, false);
  },
  fetchAnimalHembra: async ({ commit, dispatch }) => {
    commit(LOADING, true);
    try {
      const response = await get(url.animal + "?band=hembra");
      commit(FETCH_HEMBRA, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(LOADING, false);
      throw e;
    }
    commit(LOADING, false);
  },
  fetchAnimalDisponible: async ({ commit, dispatch }) => {
    commit(LOADING, true);
    try {
      const response = await get(url.animal + "?band=disponible");
      commit(FETCH_DISPONIBLE, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(LOADING, false);
      throw e;
    }
    commit(LOADING, false);
  },
  fetchAnimalToro: async ({ commit, dispatch }) => {
    commit(LOADING, true);
    try {
      const response = await get(url.animal + "?band=toro");
      commit(FETCH_TORO, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(LOADING, false);
      throw e;
    }
    commit(LOADING, false);
  },
  fetchAnimalSemen: async ({ commit, dispatch }) => {
    commit(LOADING, true);
    try {
      const response = await get(url.animal + "?band=semen");
      commit(FETCH_SEMEN, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(LOADING, false);
      throw e;
    }
    commit(LOADING, false);
  },
  fetchTernero: async ({ commit, dispatch }) => {
    commit(LOADING, true);
    try {
      const response = await get(url.animal + "?band=ternero");
      commit(FETCH_TERNERO, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(LOADING, false);
      throw e;
    }
    commit(LOADING, false);
  },
  fetchAnimalCelo: async ({ commit, dispatch }) => {
    commit(LOADING, true);
    try {
      const response = await get(url.animal + "?band=celo");
      commit(FETCH_CELO, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(LOADING, false);
      throw e;
    }
    commit(LOADING, false);
  },
  fetchAnimalSecado: async ({ commit, dispatch }) => {
    commit(LOADING, true);
    try {
      const response = await get(url.animal + "?band=secado");
      commit(FETCH_SECADO, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(LOADING, false);
      throw e;
    }
    commit(LOADING, false);
  },
  fetchAnimal: async ({ commit, dispatch }) => {
    commit(LOADING, true);
    try {
      const response = await get(url.animal);
      commit(FETCH, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(LOADING, false);
      throw e;
    }
    commit(LOADING, false);
  },
  fetchAnimalByEstado: async ({ commit, dispatch }, id) => {
    commit(LOADING, true);
    try {
      const response = await get(url.animal + "?estado=" + id);
      commit(FETCH_BY_ESTADO, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(LOADING, false);
      throw e;
    }
    commit(LOADING, false);
  },
  deleteAnimal: async ({ commit, dispatch }, id) => {
    commit(LOADING, true);
    const response = await del(`${url.animal}${id}/`);
    dispatch("snackbar/setNotification", response, { root: true });
    console.log(response)
    commit(LOADING, false);
    return response;
  },
  async setSocketData({ commit }, data) {
    commit(SET_SOCKET,data)
  },
};
