export default {
    Vget_recetario_data: (state) => {
        return state.recetario_data;
    },
    Vget_recetario_lote: (state) => {
        return state.recetario_lote;
    },
    Vget_recetario_siembra: (state) => {
        return state.recetario_siembra;
    },
    Vget_recetario_load: (state) => {
        return state.recetario_load;
    }
}