<template>
  <div>
    <v-treeview
      open-on-click
      :items="filteredElements"
      :search="search"
      :open="filteredKeys"
    >
      <template v-slot:prepend="{ item, open }">
        <v-icon v-if="item.children">
          {{ open ? "folder_open" : "folder" }}</v-icon
        >
        <v-icon v-else-if="item.icon"> {{ item.icon }}</v-icon>
      </template>
      <template slot="label" slot-scope="props">
        <router-link :to="props.item.route" v-if="props.item.route" tag="div">{{
          props.item.name
        }}</router-link>
        <div v-else>{{ props.item.name }}</div>
      </template>
    </v-treeview>
  </div>
</template>

<script>
import { getPermission } from "@/services/storage/permissionStorage.service";
import { navbar } from "./navbarV6";
export default {
  props: {
    search: String,
  },
  data: () => ({
    routes: [],
    open: [],
  }),
  watch: {
    open(val) {
      console.log(val);
    },
  },
  mounted() {
    this.routes = navbar(getPermission());
  },
  computed: {
    filteredElements() {
      return this.routes.reduce((accumulator, current) => {
        if (current.children) {
          const children = current.children.reduce((accumulator1, current1) => {
            if (current1.children) {
              const children1 = current1.children.reduce(
                (accumulator2, current2) => {
                  if (current2.children) {
                    const children2 = current2.children.filter((child) => {
                      return (
                        child.name
                          .toLowerCase()
                          .indexOf(this.search.toLowerCase()) >= 0
                      );
                    });
                    if (children2.length)
                      accumulator2.push({
                        ...current2,
                        children: [...children2],
                      });
                    return accumulator2;
                  } else {
                    if (
                      current2.name
                        .toLowerCase()
                        .indexOf(this.search.toLowerCase()) >= 0
                    )
                      accumulator2.push({
                        ...current2,
                      });
                    return accumulator2;
                  }
                },
                []
              );
              if (children1.length)
                accumulator1.push({
                  ...current1,
                  children: [...children1],
                });
              return accumulator1;
            } else {
              if (
                current1.name
                  .toLowerCase()
                  .indexOf(this.search.toLowerCase()) >= 0
              )
                accumulator1.push({
                  ...current1,
                });
              return accumulator1;
            }
          }, []);
          if (children.length)
            accumulator.push({
              ...current,
              children: [...children],
            });
          return accumulator;
        } else {
          if (
            current.name.toLowerCase().indexOf(this.search.toLowerCase()) >= 0
          )
            accumulator.push({
              ...current,
            });
          return accumulator;
        }
      }, []);
    },
    filteredKeys() {
      let open = [];
      if (this.search.length > 0) {
        this.filteredElements.map((child1) => {
          open.push(child1.id);
          if (child1.children) {
            child1.children.map((child2) => {
              if (child2.children) {
                child2.children.map((child3) => {
                  open.push(child3.id);
                });
              }
              open.push(child2.id);
            });
          }
        });
        return open;
      }
      return [];
    },
  },
};
</script>

<style>
</style>