export default {
    async tiporesponsable_create({commit},request){
        commit('tiporesponsable_create',request);
    },
    async tiporesponsable_update({commit},request){
        commit('tiporesponsable_update',request);
    },
    async tiporesponsable_reload({commit},request){
        commit('tiporesponsable_reload',request);
    },
    async tiporesponsable_show({commit},request){
        commit('tiporesponsable_show',request);
    },
}
