import { get, put, del, post } from "@/services/api/api.service";
import { SET_LOADING, SET_USER_ACTIVE, SET_USER_INACTIVE, SET_USER_WITH_PERMISSIONS, url } from './constants'
export default {
    async setUsers({ commit, dispatch }, data) {
        commit(SET_LOADING, true);
        const response = await post(url.base, data);
        dispatch('snackbar/setNotification', response, { root: true })
        commit(SET_LOADING, false);
        return response
    },
    async setUsersUpdate({ commit, dispatch }, {id,data}) {
        commit(SET_LOADING, true);
        const response = await put(`${url.base}${id}/`, data);
        dispatch('snackbar/setNotification', response, { root: true })
        commit(SET_LOADING, false);
        return response
    },
    async fetchUsersActive({ commit, dispatch }) {
        commit(SET_LOADING, true);
        try {
            const response = await get(url.active);
            commit(SET_USER_ACTIVE, response);
        } catch (e) {
            dispatch('snackbar/setNotification', e, { root: true });
            commit(SET_LOADING, false);
            throw e
        }
        commit(SET_LOADING, false);
    },
    async fetchUsersInactive({ commit, dispatch }) {
        commit(SET_LOADING, true);
        try {
            const response = await get(url.inactive);
            commit(SET_USER_INACTIVE, response);
        } catch (e) {
            dispatch('snackbar/setNotification', e, { root: true });
            commit(SET_LOADING, false);
            throw e
        }
        commit(SET_LOADING, false);
    },
    async setUserReset({ commit, dispatch }, { id, data }) {
        commit(SET_LOADING, true);
        const response = await put(`/apiuseradmin/${id}/`, data);
        dispatch('snackbar/setNotification', response, { root: true })
        commit(SET_LOADING, false)
        return response;
    },
    async setUserDisabled({ commit, dispatch }, { id, band }) {
        commit(SET_LOADING, true);
        const response = await del(`/apiuseradmin/${id}/?${band}`);
        dispatch('snackbar/setNotification', response, { root: true })
        commit(SET_LOADING, false)
        return response;
    },
    async fethUserPermission({ commit, dispatch }, { id, idempresa, idsucursal }) {
        commit(SET_LOADING, true);
        try {
            const response = await get(`/apiuseradmin/${id}/?idempresa=${idempresa}&idtambo=${idsucursal}`);
            commit(SET_USER_WITH_PERMISSIONS, response);
        } catch (e) {
            dispatch('snackbar/setNotification', e, { root: true });
            commit(SET_LOADING, false);
            throw e
        }
        commit(SET_LOADING, false);
    }
}
