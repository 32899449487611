import { get, post, put, del } from "@/services/api/api.service";
import { url, LOADING, FETCH, FETCHLOTEDEP, LOADINGLOTEDEP, FETCHLOTEDIS, LOADINGLOTEDIS, FETCHLOTELOC, LOADINGLOTELOC } from "./constants";
export default {
  fetchLote: async ({ commit, dispatch }) => {
    commit(LOADING, true);
    try {
      const response = await get(url.root);
      commit(FETCH, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(LOADING, false);
      throw e;
    }
    commit(LOADING, false);
  },
  async setLote({ commit, dispatch }, data) {
    commit(LOADING, true);
    const response = await post(url.root, data);
    dispatch("snackbar/setNotification", response, { root: true });
    commit(LOADING, false);
    return response;
  },
  async setLoteUpdate({ commit, dispatch }, { data, id }) {
    commit(LOADING, true);
    const response = await put(`${url.root}${id}/`, data);
    dispatch("snackbar/setNotification", response, { root: true });
    commit(LOADING, false);
    return response;
  },
  async setLoteDelete({ commit, dispatch }, id) {
    commit(LOADING, true);
    const response = await del(`${url.root}${id}/`);
    dispatch("snackbar/setNotification", response, { root: true });
    commit(LOADING, false);
    return response;
  },
  fetchLotedep: async ({commit, dispatch}) => {
    commit(LOADINGLOTEDEP, true);
    try {
      const response = await get(url.urldep);
      commit(FETCHLOTEDEP, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(LOADINGLOTEDEP, false);
      throw e;
    }
    commit(LOADINGLOTEDEP, false);
  },
  async fetchLotedis ({commit, dispatch}, idlotedep) {
    commit(LOADINGLOTEDIS, true);
    try {
      const response = await get(`${url.urldis}?idlotedep=${idlotedep}`);
      commit(FETCHLOTEDIS, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(LOADINGLOTEDIS, false);
      throw e;
    }
    commit(LOADINGLOTEDIS, false);
  },
  fetchLoteloc: async ({commit, dispatch}, idlotedis) => {
    commit(LOADINGLOTELOC, true);
    try {
      const response = await get(`${url.urlloc}?idlotedis=${idlotedis}`);
      commit(FETCHLOTELOC, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(LOADINGLOTELOC, false);
      throw e;
    }
    commit(LOADINGLOTELOC, false);
  }
}
