export const LOADING = 'loadingExtracto';
export const FETCH_GENERAL = 'fetchGeneral';
export const FETCH_RESUMIDO = 'fetchResumido';
export const FETCH_CLIENTE = 'fetchCliente';
export const FETCH_PROVEEDOR = 'fetchProveedor';

export const url = {
    general: '/apireporteextractoventa/detalle/',
    resumido: '/apireporteextractoventa/resumen/',
    cliente: '/apireporteextracto/cliente/saldo/',
    proveedor: '/apireporteextracto/proveedor/saldo/'
}