import { db } from "@/services/db.service";
export default {
    async Vact_pagocuenta_save({ commit },data) {
        commit('pagocuenta_load',true);
        try {
            const resp = await db.save('/apiordenpagorecibo/',data);
            commit('pagocuenta_load',false);
            return resp;
        } catch (e) {
            commit('pagocuenta_load',false);
            return false;
        } 
    },
    async Vact_pagocuenta_data({ commit }) {
        commit('pagocuenta_load',true);
        try {
            const data = await db.get('/apiordenpagorecibo/?limit=10');
            commit('pagocuenta_data',data);
            commit('pagocuenta_load',false);
            return true
        } catch (e) {
            commit('pagocuenta_load',false);
            return false
        } 
    },
    async Vact_pagocuenta_data_id({ commit },id) {
        commit('pagocuenta_load',true);
        try {
            let data = await db.get('/apiordenpagorecibo/'+id+'/');   
            data.ordenpagodet.forEach((x,i)=>{
                const est = x.idcuentapagar.idcompra.regi_compra;
                data.ordenpagodet[i].factura = est+'-'+x.idcuentapagar.idcompra.numfcompra;         
            })
            commit('pagocuenta_data_id',data);
            commit('pagocuenta_load',false);
            return true
        } catch (e) {
            commit('pagocuenta_load',false);
            return false
        } 
    },
    async Vact_pagocuenta_del({dispatch},id){
        try {
            const data = await db.delete('/apiordenpagorecibo/'+id+'/');
            dispatch('Vact_pagocuenta_data');
            return data
        } catch (e) {
            return false
        } 
    },
    async Vact_pagocuenta_by_id({ commit },{id,idmoneda, cotizacion}) {
        commit('pagocuenta_load',true);
        try {
            let data = await db.get('/apicuentapagar/?prov='+id+'&money='+idmoneda+'&cotizacion='+cotizacion);
            data.forEach((x,i)=>{
                const est = x.idcompra.regi_compra;
                data[i].factura = est+'-'+x.idcompra.numfcompra;        
            })  
            commit('pagocuenta_by_id',data);
            commit('pagocuenta_load',false);
            return null
        } catch (e) {
            commit('pagocuenta_by_id',[]);
            commit('pagocuenta_load',false);
            return e.message
        } 
    },
    
    async Vact_pagocuenta_num_rec({commit}){
        commit('pagocuenta_num_rec_load',true);
        try{
            const resp = await db.get('/apinrorecibo/');
            commit('pagocuenta_num_rec',resp[0]);
            commit('pagocuenta_num_rec_load',false);
            return true
        } catch (e) {
            commit('pagocuenta_num_rec_load',false);
            return false
        } 
    }
}
