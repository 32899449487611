export default [
  {
    path: '/timbrado',
    name: 'Timbrado',
    component: () => import( /* webpackChunkName: "Timbrado" */ '@/views/Venta/Timbrado/Timbrado.vue'),
  },
  {
    path: '/establecimiento',
    name: 'Establecimiento',
    component: () => import( /* webpackChunkName: "Timbrado" */ '@/views/Venta/Establecimiento/List.vue'),
  },
  {
    path: '/puntoventa',
    name: 'Punto Venta',
    component: () => import( /* webpackChunkName: "Timbrado" */ '@/views/Venta/PuntoVenta/PuntoVenta.vue'),
  },
  {
    path: '/apertura',
    name: 'Apertura',
    component: () => import( /* webpackChunkName: "Timbrado" */ '@/views/Venta/Apertura/Apertura.vue'),
  },
  {
    path: '/venta',
    name: 'Venta',
    component: () => import( /* webpackChunkName: "Venta" */ '@/views/Venta/Venta/Venta.vue'),
  },
  {
    path: '/venta/reportes',
    component: () => import( /* webpackChunkName: "Venta Reporte" */ '@/views/Venta/Reporte/Root.vue'),
    children:[
      {
        path: '/',
        name: 'Reporte de Ventas',
        component: () => import( /* webpackChunkName: "Venta Reporte" */ '@/views/Venta/Reporte/Custom.vue')
      },
      {
        path: 'itemventa',
        name: 'Items Vendidos',
        component: () => import( /* webpackChunkName: "Venta Reporte" */ '@/views/Venta/Reporte/VentaItem.vue')
      },
      {
        path: 'libroventa',
        name: 'Libro de Ventas',
        component: () => import( /* webpackChunkName: "Libro de Ventas" */ '@/views/Venta/Reporte/LibroVenta.vue'),
      },
      {
        path: 'extractogeneral',
        name: 'Extracto General de Clientes',
        component: () => import( /* webpackChunkName: "Venta Reporte" */ '@/views/Venta/Reporte/CuentasClientes/General.vue')
      },
      {
        path: 'extractoresumido',
        name: 'Extracto Resumido de Clientes',
        component: () => import( /* webpackChunkName: "Venta Reporte" */ '@/views/Venta/Reporte/CuentasClientes/Resumido.vue')
      },
    ]
  },
  {
    path: '/preventa',
    name: 'Preventa',
    component: () => import( /* webpackChunkName: "Venta" */ '@/views/Venta/PedidoVenta/PedidoVenta.vue'),
  },
  {
    path: '/perfilfin',
    name: 'Perfil Financiamiento',
    component: () => import( /* webpackChunkName: "Perfil Financiamiento" */ '@/views/Venta/PerfilFinanciamiento/PerfilFinanciamiento.vue'),
  },
  {
    path: '/cobrocuenta',
    name: 'Cobro Cuenta',
    component: () => import( /* webpackChunkName: "Venta" */ '@/views/Venta/CobroCuenta/CobroCuenta.vue'),
  },
  {
    path: '/notaventa',
    name: 'Nota de Credito Venta',
    component: () => import( /* webpackChunkName: "Compra" */ '@/views/Venta/NotaCredito/NotaCredito.vue'),
  },
  {
    path: '/extractocliente',
    name: 'Extracto de Cliente',
    component: () => import( /* webpackChunkName: "Compra" */ '@/views/Venta/ExtractoCliente/ExtractoCliente.vue'),
  },
  {
    path: '/libroventa',
    name: 'Libro de Venta',
    component: () => import( /* webpackChunkName: "Libro de Ventas" */ '@/views/Venta/Reporte/LibroVenta.vue'),
  }
]