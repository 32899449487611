import { SET_CONTACTO_FIREBASE, SET_LOADING} from './constans'
export default {
  [SET_CONTACTO_FIREBASE](state, request){
    state.contacto_firebase = request;
  },
  [SET_LOADING](state,request){
    state.isLoadingContacto = request;
  },
  contacto_create(state, request) {
    state.contacto_create = request;
  }, 
  contacto_update(state, request) {
    state.contacto_update = request;
  },
  contacto_reload(state, request) {
    state.contacto_reload = request;
  },  
  contacto_key(state, request) {
    state.contacto_key = request;
  },    
  contacto_show(state, request) {
    state.contacto_show = request;
  },    
  contacto_data_cliente(state, request) {
    state.contacto_data_cliente = request;
  },
  contacto_data_proveedor(state, request) {
    state.contacto_data_proveedor = request;
  },
  contacto_data_transportadora(state, request) {
    state.contacto_data_transportadora = request;
  },
  contacto_load(state, request) {
    state.contacto_load = request;
  },  
}