<template>
  <v-dialog v-model="value" persistent max-width="500">
    <v-card rounded>
      <v-toolbar flat dense>
        <v-toolbar-title class="title font-weight-medium">Modificar Asistencia</v-toolbar-title>
      </v-toolbar>
      <v-form ref="form">
        <v-row class="mx-1" dense>
          <v-col cols="12" md="6">
            <c-text-time
              seconds
              label="Hora entrada"
              v-model="data.inicio_hora"
            ></c-text-time>
          </v-col>
          <v-col cols="12" md="6">
            <c-text-time
            seconds
              label="Hora salida"
              v-model="data.fin_hora"
            ></c-text-time>
          </v-col>
          <v-col cols="12">
            <v-textarea
              outlined
              rows="3"
              label="Comentario"
              v-model="data.comentario"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-form>
      <v-card-actions>
        <c-btn-crud-close @click="cancelar()"></c-btn-crud-close>
        <v-spacer></v-spacer>
        <c-btn-crud-done @click="save()"></c-btn-crud-done>
      </v-card-actions>
      <c-overlay :value="isAsistenciaLoading"></c-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    value: Boolean,
    props: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    data: {
      inicio: "",
      inicio_fecha: "",
      inicio_hora: "",
      fin: null,
      fin_fecha: "",
      fin_hora: "",
      comentario: "",
    },
  }),
  mounted() {
    this.data = JSON.parse(JSON.stringify(this.props));
  },
 computed:{
     ...mapGetters('asistencia',['isAsistenciaLoading'])
 },
  methods: {
    ...mapActions("asistencia", ["setAsistenciaUpdate"]),
    cancelar() {
      this.$emit("input", false);
      this.$emit("reset");
    },
    async save() {
      if (!this.$refs.form.validate()) return null;
      const id = this.$route.params.id;
      const data = this.data;
      await this.setAsistenciaUpdate({ id, data });
      this.cancelar();
      this.$emit('fetch');
    },
  },
};
</script>

<style>
</style>