<template>
  <div>
    <v-dialog :value="value" max-width="800" persistent>
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Detalle de Timbrado</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row row dense>
            <v-col cols="12" sm="6" md="4">
              <c-text-field dense readonly outlined
                label="Establecimiento"
                v-model="data.idestablecimiento.establecimiento"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <c-text-field dense readonly outlined
                label="Punto de Expedicion"
                v-model="data.idpuntoexpedicion.puntoexpedicion"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <c-text-field dense readonly outlined
                label="Comprobante"
                v-model="data.idcomprobantelegal.desccomprobante"
              />
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <c-text-field dense readonly outlined label="Timbrado" v-model="data.timbrado" />
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <c-text-date dense readonly outlined label="Fecha Inicio" v-model="data.fecha_inicio" />
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <c-text-date dense readonly outlined label="Fecha Vencimiento" v-model="data.fecha_final" />
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <c-text-field dense readonly outlined label="Numero Inicio" v-model="data.numero_inicio" />
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <c-text-field dense readonly outlined label="Numero Final" v-model="data.numero_final" />
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-switch
                v-model="data.avisar_tim"
                :label="`Avisar Vto Timbrado?: ${avisar_timbrado}`"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                dense
                type="text"
                v-model="data.dia_tim"
                label="Dias de Aviso Timbrado"
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-switch
                v-model="data.avisar_num"
                :label="`Avisar Factura Restante?: ${avisar_factura}`"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                dense
                v-model="data.cantidad_num"
                label="Cantidad Restante"
                outlined
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="cancelar">
            <v-icon>clear</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { db } from "@/services/db.service";
import {  booleanConverter } from "@/services/util/boolean.service";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    xid: {
      type: Number,
      default: null,
    },
    datos: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      data: {
        idtimbrado: 0,
        fecha_inicio: "",
        fecha_final: "",
        numero_inicio: "",
        numero_final: "",
        avisar_tim: true,
        avisar_num: true,
        dia_tim: "",
        timbrado: "",
        idestablecimiento: {
          idestablecimiento: 0,
          establecimiento: "",
        },
        idpuntoexpedicion: {
          idpuntoexpedicion: 0,
          puntoexpedicion: "",
        },
        idcomprobantelegal: {
          idcomprobantelegal: 0,
          desccomprobante: "",
        },
      },
    };
  },
  watch: {
    $route(to, from) {
      if (from.path === "/timbrado") return this.cancelar();
    },
  },
  computed: {
    avisar_timbrado() {
      return booleanConverter.si_no(this.data.avisart);
    },
    avisar_factura() {
      return booleanConverter.si_no(this.data.avisarf);
    },
  },
  async created() {
    if (this.xid != null) {
      const data = await db.get(`/apitimbrado/${this.xid}`);
      return this.show(data);
    }
    this.show(this.datos);
  },

  methods: {
    cancelar() {
      this.$emit("input", false);
      this.$emit("datos", null);
    },
    show(val) {
      this.data = val;
    },
  },
};
</script>
