import {SET_LOADING, SET_USER_ACTIVE, SET_USER_INACTIVE, SET_USER_WITH_PERMISSIONS} from './constants'
export default {
  [SET_USER_ACTIVE](state, request) {
    state.active = request;
  }, 
  [SET_USER_INACTIVE](state, request) {
    state.inactive = request;
  }, 
  [SET_USER_WITH_PERMISSIONS](state, request) {
    state.user_with_permissions = request;
  }, 
  [SET_LOADING](state, request) {
    state.isLoading = request;
  },
}