<template>
  <div>
    <c-alert-permission v-model="permission"></c-alert-permission>
    <v-form ref="form" v-if="permission.can_view">
      <v-card-text>
        <v-row dense>
          <v-col cols="12" sm="2">
            <v-text-field
              autocomplete="off"
              :rules="validar"
              hide-details
              label="Año Inicio"
              id="input1"
              v-model="data.anho_inicio"
              @keyup.enter="next(1, data.anho_inicio)"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2">
            <v-text-field
              autocomplete="off"
              :rules="validar"
              hide-details
              label="Año Fin"
              id="input2"
              v-model="data.anho_fin"
              @keyup.enter="next(2, data.anho_fin)"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2">
            <v-autocomplete
              :items="tipoAplicacion"
              item-text="descripcion"
              item-value="id"
              :rules="validar"
              autocomplete="off"
              hide-details
              label="Aplicacion"
              id="input3"
              @change="changeTipoAplicacion(data.tipoaplicacion)"
              @keyup.enter="next(3, data.tipoaplicacion)"
              v-model="data.tipoaplicacion"
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" sm="2">
            <v-autocomplete
              v-if="data.tipoaplicacion === 'campo'"
              :items="Vget_campo_data"
              item-text="campo"
              item-value="idlote_agri"
              :rules="validar"
              autocomplete="off"
              hide-details
              :loading="Vget_campo_load"
              label="Seleccione el Lote"
              id="input4"
              @keyup.enter="next(5, data.idtiposeleccionado)"
              v-model="data.idtiposeleccionado"
            ></v-autocomplete>

            <v-autocomplete
              v-if="data.tipoaplicacion === 'lote'"
              :items="Vget_lotegrupo_data"
              item-text="descripcion"
              item-value="idlote_grupo"
              :rules="validar"
              autocomplete="off"
              hide-details
              :loading="Vget_lotegrupo_load"
              label="Seleccione la Zona"
              id="input4"
              @keyup.enter="next(5, data.idtiposeleccionado)"
              v-model="data.idtiposeleccionado"
            ></v-autocomplete>

            <v-autocomplete
              :filter="customFilter"
              :search-input.sync="searchitem"
              v-if="data.tipoaplicacion === 'siembra'"
              v-bind:items="Vget_item_agrostock"
              item-text="iditem.descitem"
              item-value="iditem.iditem"
              :rules="validar"
              autocomplete="off"
              hide-details
              :loading="Vget_item_load"
              label="Seleccione el Producto"
              id="input4"
              @keyup.enter="next(5, data.idtiposeleccionado)"
              v-model="data.idtiposeleccionado"
            ></v-autocomplete>

            <v-autocomplete
              v-if="data.tipoaplicacion === 'campana'"
              :items="Vget_campana_data_anho"
              item-text="descripcion"
              item-value="idcampana"
              :rules="validar"
              multiple
              autocomplete="off"
              hide-details
              :loading="Vget_campana_load"
              label="Seleccione la Campaña"
              id="input4"
              @keyup.enter="next(5, data.idtiposeleccionado)"
              v-model="data.idtiposeleccionado"
            ></v-autocomplete>

            <v-autocomplete
              v-if="data.tipoaplicacion === 'categoria'"
              :items="Vget_categoria_data"
              item-text="desccategoriaitem"
              item-value="idcategoriaitem"
              :rules="validar"
              autocomplete="off"
              hide-details
              :loading="Vget_categoria_load"
              label="Seleccione la Categoria"
              id="input4"
              @keyup.enter="next(5, data.idtiposeleccionado)"
              v-model="data.idtiposeleccionado"
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" sm="2" v-if="data.tipoaplicacion === 'campana'">
            <v-autocomplete
              :items="Vget_campo_data"
              item-text="campo"
              item-value="idlote_agri"
              multiple
              :rules="validar"
              autocomplete="off"
              hide-details
              label="Seleccione el Lote"
              id="input5"
              @keyup.enter="next(5, data.idtiposeleccionado)"
              v-model="data.lote"
            ></v-autocomplete>
          </v-col>
          <v-spacer></v-spacer>
          <v-btn
            class="mt-4"
            text
            fab
            small
            color="primary"
            id="aceptar"
            outlined
            @click="descargarDatos()"
          >
            <v-icon>cloud_download</v-icon>
          </v-btn>
          <v-btn
            v-if="response.tablas.length > 0"
            class="mt-4 ml-1"
            text
            fab
            small
            color="red"
            outlined
            @click="generarPdf()"
          >
            <v-icon>get_app</v-icon>
          </v-btn>
          <SaveConsulta
            v-if="response.tablas.length > 0"
            class="mt-4"
            :value="consulta"
            :url="obtenerUrl('simple')"
          />
        </v-row>
      </v-card-text>
    </v-form>
    <v-card-text>
      <v-row
        v-if="response.tablas.length > 0"
        dense
        row
        class="hidden-sm-and-down"
      >
        <v-col cols="12" sm="12" v-if="grafico">
          <v-chart :options="option" autoresize />
        </v-col>
      </v-row>
      <div class="d-flex justify-center" v-if="response.cotizacion > 0">
        <h2>Cotizacion de Referencia: {{ response.cotizacion }}</h2>
      </div>
      <TableResumen
        @change="obtenerDatos($event)"
        ref="childResumen"
        mode="completo"
      ></TableResumen>

      <v-row dense v-if="response.tablas.length > 0">
        <v-toolbar flat>
          <v-toolbar-title class="text-center"
            >Resumen de Lotes</v-toolbar-title
          >
          <v-divider vertical class="mx-2"></v-divider>
          <v-spacer></v-spacer>
          <v-btn text color="red" outlined @click="generarPdfLote()">
            <v-icon>get_app</v-icon>
          </v-btn>
        </v-toolbar>
        <v-col cols="12" sm="12">
          <DataTable ref="dataTable" :items="response.tablas" />
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { focus } from "@/services/focus.service";
import graficoindice from "@/views/Agro/Reporte/GraficoIndice";
import { DarkModeService } from "@/services/auth/storage.service";
import exportAvanzado, { exportResumenLote } from "@/views/Agro/Reporte/Export";
import TableResumen from "./TableResumen";
import DataTable from "./DataTable";
import tipoAplicacion from "../constant";
import ECharts from "vue-echarts/components/ECharts";
import { db } from "@/services/db.service";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { PUEDE_GENERAR_REPORTE } from "@/constants/permissions";
import { REPORTE_AGRICULTURA } from "@/constants/forms";
import SaveConsulta from "./SaveConsulta";
export default {
  components: {
    "v-chart": ECharts,
    DataTable,
    TableResumen,
    SaveConsulta,
  },
  data() {
    return {
      searchitem: "",
      validar: [(v) => !!v || "Seleccion un campo"],
      resumen: [],
      lote: [],
      consulta: {
        iddashboard: 0,
        descripcion: "",
        vista: {
          general: false,
          hectarea: false,
          gasto: false,
          zona: false,
          top: false,
          servicio: false,
        },
        url: "",
        tipo: "Agro",
      },

      tipoAplicacion: tipoAplicacion,
      lparam: [],
      data: {
        lote: [],
        lparam: [],
        tiporeporte: "",
        tipoaplicacion: "",
        idtiposeleccionado: "",
        anho_inicio: new Date().getFullYear(),
        anho_fin: new Date().getFullYear(),
      },
      response: {
        tablas: [],
        general: [],
      },
      grafico: false,
      option: {
        options: {
          legend: {
            textStyle: {
              color: "#000"
            }
          }
        }
      },
      permission: {
        can_view: false,
      },
      awaitingSearch: false,
    };
  },
  created() {
    this.permission.can_view = getPermission(REPORTE_AGRICULTURA).permiso[
      PUEDE_GENERAR_REPORTE
    ];
  },
  watch: {
    darkMode() {
      this.changeDarkMode();
    },
    searchitem(val) {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.obtain_item(val);
          this.awaitingSearch = false;
        }, 1000)
      }
      this.awaitingSearch = true;
    },
    searchItemId() {
      this.search();
    },
    $route(to) {
      if (to.path === "/agricultura/report") {
        if (to.query.url) {
          this.obtenerDesdeDashboard();
        } else {
          this.descargarDatos()
        }
      }
    },
  },
  mounted() {
    if (this.$route.query.url) this.obtenerDesdeDashboard();
    this.$vuetify.theme.dark = DarkModeService.getDarkMode();
    this.Vact_campo_data('yes');
    this.getFirstYear();
    setTimeout(() => (this.grafico = true), 200);
  },
  computed: {
    darkMode() {
      return this.$vuetify.theme.dark;
    },
    ...mapGetters("campo", ["Vget_campo_data", "Vget_campo_load"]),
    ...mapGetters("categoria", ["Vget_categoria_data", "Vget_categoria_load"]),
    ...mapGetters("lotegrupo", ["Vget_lotegrupo_data", "Vget_lotegrupo_load"]),
    ...mapGetters("campana", ["Vget_campana_data_anho", "Vget_campana_load"]),
    ...mapGetters("item", ["Vget_item_agrostock", "Vget_item_load"]),
  },
  methods: {
    ...mapActions("campo", ["Vact_campo_data"]),
    ...mapActions("lotegrupo", ["Vact_lotegrupo_data"]),
    ...mapActions("campana", ["Vact_campana_data_anho"]),
    ...mapActions("categoria", ["Vact_categoria_data"]),
    ...mapActions("item", ["Vact_item_agrostock"]),
    changeDarkMode() {
      this.option.baseOption.legend.textStyle.color = this.darkMode ? "#ccc" : "#000";
      this.option.baseOption.xAxis[0].axisLabel.textStyle.color = this.darkMode ? "#ccc" : "#000";
      this.option.baseOption.yAxis[0].axisLabel.textStyle.color = this.darkMode ? "#ccc" : "#000";
      this.option.baseOption.timeline.label.textStyle.color = this.darkMode ? "#ccc" : "#000"
    },
    obtain_item(val) {
      if (this.searchitem != null && this.searchitem.length >= 3) {
        this.Vact_item_agrostock({
          search: val
        });
      }
    },
    next(id, data) {
      focus.nextid(id, data);
    },
    async getFirstYear() {
      try {
        const response = await db.get("/apifechainicio/");
        this.data.anho_inicio = response;
      } catch (error) {
        this.data.anho_inicio = new Date().getFullYear();
      }
    },
    obtenerDesdeDashboard() {
      const parametros = this.$route.query.url.split("/");
      this.data.tipoaplicacion = parametros[2];
      this.data.anho_inicio = parametros[3];
      this.data.anho_fin = parametros[4];

      if (this.data.tipoaplicacion === "campana") {
        this.data.idtiposeleccionado = parametros[5]
          .split("-")
          .map((x) => Number(x));
      } else {
        this.data.idtiposeleccionado = Number(parametros[5]);
      }
      const url = this.obtenerUrl("completo");
      this.$refs.childResumen.ejecutarConsulta(url);
      this.changeTipoAplicacion(this.data.tipoaplicacion);
    },
    currencyFormat(value) {
      return new Intl.NumberFormat({ maximumSignificantDigits: 3 }).format(
        value
      );
    },
    changeTipoAplicacion(tipo) {
      this.data.idtiposeleccionado = []
      switch (tipo) {
        case "campana": {
          const anhoInicio = this.data.anho_inicio;
          const anhoFin = this.data.anho_fin;
          return this.Vact_campana_data_anho({ anhoInicio, anhoFin });
        }
        case "campo": {
          return this.Vact_campo_data();
        }
        case "siembra": {
          return
        }
        case "lote": {
          return this.Vact_lotegrupo_data();
        }
        case "categoria": {
          return this.Vact_categoria_data();
        }
      }
    },

    getGastoBody(index) {
      const tabla = this.response.tablas[index];
      return [
        [
          tabla.descripcion,
          this.currencyFormat(tabla.hectarea),
          this.currencyFormat(tabla.plantio),
          this.currencyFormat(tabla.fumigacion),
          this.currencyFormat(tabla.abono_aplicacion),
          this.currencyFormat(tabla.servicio),
          this.currencyFormat(tabla.pago),
          this.currencyFormat(tabla.alquiler),
          this.currencyFormat(tabla.egresohectarea),
          this.currencyFormat(tabla.totalgasto),
          this.currencyFormat(tabla.diferencia),
          this.currencyFormat(tabla.ingresohectarea),
          this.currencyFormat(tabla.kghectarea),
          this.currencyFormat(tabla.produccionen),
          this.currencyFormat(tabla.produccionhu),
          this.currencyFormat(tabla.produccion),
          this.currencyFormat(tabla.kghectareaen),
          this.currencyFormat(tabla.kghectareahu),
        ],
      ];
    },
    generarPdfLote() {
      let data = {
        gasto_head: this.$refs.dataTable.getGastoHeader(),
        gasto_body: [],
      };

      this.response.tablas.map((tabla, i) => {
        data.gasto_body.push(this.getGastoBody(i)[0]);
      });
      const title = `Resumen de Lotes`;
      const fecha = this.response.fecha;

      exportResumenLote(title, fecha, data, this.response.cotizacion);
    },
    generarPdf() {
      let reporteEspecifico = [];
      this.response.tablas.map((tabla, i) => {
        reporteEspecifico.push({
          gasto_head: this.$refs.dataTable.getGastoHeader(),
          gasto_body: this.getGastoBody(i),
          ingreso_head: this.$refs.dataTable.getIngresoHeader(),
          ingreso_body: tabla.det.map((ingreso) => {
            return [
              ingreso.descripcion,
              this.currencyFormat(ingreso.hectarea),
              this.currencyFormat(ingreso.kghectarea),
              this.currencyFormat(ingreso.precio),
              this.currencyFormat(ingreso.preciohectarea),
              this.currencyFormat(ingreso.valor_alquiler),
              this.currencyFormat(ingreso.valor),
              this.currencyFormat(ingreso.valor_vendido),
              this.currencyFormat(ingreso.total),
            ];
          }),
          lista_head: this.$refs.dataTable.getListaAplicacionHeader(),
          lista_body: tabla.datos.map((lista) => {
            return [
              lista.fecha,
              lista.descripcion,
              this.currencyFormat(lista.subtotal),
            ];
          }),
        });
      });

      const title = `Reporte Agrotech - Rango ${this.data.anho_inicio}/${this.data.anho_fin}`;
      const fecha = this.response.fecha;

      exportAvanzado(
        title,
        fecha,
        reporteEspecifico,
        this.resumen,
        this.response.cotizacion
      );
    },

    obtenerUrl(tipo) {
      let params = ""
      let api = "apireportedemo";
      let selected = this.data.idtiposeleccionado;
      if (this.data.tipoaplicacion === "campana") {
        if (typeof this.data.idtiposeleccionado == "object") {
          selected = this.data.idtiposeleccionado.reduce((acc, curr) => {
            if (acc === "") return (acc = curr);
            return (acc = acc + "-" + curr);
          }, "");
          this.data.idtiposeleccionado.forEach((camp) => {
            const campana = this.Vget_campana_data_anho.filter(
              (x) => x.idcampana === camp
            );
            if (campana[0])
              if (campana[0].parcela) return (api = "apireportedemoparcela");
          });
        }
        if (this.data.lote.length != 0) {
          for (let i = 0; i < this.data.lote.length; i++) {
            if (i === 0) {
              params += `?lote=${this.data.lote[i]}`
            } else {
              params += `+${this.data.lote[i]}`
            }
          }
        }
      }
      const tipoaplicacion = this.data.tipoaplicacion;
      const anho_i = this.data.anho_inicio;
      const anho_f = this.data.anho_fin;
      const idselected = selected;
      return `/${api}/${tipoaplicacion}/${anho_i}/${anho_f}/${idselected}/${tipo}/${params}`;
    },
    obtenerDatos(val) {
      this.response = JSON.parse(JSON.stringify(val.data));
      this.generarGrafico(this.response.grafica);
      this.resumen = JSON.parse(
        JSON.stringify(this.$refs.childResumen.resumen)
      );
    },
    async descargarDatos() {
      if (!this.$refs.form.validate()) return null;
      this.response = JSON.parse(
        JSON.stringify({
          tablas: [],
          general: [],
        })
      );
      const url = this.obtenerUrl("completo");
      this.$refs.childResumen.ejecutarConsulta(url);
    },
    generarGrafico(data) {
      const option = graficoindice(data, this.darkMode);
      this.option = JSON.parse(JSON.stringify(option));
    },
    customFilter(item, queryText) {
      const textOne = item.iditem.descitem.toLowerCase();
      const textTwo = item.iditem.iditem.toString();
      const searchText = queryText.toLowerCase();
      return (
        textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1
      );
    },
    search() {
      this.Vget_item_agrostock().forEach((x) => {
        if (x.iditem.iditem === this.searchItemId) {
          return
        }
      })
    },
  },
};
</script>
<style scoped>
.chart-wrapper {
  width: 100%;
  height: 65vh;
}
.echarts {
  width: 100%;
  height: 65vh;
}
</style>
