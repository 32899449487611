<template>
  <div>
    <v-autocomplete
      :value="value"
      @input="$emit('input', $event)"
      ref="input"
      :items="items"
      :loading="Vget_campo_load"
      item-value="idlote_agri"
      :search-input.sync="syncInput"
      :rules="rules"
      :id="id"
      return-object
      :hide-details="hideDetails"
      item-text="campo"
      :outlined="outlined"
      :dense="dense"
      :disabled="disabled"
      :readonly="readonly"
      :chips="chips"
      :small-chips="smallChips"
      @change="$emit('change')"
      :label="label"
    >
      <template v-slot:no-data>
        <v-list-item @click="crud.add = true">
          <v-list-item-content>
            <v-list-item-title>No existe concidencia</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import autocompleteProps from "@/services/mixins/autocomplete.mixin";

export default {
  mixins: [autocompleteProps],
  props: {
    rules: {
      type: Array,
      default: () => {
        return [(v) => !!v.idlote_agri || "Este campo es obligatorio"];
      },
    },
    items: {
      type: Array,
      default: function(){
        return this.Vget_campo_data
      }
    },
  },
  data: () => ({
    syncInput: null,
    crud: {
      add: false,
    },
  }),
  mounted() {
    this.Vact_campo_data();
  },
  computed: {
    ...mapGetters("campo", ["Vget_campo_load", "Vget_campo_data"]),
  },
  methods: {
    ...mapActions("campo", ["Vact_campo_data"]),
    focus() {
      this.$refs.input.focus();
    },
    isMenuActive() {
      this.$refs.input.isMenuActive = false;
    },
  },
};
</script>

<style></style>
