<template>
  <div>
    <Item v-if="formularios.item" v-model="formularios.item" />
    <v-dialog
      :value="value"
      max-width="600px"
      persistent
      @keydown.esc="cancelar()"
    >
      <v-card>
        <v-form ref="form" lazy-validation @submit.prevent="guardar()">
          <v-card-title>
            <span>{{
              operacion ? "Agregar Tipo Gimnasio" : "Editar Tipo Gimnasio"
            }}</span>
          </v-card-title>
          <v-card-text>
            <v-row row dense>
              <vue-text-field
                v-model="data.descripcion"
                label="Descripcion"
                id="input1"
                @keyup.native.enter="next(1, data.descripcion)"
              ></vue-text-field>
            </v-row>
            <v-row row dense>
              <vue-autocomplete-item-stock2
                :idmoneda="4"
                autocomplete="off"
                id="input2"
                @change="asignarDeposito()"
                ref="ventas5"
                :rules="true"
                v-model="data.idstock"
                @create="formularios.item = $event"
                @keyup.native.enter="next(2, data.idstock)"
                @sync-input="search = $event"
              ></vue-autocomplete-item-stock2>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="red" text @click="cancelar">
              <v-icon dark>close</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn id="input3" color="blue darken-1" text @click="guardar">
              <v-icon dark>done</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
        <c-overlay :value="Vget_gymtipo_save_load"></c-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { mensaje } from "@/services/notify.service";
import { focus } from "@/services/focus.service";
import Item from "@/views/Item/Item/Create"; 
export default {
  components: {
    Item
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    "sync-input": {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      formularios: {
        item: false,
      },
      data: {
        idgymtipo: 0,
        idstock: {
          idstock: "",
        },
        iddeposito: "0",
        descripcion: "",
        estado: true,
        iddedosido: 0
      },
      default: {
        idgymtipo: 0,
        idstock: {
          idstock: "",
        },
        descripcion: "",
        estado: true,
      },
      operacion: true,
    };
  },
  watch: {
    $route() {
      return this.cancelar();
    },
  },
  computed: {
    ...mapGetters("gymtipo", ["Vget_gymtipo_update", "Vget_gymtipo_save_load"]),
  },
  created() {
    this.nuevo(this.Vget_gymtipo_update);
  },
  mounted() {
    if (this.syncInput != null) this.data.descripcion = this.syncInput;
    setTimeout(() => document.getElementById("input1").focus(), 500);
  },
  methods: {
    ...mapActions("gymtipo", [
      "gymtipo_update",
      "Vact_gymtipo_save",
      "Vact_gymtipo_update",
      "Vact_gymtipo_data",
    ]),
    next(index, data) {
      focus.nextidName("input", index, data);
    },
    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.gymtipo_update(null);
      this.Vact_gymtipo_data();
      this.operacion = true;
      this.$emit("input", false);
    },
    async guardar_finalizar() {
      const sync = this.syncInput;
      let a = await this.Vact_gymtipo_save(this.data);
      if (a.estado == true) {
        if (sync != null) {
          this.$emit("sync-input", a.data.data.id);
          return this.cancelar();
        }
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$notify(a.info);
        this.$refs.form.resetValidation();
        setTimeout(() => document.getElementById("input1").focus(), 500);
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
    },
    async editar_finalizar() {
      const id = this.Vget_gymtipo_update.idgymtipo;
      let a = await this.Vact_gymtipo_update({ id, data: this.data });
      if (a.estado == true) {
        this.$notify(a.info);
        this.cancelar();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
    },
    guardar() {
      if (!this.$refs.form.validate()) return this.$notify(mensaje.completar());
      if (this.operacion === true) return this.guardar_finalizar();
      if (this.operacion === false) return this.editar_finalizar();
    },

    nuevo(val) {
      if (val != null) {
        this.data = JSON.parse(JSON.stringify(val));
        this.operacion = false;
      }
    },
    asignarDeposito() {
      this.data.iddeposito = this.data.idstock.iddeposito;
      this.data.iditem = this.data.idstock.iditem;
    }
  },
};
</script>