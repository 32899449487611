<template>
  <div>
    <div v-if="dialogLote">
      <Agro-Lotegrupo-Create
        v-model="dialogLote"
        :input="searchLote"
        @resp="data.idlote_grupo = $event"
      />
    </div>
    <v-card-text>
      <v-form ref="form">
        <v-row dense>
          <v-col cols="12">
            <v-autocomplete
              v-model="data.idlote_grupo.idlote_grupo"
              :items="Vget_lotegrupo_data"
              :loading="Vget_lotegrupo_load"
              dense
              :search-input.sync="searchLote"
              item-text="descripcion"
              item-value="idlote_grupo"
              label="Seleccione el Lote al que pertenece"
              outlined
              autocomplete="off"
            >
              <template v-if="permissionLote" v-slot:no-data>
                <v-list-item @click="crearLote()">
                  <v-list-item-content>
                    <v-list-item-title
                      >No existe concidencia. Click para
                      agregar</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              id="loteInput20"
              ref="loteInput20"
              v-model="data.idloteloc.idlotedis.idlotedep"
              :items="getLotedep"
              :loading="isLoadingLotedep"
              dense
              item-text="desclotedep"
              return-object
              label="Departamento"
              outlined
              @change="changeDep()"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              id="loteInput21"
              ref="loteInput21"
              v-model="data.idloteloc.idlotedis"
              :items="getLotedis"
              :loading="isLoadingLotedis"
              dense
              item-text="desclotedis"
              return-object
              label="Distrito"
              outlined
              @change="changeDis()"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              id="loteInput22"
              ref="loteInput22"
              v-model="data.idloteloc"
              :items="getLoteloc"
              :loading="isLoadingLoteloc"
              dense
              item-text="descloteloc"
              return-object
              label="Localidad"
              outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <c-text-currency
              label="Nro Lote"
              dense
              outlined
              v-model="data.nrolote_agri"
            ></c-text-currency>
          </v-col>
          <v-col cols="12">
            <vue-text-field
              label="Nombre del Campo"
              v-model="data.campo"
            ></vue-text-field>
          </v-col>
          <v-col cols="12">
            <vue-text-field
              :rules="false"
              label="Hectareas"
              v-model="data.hectarea"
            ></vue-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="error" small outlined to="/agricultura/campo"
        >Cancelar</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn color="primary" small outlined @click="guardar">Editar</v-btn>
    </v-card-actions>
    <c-overlay v-model="Vget_campo_save_load"></c-overlay>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { mensaje } from "@/services/notify.service";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { ZAFRA } from "@/constants/forms";
import { PUEDE_AGREGAR } from "@/constants/permissions";
export default {
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
  },
  mounted() {
    this.Vact_campo_data_by_id(this.$route.params.id);
    this.Vact_lotegrupo_data();
    this.fetchLotedep();
  },
  computed: {
    ...mapGetters("agromap", ["Vget_agromap_coordinates"]),
    ...mapGetters("lotegrupo", ["Vget_lotegrupo_data", "Vget_lotegrupo_load"]),
    ...mapGetters("campo", ["Vget_campo_save_load", "Vget_campo_geo_json"]),
    ...mapGetters("lote", [
      "getLotedep",
      "isLoadingLotedep",
      "getLotedis",
      "isLoadingLotedis",
      "getLoteloc",
      "isLoadingLoteloc",
    ]),
    permissionLote() {
      return getPermission(ZAFRA).permiso[PUEDE_AGREGAR];
    },
  },
  watch: {
    Vget_agromap_coordinates(val) {
      if (val != null) {
        let ndata = JSON.parse(JSON.stringify(val));
        if (ndata.idloteloc != null) {
          this.fetchLotedis(ndata.idloteloc.idlotedis.idlotedep.idlotedep);
          this.fetchLoteloc(ndata.idloteloc.idlotedis.idlotedis);
        }
        this.data = ndata;
        if (this.data.idloteloc == null) {
          this.data.idloteloc = this.rec
        }
      }
    },
    Vget_campo_geo_json(val) {
      if (val.id === undefined) {
        this.data.coordenadas = JSON.parse(
          JSON.stringify(val.geometry.coordinates)
        );
        this.data.hectarea = JSON.parse(JSON.stringify(val.geometry.hectarea));
      }
    },
  },
  methods: {
    ...mapActions("campo", [
      "Vact_campo_update",
      "Vact_campo_data_by_id",
      "Vact_campo_data",
    ]),
    ...mapActions("lotegrupo", ["Vact_lotegrupo_data"]),
    ...mapActions("lote", ["fetchLotedep", "fetchLotedis", "fetchLoteloc"]),
    crearLote() {
      this.dialogLote = true;
    },
    changeDep() {
      this.fetchLotedis(this.data.idloteloc.idlotedis.idlotedep.idlotedep);
    },
    changeDis() {
      this.fetchLoteloc(this.data.idloteloc.idlotedis.idlotedis);
    },
    async guardar() {
      const id = this.$route.params.id;
      const a = await this.Vact_campo_update({ id, data: this.data });
      if (a.estado == true) {
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$notify(a.info);
        this.$refs.form.resetValidation();
        this.Vact_campo_data();
        this.Vact_lotegrupo_data();
        this.$router.push({
          path: "/agricultura/campo/detalle/" + id,
          query: {
            polygon: this.$route.query.polygon,
          },
        });
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
    },
  },
  data() {
    return {
      isActivemap: false,
      searchLote: "",
      dialogLote: false,
      rec: {
        idloteloc: "",
        idlotedis: {
          idlotedis: "",
          idlotedep: {
            idlotedep: "",
          },
        },
      },
      data: {
        idlote_grupo: {
          idlote_grupo: "",
        },
        idloteloc: {
          idloteloc: "",
          idlotedis: {
            idlotedis: "",
            idlotedep: {
              idlotedep: "",
            },
          },
        },
        idlote_agri: 0,
        nrolote_agri: "",
        estado: true,
        descripcion: "",
        typelote_agri: "Polygon",
        hectarea: 0,
        campo: "",
        coordenadas: [],
      },
      default: {
        idlote_agri: 0,
        idlote_grupo: {
          idlote_grupo: "",
        },
        idloteloc: {
          idloteloc: "",
          idlotedis: {
            idlotedis: "",
            idlotedep: {
              idlotedep: "",
            },
          },
        },
        nrolote_agri: "",
        estado: true,
        descripcion: "",
        typelote_agri: "Polygon",
        hectarea: 0,
        campo: "",
        coordenadas: [],
      },
    };
  },
};
</script>

