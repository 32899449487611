import { db } from "@/services/db.service";
export default {
    async densidad_update({ commit }, data) {
        commit('densidad_update', data);
    },
    async Vact_densidad_save({ commit }, data) {
        commit('densidad_save_load', true);
        try {
            const resp = await db.save('/apidensidad/', data);
            commit('densidad_save_load', false);
            return resp;
        } catch (e) {
            commit('densidad_save_load', false);
            return false;
        }
    },
    async Vact_densidad_update({commit},{id, data}){
        commit('densidad_save_load',true);
        try {
            const resp = await db.update('/apidensidad/'+id+'/',data);
            commit('densidad_save_load',false);
            return resp;
        } catch (e) {
            commit('densidad_save_load',false);
            return e
        } 
    },
    async Vact_densidad_data({ commit }) {
        commit('densidad_load', true);
        try {
            const data = await db.get('/apidensidad/');
            commit('densidad_data', data);
            commit('densidad_load', false);
            return true
        } catch (e) {
            commit('densidad_load', false);
            return false
        }
    },

    async Vact_densidad_del({ dispatch }, id) {
        try {
            const data = await db.delete('/apidensidad/' + id + '/');
            dispatch('Vact_densidad_data');
            return data
        } catch (e) {
            return false
        }
    },
}
