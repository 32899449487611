<template>
  <div>
    <div v-if="crud.add">
      <Agro-Campana-Create v-model="crud.add" :datos="crud.datos" @datos="crud.datos = $event" />
    </div>
    <div v-if="crud.block">
      <Agro-Campana-Cierre v-model="crud.block" :datos="crud.datos" @datos="crud.datos = $event"
        :operacion="crud.operacion" />
    </div>
    <v-alert type="error" v-if="permission_alert">Error!!! Este usuario no tiene permisos en esta ventana</v-alert>
    <v-card>
      <v-toolbar flat dense>
        <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
        <widgets-Favorite :ruta="$route.path" :formulario="$route.name" />
        <v-divider class="mx-2" inset vertical></v-divider>

        <v-spacer></v-spacer>
        <c-text-table-search v-if="permission.can_view" v-model="search"></c-text-table-search>
        <v-toolbar-items>
          <c-btn-table-add class="mr-1" @click="crud.add = true" v-if="permission.can_add"></c-btn-table-add>
          <c-btn-table-reload @click="Vact_campana_data()"></c-btn-table-reload>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table :loading="Vget_campana_load" :headers="headers" :items="Vget_campana_data" :search="search"
        v-if="permission.can_view">
        <template v-slot:[`item.estado`]="{ item }">
          <v-btn color="red" outlined small text :disabled="!permission.can_update" @click="cerrarCampana(item)"
            v-if="item.estado">
            CERRAR
            <v-icon small class="ml-1" color="red">lock</v-icon>
          </v-btn>

          <v-btn class="px-5" color="primary" outlined small text v-else :disabled="!permission.can_update"
            @click="abrirCampana(item)">
            ABRIR
            <v-icon small class="ml-1" color="primary">lock_open</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.accion`]="{ item }" v-if="permission.can_view">
          <c-btn-table-edit @click="editar(item)" v-if="permission.can_update"></c-btn-table-edit>
          <c-btn-table-delete @click="eliminar(item)" v-if="permission.can_delete"></c-btn-table-delete>

        </template>

      </v-data-table>
    </v-card>
    <v-dialog v-model="crud.delete" persistent max-width="400">
      <v-card>
        <v-card-title>
          <v-icon left>info</v-icon>
          <span class="subtitle-1 font-weight-light">Ventana de Confirmacion</span>
        </v-card-title>
        <v-form ref="form" @submit.prevent="eliminarLote()">
          <v-card-subtitle>Desea eliminar la Campaña
            {{ objeto.descripcion }}?</v-card-subtitle>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text="text" @click="crud.delete = false">
            <v-icon>clear</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text="text" @click="eliminarLote()">
            <v-icon>done</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
      <c-overlay :value="overlay" absolute></c-overlay>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { ZAFRA } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";
export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "Codigo", align: "left", value: "idcampana" },
        { text: "Descripcion", align: "left", value: "descripcion" },
        { text: "Estado", align: "right", value: "estado" },
        { text: "Accion", value: "accion", sortable: false, align: "right" },
      ],
      crud: {
        add: false,
        delete: false,
        block: false,
        operacion: true,
        datos: null,
      },
      overlay: false,
      objeto: {
        descripcion: "",
      },
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
    };
  },
  watch: {},
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.Vact_campana_data();
    const permission = getPermission(ZAFRA).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  computed: {
    ...mapGetters("campana", ["Vget_campana_data", "Vget_campana_load"]),

    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },
  methods: {
    ...mapActions("campana", [
      "Vact_campana_data",
      "Vact_campana_del",
      "campana_update",
    ]),
    editar(item) {
      this.crud.datos = JSON.parse(JSON.stringify(item));
      this.crud.add = true;
    },
    eliminar(item) {
      this.crud.delete = true;
      this.objeto = item;
    },
    eliminarCancelar() {
      this.objeto.descripcion = "";
      this.crud.delete = false;
    },
    cerrarCampana(item) {
      this.crud.datos = JSON.parse(JSON.stringify(item));
      this.crud.operacion = true;
      this.crud.block = true;
    },
    async abrirCampana(item) {
      this.crud.datos = JSON.parse(JSON.stringify(item));
      this.crud.operacion = false;
      this.crud.block = true;
    },
    async eliminarLote() {
      this.overlay = true;
      const id = this.objeto.idcampana;
      const a = await this.Vact_campana_del(id);
      if (a.estado == true) {
        this.$notify(a.info);
        this.eliminarCancelar();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.overlay = false;
    },
  },
};
</script>