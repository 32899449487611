import { get, del, put, post } from "@/services/api/api.service";
import {
  SET_LOADING,
  SET_TRANSFERENCIA,
  url,
  SET_TRANSFERENCIA_BYID,
} from "./constants";
export default {
  async Vact_transferencia_data({ commit, dispatch }) {
    commit(SET_LOADING, true);
    try {
      let response = [];
      response = await get(`${url.transferencia}`);
      commit(SET_TRANSFERENCIA, null);
      commit(SET_TRANSFERENCIA, response);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(SET_LOADING, false);
      throw e;
    }
    commit(SET_LOADING, false);
  },
  async Vact_transferencia_del({ commit, dispatch }, id) {
    commit(SET_LOADING, true);
    const response = await del(url.transferencia + "" + id);
    dispatch("Vact_transferencia_data");
    dispatch("snackbar/setNotification", response, { root: true });
    commit(SET_LOADING, false);
    return response;
  },

  async Vact_transferencia_save({ commit, dispatch }, data) {
    commit(SET_LOADING, true);
    const response = await post(url.transferencia, data);
    dispatch("snackbar/setNotification", response, { root: true });
    commit(SET_LOADING, false);
    return response;
  },
  async Vact_transferencia_update({ commit, dispatch }, { data, id }) {
    commit(SET_LOADING, true);
    const response = await put(`${url.transferencia}${id}/`, data);
    dispatch("snackbar/setNotification", response, { root: true });
    commit(SET_LOADING, false);
    return response;
  },
  async Vact_transferencia_byId({ commit, dispatch }, id) {
    commit(SET_LOADING, true);
    try {
      const data = await get(`${url.transferencia}${id}/`);
      commit(SET_TRANSFERENCIA_BYID, data);
      commit(SET_LOADING, false);
    } catch (e) {
      dispatch("snackbar/setNotification", e, { root: true });
      commit(SET_LOADING, false);
      throw e;
    }
  },
};
