export default {
  item_update: (state) => {
    return state.item_update;
  },
  item_show: (state) => {
    return state.item_show;
  },
  Vget_item_ensilaje: (state) => state.item_ensilaje,
  Vget_item_data: (state) => {
    return state.item_data;
  },
  Vget_item_senave: (state) => {
    return state.item_senave;
  },
  Vget_item_lacteo: (state) => {
    return state.item_lacteo;
  },
  Vget_item_stockeable: (state) => {
    return state.item_stockeable;
  },
  Vget_item_vivo: (state) => {
    return state.item_vivo;
  },
  Vget_item_stock2: (state) => {
    return state.item_stock2;
  },
  Vget_item_stock_offline: (state) => {
    return state.item_stock_offline;
  },
  Vget_item_agrostock: (state) => {
    return state.item_agrostock;
  },
  Vget_item_agrostock_todos: (state) => {
    return state.item_agrostock_todos;
  },
  Vget_tipoitem_data: (state) => {
    return state.item_tipoitem_data;
  },
  Vget_item_siembra: (state) => {
    return state.item_siembra;
  },
  Vget_item_siembra_default: (state) => {
    return state.item_siembra_default;
  },
  Vget_item_medicina: (state) => {
    return state.item_medicina;
  },
  Vget_item_load: (state) => {
    return state.item_load;
  },
  Vget_item_senave_load: (state) => {
    return state.item_senave_load;
  },
  Vget_item_load_save: (state) => {
    return state.item_load_save;
  },
};
