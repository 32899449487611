
export default {


  preventaagro_update: (state) => {
    return state.preventaagro_update
  },
  Vget_preventaagro_data: (state) => {
    return state.preventaagro_data
  },
  Vget_preventaagro_data_byId: (state) => {
    return state.preventaagro_data_byId
  },
  Vget_preventaagro_load: (state) => {
    return state.preventaagro_load
  },
  Vget_preventaagro_save_load: (state) => {
    return state.preventaagro_save_load
  },
}
