import { db } from "@/services/db.service";
export default {


    async Vact_establecimiento_data({commit}){
        commit('establecimiento_load',true);
        try {
            const data = await db.get('/apiestablecimiento/');
            commit('establecimiento_data',data);
            commit('establecimiento_load',false);
            return true
        } catch (e) {
            commit('establecimiento_load',false);
            return false
        } 
    },
    async Vact_establecimiento_del({dispatch},id){
        try {
            const data = await db.delete('/apiestablecimiento/'+id);
            dispatch('Vact_establecimiento_data');
            return data
        } catch (e) {
            return false
        } 
    },
    async Vact_establecimiento_save({commit},data){
        commit('establecimiento_save_load',true);
        try {
            const resp = await db.save('/apiestablecimiento/',data);
            commit('establecimiento_save_load',false);
            return resp;
        } catch (e) {
            commit('establecimiento_save_load',false);
            return e
        } 
    },
    async Vact_establecimiento_update({commit},{id, data}){
        commit('establecimiento_save_load',true);
        try {
            const resp = await db.update('/apiestablecimiento/'+id+'/',data);
            commit('establecimiento_save_load',false);
            return resp;
        } catch (e) {
            commit('establecimiento_save_load',false);
            return e
        } 
    },
}


