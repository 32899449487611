import { db } from "@/services/db.service";
export default {
    async ajuste_update({commit},request){
        commit('ajuste_update',request);
    },
    async Vact_ajuste_data({commit}){
        commit('ajuste_load',true);
        try {
            const data = await db.get('/apiajuste/');
            commit('ajuste_data',data);
            commit('ajuste_load',false);
            return true
        } catch (e) {
            commit('ajuste_load',false);
            return false
        } 
    },
    async Vact_ajuste_save({commit},data){
        commit('ajuste_save_load',true);
        try {
            const resp = await db.save('/apiajuste/',data);
            commit('ajuste_save_load',false);
            return resp;
        } catch (e) {
            commit('ajuste_save_load',false);
            return e
        } 
    }
}
