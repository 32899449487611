import { db } from "@/services/db.service";
export default {
    async Vact_pasarelapago_data({commit}){
        commit('pasarelapago_load',true);
        try {
            const data = await db.get('/apipasarelapago/');
            commit('pasarelapago_data',data);
            commit('pasarelapago_load',false);
            return true
        } catch (e) {
            commit('pasarelapago_load',false);
            return false
        } 
    }
}
