
export default {

  Vget_sync_after: (state) => {
    return state.sync_after
  },
  Vget_load_sync: (state) => {
    return state.load_sync
  },
}
