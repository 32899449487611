<template>
  <v-dialog :value="value" persistent max-width="900">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>Detalle de Preventa</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form">
          <v-row dense>
            <v-col cols="6" md="2" sm="3">
              <c-text-date label="Fecha" v-model="data.fecha" :readonly="true"></c-text-date>
            </v-col>
            <v-col cols="6" md="2" sm="3">
              <c-text-date label="Fecha de Entrega" id="input1" readonly v-model="data.fecha_entrega"></c-text-date>
            </v-col>

            <v-col cols="0" md="6" sm="4"></v-col>
            <v-col cols="12" md="2" sm="2">
              <v-checkbox v-model="presupuesto" readonly label="Presupuesto" class="mt-1"></v-checkbox>
            </v-col>
            <v-col cols="12" md="8" sm="8">
              <vue-text-field label="Cliente" readonly v-model="data.idcontacto.desccontacto"></vue-text-field>
            </v-col>
            <v-col cols="12" md="4" sm="4" v-if="data.idresp != null">
              <vue-text-field label="Responsable" readonly v-model="data.idresp.desccontacto"></vue-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <vue-text-field label="Moneda" readonly v-model="data.idmoneda.descmoneda"></vue-text-field>
            </v-col>
          </v-row>
        </v-form>
        <v-form ref="formDet">
          <v-row dense>
            <v-col cols="12" md="12" sm="12">
              <v-data-table hide-default-footer :headers="headers" :items="data.pedidoventadet"></v-data-table>
            </v-col>
            <v-col md="9" sm="9"></v-col>
            <v-col cols="12" md="3" sm="3">
              <vue-text-currency :rules="false" readonly label="Total" :value="total"></vue-text-currency>
            </v-col>
            <v-col cols="12">
              <vue-text-field
                :rules="false"
                readonly
                label="Observacion"
                v-model="data.observacion"
              ></vue-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="red" text @click="cancelar()">
          <v-icon>clear</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="red" text @click="generarPdf()">
          <v-icon>get_app</v-icon>
        </v-btn>
      </v-card-actions>
      <c-overlay :value="Vget_pedidoventa_load"></c-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
import Presupuesto from "@/views/Venta/PedidoVenta/Presupuesto";
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    value: {
      type: Boolean,
      required: true
    },
    editar: {
      type: Object,
      default: null
    },
    idpedido_venta: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      presupuesto: false,
      data: {
        idpedido_venta: 0,
        idcontacto: {
          idcontacto: {
            desccontacto: ""
          }
        },
        idresp: {
          desccontacto: ''
        },
        idmoneda: {
          idmoneda: ""
        },
        fecha: "2000-01-01",
        fecha_entrega: "",
        idestadopedido: {
          idestadopedido: 1
        },
        observacion: "",
        pedidoventadet: []
      },
      default: {
        idpedido_venta: 0,
        idcontacto: {
          idcontacto: ""
        },
        idresp: {
          desccontacto: ''
        },
        idmoneda: {
          idmoneda: ""
        },
        fecha: "2000-01-01",
        fecha_entrega: "",
        idestadopedido: {
          idestadopedido: 1
        },
        observacion: "",
        pedidoventadet: []
      },
      data_det: {
        idstock: {
          iditem: {
            iditem: ""
          },
          iddeposito: {
            iddeposito: ""
          },
          idstock: ""
        },
        iditem: "",
        precio: 0,
        cantidad: 0
      },
      default_det: {
        iditem: "",
        precio: 0,
        cantidad: 0
      },
      operacion: true,
      headers: [
        { text: "#", value: "idstock.iditem.iditem", sortable: false },
        { text: "Item", value: "idstock.iditem.descitem", sortable: false },
        { text: "Precio", value: "precio", sortable: false },
        { text: "Cantidad", value: "cantidad", sortable: false },
        {
          text: "SubTotal",
          value: "subtotal",
          align: "right",
          sortable: false
        }
      ]
    };
  },
  mounted() {
    if (this.idpedido_venta != null) {
      this.Vact_pedidoventa_byId(this.idpedido_venta);
    }
    if (this.editar != null) {
      this.asignarDatos(this.editar);
    }
  },
  watch: {
    $route(to, from) {
      if (from.path === "/pedidoventa") return this.cancelar();
    },
    Vget_pedidoventa_byId(val) {
      if (val != null) {
        this.asignarDatos(val);
      }
    }
  },
  computed: {
    ...mapGetters("pedidoventa", [
      "Vget_pedidoventa_byId",
      "Vget_pedidoventa_load"
    ]),
    total() {
      let total = 0;
      this.data.pedidoventadet.forEach(x => {
        total = total + x.cantidad * x.precio;
      });
      return total;
    }
  },
  methods: {
    ...mapActions("pedidoventa", ["Vact_pedidoventa_byId"]),
    asignarDatos(val) {
      this.data = JSON.parse(JSON.stringify(val));
      if(this.data.idestadopedido.idestadopedido === 3) this.presupuesto = true;
      this.calculoEdicion(val);
    },
    calculoEdicion(val) {
      let data = val.pedidoventadet;
      data.forEach(x => {
        x.subtotal = (Number(x.precio) * Number(x.cantidad)).toFixed(2);
      });
      this.data.pedidoventadet = JSON.parse(JSON.stringify(data));
    },

    generarPdf() {
      Presupuesto(this.data, this.headers);
    },

    cancelar() {
      this.$emit("input", false);
      this.$emit("editar", null);
      this.limpiarCampos();
    },

    limpiarCampos() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.data_det = JSON.parse(JSON.stringify(this.default_det));
    }
  }
};
</script>

<style>
</style>