// https://vuex.vuejs.org/en/getters.html

export default {
  getHaSembrable: (state) => state.ha_sembrable,
  Vget_siembra_load: (state) => {
    return state.siembra_load;
  },
  Vget_siembra_data_byId: (state) => {
    return state.siembra_data_byId;
  },
  Vget_siembra_data: (state) => {
    return state.siembra_data;
  },
  Vget_siembra_timeline: (state) => {
    return state.siembra_timeline;
  },
  Vget_siembra_save_load: (state) => {
    return state.siembra_save_load;
  },
};
