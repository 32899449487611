import Vue from 'vue'
import vuetify from './plugins/vuetify';
import './components'
import './views'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import "echarts/lib/chart/bar"
import "echarts/lib/chart/pie"
import "echarts/lib/chart/line"
import "echarts/lib/chart/tree"
import "echarts/lib/chart/pictorialBar"
import "echarts/lib/chart/gauge"
import "echarts/lib/component/timeline"
import "echarts/lib/component/tooltip"
import "echarts/lib/component/legendScroll"
import "echarts/lib/component/markLine"
import "echarts/lib/component/markPoint"
import "echarts/lib/component/toolbox"



import { sync } from 'vuex-router-sync'
import { TokenService } from '@/services/auth/storage.service'
import ApiService from '@/services/auth/api.service'
import Notifications from 'vue-notification'

const ignoredMessage = "The .native modifier for v-on is only valid on components but it was used on <div>.";

Vue.config.warnHandler = (message, vm, componentTrace) => {

    if (message !== ignoredMessage) {
        console.error(message + componentTrace);
    }
};

/* Vue.config.errorHandler = function (err, vm, info)  {
  console.log('[Global Error Handler]: Error in ' + info + ': ' + err);
}; */

Vue.use(Notifications)

//ApiService.init(process.env.VUE_APP_ROOT_API="http://192.168.0.17:8001/api/") 
//ApiService.init(process.env.VUE_APP_ROOT_API="http://localhost:8001/api/")  
ApiService.init(process.env.VUE_APP_ROOT_API="https://server.integral.com.py/api/")   

// Si existe el token se envia la cabecera
if (TokenService.getToken()) {
  ApiService.setHeader()
}

Vue.config.productionTip = false

sync(store, router)

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
