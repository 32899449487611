
export default {
  Vget_emisioncheque_data: (state) => {
    return state.emisioncheque_data
  },
  Vget_emisioncheque_data_pagado: (state) => {
    return state.emisioncheque_data_pagado
  },
  Vget_emisioncheque_load: (state) => {
    return state.emisioncheque_load
  },
  Vget_emisioncheque_save_load: (state) => {
    return state.emisioncheque_save_load
  },
}
