<template>
  <v-dialog persistent max-width="1000" :value="value">
    <v-card>
      <v-toolbar flat dense>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-form ref="form" @submit.prevent="next(1, data.descripcion)">
          <v-row dense>
            <v-col cols="10">
              <c-text-field
                label="Descripcion"
                ref="relojbiometrico1"
                v-model="data.descripcion"
                placeholder="Ingrese una descripcion"
                @keyup.native.enter="next(1, data.descripcion)"
              ></c-text-field>
            </v-col>
            <v-spacer></v-spacer>

            <v-avatar class="mt-4" color="grey lighten-5" size="56">
              <v-icon x-large color="red" v-if="data.poligono.length === 0"
                >clear</v-icon
              >
              <v-icon x-large color="green accent-3" v-else>done</v-icon>
            </v-avatar>
            <v-col cols="12">
              <v-card v-if="isActivemap">
                <GoogleMapLoader
                  :mapConfig="mapConfig"
                  libraries="drawing"
                  class="travel-map"
                >
                  <template slot-scope="{ google, map }">
                    <Map
                      :google="google"
                      :map="map"
                      @coordinates="poligono($event)"
                      :props="data"
                    />
                  </template>
                </GoogleMapLoader> </v-card
            ></v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <c-btn-crud-close @click="cancel()"></c-btn-crud-close>
        <v-spacer></v-spacer>
        <c-btn-crud-done
          ref="relojbiometrico2"
          @click="save()"
        ></c-btn-crud-done>
      </v-card-actions>
      <c-overlay :value="isPoligonoLoading"></c-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GoogleMapLoader from "@/components/map/GoogleMapLoader";
import { mapSettings } from "@/services/constants/mapSettings";
import Map from "./Map";
export default {
  components: {
    GoogleMapLoader,
    Map,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
    },
  },

  mounted() {
    if (this.editable) this.data = JSON.parse(JSON.stringify(this.props));
    setTimeout(() => this.next(0, "-"), 20);
    this.isActivemap = true;
  },
  computed: {
    ...mapGetters("poligonomarcacion", ["isPoligonoLoading"]),
    title() {
      return this.editable
        ? "Modificar Poligono de Marcacion"
        : "Registrar Poligono de Marcacion";
    },
    mapConfig() {
      return {
        ...mapSettings,
        mapTypeId: "hybrid",
        fullscreenControl: false,
        mapTypeControl: false,
        center: { lat: -25.361663, lng: -55.686352 },
      };
    },
  },
  methods: {
    ...mapActions("poligonomarcacion", [
      "setPoligono",
      "fetchPoligono",
      "setPoligonoUpdate",
    ]),
    poligono(e) {
      this.data.poligono = JSON.parse(JSON.stringify(e));
    },
    cancel() {
      this.$emit("input", false);
    },
    next(id, data) {
      if (data != "")
        try {
          this.$refs[`relojbiometrico${id + 1}`].focus();
        } catch (error) {
          this.$refs[`relojbiometrico${id + 1}`].$el.focus();
        }
    },
    async save() {
      if (!this.$refs.form.validate()) return null;
      const response = this.editable
        ? await this.setPoligonoUpdate({
            data: this.data,
            id: this.data.idpoligono,
          })
        : await this.setPoligono(this.data);
      if (response.success) {
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();
        this.fetchPoligono();
        this.next(0, "-");
        if (this.editable) this.cancel();
      }
    },
  },
  data: () => ({
    data: {
      idpoligono: 0,
      descripcion: "",
      poligono: [],
    },
    default: {
      idpoligono: 0,
      descripcion: "",
      poligono: [],
    },
    isActivemap: false,
  }),
};
</script>

<style scoped>
.travel-map {
  position: relative;
  height: 63vh;
  z-index: 1;
}
</style>
