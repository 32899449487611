<template>
  <div>
    <v-row dense class="mx-1">
      <v-col cols="12" sm="8">
        <FuturosCard></FuturosCard>
      </v-col>
      <v-col cols="12" sm="4">
        <SensorCard></SensorCard>
      </v-col>
    </v-row>
    <v-expansion-panels :key="key" class="pa-1">
      <v-expansion-panel v-for="(item, i) of dashboardMenu" :key="i">
        <v-expansion-panel-header class="font-weight-black">{{
          item.descripcion
        }}</v-expansion-panel-header>
        <v-expansion-panel-content class="mx-n3">
          <h5>Parametros de la Consulta</h5>
          <v-row dense>
            <v-col cols="12" md="2">
              <v-text-field
                label="Año Inicio"
                readonly
                hide-details
                v-model="item.anhoInicio"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                label="Año Fin"
                readonly
                hide-details
                v-model="item.anhoFin"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2">
              <v-autocomplete
                :items="tipoAplicacion"
                item-text="descripcion"
                item-value="id"
                autocomplete="off"
                hide-details
                readonly
                label="Filtro"
                id="input3"
                v-model="item.tipoaplicacion"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" md="2">
              <v-text-field
                readonly
                hide-details
                v-model="item.desc"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" v-if="item.desc2 != null">
              <v-text-field
                readonly
                hide-details
                v-model="item.desc2"
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
            <v-btn
              text
              fab
              class="mr-1"
              small
              color="primary"
              outlined
              @click="reloadExpand(i)"
            >
              <v-icon>cloud_download</v-icon>
            </v-btn>
            <v-btn
              text
              fab
              small
              outlined
              color="red"
              @click="settingsReporte(item.url)"
            >
              <v-icon>visibility</v-icon>
            </v-btn>
            <SaveConsulta :value="item" :operacion="false"></SaveConsulta>
          </v-row>
          <TableResumen
            :ref="'refResumen' + i"
            :url="item.url"
            :vista="item.vista"
          ></TableResumen>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-row dense>
      <v-col cols="12" sm="12">
        <mini-statistic/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TableResumen from "./../Agro/Reporte/Avanzado2/TableResumen";
import SaveConsulta from "./../Agro/Reporte/Avanzado2/SaveConsulta";
import tipoAplicacion from "./../Agro/Reporte/constant";
import FuturosCard from "./Agro/Card";
import SensorCard from "./Agro/Sensor";
import MiniStatistic from './Agro/MiniStatistic';
export default {
  components: {
    SensorCard,
    TableResumen,
    SaveConsulta,
    FuturosCard,
    MiniStatistic,
  },
  data()
    {
    return {
      dashboardMenu: [],
      tipoAplicacion: tipoAplicacion,
      key: 1,
      dialog_futuros: false,
      data: {
        anhoInicio: "",
        anhoFin: "",
        tipoaplicacion: "",
        lote: ""
      },
    };
  },
  watch: {
    Vget_dashboard_menu(val) {
      this.key++;
      this.dashboardMenu = JSON.parse(JSON.stringify(val));
      this.dashboardMenu.map((x) => {
        const parametros = x.url.split("/");
        x.anhoInicio = parametros[3];
        x.anhoFin = parametros[4];
        x.tipoaplicacion = parametros[2];
      });
    },
  },
  mounted() {
    this.Vact_dashboard_menu();
  },
  computed: {
    ...mapGetters("dashboard", ["Vget_dashboard_menu"]),
  },
  methods: {
    ...mapActions("dashboard", ["Vact_dashboard_menu"]),
    reloadExpand(i) {
      this.$refs[`refResumen${i}`][0].init();
    },
    settingsReporte(url) {
      this.$router.push({ path: "/agricultura/report", query: { url } });
    },
  },
};
</script>

<style></style>
