<template>
    <div>
        <div v-if="crud.add">
            <Crud v-model="crud.add" :datos="crud.datos" @datos="crud.datos = $event" />
        </div>
        <v-dialog :value="value" max-width="900" persistent :fullscreen="$vuetify.breakpoint.xs" @keydown.esc="cerrar()">
            <v-card>
                <v-toolbar flat dense>
                    <v-toolbar-title>
                        Bonificacion
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <c-text-table-search v-model="search"></c-text-table-search>
                    <v-toolbar-items class="mt-1">
                        <c-btn-table-add @click="createbonificacion()" class="mr-1"></c-btn-table-add>
                        <c-btn-table-reload @click="Vact_bonificacionagro_data()"></c-btn-table-reload>
                    </v-toolbar-items>
                </v-toolbar>
                <v-container>
                    <v-alert type="warning">
                        Atencion, las bonificaciones afectan al costo de las ventas de la fecha de registro y anteriores
                        (Solo correspondiente a la zafra seleccionada).
                    </v-alert>
                </v-container>
                <v-data-table :loading="Vget_bonificacionagro_load" :headers="headers" :items="Vget_bonificacionagro_data"
                    :search="search">
                    <template v-slot:[`item.accion`]="det">
                        <c-btn-table-delete @click="meliminar(det.item)">
                        </c-btn-table-delete>
                    </template>
                </v-data-table>

                <v-container>
                    <v-divider class="mb-2"></v-divider>
                    <v-row dense no-gutters>
                        <v-btn color="red" text @click="cerrar()">
                            <v-icon>clear</v-icon>
                        </v-btn>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>
        <v-dialog v-model="crud.delete" persistent max-width="350">
            <v-card>
                <v-card-title>
                    <v-icon left>info</v-icon>
                    <span class="title font-weight-light">Ventana de Confirmacion</span>
                </v-card-title>
                <v-card-text>Desea eliminar el registro codigo
                    <strong>{{ objeto.idbonificacion_agro }}</strong>?</v-card-text>
                <v-card-actions>
                    <v-btn color="red" text @click="eliminarcancelar">
                        <v-icon>clear</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="meliminarguardar()">
                        <v-icon>done</v-icon>
                    </v-btn>
                </v-card-actions>
                <v-overlay :value="overlay">
                    <v-progress-circular indeterminate size="64"></v-progress-circular>
                </v-overlay>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Crud from "./BonificacionCreate.vue"
import { mensaje } from "@/services/notify.service";
export default {
    components: {
        Crud
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            crud: {
                add: false,
                delete: false,
                datos: null,
            },
            overlay: false,
            objeto: "",
            search: "",
            headers: [
                {
                    text: "Codigo",
                    align: "left",
                    value: "idbonificacion_agro"
                },
                {
                    text: "Fecha",
                    align: "left",
                    value: "fecha"
                },
                {
                    text: "Campaña",
                    align: "left",
                    value: "idcampana.descripcion"
                },
                {
                    text: "Comentario",
                    align: "left",
                    value: "comentario"
                },
                {
                    text: "Accion",
                    value: "accion",
                    sortable: false,
                    align: "right"
                }
            ]
        }
    },
    created() {
        this.Vact_bonificacionagro_data()
    },
    computed: {
        ...mapGetters("ventaagro", ["Vget_bonificacionagro_data", "Vget_bonificacionagro_load"]),
    },
    methods: {
        ...mapActions("ventaagro", ["Vact_bonificacionagro_data", "Vact_ventaagro_data", "Vact_bonificacionagro_del"]),
        createbonificacion() {
            this.crud.add = true
        },
        meliminar(item) {
            this.objeto = Object.assign({}, item)
            this.crud.delete = true
        },
        eliminarcancelar() {
            this.objeto = "";
            this.crud.delete = false;
        },
        async meliminarguardar() {
            this.overlay = true;

            const id = this.objeto.idbonificacion_agro;
            const a = await this.Vact_bonificacionagro_del(id);
            if (a.estado == true) {
                this.$notify(a.info);
                this.crud.delete = false;
                this.eliminarcancelar();
            } else {
                a.info.map((x) => {
                    this.$notify(mensaje.error(x));
                });
            }
            this.overlay = false;
        },
        cerrar() {
            this.$emit("input", false)
            this.$emit("datos", null)
            this.Vact_ventaagro_data()
        },
    }
}
</script>