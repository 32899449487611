<template>
  <div>
    <c-alert-permission v-model="permission"></c-alert-permission>
    <div v-if="permission.can_view">
      <v-form ref="form" class="ma-0">
        <c-overlay :value="isLoading"></c-overlay>
        <v-row dense class="ma-0">
          <v-col cols="12" md="4">
            <vue-text-field
              label="Año Inicio"
              v-model="anho_inicio"
            ></vue-text-field>
          </v-col>
          <v-col cols="9" md="4">
            <vue-text-field label="Año Fin" v-model="anho_fin"></vue-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <v-btn
            class="ma-2"
            text
            fab
            color="primary"
            outlined
            small
            @click="obtenerDatos()"
          >
            <v-icon>cloud_download</v-icon>
          </v-btn>
        </v-row>
      </v-form>
      <v-row dense row class="ma-0">
        <v-col cols="12" sm="12" v-if="grafico">
          <v-chart :options="option" autoresize />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { db } from "@/services/db.service";
import graficoindice from "@/views/Agro/Reporte/GraficoIndice";
import ECharts from "vue-echarts/components/ECharts";
import { mensaje } from "@/services/notify.service";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { PUEDE_GENERAR_REPORTE } from "@/constants/permissions";
import { REPORTE_AGRICULTURA } from "@/constants/forms";

export default {
  components: {
    "v-chart": ECharts,
  },
  data() {
    return {
      option: {},
      grafico: false,
      response: null,
      isLoading: false,
      anho_inicio: new Date().getFullYear() - 5,
      anho_fin: new Date().getFullYear(),
      permission: {
        can_view: false,
      },
    };
  },
  computed: {
    darkMode() {
      return this.$vuetify.theme.dark;
    },
  },
  watch: {
    darkMode() {
      if (this.response != null) this.generarGrafico();
    },
  },
  created() {
    this.permission.can_view = getPermission(REPORTE_AGRICULTURA).permiso[
      PUEDE_GENERAR_REPORTE
    ];
  },
  methods: {
    async obtenerDatos() {
      try {
        if (!this.$refs.form.validate()) return null;
        this.isLoading = true;
        this.response = await db.get(
          `/apicomparar_rinde/${this.anho_inicio}/${this.anho_fin}/`
        );
        this.generarGrafico();

        this.grafico = true;
      } catch (error) {
        this.$notify(mensaje.error(error.message));
      }
      this.isLoading = false;
    },
    generarGrafico() {
      this.option = graficoindice(this.response, this.darkMode);
    },
  },
};
</script>
<style scoped>
.chart-wrapper {
  width: 100%;
  height: 65vh;
}
.echarts {
  width: 100%;
  height: 65vh;
}
table {
  border-collapse: collapse;
  width: 100%;
}
</style>
