<template>
  <v-text-field
    :value="value"
    @input="$emit('input', $event)"
    append-icon="search"
    label="Busqueda"
    single-line
    hide-details
  ></v-text-field>
</template>

<script>
export default {
  props: {
    value: [String, Number],
  },
};
</script>

<style>
</style>