export default {
  venta_itemvendido: [],
  venta_advanced: [],
  venta_reimpresion: { autorizado: false},
  venta_data: [],
  venta_byId: [],
  venta_rebuild: [],
  venta_load: false
}


