<template>
  <div>
    <v-dialog max-width="800" :value="value" persistent @keydown.esc="close()">
      <v-card>
        <v-toolbar flat dense>
          <v-toolbar-title>{{
            !isEdit ? "Registrar Analisis" : "Modificar Analisis"
          }}</v-toolbar-title>
        </v-toolbar>
        <v-container>
          <v-form ref="form" lazy-validation>
            <v-row dense>
              <v-col cols="12">
                <AutocompleteAnimal
                  action="fetchAnimal"
                  getter="getAnimal"
                  id="input1"
                  no-create
                  v-model="data.idanimal.idanimal"
                  @keyup.native.enter="next(1, data.idanimal.idanimal)"
                />
              </v-col>
              <v-col cols="12">
                <input-autocomplete
                  :readonly="readonly"
                  label="Tipo Analisis"
                  api="/apitipoanalisis/"
                  pk="idtipoanalisis"
                  title="desctipoanalisis"
                  vuex="tipoanalisis/tipoanalisis_create"
                  id="input2"
                  :key="tipoanalisis_key"
                  :permission="permission.tipoanalisis"
                  v-model="data.idtipoanalisis.idtipoanalisis"
                  @keyup.native.enter="
                    next(2, data.idtipoanalisis.idtipoanalisis)
                  "
                />
                <input-dialog v-if="tipoanalisis_open" :value="true">
                  <template slot="body">
                    <Analisis-Tipoanalisis-create />
                  </template>
                </input-dialog>
              </v-col>
              <v-col cols="12">
                <c-text-date
                  label="Fecha de registro"
                  id="input3"
                  :readonly="readonly"
                  outlined
                  dense
                  v-model="data.fecaanalisis"
                  @keyup.native.enter="next(3, data.fecaanalisis)"
                >
                </c-text-date>
              </v-col>
              <v-col cols="12">
                <c-text-field
                  label="Comentario"
                  :readonly="readonly"
                  outlined
                  dense
                  id="input4"
                  :rules="[]"
                  v-model="data.comnanalisis"
                  @keyup.native.enter="next(4, data.comnanalisis)"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-card-actions>
          <c-btn-crud-close @click="close()"></c-btn-crud-close>
          <v-spacer></v-spacer>
          <c-btn-crud-done
            id="aceptar"
            :disabled="readonly"
            @click="save()"
          ></c-btn-crud-done>
        </v-card-actions>
        <c-overlay :value="isLoading" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { focus } from "@/services/focus.service";
import { current_date } from "@/services/util/date.service";
import { TIPO_ANALISIS } from "@/constants/forms";
export default {
  components: {
    AutocompleteAnimal: () =>
      import("@/views/Animal/Animal/AutocompleteAnimal"),
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    datos: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      permission: {
        tipoanalisis: TIPO_ANALISIS,
      },
      data: {
        idanalisis: 0,
        fecaanalisis: current_date(),
        fechanalisis: "2010-10-10",
        comnanalisis: "",
        idanimal: {
          idanimal: 0,
        },
        idtipoanalisis: {
          idtipoanalisis: 0,
        },
      },
      default: {
        idanalisis: 0,
        fecaanalisis: current_date(),
        fechanalisis: "2010-10-10",
        comnanalisis: "",
        idanimal: {
          idanimal: 0,
        },
        idtipoanalisis: {
          idtipoanalisis: 0,
        },
      },
      isEdit: false,
    };
  },

  computed: {
    ...mapGetters("analisis", ["isLoading"]),
    ...mapGetters("tipoanalisis", {
      tipoanalisis_open: "tipoanalisis_create",
      tipoanalisis_key: "tipoanalisis_key",
    }),
  },

  watch: {
    $route(to, from) {
      if (from.path === "/analisis") return this.close();
    },
  },
  created() {
    this.nuevo(this.datos);
  },
  mounted() {
    setTimeout(() => document.getElementById("input1").focus(), 500);
  },
  methods: {
    ...mapActions("analisis", [
      "fetchAnalisis",
      "setAnalisis",
      "setAnalisisUpdate"
    ]),
    close() {
      this.fetchAnalisis();
      this.$emit("datos", null);
      this.$emit("input", false);
      this.isEdit = false;
    },
    async save() {
      if (this.data.comnanalisis === "") this.data.comnanalisis = null;
      if (!this.$refs.form.validate()) return null;
      let a = !this.isEdit
        ? await this.setAnalisis(this.data)
        : await this.setAnalisisUpdate({
            id: this.data.idanalisis,
            data: this.data,
          });
      if (!a.success) return null;
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$refs.form.resetValidation();
      if(this.isEdit) return this.close();
      setTimeout(() => document.getElementById("input1").focus(), 500);
    },
    nuevo(val) {
      if (val === null) return null;
      this.data = JSON.parse(JSON.stringify(val));
      this.isEdit = true;
    },

    next(id, data) {
      focus.nextid(id, data);
    },
  },
};
</script>
