
export default {
  Vget_referencia_data: (state) => {
    return state.referencia_data
  },
  Vget_referencia_load: (state) => {
    return state.referencia_load
  },
  Vget_referencia_save_load: (state) => {
    return state.referencia_save_load
  },
}
