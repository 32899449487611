
export default {

  turno_create: (state) => {
    return state.turno_create
  },
  turno_update: (state) => {
    return state.turno_update
  },
  turno_reload: (state) => {
    return state.turno_reload
  },
  turno_show: (state) => {
    return state.turno_show
  },
}
