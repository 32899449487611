<template>
  <v-dialog persistent max-width="700" :value="value">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-form ref="form" @submit.prevent="">
          <v-row dense>
            <v-col cols="6">
              <v-autocomplete
                dense
                v-model="data.idmaquinaria_asignacion"
                ref="maquinaria1"
                v-bind:items="getMaquinariaAsignacion"
                label="Maquinaria Asignacion"
                item-value="idmaquinaria_asignacion"
                return-object
                :rules="[
                  (v) =>
                    !!v.idmaquinaria_asignacion || 'Seleccione una Asignacion',
                ]"
                required
                outlined
                autocomplete="off"
                @keyup.native.enter="
                  next(1, data.idmaquinaria_asignacion.idmaquinaria_asignacion)
                "
                :no-data-text="`No existe ninguna concidencia`"
              >
                <template slot="selection" slot-scope="data">
                  {{ data.item.idmaquinaria.descripcion }} -
                  {{ data.item.idmaquinaria.chapa }}
                </template>
                <template slot="item" slot-scope="data">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ data.item.idmaquinaria.descripcion }} -
                      {{ data.item.idmaquinaria.chapa }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ data.item.idchofer.nombre }}
                      {{ data.item.idchofer.apellido }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                dense
                v-model="data.idmaquinaria_gasto_tipo"
                ref="maquinaria2"
                v-bind:items="getMaquinariaGastoTipo"
                label="Tipo Gasto"
                item-text="descripcion"
                item-value="idmaquinaria_gasto_tipo"
                return-object
                :rules="[
                  (v) =>
                    !!v.idmaquinaria_gasto_tipo ||
                    'Seleccione un Tipo de Gasto',
                ]"
                required
                outlined
                autocomplete="off"
                @keyup.native.enter="
                  next(2, data.idmaquinaria_gasto_tipo.idmaquinaria_gasto_tipo)
                "
                :no-data-text="`No existe ninguna concidencia`"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <c-text-date
                autocomplete="off"
                label="Fecha Inicio"
                :rules="rules"
                outlined
                dense
                ref="maquinaria3"
                v-model="data.fecha"
                placeholder="Ingrese la fecha de inicio"
                @keyup.native.enter="next(3, data.fecha)"
              ></c-text-date>
            </v-col>
            <v-col cols="6">
              <c-text-currency
                autocomplete="off"
                label="Monto"
                :rules="rules"
                outlined
                dense
                ref="maquinaria4"
                v-model="data.monto"
                placeholder="Ingrese el monto"
                @keyup.native.enter="next(4, data.monto)"
              ></c-text-currency>
            </v-col>
            <v-col cols="6" v-if="data.idmaquinaria_gasto_tipo.idmaquinaria_gasto_tipo == 1">
              <vue-autocomplete-contacto-proveedor
                :readonly="readonly"
                label="Proveedor"
                ref="maquinaria5"
                id="maquinaria5"
                v-model="detalle.idmaquinaria_proveedor.idcontacto.idcontacto"
                @keyup.native.enter="next(5, detalle.idmaquinaria_proveedor.idcontacto.idcontacto)"
              />
            </v-col>
            <v-col cols="6" v-if="data.idmaquinaria_gasto_tipo.idmaquinaria_gasto_tipo == 1">
              <v-autocomplete
                :readonly="readonly"
                dense
                v-model="detalle.idmaquinaria_concepto.idconcepto"
                ref="maquinaria6"
                id="maquinaria6"
                v-bind:items="Vget_concepto_data"
                :search-input.sync="searchconcepto"
                label="Concepto"
                item-text="desconcepto"
                return-object
                :rules="[(v) => !!v.idconcepto || 'Seleccione un concepto']"
                :loading="Vget_concepto_load"
                outlined
                autocomplete="off"
                @keyup.native.enter="
                  next(6, detalle.idmaquinaria_concepto.idconcepto)
                "
                :no-data-text="`No existe ninguna concidencia`"
              >
                <template v-if="concepto_permission" v-slot:no-data>
                  <v-list-item @click="formularios.concepto = true">
                    <v-list-item-content>
                      <v-list-item-title>
                        No existe concidencia. Click para agregar
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-autocomplete>
              <div v-if="formularios.concepto">
                <Item-Concepto-Create
                  v-model="formularios.concepto"
                  :sync-input="searchconcepto"
                  return-object
                  @sync-input="
                    (detalle.idmaquinaria_concepto.idconcepto = $event),
                      next(6, detalle.idmaquinaria_concepto.idconcepto)
                  "
                />
              </div>
            </v-col>
            <v-col cols="12">
              <v-textarea
                label="Observacion"
                outlined
                ref="maquinaria7"
                v-model="data.observacion"
                @keyup.native.enter="next(7, data.observacion)"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <c-btn-crud-close @click="cancel()"></c-btn-crud-close>
        <v-spacer></v-spacer>
        <c-btn-crud-done ref="maquinaria8" @click="save()"></c-btn-crud-done>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { CONCEPTO } from "@/constants/forms";
import { PUEDE_AGREGAR } from "@/constants/permissions";
import { getPermission } from "@/services/storage/permissionStorage.service";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
    },
    "sync-input": {
      type: [String, Number],
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.editable) {
      this.data = JSON.parse(JSON.stringify(this.props));
      this.detalle.idmaquinaria_proveedor = this.data.idmaquinaria_proveedor
      this.detalle.idmaquinaria_concepto = this.data.idmaquinaria_concepto
    }
    setTimeout(() => this.next(0, "-"), 200);
    if (this.syncInput != null) this.data.descripcion = this.syncInput;
    this.sync = this.syncInput;
  },
  created() {
    this.fetchMaquinariaGastoTipo();
    this.fetchMaquinariaAsignacion();
    this.Vact_concepto_data();
  },
  computed: {
    ...mapGetters("maquinaria_asignacion", [
      "isLoadingMaquinaria",
      "getMaquinariaAsignacion",
    ]),
    ...mapGetters("maquinaria_gasto_tipo", [
      "isLoadingGastoTipo",
      "getMaquinariaGastoTipo",
    ]),
    ...mapGetters("concepto", ["Vget_concepto_data", "Vget_concepto_load"]),
    title() {
      return this.editable
        ? "Modificar Gasto de Maquinaria"
        : "Registrar Gasto de Maquinaria";
    },
  },
  methods: {
    ...mapActions("maquinaria_gasto_tipo", ["fetchMaquinariaGastoTipo"]),
    ...mapActions("maquinaria_asignacion", ["fetchMaquinariaAsignacion"]),
    ...mapActions("maquinaria_gasto", [
      "fetchMaquinariaGasto",
      "setMaquinariaGasto",
      "setMaquinariaGastoUpdate",
    ]),
    ...mapActions("concepto", ["Vact_concepto_data"]),
    next(id, data) {
      if (data != "")
        try {
          this.$refs[`maquinaria${id + 1}`].focus();
        } catch (error) {
          this.$refs[`maquinaria${id + 1}`].$el.focus();
        }
    },
    cancel() {
      this.$emit("input", false);
    },
    concepto_permission() {
      return getPermission(CONCEPTO).permiso[PUEDE_AGREGAR];
    },
    async save() {
      if (!this.$refs.form.validate()) return null;
      if (this.detalle.idmaquinaria_concepto.idconcepto.idconcepto != "") {
        this.data.idmaquinaria_concepto = this.detalle.idmaquinaria_concepto
      }
      if (this.detalle.idmaquinaria_proveedor.idcontacto.idcontacto != "") {
        this.data.idmaquinaria_proveedor = this.detalle.idmaquinaria_proveedor
      }
      const response = this.editable
        ? await this.setMaquinariaGastoUpdate({
            data: this.data,
            id: this.data.idmaquinaria_mantenimiento,
          })
        : await this.setMaquinariaGasto(this.data);
      if (response.success) {
        if (this.sync != null) {
          this.cancel();
          this.fetchMaquinariaGasto();
          return this.$emit(
            "sync-input",
            response.data.idmaquinaria_mantenimiento
          );
        }
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();
        this.fetchMaquinariaGasto();
        this.next(0, "-");
        if (this.editable) this.cancel();
      }
    },
    search() {
      this.item.forEach((x) => {
        if (x.iditem === this.searchItemId) {
          return;
        }
      });
    },
  },
  data: () => ({
    rules: [(v) => !!v || "Este campo es obligatorio"],
    formularios: {
      concepto: false,
    },
    item: [],
    searchitem: "",
    searchconcepto: "",
    detalle: {
      idmaquinaria_proveedor: {
        idcontacto: {
          idcontacto: {
            idcontacto: "",
          },
        }
      },
      idmaquinaria_concepto: {
        idconcepto: {
          idconcepto: "",
        },
      },
    },
    detalle_default: {
      idmaquinaria_proveedor: {
        idcontacto: {
          idcontacto: "",
        }
      },
      idmaquinaria_concepto: {
        idconcepto: {
          idconcepto: "",
        },
      },
    },
    data: {
      idmaquinaria_gasto: 0,
      idmaquinaria_gasto_tipo: {
        idmaquinaria_gasto_tipo: "",
      },
      idmaquinaria_asignacion: {
        idmaquinaria_asignacion: "",
      },
      idmaquinaria_proveedor: {
        idcontacto: {
          idcontacto: {
            idcontacto: "",
          },
        }
      },
      idmaquinaria_concepto: {
        idconcepto: {
          idconcepto: "",
        },
      },
      fecha: "",
      monto: 0,
      observacion: "",
    },
    default: {
      idmaquinaria_gasto: 0,
      idmaquinaria_gasto_tipo: {
        idmaquinaria_gasto_tipo: "",
      },
      idmaquinaria_asignacion: {
        idmaquinaria_asignacion: "",
      },
      idmaquinaria_proveedor: {
        idcontacto: {
          idcontacto: "",
        }
      },
      idmaquinaria_concepto: {
        idconcepto: {
          idconcepto: "",
        },
      },
      fecha: "",
      monto: 0,
      observacion: "",
    },
    sync: null,
  }),
};
</script>