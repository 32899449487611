<template>
  <v-dialog max-width="400" :value="value" persistent>
    <v-card>
      <v-overlay :value="isUsersLoading" absolute>
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-overlay>
      <v-toolbar flat>
        <v-toolbar-title class="subtitle-1"
          >Resetear contraseña del usuario
          <strong>{{ user.username }}</strong></v-toolbar-title
        >
      </v-toolbar>
      <v-form ref="form">
        <v-container>
          <v-text-field
            label="Escriba la nueva contraseña"
            ref="reset1"
            :append-icon="show1 ? 'visibility' : 'visibility_off'"
            counter
            :type="show1 ? 'text' : 'password'"
            @click:append="show1 = !show1"
            :rules="rules"
            :error="error.length > 0 ? true : false"
            :messages="error"
            v-model="data.password1"
            @keyup.enter="next(1, data.password1)"
          ></v-text-field>
          <v-text-field
            label="Verificar contraseña"
            ref="reset2"
            :append-icon="show2 ? 'visibility' : 'visibility_off'"
            counter
            :type="show2 ? 'text' : 'password'"
            @click:append="show2 = !show2"
            :rules="rules"
            :error="error.length > 0 ? true : false"
            :messages="error"
            v-model="data.password2"
            @keyup.enter="next(2, data.password1)"
          ></v-text-field>
        </v-container>
      </v-form>
      <v-card-actions>
        <v-btn color="red" text
          ><v-icon @click="cancel()">close</v-icon></v-btn
        >
        <v-spacer></v-spacer>
        <v-btn color="primary" ref="reset3" text @click="resetPassword()"
          ><v-icon>done</v-icon></v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    value: Boolean,
    user: Object,
  },
  async mounted() {
    setTimeout(() => this.next(0, "-"), 20);
  },
  computed: {
    ...mapGetters("users", ["isUsersLoading"]),
  },
  methods: {
    ...mapActions("users", ["setUserReset"]),
    cancel() {
      this.$emit("input", false);
    },
    next(id, data) {
      if (data != "")
        try {
          this.$refs[`reset${id + 1}`].focus();
        } catch (error) {
          this.$refs[`reset${id + 1}`].$el.focus();
        }
    },
    async resetPassword() {
      if (!this.$refs.form.validate()) return null;
      if (this.data.password1 != this.data.password2)
        return (this.error = "Las contraseñas no coinciden");
      let data = JSON.parse(JSON.stringify(this.user));
      data.password = this.data.password1;
      const id = data.id;
      const response = await this.setUserReset({
        id,
        data,
      });
      if (response.success) {
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();
        this.cancel();
      }
    },
  },
  data: () => ({
    data: {
      password1: "",
      password2: "",
    },
    default: {
      password1: "",
      password2: "",
    },
    show1: false,
    show2: false,
    error: "",
    rules: [
      (v) => !!v || "Este campo es obligatorio",
      (v) => v.toString().length > 7 || "Se requiere 8 caracteres como minimo",
    ],
  }),
};
</script>

<style>
</style>