<template>
  <div>
    <v-dialog :value="value" max-width="800px" persistent @keydown.esc="cancelar()">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>{{'Detalle de translado Banco a Caja'}}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form">
            <v-row dense>
              <v-col cols="12">
                <c-text-date outlined dense label="Fecha" v-model="data.fecha" readonly />
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <vue-text-field
                  label="Cuenta Bancaria de Origen"
                  v-model="data.idcuentabanco.desccuentabanco"
                  readonly
                />
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <vue-text-field
                  label="Caja de Destino"
                  v-model="data.idcaja.descaja"
                  readonly
                />
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <vue-text-field label="Monto" v-model="data.monto" readonly />
              </v-col>
              <v-col cols="12">
                <vue-text-field label="Numero de Translado" v-model="data.nrotranslado" readonly />
              </v-col>
              <v-col cols="12">
                <vue-text-field label="Observacion" v-model="data.observacion" readonly />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-btn color="red" text @click="cancelar">
            <v-icon dark>close</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

export default {
  props: {
    value: {
      type: Boolean,
      default: null
    },
    mostrar: {
      type: [Object, Array]
    }
  },
  data() {
    return {
      data: {
        idtransladobancocaja: 0,
        observacion: "",
        monto: "",
        idcuentabanco: {
          idcuentabanco: ""
        },
        idcaja: {
          idcaja: ""
        },
        nrotranslado: "",
        fecha: ""
      },
      default: {
        idtransladobancocaja: 0,
        observacion: "",
        monto: "",
        idcuentabanco: {
          idcuentabanco: ""
        },
        idcaja: {
          idcaja: ""
        },
        nrotranslado: "",
        fecha: ""
      }
    };
  },
  mounted() {
    this.data = JSON.parse(JSON.stringify(this.mostrar));
  },
  watch: {
    $route(to, from) {
      if (from.path === "/transladobancocaja") return this.cancelar();
    }
  },

  methods: {
    cancelar() {
      this.$emit("input", false);
      this.$emit("mostrar", null);
      this.data = JSON.parse(JSON.stringify(this.default));
    }
  }
};
</script>