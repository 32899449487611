<template>
  <div>
    <InputAutocomplete
      label="Seleccione la Enfermedad"
      item-value="idenfermedad"
      item-text="idtipoenfermedad.descenfermedad"
      :id="id"
      dense
      outlined
      :value="value"
      :items="getEnfermedadId"
      :loading="isLoading"
      @input="$emit('input', $event)"
      @sync-input="formulario.search = $event"
      @create="formulario.crud = true"
      :permission="permission"
    />
    <Crud
      v-if="formulario.crud"
      v-model="formulario.crud"
      :idanimal="idanimal"
      :sync-input="formulario.search"
      @sync-input="syncInputHandler()"
    />
  </div>
</template>

<script>
import InputAutocomplete from "@/ncomponents/InputAutocomplete";
import Crud from "./Create";
import { mapActions, mapGetters } from "vuex";
import { ENFERMEDAD } from "@/constants/forms";
import { PUEDE_AGREGAR } from "@/constants/permissions";
import { getPermission } from "@/services/storage/permissionStorage.service";
export default {
  props: {
    id: String,
    value: [String, Object, Number],
    returnObject: Boolean,
    idanimal: {
      type: Number,
      default: null
    },
  },
  components: {
    InputAutocomplete,
    Crud,
  },
  data: () => ({
    formulario: {
      crud: false,
      search: "",
    },
  }),
  computed: {
    ...mapGetters("enfermedad", ["getEnfermedadId", "isLoading"]),
    permission() {
      return getPermission(ENFERMEDAD).permiso[PUEDE_AGREGAR];
    },
  },
  mounted() {
    if (this.idanimal) this.fetchEnfermedadId(this.idanimal);
  },
  methods: {
    ...mapActions("enfermedad", ["fetchEnfermedadId", "fetchNuevo"]),
    syncInputHandler() {
      this.$emit("nextfocus");
       if (this.idanimal) this.fetchEnfermedadId(this.idanimal);
    },
  },
};
</script>

<style></style>
