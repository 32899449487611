
export default {

  Vget_tipofactura_data: (state) => {
    return state.tipofactura_data
  },
  Vget_tipofactura_load: (state) => {
    return state.tipofactura_load
  },
  getTipoFactura: (state) => {
    return state.tipofactura
  },
  isTipoFacturaLoading: (state) => {
    return state.isLoading
  }
}
