<template>
  <div>
    <v-toolbar flat dense>
      <v-select
        class="mt-1"
        :items="getAnhos"
        v-model="data.year"
        :loading="isFuncionarioLoading"
        hide-details
        label="Seleccione el Año para generar el documento"
      ></v-select>
    </v-toolbar>
    <div class="ml-3 mt-3 subtitle-1 font-weight-black">
      Planillas del MTESS
    </div>
    <v-data-table
      :headers="headers"
      hide-default-footer
      :items="items"
      dense
      :loading="isReporteLoading"
      :items-per-page="9999"
    >
      <template v-slot:[`item.action`]="{ item }">
        <v-btn text small color="red" @click="generarDocumentos(item.tipodoc)"
          >Generar <v-icon small>get_app</v-icon></v-btn
        >
      </template>
    </v-data-table>
    <div class="ml-3 mt-3 subtitle-1 font-weight-black">
      Planillas deL IPS
    </div>
    <v-data-table
      :headers="headers"
      hide-default-footer
      :items="ips"
      dense
      :loading="isReporteLoading"
      :items-per-page="9999"
    >
      <template v-slot:[`item.action`]="{ item }">
        <v-btn text small color="red" @click="generarDocumentos(item.tipodoc)"
          >Generar <v-icon small>get_app</v-icon></v-btn
        >
      </template>
    </v-data-table>
    <c-dialog-confirmation
      v-model="dialog.month"
      @cancel="dialog.month = false"
      :loading="isReporteLoading"
      @done="fetchIps()"
    >
      <v-select
        :items="months"
        v-model="monthSelect"
        label="Seleccione el mes de Inicio"
      ></v-select>
    </c-dialog-confirmation>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import {
  exportEmpleadosObreros,
  exportSueldosJornales,
  exportResumenGeneral,
  exportPlanillaIPS,
} from "./export";
import { months } from "@/services/util/date.service";
export default {
  mounted() {
    this.fetchAnhos();
  },
  data: () => ({
    data: {
      year: "",
    },
    dialog: {
      month: false,
    },
    months: months,
    headers: [
      {
        text: "Tipo de Documento",
        align: "start",
        sortable: false,
        value: "tipodoc",
      },
      { text: "Opciones", value: "action", align: "right", sortable: false },
    ],
    items: [
      {
        tipodoc: "EMPLEADOS Y OBREROS",
        action: null,
      },
      {
        tipodoc: "SUELDOS Y JORNALES",
        action: null,
      },
      {
        tipodoc: "RESUMEN GENERAL",
        action: null,
      },
    ],
    ips: [
      {
        tipodoc: "PLANILLA DE SUELDOS Y JORNALES",
        action: null,
      },
    ],
    monthSelect: months[0],
  }),
  computed: {
    ...mapGetters("funcionario", [
      "getAnhos",
      "getEmpleadoObrero",
      "getSueldosJornales",
      "getResumenGeneral",
      "getPlanillaIps",
      "isFuncionarioLoading",
      "isReporteLoading",
    ]),
  },
  watch: {
    getAnhos(val) {
      this.data.year = val[val.length - 1];
    },
    getEmpleadoObrero(val) {
      exportEmpleadosObreros(val);
    },
    getSueldosJornales(val) {
      exportSueldosJornales(val);
    },
    getResumenGeneral(val) {
      exportResumenGeneral(val);
    },
    getPlanillaIps(val) {
      exportPlanillaIPS(val);
    },
  },
  methods: {
    ...mapActions("funcionario", [
      "fetchAnhos",
      "fetchEmpleadosObreros",
      "fetchSueldoJornales",
      "fetchResumenGeneral",
      "fetchPlanillaIps",
    ]),
    async fetchIps() {
      const monthSelect = this.months.indexOf(this.monthSelect) + 1;
      await this.fetchPlanillaIps({ year: this.data.year, month: monthSelect });
      this.dialog.month = false;
    },
    generarDocumentos(type) {
      switch (type) {
        case "EMPLEADOS Y OBREROS":
          this.fetchEmpleadosObreros(this.data.year);
          break;
        case "SUELDOS Y JORNALES":
          this.fetchSueldoJornales(this.data.year);
          break;
        case "RESUMEN GENERAL":
          this.fetchResumenGeneral(this.data.year);
          break;
        case "PLANILLA DE SUELDOS Y JORNALES":
          this.dialog.month = true;
          break;
      }
    },
  },
};
</script>
