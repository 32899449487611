export default { 
  propquimica_update(state, request) {
    state.propquimica_update = request;
  },
  propquimica_data(state, request) {
    state.propquimica_data = request;
  }, 
  propquimica_load(state, request) {
    state.propquimica_load = request;
  }, 
  propquimica_save_load(state, request) {
    state.propquimica_save_load = request;
  }, 
}