<template>
  <div>
    <v-dialog :value="value" max-width="700px" persistent :key="reload">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Detalle</v-toolbar-title>
        </v-toolbar>
        <v-container>
          <v-row row dense>
            <v-col cols="12" sm="4">
              <v-text-field
                dense
                v-model="data.iditem.descitem"
                label="Item"
                persistent-hint
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                dense
                v-model="data.idmotivoajuste.descmotivoajuste"
                label="Motivo AJuste"
                persistent-hint
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                dense
                v-model="data.idtipoajuste.desctipoajuste"
                label="Tipo Ajuste"
                persistent-hint
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                dense
                v-model="data.iddeposito.desdeposito"
                label="Deposito"
                persistent-hint
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                dense
                v-model="data.cantajustestock"
                label="Cantidad"
                persistent-hint
                outlined
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
        <v-card-actions>
          <v-btn color="red" text @click="cancelar">
            <v-icon>clear</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dateFormatted: "",
      data: {
        idajustestock: 0,
        cantajustestock: 0,
        iditem: {
          iditem: 0,
        },
        idmotivoajuste: {
          idmotivoajuste: 0,
        },
        idtipoajuste: {
          idtipoajuste: 0,
        },
        iddeposito: {
          iddeposito: 0,
        },
      },
      default: {
        idajuste: 0,
        cantajustestock: 0,
        iditem: {
          iditem: 0,
        },
        idmotivoajuste: {
          idmotivoajuste: 0,
        },
        idtipoajuste: {
          idtipoajuste: 0,
        },
        iddeposito: {
          iddeposito: 0,
        },
      },
      reload: 0,
    };
  },

  computed: {
    ...mapGetters("ajuste", {
      update: "ajuste_update",
    }),
  },
  created() {
    this.show(this.update);
  },
  watch: {
    $route(to, from) {
      if (from.path === "/ajuste") return this.cancelar();
    },
  },
  methods: {
    ...mapActions("ajuste", ["ajuste_show", "ajuste_update"]),
    cancelar() {
      this.data = this.default;
      this.$emit("input", false);
      this.ajuste_update(null);
      this.reload++;
    },
    show(val) {
      this.data = val;
    },
  },
};
</script>
