<template>
  <div>
    <v-dialog :value="value" persistent max-width="900">
      <v-card>
        <v-toolbar flat dense>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-actions>
          <span class="title ml-1">Informacion Basica</span>
          <v-divider></v-divider>
        </v-card-actions>
        <v-form ref="form">
          <v-row dense class="mx-2">
            <v-col cols="12" md="3" sm="6">
              <c-text-date
                ref="contracFunc1"
                label="Fecha de Inicio"
                v-model="data.fecha_inicio"
                @keyup.native.enter="next(1, data.fecha_inicio)"
              ></c-text-date>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <c-text-date
                ref="contracFunc2"
                label="Fecha Fin"
                v-model="data.fecha_fin"
                @keyup.native.enter="next(2, data.fecha_fin)"
              ></c-text-date>
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <autocomplete-sector
                ref="contracFunc3"
                v-model="data.idsector"
                create
                label="Sector"
                @keyup.native.enter="next(3, data.idsector.idsector)"
              />
            </v-col>
            <v-col cols="12" md="3" sm="6">
              <autocomplete-nivel-sector
                ref="contracFunc4"
                v-model="data.idnivel"
                create
                label="Nivel"
                @keyup.native.enter="next(4, data.idnivel.idnivel)"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-select
                dense
                :items="getCargo"
                :rules="[(v) => !!v || 'Obligatorio']"
                item-value="idcargo"
                item-text="descripcion"
                v-model="data.idcargo.idcargo"
                label="Cargo"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6">
              <c-text-field
                ref="contracFunc5"
                label="Descripcion del Cargo"
                dense
                v-model="data.descargo"
                @keyup.native.enter="next(5, data.descargo)"
              ></c-text-field>
            </v-col>
          </v-row>
          <v-card-actions>
            <span class="title ml-1">Horario Laboral</span>
            <v-divider></v-divider>
          </v-card-actions>
          <v-row dense class="mx-2">
            <v-col cols="12" md="6" sm="6">
              <v-select
                ref="contracFunc6"
                :items="[
                  { id: null, desc: 'Por cantidad de Horas (Variable)' },
                  { id: false, desc: 'Por cantidad de Horas' },
                  { id: true, desc: 'Por hora de entrada/salida' },
                ]"
                label="Control Horario"
                item-text="desc"
                item-value="id"
                v-model="data.unidad_trabajo"
                dense
                outlined
                @keyup.native.enter="
                  next(6, data.unidad_trabajo),
                    ($refs.contracFunc6.isMenuActive = false)
                "
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" v-if="data.unidad_trabajo == false">
              <c-text-currency
                ref="contracFunc7"
                outlined
                dense
                label="Horas laborales"
                v-model="data.horas_trabajo"
                @keyup.native.enter="next(11, data.horas_trabajo)"
              ></c-text-currency>
            </v-col>
          </v-row>
          <v-row dense class="mx-2" v-if="data.unidad_trabajo == true">
            <v-col cols="12" md="3" sm="3">
              <c-text-time
                ref="contracFunc7"
                dense
                label="Hora de Entrada"
                v-model="data.hora_entrada"
                @keyup.native.enter="next(7, data.hora_entrada)"
              ></c-text-time>
            </v-col>
            <v-col cols="12" md="3" sm="3">
              <c-text-time
                dense
                ref="contracFunc8"
                label="Hora de salida Descanso"
                :rules="[]"
                v-model="data.hora_salida_descanso"
                @keyup.native.enter="next(8, data.hora_salida_descanso)"
              ></c-text-time>
            </v-col>
            <v-col cols="12" md="3" sm="3">
              <c-text-time
                ref="contracFunc9"
                dense
                label="Hora de entrada Descanso"
                :rules="[]"
                v-model="data.hora_entrada_descanso"
                @keyup.native.enter="next(9, '-')"
              ></c-text-time>
            </v-col>
            <v-col cols="12" md="3" sm="3">
              <c-text-time
                dense
                ref="contracFunc10"
                label="Hora de Salida"
                v-model="data.hora_salida"
                @keyup.native.enter="next(10, '-')"
              ></c-text-time>
            </v-col>
          </v-row>

          <v-col cols="12" class="d-flex align-center flex-column">
            <v-chip-group
              v-model="data.dias"
              column
              multiple
              color="deep-purple accent-3"
            >
              <v-chip filter outlined :value="1"> Lunes </v-chip>
              <v-chip filter outlined :value="2"> Martes </v-chip>
              <v-chip filter outlined :value="3"> Miercoles </v-chip>
              <v-chip filter outlined :value="4"> Jueves </v-chip>
              <v-chip filter outlined :value="5"> Viernes </v-chip>
              <v-chip filter outlined :value="6"> Sabado </v-chip>
              <v-chip filter outlined :value="7"> Domingo </v-chip>
            </v-chip-group>
            <div class="subtitle-2 font-weight-light">
              <strong>{{ idcontacto.desccontacto }}</strong> trabaja
              <strong>{{ data.dias.length }} dias</strong> semanales
            </div>
          </v-col>

          <v-card-actions>
            <span class="title ml-1">Fijaciones Salariales</span>
            <v-divider></v-divider>
          </v-card-actions>
          <v-row dense class="mx-2">
            <v-col cols="6">
              <vue-autocomplete-moneda-moneda
                label="Moneda"
                v-model="data.idmoneda"
              ></vue-autocomplete-moneda-moneda>
            </v-col>
          </v-row>
        </v-form>
        <v-row
          class="mx-2"
          dense
          v-for="(item, i) of data.contrato_detalle"
          :key="i"
        >
          <v-col cols="12" md="2" sm="2">
            <v-select
              :items="recurrencia"
              label="Frecuencia"
              item-text="desc"
              item-value="id"
              v-model="data.contrato_detalle[i].meses"
            ></v-select>
          </v-col>
          <v-col cols="12" md="2" sm="2">
            <c-text-date
              label="Fecha Inicio"
              v-model="data.contrato_detalle[i].fecha_inicio"
            ></c-text-date>
          </v-col>
          <v-col cols="12" md="2" sm="2">
            <c-text-currency
              label="Monto"
              v-model="data.contrato_detalle[i].monto"
              messages="Deje en blanco si no es un monto fijo"
            ></c-text-currency>
          </v-col>
          <v-col cols="12" md="2" sm="2">
            <autocomplete-concepto-salario
              create
              contrato
              label="Concepto"
              v-model="data.contrato_detalle[i].idconcepto_salario"
            />
          </v-col>
          <v-col cols="12" md="2" sm="2">
            <autocomplete-unidadmedida
              label="Unidad"
              :idmagnitud="6"
              :rules="[]"
              v-model="data.contrato_detalle[i].idunidadmedida"
            />
          </v-col>
          <v-spacer></v-spacer>
          <v-btn
            text
            small
            class="mt-2"
            color="red"
            fab
            outlined
            @click="delCompensacion(i)"
            ><v-icon>clear</v-icon></v-btn
          >
        </v-row>
        <div class="d-flex justify-end mx-2">
          <v-btn
            color="deep-purple accent-3"
            outlined
            text
            @click="addCompensacion()"
            class="text-capitalize"
            >Agregar una compensacion variable</v-btn
          >
        </div>

        <v-card-actions>
          <span class="title ml-1">Informacion Adicional</span>
          <v-divider></v-divider>
        </v-card-actions>
        <v-row dense class="mx-2">
          <v-col cols="12">
            <v-textarea
              label="Comentarios"
              rows="2"
              v-model="data.comentario"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-card-actions>
          <c-btn-crud-close @click="cancelar()"></c-btn-crud-close>
          <v-spacer></v-spacer>
          <c-btn-crud-done @click="save()"></c-btn-crud-done>
        </v-card-actions>
      </v-card>
      <c-overlay :value="isContratoFuncionarioLoading"></c-overlay>
    </v-dialog>
  </div>
</template>

<script>
import { current_date } from "@/services/util/date.service";
import AutocompleteSector from "@/views/RRHH/Mantenimiento/Sector/autocomplete";
import AutocompleteNivelSector from "@/views/RRHH/Mantenimiento/NivelSector/autocomplete";
import AutocompleteConceptoSalario from "@/views/RRHH/Mantenimiento/ConceptoSalario/autocomplete";
import AutocompleteUnidad from "@/views/Item/UnidadMedida/autocomplete";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    "autocomplete-sector": AutocompleteSector,
    "autocomplete-nivel-sector": AutocompleteNivelSector,
    "autocomplete-concepto-salario": AutocompleteConceptoSalario,
    "autocomplete-unidadmedida": AutocompleteUnidad,
  },
  props: {
    value: Boolean,
    props: {
      type: Object,
    },
    idcontacto: {
      type: Object,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    setTimeout(() => this.next(0, "-"), 20);
    this.fetchCargo();
    if (this.editable) this.data = JSON.parse(JSON.stringify(this.props));
    this.data.idcontacto.idcontacto = JSON.parse(
      JSON.stringify(this.idcontacto)
    );
  },
  computed: {
    ...mapGetters("contratofuncionario", ["isContratoFuncionarioLoading"]),
    ...mapGetters("funcionario", ["getCargo", "isFuncionarioLoading"]),
    title() {
      return this.editable ? "Modificar Contrato" : "Registrar Contrato";
    },
  },
  methods: {
    ...mapActions("contratofuncionario", [
      "setContratoFuncionario",
      "setContratoFuncionarioUpdate",
      "fetchContratoFuncionarioById",
    ]),
    ...mapActions("funcionario", ["fetchCargo"]),
    next(id, data) {
      if (data != "")
        try {
          this.$refs[`contracFunc${id + 1}`].focus();
        } catch (error) {
          this.$refs[`contracFunc${id + 1}`].$el.focus();
        }
    },
    async save() {
      this.data.idcontrato = 0;
      if (this.data.hora_entrada_descanso === "")
        this.data.hora_entrada_descanso = null;
      if (this.data.hora_salida_descanso === "")
        this.data.hora_salida_descanso = null;
      if (this.data.hora_entrada === "") this.data.hora_entrada = null;
      if (this.data.hora_salida === "") this.data.hora_salida = null;
      if (this.data.horas_trabajo === "") this.data.horas_trabajo = null;

      if (!this.$refs.form.validate()) return null;

      const response = await this.setContratoFuncionario(this.data);
      if (response.success) {
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();
        this.fetchContratoFuncionarioById(this.idcontacto.idcontacto);
        this.next(0, "-");
        if (this.editable) this.cancelar();
      }
    },
    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$emit("input", false);
      this.$emit("datos", null);
    },
    addCompensacion() {
      this.data.contrato_detalle.push({
        meses: "",
        fecha_inicio: "",
        monto: "",
        idconcepto_salario: {
          idconcepto_salario: "",
        },
        idunidadmedida: {
          idunidadmedida: "",
        },
      });
    },
    delCompensacion(i) {
      this.data.contrato_detalle.splice(i, 1);
    },
  },
  data: () => ({
    enabled: false,
    data: {
      idcontrato: 0,
      idcontacto: {
        idcontacto: {
          idcontacto: "",
        },
      },
      idcargo: {
        idcargo: "",
      },
      idsector: {
        idsector: "",
      },
      idnivel: {
        idnivel: "",
      },
      idunidadmedida: {
        idunidadmedida: "",
      },
      unidad_trabajo: true,
      comentario: "",
      horas_trabajo: "",
      hora_salida_descanso: "",
      hora_entrada_descanso: "",
      hora_entrada: "",
      descargo: "",
      hora_salida: "",
      fecha_inicio: current_date(),
      fecha_fin: "",
      dias: [1, 2, 3, 4, 5],
      idmoneda: {
        idmoneda: "",
      },
      contrato_detalle: [
        {
          meses: "",
          fecha_inicio: "",
          monto: "",
          idconcepto_salario: {
            idconcepto_salario: "",
          },
          idunidadmedida: {
            idunidadmedida: "",
          },
        },
      ],
    },
    default: {
      idcontrato: 0,
      idcontacto: {
        idcontacto: {
          idcontacto: "",
        },
      },
      idcargo: {
        idcargo: "",
      },
      idsector: {
        idsector: "",
      },
      idnivel: {
        idnivel: "",
      },
      idunidadmedida: {
        idunidadmedida: "",
      },
      unidad_trabajo: true,
      comentario: "",
      horas_trabajo: "",
      hora_salida_descanso: "",
      hora_entrada_descanso: "",
      hora_entrada: "",
      descargo: "",
      hora_salida: "",
      fecha_inicio: current_date(),
      fecha_fin: "",
      dias: [1, 2, 3, 4, 5],
      idmoneda: {
        idmoneda: "",
      },
      contrato_detalle: [
        {
          meses: "",
          fecha_inicio: "",
          monto: "",
          idconcepto_salario: {
            idconcepto_salario: "",
          },
          idunidadmedida: {
            idunidadmedida: "",
          },
        },
      ],
    },
    recurrencia: [
      {
        id: 1,
        desc: "Mensual",
      },
      {
        id: 2,
        desc: "Cada 2 Meses",
      },
      {
        id: 3,
        desc: "Cada 3 Meses",
      },
      {
        id: 4,
        desc: "Cada 4 Meses",
      },
      {
        id: 5,
        desc: "Cada 5 Meses",
      },
      {
        id: 6,
        desc: "Cada 6 Meses",
      },
      {
        id: 7,
        desc: "Cada 7 Meses",
      },
      {
        id: 8,
        desc: "Cada 8 Meses",
      },
      {
        id: 9,
        desc: "Cada 9 Meses",
      },
      {
        id: 10,
        desc: "Cada 10 Meses",
      },
      {
        id: 11,
        desc: "Cada 11 Meses",
      },
      {
        id: 12,
        desc: "Cada 12 Meses",
      },
    ],
  }),
};
</script>

<style></style>
