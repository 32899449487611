<template>
  <v-dialog persistent max-width="800" :value="value">
    <v-card>
      <v-toolbar flat dense>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-form ref="form" lazy-validation>
          <v-row dense>
            <v-col cols="12">
              <c-text-field
                dense
                outlined
                label="Descripcion"
                id="input1"
                v-model="data.descperfil"
                @keyup.native.enter="next(1, data.descperfil)"
              />
            </v-col>
            <v-col cols="12" md="6">
              <input-autocomplete
                label="Sistema de Interes"
                api="/apisistema/"
                pk="idsistema_interes"
                title="descripcion"
                id="input2"
                v-model="data.idsistema_interes.idsistema_interes"
                @keyup.native.enter="
                  next(2, data.idsistema_interes.idsistema_interes)
                "
              />
            </v-col>
            <v-col cols="12" md="6">
              <input-autocomplete
                label="Periodo de Interes"
                api="/apiperiodo/"
                pk="idperiodo_interes"
                title="descripcion"
                id="input3"
                v-model="data.idperiodo_interes.idperiodo_interes"
                @keyup.native.enter="
                  next(3, data.idperiodo_interes.idperiodo_interes)
                "
              />
            </v-col>
            <v-col cols="12" md="6">
              <c-text-field
                dense
                outlined
                label="Porcentaje de Interes"
                id="input4"
                v-model="data.porcinteres"
                @keyup.native.enter="next(4, data.porcinteres)"
              />
            </v-col>
            <v-col cols="12" md="6">
              <c-text-field
                dense
                outlined
                label="Porcentaje de Interes Mora"
                id="input5"
                v-model="data.porinteresmora"
                @keyup.native.enter="next(5, data.porinteresmora)"
              />
            </v-col>
            <v-col cols="12" md="6">
              <c-text-field
                dense
                outlined
                label="Cantidad de Cuota Minimo"
                id="input6"
                v-model="data.cantcuotamin"
                @keyup.native.enter="next(6, data.cantcuotamin)"
              />
            </v-col>
            <v-col cols="12" md="6">
              <c-text-field
                dense
                outlined
                label="Cantidad de Cuota Maximo"
                id="input7"
                v-model="data.cantcuotamax"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-card-actions>
        <c-btn-crud-close @click="close()"></c-btn-crud-close>
        <v-spacer></v-spacer>
        <c-btn-crud-done @click="save()"></c-btn-crud-done>
      </v-card-actions>
      <c-overlay :value="isPerfilFinLoadig" /> </v-card
  ></v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { focus } from "@/services/focus.service";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
    },
  },
  data: () => ({
    data: {
      idperfil_financiamiento: 0,
      descperfil: "",
      porcinteres: "",
      porinteresmora: "",
      cantcuotamin: "",
      cantcuotamax: "",
      idperiodo_interes: {
        idperiodo_interes: "",
        descripcion: "",
      },
      idsistema_interes: {
        idsistema_interes: "",
        descripcion: "",
      },
    },
    default: {
      idperfil_financiamiento: 0,
      descperfil: "",
      porcinteres: "",
      porinteresmora: "",
      cantcuotamin: "",
      cantcuotamax: "",
      idperiodo_interes: {
        idperiodo_interes: "",
        descripcion: "",
      },
      idsistema_interes: {
        idsistema_interes: "",
        descripcion: "",
      },
    },
  }),

  computed: {
    ...mapGetters("perfilfin", ["isPerfilFinLoadig"]),
    title() {
      return this.editable
        ? "Modificar Perfil de Financiamiento"
        : "Registrar Perfil de Financiamiento";
    },
  },
  watch: {
    $route(to, from) {
      if (from.path === "/perfilfin") return this.close();
    },
  },
  mounted() {
    if (this.editable) this.data = JSON.parse(JSON.stringify(this.props));
    setTimeout(() => this.next(0,'-'), 500);
  },
  methods: {
    ...mapActions("perfilfin", [
      "setPerfilFin",
      "setPerfilFinUpdate",
      "fetchPerfilFin",
    ]),
    next(id, data) {
      focus.nextid(id, data);
    },
    close() {
      this.$emit("input", false);
    },
    async save() {
      if (!this.$refs.form.validate()) return null;
      const response = this.editable
        ? await this.setPerfilFinUpdate({
            data: this.data,
            id: this.data.idperfil_financiamiento,
          })
        : await this.setPerfilFin(this.data);
      if (response.success) {
        this.fetchPerfilFin();
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();

        this.next(0, "-");
        if (this.editable) this.close();
      }
    },
  },
};
</script>
