var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span',{staticClass:"ml-3 subtitle-2 font-weight-medium"},[_vm._v("Filtrar Datos")]),_c('v-form',{ref:"form"},[_c('v-row',{staticClass:"mx-2",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('AutocompleteFilter',{attrs:{"filled":false,"rounded":false,"hide-details":false,"dense":false,"rules":[function (v) { return !!v || 'Obligatorio'; }]},model:{value:(_vm.band),callback:function ($$v) {_vm.band=$$v},expression:"band"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('c-text-date',{attrs:{"label":"Filtrar desde"},model:{value:(_vm.data.desde),callback:function ($$v) {_vm.$set(_vm.data, "desde", $$v)},expression:"data.desde"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('c-text-date',{attrs:{"label":"Filtrar hasta"},model:{value:(_vm.data.hasta),callback:function ($$v) {_vm.$set(_vm.data, "hasta", $$v)},expression:"data.hasta"}})],1),_c('v-spacer'),_c('v-btn',{staticClass:"mt-3",attrs:{"color":"primary","fab":"","small":"","outlined":"","text":""},on:{"click":function($event){return _vm.obtenerDatos()}}},[_c('v-icon',[_vm._v("cloud_download")])],1),_c('v-btn',{staticClass:"ml-1 mt-3",attrs:{"color":"red","fab":"","small":"","outlined":"","text":""},on:{"click":function($event){return _vm.exportPdf()}}},[_c('v-icon',[_vm._v("get_app")])],1)],1)],1),_c('v-row',{staticClass:"mx-2 mb-2",attrs:{"dense":""}},[_c('v-spacer'),_c('v-col',{attrs:{"cols":"7","sm":"5"}},[_c('c-text-table-search',{model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-divider'),_c('v-data-table',{attrs:{"headers":_vm.headers,"loading":_vm.isLoading,"search":_vm.search,"dense":"","items":_vm.getReporteReproduccion,"items-per-page":999999999},scopedSlots:_vm._u([{key:"item.PADRE",fn:function(ref){
var item = ref.item;
return [(item.PADRE)?_c('BtnAnimal',{on:{"click":function($event){return _vm.irFicha(item.IDPADRE)}}},[_vm._v(_vm._s(item.PADRE))]):_vm._e()]}},{key:"item.MADRE",fn:function(ref){
var item = ref.item;
return [(item.MADRE)?_c('BtnAnimal',{on:{"click":function($event){return _vm.irFicha(item.IDMADRE)}}},[_vm._v(_vm._s(item.MADRE))]):_vm._e()]}},{key:"item.VACA",fn:function(ref){
var item = ref.item;
return [(item.VACA)?_c('BtnAnimal',{on:{"click":function($event){return _vm.irFicha(item.IDVACA)}}},[_vm._v(_vm._s(item.VACA))]):_vm._e()]}},{key:"item.cria",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"fill-height d-flex justify-space-between align-center"},[(item['NRO. CRIA'])?_c('BtnAnimal',{on:{"click":function($event){_vm.irFicha(item.IDCRIA.split(',')[0])}}},[_vm._v(_vm._s(item["NRO. CRIA"].split(",")[0]))]):_vm._e(),(_vm.mellizo(item['NRO. CRIA']))?_c('BtnAnimal',{on:{"click":function($event){_vm.irFicha(item.IDCRIA.split(',')[1])}}},[_vm._v(_vm._s(item["NRO. CRIA"].split(",")[1]))]):_vm._e()],1)]}},{key:"item.TORO",fn:function(ref){
var item = ref.item;
return [(item.TORO)?_c('BtnAnimal',{on:{"click":function($event){return _vm.irFicha(item.IDTORO)}}},[_vm._v(_vm._s(item.TORO))]):_vm._e()]}},{key:"item.ultimaIA",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"mx-n2 caption"},[_vm._v(_vm._s(_vm.formatDate(item["ULTIMA FECHA I.A."])))])]}},{key:"item.fechaParto",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"ma-n2 pa-1 caption"},[_vm._v(" "+_vm._s(_vm.formatDate(item["FECHA DE PARTO"]))+" ")])]}},{key:"item.iep",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.formatNumber(item["I.E.P meses"])))])]}}],null,true)},[_c('template',{slot:"body.prepend"},[_c('tr',{staticClass:"black--text"},[_c('th'),_c('th'),_c('th'),_c('th'),_c('th'),_c('th'),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.promedioAI))]),_c('th'),_c('th'),_c('th'),_c('th',[_vm._v(_vm._s(_vm.promedioIEP))]),_c('th'),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.promedioDA))]),_c('th',{staticClass:"text-right"},[_vm._v(_vm._s(_vm.promedioDL))])])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }