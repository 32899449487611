<template>
  <InputAutocomplete
    label="Seleccione el Estado"
    item-value="idestadoanimal"
    item-text="descestadoanimal"
    dense
    :id="id"
    :outlined="outlined"
    :filled="filled"
    :rounded="rounded"
    :value="value"
    :multiple="multiple"
    :items="getEstadoAnimal"
    :loading="isLoading"
    @input="$emit('input', $event)"
  />
</template>
<script>
import InputAutocomplete from "@/ncomponents/InputAutocomplete";
import { mapActions, mapGetters } from "vuex";
export default {
  props:{
    value: [String, Object, Number, Array],
    id: String,
    filled: Boolean,
    rounded: Boolean,
    multiple: Boolean,
    outlined:{
        type: Boolean,
        default: true
    }
  },
  components: {
    InputAutocomplete,
  },
  mounted() {
    this.fetchEstadoAnimal();
  },
  computed: {
    ...mapGetters("estadoanimal", ["getEstadoAnimal", "isLoading"]),
  },
  methods: {
    ...mapActions("estadoanimal", ["fetchEstadoAnimal"]),
  },
};
</script>
