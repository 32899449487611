<template>
  <div>
    <v-dialog v-model="dialogo" max-width="700px" persistent>
      <v-card>
        <v-toolbar flat dense>
          <v-toolbar-title>Detalles</v-toolbar-title>
        </v-toolbar>
        <v-container>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                dense
                type="text"
                id="input3"
                v-model="data.desclote"
                label="Descripcion"
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                dense
                id="input2"
                v-model="data.nrolote"
                label="Nro Lote"
                persistent-hint
                outlined
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions>
          <c-btn-crud-close @click="cancelar()"></c-btn-crud-close>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props:{
    value: Boolean,
    datos: {
      type: [Object,Array],
      default: null
    }
  },
  data() {
    return {
      data: {},
      default: {}
    };
  },

  watch: {
    $route() {
     return this.cancelar();
    }
  },
  created() {
    this.show(this.datos);
  },
  methods: {
    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$emit('input',false);
      this.$emit('datos',null);
    },

    show(val) {
      this.dialogo = true;
      this.data = JSON.parse(JSON.stringify(val));
    }
  }
};
</script>
