
export default {

  tipocuenta_create: (state) => {
    return state.tipocuenta_create
  },
  tipocuenta_update: (state) => {
    return state.tipocuenta_update
  },
  tipocuenta_reload: (state) => {
    return state.tipocuenta_reload
  },
  tipocuenta_show: (state) => {
    return state.tipocuenta_show
  },
}
