export default {
  getConfigGanaderia: (state) => {
    return state.data;
  },
  isLoading: (state) => {
    return state.loading;
  },
  getColumnsAnimal: (state) => {
    if (state.data[0]) return state.data[0].lista_anim;
    return [];
  },
};
