export default {
  motivoajuste_create: false,
  motivoajuste_update: null,
  motivoajuste_reload: false,
  motivoajuste_show: false,

  motivoajuste_data: [],
  motivoajuste_load: false,
  motivoajuste_save_load: false
}
