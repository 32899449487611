import { db } from "@/services/db.service";
export default {
    async gymasistencia_update({commit},data){
        commit('gymasistencia_update',data);
    },

    async Vact_gymasistencia_data({commit}){
        commit('gymasistencia_load',true);
        try {
            const data = await db.get('/apigimnasioasistencia/');
            commit('gymasistencia_data',data);
            commit('gymasistencia_load',false);
            return true
        } catch (e) {
            commit('gymasistencia_load',false);
            return false
        } 
    },
    async Vact_gymasistencia_data_hoy({commit}, {inicio, fin}){
        commit('gymasistencia_load', true);
        try {
            const data = await db.get('/apigimnasiodiarias/' + inicio + '/' + fin + '/');
            commit('gymasistencia_data_hoy', data);
            commit('gymasistencia_load', false);
            return true
        } catch (e) {
            commit('gymasistencia_load', false);
            return false
        }
    },
    async Vact_gymasistencia_data_by({commit}, {id}) {
        commit('gymasistencia_load', true);
        try {
            const data = await db.get('/apigimnasioasistencia/?idgymplanilla=' + id)
            commit('gymasistencia_data_by', data)
            commit('gymasistencia_load', false);
            return true
        } catch (e) {
            commit('gymasistencia_load', false);
            return false
        }
    },
    async Vact_gymasistencia_save({dispatch, commit},data){
        commit('gymasistencia_save_load',true);
        try {
            const resp = await db.save('/apigimnasioasistencia/',data);
            dispatch('Vact_gymasistencia_data');
            commit('gymasistencia_save_load',false);
            return resp;
        } catch (e) {
            commit('gymasistencia_save_load',false);
            return e
        } 
    },
    async Vact_gymasistencia_update({commit},{id, data}){
        commit('gymasistencia_save_load',true);
        try {
            const resp = await db.update('/apigimnasioasistencia/'+id+'/',data);
            commit('gymasistencia_save_load',false);
            return resp;
        } catch (e) {
            commit('gymasistencia_save_load',false);
            return e
        } 
    },
    async Vact_gymasistencia_del({dispatch},id){
        try {
            const data = await db.delete('/apigimnasioasistencia/'+id);
            dispatch('Vact_gymasistencia_data');
            return data
        } catch (e) {
            return false
        } 
    }
}
