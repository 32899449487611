<template>
  <div>
    <div v-if="abrirresponsable">
      <Animal-Responsable-create />
    </div>
    <div v-if="show">
      <Animal-Responsable-show />
    </div>
    <v-card>
      <v-alert type="error" v-if="permission_alert"
        >Error!!! Este usuario no tiene permisos en esta ventana</v-alert
      >
      <v-toolbar flat dense>
        <v-toolbar-title>{{ titulo }}</v-toolbar-title>
        <widgets-Favorite ruta="/responsable" formulario="Responsable" />
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <c-text-table-search
          v-model="search"
          v-if="permission.can_view"
        ></c-text-table-search>
      </v-toolbar>
      <v-toolbar flat dense>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-tabs v-model="tab" right>
            <v-tab href="#tab-1" @click="Vact_responsable_data()">Activo</v-tab>
            <v-tab href="#tab-2" @click="Vact_responsable_data_inactivo()"
              >Inactivo</v-tab
            >
          </v-tabs>
          <c-btn-table-add
            v-if="permission.can_add"
            class="mr-1"
            @click="responsable_create(true)"
          ></c-btn-table-add>
          <c-btn-table-reload
            @click="
              tab === 'tab-1'
                ? Vact_responsable_data()
                : Vact_responsable_data_inactivo()
            "
          ></c-btn-table-reload>
        </v-toolbar-items>
      </v-toolbar>
      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1">
          <v-data-table
            :loading="Vget_responsable_load"
            :headers="headers"
            :items="Vget_responsable_data"
            :search="search"
            class="elevation-1"
            v-if="permission.can_view"
          >
            <template v-slot:[`item.accion`]="{ item }">
              <v-icon class="mr-2" @click="rowselect(item)">visibility</v-icon>
              <v-icon
                class="mr-2"
                @click="mupdate(item)"
                v-if="permission.can_update"
                >edit</v-icon
              >
              <v-icon @click="meliminar(item)" v-if="permission.can_delete"
                >do_not_disturb_on</v-icon
              >
            </template>
            <template v-slot:no-data>
              <v-alert :value="true" color="info" icon="info" outlined
                >No existe ningun registro</v-alert
              >
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item value="tab-2">
          <v-data-table
            :loading="Vget_responsable_load"
            :headers="headers"
            :items="Vget_responsable_data_inactivo"
            :search="search"
            class="elevation-1"
            v-if="permission.can_view"
          >
            <template v-slot:[`item.accion`]="{ item }">
              <v-icon class="mr-2" @click="rowselect(item)">visibility</v-icon>
              <v-icon
                class="mr-2"
                @click="mupdate(item)"
                v-if="permission.can_update"
                >edit</v-icon
              >
              <v-icon @click="meliminar(item)" v-if="permission.can_delete"
                >check_circle</v-icon
              >
            </template>
            <template v-slot:no-data>
              <v-alert :value="true" color="info" icon="info" outlined
                >No existe ningun registro</v-alert
              >
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-dialog v-model="dialogoeliminar" persistent max-width="290">
      <v-card>
        <v-card-text class="title"
          >Desea {{ objeto.estado ? "anular" : "reactivar" }}
          {{ objeto.descresponsable }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="eliminarcancelar"
            >Cancelar</v-btn
          >
          <v-btn color="blue darken-1" text @click="meliminarguardar"
            >Confirmar</v-btn
          >
        </v-card-actions>
        <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { RESPONSABLE } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";

export default {
  data() {
    return {
      tab: null,
      overlay: false,
      search: "",
      headers: [
        { text: "Nombre", align: "left", value: "descresponsable" },
        { text: "Telefono", align: "left", value: "teleresponsable" },
        {
          text: "Tipo",
          align: "left",
          value: "idtiporesponsable.desctiporesponsable",
        },
        { text: "Accion", value: "accion", sortable: false, align: "center" },
      ],
      datos: [],
      titulo: RESPONSABLE,
      objeto: "",
      dialogoeliminar: false,
      load: false,
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
    };
  },

  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.Vact_responsable_data();
    const permission = getPermission(RESPONSABLE).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  computed: {
    ...mapGetters("responsable", {
      abrirresponsable: "responsable_create",
      show: "responsable_show",
    }),
    ...mapGetters("responsable", [
      "Vget_responsable_data",
      "Vget_responsable_data_inactivo",
      "Vget_responsable_load",
    ]),
    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },
  methods: {
    ...mapActions("responsable", [
      "responsable_create",
      "responsable_update",
      "responsable_show",
      "Vact_responsable_data",
      "Vact_responsable_data_inactivo",
      "Vact_responsable_del",
    ]),
    meliminar(item) {
      this.objeto = Object.assign({}, item);
      this.dialogoeliminar = true;
    },

    eliminarcancelar() {
      this.objeto = "";
      this.dialogoeliminar = false;
    },
    rowselect(val) {
      this.responsable_update(val);
      this.responsable_show(true);
    },
    async meliminarguardar() {
      this.overlay = true;
      const id = this.objeto.idresponsable;
      const a = await this.Vact_responsable_del(id); //db.delete(`${this.url}${id}/`);
      if (a.estado == true) {
        this.$notify(a.info);
        this.dialogoeliminar = false;
        this.eliminarcancelar();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.overlay = false;
    },
    mupdate(val) {
      this.responsable_update(val);
      this.responsable_create(true);
    },
  },
};
</script>