
export default {
  getMaquinariaSalario: (state) => {
    return state.maquinariaSalario
  },
  getMaquinariaMonto: (state) => {
    return state.maquinariaMonto
  },
  isLoadingMaquinariaSalario: (state) => {
    return state.isLoadingMaquinariaSalario
  },
  isLoadingMaquinariaMonto: (state) => {
    return state.isLoadingMaquinariaMonto
  }
}
