<template>
  <div>
    <v-overlay :value="menu"></v-overlay>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="400"
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ml-1"
          v-bind="attrs"
          v-on="on"
          fab
          small
          text
          color="primary"
          outlined
        >
          <v-icon>settings</v-icon>
        </v-btn>
      </template>

      <v-card max-width="500">
        <c-overlay absolute :value="Vget_dashboard_load"></c-overlay>
        <v-form ref="dash" @submit.prevent="guardarQuery()">
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon large>settings</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>Guardar Consulta</v-list-item-title>
                <v-list-item-subtitle
                  >Crear Acceso Directo</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list dense>
            <v-list-item>
              <vue-text-field
                label="Titulo"
                v-model="query.descripcion"
              ></vue-text-field>
            </v-list-item>
          </v-list>

          <v-card-actions>
            <v-btn color="red" text @click="menu = false">
              <v-icon>clear</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="guardarQuery()">
              <v-icon>done</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { mensaje } from "@/services/notify.service";
export default {
  props: {
    url: {
      type: String,
    },
    vista: {
      type: Object
    }
  },
  data() {
    return {
      menu: false,
      query: {
        iddashboard: 0,
        descripcion: "",
        url: "",
        vista: null,
        tipo: 'Diario'
      },
      default: {
        iddashboard: 0,
        descripcion: "",
        url: "",
        vista: null,
        tipo: 'Diario'
      }
    };
  },
  computed: {
    ...mapGetters("dashboard", ["Vget_dashboard_load"]),
  },
  methods: {
    ...mapActions("dashboard", [
      "Vact_dashboard_menu_save",
      "Vact_dashboard_diariocaja",
      "Vact_dashboard_menu_update",
    ]),
    async guardarQuery() {
      if (!this.$refs.dash.validate()) return null;
      this.query.url = this.url;
      this.query.vista = JSON.stringify(this.vista);
      const a = await this.Vact_dashboard_menu_save(this.query);
      if (a.estado == true) {
        this.$notify(a.info);
        this.Vact_dashboard_diariocaja();
        this.query = JSON.parse(JSON.stringify(this.default));
        this.menu = false;
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
    },
  },
};
</script>

<style>
</style>