export default [
    {
        path: "/Gimnasio",
        component: () =>
            import(/* webpackChunkName: "Agricultura" */ "@/views/Gym/Root.vue"),
        children: [
            {
                path: "planillagimnasio",
                name: "Planilla Gimnasio",
                component: () =>
                    import(
                        /* webpackChunkName: "Mantenimiento" */ "@/views/Gym/Gymplanilla/Gymplanilla.vue"
                    )
            },
            {
                path: "mantenimiento",
                component: () =>
                    import(
                    /* webpackChunkName: "Mantenimiento" */ "@/views/Gym/Mantenimiento/Root.vue"
                    ),
                children: [
                    {
                        path: "tipogimnasio",
                        name: "Tipo Gimnasio",
                        component: () =>
                            import(
                        /* webpackChunkName: "Tipo Gimnasio" */ "@/views/Gym/Mantenimiento/Gymtipo/Gymtipo.vue"
                            ),
                    },
                ]
            }
        ]
    }
];