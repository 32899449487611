export default {

  item_update(state, request) {
    state.item_update = request;
  }, 
  item_show(state, request) {
    state.item_show = request;
  }, 

  item_senave(state, request) {
    state.item_senave = request;
  },
  item_senave_load(state, request) {
    state.item_senave_load = request;
  }, 

  item_data(state, request) {
    state.item_data = request;
  }, 
  item_lacteo(state, request) {
    state.item_lacteo = request;
  }, 
  item_ensilaje(state,request){
    state.item_ensilaje = request;
  },
  item_stockeable(state, request) {
    state.item_stockeable = request;
  }, 
  item_tipoitem_data(state, request) {
    state.item_tipoitem_data = request;
  }, 
  item_stock2(state, request) {
    state.item_stock2 = request;
  }, 
  item_stock_offline(state, request) {
    state.item_stock_offline = request;
  }, 
  item_vivo(state, request) {
    state.item_vivo = request;
  }, 
  item_agrostock(state, request) {
    state.item_agrostock = request;
  }, 
  item_agrostock_todos(state, request) {
    state.item_agrostock_todos = request;
  },
  item_siembra(state, request) {
    state.item_siembra = request;
  },
  item_siembra_default(state, request) {
    state.item_siembra_default = request;
  },
  item_medicina(state, request) {
    state.item_medicina = request;
  }, 
  item_load(state, request) {
    state.item_load = request;
  }, 
  item_load_save(state, request) {
    state.item_load_save = request;
  }, 
}