import echarts from "echarts/lib/echarts";
export default ({ y, data, dark }) => {
  const x = data.map((x, i) =>
    i === 0
      ? `${x.item?.toFixed(1)} mm/hr\n ${x.title}`
      : `${x.item?.toFixed(1)} mm\n ${x.title}`
  );
  return {
    //backgroundColor: "#0f375f",

    legend: {
      data: [],
      textStyle: {
        color: dark ? "#eee" : "#000",
      },
    },
    xAxis: {
      data: x,
      axisLine: {
        lineStyle: {
          color: dark ? "#eee" : "#000",
        },
      },
    },
    yAxis: {
      splitLine: { show: false },
      min: 0,
      axisLine: {
        lineStyle: {
          color: dark ? "#eee" : "#000",
        },
      },
    },
    series: [
      {
        name: "bar",
        type: "bar",
        barWidth: 40,
        itemStyle: {
          barBorderRadius: 5,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: "#14c8d4" },
            { offset: 1, color: "#43eec6" },
          ]),
        },
        data: data.map(({ item }) => item),
      },
      {
        name: "line",
        type: "bar",
        barGap: "-100%",
        barWidth: 40,
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: "rgba(20,200,212,0.5)" },
            { offset: 0.2, color: "rgba(20,200,212,0.2)" },
            { offset: 1, color: "rgba(20,200,212,0)" },
          ]),
        },
        z: -12,
        data: y,
      },
      {
        name: "dotted",

        type: "pictorialBar",
        symbol: "rect",
        itemStyle: {
          color: "#ccc",
        },
        symbolRepeat: true,
        symbolSize: [40, 5],
        symbolMargin: 2,
        z: -10,
        data: y,
      },
    ],
  };
};

export const presion = (value) => {
  return {
    series: [
      {
        min: (value - 100)?.toFixed(0),
        max: (value + 100)?.toFixed(0),
        type: "gauge",
        radius: "80%",
        itemStyle: {
          color: "#318CE7",
        },
        axisLine: {
          lineStyle: {
            color: [[2, "#E6EBF8"]],
            width: -5,
          },
        },
        axisLabel: {
          fontWeight: "bolder",
          color: "#a0a0a0",
        },
        axisTick: {
          length: 6,

          lineStyle: {
            color: "#000",
            shadowColor: "#fff",
            shadowBlur: 10,
          },
        },
        splitLine: {
          length: 10,
          lineStyle: {
            width: 2,
            color: "#000",
            shadowColor: "#fff",
            shadowBlur: 20,
          },
        },
        pointer: {
          shadowColor: "#fff",
          shadowBlur: 10,
        },
        title: {
          offsetCenter: [0, "60%"],
          textStyle: {
            fontWeight: "bolder",
            fontSize: 20,
            fontStyle: "italic",
            color: "#606060",
          },
        },
        detail: {
          offsetCenter: [0, "40%"],
          textStyle: {
            fontWeight: "bolder",
            fontSize: 25,
            color: "#606060",
          },
        },
        data: [{ value: value?.toFixed(0), name: "mmHg" }],
      },
    ],
  };
};
