var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isActivemap)?_c('div',[_c('v-card',[_c('v-container',{class:((_vm.minimizar
            ? "position-dialog-minimize"
            : _vm.displaySM
            ? "position-dialog-sm"
            : "position-dialog") + " overflow-y-auto px-4 pt-1 mt-0"),attrs:{"fluid":""}},[_c('v-row',{staticStyle:{"max-height":"83vh"}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":""}},[_vm._v(" AgroTech "),_c('widgets-Favorite',{attrs:{"ruta":"/agricultura/campo","formulario":"Mapa de Lotes"}}),_c('v-btn',{attrs:{"small":"","fab":"","text":"","color":"primary"},on:{"click":function($event){_vm.openHelp = true}}},[_c('v-icon',[_vm._v("help")])],1),_c('v-btn',{attrs:{"small":"","fab":"","text":"","outlined":"","color":"red","to":"/agricultura/campo/plagas"}},[_c('v-icon',[_vm._v("pest_control")])],1),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","fab":"","text":"","color":"primary"},on:{"click":function($event){return _vm.navegar()}}},[_c('v-icon',[_vm._v("arrow_back")])],1),(
                _vm.$route.path === '/agricultura/campo' && _vm.permission.can_add
              )?_c('v-btn',{attrs:{"small":"","text":"","fab":"","color":"primary","to":"/agricultura/campo/add"}},[_c('v-icon',[_vm._v("add")])],1):_vm._e(),_c('v-btn',{attrs:{"small":"","fab":"","text":"","color":"primary"},on:{"click":function($event){_vm.minimizar ? (_vm.minimizar = false) : (_vm.minimizar = true)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.minimizar ? "expand_more" : "expand_less"))])],1)],1),_c('v-divider'),(_vm.permission_alert)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v("Error!!! Este usuario no tiene permisos en esta ventana")]):_vm._e(),_c('router-view',{directives:[{name:"show",rawName:"v-show",value:(!_vm.minimizar),expression:"!minimizar"}]})],1)],1)],1)],1),_c('v-card',[_c('GoogleMapLoader',{class:_vm.displaySM ? "travel-map-sm" : "travel-map",attrs:{"mapConfig":_vm.mapConfig,"libraries":"drawing"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var google = ref.google;
              var map = ref.map;
return [_c('AgroMap',{attrs:{"google":google,"map":map},on:{"mostrar":function($event){return _vm.mostrar()}}}),_c('AgroSensor',{attrs:{"google":google,"map":map}}),_c('AgroPest',{attrs:{"google":google,"map":map}}),_c('SensorMap',{attrs:{"google":google,"map":map}})]}}],null,false,1330098021)})],1),_c('v-expand-transition',[(_vm.openHelp)?_c('v-card',{staticClass:"help-dialog overflow-y-auto",staticStyle:{"max-height":"590px"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Ayuda")]),_c('v-spacer'),_c('v-btn',{attrs:{"fab":"","text":"","small":""},on:{"click":function($event){_vm.openHelp = false}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("clear")])],1)],1),_c('v-divider'),_c('v-card-text',[(_vm.$route.path === '/agricultura/campo')?_c('div',[_c('Controles',{tag:"component"})],1):_vm._e(),(_vm.$route.path === '/agricultura/campo/add')?_c('div',[_c('CrearCampo',{tag:"component"}),_c('v-progress-linear',{attrs:{"rounded":"","height":"3","value":"100"}}),_c('CrearLote',{tag:"component"})],1):_vm._e(),(_vm.$route.name === 'Agro Detalle')?_c('div',[_c('Detail')],1):_vm._e()])],1):_vm._e()],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }