<template>
  <v-dialog :value="value" persistent max-width="800" @keydown.esc="cancelar()">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>{{operacion ? 'Registrar Tarjeta de Credito' : 'Modificar Tarjeta de Credito'}}</v-toolbar-title>
      </v-toolbar>
      <v-divider></v-divider>
      <v-container>
        <v-form ref="form">
          <v-row dense>
            <v-col cols="12">
              <vue-text-field
                id="tarjetaCreditoInput1"
                label="Descripcion"
                v-model="data.descripcion"
                @keyup.native.enter="next(1,data.descripcion)"
              ></vue-text-field>
            </v-col>
            <v-col cols="12">
              <vue-autocomplete-generic
                id="tarjetaCreditoInput2"
                action="banco/Vact_banco_data"
                getter="banco/Vget_banco_data"
                load="banco/Vget_banco_load"
                pk="idbanco"
                label="Seleccione Banco Emisor"
                title="descbanco"
                validar="idbanco"
                @sync-input="search = $event"
                permission="Banco"
                :create="true"
                @create="formulario.banco = $event"
                v-model="data.idbanco.idbanco"
                @keyup.native.enter="next(2,data.idbanco.idbanco)"
              />
              <div v-if="formulario.banco">
                <Apertura-Banco-Create
                  v-model="formulario.banco"
                  :sync-input="search"
                  @sync-input="data.idbanco = $event ,next(1,'-')"
                />
              </div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12">
              <vue-text-currency
                id="tarjetaCreditoInput3"
                label="Limite de Credito"
                :rules="true"
                v-model="data.credito"
                @keyup.native.enter="next(3,data.credito)"
              ></vue-text-currency>
            </v-col>
            <v-col cols="12">
              <vue-autocomplete-moneda-day
                label="Moneda"
                id="tarjetaCreditoInput4"
                v-model="data.idmoneda"
                :chips="false"
                @keyup.native.enter="next(4,data.idmoneda.idmoneda)"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-container>

      <v-card-actions>
        <v-btn text color="red" @click="cancelar()">
          <v-icon>clear</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text color="primary" id="tarjetaCreditoInputaceptar" @click="guardar()">
          <v-icon>done</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    <c-overlay :value="Vget_tarjetacredito_save_load"></c-overlay>
  </v-dialog>
</template>

<script>
import { focus } from "@/services/focus.service";
import { mapActions, mapGetters } from "vuex";
import { mensaje } from "@/services/notify.service";
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    datos: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      operacion: true,
      formulario: {
        banco: false,
        cheque: false,
      },
      option: {
        diferido: false,
      },
      data: {
        idtarjeta: 0,
        estado: true,
        credito: "",
        descripcion: "",
        idbanco: {
          idbanco: "",
        },
        idmoneda: {
          idmoneda: "",
        },
      },
      default: {
        idtarjeta: 0,
        estado: true,
        credito: "",
        descripcion: "",
        idbanco: {
          idbanco: "",
        },
        idmoneda: {
          idmoneda: "",
        },
      },
    };
  },
  mounted() {
    if (this.datos != null) {
      const temp = { ...this.datos, ...{ idcontacto: "" } };
      this.data = JSON.parse(JSON.stringify(temp));
      return (this.operacion = false);
    }
    this.next(0, "-");
  },
  computed: {
    ...mapGetters("tarjetacredito", ["Vget_tarjetacredito_save_load"]),
  },
  methods: {
    ...mapActions("tarjetacredito", [
      "Vact_tarjetacredito_data",
      "Vact_tarjetacredito_save",
      "Vact_tarjetacredito_update",
    ]),

    cancelar() {
      this.Vact_tarjetacredito_data();
      this.$emit("input", false);
      this.$emit("datos", null);
      this.operacion = true;
    },
    next(index, data) {
      focus.nextidName("tarjetaCreditoInput", index, data);
    },
    async guardar() {
      if (!this.$refs.form.validate())
        return this.$notify(mensaje.error("Verifique los datos ingresados"));
      let a;
      if (this.operacion) a = await this.Vact_tarjetacredito_save(this.data);
      if (!this.operacion)
        a = await this.Vact_tarjetacredito_update({
          id: this.datos.idtarjeta,
          data: this.data,
        });

      if (a.estado == true) {
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$notify(a.info);
        this.$refs.form.resetValidation();
        this.next(0, "-");
        if (!this.operacion) this.cancelar();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
    },
  },
};
</script>

<style>
</style>