import { db } from "@/services/db.service";
export default {
    async preventaagro_update({commit},request){
        commit('preventaagro_update',request);
    },
    async Vact_preventaagro_data({commit}){
        commit('preventaagro_load',true);
        try {
            const data = await db.get('/apiventaagro/?preventa=yes');
            commit('preventaagro_data',data);
            commit('preventaagro_load',false);
            return true
        } catch (e) {
            commit('preventaagro_load',false);
            return false
        } 
    },
    async Vact_preventaagro_data_byId({commit},id){
        commit('preventaagro_load',true);
        try {
            const data = await db.get('/apiventaagro/'+id+'/?preventa=yes');
            commit('preventaagro_data_byId',data);
            commit('preventaagro_load',false);
            return true
        } catch (e) {
            commit('preventaagro_load',false);
            return false
        } 
    },
    async Vact_preventaagro_del({dispatch},id){
        try {
            const data = await db.delete('/apiventaagro/'+ id+ '/');
            dispatch('Vact_preventaagro_data');
            return data
        } catch (e) {
            return false
        } 
    },
    async Vact_preventaagro_save({commit},data){
        commit('preventaagro_save_load',true);
        try {
            const resp = await db.save('/apiventaagro/?preventa=yes',data);
            commit('preventaagro_save_load',false);
            return resp;
        } catch (e) {
            commit('preventaagro_save_load',false);
            return e
        } 
    },
    async Vact_preventaagro_update({commit},{id, data}){
        commit('preventaagro_save_load',true);
        try {
            const resp = await db.update('/apiventaagro/'+id+'/?preventa=yes',data);
            commit('preventaagro_save_load',false);
            return resp;
        } catch (e) {
            commit('preventaagro_save_load',false);
            return e
        } 
    },
    async Vact_preventaagro_convertir({commit},{id, data}){
        commit('preventaagro_save_load',true);
        try {
            const resp = await db.update('/apiventaagro/'+id+'/?preventa=yes&convertir=yes',data);
            commit('preventaagro_save_load',false);
            return resp;
        } catch (e) {
            commit('preventaagro_save_load',false);
            return e
        } 
    },
}
