export default {
  notacreditocompra_data(state, request) {
    state.notacreditocompra_data = request;
  }, 
  notacreditocompra_load(state, request) {
    state.notacreditocompra_load = request;
  }, 
  notacreditocompra_save_load(state, request) {
    state.notacreditocompra_save_load = request;
  }, 
}