export default [{
    path: '*',
    component: () => import( /* webpackChunkName: "Dashboard" */ '@/views/Dashboard/Principal.vue')
  },
  {
    path: '/',
    name: 'Dashboard',
    component: () => import( /* webpackChunkName: "Dashboard" */ '@/views/Dashboard/Principal.vue')
  },
  {
    path: '/diariocaja',
    name: 'Diario de Caja',
    component: () => import( /* webpackChunkName: "Dashboard" */ '@/views/DiarioCaja/Principal.vue'),
    children: [
      {
        path: 'asiento',
        meta: {
          tab: 0
        },
        component:  () => import( /* webpackChunkName: "List" */ '@/views/DiarioCaja/Asiento/List.vue'),
        children: [
          {
            path: 'add',
            meta: {
              tab: 0
            },
            component:  () => import( /* webpackChunkName: "List" */ '@/views/DiarioCaja/Asiento/Crud.vue'),
          },
          {
            path: 'edit/:id',
            name: 'asientoEdit',
            meta: {
              tab: 0
            },
            component:  () => import( /* webpackChunkName: "List" */ '@/views/DiarioCaja/Asiento/Crud.vue'),
          }
        ]
      },
      {
        path: 'rubro',
        meta: {
          tab: 1
        },
        component:  () => import( /* webpackChunkName: "List" */ '@/views/DiarioCaja/Rubro/Rubro.vue'),
        children: [
          {
            path: 'add',
            meta: {
              tab: 1
            },
            component:  () => import( /* webpackChunkName: "List" */ '@/views/DiarioCaja/Rubro/Crud.vue'),
          },
          {
            path: 'edit/:id',
            meta: {
              tab: 1
            },
            component:  () => import( /* webpackChunkName: "List" */ '@/views/DiarioCaja/Rubro/Crud.vue'),
          }
        ]
      },
      {
        path: 'division',
        meta: {
          tab: 2
        },
        component:  () => import( /* webpackChunkNamße: "List" */ '@/views/DiarioCaja/Division/Division.vue'),
        children: [
          {
            path: 'add',
            meta: {
              tab: 2
            },
            component:  () => import( /* webpackChunkName: "List" */ '@/views/DiarioCaja/Division/Crud.vue'),
          },
          {
            path: 'edit/:id',
            meta: {
              tab: 2
            },
            component:  () => import( /* webpackChunkName: "List" */ '@/views/DiarioCaja/Division/Crud.vue'),
          }
        ]
      },
      {
        path: 'cuentadiario',
        meta: {
          tab: 3
        },
        component:  () => import( /* webpackChunkName: "List" */ '@/views/DiarioCaja/Cuenta/Cuenta.vue'),
        children: [
          {
            path: 'add',
            meta: {
              tab: 3
            },
            component:  () => import( /* webpackChunkName: "List" */ '@/views/DiarioCaja/Cuenta/Crud.vue'),
          },
          {
            path: 'edit/:id',
            meta: {
              tab: 3
            },
            component:  () => import( /* webpackChunkName: "List" */ '@/views/DiarioCaja/Cuenta/Crud.vue'),
          }
        ]
      },
      {
        path: 'referencia',
        meta: {
          tab: 4
        },
        component:  () => import( /* webpackChunkName: "List" */ '@/views/DiarioCaja/Referencia/Referencia.vue'),
        children: [
          {
            path: 'add',
            meta: {
              tab: 4
            },
            component:  () => import( /* webpackChunkName: "List" */ '@/views/DiarioCaja/Referencia/Crud.vue'),
          },
          {
            path: 'edit/:id',
            meta: {
              tab: 4
            },
            component:  () => import( /* webpackChunkName: "List" */ '@/views/DiarioCaja/Referencia/Crud.vue'),
          }
        ]
      },
      {
        path: 'reporteavanzado',
        meta: {
          tab: 5
        },
        component:  () => import( /* webpackChunkName: "List" */ '@/views/DiarioCaja/Reporte/Reporte.vue'),
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import( /* webpackChunkName: "Login" */ '@/views/Login.vue'),
    meta: {
      public: true, //Establecer esta ruta como publica
      onlyWhenLoggedOut: true
    }
  },
]