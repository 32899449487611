export default {
  motivotraslado_create(state, request) {
    state.motivotraslado_create = request;
  }, 
  motivotraslado_update(state, request) {
    state.motivotraslado_update = request;
  },   
  motivotraslado_reload(state, request) {
    state.motivotraslado_reload = request;
  },
  motivotraslado_show(state, request) {
    state.motivotraslado_show = request;
  }
}