
export default {

  responsable_create: (state) => {
    return state.responsable_create
  },
  responsable_update: (state) => {
    return state.responsable_update
  },
  responsable_show: (state) => {
    return state.responsable_show
  },
  
  Vget_responsable_data: (state) => {
    return state.responsable_data
  },
  Vget_responsable_data_inactivo: (state) => {
    return state.responsable_data_inactivo
  },
  Vget_responsable_load: (state) => {
    return state.responsable_load
  },
  Vget_responsable_save_load: (state) => {
    return state.responsable_save_load
  },
}
