
export default {

  Vget_magnitud_data: (state) => {
    return state.magnitud_data
  },
  Vget_magnitud_load: (state) => {
    return state.magnitud_load
  }
}
