<template>
  <div>
    <v-dialog v-model="value" max-width="700px" persistent @keydown.esc="cancelar()">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Detalles </v-toolbar-title>
        </v-toolbar>
        <v-container>
          <v-row dense>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                dense
                type="text"
                id="input3"
                v-model="data.idanimal.nombanimal"
                label="Animal"
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                dense
                id="input2"
                v-model="data.fecssecado"
                label="Fecha de inicio del secado"
                persistent-hint
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                dense
                id="input2"
                v-model="data.dias"
                label="Dias de secado"
                persistent-hint
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                dense
                type="text"
                id="input3"
                v-model="data.idanimal.rpanimal"
                label="RP Animal"
                outlined
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                dense
                type="text"
                id="input3"
                v-model="data.idanimal.idestadoanimal.descestadoanimal"
                label="Estado del animal"
                outlined
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <v-text-field
                dense
                type="text"
                id="input3"
                v-model="data.idanimal.idraza.descraza"
                label="Raza del animal"
                outlined
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <v-text-field
                dense
                type="text"
                id="input3"
                v-model="data.idanimal.idprocedencia.descprocedencia"
                label="Procedencia del animal"
                outlined
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <v-text-field
                dense
                type="text"
                id="input3"
                v-model="data.idanimal.idlote.desclote"
                label="Lote"
                outlined
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <v-text-field
                dense
                type="text"
                id="input3"
                v-model="data.idanimal.iddeposito.desdeposito"
                label="Deposito"
                outlined
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="12">
              <v-text-field
                dense
                type="text"
                id="input3"
                v-model="data.comnsecado"
                label="Comentarios"
                outlined
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions>
          <c-btn-crud-close @click="cancelar()"></c-btn-crud-close>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props:{
    value: Boolean,
    props: Object
  },
  data:()=>({
      data: {
        fecssecado: "",
        fechsecado: "2000-01-01",
        fechaactual: "",
        comnsecado: "",
        idanimal: {
          idanimal: "",
          nombanimal: "",
          rpanimal: "",
          idestadoanimal: {
            descestadoanimal: "",
          },
          idprocedencia: {
            descprocedencia: "",
          },
          idraza: {
            descraza: "",
          },
          idlote: {
            desclote: "",
          },
          iddeposito: {
            desdeposito: "",
          },
        },
      },
  }),

  created() {
    if(this.props) this.data = JSON.parse(JSON.stringify(this.props));
  },
  watch: {
    $route() {
      return this.cancelar();
    },
  },
  methods: {
    cancelar() {
      this.$emit('input', false);
      this.$emit('reset',null);
    }
  },
};
</script>
