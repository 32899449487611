<template>
  <v-card outlined class="pa-2">
    <v-row dense transition="slide-y-reverse-transition">
      <v-col cols="12" sm="4">
        <v-radio-group v-model="data.porcentaje" @change="updateProps()">
          <template v-slot:label>
            <div>
              Como desea debitar el
              <strong>Alquiler</strong>
            </div>
          </template>
          <v-radio label="Por % (porcentaje)" :value="true"></v-radio>
          <v-radio label="Por Kg" :value="false"></v-radio>
           <v-radio label="En Efectivo" :value="'null'"></v-radio>
        </v-radio-group>
      </v-col>

      <v-col cols="12" sm="3" class="mt-7">
        <c-text-currency
          :label="
            data.porcentaje === true ?  'Ingrese el Porcentaje' :  ( data.porcentaje === false ? 'Ingrese la Cantidad' : 'Ingrese el monto en efectivo')
          "
          v-model="data.cantidad"
          :suffix="data.porcentaje === true ? '%' : ( data.porcentaje === false ? 'Kg' : '$$')"
          @keyup.native="updateProps()"
        ></c-text-currency>
        <v-checkbox v-if="data.porcentaje !== 'null'" @change="updateProps()" class="my-n2" v-model="data.descstock" label="Descontar Stock?"></v-checkbox>
      </v-col>
      <v-spacer></v-spacer>
      <v-divider vertical></v-divider>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="4" class="mt-7">
        <v-simple-table dense>
          <template v-slot:default>
            <tbody>
              <tr>
                <td>Total a pagar en Efectivo:</td>
                <td class="text-end font-weight-bold">
                  {{ toCurrency(efectivo) }}
                </td>
              </tr>
              <tr>
                <td>Total a pagar en Kg:</td>
                <td class="text-end font-weight-bold">
                  {{ toCurrency(granos) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { currency } from "@/services/util/number.service";
export default {
  props: {
    produccion: { type: [Number, String], default: 0 },
    precio: { type: [Number, String], default: 0 },
    porcentaje: Boolean,
    cantidad: [Number, String],
    descstock: Boolean
  },
  data() {
    return {
      data: {
        descstock: false,
        porcentaje: true,
        cantidad: "",
      },
    };
  },
  computed: {
    efectivo() {
      if (this.data.cantidad === "") return 0;
      let precio = Number(this.precio) / 1000;
      let produc = Number(this.produccion);
      let cantid = Number(this.cantidad);
      return this.data.porcentaje === true
        ? (produc * precio * cantid) / 100
        : (this.data.porcentaje === false ? cantid * precio : cantid);
    },
    granos() {
      if (this.data.cantidad === "") return 0;
      let produc = Number(this.produccion);
      let cantid = Number(this.cantidad);
      return this.data.porcentaje === true ? (produc * cantid) / 100 :  (this.data.porcentaje === false ? cantid : 0);
    },
  },
  mounted() {
    const porcentaje = this.porcentaje === null ? 'null' : this.porcentaje;
    this.data.porcentaje = porcentaje;
    this.data.cantidad = this.cantidad;
    this.data.descstock = this.descstock;
  },
  methods: {
    toCurrency(val) {
      return currency(val);
    },
    updateProps() {
      const porcentaje = this.data.porcentaje === 'null' ? null : this.data.porcentaje;
      this.$emit("porcentaje", porcentaje);
      this.$emit("cantidad", this.data.cantidad);
      this.$emit("descstock", this.data.descstock);
    },
  },
};
</script>

<style></style>
