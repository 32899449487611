
<template>
  <div>
    <div v-if="crudADD">
      <Venta-Timbrado-create
        v-model="crudADD"
        :datos="datos"
        @datos="datos = $event"
      />
    </div>
    <div v-if="crudSHOW">
      <Venta-Timbrado-show
        v-model="crudSHOW"
        :datos="datos"
        @datos="datos = $event"
      />
    </div>
    <v-card>
      <v-alert type="error" v-if="permission_alert"
        >Error!!! Este usuario no tiene permisos en esta ventana</v-alert
      >
      <v-toolbar flat>
        <v-toolbar-title>{{ titulo }}</v-toolbar-title>
        <widgets-Favorite ruta="/timbrado" formulario="Timbrado" />
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Busqueda"
          single-line
          hide-details
          v-if="permission.can_view"
        ></v-text-field>
        <v-btn
          color="primary"
          small
          fab
          dark
          @click="crudADD = true"
          v-if="permission.can_add"
        >
          <v-icon dark>add</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-table
        :loading="Vget_timbrado_load"
        :headers="headers"
        :items="Vget_timbrado_data"
        :search="search"
        class="elevation-1"
        v-if="permission.can_view"
      >
        <template v-slot:item[`accion`]="props">
          <v-icon small @click="rowselect(props.item)">visibility</v-icon>
          <v-icon
            small
            @click="mupdate(props.item)"
            v-if="permission.can_update"
            >edit</v-icon
          >
          <v-icon
            small
            @click="meliminar(props.item)"
            v-if="permission.can_delete"
            >delete</v-icon
          >
        </template>
        <template v-slot:no-data>
          <v-alert :value="true" color="info" icon="info" outlined
            >No existe ningun registro</v-alert
          >
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogoeliminar" persistent max-width="350">
      <v-card>
        <v-card-title>
          <v-icon left>info</v-icon>
          <span class="title font-weight-light">Ventana de Confirmacion</span>
        </v-card-title>
        <v-card-text>Desea eliminar {{ objeto.timbrado }}?</v-card-text>
        <v-card-actions>
          <v-btn color="red" text @click="eliminarcancelar">
            <v-icon>clear</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="eliminarguardar">
            <v-icon>done</v-icon>
          </v-btn>
        </v-card-actions>
        <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import { vuex } from "@/services/vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mapActions, mapGetters } from "vuex";
import { mensaje } from "@/services/notify.service";
import { TIMBRADO } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";

export default {
  data() {
    return {
      titulo: TIMBRADO,
      crudADD: false,
      crudSHOW: false,
      search: "",
      datos: null,
      objeto: {},
      overlay: false,
      dialogoeliminar: false,
      permission_alert: false,
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
      headers: [
        { text: "Timbrado", align: "left", value: "timbrado" },
        { text: "Numero Inicio", align: "left", value: "numero_inicio" },
        { text: "Numero Final", align: "left", value: "numero_final" },
        { text: "Fecha I", align: "left", value: "fecha_inicio" },
        { text: "Fecha F", align: "left", value: "fecha_final" },
        {
          text: "Establecimiento",
          align: "left",
          value: "idestablecimiento.establecimiento",
        },
        {
          text: "Punto Expedicion",
          align: "left",
          value: "idpuntoexpedicion.puntoexpedicion",
        },
        { text: "Accion", value: "accion", sortable: false, align: "right" },
      ],
    };
  },

  computed: {
    ...mapGetters("timbrado", ["Vget_timbrado_data", "Vget_timbrado_load"]),
    open_create() {
      return vuex.get(this.data.vuex.create);
    },
    open_view() {
      return vuex.get(this.data.vuex.show);
    },
  },
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    const permission = getPermission(TIMBRADO).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
    this.Vact_timbrado_data();
  },
  methods: {
    ...mapActions("timbrado", ["Vact_timbrado_data", "Vact_timbrado_del"]),
    rowselect(val) {
      this.datos = JSON.parse(JSON.stringify(val));
      this.crudSHOW = true;
    },
    mupdate(val) {
      this.datos = JSON.parse(JSON.stringify(val));
      this.crudADD = true;
    },
    meliminar(item) {
      this.objeto = Object.assign({}, item);
      this.dialogoeliminar = true;
    },
    eliminarcancelar() {
      this.objeto = {};
      this.dialogoeliminar = false;
    },
    async eliminarguardar() {
      this.overlay = true;
      const id = this.objeto.idtimbrado;
      const a = await this.Vact_timbrado_del(id);
      if (a.estado == true) {
        this.$notify(a.info);
        this.eliminarcancelar();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.overlay = false;
    },
  },
};
</script>