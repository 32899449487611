<template>
  <c-dialog-confirmation
    :value="value"
    @cancel="close()"
    :loading="isLoading"
    @done="save()"
    >
    <div class="subtitle-2">Produce Leche?</div>
    Desea agregar al animal <strong>{{props.rpanimal}}</strong> a la nomina de <strong>Produccion?</strong></c-dialog-confirmation
  >
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    value: Boolean,
    props: Object,
  },
  computed:{
      ...mapGetters("animal",['isLoading'])
  },
  methods: {
    ...mapActions('animal',['setNomina']),
    close() {
      this.$emit("input", false);
      this.$emit("reset")
    },
    async save(){
        const response = await this.setNomina(this.props.idanimal);
        if(response.success)this.close();
    }
  },
};
</script>
