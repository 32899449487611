<template>
    <div>
        <InputAutocomplete 
            :label="label" 
            item-value="idfinanza_categoria" 
            item-text="nombre" 
            :id="id" 
            :ref="id" 
            dense 
            :outlined="outlined"
            :rounded="rounded" 
            :filled="filled" 
            :readonly="readonly" 
            :value="value" 
            :return-object="returnObject"
            :items="getCategoria" 
            :multiple="multiple" 
            :loading="isLoadingCategoria" 
            :permission="permission"
            @sync-input="formulario.search = $event" 
            @create="formulario.crud = true" 
            @input="$emit('input', $event)" />
        <Crud 
            v-if="formulario.crud" 
            v-model="formulario.crud" 
            :sync-input="formulario.search"
            @sync-input="syncInputHandler($event)" />
    </div>
</template>

<script>
import InputAutocomplete from "@/ncomponents/InputAutocomplete";
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { FINANZA } from "@/constants/forms";
import { PUEDE_AGREGAR } from "@/constants/permissions";
import Crud from "./Crud.vue";
export default {
    props: {
        id: String,
        value: [String, Object, Number],
        returnObject: Boolean,
        readonly: Boolean,
        rounded: Boolean,
        filled: Boolean,
        multiple: Boolean,
        outlined: {
            type: Boolean,
            default: true
        },
        label: {
            type: String,
            default: 'Categoria'
        }
    },
    components: {
        InputAutocomplete,
        Crud,
    },
    data: () => ({
        formulario: {
            crud: false,
            search: "",
        }
    }),
    watch: {
        value(val){
            if (val.presupuesto == true) {
                this.fetchPresupuestoDisponible({categoria: val.idfinanza_categoria})
            }
        }
    },
    computed: {
        ...mapGetters("finanza", ["getCategoria", "isLoadingCategoria"]),
        permission: () => getPermission(FINANZA).permiso[PUEDE_AGREGAR],
    },
    mounted() {
        this.fetchCategoria()
    },
    methods: {
        ...mapActions("finanza", ["fetchCategoria", "fetchPresupuestoDisponible"]),
        syncInputHandler(event) {
            if (this.returnObject) this.$emit("input", event);
            if (!this.returnObject) this.$emit("input", event.idfinanza_categoria);
            this.$emit("nextfocus");
            this.fetchCategoria();
        }
    }
}
</script>