import jsPDF from 'jspdf'
import 'jspdf-autotable'
import store from '@/store'
const currencyFormat = (value) => {
    return new Intl.NumberFormat({ maximumSignificantDigits: 3 }).format(
        value
    );
}
const exportAvanzado = (headers, parametros) => {
    const datos = JSON.parse(JSON.stringify(parametros));
    var doc = new jsPDF();
    doc.setFontSize(10);
    store.getters['empresa/Vget_empresa_active'].forEach(empresa => {
        if (empresa.est_emp_tam_usu)
            return doc.text(empresa.idempresa.descempresa, 15, 20);

    })
    //doc.setFontType("bold");
    doc.text(datos.mode + ': ', 15, 27);
    doc.text('Ruc: ', 15, 34);
    //doc.setFontType("default");
    doc.text(datos.persona, 35, 27);
    doc.text(datos.ruccontacto, 35, 34);
    doc.setFontSize(14);
    doc.text(datos.titulo || 'Extracto', doc.internal.pageSize.width / 2, 20, { align: 'center' });

    doc.setLineWidth(1);
    doc.line(13, 38, doc.internal.pageSize.width - 12, 38);
    let startY = 48;
    datos.datos.map((moneda,i) => {
        if(i>0) startY = doc.previousAutoTable.finalY + 15;
        doc.setFontSize(11);
        doc.text('Extracto en moneda '+moneda.moneda, 15, startY - 3)
        moneda.detalle.push(moneda.total)
        doc.autoTable({
            theme: 'grid',
            head: [headers.map(x => {
                return x.text
            })],
            body: moneda.detalle.map(det => {
                let titulo = 'TOTAL'
                if(det.vencido === 'Si'){
                    titulo = 'Vencido'
                }
                if(det.vencido === 'No'){
                    titulo = 'Pendiente'
                }
                return [titulo, det.fecha, det.numf, currencyFormat(det.importe), currencyFormat(det.saldo)]
            }),
            headStyles: {
                textColor: [0, 0, 0],
                fillColor: [255, 255, 255],
                fontSize: 8,
            },
            columnStyles: { 3: { halign: 'right', fontStyle: 'bold' }, 4: { halign: 'right', fontStyle: 'bold' } },
            startY: startY,
            showHead: 'firstPage',
            styles: { overflow: 'hidden' },
            didParseCell: (data) => {
                if (data.row.index == moneda.detalle.length - 1 && data.column.index == 0) {
                    data.row.cells[0].colSpan = 3;
                    Object.entries(data.row.cells).forEach((v) => {
                        v[1].styles.fontStyle = 'bold';
                    })
                }
            }
        })

    })
    doc.save('Extracto.pdf');
}
export default exportAvanzado;
