export default {
    async tipoparto_create({commit},request){
        commit('tipoparto_create',request);
    },
    async tipoparto_update({commit},request){
        commit('tipoparto_update',request);
    },
    async tipoparto_reload({commit},request){
        commit('tipoparto_reload',request);
    },
    async tipoparto_show({commit},request){
        commit('tipoparto_show',request);
    },
    async tipoparto_key({commit},request){
        commit('tipoparto_key',request);
    },
}
