<template>
  <div>
    <v-dialog
      :value="value"
      max-width="900px"
      persistent
      @keydown.esc="cancelar()"
    >
      <v-form ref="form" lazy-validation>
        <v-card>
          <v-toolbar flat dense>
            <v-toolbar-title>Detalles de Venta</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="2" sm="3">
                <c-text-field
                  readonly
                  dense
                  outlined
                  label="Tipo Factura"
                  v-model="data.idtipofactura.desctipofactura"
                />
              </v-col>
              <v-col cols="12" md="2" sm="3">
                <c-text-date
                  outlined
                  dense
                  readonly
                  label="Fecha"
                  v-model="data.fechventa"
                />
              </v-col>
              <v-col md="3"></v-col>
              <v-col cols="12" md="2" sm="2">
                <c-text-field
                  readonly
                  dense
                  outlined
                  label="Timbrado"
                  v-model="data.idtimbrado.timbrado"
                />
              </v-col>

              <v-col cols="12" md="3" sm="3">
                <c-text-field
                  dense
                  outlined
                  readonly
                  label="Factura"
                  :value="datos_factura"
                />
              </v-col>
              <v-col cols="12" md="6" sm="6">
                <c-text-field
                  readonly
                  dense
                  outlined
                  label="Cliente"
                  :value="data.idcontacto.idcontacto.desccontacto"
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
                sm="6"
                v-if="data.ventares[0] != undefined"
              >
                <c-text-field
                  readonly
                  dense
                  outlined
                  label="Responsable"
                  :value="data.ventares[0].idresp.idresp.desccontacto"
                />
              </v-col>
              <v-col
                cols="12"
                :md="data.ventares[0] != undefined ? 12 : 6"
                :sm="data.ventares[0] != undefined ? 12 : 6"
              >
                <c-text-field
                  readonly
                  dense
                  outlined
                  label="Moneda"
                  :value="data.idmoneda.descmoneda"
                />
              </v-col>
            </v-row>
            <DataTable-Detalle :headers="headers" :items="data.venta" />
            <Total-Viewer :total="totalventa" />
            <Forma-Pago-Venta
              v-if="data.ventapago.length > 0"
              class="ma-4"
              :key="key"
              :montoCobrar="totalventa"
              :datos="{
                efectivo: {
                  monto: data.ventapago[0].monto,
                  isEfectivo: data.ventapago[0].monto > 0 ? true : false,
                },
                cheque: data.ventapago[0].venta_pago_cheque,
                tarjeta: data.ventapago[0].venta_pago_tarjeta,
              }"
              readonly
            />

            <v-row dense v-if="data.idtipofactura.idtipofactura === 2">
              <v-col cols="12" md="3" sm="3">
                <v-switch
                  disabled
                  v-model="data.cuenta[0].financiar"
                  :label="`Financiar: ${financiar}`"
                ></v-switch>
              </v-col>
              <v-col cols="12" md="3" sm="3" v-if="data.cuenta[0].financiar">
                <vue-text-field
                  readonly
                  label="Cantidad de Cuotas"
                  v-model="data.cuenta[0].cantcuota"
                />
              </v-col>
              <v-col cols="12" md="3" sm="3" v-if="data.cuenta[0].financiar">
                <vue-text-field
                  readonly
                  label="Entrega"
                  :rules="false"
                  v-model="data.cuenta[0].entrega"
                />
              </v-col>
              <v-col cols="12" md="3" sm="3" v-if="data.cuenta[0].financiar">
                <vue-text-field
                  readonly
                  label="Dia de Pago"
                  v-model="data.cuenta[0].diapago"
                />
              </v-col>
              <v-col cols="12" md="3" sm="3" v-if="data.cuenta[0].financiar">
                <vue-text-field
                  readonly
                  label="%  Interes"
                  v-model="data.cuenta[0].porcinteres"
                />
              </v-col>
              <v-col cols="12" md="3" sm="3" v-if="data.cuenta[0].financiar">
                <vue-text-field
                  readonly
                  label="% Interes por Mora"
                  v-model="data.cuenta[0].porcinteresmora"
                />
              </v-col>
              <v-col cols="12" md="3" sm="3" v-if="data.cuenta[0].financiar">
                <vue-text-field
                  readonly
                  label="Periodo"
                  v-model="data.cuenta[0].idperiodo_interes.descripcion"
                />
              </v-col>
              <v-col cols="12" md="3" sm="3" v-if="data.cuenta[0].financiar">
                <vue-text-field
                  readonly
                  label="Sistema"
                  v-model="data.cuenta[0].idsistema_interes.descripcion"
                />
              </v-col>
              <v-col cols="12">
                <v-data-table
                  :headers="headers_cuota"
                  :items="data.cuenta[0].cuotaventa"
                  :items-per-page="999999"
                  hide-default-footer
                >
                  <template v-slot:[`item.montocuotacuenta`]="{ item }">
                    <div class="font-weight-black">
                      {{ currencyFormat(item.montocuotacuenta) }}
                    </div>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="red" text @click="cancelar">
              <v-icon dark>close</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              :disabled="data.anulventa"
              color="primary"
              v-if="isAutorizedReimpresion"
              text
              @click="dialogoReimpresion = true"
              >Reimprimir</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
      <v-dialog v-model="dialogoReimpresion" persistent max-width="400">
        <v-card>
          <v-card-title>
            <v-icon left>info</v-icon>
            <span class="title font-weight-light">Ventana de Confirmacion</span>
          </v-card-title>
          <v-card-text>
            <v-form ref="formPrint">
              <h3>Imprimir con la factura numero</h3>
              <v-radio-group
                :rules="[(v) => !!v || 'Campo requerido']"
                v-model="facturaImprimir"
                :mandatory="false"
                class="mb-0 pb-0"
              >
                <v-radio
                  :label="new_datos_factura"
                  :value="new_datos_factura"
                ></v-radio>
                <v-radio
                  :label="datos_factura"
                  :value="datos_factura"
                ></v-radio>
              </v-radio-group>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn color="red" text @click="dialogoReimpresion = false">
              <v-icon>clear</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="generarfactura()">
              <v-icon>done</v-icon>
            </v-btn>
          </v-card-actions>
          <c-overlay :value="Vget_venta_load"></c-overlay>
        </v-card>
      </v-dialog>
      <c-overlay :value="Vget_venta_load"></c-overlay>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { booleanConverter } from "@/services/util/boolean.service";
import { utilidad } from "@/services/compra_utilidad.service";
import { factura } from "@/services/factura.service";
import { browser } from "@/services/browser";
import { mensaje } from "@/services/notify.service";
import { currency } from "@/services/util/number.service";
import dataTableDetalle from "./Detalle";
import totalviewer from "./Totales";
import formaPagoVenta from "@/views/Venta/CobroCuenta/FormaPago";
//import {getIva5, getIva10, getTotal} from "./calculos";
import Worker from "worker-loader!@/services/workers/jspdf.worker.js";
var worker = new Worker();
export default {
  components: {
    "DataTable-Detalle": dataTableDetalle,
    "Total-Viewer": totalviewer,
    "Forma-Pago-Venta": formaPagoVenta,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    datos: {
      type: [Object, Array],
      default: null,
    },
  },
  data() {
    return {
      facturaImprimir: null,
      puntoventa: [],
      dialogoReimpresion: false,
      key: 0,
      data: {
        idventa: 0,
        idtimbrado: {
          idestablecimiento: {
            establecimiento: "",
          },
          idpuntoexpedicion: {
            puntoexpedicion: "",
          },
          timbrado: "",
        },
        fechventa: "2000-01-01",
        fechvencimiento: "",
        horaventa: "00:00:00",
        anulventa: false,
        idtipofactura: {
          idtipofactura: 1,
          desctipofactura: "",
        },
        numfventa: "",
        idcontacto: {
          idcontacto: {
            desccontacto: "",
          },
        },
        idmoneda: {
          idmoneda: "",
        },
        idcotizacion: {
          idcotizacion: "",
        },
        idpuntoventa: {
          idpuntoventa: "",
          idcaja: "",
        },
        venta: [],
        ventapago: [
          {
            idcaja: 0,
            idformapago: {
              idformapago: "",
              descformapago: "",
            },
            monto: 0,
            venta_pago_cheque: [
              {
                idbanco: {
                  idbanco: "",
                  descbanco: "",
                },
                nrocheque: "",
                monto: "",
                fechaemision: "",
                fechapago: "",
              },
            ],
            venta_pago_tarjeta: [
              {
                monto: "",
                voucher: "",
                idpasarela_pago: {
                  idpasarela_pago: "",
                  descripcion: "",
                },
              },
            ],
            opcion: false,
          },
        ],
        cuenta: [
          {
            idcuentaventa: 0,
            idventa: 0,
            idtimbrado: "",
            fecha: "2000-01-01",
            financiar: false,
            idcontacto: "",
            estadocobro: false,
            diapago: "",
            anulcuentaventa: false,
            cantcuota: 1,
            porcinteres: "",
            porcinteresmora: "",
            idperiodo_interes: {
              idperiodo_interes: "",
            },
            idsistema_interes: {
              idsistema_interes: "",
            },
            cuotaventa: [],
          },
        ],
        ventares: [],
      },
      default: {
        idventa: 0,
        idtimbrado: {
          idtimbrado: "",
        },
        fechventa: "2000-01-01",
        horaventa: "00:00:00",
        anulventa: false,
        idtipofactura: {
          idtipofactura: 1,
        },
        numfventa: "",
        idcontacto: {
          idcontacto: {
            idcontacto: "",
          },
        },
        idmoneda: {
          idmoneda: "",
        },
        idcotizacion: {
          idcotizacion: "",
        },
        idpuntoventa: {
          idpuntoventa: "",
          idcaja: "",
        },
        venta: [],
        ventapago: [],
        cuenta: [
          {
            idcuentaventa: 0,
            idventa: 0,
            idtimbrado: "",
            fecha: "2000-01-01",
            financiar: false,
            idcontacto: "",
            estadocobro: false,
            diapago: "",
            anulcuentaventa: false,
            cantcuota: 1,
            porcinteres: "",
            porcinteresmora: "",
            idperiodo_interes: {
              idperiodo_interes: "",
            },
            idsistema_interes: {
              idsistema_interes: "",
            },
            cuotaventa: [],
          },
        ],
        ventares: [],
      },
      headers_cuota: [
        { text: "Vencimiento", value: "fechavence", sortable: "false" },
        {
          text: "Total",
          value: "montocuotacuenta",
          align: "right",
          sortable: "false",
        },
      ],

      headers: [
        {
          text: "Deposito",
          sortable: false,
          value: "iddeposito.desdeposito",
        },
        { text: "Cantidad", sortable: false, value: "cantdetventa" },
        { text: "Item", sortable: false, value: "iditem.descitem" },
        { text: "Precio Unitario", sortable: false, value: "precdetventa" },

        {
          text: "Sub Total",
          sortable: false,
          align: "right",
          value: "iditem.subtotal",
        },
      ],
    };
  },
  watch: {
    $route() {
      this.cancelar();
    },
    Vget_puntoventa_parametros(val) {
      this.puntoventa = JSON.parse(JSON.stringify(val));
      this.iniciarworker();
    },
  },
  computed: {
    ...mapGetters("venta", [
      "getVentaById",
      "Vget_venta_load",
      "isAutorizedReimpresion",
    ]),
    ...mapGetters("puntoventa", ["Vget_puntoventa_parametros"]),
    totalventa() {
      return this.data.venta.reduce(
        (acc, curr) => acc + curr.cantdetventa * curr.precdetventa,
        0
      );
    },
    datos_factura() {
      const est = this.data.idtimbrado.idestablecimiento.establecimiento;
      const pun = this.data.idtimbrado.idpuntoexpedicion.puntoexpedicion;
      const fac = utilidad.completar_nrof(this.data.numfventa.toString());
      return `${est}-${pun}-${fac}`;
    },
    new_datos_factura() {
      if (this.puntoventa.length === 0) return null;
      const est = this.puntoventa[0].establecimiento;
      const pun = this.puntoventa[0].punto;
      const fac = utilidad.completar_nrof(this.puntoventa[0].actual.toString());
      return `${est}-${pun}-${fac}`;
    },
    financiar() {
      return booleanConverter.si_no(this.data.cuenta[0].financiar);
    },
  },
  async mounted() {
    try {
      await this.Vact_venta_byId(this.datos.idventa);
      await this.Vact_venta_reimpresion(this.datos.idventa);
      this.data = JSON.parse(JSON.stringify(this.getVentaById));
      this.Vact_puntoventa_parametros(this.data.idpuntoventa.idpuntoventa);
      this.data.venta = JSON.parse(JSON.stringify(this.obtener_item()));
      this.key++;
    } catch (e) {
      this.cancelar();
    }
  },
  methods: {
    ...mapActions("venta", [
      "Vact_venta_data",
      "Vact_venta_byId",
      "Vact_venta_reimpresion",
      "Vact_venta_update",
      "setVentaReimpresion",
    ]),
    ...mapActions("puntoventa", ["Vact_puntoventa_parametros"]),

    currencyFormat(value) {
      if (value != "") return currency(value);
      return "";
    },

    generarfactura() {
      if (!this.$refs.formPrint.validate())
        return this.$notify(mensaje.error("Seleccione un numero de Factura"));
      //const iva5 = getIva5(this.data);
      //const iva10 = getIva10(this.data)
      //const ivatotal = iva5 + iva10;
      //const totalventa = getTotal(this.data);
      let operacion = {
        type: "processing",
        datos: this.data,
        config: JSON.parse(JSON.stringify(this.puntoventa[0])),
        factura: this.facturaImprimir,
        iva5: this.data.gravadas[3],
        iva10: this.data.gravadas[1],
        ivatotal: this.data.gravadas[3] + this.data.gravadas[1],
        ventatotal: this.data.gravadas[5],
        moneda: this.data.idmoneda,
      };
      worker.postMessage(operacion);
    },

    async iniciarworker() {
      worker.onmessage = (e) => {
        switch (e.data.type) {
          case "init":
            console.log(e.data.data);
            break;
          case "processing":
            var x = "";
            if (browser.name() === "Firefox") {
              x = window.open();
            }
            if (browser.name() === "Chrome") {
              x = window.open(`about: whatever`);
            } else {
              x = window.open();
            }
            x.document.open();
            x.document.write(e.data.data);
            x.document.close();
            this.guardarReimpresion();
            worker.postMessage(operation);
            break;
          default:
            console.log(e.data);
            break;
        }
      };
      let operation = {
        type: "init",
        orientacion: this.puntoventa[0].parametros.config_hoja.orientacion,
        unidad: this.puntoventa[0].parametros.config_hoja.unidad,
        formato: this.puntoventa[0].parametros.config_hoja.formato,
        plantilla: await factura.getDataUri(this.puntoventa[0].plantilla[0]),
        config_hoja: this.puntoventa[0].parametros.config_hoja,
        parametros: this.puntoventa[0].parametros,
      };
      worker.postMessage(operation);
    },
    async guardarReimpresion() {
      let response;
      if (this.facturaImprimir === this.datos_factura) {
        response = await this.setVentaReimpresion(this.datos.idventa);
      } else {
        this.data.numfventa = this.puntoventa[0].actual;
        response = await this.Vact_venta_update({
          id: this.data.idventa,
          data: this.data,
        });
      }
      if (response.success) {
        this.dialogoReimpresion = false;
        this.Vact_venta_data();
        this.cancelar();
      }
    },

    completar_nrof(factura) {
      this.data.numfventa = utilidad.completar_nrof(factura);
    },
    obtener_item() {
      return this.data.venta.map((x) => {
        let subtotal = Number(x.cantdetventa) * Number(x.precdetventa);
        return {
          ...x,
          iditem: {
            iva5: x.iva5,
            iva10: x.iva10,
            exenta: x.exenta,
            ...x.iditem,
            subtotal,
          },
        };
      });
    },
    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$emit("input", false);
      this.$emit("datos", null);
    },
  },
};
</script>
