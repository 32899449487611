<template>
  <div>
    <v-progress-linear value="100" height="2"></v-progress-linear>
    <div class="d-flex flex-row justify-center">
      <div class="font-weight-black text-h6">{{ datos.mensaje }}</div>
    </div>
    <div class="d-flex flex-row justify-center">
      <div class="font-weight-medium text-h6">{{ datos.group }}</div>
    </div>

    <div class="d-flex flex-row justify-center">
      <div class="font-weight-medium subtitle-1">
        Ultima Cotizacion: <strong>{{ datos.cotizacion }}</strong>
      </div>
    </div>
    <v-container>
      <v-simple-table dense>
        <template v-slot:default>
          <tbody>
            <tr>
              <th>Fecha</th>
              <td>{{ datos.fecha }}</td>
              <th>Desde</th>
              <td>{{ parametros.fechaDesde }}</td>
            </tr>
            <tr>
              <th>Hora</th>
              <td>{{ datos.hora }}</td>
              <th>Hasta</th>
              <td>{{ parametros.fechaHasta }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-container>
    <v-row dense class="ma-1">
      <v-col cols="12" sm="3" md="3" v-for="(item, i) of datos.lista" :key="i">
        <v-card shaped min-height="150">
          <v-container>
            <div class="subtitle-1 font-weight-black text-center">
              {{ item.name }}
            </div>
            <v-divider></v-divider>
            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                  <tr v-for="(sub, y) in item.datos" :key="y">
                    <td>{{ sub.id }}</td>
                    <td>{{ sub.name }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {

  props: {
    datos: {
      type: [Object, Array],
    },
    parametros: {
      type: Object,
    },
  },
};
</script>

<style>
</style>