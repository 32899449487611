<template>
    <c-dialog-confirmation
        :value="value"
        @cancel="close()"
        @done="save()"
        :loading="isLoadingMovimiento"
    >Desea eliminar el movimiento
    <strong>{{ props.nombre }}</strong>?
    </c-dialog-confirmation>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    props: {
        value: Boolean,
        props: Object,
    },
    computed: {
        ...mapGetters("finanza", ["isLoadingMovimiento"])
    },
    watch: {
        $route() {
            return this.close();
        }
    },
    methods: {
        ...mapActions("finanza", ["setMovimientoDelete", "fetchMovimiento"]),
        async save() {
            const id = this.props.idfinanza_movimiento;
            const response = await this.setMovimientoDelete(id);
            if (!response.success) return null;
            let tipo = "transferencia"
            if (this.props.tipo) tipo = "movimiento";
            this.close(tipo);
        },
        close(tipo) {
            this.$emit("datos", null);
            this.$emit("input", false);
            this.fetchMovimiento(tipo);
        }
    }
}
</script>