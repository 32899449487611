
export default {
  getConceptoSalario: (state) => {
    return state.conceptosalario
  },
  getConceptoContrato: (state) => {
    return state.conceptocontrato
  },
  getConceptoPagoSalario: (state) => {
    return state.conceptopagosalario
  },
  isConceptoSalarioLoading: (state) => {
    return state.isLoading
  }
}
