export default {


  racion_data(state, request) {
    state.racion_data = request;
  }, 
  racion_data_all(state, request) {
    state.racion_data_all = request;
  }, 
  racion_data_anho(state, request) {
    state.racion_data_anho = request;
  }, 
  racion_data_byLote(state, request) {
    state.racion_data_byLote = request;
  }, 
  racion_load(state, request) {
    state.racion_load = request;
  }, 
  racion_save_load(state, request) {
    state.racion_save_load = request;
  }, 
}