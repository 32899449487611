import {
    PUEDE_AGREGAR,
    PUEDE_EDITAR,
    PUEDE_ELIMINAR,
    PUEDE_LISTAR,
    PUEDE_GENERAR_REPORTE,
    LIMITE_DE_REGISTRO,
    SALDO_CAJA_CUENTA,
    HISTORIAL_TRANSFERENCIAS,
    ESTADO_RESULTADO,
    LIBRO_VENTA,
    LIBRO_COMPRA,
    GRAFICO_RANKING,
} from "@/constants/permissions";


export const getPermission = (form) => {
    if (!form)
        return JSON.parse(localStorage.getItem('permisos'));
    const permisos = JSON.parse(localStorage.getItem('permisos'));
    if (permisos[form])
        return permisos[form]
    return {
        permiso: {
            [PUEDE_AGREGAR]: false,
            [PUEDE_EDITAR]: false,
            [PUEDE_ELIMINAR]: false,
            [PUEDE_LISTAR]: false,
            [PUEDE_GENERAR_REPORTE]: false,
            [LIMITE_DE_REGISTRO]: false,
            [SALDO_CAJA_CUENTA]: false,
            [HISTORIAL_TRANSFERENCIAS]: false,
            [ESTADO_RESULTADO]: false,
            [LIBRO_VENTA]: false,
            [LIBRO_COMPRA]: false,
            [GRAFICO_RANKING]: false
        }
    }
};
export const savePermission = (data) => {
    removePermission();
    localStorage.setItem('permisos', JSON.stringify(data))
};

export const removePermission = () => {
    localStorage.removeItem('permisos');
};