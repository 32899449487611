<template>
    <div>
        <div v-if="crud.add">
            <Produccion-Alimentacion-Create v-model="crud.add" :datos="crud.datos" @datos="crud.datos = $event" />
        </div>
        <v-alert type="error" v-if="permission_alert">
            Error!!! Este usuario no tiene los permisos en esta ventana
        </v-alert>
        <v-card>
            <v-toolbar flat dense>
                <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
                <widgets-Favorite :ruta="$route.path" :formulario="$route.name" />
                <v-divider class="mx-2" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <c-text-table-search v-if="permission.can_view" v-model="search" class="mr-1"></c-text-table-search>
                <v-toolbar-items>
                    <c-btn-table-add class="mr-1" @click="crud.add = true" v-if="permission.can_add"></c-btn-table-add>
                    <c-btn-table-reload @click="Vact_alimentacion_data()"></c-btn-table-reload>
                </v-toolbar-items>
            </v-toolbar>
            <v-data-table :loading="Vget_alimentacion_load" :headers="headers" :items="Vget_alimentacion_data"
                :search="search" v-if="permission.can_view">
                <template v-slot:[`item.dias`]="{ item }">
                    {{ mostrarDias(item.dias) }}
                </template>
                <template v-slot:[`item.accion`]="{ item }">
                    <c-btn-table-edit @click="editar(item)"></c-btn-table-edit>
                    <c-btn-table-delete @click="eliminar(item)"></c-btn-table-delete>
                </template>
            </v-data-table>
        </v-card>
        <v-dialog v-model="crud.delete" persistent max-width="400">
            <v-card>
                <v-card-title>
                    <v-icon left>info</v-icon>
                    <span class="subtitle-1 font-weight-light">Ventana de Confirmacion</span>
                </v-card-title>
                <v-form ref="ferm" @submit.prevent="eliminarAlimentacion()">
                    <v-card-subtitle>Desea eliminar la alimentacion?</v-card-subtitle>
                </v-form>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn color="red" text="text" @click="crud.delete = false">
                        <v-icon>clear</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text="text" @click="eliminarAlimentacion()">
                        <v-icon>done</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
            <c-overlay :value="overlay" absolute></c-overlay>
        </v-dialog>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { ALIMENTACION } from "@/constants/forms";
import {
    PUEDE_AGREGAR,
    PUEDE_EDITAR,
    PUEDE_ELIMINAR,
    PUEDE_LISTAR
} from "@/constants/permissions";
export default {
    data() {
        return {
            dofw: ["Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado", "Domingo"],
            search: "",
            headers: [
                { text: "ID", align: "left", value: "idalimentacion" },
                { text: "Dias", align: "left", value: "dias" },
                { text: "Inicio", align: "left", value: "inicio" },
                { text: "Fin", align: "left", value: "fin" },
                { text: "Accion", value: "accion", sortable: false, align: "right" }
            ],
            crud: {
                add: false,
                delete: false,
                block: false,
                operacion: true,
                datos: null,
            },
            overlay: false,
            objeto: {
                descripcion: "",
            },
            permission: {
                can_add: false,
                can_view: false,
                can_update: false,
                can_delete: false,
            },
        }
    },
    created() {
        this.$store.commit("SET_LAYOUT", "principal-layout");
        this.Vact_alimentacion_data();
        const permission = getPermission(ALIMENTACION).permiso;
        this.permission = {
            can_add: permission[PUEDE_AGREGAR],
            can_view: permission[PUEDE_LISTAR],
            can_update: permission[PUEDE_EDITAR],
            can_delete: permission[PUEDE_ELIMINAR],
        };
    },
    computed: {
        ...mapGetters("alimentacion", ["Vget_alimentacion_data", "Vget_alimentacion_load"]),
        permission_alert() {
            return mensaje.permission(this.permission);
        }
    },
    methods: {
        ...mapActions("alimentacion", ["Vact_alimentacion_data", "Vact_alimentacion_del", "Vact_alimentacion_update"]),
        editar(item) {
            this.crud.datos = JSON.parse(JSON.stringify(item));
            this.crud.add = true;
        },
        eliminar(item) {
            this.crud.delete = true;
            this.objeto = item;
        },
        eliminarCancelar() {
            this.objeto.descripcion = "";
            this.crud.delete = false;
        },
        async abrirAlimentacion(item) {
            this.crud.datos = JSON.parse(JSON.stringify(item));
            this.crud.operacion = false;
            this.crud.block = true;
        },
        async eliminarAlimentacion() {
            this.overlay = true;
            const id = this.objeto.idalimentacion;
            const a = await this.Vact_alimentacion_del(id);
            if (a.estado == true) {
                this.$notify(a.info);
                this.eliminarCancelar();
            } else {
                a.info.map((x) => {
                    this.$notify(mensaje.error(x));
                });
            }
            this.overlay = false;
        },
        obtenerDia(dia) {
            return this.dofw[dia - 1]
        },
        mostrarDias(lista) {
            let textdia = ""
            for (let i = 0; i < lista.length; i++) {
                let nombre = this.obtenerDia(lista[i])
                if (i === 0) {
                    textdia += nombre
                } else if (i === lista.length - 1) {
                    textdia += " y " + nombre
                } else {
                    textdia += ", " + nombre
                }
            }
            return textdia
        }
    }
}
</script>