<template>
  <v-autocomplete
    clearable
    :value="value"
    ref="input"
    :items="items"
    :loading="loading"
    :item-value="itemValue"
    :rules="rules"
    :return-object="returnObject"
    :item-text="itemText"
    :label="label"
    :dense="dense"
    :id="id"
    :background-color="backgroundColor"
    :rounded="rounded"
    :filled="filled"
    :outlined="outlined"
    :multiple="multiple"
    :chips="chips"
    :small-chips="chips"
    :readonly="readonly"
    :search-input.sync="search"
    @input="$emit('input', $event)"
    @change="$emit('change')"
  >
    <template v-slot:no-data v-if="permission"
      ><slot name="no-data" />
      <v-list-item @click="$emit('create', true)">
        <v-list-item-content>
          <v-list-item-title
            >No existe concidencia. Click para agregar</v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-slot:item="{ item }" v-if="$scopedSlots.item">
      <slot name="item" :item="item" />
    </template>
  </v-autocomplete>
</template>
<script>
export default {
  props: {
    value: [Object, Number, Array, String],
    "hide-details": Boolean,
    "item-text": String,
    "item-value": String,
    "small-chips": Boolean,
    'background-color': String,
    id: String,
    multiple: Boolean,
    items: Array,
    returnObject: Boolean,
    loading: Boolean,
    clearable: Boolean,
    outlined: Boolean,
    dense: Boolean,
    chips: Boolean,
    label: String,
    disabled: Boolean,
    readonly: Boolean,
    permission: Boolean,
    filled: Boolean,
    rounded: Boolean,
    rules: {
      type: Array,
      default: function() {
        return this.validator;
      },
    },
  },
  computed: {
    validator: (vm) => {
      if (vm.multiple) return [(v) => v.length > 0 || "Obligatorio"];
      return vm.returnObject
        ? [(v) => !!v[vm.itemValue] || "Obligatorio"]
        : [(v) => !!v || "Obligatorio"];
    },
  },
  watch: {
    search(val) {
      this.$emit("sync-input", val);
    },
  },
  methods: {
    isMenuActive() {
      this.$refs.input.isMenuActive = false;
    },
    focus() {
      this.$refs.input.focus();
    },
  },

  data: () => ({
    search: null,
  }),
};
</script>
