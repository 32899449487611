import { get} from "@/services/api/api.service";
import { SET_LOADING, SET_TIMELINE, url } from './constants'
export default {
    async fetchTimelineFuncionario({ commit, dispatch }, id) {
        commit(SET_LOADING, true);
        try {
            const response = await get(`${url}?contacto=` + id);
            commit(SET_TIMELINE, response);
        } catch (e) {
            dispatch('snackbar/setNotification', e, { root: true });
            commit(SET_LOADING, false);
            throw e
        }
        commit(SET_LOADING, false);
    }
}
