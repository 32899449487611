import {SET_LOADING, SET_LIST_EVENT, SET_REPORTE_LOADING, SET_LIST_REPORTE} from './constants'
export default {
  [SET_LIST_EVENT](state, request) {
    state.list_events = request;
  },
  [SET_LOADING](state, request) {
    state.isLoading = request;
  },
  [SET_LIST_REPORTE](state, request) {
    state.list_reporte = request;
  },
  [SET_REPORTE_LOADING](state, request) {
    state.isReporteLoading = request;
  }
}