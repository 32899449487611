const campos = [
    {
      name: "Fecha",
      value: "fechproduccionleche",
      type: "date",
      query: [
        {
          comparison: "",
          value: "",
          logical: "",
          add: false,
        },
      ],
    },
    {
      name: "Responsable",
      api: "/apiresponsable/",
      id: "idresponsable",
      desc: "descresponsable",
      type: "select",
      query: [
        {
          comparison: "",
          value: "",
          logical: "",
          add: false,
        },
      ],
    },
    {
      name: "Turno",
      api: "/apiturnoordeno/",
      id: "idturnoordeno",
      desc: "descturnoordeno",
      type: "select",
      query: [
        {
          comparison: "",
          value: "",
          logical: "",
          add: false,
        },
      ],
    },
    {
      name: "Deposito",
      api: "/apideposito/",
      id: "iddeposito",
      desc: "desdeposito",
      type: "select",
      query: [
        {
          comparison: "",
          value: "",
          logical: "",
          add: false,
        },
      ],
    },
    {
      name: "Por Tambo",
      value: "producciontotal",
      type: "boolean",
      query: [
        {
          comparison: "",
          value: "",
          logical: "",
          add: false,
        },
      ],
    },
    {
      name: "Animal",
      api: "/apianimal/",
      id: "idanimal",
      desc: "rpanimal",
      type: "select",
      query: [
        {
          comparison: "",
          value: "",
          logical: "",
          add: false,
        },
      ],
    },
  ]

  export default campos