export default {
  motivoajuste_create(state, request) {
    state.motivoajuste_create = request;
  }, 
  motivoajuste_update(state, request) {
    state.motivoajuste_update = request;
  },   
  motivoajuste_reload(state, request) {
    state.motivoajuste_reload = request;
  },
  motivoajuste_show(state, request) {
    state.motivoajuste_show = request;
  },

  motivoajuste_data(state, request) {
    state.motivoajuste_data = request;
  },
  motivoajuste_load(state, request) {
    state.motivoajuste_load = request;
  },
  motivoajuste_save_load(state, request) {
    state.motivoajuste_save_load = request;
  }
}