export default {

  preventaagro_update(state, request) {
    state.preventaagro_update = request;
  },
  preventaagro_data(state, request) {
    state.preventaagro_data = request;
  }, 
  preventaagro_data_byId(state, request) {
    state.preventaagro_data_byId = request;
  }, 
  preventaagro_load(state, request) {
    state.preventaagro_load = request;
  }, 
  preventaagro_save_load(state, request) {
    state.preventaagro_save_load = request;
  }, 
}