import { get, post, put, del } from "@/services/api/api.service";
export default {
    async Vact_causanoins_data({ commit ,dispatch}) {
        commit('causanoins_load', true);
        try {
            const data = await get('/apicausanoinseminacion/');
            commit('causanoins_data', data);
            commit('causanoins_load', false);
            return true
        } catch (e) {
            dispatch('snackbar/setNotification', e, { root: true })
            commit('causanoins_load', false);
            throw e
        }
    },
    async Vact_causanoins_save({ commit,dispatch }, data) {
        commit('causanoins_save_load', true);
        const resp = await post('/apicausanoinseminacion/', data);
        dispatch('snackbar/setNotification', resp, { root: true });
        commit('causanoins_save_load', false);
        return resp;

    },
    async Vact_causanoins_del({ dispatch }, id) {
        const data = await del('/apicausanoinseminacion/' + id);
        dispatch('snackbar/setNotification', data, { root: true });
        dispatch('Vact_causanoins_data');
        return data
    },
    async Vact_causanoins_update({ commit ,dispatch}, { id, data }) {
        commit('causanoins_save_load', true);
        const resp = await put('/apicausanoinseminacion/' + id + '/', data);
        dispatch('snackbar/setNotification', resp, { root: true })
        commit('causanoins_save_load', false);
        return resp;

    },
}
