
export default {

  motivoajuste_create: (state) => {
    return state.motivoajuste_create
  },
  motivoajuste_update: (state) => {
    return state.motivoajuste_update
  },
  motivoajuste_reload: (state) => {
    return state.motivoajuste_reload
  },
  motivoajuste_show: (state) => {
    return state.motivoajuste_show
  },

  Vget_motivoajuste_data: (state) => {
    return state.motivoajuste_data
  },
  Vget_motivoajuste_load: (state) => {
    return state.motivoajuste_load
  },
  Vget_motivoajuste_save_load: (state) => {
    return state.motivoajuste_save_load
  },
}
