export default {


  formulario_data(state, request) {
    state.formulario_data = request;
  }, 
  formulario_load(state, request) {
    state.formulario_load = request;
  }
}
