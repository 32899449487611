<template>
  <c-dialog-confirmation
    :value="value"
    @cancel="close()"
    @done="save()"
    :loading="isLoadingMantenimiento">
    Desea eliminar la maquinaria 
    <strong>{{ props.descripcion }}</strong>?
  </c-dialog-confirmation>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    value: Boolean,
    props: Object,
  },
  computed:{
    ...mapGetters('maquinaria_mantenimiento', ["isLoadingMantenimiento"])
  },
    watch: {
    $route() {
      return this.close();
    },
  },
  methods: {
    ...mapActions("maquinaria_mantenimiento", ["setMaquinariaMantenimientoDelete", "fetchMaquinariaMantenimiento"]),
    async save() {
      const id = this.props.idmaquinaria_mantenimiento;
      const response = await this.setMaquinariaMantenimientoDelete(id);
      if (!response.success) return null;
      this.close();
    },
    close() {
      this.$emit('datos',null);
      this.$emit("input", false);
      this.fetchMaquinariaMantenimiento();
    },
  }
};
</script>