<template>
  <div>
    <v-dialog :value="value" max-width="650" persistent>
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>
            {{ !editable ? "Agregar Validacion" : "Modificar Validacion" }}
          </v-toolbar-title>
        </v-toolbar>
        <v-container>
          <v-form ref="form" @submit.prevent="next(1, data.nombre)">
            <v-row dense>
              <v-col cols="12">
                <c-text-field
                  dense
                  outlined
                  label="Nombre"
                  ref="validacion1"
                  v-model="data.nombre"
                  @keyup.native.enter="next(1, data.nombre)"
                ></c-text-field>
              </v-col>
              <v-col cols="12">
                <c-text-field
                  dense
                  outlined
                  label="Clave"
                  ref="validacion2"
                  v-model="data.clave"
                  @keyup.native.enter="next(2, data.clave)"
                ></c-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <c-btn-crud-close @click="cancelar()"></c-btn-crud-close>
          <v-spacer></v-spacer>
          <c-btn-crud-done ref="validacion3" @click="guardar()"></c-btn-crud-done>
        </v-card-actions>
        <c-overlay :value="isValidacionLoading"></c-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
    },
  },
  data() {
    return {
      data: {
        idagro_validacion_bot: "",
        nombre: "",
        clave: "",
      },
      default: {
        idagro_validacion_bot: "",
        nombre: "",
        clave: "",
      },
    };
  },
  watch: {
    $route() {
      return this.cancelar();
    },
  },
  computed: {
    ...mapGetters("validacion", ["isValidacionLoading"]),
  },
  mounted() {
    if (this.editable) this.data = JSON.parse(JSON.stringify(this.props));
    setTimeout(() => this.next(0, "-"), 200);
  },
  methods: {
    ...mapActions("validacion", [
      "setValidacion",
      "setValidacionUpdate",
      "fetchValidacion",
    ]),
    next(id, data) {
      if (data != "")
        try {
          this.$refs[`validacion${id + 1}`].focus();
        } catch (error) {
          this.$refs[`validacion${id + 1}`].$el.focus();
        }
    },
    cancelar() {
      this.$emit("input", false);
      this.$emit("datos", null);
    },
    async guardar() {
      if (!this.$refs.form.validate()) return null;
      const response = this.editable
        ? await this.setValidacionUpdate({
            data: this.data,
            id: this.data.idagro_validacion_bot,
          })
        : await this.setValidacion(this.data);
      if (response.success) {
        this.fetchValidacion();
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();
        if (this.editable) return this.cancelar();
        this.next(0, "-");
      }
    },
  },
};
</script>
