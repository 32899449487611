<template>
    <v-btn color="#385F73" small dark @click="$emit('click')" :to="to">
        <v-icon dark>{{ hide ? `visibility` : `visibility_off` }}</v-icon>
    </v-btn>
</template>
<script>
    export default {
        props: {
            to: String,
            hide: Boolean,
        },
    }
</script>