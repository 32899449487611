<template>
  <div>
    <v-dialog
      :value="value"
      max-width="900"
      persistent
      :fullscreen="$vuetify.breakpoint.xs"
    >
      <v-card>
        <v-form ref="formCabecera">
          <v-toolbar flat>
            <v-toolbar-title>{{
              readonly
                ? "Detalles"
                : !isEdit
                ? "Registro de Canje"
                : "Modificar Canje"
            }}</v-toolbar-title>
            <v-divider class="mx-2" inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-toolbar>
        </v-form>
        <v-container>
          <v-form ref="form">
            <v-row dense>
              <v-col cols="12" sm="2">
                <c-text-date
                  label="Fecha"
                  id="canje1"
                  ref="canje1"
                  :readonly="readonly"
                  v-model="data.fecha"
                  @focus="setSelection(`canje1`)"
                  @keyup.native.enter="next(1, data.fecha)"
                ></c-text-date>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="12" sm="3">
                <AutocompleteCampana
                  ref="canje2"
                  :readonly="readonly"
                  label="Campaña Origen"
                  return-object
                  v-model="data.idcampanaorigen"
                  @keyup.native.enter="next(2, data.idcampanaorigen.idcampana)"
              /></v-col>
              <v-col cols="12" sm="3"
                ><c-text-field
                  readonly
                  label="Tipo de Grano"
                  v-model="data.idcampanaorigen.iditem.descitem"
                ></c-text-field
              ></v-col>
              <v-col cols="12" sm="3"
                ><AutocompleteCampana
                  ref="canje3"
                  create
                  :readonly="readonly"
                  label="Campaña Destino"
                  v-model="data.idcampanadestino.idcampana"
                  @next-focus="next(3, '-')"
                  @keyup.native.enter="next(3, data.idcampanadestino.idcampana)"
              /></v-col>
            </v-row>
          </v-form>
          <v-form ref="formdetalle">
            <v-row dense>
              <v-col cols="12" sm="3">
                <AutocompleteDepositoStock
                  ref="canje4"
                  :readonly="readonly"
                  :idcampana="data.idcampanaorigen.idcampana"
                  v-model="detalle.iddepositoorigen"
                  @keyup.native.enter="
                    next(4, detalle.iddepositoorigen.iddeposito)
                  "
                />
                
              </v-col>
              <v-col cols="12" sm="3">
                <c-text-currency
                  ref="canje5"
                  outlined
                  dense
                  :readonly="readonly"
                  :rules="[v=> !!v || 'Obligatorio']"
                  label="Cantidad Kg a Debitar"
                  v-model="detalle.cantidadorigen"
                  @keyup.native.enter="next(5, detalle.cantidadorigen)"
                ></c-text-currency>
              </v-col>
              <v-col cols="12" sm="6">
                <AutocompleteItem
                  ref="canje6"
                  dense
                  v-model="detalle.iditem"
                  label="Item a Acreditar"
                  @keyup.native.enter="next(6, detalle.iditem.iditem)"
                />
                <!--
                <v-autocomplete
                  ref="canje6"
                  dense
                  v-model="detalle.iditem"
                  v-bind:items="Vget_item_data"
                  :search-input.sync="search"
                  label="Item a Acreditar"
                  item-text="descitem"
                  return-object
                  :readonly="readonly"
                  :rules="[(v) => !!v.iditem || 'Seleccione un Item']"
                  :loading="Vget_item_load"
                  required
                  outlined
                  autocomplete="off"
                  @keyup.enter="next(6, detalle.iditem.iditem)"
                  :no-data-text="`No existe ninguna concidencia`"
                >
                  <template v-if="item_permission" v-slot:no-data>
                    <v-list-item @click="formularios.item = true">
                      <v-list-item-content>
                        <v-list-item-title
                          >No existe concidencia. Click para
                          agregar</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
                <div v-if="formularios.item">
                  <Item-Item-create
                    :sync-input="search"
                    @sync-input="
                      (detalle.iditem = $event), next(3, detalle.iditem.iditem)
                    "
                    v-model="formularios.item"
                  />
                </div>
                -->
              </v-col>
              <v-col cols="12" sm="3">
                <AutocompleteDeposito
                  ref="canje7"
                  label="Deposito de Destino"
                  outlined
                  create
                  dense
                  :readonly="readonly"
                  return-object
                  v-model="detalle.iddepositodestino"
                  @keyup.native.enter="
                    next(7, detalle.iddepositodestino.iddeposito)
                  "
                />
              </v-col>
              <v-col
                ><v-switch
                  class="mt-n1"
                  inset
                  dense
                  :readonly="readonly"
                  messages="Como desea acreditar el Item?"
                  v-model="detalle.isporcentaje"
                  :label="detalle.isporcentaje ? `Porcentaje` : `Cantidad`"
                ></v-switch
              ></v-col>

              <v-col>
                <c-text-currency
                  ref="canje8"
                  :readonly="readonly"
                  :label="
                    `${
                      detalle.isporcentaje ? 'Porcentaje' : 'Cantidad'
                    } a acreditar`
                  "
                  dense
                  outlined
                  :rules="[v=> !!v || 'Obligatorio']"
                  v-model="detalle.cantidaddestino"
                  @keyup.native.enter="next(8, detalle.cantidaddestino)"
                ></c-text-currency>
              </v-col>
              <v-spacer></v-spacer>
              <v-btn
                ref="canje9"
                :disabled="readonly"
                outlined
                color="primary"
                fab
                small
                @click="addTable()"
              >
                <v-icon>add</v-icon>
              </v-btn>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="data.detalle"
                  :items-per-page="100"
                  hide-default-footer
                >
                <template v-slot:[`item.cantidadorigen`]="{item}">
                    <div>{{toCurrency(item.cantidadorigen)}}</div>
                  </template>
                <template v-slot:[`item.cantidaddestino`]="{item}">
                    <div>{{calcularCantidadDestino(item)}}</div>
                  </template>
                  <template v-slot:[`item.accion`]="{item}">
                    <c-btn-table-edit :disabled="readonly" @click="editarDetalle(item)"></c-btn-table-edit>
                    <c-btn-table-delete :disabled="readonly" @click="eliminarDetalle(item)"></c-btn-table-delete>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-form>
          <v-divider class="mb-2"></v-divider>
          <v-row dense no-gutters>
            <v-spacer></v-spacer>

<!--             <v-col cols="12">
              <vue-text-field
                label="Comentario"
                :rules="false"
                :readonly="readonly"
                v-model="data.comentario"
              ></vue-text-field>
            </v-col> -->
          </v-row>
        </v-container>
        <v-card-actions>
          <c-btn-crud-close @click="cerrar()"></c-btn-crud-close>
          <v-spacer></v-spacer>
          <c-btn-crud-done
            :disabled="readonly"
            @click="guardar()"
          ></c-btn-crud-done>
        </v-card-actions>
        <c-overlay :value="isLoading"></c-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { current_date } from "@/services/util/date.service";
import { currency } from "@/services/util/number.service";
import { getPermission } from "@/services/storage/permissionStorage.service";
import AutocompleteCampana from "../../Campana/autocomplete";
import AutocompleteDeposito from "@/views/Item/Deposito/autocomplete";
import AutocompleteDepositoStock from "@/views/Item/Deposito/autocompleteStock";
import { ITEM } from "@/constants/forms";
import { PUEDE_AGREGAR } from "@/constants/permissions";
export default {
  components: {
    AutocompleteCampana,
    AutocompleteDeposito,
    AutocompleteDepositoStock,
    AutocompleteItem: () => import("@/views/Item/Item/AutocompleteItemVivo2"),
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    datos: {
      type: [Object, Array],
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("canje", ["isLoading"]),
    ...mapGetters("item", ["Vget_item_data", "Vget_item_load"]),
    item_permission() {
      return getPermission(ITEM).permiso[PUEDE_AGREGAR];
    },
  },
  mounted() {
    setTimeout(() => this.next(0, "-"), 500);
    //this.Vact_item_data();
    if (this.datos != null) {
      this.data =  JSON.parse(JSON.stringify(this.datos))
      this.data.idcampanaorigen.iditem = JSON.parse(JSON.stringify(this.datos.iditem))
      this.isEdit = true;
    }
  },
  watch:{
    $route(){
      return this.cerrar();
    }
  },
  methods: {
    ...mapActions("canje", ["setCanje", "fetchCanje", "setCanjeUpdate"]),
    ...mapActions("item",['Vact_item_data']),

    setSelection(id) {
      setTimeout(() => {
        let input = document.getElementById(id);
        input.focus();
        input.setSelectionRange(0, 10);
      }, 200);
    },
    next(id, data) {
      if (data != "" && data)
        try {
          this.$refs[`canje${id + 1}`].focus();
        } catch (error) {
          this.$refs[`canje${id + 1}`].$el.focus();
        }
    },
    editarDetalle(val) {
      const index = this.data.detalle.indexOf(val);
      this.data.detalle.splice(index, 1);
      this.detalle = JSON.parse(JSON.stringify(val));
    },
    eliminarDetalle(val) {
      const index = this.data.detalle.indexOf(val);
      this.data.detalle.splice(index, 1);
    },

    limpiarCampos() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.detalle = JSON.parse(JSON.stringify(this.detalle_default));
      this.$refs.formdetalle.resetValidation();
    },
    toCurrency:(value)=>currency(value),
    calcularCantidadDestino(item){
      const origen = item.cantidadorigen;
      const destino = item.cantidaddestino;
      const isporcentaje = item.isporcentaje;
      let cantidad = 0;
      if(isporcentaje) cantidad = origen * destino / 100;
      if(!isporcentaje)cantidad = destino;
      return currency(cantidad)
    },
    addTable() {
      if (!this.$refs.formdetalle.validate()) return null;
      this.data.detalle.push(this.detalle);
      this.detalle = JSON.parse(JSON.stringify(this.detalle_default));
      this.$refs.formdetalle.resetValidation();
      setTimeout(() => this.next(3, "-"), 200);
    },
    async guardar() {
      if (!this.$refs.form.validate() || this.data.detalle.length === 0)
        return null;
      this.data.iditem.iditem = this.data.idcampanaorigen.iditem.iditem;
      const id = this.data.idcanje;
      const response = this.isEdit
        ? await this.setCanjeUpdate({ id, data: this.data })
        : await this.setCanje(this.data);
      if (response.success) {
        this.limpiarCampos();
        if (this.isEdit) return this.cerrar();
        this.$refs.form.resetValidation();
      }
    },
    cerrar() {
      this.$emit("input", false);
      this.$emit("datos", null);
      this.$emit("readonly", false);
      this.fetchCanje();
      this.limpiarCampos();
    },
  },

  data() {
    return {
      search: "",
      isEdit: false,
      formularios: {
        item: false,
        deposito: false,
      },
      data: {
        estado: true,
        fecha: current_date(),
        idcampanaorigen: {
          idcampana: null,
          iditem: {
            descitem: "",
          },
        },
        idcampanadestino: {
          idcampana: null,
        },
        iditem: {
          iditem: null,
        },
        detalle: [],
      },
      detalle: {
        iddepositoorigen: {
          iddeposito: "",
        },
        iddepositodestino: {
          iddeposito: "",
        },
        cantidadorigen: "",
        cantidaddestino: "",
        iditem: {
          iditem: null,
        },
        isporcentaje: false,
      },
      detalle_default: {
        iddepositoorigen: {
          iddeposito: "",
        },
        iddepositodestino: {
          iddeposito: "",
        },
        cantidadorigen: null,
        cantidaddestino: null,
        iditem: {
          iditem: null,
        },
        isporcentaje: false,
      },
      default: {
        estado: true,
        fecha: current_date(),
        idcampanaorigen: {
          idcampana: null,
          iditem: {
            descitem: "",
          },
        },
        idcampanadestino: {
          idcampana: null,
        },
        iditem: {
          iditem: null,
        },
        detalle: [],
      },

      headers: [
        {
          text: "Deposito Origen",
          align: "start",
          value: "iddepositoorigen.desdeposito",
          sortable: false,
        },
        {
          text: "Cantidad Item a Debitar",
          align: "end",
          value: "cantidadorigen",
          sortable: false,
        },
        {
          text: "Item a Acreditar",
          align: "start",
          value: "iditem.descitem",
          sortable: false,
        },
        {
          text: "Cantidad Item a Acreditar",
          align: "end",
          value: "cantidaddestino",
          sortable: false,
        },
        {
          text: "Deposito Destino",
          align: "start",
          value: "iddepositodestino.desdeposito",
          sortable: false,
        },
        { text: "Accion", value: "accion", sortable: false, align: "end" },
      ],
    };
  },
};
</script>
