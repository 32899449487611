<template>
  <div>
    <Crud
      v-if="crud.add"
      v-model="crud.add"
      :datos="crud.datos"
      @datos="crud.datos = $event"
    />
    <Crud
      v-if="crud.view"
      v-model="crud.view"
      :datos="crud.datos"
      readonly
      @datos="crud.datos = $event"
    />
    <v-card>
      <v-alert type="error" v-if="permission_alert"
        >Error!!! Este usuario no tiene permisos en esta ventana</v-alert
      >
      <v-toolbar flat dense>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <widgets-Favorite :ruta="$route.path" :formulario="$route.name" />
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <c-text-table-search
          v-if="permission.can_view"
          v-model="search"
        ></c-text-table-search>

        <v-toolbar-items>
          <c-btn-table-add
            @click="crud.add = true"
            v-if="permission.can_add"
          ></c-btn-table-add>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="Vget_agenda_data"
        :loading="Vget_agenda_load"
        :search="search"
        v-if="permission.can_view"
      >
        <template v-slot:item.accion="props">
          <c-btn-table-view @click="view_item(props.item)" />
          <c-btn-table-edit @click="update_item(props.item)" />
          <c-btn-table-delete @click="delete_item(props.item)" />
        </template>
        <template v-slot:no-data>
          <v-alert :value="true" color="info" icon="info" outlined
            >No existe ningun registro</v-alert
          >
        </template>
      </v-data-table>
    </v-card>
    <Delete
      v-if="crud.delete"
      v-model="crud.delete"
      :props="crud.datos"
      @datos="crud.datos = $event"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import Crud from "@/views/Configuraciones/Agenda/Create";
import Delete from "./Delete";
import { AGENDA } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";
export default {
  components: {
    Crud,
    Delete,
  },
  data() {
    return {
      title: AGENDA,
      search: "",

      headers: [
        { text: "Titulo", align: "left", value: "title_agenda" },
        { text: "Inicio", align: "left", value: "start_agenda" },
        { text: "Hora", align: "left", value: "start_hour" },
        { text: "Fin", align: "left", value: "end_agenda" },
        { text: "Hora", align: "left", value: "end_hour" },
        { text: "Estado", align: "left", value: "estado" },
        { text: "Accion", value: "accion", sortable: false, align: "right" },
      ],
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
      crud: {
        delete: false,
        add: false,
        view: false,
        datos: null,
      },
    };
  },
  computed: {
    ...mapGetters("agenda", ["Vget_agenda_data", "Vget_agenda_load"]),
    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    const permission = getPermission(AGENDA).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
    this.Vact_agenda_data();
  },
  methods: {
    ...mapActions("agenda", ["Vact_agenda_data"]),

    view_item(data) {
      this.crud.datos = JSON.parse(JSON.stringify(data));
      this.crud.view = true;
    },
    update_item(data) {
      this.crud.datos = JSON.parse(JSON.stringify(data));
      this.crud.add = true;
    },
    delete_item(item) {
      this.crud.datos = JSON.parse(JSON.stringify(item));
      this.crud.delete = true;
    },
  },
};
</script>