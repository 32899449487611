<template>
  <div>
    <InputAutocomplete
      :label="label"
      item-value="idanimal"
      item-text="rpanimal"
      :id="id"
      dense
      :outlined="outlined"
      ref="input"
      :rounded="rounded"
      :filled="filled"
      :value="value"
      :background-color="backgroundColor"
      :items="getAnimal"
      :loading="isLoading"
      :readonly="readonly"
      :rules="rules"
      :multiple="multiple"
      :permission="!noCreate ? permission : false"
      :returnObject="returnObject"
      @input="$emit('input', $event)"
      @sync-input="formulario.search = $event"
      @create="formulario.crud = true"
      @change="$emit('change')"
    >
      <template v-slot:item="{ item }">
        <v-list-item-content>
          <v-list-item-title
            v-html="`RP: ${item.rpanimal} - Nombre: ${item.nombanimal}`"
          ></v-list-item-title>
          <v-list-item-subtitle v-if="item.idestadoanimal.descestadoanimal"
            v-html="`Estado Actual: ${item.idestadoanimal.descestadoanimal}`"
          ></v-list-item-subtitle>
          <v-list-item-subtitle v-if="item.cantidad"
            v-html="`Cantidad pajuelas: ${item.cantidad}`"
          ></v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </InputAutocomplete>
    <Crud
      v-if="formulario.crud"
      v-model="formulario.crud"
      :sync-input="formulario.search"
      @sync-input="syncInputHandler($event)"
    />
  </div>
</template>

<script>
import InputAutocomplete from "@/ncomponents/InputAutocomplete";
import Crud from "./Create";
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { ANIMAL } from "@/constants/forms";
import { PUEDE_AGREGAR } from "@/constants/permissions";
import store from "@/store";
export default {
  props: {
    id: String,
    value: [String, Object, Number, Array],
    returnObject: Boolean,
    readonly: Boolean,
    animal: Object,
    filled: Boolean,
    rounded: Boolean,
    multiple:Boolean,
    rules: Array,
    'background-color': String,
    outlined: {
      type: Boolean,
      default: true
    },
    items: {
      type: Array,
      default: null,
    },
    label: {
      type: String,
      default: "Seleccion el Animal",
    },
    noCreate: {
      type: Boolean,
      default: false,
    },
    action: {
      type: String,
      default: "fetchAnimal",
    },
    getter: {
      type: String,
      default: "getAnimal",
    },
  },
  components: {
    InputAutocomplete,
    Crud,
  },
  data: () => ({
    formulario: {
      crud: false,
      search: "",
    },
  }),
  watch: {
    action() {
      store.dispatch("animal/" + this.action);
    },
  },
  computed: {
    ...mapGetters("animal", ["isLoading"]),
    getAnimal() {
      if (this.items) {
        return this.items.map(x=> {
          const newObj = {
            ...x.idanimal,
            cantidad: x.cantidad
          }
          return newObj
        });
      }
      const animal = store.getters["animal/" + this.getter];
      if (this.animal) {
        animal.push(this.animal);
      }
      return animal;
    },
    permission() {
      return getPermission(ANIMAL).permiso[PUEDE_AGREGAR];
    },
  },
  mounted() {
    if(this.action) store.dispatch("animal/" + this.action);
  },
  methods: {
    ...mapActions("animal", ["fetchAnimal"]),
    syncInputHandler(event) {
      if (this.returnObject) this.$emit("input", event);
      if (!this.returnObject) this.$emit("input", event.idanimal);
      this.$emit("nextfocus");
      if(this.action) store.dispatch("animal/" + this.action);
    },
    isMenuActive() {
      this.$refs.input.isMenuActive = false;
    },
    focus() {
      this.$refs.input.focus();
    },
  },
};
</script>

<style></style>
