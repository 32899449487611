<template>
  <div>
    <v-row dense>
      <v-col cols="12" sm="4" md="4" v-if="!data.tipoitem">
        <v-switch
          v-model="data.stock"
          :label="`Stockeable?: ${isStockeable}`"
          @change="updateProps()"
        ></v-switch>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-switch
          v-model="data.porcentaje"
          :label="`Precio/porcentaje?: ${precioPorcentaje}`"
          @change="updateProps()"
        ></v-switch>
      </v-col>
      <v-col cols="12" sm="4" md="4">
        <v-switch
          v-model="data.active"
          :label="`Item Activo?: ${isActive}`"
          @change="updateProps()"
        ></v-switch>
      </v-col>
      <v-col cols="12" md="6">
        <vue-autocomplete-object
          id="itemInput7"
          ref="itemInput7"
          action="unidadmedida/Vact_unidadmedida_data"
          getter="unidadmedida/Vget_unidadmedida_data"
          load="unidadmedida/Vget_unidadmedida_load"
          pk="idunidadmedida"
          label="Unidad Medida"
          title="descunidadmedida"
          validar="idunidadmedida"
          permission="Unidad Medida"
          v-model="data.idunidadmedida"
          @change="updateProps()"
          @keyup.native.enter="
            next(7, data.idunidadmedida.idunidadmedida),
              ($refs.itemInput7.$children[0].isMenuActive = false)
          "
        />
      </v-col>
      <v-col cols="12" md="6">
        <vue-text-field
          :label="
            `Contenido del Item en ${ data.idunidadmedida ? data.idunidadmedida.descunidadmedida : null ||
              'Unidad'}`
          "
          id="itemInput8"
          :readonly="data.idunidadmedida == null || data.idunidadmedida.idunidadmedida === '' ? true : false"
          v-model="data.cant_contiene"
          :error-messages="error['cant_contiene']"
          @keyup.native="updateProps()"
          @keyup.native.enter="next(8, data.cant_contiene)"
        />
      </v-col>
      <v-col cols="12">
        <vue-autocomplete-object
          id="itemInput9"
          ref="itemInput9"
          action="moneda/Vact_moneda_data"
          getter="moneda/Vget_moneda_data"
          load="moneda/Vget_moneda_load"
          pk="idmoneda"
          label="Seleccione Moneda de Precio"
          title="descmoneda"
          validar="idmoneda"
          permission="Moneda"
          v-model="data.idmoneda"
          @change="updateProps()"
          @keyup.native.enter="
            next(9, data.idmoneda.idmoneda),
              ($refs.itemInput9.$children[0].isMenuActive = false)
          "
        />
      </v-col>
      <v-col cols="12">
        <vue-text-field
          label="Cantidad minima"
          :rules="false"
          id="itemInput10"
          v-model="data.minitem"
          :error-messages="error['minitem']"
          @keyup.native="updateProps()"
          @keyup.native.enter="next(10, data.minitem)"
        />
      </v-col>

      <v-col cols="12">
        <vue-autocomplete-generic
          id="itemInput11"
          ref="itemInput11"
          action="cuenta/Vact_cuenta_data"
          getter="cuenta/Vget_cuenta_data"
          load="cuenta/Vget_cuenta_load"
          pk="idcuenta"
          label="Seleccione cuenta"
          title="desccuenta"
          permission="Cuenta"
          :create="true"
          @create="abrircuenta = $event"
          v-model="data.idcuenta.idcuenta"
          @change="updateProps()"
          @sync-input="syncInput = $event"
          @keyup.native.enter="
            next(11, data.idcuenta.idcuenta),
              ($refs.itemInput11.$children[0].isMenuActive = false)
          "
        />
        <div v-if="abrircuenta">
          <Item-Cuenta-create
            v-model="abrircuenta"
            :sync-input="syncInput"
            @sync-input="(data.idcuenta.idcuenta = $event), next(11, '-')"
          />
        </div>
      </v-col>
      <v-col cols="12">
        <vue-autocomplete-generic
          id="itemInput12"
          ref="itemInput12"
          action="item/Vact_tipoitem_data"
          getter="item/Vget_tipoitem_data"
          load="item/Vget_item_load"
          pk="idtipo_item"
          label="Tipo Item"
          title="desctipo_item"
          permission="Tipo Item"
          @change="updateProps()"
          v-model="data.idtipo_item.idtipo_item"
          @keyup.native.enter="
            next(12, data.idtipo_item.idtipo_item),
              ($refs.itemInput12.$children[0].isMenuActive = false)
          "
        />
      </v-col>
      <v-col cols="12">
        <AutocompleteObjetivo
          id="itemInput13"
          ref="itemInput13"
          create
          dense
          :rules="[]"
          clearable
          outlined
          label="Objetivo del Item"
          v-model="data.idobjetivo"
          @change="updateProps()"
          @keyup.native.enter="next(13, '-'), $refs.itemInput13.isMenuActive()"
        />
      </v-col>
    </v-row>
    <div>
      <Analisis-Medicamento-CreateRef
        v-if="tipoMedicina"
        ref="refMedicina"
        :props="data.idmedicamento"
      />
    </div>
  </div>
</template>

<script>
import { booleanConverter } from "@/services/util/boolean.service";
import { mapGetters } from "vuex";
import { focus } from "@/services/focus.service";
import AutocompleteObjetivo from "../Objetivo/autocomplete";

export default {
  components: {
    AutocompleteObjetivo,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    error: [Object, Array],
    crudMode: {
      type: String,
      default: null,
    },
  },
  mounted() {
    let data = this.value;
    if (!data.idobjetivo) data.idobjetivo = { idobjetivo: null };
    this.data = JSON.parse(JSON.stringify(data));
    if (this.crudMode === "AGRO" || this.crudMode === "COMPRA") {
      this.data.idmoneda = { idmoneda: 1, descmoneda: "DOLAR" };
    }
  },
  watch: {
    value(val) {
      this.data = JSON.parse(JSON.stringify(val));
    },
  },
  computed: {
    ...mapGetters("empresa", ["Vget_empresa_moneda"]),
    tipoVivo() {
      return booleanConverter.si_no(this.data.tipoitem);
    },
    isStockeable() {
      return booleanConverter.si_no(this.data.stock);
    },
    precioPorcentaje() {
      return booleanConverter.si_no(this.data.porcentaje);
    },
    isActive() {
      return booleanConverter.si_no(this.data.active);
    },
    tipoMedicina() {
      if (
        this.data.idtipo_item.idtipo_item === 2 ||
        this.data.idtipo_item.idtipo_item === 3
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    updateProps() {
      this.$emit("input", JSON.parse(JSON.stringify(this.data)));
    },
    next(index, data) {
      focus.nextidName("itemInput", index, data);
    },
    validateMedicina() {
      return this.$refs.refMedicina.validate();
    },
    getDataMedicina() {
      return this.$refs.refMedicina.obtenerDatos();
    },
  },
  data() {
    return {
      syncInput: null,
      abrircuenta: false,
      data: {
        idcategoriaitem: {
          idcategoriaitem: "",
        },
        idtipo_item: {
          idtipo_item: "",
        },
        descitem: "",
        idmarca: {
          idmarca: "",
        },
        idunidadmedida: {
          idunidadmedida: 1,
          descunidadmedida: "Unidad",
        },
        idmoneda: {
          idmoneda: "",
        },
        preciov: "",
        cant_contiene: 1,
        porcentaje: false,
        precitem: "",
        minitem: "",
        tipoitem: false,
        stock: false,
        idcuenta: {
          idcuenta: "",
        },
        idobjetivo: {
          idobjetivo: "",
        },
        idmedicamento: null,
        band: "",
        active: true
      },
    };
  },
};
</script>

<style></style>
