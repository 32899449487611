<template>
  <v-card>
    <v-overlay :value="load">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card-title>
      <div class="text-center">Informe de {{ title }}</div>
      <v-divider class="mx-1" inset vertical></v-divider>
      <IncludeCH :url="api" :name="title"/>
    </v-card-title>
    <v-card-text>
      <div>
        <v-chart :options="options" autoresize />
      </div>
    </v-card-text>
    <v-card-text class="text-center text-h5"> {{ media }} </v-card-text>
  </v-card>
</template>
<script>
import { db } from "@/services/db.service";
import ECharts from "vue-echarts/components/ECharts";
import IncludeCH from '@/components/widgets/IncludeCH.vue';
export default {
  components: {
    "v-chart": ECharts,
    IncludeCH,
  },
  props: {
    title: String,
    api: String,
    reload: Boolean,
    desde: String,
    hasta: String,
  },
  data: () => ({
    media: null,
    load: false,
    options: {
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b}: {c} ({d}%)",
      },
      legend: {
        type: "scroll",
        orient: "vertical",
        left: 0,
        data: ["data1", "data2", "data3", "data4", "data5"],
        textStyle: {
          color: "#000",
        },
      },
      series: [
        {
          name: "Name",
          type: "pie",
          radius: ["50%", "70%"],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: "center",
          },
          emphasis: {
            label: {
              show: true,
              fontSize: "10",
              fontWeight: "bold",
            },
          },
          labelLine: {
            show: false,
          },
          data: [
            { value: 335, name: "data1" },
            { value: 310, name: "data2" },
            { value: 234, name: "data3" },
            { value: 135, name: "data4" },
            { value: 1548, name: "data5" },
          ],
        },
      ],
    },
  }),
  watch: {
    darkMode() {
      this.changeDarkMode();
    },
    reload() {
      this.obtenerDatos();
    }
  },
  async mounted() {
    this.options.legend.textStyle.color = this.darkMode ? "#eee" : "#000";
    this.obtenerDatos();
  },
  computed: {
    darkMode() {
      return this.$vuetify.theme.dark;
    },
  },
  methods: {
    changeDarkMode() {
      this.options.legend.textStyle.color = this.darkMode ? "#eee" : "#000";
    },
    async obtenerDatos() {
      this.load = true;
      try {
        const response = await db.get(`${this.api}?start=${this.desde}&end=${this.hasta}`);
        this.options.series[0].data = response.data;
        this.options.series[0].name = response.tipo;
        let legend = [];
        response.data.forEach((x) => {
          legend.push(x.name);
        });
        this.options.legend.data = legend;
        this.media = response.media;
        this.exportar({api: this.api, data: response.data});
      } catch (error) {
        console.log(error);
      }
      this.load = false;
    },
    exportar(val) {
      this.$emit('obtener', val);
    }
  },
};
</script>
