
export default {

  Vget_celo_data: (state) => {
    return state.celo_data
  },
  Vget_celo_nuevo: (state) => {
    return state.celo_nuevo
  },
  Vget_celo_historico: (state) => {
    return state.celo_historico
  },
  Vget_celo_load: (state) => {
    return state.celo_load
  },
  Vget_celo_save_load: (state) => {
    return state.celo_save_load
  }
}
