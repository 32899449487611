export default [
      {
        path: '/compra',
        name: 'Compra',
        component: () => import( /* webpackChunkName: "Compra" */ '@/views/Compra/Compra/Compra.vue'),
      },
      {
        path: '/egreso',
        name: 'Egreso',
        component: () => import( /* webpackChunkName: "Compra" */ '@/views/Compra/Egreso/Egreso.vue'),
      },
      {
        path: '/pagocuenta',
        name: 'Pago Cuenta',
        component: () => import( /* webpackChunkName: "Compra" */ '@/views/Compra/PagoCuenta/PagoCuenta.vue'),
      },
      {
        path: '/notacompra',
        name: 'Nota de Credito Compra',
        component: () => import( /* webpackChunkName: "Compra" */ '@/views/Compra/NotaCredito/NotaCredito.vue'),
      },
      {
        path: '/extractoproveedor',
        name: 'Extracto de Proveedor',
        component: () => import( /* webpackChunkName: "Compra" */ '@/views/Compra/ExtractoProveedor/ExtractoProveedor.vue'),
      },
      {
        path: '/librocompra',
        name: 'Libro de Compras',
        component: () => import( /* webpackChunkName: "Compra" */ '@/views/Compra/Reporte/LibroCompra.vue'),
      }
    ]