import jsPDF from 'jspdf'
import { current_date } from "@/services/util/date.service";
import { Workbook } from "exceljs";
import * as fs from "file-saver";


const drawAplicacion = (doc, x, pos) => {
  let start = pos + 20
  doc.setFontSize(12);
  doc.text(
    'REGISTRO DE APLICACIONES (AEREAS Y TERRESTRES)',
    doc.internal.pageSize.width / 2,
    pos + 20,
    { align: 'center' }
  )
  doc.setFontSize(8);
  doc.text(
    `CICLO: ${x.ciclo}`,
    10,
    pos + 30,
    { align: 'left' }
  )
  doc.text(
    `Parcela: ${x.parcela}`,
    60,
    pos + 30,
    { align: 'left' }
  )
  doc.text(
    `F.U.Aplic.Fung: ${x.fuaplicfung}`,
    140,
    pos + 30,
    { align: 'left' }
  )
  doc.text(
    `DDA: ${x.dda}`,
    180,
    pos + 30,
    { align: 'left' }
  )
  doc.text(
    `Empresa: ${x.empresa}`,
    10,
    pos + 35,
    { align: 'left' }
  )
  doc.text(
    `Variedad: ${x.variedad}`,
    60,
    pos + 35,
    { align: 'left' }
  )
  doc.text(
    `F. de Siembra: ${x.fdesiembra}`,
    140,
    pos + 35,
    { align: 'left' }
  )
  doc.text(
    `DDS: ${x.dds}`,
    180,
    pos + 35,
    { align: 'left' }
  )
  let dataTemporal = []
  x.detalle.forEach((z) => {
    z.forEach((y) => {
      dataTemporal.push([
        y.fecha,
        y.descripcion.cultivo,
        y.descripcion.area,
        y.producto.nombre,
        y.producto.ingrediente,
        y.tratamiento.dosis,
        y.tratamiento.objetivo,
        y.tratamiento.aplicador,
        y.tecnologia.epi,
        y.tecnologia.pico,
        y.tecnologia.agua,
        y.condiciones.temperatura,
        y.condiciones.humedad,
        y.condiciones.viento,
      ])
    })
  });
  let header = [
    [
      { content: 'Fecha', rowSpan: 2, styles: { valign: 'middle', halign: 'center' } },
      { content: 'Descripcion del area de aplicacion', colSpan: 2, styles: { valign: 'middle', halign: 'center' } },
      { content: 'Descripcion del producto', colSpan: 2, styles: { valign: 'middle', halign: 'center' } },
      { content: 'Descripcion del tratamiento', colSpan: 3, styles: { valign: 'middle', halign: 'center' } },
      { content: 'Tecnologia de aplicacion', colSpan: 3, styles: { valign: 'middle', halign: 'center' } },
      { content: 'Condiciones climaticas', colSpan: 3, styles: { valign: 'middle', halign: 'center' } },
    ],
    [
      'Cultivo',
      'Area(Ha)',
      'Nombre comercial del producto',
      'Ingrediente activo % registro ',
      'Dosis utilizada por Ha',
      'Objetivo de la aplicacion',
      'Nombre y apellido del aplicador',
      'EPI usado (Si/No)',
      'Tipos de picos',
      'Volumen de agua utilizado',
      'Temperatura (ºC)',
      'Humedad (%)',
      'Viento (Km/h)',
    ]
  ]
  doc.autoTable({
    head: header,
    body: dataTemporal,
    startY: 40 + pos,
    theme: 'plain',
    headStyles: { fontSize: 6, textColor: [0, 0, 0], lineWidth: 0.2 },
    styles: { fontSize: 6, lineWidth: 0.2 }
  })
  doc.text(
    `Nombre del productor: ${x.productor}`,
    10,
    doc.previousAutoTable.finalY + 10,
    { align: 'left' }
  )
  doc.text(
    `Localidad: ${x.localidad}`,
    10,
    doc.previousAutoTable.finalY + 15,
    { align: 'left' }
  )
  doc.text(
    `Distrito: ${x.distrito}`,
    10,
    doc.previousAutoTable.finalY + 20,
    { align: 'left' }
  )
  doc.text(
    `Departamento: ${x.departamento}`,
    10,
    doc.previousAutoTable.finalY + 25,
    { align: 'left' }
  )
  doc.setFontSize(12);
  doc.text(
    x.declaracion,
    doc.internal.pageSize.width / 2,
    doc.previousAutoTable.finalY + 30,
    { align: 'center' }
  )
  doc.setFontSize(10);
  doc.text(
    `Asesor tecnico: ${x.tecnico}`,
    10,
    doc.previousAutoTable.finalY + 40,
    { align: 'left' }
  )
  doc.text(
    'Firma: ____________',
    80,
    doc.previousAutoTable.finalY + 40,
    { align: 'left' }
  )
  doc.text(
    `Mat. Prof. Nº: ${x.mat_prof}`,
    120,
    doc.previousAutoTable.finalY + 40,
    { align: 'left' }
  )
  doc.text(
    `Reg. SENAVE: ${x.senave}`,
    150,
    doc.previousAutoTable.finalY + 40,
    { align: 'left' }
  )
  let end = doc.previousAutoTable.finalY + 40
  return end - start
}

export const exportSenavePDF2 = (datos, config) => {
  console.log(config)
  var doc = new jsPDF({ orientation: 'p', unit: 'mm', format: 'legal' })
  datos.forEach((x) => {
    var medir = new jsPDF({ orientation: 'p', unit: 'mm', format: 'legal' })
    var dist = drawAplicacion(medir, x, 0)
    var medida = doc.internal.pageSize.height - 40
    var utilizado = doc.previousAutoTable.finalY != undefined ? doc.previousAutoTable.finalY + 40 : 0
    if ((dist + utilizado) <= medida) {
      dist = drawAplicacion(doc, x, utilizado)
    } else {
      doc.addPage('legal', 'p')
      dist = drawAplicacion(doc, x, 0)
    }
  })
  doc.save('Senave' + '' + current_date() + '.pdf')
}

export const exportSenavePDF = (datos, config) => {
  console.log(config)
  var doc = new jsPDF({ orientation: 'l', unit: 'mm', format: 'a4' })
  let i = 0;
  datos.forEach((x) => {
    doc.setFontSize(12);
    doc.text(
      'REGISTRO DE APLICACIONES (AEREAS Y TERRESTRES)',
      doc.internal.pageSize.width / 2,
      20,
      { align: 'center' }
    )
    doc.setFontSize(10);
    doc.text(
      `CICLO: ${x.ciclo}`,
      20,
      30,
      { align: 'left' }
    )
    doc.text(
      `Parcela: ${x.parcela}`,
      60,
      30,
      { align: 'left' }
    )
    doc.text(
      `F.U.Aplic.Fung: ${x.fuaplicfung}`,
      180,
      30,
      { align: 'left' }
    )
    doc.text(
      `DDA: ${x.dda}`,
      240,
      30,
      { align: 'left' }
    )
    doc.text(
      `Empresa: ${x.empresa}`,
      20,
      35,
      { align: 'left' }
    )
    doc.text(
      `Variedad: ${x.variedad}`,
      90,
      35,
      { align: 'left' }
    )
    doc.text(
      `F. de Siembra: ${x.fdesiembra}`,
      180,
      35,
      { align: 'left' }
    )
    doc.text(
      `DDS: ${x.dds}`,
      240,
      35,
      { align: 'left' }
    )
    let dataTemporal = []
    x.detalle.forEach((z) => {
      z.forEach((y) => {
        dataTemporal.push([
          y.fecha,
          y.descripcion.cultivo,
          y.descripcion.area,
          y.producto.nombre,
          y.producto.ingrediente,
          y.tratamiento.dosis,
          y.tratamiento.objetivo,
          y.tratamiento.aplicador,
          y.tecnologia.epi,
          y.tecnologia.pico,
          y.tecnologia.agua,
          y.condiciones.temperatura,
          y.condiciones.humedad,
          y.condiciones.viento,
        ])
      })
    });
    let header = [
      [
        { content: 'Fecha', rowSpan: 2, styles: { valign: 'middle', halign: 'center' } },
        { content: 'Descripcion del area de aplicacion', colSpan: 2, styles: { valign: 'middle', halign: 'center' } },
        { content: 'Descripcion del producto', colSpan: 2, styles: { valign: 'middle', halign: 'center' } },
        { content: 'Descripcion del tratamiento', colSpan: 3, styles: { valign: 'middle', halign: 'center' } },
        { content: 'Tecnologia de aplicacion', colSpan: 3, styles: { valign: 'middle', halign: 'center' } },
        { content: 'Condiciones climaticas', colSpan: 3, styles: { valign: 'middle', halign: 'center' } },
      ],
      [
        'Cultivo',
        'Area(Ha)',
        'Nombre comercial del producto',
        'Ingrediente activo % registro ',
        'Dosis utilizada por Ha',
        'Objetivo de la aplicacion',
        'Nombre y apellido del aplicador',
        'EPI usado (Si/No)',
        'Tipos de picos',
        'Volumen de agua utilizado',
        'Temperatura (ºC)',
        'Humedad (%)',
        'Viento (Km/h)',
      ]
    ]
    doc.autoTable({
      head: header,
      body: dataTemporal,
      startY: 40,
      theme: 'plain',
      headStyles: { fontSize: 8, textColor: [0, 0, 0], lineWidth: 0.2 },
      styles: { fontSize: 8, lineWidth: 0.2 }
    })
    doc.text(
      `Nombre del productor: ${x.productor}`,
      20,
      doc.previousAutoTable.finalY + 10,
      { align: 'left' }
    )
    doc.text(
      `Localidad: ${x.localidad}`,
      20,
      doc.previousAutoTable.finalY + 15,
      { align: 'left' }
    )
    doc.text(
      `Distrito: ${x.distrito}`,
      20,
      doc.previousAutoTable.finalY + 20,
      { align: 'left' }
    )
    doc.text(
      `Departamento: ${x.departamento}`,
      20,
      doc.previousAutoTable.finalY + 25,
      { align: 'left' }
    )
    doc.setFontSize(12);
    doc.text(
      x.declaracion,
      doc.internal.pageSize.width / 2,
      doc.previousAutoTable.finalY + 30,
      { align: 'center' }
    )
    doc.setFontSize(10);
    doc.text(
      `Asesor tecnico: ${x.tecnico}`,
      20,
      doc.previousAutoTable.finalY + 40,
      { align: 'left' }
    )
    doc.text(
      'Firma: ____________',
      100,
      doc.previousAutoTable.finalY + 40,
      { align: 'left' }
    )
    doc.text(
      `Mat. Prof. Nº: ${x.mat_prof}`,
      160,
      doc.previousAutoTable.finalY + 40,
      { align: 'left' }
    )
    doc.text(
      `Reg. SENAVE: ${x.senave}`,
      200,
      doc.previousAutoTable.finalY + 40,
      { align: 'left' }
    )
    i++
    if (i < datos.length) {
      doc.addPage("a4", "landscape")
    }
  })
  doc.save('Senave' + '' + current_date() + '.pdf');
}

const rellenarDetalle = (data) => {
  const currentLength = data.length + 1;
  const length = 23 - currentLength;
  for (let index = 1; index < length; index++) {
    data.push(["", "", "", "", "", "", "", "", "", "", "", "", "", ""]);
  }
  return data;
};
export const exportSenave = (datos, config) => {
  let workbook = new Workbook();
  if (datos.length === 0) workbook.addWorksheet("HOJA 1");
  const header = [
    "Fecha",
    "Descripcion del area de aplicacion",
    "",
    "Descripcion del producto",
    "",
    "Descripcion del tratamiento",
    "",
    "",
    "Tecnologia de aplicacion",
    "",
    "",
    "Condiciones climaticas",
    "",
    "",
  ];
  const subheader = [
    "",
    "Cultivo",
    "Area(Ha)",
    "Nombre comercial del producto",
    "Ingrediente activo % Registro",
    "Dosis utilizada por ha",
    "Objetivos de la aplicacion",
    "Nombre y apellido del aplicador",
    "EPI Usado (Si/No)",
    "Tipos de picos",
    "Volumen de agua utilizado",
    "Temperatura (ºC)",
    "Humedad (%)",
    "Viento (km/h)",
  ];
  datos.forEach((pestana) => {
    let worksheet = workbook.addWorksheet(pestana.pestana);
    pestana.detalle.map((hoja) => {
      let dataTemp = hoja.map((x) => {
        return [
          x.fecha,
          x.descripcion.cultivo,
          x.descripcion.area,
          x.producto.nombre,
          x.producto.ingrediente,
          x.tratamiento.dosis,
          x.tratamiento.objetivo,
          x.tratamiento.aplicador,
          x.tecnologia.epi,
          x.tecnologia.pico,
          x.tecnologia.agua,
          x.condiciones.temperatura,
          x.condiciones.humedad,
          x.condiciones.viento,
        ];
      });
      const data = rellenarDetalle(dataTemp);
      const titles = [
        [
          `CICLO: ${pestana.ciclo}`,
          "",
          "",
          "",
          "",
          `Parcela: ${pestana.parcela}`,
          "",
          "",
          "",
          "F.U.Aplic.Fung:",
          pestana.fuaplicfung,
          pestana.today,
          "DDA:",
          pestana.dda,
        ],
        [
          `Empresa: ${pestana.empresa}`,
          "",
          "",
          "",
          "",
          `Variedad: ${pestana.variedad}`,
          "",
          "",
          "",
          "F. de siembra:",
          pestana.fdesiembra,
          pestana.today,
          "DDS:",
          pestana.dds,
        ],
      ];
      const footer = [
        [],
        [
          `Nombre del productor: ${config.departamento}`,
          "",
          "",
          "",
          "",
          `Localidad: ${config.localidad}`,
          "",
          "",
          `Distrito: ${config.distrito}`,
          "",
          "",
          `Departamento: ${config.departamento}`,
        ],
        [],
        ["El contenido de esta planilla tiene carácter de declaración jurada"],
        [config.asesor, "", "", "", "", "----------------------"],
        [
          `Nombre del asesor tecnico`,
          "",
          "",
          "",
          "",
          "Firma",
          "",
          "",
          `Mat. Prof. Nº: ${config.matricula}`,
          "",
          `Reg. SENAVE: ${config.regsenave}`,
        ],
        [],
      ];
      worksheet.addRow([pestana.titulo]).font = {
        name: "Arial",
        family: 4,
        size: 10,
        bold: true,
      };
      titles.map((t) => {
        let row = worksheet.addRow(t);
        row.font = {
          name: "Arial",
          family: 4,
          size: 10,
          bold: true,
        };
        const rowNumber = row._number;
        worksheet.mergeCells(`A${rowNumber}:B${rowNumber}`);
        worksheet.mergeCells(`F${rowNumber}:G${rowNumber}`);
      });
      let headerRow = worksheet.addRow(header);
      let subHeaderRow = worksheet.addRow(subheader);

      let rowNumber = headerRow._number;
      worksheet.mergeCells(`A${rowNumber}:A${rowNumber + 1}`);
      worksheet.mergeCells(`B${rowNumber}:C${rowNumber}`);
      worksheet.mergeCells(`D${rowNumber}:E${rowNumber}`);
      worksheet.mergeCells(`F${rowNumber}:H${rowNumber}`);
      worksheet.mergeCells(`I${rowNumber}:K${rowNumber}`);
      worksheet.mergeCells(`L${rowNumber}:N${rowNumber}`);

      headerRow.eachCell((cell) => {
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
        };
        cell.border = {
          top: { style: "medium" },
          left: { style: "medium" },
          right: { style: "medium" },
          bottom: { style: "medium" },
        };
        cell.font = {
          name: "Arial",
          family: 4,
          size: 10,
        };
      });

      subHeaderRow.eachCell((cell) => {
        cell.border = {
          top: { style: "medium" },
          left: { style: "medium" },
          bottom: { style: "medium" },
          right: { style: "medium" },
        };
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
          wrapText: true,
        };
        cell.font = {
          name: "Arial",
          family: 4,
          size: 10,
        };
      });
      data.forEach((x) => {
        let row = worksheet.addRow(x);
        row.eachCell((cell) => {
          cell.alignment = {
            vertical: "middle",
            horizontal: "center",
          };
          cell.border = {
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
            top: { style: "thin" },
          };
          cell.font = {
            name: "Arial",
            family: 4,
            size: 10,
          };
        });
      });

      footer.forEach((x) => {
        let row = worksheet.addRow(x);
        row.eachCell((cell) => {
          cell.font = {
            name: "Arial",
            family: 4,
            size: 10,
          };
        });
        const rowNumber = row._number;
        worksheet.mergeCells(`A${rowNumber}:E${rowNumber}`);
        worksheet.mergeCells(`F${rowNumber}:H${rowNumber}`);
        worksheet.mergeCells(`I${rowNumber}:K${rowNumber}`);
        worksheet.mergeCells(`L${rowNumber}:N${rowNumber}`);
      });

      worksheet.columns.map((column) => {
        let widthMax = 0;
        column.values.map((x, i) => {
          if (x)
            if (x.length)
              if (i > 7 && i < 10) if (x.length > widthMax) widthMax = x.length;
        });
        column.width = widthMax < 10 ? 13 : widthMax;
      });
    });
  });
  workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    fs.saveAs(
      blob,
      `REGISTRO DE APLICACIONES AEREAS Y TERRESTRES ${current_date()}.xlsx`
    );
  });
};
