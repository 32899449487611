<template>
  <div>
    <v-dialog :value="value" max-width="700px" persistent @keydown.esc="cancelar()">
      <v-card>
        <v-form ref="form" lazy-validation @submit.prevent="nextfocus(1)">
          <v-card-title>
            <span class="headline">{{titulo}}</span>
          </v-card-title>
          <v-card-text>
            <v-row row dense>
              <v-col cols="12">
                <v-text-field
                  dense
                  type="text"
                  :rules="validar"
                  ref="input1"
                  v-model="data.desccuenta"
                  label="Descripcion"
                  required
                  outlined
                  @keyup.enter="nextfocus(1)"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="red" text @click="cancelar">
              <v-icon>clear</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn ref="aceptar" color="primary" text @click="guardar">
              <v-icon>done</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
        <c-overlay :value="Vget_cuenta_save_load"></c-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { mensaje } from "@/services/notify.service";
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    'sync-input':{
      type: [String,Number],
      default: null
    }
  },
  data() {
    return {
      titulo: "Nuevo cuenta",
      data: {
        desccuenta: ""
      },
      default: {
        desccuenta: ""
      },
      url: "/apicuenta/",
      validar: [v => !!v || "Campo requerido"],
      validars: [v => !!v || "Seleccione un campo"],
      formulario: {},
      dialogo: false,
      operacion: true
    };
  },
  computed: {
    ...mapGetters("cuenta", {
      getupdate: "cuenta_update"
    }),
    ...mapGetters("cuenta", ["Vget_cuenta_save_load"])
  },
  watch: {
    $route(to, from) {
      if (from.path === "/cuenta") return this.cancelar();
    }
  },
  created() {
    this.nuevo(this.getupdate);
  },
  mounted() {
    setTimeout(() => this.$refs.input1.focus(), 500);
    if (this.syncInput != null) this.data.desccuenta = this.syncInput;
  },
  methods: {
    ...mapActions("cuenta", [
      "cuenta_update",
      "Vact_cuenta_data",
      "Vact_cuenta_save",
      "Vact_cuenta_update"
    ]),
    nextfocus(x) {
      switch (x) {
        case 1: {
          if (this.data.desccuenta != "") {
            this.$refs.aceptar.$el.focus();
          }
          break;
        }
      }
    },
    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.dialogo = false;
      this.cuenta_update(null);
      this.Vact_cuenta_data();
      this.operacion = true;
      this.$emit("input", false);
    },
    async guardar_finalizar() {
      const sync = this.syncInput;
      let a = await this.Vact_cuenta_save(this.data);
      if (a.estado == true) {
        if(sync != null){
          this.cancelar();
          this.$emit('sync-input',a.data.data.id);
        }
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$notify(a.info);
        this.$refs.form.resetValidation();
        this.$refs.input1.focus();
      } else {
        a.info.map(x => {
          this.$notify(mensaje.error(x));
        });
      }
    },
    async editar_finalizar() {
      const id = this.getupdate.idcuenta;
      const data = this.data;
      let a = await this.Vact_cuenta_update({id,data});
      if (a.estado == true) {
        this.$notify(a.info);
        this.cancelar();
      } else {
        a.info.map(x => {
          this.$notify(mensaje.error(x));
        });
      }
    },
    guardar() {
      if (this.$refs.form.validate()) {
        if (this.operacion === true) return this.guardar_finalizar();
        if (this.operacion === false) return this.editar_finalizar();
      } else {
        this.$notify(mensaje.completar());
      }
    },
    nuevo(val) {
      this.dialogo = true;

      if (val !== null) {
        this.data = val;
        this.operacion = false;
      }
    }
  }
};
</script>

