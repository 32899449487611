<template>
  <v-dialog :value="value" width="800" persistent @keydown.esc="close()">
    <v-card>
      <v-toolbar flat dense>
        <v-toolbar-title>{{
          isEdit ? "Modificar Venta de Ganado" : "Registrar Venta de Ganado"
        }}</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-form ref="form" @submit.prevent="">
          <v-row dense>
            <v-col cols="12" sm="6">
              <c-text-date
                label="Fecha"
                dense
                ref="venta1"
                outlined
                v-model="data.fecha"
                @keyup.native.enter="next(1, data.fecha)"
              ></c-text-date>
            </v-col>
            <v-col cols="12" sm="6">
              <vue-autocomplete-moneda-day
                label="Moneda"
                ref="venta2"
                v-model="data.idmoneda"
                :chips="false"
                 @keyup.native.enter="next(2, data.idmoneda.idmoneda)"
              />
            </v-col>
          </v-row>
        </v-form>
        <v-form ref="formDetail">
          <v-row dense>
            <v-col cols="12" sm="4">
              <AutocompleteAnimal
                action="fetchVivo"
                :key="key"
                getter="getVivo"
                ref="venta3"
                label="Seleccione Animal"
                return-object
                v-model="detalle.idanimal"
                @keyup.native.enter="next(3, detalle.idanimal.idanimal)"
              />
            </v-col>

            <v-col cols="9" sm="3">
              <c-text-currency
                label="Precio"
                ref="venta4"
                dense
                outlined
                v-model="detalle.precio"
                @keyup.native.enter="next(4, detalle.precio)"
              ></c-text-currency>
            </v-col>
            <v-spacer></v-spacer>
            <v-btn
              text
              fab
              small
              outlined
              color="primary"
              @click="addTable()"
              ref="venta5"
              ><v-icon>add</v-icon></v-btn
            >
          </v-row>
          <v-data-table
            :headers="headers"
            :items="data.detalle"
            :items-per-page="9999"
            hide-default-footer
          >
            <template v-slot:[`item.accion`]="{ item }">
              <v-btn text fab x-small @click="eliminar(item)"
                ><v-icon>delete</v-icon></v-btn
              >
              <v-btn text fab x-small @click="editar(item)"
                ><v-icon>edit</v-icon></v-btn
              >
            </template>
          </v-data-table>
        </v-form>
        <v-row dense>
          <c-text-field
            label="Comentario"
            v-model="data.comentario"
            outlined
            dense
            :rules="[]"
          ></c-text-field>
        </v-row>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <c-btn-crud-close @click="close()"></c-btn-crud-close>
        <v-spacer></v-spacer>
        <c-btn-crud-done @click="guardar()"></c-btn-crud-done>
      </v-card-actions>
      <c-overlay :value="isLoading"></c-overlay>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { current_date } from "@/services/util/date.service";
import AutocompleteAnimal from "../Animal/AutocompleteAnimal";

export default {
  components: {
    AutocompleteAnimal,
  },
  props: {
    value: Boolean,
    props: Object,
  },
  data: () => ({
    isEdit: false,
    key: 1,
    headers: [
      { text: "Nombre Animal", align: "left", value: "idanimal.nombanimal" },
      { text: "Rp Animal", align: "left", value: "idanimal.rpanimal" },
      { text: "Precio", align: "left", value: "precio" },
      { text: "Accion", value: "accion", sortable: false, align: "right" },
    ],
    data: {
      fecha: current_date(),
      comentario: "",
      idmoneda: {
        idmoneda: null,
      },
      detalle: [],
    },
    default: {
      fecha: current_date(),
      comentario: "",
      idmoneda: {
        idmoneda: null,
      },
      detalle: [],
    },
    detalle: {
      idanimal: {
        idanimal: "",
      },
      precio: "",
    },
    detalle_default: {
      idanimal: {
        idanimal: "",
      },
      precio: "",
    },
  }),
  created() {
    if (this.props) {
      this.data = JSON.parse(JSON.stringify(this.props));
      this.isEdit = true;
    }
  },
  mounted() {
    setTimeout(() => this.next(0, "-"), 20);
  },
  computed: {
    ...mapGetters("ventaganado", ["isLoading"]),
  },
  methods: {
    ...mapActions("ventaganado", [
      "fetchGanado",
      "setGanado",
      "setGanadoUpdate",
    ]),
    close() {
      this.$emit("input", false);
      this.$emit("reset", null);
    },

    next(id, data) {
      if (data != "")
        try {
          this.$refs[`venta${id + 1}`].focus();
        } catch (error) {
          this.$refs[`venta${id + 1}`].$el.focus();
        }
    },
    isReapeat() {
      return this.data.detalle.find(
        (x) => x.idanimal.idanimal === this.detalle.idanimal.idanimal
      );
    },
    addTable() {
      if (!this.$refs.formDetail.validate() || this.isReapeat()) return null;
      this.data.detalle.push(this.detalle);
      this.detalle = JSON.parse(JSON.stringify(this.detalle_default));
      this.$refs.formDetail.resetValidation();
      this.next(2, "-");
    },
    async guardar() {
      if (!this.$refs.form.validate()) return null;
      const id = this.data.idventa_ganado;
      const response = this.isEdit
        ? await this.setGanadoUpdate({ id, data: this.data })
        : await this.setGanado(this.data);
      if (response.success) {
        this.key++;
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();
        this.fetchGanado();
        if (this.isEdit) return this.close();
      }
    },
    editar(item) {
      this.detalle = JSON.parse(JSON.stringify(item));
      this.eliminar(item);
    },
    eliminar(item) {
      const index = this.data.detalle.indexOf(item);
      this.data.detalle.splice(index, 1);
    },
  },
};
</script>
