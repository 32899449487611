<template>
    <div>
        <Help v-model="help" :content="todos"/>
        <v-alert type="error" v-if="permission_alert">
            Error! Este usuario no tiene permiso en esta ventana
        </v-alert>
        <v-toolbar flat dense>
            <v-toolbar-items>
                <AutocompleteCategoria :returnObject="true" id="presupuestos1" v-model="data.idfinanza_categoria"
                    @nextfocus="next(3, '-')" @keyup.native.enter="next(3, data.idfinanza_categoria)" />
                <v-btn class="ml-2" text fab small color="primary" id="aceptar" outlined @click="descargarDatos()">
                    <v-icon>cloud_download</v-icon>
                </v-btn>
            </v-toolbar-items>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <c-btn-table-help @click="help = true"></c-btn-table-help>
            </v-toolbar-items>
        </v-toolbar>
        <v-container>
            <v-row dense>
                <v-col v-for="(item, index) in items" :key="index" class="mt-2">
                    <v-card color="#385F73" dark>
                        <v-card-text>
                            <v-row dense>
                                <v-col class="text-h6">
                                    Categoria:
                                    <p class="font-weight-bold">{{ item.name }}</p>
                                </v-col>
                                <v-col class="text-h6">
                                    Presupuesto:
                                    <p class="font-weight-bold">{{ toCurrency(item.montos) }}</p>
                                </v-col>
                                <v-col class="text-h6">
                                    Gasto:
                                    <p class="font-weight-bold">{{ toCurrency(item.gastos) }}</p>
                                </v-col>
                                <v-col class="text-h6">
                                    Sobrantes:
                                    <p class="font-weight-bold">{{ toCurrency(item.sobrantes) }}</p>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                    <v-card class="mt-2">
                        <v-card-text>
                            <div v-for="(item2, index2) in item.detalle" :key="index2">
                                <v-card color="#1B5E20" dark class="mb-2">
                                    <v-card-text>
                                        <v-row dense>
                                            <v-col class="text-h7">
                                                Desde:
                                                <p class="font-weight-bold">{{ item2.desde }}</p>
                                            </v-col>
                                            <v-col class="text-h7">
                                                Hasta:
                                                <p class="font-weight-bold">{{ item2.hasta }}</p>
                                            </v-col>
                                            <v-col class="text-h7">
                                                Presupuesto:
                                                <p class="font-weight-bold">{{ toCurrency(item2.presupuesto) }}</p>
                                            </v-col>
                                            <v-col class="text-h7">
                                                Gasto:
                                                <p class="font-weight-bold">{{ toCurrency(item2.gasto) }}</p>
                                            </v-col>
                                            <v-col class="text-h7">
                                                Sobrante:
                                                <p class="font-weight-bold">{{ toCurrency(item2.sobrante) }}</p>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                                <v-data-table :headers="headers" :items="item2.detalle" hide-default-footer
                                    :items-per-page="1000000">
                                    <template v-slot:[`item.monto`]="props">
                                        <p class="font-weight-bold">
                                            {{ toCurrency(props.item.monto) }}
                                        </p>
                                    </template>
                                </v-data-table>
                                <v-divider></v-divider>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>
import { db } from "@/services/db.service";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { FINANZA } from "@/constants/forms";
import {
    PUEDE_AGREGAR,
    PUEDE_EDITAR,
    PUEDE_ELIMINAR,
    PUEDE_LISTAR,
} from "@/constants/permissions";
import AutocompleteCategoria from "../Categoria/Autocomplete.vue";
import { currency } from "@/services/util/number.service";
import Help from "../Help.vue";
import { REPORTES } from "@/constants/finanzas";
export default {
    components: {
        AutocompleteCategoria,
        Help,
    },
    computed: {
        permission_alert() {
            return mensaje.permission(this.permission);
        },
    },
    created() {
        this.$store.commit("SET_LAYOUT", "principal-layout");
        this.todos = REPORTES
        const permission = getPermission(FINANZA).permiso;
        this.permission = {
            can_add: permission[PUEDE_AGREGAR],
            can_view: permission[PUEDE_LISTAR],
            can_update: permission[PUEDE_EDITAR],
            can_delete: permission[PUEDE_ELIMINAR],
        };
    },
    methods: {
        async descargarDatos() {
            this.load = true
            try {
                const response = await db.get(`apifinanzapresupuestoscategoria/${this.data.idfinanza_categoria.idfinanza_categoria}`)
                this.items = response
            } catch (error) {
                console.log(error)
            }
            this.load = false
        },
        toCurrency(value) {
            return currency(value)
        },
    },
    data: () => ({
        help: false,
        todos: null,
        load: false,
        items: [],
        permission: {
            can_add: false,
            can_view: false,
        },
        headers: [
            {
                text: "Cuenta", align: "left", value: "cuenta"
            },
            {
                text: "Monto", align: "right", value: "monto"
            }
        ],
        data: {
            idfinanza_categoria: {
                idfinanza_categoria: "",
            },
        }
    })
}
</script>