
export default {

  Vget_cosecha_data: (state) => {
    return state.cosecha_data
  },
  Vget_cosecha_load: (state) => {
    return state.cosecha_load
  },
  Vget_cosecha_save_load: (state) => {
    return state.cosecha_save_load
  },
}
