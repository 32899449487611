<template>
    <v-dialog :value="value" persistent max-width="750" @keydown.esc="$emit('cancel')">
      <v-card>
        <v-card-title>
          <v-icon left>info</v-icon>
          <span class="title font-weight-light">Ayuda</span>
        </v-card-title>
        <v-card-text> <slot /></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <c-btn-crud-close @click="$emit('cancel')"></c-btn-crud-close>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },
  };
  </script>
  
  <style>
  </style>