<template>
  <div>
    <v-form
      ref="form"
      v-if="permission.reporte"
      @submit.prevent="obtenerDatos()"
    >
      <v-row class="ma-1">
        <v-col cols="12" sm="3">
          <AutocompleteAnimal
            action="fetchTodos"
            getter="getTodos"
            no-create
            v-model="data.idanimal"
          />
        </v-col>
        <v-col cols="12" sm="3">
          <c-text-date
            dense
            outlined
            :rules="[]"
            label="Filtrar desde"
            v-model="data.desde"
          ></c-text-date>
        </v-col>
        <v-col cols="9" sm="3">
          <c-text-date
            dense
            outlined
            :rules="[]"
            label="Filtrar hasta"
            v-model="data.hasta"
          ></c-text-date>
        </v-col>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          fab
          small
          class="mt-3"
          outlined
          text
          @click="$router.go(-1)"
        >
          <v-icon>arrow_back</v-icon>
        </v-btn>
        <v-btn
          color="primary"
          fab
          small
          class="mt-3"
          outlined
          text
          @click="obtenerDatos()"
        >
          <v-icon>cloud_download</v-icon>
        </v-btn>
        <v-btn
          color="red"
          fab
          small
          class="ml-1 mt-3"
          outlined
          text
          @click="generarExcel()"
        >
          <v-icon>get_app</v-icon>
        </v-btn>
      </v-row>
    </v-form>

    <v-card-text v-if="informacion != null && permission.reporte">
      <v-row dense>
        <v-col cols="12" sm="6">
          <v-card
            :color="!isDark ? 'pink lighten-5' : 'black'"
            class="rounded-xl pa-1"
            elevation="0"
          >
            <v-container>
              <div class="caption grey--text text-center">
                Informacion General
              </div>
              <span class="caption">Nombre:</span>
              <span class="caption ml-1 font-weight-black">
                {{ informacion.nombra }}</span
              >
              <div></div>
              <span class="caption">Rp:</span>
              <span class="caption ml-1 font-weight-black">
                {{ informacion.rp }}</span
              >
              <div></div>
              <span class="caption">Sexo:</span>
              <span class="caption ml-1 font-weight-black">
                {{ informacion.sexo }}</span
              >
              <div></div>
              <span class="caption">Nacimiento:</span>
              <span class="caption ml-1 font-weight-black">
                {{ formatDate(informacion.fecha) }} ({{
                  informacion.edad
                }}
                Años)</span
              >
              <div></div>
              <span class="caption">Raza:</span>
              <span class="caption ml-1 font-weight-black">
                {{ informacion.raza }}</span
              >
              <div></div>
              <span class="caption">Procedencia:</span>
              <span class="caption ml-1 font-weight-black">
                {{ informacion.procedencia }}</span
              >
              <div></div>
              <span class="caption">Estado Actual:</span>
              <span class="caption ml-1 font-weight-black">
                {{ informacion.estado }}</span
              >
            </v-container>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6">
          <v-card
            :color="isDark ? 'black' : 'blue-grey lighten-5'"
            class="rounded-xl pa-1"
            elevation="0"
          >
            <v-container>
              <div class="caption grey--text text-center">
                Informacion Reproductiva
              </div>
              <span class="caption">Ultimo Parto:</span>
              <span class="caption ml-1 font-weight-black">{{
                formatParto()
              }}</span>
              <div></div>
              <span class="caption">Ultima Inse:</span>
              <span class="caption ml-1 font-weight-black">{{
                formatInseminacion()
              }}</span>
              <div></div>
              <span class="caption">Prox Parto:</span>
              <span class="caption ml-1 font-weight-black">{{
                formatProxParto()
              }}</span>
              <div></div>
              <span class="caption">Aproximadamente en :</span>
              <span class="caption ml-1 font-weight-black">{{
                formatDiasAprox()
              }}</span>
              <div></div>
              <span class="caption">Intentos Inseminacion para Preñez:</span>
              <span class="caption ml-1 font-weight-black">
                {{ informacion.cant_ins }}</span
              >
              <div></div>
              <span class="caption">Efectividad Reproductiva:</span>
              <span class="caption ml-1 font-weight-black"
                >{{ informacion.porc_efe }} %</span
              >
              <div></div>
              <span class="caption">Cantidad Parto:</span>
              <span class="caption ml-1 font-weight-black">{{
                informacion.cant_parto
              }}</span>
              <div></div>
              <span class="caption">Cantidad Aborto:</span>
              <span class="caption ml-1 font-weight-black">{{
                informacion.cant_aborto
              }}</span>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-data-table
      v-if="permission.reporte"
      :loading="isLoading"
      :headers="headers"
      :items="items"
      dense
      :items-per-page="9999999999"
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:[`item.tipo`]="{ item }">
        <div v-html="formatTipo(item)"></div>
      </template>
      <template v-slot:[`item.fecha`]="{ item }">
        <div>{{ formatDate(item.fecha) }}</div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { getPermission } from "@/services/storage/permissionStorage.service";
import { dmy } from "@/services/util/date.service";
import { PUEDE_GENERAR_REPORTE } from "@/constants/permissions";
import { ANIMAL } from "@/constants/forms";
import { mapActions, mapGetters } from "vuex";
import { exportFicha, formatTipoHtml } from "./export";
import { currency } from "@/services/util/number.service";
export default {
  components: {
    AutocompleteAnimal: () =>
      import("@/views/Animal/Animal/AutocompleteAnimal"),
  },
  mounted() {
    this.handlerRouteParam();
  },
  watch: {
    $route(to) {
      if (to.path === "/reporteanimal") this.handlerRouteParam();
    },
    getFicha(val) {
      this.informacion = JSON.parse(JSON.stringify(val));
      this.items = JSON.parse(JSON.stringify(val.datos));
    },
  },
  computed: {
    ...mapGetters("animal", ["getFicha", "isLoading"]),
    isDark() {
      return this.$vuetify.theme.dark;
    },
  },
  data() {
    return {
      permission: {
        reporte: getPermission(ANIMAL).permiso[PUEDE_GENERAR_REPORTE],
      },
      informacion: null,
      items: [],
      headers: [
        { text: "Operacion", align: "left", value: "operacion" },
        { text: "Fecha", align: "left", value: "fecha" },
        { text: "Tipo", align: "left", value: "tipo" },
        { text: "Comentario", align: "right", value: "comentario" },
      ],
      data: {
        idanimal: "",
        desde: "",
        hasta: "",
      },
    };
  },
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
  },
  methods: {
    ...mapActions("animal", ["fetchFicha"]),
    formatTipo(item) {
      return formatTipoHtml(item);
    },
    formatParto() {
      return this.informacion.ult_parto_f
        ? this.formatDate(this.informacion.ult_parto_f) +
            " (Hace " +
            this.informacion.ult_parto +
            " Dias)"
        : "";
    },
    formatInseminacion() {
      return this.informacion.ult_inse_f
        ? this.formatDate(this.informacion.ult_inse_f) +
            " (Hace " +
            this.informacion.ult_inse +
            " Dias)"
        : "";
    },
    formatProxParto() {
      return this.informacion.fech_parto_inicio
        ? this.formatDate(this.informacion.fech_parto_inicio) +
            " a " +
            this.formatDate(this.informacion.fech_parto_fin)
        : "";
    },
    formatDiasAprox() {
      return this.informacion.dias_parto_inicio
        ? this.informacion.dias_parto_inicio + " Dias"
        : "";
    },
    formatDate(date) {
      return dmy(date);
    },
    formatNumber(value) {
      if (!value) return "";
      return currency(Number(value));
    },
    handlerRouteParam() {
      if (!this.$route.query.idanimal) return null;
      this.data.idanimal = Number(this.$route.query.idanimal);
      this.obtenerDatos();
    },
    async obtenerDatos() {
      if (!this.$refs.form.validate()) return null;
      await this.fetchFicha(this.data);
    },
    generarExcel() {
      exportFicha(this.items, this.informacion);
    },
  },
};
</script>
<style scoped>
table {
  border-collapse: collapse;
  width: 100%;
}
th {
  padding: 8px;
  text-align: left;
  font-size: small;
  border-bottom: 1px solid #ddd;
}
td {
  padding: 8px;
  text-align: right;
  font-size: small;
  border-bottom: 1px solid #ddd;
}
</style>
