
export default {

  Vget_formulario_data: (state) => {
    return state.formulario_data
  },
  Vget_formulario_load: (state) => {
    return state.formulario_load
  },
}
