<template>
  <div>
    <v-overlay :value="load">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-responsive class="overflow-x-auto" max-width="1920">
      <div class="chart-wrapper" :style="mainResponsive">
        <v-chart :options="data" autoresize/>
      </div>
    </v-responsive>
  </div>
</template>

<style scoped>
.echarts {
  width: 100%;
  height: 100%;
}
</style>

<script>
import { db } from "@/services/db.service";
import ECharts from "vue-echarts/components/ECharts";

export default {
  props:{
    datos:{
      type: [Object,Array,Number],
      default: null
    }
  },
  components: {
    "v-chart": ECharts
  },
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.viewtree(this.datos);
  },
  watch: {
    darkMode(val) {
      this.data.series[0].label.normal.textStyle.color = val ? '#eee' : '#000'
      this.data.series[0].leaves.label.normal.textStyle.color = val ? '#eee' : '#000'
    }
  },
  mounted() {
    this.data.series[0].label.normal.textStyle.color = this.darkMode ? '#eee' : '#000'
    this.data.series[0].leaves.label.normal.textStyle.color = this.darkMode ? '#eee' : '#000'
  },
  computed: {
    darkMode() {
      return this.$vuetify.theme.dark;
    },
    mainResponsive: (vm) => {
      return `height: ${vm.height}; width: ${vm.width}`
    },
    height: (vm) => {
      if (vm.$vuetify.breakpoint.xs) return "700px";
      if (vm.$vuetify.breakpoint.sm) return "700px";
      if (vm.$vuetify.breakpoint.md) return "700px";
      return "700px";
    },
    width: (vm) => {
      if (vm.$vuetify.breakpoint.xs) return "1920px";
      if (vm.$vuetify.breakpoint.sm) return "1920px";
      if (vm.$vuetify.breakpoint.md) return "100%";
      return "100%";
    }
  },
  methods: {
    async viewtree(id) {
      await db.get(`/apiarbolgenealogico/${id}/`).then(a => {
        this.options(a);
        this.load = false;
      });
    },
    options(data) {
      this.data.series[0].data = [data]
    }
  },
  data() {
    return {
      data: {
        tooltip: {
          trigger: "item",
          triggerOn: "mousemove"
        },
        series: [
          {
            type: "tree",

            data: [],

            top: "10%",
            left: "15%",
            bottom: "10%",
            right: "15%",
            /* layout: 'radial', */
            symbolSize: 10,
            orient: "RL",

            label: {
              normal: {
                position: "left",
                verticalAlign: "middle",
                align: "right",
                fontSize: 14,
                textStyle: {
                  color: "#000"
                }
              },
            },

            leaves: {
              label: {
                normal: {
                  position: "right",
                  verticalAlign: "middle",
                  align: "left",
                  textStyle: {
                    color: "#000"
                  }
                },
              }
            },

            expandAndCollapse: true,
            animationDuration: 550,
            animationDurationUpdate: 750
          }
        ]
      },
      load: true
    };
  }
};
</script>