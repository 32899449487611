
export default {


  egresoagro_update: (state) => {
    return state.egresoagro_update
  },
  Vget_egresoagro_data: (state) => {
    return state.egresoagro_data
  },
  Vget_egresoagro_data_byId: (state) => {
    return state.egresoagro_data_byId
  },
  Vget_egresoagro_load: (state) => {
    return state.egresoagro_load
  },
  Vget_egresoagro_save_load: (state) => {
    return state.egresoagro_save_load
  },
}
