<template>
  <div>
    <v-dialog width="700" persistent :value="value">
      <v-card>
        <v-toolbar flat dense>
          <v-toolbar-title>{{ title }}</v-toolbar-title>
        </v-toolbar>
        <v-container>
          <v-form ref="form" lazy-validation>
            <c-text-field
              label="Empresa"
              id="input1"
              dense
              outlined
              :readonly="readonly"
              v-model="data.descempresa"
              @keyup.native.enter="next(1, data.descempresa)"
            />

            <c-text-field
              label="Ruc"
              id="input2"
              dense
              outlined
              :readonly="readonly"
              v-model="data.rucempresa"
              @keyup.native.enter="next(2, data.rucempresa)"
            />
            <v-autocomplete
              dense
              v-model="data.idmoneda.idmoneda"
              id="input3"
              :rules="validars"
              :loading="Vget_moneda_load"
              v-bind:items="Vget_moneda_data_all"
              label="Moneda Predeterminada"
              item-text="descmoneda"
              item-value="idmoneda"
              autocomplete="off"
              required
              :readonly="readonly"
              outlined
              @keyup.enter="next(3, data.idmoneda.idmoneda)"
              :no-data-text="`No existe ninguna concidencia`"
            ></v-autocomplete>
            <v-autocomplete
              dense
              multiple
              v-model="data.moneda"
              id="input4"
              :rules="validars"
              :loading="Vget_moneda_load"
              v-bind:items="moneda"
              label="Seleccione Moneda"
              item-text="idmoneda.descmoneda"
              return-object
              autocomplete="off"
              required
              :readonly="readonly"
              outlined
              @keyup.enter="next(3)"
              :no-data-text="`No existe ninguna concidencia`"
            ></v-autocomplete>
          </v-form>
        </v-container>
        <v-card-actions>
          <c-btn-crud-close @click="close()"></c-btn-crud-close>
          <v-spacer></v-spacer>
          <c-btn-crud-done @click="guardar()" v-if="!readonly"></c-btn-crud-done>
        </v-card-actions>

        <c-overlay :value="Vget_empresa_load" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { focus } from "@/services/focus.service";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
    },
  },
  data() {
    return {
      validars: [(v) => !!v || "Seleccione un campo"],
      data: {
        idempresa: 0,
        idmoneda: {
          idmoneda: "",
        },
        moneda: [],
      },
      default: {
        idempresa: 0,
        idmoneda: {
          idmoneda: "",
        },
        moneda: [],
      },
    };
  },
  created() {
    if (this.editable || this.readonly)
      this.data = JSON.parse(JSON.stringify(this.props));
    this.Vact_moneda_data_all();
    setTimeout(() => this.next(0, "-"), 20);
  },
  watch: {
    $route: () => this.close(),
  },
  computed: {
    ...mapGetters("empresa", ["Vget_empresa_load"]),
    ...mapGetters("moneda", ["Vget_moneda_data_all", "Vget_moneda_load"]),
    title() {
      return this.readonly
        ? "Empresa"
        : this.editable
        ? "Modificar Empresa"
        : "Registrar Empresa";
    },
    moneda() {
      var moneda = [];
      this.Vget_moneda_data_all.forEach((x) => {
        moneda.push({
          idmoneda: x,
        });
      });
      return moneda;
    },
  },
  methods: {
    ...mapActions("moneda", ["Vact_moneda_data_all"]),
    ...mapActions("empresa", [
      "Vact_empresa_data",
      "Vact_empresa_save",
      "Vact_empresa_update",
    ]),
    next(id, data) {
      focus.nextid(id, data);
    },

    async guardar() {
      if (!this.$refs.form.validate()) return null;
      const response = this.editable
        ? await this.Vact_empresa_update({
            data: this.data,
            id: this.data.idempresa,
          })
        : await this.Vact_empresa_save(this.data);
      if (response.success) {
        this.Vact_empresa_data();
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();
        this.next(0, "-");
        if (this.editable) this.close();
      }
    },
    close() {
      this.limpiar();
    },
    limpiar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$emit("input", false);
      this.$emit("datos", null);
      this.Vact_empresa_data();
    },
  },
};
</script>
