<template>
  <div>
    <v-dialog :value="value" persistent max-width="700px">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>{{title}}</v-toolbar-title>
        </v-toolbar>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md text-xs-center>
            <v-row dense>
              <v-col cols="12" md="4">
                <vue-text-field
                  label="Titulo"
                  :readonly="readonly"
                  id="agendaInput1"
                  v-model="data.title_agenda"
                  @keyup.native.enter="next(1,data.title_agenda)"
                />
              </v-col>
              <v-col cols="12" md="4">
                <c-text-date
                 dense
                  outlined
                  label="Fecha Inicio"
                  :readonly="readonly"
                  id="agendaInput2"
                  v-model="data.start_agenda"
                  @keyup.native.enter="next(2,data.start_agenda)"
                />
              </v-col>
              <v-col cols="12" md="4">
                <vue-text-field
                  label="Hora Inicio"
                  id="agendaInput3"
                  :readonly="readonly"
                  :rules="false"
                  v-mask="maskh"
                  v-model="data.start_hour"
                  @keyup.native.enter="next(3,data.start_hour)"
                />
              </v-col>
              <v-col cols="12" md="4">
                <c-text-date
                  label="Fecha Fin"
                  dense
                  outlined
                  id="agendaInput4"
                  :readonly="readonly"
                  :rules="[]"
                  v-model="data.end_agenda"
                  @keyup.native.enter="next(4,data.end_agenda)"
                />
              </v-col>
              <v-col cols="12" md="4">
                <vue-text-field
                  label="Hora Fin"
                  id="agendaInput5"
                  :readonly="readonly"
                  :rules="false"
                  v-mask="maskh"
                  v-model="data.end_hour"
                  @keyup.native.enter="next(5,data.end_hour)"
                />
              </v-col>
              <v-col cols="12" md="4">
                <vue-text-field
                  label="Detalle"
                  :readonly="readonly"
                  id="agendaInput6"
                  v-model="data.detail_agenda"
                  @keyup.native.enter="next(6,data.detail_agenda)"
                />
              </v-col>
              <v-col cols="12">
                <v-color-picker
                  v-model="data.color_agenda"
                  :hide-canvas="true"
                  :hide-inputs="true"
                  :disabled="readonly"
                  :hide-mode-switch="true"
                  :show-swatches="false"
                  class="mx-auto"
                ></v-color-picker>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="close">
            <v-icon id>clear</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            id="aceptar"
            color="blue darken-1"
            text
            @click="reanudar"
            v-if="!operacion && data.state_agenda === true"
          >Reanudar</v-btn>
          <v-btn
            id="aceptar"
            :disabled="readonly"
            color="blue darken-1"
            text
            @click="finalizar"
            v-if="!operacion && data.state_agenda === false"
          >Finalizar</v-btn>
          <v-btn id="aceptar" color="blue darken-1" :disabled="readonly" text @click="pre_guardar">
            <v-icon dark>done</v-icon>
          </v-btn>
          <c-overlay :value="overlay" />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { mask } from "vue-the-mask";
import {current_date } from "@/services/util/date.service";
import { focus } from "@/services/focus.service";
export default {
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    xid: {
      type: Number,
      default: null,
    },
    datos:{
      type: Object,
      default: null
    },
    readonly:{
      type: Boolean,
      default: false
    }
  },
  directives: {
    mask,
  },
  data() {
    return {
      title: "Agendar Evento",
      maskh: "##:##",
      data: {
        id_agenda: 0,
        title_agenda: "",
        detail_agenda: null,
        start_agenda: current_date(),
        end_agenda: current_date(),
        start_hour: null,
        end_hour: null,
        color_agenda: "#8E00FF",
        state_agenda: false,
      },
      default: {
        id_agenda: 0,
        title_agenda: "",
        detail_agenda: null,
        start_agenda: current_date(),
        end_agenda: current_date(),
        start_hour: null,
        end_hour: null,
        color_agenda: "#8E00FF",
        state_agenda: false,
      },
      url: "/apiagenda/",
      operacion: true,
      id: "",
    };
  },
  watch: {
    $route(to, from) {
      if (from.path === "/agenda") return this.close();
    },
  },

  computed: {
    ...mapGetters("agenda", ["Vget_agenda_load", "Vget_agenda_save_load"]),
    overlay() {
      if (this.Vget_agenda_load || this.Vget_agenda_save_load) return true;
      return false;
    },
  },
  async created() {
    if (this.xid != null && this.xid != -1) {
      const data = await this.Vact_agenda_data_by_id(this.xid);
      return this.nuevo(data);
    }
    this.nuevo(this.datos);
  },
  methods: {
    ...mapActions("agenda", [
      "Vact_agenda_data",
      "Vact_agenda_save",
      "Vact_agenda_update",
      "Vact_agenda_data_by_id",
    ]),
    next(index, data) {
      focus.nextidName("agendaInput", index, data);
    },

    close() {
      this.$emit("input", false);
      this.$emit("datos", null);
      this.data = JSON.parse(JSON.stringify(this.default));
      this.Vact_agenda_data();
      this.operacion = true;
    },
    finalizar() {
      this.data.state_agenda = false;
      this.data.estado = "Completada";
      this.pre_guardar();
    },
    reanudar() {
      this.data.state_agenda = true;
      this.data.estado = "Pendiente";
      this.pre_guardar();
    },
    campos_nulos() {
      if (this.data.detail_agenda === "") {
        this.data.detail_agenda = null;
      }
      if (this.data.end_agenda === "") {
        this.data.end_agenda = null;
      }
      if (this.data.start_hour === "") {
        this.data.start_hour = null;
      }
      if (this.data.end_hour === "") {
        this.data.end_hour = null;
      }
      if (this.data.color_agenda === "") {
        this.data.color_agenda = null;
      }
    },
    pre_guardar() {
      this.campos_nulos();
      if (this.data.comnagenda === "") {
        this.data.comnagenda = null;
      }
      if (this.operacion) {
        this.guardar_finalizar();
      } else {
        this.editar_finalizar();
      }
    },
    async editar_finalizar() {
      let a = await this.Vact_agenda_update({ id: this.id, data: this.data });
      if (!a.success) return null;
      this.close();
    },
    async guardar_finalizar() {
      let a = await this.Vact_agenda_save(this.data);
      if (!a.success) return null;
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$refs.form.resetValidation();
      setTimeout(() => document.getElementById("input1").focus(), 500);
      return true;
    },
    nuevo(val) {
      if (val === null) return null;

      this.title = "Modificar Evento";
      this.data = val;
      this.data.estado = null;
      this.operacion = false;
      this.id = this.data.id_agenda;
    },
  },
};
</script>