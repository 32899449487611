<template>
  <div>
    <v-dialog :value="value" max-width="700px" persistent>
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Detalles de Caja</v-toolbar-title>
        </v-toolbar>
        <v-container>
          <v-row dense>
            <v-col cols="12" sm="6">
              <vue-text-field readonly label="Codigo" v-model="data.idcaja" />
            </v-col>
            <v-col cols="12" sm="6">
              <vue-text-field readonly  label="Descripcion" v-model="data.descaja" />
            </v-col>
          </v-row>
      
            <v-row dense cols="10" v-for="(item, i) in data.cajadet" :key="i">
              <vue-text-field readonly  label="Moneda" v-model="data.cajadet[i].idmoneda.descmoneda" />
            </v-row>
        </v-container>

        <v-card-actions>
          <v-btn color="red" text @click="cancelar"><v-icon>clear</v-icon></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

export default {
  props:{
    value: {
      type: Boolean,
      default: false
    },
    mostrar: {
      type: [Object, Array],
      default: null
    }
  },
  data() {
    return {
      dateFormatted: "",
      data: {
        idcaja: 0,
        descaja: "",
        idmoneda: {
          idmoneda: 0
        },
        idpuntoexpedicion: {
          idpuntoexpedicion: 0
        }
      },
      default: {
        idcaja: 0,
        descaja: "",
        idmoneda: {
          idmoneda: 0
        },
        idpuntoexpedicion: {
          idpuntoexpedicion: 0
        }
      }
    };
  },

  created() {
    this.show(this.mostrar);
    console.log(this.mostrar)
  },
  watch: {
    $route(to, from) {
      if (from.path === "/caja") return this.cancelar();
    }
  },
  methods: {
    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$emit('input', false);
      this.$emit('mostrar', null);
    },

    show(val) {
      this.data = val;
    }
  }
};
</script>
