export default {
  transladocaja_create(state, request) {
    state.transladocaja_create = request;
  }, 
  transladocaja_update(state, request) {
    state.transladocaja_update = request;
  },
  transladocaja_reload(state, request) {
    state.transladocaja_reload = request;
  }, 
  transladocaja_show(state, request) {
    state.transladocaja_show = request;
  }, 
  transladocaja_key(state, request) {
    state.transladocaja_key = request;
  }, 

  transladocaja_data(state, request) {
    state.transladocaja_data = request;
  }, 
  transladocaja_load(state, request) {
    state.transladocaja_load = request;
  }, 
  transladocaja_save_load(state, request) {
    state.transladocaja_save_load= request;
  }, 
}