var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-progress-linear',{attrs:{"color":"blue","height":"2","rounded":"","value":"100"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers_gasto,"items":_vm.items,"show-expand":"","item-key":"id","dense":"","item-class":"caption","items-per-page":10000,"expanded":_vm.expanded},on:{"click:row":_vm.clickRow2},scopedSlots:_vm._u([{key:"item.descripcion",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-black"},[_vm._v(_vm._s(item.descripcion))])]}},{key:"item.hectarea",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(_vm._s(_vm.currencyFormat(item.hectarea)))])]}},{key:"item.plantio",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(_vm._s(_vm.currencyFormat(item.plantio)))])]}},{key:"item.fumigacion",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(_vm._s(_vm.currencyFormat(item.fumigacion)))])]}},{key:"item.abono_aplicacion",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(_vm._s(_vm.currencyFormat(item.abono_aplicacion)))])]}},{key:"item.servicio",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(_vm._s(_vm.currencyFormat(item.servicio)))])]}},{key:"item.pago",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(_vm._s(_vm.currencyFormat(item.pago)))])]}},{key:"item.alquiler",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(_vm._s(_vm.currencyFormat(item.alquiler)))])]}},{key:"item.egresohectarea",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(_vm._s(_vm.currencyFormat(item.egresohectarea)))])]}},{key:"item.totalgasto",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-black text-end"},[_vm._v(" "+_vm._s(_vm.currencyFormat(item.totalgasto))+" ")])]}},{key:"item.diferencia",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row justify-end"},[_c('div',{class:("font-weight-black mr-1 " + (item.diferencia < 0 ? 'red--text' : 'green--text'))},[_vm._v(" "+_vm._s(_vm.currencyFormat(item.diferencia))+" ")]),_c('v-icon',{attrs:{"color":item.diferencia < 0 ? 'red' : 'green',"small":""}},[_vm._v(" "+_vm._s(item.diferencia > 0 ? "north_east" : "south_east")+" ")])],1)]}},{key:"item.ingresohectarea",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(_vm._s(_vm.currencyFormat(item.ingresohectarea)))])]}},{key:"item.kghectareahu",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(_vm._s(_vm.currencyFormat(item.kghectareahu)))])]}},{key:"item.kghectareaen",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(_vm._s(_vm.currencyFormat(item.kghectareaen)))])]}},{key:"item.kghectarea",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(_vm._s(_vm.currencyFormat(item.kghectarea)))])]}},{key:"item.produccion",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(_vm._s(_vm.currencyFormat(item.produccion)))])]}},{key:"item.produccionhu",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(_vm._s(_vm.currencyFormat(item.produccionhu)))])]}},{key:"item.produccionen",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(_vm._s(_vm.currencyFormat(item.produccionen)))])]}},{key:"expanded-item",fn:function(ref){
          var item = ref.item;
return [_c('td',{attrs:{"colspan":_vm.headers_gasto.length}},[_c('v-card',{attrs:{"flat":""}},[_c('v-row',{attrs:{"dense":"","row":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h4',{staticClass:"text-center"},[_vm._v(" Resumen de Cosecha - Campo: "+_vm._s(item.descripcion)+" ")]),_c('v-progress-linear',{attrs:{"color":"orange darken-1","rounded":"","height":"2","value":"100"}}),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers_ingreso,"items":item.det,"hide-default-footer":"","dense":"","items-per-page":10000},scopedSlots:_vm._u([{key:"item.descripcion",fn:function(ref){
          var item = ref.item;
return [_c('p',{staticClass:"font-weight-black"},[_vm._v(" "+_vm._s(item.descripcion)+" ")])]}},{key:"item.hectarea",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(" "+_vm._s(_vm.currencyFormat(item.hectarea))+" ")])]}},{key:"item.kghectarea",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(" "+_vm._s(_vm.currencyFormat(item.kghectarea))+" ")])]}},{key:"item.precio",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(" "+_vm._s(_vm.currencyFormat(item.precio))+" ")])]}},{key:"item.preciohectarea",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(" "+_vm._s(_vm.currencyFormat(item.preciohectarea))+" ")])]}},{key:"item.valor_alquiler",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(" "+_vm._s(_vm.currencyFormat(item.valor_alquiler))+" ")])]}},{key:"item.valor",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(" "+_vm._s(_vm.currencyFormat(item.valor))+" ")])]}},{key:"item.valor_vendido",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(" "+_vm._s(_vm.currencyFormat(item.valor_vendido))+" ")])]}},{key:"item.total",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"font-weight-black text-end"},[_vm._v(" "+_vm._s(_vm.currencyFormat(item.total))+" ")])]}}],null,true)}),_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12"}},[_c('h4',{staticClass:"text-center"},[_vm._v(" Lista de Aplicaciones - Lote: "+_vm._s(item.descripcion)+" ")]),_c('v-progress-linear',{attrs:{"color":"light-green accent-3","rounded":"","height":"2","value":"100"}}),_c('v-data-table',{attrs:{"headers":_vm.headers_lista_apli,"items":item.datos,"dense":"","hide-default-footer":"","items-per-page":10000,"expanded":_vm.expanded2,"show-expand":""},on:{"click:row":_vm.clickRow},scopedSlots:_vm._u([{key:"item.descripcion",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.descripcion)+" ")]}},{key:"item.subtotal",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"font-weight-black text-end"},[_vm._v(" "+_vm._s(_vm.currencyFormat(item.subtotal))+" ")])]}},{key:"item.data-table-expand",fn:function(ref){
          var group = ref.group;
          var isExpanded = ref.isExpanded;
return [_c('v-btn',{ref:group,attrs:{"small":"","icon":""}},[(isExpanded)?_c('v-icon',[_vm._v("expand_less")]):_c('v-icon',[_vm._v("expand_more")])],1)]}},{key:"expanded-item",fn:function(ref){
          var item = ref.item;
return [_c('td',{attrs:{"colspan":_vm.headers_gasto.length}},[_c('v-card',{attrs:{"flat":""}},[_c('v-row',{attrs:{"dense":"","row":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers_lista_apli_det,"items":item.detalle,"dense":"","hide-default-footer":"","items-per-page":10000,"expanded":_vm.expanded3,"show-expand":""},on:{"click:row":_vm.clickRow3},scopedSlots:_vm._u([{key:"item.cantidad",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(" "+_vm._s(_vm.currencyFormat(item.cantidad))+" ")])]}},{key:"item.hectarea",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(" "+_vm._s(_vm.currencyFormat(item.hectarea))+" ")])]}},{key:"item.cantotal",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(" "+_vm._s(_vm.currencyFormat(item.cantotal))+" ")])]}},{key:"item.precio",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"text-end"},[_vm._v(" "+_vm._s(_vm.currencyFormat(item.precio))+" ")])]}},{key:"expanded-item",fn:function(ref){
          var headers = ref.headers;
          var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v(" Nombre comercial: "+_vm._s(item.descitem)+" ")])]}}],null,true)})],1)],1)],1)],1)]}}],null,true)})],1)],1)],1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }