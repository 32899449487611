<template>
  <div>
    <v-card>
      <v-alert type="error" v-if="permission_alert">Error!!! Este usuario no tiene permisos en esta ventana</v-alert>
      <v-toolbar flat dense>
        <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
        <widgets-Favorite :ruta="$route.path" :formulario="$route.name" />
        <v-divider class="mx-2" inset vertical></v-divider>

        <v-spacer></v-spacer>
        <c-text-table-search v-model="search" v-if="permission.can_view"></c-text-table-search>


      </v-toolbar>
      <v-toolbar flat dense>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-tabs v-model="tab" right>
            <v-tab href="#tab-1" @click="fetchPartoNuevo()">Parto</v-tab>
            <v-tab href="#tab-2" @click="fetchPartoHisto()">Historico</v-tab>
          </v-tabs>
          <v-divider class="mx-2" inset vertical></v-divider>
          <c-btn-table-add class="mr-1" @click="add()" v-if="permission.can_add"></c-btn-table-add>
          <c-btn-table-reload @click="tab === 'tab-1' ? fetchPartoNuevo() : fetchPartoHisto()"></c-btn-table-reload>
        </v-toolbar-items>
      </v-toolbar>
      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1">
          <v-data-table :loading="isLoading" :headers="headers" :items="datos_new" :search="search" class="elevation-1"
            v-if="permission.can_view">
            <template v-slot:[`item.accion`]="props">
              <c-btn-table-view @click="rowselect(props.item)"></c-btn-table-view>
              <c-btn-table-edit @click="mupdate(props.item)" v-if="permission.can_update"></c-btn-table-edit>
              <c-btn-table-delete @click="meliminar(props.item)" v-if="permission.can_delete"></c-btn-table-delete>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item value="tab-2">
          <v-data-table :loading="isLoading" :headers="headers" :items="datos_old" :search="search" class="elevation-1"
            v-if="permission.can_view">
            <template v-slot:[`item.accion`]="props">
              <c-btn-table-view @click="rowselect(props.item)"></c-btn-table-view>
              <c-btn-table-edit @click="mupdate(props.item)" v-if="permission.can_update"></c-btn-table-edit>
              <c-btn-table-delete @click="meliminar(props.item)" v-if="permission.can_delete"></c-btn-table-delete>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
      <Delete v-if="crud.delete" v-model="crud.delete" :props="crud.datos" @datos="crud.datos = $event" />
      <Crud v-if="crud.add" v-model="crud.add" :props="crud.datos" :readonly="crud.readonly"
        @reset="crud.datos = $event" />
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { PARTO } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";
import Delete from "./Delete";
import Crud from "./Create";
export default {
  components: {
    Crud,
    Delete,
  },
  data() {
    return {
      tab: null,
      search: "",
      crud: {
        show: false,
        add: false,
        readonly: false,
        delete: false,
        datos: null,
      },
      headers: [
        { text: "Fecha", align: "left", value: "fecpparto" },
        { text: "Madre Nomb", align: "left", value: "idanimal.nombanimal" },
        { text: "Madre Rp", align: "left", value: "idanimal.rpanimal" },
        { text: "Tipo Hijo/a", align: "left", value: "mellizo" },
        { text: "Hijo/a Rp", align: "left", value: "hijo" },
        { text: "Accion", align: "right", value: "accion", sortable: false },
      ],
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
    };
  },

  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.fetchPartoNuevo();
    const permission = getPermission(PARTO).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  computed: {
    ...mapGetters("parto", ["getNuevo", "getHisto", "isLoading"]),
    permission_alert() {
      return mensaje.permission(this.permission);
    },
    datos_new() {
      const result = [];
      this.getNuevo.map((x) => {
        if (x.parto[1] != undefined) {
          result.push({
            ...x,
            ...{ mellizo: "Mellizo/a" },
            ...{
              hijo: `${x.parto[0].idanimal.rpanimal} y ${x.parto[1].idanimal.rpanimal}`,
            },
          });
        } else {
          result.push({
            ...x,
            ...{ mellizo: "Unico/a" },
            ...{ hijo: x.parto[0].idanimal.rpanimal },
          });
        }
      });
      return result;
    },
    datos_old() {
      const result = [];
      this.getHisto.map((x) => {
        if (x.parto[1] != undefined) {
          result.push({
            ...x,
            ...{ mellizo: "Mellizo/a" },
            ...{
              hijo: `${x.parto[0].idanimal.rpanimal} y ${x.parto[1].idanimal.rpanimal}`,
            },
          });
        } else {
          result.push({
            ...x,
            ...{ mellizo: "Unico/a" },
            ...{ hijo: x.parto[0].idanimal.rpanimal },
          });
        }
      });
      return result;
    },
  },
  methods: {
    ...mapActions("parto", ["fetchPartoNuevo", "fetchPartoHisto"]),
    meliminar(item) {
      this.crud.datos = JSON.parse(JSON.stringify(item));
      this.crud.delete = true;
    },
    add() {
      this.crud.add = true;
      this.crud.readonly = false;
    },
    rowselect(val) {
      this.crud.datos = JSON.parse(JSON.stringify(val));
      this.crud.add = true;
      this.crud.readonly = true;
    },
    mupdate(val) {
      this.crud.datos = JSON.parse(JSON.stringify(val));
      this.crud.readonly = false;
      this.crud.add = true;
    },
  },
};
</script>
