export const SET_PLAGA_ZONA = "setListPlaga";
export const SET_PLAGAS = "setPlaga";
export const SET_COORD = "setCoordenadas";
export const SET_TIPO = "fetchTipoPlaga";
export const LOADING = "loading";
export const SET_ID = "setPlagaId"


export const url = {
  plagas: "/apiplagas/",
  regplagas: "/apiregistro_plagas/",
  plagazona: '/apiplagas_zona/'
};
