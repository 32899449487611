const focus = {
  nextid2(index, data) {
    if (data !== null && data !== "") {
      try {
        index = index + 1;
        document.getElementById(`input` + index).focus();
      } catch (e) {
        document.getElementById("aceptar").focus();
      }
    } else {
      document.getElementById(`input` + index).focus();
    }
  },
  nextid(index, data) {
    if (data != null && data != "") {
      try {
        index = index + 1;
        document.getElementById(`input` + index).focus();
      } catch (e) {
        document.getElementById("aceptar").focus();
      }
    } else {
      document.getElementById(`input` + index).focus();
    }
  },
  async nextidName(idname, index, data) {
    if (data != null && data != "") {
      await this.sleep(100);
      let elementId = "";
      try {
        elementId = `${idname}${index + 1}`;
        document.getElementById(elementId).focus();
      } catch (e) {
        elementId = `${idname}aceptar`;
        document.getElementById(elementId).focus();
      }
      return elementId;
    }
  },
  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  },
};

export { focus };
