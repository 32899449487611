<template>
  <div>
    <div v-if="dialogCrud">
      <Crud v-model="dialogCrud" />
    </div>
    <v-card>
      <v-alert type="error" v-if="permission_alert"
        >Error!!! Este usuario no tiene permisos en esta ventana</v-alert
      >

      <v-toolbar flat dense>
        <v-toolbar-title>Tipo Gimnasio</v-toolbar-title>
        <widgets-Favorite :ruta="$route.path" formulario="Tipo Gimnasio" />
        <v-divider class="mx-2" inset vertical></v-divider>

        <v-spacer></v-spacer>

        <c-text-table-search
          v-if="permission.can_view"
          v-model="search"
        ></c-text-table-search>
        <v-toolbar-items>
          <c-btn-table-add
            @click="dialogCrud = true"
            v-if="permission.can_add"
            class="mr-1"
          ></c-btn-table-add>
          <c-btn-table-reload @click="Vact_gymtipo_data()"></c-btn-table-reload>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table
        :loading="Vget_gymtipo_load"
        :headers="headers"
        :items="Vget_gymtipo_data"
        :search="search"
        class="elevation-1"
        v-if="permission.can_view"
      >
        <template v-slot:[`item.accion`]="{ item }" v-if="permission.can_view">
          <c-btn-table-edit @click="editar(item)"></c-btn-table-edit>
          <c-btn-table-delete @click="eliminar(item)"></c-btn-table-delete>

        </template>

      </v-data-table>
      <v-dialog v-model="dialogEliminar" persistent max-width="400">
        <v-card>
          <v-card-title>
            <v-icon left>info</v-icon>
            <span class="subtitle-1 font-weight-light"
              >Ventana de Confirmacion</span
            >
          </v-card-title>
          <v-form ref="form" @submit.prevent="eliminarGymtipo()">
            <v-card-subtitle>
              Desea eliminar el tipo {{ objeto.descripcion }}
            </v-card-subtitle>
          </v-form>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="red" text="text" @click="dialogEliminar = false">
              <v-icon>clear</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" text="text" @click="eliminarGymtipo()">
              <v-icon>done</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
        <c-overlay :value="overlay" absolute></c-overlay>
      </v-dialog>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { TIPO_GIMNASIO } from "@/constants/forms";
import Crud from './Create';
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";

export default {
  components:{
    Crud
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "Descripcion", align: "left", value: "descripcion" },
        { text: "Accion", value: "accion", sortable: false, align: "right" },
      ],
      dialogCrud: false,
      dialogEliminar: false,
      overlay: false,
      objeto: {
        descripcion: "",
      },
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
    };
  },
  watch: {},
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.Vact_gymtipo_data();
    const permission = getPermission(TIPO_GIMNASIO).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  computed: {
    ...mapGetters("gymtipo", ["Vget_gymtipo_data", "Vget_gymtipo_load"]),

    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },
  methods: {
    ...mapActions("gymtipo", [
      "Vact_gymtipo_data",
      "Vact_gymtipo_del",
      "gymtipo_update",
    ]),
    editar(item) {
      this.gymtipo_update(item);
      this.dialogCrud = true;
    },
    eliminar(item) {
      this.dialogEliminar = true;
      this.objeto = item;
    },
    eliminarCancelar() {
      this.objeto.descripcion = "";
      this.dialogEliminar = false;
    },
    async eliminarGymtipo() {
      this.overlay = true;
      const id = this.objeto.idgymtipo;
      const a = await this.Vact_gymtipo_del(id);
      if (a.estado == true) {
        this.$notify(a.info);
        this.eliminarCancelar();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.overlay = false;
    },
  },
};
</script>