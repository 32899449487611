import { db } from "@/services/db.service";
export default {

    async Vact_cuentadiario_data({commit}){
        commit('cuentadiario_load',true);
        try {
            const data = await db.get('/apicuentadiario/');
            commit('cuentadiario_data',data);
            commit('cuentadiario_load',false);
            return true
        } catch (e) {
            commit('cuentadiario_load',false);
            return false
        } 
    },
    async Vact_cuentadiario_save({commit},data){
        commit('cuentadiario_save_load',true);
        try {
            const resp = await db.save('/apicuentadiario/',data);
            commit('cuentadiario_save_load',false);
            return resp;
        } catch (e) {
            commit('cuentadiario_save_load',false);
            return e
        } 
    },
    async Vact_cuentadiario_del({dispatch},id){
        try {
            const data = await db.delete('/apicuentadiario/'+id);
            dispatch('Vact_cuentadiario_data');
            return data
        } catch (e) {
            return false
        } 
    },
    async Vact_cuentadiario_update({commit},{id, data}){
        commit('cuentadiario_save_load',true);
        try {
            const resp = await db.update('/apicuentadiario/'+id+'/',data);
            commit('cuentadiario_save_load',false);
            return resp;
        } catch (e) {
            commit('cuentadiario_save_load',false);
            return e
        } 
    },
}
