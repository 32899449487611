

export default {
  pagocuenta_load: false,
  pagocuenta_data: [],
  pagocuenta_data_id: [],
  pagocuenta_by_id: [],
  pagocuenta_num_rec_load: false,
  pagocuenta_num_rec: ''
}
