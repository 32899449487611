
export default {
  getSenave: (state) => {
    return state.senave
  },
  getSenaveuno: (state) => {
    return state.senaveuno
  },
  getSenavefecha: (state) => {
    return state.senavefecha
  },
  getReporteindice: (state) => {
    return state.reporteindice
  },
  isSenaveLoading: (state) => {
    return state.isLoading
  }
}
