export default {
  timbrado_data(state, request) {
    state.timbrado_data = request;
  },
  timbrado_load(state, request) {
    state.timbrado_load = request;
  },
  timbrado_save_load(state, request) {
    state.timbrado_save_load = request;
  }
}