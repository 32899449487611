<template>
  <div>
    <v-card>
      <v-alert type="error" v-if="permission_alert">
        Error!!! Este usuario no tiene permisos en esta ventana
      </v-alert>
      <v-toolbar flat dense>
        <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
        <widgets-Favorite :ruta="$route.path" :formulario="$route.name" />
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="search" label="Busqueda" single-line hide-details
          v-if="permission.can_view" class="mr-1"></v-text-field>
        <v-toolbar-items>
          <c-btn-table-add @click="crud.add = true" v-if="permission.can_add" class="mr-1"></c-btn-table-add>
          <c-btn-table-reload @click="fetchRechazo()"></c-btn-table-reload>
        </v-toolbar-items>
      </v-toolbar>
      <v-data-table :loading="isLoading" :headers="headers" :items="getRechazo" :search="search"
        v-if="permission.can_view">
        <template v-slot:[`item.accion`]="props">
          <c-btn-table-edit @click="mupdate(props.item)" v-if="permission.can_update"></c-btn-table-edit>
          <c-btn-table-delete @click="meliminar(props.item)" v-if="permission.can_delete"></c-btn-table-delete>
        </template>
      </v-data-table>
    </v-card>
    <Crud v-model="crud.add" v-if="crud.add" :props="crud.datos" @reset="crud.datos = $event" />
    <Delete v-model="crud.delete" v-if="crud.delete" :props="crud.datos" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { RECHAZO } from "@/constants/forms";
import Delete from "./Delete";
import Crud from "./Create";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";

export default {
  components: {
    Delete,
    Crud
  },
  data: () => ({
    search: "",
    headers: [
      { text: "Fecha", align: "left", value: "fecrrechazo" },
      { text: "Animal", align: "left", value: "idanimal.nombanimal" },
      { text: "Rp", align: "left", value: "idanimal.rpanimal" },
      { text: "Raza", align: "left", value: "idanimal.idraza.descraza" },
      { text: "Lote", align: "left", value: "idanimal.idlote.desclote" },
      {
        text: "Causa Rechazo",
        align: "left",
        value: "idcausarechazo.desccausarechazo",
      },
      { text: "Comentario", align: "left", value: "comnrechazo" },
      { text: "Accion", value: "accion", sortable: false, align: "right" },
    ],
    crud: {
      add: false,
      delete: false,
      datos: null,
    },
    permission: {
      can_add: false,
      can_view: false,
      can_update: false,
      can_delete: false,
    },
  }),

  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.fetchRechazo();
    const permission = getPermission(RECHAZO).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  computed: {
    ...mapGetters("rechazo", ["getRechazo", "isLoading"]),
    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },

  methods: {
    ...mapActions("rechazo", ["fetchRechazo"]),

    meliminar(item) {
      this.crud.datos = JSON.parse(JSON.stringify(item));
      this.crud.delete = true;
    },
    mupdate(val) {
      this.crud.datos = JSON.parse(JSON.stringify(val));
      this.crud.add = true;
    },
  },
};
</script>
