<template>
  <div>
    <v-form ref="form" lazy-validation>
      <v-row row dense>
        <v-col cols="12" v-if="!operacion">
          <v-switch
            v-model="data.curado"
            :label="`Curado?: ${tipo}`"
          ></v-switch>
        </v-col>
        <v-col cols="12" v-if="modo">
          <AutocompleteAnimal
            id="input1"
            v-model="data.idanimal.idanimal"
            @keyup.native.enter="next(1, data.idanimal.idanimal)"
            no-create
          />
        </v-col>
        <v-col cols="12">
          <AutocompleteTipoEnfermedad
            id="input2"
            v-model="data.idtipoenfermedad.idtipoenfermedad"
            @nextfocus="next(2,'-')"
            @keyup.native.enter="
              next(2, data.idtipoenfermedad.idtipoenfermedad)
            "
          />
        </v-col>
        <v-col cols="12">
          <c-text-date
            label="Fecha de registro"
            id="input3"
            v-model="data.fecrenfermedad"
            dense
            outlined
            @keyup.native.enter="next(3, data.fecrenfermedad)"
          />
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { current_date } from "@/services/util/date.service";
import { booleanConverter } from "@/services/util/boolean.service";
import { focus } from "@/services/focus.service";
export default {
  components: {
    AutocompleteAnimal: () =>
      import("@/views/Animal/Animal/AutocompleteAnimal"),
    AutocompleteTipoEnfermedad: () =>
      import("@/views/Enfermedad/Tipoenfermedad/Autocomplete"),
  },
  props: {
    props: Object,
    idanimal: {
      type: Number,
      default: null,
    },
    modo: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      data: {
        idenfermedad: 0,
        fecrenfermedad: current_date(),
        fechenfermedad: "2010-10-10",
        idanimal: {
          idanimal: 0,
        },
        idtipoenfermedad: {
          idtipoenfermedad: 0,
        },
        curado: true,
      },
      default: {
        idenfermedad: 0,
        fecrenfermedad: current_date(),
        fechenfermedad: "2010-10-10",
        idanimal: {
          idanimal: 0,
        },
        idtipoenfermedad: {
          idtipoenfermedad: 0,
        },
        curado: true,
      },
      operacion: true,
    };
  },
  computed: {
    tipo() {
      return booleanConverter.si_no(this.data.curado);
    },
  },
  created() {
    if (!this.props) return null;
    this.data = JSON.parse(JSON.stringify(this.props));
    this.operacion = false;
  },
  mounted() {
    if (this.idanimal != null) {
      this.data.idanimal.idanimal = this.idanimal;
      return this.next(1, "-");
    }
    if (this.modo) {
      setTimeout(() => document.getElementById("input1").focus(), 500);
    } else {
      setTimeout(() => document.getElementById("input2").focus(), 500);
    }
  },
  methods: {
    next(id, data) {
      focus.nextid(id, data);
    },

    validate() {
      return this.$refs.form.validate();
    },
    obtenerDatos() {
      return this.data;
    },
  },
};
</script>
