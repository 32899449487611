var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-md-flex flex-row justify-space-between"},[_c('div',{staticClass:"title hidden-sm-and-down"},[_vm._v("Finanza")]),_c('v-sheet',{staticClass:"d-flex justify-end mb-2"},[_c('v-slide-group',{attrs:{"multiple":"","show-arrows":""}},[_c('v-slide-item',[_c('v-btn',{attrs:{"active-class":"deep-purple--text accent-4","exact":"","text":"","tile":"","to":"/finanzas/mifinanza"}},[_vm._v("Mi Finanza")])],1),_c('v-slide-item',[_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"align-self-center",attrs:{"text":"","tile":""}},'v-btn',attrs,false),on),[_vm._v(" Mantenimientos "),_c('v-icon',{attrs:{"right":""}},[_vm._v("arrow_drop_down")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.menu.mantenimiento),function(sub,subkey){return _c('v-list-item',{key:subkey,attrs:{"to":sub.route,"active-class":"deep-purple--text accent-4"}},[_vm._v(_vm._s(sub.title))])}),1)],1)],1),_c('v-slide-item',[_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"align-self-center",attrs:{"text":"","tile":""}},'v-btn',attrs,false),on),[_vm._v(" Transaccion "),_c('v-icon',{attrs:{"right":""}},[_vm._v("arrow_drop_down")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.menu.transacciones),function(sub1,subkey1){return _c('v-list-item',{key:subkey1,attrs:{"to":sub1.route,"active-class":"deep-purple--text accent-4"}},[_vm._v(_vm._s(sub1.title))])}),1)],1)],1),_c('v-slide-item',[_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"align-self-center",attrs:{"text":"","tile":""}},'v-btn',attrs,false),on),[_vm._v(" Finanzas Reportes "),_c('v-icon',{attrs:{"right":""}},[_vm._v("arrow_drop_down")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.menu.reportes),function(sub1,subkey1){return _c('v-list-item',{key:subkey1,attrs:{"to":sub1.route,"active-class":"deep-purple--text accent-4"}},[_vm._v(_vm._s(sub1.title))])}),1)],1)],1)],1)],1)],1),_c('v-divider'),_c('transition',[_c('keep-alive',[_c('router-view')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }