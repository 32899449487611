
export default {
  Vget_division_data: (state) => {
    return state.division_data
  },
  Vget_division_load: (state) => {
    return state.division_load
  },
  Vget_division_save_load: (state) => {
    return state.division_save_load
  },
}
