export default {
  caja_data(state, request) {
    state.caja_data = request;
  }, 
  caja_cajacuenta(state, request) {
    state.caja_cajacuenta = request;
  }, 
  caja_load(state, request) {
    state.caja_load = request;
  }, 
  caja_save_load(state, request) {
    state.caja_save_load = request;
  }, 
}