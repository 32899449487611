export default {
  cheque_data(state, request) {
    state.cheque_data = request;
  }, 
  cheque_data_desembolso(state, request) {
    state.cheque_data_desembolso= request;
  }, 
  cheque_load(state, request) {
    state.cheque_load = request;
  }, 
  cheque_save_load(state, request) {
    state.cheque_save_load = request;
  }, 
}