<template>
  <div v-if="value">
    <v-card-text>
      <v-form ref="formFinan">
        <v-row dense>
          <v-col>
            <v-switch
              v-model="datos.cuenta[0].financiar"
              :label="`Financiar: ${financiar}`"
            ></v-switch>
          </v-col>
          <v-col
            v-if="
              getPerfilFin.length != 0 && datos.cuenta[0].financiar === true
            "
          >
            <AutocompletePerfil
              v-model="perfil_det"
              label="Perfil de Financiamiento"
              @change="asimilar_perfil(), (datos.cuenta[0].cuotaventa = [])"
            />
          </v-col>
        </v-row>
        <v-row dense v-if="datos.cuenta[0].financiar === true">
          <v-col cols="12" md="3" sm="3">
            <c-text-field
              dense
              outlined
              label="Cantidad de Cuotas"
              v-model="datos.cuenta[0].cantcuota"
              @keyup.native="datos.cuenta[0].cuotaventa = []"
            />
          </v-col>
          <v-col cols="12" md="3" sm="3">
            <c-text-currency
              label="Entrega"
              :rules="datos.cuenta[0].entrega != '' ? validarCosto : []"
              outlined
              dense
              v-model="datos.cuenta[0].entrega"
              @keyup.native="datos.cuenta[0].cuotaventa = []"
            />
          </v-col>
          <v-col cols="12" md="3" sm="3">
            <c-text-field
              dense
              outlined
              label="Dia de Pago"
              v-model="datos.cuenta[0].diapago"
              @keyup.native="datos.cuenta[0].cuotaventa = []"
            />
          </v-col>
          <v-col cols="12" md="3" sm="3">
            <c-text-field
              dense
              outlined
              :readonly="getPerfilFin.length != 0"
              label="%  Interes"
              v-model="datos.cuenta[0].porcinteres"
            />
          </v-col>
          <v-col cols="12" md="3" sm="3">
            <c-text-field
              dense
              outlined
              :readonly="getPerfilFin.length != 0"
              label="% Interes por Mora"
              v-model="datos.cuenta[0].porcinteresmora"
            />
          </v-col>
          <v-col cols="12" md="3" sm="3">
            <input-autocomplete
              :readonly="getPerfilFin.length != 0"
              label="Periodo"
              api="/apiperiodo/"
              pk="idperiodo_interes"
              title="descripcion"
              :noactive="false"
              :chips="getPerfilFin.length === 0"
              v-model="datos.cuenta[0].idperiodo_interes.idperiodo_interes"
            />
          </v-col>
          <v-col cols="12" md="3" sm="3">
            <input-autocomplete
              label="Sistema"
              :readonly="getPerfilFin.length != 0"
              api="/apisistema/"
              pk="idsistema_interes"
              title="descripcion"
              :noactive="false"
              :chips="getPerfilFin.length === 0"
              v-model="datos.cuenta[0].idsistema_interes.idsistema_interes"
            />
          </v-col>
          <v-col
            cols="12"
            md="3"
            sm="3"
            v-if="perfil_det != undefined || getPerfilFin.length === 0"
          >
            <v-btn color="error" block @click="calcular_financiamiento()"
              >GENERAR CUOTA</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
      <v-row dense>
        <v-col cols="12">
          <Forma-Pago-Venta
            v-if="datos.cuenta[0].entrega > 0"
            :montoCobrar="datos.cuenta[0].entrega"
            :key="keyFormaPago"
            @efectivo="addEfectivo($event)"
            @tarjeta="addTarjeta($event)"
            @cheque="addCheque($event)"
          />
        </v-col>
      </v-row>
      <v-data-table
        v-if="datos.cuenta[0].cuotaventa.length != 0"
        :headers="headers_cuota"
        :items="datos.cuenta[0].cuotaventa"
        hide-default-footer
        :items-per-page="99999"
      >
        <template v-slot:[`item.montocuotacuenta`]="{ item }">
          <div class="font-weight-black">
            {{ currencyFormat(item.montocuotacuenta) }}
          </div>
        </template>
      </v-data-table>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="red" text @click="cancelar_credito">
        <v-icon dark>arrow_back</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <slot
        name="action"
        v-if="
          datos.cuenta[0].cuotaventa.length != 0 || getPerfilFin.length === 0
        "
      ></slot>
    </v-card-actions>
    <v-overlay :value="isPerfilFinLoadig">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { booleanConverter } from "@/services/util/boolean.service";
import { sum_days, current_day } from "@/services/util/date.service";
import { mapGetters, mapActions } from "vuex";
import { currency } from "@/services/util/number.service";
import formaPagoVenta from "@/views/Venta/CobroCuenta/FormaPago";
import AutocompletePerfil from "@/views/Venta/PerfilFinanciamiento/autocompletePerfil";
const [FACTURA, EDICION] = ["FACTURA", "EDICION"];
export default {
  components: {
    AutocompletePerfil,
    "Forma-Pago-Venta": formaPagoVenta,
  },
  props: {
    data: {
      type: Object,
    },
    value: {
      type: Boolean,
      default: false,
    },
    monto: {
      type: Number,
      default: 0,
    },
    timbrado: {
      type: [Number, String],
    },
    mode: {
      type: String,
      default: FACTURA,
    },
  },
  watch: {
    value(val) {
      if (this.mode === FACTURA) {
        if (val) {
          (this.datos = {
            ...JSON.parse(JSON.stringify(this.data)),
            cuenta: this.cuenta,
          }),
            (this.perfil_det = undefined);
          this.credito_treintadias();
        }
      }
      if (this.mode === EDICION) {
        this.datos = JSON.parse(JSON.stringify(this.data));
      }
    },
    financiar(val) {
      if (val === "No") return this.resetear_temp();
      //return (this.datos.cuenta[0].cuotaventa = []);
    },
  },
  computed: {
    ...mapGetters("perfilfin", ["getPerfilFin", "isPerfilFinLoadig"]),
    financiar() {
      return booleanConverter.si_no(this.datos.cuenta[0].financiar);
    },
  },
  mounted() {
    this.fetchPerfilFin();
  },
  methods: {
    ...mapActions("perfilfin", ["fetchFinanciamiento", "fetchPerfilFin"]),
    currencyFormat(value) {
      if (value != "") return currency(value);
      return "";
    },
    addEfectivo(event) {
      this.datos.ventapago[0].monto = event.monto;
      this.$emit("respuesta", this.datos);
    },
    addTarjeta(event) {
      this.datos.ventapago[0].venta_pago_tarjeta = event;
      this.$emit("respuesta", this.datos);
    },
    addCheque(event) {
      this.datos.ventapago[0].venta_pago_cheque = event;
      this.$emit("respuesta", this.datos);
    },
    cancelar_credito() {
      this.resetear_temp();
      this.$emit("input", false);
    },
    resetear_temp() {
      if (this.data_temp != null) {
        this.datos.venta = JSON.parse(JSON.stringify(this.data_temp));
        this.$emit("respuesta", this.datos);
      }
      this.data_temp = null;
      this.monto_temp = null;
      this.datos.cuenta[0].financiar = false;
      setTimeout(() => this.credito_treintadias(), 500);
    },
    async obtenerDatos(data) {
      const resp = await this.fetchFinanciamiento(data);
      if(resp.success)
      return resp;
      return false;
    },
    formatearDatos() {
      this.datos.cuenta[0].cuotaventa = [];
      if (
        this.datos.cuenta[0].entrega === "" ||
        this.datos.cuenta[0].entrega === 0
      ) {
        this.datos.cuenta[0].entrega = 0;
      }
      const data = {
        dia: Number(this.datos.cuenta[0].diapago),
        cantidad: Number(this.datos.cuenta[0].cantcuota),
        monto:
          this.monto_temp != null
            ? Number(this.monto_temp)
            : Number(this.monto),
        interes: Number(this.datos.cuenta[0].porcinteres),
        entrega: Number(this.datos.cuenta[0].entrega),
        idperiodo_interes: {
          idperiodo_interes: this.datos.cuenta[0].idperiodo_interes
            .idperiodo_interes,
        },
        idsistema_interes: {
          idsistema_interes: this.datos.cuenta[0].idsistema_interes
            .idsistema_interes,
        },
        detalle: this.data_temp != null ? this.data_temp : this.datos.venta,
      };
      if (this.data_temp === null) {
        this.monto_temp = this.monto;
        this.data_temp = JSON.parse(JSON.stringify(this.datos.venta));
      }
      return data;
    },
    async calcular_financiamiento() {
      if (!this.$refs.formFinan.validate()) return null;
      if (this.datos.cuenta[0].financiar) {
        const data = await this.formatearDatos();
        const resp = await this.obtenerDatos(data);
        if (resp != false) {
          resp.data[0].cuota.map((x) => {
            this.datos.cuenta[0].cuotaventa.push({
              idcuotaventa: 0,
              idtimbrado: this.timbrado,
              montocuotacuenta: x.montocuotacuenta,
              amortizacion: x.amortizacion,
              interes: x.interes,
              fechavence: x.fechavence,
              estadocobro: false,
              idcuentaventa: 0,
              idventa: 0,
            });
          });
          this.datos.venta = JSON.parse(JSON.stringify(resp.data[0].detalle));
        }
      } else {
        this.credito_treintadias();
      }
      this.$emit("respuesta", this.datos);
    },
    credito_treintadias() {
      this.datos.cuenta[0].cuotaventa = [
        {
          idcuotaventa: 1,
          idtimbrado: this.timbrado,
          montocuotacuenta: this.monto,
          fechavence: sum_days(30),
          estadocobro: false,
          idcuentaventa: 0,
          idventa: 0,
        },
      ];
      this.$emit("respuesta", this.datos);
    },
    asimilar_perfil() {
      if (this.perfil_det != undefined) {
        this.datos.cuenta[0].porcinteres = this.perfil_det.porcinteres;
        this.datos.cuenta[0].porcinteresmora = this.perfil_det.porinteresmora;
        this.datos.cuenta[0].idperiodo_interes.idperiodo_interes = this.perfil_det.idperiodo_interes.idperiodo_interes;
        this.datos.cuenta[0].idsistema_interes.idsistema_interes = this.perfil_det.idsistema_interes.idsistema_interes;
      }
    },
  },
  data() {
    return {
      validarCosto: [
        (v) => !!v || "Este campo es obligatorio",
        (v) => !(Number(v) <= 0) || "Este campo no puede ser menor o igual 0",
      ],
      perfil_det: undefined,
      keyFormaPago: 1,
      datos: {
        cuenta: [
          {
            financiar: false,
            cuotaventa: [],
          },
        ],
      },
      data_temp: null,
      monto_temp: null,
      headers_cuota: [
        { text: "Vencimiento", value: "fechavence", sortable: false },
        { text: "Interes", value: "interes", sortable: false },
        { text: "Amortizacion", value: "amortizacion", sortable: false },
        {
          text: "Total",
          value: "montocuotacuenta",
          align: "end",
          sortable: false,
        },
      ],
      cuenta: [
        {
          idcuentaventa: 0,
          entrega: 0,
          idventa: 0,
          idtimbrado: "",
          fecha: "2000-01-01",
          financiar: false,
          idcontacto: "",
          estadocobro: false,
          diapago: current_day(),
          anulcuentaventa: false,
          cantcuota: 1,
          porcinteres: 0,
          porcinteresmora: 0,
          idperiodo_interes: {
            idperiodo_interes: "",
          },
          idsistema_interes: {
            idsistema_interes: "",
          },
          cuotaventa: [],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
