export default {
  categoria_create: false,
  categoria_update: null,
  categoria_reload: false,
  categoria_show: false,
  categoria_key: 0,

  categoria_data: [],
  categoria_load: false,
  categoria_save_load: false
}
