<template>
  <div>
    <v-list-item two-line>
      <v-list-item-content>
        <v-list-item-title class="headline"
          >Lote: {{ infoAgromap.campo }}</v-list-item-title
        >
        <v-list-item-subtitle
          >Zona:
          {{ infoAgromap.idlote_grupo.descripcion }}</v-list-item-subtitle
        >
        <v-list-item-subtitle
          >{{ infoAgromap.hectarea }} Hectareas</v-list-item-subtitle
        >
      </v-list-item-content>
   
      <v-tooltip bottom color="info" v-if="permission.can_update">
        <template v-slot:activator="{ on }">
          <v-list-item-icon @click="editItem()" v-on="on">
            <v-icon color="primary">edit</v-icon>
          </v-list-item-icon>
        </template>
        <v-icon small color="red" class="mr-2">help_outline</v-icon>
        <span class="text-center">Click para editar esta Lote</span>
      </v-tooltip>
      <v-tooltip bottom color="info" v-if="permission.can_delete">
        <template v-slot:activator="{ on }">
          <v-list-item-icon v-on="on" @click="deleteItem()">
            <v-icon color="red">delete</v-icon>
          </v-list-item-icon>
        </template>
        <v-icon small color="red" class="mr-2">help_outline</v-icon>
        <span class="text-center">Click para eliminar esta Lote</span>
      </v-tooltip>
    </v-list-item>
    <v-divider></v-divider>
    <v-list dense>
      <v-list-item-group>
        <v-list-item
          v-for="(item, i) in items"
          :key="i"
          @click="windows = item.window"
        >
          <v-list-item-icon>
            <v-icon> {{ item.icon }} </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title> {{ item.text }} </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-divider></v-divider>
    <v-progress-linear
      indeterminate
      height="2"
      v-if="Vget_agromap_load"
      color="primary"
    ></v-progress-linear>
    <v-window  :vertical="true" v-model="windows">
      <v-window-item :value="1">
        <Agro-Campo-Registro />
      </v-window-item>
      <v-window-item :value="2">
        <Agro-Campo-Mapa />
      </v-window-item>
      <v-window-item :value="4">
        <Agro-Campo-Pronostico v-if="windows === 4" v-model="windows" />
      </v-window-item>
      <v-window-item :value="5">
        <Agro-Campo-IndiceNdvi v-if="windows === 5" v-model="windows" />
      </v-window-item>
    </v-window>

    <v-dialog v-model="dialogDeleteLote" max-width="350">
      <v-card>
        <v-card-title>
          <v-icon left>info</v-icon>
          <span class="title font-weight-light">Ventana de Confirmacion</span>
        </v-card-title>
        <v-card-text>Desea Eliminar este Lote?</v-card-text>

        <v-card-actions>
          <v-btn color="red" text @click="dialogDeleteLote = false">
            <v-icon>clear</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="eliminarLote()">
            <v-icon>done</v-icon>
          </v-btn>
        </v-card-actions>
        <c-overlay v-model="overlay"></c-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mensaje } from "@/services/notify.service";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { ZONAS_CULTIVO } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";
export default {
  mounted() {
    this.init();
  },
  created() {
    const permission = getPermission(ZONAS_CULTIVO).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  watch: {
    $route(val) {
      if (val.name === "Agro Detalle") {
        this.init();
      }
    },
  },

  computed: {
    ...mapGetters("agromap", ["Vget_agromap_load", "Vget_agromap_coordinates"]),
    infoAgromap() {
      if (this.Vget_agromap_coordinates.length === 0)
        return { idlote_grupo: { descripcion: "" } };
      return this.Vget_agromap_coordinates;
    },
  },
  methods: {
    ...mapActions("campo", [
      "Vact_campo_data_by_id",
      "Vact_campo_clima",
      "Vact_campo_del",
    ]),
    ...mapActions("campana", ["Vact_campana_data_byLote", "Vact_campana_data"]),

    init() {
      this.Vact_campana_data_byLote(this.$route.params.id);
      this.Vact_campo_data_by_id(this.$route.params.id);
      this.Vact_campo_clima(this.$route.query.polygon);
      this.Vact_campana_data();
    },
    editItem() {
      this.$router.push({
        path: "/agricultura/campo/edit/" + this.$route.params.id,
        query: {
          polygon: this.$route.query.polygon,
        },
      });
    },
    deleteItem() {
      this.dialogDeleteLote = true;
    },

    async eliminarLote() {
      this.overlay = true;
      const a = await this.Vact_campo_del(this.$route.params.id);
      if (a.estado == true) {
        this.dialog = false;
        this.$notify(a.info);
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.overlay = false;
      this.dialogDeleteLote = false;
      this.$router.push("/agricultura/campo");
    },
  },
  data() {
    return {
      dialogDeleteLote: false,
      overlay: false,

      windows: 1,
      idcampana: {
        estado: false,
      },

      items: [
        {
          icon: "post_add",
          text: "Registro de Campaña",
          window: 1,
        },
        {
          icon: "public",
          text: "Mapa",
          window: 2,
        },
        {
          icon: "filter_drama",
          text: "Clima",
          window: 4,
        },
        {
          icon: "show_chart",
          text: "Indice NDVI",
          window: 5,
        },
      ],
      years: [
        {
          color: "cyan",
          year: "1960",
        },
      ],
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
    };
  },
};
</script>
