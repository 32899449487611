export default {
  bonificacionagro_data(state, request) {
    state.bonificacionagro_data = request;
  },
  bonificacionagro_load(state, request) {
    state.bonificacionagro_load = request;
  },
  ventaagro_update(state, request) {
    state.ventaagro_update = request;
  },
  ventaagro_data(state, request) {
    state.ventaagro_data = request;
  }, 
  ventaagro_data_byId(state, request) {
    state.ventaagro_data_byId = request;
  }, 
  ventaagro_load(state, request) {
    state.ventaagro_load = request;
  }, 
  ventaagro_save_load(state, request) {
    state.ventaagro_save_load = request;
  }, 
}