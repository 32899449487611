const mensaje = {
    completar() {
        return { group: "app", type: "error", title: 'Error:', text: "Verificar los datos ingresados!" }
    },
    error(data) {
        return { group: "app", type: "error", title: "Error", text: data }
    },
    permission(data) {
        var permission = false;
        Object.keys(data).forEach(key => {
            if (data[key]) permission = true
        })
        return !permission;
    }
};


export { mensaje }

