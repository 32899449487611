var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.permission_alert)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v("Error!!! Este usuario no tiene permisos en esta ventana")]):_vm._e(),_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$route.name))]),_c('widgets-Favorite',{attrs:{"ruta":_vm.$route.path,"formulario":_vm.$route.name}}),_c('v-divider',{staticClass:"mx-2",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),(_vm.permission.can_view)?_c('c-text-table-search',{model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e(),_c('v-toolbar-items',[(_vm.permission.can_add)?_c('c-btn-table-add',{staticClass:"mr-1",on:{"click":function($event){_vm.crud.add = true}}}):_vm._e(),_c('c-btn-table-reload',{on:{"click":function($event){return _vm.fetchSensor()}}})],1)],1),_c('v-tabs',{attrs:{"right":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("Mis Sensores")]),_c('v-tab',[_vm._v("Publicos")])],1),_c('v-divider'),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[(_vm.permission.can_view)?_c('v-data-table',{attrs:{"loading":_vm.isLoading,"headers":_vm.headers,"items":_vm.getSensorPrivate,"search":_vm.search},scopedSlots:_vm._u([{key:"item.descripcion",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.descripcion)+" "),_c('span',{staticClass:"caption font-weight-thin"},[_vm._v(_vm._s(item.other ? " (Publico)" : ""))])])]}},{key:"item.compartido",fn:function(ref){
var item = ref.item;
return [(item.other)?_c('v-chip',{attrs:{"small":"","dark":"","color":"red"}},[_vm._v("Origen Publico")]):_c('v-chip',{attrs:{"small":"","dark":"","color":item.compartido ? 'green' : 'light-blue darken-1'}},[_vm._v(_vm._s(item.compartido ? "Compartido" : "Privado"))])]}},{key:"item.visualizar",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","small":"","text":"","to":((_vm.$route.path) + "/" + (item.idambient_weather))}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("analytics")]),_vm._v(" visualizar datos ")],1),_c('v-btn',{attrs:{"small":"","fab":"","text":""},on:{"click":function($event){return _vm.showSensorMap(item.idambient_weather)}}},[_c('v-icon',[_vm._v("location_on")])],1)]}},{key:"item.favorite",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"fab":"","small":"","text":"","color":item.favorite ? 'yellow' : ''},on:{"click":function($event){return _vm.markFavorite(item)}}},[_c('v-icon',[_vm._v(_vm._s(item.favorite ? "star" : "star_border"))])],1)]}},(_vm.permission.can_view)?{key:"item.accion",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":item.other,"text":"","fab":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[(_vm.permission.can_update)?_c('v-list-item',{on:{"click":function($event){return _vm.editar(item)}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("edit")])],1),_c('v-list-item-title',[_vm._v("Modificar")])],1):_vm._e(),_c('v-divider'),(_vm.permission.can_delete)?_c('v-list-item',{on:{"click":function($event){return _vm.eliminar(item)}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("delete")])],1),_c('v-list-item-title',[_vm._v("Eliminar")])],1):_vm._e()],1)],1)]}}:null],null,true)}):_vm._e()],1),_c('v-tab-item',[(_vm.permission.can_view)?_c('v-data-table',{attrs:{"loading":_vm.isLoading,"headers":_vm.headers_public,"items":_vm.getSensorPublic,"search":_vm.search},scopedSlots:_vm._u([{key:"item.descripcion",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.descripcion)+" "),_c('span',{staticClass:"caption font-weight-thin"},[_vm._v(_vm._s(item.other ? " (Publico)" : ""))])])]}},{key:"item.compartido",fn:function(ref){
var item = ref.item;
return [(item.other)?_c('v-chip',{attrs:{"small":"","dark":"","color":"red"}},[_vm._v("Origen Publico")]):_c('v-chip',{attrs:{"small":"","dark":"","color":item.compartido ? 'green' : 'light-blue darken-1'}},[_vm._v(_vm._s(item.compartido ? "Compartido" : "Privado"))])]}},{key:"item.visualizar",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","small":"","text":"","to":((_vm.$route.path) + "/" + (item.idambient_weather))}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("analytics")]),_vm._v(" visualizar ")],1),_c('v-btn',{attrs:{"small":"","fab":"","text":""},on:{"click":function($event){return _vm.showSensorMap(item.idambient_weather)}}},[_c('v-icon',[_vm._v("location_on")])],1)]}},{key:"item.favorite",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"fab":"","small":"","text":"","color":item.favorite ? 'yellow' : ''},on:{"click":function($event){return _vm.markFavorite(item)}}},[_c('v-icon',[_vm._v(_vm._s(item.favorite ? "star" : "star_border"))])],1)]}}],null,true)}):_vm._e()],1)],1)],1),(_vm.crud.add)?_c('Crud',{attrs:{"props":_vm.crud.datos},on:{"reset":function($event){_vm.crud.datos = $event}},model:{value:(_vm.crud.add),callback:function ($$v) {_vm.$set(_vm.crud, "add", $$v)},expression:"crud.add"}}):_vm._e(),(_vm.crud.delete)?_c('Delete',{attrs:{"props":_vm.crud.datos},on:{"reset":function($event){_vm.crud.datos = $event}},model:{value:(_vm.crud.delete),callback:function ($$v) {_vm.$set(_vm.crud, "delete", $$v)},expression:"crud.delete"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }