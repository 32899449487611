<template>
  <div>
    <v-autocomplete
      :value="value"
      @input="$emit('input', $event)"
      ref="input"
      :items="getTipoFactura"
      :loading="isTipoFacturaLoading"
      item-value="idtipofactura"
      :rules="rules"
      return-object
      item-text="desctipofactura"
      :outlined="outlined"
      :dense="dense"
      :disabled="disabled"
      :chips="chips"
      :small-chips="smallChips"
      @change="$emit('change')"
      :label="label"
    >
    </v-autocomplete>

  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    value: [Object, Number, String],
    outlined: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    chips: {
      type: Boolean,
      default: false,
    },
    "small-chips": {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Tipo Factura",
    },
    rules: {
      type: Array,
      default: function () {
        return [(v) => !!v.idtipofactura || "Este campo es obligatorio"];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  created() {
     this.fetchTipoFactura();
  },
  computed: {
    ...mapGetters("tipofactura", [
      "getTipoFactura",
      "isTipoFacturaLoading",
    ]),
  },
  methods: {
    ...mapActions("tipofactura", [
      "fetchTipoFactura"
    ]),
    focus() {
      this.$refs.input.focus();
    },
    isMenuActive() {
      this.$refs.input.isMenuActive = false;
    },
  },
};
</script>

<style>
</style>