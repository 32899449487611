<template>
  <div>
    <v-dialog :value="value" max-width="700px" persistent :key="reload">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Detalles</v-toolbar-title>
        </v-toolbar>
        <v-container>
          <v-row row dense>
            <v-col cols="12">
              <vue-text-field
                v-model="data.descbanco"
                label="Descripcion"
                readonly
              ></vue-text-field>
            </v-col>
            <v-col cols="12">
              <vue-text-field
                v-model="data.idcontacto.idcontacto.desccontacto"
                label="Contacto"
                readonly
              ></vue-text-field>
            </v-col>
          </v-row>
        </v-container>

        <v-card-actions>
          <v-btn color="red" text @click="cancelar">
            <v-icon>clear</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    datos: {
      type: Object,
      dafault: null,
    },
  },
  data() {
    return {
      data: {
        descbanco: "",
      },
      default: {},
      dialogo: false,
      reload: 0,
    };
  },

  created() {
    this.show(this.datos);
  },
  watch: {
    $route(to, from) {
      if (from.path === "/banco") return this.cancelar();
    },
  },
  methods: {
    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$emit("input", false);
      this.$emit("datos", null);
    },

    show(val) {
      this.dialogo = true;
      this.data = JSON.parse(JSON.stringify(val));
    },
  },
};
</script>
