export default {
  transladocajabanco_data(state, request) {
    state.transladocajabanco_data = request;
  }, 
  transladocajabanco_load(state, request) {
    state.transladocajabanco_load = request;
  }, 
  transladocajabanco_save_load(state, request) {
    state.transladocajabanco_save_load = request;
  }, 
}