<template>
  <div>
    <v-alert type="error" v-if="permission_alert"
      >Error!!! Este usuario no tiene permisos en esta ventana</v-alert
    >
    <v-card>
      <v-toolbar flat dense>
        <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
        <widgets-Favorite :ruta="$route.path" :formulario="$route.name" />
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <c-text-table-search
          v-if="permission.can_view"
          v-model="search"
        ></c-text-table-search>
        <v-toolbar-items>
          <c-btn-table-add
            class="mr-1"
            @click="crud.add = true"
            v-if="permission.can_add"
          ></c-btn-table-add>
          <c-btn-table-reload @click="fetchSensor()"></c-btn-table-reload>
        </v-toolbar-items>
      </v-toolbar>
      <v-tabs right v-model="tab">
        <v-tab>Mis Sensores</v-tab>
        <v-tab>Publicos</v-tab>
      </v-tabs>
      <v-divider></v-divider>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-data-table
            :loading="isLoading"
            :headers="headers"
            :items="getSensorPrivate"
            :search="search"
            v-if="permission.can_view"
          >
            <template v-slot:[`item.descripcion`]="{ item }">
              <div>
                {{ item.descripcion }}
                <span class="caption font-weight-thin">{{
                  item.other ? " (Publico)" : ""
                }}</span>
              </div>
            </template>
            <template v-slot:[`item.compartido`]="{ item }">
              <v-chip small v-if="item.other" dark color="red"
                >Origen Publico</v-chip
              >
              <v-chip
                small
                v-else
                dark
                :color="item.compartido ? 'green' : 'light-blue darken-1'"
                >{{ item.compartido ? "Compartido" : "Privado" }}</v-chip
              >
            </template>
            <template v-slot:[`item.visualizar`]="{ item }">
              <v-btn
                color="primary"
                small
                text
                :to="`${$route.path}/${item.idambient_weather}`"
              >
                <v-icon class="mr-1">analytics</v-icon>
                visualizar datos
              </v-btn>
              <v-btn small fab text @click="showSensorMap(item.idambient_weather)"><v-icon>location_on</v-icon></v-btn>
            </template>
            <template v-slot:[`item.favorite`]="{ item }">
              <v-btn
                fab
                small
                text
                @click="markFavorite(item)"
                :color="item.favorite ? 'yellow' : ''"
                ><v-icon>{{
                  item.favorite ? "star" : "star_border"
                }}</v-icon></v-btn
              >
            </template>
            <template
              v-slot:[`item.accion`]="{ item }"
              v-if="permission.can_view"
            >
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    :disabled="item.other"
                    text
                    fab
                    small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>more_vert</v-icon>
                  </v-btn>
                </template>
                <v-list dense>
                  <v-list-item
                    @click="editar(item)"
                    v-if="permission.can_update"
                  >
                    <v-list-item-icon class="mr-2">
                      <v-icon small>edit</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Modificar</v-list-item-title>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item
                    @click="eliminar(item)"
                    v-if="permission.can_delete"
                  >
                    <v-list-item-icon class="mr-2">
                      <v-icon small>delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Eliminar</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item>
          <v-data-table
            :loading="isLoading"
            :headers="headers_public"
            :items="getSensorPublic"
            :search="search"
            v-if="permission.can_view"
          >
            <template v-slot:[`item.descripcion`]="{ item }">
              <div>
                {{ item.descripcion }}
                <span class="caption font-weight-thin">{{
                  item.other ? " (Publico)" : ""
                }}</span>
              </div>
            </template>
            <template v-slot:[`item.compartido`]="{ item }">
              <v-chip small v-if="item.other" dark color="red"
                >Origen Publico</v-chip
              >
              <v-chip
                small
                v-else
                dark
                :color="item.compartido ? 'green' : 'light-blue darken-1'"
                >{{ item.compartido ? "Compartido" : "Privado" }}</v-chip
              >
            </template>
            <template v-slot:[`item.visualizar`]="{ item }">
              <v-btn
                color="primary"
                small
                text
                :to="`${$route.path}/${item.idambient_weather}`"
              >
                <v-icon class="mr-1">analytics</v-icon>
                visualizar
              </v-btn>
              <v-btn small fab text @click="showSensorMap(item.idambient_weather)"><v-icon>location_on</v-icon></v-btn>
            </template>
            <template v-slot:[`item.favorite`]="{ item }">
              <v-btn
                fab
                small
                text
                @click="markFavorite(item)"
                :color="item.favorite ? 'yellow' : ''"
                ><v-icon>{{
                  item.favorite ? "star" : "star_border"
                }}</v-icon></v-btn
              >
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <Crud
      v-if="crud.add"
      v-model="crud.add"
      :props="crud.datos"
      @reset="crud.datos = $event"
    />
    <Delete
      v-if="crud.delete"
      v-model="crud.delete"
      :props="crud.datos"
      @reset="crud.datos = $event"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { ZONAS_CULTIVO } from "@/constants/forms";
import Crud from "./Create";
import Delete from "./Delete";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";
export default {
  components: {
    Crud,
    Delete,
  },
  data() {
    return {
      search: "",
      tab: null,
      headers: [
        { text: "Descripcion", align: "left", value: "descripcion" },
        { text: "Estado", align: "right", value: "compartido" },
        {
          text: "",
          value: "visualizar",
          divider: true,
          sortable: false,
          align: "right",
        },
        {
          text: "",
          value: "favorite",
          divider: true,
          width: "15px",
          align: "right",
        },
        {
          text: "",
          value: "accion",
          sortable: false,
          align: "right",
          width: "20px",
        },
      ],
      headers_public: [
        { text: "Descripcion", align: "left", value: "descripcion" },
        { text: "Estado", align: "right", value: "compartido" },
        {
          text: "",
          value: "visualizar",
          divider: true,
          sortable: false,
          align: "right",
        },
        {
          text: "",
          value: "favorite",
          width: "20px",
          align: "right",
        },
      ],
      crud: {
        add: false,
        delete: false,
        block: false,
        operacion: true,
        datos: null,
      },
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
    };
  },
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.fetchSensor();
    const permission = getPermission(ZONAS_CULTIVO).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  computed: {
    ...mapGetters("sensor", ["getSensor", "isLoading"]),
    getSensorPrivate() {
      return this.getSensor.filter((x) => !x.other);
    },
    getSensorPublic() {
      return this.getSensor.filter((x) => x.other);
    },
    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },
  methods: {
    ...mapActions("sensor", ["fetchSensor", "setSensorUpdate","fetchSensorId"]),
    editar(item) {
      this.crud.datos = JSON.parse(JSON.stringify(item));
      this.crud.add = true;
    },
    eliminar(item) {
      this.crud.datos = JSON.parse(JSON.stringify(item));
      this.crud.delete = true;
    },
    async showSensorMap(id){
      await this.fetchSensorId(id);
      this.$router.push('/agricultura/campo')
    },
    async markFavorite(data) {
      data.favorite = !data.favorite;
      const id = data.idambient_weather;
      const response = await this.setSensorUpdate({ id, data });
      if (response.success) return null;
      data.favorite = !data.favorite;
    },
  },
};
</script>
