export default {
    async comprobante_create({commit},request){
        commit('comprobante_create',request);
    },
    async comprobante_update({commit},request){
        commit('comprobante_update',request);
    },
    async comprobante_reload({commit},request){
        commit('comprobante_reload',request);
    },
    async comprobante_show({commit},request){
        commit('comprobante_show',request);
    },
    async comprobante_modo({commit},request){
        commit('comprobante_modo',request);
    },
    async comprobante_validar({commit},request){
        commit('comprobante_validar',request);
    },
    async comprobante_key({commit},request){
        commit('comprobante_key',request);
    },
}
