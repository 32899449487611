const campos = [
    {
        name: "Cliente",
        api: "/apicontacto/?tipo=cliente",
        id: "idcontacto",
        desc: "desccontacto",
        type: "select",
        query: [
            {
                comparison: "",
                value: "",
                logical: "",
                add: false
            }
        ]
    },
    {
        name: "Tipo Factura",
        api: "/apitipofactura/",
        id: "idtipofactura",
        desc: "desctipofactura",
        type: "select",
        query: [
            {
                comparison: "",
                value: "",
                logical: "",
                add: false
            }
        ]
    },
    {
        name: "Nro Factura",
        value: "numfventa",
        type: "text",
        query: [
            {
                comparison: "",
                value: "",
                logical: "",
                add: false
            }
        ]
    },
    {
        name: "Moneda",
        api: "/apimoneda/",
        id: "idmoneda",
        desc: "descmoneda",
        type: "select",
        query: [
            {
                comparison: "",
                value: "",
                logical: "",
                add: false
            }
        ]
    },
    {
        name: "Punto de Venta",
        api: "/apipuntoventa/",
        id: "idpuntoventa",
        desc: "descpuntoventa",
        type: "select",
        query: [
            {
                comparison: "",
                value: "",
                logical: "",
                add: false
            }
        ]
    },
    {
        name: "Fecha Venta",
        value: "fechventa",
        type: "date",
        query: [
            {
                comparison: "",
                value: "",
                logical: "",
                add: false
            }
        ]
    },
    {
        name: "Anulado",
        value: "anulventa",
        type: "boolean",
        query: [
            {
                comparison: "",
                value: "",
                logical: "",
                add: false
            }
        ]
    },
    {
        name: "Timbrado",
        api: "/apitimbrado/?band=nuevo&tipo=factura",
        id: "idtimbrado",
        desc: "timbrado",
        type: "select",
        query: [
            {
                comparison: "",
                value: "",
                logical: "",
                add: false
            }
        ]
    }
]

export default campos