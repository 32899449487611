
export default {

  Vget_moneda_data: (state) => {
    return state.moneda_data
  },
  Vget_moneda_data_all: (state) => {
    return state.moneda_data_all
  },
  Vget_moneda_cotizacion_day: (state) => {
    return state.moneda_cotizacion_day
  },
  Vget_moneda_cotizacion: (state) => {
    return state.moneda_cotizacion
  },
  Vget_moneda_load: (state) => {
    return state.moneda_load
  },
}
