export const FINANZAS = {
    title: "Mi Finanza",
    body: "Mi Finanza es el formulario principal, donde puedes ver los informes y acceder a las distintas funciones.",
    gif: null,
    elements: [
        {
            title: "Listar",
            body: [
                "Desde el Menu: Finanzas > Mi Finanza",
                "Desde el Dashboard: Vaya a la pestaña de FINANZA"
            ],
            actions: [
                {
                    title: "Botones",
                    image: "finanza/dashboard/1.png",
                    text: [
                        "1. Ayuda",
                        "2. Agregar Movimiento",
                        "3. Actualizar",
                        "4. Ver/Ocultar montos de cuentas"
                    ]
                },
                {
                    title: "Cuentas",
                    image: "finanza/dashboard/2.png",
                    text: [
                        "Cuentas y los montos actuales",
                        "Al hacer click en una de las cuentas, este muestra los movimientos de la cuenta durante el mes"
                    ]
                },
                {
                    title: "Ver movimiento de la cuenta",
                    image: "finanza/dashboard/6.png",
                    text: [
                        "1. Ir al mes anterior",
                        "2. Ir al siguiente mes",
                        "3. Saldo inicial",
                        "4. Lista de operaciones",
                        "5. Cancelar",
                    ]
                },
                {
                    title: "Grafico mensual de gastos",
                    image: "finanza/dashboard/3.png",
                    text: [
                        "1. Grafico de tipo dona en la cual ve la distribucion mensual de los gastos",
                        "2. Puede visualizar de cada mes"
                    ]
                },
                {
                    title: "Grafico mensual de ingresos",
                    image: "finanza/dashboard/4.png",
                    text: [
                        "1. Grafico de tipo dona en la cual ve la distribucion mensual de los ingresos",
                        "2. Puede visualizar de cada mes"
                    ]
                },
                {
                    title: "Grafico de presupuestos",
                    image: "finanza/dashboard/5.png",
                    text: [
                        "Grafico de tipo barra en la cual ve la distribucion de los presupuestos vs los gastos",
                    ]
                }
            ]
        },
    ],
    dependencias: []
}
export const CUENTAS = {
    title: "Cuentas",
    body: "Una cuenta representa los activos liquidos que posee (Cuentas bancarias, efectivos, creditos, etc).",
    gif: null,
    elements: [
        {
            title: "Listar",
            body: [
                "Desde el Menu: Finanzas > Operaciones > Cuenta Finanza",
                "Desde Finanza: Mantenimiento > Cuenta"
            ],
            actions: [
                {
                    title: "Botones",
                    image: "finanza/cuenta/1.png",
                    text: [
                        "1. Buscar",
                        "2. Ayuda",
                        "3. Agregar",
                        "4. Actualizar"
                    ]
                },
                {
                    title: "Botones de lista",
                    image: "finanza/cuenta/2.png",
                    text: [
                        "1. Editar",
                        "2. Eliminar"
                    ]
                }
            ]
        },
        {
            title: "Agregar",
            body: [],
            actions: [
                {
                    title: "Registrar cuenta",
                    image: "finanza/cuenta/3.png",
                    text: [
                        "1. Nombre de la cuenta",
                        "2. Tipo de cuenta (Banco, Efectivo, etc)",
                        "3. Inicia con valor positivo o negativo",
                        "4. Moneda",
                        "5. Monto",
                        "6. Cancelar",
                        "7. Guardar"
                    ]
                }
            ]
        },
        {
            title: "Editar",
            body: [],
            actions: [
                {
                    title: "Editar cuenta",
                    image: "finanza/cuenta/4.png",
                    text: [
                        "Lo mismo que agregar"
                    ]
                }
            ]
        },
        {
            title: "Eliminar",
            body: [],
            actions: [
                {
                    title: "Eliminar cuenta",
                    image: "finanza/cuenta/5.png",
                    text: [
                        "1. Cancelar",
                        "2. Eliminar"
                    ]
                }
            ]
        },  
    ],
    dependecias: []
}
export const GRUPOS = {
    title: "Grupos",
    body: "Sirve para agrupar las categorias (Automovil, Casa, Entretenimiento, etc).",
    gif: null,
    elements: [
        {
            title: "Listar",
            body: [
                "Desde el Menu: Finanzas > Operaciones > Grupo Finanza",
                "Desde Finanza: Mantenimiento > Grupo"
            ],
            actions: [
                {
                    title: "Botones",
                    image: "finanza/cuenta/1.png",
                    text: [
                        "1. Buscar",
                        "2. Ayuda",
                        "3. Agregar",
                        "4. Actualizar"
                    ]
                },
                {
                    title: "Botones de lista",
                    image: "finanza/cuenta/2.png",
                    text: [
                        "1. Editar",
                        "2. Eliminar"
                    ]
                }
            ],
        },
        {
            title: "Agregar",
            body: [],
            actions: [
                {
                    title: "Registrar grupo",
                    image: "finanza/grupo/1.png",
                    text: [
                        "1. Nombre del grupo",
                        "2. Estado de ingreso o egreso",
                        "3. Cancelar",
                        "4. Guardar"
                    ]
                }
            ]
        },
        {
            title: "Editar",
            body: [],
            actions: [
                {
                    title: "Editar grupo",
                    image: "finanza/grupo/2.png",
                    text: [
                        "Lo mismo que agregar"
                    ]
                }
            ]
        },
        {
            title: "Eliminar",
            body: [],
            actions: [
                {
                    title: "Eliminar grupo",
                    image: "finanza/grupo/3.png",
                    text: [
                        "1. Cancelar",
                        "2. Eliminar"
                    ]
                }
            ]
        },  
    ],
    dependecias: []
}
export const CATEGORIAS = {
    title: "Categoria",
    body: "Una categoria es un movimiento que se da en una cuenta (Combustible, comida, super, etc).",
    gif: null,
    elements: [
        {
            title: "Listar",
            body: [
                "Desde el Menu: Finanzas > Operaciones > Categoria Finanza",
                "Desde Finanza: Mantenimiento > Categoria"
            ],
            actions: [
                {
                    title: "Botones",
                    image: "finanza/cuenta/1.png",
                    text: [
                        "1. Buscar",
                        "2. Ayuda",
                        "3. Agregar",
                        "4. Actualizar"
                    ]
                },
                {
                    title: "Botones de lista",
                    image: "finanza/cuenta/2.png",
                    text: [
                        "1. Editar",
                        "2. Eliminar"
                    ]
                }
            ],
        },
        {
            title: "Agregar",
            body: [],
            actions: [
                {
                    title: "Registrar categoria",
                    image: "finanza/categoria/1.png",
                    text: [
                        "1. Nombre de la categoria",
                        "2. Grupo al cual pertenece",
                        "3. Sin presupuesto / Con presupuesto",
                        "4. Fecha de inicio del presupuesto (Con presupuesto)",
                        "5. Tiempo (Diario, Semanal, Quincenal, etc) (Con presupuesto)",
                        "6. Monto (Con presupuesto)",
                        "7. Cancelar",
                        "8. Guardar"
                    ]
                }
            ],
        },
        {
            title: "Editar",
            body: [],
            actions: [
                {
                    title: "Editar categoria",
                    image: "finanza/categoria/2.png",
                    text: [
                        "Lo mismo que agregar"
                    ]
                }
            ],
        },
        {
            title: "Eliminar",
            body: [],
            actions: [
                {
                    title: "Eliminar categoria",
                    image: "finanza/categoria/3.png",
                    text: [
                        "1. Cancelar",
                        "2. Eliminar"
                    ]
                }
            ],
        },  
    ],
    dependencias: []
}
export const MOVIMIENTOS = {
    title: "Movimiento",
    body: "Un movimiento es la operacion que se realiza entre una cuenta y una categoria (Movimiento) o entre dos cuentas (Transferencia).",
    gif: null,
    elements: [
        {
            title: "Listar",
            body: [
                "Desde el Menu: Finanzas > Transacciones > Movimiento",
                "Desde Finanza: Transaccion > Movimiento"
            ],
            actions: [
                {
                    title: "Pestañas",
                    image: "finanza/movimiento/1.png",
                    text: [
                        "1. Movimientos",
                        "2. Transferencias"
                    ]
                },
                {
                    title: "Botones",
                    image: "finanza/cuenta/1.png",
                    text: [
                        "1. Buscar",
                        "2. Ayuda",
                        "3. Agregar",
                        "4. Actualizar"
                    ]
                },
                {
                    title: "Botones de lista",
                    image: "finanza/cuenta/2.png",
                    text: [
                        "1. Editar",
                        "2. Eliminar"
                    ]
                }
            ],
        },
        {
            title: "Agregar",
            body: [],
            actions: [
                {
                    title: "Registrar movimiento",
                    image: "finanza/movimiento/2.png",
                    text: [
                        "1. Fecha",
                        "2. Cuenta",
                        "3. Movimiento o Transferencia (Al seleccionar, cambia entre Categoria y Cuenta Destino)",
                        "4. Categoria / Cuenta",
                        "5. Positivo o Negativo",
                        "6. Monto",
                        "7. Cancelar",
                        "8. Guardar"
                    ]
                }
            ],
        },
        {
            title: "Editar",
            body: [],
            actions: [
                {
                    title: "Editar movimiento",
                    image: "finanza/movimiento/3.png",
                    text: [
                        "Lo mismo que agregar"
                    ]
                }
            ],
        },
        {
            title: "Eliminar",
            body: [],
            actions: [
                {
                    title: "Eliminar movimiento",
                    image: "finanza/movimiento/4.png",
                    text: [
                        "1. Cancelar",
                        "2. Eliminar"
                    ]
                }
            ],
        },  
    ],
    dependencias: []
}
export const REPORTES = {
    title: "Reportes",
    body: "Son informes que comunican un analisis de los movimientos que ocurren en un determinado tiempo",
    gif: null,
    elements: [
        {
            title: "Gastos",
            body: [
                "Desde el Menu: Finanzas > Finanzas Reportes > Gastos",
                "Desde Finanza: Finanzas Reportes > Gastos"
            ],
            actions: [
                {
                    title: "Operacion sobre informe de gastos",
                    image: "finanza/reporte/1.png",
                    text: [
                        "1. Mes anterior",
                        "2. Siguiente mes",
                        "3. Grafico de tipo dona en la cual se ve la distribucion mensual de los ingresos",
                        "4. Tabla de gastos"
                    ]
                }
            ],
        },
        {
            title: "Ingresos",
            body: [
                "Desde el Menu: Finanzas > Finanzas Reportes > Ingresos",
                "Desde Finanza: Finanzas Reportes > Ingresos"
            ],
            actions: [
                {
                    title: "Operacion sobre informe de ingresos",
                    image: "finanza/reporte/2.png",
                    text: [
                        "1. Mes anterior",
                        "2. Siguiente mes",
                        "3. Grafico de tipo dona en la cual se ve la distribucion mensual de los ingresos",
                        "4. Tabla de ingresos"
                    ]
                }
            ],
        },
        {
            title: "Presupuestos",
            body: [
                "Desde el Menu: Finanzas > Finanzas Reportes > Presupuestos",
                "Desde Finanza: Finanzas Reportes > Presupuestos"
            ],
            actions: [
                {
                    title: "Operacion sobre informe de presupuestos",
                    image: "finanza/reporte/3.png",
                    text: [
                        "1. Categorias",
                        "2. Obtener datos",
                        "3. Presupuesto general y Gasto general",
                        "4. Presupuesto por rango y gasto por rango"
                    ]
                }
            ],
        }
    ],
    dependencias: []
}
export const TODOS = [
    FINANZAS,
    CUENTAS,
    GRUPOS,
    CATEGORIAS,
    MOVIMIENTOS,
    REPORTES
]