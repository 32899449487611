import {
  LOADING,
  FETCH,
  FETCH_CELO,
  FETCH_TORO,
  FETCH_DISPONIBLE,
  FETCH_HEMBRA,
  FETCH_TERMO,
  FETCH_NOMINA,
  FETCH_PARASECADO,
  FETCH_INSEMINADA,
  FETCH_PRENADA,
  FETCH_VIVO,
  FETCH_MUERTO,
  FETCH_TODOS,
  FETCH_ENFERMO,
  FETCH_VENTA,
  FETCH_POSTPARTO,
  FETCH_TERNERO,
  FETCH_SECADO,
  FETCH_BY_ESTADO,
  FETCH_NOMINA_ID,
  FETCH_COUNT,
  FETCH_SEMEN,
  FETCH_FICHA,
  FETCH_VAQUILLA,
  FETCH_REPORTE_AVANZADO,
  FETCH_PREPARTO,
  FETCH_REPORTE_REPRODUCCION,
  SET_SOCKET,
} from "./contants";
export default {
  [LOADING]: (state, request) => (state.loading = request),
  [FETCH]: (state, request) => (state.data = request),
  [FETCH_CELO]: (state, request) => (state.celo = request),
  [FETCH_TORO]: (state, request) => (state.toro = request),
  [FETCH_DISPONIBLE]: (state, request) => (state.disponible = request),
  [FETCH_HEMBRA]: (state, request) => (state.hembra = request),
  [FETCH_TERMO]: (state, request) => (state.termo = request),
  [FETCH_NOMINA]: (state, request) => (state.nomina = request),
  [FETCH_PARASECADO]: (state, request) => (state.parasecado = request),
  [FETCH_INSEMINADA]: (state, request) => (state.inseminada = request),
  [FETCH_PRENADA]: (state, request) => (state.prenada = request),
  [FETCH_VIVO]: (state, request) => (state.vivo = request),
  [FETCH_MUERTO]: (state, request) => (state.muerto = request),
  [FETCH_TODOS]: (state, request) => (state.todos = request),
  [FETCH_ENFERMO]: (state, request) => (state.enfermo = request),
  [FETCH_VENTA]: (state, request) => (state.venta = request),
  [FETCH_POSTPARTO]: (state, request) => (state.postparto = request),
  [FETCH_TERNERO]: (state, request) => (state.ternero = request),
  [FETCH_SECADO]: (state, request) => (state.secado = request),
  [FETCH_BY_ESTADO]: (state, request) => (state.dataEstado = request),
  [FETCH_NOMINA_ID]:(state,request) => (state.nominaId = request),
  [FETCH_COUNT]:(state,request) => (state.count = request),
  [FETCH_SEMEN]: (state,request) => (state.semen = request),
  [FETCH_FICHA]: (state,request) => (state.ficha = request),
  [FETCH_VAQUILLA]: (state,request) => (state.vaquilla = request),
  [FETCH_PREPARTO]: (state,request) => (state.preparto = request),
  [FETCH_REPORTE_AVANZADO]: (state,request) => (state.reporteavanzado = request),
  [FETCH_REPORTE_REPRODUCCION]: (state,request) => (state.reportereproduccion = request),
  [SET_SOCKET]: (state, request) => (state.socketData = request)
};
