<template>
    <c-dialog-help :value="value" @cancel="close()">
        <div v-if="Array.isArray(content)">
            <v-tabs>
                <v-tab v-for="(item, index) in content" :key="index">
                    {{ item.title }}
                </v-tab>
                <v-tab-item v-for="(item, index) in content" :key="index">
                    <c-dialog-helpbody :help="item"></c-dialog-helpbody>
                </v-tab-item>
            </v-tabs>
        </div>
        <div v-else-if="content == null">
            Nada para mostrar
        </div>
        <div v-else>
            <c-dialog-helpbody :help="content"></c-dialog-helpbody>
        </div>
    </c-dialog-help>
</template>
<script>
export default {
    props: {
        value: Boolean,
        content: {
            type: [
                Object,
                Array,
            ],
            default: null
        }
    },
    watch: {
        $route() {
            return this.close()
        }
    },
    methods: {
        close() {
            this.$emit("datos", null);
            this.$emit("input", false);
        }
    },
    data: () => ({
    })
}
</script>