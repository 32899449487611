<template>
  <div>
    <v-container v-if="permission.animal.can_view" fluid>
      <mini-statistic />
    </v-container>

    <v-card-text class="pa-0" v-if="montar && permission.animal.can_view">
      <v-container grid-list-xl fluid>
        <v-row>
          <v-col cols="12" lg="8" sm="12">
            <v-widget
              title="Produccion"
              :content-bg="darkMode ? 'black' : 'white'"
            >
              <v-btn icon slot="widget-header-action" @click="cargar_bar()">
                <v-icon class="text--secondary">refresh</v-icon>
              </v-btn>
              <div slot="widget-content">
                <div class="chart-wrapper">
                  <v-chart :options="datos" autoresize />
                </div>
              </div>
              <div slot="widget-content">
                <v-container>
                  <v-row dense>
                    <v-col cols="6">
                      <c-text-date
                        dense
                        outlined
                        label="Fecha Inicio"
                        v-model="production.firstDate"
                      ></c-text-date>
                    </v-col>
                    <v-col cols="6">
                      <c-text-date
                        dense
                        outlined
                        label="Fecha Fin"
                        v-model="production.lastDate"
                      ></c-text-date>
                    </v-col>
                  </v-row>
                </v-container>
              </div>
            </v-widget>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </div>
</template>

<script>
import { getPermission } from "@/services/storage/permissionStorage.service";
import MiniStatistic from "./MiniStatistic";
import VWidget from "@/components/widgets/VWidget";
import ECharts from "vue-echarts/components/ECharts";
import { substract_days, current_date } from "@/services/util/date.service";
import { db } from "@/services/db.service";
import { ANIMAL } from "@/constants/forms";
import { PUEDE_LISTAR } from "@/constants/permissions";
export default {
  components: {
    MiniStatistic,
    VWidget,
    "v-chart": ECharts,
  },
  data() {
    return {
      production: {
        firstDate: substract_days(15),
        lastDate: current_date(),
      },
      permission: {
        animal: {
          can_view: false,
        },
      },
      count: {
        ternero: "",
        celo: "",
        inseminada: "",
        prenada: "",
        secado: "",
        anestro: "",
        enfermo: "",
        disponible: "",
        productora: "",
        semen: "",
      },
      montar: false,
      datoscirculo: {
        legend: {
          bottom: "0",
        },
        xAxis: {
          show: false,
        },
        yAxis: {
          show: false,
        },
        series: [
          {
            type: "pie",
            radius: ["50%", "70%"],
            avoidLabelOverlap: true,
            data: [],
          },
        ],
      },
      datos: {
        color: ["#3398DB"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              title: {
                zoom: "Zoom",
                back: "Atras",
              },
              yAxisIndex: "none",
            },
            restore: {
              title: "Restaurar",
            },
            dataView: {
              readOnly: true,
              lang: ["Lista", "Regresar"],
              title: "Listar",
              optionToContent: function (opt) {
                var axisData = opt.xAxis[0].data;
                var series = opt.series;
                var table =
                  '<table style="width:100%;text-align:center"><tbody><tr>' +
                  "<td>Fecha:</td>" +
                  "<td>" +
                  series[0].name +
                  "</td>" +
                  "<td>";
                for (var i = 0, l = axisData.length; i < l; i++) {
                  table +=
                    "<tr>" +
                    "<td>" +
                    axisData[i] +
                    "</td>" +
                    "<td>" +
                    series[0].data[i] +
                    "</td>" +
                    "<td>";
                }
                table += "</tbody></table>";
                return table;
              },
            },
            magicType: {
              type: ["line", "bar"],
              title: {
                line: "Linea",
                bar: "Bar",
              },
            },

            saveAsImage: {
              title: "Descargar",
            },
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: [],
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "Produccion",
            type: "bar",
            barWidth: "60%",
            data: [],
          },
        ],
      },
    };
  },
  mounted() {
    //this.cargar_minigrafico();
    this.cargar_bar();
    this.permission.animal.can_view =
      getPermission(ANIMAL).permiso[PUEDE_LISTAR];
    setTimeout(() => (this.montar = true), 250);
  },
  computed: {
    color_enf() {
      if (this.count.enfermo > 0) return "red";
      return "blue";
    },
    darkMode() {
      return this.$vuetify.theme.dark;
    },
  },
  methods: {
    async cargar_bar() {
      let inicio = this.production.firstDate;
      let fin = this.production.lastDate;
      let a = await db.get(
        `/apigetproduccionparametro/?fechainicio=${inicio}&fechafin=${fin}`
      );
      this.datos.xAxis[0].data = a.data;
      this.datos.series[0].data = a.value;
    },
    async cargar_minigrafico() {
      let data_radius = [];
      db.get("/apianimalcount/").then((b) => {
        this.count.ternero = String(b.Terneros);
        this.count.celo = String(b.Celos);
        this.count.disponible = String(b.Disponibles);
        this.count.inseminada = String(b.Inseminadas);
        this.count.prenada = String(b.Prenadas);
        this.count.secado = String(b.Secadas);
        this.count.anestro = String(b.AnestroPostparto);
        this.count.enfermo = String(b.Enfermos);
        this.count.productora = String(b.Productoras);
        this.count.semen = String(b.Semen);
        Object.entries(b).map(([key, value]) => {
          data_radius.push({ value: value, name: key });
        });
      });
      this.datoscirculo.series[0].data = JSON.parse(
        JSON.stringify(data_radius)
      );
    },
  },
};
</script>

<style></style>
