export default [
  {
    path: '/stock',
    name: 'Stock',
    component: () => import( /* webpackChunkName: "Ajuste" */ '@/views/Stock/Stock/Stock.vue'),
  },
  {
    path: '/ajuste',
    name: 'Ajuste',
    component: () => import( /* webpackChunkName: "Ajuste" */ '@/views/Stock/Ajuste/Ajuste.vue'),
  },
  {
    path: '/motivoajuste',
    name: 'Motivo Ajuste',
    component: () => import( /* webpackChunkName: "Motivoajuste" */ '@/views/Stock/Motivoajuste/Motivoajuste.vue'),
  },
  {
    path: '/tipoajuste',
    name: 'Tipo Ajuste',
    component: () => import( /* webpackChunkName: "Tipoajuste" */ '@/views/Stock/Tipoajuste/Tipoajuste.vue'),
  },
  {
    path: '/motivotraslado',
    name: 'Motivo Traslado',
    component: () => import( /* webpackChunkName: "Motivotraslado" */ '@/views/Stock/Motivotraslado/Motivotraslado.vue'),
  },
  {
    path: '/transferencia',
    name: 'Transferencia',
    component: () => import( /* webpackChunkName: "Transferencia" */ '@/views/Stock/Transferencia/Transferencia.vue' ),
  }
]