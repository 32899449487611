<template>
  <div>
    <v-form
      ref="form"
      v-if="permission.reporte"
      @submit.prevent="obtenerDatos()"
    >
      <v-row class="ma-1">
        <v-col>
          <v-autocomplete
            :items="tipo"
            return-object
            item-text="descripcion"
            autocomplete="off"
            hide-details
            id="input3"
            v-model="mtipo"
            dense
          ></v-autocomplete>
        </v-col>
        <v-col>
          <AutocompleteAnimal
            action="fetchTodos"
            getter="getTodos"
            no-create
            :rules="[]"
            label="Animales"
            :outlined="false"
            multiple
            v-model="seleccionado"
          />
        </v-col>
        <v-btn
          color="primary"
          fab
          small
          class="mt-3"
          outlined
          text
          @click="obtenerDatos()"
        >
          <v-icon>cloud_download</v-icon>
        </v-btn>
        <v-btn
          color="green"
          fab
          small
          class="ml-1 mt-3"
          outlined
          text
          @click="generarPDF()"
        >
          <v-icon>get_app</v-icon>
        </v-btn>
      </v-row>
      <v-spacer></v-spacer>
      <v-divider></v-divider>
      <v-data-table
        :headers="headers"
        :loading="load"
        dense
        :items="data"
        :sort-by="sortby"
        :sort-desc="sortdesc"
        :items-per-page="999999999"
      >
      </v-data-table>
    </v-form>
  </div>
</template>
<script>
import { exportProduccionAnimal } from "./excel";
import { db } from "@/services/db.service";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { PUEDE_GENERAR_REPORTE } from "@/constants/permissions";
import { PRODUCCION_LECHE } from "@/constants/forms";

export default {
  components: {
    AutocompleteAnimal: () =>
      import("@/views/Animal/Animal/AutocompleteAnimal"),
  },
  data() {
    return {
      seleccionado: [],
      load: false,
      permission: {
        reporte: getPermission(PRODUCCION_LECHE).permiso[PUEDE_GENERAR_REPORTE],
      },
      headers: [
        {
          text: "Fecha",
          align: "start",
          value: "fecha",
          divider: true,
        },
        {
          text: "Vaca",
          align: "start",
          value: "vaca",
          divider: true,
        },
        {
          text: "Nombre",
          align: "start",
          value: "nombre",
          divider: true,
        },
        {
          text: "Raza",
          align: "start",
          value: "raza",
          divider: true,
        },
        {
          text: "Parto",
          align: "start",
          value: "fechaparto",
          divider: true,
        },
        {
          text: "Cria",
          align: "start",
          value: "cria",
          divider: true,
        },
        {
          text: "Cria Sexo",
          align: "start",
          value: "cria_sexo",
          divider: true,
        },
        {
          text: "Meses",
          align: "end",
          value: "meses",
          divider: true,
        },
        {
          text: "N. Parto",
          align: "end",
          value: "nroparto",
          divider: true,
        },
        {
          text: "Lact",
          align: "end",
          value: "diaslact",
          divider: true,
        },
        {
          text: "Litros",
          align: "end",
          value: "litros",
          divider: true,
        },
      ],
      data: [],
      sortby: [],
      sortdesc: [],
      tipo: [
        { id: 1, descripcion: 'Disponibles' },
        { id: 2, descripcion: 'Todos' },
      ],
      mtipo: { id: 1, descripcion: 'Disponibles' }
    };
  },
  async mounted() {
    this.obtenerDatos();
  },
  methods: {
    async obtenerDatos() {
      let curl = `/apireportelecheanimal/?tipo=${this.mtipo.id}`
      if (this.seleccionado.length >= 1) {
        let text = this.seleccionado.toString();
        curl = curl + `&animal=${text.replace(",", "-")}`;
      }
      this.load = true;
      try {
        const response = await db.get(curl);
        this.data = response;
        this.sortby = ["fecha", "vaca"];
      } catch (error) {
        console.log(error);
      }
      this.load = false;
    },
    async generarPDF() {
      exportProduccionAnimal(this.data, this.headers);
      console.log("generar pdf");
    },
  },
};
</script>