export default {
  procedencia_data(state, request) {
    state.procedencia_data = request;
  },
  procedencia_data_inactivo(state, request) {
    state.procedencia_data_inactivo = request;
  },
  procedencia_load(state, request) {
    state.procedencia_load = request;
  },   
  procedencia_save_load(state, request) {
    state.procedencia_save_load = request;
  },   
}