import { get } from "@/services/api/api.service";
import { SET_LOADING, SET_SENAVE, SET_SENAVEFECHA, SET_REPORTEINDICE, SET_SENAVEUNO, url, url2, url3, url4 } from './constants'
export default {
    async fetchReporteindice({ commit, dispatch }, {idlote, idcampana, fecha_inicio}) {
        commit(SET_LOADING, true);
        try {
            const response = await get(`${url4}${idlote}/${idcampana}/${fecha_inicio}`)
            commit(SET_REPORTEINDICE, response)
        } catch (e) {
            dispatch('snackbar/setNotification', e, { root: true });
            commit(SET_LOADING, false);
            throw e
        }
        commit(SET_LOADING, false);
    },
    async fetchSenave({ commit, dispatch }, idcampana) {
        commit(SET_LOADING, true);
        try {
            const response = await get(`${url}${idcampana}/`);
            commit(SET_SENAVE, response);
        } catch (e) {
            dispatch('snackbar/setNotification', e, { root: true });
            commit(SET_LOADING, false);
            throw e
        }
        commit(SET_LOADING, false);
    },
    async fetchSenaveFecha({ commit, dispatch }, data) {
        commit(SET_LOADING, true);
        try {
            let lotes = ''
            data.lotes.forEach(x => {
                if (lotes != '') {
                    lotes = lotes + '_' + x.idlote_agri
                } else {
                    lotes = x.idlote_agri
                }
            })
            const response = await get(`${url3}?inicio=${data.inicio}&fin=${data.fin}&lote=${lotes}`)
            commit(SET_SENAVEFECHA, response);
        } catch (e) {
            dispatch('snackbar/setNotification', e, { root: true });
        }
        commit(SET_LOADING, false)
    },
    async fetchSenaveuno({ commit, dispatch }, data) {
        commit(SET_LOADING, true);
        try {
            const response = await get(`${url2}${data.idcampana}/?lote=${data.idlote}&siem=${data.idsiembra}`);
            commit(SET_SENAVEUNO, response);
        } catch (e) {
            dispatch('snackbar/setNotification', e, { root: true });
            commit(SET_LOADING, false);
            throw e
        }
        commit(SET_LOADING, false);
    }
}
