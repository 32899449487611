<template>
  <div>
    <v-card>
      <v-alert type="error" v-if="permission_alert">Error!!! Este usuario no tiene permisos en esta ventana</v-alert>
      <v-toolbar flat dense>
        <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
        <widgets-Favorite :ruta="$route.path" :formulario="$route.name" />
        <v-divider class="mx-2" inset vertical></v-divider>

        <v-spacer></v-spacer>
        <c-text-table-search v-model="search" v-if="permission.can_view"></c-text-table-search>
      </v-toolbar>
      <v-toolbar flat dense>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-tabs v-model="tab" right>
            <v-tab href="#tab-1" @click="fetchPreparto()">Preparto</v-tab>
            <v-tab href="#tab-2" @click="fetchPrepartoHistorico()">Historico</v-tab>
          </v-tabs>
          <v-divider class="mx-2" inset vertical></v-divider>
          <c-btn-table-add @click="crud.add = true" v-if="permission.can_add" class="mr-1"></c-btn-table-add>
          <c-btn-table-reload
            @click="tab === 'tab-1' ? fetchPreparto() : fetchPrepartoHistorico()"></c-btn-table-reload>
        </v-toolbar-items>
      </v-toolbar>
      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1">
          <v-data-table :loading="isLoading" :headers="headers" :items="getPreparto" :search="search"
            class="elevation-1" v-if="permission.can_view">
            <template v-slot:[`item.accion`]="props">
              <c-btn-table-edit @click="mupdate(props.item)" v-if="permission.can_update"></c-btn-table-edit>
              <c-btn-table-delete @click="meliminar(props.item)" v-if="permission.can_delete"></c-btn-table-delete>
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item value="tab-2">
          <v-data-table :loading="isLoading" :headers="headers" :items="getHistorico" :search="search"
            class="elevation-1" v-if="permission.can_view">
            <template v-slot:[`item.accion`]="props">
              <c-btn-table-edit @click="mupdate(props.item)" v-if="permission.can_update"></c-btn-table-edit>
              <c-btn-table-delete @click="meliminar(props.item)" v-if="permission.can_delete"></c-btn-table-delete>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
      <Crud v-model="crud.add" v-if="crud.add" :props="crud.datos" @reset="crud.datos = $event" />
      <Delete v-model="crud.delete" v-if="crud.delete" :props="crud.datos" />
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { PREPARTO } from "@/constants/forms";
import Crud from "./Create";
import Delete from "./Delete";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";
export default {
  components: {
    Crud,
    Delete,
  },
  data() {
    return {
      tab: null,
      search: "",
      headers: [
        { text: "Animal", align: "left", value: "idanimal.rpanimal" },
        { text: "Fecha de Ingreso", align: "left", value: "fecppreparto" },
        { text: "Comentario", align: "left", value: "comnpreparto" },

        { text: "Accion", value: "accion", sortable: false, align: "right" },
      ],
      crud: {
        add: false,
        delete: false,
        datos: null,
      },
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
    };
  },
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.fetchPreparto();

    const permission = getPermission(PREPARTO).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  computed: {
    ...mapGetters("preparto", ["getPreparto", "getHistorico", "isLoading"]),
    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },
  methods: {
    ...mapActions("preparto", ["fetchPreparto", "fetchPrepartoHistorico"]),
    meliminar(item) {
      this.crud.datos = JSON.parse(JSON.stringify(item));
      this.crud.delete = true;
    },
    mupdate(val) {
      this.crud.datos = JSON.parse(JSON.stringify(val));
      this.crud.add = true;
    },
  },
};
</script>
