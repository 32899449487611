export default {
  dashboard_menu(state, request) {
    state.dashboard_menu = request;
  },
  dashboard_diariocaja(state, request) {
    state.dashboard_diariocaja = request;
  },
  dashboard_load(state, request) {
    state.dashboard_load = request;
  },

  notificacion_data(state, request) {
    state.notificacion_data = request;
  },
  notificacion_merge(state, request) {
    state.notificacion_data = state.notificacion_data.concat(request);
  },
  notificacion_splice(state, request) {
    const index = state.notificacion_data.find(
      (x) => x.idnotificacion === request
    );
    if (index)
      state.notificacion_data.splice(state.notificacion_data.indexOf(index), 1);
  },
  notificacion_load(state, request) {
    state.notificacion_load = request;
  },
  favorites(state, request) {
    state.favorites = request;
  },
  favorites_load(state, request) {
    state.favorites_load = request;
  },
  activity_data(state, request) {
    state.activity_data = request;
  },
  activity_load(state, request) {
    state.activity_load = request;
  },
  commodities_load(state, request) {
    state.commodities = {
      ...state.commodities,
      isLoading: request,
    };
  },
  commodities_data(state, request) {
    state.commodities = {
      data: request,
      isLoading: false,
    };
  },
};
