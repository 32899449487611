<template>
  <div>
    <v-autocomplete
      :value="value"
      @input="$emit('input', $event)"
      ref="input"
      :items="items"
      :loading="loading"
      :rules="rules"
      :id="id"
      autocomplete="off"
      :multiple="multiple"
      return-object
      :hide-details="hideDetails"
      item-text="idlote_agri.campo"
      item-value="idlote_agri.idlote_agri"
      :readonly="readonly"
      :outlined="outlined"
      :dense="dense"
      :disabled="disabled"
      :chips="chips"
      :small-chips="smallChips"
      @focus="$emit('focus')"
      @change="$emit('change')"
      label="Lotes"
    >
      <template v-slot:no-data>
        <v-list-item dense>
          <v-list-item-content>
            <v-list-item-title
              >No tiene lotes en esta Campaña</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import autocompleteProps from "@/services/mixins/autocomplete.mixin";
export default {
  mixins: [autocompleteProps],
  props: {
    items: {
      type: [Array],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => {
        return [(v) => !!v || "Seleccion un campo"];
      },
    },
    multiple:{
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    syncInput: null,
  }),

  methods: {
    focus() {
      this.$refs.input.focus();
    },
    isMenuActive() {
      this.$refs.input.isMenuActive = false;
    },
  },
};
</script>

<style></style>
