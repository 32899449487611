<template>
  <div>
    <InputAutocomplete
      :label="label"
      item-value="iddeposito"
      item-text="desdeposito"
      :id="id"
      ref='input'
      outlined
      dense
      :readonly="readonly"
      :value="value"
      :return-object="returnObject"
      :items="Vget_deposito_data"
      :loading="Vget_deposito_load"
      :permission="create ? permission : false"
      @sync-input="formulario.search = $event"
      @create="formulario.crud = true"
      @input="$emit('input', $event)"
    />
    <Crud
      v-model="formulario.crud"
      v-if="formulario.crud"
      :sync-input="formulario.search"
      @sync-input="syncInputHandler($event)"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import InputAutocomplete from "@/ncomponents/InputAutocomplete";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { DEPOSITO } from "@/constants/forms";
import { PUEDE_AGREGAR } from "@/constants/permissions";
import Crud from "./Create";
export default {
  components: {
    InputAutocomplete,
    Crud,
  },
  props: {
    label:{
      type: String,
      default: 'Deposito'
    },
    id: String,
    create: Boolean,
    value: [String, Object, Number],
    returnObject: Boolean,
    readonly: Boolean,
  },
  data: () => ({
    formulario: {
      crud: false,
      search: "",
    },
  }),
  mounted() {
    this.Vact_deposito_data();
  },
  computed: {
    ...mapGetters("deposito", ["Vget_deposito_data", "Vget_deposito_load"]),
    permission: () => getPermission(DEPOSITO).permiso[PUEDE_AGREGAR],
  },
  methods: {
    ...mapActions("deposito", ["Vact_deposito_data"]),
     syncInputHandler(event) {
      if (this.returnObject) this.$emit("input", event);
      if (!this.returnObject) this.$emit("input", event.iddeposito);
      this.$emit("next-focus");
    },
    focus() {
      this.$refs.input.focus();
    },
    isMenuActive() {
      this.$refs.input.isMenuActive = false;
    },
  },
};
</script>

<style></style>
