import jsPDF from "jspdf";
import "jspdf-autotable";
import { Workbook } from "exceljs";
import * as fs from "file-saver";

import { current_date, dmy, current_time } from "@/services/util/date.service";
const currencyFormat = (value) => {
  return new Intl.NumberFormat({ maximumSignificantDigits: 1 }).format(
    value.toFixed(2)
  );
};
export const exportXLS = (datos, title) => {
  let workbook = new Workbook();
  datos.forEach((moneda) => {
    let worksheet = workbook.addWorksheet(moneda.moneda);
    let data = moneda.detalle.map((x) => {
      return {
        "Año Mes": x.fecha,
        Dia: x.dia,
        Numero: x.nro,
        "Razon Social": x.razon,
        Ruc: x.ruc,
        "Gravadas 10%": x.gra10,
        "Iva 10%": x.iva10,
        "Gravadas 5%": x.gra5,
        "Iva 5%": x.iva5,
        Exentas: x.exenta,
        Total: x.total,
      };
    });
    worksheet.addRow(Object.keys(data[0]));
    data.forEach((x) => {
      worksheet.addRow(Object.values(x));
    });
    worksheet.columns.map((column) => {
      let widthMax = 0;
      column.values.map((x, i) => {
        if (x.length) if (i > 0) if (x.length > widthMax) widthMax = x.length;
      });
      column.width = widthMax < 8 ? 8 : widthMax;
    });
  });
  workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    fs.saveAs(
      blob,
      `${title} ${current_date()}.xlsx` || "Libro de Ventas.xlsx"
    );
  });
};
export const exportXLSContable = (datos, title) => {
  let workbook = new Workbook();
  let worksheet = workbook.addWorksheet("HOJA 1");
  worksheet.addRow(Object.keys(datos[0]));
  datos.forEach((x) => {
    worksheet.addRow(Object.values(x));
  });
  worksheet.columns.map((column) => {
    let widthMax = 0;
    column.values.map((x, i) => {
      if (x.length) if (i > 7) if (x.length > widthMax) widthMax = x.length;
    });
    column.width = widthMax < 8 ? 8 : widthMax;
  });
  workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    fs.saveAs(
      blob,
      `${title} ${current_date()}.xlsx` || "Libro de Ventas.xlsx"
    );
  });
};
export const exportAvanzado = (datos, headers, parametros, title) => {
  var doc = new jsPDF();
  doc.setFontSize(9);
  doc.text(
    `${title} ley 125/91 del ${dmy(parametros.fechaInicio)} al ${dmy(
      parametros.fechaFin
    )} `,
    15,
    20
  );
  doc.text("Fecha: ", 15, 25);
  doc.text("Hora: ", 15, 30);
  doc.text(current_date(), 27, 25);
  doc.text(current_time(), 27, 30);

  //doc.setFontType("bold");
  let startY = 45;
  datos.datos.forEach((moneda, i) => {
    if (i > 0) startY = doc.previousAutoTable.finalY + 30;
    doc.setFontSize(9);
    doc.text(moneda.moneda, doc.internal.pageSize.width / 2, startY - 8, {
      align: "center",
    });
    doc.setLineWidth(1);
    doc.line(13, startY - 7, doc.internal.pageSize.width - 12, startY - 7);
    moneda.detalle.forEach((mesAnho, j) => {
      if (j > 0) startY = doc.previousAutoTable.finalY + 15;
      doc.setFontSize(8);
      doc.text(mesAnho.fechaText, 15, startY - 3);
      mesAnho.totales.dia = "Total";
      mesAnho.detalle.push(mesAnho.totales);
      doc.autoTable({
        theme: "grid",
        head: [
          headers.map((x) => {
            return x.text;
          }),
        ],
        body: mesAnho.detalle.map((x) => {
          return [
            x.dia,
            x.nro,
            x.razon,
            x.ruc,
            currencyFormat(x.gra10),
            currencyFormat(x.iva10),
            currencyFormat(x.gra5),
            currencyFormat(x.iva5),
            currencyFormat(x.exenta),
            currencyFormat(x.total),
          ];
        }),
        headStyles: {
          textColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          fontSize: 7,
        },
        bodyStyles: {
          fontSize: 7,
        },
        columnStyles: {
          4: { halign: "right" },
          5: { halign: "right" },
          6: { halign: "right" },
          7: { halign: "right" },
          8: { halign: "right" },
          9: { halign: "right", fontStyle: "bold" },
        },
        startY: startY,
        showHead: "firstPage",
        styles: { overflow: "hidden" },
        didParseCell: (data) => {
          if (
            data.row.index == mesAnho.detalle.length - 1 &&
            data.column.index == 0
          ) {
            data.row.cells[0].colSpan = 4;
            Object.entries(data.row.cells).forEach((v) => {
              v[1].styles.fontStyle = "bold";
            });
          }
        },
      });
    });
  });
  doc.save(title + current_date() + ".pdf");
};
