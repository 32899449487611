export default {
  agromap_coordinates(state, request) {
    state.agromap_coordinates = request;
  },
  agromap_coordinates_list(state, request) {
    state.agromap_coordinates_list = request;
  },
  agromap_coordinates_view(state, request) {
    state.agromap_coordinates_view = request;
  },
  agromap_tile(state, request) {
    state.agromap_tile = request;
  },
  agromap_load(state, request) {
    state.agromap_load = request;
  },
  agromap_filter_data(state, request) {
    state.agromap_filter_data = request;
  },
  integralimages(state, request) {
    state.integralimages = request;
  },
  integralload(state, request) {
    state.integralload = request;
  }
}