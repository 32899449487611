import jsPDF from "jspdf";
import "jspdf-autotable";
import { currency } from "@/services/util/number.service";
//import store from '@/store'
import { current_date, dmy, current_time } from "@/services/util/date.service";

export const exportResumenPDF = (data, head, title, sub) => {
  const datos = JSON.parse(JSON.stringify(data));
  const headers = JSON.parse(JSON.stringify(head));

  var doc = new jsPDF();
  doc.setFontSize(13);
  doc.text(title, doc.internal.pageSize.width / 2, 20, { align: "center" });
  doc.setFontSize(8);
  doc.text("Fecha: ", 15, 25);
  doc.text("Hora: ", 15, 30);
  doc.text(current_date(), 27, 25);
  doc.text(current_time(), 27, 30);

  datos.map((mn, i) => {
    mn.detalle.push({
      nombre: "Total",
      montopago: sub[mn.moneda].pagado,
      montopagar: sub[mn.moneda].pagar,
      totalpago: sub[mn.moneda].total,
    });
    doc.text(
      `Moneda: ${mn.moneda}`,
      15,
      i === 0 ? 35 : doc.lastAutoTable.finalY + 15
    );
    const line = i === 0 ? 38 : doc.lastAutoTable.finalY + 18;
    doc.line(13, line, doc.internal.pageSize.width - 12, line);
    doc.autoTable({
      theme: "striped",
      head: [
        headers.map((x) => {
          return x.text;
        }),
      ],
      body: mn.detalle.map((x) => {
        return [
          x.nombre,
          x.ruc,
          currency(x.montopago),
          currency(x.montopagar),
          //currency(x.totalpago),
        ];
      }),
      headStyles: {
        textColor: [0, 0, 0],
        fillColor: [255, 255, 255],
        fontSize: 7,
      },
      bodyStyles: {
        fontSize: 7,
      },
      columnStyles: {
        2: { halign: "right", fontStyle: "bold" },
        3: { halign: "right", fontStyle: "bold" },
        4: { halign: "right", fontStyle: "bold" },
      },
      startY: i === 0 ? 45 : doc.lastAutoTable.finalY + 20,
      showHead: "firstPage",
      styles: { overflow: "hidden" },
      didParseCell: (data) => {
        if (data.row.index == mn.detalle.length - 1 && data.column.index == 0) {
          Object.entries(data.row.cells).forEach((v) => {
            v[1].styles.fontStyle = "bold";
          });
        }
      },
    });
  });

  doc.save(title + " " + dmy(current_date()) + ".pdf");
};

export const exportGeneralPDF = (data, head, title, sub) => {
  const datos = JSON.parse(JSON.stringify(data));
  const headers = JSON.parse(JSON.stringify(head));
  var doc = new jsPDF();
  doc.setFontSize(13);
  doc.text(title, doc.internal.pageSize.width / 2, 20, { align: "center" });
  doc.setFontSize(8);
  doc.text("Fecha: ", 15, 25);
  doc.text("Hora: ", 15, 30);
  doc.text(current_date(), 27, 25);
  doc.text(current_time(), 27, 30);
  datos.map((mn, i) => {
    doc.text(
      `Moneda: ${mn.moneda}`,
      15,
      i === 0 ? 35 : doc.lastAutoTable.finalY + 15
    );
    const line = i === 0 ? 38 : doc.lastAutoTable.finalY + 18;
    doc.line(13, line, doc.internal.pageSize.width - 12, line);

    mn.detalle.map((cliente, j) => {
      doc.text(
        `${cliente.cliente} - ${cliente.ruc}`,
        15,
        j === 0 && i === 0
          ? 44
          : j === 0 && i != 0
          ? doc.lastAutoTable.finalY + 25
          : doc.lastAutoTable.finalY + 8
      );
      doc.autoTable({
        theme: "striped",
        head: [
          headers.map((x) => {
            return x.text;
          }),
        ],
        body: cliente.detalle.map((x) => {
          return [
            x.factura,
            dmy(x.femision),
            dmy(x.fvencimiento),
            currency(x.montopagar),
            currency(x.montopago),
            currency(x.totalpago),
            x.total ? currency(x.total) : "",
          ];
        }),
        headStyles: {
          textColor: [0, 0, 0],
          fillColor: [255, 255, 255],
          fontSize: 7,
        },
        bodyStyles: {
          fontSize: 7,
        },
        columnStyles: {
          3: { halign: "right", fontStyle: "bold", cellWidth: 30 },
          4: { halign: "right", fontStyle: "bold", cellWidth: 35 },
          5: { halign: "right", fontStyle: "bold", cellWidth: 35 },
        },
        startY:
          j === 0 && i === 0
            ? 45
            : j === 0 && i != 0
            ? doc.lastAutoTable.finalY + 28
            : doc.lastAutoTable.finalY + 10,
        showHead: "firstPage",
        styles: { overflow: "hidden" },
      });
    });
    doc.autoTable({
      theme: "striped",
      body: [['Total General',currency(sub[mn.moneda].pagar)]],
      bodyStyles: {
        fontSize: 7,
      },
      columnStyles: {
        0: { halign: "left", fontStyle: "bold" },
        1: { halign: "right", fontStyle: "bold"},
      },
      startY: doc.lastAutoTable.finalY + 5,
      showHead: "firstPage",
      styles: { overflow: "hidden" },
    });
  });
  doc.save(title + " " + dmy(current_date()) + ".pdf");
};
