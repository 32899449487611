<template>
  <div>
    <v-progress-linear
      color="blue"
      height="2"
      rounded
      value="100"
    ></v-progress-linear>
    <v-data-table
      :headers="headers_gasto"
      :items="items"
      show-expand
      item-key="id"
      class="elevation-1"
      dense
      item-class="caption"
      :items-per-page="10000"
      :expanded.sync="expanded"
    >
      <template v-slot:[`item.descripcion`]="{ item }">
        <div class="font-weight-black">{{ item.descripcion }}</div>
      </template>
      <template v-slot:[`item.hectarea`]="{ item }">
        <div class="text-end">{{ currencyFormat(item.hectarea) }}</div>
      </template>
      <template v-slot:[`item.plantio`]="{ item }">
        <div class="text-end">{{ currencyFormat(item.plantio) }}</div>
      </template>
      <template v-slot:[`item.fumigacion`]="{ item }">
        <div class="text-end">{{ currencyFormat(item.fumigacion) }}</div>
      </template>
      <template v-slot:[`item.abono_aplicacion`]="{ item }">
        <div class="text-end">{{ currencyFormat(item.abono_aplicacion) }}</div>
      </template>
      <template v-slot:[`item.servicio`]="{ item }">
        <div class="text-end">{{ currencyFormat(item.servicio) }}</div>
      </template>
      <template v-slot:[`item.pago`]="{ item }">
        <div class="text-end">{{ currencyFormat(item.pago) }}</div>
      </template>
      <template v-slot:[`item.alquiler`]="{ item }">
        <div class="text-end">{{ currencyFormat(item.alquiler) }}</div>
      </template>

      <template v-slot:[`item.egresohectarea`]="{ item }">
        <div class="text-end">{{ currencyFormat(item.egresohectarea) }}</div>
      </template>

      <template v-slot:[`item.totalgasto`]="{ item }">
        <div class="font-weight-black text-end ">
          {{ currencyFormat(item.totalgasto) }}
        </div>
      </template>
      <template v-slot:[`item.diferencia`]="{ item }">
        <div class="d-flex flex-row justify-end">
          <div
            :class="
              `font-weight-black mr-1 ${
                item.diferencia < 0 ? 'red--text' : 'green--text'
              }`
            "
          >
            {{ currencyFormat(item.diferencia) }}
          </div>
          <v-icon :color="item.diferencia < 0 ? 'red' : 'green'" small>{{
            item.diferencia > 0 ? "north_east" : "south_east"
          }}</v-icon>
        </div>
      </template>
      <template v-slot:[`item.ingresohectarea`]="{ item }">
        <div class="text-end">{{ currencyFormat(item.ingresohectarea) }}</div>
      </template>

      <template v-slot:[`item.kghectarea`]="{ item }">
        <div class="text-end">{{ currencyFormat(item.kghectarea) }}</div>
      </template>
      <template v-slot:[`item.produccion`]="{ item }">
        <div class="text-end">{{ currencyFormat(item.produccion) }}</div>
      </template>

      <template v-slot:[`expanded-item`]="{ item }">
        <td :colspan="headers_gasto.length">
          <v-card flat>
            <v-row dense row>
              <v-col cols="12">
                <h4 class="text-center">
                  Resumen de Cosecha - Campo: {{ item.descripcion }}
                </h4>
                <v-progress-linear
                  color="orange darken-1"
                  rounded
                  height="2"
                  value="100"
                ></v-progress-linear>
                <v-data-table
                  :headers="headers_ingreso"
                  :items="item.det"
                  hide-default-footer
                  dense
                  :items-per-page="10000"
                  class="elevation-0"
                >
                  <template v-slot:[`item.descripcion`]="{ item }">
                    <p class="font-weight-black">
                      {{ item.descripcion }}
                    </p>
                  </template>
                  <template v-slot:[`item.hectarea`]="{ item }">
                    <div class="text-end">
                      {{ currencyFormat(item.hectarea) }}
                    </div>
                  </template>
                  <template v-slot:[`item.kghectarea`]="{ item }">
                    <div class="text-end">
                      {{ currencyFormat(item.kghectarea) }}
                    </div>
                  </template>
                  <template v-slot:[`item.precio`]="{ item }">
                    <div class="text-end">
                      {{ currencyFormat(item.precio) }}
                    </div>
                  </template>
                  <template v-slot:[`item.preciohectarea`]="{ item }">
                    <div class="text-end">
                      {{ currencyFormat(item.preciohectarea) }}
                    </div>
                  </template>
                  <template v-slot:[`item.valor_alquiler`]="{ item }">
                    <div class="text-end">
                      {{ currencyFormat(item.valor_alquiler) }}
                    </div>
                  </template>
                  <template v-slot:[`item.valor`]="{ item }">
                    <div class="text-end">
                      {{ currencyFormat(item.valor) }}
                    </div>
                  </template>
                  <template v-slot:[`item.valor_vendido`]="{ item }">
                    <div class="text-end">
                      {{ currencyFormat(item.valor_vendido) }}
                    </div>
                  </template>
                  <template v-slot:[`item.total`]="{ item }">
                    <div class="font-weight-black text-end ">
                      {{ currencyFormat(item.total) }}
                    </div>
                  </template>
                </v-data-table>
                <v-divider></v-divider>
              </v-col>

              <v-col cols="12">
                <h4 class="text-center">
                  Lista de Aplicaciones - Lote: {{ item.descripcion }}
                </h4>
                <v-progress-linear
                  color="light-green accent-3"
                  rounded
                  height="2"
                  value="100"
                ></v-progress-linear>
                <v-data-table
                  :headers="headers_lista_apli"
                  :items="item.datos"
                  dense
                  hide-default-footer
                  :items-per-page="10000"
                >
                  <template v-slot:[`item.cantidad`]="{ item }">
                    <div class="text-end">
                      {{ currencyFormat(item.cantidad) }}
                    </div>
                  </template>
                  <template v-slot:[`item.hectarea`]="{ item }">
                    <div class="text-end">
                      {{ currencyFormat(item.hectarea) }}
                    </div>
                  </template>
                  <template v-slot:[`item.precio`]="{ item }">
                    <div class="text-end">
                      {{ currencyFormat(item.precio) }}
                    </div>
                  </template>
                  <template v-slot:[`item.subtotal`]="{ item }">
                    <div class="font-weight-black text-end">
                      {{ currencyFormat(item.subtotal) }}
                    </div>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card>
        </td>
      </template>
    </v-data-table>
  </div>
</template>
<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    expanded: [],
    headers_gasto: [
      {
        text: "Lote",
        align: "start",
        value: "descripcion",
        divider: true,
      },
      {
        text: "Hectarea",
        align: "start",
        value: "hectarea",
        divider: true,
      },
      {
        text: "Gasto Plantio",
        align: "start",
        value: "plantio",
        divider: true,
      },
      {
        text: "Gasto Fumigacion",
        align: "start",
        value: "fumigacion",
        divider: true,
      },

      {
        text: "Gasto Abono/ Cal y Yeso",
        align: "start",
        value: "abono_aplicacion",
        divider: true,
      },
      {
        text: "Gasto Servicio",
        align: "start",
        value: "servicio",
        divider: true,
      },
      {
        text: "Gasto Pagos",
        align: "start",
        value: "pago",
        divider: true,
      },
      {
        text: "Gasto Alquiler",
        align: "start",
        value: "alquiler",
        divider: true,
      },
      {
        text: "Gasto por Ha",
        align: "start",
        value: "egresohectarea",
        divider: true,
      },
      {
        text: "Total Gasto",
        align: "start",
        value: "totalgasto",
        divider: true,
      },
      {
        text: "Ingreso Neto",
        align: "start",
        value: "diferencia",
        divider: true,
      },
      {
        text: "Ingreso x Ha",
        align: "start",
        value: "ingresohectarea",
        divider: true,
      },
      {
        text: "Kg x Ha",
        align: "start",
        value: "kghectarea",
        divider: true,
      },
      {
        text: "Kg Producido",
        align: "start",
        value: "produccion",
        divider: true,
      },

      {
        text: "",
        value: "data-table-expand",
        align: "right",
      },
    ],
    headers_ingreso: [
      { text: "Semilla", align: "start", value: "descripcion", divider: true },
      { text: "Hectarea", align: "start", value: "hectarea", divider: true },
      {
        text: "kg x Hectarea",
        align: "start",
        value: "kghectarea",
        divider: true,
      },
      { text: "Precio", align: "start", value: "precio", divider: true },
      {
        text: "Precio x Hectarea",
        align: "start",
        value: "preciohectarea",
        divider: true,
      },
      {
        text: "Total Kg Alquiler",
        align: "start",
        value: "valor_alquiler",
        divider: true,
      },
      {
        text: "Total Kg Disponible",
        align: "start",
        value: "valor",
        divider: true,
      },
      {
        text: "Total Kg Vendido",
        align: "start",
        value: "valor_vendido",
        divider: true,
      },
      { text: "Ingreso Bruto", align: "start", value: "total" },
    ],
    headers_lista_apli: [
      { text: "Fecha", value: "fecha", divider: true },
      { text: "Operacion", align: "left", value: "operacion", divider: true },
      {
        text: "Descripcion",
        align: "left",
        value: "descripcion",
        divider: true,
      },
      {
        text: "Categoria",
        align: "left",
        value: "categoria",
        divider: true,
      },
      { text: "Cantidad", align: "start", value: "cantidad", divider: true },
      { text: "Hectarea", align: "start", value: "hectarea", divider: true },
      { text: "Precio", align: "start", value: "precio", divider: true },
      {
        text: "Unidad Medida",
        align: "start",
        value: "unidad_medida",
        divider: true,
      },
      { text: "SubTotal", align: "start", value: "subtotal" },
    ],
  }),
  methods: {
    currencyFormat(value) {
      if (!value) return 0;
      return new Intl.NumberFormat({ maximumSignificantDigits: 3 }).format(
        value.toFixed(2)
      );
    },
    getGastoHeader() {
      let head_gasto = JSON.parse(JSON.stringify(this.headers_gasto));
      head_gasto.pop();
      return [head_gasto.map((x) => x.text)];
    },
    getListaAplicacionHeader() {
      return [this.headers_lista_apli.map((x) => x.text)];
    },
    getIngresoHeader() {
      return [this.headers_ingreso.map((x) => x.text)];
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-data-table__wrapper > table > tbody > tr > td {
  padding-left: 0.3rem;
  padding-right: 0.3rem;
}
::v-deep .v-data-table__wrapper > table > thead > tr > th {
  padding-left: 0.3rem;
  padding-right: 0.3rem;
}
</style>
