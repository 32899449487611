<template>
  <div class="mx-2">
    <v-toolbar flat dense>
      <v-toolbar-title>Ajustes del Funcionario</v-toolbar-title>
    </v-toolbar>
    <v-form ref="form">
      <v-row dense>
        <v-col cols="12">
          <v-checkbox
            dense
            v-model="data.marcar_aplicacion"
            label="Permitir asistencia movil"
          ></v-checkbox>
        </v-col>
        <v-col cols="12" md="6" v-if="data.marcar_aplicacion">
          <c-text-field label="Usuario" v-model="login.username"></c-text-field>
        </v-col>
        <v-col cols="12" md="6" v-if="data.marcar_aplicacion">
          <c-text-field
            label="Contraseña"
            type="password"
            v-model="login.password"
          ></c-text-field>
        </v-col>
      </v-row>
    </v-form>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="deep-purple accent-4" dark @click="guardar()" rounded
        >GUARDAR</v-btn
      >
    </v-card-actions>
    <c-overlay :value="isFuncionarioLoading"></c-overlay>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data: () => ({
    data: {
      marcar_aplicacion: false,
    },
    login: {
      username: "",
      password: "",
    },
  }),
  props: {
    props: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters("funcionario", ["isFuncionarioLoading"]),
  },
  methods: {
    ...mapActions("funcionario", ["setFuncionarioUpdate", "fetchFuncionario"]),

    async guardar() {
      const data = {
        ...this.props,
        marcar_aplicacion: this.data.marcar_aplicacion,
        funcionario_usuario: [
          {
            idcontacto: this.props.idcontacto.idcontacto,
            idusuario: {
              id: this.props.funcionario_usuario.length > 0 ? this.props.funcionario_usuario[0].idusuario.id : 0,
              ...this.login
            },
          },
        ],
      };
      if (!this.$refs.form.validate()) return null;
      const response = await this.setFuncionarioUpdate({
        data,
        id: this.props.idcontacto.idcontacto,
      });
      if (response.success) {
        this.fetchFuncionario({ active: true });
        this.$refs.form.resetValidation();
      }
    },
  },
  mounted() {
    this.data.marcar_aplicacion = this.props.marcar_aplicacion;
    if(this.props.funcionario_usuario.length > 0) this.login = JSON.parse(JSON.stringify(this.props.funcionario_usuario[0].idusuario))
  },
};
</script>
