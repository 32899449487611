import {
    money
} from '@/services/moneyconvert'
import {
    currency
} from '@/services/util/number.service'
import {
    browser
} from '@/services/browser'
import jsPDF from "jspdf"
import 'jspdf-autotable'
const recibo = {
    async generar(val, totalcobrar, fecha, ruc, detalle) {
        let body1 = [];
        let body2 = [];
        detalle.forEach((x, i) => {
            if (i < 10) {
                body1.push([x.factura, x.fecha, currency(x.monto)]);
            } else {
                body2.push([x.factura, x.fecha, currency(x.monto)]);
            }
        });
        let data = {
            config_hoja: {
                left: 10,
                top: 10,
                width: 560,
                height: 930,
                orientacion: 'p',
                unidad: 'pt',
                formato: [578.267716535, 935.433070866]
            },
            next_y: 400,
            cabecera_font: 'bold',


            recibo: {
                size: 11,
                recibo: 'RECIBO DE DINERO',
                x: 448,
                y: 30
            },

            rucnegocio: {
                size: 8,
                rucnegocio: ruc,
                x: 472,
                y: 44
            },
            nrorecibo: {
                size: 11,
                nrorecibo: val.nroreciboventa,
                x: 470,
                y: 60
            },

            moneda: {
                size: 10,
                moneda: val.idmoneda.abreviatura,
                x: 430,
                y: 90
            },
            titulolugarfecha: {
                size: 9,
                font: "bold",
                titulolugarfecha: 'Fecha: ',
                x: 430,
                y: 110
            },
            lugarfecha: {
                size: 9,
                font: "normal",
                lugarfecha: fecha,
                x: 460,
                y: 110
            },
            detalle_size: 9,
            detalle_y: 180,
            detalle_interlinea: 15,

            titulofactura: {
                size: 10,
                titulofactura: 'Factura',
                y: 185,
                x: 45
            },
            titulonumerofactura: {
                size: 10,
                titulonumerofactura: 'Numero',
                y: 185,
                x: 105
            },
            titulomontofactura: {
                size: 10,
                titulomontofactura: 'Monto',
                y: 185,
                x: 190
            },
            fechafactura: {
                x: 45
            },
            numerofactura: {
                x: 105
            },
            montofactura: {
                x: 190
            },
            titulocliente: {
                size: 9,
                font: "bold",
                titulocliente: 'Cliente:',
                x: 20,
                y: 130
            },
            cliente: {
                size: 9,
                font: "normal",
                cliente: val.idcontacto.idcontacto.desccontacto,
                x: 55,
                y: 130
            },
            tituloruccliente: {
                size: 9,
                font: "bold",
                tituloruccliente: 'Ruc:',
                x: 430,
                y: 130
            },
            ruccliente: {
                size: 9,
                font: "normal",
                ruccliente: val.idcontacto.idcontacto.ruccontacto + '-' + val.idcontacto.idcontacto.docucontactos,
                x: 455,
                y: 130
            },
            titulototalletras: {
                size: 9,
                font: "bold",
                titulototalletras: 'Total a pagar ' + val.idmoneda.abreviatura + ':',
                x: 20,
                y: 145
            },
            totalletras: {
                size: 7,
                font: "normal",
                totalletras: money.letter(totalcobrar, val.idmoneda),
                x: 110,
                y: 145
            },
            tituloconcepto: {
                size: 9,
                font: "bold",
                tituloconcepto: 'En concepto de:',
                x: 20,
                y: 160
            },
            concepto: {
                size: 9,
                font: "normal",
                concepto: 'Pago de Factura',
                x: 100,
                y: 160
            },
            total: {
                size: 10,
                total: currency(totalcobrar),
                x: 465,
                y: 88
            },
            titulofirma: {
                size: 10,
                titulofirma: 'Firma y Sello',
                x: 465,
                y: 370
            },
            firma: {
                size: 10,
                firma: '-----------------------------',
                x: 445,
                y: 350
            },
            index: 2
        }
        let lineas = {
            borde: {
                horizontal: 10,
                vertical: 15,
                anchura: 560,
                altura: 380,
                rx: 0,
                ry: 0,
                style: "S"
            },
            moneda: {
                horizontal: 460,
                vertical: 75,
                anchura: 95,
                altura: 20,
                rx: 2,
                ry: 2,
                style: "S"
            }
        }
        var doc = new jsPDF(data.config_hoja.orientacion, data.config_hoja.unidad, data.config_hoja.formato);
        var x = 0;
        var fila = 0;
        doc.roundedRect(lineas.borde.horizontal, lineas.borde.vertical, lineas.borde.anchura, lineas.borde.altura, lineas.borde.rx, lineas.borde.ry, lineas.borde.style);
        doc.roundedRect(lineas.moneda.horizontal, lineas.moneda.vertical, lineas.moneda.anchura, lineas.moneda.altura, lineas.moneda.rx, lineas.moneda.ry, lineas.moneda.style);
        doc.roundedRect(lineas.borde.horizontal, 415, lineas.borde.anchura, lineas.borde.altura, lineas.borde.rx, lineas.borde.ry, lineas.borde.style);
        doc.roundedRect(lineas.moneda.horizontal, 475, lineas.moneda.anchura, lineas.moneda.altura, lineas.moneda.rx, lineas.moneda.ry, lineas.moneda.style);


        for (var i = 1; i <= data.index; i++) {
            //doc.setFontType(data.cabecera_font);
            doc.setFontSize(data.recibo.size);
            doc.text(data.recibo.x, data.recibo.y + x, data.recibo.recibo);
            doc.setFontSize(data.rucnegocio.size);
            doc.text(data.rucnegocio.x, data.rucnegocio.y + x, data.rucnegocio.rucnegocio);
            doc.setFontSize(data.nrorecibo.size);
            doc.text(data.nrorecibo.x, data.nrorecibo.y + x, data.nrorecibo.nrorecibo);
            doc.setFontSize(data.moneda.size);
            doc.text(data.moneda.x, data.moneda.y + x, data.moneda.moneda);

            //doc.setFontType(data.titulolugarfecha.font);
            doc.setFontSize(data.titulolugarfecha.size);
            doc.text(data.titulolugarfecha.x, data.titulolugarfecha.y + x, data.titulolugarfecha.titulolugarfecha);

            //doc.setFontType(data.lugarfecha.font);
            doc.setFontSize(data.lugarfecha.size);
            doc.text(data.lugarfecha.x, data.lugarfecha.y + x, data.lugarfecha.lugarfecha);

            fila = data.detalle_y + x;
            doc.setFontSize(data.detalle_size);
            //doc.setFontType('normal');

            doc.autoTable({
                startY: fila,
                head: [
                    ['Factura', 'Numero', 'Monto']
                ],
                body: body1,
                showHead: 'firstPage',
                tableWidth: 200,
                columnStyles: { 2: { halign: 'right' } },
                theme: "grid",
                margin: { right: 200 },
                styles: { cellPadding: 1, fontSize: 9 },
                didParseCell: function (data) {
                    if (data.section === "head") {
                        data.cell.styles.fillColor = [255, 255, 255];
                        data.cell.styles.textColor = 2
                    }
                }
            });

            if (body2[0] != undefined) {
                doc.autoTable({
                    startY: fila,
                    head: [
                        ['Factura', 'Numero', 'Monto']
                    ],
                    showHead: 'firstPage',
                    body: body2,
                    tableWidth: 200,
                    columnStyles: { 2: { halign: 'right' } },
                    theme: "grid",
                    margin: { left: 340 },
                    styles: { cellPadding: 1, fontSize: 9 },
                    didParseCell: function (data) {
                        if (data.section === "head") {
                            data.cell.styles.fillColor = [255, 255, 255];
                            data.cell.styles.textColor = 2
                        }
                    }
                });
            }

            doc.setFontSize(data.total.size);
            //doc.setFontType("bold");
            doc.text(data.total.x, data.total.y + x, data.total.total);

            doc.setFontSize(data.titulocliente.size);
            //doc.setFontType(data.titulocliente.font);
            doc.text(data.titulocliente.x, data.titulocliente.y + x, data.titulocliente.titulocliente);
            doc.setFontSize(data.cliente.size);
            //doc.setFontType(data.cliente.font);
            doc.text(data.cliente.x, data.cliente.y + x, data.cliente.cliente);
            doc.setFontSize(data.tituloruccliente.size);
            //doc.setFontType(data.tituloruccliente.font);
            doc.text(data.tituloruccliente.x, data.tituloruccliente.y + x, data.tituloruccliente.tituloruccliente);
            doc.setFontSize(data.ruccliente.size);
            //doc.setFontType(data.ruccliente.font);
            doc.text(data.ruccliente.x, data.ruccliente.y + x, data.ruccliente.ruccliente);

            doc.setFontSize(data.titulototalletras.size);
            //doc.setFontType(data.titulototalletras.font);
            doc.text(data.titulototalletras.x, data.titulototalletras.y + x, data.titulototalletras.titulototalletras);

            doc.setFontSize(data.totalletras.size);
            //doc.setFontType(data.totalletras.font);
            doc.text(data.totalletras.x, data.totalletras.y + x, data.totalletras.totalletras);

            doc.setFontSize(data.tituloconcepto.size);
            //doc.setFontType(data.tituloconcepto.font);
            doc.text(data.tituloconcepto.x, data.tituloconcepto.y + x, data.tituloconcepto.tituloconcepto);

            doc.setFontSize(data.concepto.size);
            //doc.setFontType(data.concepto.font);
            doc.text(data.concepto.x, data.concepto.y + x, data.concepto.concepto);

            doc.setFontSize(data.titulofirma.size);
            //doc.setFontType(data.titulofirma.font);
            doc.text(data.titulofirma.x, data.titulofirma.y + x, data.titulofirma.titulofirma);

            doc.setFontSize(data.firma.size);
            //doc.setFontType(data.firma.font);
            doc.text(data.firma.x, data.firma.y + x, data.firma.firma);

            x = x + data.next_y;

        }

        doc.autoPrint();
        var attrib = `id="idiframe" style="border: 0; position:fixed; top:0; left:0; right:0; bottom:0; width:100%; height:100%"`;
        var iframe = "<iframe " + attrib + " src='" + doc.output('bloburl') + "'></iframe>"
        if (browser.name() === 'Firefox') {
            x = window.open();
        }
        if (browser.name() === 'Chrome') {
            x = window.open(`about: whatever`);
        } else {
            x = window.open();
        }
        x.document.open();
        x.document.write(iframe);
        x.document.close();

        doc = new jsPDF(data.config_hoja.orientacion, data.config_hoja.unidad, data.config_hoja.formato);
    }
}

export {
    recibo
}