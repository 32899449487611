<template>
    <div>
        <Help v-model="help" :content="todos"/>
        <Crud v-if="crud.add" v-model="crud.add" :datos="crud.datos" @datos="crud.datos = $event" />
        <Crud v-if="crud.view"  v-model="crud.view" :props="crud.datos" editable @datos="crud.datos = $event"/>
        <Delete v-if="crud.delete" v-model="crud.delete" :props="crud.datos" @datos="crud.datos = $event"/>
        <v-alert type="error" v-if="permission_alert">
            Error! Este usuario no tiene permiso en esta ventana
        </v-alert>
        <v-toolbar flat dense>
            <v-spacer></v-spacer>
            <c-text-table-search v-if="permission.can_view" v-model="search" class="mr-1"></c-text-table-search>
            <v-toolbar-items>
                <c-btn-table-help @click="help = true"></c-btn-table-help>
                <c-btn-table-add class="ml-1" @click="crud.add = true" v-if="permission.can_add"></c-btn-table-add>
                <c-btn-table-reload class="ml-1" @click="fetchCuenta()"></c-btn-table-reload>
            </v-toolbar-items>
        </v-toolbar>
        <v-data-table :headers="headers" :items="getCuenta" :loading="isLoadingCuenta" :search="search"
            v-if="permission.can_view">
            <template v-slot:[`item.accion`]="props">
                <c-btn-table-edit @click="update_item(props.item)" />
                <c-btn-table-delete @click="delete_item(props.item)" />
            </template>
        </v-data-table>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { FINANZA } from "@/constants/forms";
import {
    PUEDE_AGREGAR,
    PUEDE_EDITAR,
    PUEDE_ELIMINAR,
    PUEDE_LISTAR,
} from "@/constants/permissions";
import Crud from "./Crud";
import Delete from "./Delete";
import Help from "../Help.vue";
import { CUENTAS } from "@/constants/finanzas";
export default {
    components: {
        Crud,
        Delete,
        Help
    },
    data() {
        return {
            todos: null,
            help: false,
            title: 'Cuenta',
            search: "",
            headers: [
                { text: "Codigo", align: "left", value: "idfinanza_cuenta" },
                { text: "Descripcion", align: "left", value: "nombre" },
                { text: "Tipo", align: "left", value: "idfinanza_tipo.nombre" },
                { text: "Accion", align: "right", value: "accion", sortable: false },
            ],
            permission: {
                can_add: false,
                can_view: false,
                can_update: false,
                can_delete: false,
            },
            crud: {
                delete: false,
                add: false,
                view: false,
                datos: null,
            },
        }
    },
    computed: {
        ...mapGetters("finanza", ["getCuenta", "isLoadingCuenta"]),
        permission_alert() {
            return mensaje.permission(this.permission);
        },
    },
    created() {
        this.$store.commit("SET_LAYOUT", "principal-layout");
        this.todos = CUENTAS
        const permission = getPermission(FINANZA).permiso;
        this.permission = {
            can_add: permission[PUEDE_AGREGAR],
            can_view: permission[PUEDE_LISTAR],
            can_update: permission[PUEDE_EDITAR],
            can_delete: permission[PUEDE_ELIMINAR],
        };
        this.fetchCuenta();
    },
    methods: {
        ...mapActions("finanza", ["fetchCuenta"]),
        update_item(data) {
            this.crud.datos = JSON.parse(JSON.stringify(data));
            this.crud.view = true;
        },
        delete_item(item) {
            this.crud.datos = JSON.parse(JSON.stringify(item));
            this.crud.delete = true;
        },
    }
}
</script>