import dbUpdate from '@/db/dbUpdate';
export default {
    data() {
        return {
            refreshing: false,
            registration: null,
            updateExists: false,
        }
    },
    created() {
        document.addEventListener('swUpdated', this.updateAvailable, {once: true})
        navigator.serviceWorker.addEventListener('controllerchange', () => {
            if (this.refreshing) return
            this.refreshing = true
            dbUpdate.transaction("rw", dbUpdate.update, () => {
                dbUpdate.update.clear()
                dbUpdate.update.put({id: 1, update: true})
            })
            window.location.reload()
        })
    },
    methods: {
        updateAvailable(event) {
            this.registration = event.detail
            this.updateExists = true
        },
        refreshApp() {
            this.updateExists = false
            if (!this.registration || !this.registration.waiting) return 
            this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
        }
    }
}