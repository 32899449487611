var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('c-alert-permission',{model:{value:(_vm.permission),callback:function ($$v) {_vm.permission=$$v},expression:"permission"}}),_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$route.name))]),_c('widgets-Favorite',{attrs:{"ruta":_vm.$route.path,"formulario":_vm.$route.name}}),_c('v-divider',{staticClass:"mx-2",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),(_vm.permission.can_view)?_c('c-text-table-search',{model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e(),_c('v-toolbar-items',[(_vm.permission.can_add)?_c('c-btn-table-add',{staticClass:"mr-1",on:{"click":function($event){return _vm.create()}}}):_vm._e(),_c('c-btn-table-reload',{on:{"click":function($event){return _vm.fetchReajuste()}}})],1)],1),(_vm.permission.can_view)?_c('v-data-table',{attrs:{"loading":_vm.isLoading,"headers":_vm.headers,"items":_vm.getReajuste,"search":_vm.search},scopedSlots:_vm._u([{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.formDate(item.fecha)))])]}},{key:"item.positivo",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.positivo ? "Agregado" : "Restado")+" ")])]}},{key:"item.valor",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm.toCurrency(item.valor))+" ")])]}},{key:"item.accion",fn:function(ref){
var item = ref.item;
return [_c('c-btn-table-view',{staticClass:"mr-2",on:{"click":function($event){return _vm.rowselect(item)}}}),_c('c-btn-table-delete',{on:{"click":function($event){return _vm.meliminar(item)}}})]}}],null,true)}):_vm._e()],1),(_vm.crud.add)?_c('Crud',{attrs:{"readonly":_vm.crud.readonly,"datos":_vm.crud.datos},on:{"datos":function($event){_vm.crud.datos = $event}},model:{value:(_vm.crud.add),callback:function ($$v) {_vm.$set(_vm.crud, "add", $$v)},expression:"crud.add"}}):_vm._e(),(_vm.crud.delete)?_c('Delete',{attrs:{"props":_vm.crud.datos},on:{"datos":function($event){_vm.crud.datos = $event}},model:{value:(_vm.crud.delete),callback:function ($$v) {_vm.$set(_vm.crud, "delete", $$v)},expression:"crud.delete"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }