var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"dense":""}},[_vm._l((_vm.card),function(item,i){return _c('v-col',{key:i,attrs:{"cols":"12","sm":""}},[(item.commodity)?_c('v-card',{staticClass:"rounded-xl"},[_c('c-overlay',{attrs:{"absolute":"","value":_vm.Vget_commodities_load}}),_c('v-card-title',{staticClass:" mb-n2"},[_c('span',{staticClass:"title font-weight-medium"},[_vm._v(_vm._s(item.commodity))]),_c('v-spacer'),_c('span',{staticClass:"title font-weight-medium"},[_vm._v(" - ")]),_c('v-spacer'),_c('span',{staticClass:"title font-weight-light mr-2"},[_vm._v(_vm._s(item.server)+" - "+_vm._s(item.month))]),_c('v-icon',{attrs:{"color":_vm.colorCard(item.pounts)}},[_vm._v(_vm._s(_vm.iconCard(item.pounts)))])],1),_c('div',{staticClass:"d-flex justify-space-between mx-4"},[_c('div',{staticClass:"text-h5 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.toCurrency(item.price.bsh))+" ")]),_c('div',{class:("text-subtitle-1 font-weight-medium " + (_vm.colorText(
            item.pounts
          )))},[_vm._v(" "+_vm._s(item.pounts)+" ")]),_c('div',{class:("text-subtitle-1 font-weight-medium " + (_vm.colorText(
            item.pounts
          )))},[_vm._v(" ("+_vm._s(item.porcent)+") ")])]),_c('div',{staticClass:"d-flex justify-space-between mx-4 mt-3"},[_c('div',{staticClass:"caption font-weight-regular"},[_vm._v("Precio por Tn")]),_c('div',{staticClass:"text-subtitle-2 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.toCurrency(item.price.tn))+" ")])]),_c('div',{staticClass:"d-flex justify-space-between mx-4"},[_c('div',{staticClass:"caption font-weight-regular"},[_vm._v("Precio por Kg")]),_c('div',{staticClass:"text-subtitle-2 font-weight-medium"},[_vm._v(" "+_vm._s(_vm.toCurrency(item.price.kg))+" ")])]),_c('v-card-actions',{staticClass:"mx-1"},[_c('span',{staticClass:"caption"},[_vm._v("(Precios x Tn)")]),_c('v-divider')],1),_c('div',{staticClass:"d-flex justify-space-around mx-2 mt-0 mb-n2"},[_c('div',{staticClass:"text-caption"},[_vm._v(" Cierre: "),_c('strong',[_vm._v(" "+_vm._s(_vm.toCurrency(item.secondary_prices_tn.prev_close))+" ")])]),_c('div',{staticClass:"text-caption"},[_vm._v(" Apertura: "),_c('strong',[_vm._v(" "+_vm._s(_vm.toCurrency(item.secondary_prices_tn.price_open))+" ")])]),_c('div',{staticClass:"text-caption"},[_vm._v(" Rango: "),_c('strong',[_vm._v(" "+_vm._s(_vm.toCurrency(item.secondary_prices_tn.prev_range))+" - "+_vm._s(_vm.toCurrency(item.secondary_prices_tn.next_range)))])])]),_c('v-card-actions',{staticClass:"my-b3"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-0 mr-0",attrs:{"color":"primary","text":"","fab":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("settings")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.server),function(sv,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.setServerMonth(item, sv.server)}}},[_c('v-list-item-title',[_vm._v(_vm._s(sv.server))])],1)}),1)],1),_c('v-divider'),_c('div',{staticClass:"text-end",staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(item.time)+" ")]),_c('v-btn',{attrs:{"color":"primary","fab":"","small":"","text":""},on:{"click":function($event){return _vm.Vact_commodities_data()}}},[_c('v-icon',[_vm._v("autorenew")])],1),_c('v-btn',{staticClass:"ml-0 mr-0",attrs:{"color":_vm.colorCard(item.pounts),"small":"","fab":"","text":""},on:{"click":function($event){return _vm.showFutureList(item)}}},[_c('v-icon',[_vm._v("list_alt")])],1)],1)],1):_vm._e()],1)}),_c('FuturosList',{attrs:{"data":_vm.futuros.list,"title":_vm.futuros.title,"current-month":_vm.futuros.month},on:{"change":function($event){return _vm.showFutureCard()}},model:{value:(_vm.futuros.dialog),callback:function ($$v) {_vm.$set(_vm.futuros, "dialog", $$v)},expression:"futuros.dialog"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }