<template>
  <div>
    <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="200" offset-x>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="ml-1" v-bind="attrs" v-on="on" fab small text color="primary" outlined>
          <v-icon>settings</v-icon>
        </v-btn>
      </template>
      <v-card max-width="300">
        <c-overlay absolute :value="Vget_dashboard_load"></c-overlay>
        <v-form ref="dash">
          <v-list>
            <v-list-item>
              <v-list-item-avatar>
                <v-icon large>settings</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{operacion ? 'Guardar' : 'Modificar'}} Consulta</v-list-item-title>
                <v-list-item-subtitle>Visualizar en el Dashboard</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list dense>
            <v-list-item>
              <vue-text-field label="Titulo" v-model="consulta.descripcion"></vue-text-field>
            </v-list-item>
            <v-list-item class="ma-0">
              <v-checkbox v-model="consulta.vista.general" label="Resumen General"></v-checkbox>
            </v-list-item>
            <v-list-item class="ma-0">
              <v-checkbox v-model="consulta.vista.hectarea" label="Resumen por Hectarea"></v-checkbox>
            </v-list-item>
            <v-list-item class="ma-0">
              <v-checkbox v-model="consulta.vista.gasto" label="Resumen de Gastos"></v-checkbox>
            </v-list-item>
            <v-list-item class="ma-0">
              <v-checkbox v-model="consulta.vista.categoria" label="Resumen de Categorias"></v-checkbox>
            </v-list-item>
            <v-list-item class="ma-0">
              <v-checkbox v-model="consulta.vista.servicio" label="Resumen de Servicios"></v-checkbox>
            </v-list-item>
            <v-list-item class="ma-0">
              <v-checkbox v-model="consulta.vista.zona" label="Hectareas por Zona"></v-checkbox>
            </v-list-item>
            <v-list-item class="ma-0">
              <v-checkbox v-model="consulta.vista.top" label="Top Produccion Kg x Ha"></v-checkbox>
            </v-list-item>
          </v-list>

          <v-card-actions>
            <v-btn color="red" text @click="menu = false">
              <v-icon>clear</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="operacion ? guardarConsulta() : modificarConsulta()">
              <v-icon>done</v-icon>
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { mensaje } from "@/services/notify.service";
export default {
  props: {
    operacion: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Object,
      required: true,
    },
    url: String,
  },
  data() {
    return {
      menu: false,
      consulta: {
        iddashboard: 0,
        descripcion: "",
        vista: {
          general: false,
          hectarea: false,
          gasto: false,
          zona: false,
          top: false,
          servicio: false,
          categoria: false,
        },
        url: "",
        tipo: 'Agro'
      },
    };
  },
  mounted() {
    this.consulta = JSON.parse(JSON.stringify(this.value));
  },
  computed: {
    ...mapGetters("dashboard", ["Vget_dashboard_load"]),
  },
  methods: {
    ...mapActions("dashboard", ["Vact_dashboard_menu_save","Vact_dashboard_menu_update"]),
    validarConsulta() {
      let valid = false;
      Object.keys(this.consulta.vista).forEach((item) => {
        if (this.consulta.vista[item]) valid = true;
      });

      return valid;
    },
    async guardarConsulta() {
      if (!this.$refs.dash.validate() || !this.validarConsulta()) return null;
      this.consulta.url = this.url;
      const a = await this.Vact_dashboard_menu_save(this.consulta);
      if (a.estado == true) {
        this.$notify(a.info);
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
    },
    async modificarConsulta() {
      const id = this.value.iddashboard;
      let data = JSON.parse(JSON.stringify(this.consulta));
      const a = await this.Vact_dashboard_menu_update({ id, data });
      if (a.estado == true) {
        this.$notify(a.info);
      } else {
        a.info.map(x => {
          this.$notify(mensaje.error(x));
        });
      }
    },
  },
};
</script>

<style>
</style>