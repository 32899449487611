<template>
    <c-dialog-confirmation
        :value="value"
        @cancel="close()"
        @done="save()"
        :loading="isLoadingCategoria"
    >Desea eliminar la categoria
    <strong>{{ props.nombre }}</strong>?
    </c-dialog-confirmation>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
    props: {
        value: Boolean,
        props: Object,
    },
    computed: {
        ...mapGetters("finanza", ["isLoadingCategoria"])
    },
    watch: {
        $route() {
            return this.close();
        }
    },
    methods: {
        ...mapActions("finanza", ["setCategoriaDelete", "fetchCategoria"]),
        async save() {
            const id = this.props.idfinanza_categoria;
            const response = await this.setCategoriaDelete(id);
            if (!response.success) return null;
            this.close();
        },
        close() {
            this.$emit("datos", null);
            this.$emit("input", false);
            this.fetchCategoria();
        }
    }
}
</script>