
export default {


  Vget_gymasistencia_data: (state) => {
    return state.gymasistencia_data
  },
  Vget_gymasistencia_data_by: (state) => {
    return state.gymasistencia_data_by
  },
  Vget_gymasistencia_data_hoy: (state) => {
    return state.gymasistencia_data_hoy
  },
  Vget_gymasistencia_update: (state) => {
    return state.gymasistencia_update
  },
  Vget_gymasistencia_load: (state) => {
    return state.gymasistencia_load
  },
  Vget_gymasistencia_save_load: (state) => {
    return state.gymasistencia_save_load
  },
}
