<template>
  <div>
    <v-dialog :value="value" max-width="700">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Detalle Establecimiento</v-toolbar-title>
        </v-toolbar>
        <v-container>
          <vue-text-field readonly label="Descripcion" v-model="data.descestablecimiento" />
          <vue-text-field readonly label="Establecimiento" v-model="data.establecimiento" />
        </v-container>
        <v-card-actions>
          <v-btn color="red" text @click="close()">
            <v-icon dark>close</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    datos:{
      type: [Object, Array],
      default: null
    }
  },
  data() {
    return {
      header: "Detalle",
      data: {},
      default: {},
      id: "",
      operacion: true,
    };
  },
  watch: {
    $route(to, from) {
      if (from.path === "/establecimiento") return this.close();
    },
  },
  created() {
    this.update(this.datos);
  },
  methods: {
    close() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$emit('input', false);
      this.$emit('datos', null);
      this.operacion = true;
    },
    update(data) {
      if (data === null) return null;
      this.data = JSON.parse(JSON.stringify(data));
    },
  },
};
</script>