<template>
  <div>
    <v-dialog
      :value="value"
      persistent
      max-width="900"
      @keydown.esc="cancelar()"
    >
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Crear Nota de Credito Venta</v-toolbar-title>
        </v-toolbar>
        <v-container>
          <v-form ref="form1">
            <v-row dense>
              <v-col cols="12" md="2" sm="3">
                <c-text-date
                  :rules="[(v) => !!v || 'Campo Obligatorio']"
                  label="Fecha"
                  v-model="data.fecha"
                  dense
                  outlined
                  readonly
                  @keyup.native.enter="nextFocus(1, data.fecha)"
                ></c-text-date>
              </v-col>
              <v-col cols="12" md="3" sm="3">
                <vue-text-field
                  label="Numero Nota Credito"
                  readonly
                  v-mask="mask_factura"
                  :value="numeroNotaCredito"
                  @keyup.native.enter="nextFocus(2, data.numnotacreditoventa)"
                ></vue-text-field>
              </v-col>
              <v-col cols="12" md="4" sm="6">
                <vue-autocomplete-contacto-cliente
                  label="Cliente"
                  id="input3"
                  :create="false"
                  v-model="data.idcontacto.idcontacto"
                  @keyup.native.enter="nextFocus(3, data.idcontacto.idcontacto)"
                />
              </v-col>
              <v-col cols="12" md="2" sm="3">
                <vue-text-field
                  v-mask="mask_factura"
                  id="input4"
                  label="Factura que modifica"
                  v-model="factura"
                  @keyup.native.enter="nextFocus(4, factura)"
                ></vue-text-field>
              </v-col>
              <v-spacer></v-spacer>
              <v-col cols="1">
                <v-btn
                  color="red"
                  id="input5"
                  text
                  outlined
                  @click="obtenerCompra()"
                >
                  <v-icon>cloud_download</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <v-form ref="formDet" v-show="response.length > 0">
            <v-row dense>
              <v-col cols="12">{{ detalle.iditem.iditem }}</v-col>
              <v-col cols="12" sm="4" md="5">
                <v-autocomplete
                  dense
                  v-model="detalle.iditem"
                  height="12px"
                  outlined
                  id="input6"
                  :items="items"
                  label="Item"
                  item-text="iditem.descitem"
                  return-object
                  :rules="[(v) => !!v || 'Seleccione un Item']"
                  required
                  autocomplete="off"
                  :no-data-text="`No existe ninguna concidencia`"
                  small-chips
                  @change="asignarPrecio()"
                  @keyup.enter="nextFocus(6, detalle.iditem.iditem)"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          >No existe concidencia</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="2" sm="3">
                <vue-text-currency
                  v-model="detalle.precdetventa"
                  label="Precio"
                  :rules="true"
                  id="input7"
                  @keyup.native.enter="nextFocus(7, detalle.precdetventa)"
                ></vue-text-currency>
              </v-col>
              <v-col cols="8" md="2" sm="3">
                <vue-text-currency
                  v-model="detalle.cantdetventa"
                  label="Cantidad"
                  id="input8"
                  :rules="true"
                  @keyup.native.enter="nextFocus(8, detalle.cantdetventa)"
                ></vue-text-currency>
              </v-col>
              <v-col cols="1" md="2"></v-col>
              <v-col cols="1" sm="1">
                <v-btn
                  outlined
                  color="primary"
                  id="input9"
                  fab
                  small
                  @click="addTable()"
                >
                  <v-icon>add</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="data.notacreditoventa"
                  :loading="overlay"
                  :items-per-page="1000"
                  hide-default-footer
                >
                  <template v-slot:[`item.actions`]="props">
                    <v-icon
                      small
                      class="mr-1"
                      @click="editarDetalle(props.item)"
                      >edit</v-icon
                    >
                    <v-icon
                      small
                      class="mr-1"
                      @click="eliminarDetalle(props.item)"
                      >delete</v-icon
                    >
                  </template>
                </v-data-table>
              </v-col>
              <v-col md="4"></v-col>

              <v-col cols="12" md="2">
                <vue-text-field
                  readonly
                  :rules="false"
                  label="Iva 5"
                  :value="iva5"
                ></vue-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <vue-text-field
                  readonly
                  :rules="false"
                  label="Iva 10"
                  :value="iva10"
                ></vue-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <vue-text-field
                  readonly
                  :rules="false"
                  label="Iva Total"
                  :value="ivaTotal"
                ></vue-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <vue-text-field
                  readonly
                  :rules="false"
                  label="Total"
                  :value="total"
                ></vue-text-field>
              </v-col>
            </v-row>
          </v-form>
          <v-alert dense type="info" v-show="response.length === 0">
            No se encuentra ninguna factura de
            <strong>Venta</strong> con estos parametros
          </v-alert>
          <v-form ref="form2">
            <v-row dense>
              <v-col cols="12">
                <vue-text-field
                  label="Descripcion de la operacion"
                  v-model="data.comentario"
                ></vue-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn color="red" text @click="cancelar()">
            <v-icon>clear</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="guardar()">
            <v-icon>done</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
      <c-overlay :value="Vget_notacreditoventa_save_load"></c-overlay>
    </v-dialog>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import { current_date } from "@/services/util/date.service";
import { utilidad } from "@/services/compra_utilidad.service";
import { focus } from "@/services/focus.service";
import { db } from "@/services/db.service";
import { mensaje } from "@/services/notify.service";
import { mapActions, mapGetters } from "vuex";
import { factura } from "@/services/notacredito.service";
import { browser } from "@/services/browser";
import Worker from "worker-loader!@/services/workers/notacredito.worker.js";
var worker = new Worker();
export default {
  directives: {
    mask,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    puntoventa: {
      type: Array,
      default: null,
    },
  },
  computed: {
    ...mapGetters("notacreditoventa", ["Vget_notacreditoventa_save_load"]),
    numeroNotaCredito() {
      const est = this.configNota.establecimiento;
      const pun = this.configNota.punto;
      const fac = utilidad.completar_nrof(
        this.data.numnotacreditoventa.toString()
      );
      return `${est}-${pun}-${fac}`;
    },
    iva5() {
      let iva5 = 0;
      this.data.notacreditoventa.forEach((iva) => {
        iva5 = iva5 + Number(iva.iva5);
      });

      return (iva5 / 21).toFixed(2);
    },
    iva10() {
      let iva10 = 0;
      this.data.notacreditoventa.forEach((iva) => {
        iva10 = iva10 + Number(iva.iva10);
      });
      return (iva10 / 11).toFixed(2);
    },
    ivaTotal() {
      return (Number(this.iva5) + Number(this.iva10)).toFixed(2);
    },
    total() {
      let total = 0;
      this.data.notacreditoventa.forEach((x) => {
        total = total + Number(x.iva5) + Number(x.iva10) + Number(x.exenta);
      });
      return total.toFixed(2);
    },
  },
  watch: {
    $route(to, from) {
      if (from.path === "/notaventa") return this.cancelar();
    },
  },
  created() {
    this.configNota = this.puntoventa[0];
    this.data.idpuntoventa.idpuntoventa = this.puntoventa[1].idpuntoventa;
    this.data.numnotacreditoventa = this.configNota.actual;
  },
  mounted() {
    setTimeout(() => this.nextFocus(2, "-"), 100);
    this.iniciarworker();
  },
  methods: {
    ...mapActions("notacreditoventa", [
      "Vact_notacreditoventa_save",
      "Vact_notacreditoventa_data",
    ]),
    async iniciarworker() {
      worker.onmessage = (e) => {
        switch (e.data.type) {
          case "init":
            console.log(e.data.data);
            break;
          case "processing":
            var x = "";
            if (browser.name() === "Firefox") {
              x = window.open();
            }
            if (browser.name() === "Chrome") {
              x = window.open(`about: whatever`);
            } else {
              x = window.open();
            }
            x.document.open();
            x.document.write(e.data.data);
            x.document.close();
            worker.postMessage(operation);
            break;
          default:
            console.log(e.data);
            break;
        }
      };
      let operation = {
        type: "init",
        orientacion: this.puntoventa[0].parametros.config_hoja.orientacion,
        unidad: this.puntoventa[0].parametros.config_hoja.unidad,
        formato: this.puntoventa[0].parametros.config_hoja.formato,
        plantilla: await factura.getDataUri(this.puntoventa[0].plantilla[0]),
        config_hoja: this.puntoventa[0].parametros.config_hoja,
        parametros: this.puntoventa[0].parametros,
      };
      worker.postMessage(operation);
    },
    generarfactura() {
      let operacion = {
        type: "processing",
        datos: this.data,
        config: JSON.parse(JSON.stringify(this.puntoventa[0])),
        factura: this.numeroNotaCredito,
        iva5: this.iva5,
        iva10: this.iva10,
        ivatotal: this.ivaTotal,
        ventatotal: this.total,
        moneda: "",
      };
      worker.postMessage(operacion);
    },
    asignarPrecio() {
      if (this.detalle.iditem != undefined) {
        this.detalle.precdetventa = this.detalle.iditem.precitem;
      }
    },
    isRepeat() {
      let repeat = false;
      this.data.notacreditoventa.forEach((x) => {
        if (x.iditem.iditem === this.detalle.iditem.iditem)
          return (repeat = true);
      });
      return repeat;
    },
    addTable() {
      if (!this.$refs.formDet.validate() || this.isRepeat())
        return this.$notify(mensaje.error("Verifique los datos ingresados"));
      this.addDetalle(this.detalle.iditem);
      this.detalle = JSON.parse(JSON.stringify(this.detalle_default));
      this.$refs.formDet.resetValidation();
      this.nextFocus(5, "-");
    },
    cancelar() {
      this.$emit("input", false);
      this.Vact_notacreditoventa_data();
    },
    nextFocus(id, data) {
      focus.nextid(id, data);
    },
    addDetalle(item) {
      item.cantidad = item.cantdetventa;
      item.precio = item.precdetventa;
      item.iva10 = "";
      item.iva5 = "";
      item.exenta = "";
      switch (item.iditem.idcategoriaitem.idiva.idiva) {
        case 3:
          item.iva10 = Number(item.cantdetventa) * Number(item.precdetventa);
          break;
        case 2:
          item.iva5 = Number(item.cantdetventa) * Number(item.precdetventa);
          break;
        case 1:
          item.exenta = Number(item.cantdetventa) * Number(item.precdetventa);
          break;
        default:
          break;
      }
      this.data.notacreditoventa.push(item);
    },

    async obtenerCompra() {
      this.overlay = true;
      const cliente = this.data.idcontacto.idcontacto.idcontacto;
      if (cliente != undefined || this.factura != "") {
        try {
          this.response = await db.get(
            `/apiventa/?cliente=${cliente}&factura=${this.factura}`
          );

          if (this.response.length === 0)
            throw new Error("No existe ninguna factura");
          this.items = [];
          this.data.notacreditoventa = [];
          this.response[0].venta.forEach((item) => {
            item.iditem.iddeposito = item.iddeposito;
            this.items.push(item);
            this.addDetalle(item);
          });
          this.data.idventa = this.response[0];

          this.data.idtimb.idtimbrado = this.configNota.id;
          this.data.idtimbrado = this.response[0].idtimbrado;
          setTimeout(() => this.nextFocus(5, "-"), 100);
          this.$refs.formDet.resetValidation();
        } catch (error) {
          this.$notify(mensaje.error(error.message));
        }
      }
      this.overlay = false;
    },
    editarDetalle(val) {
      const index = this.data.notacreditoventa.indexOf(val);
      this.data.notacreditoventa.splice(index, 1);
      this.detalle = JSON.parse(JSON.stringify(val));
      this.detalle.iditem = val;
      this.isEdit = true;
    },
    eliminarDetalle(val) {
      const index = this.data.notacreditoventa.indexOf(val);
      this.data.notacreditoventa.splice(index, 1);
    },
    async guardar() {
      if (!this.$refs.form1.validate() || !this.$refs.form2.validate())
        return null;
      const a = await this.Vact_notacreditoventa_save(this.data);
      if (!a.estado) {
        return a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.$notify(a.info);
      const recnum = this.data.numnotacreditoventa;
      this.generarfactura();
      this.data = JSON.parse(JSON.stringify(this.default));
      this.detalle = JSON.parse(JSON.stringify(this.detalle_default));
      this.data.numnotacreditoventa = Number(recnum) + 1;
      this.$refs.formDet.resetValidation();
      this.$refs.form1.resetValidation();
      this.$refs.form2.resetValidation();
      setTimeout(() => this.nextFocus(0, "-"), 100);
    },
  },
  data() {
    return {
      mask_factura: "###-###-#######",
      configNota: null,
      factura: "",
      items: [],
      overlay: false,
      response: [],
      headers: [
        {
          text: "Deposito",
          sortable: false,
          value: "iddeposito.desdeposito",
        },
        { text: "Cantidad", sortable: false, value: "cantidad" },
        { text: "Item", sortable: false, value: "iditem.descitem" },
        { text: "Precio Unitario", sortable: false, value: "precio" },
        { text: "Exenta", sortable: false, value: "exenta" },
        { text: "Iva 5%", sortable: false, value: "iva5" },
        { text: "Iva 10%", sortable: false, value: "iva10" },
        { text: "Accion", sortable: false, value: "actions" },
      ],
      data: {
        idnotacreditoventa: 0,
        anulnotacreditoventa: false,
        comentario: "",
        fecha: current_date(),
        idventa: {
          idventa: "",
        },
        idtimbrado: {
          idtimbrado: "",
        },
        idtimb: {
          idtimbrado: "",
        },
        iddeposito: {
          iddeposito: "",
        },
        idcontacto: {
          idcontacto: "",
        },
        idpuntoventa: {
          idpuntoventa: "",
        },
        numnotacreditoventa: "",
        notacreditoventa: [],
      },

      default: {
        idnotacreditoventa: 0,
        anulnotacreditoventa: false,
        comentario: "",
        fecha: current_date(),
        idventa: {
          idventa: "",
        },
        idtimbrado: {
          idtimbrado: "",
        },
        idtimb: {
          idtimbrado: "",
        },
        iddeposito: {
          iddeposito: "",
        },
        idcontacto: {
          idcontacto: "",
        },
        idpuntoventa: {
          idpuntoventa: "",
        },
        numnotacreditoventa: "",
        notacreditoventa: [],
      },
      detalle: {
        iditem: "",
        precdetventa: "",
        cantdetventa: "",
      },
      detalle_default: {
        iditem: "",
        precdetventa: "",
        cantdetventa: "",
      },
    };
  },
};
</script>

<style>
</style>