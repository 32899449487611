
export default {
  Vget_cuentabanco_data: (state) => {
    return state.cuentabanco_data
  },
  Vget_cuentabanco_load: (state) => {
    return state.cuentabanco_load
  },
  Vget_cuentabanco_save_load: (state) => {
    return state.cuentabanco_save_load
  },
}
