<template>
  <v-dialog persistent max-width="700" :value="value">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-form ref="form" @submit.prevent="">
          <v-row dense>
            <v-col cols="12">
              <c-text-field
                label="Descripcion"
                autocomplete="off"
                :rules="rules"
                outlined
                dense
                ref="maquinaria1"
                v-model="data.descripcion"
                placeholder="Ingrese una descripcion"
                @keyup.native.enter="next(1, data.descripcion)"
              ></c-text-field>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                dense
                v-model="data.idmaquinaria_tipo"
                ref="maquinaria2"
                v-bind:items="getTipomaquinaria"
                label="Tipo Maquinaria"
                item-text="descripcion"
                item-value="idmaquinaria_tipo"
                return-object
                :rules="[
                  (v) =>
                    !!v.idmaquinaria_tipo || 'Seleccione un Tipo de Maquinaria',
                ]"
                required
                outlined
                autocomplete="off"
                @keyup.native.enter="next(2, data.idmaquinaria_tipo.idmaquinaria_tipo)"
                :no-data-text="`No existe ninguna concidencia`"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-autocomplete
                dense
                v-model="data.idmaquinaria_fabricante"
                ref="maquinaria3"
                v-bind:items="getFabricante"
                label="Fabricante"
                item-text="descripcion"
                item-value="idmaquinaria_fabricante"
                return-object
                :rules="[
                  (v) =>
                    !!v.idmaquinaria_fabricante || 'Seleccione un Fabricante',
                ]"
                required
                outlined
                autocomplete="off"
                @keyup.native.enter="
                  next(3, data.idmaquinaria_fabricante.idmaquinaria_fabricante)
                "
                :no-data-text="`No existe ninguna concidencia`"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <c-text-field
                label="Chapa"
                autocomplete="off"
                :rules="rules"
                outlined
                dense
                ref="maquinaria4"
                v-model="data.chapa"
                placeholder="Ingrese la chapa"
                @keyup.native.enter="next(4, data.chapa)"
              ></c-text-field>
            </v-col>
            <v-col cols="6">
              <c-text-field
                label="Chasis"
                autocomplete="off"
                :rules="rules"
                outlined
                dense
                ref="maquinaria5"
                v-model="data.vin"
                placeholder="Ingrese el Nro de Chasis"
                @keyup.native.enter="next(5, data.vin)"
              ></c-text-field>
            </v-col>
            <v-col cols="6">
              <c-text-field
                label="Año"
                autocomplete="off"
                :rules="rules"
                outlined
                dense
                ref="maquinaria6"
                v-model="data.anho"
                placeholder="Ingrese el Año"
                @keyup.native.enter="next(6, data.anho)"
              ></c-text-field>
            </v-col>
            <v-col cols="6">
              <c-text-date
                autocomplete="off"
                :rules="rules"
                outlined
                dense
                ref="maquinaria7"
                v-model="data.adquisicion"
                placeholder="Ingrese la fecha de adquisicion"
                @keyup.native.enter="next(7, data.adquisicion)"
              ></c-text-date>
            </v-col>
            <v-col cols="12">
              <v-file-input
                label="Imagen"
                v-model="imagen"
                ref="maquinaria8"
                prepend-icon="image"
                outlined
                dense
                @keyup.native.enter="next(8, imagen)"
              ></v-file-input>
            </v-col>
            <v-col cols="12">
              <v-textarea
                label="Observacion"
                outlined
                ref="maquinaria9"
                v-model="data.observacion"
                @keyup.native.enter="next(9, data.observacion)"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-divider></v-divider>
      <v-card-actions>
        <c-btn-crud-close @click="cancel()"></c-btn-crud-close>
        <v-spacer></v-spacer>
        <c-btn-crud-done ref="maquinaria10" @click="save()"></c-btn-crud-done>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    props: {
      type: Object,
    },
    "sync-input": {
      type: [String, Number],
      default: null,
    },
  },
  mounted() {
    if (this.editable) this.data = JSON.parse(JSON.stringify(this.props));
    setTimeout(() => this.next(0, "-"), 20);
    if (this.syncInput != null) this.data.descripcion = this.syncInput;
    this.sync = this.syncInput;
  },
  created() {
    this.fetchTipomaquinaria();
    this.fetchFabricante();
  },
  computed: {
    ...mapGetters("fabricante", ["isLoading", "getFabricante"]),
    ...mapGetters("tipo_maquinaria", ["isLoading", "getTipomaquinaria"]),
    title() {
      return this.editable ? "Modificar Maquina" : "Registrar Maquina";
    },
  },
  methods: {
    ...mapActions("tipo_maquinaria", ["fetchTipomaquinaria"]),
    ...mapActions("fabricante", ["fetchFabricante"]),
    ...mapActions("maquinaria", ["setMaquinaria", "setMaquinariaUpdate", "fetchMaquinaria"]),
    next(id, data) {
      if (data != "")
        try {
          this.$refs[`maquinaria${id + 1}`].focus();
        } catch (error) {
          this.$refs[`maquinaria${id + 1}`].$el.focus();
        }
    },
    cancel() {
      this.$emit("input", false);
    },
    obtener_form() {
      let form_data = new FormData();
      this.data.imagen = this.imagen
      if (!this.editable || (this.data.imagen != null && typeof(this.data.imagen) != 'string')) {
        form_data.append("imagen", this.data.imagen)
      }
      form_data.append("idmaquinaria", this.data.idmaquinaria)
      form_data.append("idmaquinaria_tipo", JSON.stringify(this.data.idmaquinaria_tipo))
      form_data.append("idmaquinaria_fabricante", JSON.stringify(this.data.idmaquinaria_fabricante))
      form_data.append("descripcion", this.data.descripcion)
      form_data.append("chapa", this.data.chapa)
      form_data.append("vin", this.data.vin)
      form_data.append("anho", this.data.anho)
      form_data.append("adquisicion", this.data.adquisicion)
      form_data.append("observacion", this.data.observacion)
      return form_data
    },
    async save() {
      if (!this.$refs.form.validate()) return null;
      const response = this.editable
        ? await this.setMaquinariaUpdate({
            data: this.obtener_form(),
            id: this.data.idmaquinaria,
          })
        : await this.setMaquinaria(this.obtener_form());
      if (response.success) {
        if (this.sync != null) {
          this.cancel();
          this.fetchMaquinaria();
          return this.$emit("sync-input", response.data.idmaquinaria);
        }
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();
        this.fetchMaquinaria();
        this.next(0, "-");
        if (this.editable) this.cancel();
      }
    },
  },
  data: () => ({
    rules: [(v) => !!v || "Este campo es obligatorio"],
    data: {
      idmaquinaria: 0,
      idmaquinaria_tipo: {
        idmaquinaria_tipo: "",
      },
      idmaquinaria_fabricante: {
        idmaquinaria_fabricante: "",
      },
      descripcion: "",
      chapa: "",
      vin: "",
      anho: "",
      adquisicion: "",
      imagen: null,
      observacion: "",
    },
    default: {
      idmaquinaria: 0,
      idmaquinaria_tipo: {
        idmaquinaria_tipo: "",
      },
      idmaquinaria_fabricante: {
        idmaquinaria_fabricante: "",
      },
      descripcion: "",
      chapa: "",
      vin: "",
      anho: "",
      adquisicion: "",
      imagen: null,
      observacion: "",
    },
    imagen: null,
    sync: null,
  }),
};
</script>