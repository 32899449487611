import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { dmy, current_date } from "@/services/util/date.service";
import store from '@/store'
const currency = (value) =>{
    return new Intl.NumberFormat({ maximumSignificantDigits: 3 }).format(value)
}
const Presupuesto = (preventa, headers) => {
    const fecha = dmy(preventa.fecha_entrega);
    var doc = new jsPDF();
    let pageWidth = doc.internal.pageSize.width;
    let fechaWidth = doc.getTextWidth(fecha) + 5;
    doc.setFontSize(16);
    store.getters['empresa/Vget_empresa_active'].forEach(empresa => {
        if (empresa.est_emp_tam_usu)
            return doc.text(empresa.idempresa.descempresa, 13, 20);
    })
    doc.setFontSize(11);
    doc.text(fecha, pageWidth - fechaWidth, 20);
    doc.setFontSize(12);
    doc.text('Presupuesto para:', 13, 35);
    doc.setLineWidth(1);
    doc.line(13, 37, doc.internal.pageSize.width - 12, 37);
    doc.setFontSize(11);
    doc.text('Razon Social:', 13, 45);
    doc.text(preventa.idcontacto.desccontacto, 39, 45);
    doc.text('Ruc:', 13, 52);
    doc.text(`${preventa.idcontacto.ruccontacto}-${preventa.idcontacto.docucontactos}`, 23, 52);
    doc.setLineWidth(0.4);
    doc.line(13, 55, doc.internal.pageSize.width - 12, 55);

    let head = [headers.map(x => {
        return x.text
    })];
    let total = 0;
    let body = preventa.pedidoventadet.map(x => {
        const subtotal = (Number(x.precio) * Number(x.cantidad)).toFixed(2);
        total = Number(total) + Number(subtotal);
        return [
            x.iditem.iditem,
            x.iditem.descitem,
            currency(x.precio),
            currency(x.cantidad),
            currency(subtotal)
        ]
    })
    doc.autoTable({
        head: head,
        body: body,
        startY: 60,
        theme: 'grid',

        columnStyles: { 4: { halign: 'right', fontStyle: 'bold' } },
        headStyles: {
            textColor: [0, 0, 0],
            fillColor: [255, 255, 255],
            fontSize: 8,
        },
    });

    doc.autoTable({
        body: [['Total', currency(total)]],
        startY: doc.previousAutoTable.finalY + 2,
        theme: 'grid',
        styles: { lineWidth: 0.5 },
        columnStyles: { 1: { halign: 'right', fontStyle: 'bold', cellWidth: 30 } },
        headStyles: {
            textColor: [0, 0, 0],
            fillColor: [255, 255, 255],
            fontSize: 8,
        },
    });
    let text = doc.splitTextToSize(preventa.observacion, pageWidth - 35, {})
    doc.text(text, 14, doc.previousAutoTable.finalY + 8) 

    doc.save('Presupuesto' + ' ' + current_date() + '.pdf');
}
export default Presupuesto;
