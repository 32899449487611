const colorpalette = ['#ffcdd2', '#e1bee7', '#b39ddb', '#9fa8da', '#90caf9', '#81d4fa', '#80deea', '#80cbc4', '#a5d6a7', '#c5e1a5', '#e6ee9c', '#00e676', '#c6ff00', '#fff176', '#ffb74d', '#bdbdbd', '#f4511e', '#b0bec5'];
//const canvas = document.createElement('canvas');
const grafico = {
    avanzado(data, dark, tipografico) {
        let maximo = 0;
        data.forEach(x => { if (x[tipografico] > maximo) maximo = x[tipografico] })
        maximo = maximo + ((maximo * 20) / 100);
        return {

            tooltip: {
                trigger: 'item',
                formatter: (params=>{
                    const show =''+params.name+': '+params.value.toLocaleString();+''
                    return show
                })
            },
            legend: {
                type: 'scroll',
                orient: 'vertical',
                right: '10%',
                bottom: '10%',
                top: '0%',
                icon: 'circle',
                pageIconSize: 10,
                pageIconColor: dark ? '#ccc' : '#000',
                pageTextStyle:{
                    color: dark ? '#ccc' : '#000'
                },
                textStyle: {
                    fontSize: 9,
                    color: dark ? '#ccc' : '#000'
                },
                data: data.map((key, index) => {
                    const [n1,n2] = key.nombre.split(' ');
                    return {
                        name: `${n1} ${n2 || ''}`,
                        value: key[tipografico],
                        itemStyle: {
                            color: colorpalette[index]
                        }
                    }
                }),

                selected: data.map((key, index) => {
                    const [n1,n2] = key.nombre.split(' ');
                    return {
                        name:  `${n1} ${n2 || ''}`,
                        value: key[tipografico],
                        itemStyle: {
                            color: colorpalette[index]
                        }
                    }
                }),
            },
            series: [
                {
                    name: 'Cliente',
                    type: 'pie',
                    radius: '40%',
                    center: ['25%', '40%'],
                    
                    label: {
                        show: false
                    },
                    
                    data: data.map((key, index) => {
                        const [n1,n2] = key.nombre.split(' ');
                        return {
                            name: `${n1} ${n2 || ''}`,
                            value: key[tipografico],
                            itemStyle: {
                                color: colorpalette[index]
                            }
                        }
                    }),
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        }
    }
};
export default grafico