var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.headers,"items":_vm.items,"items-per-page":99999},scopedSlots:_vm._u([{key:"item.cantdetventa",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.currencyFormat(item.cantdetventa)))])]}},{key:"item.precdetventa",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.currencyFormat(item.precdetventa)))])]}},{key:"item.iditem.subtotal",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-black"},[_vm._v(" "+_vm._s(_vm.currencyFormat(item.cantdetventa * item.precdetventa))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.$emit('rowdelete',item)}}},[_vm._v("delete")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }