export default [
  {
    path: '/banco',
    name: 'Banco',
    component: () => import( /* webpackChunkName: "Banco" */ '@/views/Apertura/Banco/Banco.vue'),
  },
  {
    path: '/puntoexp',
    name: 'Punto Expedicion',
    component: () => import( /* webpackChunkName: "Punto Expedicion" */ '@/views/Apertura/Puntoexp/Puntoexp.vue'),
  },
  {
    path: '/tipocuenta',
    name: 'Tipo Cuenta',
    component: () => import( /* webpackChunkName: "Tipo Cuenta" */ '@/views/Apertura/Tipocuenta/Tipocuenta.vue'),
  },
  {
    path: '/cuentabanco',
    name: 'Cuenta Banco',
    component: () => import( /* webpackChunkName: "Cuenta" */ '@/views/Apertura/Cuentabanco/Cuentabanco.vue'),
  },
  {
    path: '/caja',
    name: 'Caja',
    component: () => import( /* webpackChunkName: "Caja" */ '@/views/Apertura/Caja/Caja.vue'),
  }
]