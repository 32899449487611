<template>
  <div>
    <div v-if="abrircausarechazo">
      <Enfermedad-Causarechazo-create />
    </div>
    <v-dialog
      v-model="value"
      max-width="700px"
      persistent
      @keydown.esc="cancelar()"
    >
      <v-card>
        <v-form ref="form" lazy-validation>
          <v-toolbar flat dense>
            <v-toolbar-title>{{
              isEdit ? "Modificar Rechazo" : "Registrar Rechazo"
            }}</v-toolbar-title>
          </v-toolbar>
          <v-container>
            <v-row row dense>
              <v-col cols="12">
                <AutocompleteAnimal
                  action="fetchInseminada"
                  getter="getInseminada"
                  id="input1"
                  no-create
                  v-model="data.idanimal.idanimal"
                  :animal="animal"
                  @keyup.native.enter="nextfocus(1, data.idanimal.idanimal)"
                />
              </v-col>
              <v-col cols="12">
                <vue-autocomplete-generic
                  label="Seleccione una Causa"
                  id="input2"
                  action="causarechazo/Vact_causarechazo_data"
                  getter="causarechazo/Vget_causarechazo_data"
                  load="causarechazo/Vget_causarechazo_load"
                  vuex="causarechazo/causarechazo_create"
                  pk="idcausarechazo"
                  title="desccausarechazo"
                  permission="Causa Rechazo"
                  :create="true"
                  v-model="data.idcausarechazo.idcausarechazo"
                  @keyup.native.enter="
                    nextfocus(2, data.idcausarechazo.idcausarechazo)
                  "
                />
              </v-col>
              <v-col cols="12">
                <c-text-date
                  label="Fecha de registro"
                  id="input3"
                  dense
                  outlined
                  v-model="data.fecrrechazo"
                  @keyup.native.enter="nextfocus(3, data.fecrrechazo)"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  dense
                  type="text"
                  id="input4"
                  v-model="data.comnrechazo"
                  label="Descripcion"
                  outlined
                  autocomplete="off"
                  @keyup.enter="nextfocus(4, data.comnrechazo)"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>

          <v-card-actions>
            <c-btn-crud-close @click="cancelar()"></c-btn-crud-close>
            <v-spacer></v-spacer>
            <c-btn-crud-done id="aceptar" @click="guardar()"></c-btn-crud-done>
          </v-card-actions>
        </v-form>
      </v-card>
      <c-overlay :value="isLoading"></c-overlay>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { focus } from "@/services/focus.service";
import { current_date } from "@/services/util/date.service";

export default {
  components: {
    AutocompleteAnimal: () =>
      import("@/views/Animal/Animal/AutocompleteAnimal"),
  },
  props: {
    value: Boolean,
    props: Object,
  },
  data: () => ({
    isEdit: false,
    data: {
      idrechazo: 0,
      fecrrechazo: current_date(),
      fechrechazo: "2010-10-10",
      idanimal: {
        idanimal: 0,
      },
      idcausarechazo: {
        idcausarechazo: 0,
      },
      comnrechazo: "",
    },
    default: {
      idrechazo: 0,
      fecrrechazo: current_date(),
      fechrechazo: "2010-10-10",
      idanimal: {
        idanimal: 0,
      },
      idcausarechazo: {
        idcausarechazo: 0,
      },
      comnrechazo: "",
    },
    animal: null,
  }),
  watch: {
    $route() {
      return this.cancelar();
    },
  },
  computed: {
    ...mapGetters("rechazo",['isLoading']),
    ...mapGetters("causarechazo", {
      abrircausarechazo: "causarechazo_create",
    }),

  },
  created() {
    this.nuevo(this.props);
  },
  mounted() {
    setTimeout(() => document.getElementById("input1").focus(), 500);
  },
  methods: {
    ...mapActions("rechazo", [
      "fetchRechazo",
      "setRechazo",
      "setRechazoUpdate",
    ]),
    ...mapActions("animal", ["fetchInseminada"]),

    nextfocus(id, data) {
      focus.nextid(id, data);
    },
    cancelar() {
      this.data = JSON.parse(JSON.stringify(this.default));
      this.$emit("input", false);
      this.fetchRechazo();
      this.isEdit = false;
    },
    async guardar() {
      if (!this.$refs.form.validate()) return null;
      if (this.data.comnrechazo === "") this.data.comnrechazo = "";
      const id = this.data.idrechazo;
      const response = this.isEdit
        ? await this.setRechazoUpdate({ id, data: this.data })
        : await this.setRechazo(this.data);
      if (response.success) {
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$refs.form.resetValidation();
        setTimeout(() => document.getElementById("input1").focus(), 500);
        if (this.isEdit) this.cancelar();
        this.fetchInseminada();
      }
    },
    nuevo(val) {
      if (val !== null) {
        this.animal = val.idanimal;
        this.data = JSON.parse(JSON.stringify(val));
        this.isEdit = true;
      }
    },
  },
};
</script>
