export default {
  turno_create(state, request) {
    state.turno_create = request;
  }, 
  turno_update(state, request) {
    state.turno_update = request;
  },
  turno_reload(state, request) {
    state.turno_reload = request;
  }, 
  turno_show(state, request) {
    state.turno_show = request;
  }, 
}