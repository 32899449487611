export default {
  proveedor_create(state, request) {
    state.proveedor_create = request;
  }, 
  proveedor_update(state, request) {
    state.proveedor_update = request;
  },   
  proveedor_reload(state, request) {
    state.proveedor_reload = request;
  },   
  proveedor_show(state, request) {
    state.proveedor_show = request;
  },  
}