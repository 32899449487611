export default [
      {
        path: '/turno',
        name: 'Turno',
        component: () => import( /* webpackChunkName: "Compra" */ '@/views/Produccion/Turno/Turno.vue'),
      },
      {
        path: '/produccion',
        name: 'Produccion',
        component: () => import( /* webpackChunkName: "Compra" */ '@/views/Produccion/Produccion/Produccion.vue'),
      },
      {
        path: '/racion',
        name: 'Racion',
        component: () => import( /* webpackChunkName: "Racion" */ '@/views/Produccion/Racion/Racion.vue'),
      },
      {
        path: '/alimentacion',
        name: 'Alimentacion',
        component: () => import( /* webpackChunkName: "Alimentacion" */ '@/views/Produccion/Alimentacion/Alimentacion.vue'),
      },
      {
        path: '/reporteproduccionanimal',
        component: () => import( /* webpackChunkName: "Racion" */ '@/views/Produccion/Reporte/Root.vue'),
        children: [
          {
            path: "reporteleche",
            component: () => import( /* webpackChunkName: "Racion" */ '@/views/Produccion/Reporte/ProduccionLeche.vue' ),
          },
          {
            path: "reporteanimal",
            component: () => import( /* webpackChunkName: "Racion" */ '@/views/Produccion/Reporte/ProduccionAnimal.vue' ),
          }
        ]
      }
    ]