<template>
  <div>
    <InputAutocomplete
      :label="label"
      item-value="iditem"
      item-text="descitem"
      :return-object=returnObject
      :id="id"
      dense
      :outlined="outlined"
      :multiple="multiple"
      :value="value"
      :rounded="rounded"
      :rules="rules"
      :filled="filled"
      :items="getItem"
      :loading="Vget_item_load"
      @input="$emit('input', $event)"
      @sync-input="formulario.search = $event"
      @create="formulario.crud = true"
      :permission="permission"
    />
    <Crud
      v-if="formulario.crud"
      v-model="formulario.crud"
      :sync-input="formulario.search"
      @sync-input="syncInputHandler($event)"
    />
  </div>
</template>

<script>
import InputAutocomplete from "@/ncomponents/InputAutocomplete";
import Crud from "./Create";
import { mapActions, mapGetters } from "vuex";
import { ITEM } from "@/constants/forms";
import { PUEDE_AGREGAR } from "@/constants/permissions";
import { getPermission } from "@/services/storage/permissionStorage.service";
import store from "@/store";
export default {
  props: {
    id: String,
    value: [String, Object, Number, Array],
    returnObject: Boolean,
    multiple: Boolean,
    rounded: Boolean,
    filled: Boolean,
    rules: Array,
    outlined:{
      type: Boolean,
      default: true
    },
    label:{
      type: String,
      default: 'Seleccione el Item'
    },
    action: {
      fype: String,
      default: 'Vact_item_vivo'
    },
    getter:{
      type: String,
      default: 'Vget_item_vivo'
    }
  },
  components: {
    InputAutocomplete,
    Crud,
  },
  data: () => ({
    formulario: {
      crud: false,
      search: "",
    },
  }),
  computed: {
    ...mapGetters("item", ["Vget_item_load"]),
    getItem(){
      return store.getters["item/" + this.getter];
    },
    permission(){
      return getPermission(ITEM).permiso[PUEDE_AGREGAR]
    }
  },
  mounted() {
    if(this.action) store.dispatch("item/" + this.action);
  },
  methods: {
    ...mapActions("item", ["Vact_item_vivo"]),
    syncInputHandler(event) {
      if (this.returnObject) this.$emit("input", event);
      if (!this.returnObject) this.$emit("input", event.iditem);
      this.$emit("nextfocus");
      store.dispatch("item/" + this.action);
    },
  },
};
</script>

<style></style>
