<template>
  <div>
    <div v-if="crearPedidoVenta">
      <Venta-PedidoVenta-Create
        v-model="crearPedidoVenta"
        :editar="editar"
        @editar="editar = $event"
        @buscar-pedido="buscarDesdeAgregar()"

      />
    </div>
    <div v-if="showPedidoVenta">
      <Venta-PedidoVenta-Show
        v-model="showPedidoVenta"
        :editar="editar"
        @editar="editar = $event"
      />
    </div>
    <v-card>
      <v-alert type="error" v-if="permission_alert"
        >Error!!! Este usuario no tiene permisos en esta ventana</v-alert
      >
      <v-toolbar flat dense>
        <v-toolbar-title>{{ titulo }}</v-toolbar-title>
        <widgets-Favorite ruta="/preventa" formulario="Preventa" />
        <v-divider class="mx-2" inset vertical></v-divider>

        <v-text-field
          v-model="search"
          :input.sync="search"
          append-icon="search"
          label="Busqueda"
          single-line
          hide-details
          v-if="permission.can_view"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-col cols="6">
          <v-tabs v-model="tab" right>
            <v-tab
              v-for="item in items"
              :key="item.title"
              :href="'#tab-' + item.title"
              @click="obtener(item.title)"
              >{{ item.title }}</v-tab
            >
            <v-menu v-if="more.length" bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  class="align-self-center mr-4"
                  v-bind="attrs"
                  v-on="on"
                >
                  Mas
                  <v-icon right>arrow_drop_down</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  v-for="item in more"
                  :key="item.title"
                  @click="addItem(item), obtener(item.title)"
                  >{{ item.title }}</v-list-item
                >
              </v-list>
            </v-menu>
          </v-tabs>
        </v-col>
        <v-toolbar-items>
          <c-btn-table-add
            @click="crearPedidoVenta = true"
            v-if="permission.can_add"
          ></c-btn-table-add>
        </v-toolbar-items>
      </v-toolbar>
      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-Pendiente">
          <v-data-table
            :loading="Vget_pedidoventa_load"
            :headers="headers"
            :items="Vget_pedidoventa_data"
            :search="search"
            class="elevation-1"
            v-if="permission.can_view"
          >
            <template v-slot:[`item.accion`]="props">
              <v-icon @click="mshow(props.item)" v-if="permission.can_view"
                >visibility</v-icon
              >
              <v-icon
                class="ms-2"
                @click="mupdate(props.item)"
                v-if="permission.can_update"
                >edit</v-icon
              >
              <v-icon
                class="ms-2"
                @click="meliminar(props.item)"
                v-if="permission.can_delete"
                >delete</v-icon
              >
            </template>
            <template v-slot:no-data>
              <v-alert :value="true" color="info" icon="info" outlined
                >No existe ningun registro</v-alert
              >
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item value="tab-Presupuesto">
          <v-data-table
            :loading="Vget_pedidoventa_load"
            :headers="headers"
            :items="Vget_pedidoventa_presupuesto"
            :search="search"
            class="elevation-1"
            v-if="permission.can_view"
          >
            <template v-slot:[`item.accion`]="props">
              <v-icon @click="mshow(props.item)" v-if="permission.can_view"
                >visibility</v-icon
              >
              <v-icon
                class="ms-2"
                @click="mupdate(props.item)"
                v-if="permission.can_update"
                >edit</v-icon
              >
              <v-icon
                class="ms-2"
                @click="meliminar(props.item)"
                v-if="permission.can_delete"
                >delete</v-icon
              >
            </template>
            <template v-slot:no-data>
              <v-alert :value="true" color="info" icon="info" outlined
                >No existe ningun registro</v-alert
              >
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item value="tab-Finalizado">
          <v-data-table
            :loading="Vget_pedidoventa_load"
            :headers="headers"
            :items="Vget_pedidoventa_finalizado"
            :search="search"
            class="elevation-1"
            v-if="permission.can_view"
          >
            <template v-slot:[`item.accion`]="props">
              <v-icon @click="mshow(props.item)" v-if="permission.can_view"
                >visibility</v-icon
              >
            </template>
            <template v-slot:no-data>
              <v-alert :value="true" color="info" icon="info" outlined
                >No existe ningun registro</v-alert
              >
            </template>
          </v-data-table>
        </v-tab-item>
        <v-tab-item value="tab-Reporte">
          <v-form ref="form">
            <v-toolbar flat>
              <v-col cols="3">
                <v-autocomplete
                  :items="Vget_contacto_data_cliente"
                  item-text="desccontacto"
                  item-value="idcontacto"
                  autocomplete="off"
                  clearable
                  label="Cliente"
                  v-model="parametro.idcontacto"
                >
                  <template v-slot:no-data>
                    <v-list-item dense>
                      <v-list-item-content>
                        <v-list-item-title
                          >No se encontro ningun Cliente</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="3">
                <v-autocomplete
                  :items="tipoOperacion"
                  item-text="title"
                  item-value="title"
                  autocomplete="off"
                  label="Seleccione Tipo"
                  v-model="parametro.tiporeporte"
                >
                  <template v-slot:no-data>
                    <v-list-item dense>
                      <v-list-item-content>
                        <v-list-item-title>No existe</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="2">
                <c-text-date
                  autocomplete="off"
                  :rules="validar"
                  label="Fecha Inicio"
                  v-model="parametro.fecha_inicio"
                ></c-text-date>
              </v-col>
              <v-col cols="2">
                <c-text-date
                  autocomplete="off"
                  :rules="validar"
                  label="Fecha Final"
                  v-model="parametro.fecha_fin"
                ></c-text-date>
              </v-col>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="descargarReporte()">
                <v-icon>cloud_download</v-icon>
              </v-btn>
              <v-btn text color="red" @click="generarPdf()">
                <v-icon>get_app</v-icon>
              </v-btn>
            </v-toolbar>
          </v-form>
          <v-data-table
            :loading="Vget_pedidoventa_load"
            :headers="headers_reporte"
            :items="Vget_pedidoventa_reporte"
            :search="search"
            class="elevation-1"
            v-if="permission.can_view"
          >
            <template v-slot:no-data>
              <v-alert :value="true" color="info" icon="info" outlined
                >No existe ningun registro</v-alert
              >
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-dialog v-model="dialogoeliminar" persistent max-width="350">
      <v-card>
        <v-card-title>
          <v-icon left>info</v-icon>
          <span class="title font-weight-light">Ventana de Confirmacion</span>
        </v-card-title>
        <v-card-text
          >Desea eliminar el Pedido Nro
          {{ objeto.idpedido_venta }}?</v-card-text
        >
        <v-card-actions>
          <v-btn color="red" text @click="eliminarcancelar()">
            <v-icon>clear</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="eliminarguardar()">
            <v-icon>done</v-icon>
          </v-btn>
        </v-card-actions>
        <v-overlay :value="overlay">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import Reporte from "@/views/Venta/PedidoVenta/Reporte";
import { mensaje } from "@/services/notify.service";
import { substract_days, current_date } from "@/services/util/date.service";
import { VENTA } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";

export default {
  data() {
    return {
      awaitingSearch: false,
      lista: "Pendiente",
      tab: null,
      overlay: false,
      crearPedidoVenta: false,
      showPedidoVenta: false,
      opcion: false,
      search: "",
      validar: [(v) => !!v || "Este campo es requerido"],
      tipoOperacion: ["Pendiente", "Presupuesto", "Finalizado"],
      parametro: {
        idcontacto: "",
        tiporeporte: "",
        fecha_inicio: substract_days(30),
        fecha_fin: current_date(),
      },
      headers: [
        { text: "Codigo", align: "left", value: "idpedido_venta" },
        {
          text: "Cliente",
          align: "left",
          value: "idcontacto.desccontacto",
        },
        { text: "Observacion", align: "left", value: "observacion" },
        { text: "Fecha Pedido", align: "left", value: "fecha" },
        { text: "Fecha Entrega", align: "left", value: "fecha_entrega" },
        {
          text: "Estado",
          align: "left",
          value: "idestadopedido.descestadopedido",
        },
        { text: "Accion", value: "accion", sortable: false, align: "right" },
      ],
      headers_reporte: [
        { text: "Codigo", align: "left", value: "idpedido_venta" },
        {
          text: "Cliente",
          align: "left",
          value: "idcontacto.desccontacto",
        },
        {
          text: "Responsable",
          align: "left",
          value: "idresp.desccontacto",
        },
        { text: "Fecha Pedido", align: "left", value: "fecha" },
        { text: "Fecha Entrega", align: "left", value: "fecha_entrega" },
        { text: "Observacion", align: "left", value: "observacion" },

        {
          text: "Estado",
          align: "left",
          value: "idestadopedido.descestadopedido",
        },
        {
          text: "Sub Total",
          align: "rigth",
          value: "total",
        },
      ],
      items: [{ title: "Pendiente" }, { title: "Presupuesto" }],
      more: [{ title: "Finalizado" }, { title: "Reporte" }],
      titulo: "Preventa",
      objeto: {},
      dialogoeliminar: false,
      editar: null,
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
    };
  },
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    this.Vact_pedidoventa_data({limit: 100, desc: null});
    const permission = getPermission(VENTA).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
  },
  computed: {
    ...mapGetters("contacto", ["Vget_contacto_data_cliente"]),
    ...mapGetters("pedidoventa", [
      "Vget_pedidoventa_data",
      "Vget_pedidoventa_load",
      "Vget_pedidoventa_presupuesto",
      "Vget_pedidoventa_reporte",
      "Vget_pedidoventa_finalizado",
    ]),
    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },
  watch: {
    search() {
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.busqueda();
          this.awaitingSearch = false;
        }, 1500);
      }
      this.awaitingSearch = true;
    }
  },
  methods: {
    ...mapActions("contacto", ["Vact_contacto_data_cliente"]),
    ...mapActions("pedidoventa", [
      "Vact_pedidoventa_data",
      "Vact_pedidoventa_presupuesto",
      "Vact_pedidoventa_finalizado",
      "Vact_pedidoventa_reporte",
      "Vact_pedidoventa_del",
    ]),
    buscarDesdeAgregar() {
      if (this.search.length >= 3) {
        this.busqueda()
      } else {
        this.obtener(this.lista)
      }
    },
    async busqueda() {
      if (this.search.length >= 3 && this.search != null) {
        switch(this.lista) {
          case "Pendiente":
            this.Vact_pedidoventa_data({limit: null, desc: this.search});
            break;
          case "Presupuesto":
            this.Vact_pedidoventa_presupuesto({limit: null, desc: this.search});
            break;
          case "Finalizado":
            this.Vact_pedidoventa_finalizado({limit: null, desc: this.search});
            break;
          default:
            break;
        }
      }
    },
    generarPdf() {
      Reporte(this.Vget_pedidoventa_reporte, this.headers_reporte);
    },
    descargarReporte() {
      if (!this.$refs.form.validate()) return null;
      this.Vact_pedidoventa_reporte(this.parametro);
    },
    obtener(val) {
      this.lista = val
      this.search = ""
      switch (val) {
        case "Pendiente":
          this.Vact_pedidoventa_data({limit: 100, desc: null});
          break;
        case "Presupuesto":
          this.Vact_pedidoventa_presupuesto({limit: 100, desc: null});
          break;
        case "Finalizado":
          this.Vact_pedidoventa_finalizado({limit: 100, desc: null});
          break;
        default:
          this.Vact_contacto_data_cliente();
          break;
      }
    },
    addItem(item) {
      const removed = this.items.splice(0, 1);
      this.items.push(...this.more.splice(this.more.indexOf(item), 1));
      this.more.push(...removed);
      this.$nextTick(() => {
        this.tab = "tab-" + item;
      });
    },
    meliminar(item) {
      this.objeto = Object.assign({}, item);
      this.dialogoeliminar = true;
    },
    eliminarcancelar() {
      this.objeto = {};
      this.dialogoeliminar = false;
    },
    async eliminarguardar() {
      this.overlay = true;
      const id = this.objeto.idpedido_venta;
      const a = await this.Vact_pedidoventa_del(id);
      if (a.estado == true) {
        this.$notify(a.info);
        this.eliminarcancelar();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
      this.overlay = false;
    },
    mupdate(val) {
      this.editar = JSON.parse(JSON.stringify(val));
      this.crearPedidoVenta = true;
    },
    mshow(val) {
      this.editar = JSON.parse(JSON.stringify(val));
      this.showPedidoVenta = true;
    },
  },
};
</script>
