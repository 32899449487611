<template>
  <div>
    <div v-if="dialogLote">
      <AutocompleteLote
        v-model="dialogLote"
        :sync-input="searchLote"
        @sync-input="(data.idlote_grupo = $event), next(1, '-'), key++"
      />
    </div>
    <v-card-text>
      <v-form ref="form">
        <v-row dense>
          <v-col cols="12">
            <v-autocomplete
              id="loteInput1"
              ref="loteInput1"
              v-model="data.idlote_grupo"
              :items="Vget_lotegrupo_data"
              :loading="Vget_lotegrupo_load"
              dense
              :key="key"
              :search-input.sync="searchLote"
              item-text="descripcion"
              return-object
              label="Seleccione la Zona al que pertenece"
              outlined
              autocomplete="off"
              @keyup.enter="next(1, data.idlote_grupo.idlote_grupo)"
            >
              <template v-if="permissionLote" v-slot:no-data>
                <v-list-item @click="crearLote()">
                  <v-list-item-content>
                    <v-list-item-title
                      >No existe concidencia. Click para
                      agregar</v-list-item-title
                    >
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              id="loteInput20"
              ref="loteInput20"
              v-model="data.idloteloc.idlotedis.idlotedep"
              :items="getLotedep"
              :loading="isLoadingLotedep"
              dense
              item-text="desclotedep"
              return-object
              label="Departamento"
              outlined
              @change="changeDep()"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              id="loteInput21"
              ref="loteInput21"
              v-model="data.idloteloc.idlotedis"
              :items="getLotedis"
              :loading="isLoadingLotedis"
              dense
              item-text="desclotedis"
              return-object
              label="Distrito"
              outlined
              @change="changeDis()"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              id="loteInput22"
              ref="loteInput22"
              v-model="data.idloteloc"
              :items="getLoteloc"
              :loading="isLoadingLoteloc"
              dense
              item-text="descloteloc"
              return-object
              label="Localidad"
              outlined
            ></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <c-text-currency
              label="Numero de Lote"
              dense
              outlined
              v-model="data.nrolote_agri"
              id="loteInput2"
              @keyup.native.enter="next(2, data.nrolote_agri)"
            ></c-text-currency>
          </v-col>
          <v-col cols="12">
            <vue-text-field
              label="Nombre del Lote"
              v-model="data.campo"
              id="loteInput3"
              @keyup.native.enter="next(3, data.campo)"
            ></vue-text-field>
          </v-col>
          <v-col cols="12">
            <vue-text-field
              id="loteInput4"
              :rules="false"
              label="Hectareas"
              v-model="data.hectarea"
              @keyup.native.enter="next(4, data.hectarea)"
            ></vue-text-field>
          </v-col>
          <v-col cols="12">
            <v-file-input
              accept=".kml,.kmz"
              label="Importar MAPA (kml,kmz)"
              v-model="data.kml"
            ></v-file-input>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="error" small outlined to="/agricultura/campo"
        >Cancelar</v-btn
      >
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        id="loteInputaceptar"
        small
        outlined
        @click="guardar"
        >Guardar</v-btn
      >
    </v-card-actions>
    <c-overlay :value="Vget_campo_save_load"></c-overlay>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { mensaje } from "@/services/notify.service";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { ZAFRA } from "@/constants/forms";
import { PUEDE_AGREGAR } from "@/constants/permissions";
import { focus } from "@/services/focus.service";
import AutocompleteLote from "../Mantenimiento/Lotegrupo/Create";
export default {
  components: {
    AutocompleteLote,
  },
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
  },
  computed: {
    ...mapGetters("campo", ["Vget_campo_save_load", "Vget_campo_geo_json"]),
    ...mapGetters("lotegrupo", ["Vget_lotegrupo_data", "Vget_lotegrupo_load"]),
    ...mapGetters("lote", [
      "getLotedep",
      "isLoadingLotedep",
      "getLotedis",
      "isLoadingLotedis",
      "getLoteloc",
      "isLoadingLoteloc",
    ]),
    permissionLote() {
      return getPermission(ZAFRA).permiso[PUEDE_AGREGAR];
    },
  },
  mounted() {
    this.next(0, "-");
    this.Vact_lotegrupo_data();
    this.fetchLotedep();
  },
  watch: {
    Vget_campo_geo_json(val) {
      if (val.id === undefined) {
        this.data.coordenadas = val.geometry.coordinates;
        this.data.hectarea = val.geometry.hectarea;
      }
    },
  },
  methods: {
    ...mapActions("campo", ["Vact_campo_save", "Vact_campo_geo_json"]),
    ...mapActions("lotegrupo", ["Vact_lotegrupo_data"]),
    ...mapActions("lote", ["fetchLotedep", "fetchLotedis", "fetchLoteloc"]),
    centroid(coordinates) {
      let coord = []
      let lat_list = []
      let lon_list = []
      let x = 0
      coordinates.forEach((coo) => {
        if (x == 0 && coordinates[0] == coo) {
          coord.push(coo)
          lat_list.push(coo[0])
          lon_list.push(coo[1])
          x += 1
        } else if (x <= 1 && coordinates[0] != coo) {
          coord.push(coo)
          lat_list.push(coo[0])
          lon_list.push(coo[1])
        }
      })
      let len = coord.length
      let lat = lat_list.reduce(function (accum, current) {
        return accum + current
      }, 0)
      let lon = lon_list.reduce(function (accum, current) {
        return accum + current
      }, 0)
      return [lat / len, lon / len]
    },
    async guardar() {
      if (
        !this.$refs.form.validate() ||
        (this.data.hectarea < 1 && this.data.kml === null)
      )
        return this.$notify(mensaje.completar());
      const a = await this.Vact_campo_save(this.getFormData());
      if (a.estado == true) {
        this.data = JSON.parse(JSON.stringify(this.default));
        this.$notify(a.info);
        this.$refs.form.resetValidation();
        this.Vact_campo_geo_json(a.data.data);
        this.Vact_lotegrupo_data();
      } else {
        a.info.map((x) => {
          this.$notify(mensaje.error(x));
        });
      }
    },
    changeDep() {
      this.fetchLotedis(this.data.idloteloc.idlotedis.idlotedep.idlotedep);
    },
    changeDis() {
      this.fetchLoteloc(this.data.idloteloc.idlotedis.idlotedis);
    },
    next(index, data) {
      focus.nextidName("loteInput", index, data);
    },
    crearLote() {
      this.dialogLote = true;
    },
    getFormData() {
      let Form = new FormData();
      Form.append("idlote_agri", this.data.idlote_agri);
      Form.append("idlote_grupo", JSON.stringify(this.data.idlote_grupo));
      Form.append("idloteloc", JSON.stringify(this.data.idloteloc));
      Form.append("nrolote_agri", this.data.nrolote_agri);
      Form.append("estado", this.data.estado);
      Form.append("descripcion", this.data.descripcion);
      Form.append("typelote_agri", this.data.typelote_agri);
      Form.append("id_polygon", this.data.id_polygon);
      Form.append("hectarea", this.data.hectarea);
      Form.append("campo", this.data.campo);
      Form.append("kml", this.data.kml);
      Form.append("coordenadas", JSON.stringify(this.data.coordenadas));
      return Form;
    },
  },
  data() {
    return {
      isActivemap: false,
      searchLote: "",
      key: 1,
      data: {
        idlote_agri: 0,
        idlote_grupo: {
          idlote_grupo: "",
        },
        idloteloc: {
          idloteloc: "",
          idlotedis: {
            idlotedis: "",
            idlotedep: {
              idlotedep: "",
            },
          },
        },
        nrolote_agri: "",
        estado: true,
        descripcion: "",
        typelote_agri: "Polygon",
        id_polygon: "new",
        hectarea: 0,
        campo: "",
        kml: null,
        coordenadas: [],
      },
      default: {
        idlote_agri: 0,
        idlote_grupo: {
          idlote_grupo: "",
        },
        idloteloc: {
          idloteloc: "",
          idlotedis: {
            idlotedis: "",
            idlotedep: {
              idlotedep: "",
            },
          },
        },
        nrolote_agri: "",
        estado: true,
        descripcion: "",
        typelote_agri: "Polygon",
        id_polygon: "new",
        hectarea: 0,
        campo: "",
        kml: null,
        coordenadas: [],
      },
      dialogLote: false,
    };
  },
};
</script>
