<template>
  <div>
    <v-alert type="error" v-if="permission_alert">
      Error!!! Este usuario no tiene permisos en esta ventana
    </v-alert>
    <v-toolbar flat dense>
      <v-toolbar-title>{{ $route.name }}</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <c-text-table-search
        v-if="permission.can_view"
        v-model="search"
      ></c-text-table-search>
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="getTipomaquinaria"
      :loading="isLoadingTipoMaquinaria"
      :search="search"
      v-if="permission.can_view"
    >
    </v-data-table>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { getPermission } from "@/services/storage/permissionStorage.service";
import { mensaje } from "@/services/notify.service";
import { TIPO_MAQUINARIA } from "@/constants/forms";
import {
  PUEDE_AGREGAR,
  PUEDE_EDITAR,
  PUEDE_ELIMINAR,
  PUEDE_LISTAR,
} from "@/constants/permissions";
export default {
  data() {
    return {
      title: TIPO_MAQUINARIA,
      search: "",
      headers: [
        {
          text: "Codigo",
          align: "left",
          value: "idmaquinaria_tipo",
        },
        {
          text: "Descripcion",
          align: "left",
          value: "descripcion",
        },
      ],
      permission: {
        can_add: false,
        can_view: false,
        can_update: false,
        can_delete: false,
      },
      crud: {
        delete: false,
        add: false,
        view: false,
        datos: null,
      },
    };
  },
  computed: {
    ...mapGetters("tipo_maquinaria", ["getTipomaquinaria", "isLoadingTipoMaquinaria"]),
    permission_alert() {
      return mensaje.permission(this.permission);
    },
  },
  created() {
    this.$store.commit("SET_LAYOUT", "principal-layout");
    const permission = getPermission(TIPO_MAQUINARIA).permiso;
    this.permission = {
      can_add: permission[PUEDE_AGREGAR],
      can_view: permission[PUEDE_LISTAR],
      can_update: permission[PUEDE_EDITAR],
      can_delete: permission[PUEDE_ELIMINAR],
    };
    this.fetchTipomaquinaria();
  },
  methods: {
    ...mapActions("tipo_maquinaria", ["fetchTipomaquinaria"]),
  },
};
</script>