export default {
  campo_ha: 0,
  campo_data: [],
  campo_clima: null,
  campo_pronostico: null,
  campo_storyndvi: null,
  campo_geo_json: {},
  campo_load: false,
  campo_save_load: false,
  campo_deleted: null,
  campo_baja_load: false,
}


