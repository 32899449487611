export default [
      {
        path: '/aborto',
        name: 'Aborto',
        component: () => import( /* webpackChunkName: "Aborto" */ '@/views/Enfermedad/Aborto/Aborto.vue'),
      },
      {
        path: '/enfermedad',
        name: 'Enfermedad',
        component: () => import( /* webpackChunkName: "Enfermedad" */ '@/views/Enfermedad/Enfermedad/Enfermedad.vue'),
      },
      {
        path: '/muerte',
        name: 'Muerte',
        component: () => import( /* webpackChunkName: "Muerte" */ '@/views/Enfermedad/Muerte/Muerte.vue'),
      },
      {
        path: '/rechazo',
        name: 'Rechazo',
        component: () => import( /* webpackChunkName: "Rechazo" */ '@/views/Enfermedad/Rechazo/Rechazo.vue'),
      },
      {
        path: '/tacto',
        name: 'Palpacion',
        component: () => import( /* webpackChunkName: "Palpacion" */ '@/views/Enfermedad/Tacto/Tacto.vue'),
      },
      {
        path: '/causaaborto',
        name: 'Causaaborto',
        component: () => import( /* webpackChunkName: "Causaaborto" */ '@/views/Enfermedad/Causaaborto/Causaaborto.vue'),
      },
      {
        path: '/causarechazo',
        name: 'Causarechazo',
        component: () => import( /* webpackChunkName: "Causarechazo" */ '@/views/Enfermedad/Causarechazo/Causarechazo.vue'),
      },
      {
        path: '/diagnosticoutero',
        name: 'Diagnosticoutero',
        component: () => import( /* webpackChunkName: "Diagnosticoutero" */ '@/views/Enfermedad/Diagnosticoutero/Diagnosticoutero.vue'),
      },
      {
        path: '/motivomuerte',
        name: 'Motivomuerte',
        component: () => import( /* webpackChunkName: "Motivomuerte" */ '@/views/Enfermedad/Motivomuerte/Motivomuerte.vue'),
      },
      {
        path: '/tipoenfermedad',
        name: 'Tipo Enfermedad',
        component: () => import( /* webpackChunkName: "Tipo Enfermedad" */ '@/views/Enfermedad/Tipoenfermedad/Tipoenfermedad.vue'),
      },
    ]