const TOKEN_KEY = 'access_token'
const REFRESH_TOKEN_KEY = 'refresh_token'

/**
 * Manage the how Access Tokens are being stored and retreived from storage.
 *
 * Current implementation stores to sessionStorage. Local Storage should always be
 * accessed through this instace.
**/
const TokenService = {
    getToken() {
        return sessionStorage.getItem(TOKEN_KEY) || localStorage.getItem(TOKEN_KEY) 
    },

    saveToken(accessToken, recordar) {
        if(recordar) return localStorage.setItem(TOKEN_KEY, accessToken)
        if(!recordar) return sessionStorage.setItem(TOKEN_KEY, accessToken)
    },
    removeToken() {
        sessionStorage.removeItem(TOKEN_KEY)
        localStorage.removeItem(TOKEN_KEY)
    },

    getRefreshToken() {
        return sessionStorage.getItem(REFRESH_TOKEN_KEY)
    },

    saveRefreshToken(refreshToken) {
        sessionStorage.setItem(REFRESH_TOKEN_KEY, refreshToken)
    },

    removeRefreshToken() {
        sessionStorage.removeItem(REFRESH_TOKEN_KEY)
    }

};


const DarkModeService = {
    saveDarkMode(data){
        this.removeDarkMode();
        localStorage.setItem('dark',data)
    },
    getDarkMode(){
        return JSON.parse(localStorage.getItem('dark')); 
    },
    removeDarkMode(){
        localStorage.removeItem('dark');
    }
};

const LocalService = {
    save(variable,data){
        this.remove(variable);
        localStorage.setItem(variable,data)
    },
    get(variable){
        return JSON.parse(localStorage.getItem(variable)); 
    },
    remove(variable){
        localStorage.removeItem(variable);
    }
};

export { TokenService, DarkModeService, LocalService }