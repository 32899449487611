<style>
.echarts {
  width: auto;
  height: 400px;
}
</style>
<template>
  <div>
    <v-row dense class="mx-1">
      <v-col cols="12" sm="6">
        <v-card max-width="450" color="ma-1">
          <v-card-title class="font-weight-medium text-h6"
            >Pendientes de Pago</v-card-title
          >
          <v-data-table
            :headers="headers"
            :items="Vget_gymplanilla_data"
            :loading="Vget_gymplanilla_load"
            :items-per-page="10"
            dense
          >
            <template v-slot:[`item.ultimo_pago`]="{ item }">
              <v-chip :color="colorb(item.ultimo_pago)" dark>
                {{ item.ultimo_pago }}
              </v-chip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card max-width="450" color="ma-1">
          <v-card-title class="front-weight-medium text-h6">
            Rutinas de hoy
          </v-card-title>
          <v-data-table
            :headers="headers2"
            :items="Vget_gymrutina_data_hoy"
            :loading="Vget_gymrutina_load"
            :items-per-page="10"
            dense
          >
            <template v-slot:[`item.detallado`]="{ item }">
              <c-btn-table-view @click="ver(item)"></c-btn-table-view>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="12" sm="12">
        <v-card-text class="pa-0">
          <v-container grid-list-xl fluid>
            <v-row>
              <v-col cols="12" lg="12" sm="12">
                <v-widget
                  title="Asistencias Diarias"
                  :content-bg="darkMode ? 'black' : 'white'"
                >
                  <v-btn icon slot="widget-header-action" @click="cargar_bar()">
                    <v-icon class="text--secondary">refresh</v-icon>
                  </v-btn>
                  <div slot="widget-content">
                    <div class="chart-wrapper">
                      <v-chart autoresize :options="datos" />
                    </div>
                  </div>
                  <div slot="widget-content">
                    <v-container>
                      <v-row dense>
                        <v-col cols="6">
                          <c-text-date
                            dense
                            outlined
                            label="Fecha Inicio"
                            v-model="gimnasio.inicio"
                          ></c-text-date>
                        </v-col>
                        <v-col cols="6">
                          <c-text-date
                            dense
                            outlined
                            label="Fecha Fin"
                            v-model="gimnasio.fin"
                          ></c-text-date>
                        </v-col>
                      </v-row>
                    </v-container>
                  </div>
                </v-widget>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialog" scrollable max-width="300px">
        <v-card>
          <v-card-title>{{ items.name }}</v-card-title>
          <v-divider></v-divider>
          <v-card-text style="height: 300px">
            <v-list>
              <v-list-item v-for="item in items.actividades" :key="item.name">

                <v-list-item-content>
                  <v-list-item-title v-text="item.detalle"></v-list-item-title>
                </v-list-item-content>

              </v-list-item>
            </v-list>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <c-btn-crud-close @click="dialog = false"></c-btn-crud-close>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import VWidget from "@/components/widgets/VWidget";
import ECharts from "vue-echarts/components/ECharts";
import { substract_days, current_date } from "@/services/util/date.service";
export default {
  components: {
    VWidget,
    "v-chart": ECharts,
  },
  data() {
    return {
      dialog: false,
      dialogm1: "",
      items: {
        name: "",
        rutina: [],
        actividades: [],
      },
      headers: [
        {
          text: "Cliente",
          align: "left",
          value: "idcontacto.idcontacto.desccontacto",
        },
        {
          text: "Ultimo pago",
          align: "left",
          value: "ultimo_pago",
        },
      ],
      headers2: [
        {
          text: "Cliente",
          align: "left",
          value: "name",
        },
        {
          text: "Rutina",
          align: "left",
          value: "rutina",
        },
        {
          text: "Detallado",
          align: "right",
          value: "detallado",
          sortable: false,
        },
      ],
      gimnasio: {
        inicio: substract_days(15),
        fin: current_date(),
      },
      datos: {
        color: ["#3398DB"],
        tooltips: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              title: {
                zoom: "Zoom",
                back: "Atras",
              },
              yAxisIndex: "none",
            },
            restore: {
              title: "Restaurar",
            },
            dataView: {
              readOnly: true,
              title: "Listar",
              lang: ["Listar", "Regresar"],
            },
            magicType: {
              type: ["line", "bar"],
              title: {
                line: "Linea",
                bar: "Bar",
              },
            },
            saveAsImage: {
              title: "Descarga",
            },
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: [],
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "Cantidad",
            type: "line",
            barWidth: "60%",
            data: [],
            markPoint: {
              data: [
                { type: "max", name: "Max" },
                { type: "min", name: "Min" },
              ],
            },
            markLine: {
              data: [{ type: "average", name: "Average" }],
            },
          },
        ],
      },
    };
  },
  mounted() {
    this.Vact_gymplanilla_data();
    this.Vact_gymrutina_data_hoy();
    this.cargar_bar();
    //this.Vact_gymasistencia_data_hoy({inicio: this.gimnasio.inicio}, {fin: this.gimnasio.fin})
  },
  computed: {
    ...mapGetters("gymplanilla", [
      "Vget_gymplanilla_data",
      "Vget_gymplanilla_load",
    ]),
    ...mapGetters("gymrutina", [
      "Vget_gymrutina_data_hoy",
      "Vget_gymrutina_load",
    ]),
    ...mapGetters("gymasistencia", [
      "Vget_gymasistencia_data_hoy",
      "Vget_gymasistencia_load",
    ]),
    darkMode() {
      return this.$vuetify.theme.dark;
    },
  },
  methods: {
    ...mapActions("gymplanilla", ["Vact_gymplanilla_data"]),
    ...mapActions("gymrutina", ["Vact_gymrutina_data_hoy"]),
    ...mapActions("gymasistencia", ["Vact_gymasistencia_data_hoy"]),
    colorb(val) {
      if (val == "Al dia") {
        return "green";
      } else {
        return "red";
      }
    },
    ver(item) {
      console.log(item);
      this.dialog = true;
      this.items = item;
    },
    async cargar_bar() {
      await this.Vact_gymasistencia_data_hoy({
        inicio: this.gimnasio.inicio,
        fin: this.gimnasio.fin,
      });
      let b = this.Vget_gymasistencia_data_hoy;
      this.datos.xAxis[0].data = b.fecha;
      this.datos.series[0].data = b.cantidad;
    },
  },
};
</script>