

export default {
  siembra_load: false,
  ha_sembrable: [],
  siembra_timeline: null,
  siembra_save_load: false,
  siembra_data_byId: [],
  siembra_data: []
}
